import React, {Component, Fragment} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
import {setUserAction} from "../../../Model/Action/index";
import {withRouter} from 'react-router-dom';
import {Container} from "react-bootstrap";
import Select from 'react-select';
import {request} from "../../../Model/Request";
import pipe from "./Assets/pipe.png";
import {quantities} from "../../../Model/quantities";









 class RegisterServices extends Component  {

  state = {
    
    services:null,
    servicesList:[],
    mainCategorySelected : {},
    selectedMainCategoryList:[],
    saveData:typeof this.props.importData !== "undefined" ? [...this.props.importData] : [ 
  /*      { 
        id:1,
        name:"Ablakos",
         specializations:[ 
            { 
              id:1,
              name:"Tetőablak",
               unit:2,
               price:3000,
               work_type:[2,5]
            },
         ]
      } ,
      { 
        id:1,
        name:"Ablakos",
         specializations:[ 
            { 
              id:1,
              name:"Tetőablak",
               unit:2,
               price:3000,
               work_type:[2,5]
            }
         ]
      } */
     
   ],
 
   preview:null,
   finalPreView:false,

   quantities : [
  ]

  }

  
  
  componentDidMount = () => {

    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
    this.setState({quantities})

    if(typeof this.props.importData !== "undefined"){ 
      this.setState( (state, props) =>{
        return {saveData: [...props.importData]}
      }) 
    }   
  }

  componentDidUpdate(prevProps, prevState){

    
   

    /// services change
    if(this.state.services !== null && this.state.services !==  prevState.services){
      this.mainTypeRequest()
    }

    /// main category selected
    if(this.state.mainCategorySelected !==  prevState.mainCategorySelected){
    

      /// prevState saveData[id].specializations üres-e
      if(prevState.saveData.length !== 0 && typeof prevState.mainCategorySelected.value !== "undefined"){
        this.setState({saveData:this.state.saveData.filter((item, index)=> item.specializations.length > 0 ), preview:null})
      }
       

      /// this.state.savedata létezik-e,ha nem hozzd létre
      if(this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value) === -1){
          const newSaveData = [...this.state.saveData.filter((item, index)=> item.specializations.length > 0 ),{id:this.state.mainCategorySelected.value, name:this.state.mainCategorySelected.label, specializations:[] }]
          this.setState({saveData:newSaveData, preview:null})
      }

      

      this.mainCategoryRequest();
    }

  }


  /* REFS */

  firstSelectRef;

  /* REQUESTS */

  mainTypeRequest = async () => {
    const response = await request({url:`/profession?type=${this.state.services}&per_page=99999&current_page=1`, method:"GET",  });
    this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
   }

  mainCategoryRequest = async () => {
   const response = await request({url:`/profession/specialization?profession_id=${this.state.mainCategorySelected.value}`, method:"GET",  });
   this.setState({selectedMainCategoryList:response.data})
  }


  /* HANDLERS */

  buttonStyleHandler = (services) => {

    let background = "white";
    let color = "black";


    if(services ===  this.state.services){
      background = services === "indoor" ? "#2686b0" : "#0C76B3"; 
      color = "white"
    }
    

    if(this.props.systemState.dimension.width < 550){
      return { width: "100%", marginBottom: 10, background, color }
    }
    return {width: 340, background, color}

  }

  buttonFunctionHandler = (services) => {
    this.setState({services})
  }

  clickOnText = (e) =>  e.target.parentNode.parentNode.childNodes[0].childNodes[0].click()
  

  checkBox = ({type, isSelected, mainItem, mainIndex, subItem, subIndex}) => {
    return(
      <div
        className="ch_box"
        style={{height:30, width:30, border:'1px solid #4F4F4F', borderRadius:4, display:"flex", justifyContent:"center", alignItems:"center"}} 
        onClick={()=>this.toggleCheckBox({type, isSelected, mainItem, mainIndex, subItem, subIndex})}>
          { 
          isSelected 
            ? <img src={pipe} style={{height:15, width:20}} />
            : null 
          }
         
        </div>
      )
  };

  toggleCheckBox = ({type, isSelected, mainItem, mainIndex, subItem, subIndex}) => {
    
    const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
    
  



    /// main handler
    if(isSelected &&  type === "main"){
     
      const newData = this.state.saveData[saveDataMainIndex].specializations.filter((item, index) =>  item.id !== mainItem.id);
            
      
      let newSaveData = [...this.state.saveData];
          newSaveData[saveDataMainIndex].specializations = newData;


      /* let newSaveData = this.state.saveData.map( (item, index)=> {
        if(index === saveDataMainIndex){ return {...item, specializations: newData} }
        else{ return {...item} }
      }); */
 

      this.setState({saveData:newSaveData})
     
    }else if(isSelected === false && type === "main"){
      
      const newSpecializations = [...this.state.saveData[saveDataMainIndex].specializations,
      {"id":mainItem.id,
      name:mainItem.title,
      unit:null,
      price:1,
      work_type:[]}]

      let newSaveData = [...this.state.saveData];
      newSaveData[saveDataMainIndex].specializations = newSpecializations;

    /*   let newSaveData = this.state.saveData.map( (item, index)=> {
        if(index === saveDataMainIndex){ return {...item, specializations: newSpecializations} }
        else{ return {...item} }
      }); */

      this.setState({saveData:newSaveData})
    }

    if(isSelected && type === "sub"){

      const specializationsID = this.state.saveData[saveDataMainIndex].specializations.findIndex((indexOf) => indexOf.id === mainItem.id );

      const newWorktype = this.state.saveData[saveDataMainIndex].specializations[specializationsID].work_type.filter((item)=> item !== subItem.id );

      let newSaveData = [...this.state.saveData];
          newSaveData[saveDataMainIndex].specializations[specializationsID].work_type = newWorktype;
          this.setState({saveData:newSaveData})


    }else if(isSelected === false && type === "sub"){
      const specializationsID = this.state.saveData[saveDataMainIndex].specializations.findIndex((indexOf) => indexOf.id === mainItem.id );
      let newSaveData = [...this.state.saveData];
          newSaveData[saveDataMainIndex].specializations[specializationsID].work_type = [...newSaveData[saveDataMainIndex].specializations[specializationsID].work_type, ...[subItem.id]];
          this.setState({saveData:newSaveData})
    } 



  }

  inputValueHandler = ({subID, value}) => {
    /// find main id
    const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
     /// find subID
    const saveDataSubID = this.state.saveData[saveDataMainIndex].specializations.findIndex( (f)=> f.id === subID);

    let newSaveData = [...this.state.saveData];

    /// handle errors
    if(value === "" || isNaN(value) ) value = 0;

    newSaveData[saveDataMainIndex].specializations[saveDataSubID].price = parseInt(value);

    this.setState({saveData:newSaveData});

  }

  setInputValue = ({subID}) => {
    /// find main id
    const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
     /// find subID
    const saveDataSubID = this.state.saveData[saveDataMainIndex].specializations.findIndex( (f)=> f.id === subID);

    return this.state.saveData[saveDataMainIndex].specializations[saveDataSubID].price;

  }

  selectDefaultValue = ({subID}) => {
    /// find main id
    const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
    /// find subID
    const saveDataSubID = this.state.saveData[saveDataMainIndex].specializations.findIndex( (f)=> f.id === subID);


    if(this.state.saveData[saveDataMainIndex].specializations[saveDataSubID].unit === null){
      return   { value: '',   label: 'VÁLASSZ!'  }
    }else{

     const arryIndex = this.state.quantities.findIndex((f)=>f.value === this.state.saveData[saveDataMainIndex].specializations[saveDataSubID].unit);
     if(arryIndex === -1){
      return   { value: '',   label: 'VÁLASSZ!'  }
     }else{
      return this.state.quantities[arryIndex]
     }

    }

  }

  selectSetValue = ({subID, value}) => {
      /// find main id
      const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
      /// find subID
      const saveDataSubID = this.state.saveData[saveDataMainIndex].specializations.findIndex( (f)=> f.id === subID);

      /// handle errors
    if(value.value === "" || isNaN(value.value) ) value = 0;

        const newSaveData = [...this.state.saveData];
    newSaveData[saveDataMainIndex].specializations[saveDataSubID].unit = parseInt(value.value);
    this.setState({saveData:newSaveData});
  }

  preViewSelect = async (item) => {
    
    const {name} = item


    this.setState({preview: this.state.preview === item.id ? null : item.id})

        

        if(this.state.finalPreView === true) return

        const response = await request({url:"/profession?per_page=1000&current_page=1", method:"GET"});


        const services_ = response.data?.profession?.items ?? "";
        const serviceArray = services_.filter( item => item.name === name);
        const serviceType = serviceArray[0].type
        

        if(typeof serviceType === "undefined")return
        

        this.setState({
          services : serviceType ?? "",
          servicesList: services_ === "" && typeof serviceType === "undefined"  ? [] : services_.filter(item => item.type === serviceType).map( item => {return  {value: item.id, label: item.name}  }),
          mainCategorySelected:  {value: serviceArray[0].id, label:  serviceArray[0].name}
        })

        this.firstSelectRef.state.value =  {value: serviceArray[0].id, label:  serviceArray[0].name}
       



  }


  /* COMPONENTS */

  selectComponent = (item, index) => {

    /// number or -1 if false
    const saveDataMainIndex = this.state.saveData.findIndex( (f)=> f.id === this.state.mainCategorySelected.value);
    let mainSelected = false;
    let specializationsID = null;
    if(saveDataMainIndex !== -1){
     // mainSelected = this.state.saveData[saveDataMainIndex].specializations.some(e=> e.id === item.id);

    specializationsID = this.state.saveData[saveDataMainIndex].specializations.findIndex((indexOf) => indexOf.id === item.id );

     if(specializationsID !== -1){
        mainSelected = true
     }

     

    }
    

    return(
             /* MAIN CONTAINER */ /* ...styles.select.mainContainer, maxHeight:"fit-content",whiteSpace:"nowrap" */
            <div key={index}>
              {/* container 1 */}
              <div style={{...styles.select.container_1, marginTop:15, marginBottom:15}}>
                  {/* 1.st block */}
                  <div style={{...styles.select.container_1}}>{this.checkBox({type:"main", isSelected:mainSelected, mainItem:item, mainIndex:index, subItem:null, subIndex:null})}</div>
                  {/* 2.nd block */}
                  <div style={{...styles.select.container_10}} onClick={(e)=>this.clickOnText(e)} ><div style={{...styles.p, marginTop:5, fontWeight:"bold", marginLeft:8, cursor:"pointer"}}>{item.title}</div></div>
              </div> 
              {  mainSelected
              ?      
              <>
                {/* container 2 */}
                <div style={{...styles.select.container_1}}>
                  {/* 1.st block */}
                  <div style={{...styles.select.container_1}}>{/* empty */}</div>
                    {/* 2.nd block */}
                    <div style={{display:"flex", flexDirection: this.props.systemState.dimension.width > 995 ? "row" : "column", flex:10}}>
                          {/* title */}
                          <div style={{...styles.select.container_1, alignItems:'center'}}><div style={{...styles.p,fontSize:12, padding:5}}>MÉRTÉKEGYSÉG</div></div>
                          {/* select */}
                          <div style={{...styles.select.container_5, marginTop:15, marginBottom:15}}> <Select options={this.state.quantities} styles={styles.quantitySelect} placeholder={"VÁLASSZ!"} defaultValue={this.selectDefaultValue({subID:item.id})} onChange={(e)=> this.selectSetValue({subID:item.id, value:e}) }    /> </div>
                          {/* title */}
                          <div style={{...styles.select.container_1, alignItems:'center'}}><div style={{...styles.p, padding:5}}>ÁR</div></div>
                          {/* input */}
                          <div style={{...styles.select.container_5, marginTop:18, marginBottom:15}}><input type={"number"} style={{border:'1px, black solid', width:"100%", height:35, borderRadius:4, outline:'none', paddingLeft:8}} onChange={(e)=>this.inputValueHandler({subID:item.id, value:e.target.value})} value={this.setInputValue({subID:item.id})}  /></div>
                      </div>
                  </div>  

                {/* container 3 */}
                <div style={{...styles.select.container_1}}>
                    {/* 1.st block */}
                    <div style={{...styles.select.container_1}}>{/* empty */}</div>
                    {/* 2.nd block */}
                    <div style={{...styles.select.container_10, flexDirection:"column"}}>

                      {/* sub container  */}
                    
                    {item.items.map( (sItem, sIndex) => {

                        let subSelected = false
                      
                        if(mainSelected){
                        //  const specializationsID = this.state.saveData[saveDataMainIndex].specializations.findIndex((indexOf) => indexOf.id === item.id );
                          subSelected = this.state.saveData[saveDataMainIndex].specializations[specializationsID].work_type.includes(sItem.id);
                       
                        }
                      
                        return(
                          <div style={{...styles.select.container_1, marginTop:15, marginBottom:15 }} key={index + sIndex + sItem.id}>
                              {/* 1.st block */}
                              <div style={{...styles.select.container_1}}>{this.checkBox({type:"sub", isSelected:subSelected, mainItem:item, mainIndex:index, subItem:sItem, subIndex:sIndex})}</div>
                              {/* 2.nd block */}
                              <div style={{...styles.select.container_10, alignItems:'center'}} onClick={(e)=>this.clickOnText(e)} ><p style={{...styles.p, marginTop:5, fontWeight:"bold", marginLeft:8, cursor:"pointer"}}>{sItem.title}</p></div>
                          </div> 
                        )}  
                      )
                    }

                    </div>
                </div> 
              </>
              : null }
            </div> 
     
    )
  }


  preview = (item, index, isOpenAll) =>{
    return(
      <div style={{display:"flex", flexDirection:"column", margin:15, maxHeight:"fit-content", whiteSpace:"nowrap"}} key={index + "preViewMain"} onClick={()=> this.preViewSelect(item) } >

    <div style={{display:"flex",padding:15, backgroundColor:"#0C76B3", justifyContent:'flex-start', borderRadius:4}}><div style={{...styles.p, fontSize:20, fontWeight:"bold", color:"white"}} >{item.name}</div></div>

      {

      isOpenAll ?

      item.specializations.map( (subItem, subIndex) => 
      <div style={{display:"flex", flex:1,flexDirection:'column', maxHeight:80, marginBottom:20}} key={index + "preViewSub" + subItem.id} >
          <div style={{display:"flex", flex:1, marginTop:10, marginBottom:10, maxHeight:25}}>
            <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black"}} >{subItem.name}</div>
          </div>
          <div style={{display:"flex", flex:1,maxHeight:25, flexDirection:'row', justifyContent:'space-between'}}>

            <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black"}} >{subItem.price} Ft</div>
            {
              subItem.unit === null 
                ? <div style={{...styles.p, fontSize:10, color:"red", marginRight:10}} >NINCS MÉRTÉKEGYSÉG</div>  
                : <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black", marginRight:10}} >{ this.state.quantities[subItem.unit]?.label ?? ""}</div> 
            }

          </div>
      </div> )

      :

     typeof this.state.mainCategorySelected.label !==  "undefined" || item.name === this.state.mainCategorySelected.label || item.id === this.state.preview ? 

      item.specializations.map( (subItem, subIndex) => 
      <div style={{display:"flex", flex:1,flexDirection:'column', maxHeight:80, marginBottom:20}} key={index + "preViewSub" + subItem.id} >
          <div style={{display:"flex", flex:1, marginTop:10, marginBottom:10, maxHeight:25}}>
            <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black"}} >{subItem.name}</div>
          </div>
          <div style={{display:"flex", flex:1,maxHeight:25, flexDirection:'row', justifyContent:'space-between'}}>

            <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black"}} >{subItem.price} Ft</div>
            {
              subItem.unit === null && typeof subItem.unit === "undefined" 
                  ? null 
                  : <div style={{...styles.p, fontSize:15, fontWeight:"bold", color:"black", marginRight:10}} >{  this.state.quantities[subItem.unit]?.label ?? "" }</div> 
            }
          
          
          </div>
      </div>  
      )
      :null
      }


  </div>
    )
  } 

  preViewCompinent = ({type}) => {

     return this.state.saveData.filter( item => item.specializations.length > 0 ).map( (item, index)  => 
     {
        if(type === "odd" && index % 2 === 1 ){
          return this.preview(item, index, true)
        }else if(type === "even" && index % 2 === 0){
          return this.preview(item, index, true)
        }else if(type === "normal"){
          return this.preview(item, index, false)
        }else{
          return null
        }  
     })
    
  }

  registerButton = async () => {
    window.scrollTo(0, 0)

      let stop = false;

      this.state.saveData.map( item => {
        item.specializations.map( subItem => {
          if(subItem.unit === null){
            stop = true
          }
        })
      });

     

    if(stop === true){
      document.querySelector("#errorText").innerHTML = "A mértékegység megadáása kötelező";
      document.querySelector(".registerButton").style.display = "none";
    }else{
      document.querySelector("#errorText").innerHTML = "";
      document.querySelector(".registerButton").style.display = "unset";
    }
    
    if(this.state.finalPreView === false){
      this.setState({finalPreView:true})
    }else{


      if(typeof this.props.importData === "undefined"){

        //const response = "";

        const saveData = [{company_id:this.props.userState.user.incompleteRegistration.stepTwo.details.company.id}, ...this.state.saveData.filter( item => item.specializations.length > 0 )]

        const response = await request({url:"/auth/register/step-four", method:"POST", data: saveData });
            
              if( typeof response.message !== "undefined" && response.message === "Created successful!") {
            
                const { ["incompleteRegistration"]: undefined, ...restUser } = this.props.userState.user;



                this.props.setUserAction({...restUser, company:[
                { 
                type: "Company",
                id: this.props.userState.user.incompleteRegistration.stepTwo.details.company.id,
                selected: true,
                attributes: {
                    name: this.props.userState.user.incompleteRegistration.stepTwo.details.company.name,
                    type: this.props.userState.user.incompleteRegistration.stepTwo.details.company.type,
                    phone_number: this.props.userState.user.incompleteRegistration.stepTwo.details.phoneNumber,
                    tax_number: this.props.userState.user.incompleteRegistration.stepTwo.details.tax_number,
                    registration_number: this.props.userState.user.incompleteRegistration.stepTwo.details.registrationNumber,
                    certificate_number: this.props.userState.user.incompleteRegistration.stepTwo.details.certificateNumber,
                  }
                }
              ]});

                this.props.history.push("/experts/profiles");

              }else{
                document.querySelector("#errorText").innerHTML = "Hiba a szerverrel való kommunikáció során!";
              }


      }else{
        
       
         // console.log([...this.state.saveData.filter( item => item.specializations.length > 0 )])
        this.props.exportData(this.state.saveData.filter( item => item.specializations.length > 0 ))

              
              

      }
      
        





    }


  }

  render(){

    console.log(this.state)
   
    const window_width = this.props.systemState.dimension.width;
    return (
    <div> 
      <Container style={{backgroundColor:"white", padding:50, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", marginBottom:30 }}>
            
            <p style={{...styles.p, fontSize:20, fontWeight:"bold"}}>SZOLGÁLTATÁSOK</p>

            <div style={{...styles.p, fontSize:12,}}>Ahhoz hogy kialakítsd, a szolgáltatás listád először válaszd ki, hogy BELTÉRI MUNKÁLATOT vagy KÜLTÉRI MUNKÁLATOT szeretnél felvinni.</div>
            <div style={{...styles.p, fontSize:12,}}>Majd pedig a legördülő lehetőségek közül válaszd ki a konkrét szoláltatást.</div>
            <div style={{...styles.p, fontSize:12,}}>Add meg a munka mértékegységét és árát.</div>
            <div style={{...styles.p, fontSize:12,}}>Miután az előnézettben ellenőrizted az egész listád mentsd el.</div>

            <div style={{ marginBottom:25 }}></div>

            <div style={{...styles.divider}} />

            {
              this.state.finalPreView 
              ? <div>

                    <div style={{display:"flex", flexDirection:window_width < 550 ? "column" : "row", marginTop:30}}>
                      {/* SELECTOR CONTAINER */}
                      <div style={{display:"flex", flex:10, flexDirection:'column'}}>
                  
                        <div style={{display:"flex", flex:10, flexDirection:'column'}}>{this.preViewCompinent({type:"odd"})}</div>

                      </div>
                      {/* PREVIEW CONTAINER */}
                      <div style={{display:"flex", flex:1, justifyContent:'center', marginTop:10, marginBottom:10}}>
                        <div style={{height:'100%', width:1, backgroundColor:"black"}}/></div> 
                        <div style={{display:"flex", flex:10, flexDirection:'column'}}>{this.preViewCompinent({type:"even"})}</div>
                  </div>


                </div>
            
              :<div>
              {/* EXTERNAL / INTERNAL CONTAINER */}
              <div style={{display:"flex", flexDirection:window_width < 550 ? "column" : "row" , justifyContent:"space-evenly", paddingBottom:25, paddingTop:25}}>
                <div style={{...styles.p, ...styles.button, ...this.buttonStyleHandler("indoor")  }}  onClick={()=>this.buttonFunctionHandler("indoor")} >BELTÉRI MUNKÁLATOK</div>
                <div style={{...styles.p, ...styles.button, ...this.buttonStyleHandler("outdoor")  }}  onClick={()=>this.buttonFunctionHandler("outdoor")} >KÜLTÉRI MUNKÁLATOK </div>
              </div>

              <div style={{...styles.divider, marginBottom:40}} />

              <Select options={this.state.servicesList} onChange={(e)=>this.setState({mainCategorySelected:e})} styles={this.mainSelectStyle}  placeholder={"SZOLGÁLTATÁSOK LISTÁJA"} noOptionsMessage={()=><div>ELÖSZÖR ADD MEG, HOGY BELTÉRI, VAGY KÜLTÉRI MUNKÁLATOKAT SZERETNÉL-E FELVINNI</div>} ref={(ref) => this.firstSelectRef = ref}/>

            

              <div style={{display:"flex", flexDirection:"row", marginTop:30}}>
                  {/* SELECTOR CONTAINER */}
                  <div style={{display:"flex", flex:10, flexDirection:'column'}}>
              
                    {this.state.selectedMainCategoryList.map( (item, index) => this.selectComponent(item, index)) }

                  </div>
                  {/* PREVIEW CONTAINER */}
                  { window_width < 750 ? null : <><div style={{display:"flex", flex:1, justifyContent:'center', marginTop:10, marginBottom:10}}><div style={{height:'100%', width:1, backgroundColor:"black"}}/></div> <div style={{display:"flex", flex:10, flexDirection:'column'}}>{this.preViewCompinent({type:"normal"})}</div></>}
              </div>
              </div>
            }
            



        
              <div className="d-flex row w-100 errorPadding">
                    <div className="w-100">
                        <div className="hdivideretop"></div> 
                        <div className="errorText" id="errorText"></div>
                        {/* <div className="hdividerebottom"></div> */}
                    </div>
                </div>   
                
                <div className="d-flex row justify-content-center justify-content-lg-end ">
                    <div className="buttons">
                      { typeof this.props.importData === "undefined" 
                          ? <button className="backButton" onClick={()=> this.state.finalPreView ? (()=>{this.setState({finalPreView:false}); document.querySelector("#errorText").innerHTML = ""; document.querySelector(".registerButton").style.display = "unset"; })()  : this.props.goBack()} >Vissza</button>
                          : this.state.finalPreView 
                              ? <button className="backButton" onClick={()=> this.state.finalPreView ? (()=>{this.setState({finalPreView:false}); document.querySelector("#errorText").innerHTML = ""; document.querySelector(".registerButton").style.display = "unset"; })()  : this.props.goBack()} >Vissza</button>
                              : <button className="backButton" onClick={()=> this.props.cancel() }>MÉGSEM</button>

                      }
                          <button className="registerButton" onClick={()=> this.registerButton()}>{ this.state.finalPreView ? "Mentés" : "Előnézet" }</button>
                    </div>                
                </div> 
                </Container>
            
    </div>
    );

  }


  mainSelectStyle = {

    singleValue: (provided, state) => {
     const color = "white"
  
      return { ...provided, color };
    },

    placeholder: provided => ({
      ...provided,
      color:"white",
    }),
    container: provided => ({
      ...provided,
      display: this.state.services === null ? "none" : "block" ,
      border:'1px black solid',
      borderRadius:4,
      width: "100%",
      marginLeft:5, 
      marginRight:10,
     fontFamily:"Maven Pro",
     fontSize:16, 
     fontWeight:"bold",
     color:'white',
    
    }),
    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected 
      ? this.state.services === "indoor" 
        ? "#2686b0" 
        : "#0C76B3"
      :"white",

      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"
      
    }),
    control: base => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      fontFamily:"Maven Pro",
      backgroundColor: this.state.services === "indoor" ? "#2686b0" : "#0C76B3",
      color:"white!important"
    })
  };

}

const styles = {
  p:        {fontSize:12, fontFamily:"Maven Pro", letterSpacing:2},
  divider:  {height:1, widht:"100%", backgroundColor:"#4F4F4F"},
  button:   {color:"white", padding:8, display:"flex", justifyContent:"center" ,borderRadius:4, border:'1px solid #4F4F4F', height:65, alignItems:"center", fontSize:20, cursor:"pointer"},
  select: {
    mainContainer:{display:"flex", flex:1, flexDirection:'column'},
    container_1:{display:"flex", flex:1},
    container_5:{display:"flex", flex:5},
    container_10:{display:"flex", flex:10},
  },
  imgDIV : {
    backgroundSize:"cover",
    objectFitt:"cover",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"center",
    height:30,
    widht:30

  },



  quantitySelect:{
    container: provided => ({
      ...provided,
      border:'1px black solid',
      borderRadius:4,
      width: "100%",
      marginLeft:5, 
      marginRight:10,
     fontFamily:"Maven Pro",
     fontSize:12,
     color:'#4F4F4F',
     maxHeight:40,
    }),
    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected ? "#1C466F":"white",
      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"
      
    }),
    control: base => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      fontFamily:"Maven Pro"
    })
  }
}




/*====================== 
* State to Props
=====================*/
 const mapStateToProps = state => {
    const {systemState, userState, tokenState} = state;
    return {systemState, userState, tokenState};
};
  
/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
  setUserAction
})(RegisterServices));

