import React, {Component} from 'react';
import {connect} from 'react-redux';
import queryString from "query-string";
import {request}   from "../../../../Model/Request";
import parse from 'html-react-parser';
import default_image from "../../../../Assets/mester_default.png"
import { Modal, Button} from "react-bootstrap";
/* ROUTER */
import {withRouter} from "react-router-dom";
import chat_icon from "../../../../Assets/message_2.png";

import './OfferDetails.css'



 class OfferDetails extends Component  {

  state ={
    ads_tittle: '',
        ads_id:'',
        ads_description:'',
        ads_duedate:"",
        ads_cost_limit:"",
        ads_workplace:"",
        ads_img:'',
        offer_description:"",
        offer_details:[
          /*   {job_tittle:"Tetőablak",job_quantity:"1 Brb",job_price:"75000 Ft"},
            {job_tittle:"Homlokzati Nyílászáró",job_quantity:"1 Brb",job_price:"75000 Ft"},
            {job_tittle:"Műanyag Ablak",job_quantity:"1 Brb",job_price:"75000 Ft"}, */
        ],
        offer_total:"",
        status:'',  //inview, accepted, rejected
        id: '',

        expert_id:'',


        isModalShow:false, 
        errorMessage:"",  
        successMessage:""
  }


  componentDidMount(){
    const parsed = queryString.parse(this.props.location.search);
    /* GUARD FUNCTION */
    if(typeof parsed.id === "undefined" || parsed.id === null || parsed.id === "" || isNaN(parsed.id)) this.props.history.goBack()

    this.get_data(parsed.id)


    ///offers?offer_id=13
  }

  get_data = async (id) => {

        const response = await request({url:`offers?offer_id=${id}`})

      console.log(response)
     
        if(typeof response.error !== "undefined" || typeof response.data === "undefined") return


        let offer_total = 0;

        const offer_details = [

            ...response.data?.offer?.specializations?.map( item => { 
                    offer_total = offer_total + parseInt(item.price)
                    return { job_tittle:item.name, job_quantity:`${item.quantity} ${item.symbol}`,job_price:`${item.price} Ft`} 
                }) ?? null,


            ...response.data?.offer?.additional?.flatMap( (item => item.specializations.map( subItem => {
                offer_total = offer_total + parseInt(subItem.price)
                return { job_tittle: typeof subItem.name === "undefined" ? subItem.id : subItem.name, job_quantity:`${subItem.quantity} ${subItem.symbol}`,job_price:`${subItem.price} Ft`} 
             }  )) ) ?? null

        ]

        this.setState({

                ads_tittle:response.data?.ad?.attributes?.profession?.name ?? "",
                ads_id:response.data?.ad?.id ?? "",
                ads_description:response.data?.ad?.attributes?.description ?? "",
                ads_duedate:response.data?.ad?.attributes.deadline?.name ?? "",
                ads_cost_limit:response.data?.ad?.attributes?.payment_limit?.name ?? "",
                ads_workplace:response.data?.ad?.attributes?.location?.city ?? "",
                ads_img:response.data?.ad?.attributes?.basic_image?.src ?? default_image,
                offer_description:response.data?.offer?.description,
                offer_details:offer_details.filter(item => item !== null),
                offer_total,
                status:response.data?.offer?.status ?? "",  //sent,confirmed
                id: response.data?.offer?.id ?? "",
                expert_id: response.data?.offer?.sender?.id ?? ""

        })


  }


  response = async (type) => {

    let method = type === "accept" ? "PATCH" : "DELETE";

      const response = await request({ url:"/offers", method, data:{ offer_id: this.state.id } })



      if(typeof response.error !== "undefined"){
          this.setState({
                isModalShow:true,
                errorMessage:"Hiba lépett fel a szerverrel való kommunikáció során! Kérlek próbáld meg később."
          })
      }

      if(typeof response.error === "undefined"){
        this.setState({
            isModalShow:true,
            successMessage: method === "PATCH" 
                ? "SIKERESEN ELFOGADTAD AZ AJÁNLATOT!"
                : "SIKERESEN ELUTASÍTOTTAD AZ AJÁNLATOT!"
      })
      }




  }


  render(){
    
    


console.log(this.state)

    return (
      <>
      <Modal show={this.state.isModalShow} onHide={ ()=> {
              if(this.state.successMessage !== ""){
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                this.props.history.goBack()
              }else{
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
              }
              
              } }>
        <Modal.Header>
                <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{...styles.p}}>{this.state.errorMessage}</div>
                <div style={{...styles.p}}>{this.state.successMessage}</div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant={ this.state.successMessage === "" ? "secondary" : "primary"} onClick={ ()=> 
                  
                  {
                    if(this.state.successMessage !== ""){
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                      this.props.history.goBack()
                    }else{
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                    }
                  }
                  
                  }>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
      <div className="innerContainer d-flex justify-content-center">
        <div className="innerAligner d-flex flex-wrap justify-content-start">
          <div className="w-100 offersdetail">
            <div className="offersadstittle">Hirdetés</div>
            <div className="offersadssection d-flex flex-wrap justify-content-start w-100">
                <div className="offersads-left">
                    
                        <div className="adsleft-subtittle offpaddingsmall">{this.state.ads_tittle}</div>
                        <div className="adsleft-text">Hirdetés szám: {this.state.ads_id}</div>
                        <div className="adsleft-text offpaddingsmall">Leirás:</div>
                        <div className="adsleft-text" style={{...styles.description}}>{parse(this.state.ads_description)}</div>
                </div>  
                <div className="vdecor"></div>                  
                <div className="offersads-middle">
                        <div className="adsright-subtittle">Határidő</div>
                        <div className="adsright-text offpaddingsmall">{this.state.ads_duedate}</div>
                        <div className="adsright-subtittle">Költséghatár</div>
                        <div className="adsright-text offpaddingsmall">{this.state.ads_cost_limit}</div> 
                        <div className="adsright-subtittle">Munka végzés helye</div>
                        <div className="adsright-text">{this.state.ads_workplace}</div>                                                
                </div>
                
                <div className="offersads-right">
                    <img className="offersads-img" src={this.state.ads_img} alt="image"/>
                </div>                                
            </div>
            {
          this.state.expert_id !== ""
            ? <div style={{flex:1, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
             <div style={{ height:60, width:60, backgroundColor:"rgba(38,134,176,0.6)",backgroundPosition:"center", backgroundSize:"60%", backgroundRepeat:"no-repeat" ,backgroundImage:`url(${chat_icon})`, borderRadius:7 }}
              onClick={()=> this.props.history.push({
              pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
              search: '',
              state: { type: "user" , id:  this.state.expert_id}
            }) }/>
            <div style={{display:"flex", height:60, alignItems:"center"  }}><div style={{padding:5, fontSize:10}}>BESZÉLGETÉS AZ AJÁNLATTEVŐVEL</div></div>
            </div>
            : null
           }
            <div className="offersadstittle">Elkészített ajánlat</div>
            <div className="offersads-hdekor"></div>
            <div className="demotivator">
                <div className="offersadstittle tittlemargin">Tisztelt Hirdető!</div>
                <div className="offersadsdescription">{parse(this.state.offer_description)}</div>
                <div className="offersadstittle text-left tittlemodder">Tételek</div>
                <div className="offersads-hdekor tittlemarginb"></div>
                <div className="offersads-offerssection">
                    {
                    this.state.offer_details.map(
                        (ret, index)=>
                        {
                        return (
                            <div className="offersads-elements d-flex w-100 justify-content-end" key={index}>
                                <div className="offeradselementcontainer">
                                    <div className="offers-name">{ret.job_tittle}</div>
                                    <div className="offers-pricesection d-flex w-100">
                                        <div className="offers-quantity offercolor w-50">{ret.job_quantity}</div>
                                        <div className="offers-price offercolor w-50 text-right">{ret.job_price}</div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    )}

                </div>
                <div className="offersads-alldecor d-flex w-100 justify-content-end">
                    <div className="offersads-hdekor sizedekor"></div>
                </div>
                <div className="offersads-allprice d-flex w-100 justify-content-end">
                        <div className="offerads-allpricecontainer">
                            <span className="alltext">Összesen:</span>
                            <span className="allprice">{this.state.offer_total}</span>
                        </div>
                </div>
                <div className="offersads-endbuttons d-flex w-100 justify-content-end">
                    <div className="d-flex justify-content-sm-end justify-content-center">
                    { this.state.status == "sent" 
                    
                    ?
                        <div className="d-flex align-items-center flex-wrap">
                          <button className="declinebtn" onClick={()=> this.response("reject")}>Elutasítás</button>
                          <button className="acceptbtn"  onClick={()=> this.response("accept")}>Ajánlat elfogadása</button>
                        </div>
                    :
                        <div className="text-center offeraccepted">Az ajánlatot elfogadta!</div>
                    }
                    </div>
                </div>            
            </div>


          </div>
        </div>
      </div>
      </>      
    );


  }
}

const styles = {
    description:{maxHeight:145, overflow:"hidden"}
}





/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default  withRouter(connect(mapStateToProps,{})(OfferDetails));



