import React, {Component} from 'react';
import { Link } from "react-router-dom";
//styles
import './AskOffers.css';

const PromotionalOffers = [
    {
        tittle: "Mennyi ideig tarta a klima beszerelése és hogyan kell előkészülni rá",
       description:`Egy átlagos klímaszerelés munkaideje 4-5 óra. Ez egy egyszerűbb szerelés, ami 3-5 méter csövezést, illetve egy vagy két fal átfúrását jelenti. Általában egy beszerelő brigád egy napon belül két helyszínre szokott kimenni klímát felszerelni és beüzemelni.
      
       Reggel 9 órakor kezdünk az első címen, mert ekkor már nem zavarja a többi lakót a fúrás és pakolás zaja. A második címre 13-14 óra között érkezünk, s 17-18 óra között várható a befejezés. 
       
       Amennyire megoldható, a lakók életkörülményeihez igazítjuk a munka menetét. Ahol gyereket kell altatni, ott a zajosabb munkálatokat elvégezzük a munka elején, illetve figyelünk az egyéni szabályozásokra a házirend szerint is.     
             
       A szombati klímaszerelést kizárólag családi ház esetében javasoljuk, mivel egy társasházban zavarnánk a munkálatokkal a többi lakó nyugalmát, ami felesleges konfliktusok forrása lehet. `
       
    },
    
    {
        tittle: "A kerttervek: a kerttervezés művei. Melyik kertépítészeti terv mit jelent, mikor készül, mi a célja?",
       description:`Kerttervekről nem csak kert rendeltetésével összefüggésben, a kerttervezési feladatok szerint beszélhetünk, hanem a kerttervezés fázisai alapján a kertépítész különféle tervfajtákat készít:
       Kertépítészeti koncepcióterv (ötletterv, vázlatterv)
       
       Kertépítészeti tanulmányterv
       Kertépítészeti látványtervezés (fotórealisztikus számítógépes 3D-s kertépítészeti látványterv vagy kézi perspektivikus rajz)
      
       Kertépítészeti engedélyezési terv
       \n Kertépítészeti kiviteli terv
       `,
       link:"",
    },
    {
        tittle: "Mire van szükség a betonhoz?",
       description:`A betonhoz cementre, sóderre és vízre van szüksége. A cementet 25 kg-os zsákokban, a sódert ömlesztve vásárolhatja meg, a víz pedig a kerti csapból jöhet a betonozáshoz. A beton megerősítésére pedig érdemes használni a beton jellegétől függően betonvasakat vagy pedig betonhálót. Ezzel az erősítéssel repedésbiztos lesz a rideg beton.
                    A sóder a betonhoz legnagyobb mennyiségben szükséges anyag. Ez folyami homokból és kavicsból áll. Ebből vannak kisebb és nagyobb szemcsés kavicsok is. Az apró szemű folyami homokra csak a felületi munkákhoz van szükség. A betonhoz a nagyobb szemcséjű kavicsra is szükség van. Fontos még az is, hogy ne legyen agyagos a sóder, mert ha ezek az agyagdarabok belekerülnek a kész betonba, akkor ezzel nagymértékben rontják a beton minőségét.
                    Fontos kérdés az is, hogy mennyi sóderre lesz szükség a betonozásnál. Ez attól függ, hogy milyen nagyságú területet szeretne lebetonozni, és milyen vastag betont szeretne készíteni.`,
       link:"",
    },


];

 class AskOffers extends Component  {


  render(){
    
    return (
        
    <div className="row askOffer justify-content-center">
    <div className="row" style={{maxWidth:'1200px'}}>
        <div className="col-lg-4 leftPadding">
            <div className="askTittle">{PromotionalOffers[0].tittle}</div>
            <div className="askDescription">{PromotionalOffers[0].description}</div>
        </div>
        <div className="col-lg-4 centerPadding">
            <div className="askTittle">{PromotionalOffers[1].tittle}</div>
            <div className="askDescription">{PromotionalOffers[1].description}</div>
        </div>
        <div className="col-lg-4 rightPadding">
            <div className="askTittle">{PromotionalOffers[2].tittle}</div>
            <div className="askDescription">{PromotionalOffers[2].description}</div>
        </div>

        <div className="d-flex col-12 justify-content-center pl-0 pr-0">
            <Link to={"/asktheexpert"} ><button>Tovább a kérdésekre</button></Link>
        </div>
        </div>
    </div>


    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default AskOffers;

