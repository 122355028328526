import React, {Component} from 'react';
import {connect} from 'react-redux';

import {request} from '../../../Model/Request';
import {quantities} from "../../../Model/quantities";

import {Container, Modal, Button} from "react-bootstrap";
import Map from "../../../View/Map";
import parse, { htmlToDOM } from 'html-react-parser';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import "react-datepicker/dist/react-datepicker.css";
import defaultImage from "../../../Assets/header_background.png"



/* LAST */


 class SuccesFee extends Component  {


  state = {

    isModalShow:false,
    errorMessage:"",
    successMessage:"",

    companyID:null,
    page:0,
    data:{attributes: {location: null} },
    offer:null,
    contract:null,

    quantities : [],
    text:"",
  
    
    saveData:[],
 
    selected:[],

    start_date: new Date(),
    end_date: new Date(),
 
  }

   componentDidMount(){
    
  

   
    (async ()=>{
      
      await  this.setState({ quantities, data: this.props.location.state.ad, offer: this.props.location.state.offer  })
      
        console.log(this.props.location.state)

        const selectedCompany = this.props.userState.user.company.filter(item => item.selected === true)
        if(selectedCompany.length === 0) return
        const id = selectedCompany[0].id;
      
        this.setState({companyID:id})

        if(typeof this.state.offer !== "undefined" && this.state.offer.id !== null){
          const response = await request({ method:"GET", url:`/offers/confirmed/contract?offer_id=${this.state.offer.id}` })
          if(response.data[0]?.attributes?.pdf ?? null !== null){
              this.setState({contract:response.data[0].attributes.pdf})
          }
        }
        

      })()

   }


  componentDidUpdate(prevProps, prevState){
    if(this.state.page !== prevState.page && this.state.page === 1){

      (async()=>{

      const response = await request({ url:`company/profile/profession?id=${this.state.companyID}`, method:"GET" });
        if(typeof response.error === "undefined"){
            this.setState({saveData:response.data})
        }else{
            this.setState({saveData:[]})
        }
      })()


    }
  }

  removeHTMLTags = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return data.replace(regex, "");
  }
  
  handleChange = (value) => {
    this.setState({ text: value })
  };

  advert_SITE = (window_width) => {
    return(
      <Container style={{backgroundColor:"white", paddingLeft:0, paddingRight:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
            
            
        {/* adv_number */}
        <div style={{...styles.subContainer}}> <div style={{...styles.p}}> { this.state.data?.attributes?.hash_id ?? "" } </div> </div>

        {/* banner image */}
        <div style={{...styles.imgDIVBig, backgroundImage: `url(${this.state.data?.attributes?.basic_image?.src ?? defaultImage})`   }} />

        {/* sub container */}
        <div style={{...styles.subContainer}}>

            {/* text */}
            <div style={{...styles.p, display:"flex", justifyContent:"center", color:"#4F4F4F", fontWeight:"bold", textTransform:"uppercase", fontSize: window_width > 801 ? 80 : window_width / 10 }}> {this.state.data?.attributes?.profession?.name ?? ""}</div>

            {/* divider */}
            <div style={{...styles.divider}} />

            {/* text */}
            <div style={{ display:"flex", padding:20, flexDirection:window_width <= 950 ? "column" :"row"}}>

              <div style={{...styles.infoBox, justifyContent:"center"}}>
                <div style={{...styles.p}}>{this.state.data?.attributes?.deadline?.name ?? ""}</div>
              </div>

              <div style={{ flex:1 }}><div style={{...styles.divider_vertical}}/></div>

              <div style={{...styles.infoBox}}>
                <div style={{...styles.p, color:"#1E466D", fontsize:14, fontWeight:"bold", marginBottom:10}}>KÖLTSÉGHATÁR</div>
                <div style={{...styles.p}}>{this.state.data?.attributes?.payment_limit?.name ?? ""}</div>
              </div>

            </div>

            <div style={{...styles.imageContainer}}>
              {
               typeof this.state.data.attributes.images !== "undefined" && this.state.data.attributes.images !== null && this.state.data.attributes.images.length > 0 
                  ? this.state.data.attributes.images.map( (item, index) =>  <div  key={index} style={{ ...styles.imgDIV, backgroundImage:`url(${this.state.data.attributes.images[index].src})`}}  />   )
                  : null
              } 
            </div>

             <div style={{height:80, width:'100%'}}/>   

            {
              typeof this.state.data.attributes.location !== "undefined" && this.state.data.attributes.location !== null
                ?
                <>

                <div style={{height:40, width:'100%'}}/> 
                <div style={{ ...styles.p, display:"flex", justifyContent:"center"}} >MUNKAVÉGZÉS HELYE</div>
                <div style={{height:20, width:'100%'}}/> 
                <div style={{ ...styles.divider}} /> 
                <div style={{height:40, width:'100%'}}/> 

                <Map
                    center={{ lat: parseFloat(this.state.data.attributes.location.lat) , lng: parseFloat(this.state.data.attributes.location.lng) }} /// lat: 47.497913, lng: 19.040236
                    zoom={11}
                    places={
                      [
                        {
                          id: 0,
                          name: "",
                          zip:"",
                          latitude: this.state.data.attributes.location.lat,
                          longitude: this.state.data.attributes.location.lng,
                          circle : {
                            radius: 0,
                            options: {
                              strokeColor: "#217BA7",
                              fillColor:"#217BA7"
                            }
                          }
                        }
                      ]
                    }
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                    loadingElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height:'300px', width:'100%' }} />}
                />
                </>
                :null
            } 
             


      <div style={{height:100, width:"100%"}}/>
      {/* DESCRIPTION TEXT */}
      <div>

        { parse(this.state.data?.attributes?.description ?? "")}

      </div>


      <div style={{height:200, width:"100%"}}/>

      <div style={{display:"flex", justifyContent:"flex-end"}}>

       {
         /* this.setState({page:1}); window.scrollTo(0,0) } */
       }
       { this.state.contract !== null
        ?
        <div 
          style={{ ...styles.buttonContainer, width: window_width <= 950 ? "100%" : "33%", display:"flex", cursor:"pointer"  }}
          onClick={()=>  window.open(this.state.contract)  }
          >
              <div style={{...styles.p, fontWeight:"bold", color:"white"}} >SZERZŐDÉS MEGTEKINTÉSE</div>
        </div> 
        : null
        }

      </div>

      <div style={{height:200, width:"100%"}}/>

    </div>


      </Container>
    )
  }

  offer_SITE = (window_width) => {
    return(
            <Container style={{backgroundColor:"white", borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>

              <div style={{display:"flex", justifyContent:"center", paddingTop:50}}>
              <div style={{...styles.p}}>HÍRDETÉS</div>
              </div>
               {/* banner image */}
                <div style={{...styles.imgDIVBig_offer, marginTop:20, marginBottom:20}}>


                <div style={{ display:"flex", padding:20, flexDirection:window_width <= 950 ? "column" :"row"}}>

                <div style={{...styles.infoBox}}>
                  <div style={{...styles.p}}>{this.state.data?.attributes?.profession?.name ?? ""}</div>
                  <div style={{...styles.p,  marginTop:5, fontSize:10, }}>{ this.state.data?.attributes?.hash_id ?? "" }</div>
                  <div style={{...styles.p, marginTop:5}}> leírás: </div>
                  <div style={{...styles.p,  marginTop:5, maxHeight:100, overflow:"hidden"}}>{this.removeHTMLTags(this.state.data?.attributes?.description ?? "")}</div>
                </div>

                <div style={{ flex:1 }}><div style={{...styles.divider_vertical}}/></div>

                <div style={{...styles.infoBox}}>
                  <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>HATÁRIDŐ</div>
                  <div style={{...styles.p}}>{this.state.data?.attributes?.deadline?.name ?? ""}</div>
                  <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>KÖLTSÉGHATÁR</div>
                  <div style={{...styles.p}}>{this.state.data?.attributes?.payment_limit?.name ?? ""}</div>
                  <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>MUNKAVÉGZÉS HELYE</div>
                  <div style={{...styles.p}}>{this.state.data?.attributes?.location?.city  ?? ""}</div>
                </div>

                <div style={{...styles.infoBox, backgroundImage:`url(${this.state.data?.attributes?.basic_image?.src ?? defaultImage})`, backgroundSize:"cover", minHeight:200}}>
                  
                </div>

                </div>


                </div>   


                <div style={{display:"flex", justifyContent:"center", paddingTop:50, paddingBottom:25}}>
                    <div style={{...styles.p}}>ELKÉSZÍTEM AZ AJÁNLATOT</div>
                </div>

                {/* divider */}
                <div style={{...styles.divider,  marginBottom:50}} />

                {/* EDITOR */}
                <ReactQuill  
                  placeholder= "Ajánlat helye..." 
                  value={this.state.text} 
                  onChange={(value)=>this.handleChange(value)} 
                  style={{height:300, marginBottom:80}}  />


                <div style={{display:"flex", justifyContent:"center", paddingTop:50, paddingBottom:25}}>
                    <div style={{...styles.p}}>SZOLGÁLTATÁSOK</div>
                </div>

                {/* divider */}
                <div style={{...styles.divider, marginBottom:50}} />

                {/* SZOLGÁLTATÁSOK */}
                <div style={{marginLeft:50, marginRight:50}}>
                <Select 
                    options={
                      typeof this.state.saveData !== "undefined" 

                      ? [  ...this.state.saveData.map( item => {   
                        
                            return {  
                                label: item.name,
                                options: item.specializations.map( item_sub => { return {value:item_sub.id, id:item_sub.id,  label:item_sub.name, price:item_sub.price, unit:item_sub.unit, mainCategory:item.name, mainCategoryId:item.id, } }),
                            } 
                                
                          })
                        ]
                      : []
                    } 
                    onChange={(e)=> {

                      if(this.state.selected.filter( item => item.label === e.label ).length === 0 ){
                        this.setState({ selected: [...this.state.selected, e] })
                      }

                    }} 
                    styles={styles.mainSelectStyle}  
                    placeholder={"VÁLASSZ!"} 
                   
                    noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>} 
                    ref={(ref) => this.firstSelectRef = ref} />
                </div>

                    <div style={{height:50, width:"100%"}}/>


                 {/* SELECTED OPTIONS */}
                 {
                      this.state.selected.map( (item, index)=> 
                      <div  key={index} style={{display:"flex", justifyContent:"space-evenly",  marginTop:40, flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row" }}>
                      {/*   { this.name_unit_price_box(item.label, "NÉV")} */}

                      <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
                            <div style={{ ...styles.p, padding:5, fontWeight:"bold", borderRadius:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center"}} >{item.label}</div> 
                      </div>

                        { this.name_unit_price_box(this.state.quantities[item.unit].label, "MÉRTÉKENYSÉG")}
                        { this.name_unit_price_box(item.price, "ÁR")}
                          {/* <div>
                          <div style={{ ...styles.p }} >{item.label}</div> 
                          </div>
                          <div style={{ ...styles.p }} >{this.state.quantities[item.unit].label} </div> 
                          <div style={{ ...styles.p }} >{item.price} Ft </div>  */}
                          <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
                            <div 
                              style={{ ...styles.p, padding:5, borderRadius:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center", color:"red", cursor:"pointer"}} 
                              onClick={()=> this.setState({ selected: this.state.selected.filter( item_filter => item_filter.label !== item.label )  }) }
                            >{"TÖRLÉS"}</div> 
                          </div>
                      </div>
                      )
                 }
                    

                    <div style={{ ...styles.divider, marginTop:150, marginBottom:20}} />

                    {/* START / END DATE */}
                    <div style={{display:"flex", justifyContent:"space-evenly",  marginTop:30, flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row" }}>
                    
                    {this.date_box("MUNKA KEZDETE", "start_date")}
                    {this.date_box("MUNKA VÉGE", "end_date")}

                    </div>


                    <div style={{height:200, width:"100%"}}/>

                    <div style={{display:"flex", justifyContent:"flex-end"}}>

                        <div 
                          style={{ ...styles.buttonContainer, width: window_width <= 950 ? "100%" : "33%", display:"flex", cursor:"pointer"  }}
                          onClick={()=>  this.sendOffer() } >
                              <div style={{...styles.p, fontWeight:"bold", color:"white"}} >AJÁNLAT KÜLDÉS</div>
                        </div>

                    </div>

                    <div style={{height:200, width:"100%"}}/>
                    

            </Container>
       )
  }


  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`

  sendOffer = async () => {



     /// description text
    /// description text
    if(this.state.text.replace(/<(.|\n)*?>/g, '').trim().length === 0 ){
      this.setState({
        isModalShow:true,
        errorMessage:"Az ajánlathoz leírás készítése kötelező!"
      });
    
      return
    }  

    /// description text
    if(this.state.selected.length === 0 ){
      this.setState({
        isModalShow:true,
        errorMessage:"Adj az ajánlatodhoz legalább 1 szolgáltatást"
      });
      
      return
    } 


    const mainCategoryId = this.state.data?.attributes?.profession?.id ?? null

    if(mainCategoryId === null) return

    const mainData = this.state.selected.filter(  item =>  item.mainCategoryId === mainCategoryId);
    const addedData = this.state.selected.filter( item =>  item.mainCategoryId !== mainCategoryId);

    let formated_added_category = [];

    let new_added_main_category_set = new Set();

        addedData.map( item=> new_added_main_category_set.add(item.mainCategoryId) )

        Array.from(new_added_main_category_set).map( item => {
          formated_added_category = [...formated_added_category, {profession_id: item, description:this.state.text, specializations:[]}]
        });


        addedData.map( item => {
          formated_added_category.map( (f_item, f_index) => {
            if(item.mainCategoryId === f_item.profession_id){
              formated_added_category[f_index].specializations = [...formated_added_category[f_index].specializations, { "id":item.id ?? "", "symbol":this.state?.quantities[item.unit]?.label ?? "", "quantity":1, "price":item.price ?? "" }]
            }
          })
        })


      const send_data = {
            "ad_id":this.state?.data?.id ?? "",
            "main":
            {
              "profession_id":this.state.data?.attributes?.profession?.id ?? "",
              "description": this.state.text,
              "specializations" : mainData.map(item=> { 
                return {
                  "id":item.id ?? "",
                  "symbol" : this.state?.quantities[item.unit]?.label ?? "",
                  "quantity": 1,
                  "price": item.price   
                } 
              }),
            },
            "additional":formated_added_category,
            "from_date":this.dateParser_hyphen(this.state.start_date),
            "until_date":this.dateParser_hyphen(this.state.end_date)
      }


      const response = await request({url:"/offers", method:"POST", data:send_data});


      if(typeof response.error !== "undefined"){
        this.setState({
          isModalShow:true,
          errorMessage:"Hiba lépett fel a szerverrel való kommunikáció során! Kérlek próbád meg később!"
        });
      }else{
        this.setState({
          isModalShow:true,
          successMessage:"SIKERES MENTÉS"
        });
      }


  }

  


  name_unit_price_box = (label, title) => 
    <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
      <div style={{ ...styles.p, alignItems:"center", fontSize:10, display:"flex", paddingRight:10}}> {title} </div>
      <div style={{ ...styles.p, padding:5, border:"1px solid #ccc", borderRadius:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center"}} >{label}</div> 
    </div>
  
  date_box = (title, type) => 
  <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
    <div style={{ ...styles.p, alignItems:"center", fontSize:10, display:"flex", paddingRight:10}}> {title} </div>
    <div style={{ ...styles.p, padding:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center"}} >
    
    
    <DatePicker 
        locale={hu} 
        selected={this.state[type]} 
        dateFormat="yyyy-MM-dd"
        style={{ ...styles.p}}
        onChange={ date => this.setState({ [type]: date }) } />  

    </div> 
  </div>


  render(){
  
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (

      <div style={{ background:"rgb(246, 249, 254)", paddingBottom:50}}>

            <Modal show={this.state.isModalShow} onHide={ ()=> {
              if(this.state.successMessage !== ""){
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                this.props.history.goBack()
              }else{
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
              }
              
              } }>
              <Modal.Header>
                <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{...styles.p}}>{this.state.errorMessage}</div>
                <div style={{...styles.p}}>{this.state.successMessage}</div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant={ this.state.successMessage === "" ? "secondary" : "primary"} onClick={ ()=> 
                  
                  {
                    if(this.state.successMessage !== ""){
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                      this.props.history.goBack()
                    }else{
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                    }
                  }
                  
                  }>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
              <div>{ this.state.page === 0 ? "HÍRDETÉS" : "AJÁNLAT"}</div>
            </div>

           {/*  {  this.offer_SITE(window_width)  } */}
          
           { 
            this.state.page === 0 
              ? this.advert_SITE(window_width) 
              : this.offer_SITE(window_width) 
          }  


    
      </div>
    );

  }
}

const styles = {

  imgDIV : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:310, 
            height:210,
            borderRadius:6,
            marginTop:20,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },
imgDIVBig : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%", 
            height:410,
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },

imgDIVBig_offer : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%",
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },          

imgCover : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%",
            height:600,
},  

firstContainer : {
            position:"absolute", 
            left:0, 
            right:0, 
            height:200, 
            backgroundColor:"rgba(255, 255, 255, 0.9)", 
            marginTop:400, 
            display:"flex", 
            justifyContent:'center'
},

secoundContainer : { 
            height:100, 
            width:"100%",
            background: "linear-gradient(180deg, #0F3A83 0%, #0C78B5 100%)",
            ransform: "rotate(180deg)",
            marginBottom:30
},

subContainer : {
             padding:30
},

infoBox : {
            flex:10, 
            display:"flex", 
            alignItems:"center", 
            flexDirection:"column", 
            padding:10
},

p : { 
            fontFamily:"Maven Pro", 
            letterSpacing:2
},

divider : {
            width:"100%", 
            height:1, 
            backgroundColor:"#4F4F4F"
},

divider_vertical : {
            width:1, 
            height:"100%", 
            backgroundColor:"#4F4F4F"
},

imageContainer : {
            marginTop:50,
            display:"flex", 
            justifyContent:"space-evenly", 
            flexWrap:"wrap"
},
headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},

buttonContainer: {
  paddingTop:20, paddingBottom:20, paddingRight:40, paddingLeft:40, background:"#217BA7", borderRadius:5, justifyContent:'center', marginTop:30,
},

mainSelectStyle : {
  container: provided => ({
    ...provided,
    border:'1px black solid',
    borderRadius:4,
    width: "100%",
    marginLeft:5, 
    marginRight:10,
   fontFamily:"Maven Pro",
   fontSize:16, 
   fontWeight:"bold",
   color:'#4F4F4F'
  }),
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px solid #4F4F4F',
    backgroundColor: state.isSelected ? "#1C466F":"white",
    color: state.isSelected ? 'white' : '#4F4F4F',
    width: '100%',
    fontFamily:"Maven Pro"
    
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    fontFamily:"Maven Pro"
  })
},

}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(SuccesFee);


/* 
{
      "type": "Ads",
      "id": 1,
      "attributes": {
        "hash_id": "179xM",
        "status": "active",
        "profession": {
          "id": 1,
          "name": "Ablakos",
          "type": "indoor"
        },
        "deadline": {
          "id": 1,
          "name": "A lehető leghamarabb"
        },
        "payment_limit": {
          "id": 4,
          "name": "Nincs összeghatár"
        },
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sddd, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "basic_image": "https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg",
        "images": [
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
        ],
        "location": {
          "id": 2,
          "type": "area",
          "lat": 47.5011,
          "lng": 19.0469,
          "distance": 60,
          "zip_code": 1011,
          "city": "Budapest i. kerület",
          "formatted_address": "1221 Budapest, Tompa utca 8."
        } 
      },
      "relation": {
        "type": "User",
        "id": 2
      }
    }

*/