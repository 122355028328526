import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
/// redux actions
import {setTokenAction, setUserAction} from "../../Model/Action";
/* ROUTER */
import { NavLink, withRouter, Link} from "react-router-dom";
//Animation library
import {Spring, animated} from 'react-spring/renderprops';
/// AXIOS
import {request, source} from "../../Model/Request";
/// SELECT
import Select from 'react-select';
//styles
import "./Header.css"
//images
import shortmenu from "../../Assets/shortmenu.svg";
import bell from "../../Assets/bell.png";
import message from "../../Assets/message.png";
import polygon from "../../Assets/polygon.png"

import logo from "../../Assets/logo.svg";
import defaultIMG from "./Assets/default.jpg"



 class Header extends Component  {

  state = {
    isMenuOppened: false,
    /* search */
    servicesList:null,
    mainCategorySelected:null,

    selectedSubCategoryList:null,
    selectedSubCategory:null,


    selectedSettlement:"",


  }

  componentDidMount(){
    //this.props.setUserAction({});
    //this.props.setTokenAction({});

   
    this.mainTypeRequest()
    window.addEventListener("click", this.clickEventListenerHandler, false);

    this.interval = setInterval(
      async ()=>{
        try{

          if(this.isUserLoggedIN()){
           
            /// const response = await request({ url:"/notifications" });
            /// response
  
          }

        }catch(e){
          console.warn(e)
        }
        
      }, 
      10000);
 
  }

  componentDidUpdate(prevProps, prevState){
    
   if(this.props.location.pathname !== prevProps.location.pathname && this.props.location.pathname === "/"){
    this.setState({
      servicesList:null,
      mainCategorySelected:null,
      selectedSubCategoryList:null,
      selectedSubCategory:null,
      selectedSettlement:"",
    })
    this.mainTypeRequest()
   }

    if (prevProps.systemState.dimension.width !== this.props.systemState.dimension.width && this.state.isMenuOppened && this.props.systemState.dimension.width >= 922 ){
      this.menuStateHandler()
    }



    if(this.state.mainCategorySelected !==  prevState.mainCategorySelected){
      this.setState({selectedSubCategory:null})
      this.mainCategoryRequest()
    }



  }





  componentWillUnmount() {
    /// remove listener
    window.removeEventListener("click", this.clickEventListenerHandler, false);
    clearInterval(this.interval);
  }

  clickEventListenerHandler = (e) => {

    if( (e.target.className && e.target.className !== "menu" && this.state.isMenuOppened === true) || e.target.classList.length === 0 ){ 
      this.setState({isMenuOppened: false})
    }
  }

  /// menu state handler (toggle true/false)
  menuStateHandler = () =>  this.setState({isMenuOppened: !this.state.isMenuOppened})
    /// validation Timeout for search
  validationTimeout;

  /// Detect if user logged in or not
  isUserLoggedIN = () => {
    const {user} = this.props.userState
    return Object.entries(user).length === 0 && user.constructor === Object ? false : true
  }

  searchButtonFunction = () => {

    let searchString = "per_page=10&page=1";

    if(this.state.mainCategorySelected !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `mainid=${this.state.mainCategorySelected.value}&mainname=${encodeURI(this.state.mainCategorySelected.label)}`
    }

    if(this.state.selectedSubCategory !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `subid=${this.state.selectedSubCategory.value}&subname=${encodeURI(this.state.selectedSubCategory.label)}`
    }

    if(this.state.selectedSettlement !== ""){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `settlement=${encodeURI(this.state.selectedSettlement)}`
    }

    

   this.props.history.push(`/search${ "?" + searchString }`)

  }


  withdraw_registration = async () => {
    try{
      const a = await request({url:"/auth/register/cancellation", method:"POST"})
      console.log(a)
      await this.props.setUserAction({});
      await this.props.setTokenAction({});
      await this.props.history.push("/");
    }catch(e){
      console.log(e)
    }
    
  }

  /// log out method
  logOutBTN = async () => {
    try{
     
     await this.props.history.push("/");
     await request({url:"/logout", method:"POST"})
     await this.props.setUserAction({});
     await this.props.setTokenAction({});
     await source()

          
         /// emty user data
         
         /// emty navigate to index page
      
         /// logout request
         




    }catch(e){
      /// error handler

    }

  }

  mainTypeRequest = async () => {
    try{
      const response = await request({url:`/profession?per_page=1000&current_page=1`, method:"GET",  });
      this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
    }catch(e){
      /// error handler

    }
   }

   mainCategoryRequest = async () => {
     try{
       
       const response = await request({url:`/profession/specialization?profession_id=${this.state.mainCategorySelected.value}`, method:"GET",  });
      this.setState({selectedSubCategoryList:response.data.map(item=>{ return {value: item.id,     label: item.title }  })})
    }catch(e){
      /// error handler

    }
   }

   setInput = (e) => {


    let {value} = e.target;
    this.setState({selectedSettlement: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() })

/*     clearTimeout(this.validationTimeout);



    this.setState({selectedSettlement: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() })



      this.validationTimeout = setTimeout(()=>{


        const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())


        if( settlement.length === 0 ){
          alert(`A következő település nem található: ${value} `);
          this.setState({selectedSettlement: "" })
        }

    },1500)
 */


   }


  /// DESKTOP MODULE
  headerComponent = () =>(

      <div>

      { this.props.location.pathname === "/" ?

      <div className="d-flex w-100 bgimage position-absolute" >
          <div className="elasticImage"></div>
          <div className="fixImageRight"></div>
      </div>

      : null }

        <header className="header row justify-content-center" style={this.props.location.pathname === "/" ? {background: 'rgba(79, 79, 79, 0.4)'} :{background: "linear-gradient(180deg, #1D456D 0%, #0C78B5 100%)",borderBottom:0}}>
            <div className="d-flex text-white align-items-center w-100 headerMaxSize">
              
               
                { this.props.location.pathname !== "/registration/professional"
                  ?
                  <>
                   <NavLink to='/'><div className="menuLogo"><img src={logo}/></div></NavLink>

                    <div className="menuContainer d-flex align-items-center w-100">
                    <div className="dinamicSpacer"></div>

                    <NavLink to='/search'><div className="menulink menufont">Keress szakembert</div></NavLink>
                    <NavLink to='/advertoffer'><div className="spacerBetweenSecondAndFirstMenuPoint"></div></NavLink>
                    

                    {
                       !this.isUserLoggedIN() || ( this.isUserLoggedIN() && this.props.userState.user.attributes.type === "basic") 
                       ?
                       <Link   to={{pathname: this.isUserLoggedIN() ? "/users/ads/new" : "/registration/user", state: {  type: "basic", from:"header_footer_button" } }}>
                       <div className="menulink menufont">Adj fel hirdetést</div>
                     </Link>
                     : null
                    }
                   

                    <div className="spacerBetweenSecondAndThirstMenuPoint"></div>
                    {
                      this.isUserLoggedIN()
                      ? <div style={{display:"flex",flex:1, justifyContent:"space-evenly", alignItems:"center"}}>
                          {/*
                          <div style={{flex:1}}><img src={message} style={{height:25}} /></div>
                          <div style={{flex:1}}><img src={bell} style={{height:25}} /></div>
                          */}

                        {/* this.props.userState.user.attributes.avatar.src   style={{ background:`url(${defaultIMG})`,*/}

                          <div style={{flex:1}}><div style={{
                            ...styles.imageStyle, 
                            background: `url(${(
                                typeof this.props.userState.user.attributes.avatar === "undefined" || 
                                this.props.userState.user.attributes.avatar === null) ? 
                              defaultIMG : 
                              this.props.userState.user.attributes.avatar.src}) center center / cover no-repeat white`,  
                              height:70, 
                              width:70
                            }} /> </div>
                          <div className="menu" style={{flex:3, marginBottom:5, marginLeft:5, cursor:"pointer"}} onClick={()=> this.menuStateHandler()}>{this.props.userState.user.attributes.name.toUpperCase()}<img className="menu" src={polygon} style={{height:10, marginLeft:10}} /></div>

                        </div>
                      : <>
                          <NavLink to='/registration'><div className="menulink menufont">Regisztráció</div></NavLink>
                          <NavLink to='/login'><div className="menufont"><button className="loginButton">Belépés</button></div></NavLink>
                        </>
                       
                    }


                </div>
                <div className="dinamicSpacer"></div>
                <div className="menuShort" onClick={()=> this.menuStateHandler()}><img className="menu" src={shortmenu}/></div>
                  </>
                   : 
                    <div style={{width:'100%', height:'100%', display:"flex", alignItems:"center", justifyContent:"flex-end", marginRight:30}}>
                    <div className="menufont" style={{cursor:"pointer"}} onClick={()=>this.withdraw_registration()}>Regisztráció MEGSZAKÍTÁSA</div>
                    </div>
                }
           </div>
        </header>

        {/* menü */}
       
        {
          this.props.systemState.dimension.width <= 922 || !this.isUserLoggedIN()
          ? null
          : <Spring from = {{opacity:0, transform:"scaleX(2) scaleY(0)"}} to = {{ opacity:this.state.isMenuOppened ? 1:0, transform: this.state.isMenuOppened?  "scaleX(1) scaleY(1)" :  "scaleX(2) scaleY(0)", }} >
          {
              ({...animData}) =>
              <animated.div style={{display: animData.opacity > 0 ? "block":"none" ,position:"absolute", zIndex:300, top:90, right: `${this.props.systemState.dimension.width <= 1000 ? 10 : this.props.systemState.dimension.width / 100}%` , width:250, ...animData}}>
                  <div className="menu" style={{display:"flex", alignItems:"flex-start", flexDirection:"column", backgroundColor:"white",  borderRadius:5, padding:15}}>

                      <div className="menu" style={{display:"flex", flexDirection:"row"}}>
                        {/* background:`url(${this.props.userState.user.attributes.avatar.src})`, */}
                          <div className="menu" style={{ flex:1 }}>
                            <div className="menu avatar" style={{background: `url(${(typeof this.props.userState.user.attributes.avatar === "undefined" || this.props.userState.user.attributes.avatar === null) ? defaultIMG : this.props.userState.user.attributes.avatar.src}) center center / cover no-repeat white`, height:45, width:45, ...styles.imageStyle}} /></div>
                          <div className="menu" style={{ flex:3 }}>
                            <div className="menu" style={{ ...styles.fontStyle, fontWeight:"bold", marginTop:10, marginLeft:15}}>VEZÉRLŐPULT</div>
                          </div>
                      </div>

                      <div className="menu" style={{ height:1, width:"100%", marginTop:10, marginBottom:10, backgroundColor:"black" }}/>
                    
                      <div className="" style={{ ...styles.fontStyle, cursor:"pointer"}} onClick={() =>  this.props.history.push(`${this.props.userState.user.attributes.type==="basic" ? "/users/profiles"  :"/experts/profiles"}`) }  >PROFIL</div>
                      
                      <div className="" style={{ ...styles.fontStyle, cursor:"pointer"}} onClick={() =>  this.props.history.push("/faq")} >SÚGÓ KÖZPONT</div>
                      
                      <div className="" style={{ ...styles.fontStyle, cursor:"pointer"}} onClick={()=>this.logOutBTN()}>KIJELENTKEZÉS</div>
                      
                  </div>
              </animated.div>
            }
          </Spring>
        }
        {
          this.props.systemState.dimension.width >= 921
          ? null
          :  <Spring
          from={{ ...styles.containerClosed }}
          to=  { this.state.isMenuOppened ? styles.containerOpened : styles.containerClosed  }
        >
          {
            ({...animData}) =>
                <animated.div className="menu" style={{...animData}}>

                    <Spring  className="menu"
                    from={{...styles.secoundContainerClosed}}
                    to= {this.state.isMenuOppened ? styles.secoundContainerOpened : styles.secoundContainerClosed }
                    //config={{delay:500}}
                    >
                      {
                        ({...containerAnimData}) =>
                        <animated.div className="menu" style={{...containerAnimData, minWidth:300}}>
                            <div className="menu" style={{display:"flex", alignItems:"center", flexDirection:"column"}}>


                                <NavLink to='/search'><div className="menu" onClick={()=>{ this.setState({isMenuOppened:false}) } } style={{...styles.fontStyle, color:containerAnimData.color2, marginTop:25}}>KERESS SZAKEMBERT</div></NavLink>

                                
                                {
                                  !this.isUserLoggedIN() || ( this.isUserLoggedIN() && this.props.userState.user.type === "basic") 
                                  ?
                                    <Link   to={{pathname: this.isUserLoggedIN() ? "/users/ads/new" : "/registration/user", state: {  type: "basic", from:"header_footer_button" } }}>
                                        <div className="menu" onClick={()=>{ this.setState({isMenuOppened:false}) } }  style={{...styles.fontStyle, color:containerAnimData.color2, marginBottom:this.isUserLoggedIN() ? 25 : 0, marginTop:25  }}>ADJ FEL HÍRDETÉST</div>
                                    </Link>
                                  : null
                                }
                               

                                {
                                  this.isUserLoggedIN()
                                  ? null
                                  :<>
                                    <NavLink to='/registration'><div className="menu" onClick={()=>{ this.setState({isMenuOppened:false}) } }  style={{...containerAnimData, ...styles.fontStyle,  marginTop:25}}>REGISZTRÁCIÓ</div></NavLink>
                                    <NavLink to='/login'><div className="menu" onClick={()=>{ this.setState({isMenuOppened:false}) } }  style={{...containerAnimData, ...styles.fontStyle, marginTop:25}}>BELÉPÉS</div></NavLink>
                                   </>
                                }

                            </div>
                          {
                              this.isUserLoggedIN()
                              ?
                              <div className="menu" style={{display:"flex", alignItems:"center", flexDirection:"column", backgroundColor:containerAnimData.color2,  borderRadius:5, padding:15}}>
                                <div className="menu" style={{ background:`url(${this.props.userState.user.attributes.avatar === null ? defaultIMG : this.props.userState.user.attributes.avatar.src}) center center / cover no-repeat white`, height:containerAnimData.value3,  width:containerAnimData.value3,  ...styles.imageStyle}} />
                                <div className="menu" style={{...containerAnimData, ...styles.fontStyle, fontWeight:"bold"}}>VEZÉRLŐPULT</div>
                                <div className="menu" style={{...containerAnimData, height:1, width:"100%", marginTop:10, marginBottom:10, backgroundColor:containerAnimData.color}}/>
               
                                
                                  <div className="" style={{ ...containerAnimData, ...styles.fontStyle, cursor:"pointer"}} onClick={() =>  this.props.history.push(`${this.props.userState.user.attributes.type==="basic" ? "/users/profiles"  :"/experts/profiles"}`) } >PROFIL</div>
                               
                                  <div className="" style={{...containerAnimData, ...styles.fontStyle, cursor:"pointer"}} onClick={() =>  this.props.history.push("/faq")} >SÚGÓ KÖZPONT</div>
                                
                                 <div className="" style={{...containerAnimData, ...styles.fontStyle, cursor:"pointer"}} onClick={()=>this.logOutBTN()}>KIJELENTKEZÉS</div>
                             </div>
                              : null
                            }

                        </animated.div>
                      }
                    </Spring>

                </animated.div>
          }
        </Spring>

        }
        





        {/* menü end */}

        { this.props.location.pathname === "/" ?

        <div className="d-flex justify-content-center" >
        <div className="row searchboxes" style={{zIndex:99}}>

            <div className="col-xl-6 d-flex justify-content-center align-self-center searchAligner">
                <div>
                <div className="searchBigTittle">Keress</div>
                <div className="searchMediumTittle">mestereket</div>
                <div className="searchLittleTittle">Használd a szűrőt</div>
                <div className="searchLittleTittle">a gyorsabb </div>
                <div className="searchLittleTittle">kereséshez</div>
                </div>
            </div>
            <div className="col-xl-6 d-flex justify-content-center align-self-center searchRightAligner">
                <div>
                    <div className="searchInputFirstPadder">

                    <Select
                        options={this.state.servicesList}
                        onChange={(e)=>this.setState({mainCategorySelected:e})}
                        styles={styles.selectStyle}
                        placeholder={"SZOLGÁLTATÁS!"}
                        noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                        isDisabled={this.state.servicesList === null ? true:false}  />
                        {/*  <input className="searchInput" type="text" id="szolg" name="szolg" placeholder="SZOLGÁLTATÁS"/> */}

                    </div>
                    <div className="searchInputSecondPadder">

                    <Select
                        isClearable={true}
                        options={this.state.selectedSubCategoryList}
                        value={this.state.selectedSubCategory === null ? null : this.state.selectedSubCategory}
                        onChange={(e)=>this.setState({selectedSubCategory:e})}
                        styles={styles.selectStyle}
                        placeholder={"SZOLGÁLTATÁS ALKATEGÓRIA!"}
                        noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                        isDisabled={this.state.selectedSubCategoryList === null ? true:false}  />
                        {/*  <input className="searchInput" type="text" id="szolg" name="szolg" placeholder="SZOLGÁLTATÁS ALKATEGÓRIA"/> */}

                    </div>
                    <div className="searchInputThirdPadder">

                      <input className="searchInput" type="text" id="szolg" name="szolg" placeholder="VÁROS MEGADÁSA" value={this.state.selectedSettlement} onChange={(e)=> this.setInput(e)} />
                    </div>
                    <div><button className="searchButton" onClick={()=> this.searchButtonFunction() }>Keresés</button></div>
                </div>
            </div>

        </div>
        </div>

        : null}
    </div>
  )




  render(){

//console.log("HEH",this.props)
//console.log("HEH",this.state)


    return (


        <div>
        {this.headerComponent()}
         </div>


    );

  }
}


const styles = {
  containerClosed : {display:"flex", position:"absolute", height:0,   background:"linear-gradient(   0deg, #1D456D 0%, #0C78B5 100%)",  width:'100%', zIndex:100,},
  containerOpened: {display:"flex", justifyContent:"center", alignItems:"center", position:"absolute", height:400, background:'linear-gradient( 180deg, #1D456D 0%, #0C78B5 100%)',  width:'100%', zIndex:100,},
  secoundContainerClosed: {color:"rgba(0,0,0,0)", color2:"rgba(0,0,0,0)", value3:0, transform:"scale(0)"},
  secoundContainerOpened: {color:"rgba(0,0,0,1)", color2:"rgba(255,255,255,1)", value3:45, transform:"scale(1)"},
  fontStyle: {marginTop:5, fontFamily:"MONDA", fontSize:12},

  imageStyle : {backgroundSize:"cover",
                objectFitt:"cover",
                backgroundRepeat:"no-repeat",
                backgroundColor:"white",
                backgroundPosition:"center",

                borderRadius:45, marginBottom:10,

              },
  selectStyle : {
                container: provided => ({
                  ...provided,
                  borderRadius:3,
                  width: "100%",
                  fontFamily:"Maven Pro",
                  fontSize:16,
                  fontWeight:"bold",
                  color:'#4F4F4F',
                }),

                option: (provided, state) => ({
                  ...provided,
                 // borderBottom: '1px solid #4F4F4F',
                  backgroundColor: state.isSelected ? "#1C466F":"white",
                  color: state.isSelected ? 'white' : '#4F4F4F',
                  width: '100%',
                  fontFamily:"Maven Pro"

                }),

                control: base => ({
                  ...base,
                  border: 0,
                  borderRadius:3,
                  boxShadow: 'none',
                  fontFamily:"Maven Pro",
                  height:50,
                  letterSpacing: 3
                })
    },
}





/*======================
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState};
};

/*===================================================
 * Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
 setTokenAction,
 setUserAction,
})(Header));
