import { combineReducers } from 'redux';
import SystemReducer from './SystemReducer';
import UserReducer from './UserReducer';
import TokenReducer from './TokenReducer';



export default combineReducers({
    systemState: SystemReducer,
    userState: UserReducer,
    tokenState: TokenReducer
});