import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";
import Sidebar from "../SideBar/SideBar";

import WorkCalendar from "./WorkCalendar";
import ToolsCalendar from "./ToolsCalendar";




 class Calendar extends Component  {

  state= {
    calendarType:0
  }

  

  


  render(){
    return (
      <Container style={{backgroundColor:"white", paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:this.props.systemState.dimension.width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        <div style={{display:"flex"}}>
          <div style={{flex:2}}><Sidebar/></div>
          <div style={{flex:12, paddingTop:50, paddingBottom:50}}>

          <div style={{margin:20}}>
            <div style={{width:'100%', height:1, backgroundColor:"#4F4F4F", marginBottom:20, marginTop:20}} />

              <div style={{display:"flex", justifyContent:"space-evenly"}}>

                <div style={{background:"#0C78B5",borderRadius:5, padding:5, cursor:"pointer" }} onClick={()=> this.setState({calendarType:0})}>
                    <div style={{background:"#0C78B5", border:`1px ${this.state.calendarType === 0 ? "white":"#0C78B5"} solid`, borderRadius:5,paddingTop:15,paddingBottom:15, paddingLeft:30, paddingRight:30, display:"flex", flexDirection:"column", alignItems:"center", height:'100%', justifyContent:"center", ursor:"pointer"}}>
                      <div style={{color:'white',fontFamily: "Maven Pro", fontSize:12, cursor:"pointer"}}>MUNKA</div>
                      <div style={{color:'white',fontFamily: "Maven Pro", fontSize:12, cursor:"pointer"}}>NAPTÁRAM</div>
                    </div>
                </div>

                <div style={{background:"#0C78B5",borderRadius:5, padding:5, cursor:"pointer"}} onClick={()=> this.setState({calendarType:1})}>
                    <div style={{background:"#0C78B5", border:`1px ${this.state.calendarType === 1 ? "white":"#0C78B5"} solid`, borderRadius:5,paddingTop:15,paddingBottom:15, paddingLeft:30, paddingRight:30, display:"flex", flexDirection:"column", alignItems:"center", ursor:"pointer"}}>
                      <div style={{color:'white',fontFamily: "Maven Pro", fontSize:12, cursor:"pointer"}}>SZERSZÁM</div>
                      <div style={{color:'white',fontFamily: "Maven Pro", fontSize:12, cursor:"pointer"}}>BÉRBEADÁS</div>
                      <div style={{color:'white',fontFamily: "Maven Pro", fontSize:12, cursor:"pointer"}}>NAPTÁR</div>
                    </div>
                </div>


              </div>
            <div style={{width:'100%', height:1, backgroundColor:"#4F4F4F", marginBottom:20, marginTop:20}} />
          </div>

           {this.state.calendarType === 0 ? <WorkCalendar/> : <ToolsCalendar/>}

      
          </div>
        </div>
      </Container>
    );

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Calendar);

