var DATA = {
    "orszagok": [
        {
            "name": "Afganisztán",
            "Hosszú név": "Afgán Iszlám Köztársaság (hivatalos), Afganisztáni Iszlám Köztársaság (elfogadott)",
            "Kód": "AF",
            "Főváros": "Kabul",
            "Melléknévi alak": "afgán, afganisztáni, afgán iszlám köztársasági, afganisztáni iszlám köztársasági",
            "Pénznem": "afgáni",
            "ISO": "AFN",
            "Pénznem alegység": "puli",
            "Angol név": "Afghanistan"
        },
        {
            "name": "Aland-szigetek ",
            "Hosszú név": "Åland-szigetek",
            "Kód": "AX",
            "Főváros": "Mariehamn",
            "Melléknévi alak": "ålandi, Åland-szigeteki",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Åland"
        },
        {
            "name": "Albánia",
            "Hosszú név": "Albán Köztársaság",
            "Kód": "AL",
            "Főváros": "Tirana",
            "Melléknévi alak": "albán, albániai, albán köztársasági",
            "Pénznem": "lek",
            "ISO": "ALL",
            "Pénznem alegység": "qindar",
            "Angol név": "Albania"
        },
        {
            "name": "Algéria",
            "Hosszú név": "Algériai Demokratikus és Népi Köztársaság",
            "Kód": "DZ",
            "Főváros": "Algír",
            "Melléknévi alak": "algériai, algériai demokratikus és népi köztársasági",
            "Pénznem": "algériai dinár",
            "ISO": "DZD",
            "Pénznem alegység": "centime",
            "Angol név": "Algeria"
        },
        {
            "name": "Amerikai Szamoa ",
            "Hosszú név": "Amerikai Szamoa",
            "Kód": "AS",
            "Főváros": "Pago Pago",
            "Melléknévi alak": "szamoai, amerikai szamoai",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "American Samoa"
        },
        {
            "name": "Amerikai Virgin-szigetek ",
            "Hosszú név": "Amerikai Virgin-szigetek",
            "Kód": "VI",
            "Főváros": "Charlotte Amalie",
            "Melléknévi alak": "amerikai Virgin-szigeteki",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Virgin Islands, U.s."
        },
        {
            "name": "Andorra",
            "Hosszú név": "Andorrai Hercegség (hivatalos), Andorrai Fejedelemség (elfogadott)",
            "Kód": "AD",
            "Főváros": "Andorra la Vella",
            "Melléknévi alak": "andorrai, andorrai hercegségi, andorrai fejedelemségi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Andorra"
        },
        {
            "name": "Angola",
            "Hosszú név": "Angolai Köztársaság",
            "Kód": "AO",
            "Főváros": "Luanda",
            "Melléknévi alak": "angolai, angolai köztársasági",
            "Pénznem": "kwanza",
            "ISO": "AOA",
            "Pénznem alegység": "cêntimo",
            "Angol név": "Angola"
        },
        {
            "name": "Anguilla ",
            "Hosszú név": "Anguilla",
            "Kód": "AI",
            "Főváros": "The Valley",
            "Melléknévi alak": "anguillai",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Anguilla"
        },
        {
            "name": "Antarktisz",
            "Hosszú név": "Antarktisz",
            "Kód": "AQ",
            "Melléknévi alak": "antarktiszi",
            "Angol név": "Antarctica"
        },
        {
            "name": "Antigua és Barbuda",
            "Hosszú név": "Antigua és Barbuda",
            "Kód": "AG",
            "Főváros": "Saint John’s",
            "Melléknévi alak": "Antigua és Barbuda-i",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Antigua and Barbuda"
        },
        {
            "name": "Apostoli Szentszék",
            "Hosszú név": "Apostoli Szentszék (Vatikánvárosi Állam)",
            "Kód": "VA",
            "Főváros": "Vatikánváros",
            "Melléknévi alak": "apostoli szentszéki, vatikáni, vatikánvárosi, vatikánvárosi állami",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Holy See (Vatican City State)"
        },
        {
            "name": "Argentína",
            "Hosszú név": "Argentin Köztársaság",
            "Kód": "AR",
            "Főváros": "Buenos Aires",
            "Melléknévi alak": "argentin, argentínai, argentin köztársasági",
            "Pénznem": "argentin peso",
            "ISO": "ARS",
            "Pénznem alegység": "centavo",
            "Angol név": "Argentina"
        },
        {
            "name": "Aruba ",
            "Hosszú név": "Aruba",
            "Kód": "AW",
            "Főváros": "Oranjestad",
            "Melléknévi alak": "arubai",
            "Pénznem": "arubai gulden (arubai forint)",
            "ISO": "AWG",
            "Pénznem alegység": "cent",
            "Angol név": "Aruba"
        },
        {
            "name": "Ausztrália",
            "Hosszú név": "Ausztrál Államszövetség",
            "Kód": "AU",
            "Főváros": "Canberra",
            "Melléknévi alak": "ausztrál, ausztráliai, ausztrál államszövetségi",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Australia"
        },
        {
            "name": "Ausztria",
            "Hosszú név": "Osztrák Köztársaság",
            "Kód": "AT",
            "Főváros": "Bécs",
            "Melléknévi alak": "osztrák, ausztriai, osztrák köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Austria"
        },
        {
            "name": "Az Amerikai Egyesült Államok Külső Szigetei",
            "Hosszú név": "Az Amerikai Egyesült Államok Külső Szigetei",
            "Kód": "UM",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "United States Minor Outlying Islands"
        },
        {
            "name": "Azerbajdzsán",
            "Hosszú név": "Azerbajdzsán Köztársaság (hivatalos), Azeri Köztársaság (elfogadott)",
            "Kód": "AZ",
            "Főváros": "Baku",
            "Melléknévi alak": "azeri, azerbajdzsáni, Azerbajdzsán köztársasági, azeri köztársasági",
            "Pénznem": "azerbajdzsáni manát",
            "ISO": "AZN",
            "Pénznem alegység": "kopek",
            "Angol név": "Azerbaijan"
        },
        {
            "name": "Bahama-szigetek",
            "Hosszú név": "Bahamai Közösség",
            "Kód": "BS",
            "Főváros": "Nassau",
            "Melléknévi alak": "bahamai, Bahama-szigeteki, bahamai közösségi",
            "Pénznem": "bahamai dollár",
            "ISO": "BSD",
            "Pénznem alegység": "cent",
            "Angol név": "Bahamas"
        },
        {
            "name": "Bahrein",
            "Hosszú név": "Bahreini Királyság",
            "Kód": "BH",
            "Főváros": "Manáma",
            "Melléknévi alak": "bahreini, bahreini királysági",
            "Pénznem": "bahreini dinár",
            "ISO": "BHD",
            "Pénznem alegység": "fil",
            "Angol név": "Bahrain"
        },
        {
            "name": "Banglades",
            "Hosszú név": "Bangladesi Népi Köztársaság",
            "Kód": "BD",
            "Főváros": "Dakka",
            "Melléknévi alak": "bangladesi, bangladesi népi köztársasági",
            "Pénznem": "taka",
            "ISO": "BDT",
            "Pénznem alegység": "paisa",
            "Angol név": "Bangladesh"
        },
        {
            "name": "Barbados",
            "Hosszú név": "Barbados",
            "Kód": "BB",
            "Főváros": "Bridgetown",
            "Melléknévi alak": "barbadosi",
            "Pénznem": "barbadosi dollár",
            "ISO": "BBD",
            "Pénznem alegység": "cent",
            "Angol név": "Barbados"
        },
        {
            "name": "Belarusz / Fehéroroszország",
            "Hosszú név": "Belarusz Köztársaság / Fehérorosz Köztársaság",
            "Kód": "BY",
            "Főváros": "Minszk",
            "Melléknévi alak": "belorusz, fehérorosz, fehéroroszországi, belarusz köztársasági, fehérorosz köztársasági",
            "Pénznem": "belorusz rubel",
            "ISO": "BYR",
            "Pénznem alegység": "kopejka",
            "Angol név": "Belarus"
        },
        {
            "name": "Belgium",
            "Hosszú név": "Belga Királyság",
            "Kód": "BE",
            "Főváros": "Brüsszel",
            "Melléknévi alak": "belga, belgiumi, belga királysági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Belgium"
        },
        {
            "name": "Belize",
            "Hosszú név": "Belize",
            "Kód": "BZ",
            "Főváros": "Belmopan",
            "Melléknévi alak": "belize-i",
            "Pénznem": "belize-i dollár",
            "ISO": "BZD",
            "Pénznem alegység": "cent",
            "Angol név": "Belize"
        },
        {
            "name": "Benin",
            "Hosszú név": "Benini Köztársaság",
            "Kód": "BJ",
            "Főváros": "Porto Novo",
            "Melléknévi alak": "benini, benini köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Benin"
        },
        {
            "name": "Bermuda ",
            "Hosszú név": "Bermuda",
            "Kód": "BM",
            "Főváros": "Hamilton",
            "Melléknévi alak": "bermudai",
            "Pénznem": "bermudai dollár",
            "ISO": "BMD",
            "Pénznem alegység": "cent",
            "Angol név": "Bermuda"
        },
        {
            "name": "Bhután",
            "Hosszú név": "Bhutáni Királyság",
            "Kód": "BT",
            "Főváros": "Timpu",
            "Melléknévi alak": "bhutáni, bhutáni királysági",
            "Pénznem": "ngultrum",
            "ISO": "BTN",
            "Pénznem alegység": "chetrum",
            "Angol név": "Bhutan"
        },
        {
            "name": "Bissau-Guinea",
            "Hosszú név": "Bissau-guineai Köztársaság",
            "Kód": "GW",
            "Főváros": "Bissau",
            "Melléknévi alak": "Bissau-guineai, Bissau-guineai köztársasági",
            "Pénznem": "CFA-frank (BCEAO)",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Guinea-Bissau"
        },
        {
            "name": "Bolívia",
            "Hosszú név": "Többnemzetiségű Bolíviai Állam",
            "Kód": "BO",
            "Főváros": "Sucre",
            "Melléknévi alak": "bolíviai, bolíviai köztársasági",
            "Pénznem": "boliviano",
            "ISO": "BOB",
            "Pénznem alegység": "centavo",
            "Angol név": "Bolivia"
        },
        {
            "name": "Bosznia-Hercegovina",
            "Hosszú név": "Bosznia-hercegovinai Köztársaság (hivatalos), Bosznia és Hercegovina Köztársaság (elfogadott)",
            "Kód": "BA",
            "Főváros": "Szarajevó",
            "Melléknévi alak": "bosnyák, boszniai, bosznia-hercegovinai, Bosznia és Hercegovina-i, bosznia-hercegovinai köztársasági, Bosznia és Hercegovina köztársasági",
            "Pénznem": "konvertibilis márka",
            "ISO": "BAM",
            "Pénznem alegység": "pfennig",
            "Angol név": "Bosnia and Herzegovina"
        },
        {
            "name": "Botswana",
            "Hosszú név": "Botswanai Köztársaság",
            "Kód": "BW",
            "Főváros": "Gaborone",
            "Melléknévi alak": "botswanai, botswanai köztársasági",
            "Pénznem": "pula",
            "ISO": "BWP",
            "Pénznem alegység": "thebe",
            "Angol név": "Botswana"
        },
        {
            "name": "Bouvet-sziget ",
            "Hosszú név": "Bouvet-sziget",
            "Kód": "BV",
            "Melléknévi alak": "Bouvet-szigeti",
            "Angol név": "Bouvet Island"
        },
        {
            "name": "Brazília",
            "Hosszú név": "Brazil Szövetségi Köztársaság",
            "Kód": "BR",
            "Főváros": "Brazíliaváros (Brasília)",
            "Melléknévi alak": "brazil, brazíliai, brazil szövetségi köztársasági",
            "Pénznem": "real",
            "ISO": "BRL",
            "Pénznem alegység": "centavo",
            "Angol név": "Brazil"
        },
        {
            "name": "Brit Indiai-óceáni Terület",
            "Hosszú név": "Brit Indiai-óceáni Terület",
            "Kód": "IO",
            "Melléknévi alak": "brit indiai-óceáni területi",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "British Indian Ocean Territory"
        },
        {
            "name": "Brit Virgin-szigetek ",
            "Hosszú név": "Brit Virgin-szigetek",
            "Kód": "VG",
            "Főváros": "Road Town",
            "Melléknévi alak": "brit Virgin-szigeteki",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Virgin Islands, British"
        },
        {
            "name": "Brunei",
            "Hosszú név": "Brunei Darussalam Állam",
            "Kód": "BN",
            "Főváros": "Bandar Seri Begawan",
            "Melléknévi alak": "brunei, Brunei Darussalam állami",
            "Pénznem": "brunei dollár",
            "ISO": "BND",
            "Pénznem alegység": "sen",
            "Angol név": "Brunei Darussalam"
        },
        {
            "name": "Bulgária",
            "Hosszú név": "Bolgár Köztársaság",
            "Kód": "BG",
            "Főváros": "Szófia",
            "Melléknévi alak": "bolgár, bulgáriai, bolgár köztársasági",
            "Pénznem": "leva",
            "ISO": "BGN",
            "Pénznem alegység": "sztotinka",
            "Angol név": "Bulgaria"
        },
        {
            "name": "Burkina Faso",
            "Hosszú név": "Burkina Fasó-i Köztársaság",
            "Kód": "BF",
            "Főváros": "Ouagadougou",
            "Melléknévi alak": "Burkina Fasó-i, burkinabé, Burkina Fasó-i köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Burkina Faso"
        },
        {
            "name": "Burundi",
            "Hosszú név": "Burundi Köztársaság",
            "Kód": "BI",
            "Főváros": "Bujumbura",
            "Melléknévi alak": "burundi, burundi köztársasági",
            "Pénznem": "burundi frank",
            "ISO": "BIF",
            "Pénznem alegység": "centime",
            "Angol név": "Burundi"
        },
        {
            "name": "Chile",
            "Hosszú név": "Chilei Köztársaság",
            "Kód": "CL",
            "Főváros": "Santiago de Chile",
            "Melléknévi alak": "chilei, chilei köztársasági",
            "Pénznem": "chilei peso",
            "ISO": "CLP",
            "Pénznem alegység": "centavo",
            "Angol név": "Chile"
        },
        {
            "name": "Ciprus",
            "Hosszú név": "Ciprusi Köztársaság",
            "Kód": "CY",
            "Főváros": "Nicosia",
            "Melléknévi alak": "ciprusi, ciprióta, ciprusi köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Cyprus"
        },
        {
            "name": "Clipperton ",
            "Hosszú név": "Clipperton-sziget",
            "Kód": "CP",
            "Angol név": "Clipperton Island"
        },
        {
            "name": "Comore-szigetek",
            "Hosszú név": "Comore-szigeteki Unió (hivatalos), Comore Iszlám Szövetségi Köztársaság (elfogadott)",
            "Kód": "KM",
            "Főváros": "Moroni",
            "Melléknévi alak": "comore-i, Comore-szigeteki, Comore-szigeteki unióbeli, Comore iszlám szövetségi köztársasági",
            "Pénznem": "comore-i frank",
            "ISO": "KMF",
            "Pénznem alegység": "centime",
            "Angol név": "Comoros"
        },
        {
            "name": "Cook-szigetek ",
            "Hosszú név": "Cook-szigetek",
            "Kód": "CK",
            "Főváros": "Avarua",
            "Melléknévi alak": "Cook-szigeteki",
            "Pénznem": "új-zélandi dollár",
            "ISO": "NZD",
            "Pénznem alegység": "cent",
            "Angol név": "Cook Islands"
        },
        {
            "name": "Costa Rica",
            "Hosszú név": "Costa Rica Köztársaság",
            "Kód": "CR",
            "Főváros": "San José",
            "Melléknévi alak": "Costa Rica-i, Costa Rica köztársasági",
            "Pénznem": "Costa Rica-i colón",
            "ISO": "CRC",
            "Pénznem alegység": "céntimo",
            "Angol név": "Costa Rica"
        },
        {
            "name": "Csád",
            "Hosszú név": "Csádi Köztársaság",
            "Kód": "TD",
            "Főváros": "N’Djamena",
            "Melléknévi alak": "csádi, csádi köztársasági",
            "Pénznem": "CFA-frank (BEAC) ",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Chad"
        },
        {
            "name": "Csehország",
            "Hosszú név": "Cseh Köztársaság",
            "Kód": "CZ",
            "Főváros": "Prága",
            "Melléknévi alak": "cseh, csehországi, cseh köztársasági",
            "Pénznem": "cseh korona",
            "ISO": "CZK",
            "Pénznem alegység": "halér",
            "Angol név": "Czech Republic"
        },
        {
            "name": "Dánia",
            "Hosszú név": "Dán Királyság",
            "Kód": "DK",
            "Főváros": "Koppenhága",
            "Melléknévi alak": "dán, dániai, dán királysági",
            "Pénznem": "dán korona",
            "ISO": "DKK",
            "Pénznem alegység": "őre",
            "Angol név": "Denmark"
        },
        {
            "name": "Dél-Afrika",
            "Hosszú név": "Dél-afrikai Köztársaság",
            "Kód": "ZA",
            "Főváros": "Pretoria",
            "Melléknévi alak": "dél-afrikai, dél-afrikai köztársasági",
            "Pénznem": "rand",
            "ISO": "ZAR",
            "Pénznem alegység": "cent",
            "Angol név": "South Africa"
        },
        {
            "name": "Dél-Georgia és Déli-Sandwich-szigetek ",
            "Hosszú név": "Dél-Georgia és Déli-Sandwich-szigetek",
            "Kód": "GS",
            "Melléknévi alak": "déli-Georgia és déli-Sandwich-szigeteki",
            "Pénznem": "font sterling",
            "ISO": "GBP",
            "Pénznem alegység": "penny",
            "Angol név": "South Georgia and the South Sandwich Islands"
        },
        {
            "name": "Dél-Korea",
            "Hosszú név": "Koreai Köztársaság",
            "Kód": "KR",
            "Főváros": "Szöul",
            "Melléknévi alak": "dél-koreai, koreai köztársasági",
            "Pénznem": "dél-koreai von",
            "ISO": "KRW",
            "Pénznem alegység": "cson",
            "Angol név": "Korea, Republic of"
        },
        {
            "name": "Dominika",
            "Hosszú név": "Dominikai Közösség",
            "Kód": "DM",
            "Főváros": "Roseau",
            "Melléknévi alak": "dominikai, dominikai közösségi",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Dominica"
        },
        {
            "name": "Dominikai Köztársaság",
            "Hosszú név": "Dominikai Köztársaság",
            "Kód": "DO",
            "Főváros": "Santo Domingo",
            "Melléknévi alak": "dominikai, dominikai köztársasági",
            "Pénznem": "dominikai peso",
            "ISO": "DOP",
            "Pénznem alegység": "centavo",
            "Angol név": "Dominican Republic"
        },
        {
            "name": "Dzsibuti",
            "Hosszú név": "Dzsibuti Köztársaság",
            "Kód": "DJ",
            "Főváros": "Dzsibuti",
            "Melléknévi alak": "dzsibuti, dzsibuti köztársasági",
            "Pénznem": "dzsibuti frank",
            "ISO": "DJF",
            "Pénznem alegység": "centime",
            "Angol név": "Djibouti"
        },
        {
            "name": "Ecuador",
            "Hosszú név": "Ecuadori Köztársaság",
            "Kód": "EC",
            "Főváros": "Quito",
            "Melléknévi alak": "ecuadori, ecuadori köztársasági",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Ecuador"
        },
        {
            "name": "Egyenlítői-Guinea",
            "Hosszú név": "Egyenlítői-guineai Köztársaság",
            "Kód": "GQ",
            "Főváros": "Malabo",
            "Melléknévi alak": "egyenlítői-guineai, egyenlítői-guineai köztársasági",
            "Pénznem": "CFA-frank (BEAC)",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Equatorial Guinea"
        },
        {
            "name": "Egyesült Államok",
            "Hosszú név": "Amerikai Egyesült Államok",
            "Kód": "US",
            "Főváros": "Washington",
            "Melléknévi alak": "egyesült államokbeli, amerikai egyesült államokbeli",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "United States"
        },
        {
            "name": "Egyesült Arab Emírségek",
            "Hosszú név": "Egyesült Arab Emírségek",
            "Kód": "AE",
            "Főváros": "Abu-Dzabi",
            "Melléknévi alak": "egyesült arab emírségekbeli",
            "Pénznem": "dirham",
            "ISO": "AED",
            "Pénznem alegység": "fil",
            "Angol név": "United Arab Emirates"
        },
        {
            "name": "Egyesült Királyság",
            "Hosszú név": "Nagy-Britannia és Észak-Írország Egyesült Királysága",
            "Kód": "UK",
            "Főváros": "London",
            "Melléknévi alak": "egyesült királyságbeli",
            "Pénznem": "font sterling",
            "ISO": "GBP",
            "Pénznem alegység": "penny",
            "Angol név": "United Kingdom"
        },
        {
            "name": "Egyiptom",
            "Hosszú név": "Egyiptomi Arab Köztársaság",
            "Kód": "EG",
            "Főváros": "Kairó",
            "Melléknévi alak": "egyiptomi, egyiptomi arab köztársasági",
            "Pénznem": "egyiptomi font",
            "ISO": "EGP",
            "Pénznem alegység": "piaszter",
            "Angol név": "Egypt"
        },
        {
            "name": "Elefántcsontpart",
            "Hosszú név": "Elefántcsontparti Köztársaság",
            "Kód": "CI",
            "Főváros": "Yamoussoukro",
            "Melléknévi alak": "elefántcsontparti, elefántcsontparti köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Cote D'Ivoire"
        },
        {
            "name": "Eritrea",
            "Hosszú név": "Eritrea Állam",
            "Kód": "ER",
            "Főváros": "Aszmara",
            "Melléknévi alak": "eritreai, Eritrea állami",
            "Pénznem": "nakfa",
            "ISO": "ERN",
            "Pénznem alegység": "cent",
            "Angol név": "Eritrea"
        },
        {
            "name": "Északi-Mariana-szigetek ",
            "Hosszú név": "Északi-Mariana-szigetek",
            "Kód": "MP",
            "Főváros": "Garapan",
            "Melléknévi alak": "északi-Mariana-szigeteki",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Northern Mariana Islands"
        },
        {
            "name": "Észak-Korea",
            "Hosszú név": "Koreai Népi Demokratikus Köztársaság",
            "Kód": "KP",
            "Főváros": "Phenjan",
            "Melléknévi alak": "észak-koreai, koreai népi demokratikus köztársasági",
            "Pénznem": "észak-koreai von",
            "ISO": "KPW",
            "Pénznem alegység": "cson",
            "Angol név": "Korea, Democratic People's Republic of"
        },
        {
            "name": "Észtország",
            "Hosszú név": "Észt Köztársaság",
            "Kód": "EE",
            "Főváros": "Tallinn",
            "Melléknévi alak": "észt, észtországi, észt köztársasági",
            "Pénznem": "észt korona",
            "ISO": "EEK",
            "Pénznem alegység": "sent",
            "Angol név": "Estonia"
        },
        {
            "name": "Etiópia",
            "Hosszú név": "Etióp Demokratikus Szövetségi Köztársaság",
            "Kód": "ET",
            "Főváros": "Addisz-Abeba",
            "Melléknévi alak": "etióp, etiópiai, etióp demokratikus szövetségi köztársasági",
            "Pénznem": "birr",
            "ISO": "ETB",
            "Pénznem alegység": "cent",
            "Angol név": "Ethiopia"
        },
        {
            "name": "Falkland-szigetek",
            "Hosszú név": "Falkland-szigetek",
            "Kód": "FK",
            "Főváros": "Stanley",
            "Melléknévi alak": "falklandi, Falkland-szigeteki",
            "Pénznem": "falklandi font",
            "ISO": "FKP",
            "Pénznem alegység": "új penny",
            "Angol név": "Falkland Islands (Malvinas)"
        },
        {
            "name": "Feröer szigetek ",
            "Hosszú név": "Feröer szigetek",
            "Kód": "FO",
            "Főváros": "Tórshavn",
            "Melléknévi alak": "feröeri, Feröer szigeteki",
            "Pénznem": "dán korona",
            "ISO": "DKK",
            "Pénznem alegység": "őre",
            "Angol név": "Faroe Islands"
        },
        {
            "name": "Fidzsi-szigetek",
            "Hosszú név": "Fidzsi-szigeteki Köztársaság (hivatalos), Fidzsi Köztársaság (elfogadott)",
            "Kód": "FJ",
            "Főváros": "Suva",
            "Melléknévi alak": "fidzsi, Fidzsi-szigeteki, Fidzsi-szigeteki köztársasági, fidzsi köztársasági",
            "Pénznem": "fidzsi dollár",
            "ISO": "FJD",
            "Pénznem alegység": "cent",
            "Angol név": "Fiji"
        },
        {
            "name": "Finnország",
            "Hosszú név": "Finn Köztársaság",
            "Kód": "FI",
            "Főváros": "Helsinki",
            "Melléknévi alak": "finn, finnországi, finn köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Finland"
        },
        {
            "name": "Francia Déli Területek ",
            "Hosszú név": "Francia Déli Területek",
            "Kód": "TF",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "French Southern Territories"
        },
        {
            "name": "Francia Guyana ",
            "Hosszú név": "Francia Guyana",
            "Kód": "GF",
            "Főváros": "Cayenne",
            "Melléknévi alak": "francia guyanai",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "French Guiana"
        },
        {
            "name": "Francia Polinézia ",
            "Hosszú név": "Francia Polinézia",
            "Kód": "PF",
            "Főváros": "Papeete",
            "Melléknévi alak": "francia polinéziai",
            "Pénznem": "CFP-frank",
            "ISO": "XPF",
            "Pénznem alegység": "centime",
            "Angol név": "French Polynesia"
        },
        {
            "name": "Franciaország",
            "Hosszú név": "Francia Köztársaság",
            "Kód": "FR",
            "Főváros": "Párizs",
            "Melléknévi alak": "francia, franciaországi, francia köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "France"
        },
        {
            "name": "Fülöp-szigetek",
            "Hosszú név": "Fülöp-szigeteki Köztársaság",
            "Kód": "PH",
            "Főváros": "Manila",
            "Melléknévi alak": "filippínó, Fülöp-szigeteki, Fülöp-szigeteki köztársasági",
            "Pénznem": "fülöp-szigeteki peso",
            "ISO": "PHP",
            "Pénznem alegység": "centavo",
            "Angol név": "Philippines"
        },
        {
            "name": "Gabon",
            "Hosszú név": "Gaboni Köztársaság",
            "Kód": "GA",
            "Főváros": "Libreville",
            "Melléknévi alak": "gaboni, gaboni köztársasági",
            "Pénznem": "CFA-frank (BEAC)",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Gabon"
        },
        {
            "name": "Gambia",
            "Hosszú név": "Gambiai Köztársaság",
            "Kód": "GM",
            "Főváros": "Banjul",
            "Melléknévi alak": "gambiai, gambiai köztársasági",
            "Pénznem": "dalasi",
            "ISO": "GMD",
            "Pénznem alegység": "butut",
            "Angol név": "Gambia"
        },
        {
            "name": "Ghána",
            "Hosszú név": "Ghánai Köztársaság",
            "Kód": "GH",
            "Főváros": "Accra",
            "Melléknévi alak": "ghánai, ghánai köztársasági",
            "Pénznem": "ghánai cedi",
            "ISO": "GHS",
            "Pénznem alegység": "pesewa",
            "Angol név": "Ghana"
        },
        {
            "name": "Gibraltár ",
            "Hosszú név": "Gibraltár",
            "Kód": "GI",
            "Főváros": "Gibraltár",
            "Melléknévi alak": "gibraltári",
            "Pénznem": "gibraltári font",
            "ISO": "GIP",
            "Pénznem alegység": "penny",
            "Angol név": "Gibraltar"
        },
        {
            "name": "Görögország",
            "Hosszú név": "Görög Köztársaság",
            "Kód": "EL",
            "Főváros": "Athén",
            "Melléknévi alak": "görög, görögországi, görög köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Greece"
        },
        {
            "name": "Grenada",
            "Hosszú név": "Grenada",
            "Kód": "GD",
            "Főváros": "Saint George’s",
            "Melléknévi alak": "grenadai",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Grenada"
        },
        {
            "name": "Grönland ",
            "Hosszú név": "Grönland",
            "Kód": "GL",
            "Főváros": "Nuuk (Godthab)",
            "Melléknévi alak": "grönlandi",
            "Pénznem": "dán korona",
            "ISO": "DKK",
            "Pénznem alegység": "őre",
            "Angol név": "Greenland"
        },
        {
            "name": "Grúzia",
            "Hosszú név": "Grúzia",
            "Kód": "GE",
            "Főváros": "Tbiliszi",
            "Melléknévi alak": "grúz, grúziai, grúz köztársasági",
            "Pénznem": "lari",
            "ISO": "GEL",
            "Pénznem alegység": "tetri",
            "Angol név": "Georgia"
        },
        {
            "name": "Guadeloupe ",
            "Hosszú név": "Guadeloupe",
            "Kód": "GP",
            "Főváros": "Basse-Terre",
            "Melléknévi alak": "guadeloupe-i",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Guadeloupe"
        },
        {
            "name": "Guam ",
            "Hosszú név": "Guam",
            "Kód": "GU",
            "Főváros": "Hagatna",
            "Melléknévi alak": "guami",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Guam"
        },
        {
            "name": "Guatemala",
            "Hosszú név": "Guatemalai Köztársaság",
            "Kód": "GT",
            "Főváros": "Guatemalaváros",
            "Melléknévi alak": "guatemalai, guatemalai köztársasági",
            "Pénznem": "quetzal",
            "ISO": "GTQ",
            "Pénznem alegység": "centavo",
            "Angol név": "Guatemala"
        },
        {
            "name": "Guinea",
            "Hosszú név": "Guineai Köztársaság",
            "Kód": "GN",
            "Főváros": "Conakry",
            "Melléknévi alak": "guineai, guineai köztársasági",
            "Pénznem": "guineai frank",
            "ISO": "GNF",
            "Angol név": "Guinea"
        },
        {
            "name": "Guyana",
            "Hosszú név": "Guyanai Szövetkezeti Köztársaság",
            "Kód": "GY",
            "Főváros": "Georgetown",
            "Melléknévi alak": "guyanai, guyanai szövetkezeti köztársasági",
            "Pénznem": "guyanai dollár",
            "ISO": "GYD",
            "Pénznem alegység": "cent",
            "Angol név": "Guyana"
        },
        {
            "name": "Haiti",
            "Hosszú név": "Haiti Köztársaság",
            "Kód": "HT",
            "Főváros": "Port-au-Prince",
            "Melléknévi alak": "haiti, haiti köztársasági",
            "Pénznem": "gourde",
            "ISO": "HTG",
            "Pénznem alegység": "centime",
            "Angol név": "Haiti"
        },
        {
            "name": "Heard-sziget és McDonald-szigetek ",
            "Hosszú név": "Heard-sziget és McDonald-szigetek",
            "Kód": "HM",
            "Melléknévi alak": "Heard-sziget és McDonald-szigeteki",
            "Angol név": "Heard Island and Mcdonald Islands"
        },
        {
            "name": "Holland Antillák ",
            "Hosszú név": "Holland Antillák",
            "Kód": "AN",
            "Főváros": "Willemstad",
            "Melléknévi alak": "holland antilláki",
            "Pénznem": "holland antillai gulden (holland antillai forint)",
            "ISO": "ANG",
            "Pénznem alegység": "cent",
            "Angol név": "Netherlands Antilles"
        },
        {
            "name": "Hollandia",
            "Hosszú név": "Holland Királyság",
            "Kód": "NL",
            "Főváros": "Amszterdam",
            "Melléknévi alak": "holland, holland királysági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Netherlands"
        },
        {
            "name": "Honduras",
            "Hosszú név": "Hondurasi Köztársaság",
            "Kód": "HN",
            "Főváros": "Tegucigalpa",
            "Melléknévi alak": "hondurasi, hondurasi köztársasági",
            "Pénznem": "lempira",
            "ISO": "HNL",
            "Pénznem alegység": "centavo",
            "Angol név": "Honduras"
        },
        {
            "name": "Hongkong ",
            "Hosszú név": "Hongkong Különleges Közigazgatási Terület",
            "Kód": "HK",
            "Melléknévi alak": "hongkongi, hongkongi különleges közigazgatási területi",
            "Pénznem": "hongkongi dollár",
            "ISO": "HKD",
            "Pénznem alegység": "cent",
            "Angol név": "Hong Kong"
        },
        {
            "name": "Horvátország",
            "Hosszú név": "Horvát Köztársaság",
            "Kód": "HR",
            "Főváros": "Zágráb",
            "Melléknévi alak": "horvát, horvátországi, horvát köztársasági",
            "Pénznem": "kuna",
            "ISO": "HRK",
            "Pénznem alegység": "lipa",
            "Angol név": "Croatia"
        },
        {
            "name": "India",
            "Hosszú név": "Indiai Köztársaság",
            "Kód": "IN",
            "Főváros": "Újdelhi",
            "Melléknévi alak": "indiai, indiai köztársasági",
            "Pénznem": "indiai rúpia",
            "ISO": "INR",
            "Pénznem alegység": "paisa",
            "Angol név": "India"
        },
        {
            "name": "Indonézia",
            "Hosszú név": "Indonéz Köztársaság",
            "Kód": "ID",
            "Főváros": "Jakarta",
            "Melléknévi alak": "indonéz, indonéziai, indonéz köztársasági",
            "Pénznem": "indonéz rúpia",
            "ISO": "IDR",
            "Pénznem alegység": "sen",
            "Angol név": "Indonesia"
        },
        {
            "name": "Irak",
            "Hosszú név": "Iraki Köztársaság",
            "Kód": "IQ",
            "Főváros": "Bagdad",
            "Melléknévi alak": "iraki, iraki köztársasági",
            "Pénznem": "iraki dinár",
            "ISO": "IQD",
            "Pénznem alegység": "fil",
            "Angol név": "Iraq"
        },
        {
            "name": "Irán",
            "Hosszú név": "Iráni Iszlám Köztársaság",
            "Kód": "IR",
            "Főváros": "Teherán",
            "Melléknévi alak": "iráni, iráni iszlám köztársasági",
            "Pénznem": "iráni riál",
            "ISO": "IRR",
            "Pénznem alegység": "dinár",
            "Angol név": "Iran, Islamic Republic of"
        },
        {
            "name": "Írország",
            "Hosszú név": "Írország",
            "Kód": "IE",
            "Főváros": "Dublin",
            "Melléknévi alak": "ír, írországi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Ireland"
        },
        {
            "name": "Izland",
            "Hosszú név": "Izlandi Köztársaság",
            "Kód": "IS",
            "Főváros": "Reykjavík",
            "Melléknévi alak": "izlandi, izlandi köztársasági",
            "Pénznem": "izlandi korona",
            "ISO": "ISK",
            "Angol név": "Iceland"
        },
        {
            "name": "Izrael",
            "Hosszú név": "Izrael Állam",
            "Kód": "IL",
            "Főváros": "Tel Aviv (Jeruzsálem)",
            "Melléknévi alak": "izraeli, Izrael állami",
            "Pénznem": "sékel",
            "ISO": "ILS",
            "Pénznem alegység": "agora",
            "Angol név": "Israel"
        },
        {
            "name": "Jamaica",
            "Hosszú név": "Jamaica",
            "Kód": "JM",
            "Főváros": "Kingston",
            "Melléknévi alak": "jamaicai",
            "Pénznem": "jamaicai dollár",
            "ISO": "JMD",
            "Pénznem alegység": "cent",
            "Angol név": "Jamaica"
        },
        {
            "name": "Japán",
            "Hosszú név": "Japán",
            "Kód": "JP",
            "Főváros": "Tokió",
            "Melléknévi alak": "japán, japáni",
            "Pénznem": "jen",
            "ISO": "JPY",
            "Pénznem alegység": "szen",
            "Angol név": "Japan"
        },
        {
            "name": "Jemen",
            "Hosszú név": "Jemeni Köztársaság",
            "Kód": "YE",
            "Főváros": "Szanaa",
            "Melléknévi alak": "jemeni, jemeni köztársasági",
            "Pénznem": "jemeni riál",
            "ISO": "YER",
            "Pénznem alegység": "fil",
            "Angol név": "Yemen"
        },
        {
            "name": "Jordánia",
            "Hosszú név": "Jordán Hasimita Királyság",
            "Kód": "JO",
            "Főváros": "Ammán",
            "Melléknévi alak": "jordán, jordániai, jordán hasimita királysági",
            "Pénznem": "jordániai dinár",
            "ISO": "JOD",
            "Pénznem alegység": "fil",
            "Angol név": "Jordan"
        },
        {
            "name": "Kajmán-szigetek ",
            "Hosszú név": "Kajmán-szigetek",
            "Kód": "KY",
            "Főváros": "Georgetown",
            "Melléknévi alak": "kajmán-szigeteki",
            "Pénznem": "kajmán-szigeteki dollár",
            "ISO": "KYD",
            "Pénznem alegység": "cent",
            "Angol név": "Cayman Islands"
        },
        {
            "name": "Kambodzsa",
            "Hosszú név": "Kambodzsai Királyság",
            "Kód": "KH",
            "Főváros": "Phnompen",
            "Melléknévi alak": "kambodzsai, kambodzsai királysági",
            "Pénznem": "riel",
            "ISO": "KHR",
            "Pénznem alegység": "sen",
            "Angol név": "Cambodia"
        },
        {
            "name": "Kamerun",
            "Hosszú név": "Kameruni Köztársaság",
            "Kód": "CM",
            "Főváros": "Yaoundé",
            "Melléknévi alak": "kameruni, kameruni köztársasági",
            "Pénznem": "CFA-frank (BEAC)",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Cameroon"
        },
        {
            "name": "Kanada",
            "Hosszú név": "Kanada",
            "Kód": "CA",
            "Főváros": "Ottawa",
            "Melléknévi alak": "kanadai",
            "Pénznem": "kanadai dollár",
            "ISO": "CAD",
            "Pénznem alegység": "cent",
            "Angol név": "Canada"
        },
        {
            "name": "Karácsony-sziget ",
            "Hosszú név": "Karácsony-sziget",
            "Kód": "CX",
            "Főváros": "Flying Fish Cove",
            "Melléknévi alak": "karácsony-szigeti",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Christmas Island"
        },
        {
            "name": "Katar",
            "Hosszú név": "Katari Állam",
            "Kód": "QA",
            "Főváros": "Doha",
            "Melléknévi alak": "katari, katari állami",
            "Pénznem": "katari riál",
            "ISO": "QAR",
            "Pénznem alegység": "dirham",
            "Angol név": "Qatar"
        },
        {
            "name": "Kazahsztán",
            "Hosszú név": "Kazah Köztársaság",
            "Kód": "KZ",
            "Főváros": "Asztana",
            "Melléknévi alak": "kazah v. kazak, kazahsztáni, kazah köztársasági",
            "Pénznem": "tenge",
            "ISO": "KZT",
            "Pénznem alegység": "tiyin",
            "Angol név": "Kazakhstan"
        },
        {
            "name": "Kelet-Timor",
            "Hosszú név": "Kelet-timori Demokratikus Köztársaság",
            "Kód": "TL",
            "Főváros": "Dili",
            "Melléknévi alak": "kelet-timori, kelet-timori demokratikus köztársasági",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Timor-Leste"
        },
        {
            "name": "Kenya",
            "Hosszú név": "Kenyai Köztársaság",
            "Kód": "KE",
            "Főváros": "Nairobi",
            "Melléknévi alak": "kenyai, kenyai köztársasági",
            "Pénznem": "kenyai shilling",
            "ISO": "KES",
            "Pénznem alegység": "cent",
            "Angol név": "Kenya"
        },
        {
            "name": "Kína",
            "Hosszú név": "Kínai Népköztársaság",
            "Kód": "CN",
            "Főváros": "Peking",
            "Melléknévi alak": "kínai, kínai népköztársasági",
            "Pénznem": "renminbi jüan",
            "ISO": "CNY",
            "Pénznem alegység": "jiao; fen",
            "Angol név": "China"
        },
        {
            "name": "Kirgizisztán",
            "Hosszú név": "Kirgiz Köztársaság",
            "Kód": "KG",
            "Főváros": "Biskek",
            "Melléknévi alak": "kirgiz, kirgizisztáni, kirgiz köztársasági",
            "Pénznem": "kirgiz szom",
            "ISO": "KGS",
            "Pénznem alegység": "tüin",
            "Angol név": "Kyrgyzstan"
        },
        {
            "name": "Kiribati",
            "Hosszú név": "Kiribati Köztársaság",
            "Kód": "KI",
            "Főváros": "Tarawa",
            "Melléknévi alak": "kiribati, kiribati köztársasági",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Kiribati"
        },
        {
            "name": "Kókusz-szigetek/Keeling-szigetek",
            "Hosszú név": "Kókusz-szigetek/Keeling-szigetek",
            "Kód": "CC",
            "Főváros": "Bantam",
            "Melléknévi alak": "kókusz-szigeteki, Keeling-szigeteki",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Cocos (Keeling) Islands"
        },
        {
            "name": "Kolumbia",
            "Hosszú név": "Kolumbiai Köztársaság",
            "Kód": "CO",
            "Főváros": "Bogotá",
            "Melléknévi alak": "kolumbiai, kolumbiai köztársasági",
            "Pénznem": "kolumbiai peso",
            "ISO": "COP",
            "Pénznem alegység": "centavo",
            "Angol név": "Colombia"
        },
        {
            "name": "Kongó",
            "Hosszú név": "Kongói Köztársaság",
            "Kód": "CG",
            "Főváros": "Brazzaville",
            "Melléknévi alak": "kongói, kongói köztársasági",
            "Pénznem": "CFA-frank (BEAC)",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Congo"
        },
        {
            "name": "Kongói Demokratikus Köztársaság",
            "Hosszú név": "Kongói Demokratikus Köztársaság",
            "Kód": "CD",
            "Főváros": "Kinshasa",
            "Melléknévi alak": "kongói demokratikus köztársasági",
            "Pénznem": "kongói frank",
            "ISO": "CDF",
            "Pénznem alegység": "centime",
            "Angol név": "Congo, the Democratic Republic of the"
        },
        {
            "name": "Közép-afrikai Köztársaság",
            "Hosszú név": "Közép-afrikai Köztársaság",
            "Kód": "CF",
            "Főváros": "Bangui",
            "Melléknévi alak": "közép-afrikai, közép-afrikai köztársasági",
            "Pénznem": "CFA-frank (BEAC) ",
            "ISO": "XAF",
            "Pénznem alegység": "centime",
            "Angol név": "Central African Republic"
        },
        {
            "name": "Kuba",
            "Hosszú név": "Kubai Köztársaság",
            "Kód": "CU",
            "Főváros": "Havanna",
            "Melléknévi alak": "kubai, kubai köztársasági",
            "Pénznem": "kubai peso",
            "ISO": "CUP",
            "Pénznem alegység": "centavo",
            "Angol név": "Cuba"
        },
        {
            "name": "Kuvait",
            "Hosszú név": "Kuvait Állam",
            "Kód": "KW",
            "Főváros": "Kuvaitváros",
            "Melléknévi alak": "kuvaiti, Kuvait állami",
            "Pénznem": "kuvaiti dinár",
            "ISO": "KWD",
            "Pénznem alegység": "fil",
            "Angol név": "Kuwait"
        },
        {
            "name": "Laosz",
            "Hosszú név": "Laoszi Népi Demokratikus Köztársaság",
            "Kód": "LA",
            "Főváros": "Vientián",
            "Melléknévi alak": "laoszi, laoszi népi demokratikus köztársasági",
            "Pénznem": "kip",
            "ISO": "LAK",
            "Pénznem alegység": "at",
            "Angol név": "Lao People's Democratic Republic"
        },
        {
            "name": "Lengyelország",
            "Hosszú név": "Lengyel Köztársaság",
            "Kód": "PL",
            "Főváros": "Varsó",
            "Melléknévi alak": "lengyel, lengyelországi, lengyel köztársasági",
            "Pénznem": "zloty",
            "ISO": "PLN",
            "Pénznem alegység": "grosz",
            "Angol név": "Poland"
        },
        {
            "name": "Lesotho",
            "Hosszú név": "Lesothói Királyság",
            "Kód": "LS",
            "Főváros": "Maseru",
            "Melléknévi alak": "lesothói, lesothói királysági",
            "Pénznem": "loti",
            "ISO": "LSL",
            "Pénznem alegység": "sente",
            "Angol név": "Lesotho"
        },
        {
            "name": "Lettország",
            "Hosszú név": "Lett Köztársaság",
            "Kód": "LV",
            "Főváros": "Riga",
            "Melléknévi alak": "lett, lettországi, lett köztársasági",
            "Pénznem": "lat",
            "ISO": "LVL",
            "Pénznem alegység": "santims",
            "Angol név": "Latvia"
        },
        {
            "name": "Libanon",
            "Hosszú név": "Libanoni Köztársaság",
            "Kód": "LB",
            "Főváros": "Bejrút",
            "Melléknévi alak": "libanoni, libanoni köztársasági",
            "Pénznem": "libanoni font",
            "ISO": "LBP",
            "Pénznem alegység": "piaszter",
            "Angol név": "Lebanon"
        },
        {
            "name": "Libéria",
            "Hosszú név": "Libériai Köztársaság",
            "Kód": "LR",
            "Főváros": "Monrovia",
            "Melléknévi alak": "libériai, libériai köztársasági",
            "Pénznem": "libériai dollár",
            "ISO": "LRD",
            "Pénznem alegység": "cent",
            "Angol név": "Liberia"
        },
        {
            "name": "Líbia",
            "Hosszú név": "Líbiai Arab Szocialista Népi Nagy Dzsamahirija (hivatalos), Líbiai Arab Szocialista Nagy Népi Közösség (elfogadott)",
            "Kód": "LY",
            "Főváros": "Tripoli",
            "Melléknévi alak": "líbiai, líbiai arab szocialista népi nagy dzsamahirijai, líbiai arab szocialista nagy népi közösségi",
            "Pénznem": "líbiai dinár",
            "ISO": "LYD",
            "Pénznem alegység": "dirham",
            "Angol név": "Libyan Arab Jamahiriya"
        },
        {
            "name": "Liechtenstein",
            "Hosszú név": "Liechtensteini Hercegség",
            "Kód": "LI",
            "Főváros": "Vaduz",
            "Melléknévi alak": "liechtensteini, liechtensteini hercegségi",
            "Pénznem": "svájci frank",
            "ISO": "CHF",
            "Pénznem alegység": "centime",
            "Angol név": "Liechtenstein"
        },
        {
            "name": "Litvánia",
            "Hosszú név": "Litván Köztársaság",
            "Kód": "LT",
            "Főváros": "Vilnius",
            "Melléknévi alak": "litván, litvániai, litván köztársasági",
            "Pénznem": "litas",
            "ISO": "LTL",
            "Pénznem alegység": "centas",
            "Angol név": "Lithuania"
        },
        {
            "name": "Luxemburg",
            "Hosszú név": "Luxemburgi Nagyhercegség",
            "Kód": "LU",
            "Főváros": "Luxembourg",
            "Melléknévi alak": "luxemburgi, luxemburgi nagyhercegségi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Luxembourg"
        },
        {
            "name": "Macedónia",
            "Hosszú név": "Macedónia Volt Jugoszláv Köztársaság",
            "Kód": "MK",
            "Főváros": "Szkopje",
            "Melléknévi alak": "Macedónia volt jugoszláv köztársasági",
            "Pénznem": "dénár",
            "ISO": "MKD",
            "Pénznem alegység": "deni",
            "Angol név": "Macedonia"
        },
        {
            "name": "Madagaszkár",
            "Hosszú név": "Madagaszkári Köztársaság",
            "Kód": "MG",
            "Főváros": "Antananarivo",
            "Melléknévi alak": "madagaszkári, malagaszi, madagaszkári köztársasági",
            "Pénznem": "ariary",
            "ISO": "MGA",
            "Pénznem alegység": "iraimbilanja",
            "Angol név": "Madagascar"
        },
        {
            "name": "Magyarország",
            "Hosszú név": "Magyar Köztársaság",
            "Kód": "HU",
            "Főváros": "Budapest",
            "Melléknévi alak": "magyar, magyarországi, magyar köztársasági",
            "Pénznem": "forint",
            "ISO": "HUF",
            "Pénznem alegység": "fillér",
            "Angol név": "Hungary"
        },
        {
            "name": "Makaó ",
            "Hosszú név": "Makaó Különleges Közigazgatási Terület",
            "Kód": "MO",
            "Főváros": "Makaó",
            "Melléknévi alak": "makaói, Makaó különleges közigazgatási területi",
            "Pénznem": "pataca",
            "ISO": "MOP",
            "Pénznem alegység": "avo",
            "Angol név": "Macao"
        },
        {
            "name": "Malajzia",
            "Hosszú név": "Malajzia",
            "Kód": "MY",
            "Főváros": "Kuala Lumpur",
            "Melléknévi alak": "maláj, malajziai",
            "Pénznem": "ringgit",
            "ISO": "MYR",
            "Pénznem alegység": "szen",
            "Angol név": "Malaysia"
        },
        {
            "name": "Malawi",
            "Hosszú név": "Malawi Köztársaság",
            "Kód": "MW",
            "Főváros": "Lilongwe",
            "Melléknévi alak": "malawi, malawi köztársasági",
            "Pénznem": "malawi kwacha",
            "ISO": "MWK",
            "Pénznem alegység": "tambala",
            "Angol név": "Malawi"
        },
        {
            "name": "Maldív-szigetek",
            "Hosszú név": "Maldív Köztársaság",
            "Kód": "MV",
            "Főváros": "Male",
            "Melléknévi alak": "maldív, maldív-szigeteki, maldív köztársasági",
            "Pénznem": "maldív rúpia (rufiyaa)",
            "ISO": "MVR",
            "Pénznem alegység": "laari",
            "Angol név": "Maldives"
        },
        {
            "name": "Mali",
            "Hosszú név": "Mali Köztársaság",
            "Kód": "ML",
            "Főváros": "Bamako",
            "Melléknévi alak": "mali, mali köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Mali"
        },
        {
            "name": "Málta",
            "Hosszú név": "Máltai Köztársaság",
            "Kód": "MT",
            "Főváros": "Valletta",
            "Melléknévi alak": "máltai, máltai köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Malta"
        },
        {
            "name": "Marokkó",
            "Hosszú név": "Marokkói Királyság",
            "Kód": "MA",
            "Főváros": "Rabat",
            "Melléknévi alak": "marokkói, marokkói királysági",
            "Pénznem": "marokkói dirham",
            "ISO": "MAD",
            "Pénznem alegység": "centime",
            "Angol név": "Morocco"
        },
        {
            "name": "Marshall-szigetek",
            "Hosszú név": "Marshall-szigeteki Köztársaság",
            "Kód": "MH",
            "Főváros": "Majuro",
            "Melléknévi alak": "Marshall-szigeteki, Marshall-szigeteki köztársasági",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Marshall Islands"
        },
        {
            "name": "Martinique ",
            "Hosszú név": "Martinique",
            "Kód": "MQ",
            "Főváros": "Fort-de-France",
            "Melléknévi alak": "martinique-i",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Martinique"
        },
        {
            "name": "Mauritánia",
            "Hosszú név": "Mauritániai Iszlám Köztársaság",
            "Kód": "MR",
            "Főváros": "Nuáksút (Nouakchott)",
            "Melléknévi alak": "mauritániai, mauritániai iszlám köztársasági",
            "Pénznem": "uguiya",
            "ISO": "MRO",
            "Pénznem alegység": "khum",
            "Angol név": "Mauritania"
        },
        {
            "name": "Mauritius",
            "Hosszú név": "Mauritiusi Köztársaság",
            "Kód": "MU",
            "Főváros": "Port Louis",
            "Melléknévi alak": "mauritiusi, mauritiusi köztársasági",
            "Pénznem": "mauritiusi rúpia",
            "ISO": "MUR",
            "Pénznem alegység": "cent",
            "Angol név": "Mauritius"
        },
        {
            "name": "Mayotte ",
            "Hosszú név": "Mayotte Területi Közösség",
            "Kód": "YT",
            "Főváros": "Mamoudzou",
            "Melléknévi alak": "mayotte-i, Mayotte területi közösségi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Mayotte"
        },
        {
            "name": "Mexikó",
            "Hosszú név": "Mexikói Egyesült Államok",
            "Kód": "MX",
            "Főváros": "Mexikóváros",
            "Melléknévi alak": "mexikói, mexikói egyesült államokbeli",
            "Pénznem": "mexikói peso",
            "ISO": "MXN",
            "Pénznem alegység": "centavo",
            "Angol név": "Mexico"
        },
        {
            "name": "Mianmar ",
            "Hosszú név": "Mianmari Államszövetség",
            "Kód": "MM",
            "Főváros": "Rangun",
            "Melléknévi alak": "mianmari, mianmari államszövetségi, burmai",
            "Pénznem": "kyat",
            "ISO": "MMK",
            "Pénznem alegység": "pya",
            "Angol név": "Myanmar"
        },
        {
            "name": "Mikronézia",
            "Hosszú név": "Mikronéziai Szövetségi Államok",
            "Kód": "FM",
            "Főváros": "Palikir",
            "Melléknévi alak": "mikronéz, mikronéziai, mikronéziai szövetségi államokbeli",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Micronesia, Federated States of"
        },
        {
            "name": "Moldova",
            "Hosszú név": "Moldovai Köztársaság",
            "Kód": "MD",
            "Főváros": "Chişinău",
            "Melléknévi alak": "moldáv, moldovai, moldován, moldovai köztársasági",
            "Pénznem": "moldován lej",
            "ISO": "MDL",
            "Pénznem alegység": "bani",
            "Angol név": "Moldova, Republic of"
        },
        {
            "name": "Monaco",
            "Hosszú név": "Monacói Hercegség",
            "Kód": "MC",
            "Főváros": "Monaco",
            "Melléknévi alak": "monacói, monacói hercegségi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Monaco"
        },
        {
            "name": "Mongólia",
            "Hosszú név": "Mongólia",
            "Kód": "MN",
            "Főváros": "Ulánbátor",
            "Melléknévi alak": "mongol, mongóliai",
            "Pénznem": "tugrik",
            "ISO": "MNT",
            "Pénznem alegység": "möngö",
            "Angol név": "Mongolia"
        },
        {
            "name": "Montenegró",
            "Hosszú név": "Montenegró",
            "Kód": "ME",
            "Főváros": "Podgorica",
            "Melléknévi alak": "montenegrói, montenegrói köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Montenegro"
        },
        {
            "name": "Montserrat ",
            "Hosszú név": "Montserrat",
            "Kód": "MS",
            "Főváros": "Plymouth",
            "Melléknévi alak": "montserrat-i",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Montserrat"
        },
        {
            "name": "Mozambik",
            "Hosszú név": "Mozambiki Köztársaság",
            "Kód": "MZ",
            "Főváros": "Maputo",
            "Melléknévi alak": "mozambiki, mozambiki köztársasági",
            "Pénznem": "metical",
            "ISO": "MZN",
            "Pénznem alegység": "centavo",
            "Angol név": "Mozambique"
        },
        {
            "name": "Namíbia",
            "Hosszú név": "Namíbiai Köztársaság",
            "Kód": "NA",
            "Főváros": "Windhoek",
            "Melléknévi alak": "namíbiai, namíbiai köztársasági",
            "Pénznem": "namíbiai dollár; dél-afrikai rand",
            "ISO": "NAD;",
            "Pénznem alegység": "cent",
            "Angol név": "Namibia"
        },
        {
            "name": "Nauru",
            "Hosszú név": "Naurui Köztársaság",
            "Kód": "NR",
            "Főváros": "Yaren",
            "Melléknévi alak": "naurui, naurui köztársasági",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Nauru"
        },
        {
            "name": "Németország",
            "Hosszú név": "Németországi Szövetségi Köztársaság",
            "Kód": "DE",
            "Főváros": "Berlin",
            "Melléknévi alak": "német, németországi, németországi szövetségi köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Germany"
        },
        {
            "name": "Nepál",
            "Hosszú név": "Nepáli Demokratikus Szövetségi Köztársaság",
            "Kód": "NP",
            "Főváros": "Katmandu",
            "Melléknévi alak": "nepáli, nepáli demokratikus szövetségi köztársasági",
            "Pénznem": "nepáli rúpia",
            "ISO": "NPR",
            "Pénznem alegység": "paisa",
            "Angol név": "Nepal"
        },
        {
            "name": "Nicaragua",
            "Hosszú név": "Nicaraguai Köztársaság",
            "Kód": "NI",
            "Főváros": "Managua",
            "Melléknévi alak": "nicaraguai, nicaraguai köztársasági",
            "Pénznem": "córdoba",
            "ISO": "NIO",
            "Pénznem alegység": "centavo",
            "Angol név": "Nicaragua"
        },
        {
            "name": "Niger",
            "Hosszú név": "Nigeri Köztársaság",
            "Kód": "NE",
            "Főváros": "Niamey",
            "Melléknévi alak": "nigeri, nigeri köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Niger"
        },
        {
            "name": "Nigéria",
            "Hosszú név": "Nigériai Szövetségi Köztársaság",
            "Kód": "NG",
            "Főváros": "Abuja",
            "Melléknévi alak": "nigériai, nigériai szövetségi köztársasági",
            "Pénznem": "naira",
            "ISO": "NGN",
            "Pénznem alegység": "kobo",
            "Angol név": "Nigeria"
        },
        {
            "name": "Niue ",
            "Hosszú név": "Niuei Köztársaság",
            "Kód": "NU",
            "Főváros": "Alofi",
            "Melléknévi alak": "niuei, niuei köztársasági",
            "Pénznem": "új-zélandi dollár",
            "ISO": "NZD",
            "Pénznem alegység": "cent",
            "Angol név": "Niue"
        },
        {
            "name": "Norfolk-sziget ",
            "Hosszú név": "Norfolk-sziget",
            "Kód": "NF",
            "Főváros": "Kingston",
            "Melléknévi alak": "Norfolk-szigeti",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Norfolk Island"
        },
        {
            "name": "Norvégia",
            "Hosszú név": "Norvég Királyság",
            "Kód": "NO",
            "Főváros": "Oslo",
            "Melléknévi alak": "norvég, norvégiai, norvég királysági",
            "Pénznem": "norvég korona",
            "ISO": "NOK",
            "Pénznem alegység": "őre",
            "Angol név": "Norway"
        },
        {
            "name": "Nyugat-Szahara ",
            "Hosszú név": "Nyugat-Szahara ",
            "Kód": "EH",
            "Főváros": "Ajún",
            "Melléknévi alak": "nyugat-szaharai, szaharai",
            "Pénznem": "marokkói dirham",
            "ISO": "MAD",
            "Pénznem alegység": "centime",
            "Angol név": "Western Sahara"
        },
        {
            "name": "Olaszország",
            "Hosszú név": "Olasz Köztársaság",
            "Kód": "IT",
            "Főváros": "Róma",
            "Melléknévi alak": "olasz, olaszországi, olasz köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Italy"
        },
        {
            "name": "Omán",
            "Hosszú név": "Ománi Szultánság",
            "Kód": "OM",
            "Főváros": "Maszkat",
            "Melléknévi alak": "ománi, ománi szultánsági",
            "Pénznem": "ománi riál",
            "ISO": "OMR",
            "Pénznem alegység": "baiza",
            "Angol név": "Oman"
        },
        {
            "name": "Oroszország",
            "Hosszú név": "Orosz Föderáció",
            "Kód": "RU",
            "Főváros": "Moszkva",
            "Melléknévi alak": "orosz, oroszországi, orosz föderációbeli",
            "Pénznem": "orosz rubel",
            "ISO": "RUB",
            "Pénznem alegység": "kopejka",
            "Angol név": "Russian Federation"
        },
        {
            "name": "Örményország",
            "Hosszú név": "Örmény Köztársaság",
            "Kód": "AM",
            "Főváros": "Jereván",
            "Melléknévi alak": "örmény, örményországi, örmény köztársasági",
            "Pénznem": "dram",
            "ISO": "AMD",
            "Pénznem alegység": "luma",
            "Angol név": "Armenia"
        },
        {
            "name": "Pakisztán",
            "Hosszú név": "Pakisztáni Iszlám Köztársaság",
            "Kód": "PK",
            "Főváros": "Iszlámábád",
            "Melléknévi alak": "pakisztáni, pakisztáni iszlám köztársasági",
            "Pénznem": "pakisztáni rúpia",
            "ISO": "PKR",
            "Pénznem alegység": "paisa",
            "Angol név": "Pakistan"
        },
        {
            "name": "Palau",
            "Hosszú név": "Palaui Köztársaság",
            "Kód": "PW",
            "Főváros": "Melekeok",
            "Melléknévi alak": "palaui, palaui köztársasági",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Palau"
        },
        {
            "name": "Panama",
            "Hosszú név": "Panamai Köztársaság",
            "Kód": "PA",
            "Főváros": "Panamavárosk",
            "Melléknévi alak": "panamai, panamai köztársasági",
            "Pénznem": "balboa",
            "ISO": "PAB",
            "Pénznem alegység": "centésimo",
            "Angol név": "Panama"
        },
        {
            "name": "Pápua Új-Guinea",
            "Hosszú név": "Pápua Új-guineai Független Állam",
            "Kód": "PG",
            "Főváros": "Port Moresby",
            "Melléknévi alak": "pápua új-guineai, pápua új-guineai független állami",
            "Pénznem": "kina",
            "ISO": "PGK",
            "Pénznem alegység": "toea",
            "Angol név": "Papua New Guinea"
        },
        {
            "name": "Paraguay",
            "Hosszú név": "Paraguayi Köztársaság",
            "Kód": "PY",
            "Főváros": "Asunción",
            "Melléknévi alak": "paraguayi, paraguayi köztársasági",
            "Pénznem": "guaraní",
            "ISO": "PYG",
            "Pénznem alegység": "céntimo",
            "Angol név": "Paraguay"
        },
        {
            "name": "Peru",
            "Hosszú név": "Perui Köztársaság",
            "Kód": "PE",
            "Főváros": "Lima",
            "Melléknévi alak": "perui, perui köztársasági",
            "Pénznem": "új sol",
            "ISO": "PEN",
            "Pénznem alegység": "céntimo",
            "Angol név": "Peru"
        },
        {
            "name": "Pitcairn-szigetek ",
            "Hosszú név": "Pitcairn-szigetek",
            "Kód": "PN",
            "Főváros": "Adamstown",
            "Melléknévi alak": "pitcairni, Pitcairn-szigeteki",
            "Pénznem": "új-zélandi dollár",
            "ISO": "NZD",
            "Pénznem alegység": "cent",
            "Angol név": "Pitcairn"
        },
        {
            "name": "Portugália",
            "Hosszú név": "Portugál Köztársaság",
            "Kód": "PT",
            "Főváros": "Lisszabon",
            "Melléknévi alak": "portugál, portugáliai, portugál köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Portugal"
        },
        {
            "name": "Puerto Rico ",
            "Hosszú név": "Puerto Rico",
            "Kód": "PR",
            "Főváros": "San Juan",
            "Melléknévi alak": "Puerto Ricó-i",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Puerto Rico"
        },
        {
            "name": "Réunion ",
            "Hosszú név": "Réunion",
            "Kód": "RE",
            "Főváros": "Saint-Denis",
            "Melléknévi alak": "réunioni",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Reunion"
        },
        {
            "name": "Románia",
            "Hosszú név": "Románia",
            "Kód": "RO",
            "Főváros": "Bukarest",
            "Melléknévi alak": "román, romániai",
            "Pénznem": "román lej",
            "ISO": "RON",
            "Pénznem alegység": "bani",
            "Angol név": "Romania"
        },
        {
            "name": "Ruanda",
            "Hosszú név": "Ruandai Köztársaság",
            "Kód": "RW",
            "Főváros": "Kigali",
            "Melléknévi alak": "ruandai, ruandai köztársasági",
            "Pénznem": "ruandai frank",
            "ISO": "RWF",
            "Pénznem alegység": "centime",
            "Angol név": "Rwanda"
        },
        {
            "name": "Saint Kitts és Nevis",
            "Hosszú név": "Saint Kitts és Nevis Államszövetség",
            "Kód": "KN",
            "Főváros": "Basseterre",
            "Melléknévi alak": "Saint Kitts és Nevis-i, Saint Kitts és Nevis államszövetségi",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Saint Kitts and Nevis"
        },
        {
            "name": "Saint Lucia",
            "Hosszú név": "Saint Lucia",
            "Kód": "LC",
            "Főváros": "Castries",
            "Melléknévi alak": "Saint Lucia-i",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Saint Lucia"
        },
        {
            "name": "Saint-Pierre és Miquelon",
            "Hosszú név": "Saint-Pierre és Miquelon Területi Közösség",
            "Kód": "PM",
            "Főváros": "Saint-Pierre",
            "Melléknévi alak": "Saint-Pierre és Miquelon-i, Saint-Pierre és Miquelon területi közösségi",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Saint Pierre and Miquelon"
        },
        {
            "name": "Saint Vincent és Grenadine-szigetek",
            "Hosszú név": "Saint Vincent és Grenadine-szigetek",
            "Kód": "VC",
            "Főváros": "Kingstown",
            "Melléknévi alak": "Saint Vincent és Grenadine-szigeteki",
            "Pénznem": "kelet-karibi dollár",
            "ISO": "XCD",
            "Pénznem alegység": "cent",
            "Angol név": "Saint Vincent and the Grenadines"
        },
        {
            "name": "Salamon-szigetek",
            "Hosszú név": "Salamon-szigetek",
            "Kód": "SB",
            "Főváros": "Honiara",
            "Melléknévi alak": "Salamon-szigeteki",
            "Pénznem": "Salamon-szigeteki dollár",
            "ISO": "SBD",
            "Pénznem alegység": "cent",
            "Angol név": "Solomon Islands"
        },
        {
            "name": "Salvador",
            "Hosszú név": "Salvadori Köztársaság",
            "Kód": "SV",
            "Főváros": "San Salvador",
            "Melléknévi alak": "salvadori, salvadori köztársasági",
            "Pénznem": "salvadori colón",
            "ISO": "SVC",
            "Pénznem alegység": "centavo",
            "Angol név": "El Salvador"
        },
        {
            "name": "San Marino",
            "Hosszú név": "San Marino Köztársaság",
            "Kód": "SM",
            "Főváros": "San Marino",
            "Melléknévi alak": "San Marinó-i, San Marino köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "San Marino"
        },
        {
            "name": "São Tomé és Príncipe",
            "Hosszú név": "São Tomé és Príncipe Demokratikus Köztársaság",
            "Kód": "ST",
            "Főváros": "São Tomé",
            "Melléknévi alak": "São Tomé és Príncipe-i, São Tomé és Príncipe demokratikus köztársasági",
            "Pénznem": "dobra",
            "ISO": "STD",
            "Pénznem alegység": "céntimo",
            "Angol név": "Sao Tome and Principe"
        },
        {
            "name": "Seychelle-szigetek",
            "Hosszú név": "Seychelle Köztársaság",
            "Kód": "SC",
            "Főváros": "Victoria",
            "Melléknévi alak": "seychelle-i, Seychelle-szigeteki, Seychelle köztársasági",
            "Pénznem": "seychelle-i rúpia",
            "ISO": "SCR",
            "Pénznem alegység": "cent",
            "Angol név": "Seychelles"
        },
        {
            "name": "Sierra Leone",
            "Hosszú név": "Sierra Leone Köztársaság",
            "Kód": "SL",
            "Főváros": "Freetown",
            "Melléknévi alak": "Sierra Leone-i, Sierra Leone köztársasági",
            "Pénznem": "leone",
            "ISO": "SLL",
            "Pénznem alegység": "cent",
            "Angol név": "Sierra Leone"
        },
        {
            "name": "Spanyolország",
            "Hosszú név": "Spanyol Királyság",
            "Kód": "ES",
            "Főváros": "Madrid",
            "Melléknévi alak": "spanyol, spanyolországi, spanyol királysági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Spain"
        },
        {
            "name": "Srí Lanka",
            "Hosszú név": "Srí Lanka Demokratikus Szocialista Köztársaság",
            "Kód": "LK",
            "Főváros": "Colombo",
            "Melléknévi alak": "Srí Lanka-i, Srí Lanka demokratikus szocialista köztársasági",
            "Pénznem": "Srí Lanka-i rúpia",
            "ISO": "LKR",
            "Pénznem alegység": "cent",
            "Angol név": "Sri Lanka"
        },
        {
            "name": "Suriname",
            "Hosszú név": "Suriname Köztársaság",
            "Kód": "SR",
            "Főváros": "Paramaribo",
            "Melléknévi alak": "suriname-i, Suriname köztársasági",
            "Pénznem": "suriname-i dollár",
            "ISO": "SRD",
            "Pénznem alegység": "cent",
            "Angol név": "Suriname"
        },
        {
            "name": "Svájc",
            "Hosszú név": "Svájci Államszövetség",
            "Kód": "CH",
            "Főváros": "Bern",
            "Melléknévi alak": "svájci, svájci államszövetségi",
            "Pénznem": "svájci frank",
            "ISO": "CHF",
            "Pénznem alegység": "centime",
            "Angol név": "Switzerland"
        },
        {
            "name": "Svalbard- és Jan Mayen-szigetek ",
            "Hosszú név": "Svalbard- és Jan Mayen-szigetek",
            "Kód": "SJ",
            "Főváros": "Longyearbyen",
            "Melléknévi alak": "Svalbard- és Jan Mayen-szigeteki",
            "Pénznem": "norvég korona",
            "ISO": "NOK",
            "Pénznem alegység": "őre",
            "Angol név": "Svalbard and Jan Mayen"
        },
        {
            "name": "Svédország",
            "Hosszú név": "Svéd Királyság",
            "Kód": "SE",
            "Főváros": "Stockholm",
            "Melléknévi alak": "svéd, svédországi, svéd királysági",
            "Pénznem": "svéd korona",
            "ISO": "SEK",
            "Pénznem alegység": "őre",
            "Angol név": "Sweden"
        },
        {
            "name": "Szamoa",
            "Hosszú név": "Szamoai Független Állam",
            "Kód": "WS",
            "Főváros": "Apia",
            "Melléknévi alak": "szamoai, szamoai független állami",
            "Pénznem": "tala",
            "ISO": "WST",
            "Pénznem alegység": "sene",
            "Angol név": "Samoa"
        },
        {
            "name": "Szaúd-Arábia",
            "Hosszú név": "Szaúd-arábiai Királyság",
            "Kód": "SA",
            "Főváros": "Rijád",
            "Melléknévi alak": "szaúdi, Szaúd-arábiai, Szaúd-arábiai királysági",
            "Pénznem": "szaúdi riál",
            "ISO": "SAR",
            "Pénznem alegység": "halala",
            "Angol név": "Saudi Arabia"
        },
        {
            "name": "Szenegál",
            "Hosszú név": "Szenegáli Köztársaság",
            "Kód": "SN",
            "Főváros": "Dakar",
            "Melléknévi alak": "szenegáli, szenegáli köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Senegal"
        },
        {
            "name": "Szent Ilona ",
            "Hosszú név": "Szent Ilona és kapcsolt területei",
            "Kód": "SH",
            "Főváros": "Jamestown",
            "Melléknévi alak": "Szent Ilona-i",
            "Pénznem": "Szent Ilona-i font",
            "ISO": "SHP",
            "Pénznem alegység": "penny",
            "Angol név": "Saint Helena"
        },
        {
            "name": "Szerbia",
            "Hosszú név": "Szerb Köztársaság",
            "Kód": "RS",
            "Főváros": "Belgrád",
            "Melléknévi alak": "szerb, szerbiai, szerb köztársasági",
            "Pénznem": "szerb dinár",
            "ISO": "RSD",
            "Pénznem alegység": "para",
            "Angol név": "Serbia"
        },
        {
            "name": "Szingapúr",
            "Hosszú név": "Szingapúri Köztársaság",
            "Kód": "SG",
            "Főváros": "Szingapúr",
            "Melléknévi alak": "szingapúri, szingapúri köztársasági",
            "Pénznem": "szingapúri dollár",
            "ISO": "SGD",
            "Pénznem alegység": "cent",
            "Angol név": "Singapore"
        },
        {
            "name": "Szíria",
            "Hosszú név": "Szíriai Arab Köztársaság",
            "Kód": "SY",
            "Főváros": "Damaszkusz",
            "Melléknévi alak": "szír, szíriai, szíriai arab köztársasági",
            "Pénznem": "szíriai font",
            "ISO": "SYP",
            "Pénznem alegység": "piaszter",
            "Angol név": "Syrian Arab Republic"
        },
        {
            "name": "Szlovákia",
            "Hosszú név": "Szlovák Köztársaság",
            "Kód": "SK",
            "Főváros": "Pozsony (Bratislava)",
            "Melléknévi alak": "szlovák, szlovákiai, szlovák köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Slovakia"
        },
        {
            "name": "Szlovénia",
            "Hosszú név": "Szlovén Köztársaság",
            "Kód": "SI",
            "Főváros": "Ljubljana",
            "Melléknévi alak": "szlovén, szlovéniai, szlovén köztársasági",
            "Pénznem": "euró",
            "ISO": "EUR",
            "Pénznem alegység": "cent",
            "Angol név": "Slovenia"
        },
        {
            "name": "Szomália",
            "Hosszú név": "Szomália",
            "Kód": "SO",
            "Főváros": "Mogadishu",
            "Melléknévi alak": "szomáli, szomáliai",
            "Pénznem": "szomáli shilling",
            "ISO": "SOS",
            "Pénznem alegység": "cent",
            "Angol név": "Somalia"
        },
        {
            "name": "Szudán",
            "Hosszú név": "Szudáni Köztársaság",
            "Kód": "SD",
            "Főváros": "Kartúm",
            "Melléknévi alak": "szudáni, szudáni köztársasági",
            "Pénznem": "szudáni font",
            "ISO": "SDG",
            "Pénznem alegység": "piaszter",
            "Angol név": "Sudan"
        },
        {
            "name": "Szváziföld",
            "Hosszú név": "Szváziföldi Királyság",
            "Kód": "SZ",
            "Főváros": "Mbabane",
            "Melléknévi alak": "szvázi, szváziföldi, szváziföldi királysági",
            "Pénznem": "lilangeni",
            "ISO": "SZL",
            "Pénznem alegység": "cent",
            "Angol név": "Swaziland"
        },
        {
            "name": "Tádzsikisztán",
            "Hosszú név": "Tádzsik Köztársaság",
            "Kód": "TJ",
            "Főváros": "Dusanbe",
            "Melléknévi alak": "tádzsik, tádzsikisztáni, tádzsik köztársasági",
            "Pénznem": "szomoni",
            "ISO": "TJS",
            "Pénznem alegység": "diram",
            "Angol név": "Tajikistan"
        },
        {
            "name": "Tajvan ",
            "Hosszú név": "Tajvan",
            "Kód": "TW",
            "Főváros": "Tajpej",
            "Melléknévi alak": "tajvani",
            "Pénznem": "tajvani új dollár",
            "ISO": "TWD",
            "Pénznem alegység": "fen",
            "Angol név": "Taiwan, Province of China"
        },
        {
            "name": "Tanzánia",
            "Hosszú név": "Tanzániai Egyesült Köztársaság",
            "Kód": "TZ",
            "Főváros": "Dodoma",
            "Melléknévi alak": "tanzániai, tanzániai egyesült köztársasági",
            "Pénznem": "tanzániai shilling",
            "ISO": "TZS",
            "Pénznem alegység": "cent",
            "Angol név": "Tanzania, United Republic of"
        },
        {
            "name": "Thaiföld",
            "Hosszú név": "Thaiföldi Királyság",
            "Kód": "TH",
            "Főváros": "Bangkok",
            "Melléknévi alak": "thai, thaiföldi, thaiföldi királysági",
            "Pénznem": "bát",
            "ISO": "THB",
            "Pénznem alegység": "szatang",
            "Angol név": "Thailand"
        },
        {
            "name": "Togo",
            "Hosszú név": "Togói Köztársaság",
            "Kód": "TG",
            "Főváros": "Lomé",
            "Melléknévi alak": "togói, togói köztársasági",
            "Pénznem": "CFA-frank (BCEAO) ",
            "ISO": "XOF",
            "Pénznem alegység": "centime",
            "Angol név": "Togo"
        },
        {
            "name": "Tokelau-szigetek ",
            "Hosszú név": "Tokelau-szigetek",
            "Kód": "TK",
            "Melléknévi alak": "tokelaui, Tokelau-szigeteki",
            "Pénznem": "új-zélandi dollár",
            "ISO": "NZD",
            "Pénznem alegység": "cent",
            "Angol név": "Tokelau"
        },
        {
            "name": "Tonga",
            "Hosszú név": "Tongai Királyság",
            "Kód": "TO",
            "Főváros": "Nuku’alofa",
            "Melléknévi alak": "tongai, tongai királysági",
            "Pénznem": "pa’anga",
            "ISO": "TOP",
            "Pénznem alegység": "seniti",
            "Angol név": "Tonga"
        },
        {
            "name": "Törökország",
            "Hosszú név": "Török Köztársaság",
            "Kód": "TR",
            "Főváros": "Ankara",
            "Melléknévi alak": "török, törökországi, török köztársasági",
            "Pénznem": "török líra",
            "ISO": "TRY",
            "Pénznem alegység": "kurus",
            "Angol név": "Turkey"
        },
        {
            "name": "Trinidad és Tobago",
            "Hosszú név": "Trinidad és Tobago Köztársaság",
            "Kód": "TT",
            "Főváros": "Port of Spain",
            "Melléknévi alak": "Trinidad és Tobagó-i, Trinidad és Tobago köztársasági",
            "Pénznem": "Trinidad és Tobagó-i dollár",
            "ISO": "TTD",
            "Pénznem alegység": "cent",
            "Angol név": "Trinidad and Tobago"
        },
        {
            "name": "Tunézia",
            "Hosszú név": "Tunéziai Köztársaság",
            "Kód": "TN",
            "Főváros": "Tunisz",
            "Melléknévi alak": "tunéziai, tunéziai köztársasági",
            "Pénznem": "tunéziai dinár",
            "ISO": "TND",
            "Pénznem alegység": "millime",
            "Angol név": "Tunisia"
        },
        {
            "name": "Turks- és Caicos-szigetek ",
            "Hosszú név": "Turks- és Caicos-szigetek",
            "Kód": "TC",
            "Főváros": "Cockburn Town",
            "Melléknévi alak": "Turks- és Caicos-szigeteki",
            "Pénznem": "USA-dollár",
            "ISO": "USD",
            "Pénznem alegység": "cent",
            "Angol név": "Turks and Caicos Islands"
        },
        {
            "name": "Tuvalu",
            "Hosszú név": "Tuvalu",
            "Kód": "TV",
            "Főváros": "Funafuti",
            "Melléknévi alak": "tuvalui",
            "Pénznem": "ausztrál dollár",
            "ISO": "AUD",
            "Pénznem alegység": "cent",
            "Angol név": "Tuvalu"
        },
        {
            "name": "Türkmenisztán",
            "Hosszú név": "Türkmenisztán",
            "Kód": "TM",
            "Főváros": "Asgabat",
            "Melléknévi alak": "türkmén, türkmenisztáni",
            "Pénznem": "türkmén manát",
            "ISO": "TMT",
            "Pénznem alegység": "tenge",
            "Angol név": "Turkmenistan"
        },
        {
            "name": "Uganda",
            "Hosszú név": "Ugandai Köztársaság",
            "Kód": "UG",
            "Főváros": "Kampala",
            "Melléknévi alak": "ugandai, ugandai köztársasági",
            "Pénznem": "ugandai shilling",
            "ISO": "UGX",
            "Pénznem alegység": "cent",
            "Angol név": "Uganda"
        },
        {
            "name": "Új-Kaledónia ",
            "Hosszú név": "Új-Kaledónia",
            "Kód": "NC",
            "Főváros": "Noumea",
            "Melléknévi alak": "új-kaledóniai",
            "Pénznem": "CFP-frank",
            "ISO": "XPF",
            "Pénznem alegység": "centime",
            "Angol név": "New Caledonia"
        },
        {
            "name": "Új-Zéland",
            "Hosszú név": "Új-Zéland",
            "Kód": "NZ",
            "Főváros": "Wellington",
            "Melléknévi alak": "új-zélandi",
            "Pénznem": "új-zélandi dollár",
            "ISO": "NZD",
            "Pénznem alegység": "cent",
            "Angol név": "New Zealand"
        },
        {
            "name": "Ukrajna",
            "Hosszú név": "Ukrajna",
            "Kód": "UA",
            "Főváros": "Kijev",
            "Melléknévi alak": "ukrán, ukrajnai",
            "Pénznem": "hrivnya",
            "ISO": "UAH",
            "Pénznem alegység": "kopejka",
            "Angol név": "Ukraine"
        },
        {
            "name": "Uruguay",
            "Hosszú név": "Uruguayi Keleti Köztársaság",
            "Kód": "UY",
            "Főváros": "Montevideo",
            "Melléknévi alak": "uruguayi, uruguayi keleti köztársasági",
            "Pénznem": "uruguayi peso",
            "ISO": "UYU",
            "Pénznem alegység": "centésimo",
            "Angol név": "Uruguay"
        },
        {
            "name": "Üzbegisztán",
            "Hosszú név": "Üzbég Köztársaság",
            "Kód": "UZ",
            "Főváros": "Taskent",
            "Melléknévi alak": "üzbég, üzbegisztáni, üzbég köztársasági",
            "Pénznem": "üzbég szom",
            "ISO": "UZS",
            "Pénznem alegység": "tiyin",
            "Angol név": "Uzbekistan"
        },
        {
            "name": "Vanuatu",
            "Hosszú név": "Vanuatui Köztársaság",
            "Kód": "VU",
            "Főváros": "Port Vila",
            "Melléknévi alak": "vanuatui, vanuatui köztársasági",
            "Pénznem": "vatu",
            "ISO": "VUV",
            "Angol név": "Vanuatu"
        },
        {
            "name": "Venezuela",
            "Hosszú név": "Venezuelai Bolivári Köztársaság",
            "Kód": "VE",
            "Főváros": "Caracas",
            "Melléknévi alak": "venezuelai, venezuelai bolivári köztársasági",
            "Pénznem": "bolívar fuerte",
            "ISO": "VEF",
            "Pénznem alegység": "céntimo",
            "Angol név": "Venezuela"
        },
        {
            "name": "Vietnam",
            "Hosszú név": "Vietnami Szocialista Köztársaság",
            "Kód": "VN",
            "Főváros": "Hanoi",
            "Melléknévi alak": "vietnami, vietnami szocialista köztársasági",
            "Pénznem": "dong",
            "ISO": "VND",
            "Pénznem alegység": "hao, xu",
            "Angol név": "Viet Nam"
        },
        {
            "name": "Wallis és Futuna ",
            "Hosszú név": "Wallis és Futuna",
            "Kód": "WF",
            "Főváros": "Mata-Utu",
            "Melléknévi alak": "Wallis és Futuna-i",
            "Pénznem": "CFP-frank",
            "ISO": "XPF",
            "Pénznem alegység": "centime",
            "Angol név": "Wallis and Futuna"
        },
        {
            "name": "Zambia",
            "Hosszú név": "Zambiai Köztársaság",
            "Kód": "ZM",
            "Főváros": "Lusaka",
            "Melléknévi alak": "zambiai, zambiai köztársasági",
            "Pénznem": "zambiai kwacha",
            "ISO": "ZMK",
            "Pénznem alegység": "ngwee",
            "Angol név": "Zambia"
        },
        {
            "name": "Zimbabwe",
            "Hosszú név": "Zimbabwei Köztársaság",
            "Kód": "ZW",
            "Főváros": "Harare",
            "Melléknévi alak": "zimbabwei, zimbabwei köztársasági",
            "Pénznem": "zimbabwei dollár",
            "ISO": "ZWL",
            "Pénznem alegység": "cent",
            "Angol név": "Zimbabwe"
        },
        {
            "name": "Zöld-foki-szigetek",
            "Hosszú név": "Zöld-foki Köztársaság",
            "Kód": "CV",
            "Főváros": "Praia",
            "Melléknévi alak": "zöld-foki, zöld-foki-szigeteki, zöld-foki köztársasági",
            "Pénznem": "zöld-foki köztársasági escudo",
            "ISO": "CVE",
            "Pénznem alegység": "centavo",
            "Angol név": "Cape Verde"
        }
    ],
    "megyek": [
        {
            "Kód": "BK",
            "name": "Bács-Kiskun"
        },
        {
            "Kód": "BE",
            "name": "Békés"
        },
        {
            "Kód": "BA",
            "name": "Baranya"
        },
        {
            "Kód": "BZ",
            "name": "Borsod-Abaúj-Zemplén"
        },
        {
            "Kód": "BU",
            "name": "Budapest"
        },
        {
            "Kód": "CS",
            "name": "Csongrád"
        },
        {
            "Kód": "FE",
            "name": "Fejér"
        },
        {
            "Kód": "GS",
            "name": "Győr-Moson-Sopron"
        },
        {
            "Kód": "HB",
            "name": "Hajdú-Bihar"
        },
        {
            "Kód": "HE",
            "name": "Heves"
        },
        {
            "Kód": "JN",
            "name": "Jász-Nagykun-Szolnok"
        },
        {
            "Kód": "KE",
            "name": "Komárom-Esztergom"
        },
        {
            "Kód": "NO",
            "name": "Nógrád"
        },
        {
            "Kód": "PE",
            "name": "Pest"
        },
        {
            "Kód": "SO",
            "name": "Somogy"
        },
        {
            "Kód": "SZ",
            "name": "Szabolcs-Szatmár-Bereg"
        },
        {
            "Kód": "TO",
            "name": "Tolna"
        },
        {
            "Kód": "VA",
            "name": "Vas"
        },
        {
            "Kód": "VE",
            "name": "Veszprém"
        },
        {
            "Kód": "ZA",
            "name": "Zala"
        }
    ],
    telepulesek: [
        {
            "Ir. szám": "1011",
            "name": "Budapest",
            "Megyekód": "BU",
            "Lat": "47.5011",
            "Long": "19.0469",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1011",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.5011",
            "Long": "19.0469",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1012",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1013",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.4961",
            "Long": "19.0309",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1014",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.5034",
            "Long": "19.0349",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1015",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1016",
            "name": "Budapest I. kerület",
            "Megyekód": "BU",
            "Lat": "47.48",
            "Long": "19.02",
            "KSH kód": "09566",
            "Jogállás": "2",
            "Terület": "341",
            "Népesség": "24728",
            "Lakások": "16600"
        },
        {
            "Ir. szám": "1021",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.496",
            "Long": "19.0771",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1022",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1023",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.521",
            "Long": "19.0347",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1024",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.507",
            "Long": "19.0569",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1025",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.403",
            "Long": "19.0915",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1026",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.5117",
            "Long": "19.0131",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1027",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.5083",
            "Long": "19.0386",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1028",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1029",
            "name": "Budapest II. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "03179",
            "Jogállás": "2",
            "Terület": "3634",
            "Népesség": "88729",
            "Lakások": "47668"
        },
        {
            "Ir. szám": "1031",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5729",
            "Long": "19.0538",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1032",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1033",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1034",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1035",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1036",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5328",
            "Long": "19.0419",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1037",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1038",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5915",
            "Long": "19.0486",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1039",
            "name": "Budapest III. kerület",
            "Megyekód": "BU",
            "Lat": "47.5562",
            "Long": "19.0684",
            "KSH kód": "18069",
            "Jogállás": "2",
            "Terület": "3970",
            "Népesség": "123723",
            "Lakások": "61137"
        },
        {
            "Ir. szám": "1041",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1042",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1043",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1044",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1045",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1046",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1047",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1048",
            "name": "Budapest IV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "05467",
            "Jogállás": "2",
            "Terület": "1882",
            "Népesség": "98374",
            "Lakások": "47019"
        },
        {
            "Ir. szám": "1051",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.4986",
            "Long": "19.0499",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1052",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.4968",
            "Long": "19.0555",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1053",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.4839",
            "Long": "19.0597",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1054",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.5068",
            "Long": "19.0526",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1055",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1056",
            "name": "Budapest V. kerület",
            "Megyekód": "BU",
            "Lat": "47.4926",
            "Long": "19.0516",
            "KSH kód": "13392",
            "Jogállás": "2",
            "Terület": "259",
            "Népesség": "27283",
            "Lakások": "19250"
        },
        {
            "Ir. szám": "1061",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4996",
            "Long": "19.0574",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1062",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.525",
            "Long": "19.0766",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1063",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5102",
            "Long": "19.0702",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1064",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5062",
            "Long": "19.069",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1065",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4767",
            "Long": "19.0851",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1066",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5075",
            "Long": "19.0589",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1067",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5077",
            "Long": "19.0602",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1068",
            "name": "Budapest VI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5096",
            "Long": "19.0717",
            "KSH kód": "16586",
            "Jogállás": "2",
            "Terület": "238",
            "Népesség": "42120",
            "Lakások": "26049"
        },
        {
            "Ir. szám": "1071",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1072",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4997",
            "Long": "19.0716",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1073",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4992",
            "Long": "19.0718",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1074",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4768",
            "Long": "19.071",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1075",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4971",
            "Long": "19.0566",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1076",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1077",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5007",
            "Long": "19.0786",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1078",
            "name": "Budapest VII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5034",
            "Long": "19.0821",
            "KSH kód": "29744",
            "Jogállás": "2",
            "Terület": "209",
            "Népesség": "62530",
            "Lakások": "36577"
        },
        {
            "Ir. szám": "1081",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4946",
            "Long": "19.0776",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1082",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.514",
            "Long": "19.0788",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1083",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1084",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1085",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.497",
            "Long": "19.0756",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1086",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1087",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4996",
            "Long": "19.0817",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1088",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4902",
            "Long": "19.0905",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1089",
            "name": "Budapest VIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4935",
            "Long": "19.0882",
            "KSH kód": "25405",
            "Jogállás": "2",
            "Terület": "685",
            "Népesség": "82222",
            "Lakások": "41816"
        },
        {
            "Ir. szám": "1091",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1092",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1093",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.4919",
            "Long": "19.0691",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1094",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.481",
            "Long": "19.0724",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1095",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.4845",
            "Long": "19.0492",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1096",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1097",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.4892",
            "Long": "19.074",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1098",
            "name": "Budapest IX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29586",
            "Jogállás": "2",
            "Terület": "1253",
            "Népesség": "61576",
            "Lakások": "38982"
        },
        {
            "Ir. szám": "1101",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1102",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1103",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1104",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1105",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1106",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5032",
            "Long": "19.1367",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1107",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1108",
            "name": "Budapest X. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10700",
            "Jogállás": "2",
            "Terület": "3249",
            "Népesség": "79270",
            "Lakások": "37353"
        },
        {
            "Ir. szám": "1111",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.482",
            "Long": "19.0536",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1112",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4734",
            "Long": "19.0076",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1113",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4748",
            "Long": "19.037",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1114",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1115",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1116",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.444",
            "Long": "19.041",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1117",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.501",
            "Long": "19.038",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1118",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4846",
            "Long": "19.0334",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1119",
            "name": "Budapest XI. kerület",
            "Megyekód": "BU",
            "Lat": "47.4571",
            "Long": "19.0402",
            "KSH kód": "14216",
            "Jogállás": "2",
            "Terület": "3349",
            "Népesség": "139049",
            "Lakások": "73690"
        },
        {
            "Ir. szám": "1121",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5014",
            "Long": "19.0078",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1122",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1123",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4942",
            "Long": "19.024",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1124",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1125",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1126",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "1131",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1132",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.4968",
            "Long": "19.0776",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1133",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5223",
            "Long": "19.0564",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1134",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5243",
            "Long": "19.0647",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1135",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5354",
            "Long": "19.0818",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1136",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1137",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5086",
            "Long": "19.0624",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1138",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5239",
            "Long": "19.0455",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1139",
            "name": "Budapest XIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24299",
            "Jogállás": "2",
            "Terület": "1344",
            "Népesség": "113531",
            "Lakások": "70249"
        },
        {
            "Ir. szám": "1141",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.4992",
            "Long": "19.0688",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1142",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.515",
            "Long": "19.0936",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1143",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5139",
            "Long": "19.1268",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1144",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1145",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5141",
            "Long": "19.1038",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1146",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5067",
            "Long": "19.0836",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1147",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1148",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.504",
            "Long": "19.1192",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1149",
            "name": "Budapest XIV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5139",
            "Long": "19.1268",
            "KSH kód": "16337",
            "Jogállás": "2",
            "Terület": "1813",
            "Népesség": "120148",
            "Lakások": "70146"
        },
        {
            "Ir. szám": "1151",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1152",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5481",
            "Long": "19.1306",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1153",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1154",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1155",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1156",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1157",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1158",
            "name": "Budapest XV. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "11314",
            "Jogállás": "2",
            "Terület": "2694",
            "Népesség": "80218",
            "Lakások": "38177"
        },
        {
            "Ir. szám": "1161",
            "name": "Budapest XVI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "08208",
            "Jogállás": "2",
            "Terület": "3351",
            "Népesség": "68484",
            "Lakások": "29383"
        },
        {
            "Ir. szám": "1162",
            "name": "Budapest XVI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "08208",
            "Jogállás": "2",
            "Terület": "3351",
            "Népesség": "68484",
            "Lakások": "29383"
        },
        {
            "Ir. szám": "1163",
            "name": "Budapest XVI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "08208",
            "Jogállás": "2",
            "Terület": "3351",
            "Népesség": "68484",
            "Lakások": "29383"
        },
        {
            "Ir. szám": "1164",
            "name": "Budapest XVI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "08208",
            "Jogállás": "2",
            "Terület": "3351",
            "Népesség": "68484",
            "Lakások": "29383"
        },
        {
            "Ir. szám": "1165",
            "name": "Budapest XVI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "08208",
            "Jogállás": "2",
            "Terület": "3351",
            "Népesség": "68484",
            "Lakások": "29383"
        },
        {
            "Ir. szám": "1171",
            "name": "Budapest XVII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "02112",
            "Jogállás": "2",
            "Terület": "5482",
            "Népesség": "78250",
            "Lakások": "30971"
        },
        {
            "Ir. szám": "1172",
            "name": "Budapest XVII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "02112",
            "Jogállás": "2",
            "Terület": "5482",
            "Népesség": "78250",
            "Lakások": "30971"
        },
        {
            "Ir. szám": "1173",
            "name": "Budapest XVII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5231",
            "Long": "19.0554",
            "KSH kód": "02112",
            "Jogállás": "2",
            "Terület": "5482",
            "Népesség": "78250",
            "Lakások": "30971"
        },
        {
            "Ir. szám": "1174",
            "name": "Budapest XVII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "02112",
            "Jogállás": "2",
            "Terület": "5482",
            "Népesség": "78250",
            "Lakások": "30971"
        },
        {
            "Ir. szám": "1181",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1182",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1183",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1184",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1185",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1186",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1188",
            "name": "Budapest XVIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "29285",
            "Jogállás": "2",
            "Terület": "3860",
            "Népesség": "93225",
            "Lakások": "42467"
        },
        {
            "Ir. szám": "1191",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1192",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1193",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1194",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1195",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1196",
            "name": "Budapest XIX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "04011",
            "Jogállás": "2",
            "Terület": "938",
            "Népesség": "61610",
            "Lakások": "27225"
        },
        {
            "Ir. szám": "1201",
            "name": "Budapest XX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "06026",
            "Jogállás": "2",
            "Terület": "1218",
            "Népesség": "63371",
            "Lakások": "30299"
        },
        {
            "Ir. szám": "1202",
            "name": "Budapest XX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "06026",
            "Jogállás": "2",
            "Terület": "1218",
            "Népesség": "63371",
            "Lakások": "30299"
        },
        {
            "Ir. szám": "1203",
            "name": "Budapest XX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "06026",
            "Jogállás": "2",
            "Terület": "1218",
            "Népesség": "63371",
            "Lakások": "30299"
        },
        {
            "Ir. szám": "1204",
            "name": "Budapest XX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "06026",
            "Jogállás": "2",
            "Terület": "1218",
            "Népesség": "63371",
            "Lakások": "30299"
        },
        {
            "Ir. szám": "1205",
            "name": "Budapest XX. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "06026",
            "Jogállás": "2",
            "Terület": "1218",
            "Népesség": "63371",
            "Lakások": "30299"
        },
        {
            "Ir. szám": "1211",
            "name": "Budapest XXI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "13189",
            "Jogállás": "2",
            "Terület": "2575",
            "Népesség": "76339",
            "Lakások": "32634"
        },
        {
            "Ir. szám": "1212",
            "name": "Budapest XXI. kerület",
            "Megyekód": "BU",
            "Lat": "47.484",
            "Long": "19.123",
            "KSH kód": "13189",
            "Jogállás": "2",
            "Terület": "2575",
            "Népesség": "76339",
            "Lakások": "32634"
        },
        {
            "Ir. szám": "1213",
            "name": "Budapest XXI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "13189",
            "Jogállás": "2",
            "Terület": "2575",
            "Népesség": "76339",
            "Lakások": "32634"
        },
        {
            "Ir. szám": "1214",
            "name": "Budapest XXI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "13189",
            "Jogállás": "2",
            "Terület": "2575",
            "Népesség": "76339",
            "Lakások": "32634"
        },
        {
            "Ir. szám": "1215",
            "name": "Budapest XXI. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "13189",
            "Jogállás": "2",
            "Terület": "2575",
            "Népesség": "76339",
            "Lakások": "32634"
        },
        {
            "Ir. szám": "1221",
            "name": "Budapest XXII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10214",
            "Jogállás": "2",
            "Terület": "3425",
            "Népesség": "50499",
            "Lakások": "21248"
        },
        {
            "Ir. szám": "1222",
            "name": "Budapest XXII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10214",
            "Jogállás": "2",
            "Terület": "3425",
            "Népesség": "50499",
            "Lakások": "21248"
        },
        {
            "Ir. szám": "1223",
            "name": "Budapest XXII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10214",
            "Jogállás": "2",
            "Terület": "3425",
            "Népesség": "50499",
            "Lakások": "21248"
        },
        {
            "Ir. szám": "1224",
            "name": "Budapest XXII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10214",
            "Jogállás": "2",
            "Terület": "3425",
            "Népesség": "50499",
            "Lakások": "21248"
        },
        {
            "Ir. szám": "1225",
            "name": "Budapest XXII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "10214",
            "Jogállás": "2",
            "Terület": "3425",
            "Népesség": "50499",
            "Lakások": "21248"
        },
        {
            "Ir. szám": "1237",
            "name": "Budapest XXIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "34139",
            "Jogállás": "2",
            "Terület": "4077",
            "Népesség": "20387",
            "Lakások": "8592"
        },
        {
            "Ir. szám": "1238",
            "name": "Budapest XXIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "34139",
            "Jogállás": "2",
            "Terület": "4077",
            "Népesség": "20387",
            "Lakások": "8592"
        },
        {
            "Ir. szám": "1239",
            "name": "Budapest XXIII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "34139",
            "Jogállás": "2",
            "Terület": "4077",
            "Népesség": "20387",
            "Lakások": "8592"
        },
        {
            "Ir. szám": "1529",
            "name": "Budapest XII. kerület",
            "Megyekód": "BU",
            "Lat": "47.5",
            "Long": "19.0833",
            "KSH kód": "24697",
            "Jogállás": "2",
            "Terület": "2667",
            "Népesség": "56544",
            "Lakások": "33468"
        },
        {
            "Ir. szám": "2000",
            "name": "Szentendre",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.0833",
            "KSH kód": "15440",
            "Jogállás": "4",
            "Terület": "4382",
            "Népesség": "25401",
            "Lakások": "9519"
        },
        {
            "Ir. szám": "2001",
            "name": "Szentendre",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.0833",
            "KSH kód": "15440",
            "Jogállás": "4",
            "Terület": "4382",
            "Népesség": "25401",
            "Lakások": "9519"
        },
        {
            "Ir. szám": "2002",
            "name": "Szentendre",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.0833",
            "KSH kód": "15440",
            "Jogállás": "4",
            "Terület": "4382",
            "Népesség": "25401",
            "Lakások": "9519"
        },
        {
            "Ir. szám": "2003",
            "name": "Szentendre",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.0833",
            "KSH kód": "15440",
            "Jogállás": "4",
            "Terület": "4382",
            "Népesség": "25401",
            "Lakások": "9519"
        },
        {
            "Ir. szám": "2009",
            "name": "Pilisszentlászló",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19",
            "KSH kód": "08457",
            "Jogállás": "6",
            "Terület": "1775",
            "Népesség": "1123",
            "Lakások": "427"
        },
        {
            "Ir. szám": "2011",
            "name": "Budakalász",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.05",
            "KSH kód": "23463",
            "Jogállás": "5",
            "Terület": "1517",
            "Népesség": "10299",
            "Lakások": "3638"
        },
        {
            "Ir. szám": "2012",
            "name": "Budakalász",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.05",
            "KSH kód": "23463",
            "Jogállás": "5",
            "Terület": "1517",
            "Népesség": "10299",
            "Lakások": "3638"
        },
        {
            "Ir. szám": "2013",
            "name": "Budakalász",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.05",
            "KSH kód": "23463",
            "Jogállás": "5",
            "Terület": "1517",
            "Népesség": "10299",
            "Lakások": "3638"
        },
        {
            "Ir. szám": "2014",
            "name": "Csobánka",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "18.9667",
            "KSH kód": "06822",
            "Jogállás": "6",
            "Terület": "2276",
            "Népesség": "3169",
            "Lakások": "989"
        },
        {
            "Ir. szám": "2015",
            "name": "Szigetmonostor",
            "Megyekód": "PE",
            "Lat": "47.6833",
            "Long": "19.1167",
            "KSH kód": "26213",
            "Jogállás": "6",
            "Terület": "2351",
            "Népesség": "2213",
            "Lakások": "708"
        },
        {
            "Ir. szám": "2016",
            "name": "Leányfalu",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19.0833",
            "KSH kód": "30809",
            "Jogállás": "5",
            "Terület": "1537",
            "Népesség": "3475",
            "Lakások": "1153"
        },
        {
            "Ir. szám": "2017",
            "name": "Pócsmegyer",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19.1",
            "KSH kód": "04905",
            "Jogállás": "6",
            "Terület": "1308",
            "Népesség": "1733",
            "Lakások": "566"
        },
        {
            "Ir. szám": "2019",
            "name": "Pomáz",
            "Megyekód": "PE",
            "Lat": "47.65",
            "Long": "19.0333",
            "KSH kód": "06372",
            "Jogállás": "4",
            "Terület": "4903",
            "Népesség": "16677",
            "Lakások": "5685"
        },
        {
            "Ir. szám": "2021",
            "name": "Tahitótfalu",
            "Megyekód": "PE",
            "Lat": "47.75",
            "Long": "19.1",
            "KSH kód": "31963",
            "Jogállás": "6",
            "Terület": "3917",
            "Népesség": "5404",
            "Lakások": "1971"
        },
        {
            "Ir. szám": "2022",
            "name": "Tahitótfalu",
            "Megyekód": "PE",
            "Lat": "47.75",
            "Long": "19.1",
            "KSH kód": "31963",
            "Jogállás": "6",
            "Terület": "3917",
            "Népesség": "5404",
            "Lakások": "1971"
        },
        {
            "Ir. szám": "2023",
            "name": "Dunabogdány",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "19.0333",
            "KSH kód": "25362",
            "Jogállás": "6",
            "Terület": "2550",
            "Népesség": "3143",
            "Lakások": "1116"
        },
        {
            "Ir. szám": "2024",
            "name": "Kisoroszi",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "19.0167",
            "KSH kód": "29850",
            "Jogállás": "6",
            "Terület": "1094",
            "Népesség": "938",
            "Lakások": "411"
        },
        {
            "Ir. szám": "2025",
            "name": "Visegrád",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "18.9833",
            "KSH kód": "28413",
            "Jogállás": "4",
            "Terület": "3327",
            "Népesség": "1831",
            "Lakások": "718"
        },
        {
            "Ir. szám": "2026",
            "name": "Visegrád",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "18.9833",
            "KSH kód": "28413",
            "Jogállás": "4",
            "Terület": "3327",
            "Népesség": "1831",
            "Lakások": "718"
        },
        {
            "Ir. szám": "2027",
            "name": "Dömös",
            "Megyekód": "KE",
            "Lat": "47.7667",
            "Long": "18.9167",
            "KSH kód": "06594",
            "Jogállás": "6",
            "Terület": "2398",
            "Népesség": "1167",
            "Lakások": "486"
        },
        {
            "Ir. szám": "2028",
            "name": "Pilismarót",
            "Megyekód": "KE",
            "Lat": "47.7833",
            "Long": "18.8833",
            "KSH kód": "14669",
            "Jogállás": "6",
            "Terület": "4459",
            "Népesség": "2072",
            "Lakások": "738"
        },
        {
            "Ir. szám": "2030",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2031",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2032",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2033",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2035",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2036",
            "name": "Érd",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9333",
            "KSH kód": "30988",
            "Jogállás": "3",
            "Terület": "6054",
            "Népesség": "63669",
            "Lakások": "21993"
        },
        {
            "Ir. szám": "2038",
            "name": "Sóskút",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "18.8333",
            "KSH kód": "06840",
            "Jogállás": "6",
            "Terület": "2767",
            "Népesség": "3160",
            "Lakások": "1109"
        },
        {
            "Ir. szám": "2039",
            "name": "Pusztazámor",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "18.7833",
            "KSH kód": "15583",
            "Jogállás": "6",
            "Terület": "927",
            "Népesség": "1166",
            "Lakások": "437"
        },
        {
            "Ir. szám": "2040",
            "name": "Budaörs",
            "Megyekód": "PE",
            "Lat": "47.4531",
            "Long": "18.9602",
            "KSH kód": "23278",
            "Jogállás": "4",
            "Terület": "2359",
            "Népesség": "28272",
            "Lakások": "10269"
        },
        {
            "Ir. szám": "2041",
            "name": "Budaörs",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "18.9667",
            "KSH kód": "23278",
            "Jogállás": "4",
            "Terület": "2359",
            "Népesség": "28272",
            "Lakások": "10269"
        },
        {
            "Ir. szám": "2042",
            "name": "Budaörs",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "18.9667",
            "KSH kód": "23278",
            "Jogállás": "4",
            "Terület": "2359",
            "Népesség": "28272",
            "Lakások": "10269"
        },
        {
            "Ir. szám": "2043",
            "name": "Budaörs",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "18.9667",
            "KSH kód": "23278",
            "Jogállás": "4",
            "Terület": "2359",
            "Népesség": "28272",
            "Lakások": "10269"
        },
        {
            "Ir. szám": "2044",
            "name": "Budaörs",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "18.9667",
            "KSH kód": "23278",
            "Jogállás": "4",
            "Terület": "2359",
            "Népesség": "28272",
            "Lakások": "10269"
        },
        {
            "Ir. szám": "2045",
            "name": "Törökbálint",
            "Megyekód": "PE",
            "Lat": "47.4333",
            "Long": "18.9167",
            "KSH kód": "06859",
            "Jogállás": "4",
            "Terület": "2940",
            "Népesség": "13010",
            "Lakások": "4507"
        },
        {
            "Ir. szám": "2046",
            "name": "Törökbálint",
            "Megyekód": "PE",
            "Lat": "47.4333",
            "Long": "18.9167",
            "KSH kód": "06859",
            "Jogállás": "4",
            "Terület": "2940",
            "Népesség": "13010",
            "Lakások": "4507"
        },
        {
            "Ir. szám": "2047",
            "name": "Törökbálint",
            "Megyekód": "PE",
            "Lat": "47.4333",
            "Long": "18.9167",
            "KSH kód": "06859",
            "Jogállás": "4",
            "Terület": "2940",
            "Népesség": "13010",
            "Lakások": "4507"
        },
        {
            "Ir. szám": "2049",
            "name": "Diósd",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "18.95",
            "KSH kód": "24013",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "8612",
            "Lakások": "3105"
        },
        {
            "Ir. szám": "2050",
            "name": "Diósd",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "18.95",
            "KSH kód": "24013",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "8612",
            "Lakások": "3105"
        },
        {
            "Ir. szám": "2051",
            "name": "Biatorbágy",
            "Megyekód": "PE",
            "Lat": "47.4667",
            "Long": "18.8167",
            "KSH kód": "08891",
            "Jogállás": "4",
            "Terület": "4380",
            "Népesség": "11769",
            "Lakások": "4082"
        },
        {
            "Ir. szám": "2052",
            "name": "Biatorbágy",
            "Megyekód": "PE",
            "Lat": "47.4667",
            "Long": "18.8167",
            "KSH kód": "08891",
            "Jogállás": "4",
            "Terület": "4380",
            "Népesség": "11769",
            "Lakások": "4082"
        },
        {
            "Ir. szám": "2053",
            "name": "Herceghalom",
            "Megyekód": "PE",
            "Lat": "47.5",
            "Long": "18.75",
            "KSH kód": "33552",
            "Jogállás": "6",
            "Terület": "734",
            "Népesség": "1883",
            "Lakások": "616"
        },
        {
            "Ir. szám": "2055",
            "name": "Herceghalom",
            "Megyekód": "PE",
            "Lat": "47.5",
            "Long": "18.75",
            "KSH kód": "33552",
            "Jogállás": "6",
            "Terület": "734",
            "Népesség": "1883",
            "Lakások": "616"
        },
        {
            "Ir. szám": "2056",
            "name": "Herceghalom",
            "Megyekód": "PE",
            "Lat": "47.5",
            "Long": "18.75",
            "KSH kód": "33552",
            "Jogállás": "6",
            "Terület": "734",
            "Népesség": "1883",
            "Lakások": "616"
        },
        {
            "Ir. szám": "2060",
            "name": "Bicske",
            "Megyekód": "FE",
            "Lat": "47.4833",
            "Long": "18.6333",
            "KSH kód": "10481",
            "Jogállás": "4",
            "Terület": "7708",
            "Népesség": "11552",
            "Lakások": "3997"
        },
        {
            "Ir. szám": "2061",
            "name": "Bicske",
            "Megyekód": "FE",
            "Lat": "47.4833",
            "Long": "18.6333",
            "KSH kód": "10481",
            "Jogállás": "4",
            "Terület": "7708",
            "Népesség": "11552",
            "Lakások": "3997"
        },
        {
            "Ir. szám": "2063",
            "name": "Bicske",
            "Megyekód": "FE",
            "Lat": "47.4833",
            "Long": "18.6333",
            "KSH kód": "10481",
            "Jogállás": "4",
            "Terület": "7708",
            "Népesség": "11552",
            "Lakások": "3997"
        },
        {
            "Ir. szám": "2064",
            "name": "Csabdi",
            "Megyekód": "FE",
            "Lat": "47.5167",
            "Long": "18.6167",
            "KSH kód": "30544",
            "Jogállás": "6",
            "Terület": "1702",
            "Népesség": "1205",
            "Lakások": "432"
        },
        {
            "Ir. szám": "2065",
            "name": "Mány",
            "Megyekód": "FE",
            "Lat": "47.5333",
            "Long": "18.6667",
            "KSH kód": "23490",
            "Jogállás": "6",
            "Terület": "4472",
            "Népesség": "2372",
            "Lakások": "781"
        },
        {
            "Ir. szám": "2066",
            "name": "Újbarok",
            "Megyekód": "FE",
            "Lat": "47.4833",
            "Long": "18.5667",
            "KSH kód": "17482",
            "Jogállás": "6",
            "Terület": "149",
            "Népesség": "415",
            "Lakások": "143"
        },
        {
            "Ir. szám": "2066",
            "name": "Szár",
            "Megyekód": "FE",
            "Lat": "47.4833",
            "Long": "18.5167",
            "KSH kód": "19549",
            "Jogállás": "6",
            "Terület": "2263",
            "Népesség": "1675",
            "Lakások": "580"
        },
        {
            "Ir. szám": "2067",
            "name": "Szárliget",
            "Megyekód": "FE",
            "Lat": "47.4952",
            "Long": "18.6095",
            "KSH kód": "33491",
            "Jogállás": "6",
            "Terület": "1456",
            "Népesség": "2328",
            "Lakások": "809"
        },
        {
            "Ir. szám": "2071",
            "name": "Páty",
            "Megyekód": "PE",
            "Lat": "47.5167",
            "Long": "18.8333",
            "KSH kód": "15024",
            "Jogállás": "6",
            "Terület": "3930",
            "Népesség": "6773",
            "Lakások": "2011"
        },
        {
            "Ir. szám": "2072",
            "name": "Zsámbék",
            "Megyekód": "PE",
            "Lat": "47.55",
            "Long": "18.7167",
            "KSH kód": "25034",
            "Jogállás": "5",
            "Terület": "3366",
            "Népesség": "5284",
            "Lakások": "1640"
        },
        {
            "Ir. szám": "2073",
            "name": "Tök",
            "Megyekód": "PE",
            "Lat": "47.5667",
            "Long": "18.7333",
            "KSH kód": "24527",
            "Jogállás": "6",
            "Terület": "2473",
            "Népesség": "1384",
            "Lakások": "532"
        },
        {
            "Ir. szám": "2074",
            "name": "Perbál",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "18.7667",
            "KSH kód": "28185",
            "Jogállás": "6",
            "Terület": "2565",
            "Népesség": "2192",
            "Lakások": "748"
        },
        {
            "Ir. szám": "2080",
            "name": "Pilisjászfalu",
            "Megyekód": "PE",
            "Lat": "47.6042",
            "Long": "18.8521",
            "KSH kód": "34148",
            "Jogállás": "6",
            "Terület": "697",
            "Népesség": "1435",
            "Lakások": "467"
        },
        {
            "Ir. szám": "2081",
            "name": "Piliscsaba",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.8333",
            "KSH kód": "07144",
            "Jogállás": "5",
            "Terület": "2557",
            "Népesség": "7873",
            "Lakások": "2468"
        },
        {
            "Ir. szám": "2082",
            "name": "Piliscsaba",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.8333",
            "KSH kód": "07144",
            "Jogállás": "5",
            "Terület": "2557",
            "Népesség": "7873",
            "Lakások": "2468"
        },
        {
            "Ir. szám": "2083",
            "name": "Solymár",
            "Megyekód": "PE",
            "Lat": "47.5833",
            "Long": "18.9333",
            "KSH kód": "07384",
            "Jogállás": "5",
            "Terület": "1786",
            "Népesség": "10043",
            "Lakások": "3711"
        },
        {
            "Ir. szám": "2084",
            "name": "Pilisszentiván",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.9",
            "KSH kód": "11396",
            "Jogállás": "6",
            "Terület": "812",
            "Népesség": "4303",
            "Lakások": "1589"
        },
        {
            "Ir. szám": "2085",
            "name": "Pilisvörösvár",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.9167",
            "KSH kód": "14340",
            "Jogállás": "4",
            "Terület": "2430",
            "Népesség": "13463",
            "Lakások": "4565"
        },
        {
            "Ir. szám": "2086",
            "name": "Tinnye",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.7833",
            "KSH kód": "07108",
            "Jogállás": "6",
            "Terület": "1610",
            "Népesség": "1576",
            "Lakások": "559"
        },
        {
            "Ir. szám": "2087",
            "name": "Tinnye",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.7833",
            "KSH kód": "07108",
            "Jogállás": "6",
            "Terület": "1610",
            "Népesség": "1576",
            "Lakások": "559"
        },
        {
            "Ir. szám": "2089",
            "name": "Telki",
            "Megyekód": "PE",
            "Lat": "47.55",
            "Long": "18.8333",
            "KSH kód": "08280",
            "Jogállás": "6",
            "Terület": "1047",
            "Népesség": "3448",
            "Lakások": "1062"
        },
        {
            "Ir. szám": "2090",
            "name": "Remeteszőlős",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "18.8981",
            "KSH kód": "34315",
            "Jogállás": "6",
            "Terület": "56",
            "Népesség": "651",
            "Lakások": "206"
        },
        {
            "Ir. szám": "2091",
            "name": "Etyek",
            "Megyekód": "FE",
            "Lat": "47.45",
            "Long": "18.75",
            "KSH kód": "02316",
            "Jogállás": "6",
            "Terület": "5327",
            "Népesség": "4236",
            "Lakások": "1469"
        },
        {
            "Ir. szám": "2092",
            "name": "Budakeszi",
            "Megyekód": "PE",
            "Lat": "47.5167",
            "Long": "18.9333",
            "KSH kód": "12052",
            "Jogállás": "4",
            "Terület": "3710",
            "Népesség": "13933",
            "Lakások": "5382"
        },
        {
            "Ir. szám": "2093",
            "name": "Budajenő",
            "Megyekód": "PE",
            "Lat": "47.55",
            "Long": "18.8",
            "KSH kód": "03407",
            "Jogállás": "6",
            "Terület": "1242",
            "Népesség": "1701",
            "Lakások": "570"
        },
        {
            "Ir. szám": "2094",
            "name": "Nagykovácsi",
            "Megyekód": "PE",
            "Lat": "47.5833",
            "Long": "18.8833",
            "KSH kód": "09991",
            "Jogállás": "5",
            "Terület": "3001",
            "Népesség": "6310",
            "Lakások": "2175"
        },
        {
            "Ir. szám": "2095",
            "name": "Pilisszántó",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "18.9",
            "KSH kód": "05290",
            "Jogállás": "6",
            "Terület": "1596",
            "Népesség": "2467",
            "Lakások": "817"
        },
        {
            "Ir. szám": "2096",
            "name": "Üröm",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.0167",
            "KSH kód": "11934",
            "Jogállás": "6",
            "Terület": "668",
            "Népesség": "6937",
            "Lakások": "2429"
        },
        {
            "Ir. szám": "2097",
            "name": "Pilisborosjenő",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19",
            "KSH kód": "29601",
            "Jogállás": "6",
            "Terület": "927",
            "Népesség": "3453",
            "Lakások": "1226"
        },
        {
            "Ir. szám": "2098",
            "name": "Pilisszentkereszt",
            "Megyekód": "PE",
            "Lat": "47.7",
            "Long": "18.9",
            "KSH kód": "18731",
            "Jogállás": "6",
            "Terület": "1721",
            "Népesség": "2270",
            "Lakások": "788"
        },
        {
            "Ir. szám": "2099",
            "name": "Dobogókő",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "18.9",
            "KSH kód": "18731",
            "Jogállás": "6",
            "Terület": "1721",
            "Népesség": "2270",
            "Lakások": "788"
        },
        {
            "Ir. szám": "2100",
            "name": "Gödöllő",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.3667",
            "KSH kód": "32559",
            "Jogállás": "4",
            "Terület": "6197",
            "Népesség": "33575",
            "Lakások": "12070"
        },
        {
            "Ir. szám": "2101",
            "name": "Gödöllő",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.3667",
            "KSH kód": "32559",
            "Jogállás": "4",
            "Terület": "6197",
            "Népesség": "33575",
            "Lakások": "12070"
        },
        {
            "Ir. szám": "2102",
            "name": "Gödöllő",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.3667",
            "KSH kód": "32559",
            "Jogállás": "4",
            "Terület": "6197",
            "Népesség": "33575",
            "Lakások": "12070"
        },
        {
            "Ir. szám": "2103",
            "name": "Gödöllő",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.3667",
            "KSH kód": "32559",
            "Jogállás": "4",
            "Terület": "6197",
            "Népesség": "33575",
            "Lakások": "12070"
        },
        {
            "Ir. szám": "2105",
            "name": "Gödöllő",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.3667",
            "KSH kód": "32559",
            "Jogállás": "4",
            "Terület": "6197",
            "Népesség": "33575",
            "Lakások": "12070"
        },
        {
            "Ir. szám": "2111",
            "name": "Szada",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.3167",
            "KSH kód": "21458",
            "Jogállás": "6",
            "Terület": "1671",
            "Népesség": "4355",
            "Lakások": "1630"
        },
        {
            "Ir. szám": "2112",
            "name": "Veresegyház",
            "Megyekód": "PE",
            "Lat": "47.65",
            "Long": "19.2833",
            "KSH kód": "18342",
            "Jogállás": "4",
            "Terület": "2856",
            "Népesség": "15633",
            "Lakások": "5149"
        },
        {
            "Ir. szám": "2113",
            "name": "Erdőkertes",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.3167",
            "KSH kód": "13480",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "7437",
            "Lakások": "2591"
        },
        {
            "Ir. szám": "2114",
            "name": "Valkó",
            "Megyekód": "PE",
            "Lat": "47.5667",
            "Long": "19.5167",
            "KSH kód": "17598",
            "Jogállás": "5",
            "Terület": "3759",
            "Népesség": "2541",
            "Lakások": "827"
        },
        {
            "Ir. szám": "2115",
            "name": "Vácszentlászló",
            "Megyekód": "PE",
            "Lat": "47.5833",
            "Long": "19.5333",
            "KSH kód": "09104",
            "Jogállás": "6",
            "Terület": "2987",
            "Népesség": "2115",
            "Lakások": "730"
        },
        {
            "Ir. szám": "2116",
            "name": "Zsámbok",
            "Megyekód": "PE",
            "Lat": "47.55",
            "Long": "19.6167",
            "KSH kód": "22035",
            "Jogállás": "6",
            "Terület": "2335",
            "Népesség": "2414",
            "Lakások": "995"
        },
        {
            "Ir. szám": "2117",
            "name": "Isaszeg",
            "Megyekód": "PE",
            "Lat": "47.5333",
            "Long": "19.4",
            "KSH kód": "07807",
            "Jogállás": "4",
            "Terület": "5483",
            "Népesség": "11214",
            "Lakások": "3641"
        },
        {
            "Ir. szám": "2118",
            "name": "Dány",
            "Megyekód": "PE",
            "Lat": "47.5167",
            "Long": "19.55",
            "KSH kód": "18397",
            "Jogállás": "6",
            "Terület": "4301",
            "Népesség": "4364",
            "Lakások": "1463"
        },
        {
            "Ir. szám": "2119",
            "name": "Pécel",
            "Megyekód": "PE",
            "Lat": "47.4833",
            "Long": "19.35",
            "KSH kód": "04057",
            "Jogállás": "4",
            "Terület": "4364",
            "Népesség": "14868",
            "Lakások": "4839"
        },
        {
            "Ir. szám": "2120",
            "name": "Dunakeszi",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.1333",
            "KSH kód": "18616",
            "Jogállás": "4",
            "Terület": "3106",
            "Népesség": "37153",
            "Lakások": "14715"
        },
        {
            "Ir. szám": "2121",
            "name": "Dunakeszi",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.1333",
            "KSH kód": "18616",
            "Jogállás": "4",
            "Terület": "3106",
            "Népesség": "37153",
            "Lakások": "14715"
        },
        {
            "Ir. szám": "2122",
            "name": "Dunakeszi",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.1333",
            "KSH kód": "18616",
            "Jogállás": "4",
            "Terület": "3106",
            "Népesség": "37153",
            "Lakások": "14715"
        },
        {
            "Ir. szám": "2123",
            "name": "Dunakeszi",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.1333",
            "KSH kód": "18616",
            "Jogállás": "4",
            "Terület": "3106",
            "Népesség": "37153",
            "Lakások": "14715"
        },
        {
            "Ir. szám": "2131",
            "name": "Göd",
            "Megyekód": "PE",
            "Lat": "47.7",
            "Long": "19.1333",
            "KSH kód": "23649",
            "Jogállás": "4",
            "Terület": "2224",
            "Népesség": "17587",
            "Lakások": "6531"
        },
        {
            "Ir. szám": "2132",
            "name": "Göd",
            "Megyekód": "PE",
            "Lat": "47.6976",
            "Long": "19.1539",
            "KSH kód": "23649",
            "Jogállás": "4",
            "Terület": "2224",
            "Népesség": "17587",
            "Lakások": "6531"
        },
        {
            "Ir. szám": "2133",
            "name": "Sződliget",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.15",
            "KSH kód": "28866",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "4552",
            "Lakások": "1501"
        },
        {
            "Ir. szám": "2134",
            "name": "Sződ",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19.2",
            "KSH kód": "12690",
            "Jogállás": "6",
            "Terület": "1576",
            "Népesség": "3270",
            "Lakások": "1145"
        },
        {
            "Ir. szám": "2135",
            "name": "Csörög",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.1833",
            "KSH kód": "34333",
            "Jogállás": "6",
            "Terület": "504",
            "Népesség": "2174",
            "Lakások": "583"
        },
        {
            "Ir. szám": "2141",
            "name": "Csömör",
            "Megyekód": "PE",
            "Lat": "47.55",
            "Long": "19.2333",
            "KSH kód": "22804",
            "Jogállás": "5",
            "Terület": "2270",
            "Népesség": "8904",
            "Lakások": "3043"
        },
        {
            "Ir. szám": "2142",
            "name": "Nagytarcsa",
            "Megyekód": "PE",
            "Lat": "47.5333",
            "Long": "19.2833",
            "KSH kód": "23409",
            "Jogállás": "6",
            "Terület": "1214",
            "Népesség": "3539",
            "Lakások": "1110"
        },
        {
            "Ir. szám": "2143",
            "name": "Kistarcsa",
            "Megyekód": "PE",
            "Lat": "47.5333",
            "Long": "19.2667",
            "KSH kód": "34157",
            "Jogállás": "4",
            "Terület": "1102",
            "Népesség": "10986",
            "Lakások": "3715"
        },
        {
            "Ir. szám": "2144",
            "name": "Kerepes",
            "Megyekód": "PE",
            "Lat": "47.5333",
            "Long": "19.2667",
            "KSH kód": "34166",
            "Jogállás": "5",
            "Terület": "2408",
            "Népesség": "9960",
            "Lakások": "3304"
        },
        {
            "Ir. szám": "2145",
            "name": "Kerepes",
            "Megyekód": "PE",
            "Lat": "47.5333",
            "Long": "19.2667",
            "KSH kód": "34166",
            "Jogállás": "5",
            "Terület": "2408",
            "Népesség": "9960",
            "Lakások": "3304"
        },
        {
            "Ir. szám": "2146",
            "name": "Mogyoród",
            "Megyekód": "PE",
            "Lat": "47.6",
            "Long": "19.25",
            "KSH kód": "17783",
            "Jogállás": "5",
            "Terület": "3448",
            "Népesség": "6018",
            "Lakások": "1971"
        },
        {
            "Ir. szám": "2151",
            "name": "Fót",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.2",
            "KSH kód": "32610",
            "Jogállás": "4",
            "Terület": "3740",
            "Népesség": "18365",
            "Lakások": "6119"
        },
        {
            "Ir. szám": "2152",
            "name": "Fót",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.2",
            "KSH kód": "32610",
            "Jogállás": "4",
            "Terület": "3740",
            "Népesség": "18365",
            "Lakások": "6119"
        },
        {
            "Ir. szám": "2153",
            "name": "Fót",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.2",
            "KSH kód": "32610",
            "Jogállás": "4",
            "Terület": "3740",
            "Népesség": "18365",
            "Lakások": "6119"
        },
        {
            "Ir. szám": "2161",
            "name": "Csomád",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.2333",
            "KSH kód": "33118",
            "Jogállás": "6",
            "Terület": "1239",
            "Népesség": "1405",
            "Lakások": "506"
        },
        {
            "Ir. szám": "2162",
            "name": "Őrbottyán",
            "Megyekód": "PE",
            "Lat": "47.6833",
            "Long": "19.2667",
            "KSH kód": "08545",
            "Jogállás": "5",
            "Terület": "2737",
            "Népesség": "7109",
            "Lakások": "2365"
        },
        {
            "Ir. szám": "2163",
            "name": "Vácrátót",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19.2333",
            "KSH kód": "17668",
            "Jogállás": "6",
            "Terület": "1805",
            "Népesség": "1864",
            "Lakások": "644"
        },
        {
            "Ir. szám": "2164",
            "name": "Váchartyán",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.2667",
            "KSH kód": "19886",
            "Jogállás": "6",
            "Terület": "1213",
            "Népesség": "1835",
            "Lakások": "641"
        },
        {
            "Ir. szám": "2165",
            "name": "Kisnémedi",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.3",
            "KSH kód": "05227",
            "Jogállás": "6",
            "Terület": "1383",
            "Népesség": "681",
            "Lakások": "302"
        },
        {
            "Ir. szám": "2166",
            "name": "Püspökszilágy",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.3167",
            "KSH kód": "04303",
            "Jogállás": "6",
            "Terület": "2530",
            "Népesség": "767",
            "Lakások": "294"
        },
        {
            "Ir. szám": "2167",
            "name": "Vácduka",
            "Megyekód": "PE",
            "Lat": "47.75",
            "Long": "19.2167",
            "KSH kód": "05917",
            "Jogállás": "6",
            "Terület": "840",
            "Népesség": "1284",
            "Lakások": "448"
        },
        {
            "Ir. szám": "2170",
            "name": "Aszód",
            "Megyekód": "PE",
            "Lat": "47.65",
            "Long": "19.5",
            "KSH kód": "16188",
            "Jogállás": "4",
            "Terület": "1620",
            "Népesség": "6324",
            "Lakások": "2399"
        },
        {
            "Ir. szám": "2173",
            "name": "Kartal",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.55",
            "KSH kód": "30696",
            "Jogállás": "5",
            "Terület": "2911",
            "Népesség": "5923",
            "Lakások": "1870"
        },
        {
            "Ir. szám": "2174",
            "name": "Verseg",
            "Megyekód": "PE",
            "Lat": "47.7167",
            "Long": "19.55",
            "KSH kód": "22488",
            "Jogállás": "6",
            "Terület": "2958",
            "Népesség": "1351",
            "Lakások": "534"
        },
        {
            "Ir. szám": "2175",
            "name": "Kálló",
            "Megyekód": "NO",
            "Lat": "47.75",
            "Long": "19.5",
            "KSH kód": "08642",
            "Jogállás": "6",
            "Terület": "3697",
            "Népesség": "1507",
            "Lakások": "535"
        },
        {
            "Ir. szám": "2176",
            "name": "Erdőkürt",
            "Megyekód": "NO",
            "Lat": "47.7667",
            "Long": "19.4667",
            "KSH kód": "22655",
            "Jogállás": "6",
            "Terület": "2172",
            "Népesség": "580",
            "Lakások": "264"
        },
        {
            "Ir. szám": "2177",
            "name": "Erdőtarcsa",
            "Megyekód": "NO",
            "Lat": "47.7667",
            "Long": "19.55",
            "KSH kód": "21795",
            "Jogállás": "6",
            "Terület": "1507",
            "Népesség": "534",
            "Lakások": "246"
        },
        {
            "Ir. szám": "2181",
            "name": "Iklad",
            "Megyekód": "PE",
            "Lat": "47.6667",
            "Long": "19.45",
            "KSH kód": "03300",
            "Jogállás": "6",
            "Terület": "1121",
            "Népesség": "2125",
            "Lakások": "672"
        },
        {
            "Ir. szám": "2182",
            "name": "Domony",
            "Megyekód": "PE",
            "Lat": "47.65",
            "Long": "19.4333",
            "KSH kód": "04808",
            "Jogállás": "6",
            "Terület": "2180",
            "Népesség": "2139",
            "Lakások": "628"
        },
        {
            "Ir. szám": "2183",
            "name": "Galgamácsa",
            "Megyekód": "PE",
            "Lat": "47.7",
            "Long": "19.3833",
            "KSH kód": "27128",
            "Jogállás": "6",
            "Terület": "4331",
            "Népesség": "1986",
            "Lakások": "739"
        },
        {
            "Ir. szám": "2184",
            "name": "Vácegres",
            "Megyekód": "PE",
            "Lat": "47.6833",
            "Long": "19.3667",
            "KSH kód": "30331",
            "Jogállás": "6",
            "Terület": "1367",
            "Népesség": "887",
            "Lakások": "294"
        },
        {
            "Ir. szám": "2185",
            "name": "Váckisújfalu",
            "Megyekód": "PE",
            "Lat": "47.7",
            "Long": "19.35",
            "KSH kód": "05698",
            "Jogállás": "6",
            "Terület": "1069",
            "Népesség": "472",
            "Lakások": "156"
        },
        {
            "Ir. szám": "2191",
            "name": "Bag",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.4833",
            "KSH kód": "09131",
            "Jogállás": "5",
            "Terület": "2355",
            "Népesség": "3987",
            "Lakások": "1314"
        },
        {
            "Ir. szám": "2192",
            "name": "Hévízgyörk",
            "Megyekód": "PE",
            "Lat": "47.6333",
            "Long": "19.5167",
            "KSH kód": "13949",
            "Jogállás": "6",
            "Terület": "2284",
            "Népesség": "3130",
            "Lakások": "1099"
        },
        {
            "Ir. szám": "2193",
            "name": "Galgahévíz",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.5667",
            "KSH kód": "19503",
            "Jogállás": "6",
            "Terület": "3118",
            "Népesség": "2527",
            "Lakások": "982"
        },
        {
            "Ir. szám": "2194",
            "name": "Tura",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "19.6",
            "KSH kód": "09593",
            "Jogállás": "4",
            "Terület": "5592",
            "Népesség": "7945",
            "Lakások": "2974"
        },
        {
            "Ir. szám": "2200",
            "name": "Monor",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.45",
            "KSH kód": "10551",
            "Jogállás": "4",
            "Terület": "4829",
            "Népesség": "18526",
            "Lakások": "6430"
        },
        {
            "Ir. szám": "2201",
            "name": "Monor",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.45",
            "KSH kód": "10551",
            "Jogállás": "4",
            "Terület": "4829",
            "Népesség": "18526",
            "Lakások": "6430"
        },
        {
            "Ir. szám": "2202",
            "name": "Monor",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.45",
            "KSH kód": "10551",
            "Jogállás": "4",
            "Terület": "4829",
            "Népesség": "18526",
            "Lakások": "6430"
        },
        {
            "Ir. szám": "2209",
            "name": "Péteri",
            "Megyekód": "PE",
            "Lat": "47.3833",
            "Long": "19.4167",
            "KSH kód": "21847",
            "Jogállás": "6",
            "Terület": "1189",
            "Népesség": "2153",
            "Lakások": "742"
        },
        {
            "Ir. szám": "2211",
            "name": "Vasad",
            "Megyekód": "PE",
            "Lat": "47.3167",
            "Long": "19.4",
            "KSH kód": "22585",
            "Jogállás": "6",
            "Terület": "3341",
            "Népesség": "1781",
            "Lakások": "734"
        },
        {
            "Ir. szám": "2212",
            "name": "Csévharaszt",
            "Megyekód": "PE",
            "Lat": "47.3",
            "Long": "19.4333",
            "KSH kód": "18476",
            "Jogállás": "6",
            "Terület": "4922",
            "Népesség": "1953",
            "Lakások": "690"
        },
        {
            "Ir. szám": "2213",
            "name": "Csévharaszt",
            "Megyekód": "PE",
            "Lat": "47.3",
            "Long": "19.4333",
            "KSH kód": "18476",
            "Jogállás": "6",
            "Terület": "4922",
            "Népesség": "1953",
            "Lakások": "690"
        },
        {
            "Ir. szám": "2214",
            "name": "Pánd",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.6333",
            "KSH kód": "22248",
            "Jogállás": "6",
            "Terület": "2221",
            "Népesség": "2103",
            "Lakások": "720"
        },
        {
            "Ir. szám": "2215",
            "name": "Káva",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.5833",
            "KSH kód": "27827",
            "Jogállás": "6",
            "Terület": "1131",
            "Népesség": "607",
            "Lakások": "276"
        },
        {
            "Ir. szám": "2216",
            "name": "Bénye",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.55",
            "KSH kód": "25098",
            "Jogállás": "6",
            "Terület": "1652",
            "Népesség": "1268",
            "Lakások": "498"
        },
        {
            "Ir. szám": "2217",
            "name": "Gomba",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "19.5333",
            "KSH kód": "09441",
            "Jogállás": "6",
            "Terület": "3971",
            "Népesség": "3030",
            "Lakások": "1037"
        },
        {
            "Ir. szám": "2220",
            "name": "Vecsés",
            "Megyekód": "PE",
            "Lat": "47.4018",
            "Long": "19.2458",
            "KSH kód": "26815",
            "Jogállás": "4",
            "Terület": "3616",
            "Népesség": "20550",
            "Lakások": "7662"
        },
        {
            "Ir. szám": "2221",
            "name": "Vecsés",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "19.2833",
            "KSH kód": "26815",
            "Jogállás": "4",
            "Terület": "3616",
            "Népesség": "20550",
            "Lakások": "7662"
        },
        {
            "Ir. szám": "2222",
            "name": "Vecsés",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "19.2833",
            "KSH kód": "26815",
            "Jogállás": "4",
            "Terület": "3616",
            "Népesség": "20550",
            "Lakások": "7662"
        },
        {
            "Ir. szám": "2225",
            "name": "Üllő",
            "Megyekód": "PE",
            "Lat": "47.3833",
            "Long": "19.35",
            "KSH kód": "12894",
            "Jogállás": "4",
            "Terület": "4810",
            "Népesség": "10924",
            "Lakások": "3920"
        },
        {
            "Ir. szám": "2230",
            "name": "Gyömrő",
            "Megyekód": "PE",
            "Lat": "47.4167",
            "Long": "19.4",
            "KSH kód": "29735",
            "Jogállás": "4",
            "Terület": "2651",
            "Népesség": "15714",
            "Lakások": "5525"
        },
        {
            "Ir. szám": "2233",
            "name": "Ecser",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.3333",
            "KSH kód": "24518",
            "Jogállás": "6",
            "Terület": "1310",
            "Népesség": "3589",
            "Lakások": "1391"
        },
        {
            "Ir. szám": "2234",
            "name": "Maglód",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.3667",
            "KSH kód": "10922",
            "Jogállás": "4",
            "Terület": "2236",
            "Népesség": "11319",
            "Lakások": "3717"
        },
        {
            "Ir. szám": "2235",
            "name": "Mende",
            "Megyekód": "PE",
            "Lat": "47.4167",
            "Long": "19.45",
            "KSH kód": "03692",
            "Jogállás": "6",
            "Terület": "2717",
            "Népesség": "4325",
            "Lakások": "1485"
        },
        {
            "Ir. szám": "2241",
            "name": "Sülysáp",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.5583",
            "KSH kód": "21713",
            "Jogállás": "5",
            "Terület": "4719",
            "Népesség": "8258",
            "Lakások": "2888"
        },
        {
            "Ir. szám": "2242",
            "name": "Sülysáp",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.5583",
            "KSH kód": "21713",
            "Jogállás": "5",
            "Terület": "4719",
            "Népesség": "8258",
            "Lakások": "2888"
        },
        {
            "Ir. szám": "2243",
            "name": "Kóka",
            "Megyekód": "PE",
            "Lat": "47.4833",
            "Long": "19.5833",
            "KSH kód": "31361",
            "Jogállás": "6",
            "Terület": "4436",
            "Népesség": "4530",
            "Lakások": "1706"
        },
        {
            "Ir. szám": "2244",
            "name": "Úri",
            "Megyekód": "PE",
            "Lat": "47.4167",
            "Long": "19.5333",
            "KSH kód": "28644",
            "Jogállás": "6",
            "Terület": "2219",
            "Népesség": "2713",
            "Lakások": "957"
        },
        {
            "Ir. szám": "2251",
            "name": "Tápiószecső",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.6",
            "KSH kód": "31796",
            "Jogállás": "5",
            "Terület": "3838",
            "Népesség": "6434",
            "Lakások": "2323"
        },
        {
            "Ir. szám": "2252",
            "name": "Tóalmás",
            "Megyekód": "PE",
            "Lat": "47.5167",
            "Long": "19.6667",
            "KSH kód": "21467",
            "Jogállás": "6",
            "Terület": "3935",
            "Népesség": "3407",
            "Lakások": "1349"
        },
        {
            "Ir. szám": "2253",
            "name": "Tápióság",
            "Megyekód": "PE",
            "Lat": "47.4",
            "Long": "19.6167",
            "KSH kód": "09405",
            "Jogállás": "6",
            "Terület": "3354",
            "Népesség": "2705",
            "Lakások": "1136"
        },
        {
            "Ir. szám": "2254",
            "name": "Szentmártonkáta",
            "Megyekód": "PE",
            "Lat": "47.45",
            "Long": "19.7",
            "KSH kód": "28653",
            "Jogállás": "6",
            "Terület": "5220",
            "Népesség": "4999",
            "Lakások": "1927"
        },
        {
            "Ir. szám": "2255",
            "name": "Szentlőrinckáta",
            "Megyekód": "PE",
            "Lat": "47.5167",
            "Long": "19.75",
            "KSH kód": "07542",
            "Jogállás": "6",
            "Terület": "2015",
            "Népesség": "1962",
            "Lakások": "824"
        },
        {
            "Ir. szám": "2300",
            "name": "Ráckeve",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "18.95",
            "KSH kód": "17260",
            "Jogállás": "4",
            "Terület": "6409",
            "Népesség": "9842",
            "Lakások": "3692"
        },
        {
            "Ir. szám": "2301",
            "name": "Ráckeve",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "18.95",
            "KSH kód": "17260",
            "Jogállás": "4",
            "Terület": "6409",
            "Népesség": "9842",
            "Lakások": "3692"
        },
        {
            "Ir. szám": "2309",
            "name": "Lórév",
            "Megyekód": "PE",
            "Lat": "47.1167",
            "Long": "18.9",
            "KSH kód": "09140",
            "Jogállás": "6",
            "Terület": "988",
            "Népesség": "315",
            "Lakások": "126"
        },
        {
            "Ir. szám": "2310",
            "name": "Szigetszentmiklós",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.05",
            "KSH kód": "28954",
            "Jogállás": "4",
            "Terület": "4565",
            "Népesség": "32340",
            "Lakások": "11485"
        },
        {
            "Ir. szám": "2311",
            "name": "Szigetszentmiklós",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.05",
            "KSH kód": "28954",
            "Jogállás": "4",
            "Terület": "4565",
            "Népesség": "32340",
            "Lakások": "11485"
        },
        {
            "Ir. szám": "2313",
            "name": "Szigetszentmiklós",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.05",
            "KSH kód": "28954",
            "Jogállás": "4",
            "Terület": "4565",
            "Népesség": "32340",
            "Lakások": "11485"
        },
        {
            "Ir. szám": "2314",
            "name": "Halásztelek",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.9833",
            "KSH kód": "09690",
            "Jogállás": "4",
            "Terület": "864",
            "Népesség": "8620",
            "Lakások": "3104"
        },
        {
            "Ir. szám": "2315",
            "name": "Szigethalom",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "19",
            "KSH kód": "13277",
            "Jogállás": "4",
            "Terület": "912",
            "Népesség": "16477",
            "Lakások": "5236"
        },
        {
            "Ir. szám": "2316",
            "name": "Tököl",
            "Megyekód": "PE",
            "Lat": "47.3104",
            "Long": "19.0021",
            "KSH kód": "29823",
            "Jogállás": "4",
            "Terület": "3849",
            "Népesség": "10164",
            "Lakások": "3699"
        },
        {
            "Ir. szám": "2317",
            "name": "Szigetcsép",
            "Megyekód": "PE",
            "Lat": "47.2667",
            "Long": "18.9833",
            "KSH kód": "07870",
            "Jogállás": "6",
            "Terület": "1820",
            "Népesség": "2354",
            "Lakások": "901"
        },
        {
            "Ir. szám": "2318",
            "name": "Szigetszentmárton",
            "Megyekód": "PE",
            "Lat": "47.2333",
            "Long": "18.9667",
            "KSH kód": "15185",
            "Jogállás": "6",
            "Terület": "1073",
            "Népesség": "2083",
            "Lakások": "815"
        },
        {
            "Ir. szám": "2319",
            "name": "Szigetújfalu",
            "Megyekód": "PE",
            "Lat": "47.2333",
            "Long": "18.9333",
            "KSH kód": "22114",
            "Jogállás": "6",
            "Terület": "1083",
            "Népesség": "2087",
            "Lakások": "839"
        },
        {
            "Ir. szám": "2321",
            "name": "Szigetbecse",
            "Megyekód": "PE",
            "Lat": "47.1333",
            "Long": "18.95",
            "KSH kód": "26259",
            "Jogállás": "6",
            "Terület": "1712",
            "Népesség": "1346",
            "Lakások": "511"
        },
        {
            "Ir. szám": "2322",
            "name": "Makád",
            "Megyekód": "PE",
            "Lat": "47.0833",
            "Long": "18.9333",
            "KSH kód": "04394",
            "Jogállás": "6",
            "Terület": "3177",
            "Népesség": "1252",
            "Lakások": "528"
        },
        {
            "Ir. szám": "2330",
            "name": "Dunaharaszti",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.0833",
            "KSH kód": "09584",
            "Jogállás": "4",
            "Terület": "2917",
            "Népesség": "19491",
            "Lakások": "7467"
        },
        {
            "Ir. szám": "2331",
            "name": "Dunaharaszti",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.0833",
            "KSH kód": "09584",
            "Jogállás": "4",
            "Terület": "2917",
            "Népesség": "19491",
            "Lakások": "7467"
        },
        {
            "Ir. szám": "2332",
            "name": "Dunaharaszti",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.0833",
            "KSH kód": "09584",
            "Jogállás": "4",
            "Terület": "2917",
            "Népesség": "19491",
            "Lakások": "7467"
        },
        {
            "Ir. szám": "2335",
            "name": "Taksony",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "19.0833",
            "KSH kód": "30720",
            "Jogállás": "5",
            "Terület": "2085",
            "Népesség": "6369",
            "Lakások": "2062"
        },
        {
            "Ir. szám": "2336",
            "name": "Dunavarsány",
            "Megyekód": "PE",
            "Lat": "47.2833",
            "Long": "19.0667",
            "KSH kód": "20534",
            "Jogállás": "4",
            "Terület": "2252",
            "Népesség": "7112",
            "Lakások": "2350"
        },
        {
            "Ir. szám": "2337",
            "name": "Délegyháza",
            "Megyekód": "PE",
            "Lat": "47.25",
            "Long": "19.0667",
            "KSH kód": "09973",
            "Jogállás": "6",
            "Terület": "2542",
            "Népesség": "3187",
            "Lakások": "1118"
        },
        {
            "Ir. szám": "2338",
            "name": "Áporka",
            "Megyekód": "PE",
            "Lat": "47.2333",
            "Long": "19.0167",
            "KSH kód": "10108",
            "Jogállás": "6",
            "Terület": "1747",
            "Népesség": "1155",
            "Lakások": "479"
        },
        {
            "Ir. szám": "2339",
            "name": "Majosháza",
            "Megyekód": "PE",
            "Lat": "47.2667",
            "Long": "19",
            "KSH kód": "10755",
            "Jogállás": "6",
            "Terület": "1142",
            "Népesség": "1486",
            "Lakások": "527"
        },
        {
            "Ir. szám": "2340",
            "name": "Kiskunlacháza",
            "Megyekód": "PE",
            "Lat": "47.2",
            "Long": "19.0167",
            "KSH kód": "10816",
            "Jogállás": "5",
            "Terület": "9350",
            "Népesség": "9080",
            "Lakások": "3351"
        },
        {
            "Ir. szám": "2341",
            "name": "Kiskunlacháza",
            "Megyekód": "PE",
            "Lat": "47.2",
            "Long": "19.0167",
            "KSH kód": "10816",
            "Jogállás": "5",
            "Terület": "9350",
            "Népesség": "9080",
            "Lakások": "3351"
        },
        {
            "Ir. szám": "2342",
            "name": "Kiskunlacháza",
            "Megyekód": "PE",
            "Lat": "47.2",
            "Long": "19.0167",
            "KSH kód": "10816",
            "Jogállás": "5",
            "Terület": "9350",
            "Népesség": "9080",
            "Lakások": "3351"
        },
        {
            "Ir. szám": "2344",
            "name": "Dömsöd",
            "Megyekód": "PE",
            "Lat": "47.0833",
            "Long": "19",
            "KSH kód": "29647",
            "Jogállás": "5",
            "Terület": "7242",
            "Népesség": "5745",
            "Lakások": "2336"
        },
        {
            "Ir. szám": "2345",
            "name": "Apaj",
            "Megyekód": "PE",
            "Lat": "47.1167",
            "Long": "19.1",
            "KSH kód": "33561",
            "Jogállás": "6",
            "Terület": "7104",
            "Népesség": "1251",
            "Lakások": "437"
        },
        {
            "Ir. szám": "2347",
            "name": "Bugyi",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.15",
            "KSH kód": "32027",
            "Jogállás": "5",
            "Terület": "11555",
            "Népesség": "5338",
            "Lakások": "2026"
        },
        {
            "Ir. szám": "2351",
            "name": "Alsónémedi",
            "Megyekód": "PE",
            "Lat": "47.3167",
            "Long": "19.1667",
            "KSH kód": "23199",
            "Jogállás": "5",
            "Terület": "4907",
            "Népesség": "4982",
            "Lakások": "2037"
        },
        {
            "Ir. szám": "2360",
            "name": "Gyál",
            "Megyekód": "PE",
            "Lat": "47.3833",
            "Long": "19.2333",
            "KSH kód": "25627",
            "Jogállás": "4",
            "Terület": "2493",
            "Népesség": "23220",
            "Lakások": "8128"
        },
        {
            "Ir. szám": "2363",
            "name": "Felsőpakony",
            "Megyekód": "PE",
            "Lat": "47.35",
            "Long": "19.25",
            "KSH kód": "06035",
            "Jogállás": "6",
            "Terület": "1533",
            "Népesség": "3415",
            "Lakások": "1167"
        },
        {
            "Ir. szám": "2364",
            "name": "Ócsa",
            "Megyekód": "PE",
            "Lat": "47.3",
            "Long": "19.2333",
            "KSH kód": "04075",
            "Jogállás": "4",
            "Terület": "8166",
            "Népesség": "8989",
            "Lakások": "3249"
        },
        {
            "Ir. szám": "2365",
            "name": "Inárcs",
            "Megyekód": "PE",
            "Lat": "47.2667",
            "Long": "19.3333",
            "KSH kód": "32106",
            "Jogállás": "6",
            "Terület": "2253",
            "Népesség": "4448",
            "Lakások": "1533"
        },
        {
            "Ir. szám": "2366",
            "name": "Kakucs",
            "Megyekód": "PE",
            "Lat": "47.25",
            "Long": "19.3667",
            "KSH kód": "32230",
            "Jogállás": "6",
            "Terület": "2180",
            "Népesség": "2729",
            "Lakások": "966"
        },
        {
            "Ir. szám": "2367",
            "name": "Újhartyán",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.3833",
            "KSH kód": "06293",
            "Jogállás": "6",
            "Terület": "2243",
            "Népesség": "2755",
            "Lakások": "1069"
        },
        {
            "Ir. szám": "2370",
            "name": "Dabas",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "19.3167",
            "KSH kód": "09247",
            "Jogállás": "4",
            "Terület": "16599",
            "Népesség": "16671",
            "Lakások": "6007"
        },
        {
            "Ir. szám": "2371",
            "name": "Dabas",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "19.3167",
            "KSH kód": "09247",
            "Jogállás": "4",
            "Terület": "16599",
            "Népesség": "16671",
            "Lakások": "6007"
        },
        {
            "Ir. szám": "2372",
            "name": "Dabas",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "19.3167",
            "KSH kód": "09247",
            "Jogállás": "4",
            "Terület": "16599",
            "Népesség": "16671",
            "Lakások": "6007"
        },
        {
            "Ir. szám": "2373",
            "name": "Dabas",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "19.3167",
            "KSH kód": "09247",
            "Jogállás": "4",
            "Terület": "16599",
            "Népesség": "16671",
            "Lakások": "6007"
        },
        {
            "Ir. szám": "2374",
            "name": "Dabas",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "19.3167",
            "KSH kód": "09247",
            "Jogállás": "4",
            "Terület": "16599",
            "Népesség": "16671",
            "Lakások": "6007"
        },
        {
            "Ir. szám": "2375",
            "name": "Tatárszentgyörgy",
            "Megyekód": "PE",
            "Lat": "47.0667",
            "Long": "19.3833",
            "KSH kód": "27386",
            "Jogállás": "6",
            "Terület": "5506",
            "Népesség": "1966",
            "Lakások": "676"
        },
        {
            "Ir. szám": "2376",
            "name": "Hernád",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.4333",
            "KSH kód": "09849",
            "Jogállás": "6",
            "Terület": "2706",
            "Népesség": "4077",
            "Lakások": "1411"
        },
        {
            "Ir. szám": "2377",
            "name": "Örkény",
            "Megyekód": "PE",
            "Lat": "47.1333",
            "Long": "19.4333",
            "KSH kód": "05281",
            "Jogállás": "4",
            "Terület": "3644",
            "Népesség": "4924",
            "Lakások": "1728"
        },
        {
            "Ir. szám": "2378",
            "name": "Pusztavacs",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.5",
            "KSH kód": "23083",
            "Jogállás": "6",
            "Terület": "7907",
            "Népesség": "1427",
            "Lakások": "567"
        },
        {
            "Ir. szám": "2381",
            "name": "Táborfalva",
            "Megyekód": "PE",
            "Lat": "47.0833",
            "Long": "19.4667",
            "KSH kód": "08332",
            "Jogállás": "6",
            "Terület": "4205",
            "Népesség": "3429",
            "Lakások": "1380"
        },
        {
            "Ir. szám": "2400",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2401",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2402",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2403",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2404",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2405",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2406",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2407",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2408",
            "name": "Dunaújváros",
            "Megyekód": "FE",
            "Lat": "46.9833",
            "Long": "18.9333",
            "KSH kód": "03115",
            "Jogállás": "3",
            "Terület": "5267",
            "Népesség": "48562",
            "Lakások": "22831"
        },
        {
            "Ir. szám": "2421",
            "name": "Nagyvenyim",
            "Megyekód": "FE",
            "Lat": "46.9667",
            "Long": "18.85",
            "KSH kód": "26134",
            "Jogállás": "6",
            "Terület": "4368",
            "Népesség": "4156",
            "Lakások": "1469"
        },
        {
            "Ir. szám": "2422",
            "name": "Mezőfalva",
            "Megyekód": "FE",
            "Lat": "46.9333",
            "Long": "18.7667",
            "KSH kód": "17552",
            "Jogállás": "5",
            "Terület": "8042",
            "Népesség": "4908",
            "Lakások": "1724"
        },
        {
            "Ir. szám": "2423",
            "name": "Daruszentmiklós",
            "Megyekód": "FE",
            "Lat": "46.8952",
            "Long": "18.8452",
            "KSH kód": "34342",
            "Jogállás": "6",
            "Terület": "1912",
            "Népesség": "1642",
            "Lakások": "573"
        },
        {
            "Ir. szám": "2424",
            "name": "Előszállás",
            "Megyekód": "FE",
            "Lat": "46.8333",
            "Long": "18.8167",
            "KSH kód": "20358",
            "Jogállás": "5",
            "Terület": "3998",
            "Népesség": "2233",
            "Lakások": "952"
        },
        {
            "Ir. szám": "2425",
            "name": "Nagykarácsony",
            "Megyekód": "FE",
            "Lat": "46.8667",
            "Long": "18.7833",
            "KSH kód": "07001",
            "Jogállás": "6",
            "Terület": "3046",
            "Népesség": "1433",
            "Lakások": "589"
        },
        {
            "Ir. szám": "2426",
            "name": "Baracs",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.8833",
            "KSH kód": "07047",
            "Jogállás": "6",
            "Terület": "5518",
            "Népesség": "3573",
            "Lakások": "1294"
        },
        {
            "Ir. szám": "2427",
            "name": "Baracs",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.8833",
            "KSH kód": "07047",
            "Jogállás": "6",
            "Terület": "5518",
            "Népesség": "3573",
            "Lakások": "1294"
        },
        {
            "Ir. szám": "2428",
            "name": "Kisapostag",
            "Megyekód": "FE",
            "Lat": "46.9",
            "Long": "18.9333",
            "KSH kód": "12636",
            "Jogállás": "6",
            "Terület": "958",
            "Népesség": "1448",
            "Lakások": "508"
        },
        {
            "Ir. szám": "2431",
            "name": "Perkáta",
            "Megyekód": "FE",
            "Lat": "47.05",
            "Long": "18.8",
            "KSH kód": "19354",
            "Jogállás": "5",
            "Terület": "7452",
            "Népesség": "4047",
            "Lakások": "1564"
        },
        {
            "Ir. szám": "2432",
            "name": "Szabadegyháza",
            "Megyekód": "FE",
            "Lat": "47.0667",
            "Long": "18.6833",
            "KSH kód": "13259",
            "Jogállás": "6",
            "Terület": "4164",
            "Népesség": "2216",
            "Lakások": "788"
        },
        {
            "Ir. szám": "2433",
            "name": "Sárosd",
            "Megyekód": "FE",
            "Lat": "47.05",
            "Long": "18.65",
            "KSH kód": "25140",
            "Jogállás": "5",
            "Terület": "4812",
            "Népesség": "3397",
            "Lakások": "1126"
        },
        {
            "Ir. szám": "2434",
            "name": "Hantos",
            "Megyekód": "FE",
            "Lat": "47",
            "Long": "18.7",
            "KSH kód": "23427",
            "Jogállás": "6",
            "Terület": "3696",
            "Népesség": "950",
            "Lakások": "373"
        },
        {
            "Ir. szám": "2435",
            "name": "Nagylók",
            "Megyekód": "FE",
            "Lat": "46.9667",
            "Long": "18.6667",
            "KSH kód": "32364",
            "Jogállás": "6",
            "Terület": "3244",
            "Népesség": "1081",
            "Lakások": "461"
        },
        {
            "Ir. szám": "2440",
            "name": "Százhalombatta",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "18.9333",
            "KSH kód": "17312",
            "Jogállás": "4",
            "Terület": "2806",
            "Népesség": "18226",
            "Lakások": "7187"
        },
        {
            "Ir. szám": "2441",
            "name": "Százhalombatta",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "18.9333",
            "KSH kód": "17312",
            "Jogállás": "4",
            "Terület": "2806",
            "Népesség": "18226",
            "Lakások": "7187"
        },
        {
            "Ir. szám": "2442",
            "name": "Százhalombatta",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "18.9333",
            "KSH kód": "17312",
            "Jogállás": "4",
            "Terület": "2806",
            "Népesség": "18226",
            "Lakások": "7187"
        },
        {
            "Ir. szám": "2443",
            "name": "Százhalombatta",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "18.9333",
            "KSH kód": "17312",
            "Jogállás": "4",
            "Terület": "2806",
            "Népesség": "18226",
            "Lakások": "7187"
        },
        {
            "Ir. szám": "2444",
            "name": "Százhalombatta",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "18.9333",
            "KSH kód": "17312",
            "Jogállás": "4",
            "Terület": "2806",
            "Népesség": "18226",
            "Lakások": "7187"
        },
        {
            "Ir. szám": "2451",
            "name": "Ercsi",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.9",
            "KSH kód": "23603",
            "Jogállás": "4",
            "Terület": "6531",
            "Népesség": "8426",
            "Lakások": "3142"
        },
        {
            "Ir. szám": "2453",
            "name": "Ercsi",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.9",
            "KSH kód": "23603",
            "Jogállás": "4",
            "Terület": "6531",
            "Népesség": "8426",
            "Lakások": "3142"
        },
        {
            "Ir. szám": "2454",
            "name": "Iváncsa",
            "Megyekód": "FE",
            "Lat": "47.15",
            "Long": "18.8167",
            "KSH kód": "13462",
            "Jogállás": "6",
            "Terület": "2517",
            "Népesség": "2932",
            "Lakások": "1009"
        },
        {
            "Ir. szám": "2455",
            "name": "Beloiannisz",
            "Megyekód": "FE",
            "Lat": "47.1667",
            "Long": "18.8333",
            "KSH kód": "08466",
            "Jogállás": "6",
            "Terület": "454",
            "Népesség": "1161",
            "Lakások": "436"
        },
        {
            "Ir. szám": "2456",
            "name": "Besnyő",
            "Megyekód": "FE",
            "Lat": "47.1833",
            "Long": "18.8167",
            "KSH kód": "16346",
            "Jogállás": "6",
            "Terület": "4464",
            "Népesség": "1808",
            "Lakások": "659"
        },
        {
            "Ir. szám": "2457",
            "name": "Adony",
            "Megyekód": "FE",
            "Lat": "47.1167",
            "Long": "18.8667",
            "KSH kód": "08925",
            "Jogállás": "4",
            "Terület": "6105",
            "Népesség": "3839",
            "Lakások": "1458"
        },
        {
            "Ir. szám": "2458",
            "name": "Kulcs",
            "Megyekód": "FE",
            "Lat": "47.05",
            "Long": "18.9167",
            "KSH kód": "34209",
            "Jogállás": "6",
            "Terület": "1673",
            "Népesség": "2503",
            "Lakások": "792"
        },
        {
            "Ir. szám": "2459",
            "name": "Rácalmás",
            "Megyekód": "FE",
            "Lat": "47.0167",
            "Long": "18.95",
            "KSH kód": "09900",
            "Jogállás": "5",
            "Terület": "4064",
            "Népesség": "4461",
            "Lakások": "1417"
        },
        {
            "Ir. szám": "2461",
            "name": "Tárnok",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "18.85",
            "KSH kód": "04154",
            "Jogállás": "5",
            "Terület": "2360",
            "Népesség": "9029",
            "Lakások": "3320"
        },
        {
            "Ir. szám": "2462",
            "name": "Martonvásár",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.7833",
            "KSH kód": "04659",
            "Jogállás": "4",
            "Terület": "3125",
            "Népesség": "5702",
            "Lakások": "1927"
        },
        {
            "Ir. szám": "2463",
            "name": "Tordas",
            "Megyekód": "FE",
            "Lat": "47.35",
            "Long": "18.75",
            "KSH kód": "21005",
            "Jogállás": "6",
            "Terület": "1676",
            "Népesség": "2000",
            "Lakások": "613"
        },
        {
            "Ir. szám": "2464",
            "name": "Gyúró",
            "Megyekód": "FE",
            "Lat": "47.3833",
            "Long": "18.7333",
            "KSH kód": "15918",
            "Jogállás": "6",
            "Terület": "2437",
            "Népesség": "1287",
            "Lakások": "439"
        },
        {
            "Ir. szám": "2465",
            "name": "Ráckeresztúr",
            "Megyekód": "FE",
            "Lat": "47.2667",
            "Long": "18.8333",
            "KSH kód": "02015",
            "Jogállás": "6",
            "Terület": "3530",
            "Népesség": "3296",
            "Lakások": "1253"
        },
        {
            "Ir. szám": "2471",
            "name": "Baracska",
            "Megyekód": "FE",
            "Lat": "47.2833",
            "Long": "18.7667",
            "KSH kód": "08581",
            "Jogállás": "6",
            "Terület": "3968",
            "Népesség": "2723",
            "Lakások": "975"
        },
        {
            "Ir. szám": "2472",
            "name": "Kajászó",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.7333",
            "KSH kód": "21342",
            "Jogállás": "6",
            "Terület": "2398",
            "Népesség": "1054",
            "Lakások": "392"
        },
        {
            "Ir. szám": "2473",
            "name": "Vál",
            "Megyekód": "FE",
            "Lat": "47.3667",
            "Long": "18.6833",
            "KSH kód": "05829",
            "Jogállás": "6",
            "Terület": "4048",
            "Népesség": "2494",
            "Lakások": "890"
        },
        {
            "Ir. szám": "2475",
            "name": "Kápolnásnyék",
            "Megyekód": "FE",
            "Lat": "47.2333",
            "Long": "18.6833",
            "KSH kód": "21926",
            "Jogállás": "6",
            "Terület": "4150",
            "Népesség": "3483",
            "Lakások": "1421"
        },
        {
            "Ir. szám": "2476",
            "name": "Pázmánd",
            "Megyekód": "FE",
            "Lat": "47.2833",
            "Long": "18.6667",
            "KSH kód": "21786",
            "Jogállás": "6",
            "Terület": "2714",
            "Népesség": "2054",
            "Lakások": "883"
        },
        {
            "Ir. szám": "2477",
            "name": "Vereb",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.6167",
            "KSH kód": "03498",
            "Jogállás": "6",
            "Terület": "2232",
            "Népesség": "801",
            "Lakások": "310"
        },
        {
            "Ir. szám": "2481",
            "name": "Velence",
            "Megyekód": "FE",
            "Lat": "47.2333",
            "Long": "18.65",
            "KSH kód": "25016",
            "Jogállás": "4",
            "Terület": "3337",
            "Népesség": "5173",
            "Lakások": "2084"
        },
        {
            "Ir. szám": "2482",
            "name": "Velence",
            "Megyekód": "FE",
            "Lat": "47.2333",
            "Long": "18.65",
            "KSH kód": "25016",
            "Jogállás": "4",
            "Terület": "3337",
            "Népesség": "5173",
            "Lakások": "2084"
        },
        {
            "Ir. szám": "2483",
            "name": "Gárdony",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.65",
            "KSH kód": "10296",
            "Jogállás": "4",
            "Terület": "6350",
            "Népesség": "9303",
            "Lakások": "3707"
        },
        {
            "Ir. szám": "2484",
            "name": "Gárdony",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.65",
            "KSH kód": "10296",
            "Jogállás": "4",
            "Terület": "6350",
            "Népesség": "9303",
            "Lakások": "3707"
        },
        {
            "Ir. szám": "2485",
            "name": "Gárdony",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.65",
            "KSH kód": "10296",
            "Jogállás": "4",
            "Terület": "6350",
            "Népesség": "9303",
            "Lakások": "3707"
        },
        {
            "Ir. szám": "2490",
            "name": "Pusztaszabolcs",
            "Megyekód": "FE",
            "Lat": "47.1333",
            "Long": "18.7667",
            "KSH kód": "29018",
            "Jogállás": "4",
            "Terület": "5167",
            "Népesség": "6192",
            "Lakások": "2229"
        },
        {
            "Ir. szám": "2500",
            "name": "Esztergom",
            "Megyekód": "KE",
            "Lat": "47.8",
            "Long": "18.75",
            "KSH kód": "25131",
            "Jogállás": "4",
            "Terület": "10035",
            "Népesség": "30928",
            "Lakások": "11930"
        },
        {
            "Ir. szám": "2501",
            "name": "Esztergom",
            "Megyekód": "KE",
            "Lat": "47.8",
            "Long": "18.75",
            "KSH kód": "25131",
            "Jogállás": "4",
            "Terület": "10035",
            "Népesség": "30928",
            "Lakások": "11930"
        },
        {
            "Ir. szám": "2503",
            "name": "Esztergom",
            "Megyekód": "KE",
            "Lat": "47.8",
            "Long": "18.75",
            "KSH kód": "25131",
            "Jogállás": "4",
            "Terület": "10035",
            "Népesség": "30928",
            "Lakások": "11930"
        },
        {
            "Ir. szám": "2508",
            "name": "Esztergom",
            "Megyekód": "KE",
            "Lat": "47.8",
            "Long": "18.75",
            "KSH kód": "25131",
            "Jogállás": "4",
            "Terület": "10035",
            "Népesség": "30928",
            "Lakások": "11930"
        },
        {
            "Ir. szám": "2509",
            "name": "Esztergom",
            "Megyekód": "KE",
            "Lat": "47.8",
            "Long": "18.75",
            "KSH kód": "25131",
            "Jogállás": "4",
            "Terület": "10035",
            "Népesség": "30928",
            "Lakások": "11930"
        },
        {
            "Ir. szám": "2510",
            "name": "Dorog",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.7333",
            "KSH kód": "10490",
            "Jogállás": "4",
            "Terület": "1155",
            "Népesség": "12262",
            "Lakások": "4949"
        },
        {
            "Ir. szám": "2511",
            "name": "Dorog",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.7333",
            "KSH kód": "10490",
            "Jogállás": "4",
            "Terület": "1155",
            "Népesség": "12262",
            "Lakások": "4949"
        },
        {
            "Ir. szám": "2512",
            "name": "Dorog",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.7333",
            "KSH kód": "10490",
            "Jogállás": "4",
            "Terület": "1155",
            "Népesség": "12262",
            "Lakások": "4949"
        },
        {
            "Ir. szám": "2517",
            "name": "Kesztölc",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.8",
            "KSH kód": "29577",
            "Jogállás": "6",
            "Terület": "2200",
            "Népesség": "2669",
            "Lakások": "984"
        },
        {
            "Ir. szám": "2518",
            "name": "Leányvár",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.7833",
            "KSH kód": "25487",
            "Jogállás": "6",
            "Terület": "725",
            "Népesség": "1721",
            "Lakások": "647"
        },
        {
            "Ir. szám": "2519",
            "name": "Piliscsév",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.8167",
            "KSH kód": "21874",
            "Jogállás": "6",
            "Terület": "2490",
            "Népesség": "2378",
            "Lakások": "919"
        },
        {
            "Ir. szám": "2521",
            "name": "Csolnok",
            "Megyekód": "KE",
            "Lat": "47.7",
            "Long": "18.7167",
            "KSH kód": "18926",
            "Jogállás": "6",
            "Terület": "1871",
            "Népesség": "3377",
            "Lakások": "1234"
        },
        {
            "Ir. szám": "2522",
            "name": "Dág",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.7167",
            "KSH kód": "22910",
            "Jogállás": "6",
            "Terület": "1189",
            "Népesség": "990",
            "Lakások": "387"
        },
        {
            "Ir. szám": "2523",
            "name": "Sárisáp",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.6833",
            "KSH kód": "26903",
            "Jogállás": "6",
            "Terület": "1448",
            "Népesség": "2885",
            "Lakások": "1102"
        },
        {
            "Ir. szám": "2524",
            "name": "Nagysáp",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.6",
            "KSH kód": "27076",
            "Jogállás": "6",
            "Terület": "2477",
            "Népesség": "1556",
            "Lakások": "513"
        },
        {
            "Ir. szám": "2525",
            "name": "Bajna",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.6",
            "KSH kód": "16744",
            "Jogállás": "6",
            "Terület": "3727",
            "Népesség": "1955",
            "Lakások": "758"
        },
        {
            "Ir. szám": "2526",
            "name": "Epöl",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.65",
            "KSH kód": "29638",
            "Jogállás": "6",
            "Terület": "1253",
            "Népesség": "610",
            "Lakások": "258"
        },
        {
            "Ir. szám": "2527",
            "name": "Máriahalom",
            "Megyekód": "KE",
            "Lat": "47.6333",
            "Long": "18.7167",
            "KSH kód": "22637",
            "Jogállás": "6",
            "Terület": "1085",
            "Népesség": "663",
            "Lakások": "237"
        },
        {
            "Ir. szám": "2528",
            "name": "Úny",
            "Megyekód": "KE",
            "Lat": "47.6333",
            "Long": "18.7333",
            "KSH kód": "27632",
            "Jogállás": "6",
            "Terület": "1158",
            "Népesség": "743",
            "Lakások": "311"
        },
        {
            "Ir. szám": "2529",
            "name": "Annavölgy",
            "Megyekód": "KE",
            "Lat": "47.6625",
            "Long": "18.6771",
            "KSH kód": "34227",
            "Jogállás": "6",
            "Terület": "460",
            "Népesség": "958",
            "Lakások": "402"
        },
        {
            "Ir. szám": "2531",
            "name": "Tokod",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.6667",
            "KSH kód": "14155",
            "Jogállás": "5",
            "Terület": "1491",
            "Népesség": "4280",
            "Lakások": "1593"
        },
        {
            "Ir. szám": "2532",
            "name": "Tokodaltáró",
            "Megyekód": "KE",
            "Lat": "47.7444",
            "Long": "18.5972",
            "KSH kód": "34023",
            "Jogállás": "6",
            "Terület": "527",
            "Népesség": "3111",
            "Lakások": "1055"
        },
        {
            "Ir. szám": "2533",
            "name": "Bajót",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.5667",
            "KSH kód": "29355",
            "Jogállás": "6",
            "Terület": "1644",
            "Népesség": "1567",
            "Lakások": "537"
        },
        {
            "Ir. szám": "2534",
            "name": "Tát",
            "Megyekód": "KE",
            "Lat": "47.75",
            "Long": "18.65",
            "KSH kód": "08758",
            "Jogállás": "5",
            "Terület": "1177",
            "Népesség": "5437",
            "Lakások": "2011"
        },
        {
            "Ir. szám": "2535",
            "name": "Mogyorósbánya",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.6",
            "KSH kód": "28255",
            "Jogállás": "6",
            "Terület": "732",
            "Népesség": "870",
            "Lakások": "313"
        },
        {
            "Ir. szám": "2536",
            "name": "Nyergesújfalu",
            "Megyekód": "KE",
            "Lat": "47.7667",
            "Long": "18.55",
            "KSH kód": "15352",
            "Jogállás": "4",
            "Terület": "3950",
            "Népesség": "7660",
            "Lakások": "2896"
        },
        {
            "Ir. szám": "2537",
            "name": "Nyergesújfalu",
            "Megyekód": "KE",
            "Lat": "47.7667",
            "Long": "18.55",
            "KSH kód": "15352",
            "Jogállás": "4",
            "Terület": "3950",
            "Népesség": "7660",
            "Lakások": "2896"
        },
        {
            "Ir. szám": "2541",
            "name": "Lábatlan",
            "Megyekód": "KE",
            "Lat": "47.75",
            "Long": "18.5",
            "KSH kód": "15255",
            "Jogállás": "4",
            "Terület": "2635",
            "Népesség": "5115",
            "Lakások": "2085"
        },
        {
            "Ir. szám": "2542",
            "name": "Lábatlan",
            "Megyekód": "KE",
            "Lat": "47.75",
            "Long": "18.5",
            "KSH kód": "15255",
            "Jogállás": "4",
            "Terület": "2635",
            "Népesség": "5115",
            "Lakások": "2085"
        },
        {
            "Ir. szám": "2543",
            "name": "Süttő",
            "Megyekód": "KE",
            "Lat": "47.75",
            "Long": "18.45",
            "KSH kód": "08688",
            "Jogállás": "6",
            "Terület": "3436",
            "Népesség": "2024",
            "Lakások": "789"
        },
        {
            "Ir. szám": "2544",
            "name": "Neszmély",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.3667",
            "KSH kód": "33826",
            "Jogállás": "6",
            "Terület": "2776",
            "Népesség": "1385",
            "Lakások": "568"
        },
        {
            "Ir. szám": "2545",
            "name": "Dunaalmás",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.3333",
            "KSH kód": "33835",
            "Jogállás": "6",
            "Terület": "1477",
            "Népesség": "1639",
            "Lakások": "599"
        },
        {
            "Ir. szám": "2600",
            "name": "Vác",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "19.1333",
            "KSH kód": "24934",
            "Jogállás": "4",
            "Terület": "6160",
            "Népesség": "33381",
            "Lakások": "14210"
        },
        {
            "Ir. szám": "2601",
            "name": "Vác",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "19.1333",
            "KSH kód": "24934",
            "Jogállás": "4",
            "Terület": "6160",
            "Népesség": "33381",
            "Lakások": "14210"
        },
        {
            "Ir. szám": "2602",
            "name": "Vác",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "19.1333",
            "KSH kód": "24934",
            "Jogállás": "4",
            "Terület": "6160",
            "Népesség": "33381",
            "Lakások": "14210"
        },
        {
            "Ir. szám": "2603",
            "name": "Vác",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "19.1333",
            "KSH kód": "24934",
            "Jogállás": "4",
            "Terület": "6160",
            "Népesség": "33381",
            "Lakások": "14210"
        },
        {
            "Ir. szám": "2610",
            "name": "Nőtincs",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.15",
            "KSH kód": "29425",
            "Jogállás": "6",
            "Terület": "2047",
            "Népesség": "1169",
            "Lakások": "490"
        },
        {
            "Ir. szám": "2611",
            "name": "Felsőpetény",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.2",
            "KSH kód": "24323",
            "Jogállás": "6",
            "Terület": "1450",
            "Népesség": "735",
            "Lakások": "266"
        },
        {
            "Ir. szám": "2612",
            "name": "Kosd",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "19.1833",
            "KSH kód": "27687",
            "Jogállás": "6",
            "Terület": "3408",
            "Népesség": "2507",
            "Lakások": "894"
        },
        {
            "Ir. szám": "2613",
            "name": "Rád",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "19.2333",
            "KSH kód": "02370",
            "Jogállás": "6",
            "Terület": "1774",
            "Népesség": "1870",
            "Lakások": "635"
        },
        {
            "Ir. szám": "2614",
            "name": "Penc",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "19.25",
            "KSH kód": "18689",
            "Jogállás": "6",
            "Terület": "2134",
            "Népesség": "1476",
            "Lakások": "532"
        },
        {
            "Ir. szám": "2615",
            "name": "Csővár",
            "Megyekód": "PE",
            "Lat": "47.8167",
            "Long": "19.3333",
            "KSH kód": "26985",
            "Jogállás": "6",
            "Terület": "1717",
            "Népesség": "671",
            "Lakások": "254"
        },
        {
            "Ir. szám": "2616",
            "name": "Ősagárd",
            "Megyekód": "NO",
            "Lat": "47.8667",
            "Long": "19.2",
            "KSH kód": "19318",
            "Jogállás": "6",
            "Terület": "1092",
            "Népesség": "329",
            "Lakások": "160"
        },
        {
            "Ir. szám": "2616",
            "name": "Keszeg",
            "Megyekód": "NO",
            "Lat": "47.8333",
            "Long": "19.25",
            "KSH kód": "31413",
            "Jogállás": "6",
            "Terület": "995",
            "Népesség": "702",
            "Lakások": "285"
        },
        {
            "Ir. szám": "2617",
            "name": "Alsópetény",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.25",
            "KSH kód": "16425",
            "Jogállás": "6",
            "Terület": "1968",
            "Népesség": "734",
            "Lakások": "315"
        },
        {
            "Ir. szám": "2618",
            "name": "Nézsa",
            "Megyekód": "NO",
            "Lat": "47.85",
            "Long": "19.3",
            "KSH kód": "09797",
            "Jogállás": "6",
            "Terület": "1868",
            "Népesség": "1091",
            "Lakások": "471"
        },
        {
            "Ir. szám": "2619",
            "name": "Legénd",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.3167",
            "KSH kód": "30395",
            "Jogállás": "6",
            "Terület": "1841",
            "Népesség": "504",
            "Lakások": "236"
        },
        {
            "Ir. szám": "2621",
            "name": "Verőce",
            "Megyekód": "PE",
            "Lat": "47.8333",
            "Long": "19.0333",
            "KSH kód": "33729",
            "Jogállás": "6",
            "Terület": "2019",
            "Népesség": "3486",
            "Lakások": "1142"
        },
        {
            "Ir. szám": "2623",
            "name": "Kismaros",
            "Megyekód": "PE",
            "Lat": "47.8333",
            "Long": "19.0333",
            "KSH kód": "33738",
            "Jogállás": "6",
            "Terület": "1211",
            "Népesség": "2009",
            "Lakások": "680"
        },
        {
            "Ir. szám": "2624",
            "name": "Szokolya",
            "Megyekód": "PE",
            "Lat": "47.8667",
            "Long": "19.0167",
            "KSH kód": "06947",
            "Jogállás": "6",
            "Terület": "5905",
            "Népesség": "1791",
            "Lakások": "688"
        },
        {
            "Ir. szám": "2625",
            "name": "Kóspallag",
            "Megyekód": "PE",
            "Lat": "47.8833",
            "Long": "18.9333",
            "KSH kód": "24679",
            "Jogállás": "6",
            "Terület": "1277",
            "Népesség": "783",
            "Lakások": "306"
        },
        {
            "Ir. szám": "2626",
            "name": "Nagymaros",
            "Megyekód": "PE",
            "Lat": "47.7833",
            "Long": "18.9667",
            "KSH kód": "31732",
            "Jogállás": "4",
            "Terület": "3437",
            "Népesség": "4691",
            "Lakások": "1802"
        },
        {
            "Ir. szám": "2627",
            "name": "Zebegény",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "18.9167",
            "KSH kód": "14960",
            "Jogállás": "6",
            "Terület": "964",
            "Népesség": "1181",
            "Lakások": "474"
        },
        {
            "Ir. szám": "2628",
            "name": "Szob",
            "Megyekód": "PE",
            "Lat": "47.8167",
            "Long": "18.8667",
            "KSH kód": "24916",
            "Jogállás": "4",
            "Terület": "1797",
            "Népesség": "2986",
            "Lakások": "1103"
        },
        {
            "Ir. szám": "2629",
            "name": "Márianosztra",
            "Megyekód": "PE",
            "Lat": "47.8667",
            "Long": "18.8833",
            "KSH kód": "04570",
            "Jogállás": "6",
            "Terület": "2026",
            "Népesség": "937",
            "Lakások": "397"
        },
        {
            "Ir. szám": "2631",
            "name": "Ipolydamásd",
            "Megyekód": "PE",
            "Lat": "47.85",
            "Long": "18.8333",
            "KSH kód": "28097",
            "Jogállás": "6",
            "Terület": "1160",
            "Népesség": "354",
            "Lakások": "210"
        },
        {
            "Ir. szám": "2632",
            "name": "Letkés",
            "Megyekód": "PE",
            "Lat": "47.8833",
            "Long": "18.7833",
            "KSH kód": "22682",
            "Jogállás": "6",
            "Terület": "2455",
            "Népesség": "1153",
            "Lakások": "456"
        },
        {
            "Ir. szám": "2633",
            "name": "Ipolytölgyes",
            "Megyekód": "PE",
            "Lat": "47.9167",
            "Long": "18.7833",
            "KSH kód": "04978",
            "Jogállás": "6",
            "Terület": "1366",
            "Népesség": "476",
            "Lakások": "129"
        },
        {
            "Ir. szám": "2634",
            "name": "Nagybörzsöny",
            "Megyekód": "PE",
            "Lat": "47.9333",
            "Long": "18.8333",
            "KSH kód": "14775",
            "Jogállás": "6",
            "Terület": "5069",
            "Népesség": "784",
            "Lakások": "354"
        },
        {
            "Ir. szám": "2635",
            "name": "Vámosmikola",
            "Megyekód": "PE",
            "Lat": "47.9833",
            "Long": "18.7833",
            "KSH kód": "10737",
            "Jogállás": "6",
            "Terület": "2529",
            "Népesség": "1648",
            "Lakások": "632"
        },
        {
            "Ir. szám": "2636",
            "name": "Tésa",
            "Megyekód": "PE",
            "Lat": "48.0333",
            "Long": "18.85",
            "KSH kód": "19248",
            "Jogállás": "6",
            "Terület": "438",
            "Népesség": "84",
            "Lakások": "61"
        },
        {
            "Ir. szám": "2637",
            "name": "Perőcsény",
            "Megyekód": "PE",
            "Lat": "48",
            "Long": "18.8667",
            "KSH kód": "04181",
            "Jogállás": "6",
            "Terület": "4140",
            "Népesség": "349",
            "Lakások": "211"
        },
        {
            "Ir. szám": "2638",
            "name": "Kemence",
            "Megyekód": "PE",
            "Lat": "48.0167",
            "Long": "18.9",
            "KSH kód": "22345",
            "Jogállás": "6",
            "Terület": "4275",
            "Népesség": "1010",
            "Lakások": "558"
        },
        {
            "Ir. szám": "2639",
            "name": "Bernecebaráti",
            "Megyekód": "PE",
            "Lat": "48.0333",
            "Long": "18.9167",
            "KSH kód": "18777",
            "Jogállás": "6",
            "Terület": "3769",
            "Népesség": "860",
            "Lakások": "456"
        },
        {
            "Ir. szám": "2640",
            "name": "Szendehely",
            "Megyekód": "NO",
            "Lat": "47.85",
            "Long": "19.1167",
            "KSH kód": "04507",
            "Jogállás": "6",
            "Terület": "1004",
            "Népesség": "1629",
            "Lakások": "628"
        },
        {
            "Ir. szám": "2641",
            "name": "Berkenye",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.0833",
            "KSH kód": "09034",
            "Jogállás": "6",
            "Terület": "1346",
            "Népesség": "670",
            "Lakások": "253"
        },
        {
            "Ir. szám": "2642",
            "name": "Nógrád",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.05",
            "KSH kód": "04358",
            "Jogállás": "6",
            "Terület": "2954",
            "Népesség": "1572",
            "Lakások": "677"
        },
        {
            "Ir. szám": "2643",
            "name": "Diósjenő",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.05",
            "KSH kód": "06743",
            "Jogállás": "6",
            "Terület": "5750",
            "Népesség": "2937",
            "Lakások": "1065"
        },
        {
            "Ir. szám": "2644",
            "name": "Borsosberény",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.1167",
            "KSH kód": "09894",
            "Jogállás": "6",
            "Terület": "2026",
            "Népesség": "1034",
            "Lakások": "396"
        },
        {
            "Ir. szám": "2645",
            "name": "Nagyoroszi",
            "Megyekód": "NO",
            "Lat": "48",
            "Long": "19.1",
            "KSH kód": "23986",
            "Jogállás": "6",
            "Terület": "4003",
            "Népesség": "2149",
            "Lakások": "849"
        },
        {
            "Ir. szám": "2646",
            "name": "Drégelypalánk",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.05",
            "KSH kód": "08156",
            "Jogállás": "6",
            "Terület": "2218",
            "Népesség": "1580",
            "Lakások": "679"
        },
        {
            "Ir. szám": "2647",
            "name": "Hont",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19",
            "KSH kód": "13204",
            "Jogállás": "6",
            "Terület": "2413",
            "Népesség": "532",
            "Lakások": "313"
        },
        {
            "Ir. szám": "2648",
            "name": "Patak",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.15",
            "KSH kód": "07199",
            "Jogállás": "6",
            "Terület": "1622",
            "Népesség": "925",
            "Lakások": "427"
        },
        {
            "Ir. szám": "2649",
            "name": "Dejtár",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.1667",
            "KSH kód": "12511",
            "Jogállás": "6",
            "Terület": "2174",
            "Népesség": "1472",
            "Lakások": "601"
        },
        {
            "Ir. szám": "2651",
            "name": "Rétság",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.1333",
            "KSH kód": "23825",
            "Jogállás": "4",
            "Terület": "1978",
            "Népesség": "2987",
            "Lakások": "1150"
        },
        {
            "Ir. szám": "2652",
            "name": "Tereske",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.2",
            "KSH kód": "24174",
            "Jogállás": "6",
            "Terület": "1703",
            "Népesség": "730",
            "Lakások": "320"
        },
        {
            "Ir. szám": "2653",
            "name": "Bánk",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.1833",
            "KSH kód": "24341",
            "Jogállás": "6",
            "Terület": "990",
            "Népesség": "691",
            "Lakások": "269"
        },
        {
            "Ir. szám": "2654",
            "name": "Romhány",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.2667",
            "KSH kód": "12195",
            "Jogállás": "6",
            "Terület": "2547",
            "Népesség": "2282",
            "Lakások": "942"
        },
        {
            "Ir. szám": "2655",
            "name": "Kétbodony",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.2667",
            "KSH kód": "11846",
            "Jogállás": "6",
            "Terület": "1305",
            "Népesség": "484",
            "Lakások": "234"
        },
        {
            "Ir. szám": "2655",
            "name": "Kisecset",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.3167",
            "KSH kód": "33206",
            "Jogállás": "6",
            "Terület": "862",
            "Népesség": "176",
            "Lakások": "141"
        },
        {
            "Ir. szám": "2655",
            "name": "Szente",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.2833",
            "KSH kód": "09043",
            "Jogállás": "6",
            "Terület": "753",
            "Népesség": "357",
            "Lakások": "158"
        },
        {
            "Ir. szám": "2656",
            "name": "Szátok",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.2333",
            "KSH kód": "15325",
            "Jogállás": "6",
            "Terület": "895",
            "Népesség": "581",
            "Lakások": "167"
        },
        {
            "Ir. szám": "2657",
            "name": "Tolmács",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.1333",
            "KSH kód": "06381",
            "Jogállás": "6",
            "Terület": "1224",
            "Népesség": "721",
            "Lakások": "337"
        },
        {
            "Ir. szám": "2658",
            "name": "Horpács",
            "Megyekód": "NO",
            "Lat": "48",
            "Long": "19.1333",
            "KSH kód": "13718",
            "Jogállás": "6",
            "Terület": "672",
            "Népesség": "178",
            "Lakások": "92"
        },
        {
            "Ir. szám": "2658",
            "name": "Pusztaberki",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.1667",
            "KSH kód": "17862",
            "Jogállás": "6",
            "Terület": "685",
            "Népesség": "129",
            "Lakások": "106"
        },
        {
            "Ir. szám": "2659",
            "name": "Érsekvadkert",
            "Megyekód": "NO",
            "Lat": "48",
            "Long": "19.2",
            "KSH kód": "21582",
            "Jogállás": "6",
            "Terület": "5537",
            "Népesség": "3650",
            "Lakások": "1391"
        },
        {
            "Ir. szám": "2660",
            "name": "Balassagyarmat",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.3",
            "KSH kód": "13657",
            "Jogállás": "4",
            "Terület": "2374",
            "Népesség": "16580",
            "Lakások": "6730"
        },
        {
            "Ir. szám": "2661",
            "name": "Balassagyarmat",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.3",
            "KSH kód": "13657",
            "Jogállás": "4",
            "Terület": "2374",
            "Népesség": "16580",
            "Lakások": "6730"
        },
        {
            "Ir. szám": "2662",
            "name": "Balassagyarmat",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.3",
            "KSH kód": "13657",
            "Jogállás": "4",
            "Terület": "2374",
            "Népesség": "16580",
            "Lakások": "6730"
        },
        {
            "Ir. szám": "2668",
            "name": "Patvarc",
            "Megyekód": "NO",
            "Lat": "48.0667",
            "Long": "19.35",
            "KSH kód": "33880",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "645",
            "Lakások": "270"
        },
        {
            "Ir. szám": "2669",
            "name": "Ipolyvece",
            "Megyekód": "NO",
            "Lat": "48.0667",
            "Long": "19.1167",
            "KSH kód": "29319",
            "Jogállás": "6",
            "Terület": "1390",
            "Népesség": "879",
            "Lakások": "366"
        },
        {
            "Ir. szám": "2671",
            "name": "Őrhalom",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.4167",
            "KSH kód": "03249",
            "Jogállás": "6",
            "Terület": "1746",
            "Népesség": "1076",
            "Lakások": "403"
        },
        {
            "Ir. szám": "2672",
            "name": "Hugyag",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.4333",
            "KSH kód": "16878",
            "Jogállás": "6",
            "Terület": "1090",
            "Népesség": "886",
            "Lakások": "336"
        },
        {
            "Ir. szám": "2673",
            "name": "Csitár",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.4333",
            "KSH kód": "05050",
            "Jogállás": "6",
            "Terület": "1685",
            "Népesség": "398",
            "Lakások": "209"
        },
        {
            "Ir. szám": "2675",
            "name": "Iliny",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.4333",
            "KSH kód": "26833",
            "Jogállás": "6",
            "Terület": "646",
            "Népesség": "170",
            "Lakások": "92"
        },
        {
            "Ir. szám": "2675",
            "name": "Nógrádmarcal",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.4",
            "KSH kód": "29832",
            "Jogállás": "6",
            "Terület": "1994",
            "Népesség": "555",
            "Lakások": "216"
        },
        {
            "Ir. szám": "2676",
            "name": "Cserhátsurány",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.4333",
            "KSH kód": "22594",
            "Jogállás": "6",
            "Terület": "1880",
            "Népesség": "857",
            "Lakások": "364"
        },
        {
            "Ir. szám": "2677",
            "name": "Herencsény",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.4833",
            "KSH kód": "05324",
            "Jogállás": "6",
            "Terület": "3318",
            "Népesség": "653",
            "Lakások": "298"
        },
        {
            "Ir. szám": "2678",
            "name": "Csesztve",
            "Megyekód": "NO",
            "Lat": "48.0167",
            "Long": "19.2833",
            "KSH kód": "20145",
            "Jogállás": "6",
            "Terület": "1625",
            "Népesség": "327",
            "Lakások": "170"
        },
        {
            "Ir. szám": "2681",
            "name": "Galgagyörk",
            "Megyekód": "PE",
            "Lat": "47.7333",
            "Long": "19.3833",
            "KSH kód": "13295",
            "Jogállás": "6",
            "Terület": "1471",
            "Népesség": "1075",
            "Lakások": "346"
        },
        {
            "Ir. szám": "2682",
            "name": "Püspökhatvan",
            "Megyekód": "PE",
            "Lat": "47.7667",
            "Long": "19.3667",
            "KSH kód": "21388",
            "Jogállás": "6",
            "Terület": "2472",
            "Népesség": "1525",
            "Lakások": "503"
        },
        {
            "Ir. szám": "2683",
            "name": "Acsa",
            "Megyekód": "PE",
            "Lat": "47.8",
            "Long": "19.3833",
            "KSH kód": "18573",
            "Jogállás": "6",
            "Terület": "2694",
            "Népesség": "1513",
            "Lakások": "473"
        },
        {
            "Ir. szám": "2685",
            "name": "Nógrádsáp",
            "Megyekód": "NO",
            "Lat": "47.8333",
            "Long": "19.35",
            "KSH kód": "08387",
            "Jogállás": "6",
            "Terület": "1546",
            "Népesség": "855",
            "Lakások": "367"
        },
        {
            "Ir. szám": "2686",
            "name": "Galgaguta",
            "Megyekód": "NO",
            "Lat": "47.85",
            "Long": "19.4",
            "KSH kód": "25663",
            "Jogállás": "6",
            "Terület": "1589",
            "Népesség": "659",
            "Lakások": "303"
        },
        {
            "Ir. szám": "2687",
            "name": "Bercel",
            "Megyekód": "NO",
            "Lat": "47.8667",
            "Long": "19.4167",
            "KSH kód": "02389",
            "Jogállás": "6",
            "Terület": "3588",
            "Népesség": "2020",
            "Lakások": "739"
        },
        {
            "Ir. szám": "2688",
            "name": "Vanyarc",
            "Megyekód": "NO",
            "Lat": "47.8333",
            "Long": "19.45",
            "KSH kód": "30915",
            "Jogállás": "6",
            "Terület": "3222",
            "Népesség": "1306",
            "Lakások": "505"
        },
        {
            "Ir. szám": "2691",
            "name": "Nógrádkövesd",
            "Megyekód": "NO",
            "Lat": "47.8667",
            "Long": "19.3833",
            "KSH kód": "32300",
            "Jogállás": "6",
            "Terület": "880",
            "Népesség": "713",
            "Lakások": "291"
        },
        {
            "Ir. szám": "2692",
            "name": "Szécsénke",
            "Megyekód": "NO",
            "Lat": "47.9",
            "Long": "19.3333",
            "KSH kód": "23047",
            "Jogállás": "6",
            "Terület": "978",
            "Népesség": "226",
            "Lakások": "125"
        },
        {
            "Ir. szám": "2693",
            "name": "Becske",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.3833",
            "KSH kód": "12016",
            "Jogállás": "6",
            "Terület": "1566",
            "Népesség": "574",
            "Lakások": "286"
        },
        {
            "Ir. szám": "2694",
            "name": "Magyarnándor",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.35",
            "KSH kód": "32407",
            "Jogállás": "6",
            "Terület": "1867",
            "Népesség": "1127",
            "Lakások": "466"
        },
        {
            "Ir. szám": "2694",
            "name": "Debercsény",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.3167",
            "KSH kód": "07320",
            "Jogállás": "6",
            "Terület": "543",
            "Népesség": "77",
            "Lakások": "51"
        },
        {
            "Ir. szám": "2694",
            "name": "Cserháthaláp",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.3667",
            "KSH kód": "21935",
            "Jogállás": "6",
            "Terület": "1047",
            "Népesség": "371",
            "Lakások": "179"
        },
        {
            "Ir. szám": "2696",
            "name": "Terény",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.45",
            "KSH kód": "04844",
            "Jogállás": "6",
            "Terület": "2435",
            "Népesség": "435",
            "Lakások": "252"
        },
        {
            "Ir. szám": "2697",
            "name": "Szanda",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.45",
            "KSH kód": "13754",
            "Jogállás": "6",
            "Terület": "2149",
            "Népesség": "622",
            "Lakások": "418"
        },
        {
            "Ir. szám": "2698",
            "name": "Mohora",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.3333",
            "KSH kód": "27915",
            "Jogállás": "6",
            "Terület": "1595",
            "Népesség": "968",
            "Lakások": "379"
        },
        {
            "Ir. szám": "2699",
            "name": "Szügy",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.3333",
            "KSH kód": "18786",
            "Jogállás": "6",
            "Terület": "1951",
            "Népesség": "1425",
            "Lakások": "534"
        },
        {
            "Ir. szám": "2700",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2701",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2702",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2703",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2704",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2711",
            "name": "Tápiószentmárton",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "19.75",
            "KSH kód": "14571",
            "Jogállás": "5",
            "Terület": "10291",
            "Népesség": "5505",
            "Lakások": "2449"
        },
        {
            "Ir. szám": "2712",
            "name": "Nyársapát",
            "Megyekód": "PE",
            "Lat": "47.1",
            "Long": "19.8",
            "KSH kód": "20066",
            "Jogállás": "6",
            "Terület": "5403",
            "Népesség": "1828",
            "Lakások": "814"
        },
        {
            "Ir. szám": "2713",
            "name": "Csemő",
            "Megyekód": "PE",
            "Lat": "47.1167",
            "Long": "19.7",
            "KSH kód": "05184",
            "Jogállás": "6",
            "Terület": "7944",
            "Népesség": "4298",
            "Lakások": "1882"
        },
        {
            "Ir. szám": "2721",
            "name": "Pilis",
            "Megyekód": "PE",
            "Lat": "47.2833",
            "Long": "19.55",
            "KSH kód": "09821",
            "Jogállás": "4",
            "Terület": "4730",
            "Népesség": "11410",
            "Lakások": "4045"
        },
        {
            "Ir. szám": "2723",
            "name": "Nyáregyháza",
            "Megyekód": "PE",
            "Lat": "47.25",
            "Long": "19.5",
            "KSH kód": "23038",
            "Jogállás": "6",
            "Terület": "3199",
            "Népesség": "3957",
            "Lakások": "1393"
        },
        {
            "Ir. szám": "2724",
            "name": "Újlengyel",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.45",
            "KSH kód": "19682",
            "Jogállás": "6",
            "Terület": "2625",
            "Népesség": "1757",
            "Lakások": "675"
        },
        {
            "Ir. szám": "2730",
            "name": "Albertirsa",
            "Megyekód": "PE",
            "Lat": "47.25",
            "Long": "19.6167",
            "KSH kód": "31653",
            "Jogállás": "4",
            "Terület": "7296",
            "Népesség": "12348",
            "Lakások": "4802"
        },
        {
            "Ir. szám": "2735",
            "name": "Dánszentmiklós",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.55",
            "KSH kód": "31811",
            "Jogállás": "6",
            "Terület": "3801",
            "Népesség": "2869",
            "Lakások": "1058"
        },
        {
            "Ir. szám": "2736",
            "name": "Mikebuda",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.6333",
            "KSH kód": "24466",
            "Jogállás": "6",
            "Terület": "4217",
            "Népesség": "732",
            "Lakások": "351"
        },
        {
            "Ir. szám": "2737",
            "name": "Ceglédbercel",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.6833",
            "KSH kód": "20640",
            "Jogállás": "6",
            "Terület": "2815",
            "Népesség": "4440",
            "Lakások": "1655"
        },
        {
            "Ir. szám": "2738",
            "name": "Ceglédbercel",
            "Megyekód": "PE",
            "Lat": "47.2167",
            "Long": "19.6833",
            "KSH kód": "20640",
            "Jogállás": "6",
            "Terület": "2815",
            "Népesség": "4440",
            "Lakások": "1655"
        },
        {
            "Ir. szám": "2740",
            "name": "Abony",
            "Megyekód": "PE",
            "Lat": "47.1833",
            "Long": "20",
            "KSH kód": "27872",
            "Jogállás": "4",
            "Terület": "12797",
            "Népesség": "15498",
            "Lakások": "6143"
        },
        {
            "Ir. szám": "2745",
            "name": "Kőröstetétlen",
            "Megyekód": "PE",
            "Lat": "47.1",
            "Long": "20.0333",
            "KSH kód": "32975",
            "Jogállás": "6",
            "Terület": "3265",
            "Népesség": "844",
            "Lakások": "363"
        },
        {
            "Ir. szám": "2746",
            "name": "Jászkarajenő",
            "Megyekód": "PE",
            "Lat": "47.05",
            "Long": "20.0667",
            "KSH kód": "11004",
            "Jogállás": "6",
            "Terület": "6515",
            "Népesség": "2811",
            "Lakások": "1427"
        },
        {
            "Ir. szám": "2747",
            "name": "Törtel",
            "Megyekód": "PE",
            "Lat": "47.1167",
            "Long": "19.9333",
            "KSH kód": "22008",
            "Jogállás": "6",
            "Terület": "8416",
            "Népesség": "4367",
            "Lakások": "1872"
        },
        {
            "Ir. szám": "2750",
            "name": "Nagykőrös",
            "Megyekód": "PE",
            "Lat": "47.0333",
            "Long": "19.7833",
            "KSH kód": "19716",
            "Jogállás": "4",
            "Terület": "22794",
            "Népesség": "25105",
            "Lakások": "10392"
        },
        {
            "Ir. szám": "2751",
            "name": "Nagykőrös",
            "Megyekód": "PE",
            "Lat": "47.0333",
            "Long": "19.7833",
            "KSH kód": "19716",
            "Jogállás": "4",
            "Terület": "22794",
            "Népesség": "25105",
            "Lakások": "10392"
        },
        {
            "Ir. szám": "2752",
            "name": "Nagykőrös",
            "Megyekód": "PE",
            "Lat": "47.0333",
            "Long": "19.7833",
            "KSH kód": "19716",
            "Jogállás": "4",
            "Terület": "22794",
            "Népesség": "25105",
            "Lakások": "10392"
        },
        {
            "Ir. szám": "2755",
            "name": "Kocsér",
            "Megyekód": "PE",
            "Lat": "47.0053",
            "Long": "19.9114",
            "KSH kód": "32771",
            "Jogállás": "6",
            "Terület": "6728",
            "Népesség": "1947",
            "Lakások": "1011"
        },
        {
            "Ir. szám": "2760",
            "name": "Nagykáta",
            "Megyekód": "PE",
            "Lat": "47.4167",
            "Long": "19.75",
            "KSH kód": "13435",
            "Jogállás": "4",
            "Terület": "8161",
            "Népesség": "12739",
            "Lakások": "5001"
        },
        {
            "Ir. szám": "2761",
            "name": "Nagykáta",
            "Megyekód": "PE",
            "Lat": "47.4167",
            "Long": "19.75",
            "KSH kód": "13435",
            "Jogállás": "4",
            "Terület": "8161",
            "Népesség": "12739",
            "Lakások": "5001"
        },
        {
            "Ir. szám": "2764",
            "name": "Tápióbicske",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "19.6833",
            "KSH kód": "15015",
            "Jogállás": "6",
            "Terület": "4848",
            "Népesség": "3512",
            "Lakások": "1512"
        },
        {
            "Ir. szám": "2765",
            "name": "Farmos",
            "Megyekód": "PE",
            "Lat": "47.3667",
            "Long": "19.85",
            "KSH kód": "09122",
            "Jogállás": "6",
            "Terület": "4012",
            "Népesség": "3754",
            "Lakások": "1551"
        },
        {
            "Ir. szám": "2766",
            "name": "Tápiószele",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "19.8833",
            "KSH kód": "14146",
            "Jogállás": "5",
            "Terület": "3699",
            "Népesség": "6207",
            "Lakások": "2418"
        },
        {
            "Ir. szám": "2767",
            "name": "Tápiógyörgye",
            "Megyekód": "PE",
            "Lat": "47.3333",
            "Long": "19.95",
            "KSH kód": "17303",
            "Jogállás": "6",
            "Terület": "5331",
            "Népesség": "3756",
            "Lakások": "1569"
        },
        {
            "Ir. szám": "2768",
            "name": "Újszilvás",
            "Megyekód": "PE",
            "Lat": "47.2833",
            "Long": "19.9333",
            "KSH kód": "17808",
            "Jogállás": "6",
            "Terület": "3898",
            "Népesség": "2708",
            "Lakások": "1200"
        },
        {
            "Ir. szám": "2769",
            "name": "Tápiószőlős",
            "Megyekód": "PE",
            "Lat": "47.3",
            "Long": "19.85",
            "KSH kód": "02769",
            "Jogállás": "6",
            "Terület": "3179",
            "Népesség": "3036",
            "Lakások": "1314"
        },
        {
            "Ir. szám": "2800",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2801",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2802",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2803",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2804",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2805",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2806",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2807",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2808",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2809",
            "name": "Tatabánya",
            "Megyekód": "KE",
            "Lat": "47.5667",
            "Long": "18.4167",
            "KSH kód": "18157",
            "Jogállás": "1",
            "Terület": "9143",
            "Népesség": "70333",
            "Lakások": "29978"
        },
        {
            "Ir. szám": "2821",
            "name": "Gyermely",
            "Megyekód": "KE",
            "Lat": "47.6",
            "Long": "18.65",
            "KSH kód": "06521",
            "Jogállás": "6",
            "Terület": "4545",
            "Népesség": "1454",
            "Lakások": "519"
        },
        {
            "Ir. szám": "2822",
            "name": "Szomor",
            "Megyekód": "KE",
            "Lat": "47.5833",
            "Long": "18.6667",
            "KSH kód": "21421",
            "Jogállás": "6",
            "Terület": "1319",
            "Népesség": "1135",
            "Lakások": "396"
        },
        {
            "Ir. szám": "2823",
            "name": "Vértessomló",
            "Megyekód": "KE",
            "Lat": "47.5167",
            "Long": "18.3667",
            "KSH kód": "15282",
            "Jogállás": "6",
            "Terület": "2229",
            "Népesség": "1348",
            "Lakások": "499"
        },
        {
            "Ir. szám": "2824",
            "name": "Várgesztes",
            "Megyekód": "KE",
            "Lat": "47.4667",
            "Long": "18.4",
            "KSH kód": "17251",
            "Jogállás": "6",
            "Terület": "1204",
            "Népesség": "569",
            "Lakások": "250"
        },
        {
            "Ir. szám": "2831",
            "name": "Tarján",
            "Megyekód": "KE",
            "Lat": "47.6167",
            "Long": "18.5167",
            "KSH kód": "18935",
            "Jogállás": "6",
            "Terület": "4307",
            "Népesség": "2744",
            "Lakások": "1074"
        },
        {
            "Ir. szám": "2832",
            "name": "Héreg",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.5167",
            "KSH kód": "11891",
            "Jogállás": "6",
            "Terület": "2713",
            "Népesség": "1030",
            "Lakások": "443"
        },
        {
            "Ir. szám": "2833",
            "name": "Vértestolna",
            "Megyekód": "KE",
            "Lat": "47.6333",
            "Long": "18.45",
            "KSH kód": "29629",
            "Jogállás": "6",
            "Terület": "1695",
            "Népesség": "548",
            "Lakások": "217"
        },
        {
            "Ir. szám": "2834",
            "name": "Tardos",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.45",
            "KSH kód": "30225",
            "Jogállás": "6",
            "Terület": "2332",
            "Népesség": "1630",
            "Lakások": "680"
        },
        {
            "Ir. szám": "2835",
            "name": "Tardos",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.45",
            "KSH kód": "30225",
            "Jogállás": "6",
            "Terület": "2332",
            "Népesség": "1630",
            "Lakások": "680"
        },
        {
            "Ir. szám": "2836",
            "name": "Baj",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3667",
            "KSH kód": "29212",
            "Jogállás": "6",
            "Terület": "2113",
            "Népesség": "2838",
            "Lakások": "1043"
        },
        {
            "Ir. szám": "2837",
            "name": "Vértesszőlős",
            "Megyekód": "KE",
            "Lat": "47.6167",
            "Long": "18.3833",
            "KSH kód": "31264",
            "Jogállás": "6",
            "Terület": "1713",
            "Népesség": "3059",
            "Lakások": "977"
        },
        {
            "Ir. szám": "2840",
            "name": "Oroszlány",
            "Megyekód": "KE",
            "Lat": "47.4833",
            "Long": "18.3167",
            "KSH kód": "30766",
            "Jogállás": "4",
            "Terület": "7586",
            "Népesség": "18968",
            "Lakások": "7905"
        },
        {
            "Ir. szám": "2841",
            "name": "Oroszlány",
            "Megyekód": "KE",
            "Lat": "47.4833",
            "Long": "18.3167",
            "KSH kód": "30766",
            "Jogállás": "4",
            "Terület": "7586",
            "Népesség": "18968",
            "Lakások": "7905"
        },
        {
            "Ir. szám": "2842",
            "name": "Oroszlány",
            "Megyekód": "KE",
            "Lat": "47.4833",
            "Long": "18.3167",
            "KSH kód": "30766",
            "Jogállás": "4",
            "Terület": "7586",
            "Népesség": "18968",
            "Lakások": "7905"
        },
        {
            "Ir. szám": "2851",
            "name": "Környe",
            "Megyekód": "KE",
            "Lat": "47.55",
            "Long": "18.3333",
            "KSH kód": "30553",
            "Jogállás": "6",
            "Terület": "4534",
            "Népesség": "4454",
            "Lakások": "1643"
        },
        {
            "Ir. szám": "2852",
            "name": "Kecskéd",
            "Megyekód": "KE",
            "Lat": "47.5167",
            "Long": "18.3167",
            "KSH kód": "04525",
            "Jogállás": "6",
            "Terület": "1108",
            "Népesség": "2021",
            "Lakások": "667"
        },
        {
            "Ir. szám": "2853",
            "name": "Kömlőd",
            "Megyekód": "KE",
            "Lat": "47.55",
            "Long": "18.2667",
            "KSH kód": "07630",
            "Jogállás": "6",
            "Terület": "2284",
            "Népesség": "1249",
            "Lakások": "378"
        },
        {
            "Ir. szám": "2854",
            "name": "Dad",
            "Megyekód": "KE",
            "Lat": "47.5167",
            "Long": "18.2333",
            "KSH kód": "33163",
            "Jogállás": "6",
            "Terület": "2375",
            "Népesség": "1019",
            "Lakások": "389"
        },
        {
            "Ir. szám": "2855",
            "name": "Bokod",
            "Megyekód": "KE",
            "Lat": "47.5",
            "Long": "18.25",
            "KSH kód": "07311",
            "Jogállás": "6",
            "Terület": "2992",
            "Népesség": "2245",
            "Lakások": "807"
        },
        {
            "Ir. szám": "2856",
            "name": "Szákszend",
            "Megyekód": "KE",
            "Lat": "47.55",
            "Long": "18.1667",
            "KSH kód": "33516",
            "Jogállás": "6",
            "Terület": "3593",
            "Népesség": "1534",
            "Lakások": "643"
        },
        {
            "Ir. szám": "2858",
            "name": "Császár",
            "Megyekód": "KE",
            "Lat": "47.5",
            "Long": "18.15",
            "KSH kód": "16416",
            "Jogállás": "6",
            "Terület": "6780",
            "Népesség": "1777",
            "Lakások": "717"
        },
        {
            "Ir. szám": "2859",
            "name": "Vérteskethely",
            "Megyekód": "KE",
            "Lat": "47.4833",
            "Long": "18.0833",
            "KSH kód": "32586",
            "Jogállás": "6",
            "Terület": "1747",
            "Népesség": "576",
            "Lakások": "235"
        },
        {
            "Ir. szám": "2861",
            "name": "Bakonysárkány",
            "Megyekód": "KE",
            "Lat": "47.45",
            "Long": "18.1",
            "KSH kód": "25229",
            "Jogállás": "6",
            "Terület": "1413",
            "Népesség": "953",
            "Lakások": "389"
        },
        {
            "Ir. szám": "2862",
            "name": "Aka",
            "Megyekód": "KE",
            "Lat": "47.4",
            "Long": "18.0667",
            "KSH kód": "06682",
            "Jogállás": "6",
            "Terület": "1811",
            "Népesség": "257",
            "Lakások": "132"
        },
        {
            "Ir. szám": "2870",
            "name": "Kisbér",
            "Megyekód": "KE",
            "Lat": "47.5",
            "Long": "18.0333",
            "KSH kód": "17330",
            "Jogállás": "4",
            "Terület": "5218",
            "Népesség": "5516",
            "Lakások": "2181"
        },
        {
            "Ir. szám": "2879",
            "name": "Kisbér",
            "Megyekód": "KE",
            "Lat": "47.5",
            "Long": "18.0333",
            "KSH kód": "17330",
            "Jogállás": "4",
            "Terület": "5218",
            "Népesség": "5516",
            "Lakások": "2181"
        },
        {
            "Ir. szám": "2881",
            "name": "Ászár",
            "Megyekód": "KE",
            "Lat": "47.5042",
            "Long": "18",
            "KSH kód": "23852",
            "Jogállás": "6",
            "Terület": "1869",
            "Népesség": "1946",
            "Lakások": "703"
        },
        {
            "Ir. szám": "2882",
            "name": "Kerékteleki",
            "Megyekód": "KE",
            "Lat": "47.5167",
            "Long": "17.9333",
            "KSH kód": "10995",
            "Jogállás": "6",
            "Terület": "2946",
            "Népesség": "675",
            "Lakások": "280"
        },
        {
            "Ir. szám": "2883",
            "name": "Bársonyos",
            "Megyekód": "KE",
            "Lat": "47.5167",
            "Long": "17.9167",
            "KSH kód": "08624",
            "Jogállás": "6",
            "Terület": "1687",
            "Népesség": "761",
            "Lakások": "303"
        },
        {
            "Ir. szám": "2884",
            "name": "Bakonyszombathely",
            "Megyekód": "KE",
            "Lat": "47.4667",
            "Long": "17.9667",
            "KSH kód": "22381",
            "Jogállás": "6",
            "Terület": "3653",
            "Népesség": "1460",
            "Lakások": "607"
        },
        {
            "Ir. szám": "2885",
            "name": "Bakonybánk",
            "Megyekód": "KE",
            "Lat": "47.4667",
            "Long": "17.9",
            "KSH kód": "24244",
            "Jogállás": "6",
            "Terület": "1507",
            "Népesség": "457",
            "Lakások": "219"
        },
        {
            "Ir. szám": "2886",
            "name": "Réde",
            "Megyekód": "KE",
            "Lat": "47.4333",
            "Long": "17.9167",
            "KSH kód": "30012",
            "Jogállás": "6",
            "Terület": "4588",
            "Népesség": "1405",
            "Lakások": "566"
        },
        {
            "Ir. szám": "2887",
            "name": "Ácsteszér",
            "Megyekód": "KE",
            "Lat": "47.4",
            "Long": "18.0167",
            "KSH kód": "18139",
            "Jogállás": "6",
            "Terület": "1773",
            "Népesség": "708",
            "Lakások": "286"
        },
        {
            "Ir. szám": "2888",
            "name": "Csatka",
            "Megyekód": "KE",
            "Lat": "47.3667",
            "Long": "17.9833",
            "KSH kód": "33109",
            "Jogállás": "6",
            "Terület": "1777",
            "Népesség": "265",
            "Lakások": "171"
        },
        {
            "Ir. szám": "2889",
            "name": "Súr",
            "Megyekód": "KE",
            "Lat": "47.3667",
            "Long": "18.0333",
            "KSH kód": "31990",
            "Jogállás": "6",
            "Terület": "3736",
            "Népesség": "1274",
            "Lakások": "517"
        },
        {
            "Ir. szám": "2890",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2891",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2892",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2893",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2894",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2896",
            "name": "Szomód",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.35",
            "KSH kód": "22619",
            "Jogállás": "6",
            "Terület": "2835",
            "Népesség": "2104",
            "Lakások": "722"
        },
        {
            "Ir. szám": "2897",
            "name": "Dunaszentmiklós",
            "Megyekód": "KE",
            "Lat": "47.7",
            "Long": "18.3833",
            "KSH kód": "24101",
            "Jogállás": "6",
            "Terület": "777",
            "Népesség": "452",
            "Lakások": "178"
        },
        {
            "Ir. szám": "2898",
            "name": "Kocs",
            "Megyekód": "KE",
            "Lat": "47.6",
            "Long": "18.2167",
            "KSH kód": "02510",
            "Jogállás": "6",
            "Terület": "5826",
            "Népesség": "2636",
            "Lakások": "960"
        },
        {
            "Ir. szám": "2899",
            "name": "Naszály",
            "Megyekód": "KE",
            "Lat": "47.7",
            "Long": "18.2667",
            "KSH kód": "20163",
            "Jogállás": "6",
            "Terület": "3023",
            "Népesség": "2359",
            "Lakások": "835"
        },
        {
            "Ir. szám": "2900",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "2901",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "2902",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "2903",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "2904",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "2911",
            "name": "Mocsa",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.1833",
            "KSH kód": "26930",
            "Jogállás": "6",
            "Terület": "6722",
            "Népesség": "2257",
            "Lakások": "832"
        },
        {
            "Ir. szám": "2921",
            "name": "Mocsa",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.1833",
            "KSH kód": "26930",
            "Jogállás": "6",
            "Terület": "6722",
            "Népesség": "2257",
            "Lakások": "832"
        },
        {
            "Ir. szám": "2922",
            "name": "Mocsa",
            "Megyekód": "KE",
            "Lat": "47.6667",
            "Long": "18.1833",
            "KSH kód": "26930",
            "Jogállás": "6",
            "Terület": "6722",
            "Népesség": "2257",
            "Lakások": "832"
        },
        {
            "Ir. szám": "2931",
            "name": "Almásfüzitő",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.2667",
            "KSH kód": "32346",
            "Jogállás": "6",
            "Terület": "819",
            "Népesség": "2246",
            "Lakások": "1146"
        },
        {
            "Ir. szám": "2932",
            "name": "Almásfüzitő",
            "Megyekód": "KE",
            "Lat": "47.7167",
            "Long": "18.2667",
            "KSH kód": "32346",
            "Jogállás": "6",
            "Terület": "819",
            "Népesség": "2246",
            "Lakások": "1146"
        },
        {
            "Ir. szám": "2941",
            "name": "Ács",
            "Megyekód": "KE",
            "Lat": "47.7",
            "Long": "18.0167",
            "KSH kód": "04428",
            "Jogállás": "4",
            "Terület": "10384",
            "Népesség": "7156",
            "Lakások": "2675"
        },
        {
            "Ir. szám": "2942",
            "name": "Nagyigmánd",
            "Megyekód": "KE",
            "Lat": "47.6333",
            "Long": "18.0833",
            "KSH kód": "22372",
            "Jogállás": "5",
            "Terület": "5139",
            "Népesség": "3014",
            "Lakások": "1178"
        },
        {
            "Ir. szám": "2943",
            "name": "Bábolna",
            "Megyekód": "KE",
            "Lat": "47.6333",
            "Long": "17.9833",
            "KSH kód": "19363",
            "Jogállás": "4",
            "Terület": "3359",
            "Népesség": "3756",
            "Lakások": "1366"
        },
        {
            "Ir. szám": "2944",
            "name": "Bana",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "17.9167",
            "KSH kód": "31422",
            "Jogállás": "6",
            "Terület": "2514",
            "Népesség": "1685",
            "Lakások": "564"
        },
        {
            "Ir. szám": "2945",
            "name": "Tárkány",
            "Megyekód": "KE",
            "Lat": "47.5833",
            "Long": "18",
            "KSH kód": "20987",
            "Jogállás": "6",
            "Terület": "6498",
            "Népesség": "1620",
            "Lakások": "627"
        },
        {
            "Ir. szám": "2946",
            "name": "Csép",
            "Megyekód": "KE",
            "Lat": "47.5833",
            "Long": "18.0667",
            "KSH kód": "18272",
            "Jogállás": "6",
            "Terület": "2006",
            "Népesség": "366",
            "Lakások": "157"
        },
        {
            "Ir. szám": "2947",
            "name": "Ete",
            "Megyekód": "KE",
            "Lat": "47.5",
            "Long": "18.0333",
            "KSH kód": "06664",
            "Jogállás": "6",
            "Terület": "2061",
            "Népesség": "591",
            "Lakások": "248"
        },
        {
            "Ir. szám": "2948",
            "name": "Kisigmánd",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.1",
            "KSH kód": "20923",
            "Jogállás": "6",
            "Terület": "1314",
            "Népesség": "524",
            "Lakások": "203"
        },
        {
            "Ir. szám": "2949",
            "name": "Csém",
            "Megyekód": "KE",
            "Lat": "47.6833",
            "Long": "18.1",
            "KSH kód": "33640",
            "Jogállás": "6",
            "Terület": "629",
            "Népesség": "477",
            "Lakások": "169"
        },
        {
            "Ir. szám": "3000",
            "name": "Hatvan",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "19.6833",
            "KSH kód": "22309",
            "Jogállás": "4",
            "Terület": "6617",
            "Népesség": "20949",
            "Lakások": "8949"
        },
        {
            "Ir. szám": "3001",
            "name": "Hatvan",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "19.6833",
            "KSH kód": "22309",
            "Jogállás": "4",
            "Terület": "6617",
            "Népesség": "20949",
            "Lakások": "8949"
        },
        {
            "Ir. szám": "3002",
            "name": "Hatvan",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "19.6833",
            "KSH kód": "22309",
            "Jogállás": "4",
            "Terület": "6617",
            "Népesség": "20949",
            "Lakások": "8949"
        },
        {
            "Ir. szám": "3003",
            "name": "Hatvan",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "19.6833",
            "KSH kód": "22309",
            "Jogállás": "4",
            "Terület": "6617",
            "Népesség": "20949",
            "Lakások": "8949"
        },
        {
            "Ir. szám": "3009",
            "name": "Hatvan",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "19.6833",
            "KSH kód": "22309",
            "Jogállás": "4",
            "Terület": "6617",
            "Népesség": "20949",
            "Lakások": "8949"
        },
        {
            "Ir. szám": "3011",
            "name": "Heréd",
            "Megyekód": "HE",
            "Lat": "47.7",
            "Long": "19.6333",
            "KSH kód": "20242",
            "Jogállás": "6",
            "Terület": "1396",
            "Népesség": "2074",
            "Lakások": "777"
        },
        {
            "Ir. szám": "3012",
            "name": "Nagykökényes",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.6",
            "KSH kód": "24943",
            "Jogállás": "6",
            "Terület": "1728",
            "Népesség": "606",
            "Lakások": "259"
        },
        {
            "Ir. szám": "3013",
            "name": "Ecséd",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.7833",
            "KSH kód": "17181",
            "Jogállás": "6",
            "Terület": "4160",
            "Népesség": "3335",
            "Lakások": "1450"
        },
        {
            "Ir. szám": "3014",
            "name": "Hort",
            "Megyekód": "HE",
            "Lat": "47.7",
            "Long": "19.7833",
            "KSH kód": "04145",
            "Jogállás": "6",
            "Terület": "4309",
            "Népesség": "3839",
            "Lakások": "1602"
        },
        {
            "Ir. szám": "3015",
            "name": "Csány",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "19.8333",
            "KSH kód": "16841",
            "Jogállás": "6",
            "Terület": "4789",
            "Népesség": "2308",
            "Lakások": "987"
        },
        {
            "Ir. szám": "3016",
            "name": "Boldog",
            "Megyekód": "HE",
            "Lat": "47.6",
            "Long": "19.7",
            "KSH kód": "03452",
            "Jogállás": "6",
            "Terület": "2674",
            "Népesség": "3067",
            "Lakások": "1174"
        },
        {
            "Ir. szám": "3021",
            "name": "Lőrinci",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.6833",
            "KSH kód": "30401",
            "Jogállás": "4",
            "Terület": "2353",
            "Népesség": "6126",
            "Lakások": "2318"
        },
        {
            "Ir. szám": "3022",
            "name": "Lőrinci",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.6833",
            "KSH kód": "30401",
            "Jogállás": "4",
            "Terület": "2353",
            "Népesség": "6126",
            "Lakások": "2318"
        },
        {
            "Ir. szám": "3023",
            "name": "Petőfibánya",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "19.7167",
            "KSH kód": "33686",
            "Jogállás": "6",
            "Terület": "1111",
            "Népesség": "2869",
            "Lakások": "1357"
        },
        {
            "Ir. szám": "3024",
            "name": "Petőfibánya",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "19.7167",
            "KSH kód": "33686",
            "Jogállás": "6",
            "Terület": "1111",
            "Népesség": "2869",
            "Lakások": "1357"
        },
        {
            "Ir. szám": "3031",
            "name": "Zagyvaszántó",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.6833",
            "KSH kód": "21722",
            "Jogállás": "6",
            "Terület": "952",
            "Népesség": "2014",
            "Lakások": "777"
        },
        {
            "Ir. szám": "3032",
            "name": "Apc",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "19.7",
            "KSH kód": "07241",
            "Jogállás": "6",
            "Terület": "2046",
            "Népesség": "2655",
            "Lakások": "1106"
        },
        {
            "Ir. szám": "3033",
            "name": "Rózsaszentmárton",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.75",
            "KSH kód": "27650",
            "Jogállás": "6",
            "Terület": "1631",
            "Népesség": "2043",
            "Lakások": "890"
        },
        {
            "Ir. szám": "3034",
            "name": "Szűcsi",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "19.7667",
            "KSH kód": "09982",
            "Jogállás": "6",
            "Terület": "1738",
            "Népesség": "1605",
            "Lakások": "663"
        },
        {
            "Ir. szám": "3035",
            "name": "Gyöngyöspata",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "19.8",
            "KSH kód": "08323",
            "Jogállás": "6",
            "Terület": "6075",
            "Népesség": "2583",
            "Lakások": "1069"
        },
        {
            "Ir. szám": "3036",
            "name": "Gyöngyöstarján",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "19.8667",
            "KSH kód": "28088",
            "Jogállás": "6",
            "Terület": "4639",
            "Népesség": "2514",
            "Lakások": "983"
        },
        {
            "Ir. szám": "3041",
            "name": "Héhalom",
            "Megyekód": "NO",
            "Lat": "47.8",
            "Long": "19.6",
            "KSH kód": "03993",
            "Jogállás": "6",
            "Terület": "1867",
            "Népesség": "1011",
            "Lakások": "407"
        },
        {
            "Ir. szám": "3042",
            "name": "Palotás",
            "Megyekód": "NO",
            "Lat": "47.8",
            "Long": "19.6",
            "KSH kód": "05883",
            "Jogállás": "6",
            "Terület": "1707",
            "Népesség": "1667",
            "Lakások": "665"
        },
        {
            "Ir. szám": "3043",
            "name": "Egyházasdengeleg",
            "Megyekód": "NO",
            "Lat": "47.8",
            "Long": "19.5667",
            "KSH kód": "17659",
            "Jogállás": "6",
            "Terület": "1575",
            "Népesség": "480",
            "Lakások": "219"
        },
        {
            "Ir. szám": "3044",
            "name": "Szirák",
            "Megyekód": "NO",
            "Lat": "47.8333",
            "Long": "19.5333",
            "KSH kód": "21634",
            "Jogállás": "6",
            "Terület": "1909",
            "Népesség": "1141",
            "Lakások": "456"
        },
        {
            "Ir. szám": "3045",
            "name": "Bér",
            "Megyekód": "NO",
            "Lat": "47.8667",
            "Long": "19.5",
            "KSH kód": "32911",
            "Jogállás": "6",
            "Terület": "2549",
            "Népesség": "399",
            "Lakások": "212"
        },
        {
            "Ir. szám": "3046",
            "name": "Kisbágyon",
            "Megyekód": "NO",
            "Lat": "47.8167",
            "Long": "19.5833",
            "KSH kód": "27243",
            "Jogállás": "6",
            "Terület": "1024",
            "Népesség": "445",
            "Lakások": "205"
        },
        {
            "Ir. szám": "3047",
            "name": "Buják",
            "Megyekód": "NO",
            "Lat": "47.8833",
            "Long": "19.55",
            "KSH kód": "14234",
            "Jogállás": "6",
            "Terület": "5304",
            "Népesség": "2238",
            "Lakások": "921"
        },
        {
            "Ir. szám": "3051",
            "name": "Szarvasgede",
            "Megyekód": "NO",
            "Lat": "47.8167",
            "Long": "19.65",
            "KSH kód": "10199",
            "Jogállás": "6",
            "Terület": "999",
            "Népesség": "432",
            "Lakások": "213"
        },
        {
            "Ir. szám": "3052",
            "name": "Csécse",
            "Megyekód": "NO",
            "Lat": "47.8667",
            "Long": "19.6333",
            "KSH kód": "30270",
            "Jogállás": "6",
            "Terület": "2173",
            "Népesség": "901",
            "Lakások": "423"
        },
        {
            "Ir. szám": "3053",
            "name": "Kozárd",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.6167",
            "KSH kód": "13842",
            "Jogállás": "6",
            "Terület": "644",
            "Népesség": "171",
            "Lakások": "130"
        },
        {
            "Ir. szám": "3053",
            "name": "Ecseg",
            "Megyekód": "NO",
            "Lat": "47.9",
            "Long": "19.6",
            "KSH kód": "04251",
            "Jogállás": "6",
            "Terület": "2410",
            "Népesség": "1243",
            "Lakások": "542"
        },
        {
            "Ir. szám": "3060",
            "name": "Pásztó",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.7",
            "KSH kód": "07409",
            "Jogállás": "4",
            "Terület": "7260",
            "Népesség": "9880",
            "Lakások": "4108"
        },
        {
            "Ir. szám": "3061",
            "name": "Pásztó",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.7",
            "KSH kód": "07409",
            "Jogállás": "4",
            "Terület": "7260",
            "Népesség": "9880",
            "Lakások": "4108"
        },
        {
            "Ir. szám": "3062",
            "name": "Pásztó",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.7",
            "KSH kód": "07409",
            "Jogállás": "4",
            "Terület": "7260",
            "Népesség": "9880",
            "Lakások": "4108"
        },
        {
            "Ir. szám": "3063",
            "name": "Jobbágyi",
            "Megyekód": "NO",
            "Lat": "47.8333",
            "Long": "19.6833",
            "KSH kód": "08712",
            "Jogállás": "6",
            "Terület": "1783",
            "Népesség": "2289",
            "Lakások": "874"
        },
        {
            "Ir. szám": "3064",
            "name": "Szurdokpüspöki",
            "Megyekód": "NO",
            "Lat": "47.85",
            "Long": "19.6833",
            "KSH kód": "19044",
            "Jogállás": "6",
            "Terület": "2671",
            "Népesség": "1905",
            "Lakások": "896"
        },
        {
            "Ir. szám": "3065",
            "name": "Szurdokpüspöki",
            "Megyekód": "NO",
            "Lat": "47.85",
            "Long": "19.6833",
            "KSH kód": "19044",
            "Jogállás": "6",
            "Terület": "2671",
            "Népesség": "1905",
            "Lakások": "896"
        },
        {
            "Ir. szám": "3066",
            "name": "Kutasó",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.55",
            "KSH kód": "19451",
            "Jogállás": "6",
            "Terület": "605",
            "Népesség": "122",
            "Lakások": "78"
        },
        {
            "Ir. szám": "3066",
            "name": "Bokor",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.55",
            "KSH kód": "03841",
            "Jogállás": "6",
            "Terület": "675",
            "Népesség": "115",
            "Lakások": "74"
        },
        {
            "Ir. szám": "3066",
            "name": "Cserhátszentiván",
            "Megyekód": "NO",
            "Lat": "47.9333",
            "Long": "19.5833",
            "KSH kód": "32319",
            "Jogállás": "6",
            "Terület": "1066",
            "Népesség": "148",
            "Lakások": "130"
        },
        {
            "Ir. szám": "3067",
            "name": "Felsőtold",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.6167",
            "KSH kód": "33312",
            "Jogállás": "6",
            "Terület": "1083",
            "Népesség": "152",
            "Lakások": "107"
        },
        {
            "Ir. szám": "3067",
            "name": "Garáb",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.65",
            "KSH kód": "18494",
            "Jogállás": "6",
            "Terület": "820",
            "Népesség": "61",
            "Lakások": "68"
        },
        {
            "Ir. szám": "3068",
            "name": "Mátraszőlős",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.6833",
            "KSH kód": "04330",
            "Jogállás": "6",
            "Terület": "2916",
            "Népesség": "1621",
            "Lakások": "678"
        },
        {
            "Ir. szám": "3069",
            "name": "Alsótold",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.6",
            "KSH kód": "07621",
            "Jogállás": "6",
            "Terület": "786",
            "Népesség": "257",
            "Lakások": "109"
        },
        {
            "Ir. szám": "3070",
            "name": "Bátonyterenye",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.8333",
            "KSH kód": "33534",
            "Jogállás": "4",
            "Terület": "7892",
            "Népesség": "13270",
            "Lakások": "5820"
        },
        {
            "Ir. szám": "3071",
            "name": "Bátonyterenye",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.8333",
            "KSH kód": "33534",
            "Jogállás": "4",
            "Terület": "7892",
            "Népesség": "13270",
            "Lakások": "5820"
        },
        {
            "Ir. szám": "3072",
            "name": "Bátonyterenye",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.8333",
            "KSH kód": "33534",
            "Jogállás": "4",
            "Terület": "7892",
            "Népesség": "13270",
            "Lakások": "5820"
        },
        {
            "Ir. szám": "3073",
            "name": "Tar",
            "Megyekód": "NO",
            "Lat": "47.95",
            "Long": "19.75",
            "KSH kód": "32896",
            "Jogállás": "6",
            "Terület": "2734",
            "Népesség": "1933",
            "Lakások": "864"
        },
        {
            "Ir. szám": "3074",
            "name": "Sámsonháza",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.7333",
            "KSH kód": "24572",
            "Jogállás": "6",
            "Terület": "1271",
            "Népesség": "273",
            "Lakások": "156"
        },
        {
            "Ir. szám": "3075",
            "name": "Márkháza",
            "Megyekód": "NO",
            "Lat": "48.0167",
            "Long": "19.7333",
            "KSH kód": "14641",
            "Jogállás": "6",
            "Terület": "631",
            "Népesség": "236",
            "Lakások": "115"
        },
        {
            "Ir. szám": "3075",
            "name": "Kisbárkány",
            "Megyekód": "NO",
            "Lat": "48.0167",
            "Long": "19.6833",
            "KSH kód": "26295",
            "Jogállás": "6",
            "Terület": "803",
            "Népesség": "214",
            "Lakások": "107"
        },
        {
            "Ir. szám": "3075",
            "name": "Nagybárkány",
            "Megyekód": "NO",
            "Lat": "48",
            "Long": "19.7",
            "KSH kód": "16391",
            "Jogállás": "6",
            "Terület": "884",
            "Népesség": "671",
            "Lakások": "246"
        },
        {
            "Ir. szám": "3077",
            "name": "Mátraverebély",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.7833",
            "KSH kód": "30100",
            "Jogállás": "6",
            "Terület": "1840",
            "Népesség": "2159",
            "Lakások": "875"
        },
        {
            "Ir. szám": "3078",
            "name": "Mátraverebély",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.7833",
            "KSH kód": "30100",
            "Jogállás": "6",
            "Terület": "1840",
            "Népesség": "2159",
            "Lakások": "875"
        },
        {
            "Ir. szám": "3082",
            "name": "Mátraverebély",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.7833",
            "KSH kód": "30100",
            "Jogállás": "6",
            "Terület": "1840",
            "Népesség": "2159",
            "Lakások": "875"
        },
        {
            "Ir. szám": "3100",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3101",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3102",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3103",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3104",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3107",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3109",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3110",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3121",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3123",
            "name": "Cered",
            "Megyekód": "NO",
            "Lat": "48.15",
            "Long": "19.9667",
            "KSH kód": "03665",
            "Jogállás": "6",
            "Terület": "3858",
            "Népesség": "1162",
            "Lakások": "561"
        },
        {
            "Ir. szám": "3124",
            "name": "Zabar",
            "Megyekód": "NO",
            "Lat": "48.1333",
            "Long": "20.05",
            "KSH kód": "21661",
            "Jogállás": "6",
            "Terület": "1817",
            "Népesség": "556",
            "Lakások": "258"
        },
        {
            "Ir. szám": "3125",
            "name": "Szilaspogony",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "20.0333",
            "KSH kód": "07959",
            "Jogállás": "6",
            "Terület": "1440",
            "Népesség": "341",
            "Lakások": "177"
        },
        {
            "Ir. szám": "3126",
            "name": "Bárna",
            "Megyekód": "NO",
            "Lat": "48.1",
            "Long": "19.9333",
            "KSH kód": "20048",
            "Jogállás": "6",
            "Terület": "1539",
            "Népesség": "1130",
            "Lakások": "456"
        },
        {
            "Ir. szám": "3127",
            "name": "Kazár",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.8667",
            "KSH kód": "28389",
            "Jogállás": "6",
            "Terület": "3039",
            "Népesség": "2066",
            "Lakások": "804"
        },
        {
            "Ir. szám": "3128",
            "name": "Vizslás",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.8167",
            "KSH kód": "10320",
            "Jogállás": "6",
            "Terület": "1012",
            "Népesség": "1333",
            "Lakások": "546"
        },
        {
            "Ir. szám": "3129",
            "name": "Lucfalva",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.7",
            "KSH kód": "20190",
            "Jogállás": "6",
            "Terület": "1410",
            "Népesség": "628",
            "Lakások": "234"
        },
        {
            "Ir. szám": "3131",
            "name": "Sóshartyán",
            "Megyekód": "NO",
            "Lat": "48.0667",
            "Long": "19.6833",
            "KSH kód": "14881",
            "Jogállás": "6",
            "Terület": "1214",
            "Népesség": "941",
            "Lakások": "301"
        },
        {
            "Ir. szám": "3132",
            "name": "Nógrádmegyer",
            "Megyekód": "NO",
            "Lat": "48.0667",
            "Long": "19.6333",
            "KSH kód": "12131",
            "Jogállás": "6",
            "Terület": "2277",
            "Népesség": "1707",
            "Lakások": "573"
        },
        {
            "Ir. szám": "3133",
            "name": "Magyargéc",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.6167",
            "KSH kód": "26967",
            "Jogállás": "6",
            "Terület": "1235",
            "Népesség": "888",
            "Lakások": "300"
        },
        {
            "Ir. szám": "3134",
            "name": "Piliny",
            "Megyekód": "NO",
            "Lat": "48.15",
            "Long": "19.6",
            "KSH kód": "11590",
            "Jogállás": "6",
            "Terület": "1604",
            "Népesség": "596",
            "Lakások": "282"
        },
        {
            "Ir. szám": "3135",
            "name": "Szécsényfelfalu",
            "Megyekód": "NO",
            "Lat": "48.15",
            "Long": "19.5667",
            "KSH kód": "33011",
            "Jogállás": "6",
            "Terület": "822",
            "Népesség": "462",
            "Lakások": "226"
        },
        {
            "Ir. szám": "3136",
            "name": "Etes",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.7167",
            "KSH kód": "15370",
            "Jogállás": "6",
            "Terület": "1580",
            "Népesség": "1493",
            "Lakások": "720"
        },
        {
            "Ir. szám": "3137",
            "name": "Karancsberény",
            "Megyekód": "NO",
            "Lat": "48.1833",
            "Long": "19.75",
            "KSH kód": "25548",
            "Jogállás": "6",
            "Terület": "2314",
            "Népesség": "955",
            "Lakások": "380"
        },
        {
            "Ir. szám": "3138",
            "name": "Ipolytarnóc",
            "Megyekód": "NO",
            "Lat": "48.2333",
            "Long": "19.6333",
            "KSH kód": "03328",
            "Jogállás": "6",
            "Terület": "1366",
            "Népesség": "481",
            "Lakások": "213"
        },
        {
            "Ir. szám": "3141",
            "name": "Ipolytarnóc",
            "Megyekód": "NO",
            "Lat": "48.2333",
            "Long": "19.6333",
            "KSH kód": "03328",
            "Jogállás": "6",
            "Terület": "1366",
            "Népesség": "481",
            "Lakások": "213"
        },
        {
            "Ir. szám": "3142",
            "name": "Mátraszele",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.9",
            "KSH kód": "24332",
            "Jogállás": "6",
            "Terület": "1697",
            "Népesség": "1050",
            "Lakások": "470"
        },
        {
            "Ir. szám": "3143",
            "name": "Mátranovák",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.9833",
            "KSH kód": "19372",
            "Jogállás": "6",
            "Terület": "2697",
            "Népesség": "1779",
            "Lakások": "1112"
        },
        {
            "Ir. szám": "3144",
            "name": "Mátranovák",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.9833",
            "KSH kód": "19372",
            "Jogállás": "6",
            "Terület": "2697",
            "Népesség": "1779",
            "Lakások": "1112"
        },
        {
            "Ir. szám": "3145",
            "name": "Mátraterenye",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.95",
            "KSH kód": "33525",
            "Jogállás": "6",
            "Terület": "2813",
            "Népesség": "1861",
            "Lakások": "897"
        },
        {
            "Ir. szám": "3146",
            "name": "Mátraterenye",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.95",
            "KSH kód": "33525",
            "Jogállás": "6",
            "Terület": "2813",
            "Népesség": "1861",
            "Lakások": "897"
        },
        {
            "Ir. szám": "3147",
            "name": "Mátraterenye",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.95",
            "KSH kód": "33525",
            "Jogállás": "6",
            "Terület": "2813",
            "Népesség": "1861",
            "Lakások": "897"
        },
        {
            "Ir. szám": "3151",
            "name": "Rákóczibánya",
            "Megyekód": "NO",
            "Lat": "47.9875",
            "Long": "19.9125",
            "KSH kód": "34360",
            "Jogállás": "6",
            "Terület": "457",
            "Népesség": "787",
            "Lakások": "307"
        },
        {
            "Ir. szám": "3152",
            "name": "Nemti",
            "Megyekód": "NO",
            "Lat": "48.0167",
            "Long": "19.9",
            "KSH kód": "27580",
            "Jogállás": "6",
            "Terület": "1106",
            "Népesség": "776",
            "Lakások": "432"
        },
        {
            "Ir. szám": "3153",
            "name": "Dorogháza",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.9",
            "KSH kód": "24439",
            "Jogállás": "6",
            "Terület": "1768",
            "Népesség": "1143",
            "Lakások": "568"
        },
        {
            "Ir. szám": "3154",
            "name": "Szuha",
            "Megyekód": "NO",
            "Lat": "47.9833",
            "Long": "19.9167",
            "KSH kód": "28194",
            "Jogállás": "6",
            "Terület": "1756",
            "Népesség": "658",
            "Lakások": "358"
        },
        {
            "Ir. szám": "3155",
            "name": "Mátramindszent",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.9333",
            "KSH kód": "20075",
            "Jogállás": "6",
            "Terület": "1673",
            "Népesség": "854",
            "Lakások": "446"
        },
        {
            "Ir. szám": "3161",
            "name": "Kishartyán",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.7167",
            "KSH kód": "33400",
            "Jogállás": "6",
            "Terület": "895",
            "Népesség": "606",
            "Lakások": "264"
        },
        {
            "Ir. szám": "3162",
            "name": "Ságújfalu",
            "Megyekód": "NO",
            "Lat": "48.1",
            "Long": "19.6833",
            "KSH kód": "12520",
            "Jogállás": "6",
            "Terület": "1203",
            "Népesség": "1084",
            "Lakások": "395"
        },
        {
            "Ir. szám": "3163",
            "name": "Karancsság",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.6667",
            "KSH kód": "26897",
            "Jogállás": "6",
            "Terület": "2139",
            "Népesség": "1190",
            "Lakások": "417"
        },
        {
            "Ir. szám": "3163",
            "name": "Szalmatercs",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.6333",
            "KSH kód": "19798",
            "Jogállás": "6",
            "Terület": "758",
            "Népesség": "490",
            "Lakások": "194"
        },
        {
            "Ir. szám": "3165",
            "name": "Endrefalva",
            "Megyekód": "NO",
            "Lat": "48.1333",
            "Long": "19.5833",
            "KSH kód": "25496",
            "Jogállás": "6",
            "Terület": "1323",
            "Népesség": "1321",
            "Lakások": "455"
        },
        {
            "Ir. szám": "3170",
            "name": "Szécsény",
            "Megyekód": "NO",
            "Lat": "48.0833",
            "Long": "19.5167",
            "KSH kód": "06628",
            "Jogállás": "4",
            "Terület": "4583",
            "Népesség": "5917",
            "Lakások": "2528"
        },
        {
            "Ir. szám": "3175",
            "name": "Nagylóc",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.5833",
            "KSH kód": "21102",
            "Jogállás": "6",
            "Terület": "3912",
            "Népesség": "1618",
            "Lakások": "719"
        },
        {
            "Ir. szám": "3176",
            "name": "Hollókő",
            "Megyekód": "NO",
            "Lat": "48",
            "Long": "19.6",
            "KSH kód": "33242",
            "Jogállás": "6",
            "Terület": "518",
            "Népesség": "374",
            "Lakások": "224"
        },
        {
            "Ir. szám": "3177",
            "name": "Rimóc",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.5333",
            "KSH kód": "28884",
            "Jogállás": "6",
            "Terület": "2926",
            "Népesség": "1829",
            "Lakások": "695"
        },
        {
            "Ir. szám": "3178",
            "name": "Varsány",
            "Megyekód": "NO",
            "Lat": "48.0333",
            "Long": "19.5",
            "KSH kód": "29498",
            "Jogállás": "6",
            "Terület": "2558",
            "Népesség": "1705",
            "Lakások": "638"
        },
        {
            "Ir. szám": "3179",
            "name": "Nógrádsipek",
            "Megyekód": "NO",
            "Lat": "48.0167",
            "Long": "19.5",
            "KSH kód": "19497",
            "Jogállás": "6",
            "Terület": "2012",
            "Népesség": "667",
            "Lakások": "305"
        },
        {
            "Ir. szám": "3181",
            "name": "Karancsalja",
            "Megyekód": "NO",
            "Lat": "48.1333",
            "Long": "19.75",
            "KSH kód": "18625",
            "Jogállás": "6",
            "Terület": "1254",
            "Népesség": "1597",
            "Lakások": "636"
        },
        {
            "Ir. szám": "3182",
            "name": "Karancslapujtő",
            "Megyekód": "NO",
            "Lat": "48.15",
            "Long": "19.7333",
            "KSH kód": "21041",
            "Jogállás": "6",
            "Terület": "1935",
            "Népesség": "2719",
            "Lakások": "1068"
        },
        {
            "Ir. szám": "3183",
            "name": "Karancskeszi",
            "Megyekód": "NO",
            "Lat": "48.1667",
            "Long": "19.7",
            "KSH kód": "08855",
            "Jogállás": "6",
            "Terület": "3169",
            "Népesség": "1907",
            "Lakások": "828"
        },
        {
            "Ir. szám": "3184",
            "name": "Mihálygerge",
            "Megyekód": "NO",
            "Lat": "48.2",
            "Long": "19.6333",
            "KSH kód": "13222",
            "Jogállás": "6",
            "Terület": "1106",
            "Népesség": "612",
            "Lakások": "309"
        },
        {
            "Ir. szám": "3185",
            "name": "Egyházasgerge",
            "Megyekód": "NO",
            "Lat": "48.1833",
            "Long": "19.65",
            "KSH kód": "05980",
            "Jogállás": "6",
            "Terület": "1537",
            "Népesség": "763",
            "Lakások": "370"
        },
        {
            "Ir. szám": "3186",
            "name": "Litke",
            "Megyekód": "NO",
            "Lat": "48.2167",
            "Long": "19.6",
            "KSH kód": "04871",
            "Jogállás": "6",
            "Terület": "1816",
            "Népesség": "867",
            "Lakások": "362"
        },
        {
            "Ir. szám": "3187",
            "name": "Nógrádszakál",
            "Megyekód": "NO",
            "Lat": "48.1833",
            "Long": "19.5333",
            "KSH kód": "27340",
            "Jogállás": "6",
            "Terület": "1879",
            "Népesség": "580",
            "Lakások": "258"
        },
        {
            "Ir. szám": "3188",
            "name": "Ludányhalászi",
            "Megyekód": "NO",
            "Lat": "48.1333",
            "Long": "19.5167",
            "KSH kód": "02778",
            "Jogállás": "6",
            "Terület": "2120",
            "Népesség": "1589",
            "Lakások": "502"
        },
        {
            "Ir. szám": "3200",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3201",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3202",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3211",
            "name": "Gyöngyösoroszi",
            "Megyekód": "HE",
            "Lat": "47.8333",
            "Long": "19.9",
            "KSH kód": "13338",
            "Jogállás": "6",
            "Terület": "2139",
            "Népesség": "1533",
            "Lakások": "576"
        },
        {
            "Ir. szám": "3212",
            "name": "Gyöngyöshalász",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.9333",
            "KSH kód": "17534",
            "Jogállás": "6",
            "Terület": "2713",
            "Népesség": "2579",
            "Lakások": "1032"
        },
        {
            "Ir. szám": "3213",
            "name": "Atkár",
            "Megyekód": "HE",
            "Lat": "47.7167",
            "Long": "19.9",
            "KSH kód": "16090",
            "Jogállás": "6",
            "Terület": "3376",
            "Népesség": "1726",
            "Lakások": "724"
        },
        {
            "Ir. szám": "3214",
            "name": "Nagyréde",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "19.85",
            "KSH kód": "31486",
            "Jogállás": "6",
            "Terület": "3434",
            "Népesség": "3211",
            "Lakások": "1239"
        },
        {
            "Ir. szám": "3221",
            "name": "Nagyréde",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "19.85",
            "KSH kód": "31486",
            "Jogállás": "6",
            "Terület": "3434",
            "Népesség": "3211",
            "Lakások": "1239"
        },
        {
            "Ir. szám": "3231",
            "name": "Gyöngyössolymos",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "19.9333",
            "KSH kód": "19123",
            "Jogállás": "6",
            "Terület": "6485",
            "Népesség": "3085",
            "Lakások": "1291"
        },
        {
            "Ir. szám": "3232",
            "name": "Gyöngyössolymos",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "19.9333",
            "KSH kód": "19123",
            "Jogállás": "6",
            "Terület": "6485",
            "Népesség": "3085",
            "Lakások": "1291"
        },
        {
            "Ir. szám": "3233",
            "name": "Gyöngyössolymos",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "19.9333",
            "KSH kód": "19123",
            "Jogállás": "6",
            "Terület": "6485",
            "Népesség": "3085",
            "Lakások": "1291"
        },
        {
            "Ir. szám": "3234",
            "name": "Gyöngyössolymos",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "19.9333",
            "KSH kód": "19123",
            "Jogállás": "6",
            "Terület": "6485",
            "Népesség": "3085",
            "Lakások": "1291"
        },
        {
            "Ir. szám": "3235",
            "name": "Mátraszentimre",
            "Megyekód": "HE",
            "Lat": "47.9167",
            "Long": "19.8833",
            "KSH kód": "29045",
            "Jogállás": "6",
            "Terület": "2129",
            "Népesség": "501",
            "Lakások": "271"
        },
        {
            "Ir. szám": "3240",
            "name": "Parád",
            "Megyekód": "HE",
            "Lat": "47.9167",
            "Long": "20.0333",
            "KSH kód": "07436",
            "Jogállás": "5",
            "Terület": "3720",
            "Népesség": "1961",
            "Lakások": "1011"
        },
        {
            "Ir. szám": "3242",
            "name": "Parádsasvár",
            "Megyekód": "HE",
            "Lat": "47.9167",
            "Long": "19.9833",
            "KSH kód": "20215",
            "Jogállás": "6",
            "Terület": "1694",
            "Népesség": "416",
            "Lakások": "239"
        },
        {
            "Ir. szám": "3243",
            "name": "Bodony",
            "Megyekód": "HE",
            "Lat": "47.95",
            "Long": "20.0333",
            "KSH kód": "14933",
            "Jogállás": "6",
            "Terület": "2897",
            "Népesség": "770",
            "Lakások": "459"
        },
        {
            "Ir. szám": "3244",
            "name": "Bodony",
            "Megyekód": "HE",
            "Lat": "47.95",
            "Long": "20.0333",
            "KSH kód": "14933",
            "Jogállás": "6",
            "Terület": "2897",
            "Népesség": "770",
            "Lakások": "459"
        },
        {
            "Ir. szám": "3245",
            "name": "Recsk",
            "Megyekód": "HE",
            "Lat": "47.9333",
            "Long": "20.1167",
            "KSH kód": "09609",
            "Jogállás": "5",
            "Terület": "4537",
            "Népesség": "2782",
            "Lakások": "1318"
        },
        {
            "Ir. szám": "3246",
            "name": "Mátraderecske",
            "Megyekód": "HE",
            "Lat": "47.95",
            "Long": "20.0833",
            "KSH kód": "14872",
            "Jogállás": "6",
            "Terület": "1399",
            "Népesség": "2000",
            "Lakások": "992"
        },
        {
            "Ir. szám": "3247",
            "name": "Mátraballa",
            "Megyekód": "HE",
            "Lat": "47.9833",
            "Long": "20.0333",
            "KSH kód": "19965",
            "Jogállás": "6",
            "Terület": "2636",
            "Népesség": "768",
            "Lakások": "424"
        },
        {
            "Ir. szám": "3248",
            "name": "Ivád",
            "Megyekód": "HE",
            "Lat": "48.0167",
            "Long": "20.0667",
            "KSH kód": "13879",
            "Jogállás": "6",
            "Terület": "1194",
            "Népesség": "361",
            "Lakások": "202"
        },
        {
            "Ir. szám": "3250",
            "name": "Pétervására",
            "Megyekód": "HE",
            "Lat": "48.0167",
            "Long": "20.1",
            "KSH kód": "12070",
            "Jogállás": "4",
            "Terület": "3387",
            "Népesség": "2534",
            "Lakások": "927"
        },
        {
            "Ir. szám": "3252",
            "name": "Erdőkövesd",
            "Megyekód": "HE",
            "Lat": "48.0333",
            "Long": "20.1",
            "KSH kód": "28556",
            "Jogállás": "6",
            "Terület": "1718",
            "Népesség": "636",
            "Lakások": "304"
        },
        {
            "Ir. szám": "3253",
            "name": "Istenmezeje",
            "Megyekód": "HE",
            "Lat": "48.0833",
            "Long": "20.05",
            "KSH kód": "10074",
            "Jogállás": "6",
            "Terület": "3279",
            "Népesség": "1615",
            "Lakások": "720"
        },
        {
            "Ir. szám": "3254",
            "name": "Váraszó",
            "Megyekód": "HE",
            "Lat": "48.0333",
            "Long": "20.1167",
            "KSH kód": "27012",
            "Jogállás": "6",
            "Terület": "2705",
            "Népesség": "522",
            "Lakások": "256"
        },
        {
            "Ir. szám": "3255",
            "name": "Fedémes",
            "Megyekód": "HE",
            "Lat": "48.0333",
            "Long": "20.2",
            "KSH kód": "12432",
            "Jogállás": "6",
            "Terület": "862",
            "Népesség": "315",
            "Lakások": "175"
        },
        {
            "Ir. szám": "3256",
            "name": "Kisfüzes",
            "Megyekód": "HE",
            "Lat": "47.9833",
            "Long": "20.1333",
            "KSH kód": "22460",
            "Jogállás": "6",
            "Terület": "481",
            "Népesség": "132",
            "Lakások": "88"
        },
        {
            "Ir. szám": "3257",
            "name": "Bükkszenterzsébet",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.1667",
            "KSH kód": "10621",
            "Jogállás": "6",
            "Terület": "2486",
            "Népesség": "1119",
            "Lakások": "489"
        },
        {
            "Ir. szám": "3258",
            "name": "Tarnalelesz",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.1833",
            "KSH kód": "13240",
            "Jogállás": "6",
            "Terület": "3713",
            "Népesség": "1715",
            "Lakások": "694"
        },
        {
            "Ir. szám": "3259",
            "name": "Szentdomonkos",
            "Megyekód": "HE",
            "Lat": "48.0667",
            "Long": "20.2",
            "KSH kód": "13231",
            "Jogállás": "6",
            "Terület": "1840",
            "Népesség": "464",
            "Lakások": "222"
        },
        {
            "Ir. szám": "3261",
            "name": "Abasár",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "20",
            "KSH kód": "24554",
            "Jogállás": "6",
            "Terület": "2082",
            "Népesség": "2562",
            "Lakások": "1151"
        },
        {
            "Ir. szám": "3262",
            "name": "Markaz",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "20.0667",
            "KSH kód": "16540",
            "Jogállás": "6",
            "Terület": "2561",
            "Népesség": "1826",
            "Lakások": "798"
        },
        {
            "Ir. szám": "3263",
            "name": "Domoszló",
            "Megyekód": "HE",
            "Lat": "47.8333",
            "Long": "20.1167",
            "KSH kód": "07515",
            "Jogállás": "6",
            "Terület": "4022",
            "Népesség": "2108",
            "Lakások": "960"
        },
        {
            "Ir. szám": "3264",
            "name": "Kisnána",
            "Megyekód": "HE",
            "Lat": "47.85",
            "Long": "20.15",
            "KSH kód": "12502",
            "Jogállás": "6",
            "Terület": "2260",
            "Népesség": "1016",
            "Lakások": "521"
        },
        {
            "Ir. szám": "3265",
            "name": "Vécs",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "20.1667",
            "KSH kód": "05759",
            "Jogállás": "6",
            "Terület": "2566",
            "Népesség": "638",
            "Lakások": "385"
        },
        {
            "Ir. szám": "3271",
            "name": "Visonta",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "20.0333",
            "KSH kód": "31246",
            "Jogállás": "6",
            "Terület": "2529",
            "Népesség": "1091",
            "Lakások": "491"
        },
        {
            "Ir. szám": "3272",
            "name": "Visonta",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "20.0333",
            "KSH kód": "31246",
            "Jogállás": "6",
            "Terület": "2529",
            "Népesség": "1091",
            "Lakások": "491"
        },
        {
            "Ir. szám": "3273",
            "name": "Halmajugra",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "20.0667",
            "KSH kód": "11411",
            "Jogállás": "6",
            "Terület": "2168",
            "Népesség": "1306",
            "Lakások": "443"
        },
        {
            "Ir. szám": "3274",
            "name": "Ludas",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "20.1",
            "KSH kód": "15796",
            "Jogállás": "6",
            "Terület": "1079",
            "Népesség": "855",
            "Lakások": "382"
        },
        {
            "Ir. szám": "3275",
            "name": "Detk",
            "Megyekód": "HE",
            "Lat": "47.75",
            "Long": "20.1",
            "KSH kód": "09201",
            "Jogállás": "6",
            "Terület": "2808",
            "Népesség": "1192",
            "Lakások": "523"
        },
        {
            "Ir. szám": "3281",
            "name": "Karácsond",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "20.0333",
            "KSH kód": "05935",
            "Jogállás": "6",
            "Terület": "3160",
            "Népesség": "3178",
            "Lakások": "1266"
        },
        {
            "Ir. szám": "3282",
            "name": "Nagyfüged",
            "Megyekód": "HE",
            "Lat": "47.6833",
            "Long": "20.1167",
            "KSH kód": "26879",
            "Jogállás": "6",
            "Terület": "2751",
            "Népesség": "1929",
            "Lakások": "749"
        },
        {
            "Ir. szám": "3283",
            "name": "Tarnazsadány",
            "Megyekód": "HE",
            "Lat": "47.6667",
            "Long": "20.1667",
            "KSH kód": "17163",
            "Jogállás": "6",
            "Terület": "2519",
            "Népesség": "1188",
            "Lakások": "513"
        },
        {
            "Ir. szám": "3284",
            "name": "Tarnaméra",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "20.1667",
            "KSH kód": "23348",
            "Jogállás": "6",
            "Terület": "2826",
            "Népesség": "1656",
            "Lakások": "707"
        },
        {
            "Ir. szám": "3291",
            "name": "Vámosgyörk",
            "Megyekód": "HE",
            "Lat": "47.6833",
            "Long": "19.9333",
            "KSH kód": "14580",
            "Jogállás": "6",
            "Terület": "2182",
            "Népesség": "2012",
            "Lakások": "783"
        },
        {
            "Ir. szám": "3292",
            "name": "Adács",
            "Megyekód": "HE",
            "Lat": "47.7",
            "Long": "19.9833",
            "KSH kód": "23241",
            "Jogállás": "6",
            "Terület": "3794",
            "Népesség": "2820",
            "Lakások": "1228"
        },
        {
            "Ir. szám": "3293",
            "name": "Visznek",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "20.0333",
            "KSH kód": "03513",
            "Jogállás": "6",
            "Terület": "2273",
            "Népesség": "1135",
            "Lakások": "535"
        },
        {
            "Ir. szám": "3294",
            "name": "Tarnaörs",
            "Megyekód": "HE",
            "Lat": "47.66",
            "Long": "20.03",
            "KSH kód": "14128",
            "Jogállás": "6",
            "Terület": "3028",
            "Népesség": "1857",
            "Lakások": "755"
        },
        {
            "Ir. szám": "3295",
            "name": "Erk",
            "Megyekód": "HE",
            "Lat": "47.6167",
            "Long": "20.0833",
            "KSH kód": "20118",
            "Jogállás": "6",
            "Terület": "2168",
            "Népesség": "881",
            "Lakások": "371"
        },
        {
            "Ir. szám": "3296",
            "name": "Zaránk",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "20.1167",
            "KSH kód": "23445",
            "Jogállás": "6",
            "Terület": "1453",
            "Népesség": "469",
            "Lakások": "305"
        },
        {
            "Ir. szám": "3300",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9058",
            "Long": "20.3709",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3301",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3302",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3303",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3304",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3305",
            "name": "Eger",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "20491",
            "Jogállás": "1",
            "Terület": "9224",
            "Népesség": "56429",
            "Lakások": "25295"
        },
        {
            "Ir. szám": "3321",
            "name": "Egerbakta",
            "Megyekód": "HE",
            "Lat": "47.9333",
            "Long": "20.3",
            "KSH kód": "12821",
            "Jogállás": "6",
            "Terület": "3237",
            "Népesség": "1504",
            "Lakások": "501"
        },
        {
            "Ir. szám": "3322",
            "name": "Hevesaranyos",
            "Megyekód": "HE",
            "Lat": "48.0167",
            "Long": "20.2333",
            "KSH kód": "10241",
            "Jogállás": "6",
            "Terület": "1702",
            "Népesség": "659",
            "Lakások": "280"
        },
        {
            "Ir. szám": "3323",
            "name": "Szarvaskő",
            "Megyekód": "HE",
            "Lat": "47.9",
            "Long": "20.3833",
            "KSH kód": "03382",
            "Jogállás": "6",
            "Terület": "1259",
            "Népesség": "374",
            "Lakások": "154"
        },
        {
            "Ir. szám": "3324",
            "name": "Felsőtárkány",
            "Megyekód": "HE",
            "Lat": "47.9667",
            "Long": "20.4167",
            "KSH kód": "16328",
            "Jogállás": "6",
            "Terület": "7732",
            "Népesség": "3468",
            "Lakások": "1232"
        },
        {
            "Ir. szám": "3325",
            "name": "Noszvaj",
            "Megyekód": "HE",
            "Lat": "47.9333",
            "Long": "20.4833",
            "KSH kód": "18810",
            "Jogállás": "6",
            "Terület": "1884",
            "Népesség": "1821",
            "Lakások": "696"
        },
        {
            "Ir. szám": "3326",
            "name": "Ostoros",
            "Megyekód": "HE",
            "Lat": "47.8667",
            "Long": "20.4333",
            "KSH kód": "27216",
            "Jogállás": "6",
            "Terület": "2351",
            "Népesség": "2737",
            "Lakások": "943"
        },
        {
            "Ir. szám": "3327",
            "name": "Novaj",
            "Megyekód": "HE",
            "Lat": "47.85",
            "Long": "20.4833",
            "KSH kód": "29276",
            "Jogállás": "6",
            "Terület": "1852",
            "Népesség": "1463",
            "Lakások": "557"
        },
        {
            "Ir. szám": "3328",
            "name": "Egerszólát",
            "Megyekód": "HE",
            "Lat": "47.8833",
            "Long": "20.2667",
            "KSH kód": "13648",
            "Jogállás": "6",
            "Terület": "2610",
            "Népesség": "1090",
            "Lakások": "441"
        },
        {
            "Ir. szám": "3331",
            "name": "Tarnaszentmária",
            "Megyekód": "HE",
            "Lat": "47.8833",
            "Long": "20.2",
            "KSH kód": "09052",
            "Jogállás": "6",
            "Terület": "1100",
            "Népesség": "249",
            "Lakások": "183"
        },
        {
            "Ir. szám": "3332",
            "name": "Sirok",
            "Megyekód": "HE",
            "Lat": "47.9333",
            "Long": "20.2",
            "KSH kód": "08527",
            "Jogállás": "6",
            "Terület": "6339",
            "Népesség": "1903",
            "Lakások": "957"
        },
        {
            "Ir. szám": "3333",
            "name": "Terpes",
            "Megyekód": "HE",
            "Lat": "47.9667",
            "Long": "20.15",
            "KSH kód": "12229",
            "Jogállás": "6",
            "Terület": "220",
            "Népesség": "204",
            "Lakások": "103"
        },
        {
            "Ir. szám": "3334",
            "name": "Szajla",
            "Megyekód": "HE",
            "Lat": "47.9667",
            "Long": "20.15",
            "KSH kód": "16063",
            "Jogállás": "6",
            "Terület": "870",
            "Népesség": "658",
            "Lakások": "272"
        },
        {
            "Ir. szám": "3335",
            "name": "Bükkszék",
            "Megyekód": "HE",
            "Lat": "47.9833",
            "Long": "20.1833",
            "KSH kód": "02963",
            "Jogállás": "6",
            "Terület": "1530",
            "Népesség": "758",
            "Lakások": "404"
        },
        {
            "Ir. szám": "3336",
            "name": "Bátor",
            "Megyekód": "HE",
            "Lat": "47.9833",
            "Long": "20.2667",
            "KSH kód": "24022",
            "Jogállás": "6",
            "Terület": "2732",
            "Népesség": "437",
            "Lakások": "195"
        },
        {
            "Ir. szám": "3337",
            "name": "Egerbocs",
            "Megyekód": "HE",
            "Lat": "48.0333",
            "Long": "20.2667",
            "KSH kód": "26019",
            "Jogállás": "6",
            "Terület": "1595",
            "Népesség": "559",
            "Lakások": "269"
        },
        {
            "Ir. szám": "3341",
            "name": "Szúcs",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.25",
            "KSH kód": "13523",
            "Jogállás": "6",
            "Terület": "849",
            "Népesség": "413",
            "Lakások": "158"
        },
        {
            "Ir. szám": "3341",
            "name": "Egercsehi",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.2833",
            "KSH kód": "16610",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "1467",
            "Lakások": "648"
        },
        {
            "Ir. szám": "3343",
            "name": "Bekölce",
            "Megyekód": "HE",
            "Lat": "48.0833",
            "Long": "20.2833",
            "KSH kód": "04400",
            "Jogállás": "6",
            "Terület": "1377",
            "Népesség": "688",
            "Lakások": "368"
        },
        {
            "Ir. szám": "3344",
            "name": "Mikófalva",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.3167",
            "KSH kód": "31282",
            "Jogállás": "6",
            "Terület": "1441",
            "Népesség": "764",
            "Lakások": "317"
        },
        {
            "Ir. szám": "3345",
            "name": "Mónosbél",
            "Megyekód": "HE",
            "Lat": "48.0333",
            "Long": "20.35",
            "KSH kód": "31565",
            "Jogállás": "6",
            "Terület": "1378",
            "Népesség": "440",
            "Lakások": "148"
        },
        {
            "Ir. szám": "3346",
            "name": "Bélapátfalva",
            "Megyekód": "HE",
            "Lat": "48.05",
            "Long": "20.3667",
            "KSH kód": "33260",
            "Jogállás": "4",
            "Terület": "3663",
            "Népesség": "3215",
            "Lakások": "1154"
        },
        {
            "Ir. szám": "3346",
            "name": "Bükkszentmárton",
            "Megyekód": "HE",
            "Lat": "48.0667",
            "Long": "20.35",
            "KSH kód": "22099",
            "Jogállás": "6",
            "Terület": "826",
            "Népesség": "331",
            "Lakások": "131"
        },
        {
            "Ir. szám": "3347",
            "name": "Balaton",
            "Megyekód": "HE",
            "Lat": "48.1",
            "Long": "20.3167",
            "KSH kód": "11527",
            "Jogállás": "6",
            "Terület": "1320",
            "Népesség": "1162",
            "Lakások": "456"
        },
        {
            "Ir. szám": "3348",
            "name": "Szilvásvárad",
            "Megyekód": "HE",
            "Lat": "48.1",
            "Long": "20.4",
            "KSH kód": "05643",
            "Jogállás": "6",
            "Terület": "3782",
            "Népesség": "1742",
            "Lakások": "745"
        },
        {
            "Ir. szám": "3349",
            "name": "Nagyvisnyó",
            "Megyekód": "HE",
            "Lat": "48.1333",
            "Long": "20.4333",
            "KSH kód": "28282",
            "Jogállás": "6",
            "Terület": "4302",
            "Népesség": "977",
            "Lakások": "491"
        },
        {
            "Ir. szám": "3350",
            "name": "Kál",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "20.2667",
            "KSH kód": "32179",
            "Jogállás": "5",
            "Terület": "3481",
            "Népesség": "3645",
            "Lakások": "1365"
        },
        {
            "Ir. szám": "3351",
            "name": "Verpelét",
            "Megyekód": "HE",
            "Lat": "47.85",
            "Long": "20.2333",
            "KSH kód": "24147",
            "Jogállás": "5",
            "Terület": "5318",
            "Népesség": "3931",
            "Lakások": "1599"
        },
        {
            "Ir. szám": "3352",
            "name": "Feldebrő",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "20.25",
            "KSH kód": "20747",
            "Jogállás": "6",
            "Terület": "2652",
            "Népesség": "1112",
            "Lakások": "554"
        },
        {
            "Ir. szám": "3353",
            "name": "Aldebrő",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "20.2333",
            "KSH kód": "06345",
            "Jogállás": "6",
            "Terület": "2178",
            "Népesség": "696",
            "Lakások": "354"
        },
        {
            "Ir. szám": "3354",
            "name": "Tófalu",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "20.25",
            "KSH kód": "09964",
            "Jogállás": "6",
            "Terület": "1448",
            "Népesség": "570",
            "Lakások": "284"
        },
        {
            "Ir. szám": "3355",
            "name": "Kápolna",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "20.25",
            "KSH kód": "15307",
            "Jogállás": "6",
            "Terület": "2151",
            "Népesség": "1602",
            "Lakások": "556"
        },
        {
            "Ir. szám": "3356",
            "name": "Kompolt",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "20.25",
            "KSH kód": "23995",
            "Jogállás": "6",
            "Terület": "2273",
            "Népesség": "2174",
            "Lakások": "789"
        },
        {
            "Ir. szám": "3357",
            "name": "Nagyút",
            "Megyekód": "HE",
            "Lat": "47.7167",
            "Long": "20.1667",
            "KSH kód": "10418",
            "Jogállás": "6",
            "Terület": "1894",
            "Népesség": "729",
            "Lakások": "337"
        },
        {
            "Ir. szám": "3358",
            "name": "Erdőtelek",
            "Megyekód": "HE",
            "Lat": "47.6833",
            "Long": "20.3167",
            "KSH kód": "24235",
            "Jogállás": "6",
            "Terület": "4490",
            "Népesség": "3306",
            "Lakások": "1379"
        },
        {
            "Ir. szám": "3359",
            "name": "Tenk",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "20.35",
            "KSH kód": "14076",
            "Jogállás": "6",
            "Terület": "1234",
            "Népesség": "1189",
            "Lakások": "455"
        },
        {
            "Ir. szám": "3360",
            "name": "Heves",
            "Megyekód": "HE",
            "Lat": "47.6",
            "Long": "20.2833",
            "KSH kód": "14526",
            "Jogállás": "4",
            "Terület": "9931",
            "Népesség": "10804",
            "Lakások": "4178"
        },
        {
            "Ir. szám": "3368",
            "name": "Boconád",
            "Megyekód": "HE",
            "Lat": "47.6333",
            "Long": "20.2",
            "KSH kód": "22354",
            "Jogállás": "6",
            "Terület": "2956",
            "Népesség": "1350",
            "Lakások": "628"
        },
        {
            "Ir. szám": "3369",
            "name": "Tarnabod",
            "Megyekód": "HE",
            "Lat": "47.6833",
            "Long": "20.2333",
            "KSH kód": "32966",
            "Jogállás": "6",
            "Terület": "1033",
            "Népesség": "718",
            "Lakások": "280"
        },
        {
            "Ir. szám": "3371",
            "name": "Átány",
            "Megyekód": "HE",
            "Lat": "47.6167",
            "Long": "20.3667",
            "KSH kód": "06503",
            "Jogállás": "6",
            "Terület": "5044",
            "Népesség": "1394",
            "Lakások": "587"
        },
        {
            "Ir. szám": "3372",
            "name": "Kömlő",
            "Megyekód": "HE",
            "Lat": "47.6",
            "Long": "20.45",
            "KSH kód": "14535",
            "Jogállás": "6",
            "Terület": "4922",
            "Népesség": "1820",
            "Lakások": "679"
        },
        {
            "Ir. szám": "3373",
            "name": "Besenyőtelek",
            "Megyekód": "HE",
            "Lat": "47.7",
            "Long": "20.4333",
            "KSH kód": "27517",
            "Jogállás": "6",
            "Terület": "4910",
            "Népesség": "2710",
            "Lakások": "1108"
        },
        {
            "Ir. szám": "3374",
            "name": "Dormánd",
            "Megyekód": "HE",
            "Lat": "47.7167",
            "Long": "20.4167",
            "KSH kód": "30261",
            "Jogállás": "6",
            "Terület": "2006",
            "Népesség": "1061",
            "Lakások": "382"
        },
        {
            "Ir. szám": "3375",
            "name": "Mezőtárkány",
            "Megyekód": "HE",
            "Lat": "47.7167",
            "Long": "20.4833",
            "KSH kód": "31662",
            "Jogállás": "6",
            "Terület": "4064",
            "Népesség": "1646",
            "Lakások": "738"
        },
        {
            "Ir. szám": "3377",
            "name": "Szihalom",
            "Megyekód": "HE",
            "Lat": "47.7667",
            "Long": "20.4833",
            "KSH kód": "11013",
            "Jogállás": "6",
            "Terület": "3417",
            "Népesség": "2073",
            "Lakások": "972"
        },
        {
            "Ir. szám": "3378",
            "name": "Mezőszemere",
            "Megyekód": "HE",
            "Lat": "47.75",
            "Long": "20.5333",
            "KSH kód": "25089",
            "Jogállás": "6",
            "Terület": "2148",
            "Népesség": "1336",
            "Lakások": "613"
        },
        {
            "Ir. szám": "3379",
            "name": "Egerfarmos",
            "Megyekód": "HE",
            "Lat": "47.7167",
            "Long": "20.55",
            "KSH kód": "02981",
            "Jogállás": "6",
            "Terület": "2380",
            "Népesség": "730",
            "Lakások": "338"
        },
        {
            "Ir. szám": "3381",
            "name": "Pély",
            "Megyekód": "HE",
            "Lat": "47.4833",
            "Long": "20.35",
            "KSH kód": "19567",
            "Jogállás": "6",
            "Terület": "9030",
            "Népesség": "1434",
            "Lakások": "666"
        },
        {
            "Ir. szám": "3382",
            "name": "Tarnaszentmiklós",
            "Megyekód": "HE",
            "Lat": "47.5333",
            "Long": "20.3833",
            "KSH kód": "16160",
            "Jogállás": "6",
            "Terület": "3500",
            "Népesség": "901",
            "Lakások": "445"
        },
        {
            "Ir. szám": "3383",
            "name": "Hevesvezekény",
            "Megyekód": "HE",
            "Lat": "47.55",
            "Long": "20.3667",
            "KSH kód": "04084",
            "Jogállás": "6",
            "Terület": "1978",
            "Népesség": "638",
            "Lakások": "313"
        },
        {
            "Ir. szám": "3384",
            "name": "Kisköre",
            "Megyekód": "HE",
            "Lat": "47.5",
            "Long": "20.5",
            "KSH kód": "18281",
            "Jogállás": "4",
            "Terület": "6842",
            "Népesség": "2892",
            "Lakások": "1227"
        },
        {
            "Ir. szám": "3385",
            "name": "Tiszanána",
            "Megyekód": "HE",
            "Lat": "47.5667",
            "Long": "20.5333",
            "KSH kód": "07083",
            "Jogállás": "6",
            "Terület": "6814",
            "Népesség": "2425",
            "Lakások": "1016"
        },
        {
            "Ir. szám": "3386",
            "name": "Sarud",
            "Megyekód": "HE",
            "Lat": "47.5833",
            "Long": "20.6",
            "KSH kód": "07180",
            "Jogállás": "6",
            "Terület": "5162",
            "Népesség": "1183",
            "Lakások": "557"
        },
        {
            "Ir. szám": "3387",
            "name": "Újlőrincfalva",
            "Megyekód": "HE",
            "Lat": "47.6333",
            "Long": "20.6",
            "KSH kód": "27623",
            "Jogállás": "6",
            "Terület": "4809",
            "Népesség": "271",
            "Lakások": "151"
        },
        {
            "Ir. szám": "3388",
            "name": "Poroszló",
            "Megyekód": "HE",
            "Lat": "47.65",
            "Long": "20.6667",
            "KSH kód": "22196",
            "Jogállás": "6",
            "Terület": "10904",
            "Népesség": "2773",
            "Lakások": "1368"
        },
        {
            "Ir. szám": "3390",
            "name": "Füzesabony",
            "Megyekód": "HE",
            "Lat": "47.75",
            "Long": "20.4167",
            "KSH kód": "03276",
            "Jogállás": "4",
            "Terület": "4634",
            "Népesség": "7963",
            "Lakások": "3081"
        },
        {
            "Ir. szám": "3392",
            "name": "Füzesabony",
            "Megyekód": "HE",
            "Lat": "47.75",
            "Long": "20.4167",
            "KSH kód": "03276",
            "Jogállás": "4",
            "Terület": "4634",
            "Népesség": "7963",
            "Lakások": "3081"
        },
        {
            "Ir. szám": "3393",
            "name": "Füzesabony",
            "Megyekód": "HE",
            "Lat": "47.75",
            "Long": "20.4167",
            "KSH kód": "03276",
            "Jogállás": "4",
            "Terület": "4634",
            "Népesség": "7963",
            "Lakások": "3081"
        },
        {
            "Ir. szám": "3394",
            "name": "Egerszalók",
            "Megyekód": "HE",
            "Lat": "47.8667",
            "Long": "20.3333",
            "KSH kód": "24758",
            "Jogállás": "6",
            "Terület": "2311",
            "Népesség": "1908",
            "Lakások": "797"
        },
        {
            "Ir. szám": "3395",
            "name": "Demjén",
            "Megyekód": "HE",
            "Lat": "47.8333",
            "Long": "20.3333",
            "KSH kód": "08660",
            "Jogállás": "6",
            "Terület": "2508",
            "Népesség": "615",
            "Lakások": "307"
        },
        {
            "Ir. szám": "3396",
            "name": "Kerecsend",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "20.35",
            "KSH kód": "28079",
            "Jogállás": "6",
            "Terület": "2458",
            "Népesség": "2289",
            "Lakások": "737"
        },
        {
            "Ir. szám": "3397",
            "name": "Maklár",
            "Megyekód": "HE",
            "Lat": "47.8",
            "Long": "20.4167",
            "KSH kód": "27696",
            "Jogállás": "6",
            "Terület": "2801",
            "Népesség": "2415",
            "Lakások": "965"
        },
        {
            "Ir. szám": "3398",
            "name": "Nagytálya",
            "Megyekód": "HE",
            "Lat": "47.8167",
            "Long": "20.4167",
            "KSH kód": "27605",
            "Jogállás": "6",
            "Terület": "1319",
            "Népesség": "872",
            "Lakások": "338"
        },
        {
            "Ir. szám": "3399",
            "name": "Andornaktálya",
            "Megyekód": "HE",
            "Lat": "47.85",
            "Long": "20.4167",
            "KSH kód": "17987",
            "Jogállás": "6",
            "Terület": "1675",
            "Népesség": "2743",
            "Lakások": "1015"
        },
        {
            "Ir. szám": "3400",
            "name": "Mezőkövesd",
            "Megyekód": "BZ",
            "Lat": "47.8167",
            "Long": "20.5833",
            "KSH kód": "19433",
            "Jogállás": "4",
            "Terület": "10056",
            "Népesség": "16905",
            "Lakások": "7539"
        },
        {
            "Ir. szám": "3401",
            "name": "Mezőkövesd",
            "Megyekód": "BZ",
            "Lat": "47.8167",
            "Long": "20.5833",
            "KSH kód": "19433",
            "Jogállás": "4",
            "Terület": "10056",
            "Népesség": "16905",
            "Lakások": "7539"
        },
        {
            "Ir. szám": "3402",
            "name": "Mezőkövesd",
            "Megyekód": "BZ",
            "Lat": "47.8167",
            "Long": "20.5833",
            "KSH kód": "19433",
            "Jogállás": "4",
            "Terület": "10056",
            "Népesség": "16905",
            "Lakások": "7539"
        },
        {
            "Ir. szám": "3411",
            "name": "Szomolya",
            "Megyekód": "BZ",
            "Lat": "47.9",
            "Long": "20.5",
            "KSH kód": "18892",
            "Jogállás": "6",
            "Terület": "2269",
            "Népesség": "1613",
            "Lakások": "707"
        },
        {
            "Ir. szám": "3412",
            "name": "Bogács",
            "Megyekód": "BZ",
            "Lat": "47.9",
            "Long": "20.5333",
            "KSH kód": "25195",
            "Jogállás": "6",
            "Terület": "2470",
            "Népesség": "2011",
            "Lakások": "973"
        },
        {
            "Ir. szám": "3413",
            "name": "Cserépfalu",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "20.5333",
            "KSH kód": "06974",
            "Jogállás": "6",
            "Terület": "4466",
            "Népesség": "1049",
            "Lakások": "555"
        },
        {
            "Ir. szám": "3414",
            "name": "Bükkzsérc",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "20.5",
            "KSH kód": "32887",
            "Jogállás": "6",
            "Terület": "3725",
            "Népesség": "1009",
            "Lakások": "495"
        },
        {
            "Ir. szám": "3416",
            "name": "Tard",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "20.6167",
            "KSH kód": "08165",
            "Jogállás": "6",
            "Terület": "4050",
            "Népesség": "1331",
            "Lakások": "579"
        },
        {
            "Ir. szám": "3417",
            "name": "Cserépváralja",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "20.5667",
            "KSH kód": "25575",
            "Jogállás": "6",
            "Terület": "1462",
            "Népesség": "470",
            "Lakások": "213"
        },
        {
            "Ir. szám": "3418",
            "name": "Szentistván",
            "Megyekód": "BZ",
            "Lat": "47.7667",
            "Long": "20.6667",
            "KSH kód": "22169",
            "Jogállás": "5",
            "Terület": "5129",
            "Népesség": "2479",
            "Lakások": "1141"
        },
        {
            "Ir. szám": "3421",
            "name": "Mezőnyárád",
            "Megyekód": "BZ",
            "Lat": "47.8667",
            "Long": "20.6833",
            "KSH kód": "11749",
            "Jogállás": "6",
            "Terület": "1408",
            "Népesség": "1597",
            "Lakások": "689"
        },
        {
            "Ir. szám": "3422",
            "name": "Bükkábrány",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "20.6833",
            "KSH kód": "13596",
            "Jogállás": "6",
            "Terület": "1803",
            "Népesség": "1590",
            "Lakások": "699"
        },
        {
            "Ir. szám": "3423",
            "name": "Tibolddaróc",
            "Megyekód": "BZ",
            "Lat": "47.9167",
            "Long": "20.6333",
            "KSH kód": "30447",
            "Jogállás": "6",
            "Terület": "3034",
            "Népesség": "1479",
            "Lakások": "665"
        },
        {
            "Ir. szám": "3424",
            "name": "Kács",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "20.6167",
            "KSH kód": "32993",
            "Jogállás": "6",
            "Terület": "1670",
            "Népesség": "541",
            "Lakások": "335"
        },
        {
            "Ir. szám": "3425",
            "name": "Sály",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "20.6667",
            "KSH kód": "04729",
            "Jogállás": "6",
            "Terület": "2558",
            "Népesség": "2187",
            "Lakások": "739"
        },
        {
            "Ir. szám": "3426",
            "name": "Borsodgeszt",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "20.7",
            "KSH kód": "16124",
            "Jogállás": "6",
            "Terület": "1508",
            "Népesség": "263",
            "Lakások": "180"
        },
        {
            "Ir. szám": "3431",
            "name": "Vatta",
            "Megyekód": "BZ",
            "Lat": "47.9167",
            "Long": "20.75",
            "KSH kód": "30076",
            "Jogállás": "6",
            "Terület": "2334",
            "Népesség": "919",
            "Lakások": "376"
        },
        {
            "Ir. szám": "3432",
            "name": "Emőd",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "20.8167",
            "KSH kód": "04677",
            "Jogállás": "4",
            "Terület": "4938",
            "Népesség": "5203",
            "Lakások": "1983"
        },
        {
            "Ir. szám": "3433",
            "name": "Nyékládháza",
            "Megyekód": "BZ",
            "Lat": "47.9833",
            "Long": "20.8333",
            "KSH kód": "12885",
            "Jogállás": "4",
            "Terület": "2467",
            "Népesség": "4950",
            "Lakások": "1756"
        },
        {
            "Ir. szám": "3434",
            "name": "Mályi",
            "Megyekód": "BZ",
            "Lat": "48.0167",
            "Long": "20.8333",
            "KSH kód": "27395",
            "Jogállás": "6",
            "Terület": "1128",
            "Népesség": "4252",
            "Lakások": "1369"
        },
        {
            "Ir. szám": "3435",
            "name": "Mályi",
            "Megyekód": "BZ",
            "Lat": "48.0167",
            "Long": "20.8333",
            "KSH kód": "27395",
            "Jogállás": "6",
            "Terület": "1128",
            "Népesség": "4252",
            "Lakások": "1369"
        },
        {
            "Ir. szám": "3441",
            "name": "Mezőkeresztes",
            "Megyekód": "BZ",
            "Lat": "47.8333",
            "Long": "20.7",
            "KSH kód": "11323",
            "Jogállás": "5",
            "Terület": "7426",
            "Népesség": "4089",
            "Lakások": "1760"
        },
        {
            "Ir. szám": "3442",
            "name": "Csincse",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "20.7667",
            "KSH kód": "34111",
            "Jogállás": "6",
            "Terület": "1134",
            "Népesség": "560",
            "Lakások": "212"
        },
        {
            "Ir. szám": "3443",
            "name": "Mezőnagymihály",
            "Megyekód": "BZ",
            "Lat": "47.8167",
            "Long": "20.7333",
            "KSH kód": "18379",
            "Jogállás": "6",
            "Terület": "6188",
            "Népesség": "1064",
            "Lakások": "539"
        },
        {
            "Ir. szám": "3444",
            "name": "Gelej",
            "Megyekód": "BZ",
            "Lat": "47.8333",
            "Long": "20.7833",
            "KSH kód": "23719",
            "Jogállás": "6",
            "Terület": "3211",
            "Népesség": "632",
            "Lakások": "303"
        },
        {
            "Ir. szám": "3450",
            "name": "Mezőcsát",
            "Megyekód": "BZ",
            "Lat": "47.8167",
            "Long": "20.9167",
            "KSH kód": "13833",
            "Jogállás": "4",
            "Terület": "10309",
            "Népesség": "6214",
            "Lakások": "2492"
        },
        {
            "Ir. szám": "3458",
            "name": "Tiszakeszi",
            "Megyekód": "BZ",
            "Lat": "47.7833",
            "Long": "21",
            "KSH kód": "13888",
            "Jogállás": "6",
            "Terület": "4618",
            "Népesség": "2515",
            "Lakások": "1056"
        },
        {
            "Ir. szám": "3459",
            "name": "Igrici",
            "Megyekód": "BZ",
            "Lat": "47.8667",
            "Long": "20.8833",
            "KSH kód": "25399",
            "Jogállás": "6",
            "Terület": "1947",
            "Népesség": "1247",
            "Lakások": "478"
        },
        {
            "Ir. szám": "3461",
            "name": "Egerlövő",
            "Megyekód": "BZ",
            "Lat": "47.7167",
            "Long": "20.6333",
            "KSH kód": "05865",
            "Jogállás": "6",
            "Terület": "1984",
            "Népesség": "583",
            "Lakások": "294"
        },
        {
            "Ir. szám": "3462",
            "name": "Borsodivánka",
            "Megyekód": "BZ",
            "Lat": "47.7",
            "Long": "20.6667",
            "KSH kód": "06707",
            "Jogállás": "6",
            "Terület": "1591",
            "Népesség": "753",
            "Lakások": "241"
        },
        {
            "Ir. szám": "3463",
            "name": "Négyes",
            "Megyekód": "BZ",
            "Lat": "47.7",
            "Long": "20.7167",
            "KSH kód": "24156",
            "Jogállás": "6",
            "Terület": "1734",
            "Népesség": "259",
            "Lakások": "217"
        },
        {
            "Ir. szám": "3464",
            "name": "Tiszavalk",
            "Megyekód": "BZ",
            "Lat": "47.6833",
            "Long": "20.75",
            "KSH kód": "10977",
            "Jogállás": "6",
            "Terület": "1155",
            "Népesség": "322",
            "Lakások": "179"
        },
        {
            "Ir. szám": "3465",
            "name": "Tiszabábolna",
            "Megyekód": "BZ",
            "Lat": "47.6833",
            "Long": "20.8167",
            "KSH kód": "02291",
            "Jogállás": "6",
            "Terület": "3271",
            "Népesség": "393",
            "Lakások": "269"
        },
        {
            "Ir. szám": "3466",
            "name": "Tiszadorogma",
            "Megyekód": "BZ",
            "Lat": "47.6833",
            "Long": "20.8667",
            "KSH kód": "03717",
            "Jogállás": "6",
            "Terület": "4664",
            "Népesség": "389",
            "Lakások": "258"
        },
        {
            "Ir. szám": "3467",
            "name": "Ároktő",
            "Megyekód": "BZ",
            "Lat": "47.7333",
            "Long": "20.95",
            "KSH kód": "03823",
            "Jogállás": "6",
            "Terület": "4641",
            "Népesség": "1128",
            "Lakások": "529"
        },
        {
            "Ir. szám": "3500",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3501",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3502",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3503",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3504",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3505",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3506",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3507",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3508",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3509",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3510",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3511",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3513",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3514",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3515",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3516",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3517",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3518",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3519",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3521",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3523",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3524",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3525",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3526",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3527",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3528",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3529",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3530",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3531",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3532",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3533",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3534",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3535",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3542",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3543",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3544",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3545",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3546",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3547",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3548",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3549",
            "name": "Miskolc",
            "Megyekód": "BZ",
            "Lat": "47.9804",
            "Long": "20.9354",
            "KSH kód": "30456",
            "Jogállás": "1",
            "Terület": "23666",
            "Népesség": "170234",
            "Lakások": "75747"
        },
        {
            "Ir. szám": "3551",
            "name": "Ónod",
            "Megyekód": "BZ",
            "Lat": "48",
            "Long": "20.9167",
            "KSH kód": "22628",
            "Jogállás": "6",
            "Terület": "1773",
            "Népesség": "2625",
            "Lakások": "835"
        },
        {
            "Ir. szám": "3552",
            "name": "Muhi",
            "Megyekód": "BZ",
            "Lat": "47.9833",
            "Long": "20.9333",
            "KSH kód": "02158",
            "Jogállás": "6",
            "Terület": "962",
            "Népesség": "549",
            "Lakások": "209"
        },
        {
            "Ir. szám": "3553",
            "name": "Kistokaj",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "20.85",
            "KSH kód": "12399",
            "Jogállás": "6",
            "Terület": "976",
            "Népesség": "2010",
            "Lakások": "688"
        },
        {
            "Ir. szám": "3554",
            "name": "Bükkaranyos",
            "Megyekód": "BZ",
            "Lat": "47.9833",
            "Long": "20.7833",
            "KSH kód": "27890",
            "Jogállás": "6",
            "Terület": "2620",
            "Népesség": "1525",
            "Lakások": "528"
        },
        {
            "Ir. szám": "3555",
            "name": "Harsány",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "20.75",
            "KSH kód": "05847",
            "Jogállás": "6",
            "Terület": "3653",
            "Népesség": "2058",
            "Lakások": "794"
        },
        {
            "Ir. szám": "3556",
            "name": "Kisgyőr",
            "Megyekód": "BZ",
            "Lat": "48.0167",
            "Long": "20.7",
            "KSH kód": "22840",
            "Jogállás": "6",
            "Terület": "7113",
            "Népesség": "1703",
            "Lakások": "638"
        },
        {
            "Ir. szám": "3557",
            "name": "Bükkszentkereszt",
            "Megyekód": "BZ",
            "Lat": "48.0667",
            "Long": "20.6333",
            "KSH kód": "08022",
            "Jogállás": "6",
            "Terület": "2697",
            "Népesség": "1209",
            "Lakások": "508"
        },
        {
            "Ir. szám": "3559",
            "name": "Répáshuta",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "20.5333",
            "KSH kód": "31884",
            "Jogállás": "6",
            "Terület": "1679",
            "Népesség": "467",
            "Lakások": "181"
        },
        {
            "Ir. szám": "3561",
            "name": "Felsőzsolca",
            "Megyekód": "BZ",
            "Lat": "48.1",
            "Long": "20.8667",
            "KSH kód": "02848",
            "Jogállás": "4",
            "Terület": "1625",
            "Népesség": "7043",
            "Lakások": "2237"
        },
        {
            "Ir. szám": "3562",
            "name": "Onga",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "20.9167",
            "KSH kód": "22558",
            "Jogállás": "5",
            "Terület": "3149",
            "Népesség": "4826",
            "Lakások": "1524"
        },
        {
            "Ir. szám": "3563",
            "name": "Hernádkak",
            "Megyekód": "BZ",
            "Lat": "48.0833",
            "Long": "20.9667",
            "KSH kód": "24165",
            "Jogállás": "6",
            "Terület": "1090",
            "Népesség": "1655",
            "Lakások": "484"
        },
        {
            "Ir. szám": "3564",
            "name": "Hernádnémeti",
            "Megyekód": "BZ",
            "Lat": "48.0667",
            "Long": "20.9833",
            "KSH kód": "31200",
            "Jogállás": "6",
            "Terület": "2876",
            "Népesség": "3617",
            "Lakások": "1191"
        },
        {
            "Ir. szám": "3565",
            "name": "Tiszalúc",
            "Megyekód": "BZ",
            "Lat": "48.0333",
            "Long": "21.0833",
            "KSH kód": "28398",
            "Jogállás": "5",
            "Terület": "4488",
            "Népesség": "5481",
            "Lakások": "1926"
        },
        {
            "Ir. szám": "3571",
            "name": "Alsózsolca",
            "Megyekód": "BZ",
            "Lat": "48.0667",
            "Long": "20.8833",
            "KSH kód": "21032",
            "Jogállás": "4",
            "Terület": "2602",
            "Népesség": "6044",
            "Lakások": "1839"
        },
        {
            "Ir. szám": "3572",
            "name": "Sajólád",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "20.9",
            "KSH kód": "27173",
            "Jogállás": "6",
            "Terület": "1268",
            "Népesség": "3127",
            "Lakások": "1002"
        },
        {
            "Ir. szám": "3573",
            "name": "Sajópetri",
            "Megyekód": "BZ",
            "Lat": "48.0333",
            "Long": "20.9",
            "KSH kód": "16638",
            "Jogállás": "6",
            "Terület": "928",
            "Népesség": "1569",
            "Lakások": "490"
        },
        {
            "Ir. szám": "3574",
            "name": "Bőcs",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "20.9667",
            "KSH kód": "05306",
            "Jogállás": "6",
            "Terület": "2432",
            "Népesség": "2793",
            "Lakások": "952"
        },
        {
            "Ir. szám": "3575",
            "name": "Berzék",
            "Megyekód": "BZ",
            "Lat": "48.0167",
            "Long": "20.95",
            "KSH kód": "26356",
            "Jogállás": "6",
            "Terület": "1059",
            "Népesség": "1118",
            "Lakások": "347"
        },
        {
            "Ir. szám": "3576",
            "name": "Sajóhídvég",
            "Megyekód": "BZ",
            "Lat": "48",
            "Long": "20.95",
            "KSH kód": "03081",
            "Jogállás": "6",
            "Terület": "1344",
            "Népesség": "1065",
            "Lakások": "346"
        },
        {
            "Ir. szám": "3577",
            "name": "Köröm",
            "Megyekód": "BZ",
            "Lat": "47.9833",
            "Long": "20.95",
            "KSH kód": "26602",
            "Jogállás": "6",
            "Terület": "834",
            "Népesség": "1366",
            "Lakások": "353"
        },
        {
            "Ir. szám": "3578",
            "name": "Girincs",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "20.9833",
            "KSH kód": "21564",
            "Jogállás": "6",
            "Terület": "1120",
            "Népesség": "890",
            "Lakások": "294"
        },
        {
            "Ir. szám": "3578",
            "name": "Kiscsécs",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "21.0167",
            "KSH kód": "17349",
            "Jogállás": "6",
            "Terület": "413",
            "Népesség": "208",
            "Lakások": "54"
        },
        {
            "Ir. szám": "3579",
            "name": "Kesznyéten",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "21.05",
            "KSH kód": "29249",
            "Jogállás": "6",
            "Terület": "3640",
            "Népesség": "1912",
            "Lakások": "655"
        },
        {
            "Ir. szám": "3580",
            "name": "Tiszaújváros",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "21.0833",
            "KSH kód": "28352",
            "Jogállás": "4",
            "Terület": "4604",
            "Népesség": "16641",
            "Lakások": "7023"
        },
        {
            "Ir. szám": "3581",
            "name": "Tiszaújváros",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "21.0833",
            "KSH kód": "28352",
            "Jogállás": "4",
            "Terület": "4604",
            "Népesség": "16641",
            "Lakások": "7023"
        },
        {
            "Ir. szám": "3582",
            "name": "Tiszaújváros",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "21.0833",
            "KSH kód": "28352",
            "Jogállás": "4",
            "Terület": "4604",
            "Népesség": "16641",
            "Lakások": "7023"
        },
        {
            "Ir. szám": "3585",
            "name": "Tiszaújváros",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "21.0833",
            "KSH kód": "28352",
            "Jogállás": "4",
            "Terület": "4604",
            "Népesség": "16641",
            "Lakások": "7023"
        },
        {
            "Ir. szám": "3586",
            "name": "Sajóörös",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "21.0333",
            "KSH kód": "08129",
            "Jogállás": "6",
            "Terület": "844",
            "Népesség": "1304",
            "Lakások": "467"
        },
        {
            "Ir. szám": "3587",
            "name": "Tiszapalkonya",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "21.0667",
            "KSH kód": "08633",
            "Jogállás": "6",
            "Terület": "1349",
            "Népesség": "1480",
            "Lakások": "631"
        },
        {
            "Ir. szám": "3588",
            "name": "Hejőkürt",
            "Megyekód": "BZ",
            "Lat": "47.8667",
            "Long": "21",
            "KSH kód": "02282",
            "Jogállás": "6",
            "Terület": "1490",
            "Népesség": "332",
            "Lakások": "151"
        },
        {
            "Ir. szám": "3589",
            "name": "Tiszatarján",
            "Megyekód": "BZ",
            "Lat": "47.8333",
            "Long": "21.0167",
            "KSH kód": "30377",
            "Jogállás": "6",
            "Terület": "4039",
            "Népesség": "1433",
            "Lakások": "532"
        },
        {
            "Ir. szám": "3591",
            "name": "Oszlár",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "21.0333",
            "KSH kód": "02866",
            "Jogállás": "6",
            "Terület": "571",
            "Népesség": "425",
            "Lakások": "183"
        },
        {
            "Ir. szám": "3592",
            "name": "Nemesbikk",
            "Megyekód": "BZ",
            "Lat": "47.8833",
            "Long": "20.9667",
            "KSH kód": "05245",
            "Jogállás": "6",
            "Terület": "2410",
            "Népesség": "1043",
            "Lakások": "380"
        },
        {
            "Ir. szám": "3593",
            "name": "Hejőbába",
            "Megyekód": "BZ",
            "Lat": "47.9",
            "Long": "20.95",
            "KSH kód": "06655",
            "Jogállás": "6",
            "Terület": "1872",
            "Népesség": "1970",
            "Lakások": "550"
        },
        {
            "Ir. szám": "3594",
            "name": "Hejőpapi",
            "Megyekód": "BZ",
            "Lat": "47.9",
            "Long": "20.9167",
            "KSH kód": "16780",
            "Jogállás": "6",
            "Terület": "1702",
            "Népesség": "1219",
            "Lakások": "427"
        },
        {
            "Ir. szám": "3595",
            "name": "Hejőszalonta",
            "Megyekód": "BZ",
            "Lat": "47.9333",
            "Long": "20.8833",
            "KSH kód": "12159",
            "Jogállás": "6",
            "Terület": "1097",
            "Népesség": "852",
            "Lakások": "244"
        },
        {
            "Ir. szám": "3596",
            "name": "Szakáld",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "20.9167",
            "KSH kód": "03805",
            "Jogállás": "6",
            "Terület": "1140",
            "Népesség": "558",
            "Lakások": "227"
        },
        {
            "Ir. szám": "3597",
            "name": "Hejőkeresztúr",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "20.8833",
            "KSH kód": "04604",
            "Jogállás": "6",
            "Terület": "1028",
            "Népesség": "998",
            "Lakások": "404"
        },
        {
            "Ir. szám": "3598",
            "name": "Nagycsécs",
            "Megyekód": "BZ",
            "Lat": "47.9667",
            "Long": "20.95",
            "KSH kód": "05582",
            "Jogállás": "6",
            "Terület": "983",
            "Népesség": "835",
            "Lakások": "292"
        },
        {
            "Ir. szám": "3599",
            "name": "Sajószöged",
            "Megyekód": "BZ",
            "Lat": "47.95",
            "Long": "21",
            "KSH kód": "30340",
            "Jogállás": "6",
            "Terület": "1362",
            "Népesség": "2372",
            "Lakások": "893"
        },
        {
            "Ir. szám": "3600",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3601",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3603",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3604",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3607",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3608",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3621",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3622",
            "name": "Uppony",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.4333",
            "KSH kód": "18351",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "319",
            "Lakások": "191"
        },
        {
            "Ir. szám": "3623",
            "name": "Borsodszentgyörgy",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.2",
            "KSH kód": "30207",
            "Jogállás": "6",
            "Terület": "2152",
            "Népesség": "1188",
            "Lakások": "483"
        },
        {
            "Ir. szám": "3625",
            "name": "Borsodszentgyörgy",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.2",
            "KSH kód": "30207",
            "Jogállás": "6",
            "Terület": "2152",
            "Népesség": "1188",
            "Lakások": "483"
        },
        {
            "Ir. szám": "3626",
            "name": "Hangony",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "20.2",
            "KSH kód": "25104",
            "Jogállás": "6",
            "Terület": "3915",
            "Népesség": "1561",
            "Lakások": "616"
        },
        {
            "Ir. szám": "3627",
            "name": "Kissikátor",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.1333",
            "KSH kód": "14702",
            "Jogállás": "6",
            "Terület": "1038",
            "Népesség": "340",
            "Lakások": "128"
        },
        {
            "Ir. szám": "3627",
            "name": "Domaháza",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.1167",
            "KSH kód": "25690",
            "Jogállás": "6",
            "Terület": "2883",
            "Népesség": "872",
            "Lakások": "345"
        },
        {
            "Ir. szám": "3630",
            "name": "Putnok",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.4333",
            "KSH kód": "27410",
            "Jogállás": "4",
            "Terület": "3473",
            "Népesség": "7038",
            "Lakások": "2598"
        },
        {
            "Ir. szám": "3635",
            "name": "Dubicsány",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.5",
            "KSH kód": "27669",
            "Jogállás": "6",
            "Terület": "1300",
            "Népesség": "309",
            "Lakások": "119"
        },
        {
            "Ir. szám": "3636",
            "name": "Sajógalgóc",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.5333",
            "KSH kód": "10171",
            "Jogállás": "6",
            "Terület": "1012",
            "Népesség": "382",
            "Lakások": "137"
        },
        {
            "Ir. szám": "3636",
            "name": "Vadna",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.5667",
            "KSH kód": "07223",
            "Jogállás": "6",
            "Terület": "782",
            "Népesség": "617",
            "Lakások": "222"
        },
        {
            "Ir. szám": "3641",
            "name": "Nagybarca",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.5333",
            "KSH kód": "29188",
            "Jogállás": "6",
            "Terület": "1439",
            "Népesség": "1104",
            "Lakások": "354"
        },
        {
            "Ir. szám": "3642",
            "name": "Bánhorváti",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "20.5167",
            "KSH kód": "25159",
            "Jogállás": "6",
            "Terület": "2848",
            "Népesség": "1409",
            "Lakások": "592"
        },
        {
            "Ir. szám": "3643",
            "name": "Dédestapolcsány",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.4833",
            "KSH kód": "04686",
            "Jogállás": "6",
            "Terület": "2943",
            "Népesség": "1475",
            "Lakások": "769"
        },
        {
            "Ir. szám": "3644",
            "name": "Tardona",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.5333",
            "KSH kód": "14784",
            "Jogállás": "6",
            "Terület": "1223",
            "Népesség": "1056",
            "Lakások": "433"
        },
        {
            "Ir. szám": "3645",
            "name": "Mályinka",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.5",
            "KSH kód": "14915",
            "Jogállás": "6",
            "Terület": "2421",
            "Népesség": "484",
            "Lakások": "236"
        },
        {
            "Ir. szám": "3646",
            "name": "Nekézseny",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.4333",
            "KSH kód": "28033",
            "Jogállás": "6",
            "Terület": "1410",
            "Népesség": "810",
            "Lakások": "343"
        },
        {
            "Ir. szám": "3647",
            "name": "Csokvaomány",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.3833",
            "KSH kód": "14289",
            "Jogállás": "6",
            "Terület": "1499",
            "Népesség": "858",
            "Lakások": "450"
        },
        {
            "Ir. szám": "3648",
            "name": "Lénárddaróc",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.3833",
            "KSH kód": "07038",
            "Jogállás": "6",
            "Terület": "516",
            "Népesség": "305",
            "Lakások": "170"
        },
        {
            "Ir. szám": "3648",
            "name": "Bükkmogyorósd",
            "Megyekód": "BZ",
            "Lat": "48.1333",
            "Long": "20.3667",
            "KSH kód": "19406",
            "Jogállás": "6",
            "Terület": "990",
            "Népesség": "141",
            "Lakások": "107"
        },
        {
            "Ir. szám": "3648",
            "name": "Csernely",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.35",
            "KSH kód": "28459",
            "Jogállás": "6",
            "Terület": "2062",
            "Népesség": "819",
            "Lakások": "459"
        },
        {
            "Ir. szám": "3651",
            "name": "Lénárddaróc",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.3833",
            "KSH kód": "07038",
            "Jogállás": "6",
            "Terület": "516",
            "Népesség": "305",
            "Lakások": "170"
        },
        {
            "Ir. szám": "3652",
            "name": "Sajónémeti",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.3833",
            "KSH kód": "11332",
            "Jogállás": "6",
            "Terület": "738",
            "Népesség": "533",
            "Lakások": "249"
        },
        {
            "Ir. szám": "3653",
            "name": "Sajópüspöki",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.35",
            "KSH kód": "23782",
            "Jogállás": "6",
            "Terület": "945",
            "Népesség": "540",
            "Lakások": "205"
        },
        {
            "Ir. szám": "3654",
            "name": "Bánréve",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.3667",
            "KSH kód": "21953",
            "Jogállás": "6",
            "Terület": "663",
            "Népesség": "1376",
            "Lakások": "556"
        },
        {
            "Ir. szám": "3655",
            "name": "Hét",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.3833",
            "KSH kód": "08004",
            "Jogállás": "6",
            "Terület": "371",
            "Népesség": "543",
            "Lakások": "214"
        },
        {
            "Ir. szám": "3656",
            "name": "Sajómercse",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.4167",
            "KSH kód": "15945",
            "Jogállás": "6",
            "Terület": "1118",
            "Népesség": "224",
            "Lakások": "130"
        },
        {
            "Ir. szám": "3656",
            "name": "Sajóvelezd",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.4667",
            "KSH kód": "27757",
            "Jogállás": "6",
            "Terület": "2302",
            "Népesség": "849",
            "Lakások": "317"
        },
        {
            "Ir. szám": "3657",
            "name": "Királd",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.4",
            "KSH kód": "32090",
            "Jogállás": "6",
            "Terület": "788",
            "Népesség": "896",
            "Lakások": "413"
        },
        {
            "Ir. szám": "3658",
            "name": "Borsodbóta",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.4",
            "KSH kód": "30669",
            "Jogállás": "6",
            "Terület": "814",
            "Népesség": "901",
            "Lakások": "357"
        },
        {
            "Ir. szám": "3659",
            "name": "Sáta",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.4",
            "KSH kód": "02875",
            "Jogállás": "6",
            "Terület": "1651",
            "Népesség": "1142",
            "Lakások": "544"
        },
        {
            "Ir. szám": "3661",
            "name": "Sáta",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.4",
            "KSH kód": "02875",
            "Jogállás": "6",
            "Terület": "1651",
            "Népesség": "1142",
            "Lakások": "544"
        },
        {
            "Ir. szám": "3662",
            "name": "Sáta",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.4",
            "KSH kód": "02875",
            "Jogállás": "6",
            "Terület": "1651",
            "Népesség": "1142",
            "Lakások": "544"
        },
        {
            "Ir. szám": "3663",
            "name": "Arló",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.2667",
            "KSH kód": "14331",
            "Jogállás": "5",
            "Terület": "4687",
            "Népesség": "3785",
            "Lakások": "1170"
        },
        {
            "Ir. szám": "3664",
            "name": "Járdánháza",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.25",
            "KSH kód": "26657",
            "Jogállás": "6",
            "Terület": "1125",
            "Népesség": "1867",
            "Lakások": "652"
        },
        {
            "Ir. szám": "3671",
            "name": "Borsodnádasd",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "20.25",
            "KSH kód": "05315",
            "Jogállás": "4",
            "Terület": "2807",
            "Népesség": "3146",
            "Lakások": "1382"
        },
        {
            "Ir. szám": "3672",
            "name": "Borsodnádasd",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "20.25",
            "KSH kód": "05315",
            "Jogállás": "4",
            "Terület": "2807",
            "Népesség": "3146",
            "Lakások": "1382"
        },
        {
            "Ir. szám": "3700",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3701",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3702",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3703",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3704",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3705",
            "name": "Kazincbarcika",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.6333",
            "KSH kód": "06691",
            "Jogállás": "4",
            "Terület": "3667",
            "Népesség": "29695",
            "Lakások": "12616"
        },
        {
            "Ir. szám": "3711",
            "name": "Szirmabesenyő",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "20.8",
            "KSH kód": "09496",
            "Jogállás": "5",
            "Terület": "1575",
            "Népesség": "4531",
            "Lakások": "1612"
        },
        {
            "Ir. szám": "3712",
            "name": "Sajósenye",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.8333",
            "KSH kód": "08970",
            "Jogállás": "6",
            "Terület": "847",
            "Népesség": "470",
            "Lakások": "135"
        },
        {
            "Ir. szám": "3712",
            "name": "Sajóvámos",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.85",
            "KSH kód": "20738",
            "Jogállás": "6",
            "Terület": "3122",
            "Népesség": "2247",
            "Lakások": "778"
        },
        {
            "Ir. szám": "3713",
            "name": "Arnót",
            "Megyekód": "BZ",
            "Lat": "48.1333",
            "Long": "20.8667",
            "KSH kód": "03771",
            "Jogállás": "6",
            "Terület": "1754",
            "Népesség": "2607",
            "Lakások": "806"
        },
        {
            "Ir. szám": "3714",
            "name": "Sajópálfala",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.85",
            "KSH kód": "18537",
            "Jogállás": "6",
            "Terület": "711",
            "Népesség": "792",
            "Lakások": "282"
        },
        {
            "Ir. szám": "3715",
            "name": "Gesztely",
            "Megyekód": "BZ",
            "Lat": "48.1",
            "Long": "20.9667",
            "KSH kód": "15608",
            "Jogállás": "6",
            "Terület": "2882",
            "Népesség": "2826",
            "Lakások": "951"
        },
        {
            "Ir. szám": "3716",
            "name": "Újcsanálos",
            "Megyekód": "BZ",
            "Lat": "48.1333",
            "Long": "21.0167",
            "KSH kód": "12487",
            "Jogállás": "6",
            "Terület": "1218",
            "Népesség": "905",
            "Lakások": "335"
        },
        {
            "Ir. szám": "3716",
            "name": "Sóstófalva",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "21",
            "KSH kód": "13268",
            "Jogállás": "6",
            "Terület": "728",
            "Népesség": "301",
            "Lakások": "112"
        },
        {
            "Ir. szám": "3717",
            "name": "Alsódobsza",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "21",
            "KSH kód": "19664",
            "Jogállás": "6",
            "Terület": "1069",
            "Népesség": "344",
            "Lakások": "163"
        },
        {
            "Ir. szám": "3718",
            "name": "Megyaszó",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "21.05",
            "KSH kód": "21768",
            "Jogállás": "6",
            "Terület": "5342",
            "Népesség": "2875",
            "Lakások": "920"
        },
        {
            "Ir. szám": "3720",
            "name": "Sajókaza",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.5833",
            "KSH kód": "14313",
            "Jogállás": "6",
            "Terület": "3072",
            "Népesség": "3489",
            "Lakások": "1053"
        },
        {
            "Ir. szám": "3720",
            "name": "Sajóivánka",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.5833",
            "KSH kód": "03212",
            "Jogállás": "6",
            "Terület": "1023",
            "Népesség": "592",
            "Lakások": "220"
        },
        {
            "Ir. szám": "3721",
            "name": "Felsőnyárád",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.6",
            "KSH kód": "32762",
            "Jogállás": "6",
            "Terület": "1167",
            "Népesség": "1049",
            "Lakások": "394"
        },
        {
            "Ir. szám": "3721",
            "name": "Jákfalva",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.5833",
            "KSH kód": "20233",
            "Jogállás": "6",
            "Terület": "906",
            "Népesség": "513",
            "Lakások": "149"
        },
        {
            "Ir. szám": "3721",
            "name": "Dövény",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "20.55",
            "KSH kód": "06123",
            "Jogállás": "6",
            "Terület": "1165",
            "Népesség": "289",
            "Lakások": "115"
        },
        {
            "Ir. szám": "3722",
            "name": "Felsőkelecsény",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "20.6",
            "KSH kód": "31723",
            "Jogállás": "6",
            "Terület": "799",
            "Népesség": "407",
            "Lakások": "144"
        },
        {
            "Ir. szám": "3723",
            "name": "Zubogy",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.5833",
            "KSH kód": "19105",
            "Jogállás": "6",
            "Terület": "1143",
            "Népesség": "601",
            "Lakások": "233"
        },
        {
            "Ir. szám": "3724",
            "name": "Imola",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "20.55",
            "KSH kód": "16577",
            "Jogállás": "6",
            "Terület": "1812",
            "Népesség": "101",
            "Lakások": "70"
        },
        {
            "Ir. szám": "3724",
            "name": "Ragály",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.5167",
            "KSH kód": "06053",
            "Jogállás": "6",
            "Terület": "1554",
            "Népesség": "664",
            "Lakások": "253"
        },
        {
            "Ir. szám": "3724",
            "name": "Trizs",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "20.5",
            "KSH kód": "04914",
            "Jogállás": "6",
            "Terület": "1025",
            "Népesség": "227",
            "Lakások": "113"
        },
        {
            "Ir. szám": "3726",
            "name": "Alsószuha",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "20.5167",
            "KSH kód": "28839",
            "Jogállás": "6",
            "Terület": "2242",
            "Népesség": "459",
            "Lakások": "183"
        },
        {
            "Ir. szám": "3726",
            "name": "Szuhafő",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "20.45",
            "KSH kód": "03160",
            "Jogállás": "6",
            "Terület": "1605",
            "Népesség": "156",
            "Lakások": "91"
        },
        {
            "Ir. szám": "3726",
            "name": "Zádorfalva",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.4833",
            "KSH kód": "03063",
            "Jogállás": "6",
            "Terület": "1375",
            "Népesség": "475",
            "Lakások": "179"
        },
        {
            "Ir. szám": "3728",
            "name": "Gömörszőlős",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.4333",
            "KSH kód": "09706",
            "Jogállás": "6",
            "Terület": "878",
            "Népesség": "69",
            "Lakások": "47"
        },
        {
            "Ir. szám": "3728",
            "name": "Kelemér",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "20.4333",
            "KSH kód": "12034",
            "Jogállás": "6",
            "Terület": "1881",
            "Népesség": "539",
            "Lakások": "181"
        },
        {
            "Ir. szám": "3729",
            "name": "Serényfalva",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "20.4",
            "KSH kód": "32531",
            "Jogállás": "6",
            "Terület": "1964",
            "Népesség": "998",
            "Lakások": "369"
        },
        {
            "Ir. szám": "3731",
            "name": "Szuhakálló",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.6667",
            "KSH kód": "11110",
            "Jogállás": "6",
            "Terület": "696",
            "Népesség": "1037",
            "Lakások": "393"
        },
        {
            "Ir. szám": "3732",
            "name": "Kurityán",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "20.6333",
            "KSH kód": "11819",
            "Jogállás": "6",
            "Terület": "754",
            "Népesség": "1712",
            "Lakások": "603"
        },
        {
            "Ir. szám": "3733",
            "name": "Rudabánya",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.6333",
            "KSH kód": "23029",
            "Jogállás": "4",
            "Terület": "1646",
            "Népesség": "2668",
            "Lakások": "1151"
        },
        {
            "Ir. szám": "3734",
            "name": "Szuhogy",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.6833",
            "KSH kód": "24606",
            "Jogállás": "6",
            "Terület": "1700",
            "Népesség": "1243",
            "Lakások": "448"
        },
        {
            "Ir. szám": "3735",
            "name": "Kánó",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "20.6",
            "KSH kód": "07764",
            "Jogállás": "6",
            "Terület": "1377",
            "Népesség": "164",
            "Lakások": "95"
        },
        {
            "Ir. szám": "3735",
            "name": "Felsőtelekes",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.6333",
            "KSH kód": "31671",
            "Jogállás": "6",
            "Terület": "845",
            "Népesség": "762",
            "Lakások": "288"
        },
        {
            "Ir. szám": "3735",
            "name": "Alsótelekes",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "20.6667",
            "KSH kód": "08217",
            "Jogállás": "6",
            "Terület": "644",
            "Népesség": "137",
            "Lakások": "93"
        },
        {
            "Ir. szám": "3741",
            "name": "Izsófalva",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.6667",
            "KSH kód": "05591",
            "Jogállás": "5",
            "Terület": "941",
            "Népesség": "1763",
            "Lakások": "617"
        },
        {
            "Ir. szám": "3742",
            "name": "Rudolftelep",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "20.6833",
            "KSH kód": "34120",
            "Jogállás": "6",
            "Terület": "439",
            "Népesség": "746",
            "Lakások": "294"
        },
        {
            "Ir. szám": "3743",
            "name": "Ormosbánya",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "20.65",
            "KSH kód": "34069",
            "Jogállás": "6",
            "Terület": "755",
            "Népesség": "1779",
            "Lakások": "701"
        },
        {
            "Ir. szám": "3744",
            "name": "Múcsony",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.6833",
            "KSH kód": "21546",
            "Jogállás": "5",
            "Terület": "1754",
            "Népesség": "3210",
            "Lakások": "1209"
        },
        {
            "Ir. szám": "3751",
            "name": "Szendrőlád",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "20.75",
            "KSH kód": "27456",
            "Jogállás": "6",
            "Terület": "1770",
            "Népesség": "1933",
            "Lakások": "440"
        },
        {
            "Ir. szám": "3752",
            "name": "Galvács",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "20.7833",
            "KSH kód": "19293",
            "Jogállás": "6",
            "Terület": "1504",
            "Népesség": "91",
            "Lakások": "79"
        },
        {
            "Ir. szám": "3752",
            "name": "Szendrő",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.7333",
            "KSH kód": "08077",
            "Jogállás": "4",
            "Terület": "5356",
            "Népesség": "4177",
            "Lakások": "1454"
        },
        {
            "Ir. szám": "3753",
            "name": "Abod",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.8",
            "KSH kód": "10357",
            "Jogállás": "6",
            "Terület": "3117",
            "Népesség": "228",
            "Lakások": "126"
        },
        {
            "Ir. szám": "3754",
            "name": "Szalonna",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "20.75",
            "KSH kód": "16753",
            "Jogállás": "6",
            "Terület": "2008",
            "Népesség": "1023",
            "Lakások": "364"
        },
        {
            "Ir. szám": "3754",
            "name": "Meszes",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "20.8",
            "KSH kód": "11758",
            "Jogállás": "6",
            "Terület": "1140",
            "Népesség": "169",
            "Lakások": "117"
        },
        {
            "Ir. szám": "3755",
            "name": "Martonyi",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.7667",
            "KSH kód": "02024",
            "Jogállás": "6",
            "Terület": "1757",
            "Népesség": "424",
            "Lakások": "182"
        },
        {
            "Ir. szám": "3756",
            "name": "Varbóc",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.65",
            "KSH kód": "10144",
            "Jogállás": "6",
            "Terület": "1010",
            "Népesség": "48",
            "Lakások": "60"
        },
        {
            "Ir. szám": "3756",
            "name": "Perkupa",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.7",
            "KSH kód": "33419",
            "Jogállás": "6",
            "Terület": "1940",
            "Népesség": "854",
            "Lakások": "357"
        },
        {
            "Ir. szám": "3757",
            "name": "Égerszög",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "20.5833",
            "KSH kód": "13356",
            "Jogállás": "6",
            "Terület": "1077",
            "Népesség": "52",
            "Lakások": "46"
        },
        {
            "Ir. szám": "3757",
            "name": "Teresztenye",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "20.6167",
            "KSH kód": "28635",
            "Jogállás": "6",
            "Terület": "869",
            "Népesség": "27",
            "Lakások": "23"
        },
        {
            "Ir. szám": "3757",
            "name": "Szőlősardó",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "20.6333",
            "KSH kód": "07889",
            "Jogállás": "6",
            "Terület": "1066",
            "Népesség": "109",
            "Lakások": "84"
        },
        {
            "Ir. szám": "3758",
            "name": "Jósvafő",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "20.5667",
            "KSH kód": "15680",
            "Jogállás": "6",
            "Terület": "2155",
            "Népesség": "256",
            "Lakások": "176"
        },
        {
            "Ir. szám": "3759",
            "name": "Aggtelek",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.5167",
            "KSH kód": "09362",
            "Jogállás": "6",
            "Terület": "4382",
            "Népesség": "569",
            "Lakások": "247"
        },
        {
            "Ir. szám": "3761",
            "name": "Szin",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.6667",
            "KSH kód": "20871",
            "Jogállás": "6",
            "Terület": "1810",
            "Népesség": "751",
            "Lakások": "256"
        },
        {
            "Ir. szám": "3761",
            "name": "Tornakápolna",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.6167",
            "KSH kód": "10375",
            "Jogállás": "6",
            "Terület": "402",
            "Népesség": "29",
            "Lakások": "34"
        },
        {
            "Ir. szám": "3761",
            "name": "Szinpetri",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "20.6333",
            "KSH kód": "11493",
            "Jogállás": "6",
            "Terület": "971",
            "Népesség": "228",
            "Lakások": "100"
        },
        {
            "Ir. szám": "3762",
            "name": "Szögliget",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "20.6833",
            "KSH kód": "16179",
            "Jogállás": "6",
            "Terület": "3474",
            "Népesség": "641",
            "Lakások": "395"
        },
        {
            "Ir. szám": "3763",
            "name": "Bódvaszilas",
            "Megyekód": "BZ",
            "Lat": "48.5333",
            "Long": "20.7333",
            "KSH kód": "05926",
            "Jogállás": "6",
            "Terület": "1922",
            "Népesség": "1067",
            "Lakások": "484"
        },
        {
            "Ir. szám": "3764",
            "name": "Bódvarákó",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "20.75",
            "KSH kód": "33303",
            "Jogállás": "6",
            "Terület": "922",
            "Népesség": "109",
            "Lakások": "82"
        },
        {
            "Ir. szám": "3765",
            "name": "Tornaszentandrás",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "20.7833",
            "KSH kód": "27836",
            "Jogállás": "6",
            "Terület": "1565",
            "Népesség": "224",
            "Lakások": "135"
        },
        {
            "Ir. szám": "3765",
            "name": "Komjáti",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "20.7667",
            "KSH kód": "10612",
            "Jogállás": "6",
            "Terület": "1108",
            "Népesség": "290",
            "Lakások": "143"
        },
        {
            "Ir. szám": "3765",
            "name": "Tornabarakony",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.8167",
            "KSH kód": "16902",
            "Jogállás": "6",
            "Terület": "820",
            "Népesség": "15",
            "Lakások": "29"
        },
        {
            "Ir. szám": "3767",
            "name": "Tornanádaska",
            "Megyekód": "BZ",
            "Lat": "48.5667",
            "Long": "20.7833",
            "KSH kód": "18801",
            "Jogállás": "6",
            "Terület": "783",
            "Népesség": "595",
            "Lakások": "134"
        },
        {
            "Ir. szám": "3768",
            "name": "Hidvégardó",
            "Megyekód": "BZ",
            "Lat": "48.5667",
            "Long": "20.85",
            "KSH kód": "25672",
            "Jogállás": "6",
            "Terület": "1699",
            "Népesség": "575",
            "Lakások": "298"
        },
        {
            "Ir. szám": "3768",
            "name": "Bódvalenke",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "20.8167",
            "KSH kód": "27429",
            "Jogállás": "6",
            "Terület": "663",
            "Népesség": "182",
            "Lakások": "62"
        },
        {
            "Ir. szám": "3768",
            "name": "Becskeháza",
            "Megyekód": "BZ",
            "Lat": "48.5333",
            "Long": "20.85",
            "KSH kód": "27049",
            "Jogállás": "6",
            "Terület": "500",
            "Népesség": "40",
            "Lakások": "40"
        },
        {
            "Ir. szám": "3769",
            "name": "Tornaszentjakab",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "20.8833",
            "KSH kód": "30517",
            "Jogállás": "6",
            "Terület": "2822",
            "Népesség": "205",
            "Lakások": "135"
        },
        {
            "Ir. szám": "3770",
            "name": "Sajószentpéter",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.7167",
            "KSH kód": "16054",
            "Jogállás": "4",
            "Terület": "3485",
            "Népesség": "12453",
            "Lakások": "4579"
        },
        {
            "Ir. szám": "3773",
            "name": "Sajókápolna",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.7",
            "KSH kód": "21670",
            "Jogállás": "6",
            "Terület": "1051",
            "Népesség": "409",
            "Lakások": "165"
        },
        {
            "Ir. szám": "3773",
            "name": "Sajólászlófalva",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.6833",
            "KSH kód": "22479",
            "Jogállás": "6",
            "Terület": "662",
            "Népesség": "438",
            "Lakások": "166"
        },
        {
            "Ir. szám": "3775",
            "name": "Kondó",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.65",
            "KSH kód": "32498",
            "Jogállás": "6",
            "Terület": "1963",
            "Népesség": "607",
            "Lakások": "222"
        },
        {
            "Ir. szám": "3776",
            "name": "Radostyán",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "20.6667",
            "KSH kód": "21193",
            "Jogállás": "6",
            "Terület": "810",
            "Népesség": "627",
            "Lakások": "223"
        },
        {
            "Ir. szám": "3777",
            "name": "Parasznya",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.65",
            "KSH kód": "26745",
            "Jogállás": "6",
            "Terület": "1676",
            "Népesség": "1208",
            "Lakások": "438"
        },
        {
            "Ir. szám": "3778",
            "name": "Varbó",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.6333",
            "KSH kód": "21810",
            "Jogállás": "6",
            "Terület": "2588",
            "Népesség": "1105",
            "Lakások": "404"
        },
        {
            "Ir. szám": "3779",
            "name": "Alacska",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.65",
            "KSH kód": "33093",
            "Jogállás": "6",
            "Terület": "856",
            "Népesség": "812",
            "Lakások": "281"
        },
        {
            "Ir. szám": "3780",
            "name": "Ládbesenyő",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.7333",
            "KSH kód": "26231",
            "Jogállás": "6",
            "Terület": "1040",
            "Népesség": "285",
            "Lakások": "127"
        },
        {
            "Ir. szám": "3780",
            "name": "Damak",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "20.8333",
            "KSH kód": "11350",
            "Jogállás": "6",
            "Terület": "752",
            "Népesség": "264",
            "Lakások": "104"
        },
        {
            "Ir. szám": "3780",
            "name": "Balajt",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.7333",
            "KSH kód": "22521",
            "Jogállás": "6",
            "Terület": "903",
            "Népesség": "465",
            "Lakások": "149"
        },
        {
            "Ir. szám": "3780",
            "name": "Edelény",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.7333",
            "KSH kód": "10728",
            "Jogállás": "4",
            "Terület": "5684",
            "Népesség": "10372",
            "Lakások": "3567"
        },
        {
            "Ir. szám": "3783",
            "name": "Ládbesenyő",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.7333",
            "KSH kód": "26231",
            "Jogállás": "6",
            "Terület": "1040",
            "Népesség": "285",
            "Lakások": "127"
        },
        {
            "Ir. szám": "3786",
            "name": "Irota",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.8833",
            "KSH kód": "05005",
            "Jogállás": "6",
            "Terület": "1234",
            "Népesség": "70",
            "Lakások": "64"
        },
        {
            "Ir. szám": "3786",
            "name": "Szakácsi",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "20.8667",
            "KSH kód": "32258",
            "Jogállás": "6",
            "Terület": "862",
            "Népesség": "139",
            "Lakások": "81"
        },
        {
            "Ir. szám": "3786",
            "name": "Hegymeg",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.8667",
            "KSH kód": "02468",
            "Jogállás": "6",
            "Terület": "570",
            "Népesség": "110",
            "Lakások": "57"
        },
        {
            "Ir. szám": "3786",
            "name": "Lak",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "20.8667",
            "KSH kód": "15857",
            "Jogállás": "6",
            "Terület": "1976",
            "Népesség": "606",
            "Lakások": "209"
        },
        {
            "Ir. szám": "3787",
            "name": "Tomor",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.8833",
            "KSH kód": "14890",
            "Jogállás": "6",
            "Terület": "1289",
            "Népesség": "230",
            "Lakások": "124"
        },
        {
            "Ir. szám": "3791",
            "name": "Sajókeresztúr",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.7833",
            "KSH kód": "26949",
            "Jogállás": "6",
            "Terület": "1639",
            "Népesség": "1541",
            "Lakások": "570"
        },
        {
            "Ir. szám": "3792",
            "name": "Sajóbábony",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "20.7333",
            "KSH kód": "03504",
            "Jogállás": "5",
            "Terület": "1343",
            "Népesség": "2877",
            "Lakások": "1144"
        },
        {
            "Ir. szám": "3793",
            "name": "Sajóecseg",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.7833",
            "KSH kód": "27331",
            "Jogállás": "6",
            "Terület": "794",
            "Népesség": "1024",
            "Lakások": "433"
        },
        {
            "Ir. szám": "3794",
            "name": "Boldva",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.8",
            "KSH kód": "08396",
            "Jogállás": "6",
            "Terület": "2834",
            "Népesség": "2384",
            "Lakások": "801"
        },
        {
            "Ir. szám": "3794",
            "name": "Ziliz",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.8",
            "KSH kód": "19275",
            "Jogállás": "6",
            "Terület": "925",
            "Népesség": "369",
            "Lakások": "137"
        },
        {
            "Ir. szám": "3795",
            "name": "Hangács",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.8333",
            "KSH kód": "11226",
            "Jogállás": "6",
            "Terület": "2253",
            "Népesség": "623",
            "Lakások": "256"
        },
        {
            "Ir. szám": "3795",
            "name": "Nyomár",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.8167",
            "KSH kód": "04215",
            "Jogállás": "6",
            "Terület": "1046",
            "Népesség": "332",
            "Lakások": "133"
        },
        {
            "Ir. szám": "3796",
            "name": "Borsodszirák",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "20.7667",
            "KSH kód": "16799",
            "Jogállás": "6",
            "Terület": "1101",
            "Népesség": "1228",
            "Lakások": "390"
        },
        {
            "Ir. szám": "3800",
            "name": "Szikszó",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "20.9333",
            "KSH kód": "21351",
            "Jogállás": "4",
            "Terület": "3623",
            "Népesség": "5637",
            "Lakások": "2212"
        },
        {
            "Ir. szám": "3809",
            "name": "Abaújszolnok",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "20.9833",
            "KSH kód": "26338",
            "Jogállás": "6",
            "Terület": "866",
            "Népesség": "164",
            "Lakások": "70"
        },
        {
            "Ir. szám": "3809",
            "name": "Nyésta",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "20.95",
            "KSH kód": "04419",
            "Jogállás": "6",
            "Terület": "693",
            "Népesség": "53",
            "Lakások": "36"
        },
        {
            "Ir. szám": "3809",
            "name": "Selyeb",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.9667",
            "KSH kód": "25380",
            "Jogállás": "6",
            "Terület": "1668",
            "Népesség": "497",
            "Lakások": "186"
        },
        {
            "Ir. szám": "3811",
            "name": "Alsóvadász",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "20.9167",
            "KSH kód": "29814",
            "Jogállás": "6",
            "Terület": "2291",
            "Népesség": "1554",
            "Lakások": "457"
        },
        {
            "Ir. szám": "3812",
            "name": "Monaj",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.95",
            "KSH kód": "09016",
            "Jogállás": "6",
            "Terület": "1155",
            "Népesség": "265",
            "Lakások": "107"
        },
        {
            "Ir. szám": "3812",
            "name": "Homrogd",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.9167",
            "KSH kód": "21236",
            "Jogállás": "6",
            "Terület": "1347",
            "Népesség": "986",
            "Lakások": "327"
        },
        {
            "Ir. szám": "3813",
            "name": "Kupa",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "20.9167",
            "KSH kód": "18722",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "144",
            "Lakások": "79"
        },
        {
            "Ir. szám": "3814",
            "name": "Felsővadász",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "20.9333",
            "KSH kód": "23533",
            "Jogállás": "6",
            "Terület": "1866",
            "Népesség": "549",
            "Lakások": "186"
        },
        {
            "Ir. szám": "3815",
            "name": "Gadna",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.9333",
            "KSH kód": "05494",
            "Jogállás": "6",
            "Terület": "809",
            "Népesség": "260",
            "Lakások": "83"
        },
        {
            "Ir. szám": "3815",
            "name": "Abaújlak",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "20.9667",
            "KSH kód": "02820",
            "Jogállás": "6",
            "Terület": "706",
            "Népesség": "114",
            "Lakások": "86"
        },
        {
            "Ir. szám": "3816",
            "name": "Gagyvendégi",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "20.9833",
            "KSH kód": "03744",
            "Jogállás": "6",
            "Terület": "1232",
            "Népesség": "188",
            "Lakások": "108"
        },
        {
            "Ir. szám": "3817",
            "name": "Gagybátor",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "20.95",
            "KSH kód": "28307",
            "Jogállás": "6",
            "Terület": "1877",
            "Népesség": "238",
            "Lakások": "118"
        },
        {
            "Ir. szám": "3821",
            "name": "Pamlény",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.9333",
            "KSH kód": "12371",
            "Jogállás": "6",
            "Terület": "1243",
            "Népesség": "58",
            "Lakások": "55"
        },
        {
            "Ir. szám": "3821",
            "name": "Büttös",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.0167",
            "KSH kód": "23977",
            "Jogállás": "6",
            "Terület": "1822",
            "Népesség": "188",
            "Lakások": "108"
        },
        {
            "Ir. szám": "3821",
            "name": "Szászfa",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.95",
            "KSH kód": "08147",
            "Jogállás": "6",
            "Terület": "1213",
            "Népesség": "155",
            "Lakások": "85"
        },
        {
            "Ir. szám": "3821",
            "name": "Kány",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "21.0167",
            "KSH kód": "25742",
            "Jogállás": "6",
            "Terület": "925",
            "Népesség": "61",
            "Lakások": "57"
        },
        {
            "Ir. szám": "3821",
            "name": "Perecse",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21",
            "KSH kód": "32683",
            "Jogállás": "6",
            "Terület": "1257",
            "Népesség": "33",
            "Lakások": "38"
        },
        {
            "Ir. szám": "3821",
            "name": "Krasznokvajda",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.9833",
            "KSH kód": "19576",
            "Jogállás": "6",
            "Terület": "1144",
            "Népesség": "503",
            "Lakások": "202"
        },
        {
            "Ir. szám": "3821",
            "name": "Keresztéte",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.95",
            "KSH kód": "17066",
            "Jogállás": "6",
            "Terület": "401",
            "Népesség": "31",
            "Lakások": "27"
        },
        {
            "Ir. szám": "3825",
            "name": "Debréte",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.8667",
            "KSH kód": "31954",
            "Jogállás": "6",
            "Terület": "962",
            "Népesség": "19",
            "Lakások": "28"
        },
        {
            "Ir. szám": "3825",
            "name": "Rakaca",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.9",
            "KSH kód": "29717",
            "Jogállás": "6",
            "Terület": "1900",
            "Népesség": "763",
            "Lakások": "262"
        },
        {
            "Ir. szám": "3825",
            "name": "Viszló",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "20.9",
            "KSH kód": "03957",
            "Jogállás": "6",
            "Terület": "1110",
            "Népesség": "72",
            "Lakások": "58"
        },
        {
            "Ir. szám": "3826",
            "name": "Rakacaszend",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "20.85",
            "KSH kód": "16133",
            "Jogállás": "6",
            "Terület": "1571",
            "Népesség": "352",
            "Lakások": "166"
        },
        {
            "Ir. szám": "3831",
            "name": "Kázsmárk",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.9833",
            "KSH kód": "13374",
            "Jogállás": "6",
            "Terület": "1252",
            "Népesség": "958",
            "Lakások": "266"
        },
        {
            "Ir. szám": "3832",
            "name": "Léh",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "20.9833",
            "KSH kód": "03531",
            "Jogállás": "6",
            "Terület": "845",
            "Népesség": "452",
            "Lakások": "153"
        },
        {
            "Ir. szám": "3833",
            "name": "Rásonysápberencs",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21",
            "KSH kód": "31909",
            "Jogállás": "6",
            "Terület": "916",
            "Népesség": "591",
            "Lakások": "197"
        },
        {
            "Ir. szám": "3834",
            "name": "Beret",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.0333",
            "KSH kód": "29674",
            "Jogállás": "6",
            "Terület": "682",
            "Népesség": "273",
            "Lakások": "93"
        },
        {
            "Ir. szám": "3834",
            "name": "Detek",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.0333",
            "KSH kód": "10524",
            "Jogállás": "6",
            "Terület": "970",
            "Népesség": "279",
            "Lakások": "135"
        },
        {
            "Ir. szám": "3836",
            "name": "Baktakék",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.0333",
            "KSH kód": "18184",
            "Jogállás": "6",
            "Terület": "1885",
            "Népesség": "700",
            "Lakások": "294"
        },
        {
            "Ir. szám": "3837",
            "name": "Felsőgagy",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "21.0167",
            "KSH kód": "29708",
            "Jogállás": "6",
            "Terület": "1447",
            "Népesség": "161",
            "Lakások": "89"
        },
        {
            "Ir. szám": "3837",
            "name": "Gagyapáti",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "21",
            "KSH kód": "28732",
            "Jogállás": "6",
            "Terület": "326",
            "Népesség": "24",
            "Lakások": "14"
        },
        {
            "Ir. szám": "3837",
            "name": "Alsógagy",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.0333",
            "KSH kód": "14429",
            "Jogállás": "6",
            "Terület": "628",
            "Népesség": "100",
            "Lakások": "56"
        },
        {
            "Ir. szám": "3837",
            "name": "Csenyéte",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "21.05",
            "KSH kód": "08493",
            "Jogállás": "6",
            "Terület": "1000",
            "Népesség": "454",
            "Lakások": "83"
        },
        {
            "Ir. szám": "3841",
            "name": "Aszaló",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.9667",
            "KSH kód": "04233",
            "Jogállás": "6",
            "Terület": "2540",
            "Népesség": "1960",
            "Lakások": "644"
        },
        {
            "Ir. szám": "3842",
            "name": "Halmaj",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "21",
            "KSH kód": "27942",
            "Jogállás": "6",
            "Terület": "1263",
            "Népesség": "1885",
            "Lakások": "600"
        },
        {
            "Ir. szám": "3843",
            "name": "Kiskinizs",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "21.05",
            "KSH kód": "03762",
            "Jogállás": "6",
            "Terület": "718",
            "Népesség": "339",
            "Lakások": "130"
        },
        {
            "Ir. szám": "3844",
            "name": "Szentistvánbaksa",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "21.0333",
            "KSH kód": "08484",
            "Jogállás": "6",
            "Terület": "656",
            "Népesség": "277",
            "Lakások": "140"
        },
        {
            "Ir. szám": "3844",
            "name": "Nagykinizs",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "21.0333",
            "KSH kód": "26505",
            "Jogállás": "6",
            "Terület": "662",
            "Népesség": "350",
            "Lakások": "138"
        },
        {
            "Ir. szám": "3846",
            "name": "Hernádkércs",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "21.05",
            "KSH kód": "21829",
            "Jogállás": "6",
            "Terület": "741",
            "Népesség": "267",
            "Lakások": "151"
        },
        {
            "Ir. szám": "3847",
            "name": "Felsődobsza",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "21.0667",
            "KSH kód": "09742",
            "Jogállás": "6",
            "Terület": "1527",
            "Népesség": "933",
            "Lakások": "343"
        },
        {
            "Ir. szám": "3848",
            "name": "Csobád",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.0333",
            "KSH kód": "05333",
            "Jogállás": "6",
            "Terület": "1186",
            "Népesség": "697",
            "Lakások": "265"
        },
        {
            "Ir. szám": "3849",
            "name": "Forró",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.0833",
            "KSH kód": "30483",
            "Jogállás": "6",
            "Terület": "1903",
            "Népesség": "2476",
            "Lakások": "859"
        },
        {
            "Ir. szám": "3851",
            "name": "Ináncs",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.0667",
            "KSH kód": "08086",
            "Jogállás": "6",
            "Terület": "1098",
            "Népesség": "1278",
            "Lakások": "437"
        },
        {
            "Ir. szám": "3852",
            "name": "Hernádszentandrás",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.1",
            "KSH kód": "17136",
            "Jogállás": "6",
            "Terület": "704",
            "Népesség": "475",
            "Lakások": "165"
        },
        {
            "Ir. szám": "3853",
            "name": "Pere",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.1333",
            "KSH kód": "24420",
            "Jogállás": "6",
            "Terület": "956",
            "Népesség": "357",
            "Lakások": "182"
        },
        {
            "Ir. szám": "3853",
            "name": "Hernádbűd",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.1333",
            "KSH kód": "15839",
            "Jogállás": "6",
            "Terület": "591",
            "Népesség": "142",
            "Lakások": "79"
        },
        {
            "Ir. szám": "3854",
            "name": "Hernádbűd",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.1333",
            "KSH kód": "15839",
            "Jogállás": "6",
            "Terület": "591",
            "Népesség": "142",
            "Lakások": "79"
        },
        {
            "Ir. szám": "3855",
            "name": "Fancsal",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.0667",
            "KSH kód": "12557",
            "Jogállás": "6",
            "Terület": "981",
            "Népesség": "317",
            "Lakások": "146"
        },
        {
            "Ir. szám": "3860",
            "name": "Encs",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.1333",
            "KSH kód": "33048",
            "Jogállás": "4",
            "Terület": "2580",
            "Népesség": "6396",
            "Lakások": "2274"
        },
        {
            "Ir. szám": "3861",
            "name": "Encs",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.1333",
            "KSH kód": "33048",
            "Jogállás": "4",
            "Terület": "2580",
            "Népesség": "6396",
            "Lakások": "2274"
        },
        {
            "Ir. szám": "3863",
            "name": "Szalaszend",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.1333",
            "KSH kód": "31015",
            "Jogállás": "6",
            "Terület": "1826",
            "Népesség": "1117",
            "Lakások": "380"
        },
        {
            "Ir. szám": "3864",
            "name": "Fulókércs",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "21.1167",
            "KSH kód": "22123",
            "Jogállás": "6",
            "Terület": "1863",
            "Népesség": "387",
            "Lakások": "120"
        },
        {
            "Ir. szám": "3865",
            "name": "Fáj",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "21.0833",
            "KSH kód": "02741",
            "Jogállás": "6",
            "Terület": "1932",
            "Népesség": "358",
            "Lakások": "100"
        },
        {
            "Ir. szám": "3866",
            "name": "Szemere",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.1",
            "KSH kód": "09830",
            "Jogállás": "6",
            "Terület": "2630",
            "Népesség": "386",
            "Lakások": "157"
        },
        {
            "Ir. szám": "3866",
            "name": "Litka",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "21.0667",
            "KSH kód": "28219",
            "Jogállás": "6",
            "Terület": "670",
            "Népesség": "56",
            "Lakások": "49"
        },
        {
            "Ir. szám": "3871",
            "name": "Méra",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.15",
            "KSH kód": "25618",
            "Jogállás": "6",
            "Terület": "1511",
            "Népesség": "1775",
            "Lakások": "596"
        },
        {
            "Ir. szám": "3872",
            "name": "Novajidrány",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.1833",
            "KSH kód": "27191",
            "Jogállás": "6",
            "Terület": "1435",
            "Népesség": "1428",
            "Lakások": "465"
        },
        {
            "Ir. szám": "3873",
            "name": "Garadna",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "21.1833",
            "KSH kód": "10904",
            "Jogállás": "6",
            "Terület": "978",
            "Népesség": "458",
            "Lakások": "194"
        },
        {
            "Ir. szám": "3874",
            "name": "Pusztaradvány",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.1333",
            "KSH kód": "15413",
            "Jogállás": "6",
            "Terület": "718",
            "Népesség": "232",
            "Lakások": "66"
        },
        {
            "Ir. szám": "3874",
            "name": "Hernádvécse",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "21.1667",
            "KSH kód": "19840",
            "Jogállás": "6",
            "Terület": "1694",
            "Népesség": "978",
            "Lakások": "288"
        },
        {
            "Ir. szám": "3874",
            "name": "Hernádpetri",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.1667",
            "KSH kód": "24882",
            "Jogállás": "6",
            "Terület": "1711",
            "Népesség": "270",
            "Lakások": "103"
        },
        {
            "Ir. szám": "3875",
            "name": "Hernádszurdok",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.2167",
            "KSH kód": "24970",
            "Jogállás": "6",
            "Terület": "448",
            "Népesség": "197",
            "Lakások": "97"
        },
        {
            "Ir. szám": "3876",
            "name": "Hidasnémeti",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21.2333",
            "KSH kód": "11697",
            "Jogállás": "6",
            "Terület": "1611",
            "Népesség": "1115",
            "Lakások": "442"
        },
        {
            "Ir. szám": "3877",
            "name": "Tornyosnémeti",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "21.2667",
            "KSH kód": "29054",
            "Jogállás": "6",
            "Terület": "1404",
            "Népesség": "510",
            "Lakások": "273"
        },
        {
            "Ir. szám": "3881",
            "name": "Abaújszántó",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.2",
            "KSH kód": "03595",
            "Jogállás": "4",
            "Terület": "4736",
            "Népesség": "3169",
            "Lakások": "1306"
        },
        {
            "Ir. szám": "3881",
            "name": "Sima",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.3",
            "KSH kód": "23418",
            "Jogállás": "6",
            "Terület": "488",
            "Népesség": "23",
            "Lakások": "16"
        },
        {
            "Ir. szám": "3881",
            "name": "Baskó",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.3333",
            "KSH kód": "08846",
            "Jogállás": "6",
            "Terület": "2963",
            "Népesség": "186",
            "Lakások": "116"
        },
        {
            "Ir. szám": "3882",
            "name": "Abaújalpár",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.2333",
            "KSH kód": "15662",
            "Jogállás": "6",
            "Terület": "848",
            "Népesség": "77",
            "Lakások": "61"
        },
        {
            "Ir. szám": "3882",
            "name": "Abaújkér",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.2",
            "KSH kód": "26718",
            "Jogállás": "6",
            "Terület": "1727",
            "Népesség": "692",
            "Lakások": "300"
        },
        {
            "Ir. szám": "3884",
            "name": "Boldogkőújfalu",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.25",
            "KSH kód": "18944",
            "Jogállás": "6",
            "Terület": "1102",
            "Népesség": "534",
            "Lakások": "214"
        },
        {
            "Ir. szám": "3885",
            "name": "Boldogkőváralja",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.2333",
            "KSH kód": "14474",
            "Jogállás": "6",
            "Terület": "2203",
            "Népesség": "1160",
            "Lakások": "435"
        },
        {
            "Ir. szám": "3885",
            "name": "Arka",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.25",
            "KSH kód": "26198",
            "Jogállás": "6",
            "Terület": "953",
            "Népesség": "81",
            "Lakások": "65"
        },
        {
            "Ir. szám": "3886",
            "name": "Korlát",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.25",
            "KSH kód": "22956",
            "Jogállás": "6",
            "Terület": "794",
            "Népesség": "293",
            "Lakások": "143"
        },
        {
            "Ir. szám": "3887",
            "name": "Hernádcéce",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.2",
            "KSH kód": "09399",
            "Jogállás": "6",
            "Terület": "994",
            "Népesség": "206",
            "Lakások": "122"
        },
        {
            "Ir. szám": "3888",
            "name": "Vizsoly",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.2167",
            "KSH kód": "21087",
            "Jogállás": "6",
            "Terület": "1841",
            "Népesség": "878",
            "Lakások": "359"
        },
        {
            "Ir. szám": "3891",
            "name": "Vilmány",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "21.2333",
            "KSH kód": "11581",
            "Jogállás": "6",
            "Terület": "1258",
            "Népesség": "1375",
            "Lakások": "460"
        },
        {
            "Ir. szám": "3892",
            "name": "Hejce",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "21.2833",
            "KSH kód": "22187",
            "Jogállás": "6",
            "Terület": "953",
            "Népesség": "267",
            "Lakások": "113"
        },
        {
            "Ir. szám": "3893",
            "name": "Regéc",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.35",
            "KSH kód": "08402",
            "Jogállás": "6",
            "Terület": "2721",
            "Népesség": "99",
            "Lakások": "62"
        },
        {
            "Ir. szám": "3893",
            "name": "Fony",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.3",
            "KSH kód": "17932",
            "Jogállás": "6",
            "Terület": "4058",
            "Népesség": "368",
            "Lakások": "210"
        },
        {
            "Ir. szám": "3893",
            "name": "Mogyoróska",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.3333",
            "KSH kód": "10968",
            "Jogállás": "6",
            "Terület": "1917",
            "Népesség": "75",
            "Lakások": "70"
        },
        {
            "Ir. szám": "3894",
            "name": "Göncruszka",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "21.25",
            "KSH kód": "18643",
            "Jogállás": "6",
            "Terület": "1673",
            "Népesség": "623",
            "Lakások": "324"
        },
        {
            "Ir. szám": "3895",
            "name": "Gönc",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.2833",
            "KSH kód": "15936",
            "Jogállás": "4",
            "Terület": "3730",
            "Népesség": "2060",
            "Lakások": "950"
        },
        {
            "Ir. szám": "3896",
            "name": "Telkibánya",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.3667",
            "KSH kód": "13763",
            "Jogállás": "6",
            "Terület": "4686",
            "Népesség": "634",
            "Lakások": "342"
        },
        {
            "Ir. szám": "3897",
            "name": "Zsujta",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21.2833",
            "KSH kód": "11022",
            "Jogállás": "6",
            "Terület": "677",
            "Népesség": "173",
            "Lakások": "88"
        },
        {
            "Ir. szám": "3898",
            "name": "Abaújvár",
            "Megyekód": "BZ",
            "Lat": "48.5333",
            "Long": "21.3167",
            "KSH kód": "02273",
            "Jogállás": "6",
            "Terület": "736",
            "Népesség": "232",
            "Lakások": "159"
        },
        {
            "Ir. szám": "3898",
            "name": "Pányok",
            "Megyekód": "BZ",
            "Lat": "48.5333",
            "Long": "21.3667",
            "KSH kód": "24730",
            "Jogállás": "6",
            "Terület": "850",
            "Népesség": "53",
            "Lakások": "81"
        },
        {
            "Ir. szám": "3899",
            "name": "Kéked",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "21.35",
            "KSH kód": "15264",
            "Jogállás": "6",
            "Terület": "1305",
            "Népesség": "193",
            "Lakások": "139"
        },
        {
            "Ir. szám": "3900",
            "name": "Szerencs",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "21.2",
            "KSH kód": "30739",
            "Jogállás": "4",
            "Terület": "3668",
            "Népesség": "9444",
            "Lakások": "3869"
        },
        {
            "Ir. szám": "3901",
            "name": "Szerencs",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "21.2",
            "KSH kód": "30739",
            "Jogállás": "4",
            "Terület": "3668",
            "Népesség": "9444",
            "Lakások": "3869"
        },
        {
            "Ir. szám": "3903",
            "name": "Bekecs",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "21.1833",
            "KSH kód": "06929",
            "Jogállás": "6",
            "Terület": "2572",
            "Népesség": "2486",
            "Lakások": "928"
        },
        {
            "Ir. szám": "3904",
            "name": "Legyesbénye",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "21.15",
            "KSH kód": "11660",
            "Jogállás": "6",
            "Terület": "2031",
            "Népesség": "1593",
            "Lakások": "630"
        },
        {
            "Ir. szám": "3905",
            "name": "Monok",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "21.15",
            "KSH kód": "07825",
            "Jogállás": "6",
            "Terület": "4198",
            "Népesség": "1606",
            "Lakások": "806"
        },
        {
            "Ir. szám": "3906",
            "name": "Golop",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "21.2",
            "KSH kód": "13134",
            "Jogállás": "6",
            "Terület": "943",
            "Népesség": "585",
            "Lakások": "301"
        },
        {
            "Ir. szám": "3907",
            "name": "Tállya",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "21.2333",
            "KSH kód": "12210",
            "Jogállás": "6",
            "Terület": "3795",
            "Népesség": "2025",
            "Lakások": "1019"
        },
        {
            "Ir. szám": "3908",
            "name": "Rátka",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "21.2333",
            "KSH kód": "12469",
            "Jogállás": "6",
            "Terület": "1177",
            "Népesség": "975",
            "Lakások": "432"
        },
        {
            "Ir. szám": "3909",
            "name": "Mád",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "21.2833",
            "KSH kód": "03902",
            "Jogállás": "6",
            "Terület": "3186",
            "Népesség": "2251",
            "Lakások": "1211"
        },
        {
            "Ir. szám": "3910",
            "name": "Tokaj",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "21.4167",
            "KSH kód": "18306",
            "Jogállás": "4",
            "Terület": "2819",
            "Népesség": "4747",
            "Lakások": "1707"
        },
        {
            "Ir. szám": "3911",
            "name": "Tokaj",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "21.4167",
            "KSH kód": "18306",
            "Jogállás": "4",
            "Terület": "2819",
            "Népesség": "4747",
            "Lakások": "1707"
        },
        {
            "Ir. szám": "3915",
            "name": "Tarcal",
            "Megyekód": "BZ",
            "Lat": "48.1333",
            "Long": "21.35",
            "KSH kód": "21740",
            "Jogállás": "6",
            "Terület": "5373",
            "Népesség": "3006",
            "Lakások": "1353"
        },
        {
            "Ir. szám": "3916",
            "name": "Bodrogkeresztúr",
            "Megyekód": "BZ",
            "Lat": "48.1667",
            "Long": "21.3667",
            "KSH kód": "30784",
            "Jogállás": "6",
            "Terület": "2987",
            "Népesség": "1157",
            "Lakások": "560"
        },
        {
            "Ir. szám": "3917",
            "name": "Bodrogkisfalud",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "21.3667",
            "KSH kód": "33808",
            "Jogállás": "6",
            "Terület": "1468",
            "Népesség": "891",
            "Lakások": "432"
        },
        {
            "Ir. szám": "3918",
            "name": "Szegi",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "21.3833",
            "KSH kód": "33817",
            "Jogállás": "6",
            "Terület": "905",
            "Népesség": "323",
            "Lakások": "138"
        },
        {
            "Ir. szám": "3918",
            "name": "Szegilong",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "21.4",
            "KSH kód": "31510",
            "Jogállás": "6",
            "Terület": "694",
            "Népesség": "231",
            "Lakások": "120"
        },
        {
            "Ir. szám": "3921",
            "name": "Taktaszada",
            "Megyekód": "BZ",
            "Lat": "48.1167",
            "Long": "21.1833",
            "KSH kód": "03133",
            "Jogállás": "6",
            "Terület": "2574",
            "Népesség": "1947",
            "Lakások": "682"
        },
        {
            "Ir. szám": "3922",
            "name": "Taktaharkány",
            "Megyekód": "BZ",
            "Lat": "48.0833",
            "Long": "21.1333",
            "KSH kód": "18245",
            "Jogállás": "5",
            "Terület": "3913",
            "Népesség": "3853",
            "Lakások": "1343"
        },
        {
            "Ir. szám": "3923",
            "name": "Taktaharkány",
            "Megyekód": "BZ",
            "Lat": "48.0833",
            "Long": "21.1333",
            "KSH kód": "18245",
            "Jogállás": "5",
            "Terület": "3913",
            "Népesség": "3853",
            "Lakások": "1343"
        },
        {
            "Ir. szám": "3924",
            "name": "Taktakenéz",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "21.2167",
            "KSH kód": "28787",
            "Jogállás": "6",
            "Terület": "1974",
            "Népesség": "1268",
            "Lakások": "436"
        },
        {
            "Ir. szám": "3925",
            "name": "Prügy",
            "Megyekód": "BZ",
            "Lat": "48.0833",
            "Long": "21.25",
            "KSH kód": "21272",
            "Jogállás": "6",
            "Terület": "3139",
            "Népesség": "2454",
            "Lakások": "837"
        },
        {
            "Ir. szám": "3926",
            "name": "Taktabáj",
            "Megyekód": "BZ",
            "Lat": "48.0667",
            "Long": "21.3167",
            "KSH kód": "03708",
            "Jogállás": "6",
            "Terület": "1682",
            "Népesség": "597",
            "Lakások": "239"
        },
        {
            "Ir. szám": "3927",
            "name": "Csobaj",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "21.35",
            "KSH kód": "20774",
            "Jogállás": "6",
            "Terület": "1959",
            "Népesség": "767",
            "Lakások": "345"
        },
        {
            "Ir. szám": "3928",
            "name": "Tiszatardos",
            "Megyekód": "BZ",
            "Lat": "48.05",
            "Long": "21.3833",
            "KSH kód": "30298",
            "Jogállás": "6",
            "Terület": "893",
            "Népesség": "240",
            "Lakások": "146"
        },
        {
            "Ir. szám": "3929",
            "name": "Tiszaladány",
            "Megyekód": "BZ",
            "Lat": "48.0667",
            "Long": "21.4167",
            "KSH kód": "19381",
            "Jogállás": "6",
            "Terület": "2221",
            "Népesség": "704",
            "Lakások": "330"
        },
        {
            "Ir. szám": "3931",
            "name": "Mezőzombor",
            "Megyekód": "BZ",
            "Lat": "48.15",
            "Long": "21.2667",
            "KSH kód": "03443",
            "Jogállás": "6",
            "Terület": "3878",
            "Népesség": "2475",
            "Lakások": "902"
        },
        {
            "Ir. szám": "3932",
            "name": "Erdőbénye",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "21.3667",
            "KSH kód": "25326",
            "Jogállás": "6",
            "Terület": "4579",
            "Népesség": "1099",
            "Lakások": "699"
        },
        {
            "Ir. szám": "3933",
            "name": "Olaszliszka",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "21.4333",
            "KSH kód": "31778",
            "Jogállás": "6",
            "Terület": "3949",
            "Népesség": "1672",
            "Lakások": "791"
        },
        {
            "Ir. szám": "3934",
            "name": "Tolcsva",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.45",
            "KSH kód": "28051",
            "Jogállás": "6",
            "Terület": "1649",
            "Népesség": "1756",
            "Lakások": "805"
        },
        {
            "Ir. szám": "3935",
            "name": "Erdőhorváti",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.4333",
            "KSH kód": "22503",
            "Jogállás": "6",
            "Terület": "5011",
            "Népesség": "604",
            "Lakások": "319"
        },
        {
            "Ir. szám": "3936",
            "name": "Háromhuta",
            "Megyekód": "BZ",
            "Lat": "48.3833",
            "Long": "21.4333",
            "KSH kód": "12706",
            "Jogállás": "6",
            "Terület": "3780",
            "Népesség": "132",
            "Lakások": "89"
        },
        {
            "Ir. szám": "3937",
            "name": "Komlóska",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.4667",
            "KSH kód": "16559",
            "Jogállás": "6",
            "Terület": "2986",
            "Népesség": "268",
            "Lakások": "179"
        },
        {
            "Ir. szám": "3941",
            "name": "Vámosújfalu",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "21.4667",
            "KSH kód": "15149",
            "Jogállás": "6",
            "Terület": "1065",
            "Népesség": "863",
            "Lakások": "331"
        },
        {
            "Ir. szám": "3942",
            "name": "Sárazsadány",
            "Megyekód": "BZ",
            "Lat": "48.2667",
            "Long": "21.5",
            "KSH kód": "20516",
            "Jogállás": "6",
            "Terület": "1533",
            "Népesség": "260",
            "Lakások": "181"
        },
        {
            "Ir. szám": "3943",
            "name": "Bodrogolaszi",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.5167",
            "KSH kód": "14401",
            "Jogállás": "6",
            "Terület": "2061",
            "Népesség": "942",
            "Lakások": "400"
        },
        {
            "Ir. szám": "3944",
            "name": "Bodrogolaszi",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.5167",
            "KSH kód": "14401",
            "Jogállás": "6",
            "Terület": "2061",
            "Népesség": "942",
            "Lakások": "400"
        },
        {
            "Ir. szám": "3945",
            "name": "Bodrogolaszi",
            "Megyekód": "BZ",
            "Lat": "48.2833",
            "Long": "21.5167",
            "KSH kód": "14401",
            "Jogállás": "6",
            "Terület": "2061",
            "Népesség": "942",
            "Lakások": "400"
        },
        {
            "Ir. szám": "3950",
            "name": "Sárospatak",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.5833",
            "KSH kód": "27474",
            "Jogállás": "4",
            "Terület": "13919",
            "Népesség": "13357",
            "Lakások": "5323"
        },
        {
            "Ir. szám": "3954",
            "name": "Györgytarló",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "21.6667",
            "KSH kód": "05069",
            "Jogállás": "6",
            "Terület": "3019",
            "Népesség": "578",
            "Lakások": "224"
        },
        {
            "Ir. szám": "3955",
            "name": "Kenézlő",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "21.5333",
            "KSH kód": "05458",
            "Jogállás": "6",
            "Terület": "2296",
            "Népesség": "1261",
            "Lakások": "566"
        },
        {
            "Ir. szám": "3956",
            "name": "Viss",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "21.5167",
            "KSH kód": "05096",
            "Jogállás": "6",
            "Terület": "1392",
            "Népesség": "672",
            "Lakások": "353"
        },
        {
            "Ir. szám": "3957",
            "name": "Zalkod",
            "Megyekód": "BZ",
            "Lat": "48.1833",
            "Long": "21.4667",
            "KSH kód": "15617",
            "Jogállás": "6",
            "Terület": "1022",
            "Népesség": "244",
            "Lakások": "215"
        },
        {
            "Ir. szám": "3958",
            "name": "Hercegkút",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.5333",
            "KSH kód": "30137",
            "Jogállás": "6",
            "Terület": "782",
            "Népesség": "634",
            "Lakások": "258"
        },
        {
            "Ir. szám": "3959",
            "name": "Makkoshotyka",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.5167",
            "KSH kód": "19600",
            "Jogállás": "6",
            "Terület": "1041",
            "Népesség": "905",
            "Lakások": "310"
        },
        {
            "Ir. szám": "3961",
            "name": "Vajdácska",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.6667",
            "KSH kód": "30003",
            "Jogállás": "6",
            "Terület": "2278",
            "Népesség": "1343",
            "Lakások": "468"
        },
        {
            "Ir. szám": "3962",
            "name": "Karos",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.75",
            "KSH kód": "30508",
            "Jogállás": "6",
            "Terület": "1532",
            "Népesség": "508",
            "Lakások": "206"
        },
        {
            "Ir. szám": "3963",
            "name": "Karcsa",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "21.8",
            "KSH kód": "21218",
            "Jogállás": "6",
            "Terület": "4370",
            "Népesség": "1806",
            "Lakások": "665"
        },
        {
            "Ir. szám": "3964",
            "name": "Pácin",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.8333",
            "KSH kód": "15893",
            "Jogállás": "6",
            "Terület": "3394",
            "Népesség": "1421",
            "Lakások": "622"
        },
        {
            "Ir. szám": "3965",
            "name": "Kisrozvágy",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.95",
            "KSH kód": "11448",
            "Jogállás": "6",
            "Terület": "834",
            "Népesség": "150",
            "Lakások": "87"
        },
        {
            "Ir. szám": "3965",
            "name": "Nagyrozvágy",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.9167",
            "KSH kód": "33181",
            "Jogállás": "6",
            "Terület": "2682",
            "Népesség": "642",
            "Lakások": "292"
        },
        {
            "Ir. szám": "3967",
            "name": "Lácacséke",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "22",
            "KSH kód": "20844",
            "Jogállás": "6",
            "Terület": "1637",
            "Népesség": "291",
            "Lakások": "208"
        },
        {
            "Ir. szám": "3971",
            "name": "Tiszakarád",
            "Megyekód": "BZ",
            "Lat": "48.2",
            "Long": "21.7167",
            "KSH kód": "13976",
            "Jogállás": "6",
            "Terület": "4757",
            "Népesség": "2373",
            "Lakások": "929"
        },
        {
            "Ir. szám": "3972",
            "name": "Tiszacsermely",
            "Megyekód": "BZ",
            "Lat": "48.2333",
            "Long": "21.7833",
            "KSH kód": "29133",
            "Jogállás": "6",
            "Terület": "2028",
            "Népesség": "629",
            "Lakások": "254"
        },
        {
            "Ir. szám": "3973",
            "name": "Cigánd",
            "Megyekód": "BZ",
            "Lat": "48.25",
            "Long": "21.9",
            "KSH kód": "03939",
            "Jogállás": "4",
            "Terület": "5676",
            "Népesség": "2820",
            "Lakások": "1170"
        },
        {
            "Ir. szám": "3974",
            "name": "Ricse",
            "Megyekód": "BZ",
            "Lat": "48.3333",
            "Long": "21.9667",
            "KSH kód": "19220",
            "Jogállás": "5",
            "Terület": "2476",
            "Népesség": "1737",
            "Lakások": "660"
        },
        {
            "Ir. szám": "3974",
            "name": "Semjén",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.9667",
            "KSH kód": "23755",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "461",
            "Lakások": "168"
        },
        {
            "Ir. szám": "3976",
            "name": "Révleányvár",
            "Megyekód": "BZ",
            "Lat": "48.3167",
            "Long": "22.05",
            "KSH kód": "09317",
            "Jogállás": "6",
            "Terület": "1649",
            "Népesség": "521",
            "Lakások": "320"
        },
        {
            "Ir. szám": "3977",
            "name": "Zemplénagárd",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "22.0833",
            "KSH kód": "31608",
            "Jogállás": "6",
            "Terület": "2911",
            "Népesség": "775",
            "Lakások": "430"
        },
        {
            "Ir. szám": "3978",
            "name": "Dámóc",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "22.0333",
            "KSH kód": "07719",
            "Jogállás": "6",
            "Terület": "1599",
            "Népesség": "392",
            "Lakások": "201"
        },
        {
            "Ir. szám": "3980",
            "name": "Sátoraljaújhely",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.6667",
            "KSH kód": "05120",
            "Jogállás": "4",
            "Terület": "7347",
            "Népesség": "16299",
            "Lakások": "6875"
        },
        {
            "Ir. szám": "3981",
            "name": "Sátoraljaújhely",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.6667",
            "KSH kód": "05120",
            "Jogállás": "4",
            "Terület": "7347",
            "Népesség": "16299",
            "Lakások": "6875"
        },
        {
            "Ir. szám": "3985",
            "name": "Felsőberecki",
            "Megyekód": "BZ",
            "Lat": "48.3667",
            "Long": "21.7",
            "KSH kód": "08174",
            "Jogállás": "6",
            "Terület": "353",
            "Népesség": "296",
            "Lakások": "117"
        },
        {
            "Ir. szám": "3985",
            "name": "Alsóberecki",
            "Megyekód": "BZ",
            "Lat": "48.35",
            "Long": "21.7",
            "KSH kód": "20482",
            "Jogállás": "6",
            "Terület": "686",
            "Népesség": "795",
            "Lakások": "288"
        },
        {
            "Ir. szám": "3987",
            "name": "Bodroghalom",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.7167",
            "KSH kód": "23737",
            "Jogállás": "6",
            "Terület": "2687",
            "Népesség": "1355",
            "Lakások": "564"
        },
        {
            "Ir. szám": "3988",
            "name": "Bodroghalom",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "21.7167",
            "KSH kód": "23737",
            "Jogállás": "6",
            "Terület": "2687",
            "Népesség": "1355",
            "Lakások": "564"
        },
        {
            "Ir. szám": "3989",
            "name": "Mikóháza",
            "Megyekód": "BZ",
            "Lat": "48.475",
            "Long": "21.6167",
            "KSH kód": "24253",
            "Jogállás": "6",
            "Terület": "1692",
            "Népesség": "578",
            "Lakások": "228"
        },
        {
            "Ir. szám": "3989",
            "name": "Felsőregmec",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.6167",
            "KSH kód": "07588",
            "Jogállás": "6",
            "Terület": "1075",
            "Népesség": "309",
            "Lakások": "103"
        },
        {
            "Ir. szám": "3989",
            "name": "Alsóregmec",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.6167",
            "KSH kód": "23223",
            "Jogállás": "6",
            "Terület": "1539",
            "Népesség": "202",
            "Lakások": "92"
        },
        {
            "Ir. szám": "3991",
            "name": "Vilyvitány",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21.5667",
            "KSH kód": "12982",
            "Jogállás": "6",
            "Terület": "1313",
            "Népesség": "249",
            "Lakások": "177"
        },
        {
            "Ir. szám": "3992",
            "name": "Vágáshuta",
            "Megyekód": "BZ",
            "Lat": "48.4167",
            "Long": "21.55",
            "KSH kód": "29151",
            "Jogállás": "6",
            "Terület": "200",
            "Népesség": "99",
            "Lakások": "70"
        },
        {
            "Ir. szám": "3992",
            "name": "Kovácsvágás",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "21.5333",
            "KSH kód": "28547",
            "Jogállás": "6",
            "Terület": "2113",
            "Népesség": "632",
            "Lakások": "238"
        },
        {
            "Ir. szám": "3993",
            "name": "Füzérradvány",
            "Megyekód": "BZ",
            "Lat": "48.4833",
            "Long": "21.5333",
            "KSH kód": "10366",
            "Jogállás": "6",
            "Terület": "986",
            "Népesség": "390",
            "Lakások": "209"
        },
        {
            "Ir. szám": "3994",
            "name": "Kishuta",
            "Megyekód": "BZ",
            "Lat": "48.45",
            "Long": "21.4833",
            "KSH kód": "28875",
            "Jogállás": "6",
            "Terület": "518",
            "Népesség": "293",
            "Lakások": "133"
        },
        {
            "Ir. szám": "3994",
            "name": "Filkeháza",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21.5",
            "KSH kód": "25238",
            "Jogállás": "6",
            "Terület": "441",
            "Népesség": "102",
            "Lakások": "42"
        },
        {
            "Ir. szám": "3994",
            "name": "Nagyhuta",
            "Megyekód": "BZ",
            "Lat": "48.4333",
            "Long": "21.5",
            "KSH kód": "15468",
            "Jogállás": "6",
            "Terület": "3506",
            "Népesség": "73",
            "Lakások": "80"
        },
        {
            "Ir. szám": "3994",
            "name": "Füzérkajata",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "21.5",
            "KSH kód": "06460",
            "Jogállás": "6",
            "Terület": "1145",
            "Népesség": "116",
            "Lakások": "77"
        },
        {
            "Ir. szám": "3994",
            "name": "Pálháza",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.5167",
            "KSH kód": "12362",
            "Jogállás": "4",
            "Terület": "676",
            "Népesség": "1062",
            "Lakások": "416"
        },
        {
            "Ir. szám": "3994",
            "name": "Bózsva",
            "Megyekód": "BZ",
            "Lat": "48.4667",
            "Long": "21.4667",
            "KSH kód": "31006",
            "Jogállás": "6",
            "Terület": "1639",
            "Népesség": "197",
            "Lakások": "124"
        },
        {
            "Ir. szám": "3995",
            "name": "Pusztafalu",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "21.5",
            "KSH kód": "17048",
            "Jogállás": "6",
            "Terület": "700",
            "Népesség": "213",
            "Lakások": "106"
        },
        {
            "Ir. szám": "3996",
            "name": "Füzér",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "21.4667",
            "KSH kód": "17109",
            "Jogállás": "6",
            "Terület": "3750",
            "Népesség": "477",
            "Lakások": "228"
        },
        {
            "Ir. szám": "3997",
            "name": "Füzérkomlós",
            "Megyekód": "BZ",
            "Lat": "48.5167",
            "Long": "21.4667",
            "KSH kód": "11378",
            "Jogállás": "6",
            "Terület": "587",
            "Népesség": "340",
            "Lakások": "139"
        },
        {
            "Ir. szám": "3998",
            "name": "Nyíri",
            "Megyekód": "BZ",
            "Lat": "48.5",
            "Long": "21.45",
            "KSH kód": "26435",
            "Jogállás": "6",
            "Terület": "1647",
            "Népesség": "426",
            "Lakások": "177"
        },
        {
            "Ir. szám": "3999",
            "name": "Hollóháza",
            "Megyekód": "BZ",
            "Lat": "48.55",
            "Long": "21.4167",
            "KSH kód": "31167",
            "Jogállás": "6",
            "Terület": "236",
            "Népesség": "885",
            "Lakások": "433"
        },
        {
            "Ir. szám": "4000",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4001",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4002",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4003",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4004",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4005",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4006",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4007",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4008",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4009",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4010",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4011",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4012",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4013",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4014",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4015",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4017",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4021",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4022",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4024",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4025",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4026",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4027",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4028",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4029",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4030",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4031",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4032",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4033",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4034",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4040",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4041",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4042",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4044",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4045",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4046",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4047",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4048",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4060",
            "name": "Balmazújváros",
            "Megyekód": "HB",
            "Lat": "47.6167",
            "Long": "21.35",
            "KSH kód": "02918",
            "Jogállás": "4",
            "Terület": "20545",
            "Népesség": "17575",
            "Lakások": "6669"
        },
        {
            "Ir. szám": "4063",
            "name": "Balmazújváros",
            "Megyekód": "HB",
            "Lat": "47.6167",
            "Long": "21.35",
            "KSH kód": "02918",
            "Jogállás": "4",
            "Terület": "20545",
            "Népesség": "17575",
            "Lakások": "6669"
        },
        {
            "Ir. szám": "4063",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.6167",
            "Long": "21.35",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4064",
            "name": "Nagyhegyes",
            "Megyekód": "HB",
            "Lat": "47.5167",
            "Long": "21.4167",
            "KSH kód": "09478",
            "Jogállás": "6",
            "Terület": "13276",
            "Népesség": "2752",
            "Lakások": "990"
        },
        {
            "Ir. szám": "4065",
            "name": "Újszentmargita",
            "Megyekód": "HB",
            "Lat": "47.7333",
            "Long": "21.1",
            "KSH kód": "32568",
            "Jogállás": "6",
            "Terület": "9622",
            "Népesség": "1442",
            "Lakások": "652"
        },
        {
            "Ir. szám": "4066",
            "name": "Tiszacsege",
            "Megyekód": "HB",
            "Lat": "47.7",
            "Long": "21",
            "KSH kód": "15644",
            "Jogállás": "4",
            "Terület": "13640",
            "Népesség": "4705",
            "Lakások": "2101"
        },
        {
            "Ir. szám": "4067",
            "name": "Tiszacsege",
            "Megyekód": "HB",
            "Lat": "47.7",
            "Long": "21",
            "KSH kód": "15644",
            "Jogállás": "4",
            "Terület": "13640",
            "Népesség": "4705",
            "Lakások": "2101"
        },
        {
            "Ir. szám": "4069",
            "name": "Egyek",
            "Megyekód": "HB",
            "Lat": "47.6333",
            "Long": "20.9",
            "KSH kód": "15741",
            "Jogállás": "5",
            "Terület": "10479",
            "Népesség": "5071",
            "Lakások": "2392"
        },
        {
            "Ir. szám": "4071",
            "name": "Hortobágy",
            "Megyekód": "HB",
            "Lat": "47.5833",
            "Long": "21.1667",
            "KSH kód": "04118",
            "Jogállás": "6",
            "Terület": "28458",
            "Népesség": "1553",
            "Lakások": "651"
        },
        {
            "Ir. szám": "4074",
            "name": "Tarnalelesz",
            "Megyekód": "HB",
            "Lat": "47.7708",
            "Long": "21.2167",
            "KSH kód": "13240",
            "Jogállás": "6",
            "Terület": "3713",
            "Népesség": "1715",
            "Lakások": "694"
        },
        {
            "Ir. szám": "4075",
            "name": "Görbeháza",
            "Megyekód": "HB",
            "Lat": "47.8333",
            "Long": "21.2333",
            "KSH kód": "16568",
            "Jogállás": "6",
            "Terület": "8020",
            "Népesség": "2463",
            "Lakások": "1071"
        },
        {
            "Ir. szám": "4078",
            "name": "Görbeháza",
            "Megyekód": "HB",
            "Lat": "47.8333",
            "Long": "21.2333",
            "KSH kód": "16568",
            "Jogállás": "6",
            "Terület": "8020",
            "Népesség": "2463",
            "Lakások": "1071"
        },
        {
            "Ir. szám": "4079",
            "name": "Görbeháza",
            "Megyekód": "HB",
            "Lat": "47.8333",
            "Long": "21.2333",
            "KSH kód": "16568",
            "Jogállás": "6",
            "Terület": "8020",
            "Népesség": "2463",
            "Lakások": "1071"
        },
        {
            "Ir. szám": "4080",
            "name": "Hajdúnánás",
            "Megyekód": "HB",
            "Lat": "47.85",
            "Long": "21.4333",
            "KSH kód": "22406",
            "Jogállás": "4",
            "Terület": "25962",
            "Népesség": "17498",
            "Lakások": "7027"
        },
        {
            "Ir. szám": "4081",
            "name": "Hajdúnánás",
            "Megyekód": "HB",
            "Lat": "47.85",
            "Long": "21.4333",
            "KSH kód": "22406",
            "Jogállás": "4",
            "Terület": "25962",
            "Népesség": "17498",
            "Lakások": "7027"
        },
        {
            "Ir. szám": "4085",
            "name": "Hajdúnánás",
            "Megyekód": "HB",
            "Lat": "47.85",
            "Long": "21.4333",
            "KSH kód": "22406",
            "Jogállás": "4",
            "Terület": "25962",
            "Népesség": "17498",
            "Lakások": "7027"
        },
        {
            "Ir. szám": "4086",
            "name": "Hajdúnánás",
            "Megyekód": "HB",
            "Lat": "47.85",
            "Long": "21.4333",
            "KSH kód": "22406",
            "Jogállás": "4",
            "Terület": "25962",
            "Népesség": "17498",
            "Lakások": "7027"
        },
        {
            "Ir. szám": "4087",
            "name": "Hajdúdorog",
            "Megyekód": "HB",
            "Lat": "47.8167",
            "Long": "21.5",
            "KSH kód": "12803",
            "Jogállás": "4",
            "Terület": "10065",
            "Népesség": "9070",
            "Lakások": "3562"
        },
        {
            "Ir. szám": "4090",
            "name": "Polgár",
            "Megyekód": "HB",
            "Lat": "47.8667",
            "Long": "21.1167",
            "KSH kód": "23117",
            "Jogállás": "4",
            "Terület": "9746",
            "Népesség": "8133",
            "Lakások": "3364"
        },
        {
            "Ir. szám": "4090",
            "name": "Folyás",
            "Megyekód": "HB",
            "Lat": "47.8",
            "Long": "21.1333",
            "KSH kód": "34014",
            "Jogállás": "6",
            "Terület": "5392",
            "Népesség": "352",
            "Lakások": "154"
        },
        {
            "Ir. szám": "4096",
            "name": "Újtikos",
            "Megyekód": "HB",
            "Lat": "47.9167",
            "Long": "21.1833",
            "KSH kód": "11925",
            "Jogállás": "6",
            "Terület": "3529",
            "Népesség": "905",
            "Lakások": "386"
        },
        {
            "Ir. szám": "4097",
            "name": "Tiszagyulaháza",
            "Megyekód": "HB",
            "Lat": "47.95",
            "Long": "21.15",
            "KSH kód": "30845",
            "Jogállás": "6",
            "Terület": "2078",
            "Népesség": "768",
            "Lakások": "373"
        },
        {
            "Ir. szám": "4100",
            "name": "Berettyóújfalu",
            "Megyekód": "HB",
            "Lat": "47.2167",
            "Long": "21.55",
            "KSH kód": "12788",
            "Jogállás": "4",
            "Terület": "17098",
            "Népesség": "15427",
            "Lakások": "6308"
        },
        {
            "Ir. szám": "4101",
            "name": "Berettyóújfalu",
            "Megyekód": "HB",
            "Lat": "47.2167",
            "Long": "21.55",
            "KSH kód": "12788",
            "Jogállás": "4",
            "Terület": "17098",
            "Népesség": "15427",
            "Lakások": "6308"
        },
        {
            "Ir. szám": "4103",
            "name": "Berettyóújfalu",
            "Megyekód": "HB",
            "Lat": "47.2167",
            "Long": "21.55",
            "KSH kód": "12788",
            "Jogállás": "4",
            "Terület": "17098",
            "Népesség": "15427",
            "Lakások": "6308"
        },
        {
            "Ir. szám": "4110",
            "name": "Biharkeresztes",
            "Megyekód": "HB",
            "Lat": "47.1333",
            "Long": "21.7167",
            "KSH kód": "19956",
            "Jogállás": "4",
            "Terület": "4926",
            "Népesség": "3819",
            "Lakások": "1655"
        },
        {
            "Ir. szám": "4114",
            "name": "Bojt",
            "Megyekód": "HB",
            "Lat": "47.2",
            "Long": "21.7333",
            "KSH kód": "14137",
            "Jogállás": "6",
            "Terület": "2688",
            "Népesség": "501",
            "Lakások": "246"
        },
        {
            "Ir. szám": "4115",
            "name": "Ártánd",
            "Megyekód": "HB",
            "Lat": "47.1167",
            "Long": "21.7667",
            "KSH kód": "03319",
            "Jogállás": "6",
            "Terület": "1982",
            "Népesség": "506",
            "Lakások": "240"
        },
        {
            "Ir. szám": "4116",
            "name": "Berekböszörmény",
            "Megyekód": "HB",
            "Lat": "47.0667",
            "Long": "21.6833",
            "KSH kód": "18467",
            "Jogállás": "6",
            "Terület": "4284",
            "Népesség": "1707",
            "Lakások": "785"
        },
        {
            "Ir. szám": "4117",
            "name": "Told",
            "Megyekód": "HB",
            "Lat": "47.1167",
            "Long": "21.65",
            "KSH kód": "25876",
            "Jogállás": "6",
            "Terület": "1491",
            "Népesség": "312",
            "Lakások": "150"
        },
        {
            "Ir. szám": "4118",
            "name": "Mezőpeterd",
            "Megyekód": "HB",
            "Lat": "47.1667",
            "Long": "21.6167",
            "KSH kód": "31033",
            "Jogállás": "6",
            "Terület": "1823",
            "Népesség": "500",
            "Lakások": "269"
        },
        {
            "Ir. szám": "4119",
            "name": "Váncsod",
            "Megyekód": "HB",
            "Lat": "47.2",
            "Long": "21.65",
            "KSH kód": "16762",
            "Jogállás": "6",
            "Terület": "3443",
            "Népesség": "1241",
            "Lakások": "543"
        },
        {
            "Ir. szám": "4121",
            "name": "Szentpéterszeg",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.6167",
            "KSH kód": "19099",
            "Jogállás": "6",
            "Terület": "2551",
            "Népesség": "1111",
            "Lakások": "477"
        },
        {
            "Ir. szám": "4122",
            "name": "Gáborján",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.6667",
            "KSH kód": "18175",
            "Jogállás": "6",
            "Terület": "2646",
            "Népesség": "898",
            "Lakások": "386"
        },
        {
            "Ir. szám": "4123",
            "name": "Hencida",
            "Megyekód": "HB",
            "Lat": "47.25",
            "Long": "21.7",
            "KSH kód": "29391",
            "Jogállás": "6",
            "Terület": "3479",
            "Népesség": "1280",
            "Lakások": "484"
        },
        {
            "Ir. szám": "4124",
            "name": "Esztár",
            "Megyekód": "HB",
            "Lat": "47.2833",
            "Long": "21.7833",
            "KSH kód": "25469",
            "Jogállás": "6",
            "Terület": "3171",
            "Népesség": "1351",
            "Lakások": "525"
        },
        {
            "Ir. szám": "4125",
            "name": "Pocsaj",
            "Megyekód": "HB",
            "Lat": "47.2833",
            "Long": "21.8167",
            "KSH kód": "11837",
            "Jogállás": "5",
            "Terület": "4955",
            "Népesség": "2588",
            "Lakások": "1033"
        },
        {
            "Ir. szám": "4126",
            "name": "Kismarja",
            "Megyekód": "HB",
            "Lat": "47.25",
            "Long": "21.8333",
            "KSH kód": "15477",
            "Jogállás": "6",
            "Terület": "4716",
            "Népesség": "1239",
            "Lakások": "535"
        },
        {
            "Ir. szám": "4127",
            "name": "Nagykereki",
            "Megyekód": "HB",
            "Lat": "47.1833",
            "Long": "21.8",
            "KSH kód": "08907",
            "Jogállás": "6",
            "Terület": "3727",
            "Népesség": "1185",
            "Lakások": "530"
        },
        {
            "Ir. szám": "4128",
            "name": "Bedő",
            "Megyekód": "HB",
            "Lat": "47.1667",
            "Long": "21.75",
            "KSH kód": "33446",
            "Jogállás": "6",
            "Terület": "1020",
            "Népesség": "252",
            "Lakások": "142"
        },
        {
            "Ir. szám": "4130",
            "name": "Derecske",
            "Megyekód": "HB",
            "Lat": "47.35",
            "Long": "21.5667",
            "KSH kód": "05573",
            "Jogállás": "4",
            "Terület": "10358",
            "Népesség": "9116",
            "Lakások": "3315"
        },
        {
            "Ir. szám": "4131",
            "name": "Derecske",
            "Megyekód": "HB",
            "Lat": "47.35",
            "Long": "21.5667",
            "KSH kód": "05573",
            "Jogállás": "4",
            "Terület": "10358",
            "Népesség": "9116",
            "Lakások": "3315"
        },
        {
            "Ir. szám": "4132",
            "name": "Tépe",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.5833",
            "KSH kód": "31042",
            "Jogállás": "6",
            "Terület": "2322",
            "Népesség": "1116",
            "Lakások": "420"
        },
        {
            "Ir. szám": "4133",
            "name": "Konyár",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.6667",
            "KSH kód": "25964",
            "Jogállás": "6",
            "Terület": "4170",
            "Népesség": "2099",
            "Lakások": "857"
        },
        {
            "Ir. szám": "4134",
            "name": "Mezősas",
            "Megyekód": "HB",
            "Lat": "47.1167",
            "Long": "21.5667",
            "KSH kód": "18847",
            "Jogállás": "6",
            "Terület": "2639",
            "Népesség": "635",
            "Lakások": "310"
        },
        {
            "Ir. szám": "4135",
            "name": "Körösszegapáti",
            "Megyekód": "HB",
            "Lat": "47.0333",
            "Long": "21.6333",
            "KSH kód": "08943",
            "Jogállás": "6",
            "Terület": "4585",
            "Népesség": "862",
            "Lakások": "500"
        },
        {
            "Ir. szám": "4136",
            "name": "Körösszakál",
            "Megyekód": "HB",
            "Lat": "47.0167",
            "Long": "21.6",
            "KSH kód": "31130",
            "Jogállás": "6",
            "Terület": "1502",
            "Népesség": "828",
            "Lakások": "391"
        },
        {
            "Ir. szám": "4137",
            "name": "Magyarhomorog",
            "Megyekód": "HB",
            "Lat": "47.0167",
            "Long": "21.55",
            "KSH kód": "03683",
            "Jogállás": "6",
            "Terület": "3956",
            "Népesség": "861",
            "Lakások": "453"
        },
        {
            "Ir. szám": "4138",
            "name": "Komádi",
            "Megyekód": "HB",
            "Lat": "47",
            "Long": "21.5",
            "KSH kód": "02167",
            "Jogállás": "4",
            "Terület": "14465",
            "Népesség": "5407",
            "Lakások": "2604"
        },
        {
            "Ir. szám": "4141",
            "name": "Furta",
            "Megyekód": "HB",
            "Lat": "47.1333",
            "Long": "21.4667",
            "KSH kód": "16993",
            "Jogállás": "6",
            "Terület": "4285",
            "Népesség": "1151",
            "Lakások": "599"
        },
        {
            "Ir. szám": "4142",
            "name": "Zsáka",
            "Megyekód": "HB",
            "Lat": "47.1333",
            "Long": "21.4333",
            "KSH kód": "04817",
            "Jogállás": "5",
            "Terület": "7881",
            "Népesség": "1622",
            "Lakások": "764"
        },
        {
            "Ir. szám": "4143",
            "name": "Vekerd",
            "Megyekód": "HB",
            "Lat": "47.1",
            "Long": "21.4",
            "KSH kód": "11138",
            "Jogállás": "6",
            "Terület": "741",
            "Népesség": "119",
            "Lakások": "114"
        },
        {
            "Ir. szám": "4144",
            "name": "Darvas",
            "Megyekód": "HB",
            "Lat": "47.1",
            "Long": "21.35",
            "KSH kód": "14678",
            "Jogállás": "6",
            "Terület": "4231",
            "Népesség": "574",
            "Lakások": "284"
        },
        {
            "Ir. szám": "4145",
            "name": "Csökmő",
            "Megyekód": "HB",
            "Lat": "47.0333",
            "Long": "21.3",
            "KSH kód": "12450",
            "Jogállás": "5",
            "Terület": "6897",
            "Népesség": "1885",
            "Lakások": "916"
        },
        {
            "Ir. szám": "4146",
            "name": "Újiráz",
            "Megyekód": "HB",
            "Lat": "46.9833",
            "Long": "21.3667",
            "KSH kód": "23393",
            "Jogállás": "6",
            "Terület": "1547",
            "Népesség": "543",
            "Lakások": "275"
        },
        {
            "Ir. szám": "4150",
            "name": "Püspökladány",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.1167",
            "KSH kód": "10162",
            "Jogállás": "4",
            "Terület": "18695",
            "Népesség": "15117",
            "Lakások": "5964"
        },
        {
            "Ir. szám": "4151",
            "name": "Püspökladány",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.1167",
            "KSH kód": "10162",
            "Jogállás": "4",
            "Terület": "18695",
            "Népesség": "15117",
            "Lakások": "5964"
        },
        {
            "Ir. szám": "4152",
            "name": "Püspökladány",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.1167",
            "KSH kód": "10162",
            "Jogállás": "4",
            "Terület": "18695",
            "Népesség": "15117",
            "Lakások": "5964"
        },
        {
            "Ir. szám": "4155",
            "name": "Püspökladány",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.1167",
            "KSH kód": "10162",
            "Jogállás": "4",
            "Terület": "18695",
            "Népesség": "15117",
            "Lakások": "5964"
        },
        {
            "Ir. szám": "4161",
            "name": "Báránd",
            "Megyekód": "HB",
            "Lat": "47.3",
            "Long": "21.2333",
            "KSH kód": "26693",
            "Jogállás": "6",
            "Terület": "4257",
            "Népesség": "2624",
            "Lakások": "1183"
        },
        {
            "Ir. szám": "4162",
            "name": "Báránd",
            "Megyekód": "HB",
            "Lat": "47.3",
            "Long": "21.2333",
            "KSH kód": "26693",
            "Jogállás": "6",
            "Terület": "4257",
            "Népesség": "2624",
            "Lakások": "1183"
        },
        {
            "Ir. szám": "4163",
            "name": "Szerep",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.15",
            "KSH kód": "33437",
            "Jogállás": "6",
            "Terület": "5604",
            "Népesség": "1604",
            "Lakások": "571"
        },
        {
            "Ir. szám": "4164",
            "name": "Bakonszeg",
            "Megyekód": "HB",
            "Lat": "47.1833",
            "Long": "21.45",
            "KSH kód": "15167",
            "Jogállás": "6",
            "Terület": "3503",
            "Népesség": "1176",
            "Lakások": "584"
        },
        {
            "Ir. szám": "4171",
            "name": "Sárrétudvari",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.2",
            "KSH kód": "23940",
            "Jogállás": "5",
            "Terület": "5442",
            "Népesség": "2870",
            "Lakások": "1251"
        },
        {
            "Ir. szám": "4172",
            "name": "Biharnagybajom",
            "Megyekód": "HB",
            "Lat": "47.2167",
            "Long": "21.2333",
            "KSH kód": "24828",
            "Jogállás": "6",
            "Terület": "6135",
            "Népesség": "2801",
            "Lakások": "1145"
        },
        {
            "Ir. szám": "4173",
            "name": "Nagyrábé",
            "Megyekód": "HB",
            "Lat": "47.2",
            "Long": "21.3333",
            "KSH kód": "06309",
            "Jogállás": "5",
            "Terület": "8542",
            "Népesség": "2102",
            "Lakások": "964"
        },
        {
            "Ir. szám": "4174",
            "name": "Bihartorda",
            "Megyekód": "HB",
            "Lat": "47.2167",
            "Long": "21.3667",
            "KSH kód": "29887",
            "Jogállás": "6",
            "Terület": "2238",
            "Népesség": "905",
            "Lakások": "435"
        },
        {
            "Ir. szám": "4175",
            "name": "Bihardancsháza",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.3167",
            "KSH kód": "25256",
            "Jogállás": "6",
            "Terület": "831",
            "Népesség": "193",
            "Lakások": "104"
        },
        {
            "Ir. szám": "4176",
            "name": "Sáp",
            "Megyekód": "HB",
            "Lat": "47.25",
            "Long": "21.3667",
            "KSH kód": "26116",
            "Jogállás": "6",
            "Terület": "1922",
            "Népesség": "980",
            "Lakások": "410"
        },
        {
            "Ir. szám": "4177",
            "name": "Földes",
            "Megyekód": "HB",
            "Lat": "47.3",
            "Long": "21.3667",
            "KSH kód": "03258",
            "Jogállás": "5",
            "Terület": "6524",
            "Népesség": "4092",
            "Lakások": "1753"
        },
        {
            "Ir. szám": "4181",
            "name": "Nádudvar",
            "Megyekód": "HB",
            "Lat": "47.4167",
            "Long": "21.1667",
            "KSH kód": "28103",
            "Jogállás": "4",
            "Terület": "22591",
            "Népesség": "8995",
            "Lakások": "3728"
        },
        {
            "Ir. szám": "4183",
            "name": "Kaba",
            "Megyekód": "HB",
            "Lat": "47.35",
            "Long": "21.2833",
            "KSH kód": "02307",
            "Jogállás": "4",
            "Terület": "9504",
            "Népesség": "6087",
            "Lakások": "2369"
        },
        {
            "Ir. szám": "4184",
            "name": "Tetétlen",
            "Megyekód": "HB",
            "Lat": "47.3167",
            "Long": "21.3",
            "KSH kód": "19691",
            "Jogállás": "6",
            "Terület": "3211",
            "Népesség": "1397",
            "Lakások": "558"
        },
        {
            "Ir. szám": "4200",
            "name": "Hajdúszoboszló",
            "Megyekód": "HB",
            "Lat": "47.4467",
            "Long": "21.3924",
            "KSH kód": "05175",
            "Jogállás": "4",
            "Terület": "23870",
            "Népesség": "23296",
            "Lakások": "10524"
        },
        {
            "Ir. szám": "4201",
            "name": "Hajdúszoboszló",
            "Megyekód": "HB",
            "Lat": "47.45",
            "Long": "21.4",
            "KSH kód": "05175",
            "Jogállás": "4",
            "Terület": "23870",
            "Népesség": "23296",
            "Lakások": "10524"
        },
        {
            "Ir. szám": "4202",
            "name": "Hajdúszoboszló",
            "Megyekód": "HB",
            "Lat": "47.45",
            "Long": "21.4",
            "KSH kód": "05175",
            "Jogállás": "4",
            "Terület": "23870",
            "Népesség": "23296",
            "Lakások": "10524"
        },
        {
            "Ir. szám": "4211",
            "name": "Ebes",
            "Megyekód": "HB",
            "Lat": "47.4667",
            "Long": "21.5",
            "KSH kód": "14614",
            "Jogállás": "6",
            "Terület": "7727",
            "Népesség": "4407",
            "Lakások": "1579"
        },
        {
            "Ir. szám": "4212",
            "name": "Hajdúszovát",
            "Megyekód": "HB",
            "Lat": "47.3833",
            "Long": "21.4833",
            "KSH kód": "17473",
            "Jogállás": "6",
            "Terület": "5801",
            "Népesség": "3087",
            "Lakások": "1101"
        },
        {
            "Ir. szám": "4220",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4221",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4224",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4225",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4225",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4231",
            "name": "Bököny",
            "Megyekód": "SZ",
            "Lat": "47.7333",
            "Long": "21.75",
            "KSH kód": "11299",
            "Jogállás": "6",
            "Terület": "3547",
            "Népesség": "3272",
            "Lakások": "1267"
        },
        {
            "Ir. szám": "4232",
            "name": "Geszteréd",
            "Megyekód": "SZ",
            "Lat": "47.7667",
            "Long": "21.7833",
            "KSH kód": "28893",
            "Jogállás": "6",
            "Terület": "3352",
            "Népesség": "1709",
            "Lakások": "798"
        },
        {
            "Ir. szám": "4233",
            "name": "Balkány",
            "Megyekód": "SZ",
            "Lat": "47.7667",
            "Long": "21.8667",
            "KSH kód": "26958",
            "Jogállás": "4",
            "Terület": "8999",
            "Népesség": "6442",
            "Lakások": "2494"
        },
        {
            "Ir. szám": "4234",
            "name": "Szakoly",
            "Megyekód": "SZ",
            "Lat": "47.7667",
            "Long": "21.9167",
            "KSH kód": "04774",
            "Jogállás": "6",
            "Terület": "4144",
            "Népesség": "2847",
            "Lakások": "1028"
        },
        {
            "Ir. szám": "4235",
            "name": "Biri",
            "Megyekód": "SZ",
            "Lat": "47.8167",
            "Long": "21.85",
            "KSH kód": "02945",
            "Jogállás": "6",
            "Terület": "2259",
            "Népesség": "1356",
            "Lakások": "495"
        },
        {
            "Ir. szám": "4241",
            "name": "Bocskaikert",
            "Megyekód": "HB",
            "Lat": "47.4333",
            "Long": "21.9167",
            "KSH kód": "34102",
            "Jogállás": "6",
            "Terület": "1089",
            "Népesség": "2964",
            "Lakások": "1059"
        },
        {
            "Ir. szám": "4242",
            "name": "Hajdúhadház",
            "Megyekód": "HB",
            "Lat": "47.6833",
            "Long": "21.6667",
            "KSH kód": "10393",
            "Jogállás": "4",
            "Terület": "8780",
            "Népesség": "12650",
            "Lakások": "4203"
        },
        {
            "Ir. szám": "4243",
            "name": "Téglás",
            "Megyekód": "HB",
            "Lat": "47.7167",
            "Long": "21.6833",
            "KSH kód": "23214",
            "Jogállás": "4",
            "Terület": "3833",
            "Népesség": "6624",
            "Lakások": "2337"
        },
        {
            "Ir. szám": "4244",
            "name": "Újfehértó",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "21.6833",
            "KSH kód": "26611",
            "Jogállás": "4",
            "Terület": "14088",
            "Népesség": "13335",
            "Lakások": "4971"
        },
        {
            "Ir. szám": "4245",
            "name": "Érpatak",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "21.7667",
            "KSH kód": "10852",
            "Jogállás": "6",
            "Terület": "3133",
            "Népesség": "1749",
            "Lakások": "735"
        },
        {
            "Ir. szám": "4246",
            "name": "Érpatak",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "21.7667",
            "KSH kód": "10852",
            "Jogállás": "6",
            "Terület": "3133",
            "Népesség": "1749",
            "Lakások": "735"
        },
        {
            "Ir. szám": "4251",
            "name": "Hajdúsámson",
            "Megyekód": "HB",
            "Lat": "47.6",
            "Long": "21.7667",
            "KSH kód": "31097",
            "Jogállás": "4",
            "Terület": "6947",
            "Népesség": "12846",
            "Lakások": "4103"
        },
        {
            "Ir. szám": "4252",
            "name": "Hajdúsámson",
            "Megyekód": "HB",
            "Lat": "47.6",
            "Long": "21.7667",
            "KSH kód": "31097",
            "Jogállás": "4",
            "Terület": "6947",
            "Népesség": "12846",
            "Lakások": "4103"
        },
        {
            "Ir. szám": "4253",
            "name": "Hajdúsámson",
            "Megyekód": "HB",
            "Lat": "47.6",
            "Long": "21.7667",
            "KSH kód": "31097",
            "Jogállás": "4",
            "Terület": "6947",
            "Népesség": "12846",
            "Lakások": "4103"
        },
        {
            "Ir. szám": "4254",
            "name": "Nyíradony",
            "Megyekód": "HB",
            "Lat": "47.6833",
            "Long": "21.9167",
            "KSH kód": "06187",
            "Jogállás": "4",
            "Terület": "9659",
            "Népesség": "7874",
            "Lakások": "2796"
        },
        {
            "Ir. szám": "4262",
            "name": "Nyíracsád",
            "Megyekód": "HB",
            "Lat": "47.6",
            "Long": "21.9833",
            "KSH kód": "14003",
            "Jogállás": "6",
            "Terület": "7492",
            "Népesség": "3873",
            "Lakások": "1482"
        },
        {
            "Ir. szám": "4263",
            "name": "Nyírmártonfalva",
            "Megyekód": "HB",
            "Lat": "47.5833",
            "Long": "21.9",
            "KSH kód": "32382",
            "Jogállás": "6",
            "Terület": "5748",
            "Népesség": "2075",
            "Lakások": "818"
        },
        {
            "Ir. szám": "4264",
            "name": "Nyírábrány",
            "Megyekód": "HB",
            "Lat": "47.55",
            "Long": "22.0333",
            "KSH kód": "32294",
            "Jogállás": "5",
            "Terület": "5562",
            "Népesség": "3854",
            "Lakások": "1545"
        },
        {
            "Ir. szám": "4266",
            "name": "Fülöp",
            "Megyekód": "HB",
            "Lat": "47.6",
            "Long": "22.0667",
            "KSH kód": "22150",
            "Jogállás": "6",
            "Terület": "5595",
            "Népesség": "1767",
            "Lakások": "784"
        },
        {
            "Ir. szám": "4267",
            "name": "Penészlek",
            "Megyekód": "SZ",
            "Lat": "47.6333",
            "Long": "22.15",
            "KSH kód": "17084",
            "Jogállás": "6",
            "Terület": "3692",
            "Népesség": "959",
            "Lakások": "503"
        },
        {
            "Ir. szám": "4271",
            "name": "Mikepércs",
            "Megyekód": "HB",
            "Lat": "47.45",
            "Long": "21.6333",
            "KSH kód": "24217",
            "Jogállás": "6",
            "Terület": "3693",
            "Népesség": "4177",
            "Lakások": "1221"
        },
        {
            "Ir. szám": "4272",
            "name": "Sáránd",
            "Megyekód": "HB",
            "Lat": "47.4",
            "Long": "21.6333",
            "KSH kód": "25007",
            "Jogállás": "6",
            "Terület": "2268",
            "Népesség": "2338",
            "Lakások": "831"
        },
        {
            "Ir. szám": "4273",
            "name": "Hajdúbagos",
            "Megyekód": "HB",
            "Lat": "47.4",
            "Long": "21.6667",
            "KSH kód": "26170",
            "Jogállás": "6",
            "Terület": "3744",
            "Népesség": "1995",
            "Lakások": "854"
        },
        {
            "Ir. szám": "4274",
            "name": "Hosszúpályi",
            "Megyekód": "HB",
            "Lat": "47.4",
            "Long": "21.75",
            "KSH kód": "06266",
            "Jogállás": "5",
            "Terület": "7918",
            "Népesség": "5839",
            "Lakások": "2045"
        },
        {
            "Ir. szám": "4275",
            "name": "Monostorpályi",
            "Megyekód": "HB",
            "Lat": "47.4",
            "Long": "21.7833",
            "KSH kód": "25894",
            "Jogállás": "6",
            "Terület": "4444",
            "Népesség": "2217",
            "Lakások": "954"
        },
        {
            "Ir. szám": "4281",
            "name": "Létavértes",
            "Megyekód": "HB",
            "Lat": "47.3833",
            "Long": "21.9",
            "KSH kód": "05768",
            "Jogállás": "4",
            "Terület": "11662",
            "Népesség": "7165",
            "Lakások": "2724"
        },
        {
            "Ir. szám": "4283",
            "name": "Létavértes",
            "Megyekód": "HB",
            "Lat": "47.3833",
            "Long": "21.9",
            "KSH kód": "05768",
            "Jogállás": "4",
            "Terület": "11662",
            "Népesség": "7165",
            "Lakások": "2724"
        },
        {
            "Ir. szám": "4284",
            "name": "Kokad",
            "Megyekód": "HB",
            "Lat": "47.4",
            "Long": "21.95",
            "KSH kód": "17455",
            "Jogállás": "6",
            "Terület": "1610",
            "Népesség": "596",
            "Lakások": "302"
        },
        {
            "Ir. szám": "4285",
            "name": "Álmosd",
            "Megyekód": "HB",
            "Lat": "47.4167",
            "Long": "21.9833",
            "KSH kód": "27641",
            "Jogállás": "6",
            "Terület": "3413",
            "Népesség": "1602",
            "Lakások": "725"
        },
        {
            "Ir. szám": "4286",
            "name": "Bagamér",
            "Megyekód": "HB",
            "Lat": "47.45",
            "Long": "22",
            "KSH kód": "20011",
            "Jogállás": "5",
            "Terület": "4702",
            "Népesség": "2553",
            "Lakások": "1023"
        },
        {
            "Ir. szám": "4287",
            "name": "Vámospércs",
            "Megyekód": "HB",
            "Lat": "47.5333",
            "Long": "21.9",
            "KSH kód": "08989",
            "Jogállás": "4",
            "Terület": "5819",
            "Népesség": "5347",
            "Lakások": "2146"
        },
        {
            "Ir. szám": "4288",
            "name": "Újléta",
            "Megyekód": "HB",
            "Lat": "47.4667",
            "Long": "21.8833",
            "KSH kód": "20419",
            "Jogállás": "6",
            "Terület": "3041",
            "Népesség": "1063",
            "Lakások": "445"
        },
        {
            "Ir. szám": "4300",
            "name": "Nyírbátor",
            "Megyekód": "SZ",
            "Lat": "47.8333",
            "Long": "22.1333",
            "KSH kód": "14845",
            "Jogállás": "4",
            "Terület": "6673",
            "Népesség": "12522",
            "Lakások": "4764"
        },
        {
            "Ir. szám": "4301",
            "name": "Nyírbátor",
            "Megyekód": "SZ",
            "Lat": "47.8333",
            "Long": "22.1333",
            "KSH kód": "14845",
            "Jogállás": "4",
            "Terület": "6673",
            "Népesség": "12522",
            "Lakások": "4764"
        },
        {
            "Ir. szám": "4311",
            "name": "Nyírgyulaj",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "22.1",
            "KSH kód": "09238",
            "Jogállás": "6",
            "Terület": "3576",
            "Népesség": "2000",
            "Lakások": "786"
        },
        {
            "Ir. szám": "4320",
            "name": "Nagykálló",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "21.85",
            "KSH kód": "24785",
            "Jogállás": "4",
            "Terület": "6855",
            "Népesség": "10064",
            "Lakások": "3653"
        },
        {
            "Ir. szám": "4321",
            "name": "Nagykálló",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "21.85",
            "KSH kód": "24785",
            "Jogállás": "4",
            "Terület": "6855",
            "Népesség": "10064",
            "Lakások": "3653"
        },
        {
            "Ir. szám": "4324",
            "name": "Kállósemjén",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "21.9333",
            "KSH kód": "31404",
            "Jogállás": "5",
            "Terület": "5452",
            "Népesség": "3820",
            "Lakások": "1570"
        },
        {
            "Ir. szám": "4325",
            "name": "Kisléta",
            "Megyekód": "SZ",
            "Lat": "47.8333",
            "Long": "22.0167",
            "KSH kód": "28477",
            "Jogállás": "6",
            "Terület": "2200",
            "Népesség": "1896",
            "Lakások": "574"
        },
        {
            "Ir. szám": "4326",
            "name": "Máriapócs",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "22.0333",
            "KSH kód": "19655",
            "Jogállás": "4",
            "Terület": "2209",
            "Népesség": "2130",
            "Lakások": "728"
        },
        {
            "Ir. szám": "4327",
            "name": "Pócspetri",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "22",
            "KSH kód": "11244",
            "Jogállás": "6",
            "Terület": "2637",
            "Népesség": "1762",
            "Lakások": "669"
        },
        {
            "Ir. szám": "4331",
            "name": "Nyírcsászári",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.1833",
            "KSH kód": "25973",
            "Jogállás": "6",
            "Terület": "1323",
            "Népesség": "1166",
            "Lakások": "430"
        },
        {
            "Ir. szám": "4332",
            "name": "Nyírderzs",
            "Megyekód": "SZ",
            "Lat": "47.9",
            "Long": "22.1667",
            "KSH kód": "05041",
            "Jogállás": "6",
            "Terület": "1701",
            "Népesség": "598",
            "Lakások": "266"
        },
        {
            "Ir. szám": "4333",
            "name": "Nyírkáta",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.2667",
            "KSH kód": "32452",
            "Jogállás": "6",
            "Terület": "3863",
            "Népesség": "1816",
            "Lakások": "661"
        },
        {
            "Ir. szám": "4334",
            "name": "Hodász",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.2167",
            "KSH kód": "13019",
            "Jogállás": "5",
            "Terület": "2649",
            "Népesség": "3399",
            "Lakások": "1134"
        },
        {
            "Ir. szám": "4335",
            "name": "Kántorjánosi",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.15",
            "KSH kód": "02671",
            "Jogállás": "6",
            "Terület": "4162",
            "Népesség": "2183",
            "Lakások": "883"
        },
        {
            "Ir. szám": "4336",
            "name": "Őr",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.2",
            "KSH kód": "09025",
            "Jogállás": "6",
            "Terület": "1778",
            "Népesség": "1447",
            "Lakások": "544"
        },
        {
            "Ir. szám": "4337",
            "name": "Jármi",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.25",
            "KSH kód": "17589",
            "Jogállás": "6",
            "Terület": "1237",
            "Népesség": "1267",
            "Lakások": "482"
        },
        {
            "Ir. szám": "4338",
            "name": "Papos",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.25",
            "KSH kód": "32577",
            "Jogállás": "6",
            "Terület": "1067",
            "Népesség": "837",
            "Lakások": "320"
        },
        {
            "Ir. szám": "4341",
            "name": "Nyírvasvári",
            "Megyekód": "SZ",
            "Lat": "47.8167",
            "Long": "22.1833",
            "KSH kód": "16522",
            "Jogállás": "6",
            "Terület": "2843",
            "Népesség": "1944",
            "Lakások": "743"
        },
        {
            "Ir. szám": "4342",
            "name": "Terem",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "22.3167",
            "KSH kód": "33358",
            "Jogállás": "6",
            "Terület": "4951",
            "Népesség": "650",
            "Lakások": "306"
        },
        {
            "Ir. szám": "4343",
            "name": "Bátorliget",
            "Megyekód": "SZ",
            "Lat": "47.8083",
            "Long": "22.25",
            "KSH kód": "02990",
            "Jogállás": "6",
            "Terület": "3330",
            "Népesség": "710",
            "Lakások": "353"
        },
        {
            "Ir. szám": "4351",
            "name": "Vállaj",
            "Megyekód": "SZ",
            "Lat": "47.7667",
            "Long": "22.3833",
            "KSH kód": "06938",
            "Jogállás": "6",
            "Terület": "2227",
            "Népesség": "867",
            "Lakások": "456"
        },
        {
            "Ir. szám": "4352",
            "name": "Mérk",
            "Megyekód": "SZ",
            "Lat": "47.7833",
            "Long": "22.3667",
            "KSH kód": "07463",
            "Jogállás": "5",
            "Terület": "2508",
            "Népesség": "2118",
            "Lakások": "817"
        },
        {
            "Ir. szám": "4353",
            "name": "Tiborszállás",
            "Megyekód": "SZ",
            "Lat": "47.8167",
            "Long": "22.4167",
            "KSH kód": "08952",
            "Jogállás": "6",
            "Terület": "2552",
            "Népesség": "1003",
            "Lakások": "373"
        },
        {
            "Ir. szám": "4354",
            "name": "Fábiánháza",
            "Megyekód": "SZ",
            "Lat": "47.8333",
            "Long": "22.3667",
            "KSH kód": "23250",
            "Jogállás": "6",
            "Terület": "2735",
            "Népesség": "1730",
            "Lakások": "777"
        },
        {
            "Ir. szám": "4355",
            "name": "Nagyecsed",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.4",
            "KSH kód": "06488",
            "Jogállás": "4",
            "Terület": "4387",
            "Népesség": "6306",
            "Lakások": "2391"
        },
        {
            "Ir. szám": "4356",
            "name": "Nyírcsaholy",
            "Megyekód": "SZ",
            "Lat": "47.9",
            "Long": "22.3333",
            "KSH kód": "07904",
            "Jogállás": "6",
            "Terület": "3434",
            "Népesség": "2172",
            "Lakások": "833"
        },
        {
            "Ir. szám": "4361",
            "name": "Nyírbogát",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "22.0667",
            "KSH kód": "31158",
            "Jogállás": "5",
            "Terület": "5536",
            "Népesség": "3279",
            "Lakások": "1287"
        },
        {
            "Ir. szám": "4362",
            "name": "Nyírgelse",
            "Megyekód": "SZ",
            "Lat": "47.75",
            "Long": "21.9833",
            "KSH kód": "28440",
            "Jogállás": "6",
            "Terület": "2761",
            "Népesség": "1117",
            "Lakások": "504"
        },
        {
            "Ir. szám": "4363",
            "name": "Nyírmihálydi",
            "Megyekód": "SZ",
            "Lat": "47.7333",
            "Long": "21.9833",
            "KSH kód": "26365",
            "Jogállás": "6",
            "Terület": "2548",
            "Népesség": "2005",
            "Lakások": "638"
        },
        {
            "Ir. szám": "4371",
            "name": "Nyírlugos",
            "Megyekód": "SZ",
            "Lat": "47.7",
            "Long": "22.05",
            "KSH kód": "11271",
            "Jogállás": "4",
            "Terület": "5838",
            "Népesség": "2837",
            "Lakások": "1226"
        },
        {
            "Ir. szám": "4372",
            "name": "Nyírbéltek",
            "Megyekód": "SZ",
            "Lat": "47.7",
            "Long": "22.1333",
            "KSH kód": "15802",
            "Jogállás": "5",
            "Terület": "6217",
            "Népesség": "2815",
            "Lakások": "1119"
        },
        {
            "Ir. szám": "4373",
            "name": "Ömböly",
            "Megyekód": "SZ",
            "Lat": "47.7",
            "Long": "22.2167",
            "KSH kód": "26550",
            "Jogállás": "6",
            "Terület": "3035",
            "Népesség": "425",
            "Lakások": "200"
        },
        {
            "Ir. szám": "4374",
            "name": "Encsencs",
            "Megyekód": "SZ",
            "Lat": "47.7333",
            "Long": "22.1167",
            "KSH kód": "32328",
            "Jogállás": "6",
            "Terület": "3191",
            "Népesség": "1986",
            "Lakások": "678"
        },
        {
            "Ir. szám": "4375",
            "name": "Piricse",
            "Megyekód": "SZ",
            "Lat": "47.7667",
            "Long": "22.15",
            "KSH kód": "03391",
            "Jogállás": "6",
            "Terület": "3699",
            "Népesség": "1891",
            "Lakások": "642"
        },
        {
            "Ir. szám": "4376",
            "name": "Nyírpilis",
            "Megyekód": "SZ",
            "Lat": "47.7833",
            "Long": "22.1833",
            "KSH kód": "03878",
            "Jogállás": "6",
            "Terület": "1634",
            "Népesség": "836",
            "Lakások": "243"
        },
        {
            "Ir. szám": "4400",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4401",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4402",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4403",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4404",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4405",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4406",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4407",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4410",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4411",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4412",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4413",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4431",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4432",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4433",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4434",
            "name": "Kálmánháza",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "21.5833",
            "KSH kód": "27225",
            "Jogállás": "6",
            "Terület": "3724",
            "Népesség": "2051",
            "Lakások": "762"
        },
        {
            "Ir. szám": "4435",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4440",
            "name": "Tiszavasvári",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.35",
            "KSH kód": "07597",
            "Jogállás": "4",
            "Terület": "12659",
            "Népesség": "13012",
            "Lakások": "4559"
        },
        {
            "Ir. szám": "4441",
            "name": "Szorgalmatos",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.4875",
            "KSH kód": "34388",
            "Jogállás": "6",
            "Terület": "1038",
            "Népesség": "1070",
            "Lakások": "330"
        },
        {
            "Ir. szám": "4444",
            "name": "Szorgalmatos",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.4875",
            "KSH kód": "34388",
            "Jogállás": "6",
            "Terület": "1038",
            "Népesség": "1070",
            "Lakások": "330"
        },
        {
            "Ir. szám": "4445",
            "name": "Nagycserkesz",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.5333",
            "KSH kód": "22743",
            "Jogállás": "6",
            "Terület": "4151",
            "Népesség": "1800",
            "Lakások": "684"
        },
        {
            "Ir. szám": "4446",
            "name": "Nagycserkesz",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.5333",
            "KSH kód": "22743",
            "Jogállás": "6",
            "Terület": "4151",
            "Népesség": "1800",
            "Lakások": "684"
        },
        {
            "Ir. szám": "4447",
            "name": "Nagycserkesz",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.5333",
            "KSH kód": "22743",
            "Jogállás": "6",
            "Terület": "4151",
            "Népesség": "1800",
            "Lakások": "684"
        },
        {
            "Ir. szám": "4450",
            "name": "Tiszalök",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.3833",
            "KSH kód": "23524",
            "Jogállás": "4",
            "Terület": "5872",
            "Népesség": "5768",
            "Lakások": "2262"
        },
        {
            "Ir. szám": "4455",
            "name": "Tiszadada",
            "Megyekód": "SZ",
            "Lat": "48.0333",
            "Long": "21.25",
            "KSH kód": "06433",
            "Jogállás": "6",
            "Terület": "4877",
            "Népesség": "2327",
            "Lakások": "977"
        },
        {
            "Ir. szám": "4456",
            "name": "Tiszadob",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.1667",
            "KSH kód": "12593",
            "Jogállás": "5",
            "Terület": "8261",
            "Népesség": "3071",
            "Lakások": "1233"
        },
        {
            "Ir. szám": "4461",
            "name": "Nyírtelek",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.6333",
            "KSH kód": "13550",
            "Jogállás": "4",
            "Terület": "6792",
            "Népesség": "7001",
            "Lakások": "2552"
        },
        {
            "Ir. szám": "4463",
            "name": "Tiszanagyfalu",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "21.4833",
            "KSH kód": "27252",
            "Jogállás": "6",
            "Terület": "2705",
            "Népesség": "1904",
            "Lakások": "707"
        },
        {
            "Ir. szám": "4464",
            "name": "Tiszaeszlár",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "21.4667",
            "KSH kód": "09113",
            "Jogállás": "6",
            "Terület": "5460",
            "Népesség": "2676",
            "Lakások": "1097"
        },
        {
            "Ir. szám": "4465",
            "name": "Rakamaz",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "21.4667",
            "KSH kód": "14739",
            "Jogállás": "4",
            "Terület": "4264",
            "Népesség": "4910",
            "Lakások": "1893"
        },
        {
            "Ir. szám": "4466",
            "name": "Timár",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "21.4667",
            "KSH kód": "24475",
            "Jogállás": "6",
            "Terület": "2182",
            "Népesség": "1350",
            "Lakások": "533"
        },
        {
            "Ir. szám": "4467",
            "name": "Szabolcs",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "21.5",
            "KSH kód": "19169",
            "Jogállás": "6",
            "Terület": "588",
            "Népesség": "423",
            "Lakások": "201"
        },
        {
            "Ir. szám": "4468",
            "name": "Balsa",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "21.55",
            "KSH kód": "15963",
            "Jogállás": "6",
            "Terület": "2251",
            "Népesség": "856",
            "Lakások": "433"
        },
        {
            "Ir. szám": "4471",
            "name": "Gávavencsellő",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "21.6",
            "KSH kód": "05801",
            "Jogállás": "5",
            "Terület": "6683",
            "Népesség": "3596",
            "Lakások": "1662"
        },
        {
            "Ir. szám": "4472",
            "name": "Gávavencsellő",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "21.6",
            "KSH kód": "05801",
            "Jogállás": "5",
            "Terület": "6683",
            "Népesség": "3596",
            "Lakások": "1662"
        },
        {
            "Ir. szám": "4474",
            "name": "Tiszabercel",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "21.65",
            "KSH kód": "03850",
            "Jogállás": "6",
            "Terület": "3138",
            "Népesség": "1957",
            "Lakások": "754"
        },
        {
            "Ir. szám": "4475",
            "name": "Paszab",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "21.6833",
            "KSH kód": "31972",
            "Jogállás": "6",
            "Terület": "1296",
            "Népesség": "1306",
            "Lakások": "434"
        },
        {
            "Ir. szám": "4481",
            "name": "Paszab",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "21.6833",
            "KSH kód": "31972",
            "Jogállás": "6",
            "Terület": "1296",
            "Népesség": "1306",
            "Lakások": "434"
        },
        {
            "Ir. szám": "4482",
            "name": "Kótaj",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "21.7167",
            "KSH kód": "23728",
            "Jogállás": "6",
            "Terület": "2593",
            "Népesség": "4542",
            "Lakások": "1523"
        },
        {
            "Ir. szám": "4483",
            "name": "Buj",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "21.65",
            "KSH kód": "19707",
            "Jogállás": "6",
            "Terület": "3276",
            "Népesség": "2305",
            "Lakások": "978"
        },
        {
            "Ir. szám": "4484",
            "name": "Ibrány",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "21.7167",
            "KSH kód": "25636",
            "Jogállás": "4",
            "Terület": "6048",
            "Népesség": "6955",
            "Lakások": "2369"
        },
        {
            "Ir. szám": "4485",
            "name": "Nagyhalász",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "21.7667",
            "KSH kód": "27155",
            "Jogállás": "4",
            "Terület": "4431",
            "Népesség": "5715",
            "Lakások": "1963"
        },
        {
            "Ir. szám": "4486",
            "name": "Nagyhalász",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "21.7667",
            "KSH kód": "27155",
            "Jogállás": "4",
            "Terület": "4431",
            "Népesség": "5715",
            "Lakások": "1963"
        },
        {
            "Ir. szám": "4487",
            "name": "Tiszatelek",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "21.8",
            "KSH kód": "14447",
            "Jogállás": "6",
            "Terület": "3378",
            "Népesség": "1473",
            "Lakások": "541"
        },
        {
            "Ir. szám": "4488",
            "name": "Beszterec",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "21.85",
            "KSH kód": "21227",
            "Jogállás": "6",
            "Terület": "1159",
            "Népesség": "1044",
            "Lakások": "379"
        },
        {
            "Ir. szám": "4491",
            "name": "Újdombrád",
            "Megyekód": "SZ",
            "Lat": "48.2333",
            "Long": "21.9333",
            "KSH kód": "33659",
            "Jogállás": "6",
            "Terület": "1078",
            "Népesség": "703",
            "Lakások": "220"
        },
        {
            "Ir. szám": "4492",
            "name": "Dombrád",
            "Megyekód": "SZ",
            "Lat": "48.2333",
            "Long": "21.9333",
            "KSH kód": "14508",
            "Jogállás": "4",
            "Terület": "5184",
            "Népesség": "4069",
            "Lakások": "1484"
        },
        {
            "Ir. szám": "4493",
            "name": "Tiszakanyár",
            "Megyekód": "SZ",
            "Lat": "48.25",
            "Long": "21.9667",
            "KSH kód": "08554",
            "Jogállás": "6",
            "Terület": "1551",
            "Népesség": "1630",
            "Lakások": "551"
        },
        {
            "Ir. szám": "4494",
            "name": "Kékcse",
            "Megyekód": "SZ",
            "Lat": "48.25",
            "Long": "22",
            "KSH kód": "14359",
            "Jogállás": "6",
            "Terület": "1823",
            "Népesség": "1568",
            "Lakások": "493"
        },
        {
            "Ir. szám": "4495",
            "name": "Döge",
            "Megyekód": "SZ",
            "Lat": "48.2667",
            "Long": "22.0833",
            "KSH kód": "03647",
            "Jogállás": "6",
            "Terület": "1654",
            "Népesség": "2115",
            "Lakások": "743"
        },
        {
            "Ir. szám": "4496",
            "name": "Szabolcsveresmart",
            "Megyekód": "SZ",
            "Lat": "48.3",
            "Long": "22.0167",
            "KSH kód": "03586",
            "Jogállás": "6",
            "Terület": "2295",
            "Népesség": "1708",
            "Lakások": "578"
        },
        {
            "Ir. szám": "4501",
            "name": "Kemecse",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "21.8",
            "KSH kód": "19992",
            "Jogállás": "4",
            "Terület": "3894",
            "Népesség": "4840",
            "Lakások": "1673"
        },
        {
            "Ir. szám": "4502",
            "name": "Vasmegyer",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "21.8167",
            "KSH kód": "27100",
            "Jogállás": "6",
            "Terület": "2273",
            "Népesség": "1726",
            "Lakások": "584"
        },
        {
            "Ir. szám": "4503",
            "name": "Tiszarád",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "21.8",
            "KSH kód": "10205",
            "Jogállás": "6",
            "Terület": "923",
            "Népesség": "603",
            "Lakások": "209"
        },
        {
            "Ir. szám": "4511",
            "name": "Nyírbogdány",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "21.8833",
            "KSH kód": "28802",
            "Jogállás": "6",
            "Terület": "3503",
            "Népesség": "2985",
            "Lakások": "1046"
        },
        {
            "Ir. szám": "4512",
            "name": "Nyírbogdány",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "21.8833",
            "KSH kód": "28802",
            "Jogállás": "6",
            "Terület": "3503",
            "Népesség": "2985",
            "Lakások": "1046"
        },
        {
            "Ir. szám": "4515",
            "name": "Kék",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "21.8833",
            "KSH kód": "28431",
            "Jogállás": "6",
            "Terület": "2205",
            "Népesség": "1966",
            "Lakások": "670"
        },
        {
            "Ir. szám": "4516",
            "name": "Demecser",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "21.9167",
            "KSH kód": "17756",
            "Jogállás": "4",
            "Terület": "3698",
            "Népesség": "4368",
            "Lakások": "1524"
        },
        {
            "Ir. szám": "4517",
            "name": "Gégény",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "21.95",
            "KSH kód": "05670",
            "Jogállás": "6",
            "Terület": "2344",
            "Népesség": "1981",
            "Lakások": "733"
        },
        {
            "Ir. szám": "4521",
            "name": "Berkesz",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "21.9833",
            "KSH kód": "07472",
            "Jogállás": "6",
            "Terület": "1318",
            "Népesség": "900",
            "Lakások": "345"
        },
        {
            "Ir. szám": "4522",
            "name": "Nyírtass",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "22.0333",
            "KSH kód": "28060",
            "Jogállás": "6",
            "Terület": "3795",
            "Népesség": "2034",
            "Lakások": "832"
        },
        {
            "Ir. szám": "4523",
            "name": "Pátroha",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "22",
            "KSH kód": "12186",
            "Jogállás": "6",
            "Terület": "3922",
            "Népesség": "2939",
            "Lakások": "1032"
        },
        {
            "Ir. szám": "4524",
            "name": "Ajak",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "22.0667",
            "KSH kód": "08776",
            "Jogállás": "5",
            "Terület": "2476",
            "Népesség": "3691",
            "Lakások": "1297"
        },
        {
            "Ir. szám": "4525",
            "name": "Rétközberencs",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.0167",
            "KSH kód": "21573",
            "Jogállás": "6",
            "Terület": "1580",
            "Népesség": "1171",
            "Lakások": "403"
        },
        {
            "Ir. szám": "4531",
            "name": "Nyírpazony",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "21.8",
            "KSH kód": "33145",
            "Jogállás": "6",
            "Terület": "1505",
            "Népesség": "3396",
            "Lakások": "1220"
        },
        {
            "Ir. szám": "4532",
            "name": "Nyírtura",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.8333",
            "KSH kód": "12098",
            "Jogállás": "6",
            "Terület": "2148",
            "Népesség": "1872",
            "Lakások": "659"
        },
        {
            "Ir. szám": "4533",
            "name": "Sényő",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "21.8833",
            "KSH kód": "04491",
            "Jogállás": "6",
            "Terület": "1830",
            "Népesség": "1439",
            "Lakások": "463"
        },
        {
            "Ir. szám": "4534",
            "name": "Székely",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "21.95",
            "KSH kód": "31088",
            "Jogállás": "6",
            "Terület": "1608",
            "Népesség": "1076",
            "Lakások": "363"
        },
        {
            "Ir. szám": "4535",
            "name": "Nyíribrony",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.9667",
            "KSH kód": "14696",
            "Jogállás": "6",
            "Terület": "2009",
            "Népesség": "1118",
            "Lakások": "429"
        },
        {
            "Ir. szám": "4536",
            "name": "Ramocsaháza",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22",
            "KSH kód": "31857",
            "Jogállás": "6",
            "Terület": "1811",
            "Népesség": "1493",
            "Lakások": "547"
        },
        {
            "Ir. szám": "4537",
            "name": "Nyírkércs",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.05",
            "KSH kód": "25928",
            "Jogállás": "6",
            "Terület": "1428",
            "Népesség": "799",
            "Lakások": "321"
        },
        {
            "Ir. szám": "4541",
            "name": "Nyírjákó",
            "Megyekód": "SZ",
            "Lat": "48.0333",
            "Long": "22.0833",
            "KSH kód": "31477",
            "Jogállás": "6",
            "Terület": "1034",
            "Népesség": "927",
            "Lakások": "365"
        },
        {
            "Ir. szám": "4542",
            "name": "Petneháza",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "22.0833",
            "KSH kód": "17224",
            "Jogállás": "6",
            "Terület": "2420",
            "Népesség": "1849",
            "Lakások": "703"
        },
        {
            "Ir. szám": "4543",
            "name": "Laskod",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.05",
            "KSH kód": "21290",
            "Jogállás": "6",
            "Terület": "1359",
            "Népesség": "1006",
            "Lakások": "415"
        },
        {
            "Ir. szám": "4544",
            "name": "Nyírkarász",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.1",
            "KSH kód": "18290",
            "Jogállás": "6",
            "Terület": "4143",
            "Népesség": "2360",
            "Lakások": "872"
        },
        {
            "Ir. szám": "4545",
            "name": "Gyulaháza",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.1167",
            "KSH kód": "07676",
            "Jogállás": "6",
            "Terület": "2209",
            "Népesség": "1970",
            "Lakások": "691"
        },
        {
            "Ir. szám": "4546",
            "name": "Anarcs",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "22.1167",
            "KSH kód": "29975",
            "Jogállás": "6",
            "Terület": "1706",
            "Népesség": "1977",
            "Lakások": "682"
        },
        {
            "Ir. szám": "4547",
            "name": "Szabolcsbáka",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "22.15",
            "KSH kód": "22053",
            "Jogállás": "6",
            "Terület": "1984",
            "Népesség": "1215",
            "Lakások": "464"
        },
        {
            "Ir. szám": "4551",
            "name": "Szabolcsbáka",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "22.15",
            "KSH kód": "22053",
            "Jogállás": "6",
            "Terület": "1984",
            "Népesség": "1215",
            "Lakások": "464"
        },
        {
            "Ir. szám": "4552",
            "name": "Napkor",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "21.8833",
            "KSH kód": "08420",
            "Jogállás": "6",
            "Terület": "3735",
            "Népesség": "3844",
            "Lakások": "1336"
        },
        {
            "Ir. szám": "4553",
            "name": "Apagy",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "21.9333",
            "KSH kód": "20303",
            "Jogállás": "6",
            "Terület": "3204",
            "Népesség": "2269",
            "Lakások": "862"
        },
        {
            "Ir. szám": "4554",
            "name": "Nyírtét",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.9333",
            "KSH kód": "09256",
            "Jogállás": "6",
            "Terület": "1717",
            "Népesség": "1080",
            "Lakások": "375"
        },
        {
            "Ir. szám": "4555",
            "name": "Levelek",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22",
            "KSH kód": "30979",
            "Jogállás": "5",
            "Terület": "2573",
            "Népesség": "2898",
            "Lakások": "953"
        },
        {
            "Ir. szám": "4556",
            "name": "Magy",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "21.9833",
            "KSH kód": "16629",
            "Jogállás": "6",
            "Terület": "2070",
            "Népesség": "987",
            "Lakások": "332"
        },
        {
            "Ir. szám": "4557",
            "name": "Besenyőd",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.0167",
            "KSH kód": "13639",
            "Jogállás": "6",
            "Terület": "945",
            "Népesség": "642",
            "Lakások": "254"
        },
        {
            "Ir. szám": "4558",
            "name": "Ófehértó",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.05",
            "KSH kód": "22284",
            "Jogállás": "6",
            "Terület": "4317",
            "Népesség": "2614",
            "Lakások": "1020"
        },
        {
            "Ir. szám": "4561",
            "name": "Baktalórántháza",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "22.0833",
            "KSH kód": "02325",
            "Jogállás": "4",
            "Terület": "3525",
            "Népesség": "4306",
            "Lakások": "1204"
        },
        {
            "Ir. szám": "4562",
            "name": "Vaja",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "22.1667",
            "KSH kód": "18591",
            "Jogállás": "5",
            "Terület": "2861",
            "Népesség": "3625",
            "Lakások": "1171"
        },
        {
            "Ir. szám": "4563",
            "name": "Rohod",
            "Megyekód": "SZ",
            "Lat": "48.0333",
            "Long": "22.1333",
            "KSH kód": "24581",
            "Jogállás": "6",
            "Terület": "1956",
            "Népesség": "1225",
            "Lakások": "434"
        },
        {
            "Ir. szám": "4564",
            "name": "Nyírmada",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "22.2",
            "KSH kód": "12274",
            "Jogállás": "5",
            "Terület": "3882",
            "Népesség": "4866",
            "Lakások": "1568"
        },
        {
            "Ir. szám": "4565",
            "name": "Pusztadobos",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "22.2333",
            "KSH kód": "13860",
            "Jogállás": "6",
            "Terület": "1669",
            "Népesség": "1330",
            "Lakások": "511"
        },
        {
            "Ir. szám": "4566",
            "name": "Ilk",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "22.2333",
            "KSH kód": "09654",
            "Jogállás": "6",
            "Terület": "1456",
            "Népesség": "1234",
            "Lakások": "418"
        },
        {
            "Ir. szám": "4567",
            "name": "Gemzse",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.2",
            "KSH kód": "13000",
            "Jogállás": "6",
            "Terület": "1360",
            "Népesség": "829",
            "Lakások": "302"
        },
        {
            "Ir. szám": "4600",
            "name": "Kisvárda",
            "Megyekód": "SZ",
            "Lat": "48.2167",
            "Long": "22.0833",
            "KSH kód": "09265",
            "Jogállás": "4",
            "Terület": "3591",
            "Népesség": "17097",
            "Lakások": "6915"
        },
        {
            "Ir. szám": "4601",
            "name": "Kisvárda",
            "Megyekód": "SZ",
            "Lat": "48.2167",
            "Long": "22.0833",
            "KSH kód": "09265",
            "Jogállás": "4",
            "Terület": "3591",
            "Népesség": "17097",
            "Lakások": "6915"
        },
        {
            "Ir. szám": "4603",
            "name": "Kisvárda",
            "Megyekód": "SZ",
            "Lat": "48.2167",
            "Long": "22.0833",
            "KSH kód": "09265",
            "Jogállás": "4",
            "Terület": "3591",
            "Népesség": "17097",
            "Lakások": "6915"
        },
        {
            "Ir. szám": "4611",
            "name": "Jéke",
            "Megyekód": "SZ",
            "Lat": "48.2333",
            "Long": "22.1667",
            "KSH kód": "13143",
            "Jogállás": "6",
            "Terület": "632",
            "Népesség": "736",
            "Lakások": "278"
        },
        {
            "Ir. szám": "4621",
            "name": "Fényeslitke",
            "Megyekód": "SZ",
            "Lat": "48.2667",
            "Long": "22.1",
            "KSH kód": "22415",
            "Jogállás": "6",
            "Terület": "2515",
            "Népesség": "2409",
            "Lakások": "868"
        },
        {
            "Ir. szám": "4622",
            "name": "Komoró",
            "Megyekód": "SZ",
            "Lat": "48.3",
            "Long": "22.1167",
            "KSH kód": "27146",
            "Jogállás": "6",
            "Terület": "1169",
            "Népesség": "1318",
            "Lakások": "459"
        },
        {
            "Ir. szám": "4623",
            "name": "Tuzsér",
            "Megyekód": "SZ",
            "Lat": "48.35",
            "Long": "22.1333",
            "KSH kód": "09919",
            "Jogállás": "5",
            "Terület": "1551",
            "Népesség": "3360",
            "Lakások": "1016"
        },
        {
            "Ir. szám": "4624",
            "name": "Tiszabezdéd",
            "Megyekód": "SZ",
            "Lat": "48.3667",
            "Long": "22.15",
            "KSH kód": "20172",
            "Jogállás": "6",
            "Terület": "1860",
            "Népesség": "1961",
            "Lakások": "674"
        },
        {
            "Ir. szám": "4625",
            "name": "Győröcske",
            "Megyekód": "SZ",
            "Lat": "48.3833",
            "Long": "22.15",
            "KSH kód": "28945",
            "Jogállás": "6",
            "Terület": "213",
            "Népesség": "136",
            "Lakások": "55"
        },
        {
            "Ir. szám": "4625",
            "name": "Záhony",
            "Megyekód": "SZ",
            "Lat": "48.4167",
            "Long": "22.1833",
            "KSH kód": "16203",
            "Jogállás": "4",
            "Terület": "687",
            "Népesség": "4279",
            "Lakások": "1800"
        },
        {
            "Ir. szám": "4627",
            "name": "Zsurk",
            "Megyekód": "SZ",
            "Lat": "48.4167",
            "Long": "22.2167",
            "KSH kód": "13037",
            "Jogállás": "6",
            "Terület": "1539",
            "Népesség": "751",
            "Lakások": "278"
        },
        {
            "Ir. szám": "4628",
            "name": "Tiszaszentmárton",
            "Megyekód": "SZ",
            "Lat": "48.3833",
            "Long": "22.2333",
            "KSH kód": "27544",
            "Jogállás": "6",
            "Terület": "1455",
            "Népesség": "1190",
            "Lakások": "414"
        },
        {
            "Ir. szám": "4631",
            "name": "Pap",
            "Megyekód": "SZ",
            "Lat": "48.2167",
            "Long": "22.15",
            "KSH kód": "27748",
            "Jogállás": "6",
            "Terület": "1715",
            "Népesség": "1829",
            "Lakások": "635"
        },
        {
            "Ir. szám": "4632",
            "name": "Nyírlövő",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.1833",
            "KSH kód": "11095",
            "Jogállás": "6",
            "Terület": "791",
            "Népesség": "707",
            "Lakások": "258"
        },
        {
            "Ir. szám": "4633",
            "name": "Lövőpetri",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "22.2",
            "KSH kód": "07995",
            "Jogállás": "6",
            "Terület": "926",
            "Népesség": "460",
            "Lakások": "220"
        },
        {
            "Ir. szám": "4634",
            "name": "Aranyosapáti",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.2667",
            "KSH kód": "09353",
            "Jogállás": "6",
            "Terület": "2136",
            "Népesség": "1993",
            "Lakások": "711"
        },
        {
            "Ir. szám": "4635",
            "name": "Újkenéz",
            "Megyekód": "SZ",
            "Lat": "48.25",
            "Long": "22.2333",
            "KSH kód": "10117",
            "Jogállás": "6",
            "Terület": "969",
            "Népesség": "1047",
            "Lakások": "382"
        },
        {
            "Ir. szám": "4641",
            "name": "Mezőladány",
            "Megyekód": "SZ",
            "Lat": "48.2833",
            "Long": "22.2167",
            "KSH kód": "32656",
            "Jogállás": "6",
            "Terület": "1404",
            "Népesség": "1009",
            "Lakások": "363"
        },
        {
            "Ir. szám": "4642",
            "name": "Tornyospálca",
            "Megyekód": "SZ",
            "Lat": "48.2667",
            "Long": "22.1833",
            "KSH kód": "16957",
            "Jogállás": "6",
            "Terület": "4366",
            "Népesség": "2542",
            "Lakások": "960"
        },
        {
            "Ir. szám": "4643",
            "name": "Benk",
            "Megyekód": "SZ",
            "Lat": "48.3",
            "Long": "22.2333",
            "KSH kód": "25441",
            "Jogállás": "6",
            "Terület": "850",
            "Népesség": "452",
            "Lakások": "189"
        },
        {
            "Ir. szám": "4644",
            "name": "Mándok",
            "Megyekód": "SZ",
            "Lat": "48.3167",
            "Long": "22.2",
            "KSH kód": "17826",
            "Jogállás": "4",
            "Terület": "2891",
            "Népesség": "4366",
            "Lakások": "1486"
        },
        {
            "Ir. szám": "4645",
            "name": "Tiszamogyorós",
            "Megyekód": "SZ",
            "Lat": "48.3167",
            "Long": "22.2333",
            "KSH kód": "11907",
            "Jogállás": "6",
            "Terület": "1025",
            "Népesség": "680",
            "Lakások": "252"
        },
        {
            "Ir. szám": "4646",
            "name": "Eperjeske",
            "Megyekód": "SZ",
            "Lat": "48.35",
            "Long": "22.2167",
            "KSH kód": "18528",
            "Jogállás": "6",
            "Terület": "1357",
            "Népesség": "1155",
            "Lakások": "379"
        },
        {
            "Ir. szám": "4700",
            "name": "Mátészalka",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.3333",
            "KSH kód": "18874",
            "Jogállás": "4",
            "Terület": "4140",
            "Népesség": "17436",
            "Lakások": "7015"
        },
        {
            "Ir. szám": "4701",
            "name": "Mátészalka",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.3333",
            "KSH kód": "18874",
            "Jogállás": "4",
            "Terület": "4140",
            "Népesség": "17436",
            "Lakások": "7015"
        },
        {
            "Ir. szám": "4702",
            "name": "Mátészalka",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.3333",
            "KSH kód": "18874",
            "Jogállás": "4",
            "Terület": "4140",
            "Népesség": "17436",
            "Lakások": "7015"
        },
        {
            "Ir. szám": "4721",
            "name": "Szamoskér",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.4167",
            "KSH kód": "16300",
            "Jogállás": "6",
            "Terület": "971",
            "Népesség": "426",
            "Lakások": "190"
        },
        {
            "Ir. szám": "4722",
            "name": "Nyírmeggyes",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.2667",
            "KSH kód": "23269",
            "Jogállás": "6",
            "Terület": "2879",
            "Népesség": "2599",
            "Lakások": "1045"
        },
        {
            "Ir. szám": "4731",
            "name": "Tunyogmatolcs",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.4667",
            "KSH kód": "13213",
            "Jogállás": "6",
            "Terület": "2628",
            "Népesség": "2464",
            "Lakások": "935"
        },
        {
            "Ir. szám": "4732",
            "name": "Cégénydányád",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.55",
            "KSH kód": "09681",
            "Jogállás": "6",
            "Terület": "1220",
            "Népesség": "706",
            "Lakások": "285"
        },
        {
            "Ir. szám": "4733",
            "name": "Gyügye",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.5667",
            "KSH kód": "19558",
            "Jogállás": "6",
            "Terület": "433",
            "Népesség": "240",
            "Lakások": "138"
        },
        {
            "Ir. szám": "4734",
            "name": "Szamosújlak",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.6",
            "KSH kód": "31273",
            "Jogállás": "6",
            "Terület": "527",
            "Népesség": "419",
            "Lakások": "197"
        },
        {
            "Ir. szám": "4735",
            "name": "Hermánszeg",
            "Megyekód": "SZ",
            "Lat": "47.9",
            "Long": "22.6333",
            "KSH kód": "12061",
            "Jogállás": "6",
            "Terület": "520",
            "Népesség": "248",
            "Lakások": "141"
        },
        {
            "Ir. szám": "4735",
            "name": "Szamossályi",
            "Megyekód": "SZ",
            "Lat": "47.9",
            "Long": "22.6167",
            "KSH kód": "10436",
            "Jogállás": "6",
            "Terület": "1157",
            "Népesség": "672",
            "Lakások": "343"
        },
        {
            "Ir. szám": "4737",
            "name": "Darnó",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.6667",
            "KSH kód": "18795",
            "Jogállás": "6",
            "Terület": "464",
            "Népesség": "177",
            "Lakások": "76"
        },
        {
            "Ir. szám": "4737",
            "name": "Kisnamény",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.7",
            "KSH kód": "16036",
            "Jogállás": "6",
            "Terület": "1801",
            "Népesség": "322",
            "Lakások": "155"
        },
        {
            "Ir. szám": "4741",
            "name": "Jánkmajtis",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.65",
            "KSH kód": "07843",
            "Jogállás": "6",
            "Terület": "2503",
            "Népesség": "1690",
            "Lakások": "646"
        },
        {
            "Ir. szám": "4742",
            "name": "Csegöld",
            "Megyekód": "SZ",
            "Lat": "47.8875",
            "Long": "22.6875",
            "KSH kód": "26107",
            "Jogállás": "6",
            "Terület": "1935",
            "Népesség": "602",
            "Lakások": "257"
        },
        {
            "Ir. szám": "4743",
            "name": "Csengersima",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.7333",
            "KSH kód": "24095",
            "Jogállás": "6",
            "Terület": "2380",
            "Népesség": "634",
            "Lakások": "288"
        },
        {
            "Ir. szám": "4745",
            "name": "Szamosbecs",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.7",
            "KSH kód": "22017",
            "Jogállás": "6",
            "Terület": "671",
            "Népesség": "340",
            "Lakások": "167"
        },
        {
            "Ir. szám": "4746",
            "name": "Szamostatárfalva",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.6667",
            "KSH kód": "30085",
            "Jogállás": "6",
            "Terület": "450",
            "Népesség": "312",
            "Lakások": "132"
        },
        {
            "Ir. szám": "4751",
            "name": "Kocsord",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.3833",
            "KSH kód": "07445",
            "Jogállás": "6",
            "Terület": "2547",
            "Népesség": "2883",
            "Lakások": "1025"
        },
        {
            "Ir. szám": "4752",
            "name": "Győrtelek",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.4333",
            "KSH kód": "10126",
            "Jogállás": "6",
            "Terület": "1478",
            "Népesség": "1630",
            "Lakások": "475"
        },
        {
            "Ir. szám": "4754",
            "name": "Géberjén",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.4667",
            "KSH kód": "03629",
            "Jogállás": "6",
            "Terület": "538",
            "Népesség": "546",
            "Lakások": "207"
        },
        {
            "Ir. szám": "4754",
            "name": "Fülpösdaróc",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.5",
            "KSH kód": "14377",
            "Jogállás": "6",
            "Terület": "425",
            "Népesség": "330",
            "Lakások": "117"
        },
        {
            "Ir. szám": "4755",
            "name": "Ököritófülpös",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.5167",
            "KSH kód": "31769",
            "Jogállás": "5",
            "Terület": "3342",
            "Népesség": "1800",
            "Lakások": "685"
        },
        {
            "Ir. szám": "4756",
            "name": "Rápolt",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.5667",
            "KSH kód": "09061",
            "Jogállás": "6",
            "Terület": "323",
            "Népesség": "146",
            "Lakások": "86"
        },
        {
            "Ir. szám": "4761",
            "name": "Porcsalma",
            "Megyekód": "SZ",
            "Lat": "47.8833",
            "Long": "22.5667",
            "KSH kód": "17215",
            "Jogállás": "5",
            "Terület": "3110",
            "Népesség": "2575",
            "Lakások": "1002"
        },
        {
            "Ir. szám": "4762",
            "name": "Tyukod",
            "Megyekód": "SZ",
            "Lat": "47.85",
            "Long": "22.55",
            "KSH kód": "31398",
            "Jogállás": "5",
            "Terület": "6222",
            "Népesség": "1901",
            "Lakások": "927"
        },
        {
            "Ir. szám": "4763",
            "name": "Ura",
            "Megyekód": "SZ",
            "Lat": "47.8167",
            "Long": "22.6167",
            "KSH kód": "28981",
            "Jogállás": "6",
            "Terület": "3234",
            "Népesség": "646",
            "Lakások": "280"
        },
        {
            "Ir. szám": "4764",
            "name": "Csengerújfalu",
            "Megyekód": "SZ",
            "Lat": "47.8",
            "Long": "22.6333",
            "KSH kód": "26851",
            "Jogállás": "6",
            "Terület": "2561",
            "Népesség": "820",
            "Lakások": "350"
        },
        {
            "Ir. szám": "4765",
            "name": "Komlódtótfalu",
            "Megyekód": "SZ",
            "Lat": "47.85",
            "Long": "22.7",
            "KSH kód": "22336",
            "Jogállás": "6",
            "Terület": "697",
            "Népesség": "115",
            "Lakások": "62"
        },
        {
            "Ir. szám": "4765",
            "name": "Csenger",
            "Megyekód": "SZ",
            "Lat": "47.8333",
            "Long": "22.6833",
            "KSH kód": "30641",
            "Jogállás": "4",
            "Terület": "3616",
            "Népesség": "4824",
            "Lakások": "1924"
        },
        {
            "Ir. szám": "4766",
            "name": "Pátyod",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.6167",
            "KSH kód": "23685",
            "Jogállás": "6",
            "Terület": "905",
            "Népesség": "640",
            "Lakások": "295"
        },
        {
            "Ir. szám": "4767",
            "name": "Szamosangyalos",
            "Megyekód": "SZ",
            "Lat": "47.8667",
            "Long": "22.6667",
            "KSH kód": "18005",
            "Jogállás": "6",
            "Terület": "809",
            "Népesség": "505",
            "Lakások": "206"
        },
        {
            "Ir. szám": "4800",
            "name": "Vásárosnamény",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.3167",
            "KSH kód": "18324",
            "Jogállás": "4",
            "Terület": "6566",
            "Népesség": "8917",
            "Lakások": "3319"
        },
        {
            "Ir. szám": "4801",
            "name": "Vásárosnamény",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.3167",
            "KSH kód": "18324",
            "Jogállás": "4",
            "Terület": "6566",
            "Népesség": "8917",
            "Lakások": "3319"
        },
        {
            "Ir. szám": "4803",
            "name": "Vásárosnamény",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.3167",
            "KSH kód": "18324",
            "Jogállás": "4",
            "Terület": "6566",
            "Népesség": "8917",
            "Lakások": "3319"
        },
        {
            "Ir. szám": "4804",
            "name": "Vásárosnamény",
            "Megyekód": "SZ",
            "Lat": "48.1333",
            "Long": "22.3167",
            "KSH kód": "18324",
            "Jogállás": "4",
            "Terület": "6566",
            "Népesség": "8917",
            "Lakások": "3319"
        },
        {
            "Ir. szám": "4811",
            "name": "Kisvarsány",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "22.3",
            "KSH kód": "12672",
            "Jogállás": "6",
            "Terület": "1283",
            "Népesség": "963",
            "Lakások": "383"
        },
        {
            "Ir. szám": "4812",
            "name": "Nagyvarsány",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "22.2833",
            "KSH kód": "33783",
            "Jogállás": "6",
            "Terület": "1254",
            "Népesség": "1501",
            "Lakások": "546"
        },
        {
            "Ir. szám": "4813",
            "name": "Gyüre",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "22.2833",
            "KSH kód": "33774",
            "Jogállás": "6",
            "Terület": "1587",
            "Népesség": "1259",
            "Lakások": "449"
        },
        {
            "Ir. szám": "4821",
            "name": "Ópályi",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "22.3167",
            "KSH kód": "27924",
            "Jogállás": "6",
            "Terület": "2672",
            "Népesség": "2873",
            "Lakások": "898"
        },
        {
            "Ir. szám": "4822",
            "name": "Nyírparasznya",
            "Megyekód": "SZ",
            "Lat": "48.0333",
            "Long": "22.2833",
            "KSH kód": "10807",
            "Jogállás": "6",
            "Terület": "1476",
            "Népesség": "970",
            "Lakások": "330"
        },
        {
            "Ir. szám": "4823",
            "name": "Nagydobos",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.3167",
            "KSH kód": "21485",
            "Jogállás": "6",
            "Terület": "2693",
            "Népesség": "2194",
            "Lakások": "752"
        },
        {
            "Ir. szám": "4824",
            "name": "Szamosszeg",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.3667",
            "KSH kód": "13046",
            "Jogállás": "6",
            "Terület": "3530",
            "Népesség": "1883",
            "Lakások": "734"
        },
        {
            "Ir. szám": "4826",
            "name": "Olcsva",
            "Megyekód": "SZ",
            "Lat": "48.0833",
            "Long": "22.3333",
            "KSH kód": "11129",
            "Jogállás": "6",
            "Terület": "996",
            "Népesség": "644",
            "Lakások": "259"
        },
        {
            "Ir. szám": "4831",
            "name": "Tiszaszalka",
            "Megyekód": "SZ",
            "Lat": "48.1833",
            "Long": "22.3167",
            "KSH kód": "13541",
            "Jogállás": "6",
            "Terület": "1495",
            "Népesség": "851",
            "Lakások": "369"
        },
        {
            "Ir. szám": "4832",
            "name": "Tiszavid",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.3",
            "KSH kód": "33747",
            "Jogállás": "6",
            "Terület": "1075",
            "Népesség": "491",
            "Lakások": "197"
        },
        {
            "Ir. szám": "4833",
            "name": "Tiszaadony",
            "Megyekód": "SZ",
            "Lat": "48.2333",
            "Long": "22.3",
            "KSH kód": "09423",
            "Jogállás": "6",
            "Terület": "1563",
            "Népesség": "627",
            "Lakások": "265"
        },
        {
            "Ir. szám": "4834",
            "name": "Tiszakerecseny",
            "Megyekód": "SZ",
            "Lat": "48.2667",
            "Long": "22.3",
            "KSH kód": "04446",
            "Jogállás": "6",
            "Terület": "2387",
            "Népesség": "869",
            "Lakások": "407"
        },
        {
            "Ir. szám": "4835",
            "name": "Mátyus",
            "Megyekód": "SZ",
            "Lat": "48.2833",
            "Long": "22.2833",
            "KSH kód": "20668",
            "Jogállás": "6",
            "Terület": "1103",
            "Népesség": "327",
            "Lakások": "145"
        },
        {
            "Ir. szám": "4836",
            "name": "Lónya",
            "Megyekód": "SZ",
            "Lat": "48.3167",
            "Long": "22.2833",
            "KSH kód": "26091",
            "Jogállás": "6",
            "Terület": "3549",
            "Népesség": "723",
            "Lakások": "420"
        },
        {
            "Ir. szám": "4841",
            "name": "Jánd",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "22.3833",
            "KSH kód": "17075",
            "Jogállás": "6",
            "Terület": "1654",
            "Népesség": "794",
            "Lakások": "278"
        },
        {
            "Ir. szám": "4842",
            "name": "Gulács",
            "Megyekód": "SZ",
            "Lat": "48.0833",
            "Long": "22.4667",
            "KSH kód": "29443",
            "Jogállás": "6",
            "Terület": "2335",
            "Népesség": "814",
            "Lakások": "341"
        },
        {
            "Ir. szám": "4843",
            "name": "Hetefejércse",
            "Megyekód": "SZ",
            "Lat": "48.1167",
            "Long": "22.4833",
            "KSH kód": "05616",
            "Jogállás": "6",
            "Terület": "1545",
            "Népesség": "267",
            "Lakások": "158"
        },
        {
            "Ir. szám": "4844",
            "name": "Csaroda",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "22.4667",
            "KSH kód": "29416",
            "Jogállás": "6",
            "Terület": "2468",
            "Népesség": "595",
            "Lakások": "260"
        },
        {
            "Ir. szám": "4845",
            "name": "Tákos",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "22.4333",
            "KSH kód": "29911",
            "Jogállás": "6",
            "Terület": "1082",
            "Népesség": "382",
            "Lakások": "162"
        },
        {
            "Ir. szám": "4900",
            "name": "Fehérgyarmat",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.5167",
            "KSH kód": "18971",
            "Jogállás": "4",
            "Terület": "5246",
            "Népesség": "8180",
            "Lakások": "3247"
        },
        {
            "Ir. szám": "4901",
            "name": "Fehérgyarmat",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.5167",
            "KSH kód": "18971",
            "Jogállás": "4",
            "Terület": "5246",
            "Népesség": "8180",
            "Lakások": "3247"
        },
        {
            "Ir. szám": "4911",
            "name": "Nábrád",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "22.45",
            "KSH kód": "19211",
            "Jogállás": "6",
            "Terület": "1769",
            "Népesség": "895",
            "Lakások": "365"
        },
        {
            "Ir. szám": "4912",
            "name": "Kérsemjén",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.4167",
            "KSH kód": "32869",
            "Jogállás": "6",
            "Terület": "708",
            "Népesség": "289",
            "Lakások": "123"
        },
        {
            "Ir. szám": "4913",
            "name": "Panyola",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.4",
            "KSH kód": "29559",
            "Jogállás": "6",
            "Terület": "1223",
            "Népesség": "591",
            "Lakások": "292"
        },
        {
            "Ir. szám": "4914",
            "name": "Olcsvaapáti",
            "Megyekód": "SZ",
            "Lat": "48.0833",
            "Long": "22.35",
            "KSH kód": "10834",
            "Jogállás": "6",
            "Terület": "1100",
            "Népesség": "312",
            "Lakások": "153"
        },
        {
            "Ir. szám": "4921",
            "name": "Tivadar",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "22.5167",
            "KSH kód": "20260",
            "Jogállás": "6",
            "Terület": "480",
            "Népesség": "197",
            "Lakások": "88"
        },
        {
            "Ir. szám": "4921",
            "name": "Kisar",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.5167",
            "KSH kód": "19424",
            "Jogállás": "6",
            "Terület": "1543",
            "Népesség": "1077",
            "Lakások": "374"
        },
        {
            "Ir. szám": "4922",
            "name": "Nagyar",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.5667",
            "KSH kód": "04710",
            "Jogállás": "6",
            "Terület": "1599",
            "Népesség": "687",
            "Lakások": "280"
        },
        {
            "Ir. szám": "4931",
            "name": "Tarpa",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.5333",
            "KSH kód": "04312",
            "Jogállás": "5",
            "Terület": "4973",
            "Népesség": "2141",
            "Lakások": "902"
        },
        {
            "Ir. szám": "4932",
            "name": "Márokpapi",
            "Megyekód": "SZ",
            "Lat": "48.15",
            "Long": "22.5167",
            "KSH kód": "33224",
            "Jogállás": "6",
            "Terület": "2118",
            "Népesség": "441",
            "Lakások": "222"
        },
        {
            "Ir. szám": "4933",
            "name": "Beregsurány",
            "Megyekód": "SZ",
            "Lat": "48.1667",
            "Long": "22.55",
            "KSH kód": "20677",
            "Jogállás": "6",
            "Terület": "1600",
            "Népesség": "633",
            "Lakások": "237"
        },
        {
            "Ir. szám": "4934",
            "name": "Beregdaróc",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.5333",
            "KSH kód": "28246",
            "Jogállás": "6",
            "Terület": "2382",
            "Népesség": "755",
            "Lakások": "364"
        },
        {
            "Ir. szám": "4935",
            "name": "Gelénes",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.45",
            "KSH kód": "04613",
            "Jogállás": "6",
            "Terület": "2055",
            "Népesség": "534",
            "Lakások": "243"
        },
        {
            "Ir. szám": "4936",
            "name": "Vámosatya",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "22.4167",
            "KSH kód": "27322",
            "Jogállás": "6",
            "Terület": "2429",
            "Népesség": "549",
            "Lakások": "259"
        },
        {
            "Ir. szám": "4937",
            "name": "Barabás",
            "Megyekód": "SZ",
            "Lat": "48.2333",
            "Long": "22.4333",
            "KSH kód": "26480",
            "Jogállás": "6",
            "Terület": "3819",
            "Népesség": "769",
            "Lakások": "382"
        },
        {
            "Ir. szám": "4941",
            "name": "Penyige",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.5667",
            "KSH kód": "32692",
            "Jogállás": "6",
            "Terület": "1889",
            "Népesség": "742",
            "Lakások": "289"
        },
        {
            "Ir. szám": "4942",
            "name": "Mánd",
            "Megyekód": "SZ",
            "Lat": "48",
            "Long": "22.6167",
            "KSH kód": "02088",
            "Jogállás": "6",
            "Terület": "517",
            "Népesség": "258",
            "Lakások": "107"
        },
        {
            "Ir. szám": "4942",
            "name": "Nemesborzova",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.6333",
            "KSH kód": "27119",
            "Jogállás": "6",
            "Terület": "220",
            "Népesség": "82",
            "Lakások": "45"
        },
        {
            "Ir. szám": "4943",
            "name": "Kömörő",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.5833",
            "KSH kód": "23612",
            "Jogállás": "6",
            "Terület": "1637",
            "Népesség": "532",
            "Lakások": "246"
        },
        {
            "Ir. szám": "4944",
            "name": "Túristvándi",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.65",
            "KSH kód": "13602",
            "Jogállás": "6",
            "Terület": "1522",
            "Népesség": "699",
            "Lakások": "261"
        },
        {
            "Ir. szám": "4945",
            "name": "Szatmárcseke",
            "Megyekód": "SZ",
            "Lat": "48.0833",
            "Long": "22.6333",
            "KSH kód": "31237",
            "Jogállás": "6",
            "Terület": "3631",
            "Népesség": "1429",
            "Lakások": "600"
        },
        {
            "Ir. szám": "4946",
            "name": "Tiszakóród",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.7167",
            "KSH kód": "08794",
            "Jogállás": "6",
            "Terület": "1669",
            "Népesség": "749",
            "Lakások": "321"
        },
        {
            "Ir. szám": "4947",
            "name": "Tiszacsécse",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.75",
            "KSH kód": "24448",
            "Jogállás": "6",
            "Terület": "482",
            "Népesség": "247",
            "Lakások": "118"
        },
        {
            "Ir. szám": "4948",
            "name": "Milota",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.7833",
            "KSH kód": "31750",
            "Jogállás": "6",
            "Terület": "1539",
            "Népesség": "829",
            "Lakások": "321"
        },
        {
            "Ir. szám": "4951",
            "name": "Tiszabecs",
            "Megyekód": "SZ",
            "Lat": "48.1",
            "Long": "22.8167",
            "KSH kód": "17817",
            "Jogállás": "5",
            "Terület": "1714",
            "Népesség": "998",
            "Lakások": "418"
        },
        {
            "Ir. szám": "4952",
            "name": "Uszka",
            "Megyekód": "SZ",
            "Lat": "48.0667",
            "Long": "22.8667",
            "KSH kód": "31820",
            "Jogállás": "6",
            "Terület": "736",
            "Népesség": "369",
            "Lakások": "109"
        },
        {
            "Ir. szám": "4953",
            "name": "Magosliget",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.8667",
            "KSH kód": "29984",
            "Jogállás": "6",
            "Terület": "518",
            "Népesség": "248",
            "Lakások": "92"
        },
        {
            "Ir. szám": "4954",
            "name": "Sonkád",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.75",
            "KSH kód": "23889",
            "Jogállás": "6",
            "Terület": "1981",
            "Népesség": "705",
            "Lakások": "247"
        },
        {
            "Ir. szám": "4955",
            "name": "Botpalád",
            "Megyekód": "SZ",
            "Lat": "48.0333",
            "Long": "22.8",
            "KSH kód": "22239",
            "Jogállás": "6",
            "Terület": "1650",
            "Népesség": "591",
            "Lakások": "247"
        },
        {
            "Ir. szám": "4956",
            "name": "Kispalád",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.8333",
            "KSH kód": "29300",
            "Jogállás": "6",
            "Terület": "1674",
            "Népesség": "538",
            "Lakások": "191"
        },
        {
            "Ir. szám": "4961",
            "name": "Zsarolyán",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.6",
            "KSH kód": "28750",
            "Jogállás": "6",
            "Terület": "639",
            "Népesség": "387",
            "Lakások": "154"
        },
        {
            "Ir. szám": "4962",
            "name": "Nagyszekeres",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.6167",
            "KSH kód": "27988",
            "Jogállás": "6",
            "Terület": "1052",
            "Népesség": "545",
            "Lakások": "209"
        },
        {
            "Ir. szám": "4963",
            "name": "Kisszekeres",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.6333",
            "KSH kód": "09751",
            "Jogállás": "6",
            "Terület": "1467",
            "Népesség": "555",
            "Lakások": "213"
        },
        {
            "Ir. szám": "4964",
            "name": "Fülesd",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "22.6833",
            "KSH kód": "10791",
            "Jogállás": "6",
            "Terület": "1505",
            "Népesség": "477",
            "Lakások": "176"
        },
        {
            "Ir. szám": "4965",
            "name": "Kölcse",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "22.7167",
            "KSH kód": "16665",
            "Jogállás": "5",
            "Terület": "2824",
            "Népesség": "1276",
            "Lakások": "560"
        },
        {
            "Ir. szám": "4966",
            "name": "Vámosoroszi",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.6833",
            "KSH kód": "08934",
            "Jogállás": "6",
            "Terület": "1316",
            "Népesség": "530",
            "Lakások": "181"
        },
        {
            "Ir. szám": "4967",
            "name": "Csaholc",
            "Megyekód": "SZ",
            "Lat": "47.9833",
            "Long": "22.7333",
            "KSH kód": "12928",
            "Jogállás": "6",
            "Terület": "1935",
            "Népesség": "486",
            "Lakások": "180"
        },
        {
            "Ir. szám": "4968",
            "name": "Túrricse",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.7667",
            "KSH kód": "08998",
            "Jogállás": "6",
            "Terület": "1299",
            "Népesség": "606",
            "Lakások": "233"
        },
        {
            "Ir. szám": "4969",
            "name": "Tisztaberek",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.8",
            "KSH kód": "27261",
            "Jogállás": "6",
            "Terület": "1822",
            "Népesség": "608",
            "Lakások": "213"
        },
        {
            "Ir. szám": "4971",
            "name": "Rozsály",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.8",
            "KSH kód": "17428",
            "Jogállás": "6",
            "Terület": "1487",
            "Népesség": "763",
            "Lakások": "258"
        },
        {
            "Ir. szám": "4972",
            "name": "Gacsály",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.7667",
            "KSH kód": "13727",
            "Jogállás": "6",
            "Terület": "1982",
            "Népesség": "890",
            "Lakások": "307"
        },
        {
            "Ir. szám": "4973",
            "name": "Császló",
            "Megyekód": "SZ",
            "Lat": "47.9167",
            "Long": "22.7333",
            "KSH kód": "09715",
            "Jogállás": "6",
            "Terület": "1018",
            "Népesség": "373",
            "Lakások": "152"
        },
        {
            "Ir. szám": "4974",
            "name": "Zajta",
            "Megyekód": "SZ",
            "Lat": "47.9",
            "Long": "22.8",
            "KSH kód": "06275",
            "Jogállás": "6",
            "Terület": "917",
            "Népesség": "419",
            "Lakások": "176"
        },
        {
            "Ir. szám": "4975",
            "name": "Méhtelek",
            "Megyekód": "SZ",
            "Lat": "47.9333",
            "Long": "22.85",
            "KSH kód": "29799",
            "Jogállás": "6",
            "Terület": "862",
            "Népesség": "702",
            "Lakások": "280"
        },
        {
            "Ir. szám": "4976",
            "name": "Garbolc",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "22.8667",
            "KSH kód": "04996",
            "Jogállás": "6",
            "Terület": "726",
            "Népesség": "144",
            "Lakások": "71"
        },
        {
            "Ir. szám": "4977",
            "name": "Kishódos",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.8333",
            "KSH kód": "08509",
            "Jogállás": "6",
            "Terület": "892",
            "Népesség": "96",
            "Lakások": "35"
        },
        {
            "Ir. szám": "4977",
            "name": "Nagyhódos",
            "Megyekód": "SZ",
            "Lat": "47.9667",
            "Long": "22.85",
            "KSH kód": "26976",
            "Jogállás": "6",
            "Terület": "765",
            "Népesség": "115",
            "Lakások": "83"
        },
        {
            "Ir. szám": "5000",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5001",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5002",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5003",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5004",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5005",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5006",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5007",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5008",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5009",
            "name": "Szolnok",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.2",
            "KSH kód": "27854",
            "Jogállás": "1",
            "Terület": "18724",
            "Népesség": "74885",
            "Lakások": "33758"
        },
        {
            "Ir. szám": "5051",
            "name": "Zagyvarékas",
            "Megyekód": "JN",
            "Lat": "47.2667",
            "Long": "20.1333",
            "KSH kód": "14836",
            "Jogállás": "6",
            "Terület": "3171",
            "Népesség": "3729",
            "Lakások": "1494"
        },
        {
            "Ir. szám": "5052",
            "name": "Újszász",
            "Megyekód": "JN",
            "Lat": "47.3",
            "Long": "20.0833",
            "KSH kód": "15291",
            "Jogállás": "4",
            "Terület": "5820",
            "Népesség": "6598",
            "Lakások": "2558"
        },
        {
            "Ir. szám": "5053",
            "name": "Szászberek",
            "Megyekód": "JN",
            "Lat": "47.3167",
            "Long": "20.1",
            "KSH kód": "05777",
            "Jogállás": "6",
            "Terület": "3922",
            "Népesség": "994",
            "Lakások": "415"
        },
        {
            "Ir. szám": "5054",
            "name": "Jászalsószentgyörgy",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20.1",
            "KSH kód": "30711",
            "Jogállás": "6",
            "Terület": "4767",
            "Népesség": "3640",
            "Lakások": "1575"
        },
        {
            "Ir. szám": "5055",
            "name": "Jászladány",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20.1667",
            "KSH kód": "21111",
            "Jogállás": "5",
            "Terület": "9273",
            "Népesség": "5922",
            "Lakások": "2404"
        },
        {
            "Ir. szám": "5061",
            "name": "Tiszasüly",
            "Megyekód": "JN",
            "Lat": "47.3833",
            "Long": "20.4",
            "KSH kód": "17695",
            "Jogállás": "6",
            "Terület": "9177",
            "Népesség": "1470",
            "Lakások": "788"
        },
        {
            "Ir. szám": "5062",
            "name": "Kőtelek",
            "Megyekód": "JN",
            "Lat": "47.3333",
            "Long": "20.45",
            "KSH kód": "11235",
            "Jogállás": "6",
            "Terület": "4514",
            "Népesség": "1631",
            "Lakások": "868"
        },
        {
            "Ir. szám": "5063",
            "name": "Hunyadfalva",
            "Megyekód": "JN",
            "Lat": "47.3167",
            "Long": "20.3833",
            "KSH kód": "34050",
            "Jogállás": "6",
            "Terület": "535",
            "Népesség": "191",
            "Lakások": "106"
        },
        {
            "Ir. szám": "5064",
            "name": "Csataszög",
            "Megyekód": "JN",
            "Lat": "47.2833",
            "Long": "20.4",
            "KSH kód": "34175",
            "Jogállás": "6",
            "Terület": "1121",
            "Népesség": "315",
            "Lakások": "132"
        },
        {
            "Ir. szám": "5065",
            "name": "Nagykörű",
            "Megyekód": "JN",
            "Lat": "47.2667",
            "Long": "20.45",
            "KSH kód": "15574",
            "Jogállás": "6",
            "Terület": "4281",
            "Népesség": "1636",
            "Lakások": "925"
        },
        {
            "Ir. szám": "5071",
            "name": "Besenyszög",
            "Megyekód": "JN",
            "Lat": "47.3",
            "Long": "20.2667",
            "KSH kód": "11305",
            "Jogállás": "6",
            "Terület": "13808",
            "Népesség": "3452",
            "Lakások": "1426"
        },
        {
            "Ir. szám": "5081",
            "name": "Szajol",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.3",
            "KSH kód": "05874",
            "Jogállás": "6",
            "Terület": "3697",
            "Népesség": "3942",
            "Lakások": "1554"
        },
        {
            "Ir. szám": "5082",
            "name": "Tiszatenyő",
            "Megyekód": "JN",
            "Lat": "47.1333",
            "Long": "20.3833",
            "KSH kód": "09627",
            "Jogállás": "6",
            "Terület": "2355",
            "Népesség": "1705",
            "Lakások": "666"
        },
        {
            "Ir. szám": "5083",
            "name": "Kengyel",
            "Megyekód": "JN",
            "Lat": "47.0833",
            "Long": "20.3333",
            "KSH kód": "07418",
            "Jogállás": "6",
            "Terület": "7914",
            "Népesség": "4037",
            "Lakások": "1413"
        },
        {
            "Ir. szám": "5084",
            "name": "Rákócziújfalu",
            "Megyekód": "JN",
            "Lat": "47.0667",
            "Long": "20.2667",
            "KSH kód": "12423",
            "Jogállás": "6",
            "Terület": "1961",
            "Népesség": "2039",
            "Lakások": "722"
        },
        {
            "Ir. szám": "5085",
            "name": "Rákóczifalva",
            "Megyekód": "JN",
            "Lat": "47.0833",
            "Long": "20.2333",
            "KSH kód": "14207",
            "Jogállás": "5",
            "Terület": "3594",
            "Népesség": "5440",
            "Lakások": "2175"
        },
        {
            "Ir. szám": "5091",
            "name": "Tószeg",
            "Megyekód": "JN",
            "Lat": "47.1",
            "Long": "20.15",
            "KSH kód": "07490",
            "Jogállás": "6",
            "Terület": "5917",
            "Népesség": "4602",
            "Lakások": "1791"
        },
        {
            "Ir. szám": "5092",
            "name": "Tiszavárkony",
            "Megyekód": "JN",
            "Lat": "47.0667",
            "Long": "20.1833",
            "KSH kód": "31866",
            "Jogállás": "6",
            "Terület": "3562",
            "Népesség": "1598",
            "Lakások": "693"
        },
        {
            "Ir. szám": "5093",
            "name": "Vezseny",
            "Megyekód": "JN",
            "Lat": "47.0333",
            "Long": "20.2167",
            "KSH kód": "21157",
            "Jogállás": "6",
            "Terület": "2517",
            "Népesség": "690",
            "Lakások": "320"
        },
        {
            "Ir. szám": "5094",
            "name": "Tiszajenő",
            "Megyekód": "JN",
            "Lat": "47.0333",
            "Long": "20.15",
            "KSH kód": "29346",
            "Jogállás": "6",
            "Terület": "2819",
            "Népesség": "1656",
            "Lakások": "791"
        },
        {
            "Ir. szám": "5095",
            "name": "Tiszajenő",
            "Megyekód": "JN",
            "Lat": "47.0333",
            "Long": "20.15",
            "KSH kód": "29346",
            "Jogállás": "6",
            "Terület": "2819",
            "Népesség": "1656",
            "Lakások": "791"
        },
        {
            "Ir. szám": "5100",
            "name": "Jászberény",
            "Megyekód": "JN",
            "Lat": "47.5",
            "Long": "19.9167",
            "KSH kód": "18209",
            "Jogállás": "4",
            "Terület": "22135",
            "Népesség": "27134",
            "Lakások": "11771"
        },
        {
            "Ir. szám": "5101",
            "name": "Jászberény",
            "Megyekód": "JN",
            "Lat": "47.5",
            "Long": "19.9167",
            "KSH kód": "18209",
            "Jogállás": "4",
            "Terület": "22135",
            "Népesség": "27134",
            "Lakások": "11771"
        },
        {
            "Ir. szám": "5102",
            "name": "Jászberény",
            "Megyekód": "JN",
            "Lat": "47.5",
            "Long": "19.9167",
            "KSH kód": "18209",
            "Jogállás": "4",
            "Terület": "22135",
            "Népesség": "27134",
            "Lakások": "11771"
        },
        {
            "Ir. szám": "5111",
            "name": "Jászfelsőszentgyörgy",
            "Megyekód": "JN",
            "Lat": "47.5",
            "Long": "19.8",
            "KSH kód": "23579",
            "Jogállás": "6",
            "Terület": "3928",
            "Népesség": "1925",
            "Lakások": "784"
        },
        {
            "Ir. szám": "5121",
            "name": "Jászjákóhalma",
            "Megyekód": "JN",
            "Lat": "47.5167",
            "Long": "20",
            "KSH kód": "25186",
            "Jogállás": "6",
            "Terület": "4504",
            "Népesség": "3105",
            "Lakások": "1375"
        },
        {
            "Ir. szám": "5122",
            "name": "Jászdózsa",
            "Megyekód": "JN",
            "Lat": "47.5667",
            "Long": "20.0167",
            "KSH kód": "17978",
            "Jogállás": "6",
            "Terület": "4286",
            "Népesség": "2174",
            "Lakások": "1143"
        },
        {
            "Ir. szám": "5123",
            "name": "Jászárokszállás",
            "Megyekód": "JN",
            "Lat": "47.6333",
            "Long": "19.9833",
            "KSH kód": "22105",
            "Jogállás": "4",
            "Terület": "7717",
            "Népesség": "8041",
            "Lakások": "3734"
        },
        {
            "Ir. szám": "5124",
            "name": "Jászágó",
            "Megyekód": "JN",
            "Lat": "47.6",
            "Long": "19.8667",
            "KSH kód": "22929",
            "Jogállás": "6",
            "Terület": "3693",
            "Népesség": "738",
            "Lakások": "421"
        },
        {
            "Ir. szám": "5125",
            "name": "Pusztamonostor",
            "Megyekód": "JN",
            "Lat": "47.55",
            "Long": "19.8",
            "KSH kód": "15246",
            "Jogállás": "6",
            "Terület": "2462",
            "Népesség": "1615",
            "Lakások": "738"
        },
        {
            "Ir. szám": "5126",
            "name": "Jászfényszaru",
            "Megyekód": "JN",
            "Lat": "47.5667",
            "Long": "19.7167",
            "KSH kód": "23339",
            "Jogállás": "4",
            "Terület": "7616",
            "Népesség": "5755",
            "Lakások": "2613"
        },
        {
            "Ir. szám": "5130",
            "name": "Jászapáti",
            "Megyekód": "JN",
            "Lat": "47.5167",
            "Long": "20.15",
            "KSH kód": "22202",
            "Jogállás": "4",
            "Terület": "7816",
            "Népesség": "9217",
            "Lakások": "3666"
        },
        {
            "Ir. szám": "5131",
            "name": "Jászapáti",
            "Megyekód": "JN",
            "Lat": "47.5167",
            "Long": "20.15",
            "KSH kód": "22202",
            "Jogállás": "4",
            "Terület": "7816",
            "Népesség": "9217",
            "Lakások": "3666"
        },
        {
            "Ir. szám": "5135",
            "name": "Jászivány",
            "Megyekód": "JN",
            "Lat": "47.5333",
            "Long": "20.25",
            "KSH kód": "24086",
            "Jogállás": "6",
            "Terület": "3951",
            "Népesség": "392",
            "Lakások": "212"
        },
        {
            "Ir. szám": "5136",
            "name": "Jászszentandrás",
            "Megyekód": "JN",
            "Lat": "47.5833",
            "Long": "20.1833",
            "KSH kód": "13514",
            "Jogállás": "6",
            "Terület": "4433",
            "Népesség": "2479",
            "Lakások": "1567"
        },
        {
            "Ir. szám": "5137",
            "name": "Jászkisér",
            "Megyekód": "JN",
            "Lat": "47.45",
            "Long": "20.2167",
            "KSH kód": "22798",
            "Jogállás": "5",
            "Terület": "13011",
            "Népesség": "5427",
            "Lakások": "2251"
        },
        {
            "Ir. szám": "5141",
            "name": "Jásztelek",
            "Megyekód": "JN",
            "Lat": "47.4833",
            "Long": "20",
            "KSH kód": "23135",
            "Jogállás": "6",
            "Terület": "4115",
            "Népesség": "1656",
            "Lakások": "735"
        },
        {
            "Ir. szám": "5142",
            "name": "Alattyán",
            "Megyekód": "JN",
            "Lat": "47.4333",
            "Long": "20.05",
            "KSH kód": "25265",
            "Jogállás": "6",
            "Terület": "3429",
            "Népesség": "2145",
            "Lakások": "917"
        },
        {
            "Ir. szám": "5143",
            "name": "Jánoshida",
            "Megyekód": "JN",
            "Lat": "47.3833",
            "Long": "20.0667",
            "KSH kód": "22859",
            "Jogállás": "6",
            "Terület": "3479",
            "Népesség": "2585",
            "Lakások": "1157"
        },
        {
            "Ir. szám": "5144",
            "name": "Jászboldogháza",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20",
            "KSH kód": "15811",
            "Jogállás": "6",
            "Terület": "5531",
            "Népesség": "1668",
            "Lakások": "821"
        },
        {
            "Ir. szám": "5152",
            "name": "Jászboldogháza",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20",
            "KSH kód": "15811",
            "Jogállás": "6",
            "Terület": "5531",
            "Népesség": "1668",
            "Lakások": "821"
        },
        {
            "Ir. szám": "5200",
            "name": "Törökszentmiklós",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.4167",
            "KSH kód": "27313",
            "Jogállás": "4",
            "Terület": "18516",
            "Népesség": "21758",
            "Lakások": "8989"
        },
        {
            "Ir. szám": "5201",
            "name": "Törökszentmiklós",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.4167",
            "KSH kód": "27313",
            "Jogállás": "4",
            "Terület": "18516",
            "Népesség": "21758",
            "Lakások": "8989"
        },
        {
            "Ir. szám": "5211",
            "name": "Tiszapüspöki",
            "Megyekód": "JN",
            "Lat": "47.2167",
            "Long": "20.3167",
            "KSH kód": "03373",
            "Jogállás": "6",
            "Terület": "3745",
            "Népesség": "2099",
            "Lakások": "836"
        },
        {
            "Ir. szám": "5212",
            "name": "Tiszapüspöki",
            "Megyekód": "JN",
            "Lat": "47.2167",
            "Long": "20.3167",
            "KSH kód": "03373",
            "Jogállás": "6",
            "Terület": "3745",
            "Népesség": "2099",
            "Lakások": "836"
        },
        {
            "Ir. szám": "5213",
            "name": "Tiszapüspöki",
            "Megyekód": "JN",
            "Lat": "47.2167",
            "Long": "20.3167",
            "KSH kód": "03373",
            "Jogállás": "6",
            "Terület": "3745",
            "Népesség": "2099",
            "Lakások": "836"
        },
        {
            "Ir. szám": "5222",
            "name": "Örményes",
            "Megyekód": "JN",
            "Lat": "47.2",
            "Long": "20.5833",
            "KSH kód": "29382",
            "Jogállás": "6",
            "Terület": "3413",
            "Népesség": "1105",
            "Lakások": "460"
        },
        {
            "Ir. szám": "5231",
            "name": "Fegyvernek",
            "Megyekód": "JN",
            "Lat": "47.2667",
            "Long": "20.5333",
            "KSH kód": "16647",
            "Jogállás": "5",
            "Terület": "7148",
            "Népesség": "6629",
            "Lakások": "2680"
        },
        {
            "Ir. szám": "5232",
            "name": "Tiszabő",
            "Megyekód": "JN",
            "Lat": "47.3",
            "Long": "20.4833",
            "KSH kód": "10773",
            "Jogállás": "6",
            "Terület": "3504",
            "Népesség": "1936",
            "Lakások": "572"
        },
        {
            "Ir. szám": "5233",
            "name": "Tiszagyenda",
            "Megyekód": "JN",
            "Lat": "47.3833",
            "Long": "20.5167",
            "KSH kód": "30304",
            "Jogállás": "6",
            "Terület": "3693",
            "Népesség": "1001",
            "Lakások": "542"
        },
        {
            "Ir. szám": "5234",
            "name": "Tiszaroff",
            "Megyekód": "JN",
            "Lat": "47.4",
            "Long": "20.45",
            "KSH kód": "20181",
            "Jogállás": "6",
            "Terület": "5249",
            "Népesség": "1674",
            "Lakások": "977"
        },
        {
            "Ir. szám": "5235",
            "name": "Tiszabura",
            "Megyekód": "JN",
            "Lat": "47.45",
            "Long": "20.4667",
            "KSH kód": "22770",
            "Jogállás": "6",
            "Terület": "4520",
            "Népesség": "2801",
            "Lakások": "833"
        },
        {
            "Ir. szám": "5241",
            "name": "Abádszalók",
            "Megyekód": "JN",
            "Lat": "47.4667",
            "Long": "20.6",
            "KSH kód": "12441",
            "Jogállás": "4",
            "Terület": "13223",
            "Népesség": "4276",
            "Lakások": "2101"
        },
        {
            "Ir. szám": "5243",
            "name": "Tiszaderzs",
            "Megyekód": "JN",
            "Lat": "47.5167",
            "Long": "20.65",
            "KSH kód": "16230",
            "Jogállás": "6",
            "Terület": "2719",
            "Népesség": "1101",
            "Lakások": "644"
        },
        {
            "Ir. szám": "5244",
            "name": "Tiszaszőlős",
            "Megyekód": "JN",
            "Lat": "47.6167",
            "Long": "20.7667",
            "KSH kód": "07852",
            "Jogállás": "6",
            "Terület": "4779",
            "Népesség": "1949",
            "Lakások": "742"
        },
        {
            "Ir. szám": "5300",
            "name": "Karcag",
            "Megyekód": "JN",
            "Lat": "47.3167",
            "Long": "20.9333",
            "KSH kód": "04923",
            "Jogállás": "4",
            "Terület": "36863",
            "Népesség": "20872",
            "Lakások": "8219"
        },
        {
            "Ir. szám": "5301",
            "name": "Karcag",
            "Megyekód": "JN",
            "Lat": "47.3167",
            "Long": "20.9333",
            "KSH kód": "04923",
            "Jogállás": "4",
            "Terület": "36863",
            "Népesség": "20872",
            "Lakások": "8219"
        },
        {
            "Ir. szám": "5302",
            "name": "Karcag",
            "Megyekód": "JN",
            "Lat": "47.3167",
            "Long": "20.9333",
            "KSH kód": "04923",
            "Jogállás": "4",
            "Terület": "36863",
            "Népesség": "20872",
            "Lakások": "8219"
        },
        {
            "Ir. szám": "5309",
            "name": "Berekfürdő",
            "Megyekód": "JN",
            "Lat": "47.3833",
            "Long": "20.85",
            "KSH kód": "34005",
            "Jogállás": "6",
            "Terület": "1857",
            "Népesség": "956",
            "Lakások": "474"
        },
        {
            "Ir. szám": "5310",
            "name": "Kisújszállás",
            "Megyekód": "JN",
            "Lat": "47.2167",
            "Long": "20.7667",
            "KSH kód": "25919",
            "Jogállás": "4",
            "Terület": "20527",
            "Népesség": "11738",
            "Lakások": "4767"
        },
        {
            "Ir. szám": "5311",
            "name": "Kisújszállás",
            "Megyekód": "JN",
            "Lat": "47.2167",
            "Long": "20.7667",
            "KSH kód": "25919",
            "Jogállás": "4",
            "Terület": "20527",
            "Népesség": "11738",
            "Lakások": "4767"
        },
        {
            "Ir. szám": "5321",
            "name": "Kunmadaras",
            "Megyekód": "JN",
            "Lat": "47.4333",
            "Long": "20.8",
            "KSH kód": "23171",
            "Jogállás": "5",
            "Terület": "15355",
            "Népesség": "5507",
            "Lakások": "2083"
        },
        {
            "Ir. szám": "5322",
            "name": "Tiszaszentimre",
            "Megyekód": "JN",
            "Lat": "47.4833",
            "Long": "20.7333",
            "KSH kód": "22789",
            "Jogállás": "6",
            "Terület": "6561",
            "Népesség": "2140",
            "Lakások": "1042"
        },
        {
            "Ir. szám": "5323",
            "name": "Tiszaszentimre",
            "Megyekód": "JN",
            "Lat": "47.4833",
            "Long": "20.7333",
            "KSH kód": "22789",
            "Jogállás": "6",
            "Terület": "6561",
            "Népesség": "2140",
            "Lakások": "1042"
        },
        {
            "Ir. szám": "5324",
            "name": "Tomajmonostora",
            "Megyekód": "JN",
            "Lat": "47.4333",
            "Long": "20.7167",
            "KSH kód": "09557",
            "Jogállás": "6",
            "Terület": "1376",
            "Népesség": "680",
            "Lakások": "360"
        },
        {
            "Ir. szám": "5331",
            "name": "Kenderes",
            "Megyekód": "JN",
            "Lat": "47.25",
            "Long": "20.6833",
            "KSH kód": "17145",
            "Jogállás": "4",
            "Terület": "11124",
            "Népesség": "4917",
            "Lakások": "2013"
        },
        {
            "Ir. szám": "5340",
            "name": "Kunhegyes",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20.6333",
            "KSH kód": "22567",
            "Jogállás": "4",
            "Terület": "14894",
            "Népesség": "7866",
            "Lakások": "3388"
        },
        {
            "Ir. szám": "5341",
            "name": "Kunhegyes",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20.6333",
            "KSH kód": "22567",
            "Jogállás": "4",
            "Terület": "14894",
            "Népesség": "7866",
            "Lakások": "3388"
        },
        {
            "Ir. szám": "5349",
            "name": "Kunhegyes",
            "Megyekód": "JN",
            "Lat": "47.3667",
            "Long": "20.6333",
            "KSH kód": "22567",
            "Jogállás": "4",
            "Terület": "14894",
            "Népesség": "7866",
            "Lakások": "3388"
        },
        {
            "Ir. szám": "5350",
            "name": "Tiszafüred",
            "Megyekód": "JN",
            "Lat": "47.6167",
            "Long": "20.7667",
            "KSH kód": "29726",
            "Jogállás": "4",
            "Terület": "16218",
            "Népesség": "11136",
            "Lakások": "5277"
        },
        {
            "Ir. szám": "5351",
            "name": "Tiszafüred",
            "Megyekód": "JN",
            "Lat": "47.6167",
            "Long": "20.7667",
            "KSH kód": "29726",
            "Jogállás": "4",
            "Terület": "16218",
            "Népesség": "11136",
            "Lakások": "5277"
        },
        {
            "Ir. szám": "5358",
            "name": "Tiszafüred",
            "Megyekód": "JN",
            "Lat": "47.6167",
            "Long": "20.7667",
            "KSH kód": "29726",
            "Jogállás": "4",
            "Terület": "16218",
            "Népesség": "11136",
            "Lakások": "5277"
        },
        {
            "Ir. szám": "5359",
            "name": "Tiszafüred",
            "Megyekód": "JN",
            "Lat": "47.6167",
            "Long": "20.7667",
            "KSH kód": "29726",
            "Jogállás": "4",
            "Terület": "16218",
            "Népesség": "11136",
            "Lakások": "5277"
        },
        {
            "Ir. szám": "5361",
            "name": "Tiszaigar",
            "Megyekód": "JN",
            "Lat": "47.5333",
            "Long": "20.8",
            "KSH kód": "28699",
            "Jogállás": "6",
            "Terület": "3402",
            "Népesség": "817",
            "Lakások": "409"
        },
        {
            "Ir. szám": "5362",
            "name": "Tiszaörs",
            "Megyekód": "JN",
            "Lat": "47.5167",
            "Long": "20.8333",
            "KSH kód": "15787",
            "Jogállás": "6",
            "Terület": "3710",
            "Népesség": "1291",
            "Lakások": "672"
        },
        {
            "Ir. szám": "5363",
            "name": "Nagyiván",
            "Megyekód": "JN",
            "Lat": "47.4833",
            "Long": "20.9333",
            "KSH kód": "21689",
            "Jogállás": "6",
            "Terület": "4316",
            "Népesség": "1168",
            "Lakások": "517"
        },
        {
            "Ir. szám": "5400",
            "name": "Mezőtúr",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.6333",
            "KSH kód": "04260",
            "Jogállás": "4",
            "Terület": "28972",
            "Népesség": "18176",
            "Lakások": "7759"
        },
        {
            "Ir. szám": "5401",
            "name": "Mezőtúr",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.6333",
            "KSH kód": "04260",
            "Jogállás": "4",
            "Terület": "28972",
            "Népesség": "18176",
            "Lakások": "7759"
        },
        {
            "Ir. szám": "5403",
            "name": "Mezőtúr",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.6333",
            "KSH kód": "04260",
            "Jogállás": "4",
            "Terület": "28972",
            "Népesség": "18176",
            "Lakások": "7759"
        },
        {
            "Ir. szám": "5404",
            "name": "Mezőtúr",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.6333",
            "KSH kód": "04260",
            "Jogállás": "4",
            "Terület": "28972",
            "Népesség": "18176",
            "Lakások": "7759"
        },
        {
            "Ir. szám": "5411",
            "name": "Kétpó",
            "Megyekód": "JN",
            "Lat": "47.0833",
            "Long": "20.4667",
            "KSH kód": "19813",
            "Jogállás": "6",
            "Terület": "6676",
            "Népesség": "723",
            "Lakások": "324"
        },
        {
            "Ir. szám": "5412",
            "name": "Kuncsorba",
            "Megyekód": "JN",
            "Lat": "47.1333",
            "Long": "20.5667",
            "KSH kód": "05254",
            "Jogállás": "6",
            "Terület": "3363",
            "Népesség": "637",
            "Lakások": "305"
        },
        {
            "Ir. szám": "5420",
            "name": "Túrkeve",
            "Megyekód": "JN",
            "Lat": "47.1",
            "Long": "20.75",
            "KSH kód": "28228",
            "Jogállás": "4",
            "Terület": "23652",
            "Népesség": "9215",
            "Lakások": "3995"
        },
        {
            "Ir. szám": "5421",
            "name": "Túrkeve",
            "Megyekód": "JN",
            "Lat": "47.1",
            "Long": "20.75",
            "KSH kód": "28228",
            "Jogállás": "4",
            "Terület": "23652",
            "Népesség": "9215",
            "Lakások": "3995"
        },
        {
            "Ir. szám": "5430",
            "name": "Tiszaföldvár",
            "Megyekód": "JN",
            "Lat": "46.9833",
            "Long": "20.25",
            "KSH kód": "13815",
            "Jogállás": "4",
            "Terület": "8034",
            "Népesség": "11548",
            "Lakások": "4724"
        },
        {
            "Ir. szám": "5431",
            "name": "Tiszaföldvár",
            "Megyekód": "JN",
            "Lat": "46.9833",
            "Long": "20.25",
            "KSH kód": "13815",
            "Jogállás": "4",
            "Terület": "8034",
            "Népesség": "11548",
            "Lakások": "4724"
        },
        {
            "Ir. szám": "5432",
            "name": "Tiszaföldvár",
            "Megyekód": "JN",
            "Lat": "46.9833",
            "Long": "20.25",
            "KSH kód": "13815",
            "Jogállás": "4",
            "Terület": "8034",
            "Népesség": "11548",
            "Lakások": "4724"
        },
        {
            "Ir. szám": "5435",
            "name": "Martfű",
            "Megyekód": "JN",
            "Lat": "47.0167",
            "Long": "20.2833",
            "KSH kód": "02626",
            "Jogállás": "4",
            "Terület": "2308",
            "Népesség": "6715",
            "Lakások": "2919"
        },
        {
            "Ir. szám": "5440",
            "name": "Kunszentmárton",
            "Megyekód": "JN",
            "Lat": "46.8333",
            "Long": "20.2833",
            "KSH kód": "32504",
            "Jogállás": "4",
            "Terület": "14365",
            "Népesség": "8850",
            "Lakások": "3969"
        },
        {
            "Ir. szám": "5441",
            "name": "Kunszentmárton",
            "Megyekód": "JN",
            "Lat": "46.8333",
            "Long": "20.2833",
            "KSH kód": "32504",
            "Jogállás": "4",
            "Terület": "14365",
            "Népesség": "8850",
            "Lakások": "3969"
        },
        {
            "Ir. szám": "5449",
            "name": "Kunszentmárton",
            "Megyekód": "JN",
            "Lat": "46.8333",
            "Long": "20.2833",
            "KSH kód": "32504",
            "Jogállás": "4",
            "Terület": "14365",
            "Népesség": "8850",
            "Lakások": "3969"
        },
        {
            "Ir. szám": "5451",
            "name": "Öcsöd",
            "Megyekód": "JN",
            "Lat": "46.9",
            "Long": "20.4",
            "KSH kód": "28006",
            "Jogállás": "5",
            "Terület": "10366",
            "Népesség": "3390",
            "Lakások": "1754"
        },
        {
            "Ir. szám": "5452",
            "name": "Mesterszállás",
            "Megyekód": "JN",
            "Lat": "46.95",
            "Long": "20.4333",
            "KSH kód": "30234",
            "Jogállás": "6",
            "Terület": "4292",
            "Népesség": "711",
            "Lakások": "393"
        },
        {
            "Ir. szám": "5453",
            "name": "Mezőhék",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.4",
            "KSH kód": "26286",
            "Jogállás": "6",
            "Terület": "8982",
            "Népesség": "327",
            "Lakások": "203"
        },
        {
            "Ir. szám": "5461",
            "name": "Mezőhék",
            "Megyekód": "JN",
            "Lat": "47",
            "Long": "20.4",
            "KSH kód": "26286",
            "Jogállás": "6",
            "Terület": "8982",
            "Népesség": "327",
            "Lakások": "203"
        },
        {
            "Ir. szám": "5462",
            "name": "Cibakháza",
            "Megyekód": "JN",
            "Lat": "46.9667",
            "Long": "20.2",
            "KSH kód": "22938",
            "Jogállás": "5",
            "Terület": "3821",
            "Népesség": "4439",
            "Lakások": "1924"
        },
        {
            "Ir. szám": "5463",
            "name": "Nagyrév",
            "Megyekód": "JN",
            "Lat": "46.95",
            "Long": "20.15",
            "KSH kód": "06318",
            "Jogállás": "6",
            "Terület": "2979",
            "Népesség": "726",
            "Lakások": "432"
        },
        {
            "Ir. szám": "5464",
            "name": "Tiszainoka",
            "Megyekód": "JN",
            "Lat": "46.9",
            "Long": "20.15",
            "KSH kód": "20446",
            "Jogállás": "6",
            "Terület": "1792",
            "Népesség": "406",
            "Lakások": "220"
        },
        {
            "Ir. szám": "5465",
            "name": "Cserkeszőlő",
            "Megyekód": "JN",
            "Lat": "46.8667",
            "Long": "20.1833",
            "KSH kód": "05795",
            "Jogállás": "6",
            "Terület": "3070",
            "Népesség": "2119",
            "Lakások": "1385"
        },
        {
            "Ir. szám": "5471",
            "name": "Tiszakürt",
            "Megyekód": "JN",
            "Lat": "46.8833",
            "Long": "20.1167",
            "KSH kód": "30386",
            "Jogállás": "6",
            "Terület": "2837",
            "Népesség": "1463",
            "Lakások": "783"
        },
        {
            "Ir. szám": "5473",
            "name": "Tiszaug",
            "Megyekód": "JN",
            "Lat": "46.8333",
            "Long": "20.1542",
            "KSH kód": "14094",
            "Jogállás": "6",
            "Terület": "2504",
            "Népesség": "908",
            "Lakások": "432"
        },
        {
            "Ir. szám": "5474",
            "name": "Tiszasas",
            "Megyekód": "JN",
            "Lat": "46.8167",
            "Long": "20.0833",
            "KSH kód": "21494",
            "Jogállás": "6",
            "Terület": "2879",
            "Népesség": "1061",
            "Lakások": "586"
        },
        {
            "Ir. szám": "5475",
            "name": "Csépa",
            "Megyekód": "JN",
            "Lat": "46.8",
            "Long": "20.1333",
            "KSH kód": "13170",
            "Jogállás": "6",
            "Terület": "2967",
            "Népesség": "1667",
            "Lakások": "902"
        },
        {
            "Ir. szám": "5476",
            "name": "Szelevény",
            "Megyekód": "JN",
            "Lat": "46.8333",
            "Long": "20.2833",
            "KSH kód": "20428",
            "Jogállás": "6",
            "Terület": "4539",
            "Népesség": "1073",
            "Lakások": "588"
        },
        {
            "Ir. szám": "5500",
            "name": "Gyomaendrőd",
            "Megyekód": "BE",
            "Lat": "46.9333",
            "Long": "20.8333",
            "KSH kód": "33455",
            "Jogállás": "4",
            "Terület": "30394",
            "Népesség": "14375",
            "Lakások": "6479"
        },
        {
            "Ir. szám": "5501",
            "name": "Gyomaendrőd",
            "Megyekód": "BE",
            "Lat": "46.9333",
            "Long": "20.8333",
            "KSH kód": "33455",
            "Jogállás": "4",
            "Terület": "30394",
            "Népesség": "14375",
            "Lakások": "6479"
        },
        {
            "Ir. szám": "5502",
            "name": "Gyomaendrőd",
            "Megyekód": "BE",
            "Lat": "46.9333",
            "Long": "20.8333",
            "KSH kód": "33455",
            "Jogállás": "4",
            "Terület": "30394",
            "Népesség": "14375",
            "Lakások": "6479"
        },
        {
            "Ir. szám": "5510",
            "name": "Dévaványa",
            "Megyekód": "BE",
            "Lat": "47.0333",
            "Long": "20.9667",
            "KSH kód": "24819",
            "Jogállás": "4",
            "Terület": "21655",
            "Népesség": "8067",
            "Lakások": "3287"
        },
        {
            "Ir. szám": "5515",
            "name": "Ecsegfalva",
            "Megyekód": "BE",
            "Lat": "47.15",
            "Long": "20.9167",
            "KSH kód": "09432",
            "Jogállás": "6",
            "Terület": "7899",
            "Népesség": "1232",
            "Lakások": "555"
        },
        {
            "Ir. szám": "5516",
            "name": "Körösladány",
            "Megyekód": "BE",
            "Lat": "46.9667",
            "Long": "21.0833",
            "KSH kód": "11615",
            "Jogállás": "4",
            "Terület": "12379",
            "Népesség": "4788",
            "Lakások": "2034"
        },
        {
            "Ir. szám": "5520",
            "name": "Szeghalom",
            "Megyekód": "BE",
            "Lat": "47.0333",
            "Long": "21.1667",
            "KSH kód": "21883",
            "Jogállás": "4",
            "Terület": "21713",
            "Népesség": "9314",
            "Lakások": "3899"
        },
        {
            "Ir. szám": "5521",
            "name": "Szeghalom",
            "Megyekód": "BE",
            "Lat": "47.0333",
            "Long": "21.1667",
            "KSH kód": "21883",
            "Jogállás": "4",
            "Terület": "21713",
            "Népesség": "9314",
            "Lakások": "3899"
        },
        {
            "Ir. szám": "5525",
            "name": "Füzesgyarmat",
            "Megyekód": "BE",
            "Lat": "47.1",
            "Long": "21.2167",
            "KSH kód": "12256",
            "Jogállás": "4",
            "Terület": "12734",
            "Népesség": "5981",
            "Lakások": "2414"
        },
        {
            "Ir. szám": "5526",
            "name": "Kertészsziget",
            "Megyekód": "BE",
            "Lat": "47.15",
            "Long": "21.0667",
            "KSH kód": "12618",
            "Jogállás": "6",
            "Terület": "3911",
            "Népesség": "401",
            "Lakások": "189"
        },
        {
            "Ir. szám": "5527",
            "name": "Bucsa",
            "Megyekód": "BE",
            "Lat": "47.2",
            "Long": "21",
            "KSH kód": "13471",
            "Jogállás": "6",
            "Terület": "5582",
            "Népesség": "2251",
            "Lakások": "993"
        },
        {
            "Ir. szám": "5530",
            "name": "Vésztő",
            "Megyekód": "BE",
            "Lat": "46.9167",
            "Long": "21.2667",
            "KSH kód": "29531",
            "Jogállás": "4",
            "Terület": "12570",
            "Népesség": "7094",
            "Lakások": "3089"
        },
        {
            "Ir. szám": "5534",
            "name": "Okány",
            "Megyekód": "BE",
            "Lat": "46.9",
            "Long": "21.35",
            "KSH kód": "19257",
            "Jogállás": "6",
            "Terület": "7062",
            "Népesség": "2688",
            "Lakások": "1267"
        },
        {
            "Ir. szám": "5536",
            "name": "Körösújfalu",
            "Megyekód": "BE",
            "Lat": "46.9667",
            "Long": "21.4",
            "KSH kód": "30164",
            "Jogállás": "6",
            "Terület": "2530",
            "Népesség": "610",
            "Lakások": "281"
        },
        {
            "Ir. szám": "5537",
            "name": "Zsadány",
            "Megyekód": "BE",
            "Lat": "46.9167",
            "Long": "21.5",
            "KSH kód": "06257",
            "Jogállás": "6",
            "Terület": "6584",
            "Népesség": "1649",
            "Lakások": "768"
        },
        {
            "Ir. szám": "5538",
            "name": "Biharugra",
            "Megyekód": "BE",
            "Lat": "46.9667",
            "Long": "21.6",
            "KSH kód": "29610",
            "Jogállás": "6",
            "Terület": "5284",
            "Népesség": "899",
            "Lakások": "579"
        },
        {
            "Ir. szám": "5539",
            "name": "Körösnagyharsány",
            "Megyekód": "BE",
            "Lat": "47",
            "Long": "21.65",
            "KSH kód": "10764",
            "Jogállás": "6",
            "Terület": "1991",
            "Népesség": "560",
            "Lakások": "351"
        },
        {
            "Ir. szám": "5540",
            "name": "Szarvas",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "20.55",
            "KSH kód": "23870",
            "Jogállás": "4",
            "Terület": "16157",
            "Népesség": "17557",
            "Lakások": "7531"
        },
        {
            "Ir. szám": "5541",
            "name": "Szarvas",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "20.55",
            "KSH kód": "23870",
            "Jogállás": "4",
            "Terület": "16157",
            "Népesség": "17557",
            "Lakások": "7531"
        },
        {
            "Ir. szám": "5551",
            "name": "Csabacsűd",
            "Megyekód": "BE",
            "Lat": "46.8333",
            "Long": "20.65",
            "KSH kód": "31334",
            "Jogállás": "5",
            "Terület": "6685",
            "Népesség": "1879",
            "Lakások": "857"
        },
        {
            "Ir. szám": "5552",
            "name": "Kardos",
            "Megyekód": "BE",
            "Lat": "46.8167",
            "Long": "20.7708",
            "KSH kód": "24794",
            "Jogállás": "6",
            "Terület": "4279",
            "Népesség": "654",
            "Lakások": "389"
        },
        {
            "Ir. szám": "5553",
            "name": "Kondoros",
            "Megyekód": "BE",
            "Lat": "46.7667",
            "Long": "20.8",
            "KSH kód": "10287",
            "Jogállás": "5",
            "Terület": "8184",
            "Népesség": "5355",
            "Lakások": "2529"
        },
        {
            "Ir. szám": "5555",
            "name": "Hunya",
            "Megyekód": "BE",
            "Lat": "46.8167",
            "Long": "20.85",
            "KSH kód": "33297",
            "Jogállás": "6",
            "Terület": "3257",
            "Népesség": "694",
            "Lakások": "423"
        },
        {
            "Ir. szám": "5556",
            "name": "Örménykút",
            "Megyekód": "BE",
            "Lat": "46.85",
            "Long": "20.7833",
            "KSH kód": "27438",
            "Jogállás": "6",
            "Terület": "5456",
            "Népesség": "417",
            "Lakások": "217"
        },
        {
            "Ir. szám": "5561",
            "name": "Békésszentandrás",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "20.4833",
            "KSH kód": "02680",
            "Jogállás": "5",
            "Terület": "7745",
            "Népesség": "3909",
            "Lakások": "1928"
        },
        {
            "Ir. szám": "5600",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5601",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5602",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5603",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5604",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5605",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5609",
            "name": "Csabaszabadi",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.0833",
            "KSH kód": "34078",
            "Jogállás": "6",
            "Terület": "3271",
            "Népesség": "344",
            "Lakások": "186"
        },
        {
            "Ir. szám": "5621",
            "name": "Csárdaszállás",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "20.9333",
            "KSH kód": "25502",
            "Jogállás": "6",
            "Terület": "5416",
            "Népesség": "468",
            "Lakások": "244"
        },
        {
            "Ir. szám": "5622",
            "name": "Köröstarcsa",
            "Megyekód": "BE",
            "Lat": "46.8833",
            "Long": "21.0333",
            "KSH kód": "12900",
            "Jogállás": "6",
            "Terület": "6280",
            "Népesség": "2585",
            "Lakások": "1244"
        },
        {
            "Ir. szám": "5623",
            "name": "Köröstarcsa",
            "Megyekód": "BE",
            "Lat": "46.8833",
            "Long": "21.0333",
            "KSH kód": "12900",
            "Jogállás": "6",
            "Terület": "6280",
            "Népesség": "2585",
            "Lakások": "1244"
        },
        {
            "Ir. szám": "5624",
            "name": "Doboz",
            "Megyekód": "BE",
            "Lat": "46.7333",
            "Long": "21.25",
            "KSH kód": "33190",
            "Jogállás": "5",
            "Terület": "5447",
            "Népesség": "4345",
            "Lakások": "1898"
        },
        {
            "Ir. szám": "5630",
            "name": "Békés",
            "Megyekód": "BE",
            "Lat": "46.7667",
            "Long": "21.1333",
            "KSH kód": "09760",
            "Jogállás": "4",
            "Terület": "12723",
            "Népesség": "20465",
            "Lakások": "8266"
        },
        {
            "Ir. szám": "5631",
            "name": "Békés",
            "Megyekód": "BE",
            "Lat": "46.7667",
            "Long": "21.1333",
            "KSH kód": "09760",
            "Jogállás": "4",
            "Terület": "12723",
            "Népesség": "20465",
            "Lakások": "8266"
        },
        {
            "Ir. szám": "5641",
            "name": "Tarhos",
            "Megyekód": "BE",
            "Lat": "46.8167",
            "Long": "21.2167",
            "KSH kód": "33075",
            "Jogállás": "6",
            "Terület": "5745",
            "Népesség": "968",
            "Lakások": "378"
        },
        {
            "Ir. szám": "5643",
            "name": "Bélmegyer",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "21.1833",
            "KSH kód": "19390",
            "Jogállás": "6",
            "Terület": "6305",
            "Népesség": "1064",
            "Lakások": "575"
        },
        {
            "Ir. szám": "5650",
            "name": "Mezőberény",
            "Megyekód": "BE",
            "Lat": "46.8167",
            "Long": "21.0333",
            "KSH kód": "19628",
            "Jogállás": "4",
            "Terület": "11853",
            "Népesség": "11241",
            "Lakások": "4618"
        },
        {
            "Ir. szám": "5661",
            "name": "Újkígyós",
            "Megyekód": "BE",
            "Lat": "46.5833",
            "Long": "21.0333",
            "KSH kód": "02352",
            "Jogállás": "5",
            "Terület": "5492",
            "Népesség": "5436",
            "Lakások": "2251"
        },
        {
            "Ir. szám": "5662",
            "name": "Csanádapáca",
            "Megyekód": "BE",
            "Lat": "46.55",
            "Long": "20.8833",
            "KSH kód": "20455",
            "Jogállás": "6",
            "Terület": "5130",
            "Népesség": "2729",
            "Lakások": "1388"
        },
        {
            "Ir. szám": "5663",
            "name": "Medgyesbodzás",
            "Megyekód": "BE",
            "Lat": "46.5167",
            "Long": "20.9667",
            "KSH kód": "20765",
            "Jogállás": "6",
            "Terület": "3167",
            "Népesség": "1089",
            "Lakások": "595"
        },
        {
            "Ir. szám": "5664",
            "name": "Medgyesbodzás",
            "Megyekód": "BE",
            "Lat": "46.5167",
            "Long": "20.9667",
            "KSH kód": "20765",
            "Jogállás": "6",
            "Terület": "3167",
            "Népesség": "1089",
            "Lakások": "595"
        },
        {
            "Ir. szám": "5665",
            "name": "Pusztaottlaka",
            "Megyekód": "BE",
            "Lat": "46.55",
            "Long": "20.9833",
            "KSH kód": "19594",
            "Jogállás": "6",
            "Terület": "1887",
            "Népesség": "374",
            "Lakások": "237"
        },
        {
            "Ir. szám": "5666",
            "name": "Medgyesegyháza",
            "Megyekód": "BE",
            "Lat": "46.5",
            "Long": "21.0333",
            "KSH kód": "30128",
            "Jogállás": "5",
            "Terület": "6429",
            "Népesség": "3848",
            "Lakások": "1873"
        },
        {
            "Ir. szám": "5667",
            "name": "Magyarbánhegyes",
            "Megyekód": "BE",
            "Lat": "46.45",
            "Long": "20.9667",
            "KSH kód": "27906",
            "Jogállás": "6",
            "Terület": "3656",
            "Népesség": "2462",
            "Lakások": "1101"
        },
        {
            "Ir. szám": "5668",
            "name": "Nagybánhegyes",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "20.9",
            "KSH kód": "26028",
            "Jogállás": "6",
            "Terület": "4224",
            "Népesség": "1223",
            "Lakások": "796"
        },
        {
            "Ir. szám": "5671",
            "name": "Nagybánhegyes",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "20.9",
            "KSH kód": "26028",
            "Jogállás": "6",
            "Terület": "4224",
            "Népesség": "1223",
            "Lakások": "796"
        },
        {
            "Ir. szám": "5672",
            "name": "Murony",
            "Megyekód": "BE",
            "Lat": "46.7667",
            "Long": "21.05",
            "KSH kód": "11989",
            "Jogállás": "6",
            "Terület": "3568",
            "Népesség": "1307",
            "Lakások": "575"
        },
        {
            "Ir. szám": "5673",
            "name": "Kamut",
            "Megyekód": "BE",
            "Lat": "46.7667",
            "Long": "20.9833",
            "KSH kód": "04279",
            "Jogállás": "6",
            "Terület": "6048",
            "Népesség": "1053",
            "Lakások": "529"
        },
        {
            "Ir. szám": "5674",
            "name": "Kétsoprony",
            "Megyekód": "BE",
            "Lat": "46.7167",
            "Long": "20.8667",
            "KSH kód": "03106",
            "Jogállás": "6",
            "Terület": "5124",
            "Népesség": "1507",
            "Lakások": "600"
        },
        {
            "Ir. szám": "5675",
            "name": "Telekgerendás",
            "Megyekód": "BE",
            "Lat": "46.65",
            "Long": "20.9667",
            "KSH kód": "12681",
            "Jogállás": "6",
            "Terület": "7237",
            "Népesség": "1599",
            "Lakások": "703"
        },
        {
            "Ir. szám": "5700",
            "name": "Gyula",
            "Megyekód": "BE",
            "Lat": "46.6474",
            "Long": "21.2773",
            "KSH kód": "05032",
            "Jogállás": "4",
            "Terület": "25580",
            "Népesség": "32055",
            "Lakások": "13642"
        },
        {
            "Ir. szám": "5701",
            "name": "Gyula",
            "Megyekód": "BE",
            "Lat": "46.65",
            "Long": "21.2833",
            "KSH kód": "05032",
            "Jogállás": "4",
            "Terület": "25580",
            "Népesség": "32055",
            "Lakások": "13642"
        },
        {
            "Ir. szám": "5702",
            "name": "Gyula",
            "Megyekód": "BE",
            "Lat": "46.65",
            "Long": "21.2833",
            "KSH kód": "05032",
            "Jogállás": "4",
            "Terület": "25580",
            "Népesség": "32055",
            "Lakások": "13642"
        },
        {
            "Ir. szám": "5703",
            "name": "Gyula",
            "Megyekód": "BE",
            "Lat": "46.65",
            "Long": "21.2833",
            "KSH kód": "05032",
            "Jogállás": "4",
            "Terület": "25580",
            "Népesség": "32055",
            "Lakások": "13642"
        },
        {
            "Ir. szám": "5711",
            "name": "Gyula",
            "Megyekód": "BE",
            "Lat": "46.65",
            "Long": "21.2833",
            "KSH kód": "05032",
            "Jogállás": "4",
            "Terület": "25580",
            "Népesség": "32055",
            "Lakások": "13642"
        },
        {
            "Ir. szám": "5712",
            "name": "Szabadkígyós",
            "Megyekód": "BE",
            "Lat": "46.6167",
            "Long": "21.1",
            "KSH kód": "31325",
            "Jogállás": "6",
            "Terület": "4556",
            "Népesség": "2809",
            "Lakások": "1092"
        },
        {
            "Ir. szám": "5720",
            "name": "Sarkad",
            "Megyekód": "BE",
            "Lat": "46.75",
            "Long": "21.3833",
            "KSH kód": "28565",
            "Jogállás": "4",
            "Terület": "12557",
            "Népesség": "10463",
            "Lakások": "4323"
        },
        {
            "Ir. szám": "5721",
            "name": "Sarkad",
            "Megyekód": "BE",
            "Lat": "46.75",
            "Long": "21.3833",
            "KSH kód": "28565",
            "Jogállás": "4",
            "Terület": "12557",
            "Népesség": "10463",
            "Lakások": "4323"
        },
        {
            "Ir. szám": "5722",
            "name": "Sarkad",
            "Megyekód": "BE",
            "Lat": "46.75",
            "Long": "21.3833",
            "KSH kód": "28565",
            "Jogállás": "4",
            "Terület": "12557",
            "Népesség": "10463",
            "Lakások": "4323"
        },
        {
            "Ir. szám": "5725",
            "name": "Kötegyán",
            "Megyekód": "BE",
            "Lat": "46.7333",
            "Long": "21.4833",
            "KSH kód": "06804",
            "Jogállás": "6",
            "Terület": "4295",
            "Népesség": "1402",
            "Lakások": "737"
        },
        {
            "Ir. szám": "5726",
            "name": "Méhkerék",
            "Megyekód": "BE",
            "Lat": "46.7833",
            "Long": "21.45",
            "KSH kód": "23931",
            "Jogállás": "6",
            "Terület": "2585",
            "Népesség": "2125",
            "Lakások": "878"
        },
        {
            "Ir. szám": "5727",
            "name": "Újszalonta",
            "Megyekód": "BE",
            "Lat": "46.8",
            "Long": "21.5",
            "KSH kód": "24350",
            "Jogállás": "6",
            "Terület": "2083",
            "Népesség": "112",
            "Lakások": "75"
        },
        {
            "Ir. szám": "5731",
            "name": "Sarkadkeresztúr",
            "Megyekód": "BE",
            "Lat": "46.8",
            "Long": "21.3833",
            "KSH kód": "25168",
            "Jogállás": "6",
            "Terület": "3530",
            "Népesség": "1598",
            "Lakások": "800"
        },
        {
            "Ir. szám": "5732",
            "name": "Mezőgyán",
            "Megyekód": "BE",
            "Lat": "46.8667",
            "Long": "21.5333",
            "KSH kód": "04206",
            "Jogállás": "6",
            "Terület": "5986",
            "Népesség": "1119",
            "Lakások": "549"
        },
        {
            "Ir. szám": "5734",
            "name": "Geszt",
            "Megyekód": "BE",
            "Lat": "46.8833",
            "Long": "21.5833",
            "KSH kód": "17394",
            "Jogállás": "6",
            "Terület": "5139",
            "Népesség": "756",
            "Lakások": "364"
        },
        {
            "Ir. szám": "5741",
            "name": "Kétegyháza",
            "Megyekód": "BE",
            "Lat": "46.5333",
            "Long": "21.1833",
            "KSH kód": "03461",
            "Jogállás": "5",
            "Terület": "5049",
            "Népesség": "4197",
            "Lakások": "1746"
        },
        {
            "Ir. szám": "5742",
            "name": "Elek",
            "Megyekód": "BE",
            "Lat": "46.5333",
            "Long": "21.25",
            "KSH kód": "32957",
            "Jogállás": "4",
            "Terület": "5491",
            "Népesség": "5048",
            "Lakások": "1995"
        },
        {
            "Ir. szám": "5743",
            "name": "Lőkösháza",
            "Megyekód": "BE",
            "Lat": "46.4333",
            "Long": "21.2333",
            "KSH kód": "21209",
            "Jogállás": "6",
            "Terület": "5202",
            "Népesség": "1868",
            "Lakások": "831"
        },
        {
            "Ir. szám": "5744",
            "name": "Kevermes",
            "Megyekód": "BE",
            "Lat": "46.4167",
            "Long": "21.1833",
            "KSH kód": "31574",
            "Jogállás": "5",
            "Terület": "4334",
            "Népesség": "2136",
            "Lakások": "1160"
        },
        {
            "Ir. szám": "5745",
            "name": "Dombiratos",
            "Megyekód": "BE",
            "Lat": "46.4167",
            "Long": "21.1167",
            "KSH kód": "22132",
            "Jogállás": "6",
            "Terület": "1830",
            "Népesség": "584",
            "Lakások": "406"
        },
        {
            "Ir. szám": "5746",
            "name": "Kunágota",
            "Megyekód": "BE",
            "Lat": "46.4333",
            "Long": "21.05",
            "KSH kód": "16045",
            "Jogállás": "6",
            "Terület": "6396",
            "Népesség": "2685",
            "Lakások": "1390"
        },
        {
            "Ir. szám": "5747",
            "name": "Almáskamarás",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "21.1",
            "KSH kód": "29595",
            "Jogállás": "6",
            "Terület": "1475",
            "Népesség": "869",
            "Lakások": "466"
        },
        {
            "Ir. szám": "5751",
            "name": "Nagykamarás",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "21.1167",
            "KSH kód": "04242",
            "Jogállás": "6",
            "Terület": "4305",
            "Népesség": "1493",
            "Lakások": "858"
        },
        {
            "Ir. szám": "5752",
            "name": "Nagykamarás",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "21.1167",
            "KSH kód": "04242",
            "Jogállás": "6",
            "Terület": "4305",
            "Népesség": "1493",
            "Lakások": "858"
        },
        {
            "Ir. szám": "5800",
            "name": "Mezőkovácsháza",
            "Megyekód": "BE",
            "Lat": "46.4",
            "Long": "20.9167",
            "KSH kód": "30322",
            "Jogállás": "4",
            "Terület": "6259",
            "Népesség": "6304",
            "Lakások": "2961"
        },
        {
            "Ir. szám": "5801",
            "name": "Mezőkovácsháza",
            "Megyekód": "BE",
            "Lat": "46.4",
            "Long": "20.9167",
            "KSH kód": "30322",
            "Jogállás": "4",
            "Terület": "6259",
            "Népesség": "6304",
            "Lakások": "2961"
        },
        {
            "Ir. szám": "5802",
            "name": "Mezőkovácsháza",
            "Megyekód": "BE",
            "Lat": "46.4",
            "Long": "20.9167",
            "KSH kód": "30322",
            "Jogállás": "4",
            "Terület": "6259",
            "Népesség": "6304",
            "Lakások": "2961"
        },
        {
            "Ir. szám": "5811",
            "name": "Végegyháza",
            "Megyekód": "BE",
            "Lat": "46.3833",
            "Long": "20.8667",
            "KSH kód": "31228",
            "Jogállás": "6",
            "Terület": "2894",
            "Népesség": "1455",
            "Lakások": "764"
        },
        {
            "Ir. szám": "5820",
            "name": "Mezőhegyes",
            "Megyekód": "BE",
            "Lat": "46.3167",
            "Long": "20.8167",
            "KSH kód": "11873",
            "Jogállás": "4",
            "Terület": "15544",
            "Népesség": "5408",
            "Lakások": "2899"
        },
        {
            "Ir. szám": "5830",
            "name": "Battonya",
            "Megyekód": "BE",
            "Lat": "46.2833",
            "Long": "21.0167",
            "KSH kód": "18102",
            "Jogállás": "4",
            "Terület": "14571",
            "Népesség": "5838",
            "Lakások": "3099"
        },
        {
            "Ir. szám": "5836",
            "name": "Dombegyház",
            "Megyekód": "BE",
            "Lat": "46.3333",
            "Long": "21.1333",
            "KSH kód": "24031",
            "Jogállás": "5",
            "Terület": "5794",
            "Népesség": "2064",
            "Lakások": "1083"
        },
        {
            "Ir. szám": "5837",
            "name": "Kisdombegyház",
            "Megyekód": "BE",
            "Lat": "46.3667",
            "Long": "21.1",
            "KSH kód": "18838",
            "Jogállás": "6",
            "Terület": "1261",
            "Népesség": "478",
            "Lakások": "279"
        },
        {
            "Ir. szám": "5838",
            "name": "Magyardombegyház",
            "Megyekód": "BE",
            "Lat": "46.3833",
            "Long": "21.0667",
            "KSH kód": "11536",
            "Jogállás": "6",
            "Terület": "765",
            "Népesség": "259",
            "Lakások": "168"
        },
        {
            "Ir. szám": "5900",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5901",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5902",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5903",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5904",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5905",
            "name": "Orosháza",
            "Megyekód": "BE",
            "Lat": "46.5667",
            "Long": "20.6667",
            "KSH kód": "23065",
            "Jogállás": "4",
            "Terület": "20222",
            "Népesség": "30032",
            "Lakások": "14030"
        },
        {
            "Ir. szám": "5919",
            "name": "Pusztaföldvár",
            "Megyekód": "BE",
            "Lat": "46.5333",
            "Long": "20.8",
            "KSH kód": "05397",
            "Jogállás": "6",
            "Terület": "5713",
            "Népesség": "1752",
            "Lakások": "905"
        },
        {
            "Ir. szám": "5920",
            "name": "Csorvás",
            "Megyekód": "BE",
            "Lat": "46.6333",
            "Long": "20.8333",
            "KSH kód": "26709",
            "Jogállás": "4",
            "Terület": "9018",
            "Népesség": "5302",
            "Lakások": "2393"
        },
        {
            "Ir. szám": "5925",
            "name": "Gerendás",
            "Megyekód": "BE",
            "Lat": "46.6",
            "Long": "20.8667",
            "KSH kód": "07393",
            "Jogállás": "6",
            "Terület": "4078",
            "Népesség": "1397",
            "Lakások": "719"
        },
        {
            "Ir. szám": "5931",
            "name": "Nagyszénás",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "20.6667",
            "KSH kód": "08244",
            "Jogállás": "5",
            "Terület": "9556",
            "Népesség": "5256",
            "Lakások": "2366"
        },
        {
            "Ir. szám": "5932",
            "name": "Gádoros",
            "Megyekód": "BE",
            "Lat": "46.6667",
            "Long": "20.6",
            "KSH kód": "09511",
            "Jogállás": "5",
            "Terület": "3813",
            "Népesség": "3833",
            "Lakások": "1880"
        },
        {
            "Ir. szám": "5940",
            "name": "Tótkomlós",
            "Megyekód": "BE",
            "Lat": "46.4167",
            "Long": "20.7333",
            "KSH kód": "16434",
            "Jogállás": "4",
            "Terület": "12505",
            "Népesség": "6049",
            "Lakások": "2952"
        },
        {
            "Ir. szám": "5945",
            "name": "Kardoskút",
            "Megyekód": "BE",
            "Lat": "46.4833",
            "Long": "20.6667",
            "KSH kód": "12177",
            "Jogállás": "6",
            "Terület": "7658",
            "Népesség": "917",
            "Lakások": "429"
        },
        {
            "Ir. szám": "5946",
            "name": "Békéssámson",
            "Megyekód": "BE",
            "Lat": "46.4167",
            "Long": "20.6333",
            "KSH kód": "26189",
            "Jogállás": "6",
            "Terület": "7121",
            "Népesség": "2434",
            "Lakások": "1135"
        },
        {
            "Ir. szám": "5948",
            "name": "Kaszaper",
            "Megyekód": "BE",
            "Lat": "46.4667",
            "Long": "20.8333",
            "KSH kód": "22752",
            "Jogállás": "6",
            "Terület": "3357",
            "Népesség": "1960",
            "Lakások": "892"
        },
        {
            "Ir. szám": "6000",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6001",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6002",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6003",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6004",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6005",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6006",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6007",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6008",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6010",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6031",
            "name": "Szentkirály",
            "Megyekód": "BK",
            "Lat": "46.9167",
            "Long": "19.9333",
            "KSH kód": "21120",
            "Jogállás": "6",
            "Terület": "10189",
            "Népesség": "1958",
            "Lakások": "914"
        },
        {
            "Ir. szám": "6032",
            "name": "Nyárlőrinc",
            "Megyekód": "BK",
            "Lat": "46.8622",
            "Long": "19.8733",
            "KSH kód": "23056",
            "Jogállás": "6",
            "Terület": "6636",
            "Népesség": "2364",
            "Lakások": "1013"
        },
        {
            "Ir. szám": "6033",
            "name": "Városföld",
            "Megyekód": "BK",
            "Lat": "46.8167",
            "Long": "19.7667",
            "KSH kód": "10667",
            "Jogállás": "6",
            "Terület": "6287",
            "Népesség": "2186",
            "Lakások": "807"
        },
        {
            "Ir. szám": "6034",
            "name": "Helvécia",
            "Megyekód": "BK",
            "Lat": "46.8333",
            "Long": "19.6167",
            "KSH kód": "04093",
            "Jogállás": "6",
            "Terület": "5647",
            "Népesség": "4362",
            "Lakások": "1588"
        },
        {
            "Ir. szám": "6035",
            "name": "Ballószög",
            "Megyekód": "BK",
            "Lat": "46.8667",
            "Long": "19.5833",
            "KSH kód": "13408",
            "Jogállás": "6",
            "Terület": "3500",
            "Népesség": "3356",
            "Lakások": "1060"
        },
        {
            "Ir. szám": "6041",
            "name": "Kerekegyháza",
            "Megyekód": "BK",
            "Lat": "46.9372",
            "Long": "19.4781",
            "KSH kód": "22530",
            "Jogállás": "4",
            "Terület": "8128",
            "Népesség": "6225",
            "Lakások": "2736"
        },
        {
            "Ir. szám": "6042",
            "name": "Fülöpháza",
            "Megyekód": "BK",
            "Lat": "46.8833",
            "Long": "19.4167",
            "KSH kód": "31468",
            "Jogállás": "6",
            "Terület": "4706",
            "Népesség": "862",
            "Lakások": "479"
        },
        {
            "Ir. szám": "6043",
            "name": "Kunbaracs",
            "Megyekód": "BK",
            "Lat": "46.9833",
            "Long": "19.4167",
            "KSH kód": "07728",
            "Jogállás": "6",
            "Terület": "5511",
            "Népesség": "655",
            "Lakások": "328"
        },
        {
            "Ir. szám": "6044",
            "name": "Kunbaracs",
            "Megyekód": "BK",
            "Lat": "46.9833",
            "Long": "19.4167",
            "KSH kód": "07728",
            "Jogállás": "6",
            "Terület": "5511",
            "Népesség": "655",
            "Lakások": "328"
        },
        {
            "Ir. szám": "6045",
            "name": "Ladánybene",
            "Megyekód": "BK",
            "Lat": "47.0333",
            "Long": "19.45",
            "KSH kód": "05786",
            "Jogállás": "6",
            "Terület": "4074",
            "Népesség": "1688",
            "Lakások": "801"
        },
        {
            "Ir. szám": "6050",
            "name": "Lajosmizse",
            "Megyekód": "BK",
            "Lat": "47.0167",
            "Long": "19.55",
            "KSH kód": "17677",
            "Jogállás": "4",
            "Terület": "16466",
            "Népesség": "11053",
            "Lakások": "4886"
        },
        {
            "Ir. szám": "6055",
            "name": "Felsőlajos",
            "Megyekód": "BK",
            "Lat": "47.0667",
            "Long": "19.5167",
            "KSH kód": "33598",
            "Jogállás": "6",
            "Terület": "1141",
            "Népesség": "1003",
            "Lakások": "409"
        },
        {
            "Ir. szám": "6060",
            "name": "Tiszakécske",
            "Megyekód": "BK",
            "Lat": "46.9333",
            "Long": "20.1",
            "KSH kód": "30623",
            "Jogállás": "4",
            "Terület": "13327",
            "Népesség": "11504",
            "Lakások": "5206"
        },
        {
            "Ir. szám": "6061",
            "name": "Tiszakécske",
            "Megyekód": "BK",
            "Lat": "46.9333",
            "Long": "20.1",
            "KSH kód": "30623",
            "Jogállás": "4",
            "Terület": "13327",
            "Népesség": "11504",
            "Lakások": "5206"
        },
        {
            "Ir. szám": "6062",
            "name": "Tiszakécske",
            "Megyekód": "BK",
            "Lat": "46.9333",
            "Long": "20.1",
            "KSH kód": "30623",
            "Jogállás": "4",
            "Terület": "13327",
            "Népesség": "11504",
            "Lakások": "5206"
        },
        {
            "Ir. szám": "6063",
            "name": "Tiszakécske",
            "Megyekód": "BK",
            "Lat": "46.9333",
            "Long": "20.1",
            "KSH kód": "30623",
            "Jogállás": "4",
            "Terület": "13327",
            "Népesség": "11504",
            "Lakások": "5206"
        },
        {
            "Ir. szám": "6064",
            "name": "Tiszaug",
            "Megyekód": "BK",
            "Lat": "46.8515",
            "Long": "20.0553",
            "KSH kód": "14094",
            "Jogállás": "6",
            "Terület": "2504",
            "Népesség": "908",
            "Lakások": "432"
        },
        {
            "Ir. szám": "6065",
            "name": "Lakitelek",
            "Megyekód": "BK",
            "Lat": "46.8761",
            "Long": "19.9889",
            "KSH kód": "06202",
            "Jogállás": "5",
            "Terület": "5466",
            "Népesség": "4449",
            "Lakások": "1966"
        },
        {
            "Ir. szám": "6066",
            "name": "Tiszaalpár",
            "Megyekód": "BK",
            "Lat": "46.8217",
            "Long": "19.9842",
            "KSH kód": "24545",
            "Jogállás": "5",
            "Terület": "9113",
            "Népesség": "5031",
            "Lakások": "2314"
        },
        {
            "Ir. szám": "6067",
            "name": "Tiszaalpár",
            "Megyekód": "BK",
            "Lat": "46.8217",
            "Long": "19.9842",
            "KSH kód": "24545",
            "Jogállás": "5",
            "Terület": "9113",
            "Népesség": "5031",
            "Lakások": "2314"
        },
        {
            "Ir. szám": "6070",
            "name": "Izsák",
            "Megyekód": "BK",
            "Lat": "46.8014",
            "Long": "19.3547",
            "KSH kód": "21999",
            "Jogállás": "4",
            "Terület": "11376",
            "Népesség": "6004",
            "Lakások": "2904"
        },
        {
            "Ir. szám": "6075",
            "name": "Páhi",
            "Megyekód": "BK",
            "Lat": "46.7167",
            "Long": "19.4",
            "KSH kód": "18670",
            "Jogállás": "6",
            "Terület": "3896",
            "Népesség": "1213",
            "Lakások": "688"
        },
        {
            "Ir. szám": "6076",
            "name": "Ágasegyháza",
            "Megyekód": "BK",
            "Lat": "46.8333",
            "Long": "19.45",
            "KSH kód": "17686",
            "Jogállás": "6",
            "Terület": "5587",
            "Népesség": "1912",
            "Lakások": "898"
        },
        {
            "Ir. szám": "6077",
            "name": "Orgovány",
            "Megyekód": "BK",
            "Lat": "46.7486",
            "Long": "19.4736",
            "KSH kód": "16939",
            "Jogállás": "6",
            "Terület": "9916",
            "Népesség": "3282",
            "Lakások": "1656"
        },
        {
            "Ir. szám": "6078",
            "name": "Jakabszállás",
            "Megyekód": "BK",
            "Lat": "46.7603",
            "Long": "19.6072",
            "KSH kód": "17923",
            "Jogállás": "6",
            "Terület": "7086",
            "Népesség": "2645",
            "Lakások": "1235"
        },
        {
            "Ir. szám": "6080",
            "name": "Szabadszállás",
            "Megyekód": "BK",
            "Lat": "46.8728",
            "Long": "19.2242",
            "KSH kód": "25061",
            "Jogállás": "4",
            "Terület": "16462",
            "Népesség": "6221",
            "Lakások": "3083"
        },
        {
            "Ir. szám": "6085",
            "name": "Fülöpszállás",
            "Megyekód": "BK",
            "Lat": "46.8178",
            "Long": "19.2386",
            "KSH kód": "14058",
            "Jogállás": "6",
            "Terület": "9132",
            "Népesség": "2357",
            "Lakások": "1287"
        },
        {
            "Ir. szám": "6086",
            "name": "Szalkszentmárton",
            "Megyekód": "BK",
            "Lat": "46.9742",
            "Long": "19.02",
            "KSH kód": "19947",
            "Jogállás": "6",
            "Terület": "8208",
            "Népesség": "2948",
            "Lakások": "1279"
        },
        {
            "Ir. szám": "6087",
            "name": "Dunavecse",
            "Megyekód": "BK",
            "Lat": "46.9167",
            "Long": "18.9833",
            "KSH kód": "07612",
            "Jogállás": "4",
            "Terület": "6677",
            "Népesség": "4026",
            "Lakások": "1714"
        },
        {
            "Ir. szám": "6088",
            "name": "Apostag",
            "Megyekód": "BK",
            "Lat": "46.8833",
            "Long": "18.95",
            "KSH kód": "21148",
            "Jogállás": "6",
            "Terület": "3194",
            "Népesség": "2035",
            "Lakások": "971"
        },
        {
            "Ir. szám": "6090",
            "name": "Kunszentmiklós",
            "Megyekód": "BK",
            "Lat": "47.0333",
            "Long": "19.1333",
            "KSH kód": "28130",
            "Jogállás": "4",
            "Terület": "17211",
            "Népesség": "8704",
            "Lakások": "3545"
        },
        {
            "Ir. szám": "6093",
            "name": "Kunszentmiklós",
            "Megyekód": "BK",
            "Lat": "47.0333",
            "Long": "19.1333",
            "KSH kód": "28130",
            "Jogállás": "4",
            "Terület": "17211",
            "Népesség": "8704",
            "Lakások": "3545"
        },
        {
            "Ir. szám": "6096",
            "name": "Kunpeszér",
            "Megyekód": "BK",
            "Lat": "47.0667",
            "Long": "19.2833",
            "KSH kód": "31918",
            "Jogállás": "6",
            "Terület": "7755",
            "Népesség": "641",
            "Lakások": "306"
        },
        {
            "Ir. szám": "6097",
            "name": "Kunadacs",
            "Megyekód": "BK",
            "Lat": "46.95",
            "Long": "19.3167",
            "KSH kód": "05856",
            "Jogállás": "6",
            "Terület": "8990",
            "Népesség": "1612",
            "Lakások": "770"
        },
        {
            "Ir. szám": "6098",
            "name": "Tass",
            "Megyekód": "BK",
            "Lat": "47.0167",
            "Long": "19.0333",
            "KSH kód": "20525",
            "Jogállás": "6",
            "Terület": "7472",
            "Népesség": "2957",
            "Lakások": "1143"
        },
        {
            "Ir. szám": "6100",
            "name": "Kiskunfélegyháza",
            "Megyekód": "BK",
            "Lat": "46.7136",
            "Long": "19.8522",
            "KSH kód": "20297",
            "Jogállás": "4",
            "Terület": "25630",
            "Népesség": "30523",
            "Lakások": "14644"
        },
        {
            "Ir. szám": "6101",
            "name": "Kiskunfélegyháza",
            "Megyekód": "BK",
            "Lat": "46.7136",
            "Long": "19.8522",
            "KSH kód": "20297",
            "Jogállás": "4",
            "Terület": "25630",
            "Népesség": "30523",
            "Lakások": "14644"
        },
        {
            "Ir. szám": "6102",
            "name": "Kiskunfélegyháza",
            "Megyekód": "BK",
            "Lat": "46.7136",
            "Long": "19.8522",
            "KSH kód": "20297",
            "Jogállás": "4",
            "Terület": "25630",
            "Népesség": "30523",
            "Lakások": "14644"
        },
        {
            "Ir. szám": "6111",
            "name": "Gátér",
            "Megyekód": "BK",
            "Lat": "46.6833",
            "Long": "19.9667",
            "KSH kód": "26383",
            "Jogállás": "6",
            "Terület": "3089",
            "Népesség": "989",
            "Lakások": "492"
        },
        {
            "Ir. szám": "6112",
            "name": "Pálmonostora",
            "Megyekód": "BK",
            "Lat": "46.6278",
            "Long": "19.9422",
            "KSH kód": "02705",
            "Jogállás": "6",
            "Terület": "5328",
            "Népesség": "1818",
            "Lakások": "1094"
        },
        {
            "Ir. szám": "6113",
            "name": "Petőfiszállás",
            "Megyekód": "BK",
            "Lat": "46.6167",
            "Long": "19.8333",
            "KSH kód": "15431",
            "Jogállás": "6",
            "Terület": "6779",
            "Népesség": "1495",
            "Lakások": "915"
        },
        {
            "Ir. szám": "6114",
            "name": "Bugac",
            "Megyekód": "BK",
            "Lat": "46.6889",
            "Long": "19.6794",
            "KSH kód": "32823",
            "Jogállás": "5",
            "Terület": "13111",
            "Népesség": "2869",
            "Lakások": "1481"
        },
        {
            "Ir. szám": "6114",
            "name": "Bugacpusztaháza",
            "Megyekód": "BK",
            "Lat": "46.6889",
            "Long": "19.6794",
            "KSH kód": "33631",
            "Jogállás": "6",
            "Terület": "4300",
            "Népesség": "307",
            "Lakások": "143"
        },
        {
            "Ir. szám": "6115",
            "name": "Kunszállás",
            "Megyekód": "BK",
            "Lat": "46.7667",
            "Long": "19.75",
            "KSH kód": "31893",
            "Jogállás": "6",
            "Terület": "2085",
            "Népesség": "1660",
            "Lakások": "743"
        },
        {
            "Ir. szám": "6116",
            "name": "Fülöpjakab",
            "Megyekód": "BK",
            "Lat": "46.6767",
            "Long": "19.8343",
            "KSH kód": "33622",
            "Jogállás": "6",
            "Terület": "2316",
            "Népesség": "1169",
            "Lakások": "494"
        },
        {
            "Ir. szám": "6120",
            "name": "Kiskunmajsa",
            "Megyekód": "BK",
            "Lat": "46.4903",
            "Long": "19.74",
            "KSH kód": "24396",
            "Jogállás": "4",
            "Terület": "22199",
            "Népesség": "11707",
            "Lakások": "5434"
        },
        {
            "Ir. szám": "6131",
            "name": "Szank",
            "Megyekód": "BK",
            "Lat": "46.5544",
            "Long": "19.6722",
            "KSH kód": "11794",
            "Jogállás": "6",
            "Terület": "7483",
            "Népesség": "2472",
            "Lakások": "1167"
        },
        {
            "Ir. szám": "6132",
            "name": "Móricgát",
            "Megyekód": "BK",
            "Lat": "46.4915",
            "Long": "19.7487",
            "KSH kód": "34096",
            "Jogállás": "6",
            "Terület": "3289",
            "Népesség": "490",
            "Lakások": "378"
        },
        {
            "Ir. szám": "6133",
            "name": "Jászszentlászló",
            "Megyekód": "BK",
            "Lat": "46.5681",
            "Long": "19.7611",
            "KSH kód": "08378",
            "Jogállás": "6",
            "Terület": "6034",
            "Népesség": "2537",
            "Lakások": "1299"
        },
        {
            "Ir. szám": "6134",
            "name": "Kömpöc",
            "Megyekód": "BK",
            "Lat": "46.4667",
            "Long": "19.8667",
            "KSH kód": "13806",
            "Jogállás": "6",
            "Terület": "2998",
            "Népesség": "744",
            "Lakások": "396"
        },
        {
            "Ir. szám": "6135",
            "name": "Csólyospálos",
            "Megyekód": "BK",
            "Lat": "46.4183",
            "Long": "19.8436",
            "KSH kód": "12025",
            "Jogállás": "6",
            "Terület": "6510",
            "Népesség": "1699",
            "Lakások": "1087"
        },
        {
            "Ir. szám": "6136",
            "name": "Harkakötöny",
            "Megyekód": "BK",
            "Lat": "46.45",
            "Long": "19.6",
            "KSH kód": "08350",
            "Jogállás": "6",
            "Terület": "5270",
            "Népesség": "932",
            "Lakások": "395"
        },
        {
            "Ir. szám": "6200",
            "name": "Kiskőrös",
            "Megyekód": "BK",
            "Lat": "46.6206",
            "Long": "19.2886",
            "KSH kód": "09344",
            "Jogállás": "4",
            "Terület": "10223",
            "Népesség": "14452",
            "Lakások": "6770"
        },
        {
            "Ir. szám": "6201",
            "name": "Kiskőrös",
            "Megyekód": "BK",
            "Lat": "46.6206",
            "Long": "19.2886",
            "KSH kód": "09344",
            "Jogállás": "4",
            "Terület": "10223",
            "Népesség": "14452",
            "Lakások": "6770"
        },
        {
            "Ir. szám": "6211",
            "name": "Kaskantyú",
            "Megyekód": "BK",
            "Lat": "46.6667",
            "Long": "19.4",
            "KSH kód": "30605",
            "Jogállás": "6",
            "Terület": "5828",
            "Népesség": "937",
            "Lakások": "559"
        },
        {
            "Ir. szám": "6221",
            "name": "Akasztó",
            "Megyekód": "BK",
            "Lat": "46.6917",
            "Long": "19.2081",
            "KSH kód": "21944",
            "Jogállás": "6",
            "Terület": "6488",
            "Népesség": "3367",
            "Lakások": "1557"
        },
        {
            "Ir. szám": "6222",
            "name": "Csengőd",
            "Megyekód": "BK",
            "Lat": "46.7167",
            "Long": "19.2667",
            "KSH kód": "12344",
            "Jogállás": "6",
            "Terület": "4889",
            "Népesség": "2176",
            "Lakások": "1180"
        },
        {
            "Ir. szám": "6223",
            "name": "Soltszentimre",
            "Megyekód": "BK",
            "Lat": "46.7697",
            "Long": "19.2831",
            "KSH kód": "18218",
            "Jogállás": "6",
            "Terület": "4449",
            "Népesség": "1302",
            "Lakások": "668"
        },
        {
            "Ir. szám": "6224",
            "name": "Tabdi",
            "Megyekód": "BK",
            "Lat": "46.6833",
            "Long": "19.3",
            "KSH kód": "25432",
            "Jogállás": "6",
            "Terület": "2139",
            "Népesség": "1127",
            "Lakások": "549"
        },
        {
            "Ir. szám": "6230",
            "name": "Soltvadkert",
            "Megyekód": "BK",
            "Lat": "46.5781",
            "Long": "19.3958",
            "KSH kód": "19983",
            "Jogállás": "4",
            "Terület": "10886",
            "Népesség": "7519",
            "Lakások": "3369"
        },
        {
            "Ir. szám": "6235",
            "name": "Bócsa",
            "Megyekód": "BK",
            "Lat": "46.6",
            "Long": "19.5",
            "KSH kód": "08305",
            "Jogállás": "6",
            "Terület": "9704",
            "Népesség": "1816",
            "Lakások": "945"
        },
        {
            "Ir. szám": "6236",
            "name": "Tázlár",
            "Megyekód": "BK",
            "Lat": "46.5486",
            "Long": "19.5203",
            "KSH kód": "24998",
            "Jogállás": "6",
            "Terület": "7338",
            "Népesség": "1782",
            "Lakások": "911"
        },
        {
            "Ir. szám": "6237",
            "name": "Kecel",
            "Megyekód": "BK",
            "Lat": "46.5253",
            "Long": "19.2519",
            "KSH kód": "19789",
            "Jogállás": "4",
            "Terület": "11448",
            "Népesség": "8892",
            "Lakások": "3787"
        },
        {
            "Ir. szám": "6238",
            "name": "Imrehegy",
            "Megyekód": "BK",
            "Lat": "46.4833",
            "Long": "19.3167",
            "KSH kód": "08095",
            "Jogállás": "6",
            "Terület": "7031",
            "Népesség": "738",
            "Lakások": "355"
        },
        {
            "Ir. szám": "6239",
            "name": "Császártöltés",
            "Megyekód": "BK",
            "Lat": "46.4219",
            "Long": "19.1836",
            "KSH kód": "10472",
            "Jogállás": "6",
            "Terület": "8206",
            "Népesség": "2457",
            "Lakások": "1055"
        },
        {
            "Ir. szám": "6300",
            "name": "Kalocsa",
            "Megyekód": "BK",
            "Lat": "46.5339",
            "Long": "18.9861",
            "KSH kód": "06442",
            "Jogállás": "4",
            "Terület": "5318",
            "Népesség": "17492",
            "Lakások": "7737"
        },
        {
            "Ir. szám": "6301",
            "name": "Kalocsa",
            "Megyekód": "BK",
            "Lat": "46.5264",
            "Long": "18.9858",
            "KSH kód": "06442",
            "Jogállás": "4",
            "Terület": "5318",
            "Népesség": "17492",
            "Lakások": "7737"
        },
        {
            "Ir. szám": "6311",
            "name": "Öregcsertő",
            "Megyekód": "BK",
            "Lat": "46.5139",
            "Long": "19.1117",
            "KSH kód": "08679",
            "Jogállás": "6",
            "Terület": "4306",
            "Népesség": "888",
            "Lakások": "423"
        },
        {
            "Ir. szám": "6320",
            "name": "Solt",
            "Megyekód": "BK",
            "Lat": "46.7997",
            "Long": "19.0161",
            "KSH kód": "29115",
            "Jogállás": "4",
            "Terület": "13267",
            "Népesség": "6632",
            "Lakások": "3000"
        },
        {
            "Ir. szám": "6321",
            "name": "Újsolt",
            "Megyekód": "BK",
            "Lat": "46.8667",
            "Long": "19.1167",
            "KSH kód": "08785",
            "Jogállás": "6",
            "Terület": "3298",
            "Népesség": "152",
            "Lakások": "127"
        },
        {
            "Ir. szám": "6323",
            "name": "Dunaegyháza",
            "Megyekód": "BK",
            "Lat": "46.8333",
            "Long": "18.95",
            "KSH kód": "21069",
            "Jogállás": "6",
            "Terület": "1012",
            "Népesség": "1543",
            "Lakások": "728"
        },
        {
            "Ir. szám": "6325",
            "name": "Dunatetétlen",
            "Megyekód": "BK",
            "Lat": "46.75",
            "Long": "19.1167",
            "KSH kód": "14766",
            "Jogállás": "6",
            "Terület": "4319",
            "Népesség": "549",
            "Lakások": "283"
        },
        {
            "Ir. szám": "6326",
            "name": "Harta",
            "Megyekód": "BK",
            "Lat": "46.6936",
            "Long": "19.0314",
            "KSH kód": "18458",
            "Jogállás": "5",
            "Terület": "12968",
            "Népesség": "3414",
            "Lakások": "1664"
        },
        {
            "Ir. szám": "6327",
            "name": "Harta",
            "Megyekód": "BK",
            "Lat": "46.6936",
            "Long": "19.0314",
            "KSH kód": "18458",
            "Jogállás": "5",
            "Terület": "12968",
            "Népesség": "3414",
            "Lakások": "1664"
        },
        {
            "Ir. szám": "6328",
            "name": "Dunapataj",
            "Megyekód": "BK",
            "Lat": "46.64",
            "Long": "19.0022",
            "KSH kód": "07861",
            "Jogállás": "5",
            "Terület": "9047",
            "Népesség": "3347",
            "Lakások": "1655"
        },
        {
            "Ir. szám": "6331",
            "name": "Foktő",
            "Megyekód": "BK",
            "Lat": "46.5167",
            "Long": "18.9167",
            "KSH kód": "02149",
            "Jogállás": "6",
            "Terület": "3146",
            "Népesség": "1646",
            "Lakások": "757"
        },
        {
            "Ir. szám": "6332",
            "name": "Uszód",
            "Megyekód": "BK",
            "Lat": "46.5667",
            "Long": "18.9167",
            "KSH kód": "16294",
            "Jogállás": "6",
            "Terület": "2446",
            "Népesség": "1068",
            "Lakások": "488"
        },
        {
            "Ir. szám": "6333",
            "name": "Dunaszentbenedek",
            "Megyekód": "BK",
            "Lat": "46.6",
            "Long": "18.9",
            "KSH kód": "11606",
            "Jogállás": "6",
            "Terület": "2324",
            "Népesség": "847",
            "Lakások": "434"
        },
        {
            "Ir. szám": "6334",
            "name": "Géderlak",
            "Megyekód": "BK",
            "Lat": "46.6167",
            "Long": "18.9167",
            "KSH kód": "03577",
            "Jogállás": "6",
            "Terület": "1874",
            "Népesség": "1041",
            "Lakások": "480"
        },
        {
            "Ir. szám": "6335",
            "name": "Ordas",
            "Megyekód": "BK",
            "Lat": "46.6333",
            "Long": "18.95",
            "KSH kód": "16276",
            "Jogállás": "6",
            "Terület": "1652",
            "Népesség": "446",
            "Lakások": "265"
        },
        {
            "Ir. szám": "6336",
            "name": "Szakmár",
            "Megyekód": "BK",
            "Lat": "46.5522",
            "Long": "19.0742",
            "KSH kód": "19530",
            "Jogállás": "6",
            "Terület": "7464",
            "Népesség": "1246",
            "Lakások": "618"
        },
        {
            "Ir. szám": "6337",
            "name": "Újtelek",
            "Megyekód": "BK",
            "Lat": "46.59",
            "Long": "19.0586",
            "KSH kód": "33604",
            "Jogállás": "6",
            "Terület": "956",
            "Népesség": "404",
            "Lakások": "253"
        },
        {
            "Ir. szám": "6341",
            "name": "Homokmégy",
            "Megyekód": "BK",
            "Lat": "46.4869",
            "Long": "19.0744",
            "KSH kód": "27845",
            "Jogállás": "6",
            "Terület": "7032",
            "Népesség": "1387",
            "Lakások": "760"
        },
        {
            "Ir. szám": "6342",
            "name": "Drágszél",
            "Megyekód": "BK",
            "Lat": "46.4667",
            "Long": "19.05",
            "KSH kód": "07524",
            "Jogállás": "6",
            "Terület": "1259",
            "Népesség": "362",
            "Lakások": "174"
        },
        {
            "Ir. szám": "6343",
            "name": "Miske",
            "Megyekód": "BK",
            "Lat": "46.4417",
            "Long": "19.0322",
            "KSH kód": "30632",
            "Jogállás": "6",
            "Terület": "4227",
            "Népesség": "1789",
            "Lakások": "757"
        },
        {
            "Ir. szám": "6344",
            "name": "Hajós",
            "Megyekód": "BK",
            "Lat": "46.3986",
            "Long": "19.1206",
            "KSH kód": "18759",
            "Jogállás": "4",
            "Terület": "8992",
            "Népesség": "3262",
            "Lakások": "1418"
        },
        {
            "Ir. szám": "6345",
            "name": "Nemesnádudvar",
            "Megyekód": "BK",
            "Lat": "46.3356",
            "Long": "19.0531",
            "KSH kód": "32540",
            "Jogállás": "6",
            "Terület": "5878",
            "Népesség": "1885",
            "Lakások": "727"
        },
        {
            "Ir. szám": "6346",
            "name": "Sükösd",
            "Megyekód": "BK",
            "Lat": "46.2833",
            "Long": "19",
            "KSH kód": "21245",
            "Jogállás": "5",
            "Terület": "9418",
            "Népesség": "3816",
            "Lakások": "1549"
        },
        {
            "Ir. szám": "6347",
            "name": "Érsekcsanád",
            "Megyekód": "BK",
            "Lat": "46.25",
            "Long": "18.9833",
            "KSH kód": "11864",
            "Jogállás": "6",
            "Terület": "5830",
            "Népesség": "2831",
            "Lakások": "978"
        },
        {
            "Ir. szám": "6348",
            "name": "Érsekhalma",
            "Megyekód": "BK",
            "Lat": "46.35",
            "Long": "19.1167",
            "KSH kód": "33589",
            "Jogállás": "6",
            "Terület": "2756",
            "Népesség": "657",
            "Lakások": "293"
        },
        {
            "Ir. szám": "6351",
            "name": "Bátya",
            "Megyekód": "BK",
            "Lat": "46.4833",
            "Long": "18.95",
            "KSH kód": "11961",
            "Jogállás": "6",
            "Terület": "3386",
            "Népesség": "2132",
            "Lakások": "970"
        },
        {
            "Ir. szám": "6352",
            "name": "Fajsz",
            "Megyekód": "BK",
            "Lat": "46.4167",
            "Long": "18.9167",
            "KSH kód": "03230",
            "Jogállás": "6",
            "Terület": "3199",
            "Népesség": "1759",
            "Lakások": "884"
        },
        {
            "Ir. szám": "6353",
            "name": "Dusnok",
            "Megyekód": "BK",
            "Lat": "46.3833",
            "Long": "18.9667",
            "KSH kód": "04109",
            "Jogállás": "6",
            "Terület": "5747",
            "Népesség": "3022",
            "Lakások": "1246"
        },
        {
            "Ir. szám": "6400",
            "name": "Kiskunhalas",
            "Megyekód": "BK",
            "Lat": "46.4314",
            "Long": "19.4875",
            "KSH kód": "32434",
            "Jogállás": "4",
            "Terület": "22758",
            "Népesség": "28997",
            "Lakások": "12208"
        },
        {
            "Ir. szám": "6401",
            "name": "Kiskunhalas",
            "Megyekód": "BK",
            "Lat": "46.4314",
            "Long": "19.4875",
            "KSH kód": "32434",
            "Jogállás": "4",
            "Terület": "22758",
            "Népesség": "28997",
            "Lakások": "12208"
        },
        {
            "Ir. szám": "6402",
            "name": "Kiskunhalas",
            "Megyekód": "BK",
            "Lat": "46.4314",
            "Long": "19.4875",
            "KSH kód": "32434",
            "Jogállás": "4",
            "Terület": "22758",
            "Népesség": "28997",
            "Lakások": "12208"
        },
        {
            "Ir. szám": "6411",
            "name": "Zsana",
            "Megyekód": "BK",
            "Lat": "46.4167",
            "Long": "19.6833",
            "KSH kód": "15158",
            "Jogállás": "6",
            "Terület": "8794",
            "Népesség": "811",
            "Lakások": "448"
        },
        {
            "Ir. szám": "6412",
            "name": "Balotaszállás",
            "Megyekód": "BK",
            "Lat": "46.35",
            "Long": "19.5333",
            "KSH kód": "25937",
            "Jogállás": "6",
            "Terület": "10494",
            "Népesség": "1541",
            "Lakások": "821"
        },
        {
            "Ir. szám": "6413",
            "name": "Kunfehértó",
            "Megyekód": "BK",
            "Lat": "46.35",
            "Long": "19.4167",
            "KSH kód": "29027",
            "Jogállás": "6",
            "Terület": "7837",
            "Népesség": "2089",
            "Lakások": "911"
        },
        {
            "Ir. szám": "6414",
            "name": "Pirtó",
            "Megyekód": "BK",
            "Lat": "46.5167",
            "Long": "19.4333",
            "KSH kód": "15398",
            "Jogállás": "6",
            "Terület": "3448",
            "Népesség": "976",
            "Lakások": "440"
        },
        {
            "Ir. szám": "6421",
            "name": "Kisszállás",
            "Megyekód": "BK",
            "Lat": "46.2783",
            "Long": "19.4961",
            "KSH kód": "28158",
            "Jogállás": "6",
            "Terület": "9205",
            "Népesség": "2558",
            "Lakások": "1212"
        },
        {
            "Ir. szám": "6422",
            "name": "Tompa",
            "Megyekód": "BK",
            "Lat": "46.2178",
            "Long": "19.5422",
            "KSH kód": "28486",
            "Jogállás": "4",
            "Terület": "8157",
            "Népesség": "4689",
            "Lakások": "2081"
        },
        {
            "Ir. szám": "6423",
            "name": "Kelebia",
            "Megyekód": "BK",
            "Lat": "46.1939",
            "Long": "19.6119",
            "KSH kód": "27571",
            "Jogállás": "6",
            "Terület": "6670",
            "Népesség": "2774",
            "Lakások": "1320"
        },
        {
            "Ir. szám": "6424",
            "name": "Csikéria",
            "Megyekód": "BK",
            "Lat": "46.1222",
            "Long": "19.4789",
            "KSH kód": "15699",
            "Jogállás": "6",
            "Terület": "2581",
            "Népesség": "952",
            "Lakások": "501"
        },
        {
            "Ir. szám": "6425",
            "name": "Bácsszőlős",
            "Megyekód": "BK",
            "Lat": "46.15",
            "Long": "19.45",
            "KSH kód": "30155",
            "Jogállás": "6",
            "Terület": "3883",
            "Népesség": "388",
            "Lakások": "272"
        },
        {
            "Ir. szám": "6430",
            "name": "Bácsalmás",
            "Megyekód": "BK",
            "Lat": "46.1253",
            "Long": "19.3361",
            "KSH kód": "10719",
            "Jogállás": "4",
            "Terület": "10832",
            "Népesség": "7045",
            "Lakások": "3287"
        },
        {
            "Ir. szám": "6435",
            "name": "Kunbaja",
            "Megyekód": "BK",
            "Lat": "46.0836",
            "Long": "19.4278",
            "KSH kód": "06044",
            "Jogállás": "6",
            "Terület": "3372",
            "Népesség": "1598",
            "Lakások": "757"
        },
        {
            "Ir. szám": "6440",
            "name": "Jánoshalma",
            "Megyekód": "BK",
            "Lat": "46.2986",
            "Long": "19.3258",
            "KSH kód": "09469",
            "Jogállás": "4",
            "Terület": "13221",
            "Népesség": "9343",
            "Lakások": "4476"
        },
        {
            "Ir. szám": "6444",
            "name": "Kéleshalom",
            "Megyekód": "BK",
            "Lat": "46.3667",
            "Long": "19.25",
            "KSH kód": "18166",
            "Jogállás": "6",
            "Terület": "6163",
            "Népesség": "451",
            "Lakások": "336"
        },
        {
            "Ir. szám": "6445",
            "name": "Borota",
            "Megyekód": "BK",
            "Lat": "46.2631",
            "Long": "19.2253",
            "KSH kód": "19327",
            "Jogállás": "6",
            "Terület": "8180",
            "Népesség": "1365",
            "Lakások": "739"
        },
        {
            "Ir. szám": "6446",
            "name": "Rém",
            "Megyekód": "BK",
            "Lat": "46.2431",
            "Long": "19.1461",
            "KSH kód": "26310",
            "Jogállás": "6",
            "Terület": "3993",
            "Népesség": "1371",
            "Lakások": "576"
        },
        {
            "Ir. szám": "6447",
            "name": "Felsőszentiván",
            "Megyekód": "BK",
            "Lat": "46.195",
            "Long": "19.1903",
            "KSH kód": "02954",
            "Jogállás": "6",
            "Terület": "5354",
            "Népesség": "1990",
            "Lakások": "882"
        },
        {
            "Ir. szám": "6448",
            "name": "Csávoly",
            "Megyekód": "BK",
            "Lat": "46.1892",
            "Long": "19.1467",
            "KSH kód": "16373",
            "Jogállás": "6",
            "Terület": "4742",
            "Népesség": "1927",
            "Lakások": "824"
        },
        {
            "Ir. szám": "6449",
            "name": "Mélykút",
            "Megyekód": "BK",
            "Lat": "46.2111",
            "Long": "19.3869",
            "KSH kód": "16018",
            "Jogállás": "5",
            "Terület": "12346",
            "Népesség": "5403",
            "Lakások": "2918"
        },
        {
            "Ir. szám": "6451",
            "name": "Tataháza",
            "Megyekód": "BK",
            "Lat": "46.1742",
            "Long": "19.3061",
            "KSH kód": "14544",
            "Jogállás": "6",
            "Terület": "2610",
            "Népesség": "1300",
            "Lakások": "688"
        },
        {
            "Ir. szám": "6452",
            "name": "Mátételke",
            "Megyekód": "BK",
            "Lat": "46.1667",
            "Long": "19.2833",
            "KSH kód": "27809",
            "Jogállás": "6",
            "Terület": "2793",
            "Népesség": "549",
            "Lakások": "298"
        },
        {
            "Ir. szám": "6453",
            "name": "Bácsbokod",
            "Megyekód": "BK",
            "Lat": "46.1219",
            "Long": "19.1589",
            "KSH kód": "10180",
            "Jogállás": "5",
            "Terület": "6393",
            "Népesség": "2804",
            "Lakások": "1248"
        },
        {
            "Ir. szám": "6454",
            "name": "Bácsborsód",
            "Megyekód": "BK",
            "Lat": "46.0964",
            "Long": "19.1581",
            "KSH kód": "27234",
            "Jogállás": "6",
            "Terület": "7752",
            "Népesség": "1238",
            "Lakások": "427"
        },
        {
            "Ir. szám": "6455",
            "name": "Katymár",
            "Megyekód": "BK",
            "Lat": "46.0322",
            "Long": "19.2108",
            "KSH kód": "11280",
            "Jogállás": "6",
            "Terület": "7108",
            "Népesség": "2229",
            "Lakások": "991"
        },
        {
            "Ir. szám": "6456",
            "name": "Madaras",
            "Megyekód": "BK",
            "Lat": "46.055",
            "Long": "19.2667",
            "KSH kód": "23357",
            "Jogállás": "6",
            "Terület": "4930",
            "Népesség": "3046",
            "Lakások": "1558"
        },
        {
            "Ir. szám": "6500",
            "name": "Baja",
            "Megyekód": "BK",
            "Lat": "46.1833",
            "Long": "18.9667",
            "KSH kód": "03522",
            "Jogállás": "4",
            "Terület": "17761",
            "Népesség": "37707",
            "Lakások": "15969"
        },
        {
            "Ir. szám": "6501",
            "name": "Baja",
            "Megyekód": "BK",
            "Lat": "46.1833",
            "Long": "18.9667",
            "KSH kód": "03522",
            "Jogállás": "4",
            "Terület": "17761",
            "Népesség": "37707",
            "Lakások": "15969"
        },
        {
            "Ir. szám": "6502",
            "name": "Baja",
            "Megyekód": "BK",
            "Lat": "46.1833",
            "Long": "18.9667",
            "KSH kód": "03522",
            "Jogállás": "4",
            "Terület": "17761",
            "Népesség": "37707",
            "Lakások": "15969"
        },
        {
            "Ir. szám": "6503",
            "name": "Baja",
            "Megyekód": "BK",
            "Lat": "46.1833",
            "Long": "18.9667",
            "KSH kód": "03522",
            "Jogállás": "4",
            "Terület": "17761",
            "Népesség": "37707",
            "Lakások": "15969"
        },
        {
            "Ir. szám": "6511",
            "name": "Bácsszentgyörgy",
            "Megyekód": "BK",
            "Lat": "45.9722",
            "Long": "19.0389",
            "KSH kód": "08697",
            "Jogállás": "6",
            "Terület": "1473",
            "Népesség": "159",
            "Lakások": "98"
        },
        {
            "Ir. szám": "6512",
            "name": "Szeremle",
            "Megyekód": "BK",
            "Lat": "46.15",
            "Long": "18.8833",
            "KSH kód": "28820",
            "Jogállás": "6",
            "Terület": "3464",
            "Népesség": "1511",
            "Lakások": "627"
        },
        {
            "Ir. szám": "6513",
            "name": "Dunafalva",
            "Megyekód": "BK",
            "Lat": "46.0611",
            "Long": "18.9611",
            "KSH kód": "12566",
            "Jogállás": "6",
            "Terület": "5789",
            "Népesség": "876",
            "Lakások": "581"
        },
        {
            "Ir. szám": "6521",
            "name": "Vaskút",
            "Megyekód": "BK",
            "Lat": "46.1167",
            "Long": "18.9833",
            "KSH kód": "28343",
            "Jogállás": "6",
            "Terület": "7149",
            "Népesség": "3506",
            "Lakások": "1363"
        },
        {
            "Ir. szám": "6522",
            "name": "Gara",
            "Megyekód": "BK",
            "Lat": "46.0319",
            "Long": "19.0428",
            "KSH kód": "31848",
            "Jogállás": "6",
            "Terület": "5996",
            "Népesség": "2556",
            "Lakások": "953"
        },
        {
            "Ir. szám": "6523",
            "name": "Csátalja",
            "Megyekód": "BK",
            "Lat": "46.0333",
            "Long": "18.95",
            "KSH kód": "26471",
            "Jogállás": "6",
            "Terület": "3905",
            "Népesség": "1557",
            "Lakások": "668"
        },
        {
            "Ir. szám": "6524",
            "name": "Dávod",
            "Megyekód": "BK",
            "Lat": "45.995",
            "Long": "18.9172",
            "KSH kód": "10533",
            "Jogállás": "6",
            "Terület": "6921",
            "Népesség": "1984",
            "Lakások": "1078"
        },
        {
            "Ir. szám": "6525",
            "name": "Hercegszántó",
            "Megyekód": "BK",
            "Lat": "45.95",
            "Long": "18.9392",
            "KSH kód": "12937",
            "Jogállás": "6",
            "Terület": "6855",
            "Népesség": "2008",
            "Lakások": "1150"
        },
        {
            "Ir. szám": "6527",
            "name": "Nagybaracska",
            "Megyekód": "BK",
            "Lat": "46.05",
            "Long": "18.9",
            "KSH kód": "25955",
            "Jogállás": "6",
            "Terület": "3795",
            "Népesség": "2325",
            "Lakások": "1030"
        },
        {
            "Ir. szám": "6528",
            "name": "Bátmonostor",
            "Megyekód": "BK",
            "Lat": "46.1",
            "Long": "18.9333",
            "KSH kód": "03656",
            "Jogállás": "6",
            "Terület": "3795",
            "Népesség": "1574",
            "Lakások": "699"
        },
        {
            "Ir. szám": "6600",
            "name": "Szentes",
            "Megyekód": "CS",
            "Lat": "46.65",
            "Long": "20.2667",
            "KSH kód": "14456",
            "Jogállás": "4",
            "Terület": "35325",
            "Népesség": "29117",
            "Lakások": "12722"
        },
        {
            "Ir. szám": "6601",
            "name": "Szentes",
            "Megyekód": "CS",
            "Lat": "46.65",
            "Long": "20.2667",
            "KSH kód": "14456",
            "Jogállás": "4",
            "Terület": "35325",
            "Népesség": "29117",
            "Lakások": "12722"
        },
        {
            "Ir. szám": "6602",
            "name": "Szentes",
            "Megyekód": "CS",
            "Lat": "46.65",
            "Long": "20.2667",
            "KSH kód": "14456",
            "Jogállás": "4",
            "Terület": "35325",
            "Népesség": "29117",
            "Lakások": "12722"
        },
        {
            "Ir. szám": "6603",
            "name": "Szentes",
            "Megyekód": "CS",
            "Lat": "46.65",
            "Long": "20.2667",
            "KSH kód": "14456",
            "Jogállás": "4",
            "Terület": "35325",
            "Népesség": "29117",
            "Lakások": "12722"
        },
        {
            "Ir. szám": "6612",
            "name": "Nagytőke",
            "Megyekód": "CS",
            "Lat": "46.7",
            "Long": "20.2833",
            "KSH kód": "29179",
            "Jogállás": "6",
            "Terület": "5468",
            "Népesség": "455",
            "Lakások": "233"
        },
        {
            "Ir. szám": "6621",
            "name": "Derekegyház",
            "Megyekód": "CS",
            "Lat": "46.5833",
            "Long": "20.3667",
            "KSH kód": "07834",
            "Jogállás": "6",
            "Terület": "5378",
            "Népesség": "1670",
            "Lakások": "678"
        },
        {
            "Ir. szám": "6622",
            "name": "Nagymágocs",
            "Megyekód": "CS",
            "Lat": "46.5833",
            "Long": "20.5",
            "KSH kód": "17233",
            "Jogállás": "5",
            "Terület": "7509",
            "Népesség": "3152",
            "Lakások": "1249"
        },
        {
            "Ir. szám": "6623",
            "name": "Árpádhalom",
            "Megyekód": "CS",
            "Lat": "46.6167",
            "Long": "20.55",
            "KSH kód": "19062",
            "Jogállás": "6",
            "Terület": "4520",
            "Népesség": "529",
            "Lakások": "259"
        },
        {
            "Ir. szám": "6624",
            "name": "Eperjes",
            "Megyekód": "CS",
            "Lat": "46.6833",
            "Long": "20.5667",
            "KSH kód": "22992",
            "Jogállás": "6",
            "Terület": "7389",
            "Népesség": "547",
            "Lakások": "349"
        },
        {
            "Ir. szám": "6625",
            "name": "Fábiánsebestyén",
            "Megyekód": "CS",
            "Lat": "46.6833",
            "Long": "20.4667",
            "KSH kód": "19974",
            "Jogállás": "6",
            "Terület": "7173",
            "Népesség": "2134",
            "Lakások": "895"
        },
        {
            "Ir. szám": "6630",
            "name": "Mindszent",
            "Megyekód": "CS",
            "Lat": "46.5333",
            "Long": "20.2",
            "KSH kód": "21555",
            "Jogállás": "4",
            "Terület": "5935",
            "Népesség": "6909",
            "Lakások": "3187"
        },
        {
            "Ir. szám": "6635",
            "name": "Szegvár",
            "Megyekód": "CS",
            "Lat": "46.5833",
            "Long": "20.2333",
            "KSH kód": "32489",
            "Jogállás": "5",
            "Terület": "8622",
            "Népesség": "4673",
            "Lakások": "2096"
        },
        {
            "Ir. szám": "6636",
            "name": "Mártély",
            "Megyekód": "CS",
            "Lat": "46.4833",
            "Long": "20.2167",
            "KSH kód": "25733",
            "Jogállás": "6",
            "Terület": "3645",
            "Népesség": "1368",
            "Lakások": "568"
        },
        {
            "Ir. szám": "6640",
            "name": "Csongrád",
            "Megyekód": "CS",
            "Lat": "46.7",
            "Long": "20.15",
            "KSH kód": "05111",
            "Jogállás": "4",
            "Terület": "17389",
            "Népesség": "17686",
            "Lakások": "8468"
        },
        {
            "Ir. szám": "6641",
            "name": "Csongrád",
            "Megyekód": "CS",
            "Lat": "46.7",
            "Long": "20.15",
            "KSH kód": "05111",
            "Jogállás": "4",
            "Terület": "17389",
            "Népesség": "17686",
            "Lakások": "8468"
        },
        {
            "Ir. szám": "6642",
            "name": "Csongrád",
            "Megyekód": "CS",
            "Lat": "46.7",
            "Long": "20.15",
            "KSH kód": "05111",
            "Jogállás": "4",
            "Terület": "17389",
            "Népesség": "17686",
            "Lakások": "8468"
        },
        {
            "Ir. szám": "6645",
            "name": "Felgyő",
            "Megyekód": "CS",
            "Lat": "46.65",
            "Long": "20.1",
            "KSH kód": "22646",
            "Jogállás": "6",
            "Terület": "7673",
            "Népesség": "1324",
            "Lakások": "616"
        },
        {
            "Ir. szám": "6646",
            "name": "Tömörkény",
            "Megyekód": "CS",
            "Lat": "46.6167",
            "Long": "20.0333",
            "KSH kód": "25900",
            "Jogállás": "6",
            "Terület": "5391",
            "Népesség": "1836",
            "Lakások": "1034"
        },
        {
            "Ir. szám": "6647",
            "name": "Csanytelek",
            "Megyekód": "CS",
            "Lat": "46.6",
            "Long": "20.1167",
            "KSH kód": "22293",
            "Jogállás": "6",
            "Terület": "3471",
            "Népesség": "2848",
            "Lakások": "1408"
        },
        {
            "Ir. szám": "6648",
            "name": "Csanytelek",
            "Megyekód": "CS",
            "Lat": "46.6",
            "Long": "20.1167",
            "KSH kód": "22293",
            "Jogállás": "6",
            "Terület": "3471",
            "Népesség": "2848",
            "Lakások": "1408"
        },
        {
            "Ir. szám": "6700",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6701",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6702",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6703",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6704",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6705",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6706",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6707",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6708",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6709",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6710",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6712",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6713",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6717",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6718",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6719",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6720",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6721",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6722",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6723",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6724",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6725",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6726",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6727",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6728",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6729",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6740",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6741",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6742",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6743",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6744",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6745",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6746",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6747",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6748",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.318",
            "Long": "20.027",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6750",
            "name": "Algyő",
            "Megyekód": "CS",
            "Lat": "46.17",
            "Long": "20.17",
            "KSH kód": "34245",
            "Jogállás": "5",
            "Terület": "7577",
            "Népesség": "5247",
            "Lakások": "2018"
        },
        {
            "Ir. szám": "6750",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.17",
            "Long": "20.17",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6753",
            "name": "Algyő",
            "Megyekód": "CS",
            "Lat": "46.17",
            "Long": "20.17",
            "KSH kód": "34245",
            "Jogállás": "5",
            "Terület": "7577",
            "Népesség": "5247",
            "Lakások": "2018"
        },
        {
            "Ir. szám": "6753",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.17",
            "Long": "20.17",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6754",
            "name": "Újszentiván",
            "Megyekód": "CS",
            "Lat": "46.1833",
            "Long": "20.1833",
            "KSH kód": "14924",
            "Jogállás": "6",
            "Terület": "1549",
            "Népesség": "1657",
            "Lakások": "542"
        },
        {
            "Ir. szám": "6755",
            "name": "Kübekháza",
            "Megyekód": "CS",
            "Lat": "46.15",
            "Long": "20.2833",
            "KSH kód": "14410",
            "Jogállás": "6",
            "Terület": "2731",
            "Népesség": "1561",
            "Lakások": "592"
        },
        {
            "Ir. szám": "6756",
            "name": "Tiszasziget",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.1667",
            "KSH kód": "16966",
            "Jogállás": "6",
            "Terület": "2689",
            "Népesség": "1767",
            "Lakások": "614"
        },
        {
            "Ir. szám": "6757",
            "name": "Tiszasziget",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.1667",
            "KSH kód": "16966",
            "Jogállás": "6",
            "Terület": "2689",
            "Népesség": "1767",
            "Lakások": "614"
        },
        {
            "Ir. szám": "6757",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.1667",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6758",
            "name": "Röszke",
            "Megyekód": "CS",
            "Lat": "46.1833",
            "Long": "20.05",
            "KSH kód": "13161",
            "Jogállás": "6",
            "Terület": "3663",
            "Népesség": "3269",
            "Lakások": "1410"
        },
        {
            "Ir. szám": "6760",
            "name": "Kistelek",
            "Megyekód": "CS",
            "Lat": "46.4725",
            "Long": "19.9797",
            "KSH kód": "31024",
            "Jogállás": "4",
            "Terület": "6919",
            "Népesség": "7198",
            "Lakások": "3405"
        },
        {
            "Ir. szám": "6762",
            "name": "Sándorfalva",
            "Megyekód": "CS",
            "Lat": "46.3667",
            "Long": "20.1",
            "KSH kód": "31705",
            "Jogállás": "4",
            "Terület": "5577",
            "Népesség": "8042",
            "Lakások": "3073"
        },
        {
            "Ir. szám": "6763",
            "name": "Szatymaz",
            "Megyekód": "CS",
            "Lat": "46.35",
            "Long": "20.05",
            "KSH kód": "12007",
            "Jogállás": "6",
            "Terület": "5372",
            "Népesség": "4593",
            "Lakások": "1932"
        },
        {
            "Ir. szám": "6764",
            "name": "Balástya",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.0167",
            "KSH kód": "23676",
            "Jogállás": "6",
            "Terület": "11000",
            "Népesség": "3450",
            "Lakások": "1929"
        },
        {
            "Ir. szám": "6765",
            "name": "Csengele",
            "Megyekód": "CS",
            "Lat": "46.5333",
            "Long": "19.8667",
            "KSH kód": "32285",
            "Jogállás": "6",
            "Terület": "6066",
            "Népesség": "1958",
            "Lakások": "991"
        },
        {
            "Ir. szám": "6766",
            "name": "Dóc",
            "Megyekód": "CS",
            "Lat": "46.4333",
            "Long": "20.1333",
            "KSH kód": "30535",
            "Jogállás": "6",
            "Terület": "4943",
            "Népesség": "786",
            "Lakások": "326"
        },
        {
            "Ir. szám": "6767",
            "name": "Ópusztaszer",
            "Megyekód": "CS",
            "Lat": "46.4833",
            "Long": "20.0833",
            "KSH kód": "12797",
            "Jogállás": "6",
            "Terület": "5950",
            "Népesség": "2236",
            "Lakások": "941"
        },
        {
            "Ir. szám": "6768",
            "name": "Baks",
            "Megyekód": "CS",
            "Lat": "46.55",
            "Long": "20.1",
            "KSH kód": "29106",
            "Jogállás": "6",
            "Terület": "6192",
            "Népesség": "2141",
            "Lakások": "881"
        },
        {
            "Ir. szám": "6769",
            "name": "Pusztaszer",
            "Megyekód": "CS",
            "Lat": "46.55",
            "Long": "19.9833",
            "KSH kód": "28592",
            "Jogállás": "6",
            "Terület": "4893",
            "Népesség": "1520",
            "Lakások": "785"
        },
        {
            "Ir. szám": "6771",
            "name": "Pusztaszer",
            "Megyekód": "CS",
            "Lat": "46.55",
            "Long": "19.9833",
            "KSH kód": "28592",
            "Jogállás": "6",
            "Terület": "4893",
            "Népesség": "1520",
            "Lakások": "785"
        },
        {
            "Ir. szám": "6771",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.55",
            "Long": "19.9833",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6772",
            "name": "Deszk",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.25",
            "KSH kód": "24077",
            "Jogállás": "6",
            "Terület": "5205",
            "Népesség": "3584",
            "Lakások": "1233"
        },
        {
            "Ir. szám": "6773",
            "name": "Klárafalva",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.3333",
            "KSH kód": "08253",
            "Jogállás": "6",
            "Terület": "910",
            "Népesség": "513",
            "Lakások": "199"
        },
        {
            "Ir. szám": "6774",
            "name": "Ferencszállás",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.35",
            "KSH kód": "18999",
            "Jogállás": "6",
            "Terület": "579",
            "Népesség": "619",
            "Lakások": "272"
        },
        {
            "Ir. szám": "6775",
            "name": "Kiszombor",
            "Megyekód": "CS",
            "Lat": "46.1833",
            "Long": "20.4333",
            "KSH kód": "26666",
            "Jogállás": "6",
            "Terület": "6581",
            "Népesség": "3970",
            "Lakások": "1651"
        },
        {
            "Ir. szám": "6781",
            "name": "Domaszék",
            "Megyekód": "CS",
            "Lat": "46.2492",
            "Long": "20.0111",
            "KSH kód": "13383",
            "Jogállás": "6",
            "Terület": "5215",
            "Népesség": "4851",
            "Lakások": "1900"
        },
        {
            "Ir. szám": "6782",
            "name": "Mórahalom",
            "Megyekód": "CS",
            "Lat": "46.2194",
            "Long": "19.8911",
            "KSH kód": "04349",
            "Jogállás": "4",
            "Terület": "8315",
            "Népesség": "6007",
            "Lakások": "2549"
        },
        {
            "Ir. szám": "6783",
            "name": "Ásotthalom",
            "Megyekód": "CS",
            "Lat": "46.2006",
            "Long": "19.7906",
            "KSH kód": "10339",
            "Jogállás": "6",
            "Terület": "12254",
            "Népesség": "4063",
            "Lakások": "1988"
        },
        {
            "Ir. szám": "6784",
            "name": "Öttömös",
            "Megyekód": "CS",
            "Lat": "46.2833",
            "Long": "19.6833",
            "KSH kód": "31079",
            "Jogállás": "6",
            "Terület": "3091",
            "Népesség": "782",
            "Lakások": "426"
        },
        {
            "Ir. szám": "6785",
            "name": "Pusztamérges",
            "Megyekód": "CS",
            "Lat": "46.3333",
            "Long": "19.6833",
            "KSH kód": "06354",
            "Jogállás": "6",
            "Terület": "2439",
            "Népesség": "1261",
            "Lakások": "613"
        },
        {
            "Ir. szám": "6786",
            "name": "Ruzsa",
            "Megyekód": "CS",
            "Lat": "46.2897",
            "Long": "19.7544",
            "KSH kód": "03966",
            "Jogállás": "6",
            "Terület": "8468",
            "Népesség": "2578",
            "Lakások": "1439"
        },
        {
            "Ir. szám": "6787",
            "name": "Zákányszék",
            "Megyekód": "CS",
            "Lat": "46.2667",
            "Long": "19.9167",
            "KSH kód": "05546",
            "Jogállás": "6",
            "Terület": "6607",
            "Népesség": "2793",
            "Lakások": "1447"
        },
        {
            "Ir. szám": "6791",
            "name": "Szeged",
            "Megyekód": "CS",
            "Lat": "46.2667",
            "Long": "19.9167",
            "KSH kód": "33367",
            "Jogállás": "1",
            "Terület": "28100",
            "Népesség": "169030",
            "Lakások": "75739"
        },
        {
            "Ir. szám": "6791",
            "name": "Zákányszék",
            "Megyekód": "CS",
            "Lat": "46.2667",
            "Long": "19.9167",
            "KSH kód": "05546",
            "Jogállás": "6",
            "Terület": "6607",
            "Népesség": "2793",
            "Lakások": "1447"
        },
        {
            "Ir. szám": "6792",
            "name": "Zsombó",
            "Megyekód": "CS",
            "Lat": "46.3333",
            "Long": "19.9833",
            "KSH kód": "17765",
            "Jogállás": "6",
            "Terület": "2689",
            "Népesség": "3475",
            "Lakások": "1340"
        },
        {
            "Ir. szám": "6793",
            "name": "Forráskút",
            "Megyekód": "CS",
            "Lat": "46.3667",
            "Long": "19.9167",
            "KSH kód": "33020",
            "Jogállás": "6",
            "Terület": "3667",
            "Népesség": "2335",
            "Lakások": "1044"
        },
        {
            "Ir. szám": "6794",
            "name": "Üllés",
            "Megyekód": "CS",
            "Lat": "46.3381",
            "Long": "19.8519",
            "KSH kód": "21412",
            "Jogállás": "6",
            "Terület": "4988",
            "Népesség": "3217",
            "Lakások": "1520"
        },
        {
            "Ir. szám": "6795",
            "name": "Bordány",
            "Megyekód": "CS",
            "Lat": "46.3217",
            "Long": "19.925",
            "KSH kód": "08192",
            "Jogállás": "6",
            "Terület": "3648",
            "Népesség": "3155",
            "Lakások": "1421"
        },
        {
            "Ir. szám": "6800",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6801",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6802",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6803",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6804",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6806",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6808",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6811",
            "name": "Hódmezővásárhely",
            "Megyekód": "CS",
            "Lat": "46.4167",
            "Long": "20.3333",
            "KSH kód": "08314",
            "Jogállás": "3",
            "Terület": "48798",
            "Népesség": "47258",
            "Lakások": "19616"
        },
        {
            "Ir. szám": "6821",
            "name": "Székkutas",
            "Megyekód": "CS",
            "Lat": "46.5",
            "Long": "20.5333",
            "KSH kód": "12265",
            "Jogállás": "6",
            "Terület": "12399",
            "Népesség": "2422",
            "Lakások": "1080"
        },
        {
            "Ir. szám": "6900",
            "name": "Makó",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.4833",
            "KSH kód": "07357",
            "Jogállás": "4",
            "Terület": "22923",
            "Népesség": "24403",
            "Lakások": "11422"
        },
        {
            "Ir. szám": "6901",
            "name": "Makó",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.4833",
            "KSH kód": "07357",
            "Jogállás": "4",
            "Terület": "22923",
            "Népesség": "24403",
            "Lakások": "11422"
        },
        {
            "Ir. szám": "6902",
            "name": "Makó",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.4833",
            "KSH kód": "07357",
            "Jogállás": "4",
            "Terület": "22923",
            "Népesség": "24403",
            "Lakások": "11422"
        },
        {
            "Ir. szám": "6903",
            "name": "Makó",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.4833",
            "KSH kód": "07357",
            "Jogállás": "4",
            "Terület": "22923",
            "Népesség": "24403",
            "Lakások": "11422"
        },
        {
            "Ir. szám": "6911",
            "name": "Királyhegyes",
            "Megyekód": "CS",
            "Lat": "46.2667",
            "Long": "20.6167",
            "KSH kód": "13666",
            "Jogállás": "6",
            "Terület": "2980",
            "Népesség": "664",
            "Lakások": "373"
        },
        {
            "Ir. szám": "6912",
            "name": "Kövegy",
            "Megyekód": "CS",
            "Lat": "46.2167",
            "Long": "20.6833",
            "KSH kód": "09955",
            "Jogállás": "6",
            "Terület": "971",
            "Népesség": "411",
            "Lakások": "254"
        },
        {
            "Ir. szám": "6913",
            "name": "Csanádpalota",
            "Megyekód": "CS",
            "Lat": "46.25",
            "Long": "20.7333",
            "KSH kód": "05379",
            "Jogállás": "5",
            "Terület": "7776",
            "Népesség": "3064",
            "Lakások": "1680"
        },
        {
            "Ir. szám": "6914",
            "name": "Pitvaros",
            "Megyekód": "CS",
            "Lat": "46.2972",
            "Long": "20.7028",
            "KSH kód": "06284",
            "Jogállás": "6",
            "Terület": "1314",
            "Népesség": "1417",
            "Lakások": "677"
        },
        {
            "Ir. szám": "6915",
            "name": "Csanádalberti",
            "Megyekód": "CS",
            "Lat": "46.3333",
            "Long": "20.7167",
            "KSH kód": "02121",
            "Jogállás": "6",
            "Terület": "1532",
            "Népesség": "440",
            "Lakások": "261"
        },
        {
            "Ir. szám": "6916",
            "name": "Ambrózfalva",
            "Megyekód": "CS",
            "Lat": "46.35",
            "Long": "20.7333",
            "KSH kód": "16197",
            "Jogállás": "6",
            "Terület": "1122",
            "Népesség": "509",
            "Lakások": "247"
        },
        {
            "Ir. szám": "6917",
            "name": "Nagyér",
            "Megyekód": "CS",
            "Lat": "46.3667",
            "Long": "20.7333",
            "KSH kód": "20914",
            "Jogállás": "6",
            "Terület": "1229",
            "Népesség": "526",
            "Lakások": "269"
        },
        {
            "Ir. szám": "6921",
            "name": "Maroslele",
            "Megyekód": "CS",
            "Lat": "46.2667",
            "Long": "20.35",
            "KSH kód": "10515",
            "Jogállás": "6",
            "Terület": "4656",
            "Népesség": "2084",
            "Lakások": "937"
        },
        {
            "Ir. szám": "6922",
            "name": "Földeák",
            "Megyekód": "CS",
            "Lat": "46.3167",
            "Long": "20.5",
            "KSH kód": "09210",
            "Jogállás": "6",
            "Terület": "3637",
            "Népesség": "3199",
            "Lakások": "1478"
        },
        {
            "Ir. szám": "6923",
            "name": "Óföldeák",
            "Megyekód": "CS",
            "Lat": "46.3",
            "Long": "20.45",
            "KSH kód": "07755",
            "Jogállás": "6",
            "Terület": "3509",
            "Népesség": "454",
            "Lakások": "192"
        },
        {
            "Ir. szám": "6931",
            "name": "Apátfalva",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.5833",
            "KSH kód": "14252",
            "Jogállás": "6",
            "Terület": "5377",
            "Népesség": "3065",
            "Lakások": "1597"
        },
        {
            "Ir. szám": "6932",
            "name": "Magyarcsanád",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.6167",
            "KSH kód": "05962",
            "Jogállás": "6",
            "Terület": "4819",
            "Népesség": "1525",
            "Lakások": "752"
        },
        {
            "Ir. szám": "6933",
            "name": "Nagylak",
            "Megyekód": "CS",
            "Lat": "46.1667",
            "Long": "20.7167",
            "KSH kód": "12779",
            "Jogállás": "6",
            "Terület": "470",
            "Népesség": "479",
            "Lakások": "296"
        },
        {
            "Ir. szám": "7000",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7001",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7002",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7003",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7011",
            "name": "Alap",
            "Megyekód": "FE",
            "Lat": "46.8",
            "Long": "18.7",
            "KSH kód": "26824",
            "Jogállás": "6",
            "Terület": "4829",
            "Népesség": "2005",
            "Lakások": "902"
        },
        {
            "Ir. szám": "7012",
            "name": "Alsószentiván",
            "Megyekód": "FE",
            "Lat": "46.7833",
            "Long": "18.7333",
            "KSH kód": "25283",
            "Jogállás": "6",
            "Terület": "3965",
            "Népesség": "650",
            "Lakások": "289"
        },
        {
            "Ir. szám": "7013",
            "name": "Cece",
            "Megyekód": "FE",
            "Lat": "46.7667",
            "Long": "18.65",
            "KSH kód": "13152",
            "Jogállás": "5",
            "Terület": "5885",
            "Népesség": "2644",
            "Lakások": "1137"
        },
        {
            "Ir. szám": "7014",
            "name": "Sáregres",
            "Megyekód": "FE",
            "Lat": "46.7833",
            "Long": "18.6",
            "KSH kód": "02723",
            "Jogállás": "6",
            "Terület": "2616",
            "Népesség": "812",
            "Lakások": "356"
        },
        {
            "Ir. szám": "7015",
            "name": "Igar",
            "Megyekód": "FE",
            "Lat": "46.7833",
            "Long": "18.5167",
            "KSH kód": "17738",
            "Jogállás": "6",
            "Terület": "4119",
            "Népesség": "1022",
            "Lakások": "526"
        },
        {
            "Ir. szám": "7016",
            "name": "Igar",
            "Megyekód": "FE",
            "Lat": "46.7833",
            "Long": "18.5167",
            "KSH kód": "17738",
            "Jogállás": "6",
            "Terület": "4119",
            "Népesség": "1022",
            "Lakások": "526"
        },
        {
            "Ir. szám": "7017",
            "name": "Mezőszilas",
            "Megyekód": "FE",
            "Lat": "46.8167",
            "Long": "18.4833",
            "KSH kód": "29036",
            "Jogállás": "6",
            "Terület": "6497",
            "Népesség": "2157",
            "Lakások": "840"
        },
        {
            "Ir. szám": "7018",
            "name": "Mezőszilas",
            "Megyekód": "FE",
            "Lat": "46.8167",
            "Long": "18.4833",
            "KSH kód": "29036",
            "Jogállás": "6",
            "Terület": "6497",
            "Népesség": "2157",
            "Lakások": "840"
        },
        {
            "Ir. szám": "7019",
            "name": "Mezőszilas",
            "Megyekód": "FE",
            "Lat": "46.8167",
            "Long": "18.4833",
            "KSH kód": "29036",
            "Jogállás": "6",
            "Terület": "6497",
            "Népesség": "2157",
            "Lakások": "840"
        },
        {
            "Ir. szám": "7020",
            "name": "Dunaföldvár",
            "Megyekód": "TO",
            "Lat": "46.8",
            "Long": "18.9333",
            "KSH kód": "31501",
            "Jogállás": "4",
            "Terület": "11142",
            "Népesség": "9003",
            "Lakások": "4039"
        },
        {
            "Ir. szám": "7021",
            "name": "Dunaföldvár",
            "Megyekód": "TO",
            "Lat": "46.8",
            "Long": "18.9333",
            "KSH kód": "31501",
            "Jogállás": "4",
            "Terület": "11142",
            "Népesség": "9003",
            "Lakások": "4039"
        },
        {
            "Ir. szám": "7025",
            "name": "Bölcske",
            "Megyekód": "TO",
            "Lat": "46.7333",
            "Long": "18.9667",
            "KSH kód": "06558",
            "Jogállás": "6",
            "Terület": "5878",
            "Népesség": "2914",
            "Lakások": "1268"
        },
        {
            "Ir. szám": "7026",
            "name": "Madocsa",
            "Megyekód": "TO",
            "Lat": "46.6833",
            "Long": "18.9667",
            "KSH kód": "29337",
            "Jogállás": "6",
            "Terület": "4333",
            "Népesség": "1920",
            "Lakások": "841"
        },
        {
            "Ir. szám": "7027",
            "name": "Madocsa",
            "Megyekód": "TO",
            "Lat": "46.6833",
            "Long": "18.9667",
            "KSH kód": "29337",
            "Jogállás": "6",
            "Terület": "4333",
            "Népesség": "1920",
            "Lakások": "841"
        },
        {
            "Ir. szám": "7030",
            "name": "Paks",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.8667",
            "KSH kód": "04862",
            "Jogállás": "4",
            "Terület": "15408",
            "Népesség": "19833",
            "Lakások": "8241"
        },
        {
            "Ir. szám": "7031",
            "name": "Paks",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.8667",
            "KSH kód": "04862",
            "Jogállás": "4",
            "Terület": "15408",
            "Népesség": "19833",
            "Lakások": "8241"
        },
        {
            "Ir. szám": "7032",
            "name": "Paks",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.8667",
            "KSH kód": "04862",
            "Jogállás": "4",
            "Terület": "15408",
            "Népesség": "19833",
            "Lakások": "8241"
        },
        {
            "Ir. szám": "7038",
            "name": "Pusztahencse",
            "Megyekód": "TO",
            "Lat": "46.6",
            "Long": "18.7167",
            "KSH kód": "19938",
            "Jogállás": "6",
            "Terület": "3171",
            "Népesség": "1031",
            "Lakások": "411"
        },
        {
            "Ir. szám": "7039",
            "name": "Németkér",
            "Megyekód": "TO",
            "Lat": "46.7167",
            "Long": "18.7667",
            "KSH kód": "15006",
            "Jogállás": "6",
            "Terület": "6497",
            "Népesség": "1771",
            "Lakások": "754"
        },
        {
            "Ir. szám": "7041",
            "name": "Vajta",
            "Megyekód": "FE",
            "Lat": "46.7167",
            "Long": "18.6667",
            "KSH kód": "02459",
            "Jogállás": "6",
            "Terület": "2343",
            "Népesség": "1004",
            "Lakások": "370"
        },
        {
            "Ir. szám": "7042",
            "name": "Pálfa",
            "Megyekód": "TO",
            "Lat": "46.7167",
            "Long": "18.6333",
            "KSH kód": "09371",
            "Jogállás": "6",
            "Terület": "3474",
            "Népesség": "1592",
            "Lakások": "670"
        },
        {
            "Ir. szám": "7043",
            "name": "Bikács",
            "Megyekód": "TO",
            "Lat": "46.6833",
            "Long": "18.6667",
            "KSH kód": "11970",
            "Jogállás": "6",
            "Terület": "3467",
            "Népesség": "443",
            "Lakások": "220"
        },
        {
            "Ir. szám": "7044",
            "name": "Nagydorog",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.6667",
            "KSH kód": "18388",
            "Jogállás": "5",
            "Terület": "4144",
            "Népesség": "2711",
            "Lakások": "1113"
        },
        {
            "Ir. szám": "7045",
            "name": "Györköny",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.7",
            "KSH kód": "12326",
            "Jogállás": "6",
            "Terület": "3160",
            "Népesség": "922",
            "Lakások": "469"
        },
        {
            "Ir. szám": "7047",
            "name": "Sárszentlőrinc",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.6167",
            "KSH kód": "20817",
            "Jogállás": "6",
            "Terület": "3845",
            "Népesség": "960",
            "Lakások": "460"
        },
        {
            "Ir. szám": "7051",
            "name": "Kajdacs",
            "Megyekód": "TO",
            "Lat": "46.5667",
            "Long": "18.6333",
            "KSH kód": "14100",
            "Jogállás": "6",
            "Terület": "3773",
            "Népesség": "1277",
            "Lakások": "551"
        },
        {
            "Ir. szám": "7052",
            "name": "Kölesd",
            "Megyekód": "TO",
            "Lat": "46.5167",
            "Long": "18.5833",
            "KSH kód": "10463",
            "Jogállás": "6",
            "Terület": "3813",
            "Népesség": "1562",
            "Lakások": "613"
        },
        {
            "Ir. szám": "7054",
            "name": "Tengelic",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.7167",
            "KSH kód": "06901",
            "Jogállás": "6",
            "Terület": "7093",
            "Népesség": "2401",
            "Lakások": "1002"
        },
        {
            "Ir. szám": "7056",
            "name": "Szedres",
            "Megyekód": "TO",
            "Lat": "46.4833",
            "Long": "18.6833",
            "KSH kód": "16814",
            "Jogállás": "6",
            "Terület": "4631",
            "Népesség": "2324",
            "Lakások": "800"
        },
        {
            "Ir. szám": "7057",
            "name": "Medina",
            "Megyekód": "TO",
            "Lat": "46.4667",
            "Long": "18.65",
            "KSH kód": "30562",
            "Jogállás": "6",
            "Terület": "2224",
            "Népesség": "849",
            "Lakások": "351"
        },
        {
            "Ir. szám": "7061",
            "name": "Belecska",
            "Megyekód": "TO",
            "Lat": "46.65",
            "Long": "18.4167",
            "KSH kód": "28662",
            "Jogállás": "6",
            "Terület": "1439",
            "Népesség": "432",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7062",
            "name": "Keszőhidegkút",
            "Megyekód": "TO",
            "Lat": "46.6167",
            "Long": "18.4333",
            "KSH kód": "17640",
            "Jogállás": "6",
            "Terület": "1033",
            "Népesség": "210",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7063",
            "name": "Szárazd",
            "Megyekód": "TO",
            "Lat": "46.5667",
            "Long": "18.4333",
            "KSH kód": "18263",
            "Jogállás": "6",
            "Terület": "778",
            "Népesség": "230",
            "Lakások": "136"
        },
        {
            "Ir. szám": "7064",
            "name": "Gyönk",
            "Megyekód": "TO",
            "Lat": "46.55",
            "Long": "18.4833",
            "KSH kód": "30289",
            "Jogállás": "5",
            "Terület": "3812",
            "Népesség": "2051",
            "Lakások": "704"
        },
        {
            "Ir. szám": "7065",
            "name": "Miszla",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.4833",
            "KSH kód": "02811",
            "Jogállás": "6",
            "Terület": "3471",
            "Népesség": "280",
            "Lakások": "185"
        },
        {
            "Ir. szám": "7066",
            "name": "Udvari",
            "Megyekód": "TO",
            "Lat": "46.6",
            "Long": "18.5167",
            "KSH kód": "21360",
            "Jogállás": "6",
            "Terület": "2765",
            "Népesség": "441",
            "Lakások": "216"
        },
        {
            "Ir. szám": "7067",
            "name": "Varsád",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.5167",
            "KSH kód": "06637",
            "Jogállás": "6",
            "Terület": "2156",
            "Népesség": "397",
            "Lakások": "182"
        },
        {
            "Ir. szám": "7068",
            "name": "Kistormás",
            "Megyekód": "TO",
            "Lat": "46.5",
            "Long": "18.5667",
            "KSH kód": "03869",
            "Jogállás": "6",
            "Terület": "1135",
            "Népesség": "336",
            "Lakások": "128"
        },
        {
            "Ir. szám": "7071",
            "name": "Szakadát",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.4833",
            "KSH kód": "15316",
            "Jogállás": "6",
            "Terület": "1070",
            "Népesség": "268",
            "Lakások": "146"
        },
        {
            "Ir. szám": "7072",
            "name": "Diósberény",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.45",
            "KSH kód": "11688",
            "Jogállás": "6",
            "Terület": "1775",
            "Népesség": "361",
            "Lakások": "179"
        },
        {
            "Ir. szám": "7081",
            "name": "Simontornya",
            "Megyekód": "TO",
            "Lat": "46.75",
            "Long": "18.55",
            "KSH kód": "20783",
            "Jogállás": "4",
            "Terület": "3383",
            "Népesség": "4220",
            "Lakások": "1883"
        },
        {
            "Ir. szám": "7082",
            "name": "Kisszékely",
            "Megyekód": "TO",
            "Lat": "46.6833",
            "Long": "18.55",
            "KSH kód": "27766",
            "Jogállás": "6",
            "Terület": "2830",
            "Népesség": "336",
            "Lakások": "222"
        },
        {
            "Ir. szám": "7083",
            "name": "Tolnanémedi",
            "Megyekód": "TO",
            "Lat": "46.7167",
            "Long": "18.4833",
            "KSH kód": "11031",
            "Jogállás": "6",
            "Terület": "2195",
            "Népesség": "1146",
            "Lakások": "534"
        },
        {
            "Ir. szám": "7084",
            "name": "Pincehely",
            "Megyekód": "TO",
            "Lat": "46.6833",
            "Long": "18.45",
            "KSH kód": "19585",
            "Jogállás": "5",
            "Terület": "5008",
            "Népesség": "2371",
            "Lakások": "1071"
        },
        {
            "Ir. szám": "7085",
            "name": "Nagyszékely",
            "Megyekód": "TO",
            "Lat": "46.65",
            "Long": "18.5333",
            "KSH kód": "06761",
            "Jogállás": "6",
            "Terület": "3670",
            "Népesség": "437",
            "Lakások": "232"
        },
        {
            "Ir. szám": "7086",
            "name": "Ozora",
            "Megyekód": "TO",
            "Lat": "46.75",
            "Long": "18.4",
            "KSH kód": "05661",
            "Jogállás": "6",
            "Terület": "5958",
            "Népesség": "1666",
            "Lakások": "764"
        },
        {
            "Ir. szám": "7087",
            "name": "Fürged",
            "Megyekód": "TO",
            "Lat": "46.7167",
            "Long": "18.3",
            "KSH kód": "17950",
            "Jogállás": "6",
            "Terület": "2676",
            "Népesség": "722",
            "Lakások": "252"
        },
        {
            "Ir. szám": "7090",
            "name": "Tamási",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.2833",
            "KSH kód": "24563",
            "Jogállás": "4",
            "Terület": "11196",
            "Népesség": "8592",
            "Lakások": "3717"
        },
        {
            "Ir. szám": "7091",
            "name": "Tamási",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.2833",
            "KSH kód": "24563",
            "Jogállás": "4",
            "Terület": "11196",
            "Népesség": "8592",
            "Lakások": "3717"
        },
        {
            "Ir. szám": "7092",
            "name": "Nagykónyi",
            "Megyekód": "TO",
            "Lat": "46.5833",
            "Long": "18.2",
            "KSH kód": "27182",
            "Jogállás": "6",
            "Terület": "4788",
            "Népesség": "1149",
            "Lakások": "545"
        },
        {
            "Ir. szám": "7093",
            "name": "Értény",
            "Megyekód": "TO",
            "Lat": "46.6167",
            "Long": "18.1333",
            "KSH kód": "08448",
            "Jogállás": "6",
            "Terület": "2995",
            "Népesség": "773",
            "Lakások": "277"
        },
        {
            "Ir. szám": "7094",
            "name": "Koppányszántó",
            "Megyekód": "TO",
            "Lat": "46.6",
            "Long": "18.1167",
            "KSH kód": "21184",
            "Jogállás": "6",
            "Terület": "2255",
            "Népesség": "340",
            "Lakások": "220"
        },
        {
            "Ir. szám": "7095",
            "name": "Iregszemcse",
            "Megyekód": "TO",
            "Lat": "46.7",
            "Long": "18.1833",
            "KSH kód": "04701",
            "Jogállás": "6",
            "Terület": "5833",
            "Népesség": "2680",
            "Lakások": "1075"
        },
        {
            "Ir. szám": "7095",
            "name": "Újireg",
            "Megyekód": "TO",
            "Lat": "46.65",
            "Long": "18.1833",
            "KSH kód": "20561",
            "Jogállás": "6",
            "Terület": "1090",
            "Népesség": "296",
            "Lakások": "126"
        },
        {
            "Ir. szám": "7097",
            "name": "Nagyszokoly",
            "Megyekód": "TO",
            "Lat": "46.7167",
            "Long": "18.2167",
            "KSH kód": "13709",
            "Jogállás": "6",
            "Terület": "2799",
            "Népesség": "878",
            "Lakások": "434"
        },
        {
            "Ir. szám": "7098",
            "name": "Magyarkeszi",
            "Megyekód": "TO",
            "Lat": "46.75",
            "Long": "18.2333",
            "KSH kód": "06017",
            "Jogállás": "6",
            "Terület": "3816",
            "Népesség": "1269",
            "Lakások": "643"
        },
        {
            "Ir. szám": "7099",
            "name": "Felsőnyék",
            "Megyekód": "TO",
            "Lat": "46.7833",
            "Long": "18.3",
            "KSH kód": "17914",
            "Jogállás": "6",
            "Terület": "3202",
            "Népesség": "1036",
            "Lakások": "505"
        },
        {
            "Ir. szám": "7100",
            "name": "Szekszárd",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.7167",
            "KSH kód": "22761",
            "Jogállás": "1",
            "Terület": "9628",
            "Népesség": "33883",
            "Lakások": "14923"
        },
        {
            "Ir. szám": "7101",
            "name": "Szekszárd",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.7167",
            "KSH kód": "22761",
            "Jogállás": "1",
            "Terület": "9628",
            "Népesség": "33883",
            "Lakások": "14923"
        },
        {
            "Ir. szám": "7102",
            "name": "Szekszárd",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.7167",
            "KSH kód": "22761",
            "Jogállás": "1",
            "Terület": "9628",
            "Népesség": "33883",
            "Lakások": "14923"
        },
        {
            "Ir. szám": "7103",
            "name": "Szekszárd",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.7167",
            "KSH kód": "22761",
            "Jogállás": "1",
            "Terület": "9628",
            "Népesség": "33883",
            "Lakások": "14923"
        },
        {
            "Ir. szám": "7121",
            "name": "Szálka",
            "Megyekód": "TO",
            "Lat": "46.2833",
            "Long": "18.6333",
            "KSH kód": "14711",
            "Jogállás": "6",
            "Terület": "1708",
            "Népesség": "593",
            "Lakások": "261"
        },
        {
            "Ir. szám": "7122",
            "name": "Kakasd",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.6",
            "KSH kód": "02033",
            "Jogállás": "6",
            "Terület": "2079",
            "Népesség": "1633",
            "Lakások": "609"
        },
        {
            "Ir. szám": "7130",
            "name": "Tolna",
            "Megyekód": "TO",
            "Lat": "46.4333",
            "Long": "18.7833",
            "KSH kód": "25274",
            "Jogállás": "4",
            "Terület": "7108",
            "Népesség": "11637",
            "Lakások": "4483"
        },
        {
            "Ir. szám": "7131",
            "name": "Tolna",
            "Megyekód": "TO",
            "Lat": "46.4333",
            "Long": "18.7833",
            "KSH kód": "25274",
            "Jogállás": "4",
            "Terület": "7108",
            "Népesség": "11637",
            "Lakások": "4483"
        },
        {
            "Ir. szám": "7132",
            "name": "Bogyiszló",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.8333",
            "KSH kód": "03425",
            "Jogállás": "6",
            "Terület": "5593",
            "Népesség": "2231",
            "Lakások": "912"
        },
        {
            "Ir. szám": "7133",
            "name": "Fadd",
            "Megyekód": "TO",
            "Lat": "46.4667",
            "Long": "18.8333",
            "KSH kód": "18980",
            "Jogállás": "5",
            "Terület": "6753",
            "Népesség": "4501",
            "Lakások": "1567"
        },
        {
            "Ir. szám": "7134",
            "name": "Gerjen",
            "Megyekód": "TO",
            "Lat": "46.5",
            "Long": "18.9",
            "KSH kód": "05731",
            "Jogállás": "6",
            "Terület": "3628",
            "Népesség": "1235",
            "Lakások": "534"
        },
        {
            "Ir. szám": "7135",
            "name": "Dunaszentgyörgy",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.8333",
            "KSH kód": "09539",
            "Jogállás": "6",
            "Terület": "3763",
            "Népesség": "2531",
            "Lakások": "1020"
        },
        {
            "Ir. szám": "7136",
            "name": "Fácánkert",
            "Megyekód": "TO",
            "Lat": "46.45",
            "Long": "18.7333",
            "KSH kód": "24192",
            "Jogállás": "6",
            "Terület": "1070",
            "Népesség": "676",
            "Lakások": "292"
        },
        {
            "Ir. szám": "7140",
            "name": "Bátaszék",
            "Megyekód": "TO",
            "Lat": "46.1833",
            "Long": "18.7333",
            "KSH kód": "08864",
            "Jogállás": "4",
            "Terület": "6354",
            "Népesség": "6607",
            "Lakások": "2569"
        },
        {
            "Ir. szám": "7142",
            "name": "Pörböly",
            "Megyekód": "TO",
            "Lat": "46.2",
            "Long": "18.8167",
            "KSH kód": "33570",
            "Jogállás": "6",
            "Terület": "1111",
            "Népesség": "555",
            "Lakások": "244"
        },
        {
            "Ir. szám": "7143",
            "name": "Őcsény",
            "Megyekód": "TO",
            "Lat": "46.3167",
            "Long": "18.7667",
            "KSH kód": "08961",
            "Jogállás": "6",
            "Terület": "7261",
            "Népesség": "2445",
            "Lakások": "945"
        },
        {
            "Ir. szám": "7144",
            "name": "Decs",
            "Megyekód": "TO",
            "Lat": "46.2833",
            "Long": "18.7667",
            "KSH kód": "24989",
            "Jogállás": "5",
            "Terület": "9468",
            "Népesség": "4164",
            "Lakások": "1550"
        },
        {
            "Ir. szám": "7145",
            "name": "Sárpilis",
            "Megyekód": "TO",
            "Lat": "46.25",
            "Long": "18.75",
            "KSH kód": "04747",
            "Jogállás": "6",
            "Terület": "2170",
            "Népesség": "694",
            "Lakások": "218"
        },
        {
            "Ir. szám": "7146",
            "name": "Várdomb",
            "Megyekód": "TO",
            "Lat": "46.25",
            "Long": "18.7",
            "KSH kód": "09414",
            "Jogállás": "6",
            "Terület": "955",
            "Népesség": "1212",
            "Lakások": "437"
        },
        {
            "Ir. szám": "7147",
            "name": "Alsónána",
            "Megyekód": "TO",
            "Lat": "46.25",
            "Long": "18.6667",
            "KSH kód": "29665",
            "Jogállás": "6",
            "Terület": "1314",
            "Népesség": "690",
            "Lakások": "279"
        },
        {
            "Ir. szám": "7148",
            "name": "Alsónyék",
            "Megyekód": "TO",
            "Lat": "46.2",
            "Long": "18.7333",
            "KSH kód": "11563",
            "Jogállás": "6",
            "Terület": "3205",
            "Népesség": "778",
            "Lakások": "307"
        },
        {
            "Ir. szám": "7149",
            "name": "Báta",
            "Megyekód": "TO",
            "Lat": "46.1333",
            "Long": "18.7833",
            "KSH kód": "11712",
            "Jogállás": "6",
            "Terület": "6617",
            "Népesség": "1758",
            "Lakások": "822"
        },
        {
            "Ir. szám": "7150",
            "name": "Bonyhád",
            "Megyekód": "TO",
            "Lat": "46.3",
            "Long": "18.5333",
            "KSH kód": "06497",
            "Jogállás": "4",
            "Terület": "7213",
            "Népesség": "13957",
            "Lakások": "5592"
        },
        {
            "Ir. szám": "7151",
            "name": "Bonyhád",
            "Megyekód": "TO",
            "Lat": "46.3",
            "Long": "18.5333",
            "KSH kód": "06497",
            "Jogállás": "4",
            "Terület": "7213",
            "Népesség": "13957",
            "Lakások": "5592"
        },
        {
            "Ir. szám": "7153",
            "name": "Bonyhád",
            "Megyekód": "TO",
            "Lat": "46.3",
            "Long": "18.5333",
            "KSH kód": "06497",
            "Jogállás": "4",
            "Terület": "7213",
            "Népesség": "13957",
            "Lakások": "5592"
        },
        {
            "Ir. szám": "7158",
            "name": "Bonyhádvarasd",
            "Megyekód": "TO",
            "Lat": "46.3667",
            "Long": "18.4833",
            "KSH kód": "14818",
            "Jogállás": "6",
            "Terület": "1045",
            "Népesség": "421",
            "Lakások": "159"
        },
        {
            "Ir. szám": "7159",
            "name": "Kisdorog",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.5",
            "KSH kód": "17710",
            "Jogállás": "6",
            "Terület": "2000",
            "Népesség": "755",
            "Lakások": "286"
        },
        {
            "Ir. szám": "7161",
            "name": "Cikó",
            "Megyekód": "TO",
            "Lat": "46.25",
            "Long": "18.5667",
            "KSH kód": "19284",
            "Jogállás": "6",
            "Terület": "1986",
            "Népesség": "885",
            "Lakások": "352"
        },
        {
            "Ir. szám": "7162",
            "name": "Grábóc",
            "Megyekód": "TO",
            "Lat": "46.2833",
            "Long": "18.6167",
            "KSH kód": "26727",
            "Jogállás": "6",
            "Terület": "1373",
            "Népesség": "172",
            "Lakások": "92"
        },
        {
            "Ir. szám": "7163",
            "name": "Mőcsény",
            "Megyekód": "TO",
            "Lat": "46.2667",
            "Long": "18.6",
            "KSH kód": "33349",
            "Jogállás": "6",
            "Terület": "1215",
            "Népesség": "367",
            "Lakások": "148"
        },
        {
            "Ir. szám": "7164",
            "name": "Bátaapáti",
            "Megyekód": "TO",
            "Lat": "46.2167",
            "Long": "18.6",
            "KSH kód": "28909",
            "Jogállás": "6",
            "Terület": "2044",
            "Népesség": "405",
            "Lakások": "136"
        },
        {
            "Ir. szám": "7165",
            "name": "Mórágy",
            "Megyekód": "TO",
            "Lat": "46.2167",
            "Long": "18.65",
            "KSH kód": "20701",
            "Jogállás": "6",
            "Terület": "1762",
            "Népesség": "735",
            "Lakások": "279"
        },
        {
            "Ir. szám": "7171",
            "name": "Sióagárd",
            "Megyekód": "TO",
            "Lat": "46.4",
            "Long": "18.65",
            "KSH kód": "25645",
            "Jogállás": "6",
            "Terület": "2440",
            "Népesség": "1312",
            "Lakások": "518"
        },
        {
            "Ir. szám": "7172",
            "name": "Harc",
            "Megyekód": "TO",
            "Lat": "46.4",
            "Long": "18.6333",
            "KSH kód": "14164",
            "Jogállás": "6",
            "Terület": "1586",
            "Népesség": "903",
            "Lakások": "317"
        },
        {
            "Ir. szám": "7173",
            "name": "Zomba",
            "Megyekód": "TO",
            "Lat": "46.4",
            "Long": "18.5667",
            "KSH kód": "21625",
            "Jogállás": "6",
            "Terület": "5729",
            "Népesség": "2185",
            "Lakások": "851"
        },
        {
            "Ir. szám": "7174",
            "name": "Kéty",
            "Megyekód": "TO",
            "Lat": "46.4333",
            "Long": "18.5333",
            "KSH kód": "21731",
            "Jogállás": "6",
            "Terület": "1656",
            "Népesség": "705",
            "Lakások": "265"
        },
        {
            "Ir. szám": "7175",
            "name": "Felsőnána",
            "Megyekód": "TO",
            "Lat": "46.4667",
            "Long": "18.5333",
            "KSH kód": "15820",
            "Jogállás": "6",
            "Terület": "1890",
            "Népesség": "612",
            "Lakások": "242"
        },
        {
            "Ir. szám": "7176",
            "name": "Murga",
            "Megyekód": "TO",
            "Lat": "46.4667",
            "Long": "18.4833",
            "KSH kód": "18917",
            "Jogállás": "6",
            "Terület": "659",
            "Népesség": "75",
            "Lakások": "56"
        },
        {
            "Ir. szám": "7181",
            "name": "Tevel",
            "Megyekód": "TO",
            "Lat": "46.4167",
            "Long": "18.45",
            "KSH kód": "31459",
            "Jogállás": "6",
            "Terület": "2527",
            "Népesség": "1537",
            "Lakások": "570"
        },
        {
            "Ir. szám": "7182",
            "name": "Závod",
            "Megyekód": "TO",
            "Lat": "46.4",
            "Long": "18.4167",
            "KSH kód": "14304",
            "Jogállás": "6",
            "Terület": "1291",
            "Népesség": "306",
            "Lakások": "140"
        },
        {
            "Ir. szám": "7183",
            "name": "Kisvejke",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.4167",
            "KSH kód": "31185",
            "Jogállás": "6",
            "Terület": "1133",
            "Népesség": "423",
            "Lakások": "139"
        },
        {
            "Ir. szám": "7184",
            "name": "Lengyel",
            "Megyekód": "BA",
            "Lat": "46.3786",
            "Long": "18.4357",
            "KSH kód": "24411",
            "Jogállás": "6",
            "Terület": "2003",
            "Népesség": "536",
            "Lakások": "241"
        },
        {
            "Ir. szám": "7184",
            "name": "Szárász",
            "Megyekód": "TO",
            "Lat": "46.3786",
            "Long": "18.4357",
            "KSH kód": "07366",
            "Jogállás": "6",
            "Terület": "598",
            "Népesség": "34",
            "Lakások": "37"
        },
        {
            "Ir. szám": "7185",
            "name": "Mucsfa",
            "Megyekód": "TO",
            "Lat": "46.35",
            "Long": "18.4167",
            "KSH kód": "13620",
            "Jogállás": "6",
            "Terület": "1275",
            "Népesség": "398",
            "Lakások": "150"
        },
        {
            "Ir. szám": "7186",
            "name": "Aparhant",
            "Megyekód": "TO",
            "Lat": "46.3333",
            "Long": "18.45",
            "KSH kód": "26125",
            "Jogállás": "6",
            "Terület": "1796",
            "Népesség": "1070",
            "Lakások": "385"
        },
        {
            "Ir. szám": "7186",
            "name": "Nagyvejke",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.45",
            "KSH kód": "16452",
            "Jogállás": "6",
            "Terület": "777",
            "Népesség": "161",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7187",
            "name": "Nagyvejke",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.45",
            "KSH kód": "16452",
            "Jogállás": "6",
            "Terület": "777",
            "Népesség": "161",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7188",
            "name": "Szárász",
            "Megyekód": "BA",
            "Lat": "46.3400",
            "Long": "18.4046",
            "KSH kód": "07366",
            "Jogállás": "6",
            "Terület": "598",
            "Népesség": "34",
            "Lakások": "37"
        },
        {
            "Ir. szám": "7191",
            "name": "Hőgyész",
            "Megyekód": "TO",
            "Lat": "46.4833",
            "Long": "18.4167",
            "KSH kód": "26055",
            "Jogállás": "5",
            "Terület": "3715",
            "Népesség": "2962",
            "Lakások": "1126"
        },
        {
            "Ir. szám": "7192",
            "name": "Szakály",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.3833",
            "KSH kód": "04464",
            "Jogállás": "6",
            "Terület": "4101",
            "Népesség": "1548",
            "Lakások": "627"
        },
        {
            "Ir. szám": "7193",
            "name": "Regöly",
            "Megyekód": "TO",
            "Lat": "46.5833",
            "Long": "18.4167",
            "KSH kód": "15459",
            "Jogállás": "6",
            "Terület": "6267",
            "Népesség": "1177",
            "Lakások": "546"
        },
        {
            "Ir. szám": "7194",
            "name": "Kalaznó",
            "Megyekód": "TO",
            "Lat": "46.5",
            "Long": "18.4833",
            "KSH kód": "23791",
            "Jogállás": "6",
            "Terület": "1835",
            "Népesség": "172",
            "Lakások": "111"
        },
        {
            "Ir. szám": "7195",
            "name": "Mucsi",
            "Megyekód": "TO",
            "Lat": "46.4333",
            "Long": "18.4",
            "KSH kód": "07029",
            "Jogállás": "6",
            "Terület": "2476",
            "Népesség": "485",
            "Lakások": "219"
        },
        {
            "Ir. szám": "7200",
            "name": "Dombóvár",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.1167",
            "KSH kód": "07685",
            "Jogállás": "4",
            "Terület": "7848",
            "Népesség": "20084",
            "Lakások": "8374"
        },
        {
            "Ir. szám": "7201",
            "name": "Dombóvár",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.1167",
            "KSH kód": "07685",
            "Jogállás": "4",
            "Terület": "7848",
            "Népesség": "20084",
            "Lakások": "8374"
        },
        {
            "Ir. szám": "7202",
            "name": "Dombóvár",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.1167",
            "KSH kód": "07685",
            "Jogállás": "4",
            "Terület": "7848",
            "Népesség": "20084",
            "Lakások": "8374"
        },
        {
            "Ir. szám": "7203",
            "name": "Dombóvár",
            "Megyekód": "TO",
            "Lat": "46.3833",
            "Long": "18.1167",
            "KSH kód": "07685",
            "Jogállás": "4",
            "Terület": "7848",
            "Népesség": "20084",
            "Lakások": "8374"
        },
        {
            "Ir. szám": "7211",
            "name": "Dalmand",
            "Megyekód": "TO",
            "Lat": "46.5",
            "Long": "18.1833",
            "KSH kód": "29230",
            "Jogállás": "6",
            "Terület": "4877",
            "Népesség": "1353",
            "Lakások": "519"
        },
        {
            "Ir. szám": "7212",
            "name": "Kocsola",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.1833",
            "KSH kód": "22433",
            "Jogállás": "6",
            "Terület": "3334",
            "Népesség": "1258",
            "Lakások": "527"
        },
        {
            "Ir. szám": "7213",
            "name": "Szakcs",
            "Megyekód": "TO",
            "Lat": "46.5133",
            "Long": "18.11",
            "KSH kód": "10083",
            "Jogállás": "6",
            "Terület": "5579",
            "Népesség": "929",
            "Lakások": "488"
        },
        {
            "Ir. szám": "7214",
            "name": "Várong",
            "Megyekód": "TO",
            "Lat": "46.5333",
            "Long": "18.05",
            "KSH kód": "29124",
            "Jogállás": "6",
            "Terület": "663",
            "Népesség": "164",
            "Lakások": "91"
        },
        {
            "Ir. szám": "7214",
            "name": "Lápafő",
            "Megyekód": "TO",
            "Lat": "46.5167",
            "Long": "18.0667",
            "KSH kód": "14605",
            "Jogállás": "6",
            "Terület": "931",
            "Népesség": "161",
            "Lakások": "84"
        },
        {
            "Ir. szám": "7215",
            "name": "Nak",
            "Megyekód": "TO",
            "Lat": "46.4833",
            "Long": "18.0667",
            "KSH kód": "02796",
            "Jogállás": "6",
            "Terület": "2778",
            "Népesség": "580",
            "Lakások": "299"
        },
        {
            "Ir. szám": "7224",
            "name": "Dúzs",
            "Megyekód": "TO",
            "Lat": "46.4833",
            "Long": "18.3833",
            "KSH kód": "19202",
            "Jogállás": "6",
            "Terület": "1127",
            "Népesség": "273",
            "Lakások": "120"
        },
        {
            "Ir. szám": "7225",
            "name": "Csibrák",
            "Megyekód": "TO",
            "Lat": "46.4667",
            "Long": "18.35",
            "KSH kód": "11998",
            "Jogállás": "6",
            "Terület": "1462",
            "Népesség": "355",
            "Lakások": "156"
        },
        {
            "Ir. szám": "7226",
            "name": "Kurd",
            "Megyekód": "TO",
            "Lat": "46.45",
            "Long": "18.3167",
            "KSH kód": "20507",
            "Jogállás": "6",
            "Terület": "3119",
            "Népesség": "1244",
            "Lakások": "509"
        },
        {
            "Ir. szám": "7227",
            "name": "Gyulaj",
            "Megyekód": "TO",
            "Lat": "46.5",
            "Long": "18.3",
            "KSH kód": "30359",
            "Jogállás": "6",
            "Terület": "7082",
            "Népesség": "991",
            "Lakások": "367"
        },
        {
            "Ir. szám": "7228",
            "name": "Döbrököz",
            "Megyekód": "TO",
            "Lat": "46.4167",
            "Long": "18.25",
            "KSH kód": "02565",
            "Jogállás": "6",
            "Terület": "4313",
            "Népesség": "2007",
            "Lakások": "983"
        },
        {
            "Ir. szám": "7251",
            "name": "Kapospula",
            "Megyekód": "TO",
            "Lat": "46.3524",
            "Long": "18.0143",
            "KSH kód": "23296",
            "Jogállás": "6",
            "Terület": "1980",
            "Népesség": "928",
            "Lakások": "352"
        },
        {
            "Ir. szám": "7252",
            "name": "Attala",
            "Megyekód": "TO",
            "Lat": "46.3524",
            "Long": "18.0143",
            "KSH kód": "32735",
            "Jogállás": "6",
            "Terület": "2064",
            "Népesség": "869",
            "Lakások": "378"
        },
        {
            "Ir. szám": "7253",
            "name": "Csoma",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "18.05",
            "KSH kód": "08040",
            "Jogállás": "6",
            "Terület": "843",
            "Népesség": "440",
            "Lakások": "184"
        },
        {
            "Ir. szám": "7253",
            "name": "Szabadi",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "18.0333",
            "KSH kód": "14854",
            "Jogállás": "6",
            "Terület": "823",
            "Népesség": "294",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7255",
            "name": "Nagyberki",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "18.0167",
            "KSH kód": "21449",
            "Jogállás": "6",
            "Terület": "2174",
            "Népesség": "1480",
            "Lakások": "529"
        },
        {
            "Ir. szám": "7256",
            "name": "Kercseliget",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "18.0667",
            "KSH kód": "14386",
            "Jogállás": "6",
            "Terület": "1947",
            "Népesség": "388",
            "Lakások": "182"
        },
        {
            "Ir. szám": "7257",
            "name": "Mosdós",
            "Megyekód": "SO",
            "Lat": "46.35",
            "Long": "18",
            "KSH kód": "31343",
            "Jogállás": "6",
            "Terület": "1695",
            "Népesség": "1036",
            "Lakások": "330"
        },
        {
            "Ir. szám": "7258",
            "name": "Kaposkeresztúr",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.9667",
            "KSH kód": "12663",
            "Jogállás": "6",
            "Terület": "1980",
            "Népesség": "341",
            "Lakások": "137"
        },
        {
            "Ir. szám": "7258",
            "name": "Baté",
            "Megyekód": "SO",
            "Lat": "46.35",
            "Long": "17.9667",
            "KSH kód": "32337",
            "Jogállás": "6",
            "Terület": "1028",
            "Népesség": "834",
            "Lakások": "297"
        },
        {
            "Ir. szám": "7261",
            "name": "Kaposhomok",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.9167",
            "KSH kód": "25867",
            "Jogállás": "6",
            "Terület": "1148",
            "Népesség": "496",
            "Lakások": "153"
        },
        {
            "Ir. szám": "7261",
            "name": "Taszár",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.9167",
            "KSH kód": "04932",
            "Jogállás": "6",
            "Terület": "1729",
            "Népesség": "1982",
            "Lakások": "800"
        },
        {
            "Ir. szám": "7271",
            "name": "Fonó",
            "Megyekód": "SO",
            "Lat": "46.4",
            "Long": "17.95",
            "KSH kód": "22026",
            "Jogállás": "6",
            "Terület": "1061",
            "Népesség": "325",
            "Lakások": "155"
        },
        {
            "Ir. szám": "7272",
            "name": "Gölle",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "18.0167",
            "KSH kód": "30571",
            "Jogállás": "6",
            "Terület": "4469",
            "Népesség": "996",
            "Lakások": "436"
        },
        {
            "Ir. szám": "7273",
            "name": "Büssü",
            "Megyekód": "SO",
            "Lat": "46.4667",
            "Long": "17.9667",
            "KSH kód": "08703",
            "Jogállás": "6",
            "Terület": "1719",
            "Népesség": "336",
            "Lakások": "160"
        },
        {
            "Ir. szám": "7274",
            "name": "Kazsok",
            "Megyekód": "SO",
            "Lat": "46.4833",
            "Long": "17.9667",
            "KSH kód": "26888",
            "Jogállás": "6",
            "Terület": "1071",
            "Népesség": "321",
            "Lakások": "123"
        },
        {
            "Ir. szám": "7275",
            "name": "Igal",
            "Megyekód": "SO",
            "Lat": "46.5333",
            "Long": "17.95",
            "KSH kód": "11192",
            "Jogállás": "5",
            "Terület": "3606",
            "Népesség": "1243",
            "Lakások": "579"
        },
        {
            "Ir. szám": "7276",
            "name": "Somogyszil",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "18",
            "KSH kód": "03416",
            "Jogállás": "6",
            "Terület": "3846",
            "Népesség": "754",
            "Lakások": "369"
        },
        {
            "Ir. szám": "7276",
            "name": "Gadács",
            "Megyekód": "SO",
            "Lat": "46.5333",
            "Long": "18.0167",
            "KSH kód": "28264",
            "Jogállás": "6",
            "Terület": "386",
            "Népesség": "91",
            "Lakások": "63"
        },
        {
            "Ir. szám": "7279",
            "name": "Kisgyalán",
            "Megyekód": "SO",
            "Lat": "46.4167",
            "Long": "17.9833",
            "KSH kód": "04826",
            "Jogállás": "6",
            "Terület": "941",
            "Népesség": "188",
            "Lakások": "103"
        },
        {
            "Ir. szám": "7281",
            "name": "Bonnya",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.9",
            "KSH kód": "22673",
            "Jogállás": "6",
            "Terület": "1462",
            "Népesség": "269",
            "Lakások": "150"
        },
        {
            "Ir. szám": "7282",
            "name": "Kisbárapáti",
            "Megyekód": "SO",
            "Lat": "46.6",
            "Long": "17.8667",
            "KSH kód": "24493",
            "Jogállás": "6",
            "Terület": "2871",
            "Népesség": "467",
            "Lakások": "235"
        },
        {
            "Ir. szám": "7282",
            "name": "Fiad",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.85",
            "KSH kód": "31644",
            "Jogállás": "6",
            "Terület": "1491",
            "Népesség": "162",
            "Lakások": "79"
        },
        {
            "Ir. szám": "7283",
            "name": "Somogyacsa",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.95",
            "KSH kód": "30580",
            "Jogállás": "6",
            "Terület": "2446",
            "Népesség": "150",
            "Lakások": "182"
        },
        {
            "Ir. szám": "7284",
            "name": "Somogydöröcske",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "18",
            "KSH kód": "15981",
            "Jogállás": "6",
            "Terület": "1083",
            "Népesség": "151",
            "Lakások": "102"
        },
        {
            "Ir. szám": "7285",
            "name": "Kára",
            "Megyekód": "SO",
            "Lat": "46.6167",
            "Long": "18.0167",
            "KSH kód": "05351",
            "Jogállás": "6",
            "Terület": "537",
            "Népesség": "54",
            "Lakások": "46"
        },
        {
            "Ir. szám": "7285",
            "name": "Szorosad",
            "Megyekód": "SO",
            "Lat": "46.6",
            "Long": "18.0333",
            "KSH kód": "05193",
            "Jogállás": "6",
            "Terület": "648",
            "Népesség": "93",
            "Lakások": "59"
        },
        {
            "Ir. szám": "7285",
            "name": "Törökkoppány",
            "Megyekód": "SO",
            "Lat": "46.6",
            "Long": "18.05",
            "KSH kód": "20093",
            "Jogállás": "6",
            "Terület": "2580",
            "Népesség": "481",
            "Lakások": "239"
        },
        {
            "Ir. szám": "7286",
            "name": "Miklósi",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "18",
            "KSH kód": "33376",
            "Jogállás": "6",
            "Terület": "1047",
            "Népesség": "234",
            "Lakások": "124"
        },
        {
            "Ir. szám": "7300",
            "name": "Mecsekpölöske",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.2167",
            "KSH kód": "11402",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "450",
            "Lakások": "154"
        },
        {
            "Ir. szám": "7300",
            "name": "Komló",
            "Megyekód": "BA",
            "Lat": "46.2",
            "Long": "18.2667",
            "KSH kód": "26408",
            "Jogállás": "4",
            "Terület": "4655",
            "Népesség": "25881",
            "Lakások": "11198"
        },
        {
            "Ir. szám": "7301",
            "name": "Mecsekpölöske",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.2167",
            "KSH kód": "11402",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "450",
            "Lakások": "154"
        },
        {
            "Ir. szám": "7302",
            "name": "Mecsekpölöske",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.2167",
            "KSH kód": "11402",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "450",
            "Lakások": "154"
        },
        {
            "Ir. szám": "7303",
            "name": "Mecsekpölöske",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.2167",
            "KSH kód": "11402",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "450",
            "Lakások": "154"
        },
        {
            "Ir. szám": "7304",
            "name": "Mánfa",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.25",
            "KSH kód": "33923",
            "Jogállás": "6",
            "Terület": "2770",
            "Népesség": "875",
            "Lakások": "331"
        },
        {
            "Ir. szám": "7305",
            "name": "Mánfa",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.25",
            "KSH kód": "33923",
            "Jogállás": "6",
            "Terület": "2770",
            "Népesség": "875",
            "Lakások": "331"
        },
        {
            "Ir. szám": "7306",
            "name": "Mánfa",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.25",
            "KSH kód": "33923",
            "Jogállás": "6",
            "Terület": "2770",
            "Népesség": "875",
            "Lakások": "331"
        },
        {
            "Ir. szám": "7309",
            "name": "Mánfa",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.25",
            "KSH kód": "33923",
            "Jogállás": "6",
            "Terület": "2770",
            "Népesség": "875",
            "Lakások": "331"
        },
        {
            "Ir. szám": "7331",
            "name": "Liget",
            "Megyekód": "BA",
            "Lat": "46.2333",
            "Long": "18.2",
            "KSH kód": "17604",
            "Jogállás": "6",
            "Terület": "1207",
            "Népesség": "446",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7332",
            "name": "Magyaregregy",
            "Megyekód": "BA",
            "Lat": "46.25",
            "Long": "18.3167",
            "KSH kód": "29753",
            "Jogállás": "6",
            "Terület": "2681",
            "Népesség": "752",
            "Lakások": "341"
        },
        {
            "Ir. szám": "7333",
            "name": "Kárász",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.3167",
            "KSH kód": "05999",
            "Jogállás": "6",
            "Terület": "802",
            "Népesség": "336",
            "Lakások": "145"
        },
        {
            "Ir. szám": "7333",
            "name": "Vékény",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.35",
            "KSH kód": "24402",
            "Jogállás": "6",
            "Terület": "936",
            "Népesség": "155",
            "Lakások": "65"
        },
        {
            "Ir. szám": "7334",
            "name": "Köblény",
            "Megyekód": "BA",
            "Lat": "46.3",
            "Long": "18.3",
            "KSH kód": "25247",
            "Jogállás": "6",
            "Terület": "804",
            "Népesség": "249",
            "Lakások": "106"
        },
        {
            "Ir. szám": "7334",
            "name": "Szalatnak",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "18.2833",
            "KSH kód": "09007",
            "Jogállás": "6",
            "Terület": "1028",
            "Népesség": "348",
            "Lakások": "171"
        },
        {
            "Ir. szám": "7341",
            "name": "Csikóstőttős",
            "Megyekód": "TO",
            "Lat": "46.3333",
            "Long": "18.1667",
            "KSH kód": "30094",
            "Jogállás": "6",
            "Terület": "1770",
            "Népesség": "882",
            "Lakások": "371"
        },
        {
            "Ir. szám": "7342",
            "name": "Mágocs",
            "Megyekód": "BA",
            "Lat": "46.35",
            "Long": "18.2333",
            "KSH kód": "06813",
            "Jogállás": "5",
            "Terület": "4254",
            "Népesség": "2483",
            "Lakások": "975"
        },
        {
            "Ir. szám": "7343",
            "name": "Nagyhajmás",
            "Megyekód": "BA",
            "Lat": "46.3278",
            "Long": "18.2944",
            "KSH kód": "12858",
            "Jogállás": "6",
            "Terület": "1801",
            "Népesség": "359",
            "Lakások": "148"
        },
        {
            "Ir. szám": "7344",
            "name": "Mekényes",
            "Megyekód": "BA",
            "Lat": "46.3833",
            "Long": "18.3333",
            "KSH kód": "30492",
            "Jogállás": "6",
            "Terület": "1777",
            "Népesség": "277",
            "Lakások": "145"
        },
        {
            "Ir. szám": "7345",
            "name": "Alsómocsolád",
            "Megyekód": "BA",
            "Lat": "46.3167",
            "Long": "18.25",
            "KSH kód": "17385",
            "Jogállás": "6",
            "Terület": "1300",
            "Népesség": "349",
            "Lakások": "141"
        },
        {
            "Ir. szám": "7346",
            "name": "Bikal",
            "Megyekód": "BA",
            "Lat": "46.3333",
            "Long": "18.2833",
            "KSH kód": "04899",
            "Jogállás": "6",
            "Terület": "1703",
            "Népesség": "763",
            "Lakások": "336"
        },
        {
            "Ir. szám": "7347",
            "name": "Egyházaskozár",
            "Megyekód": "BA",
            "Lat": "46.3333",
            "Long": "18.3167",
            "KSH kód": "27401",
            "Jogállás": "6",
            "Terület": "2431",
            "Népesség": "801",
            "Lakások": "348"
        },
        {
            "Ir. szám": "7348",
            "name": "Tófű",
            "Megyekód": "BA",
            "Lat": "46.3167",
            "Long": "18.3667",
            "KSH kód": "04048",
            "Jogállás": "6",
            "Terület": "434",
            "Népesség": "117",
            "Lakások": "58"
        },
        {
            "Ir. szám": "7348",
            "name": "Hegyhátmaróc",
            "Megyekód": "BA",
            "Lat": "46.3167",
            "Long": "18.3333",
            "KSH kód": "30951",
            "Jogállás": "6",
            "Terület": "759",
            "Népesség": "154",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7349",
            "name": "Szászvár",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.3667",
            "KSH kód": "33765",
            "Jogállás": "5",
            "Terület": "2117",
            "Népesség": "2428",
            "Lakások": "1037"
        },
        {
            "Ir. szám": "7351",
            "name": "Máza",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.4",
            "KSH kód": "33756",
            "Jogállás": "6",
            "Terület": "1069",
            "Népesség": "1269",
            "Lakások": "485"
        },
        {
            "Ir. szám": "7352",
            "name": "Györe",
            "Megyekód": "TO",
            "Lat": "46.3",
            "Long": "18.4",
            "KSH kód": "25539",
            "Jogállás": "6",
            "Terület": "1131",
            "Népesség": "667",
            "Lakások": "253"
        },
        {
            "Ir. szám": "7353",
            "name": "Izmény",
            "Megyekód": "TO",
            "Lat": "46.3167",
            "Long": "18.4167",
            "KSH kód": "27711",
            "Jogállás": "6",
            "Terület": "1373",
            "Népesség": "519",
            "Lakások": "204"
        },
        {
            "Ir. szám": "7354",
            "name": "Váralja",
            "Megyekód": "TO",
            "Lat": "46.2667",
            "Long": "18.4333",
            "KSH kód": "32850",
            "Jogállás": "6",
            "Terület": "2113",
            "Népesség": "927",
            "Lakások": "372"
        },
        {
            "Ir. szám": "7355",
            "name": "Nagymányok",
            "Megyekód": "TO",
            "Lat": "46.2833",
            "Long": "18.4667",
            "KSH kód": "14030",
            "Jogállás": "5",
            "Terület": "1068",
            "Népesség": "2357",
            "Lakások": "923"
        },
        {
            "Ir. szám": "7356",
            "name": "Kismányok",
            "Megyekód": "TO",
            "Lat": "46.2667",
            "Long": "18.4833",
            "KSH kód": "06512",
            "Jogállás": "6",
            "Terület": "543",
            "Népesség": "357",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7357",
            "name": "Jágónak",
            "Megyekód": "TO",
            "Lat": "46.3167",
            "Long": "18.0833",
            "KSH kód": "09326",
            "Jogállás": "6",
            "Terület": "1540",
            "Népesség": "261",
            "Lakások": "118"
        },
        {
            "Ir. szám": "7361",
            "name": "Kaposszekcső",
            "Megyekód": "TO",
            "Lat": "46.3333",
            "Long": "18.1333",
            "KSH kód": "18962",
            "Jogállás": "6",
            "Terület": "1562",
            "Népesség": "1549",
            "Lakások": "645"
        },
        {
            "Ir. szám": "7362",
            "name": "Gerényes",
            "Megyekód": "BA",
            "Lat": "46.3",
            "Long": "18.1833",
            "KSH kód": "13347",
            "Jogállás": "6",
            "Terület": "1234",
            "Népesség": "250",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7362",
            "name": "Vásárosdombó",
            "Megyekód": "BA",
            "Lat": "46.3",
            "Long": "18.15",
            "KSH kód": "02264",
            "Jogállás": "6",
            "Terület": "1174",
            "Népesség": "1160",
            "Lakások": "397"
        },
        {
            "Ir. szám": "7362",
            "name": "Tarrós",
            "Megyekód": "TO",
            "Lat": "46.3056",
            "Long": "18.1389",
            "KSH kód": "15635",
            "Jogállás": "6",
            "Terület": "529",
            "Népesség": "117",
            "Lakások": "52"
        },
        {
            "Ir. szám": "7362",
            "name": "Jágónak",
            "Megyekód": "TO",
            "Lat": "46.3167",
            "Long": "18.0833",
            "KSH kód": "09326",
            "Jogállás": "6",
            "Terület": "1540",
            "Népesség": "261",
            "Lakások": "118"
        },
        {
            "Ir. szám": "7370",
            "name": "Felsőegerszeg",
            "Megyekód": "BA",
            "Lat": "46.25",
            "Long": "18.1333",
            "KSH kód": "13286",
            "Jogállás": "6",
            "Terület": "484",
            "Népesség": "143",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7370",
            "name": "Oroszló",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.1333",
            "KSH kód": "04482",
            "Jogállás": "6",
            "Terület": "612",
            "Népesség": "331",
            "Lakások": "119"
        },
        {
            "Ir. szám": "7370",
            "name": "Palé",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.0667",
            "KSH kód": "23764",
            "Jogállás": "6",
            "Terület": "215",
            "Népesség": "111",
            "Lakások": "37"
        },
        {
            "Ir. szám": "7370",
            "name": "Sásd",
            "Megyekód": "BA",
            "Lat": "46.25",
            "Long": "18.1167",
            "KSH kód": "32160",
            "Jogállás": "4",
            "Terület": "1488",
            "Népesség": "3322",
            "Lakások": "1396"
        },
        {
            "Ir. szám": "7370",
            "name": "Varga",
            "Megyekód": "BA",
            "Lat": "46.2333",
            "Long": "18.15",
            "KSH kód": "28529",
            "Jogállás": "6",
            "Terület": "836",
            "Népesség": "119",
            "Lakások": "51"
        },
        {
            "Ir. szám": "7370",
            "name": "Vázsnok",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.1333",
            "KSH kód": "07074",
            "Jogállás": "6",
            "Terület": "475",
            "Népesség": "117",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7370",
            "name": "Meződ",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "18.1",
            "KSH kód": "03470",
            "Jogállás": "6",
            "Terület": "1041",
            "Népesség": "124",
            "Lakások": "75"
        },
        {
            "Ir. szám": "7381",
            "name": "Ág",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "18.2",
            "KSH kód": "25812",
            "Jogállás": "6",
            "Terület": "1203",
            "Népesség": "178",
            "Lakások": "66"
        },
        {
            "Ir. szám": "7381",
            "name": "Kisvaszar",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "18.2167",
            "KSH kód": "09548",
            "Jogállás": "6",
            "Terület": "2035",
            "Népesség": "332",
            "Lakások": "120"
        },
        {
            "Ir. szám": "7381",
            "name": "Tékes",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "18.1667",
            "KSH kód": "27702",
            "Jogállás": "6",
            "Terület": "1256",
            "Népesség": "261",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7383",
            "name": "Baranyaszentgyörgy",
            "Megyekód": "BA",
            "Lat": "46.2333",
            "Long": "18.0167",
            "KSH kód": "05485",
            "Jogállás": "6",
            "Terület": "727",
            "Népesség": "137",
            "Lakások": "76"
        },
        {
            "Ir. szám": "7383",
            "name": "Tormás",
            "Megyekód": "BA",
            "Lat": "46.2333",
            "Long": "18",
            "KSH kód": "22424",
            "Jogállás": "6",
            "Terület": "2470",
            "Népesség": "290",
            "Lakások": "130"
        },
        {
            "Ir. szám": "7383",
            "name": "Szágy",
            "Megyekód": "BA",
            "Lat": "46.2333",
            "Long": "17.95",
            "KSH kód": "27508",
            "Jogállás": "6",
            "Terület": "940",
            "Népesség": "145",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7384",
            "name": "Baranyajenő",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.05",
            "KSH kód": "24749",
            "Jogállás": "6",
            "Terület": "1555",
            "Népesség": "527",
            "Lakások": "169"
        },
        {
            "Ir. szám": "7385",
            "name": "Gödre",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "17.9833",
            "KSH kód": "33233",
            "Jogállás": "6",
            "Terület": "3993",
            "Népesség": "930",
            "Lakások": "405"
        },
        {
            "Ir. szám": "7386",
            "name": "Gödre",
            "Megyekód": "BA",
            "Lat": "46.2833",
            "Long": "17.9833",
            "KSH kód": "33233",
            "Jogállás": "6",
            "Terület": "3993",
            "Népesség": "930",
            "Lakások": "405"
        },
        {
            "Ir. szám": "7391",
            "name": "Kisbeszterce",
            "Megyekód": "BA",
            "Lat": "46.2",
            "Long": "18.0333",
            "KSH kód": "05722",
            "Jogállás": "6",
            "Terület": "747",
            "Népesség": "80",
            "Lakások": "49"
        },
        {
            "Ir. szám": "7391",
            "name": "Kishajmás",
            "Megyekód": "BA",
            "Lat": "46.2",
            "Long": "18.0833",
            "KSH kód": "06831",
            "Jogállás": "6",
            "Terület": "1176",
            "Népesség": "220",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7391",
            "name": "Mindszentgodisa",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.0667",
            "KSH kód": "16285",
            "Jogállás": "6",
            "Terület": "2297",
            "Népesség": "926",
            "Lakások": "384"
        },
        {
            "Ir. szám": "7393",
            "name": "Bakóca",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18",
            "KSH kód": "22275",
            "Jogállás": "6",
            "Terület": "1065",
            "Népesség": "280",
            "Lakások": "160"
        },
        {
            "Ir. szám": "7394",
            "name": "Magyarhertelend",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "18.15",
            "KSH kód": "05430",
            "Jogállás": "6",
            "Terület": "1616",
            "Népesség": "647",
            "Lakások": "260"
        },
        {
            "Ir. szám": "7394",
            "name": "Bodolyabér",
            "Megyekód": "BA",
            "Lat": "46.2",
            "Long": "18.1167",
            "KSH kód": "33002",
            "Jogállás": "6",
            "Terület": "909",
            "Népesség": "251",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7396",
            "name": "Magyarszék",
            "Megyekód": "BA",
            "Lat": "46.2",
            "Long": "18.2",
            "KSH kód": "22600",
            "Jogállás": "6",
            "Terület": "1386",
            "Népesség": "1101",
            "Lakások": "382"
        },
        {
            "Ir. szám": "7400",
            "name": "Kaposvár",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.8",
            "KSH kód": "20473",
            "Jogállás": "1",
            "Terület": "11359",
            "Népesség": "67663",
            "Lakások": "27911"
        },
        {
            "Ir. szám": "7400",
            "name": "Orci",
            "Megyekód": "SO",
            "Lat": "46.4",
            "Long": "17.8667",
            "KSH kód": "24536",
            "Jogállás": "6",
            "Terület": "997",
            "Népesség": "582",
            "Lakások": "174"
        },
        {
            "Ir. szám": "7400",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7401",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7402",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7403",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7404",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7405",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7406",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7407",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7408",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7409",
            "name": "Zselickislak",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8167",
            "KSH kód": "24110",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "335",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7431",
            "name": "Juta",
            "Megyekód": "SO",
            "Lat": "46.4",
            "Long": "17.75",
            "KSH kód": "17279",
            "Jogállás": "6",
            "Terület": "1923",
            "Népesség": "1224",
            "Lakások": "409"
        },
        {
            "Ir. szám": "7432",
            "name": "Csombárd",
            "Megyekód": "SO",
            "Lat": "46.45",
            "Long": "17.6833",
            "KSH kód": "02477",
            "Jogállás": "6",
            "Terület": "899",
            "Népesség": "286",
            "Lakások": "115"
        },
        {
            "Ir. szám": "7432",
            "name": "Hetes",
            "Megyekód": "SO",
            "Lat": "46.4167",
            "Long": "17.7",
            "KSH kód": "09928",
            "Jogállás": "6",
            "Terület": "2663",
            "Népesség": "1172",
            "Lakások": "381"
        },
        {
            "Ir. szám": "7434",
            "name": "Mezőcsokonya",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "17.65",
            "KSH kód": "30854",
            "Jogállás": "6",
            "Terület": "3078",
            "Népesség": "1264",
            "Lakások": "455"
        },
        {
            "Ir. szám": "7435",
            "name": "Somogysárd",
            "Megyekód": "SO",
            "Lat": "46.4167",
            "Long": "17.6",
            "KSH kód": "28723",
            "Jogállás": "6",
            "Terület": "3636",
            "Népesség": "1270",
            "Lakások": "509"
        },
        {
            "Ir. szám": "7436",
            "name": "Újvárfalva",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "17.5833",
            "KSH kód": "07205",
            "Jogállás": "6",
            "Terület": "1222",
            "Népesség": "333",
            "Lakások": "137"
        },
        {
            "Ir. szám": "7439",
            "name": "Bodrog",
            "Megyekód": "SO",
            "Lat": "46.4667",
            "Long": "17.6667",
            "KSH kód": "10506",
            "Jogállás": "6",
            "Terület": "1478",
            "Népesség": "452",
            "Lakások": "171"
        },
        {
            "Ir. szám": "7441",
            "name": "Magyaregres",
            "Megyekód": "SO",
            "Lat": "46.45",
            "Long": "17.7833",
            "KSH kód": "25371",
            "Jogállás": "6",
            "Terület": "1440",
            "Népesség": "643",
            "Lakások": "210"
        },
        {
            "Ir. szám": "7442",
            "name": "Várda",
            "Megyekód": "SO",
            "Lat": "46.45",
            "Long": "17.7333",
            "KSH kód": "26781",
            "Jogállás": "6",
            "Terület": "1036",
            "Népesség": "517",
            "Lakások": "198"
        },
        {
            "Ir. szám": "7443",
            "name": "Alsóbogát",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.75",
            "KSH kód": "34184",
            "Jogállás": "6",
            "Terület": "1201",
            "Népesség": "273",
            "Lakások": "96"
        },
        {
            "Ir. szám": "7443",
            "name": "Somogyjád",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.7",
            "KSH kód": "28963",
            "Jogállás": "6",
            "Terület": "2506",
            "Népesség": "1578",
            "Lakások": "567"
        },
        {
            "Ir. szám": "7443",
            "name": "Edde",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.7167",
            "KSH kód": "03489",
            "Jogállás": "6",
            "Terület": "851",
            "Népesség": "202",
            "Lakások": "98"
        },
        {
            "Ir. szám": "7444",
            "name": "Osztopán",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.6667",
            "KSH kód": "19770",
            "Jogállás": "6",
            "Terület": "2283",
            "Népesség": "846",
            "Lakások": "302"
        },
        {
            "Ir. szám": "7452",
            "name": "Somogyaszaló",
            "Megyekód": "SO",
            "Lat": "46.45",
            "Long": "17.8167",
            "KSH kód": "31219",
            "Jogállás": "6",
            "Terület": "2212",
            "Népesség": "712",
            "Lakások": "285"
        },
        {
            "Ir. szám": "7453",
            "name": "Mernye",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.8333",
            "KSH kód": "23630",
            "Jogállás": "6",
            "Terület": "2593",
            "Népesség": "1491",
            "Lakások": "565"
        },
        {
            "Ir. szám": "7454",
            "name": "Somodor",
            "Megyekód": "SO",
            "Lat": "46.4833",
            "Long": "17.85",
            "KSH kód": "13824",
            "Jogállás": "6",
            "Terület": "1991",
            "Népesség": "429",
            "Lakások": "177"
        },
        {
            "Ir. szám": "7455",
            "name": "Somogygeszti",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.7833",
            "KSH kód": "20330",
            "Jogállás": "6",
            "Terület": "2171",
            "Népesség": "488",
            "Lakások": "209"
        },
        {
            "Ir. szám": "7456",
            "name": "Felsőmocsolád",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.8333",
            "KSH kód": "13985",
            "Jogállás": "6",
            "Terület": "1651",
            "Népesség": "439",
            "Lakások": "253"
        },
        {
            "Ir. szám": "7457",
            "name": "Ecseny",
            "Megyekód": "SO",
            "Lat": "46.55",
            "Long": "17.85",
            "KSH kód": "10861",
            "Jogállás": "6",
            "Terület": "1292",
            "Népesség": "214",
            "Lakások": "178"
        },
        {
            "Ir. szám": "7458",
            "name": "Polány",
            "Megyekód": "SO",
            "Lat": "46.55",
            "Long": "17.7833",
            "KSH kód": "13790",
            "Jogállás": "6",
            "Terület": "1287",
            "Népesség": "232",
            "Lakások": "152"
        },
        {
            "Ir. szám": "7463",
            "name": "Magyaratád",
            "Megyekód": "SO",
            "Lat": "46.4667",
            "Long": "17.9",
            "KSH kód": "10427",
            "Jogállás": "6",
            "Terület": "1882",
            "Népesség": "862",
            "Lakások": "370"
        },
        {
            "Ir. szám": "7463",
            "name": "Patalom",
            "Megyekód": "SO",
            "Lat": "46.45",
            "Long": "17.9167",
            "KSH kód": "06141",
            "Jogállás": "6",
            "Terület": "668",
            "Népesség": "351",
            "Lakások": "93"
        },
        {
            "Ir. szám": "7464",
            "name": "Ráksi",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.9167",
            "KSH kód": "30863",
            "Jogállás": "6",
            "Terület": "1517",
            "Népesség": "489",
            "Lakások": "187"
        },
        {
            "Ir. szám": "7465",
            "name": "Szentgáloskér",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.8833",
            "KSH kód": "12973",
            "Jogállás": "6",
            "Terület": "2725",
            "Népesség": "529",
            "Lakások": "247"
        },
        {
            "Ir. szám": "7471",
            "name": "Zimány",
            "Megyekód": "SO",
            "Lat": "46.4167",
            "Long": "17.9167",
            "KSH kód": "27614",
            "Jogállás": "6",
            "Terület": "1297",
            "Népesség": "626",
            "Lakások": "198"
        },
        {
            "Ir. szám": "7472",
            "name": "Cserénfa",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.8833",
            "KSH kód": "25681",
            "Jogállás": "6",
            "Terület": "1775",
            "Népesség": "210",
            "Lakások": "90"
        },
        {
            "Ir. szám": "7472",
            "name": "Szentbalázs",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.9",
            "KSH kód": "25706",
            "Jogállás": "6",
            "Terület": "1214",
            "Népesség": "322",
            "Lakások": "118"
        },
        {
            "Ir. szám": "7473",
            "name": "Gálosfa",
            "Megyekód": "SO",
            "Lat": "46.25",
            "Long": "17.9",
            "KSH kód": "06585",
            "Jogállás": "6",
            "Terület": "1976",
            "Népesség": "315",
            "Lakások": "118"
        },
        {
            "Ir. szám": "7473",
            "name": "Kaposgyarmat",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.8833",
            "KSH kód": "28811",
            "Jogállás": "6",
            "Terület": "1107",
            "Népesség": "107",
            "Lakások": "35"
        },
        {
            "Ir. szám": "7473",
            "name": "Hajmás",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.9167",
            "KSH kód": "25830",
            "Jogállás": "6",
            "Terület": "1111",
            "Népesség": "241",
            "Lakások": "85"
        },
        {
            "Ir. szám": "7474",
            "name": "Zselicszentpál",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.8333",
            "KSH kód": "17491",
            "Jogállás": "6",
            "Terület": "1035",
            "Népesség": "385",
            "Lakások": "150"
        },
        {
            "Ir. szám": "7474",
            "name": "Simonfa",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.8333",
            "KSH kód": "32780",
            "Jogállás": "6",
            "Terület": "1100",
            "Népesség": "374",
            "Lakások": "141"
        },
        {
            "Ir. szám": "7475",
            "name": "Bőszénfa",
            "Megyekód": "SO",
            "Lat": "46.2333",
            "Long": "17.85",
            "KSH kód": "26277",
            "Jogállás": "6",
            "Terület": "4295",
            "Népesség": "581",
            "Lakások": "195"
        },
        {
            "Ir. szám": "7476",
            "name": "Kaposszerdahely",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.75",
            "KSH kód": "06424",
            "Jogállás": "6",
            "Terület": "885",
            "Népesség": "999",
            "Lakások": "346"
        },
        {
            "Ir. szám": "7477",
            "name": "Patca",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.7167",
            "KSH kód": "12168",
            "Jogállás": "6",
            "Terület": "496",
            "Népesség": "57",
            "Lakások": "36"
        },
        {
            "Ir. szám": "7477",
            "name": "Szenna",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.7333",
            "KSH kód": "15103",
            "Jogállás": "6",
            "Terület": "2698",
            "Népesség": "763",
            "Lakások": "247"
        },
        {
            "Ir. szám": "7477",
            "name": "Zselickisfalud",
            "Megyekód": "SO",
            "Lat": "46.2667",
            "Long": "17.7333",
            "KSH kód": "26842",
            "Jogállás": "6",
            "Terület": "2608",
            "Népesség": "246",
            "Lakások": "114"
        },
        {
            "Ir. szám": "7477",
            "name": "Szilvásszentmárton",
            "Megyekód": "SO",
            "Lat": "46.2667",
            "Long": "17.7333",
            "KSH kód": "32461",
            "Jogállás": "6",
            "Terület": "708",
            "Népesség": "196",
            "Lakások": "70"
        },
        {
            "Ir. szám": "7478",
            "name": "Bárdudvarnok",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.6833",
            "KSH kód": "03735",
            "Jogállás": "6",
            "Terület": "4856",
            "Népesség": "1200",
            "Lakások": "544"
        },
        {
            "Ir. szám": "7479",
            "name": "Sántos",
            "Megyekód": "SO",
            "Lat": "46.35",
            "Long": "17.8833",
            "KSH kód": "31121",
            "Jogállás": "6",
            "Terület": "1138",
            "Népesség": "549",
            "Lakások": "186"
        },
        {
            "Ir. szám": "7500",
            "name": "Nagyatád",
            "Megyekód": "SO",
            "Lat": "46.2167",
            "Long": "17.3667",
            "KSH kód": "17941",
            "Jogállás": "4",
            "Terület": "7060",
            "Népesség": "11196",
            "Lakások": "5070"
        },
        {
            "Ir. szám": "7501",
            "name": "Nagyatád",
            "Megyekód": "SO",
            "Lat": "46.2167",
            "Long": "17.3667",
            "KSH kód": "17941",
            "Jogállás": "4",
            "Terület": "7060",
            "Népesség": "11196",
            "Lakások": "5070"
        },
        {
            "Ir. szám": "7511",
            "name": "Ötvöskónyi",
            "Megyekód": "SO",
            "Lat": "46.2167",
            "Long": "17.3667",
            "KSH kód": "32115",
            "Jogállás": "6",
            "Terület": "2771",
            "Népesség": "915",
            "Lakások": "300"
        },
        {
            "Ir. szám": "7512",
            "name": "Mike",
            "Megyekód": "SO",
            "Lat": "46.2333",
            "Long": "17.5333",
            "KSH kód": "20905",
            "Jogállás": "6",
            "Terület": "3241",
            "Népesség": "652",
            "Lakások": "250"
        },
        {
            "Ir. szám": "7513",
            "name": "Rinyaszentkirály",
            "Megyekód": "SO",
            "Lat": "46.15",
            "Long": "17.4",
            "KSH kód": "26754",
            "Jogállás": "6",
            "Terület": "3056",
            "Népesség": "394",
            "Lakások": "173"
        },
        {
            "Ir. szám": "7514",
            "name": "Tarany",
            "Megyekód": "SO",
            "Lat": "46.1833",
            "Long": "17.3",
            "KSH kód": "16735",
            "Jogállás": "6",
            "Terület": "5468",
            "Népesség": "1200",
            "Lakások": "470"
        },
        {
            "Ir. szám": "7515",
            "name": "Somogyudvarhely",
            "Megyekód": "SO",
            "Lat": "46.1667",
            "Long": "17.2",
            "KSH kód": "04853",
            "Jogállás": "6",
            "Terület": "4042",
            "Népesség": "1101",
            "Lakások": "455"
        },
        {
            "Ir. szám": "7516",
            "name": "Berzence",
            "Megyekód": "SO",
            "Lat": "46.2",
            "Long": "17.15",
            "KSH kód": "30119",
            "Jogállás": "5",
            "Terület": "5376",
            "Népesség": "2570",
            "Lakások": "997"
        },
        {
            "Ir. szám": "7517",
            "name": "Bolhás",
            "Megyekód": "SO",
            "Lat": "46.2667",
            "Long": "17.2833",
            "KSH kód": "13994",
            "Jogállás": "6",
            "Terület": "3143",
            "Népesség": "441",
            "Lakások": "196"
        },
        {
            "Ir. szám": "7521",
            "name": "Kaposmérő",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.7167",
            "KSH kód": "18227",
            "Jogállás": "6",
            "Terület": "1398",
            "Népesség": "2471",
            "Lakások": "856"
        },
        {
            "Ir. szám": "7522",
            "name": "Kaposújlak",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.7333",
            "KSH kód": "15732",
            "Jogállás": "6",
            "Terület": "893",
            "Népesség": "728",
            "Lakások": "274"
        },
        {
            "Ir. szám": "7523",
            "name": "Kisasszond",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.6333",
            "KSH kód": "19053",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "187",
            "Lakások": "71"
        },
        {
            "Ir. szám": "7523",
            "name": "Kaposfő",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.6667",
            "KSH kód": "09098",
            "Jogállás": "6",
            "Terület": "2726",
            "Népesség": "1730",
            "Lakások": "589"
        },
        {
            "Ir. szám": "7524",
            "name": "Kiskorpád",
            "Megyekód": "SO",
            "Lat": "46.35",
            "Long": "17.6167",
            "KSH kód": "13781",
            "Jogállás": "6",
            "Terület": "1695",
            "Népesség": "904",
            "Lakások": "347"
        },
        {
            "Ir. szám": "7525",
            "name": "Jákó",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.55",
            "KSH kód": "15927",
            "Jogállás": "6",
            "Terület": "1669",
            "Népesség": "641",
            "Lakások": "249"
        },
        {
            "Ir. szám": "7526",
            "name": "Csököly",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.55",
            "KSH kód": "24314",
            "Jogállás": "6",
            "Terület": "2984",
            "Népesség": "1154",
            "Lakások": "433"
        },
        {
            "Ir. szám": "7527",
            "name": "Rinyakovácsi",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.6",
            "KSH kód": "32674",
            "Jogállás": "6",
            "Terület": "1109",
            "Népesség": "144",
            "Lakások": "69"
        },
        {
            "Ir. szám": "7527",
            "name": "Gige",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.6167",
            "KSH kód": "09177",
            "Jogállás": "6",
            "Terület": "1319",
            "Népesség": "362",
            "Lakások": "136"
        },
        {
            "Ir. szám": "7530",
            "name": "Kőkút",
            "Megyekód": "SO",
            "Lat": "46.1833",
            "Long": "17.5833",
            "KSH kód": "13745",
            "Jogállás": "6",
            "Terület": "2023",
            "Népesség": "642",
            "Lakások": "135"
        },
        {
            "Ir. szám": "7530",
            "name": "Kadarkút",
            "Megyekód": "SO",
            "Lat": "46.2333",
            "Long": "17.6333",
            "KSH kód": "26453",
            "Jogállás": "4",
            "Terület": "3974",
            "Népesség": "2624",
            "Lakások": "979"
        },
        {
            "Ir. szám": "7532",
            "name": "Hencse",
            "Megyekód": "SO",
            "Lat": "46.2",
            "Long": "17.6333",
            "KSH kód": "09946",
            "Jogállás": "6",
            "Terület": "711",
            "Népesség": "367",
            "Lakások": "150"
        },
        {
            "Ir. szám": "7533",
            "name": "Visnye",
            "Megyekód": "SO",
            "Lat": "46.1833",
            "Long": "17.6833",
            "KSH kód": "19017",
            "Jogállás": "6",
            "Terület": "2438",
            "Népesség": "225",
            "Lakások": "116"
        },
        {
            "Ir. szám": "7533",
            "name": "Hedrehely",
            "Megyekód": "SO",
            "Lat": "46.1833",
            "Long": "17.6667",
            "KSH kód": "16726",
            "Jogállás": "6",
            "Terület": "2551",
            "Népesség": "435",
            "Lakások": "194"
        },
        {
            "Ir. szám": "7535",
            "name": "Lad",
            "Megyekód": "SO",
            "Lat": "46.15",
            "Long": "17.65",
            "KSH kód": "16258",
            "Jogállás": "6",
            "Terület": "2235",
            "Népesség": "592",
            "Lakások": "273"
        },
        {
            "Ir. szám": "7536",
            "name": "Patosfa",
            "Megyekód": "SO",
            "Lat": "46.1333",
            "Long": "17.6667",
            "KSH kód": "15592",
            "Jogállás": "6",
            "Terület": "1507",
            "Népesség": "254",
            "Lakások": "146"
        },
        {
            "Ir. szám": "7537",
            "name": "Homokszentgyörgy",
            "Megyekód": "SO",
            "Lat": "46.1167",
            "Long": "17.5833",
            "KSH kód": "19150",
            "Jogállás": "6",
            "Terület": "4964",
            "Népesség": "1137",
            "Lakások": "499"
        },
        {
            "Ir. szám": "7538",
            "name": "Kálmáncsa",
            "Megyekód": "SO",
            "Lat": "46.0667",
            "Long": "17.6167",
            "KSH kód": "06105",
            "Jogállás": "6",
            "Terület": "4885",
            "Népesség": "662",
            "Lakások": "248"
        },
        {
            "Ir. szám": "7539",
            "name": "Szulok",
            "Megyekód": "SO",
            "Lat": "46.05",
            "Long": "17.55",
            "KSH kód": "10986",
            "Jogállás": "6",
            "Terület": "2791",
            "Népesség": "620",
            "Lakások": "293"
        },
        {
            "Ir. szám": "7541",
            "name": "Kutas",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.45",
            "KSH kód": "28857",
            "Jogállás": "6",
            "Terület": "3655",
            "Népesség": "1507",
            "Lakások": "516"
        },
        {
            "Ir. szám": "7542",
            "name": "Kisbajom",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.5",
            "KSH kód": "24387",
            "Jogállás": "6",
            "Terület": "1367",
            "Népesség": "401",
            "Lakások": "160"
        },
        {
            "Ir. szám": "7543",
            "name": "Beleg",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.4167",
            "KSH kód": "17127",
            "Jogállás": "6",
            "Terület": "1799",
            "Népesség": "661",
            "Lakások": "233"
        },
        {
            "Ir. szám": "7544",
            "name": "Szabás",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.45",
            "KSH kód": "28574",
            "Jogállás": "6",
            "Terület": "1811",
            "Népesség": "652",
            "Lakások": "196"
        },
        {
            "Ir. szám": "7545",
            "name": "Nagykorpád",
            "Megyekód": "SO",
            "Lat": "46.2667",
            "Long": "17.4667",
            "KSH kód": "32805",
            "Jogállás": "6",
            "Terület": "3342",
            "Népesség": "609",
            "Lakások": "218"
        },
        {
            "Ir. szám": "7551",
            "name": "Lábod",
            "Megyekód": "SO",
            "Lat": "46.2",
            "Long": "17.45",
            "KSH kód": "28291",
            "Jogállás": "6",
            "Terület": "6651",
            "Népesség": "2074",
            "Lakások": "749"
        },
        {
            "Ir. szám": "7552",
            "name": "Rinyabesenyő",
            "Megyekód": "SO",
            "Lat": "46.1667",
            "Long": "17.5167",
            "KSH kód": "05078",
            "Jogállás": "6",
            "Terület": "2863",
            "Népesség": "176",
            "Lakások": "71"
        },
        {
            "Ir. szám": "7553",
            "name": "Görgeteg",
            "Megyekód": "SO",
            "Lat": "46.15",
            "Long": "17.4333",
            "KSH kód": "14599",
            "Jogállás": "6",
            "Terület": "3350",
            "Népesség": "1191",
            "Lakások": "449"
        },
        {
            "Ir. szám": "7555",
            "name": "Csokonyavisonta",
            "Megyekód": "SO",
            "Lat": "46.0833",
            "Long": "17.45",
            "KSH kód": "05971",
            "Jogállás": "6",
            "Terület": "8129",
            "Népesség": "1646",
            "Lakások": "644"
        },
        {
            "Ir. szám": "7556",
            "name": "Rinyaújlak",
            "Megyekód": "SO",
            "Lat": "46.0833",
            "Long": "17.4167",
            "KSH kód": "20622",
            "Jogállás": "6",
            "Terület": "2426",
            "Népesség": "306",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7557",
            "name": "Rinyaújlak",
            "Megyekód": "SO",
            "Lat": "46.0833",
            "Long": "17.4167",
            "KSH kód": "20622",
            "Jogállás": "6",
            "Terület": "2426",
            "Népesség": "306",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7561",
            "name": "Nagybajom",
            "Megyekód": "SO",
            "Lat": "46.3833",
            "Long": "17.5167",
            "KSH kód": "21652",
            "Jogállás": "4",
            "Terület": "10806",
            "Népesség": "3501",
            "Lakások": "1154"
        },
        {
            "Ir. szám": "7561",
            "name": "Pálmajor",
            "Megyekód": "SO",
            "Lat": "46.3833",
            "Long": "17.5667",
            "KSH kód": "34041",
            "Jogállás": "6",
            "Terület": "624",
            "Népesség": "378",
            "Lakások": "102"
        },
        {
            "Ir. szám": "7562",
            "name": "Segesd",
            "Megyekód": "SO",
            "Lat": "46.35",
            "Long": "17.35",
            "KSH kód": "27368",
            "Jogállás": "6",
            "Terület": "7309",
            "Népesség": "2580",
            "Lakások": "912"
        },
        {
            "Ir. szám": "7563",
            "name": "Somogyszob",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.3",
            "KSH kód": "18546",
            "Jogállás": "6",
            "Terület": "4008",
            "Népesség": "1640",
            "Lakások": "645"
        },
        {
            "Ir. szám": "7564",
            "name": "Kaszó",
            "Megyekód": "SO",
            "Lat": "46.3542",
            "Long": "17.4333",
            "KSH kód": "34193",
            "Jogállás": "6",
            "Terület": "2248",
            "Népesség": "124",
            "Lakások": "58"
        },
        {
            "Ir. szám": "7570",
            "name": "Barcs",
            "Megyekód": "SO",
            "Lat": "45.96",
            "Long": "17.4586",
            "KSH kód": "32799",
            "Jogállás": "4",
            "Terület": "12290",
            "Népesség": "11699",
            "Lakások": "4511"
        },
        {
            "Ir. szám": "7571",
            "name": "Barcs",
            "Megyekód": "SO",
            "Lat": "45.96",
            "Long": "17.4586",
            "KSH kód": "32799",
            "Jogállás": "4",
            "Terület": "12290",
            "Népesség": "11699",
            "Lakások": "4511"
        },
        {
            "Ir. szám": "7582",
            "name": "Komlósd",
            "Megyekód": "SO",
            "Lat": "46.0167",
            "Long": "17.3833",
            "KSH kód": "09858",
            "Jogállás": "6",
            "Terület": "746",
            "Népesség": "196",
            "Lakások": "92"
        },
        {
            "Ir. szám": "7582",
            "name": "Péterhida",
            "Megyekód": "SO",
            "Lat": "46.0167",
            "Long": "17.3667",
            "KSH kód": "29197",
            "Jogállás": "6",
            "Terület": "1162",
            "Népesség": "155",
            "Lakások": "101"
        },
        {
            "Ir. szám": "7584",
            "name": "Babócsa",
            "Megyekód": "SO",
            "Lat": "46.0333",
            "Long": "17.3667",
            "KSH kód": "30474",
            "Jogállás": "6",
            "Terület": "3099",
            "Népesség": "1653",
            "Lakások": "711"
        },
        {
            "Ir. szám": "7584",
            "name": "Rinyaújnép",
            "Megyekód": "SO",
            "Lat": "46.0833",
            "Long": "17.35",
            "KSH kód": "20321",
            "Jogállás": "6",
            "Terület": "828",
            "Népesség": "56",
            "Lakások": "51"
        },
        {
            "Ir. szám": "7584",
            "name": "Somogyaracs",
            "Megyekód": "SO",
            "Lat": "46.05",
            "Long": "17.4",
            "KSH kód": "05500",
            "Jogállás": "6",
            "Terület": "770",
            "Népesség": "206",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7585",
            "name": "Háromfa",
            "Megyekód": "SO",
            "Lat": "46.1",
            "Long": "17.3333",
            "KSH kód": "08837",
            "Jogállás": "6",
            "Terület": "4214",
            "Népesség": "797",
            "Lakások": "331"
        },
        {
            "Ir. szám": "7585",
            "name": "Bakháza",
            "Megyekód": "SO",
            "Lat": "46.1",
            "Long": "17.3667",
            "KSH kód": "14395",
            "Jogállás": "6",
            "Terület": "592",
            "Népesség": "199",
            "Lakások": "85"
        },
        {
            "Ir. szám": "7586",
            "name": "Bolhó",
            "Megyekód": "SO",
            "Lat": "46.0333",
            "Long": "17.3",
            "KSH kód": "18120",
            "Jogállás": "6",
            "Terület": "2507",
            "Népesség": "752",
            "Lakások": "401"
        },
        {
            "Ir. szám": "7587",
            "name": "Heresznye",
            "Megyekód": "SO",
            "Lat": "46.05",
            "Long": "17.2833",
            "KSH kód": "24846",
            "Jogállás": "6",
            "Terület": "990",
            "Népesség": "284",
            "Lakások": "158"
        },
        {
            "Ir. szám": "7588",
            "name": "Vízvár",
            "Megyekód": "SO",
            "Lat": "46.0833",
            "Long": "17.2333",
            "KSH kód": "29780",
            "Jogállás": "6",
            "Terület": "3224",
            "Népesség": "575",
            "Lakások": "349"
        },
        {
            "Ir. szám": "7589",
            "name": "Bélavár",
            "Megyekód": "SO",
            "Lat": "46.1167",
            "Long": "17.2167",
            "KSH kód": "06910",
            "Jogállás": "6",
            "Terület": "2278",
            "Népesség": "370",
            "Lakások": "221"
        },
        {
            "Ir. szám": "7600",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7601",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7602",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7603",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7604",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7605",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7606",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7607",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7608",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7610",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7611",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7612",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7613",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7614",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7615",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7616",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7617",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7618",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7619",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7621",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7622",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7623",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7624",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7625",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7626",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7627",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7628",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7629",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7630",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7631",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7632",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7633",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7634",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7635",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7636",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7639",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7643",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7644",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7645",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7646",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7647",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7648",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7650",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0967",
            "Long": "18.2068",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7661",
            "name": "Erzsébet",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.4667",
            "KSH kód": "13499",
            "Jogállás": "6",
            "Terület": "975",
            "Népesség": "316",
            "Lakások": "128"
        },
        {
            "Ir. szám": "7661",
            "name": "Kékesd",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.4667",
            "KSH kód": "16805",
            "Jogállás": "6",
            "Terület": "811",
            "Népesség": "185",
            "Lakások": "96"
        },
        {
            "Ir. szám": "7661",
            "name": "Kátoly",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.45",
            "KSH kód": "19132",
            "Jogállás": "6",
            "Terület": "875",
            "Népesség": "321",
            "Lakások": "146"
        },
        {
            "Ir. szám": "7661",
            "name": "Szellő",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.4667",
            "KSH kód": "16984",
            "Jogállás": "6",
            "Terület": "596",
            "Népesség": "167",
            "Lakások": "74"
        },
        {
            "Ir. szám": "7663",
            "name": "Máriakéménd",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.4667",
            "KSH kód": "14483",
            "Jogállás": "6",
            "Terület": "1578",
            "Népesség": "574",
            "Lakások": "214"
        },
        {
            "Ir. szám": "7664",
            "name": "Berkesd",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.4167",
            "KSH kód": "16461",
            "Jogállás": "6",
            "Terület": "1904",
            "Népesség": "868",
            "Lakások": "289"
        },
        {
            "Ir. szám": "7664",
            "name": "Szilágy",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.4167",
            "KSH kód": "19831",
            "Jogállás": "6",
            "Terület": "1155",
            "Népesség": "305",
            "Lakások": "132"
        },
        {
            "Ir. szám": "7664",
            "name": "Pereked",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.3833",
            "KSH kód": "12867",
            "Jogállás": "6",
            "Terület": "674",
            "Népesség": "179",
            "Lakások": "75"
        },
        {
            "Ir. szám": "7666",
            "name": "Pogány",
            "Megyekód": "BA",
            "Lat": "45.98",
            "Long": "18.26",
            "KSH kód": "17242",
            "Jogállás": "6",
            "Terület": "1155",
            "Népesség": "1105",
            "Lakások": "372"
        },
        {
            "Ir. szám": "7668",
            "name": "Gyód",
            "Megyekód": "BA",
            "Lat": "45.9958",
            "Long": "18.1786",
            "KSH kód": "18315",
            "Jogállás": "6",
            "Terület": "325",
            "Népesség": "699",
            "Lakások": "225"
        },
        {
            "Ir. szám": "7668",
            "name": "Keszü",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.1833",
            "KSH kód": "03832",
            "Jogállás": "6",
            "Terület": "731",
            "Népesség": "1326",
            "Lakások": "398"
        },
        {
            "Ir. szám": "7668",
            "name": "Kökény",
            "Megyekód": "BA",
            "Lat": "45.9969",
            "Long": "18.2028",
            "KSH kód": "03540",
            "Jogállás": "6",
            "Terület": "489",
            "Népesség": "609",
            "Lakások": "216"
        },
        {
            "Ir. szám": "7671",
            "name": "Bicsérd",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.0833",
            "KSH kód": "13310",
            "Jogállás": "6",
            "Terület": "1975",
            "Népesség": "1035",
            "Lakások": "349"
        },
        {
            "Ir. szám": "7671",
            "name": "Zók",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "18.1",
            "KSH kód": "12201",
            "Jogállás": "6",
            "Terület": "893",
            "Népesség": "294",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7671",
            "name": "Aranyosgadány",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "18.1167",
            "KSH kód": "06886",
            "Jogállás": "6",
            "Terület": "826",
            "Népesség": "340",
            "Lakások": "133"
        },
        {
            "Ir. szám": "7672",
            "name": "Boda",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.05",
            "KSH kód": "20899",
            "Jogállás": "6",
            "Terület": "1542",
            "Népesség": "438",
            "Lakások": "168"
        },
        {
            "Ir. szám": "7673",
            "name": "Kővágószőlős",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.1333",
            "KSH kód": "15538",
            "Jogállás": "6",
            "Terület": "1825",
            "Népesség": "1317",
            "Lakások": "505"
        },
        {
            "Ir. szám": "7673",
            "name": "Cserkút",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.1333",
            "KSH kód": "03896",
            "Jogállás": "6",
            "Terület": "667",
            "Népesség": "552",
            "Lakások": "167"
        },
        {
            "Ir. szám": "7675",
            "name": "Kővágótöttös",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.0833",
            "KSH kód": "06992",
            "Jogállás": "6",
            "Terület": "1394",
            "Népesség": "318",
            "Lakások": "122"
        },
        {
            "Ir. szám": "7675",
            "name": "Bakonya",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.0833",
            "KSH kód": "08299",
            "Jogállás": "6",
            "Terület": "1507",
            "Népesség": "388",
            "Lakások": "143"
        },
        {
            "Ir. szám": "7677",
            "name": "Orfű",
            "Megyekód": "BA",
            "Lat": "46.1333",
            "Long": "18.15",
            "KSH kód": "11730",
            "Jogállás": "6",
            "Terület": "3215",
            "Népesség": "882",
            "Lakások": "320"
        },
        {
            "Ir. szám": "7678",
            "name": "Abaliget",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.1167",
            "KSH kód": "12548",
            "Jogállás": "6",
            "Terület": "1609",
            "Népesség": "605",
            "Lakások": "233"
        },
        {
            "Ir. szám": "7678",
            "name": "Kovácsszénája",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.1167",
            "KSH kód": "14517",
            "Jogállás": "6",
            "Terület": "783",
            "Népesség": "52",
            "Lakások": "30"
        },
        {
            "Ir. szám": "7678",
            "name": "Husztót",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.0833",
            "KSH kód": "31431",
            "Jogállás": "6",
            "Terület": "675",
            "Népesség": "53",
            "Lakások": "37"
        },
        {
            "Ir. szám": "7681",
            "name": "Okorvölgy",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.0667",
            "KSH kód": "08341",
            "Jogállás": "6",
            "Terület": "311",
            "Népesség": "95",
            "Lakások": "47"
        },
        {
            "Ir. szám": "7681",
            "name": "Hetvehely",
            "Megyekód": "BA",
            "Lat": "46.1333",
            "Long": "18.05",
            "KSH kód": "07126",
            "Jogállás": "6",
            "Terület": "2138",
            "Népesség": "449",
            "Lakások": "160"
        },
        {
            "Ir. szám": "7681",
            "name": "Szentkatalin",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.05",
            "KSH kód": "09803",
            "Jogállás": "6",
            "Terület": "1331",
            "Népesség": "96",
            "Lakások": "73"
        },
        {
            "Ir. szám": "7682",
            "name": "Bükkösd",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "17.9833",
            "KSH kód": "23162",
            "Jogállás": "6",
            "Terület": "3015",
            "Népesség": "1160",
            "Lakások": "448"
        },
        {
            "Ir. szám": "7683",
            "name": "Helesfa",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.9833",
            "KSH kód": "17190",
            "Jogállás": "6",
            "Terület": "987",
            "Népesség": "538",
            "Lakások": "145"
        },
        {
            "Ir. szám": "7683",
            "name": "Dinnyeberki",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.95",
            "KSH kód": "11952",
            "Jogállás": "6",
            "Terület": "856",
            "Népesség": "88",
            "Lakások": "63"
        },
        {
            "Ir. szám": "7683",
            "name": "Cserdi",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18",
            "KSH kód": "26082",
            "Jogállás": "6",
            "Terület": "647",
            "Népesség": "385",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7691",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.95",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7691",
            "name": "Dinnyeberki",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.95",
            "KSH kód": "11952",
            "Jogállás": "6",
            "Terület": "856",
            "Népesség": "88",
            "Lakások": "63"
        },
        {
            "Ir. szám": "7693",
            "name": "Pécs",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.95",
            "KSH kód": "19415",
            "Jogállás": "1",
            "Terület": "16278",
            "Népesség": "156974",
            "Lakások": "69458"
        },
        {
            "Ir. szám": "7693",
            "name": "Dinnyeberki",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.95",
            "KSH kód": "11952",
            "Jogállás": "6",
            "Terület": "856",
            "Népesség": "88",
            "Lakások": "63"
        },
        {
            "Ir. szám": "7694",
            "name": "Hosszúhetény",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.35",
            "KSH kód": "30836",
            "Jogállás": "6",
            "Terület": "4527",
            "Népesség": "3413",
            "Lakások": "1160"
        },
        {
            "Ir. szám": "7695",
            "name": "Ófalu",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.5333",
            "KSH kód": "19646",
            "Jogállás": "6",
            "Terület": "985",
            "Népesség": "320",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7695",
            "name": "Mecseknádasd",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.4667",
            "KSH kód": "13444",
            "Jogállás": "6",
            "Terület": "3608",
            "Népesség": "1559",
            "Lakások": "631"
        },
        {
            "Ir. szám": "7695",
            "name": "Óbánya",
            "Megyekód": "BA",
            "Lat": "46.2167",
            "Long": "18.4167",
            "KSH kód": "12645",
            "Jogállás": "6",
            "Terület": "749",
            "Népesség": "135",
            "Lakások": "90"
        },
        {
            "Ir. szám": "7696",
            "name": "Hidas",
            "Megyekód": "BA",
            "Lat": "46.2667",
            "Long": "18.5",
            "KSH kód": "06798",
            "Jogállás": "6",
            "Terület": "1906",
            "Népesség": "2086",
            "Lakások": "816"
        },
        {
            "Ir. szám": "7700",
            "name": "Mohács",
            "Megyekód": "BA",
            "Lat": "45.9931",
            "Long": "18.6831",
            "KSH kód": "23959",
            "Jogállás": "4",
            "Terület": "11223",
            "Népesség": "19129",
            "Lakások": "8706"
        },
        {
            "Ir. szám": "7701",
            "name": "Mohács",
            "Megyekód": "BA",
            "Lat": "45.9931",
            "Long": "18.6831",
            "KSH kód": "23959",
            "Jogállás": "4",
            "Terület": "11223",
            "Népesség": "19129",
            "Lakások": "8706"
        },
        {
            "Ir. szám": "7702",
            "name": "Mohács",
            "Megyekód": "BA",
            "Lat": "45.9931",
            "Long": "18.6831",
            "KSH kód": "23959",
            "Jogállás": "4",
            "Terület": "11223",
            "Népesség": "19129",
            "Lakások": "8706"
        },
        {
            "Ir. szám": "7711",
            "name": "Bár",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "18.7167",
            "KSH kód": "24378",
            "Jogállás": "6",
            "Terület": "900",
            "Népesség": "543",
            "Lakások": "218"
        },
        {
            "Ir. szám": "7712",
            "name": "Dunaszekcső",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.7667",
            "KSH kód": "09186",
            "Jogállás": "6",
            "Terület": "3675",
            "Népesség": "1970",
            "Lakások": "955"
        },
        {
            "Ir. szám": "7714",
            "name": "Dunaszekcső",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.7667",
            "KSH kód": "09186",
            "Jogállás": "6",
            "Terület": "3675",
            "Népesség": "1970",
            "Lakások": "955"
        },
        {
            "Ir. szám": "7715",
            "name": "Dunaszekcső",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.7667",
            "KSH kód": "09186",
            "Jogállás": "6",
            "Terület": "3675",
            "Népesség": "1970",
            "Lakások": "955"
        },
        {
            "Ir. szám": "7716",
            "name": "Homorúd",
            "Megyekód": "BA",
            "Lat": "45.9814",
            "Long": "18.7922",
            "KSH kód": "29966",
            "Jogállás": "6",
            "Terület": "4473",
            "Népesség": "612",
            "Lakások": "400"
        },
        {
            "Ir. szám": "7717",
            "name": "Kölked",
            "Megyekód": "BA",
            "Lat": "45.9503",
            "Long": "18.7078",
            "KSH kód": "17899",
            "Jogállás": "6",
            "Terület": "6194",
            "Népesség": "1100",
            "Lakások": "414"
        },
        {
            "Ir. szám": "7718",
            "name": "Udvar",
            "Megyekód": "BA",
            "Lat": "45.9006",
            "Long": "18.6597",
            "KSH kód": "04437",
            "Jogállás": "6",
            "Terület": "440",
            "Népesség": "160",
            "Lakások": "67"
        },
        {
            "Ir. szám": "7720",
            "name": "Apátvarasd",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "18.4833",
            "KSH kód": "27298",
            "Jogállás": "6",
            "Terület": "814",
            "Népesség": "132",
            "Lakások": "42"
        },
        {
            "Ir. szám": "7720",
            "name": "Martonfa",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "18.3833",
            "KSH kód": "02228",
            "Jogállás": "6",
            "Terület": "569",
            "Népesség": "205",
            "Lakások": "82"
        },
        {
            "Ir. szám": "7720",
            "name": "Lovászhetény",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.4833",
            "KSH kód": "24624",
            "Jogállás": "6",
            "Terület": "895",
            "Népesség": "272",
            "Lakások": "117"
        },
        {
            "Ir. szám": "7720",
            "name": "Zengővárkony",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.4333",
            "KSH kód": "15848",
            "Jogállás": "6",
            "Terület": "1671",
            "Népesség": "419",
            "Lakások": "172"
        },
        {
            "Ir. szám": "7720",
            "name": "Pécsvárad",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.4167",
            "KSH kód": "10825",
            "Jogállás": "4",
            "Terület": "3603",
            "Népesség": "4058",
            "Lakások": "1476"
        },
        {
            "Ir. szám": "7723",
            "name": "Erdősmecske",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "18.5167",
            "KSH kód": "18704",
            "Jogállás": "6",
            "Terület": "2644",
            "Népesség": "398",
            "Lakások": "187"
        },
        {
            "Ir. szám": "7724",
            "name": "Feked",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "18.5667",
            "KSH kód": "04543",
            "Jogállás": "6",
            "Terület": "1933",
            "Népesség": "181",
            "Lakások": "117"
        },
        {
            "Ir. szám": "7725",
            "name": "Szebény",
            "Megyekód": "BA",
            "Lat": "46.1333",
            "Long": "18.6",
            "KSH kód": "03018",
            "Jogállás": "6",
            "Terület": "1437",
            "Népesség": "453",
            "Lakások": "215"
        },
        {
            "Ir. szám": "7726",
            "name": "Véménd",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.6167",
            "KSH kód": "20279",
            "Jogállás": "6",
            "Terület": "3181",
            "Népesség": "1500",
            "Lakások": "600"
        },
        {
            "Ir. szám": "7727",
            "name": "Palotabozsok",
            "Megyekód": "BA",
            "Lat": "46.1333",
            "Long": "18.65",
            "KSH kód": "10135",
            "Jogállás": "6",
            "Terület": "2019",
            "Népesség": "947",
            "Lakások": "429"
        },
        {
            "Ir. szám": "7728",
            "name": "Görcsönydoboka",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.6333",
            "KSH kód": "09636",
            "Jogállás": "6",
            "Terület": "951",
            "Népesség": "409",
            "Lakások": "186"
        },
        {
            "Ir. szám": "7728",
            "name": "Somberek",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.6667",
            "KSH kód": "23472",
            "Jogállás": "6",
            "Terület": "3142",
            "Népesség": "1464",
            "Lakások": "532"
        },
        {
            "Ir. szám": "7731",
            "name": "Nagypall",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "18.45",
            "KSH kód": "19877",
            "Jogállás": "6",
            "Terület": "713",
            "Népesség": "432",
            "Lakások": "156"
        },
        {
            "Ir. szám": "7732",
            "name": "Fazekasboda",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "18.4833",
            "KSH kód": "17835",
            "Jogállás": "6",
            "Terület": "702",
            "Népesség": "187",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7733",
            "name": "Maráza",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.5167",
            "KSH kód": "29920",
            "Jogállás": "6",
            "Terület": "970",
            "Népesség": "186",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7733",
            "name": "Geresdlak",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.5333",
            "KSH kód": "02857",
            "Jogállás": "6",
            "Terület": "2528",
            "Népesség": "819",
            "Lakások": "414"
        },
        {
            "Ir. szám": "7735",
            "name": "Szűr",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "18.5833",
            "KSH kód": "11217",
            "Jogállás": "6",
            "Terület": "783",
            "Népesség": "282",
            "Lakások": "125"
        },
        {
            "Ir. szám": "7735",
            "name": "Erdősmárok",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "18.55",
            "KSH kód": "25821",
            "Jogállás": "6",
            "Terület": "814",
            "Népesség": "84",
            "Lakások": "63"
        },
        {
            "Ir. szám": "7735",
            "name": "Himesháza",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.5667",
            "KSH kód": "27933",
            "Jogállás": "6",
            "Terület": "1754",
            "Népesség": "1116",
            "Lakások": "453"
        },
        {
            "Ir. szám": "7737",
            "name": "Székelyszabar",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "18.6",
            "KSH kód": "16771",
            "Jogállás": "6",
            "Terület": "1548",
            "Népesség": "612",
            "Lakások": "263"
        },
        {
            "Ir. szám": "7741",
            "name": "Bogád",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.3333",
            "KSH kód": "32151",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "1080",
            "Lakások": "323"
        },
        {
            "Ir. szám": "7741",
            "name": "Nagykozár",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "18.3333",
            "KSH kód": "10940",
            "Jogállás": "6",
            "Terület": "1146",
            "Népesség": "1839",
            "Lakások": "591"
        },
        {
            "Ir. szám": "7741",
            "name": "Romonya",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.35",
            "KSH kód": "24855",
            "Jogállás": "6",
            "Terület": "707",
            "Népesség": "456",
            "Lakások": "163"
        },
        {
            "Ir. szám": "7742",
            "name": "Bogád",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.3333",
            "KSH kód": "32151",
            "Jogállás": "6",
            "Terület": "784",
            "Népesség": "1080",
            "Lakások": "323"
        },
        {
            "Ir. szám": "7743",
            "name": "Romonya",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "18.35",
            "KSH kód": "24855",
            "Jogállás": "6",
            "Terület": "707",
            "Népesség": "456",
            "Lakások": "163"
        },
        {
            "Ir. szám": "7744",
            "name": "Ellend",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "18.3833",
            "KSH kód": "06099",
            "Jogállás": "6",
            "Terület": "799",
            "Népesség": "239",
            "Lakások": "97"
        },
        {
            "Ir. szám": "7745",
            "name": "Olasz",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.4167",
            "KSH kód": "18555",
            "Jogállás": "6",
            "Terület": "1029",
            "Népesség": "640",
            "Lakások": "254"
        },
        {
            "Ir. szám": "7745",
            "name": "Hásságy",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.3833",
            "KSH kód": "27881",
            "Jogállás": "6",
            "Terület": "800",
            "Népesség": "268",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7747",
            "name": "Belvárdgyula",
            "Megyekód": "BA",
            "Lat": "45.9722",
            "Long": "18.4339",
            "KSH kód": "19008",
            "Jogállás": "6",
            "Terület": "1723",
            "Népesség": "423",
            "Lakások": "183"
        },
        {
            "Ir. szám": "7747",
            "name": "Birján",
            "Megyekód": "BA",
            "Lat": "45.9978",
            "Long": "18.3747",
            "KSH kód": "05139",
            "Jogállás": "6",
            "Terület": "907",
            "Népesség": "492",
            "Lakások": "198"
        },
        {
            "Ir. szám": "7751",
            "name": "Szederkény",
            "Megyekód": "BA",
            "Lat": "45.9969",
            "Long": "18.4578",
            "KSH kód": "05607",
            "Jogállás": "6",
            "Terület": "1433",
            "Népesség": "1827",
            "Lakások": "638"
        },
        {
            "Ir. szám": "7751",
            "name": "Monyoród",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "18.4833",
            "KSH kód": "16027",
            "Jogállás": "6",
            "Terület": "712",
            "Népesség": "190",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7752",
            "name": "Versend",
            "Megyekód": "BA",
            "Lat": "45.9953",
            "Long": "18.5114",
            "KSH kód": "19725",
            "Jogállás": "6",
            "Terület": "1413",
            "Népesség": "925",
            "Lakások": "302"
        },
        {
            "Ir. szám": "7753",
            "name": "Szajk",
            "Megyekód": "BA",
            "Lat": "45.9906",
            "Long": "18.5314",
            "KSH kód": "08475",
            "Jogállás": "6",
            "Terület": "1136",
            "Népesség": "857",
            "Lakások": "313"
        },
        {
            "Ir. szám": "7754",
            "name": "Bóly",
            "Megyekód": "BA",
            "Lat": "45.9672",
            "Long": "18.5183",
            "KSH kód": "33154",
            "Jogállás": "4",
            "Terület": "2538",
            "Népesség": "3970",
            "Lakások": "1506"
        },
        {
            "Ir. szám": "7755",
            "name": "Töttös",
            "Megyekód": "BA",
            "Lat": "45.9156",
            "Long": "18.5422",
            "KSH kód": "04136",
            "Jogállás": "6",
            "Terület": "2299",
            "Népesség": "587",
            "Lakások": "264"
        },
        {
            "Ir. szám": "7756",
            "name": "Borjád",
            "Megyekód": "BA",
            "Lat": "45.9339",
            "Long": "18.4672",
            "KSH kód": "06725",
            "Jogállás": "6",
            "Terület": "1559",
            "Népesség": "422",
            "Lakások": "160"
        },
        {
            "Ir. szám": "7756",
            "name": "Pócsa",
            "Megyekód": "BA",
            "Lat": "45.9097",
            "Long": "18.4708",
            "KSH kód": "22266",
            "Jogállás": "6",
            "Terület": "805",
            "Népesség": "193",
            "Lakások": "73"
        },
        {
            "Ir. szám": "7756",
            "name": "Kisbudmér",
            "Megyekód": "BA",
            "Lat": "45.9125",
            "Long": "18.4475",
            "KSH kód": "22868",
            "Jogállás": "6",
            "Terület": "572",
            "Népesség": "126",
            "Lakások": "47"
        },
        {
            "Ir. szám": "7756",
            "name": "Nagybudmér",
            "Megyekód": "BA",
            "Lat": "45.9361",
            "Long": "18.4456",
            "KSH kód": "03984",
            "Jogállás": "6",
            "Terület": "1022",
            "Népesség": "227",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7757",
            "name": "Babarc",
            "Megyekód": "BA",
            "Lat": "45.9981",
            "Long": "18.5547",
            "KSH kód": "05403",
            "Jogállás": "6",
            "Terület": "1885",
            "Népesség": "758",
            "Lakások": "326"
        },
        {
            "Ir. szám": "7757",
            "name": "Liptód",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "18.5167",
            "KSH kód": "10038",
            "Jogállás": "6",
            "Terület": "1499",
            "Népesség": "208",
            "Lakások": "145"
        },
        {
            "Ir. szám": "7759",
            "name": "Lánycsók",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "18.6333",
            "KSH kód": "33330",
            "Jogállás": "6",
            "Terület": "2540",
            "Népesség": "2608",
            "Lakások": "877"
        },
        {
            "Ir. szám": "7759",
            "name": "Kisnyárád",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.5667",
            "KSH kód": "33215",
            "Jogállás": "6",
            "Terület": "903",
            "Népesség": "197",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7761",
            "name": "Lothárd",
            "Megyekód": "BA",
            "Lat": "45.9989",
            "Long": "18.3525",
            "KSH kód": "31389",
            "Jogállás": "6",
            "Terület": "1046",
            "Népesség": "266",
            "Lakások": "110"
        },
        {
            "Ir. szám": "7761",
            "name": "Kozármisleny",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.3",
            "KSH kód": "06336",
            "Jogállás": "4",
            "Terület": "1445",
            "Népesség": "5802",
            "Lakások": "1998"
        },
        {
            "Ir. szám": "7761",
            "name": "Magyarsarlós",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.3667",
            "KSH kód": "27067",
            "Jogállás": "6",
            "Terület": "803",
            "Népesség": "313",
            "Lakások": "113"
        },
        {
            "Ir. szám": "7762",
            "name": "Pécsudvard",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.2833",
            "KSH kód": "21096",
            "Jogállás": "6",
            "Terület": "843",
            "Népesség": "732",
            "Lakások": "276"
        },
        {
            "Ir. szám": "7763",
            "name": "Áta",
            "Megyekód": "BA",
            "Lat": "45.9364",
            "Long": "18.2947",
            "KSH kód": "28583",
            "Jogállás": "6",
            "Terület": "809",
            "Népesség": "186",
            "Lakások": "92"
        },
        {
            "Ir. szám": "7763",
            "name": "Szemely",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.3333",
            "KSH kód": "09308",
            "Jogállás": "6",
            "Terület": "947",
            "Népesség": "479",
            "Lakások": "173"
        },
        {
            "Ir. szám": "7763",
            "name": "Egerág",
            "Megyekód": "BA",
            "Lat": "45.9839",
            "Long": "18.3047",
            "KSH kód": "28918",
            "Jogállás": "6",
            "Terület": "1301",
            "Népesség": "1002",
            "Lakások": "339"
        },
        {
            "Ir. szám": "7763",
            "name": "Szőkéd",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "18.2869",
            "KSH kód": "07694",
            "Jogállás": "6",
            "Terület": "958",
            "Népesség": "388",
            "Lakások": "138"
        },
        {
            "Ir. szám": "7763",
            "name": "Kisherend",
            "Megyekód": "BA",
            "Lat": "45.9661",
            "Long": "18.3297",
            "KSH kód": "16975",
            "Jogállás": "6",
            "Terület": "692",
            "Népesség": "178",
            "Lakások": "81"
        },
        {
            "Ir. szám": "7766",
            "name": "Pécsdevecser",
            "Megyekód": "BA",
            "Lat": "45.9567",
            "Long": "18.3842",
            "KSH kód": "15389",
            "Jogállás": "6",
            "Terület": "474",
            "Népesség": "107",
            "Lakások": "49"
        },
        {
            "Ir. szám": "7766",
            "name": "Újpetre",
            "Megyekód": "BA",
            "Lat": "45.9367",
            "Long": "18.3625",
            "KSH kód": "06062",
            "Jogállás": "6",
            "Terület": "1671",
            "Népesség": "1099",
            "Lakások": "386"
        },
        {
            "Ir. szám": "7766",
            "name": "Peterd",
            "Megyekód": "BA",
            "Lat": "45.9742",
            "Long": "18.3589",
            "KSH kód": "32425",
            "Jogállás": "6",
            "Terület": "1060",
            "Népesség": "204",
            "Lakások": "95"
        },
        {
            "Ir. szám": "7766",
            "name": "Kiskassa",
            "Megyekód": "BA",
            "Lat": "45.9531",
            "Long": "18.3978",
            "KSH kód": "19910",
            "Jogállás": "6",
            "Terület": "1333",
            "Népesség": "263",
            "Lakások": "112"
        },
        {
            "Ir. szám": "7768",
            "name": "Kistótfalu",
            "Megyekód": "BA",
            "Lat": "45.9075",
            "Long": "18.3075",
            "KSH kód": "10746",
            "Jogállás": "6",
            "Terület": "1050",
            "Népesség": "331",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7768",
            "name": "Vokány",
            "Megyekód": "BA",
            "Lat": "45.9094",
            "Long": "18.3361",
            "KSH kód": "05892",
            "Jogállás": "6",
            "Terület": "1709",
            "Népesség": "875",
            "Lakások": "355"
        },
        {
            "Ir. szám": "7771",
            "name": "Palkonya",
            "Megyekód": "BA",
            "Lat": "45.8967",
            "Long": "18.3897",
            "KSH kód": "07153",
            "Jogállás": "6",
            "Terület": "1000",
            "Népesség": "295",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7772",
            "name": "Ivánbattyán",
            "Megyekód": "BA",
            "Lat": "45.9072",
            "Long": "18.4181",
            "KSH kód": "03337",
            "Jogállás": "6",
            "Terület": "613",
            "Népesség": "134",
            "Lakások": "70"
        },
        {
            "Ir. szám": "7772",
            "name": "Villánykövesd",
            "Megyekód": "BA",
            "Lat": "45.8822",
            "Long": "18.4253",
            "KSH kód": "05209",
            "Jogállás": "6",
            "Terület": "749",
            "Népesség": "284",
            "Lakások": "128"
        },
        {
            "Ir. szám": "7773",
            "name": "Villány",
            "Megyekód": "BA",
            "Lat": "45.8689",
            "Long": "18.4539",
            "KSH kód": "28024",
            "Jogállás": "4",
            "Terület": "2202",
            "Népesség": "2480",
            "Lakások": "1010"
        },
        {
            "Ir. szám": "7773",
            "name": "Kisjakabfalva",
            "Megyekód": "BA",
            "Lat": "45.8958",
            "Long": "18.4347",
            "KSH kód": "12849",
            "Jogállás": "6",
            "Terület": "662",
            "Népesség": "125",
            "Lakások": "82"
        },
        {
            "Ir. szám": "7774",
            "name": "Márok",
            "Megyekód": "BA",
            "Lat": "45.8736",
            "Long": "18.5086",
            "KSH kód": "20659",
            "Jogállás": "6",
            "Terület": "1593",
            "Népesség": "455",
            "Lakások": "191"
        },
        {
            "Ir. szám": "7775",
            "name": "Illocska",
            "Megyekód": "BA",
            "Lat": "45.8003",
            "Long": "18.5225",
            "KSH kód": "31936",
            "Jogállás": "6",
            "Terület": "1510",
            "Népesség": "228",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7775",
            "name": "Magyarbóly",
            "Megyekód": "BA",
            "Lat": "45.8419",
            "Long": "18.4914",
            "KSH kód": "25177",
            "Jogállás": "6",
            "Terület": "1722",
            "Népesség": "1025",
            "Lakások": "376"
        },
        {
            "Ir. szám": "7775",
            "name": "Kislippó",
            "Megyekód": "BA",
            "Lat": "45.8272",
            "Long": "18.5297",
            "KSH kód": "21616",
            "Jogállás": "6",
            "Terület": "837",
            "Népesség": "279",
            "Lakások": "123"
        },
        {
            "Ir. szám": "7775",
            "name": "Lapáncsa",
            "Megyekód": "BA",
            "Lat": "45.8192",
            "Long": "18.4978",
            "KSH kód": "06479",
            "Jogállás": "6",
            "Terület": "436",
            "Népesség": "201",
            "Lakások": "77"
        },
        {
            "Ir. szám": "7781",
            "name": "Ivándárda",
            "Megyekód": "BA",
            "Lat": "45.8306",
            "Long": "18.5903",
            "KSH kód": "03346",
            "Jogállás": "6",
            "Terület": "767",
            "Népesség": "248",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7781",
            "name": "Lippó",
            "Megyekód": "BA",
            "Lat": "45.8628",
            "Long": "18.57",
            "KSH kód": "22974",
            "Jogállás": "6",
            "Terület": "1453",
            "Népesség": "465",
            "Lakások": "212"
        },
        {
            "Ir. szám": "7781",
            "name": "Sárok",
            "Megyekód": "BA",
            "Lat": "45.8411",
            "Long": "18.6114",
            "KSH kód": "20862",
            "Jogállás": "6",
            "Terület": "462",
            "Népesség": "131",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7782",
            "name": "Bezedek",
            "Megyekód": "BA",
            "Lat": "45.8647",
            "Long": "18.5864",
            "KSH kód": "14119",
            "Jogállás": "6",
            "Terület": "1136",
            "Népesség": "242",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7783",
            "name": "Majs",
            "Megyekód": "BA",
            "Lat": "45.9092",
            "Long": "18.6003",
            "KSH kód": "27863",
            "Jogállás": "6",
            "Terület": "3206",
            "Népesség": "1002",
            "Lakások": "394"
        },
        {
            "Ir. szám": "7784",
            "name": "Nagynyárád",
            "Megyekód": "BA",
            "Lat": "45.9433",
            "Long": "18.5781",
            "KSH kód": "14650",
            "Jogállás": "6",
            "Terület": "2434",
            "Népesség": "739",
            "Lakások": "330"
        },
        {
            "Ir. szám": "7785",
            "name": "Sátorhely",
            "Megyekód": "BA",
            "Lat": "45.9408",
            "Long": "18.6344",
            "KSH kód": "33482",
            "Jogállás": "6",
            "Terület": "1760",
            "Népesség": "668",
            "Lakások": "242"
        },
        {
            "Ir. szám": "7800",
            "name": "Kisharsány",
            "Megyekód": "BA",
            "Lat": "45.8586",
            "Long": "18.3642",
            "KSH kód": "27021",
            "Jogállás": "6",
            "Terület": "1293",
            "Népesség": "557",
            "Lakások": "195"
        },
        {
            "Ir. szám": "7800",
            "name": "Siklós",
            "Megyekód": "BA",
            "Lat": "45.8553",
            "Long": "18.2986",
            "KSH kód": "05519",
            "Jogállás": "4",
            "Terület": "5092",
            "Népesség": "9944",
            "Lakások": "4002"
        },
        {
            "Ir. szám": "7800",
            "name": "Nagytótfalu",
            "Megyekód": "BA",
            "Lat": "45.8639",
            "Long": "18.3453",
            "KSH kód": "28927",
            "Jogállás": "6",
            "Terület": "1117",
            "Népesség": "396",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7801",
            "name": "Nagytótfalu",
            "Megyekód": "BA",
            "Lat": "45.8639",
            "Long": "18.3453",
            "KSH kód": "28927",
            "Jogállás": "6",
            "Terület": "1117",
            "Népesség": "396",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7802",
            "name": "Nagytótfalu",
            "Megyekód": "BA",
            "Lat": "45.8639",
            "Long": "18.3453",
            "KSH kód": "28927",
            "Jogállás": "6",
            "Terület": "1117",
            "Népesség": "396",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7811",
            "name": "Bisse",
            "Megyekód": "BA",
            "Lat": "45.9078",
            "Long": "18.2581",
            "KSH kód": "24925",
            "Jogállás": "6",
            "Terület": "1380",
            "Népesség": "242",
            "Lakások": "117"
        },
        {
            "Ir. szám": "7811",
            "name": "Szalánta",
            "Megyekód": "BA",
            "Lat": "45.9486",
            "Long": "18.2375",
            "KSH kód": "22257",
            "Jogállás": "6",
            "Terület": "1708",
            "Népesség": "1250",
            "Lakások": "416"
        },
        {
            "Ir. szám": "7811",
            "name": "Bosta",
            "Megyekód": "BA",
            "Lat": "45.9483",
            "Long": "18.2092",
            "KSH kód": "14368",
            "Jogállás": "6",
            "Terület": "595",
            "Népesség": "140",
            "Lakások": "54"
        },
        {
            "Ir. szám": "7811",
            "name": "Szilvás",
            "Megyekód": "BA",
            "Lat": "45.9622",
            "Long": "18.2",
            "KSH kód": "24712",
            "Jogállás": "6",
            "Terület": "604",
            "Népesség": "168",
            "Lakások": "58"
        },
        {
            "Ir. szám": "7811",
            "name": "Csarnóta",
            "Megyekód": "BA",
            "Lat": "45.8953",
            "Long": "18.2147",
            "KSH kód": "30614",
            "Jogállás": "6",
            "Terület": "533",
            "Népesség": "134",
            "Lakások": "57"
        },
        {
            "Ir. szám": "7811",
            "name": "Túrony",
            "Megyekód": "BA",
            "Lat": "45.9044",
            "Long": "18.2317",
            "KSH kód": "18582",
            "Jogállás": "6",
            "Terület": "940",
            "Népesség": "251",
            "Lakások": "97"
        },
        {
            "Ir. szám": "7812",
            "name": "Garé",
            "Megyekód": "BA",
            "Lat": "45.9189",
            "Long": "18.1933",
            "KSH kód": "07560",
            "Jogállás": "6",
            "Terület": "853",
            "Népesség": "318",
            "Lakások": "113"
        },
        {
            "Ir. szám": "7813",
            "name": "Szava",
            "Megyekód": "BA",
            "Lat": "45.9022",
            "Long": "18.1761",
            "KSH kód": "31060",
            "Jogállás": "6",
            "Terület": "1264",
            "Népesség": "340",
            "Lakások": "127"
        },
        {
            "Ir. szám": "7814",
            "name": "Babarcszőlős",
            "Megyekód": "BA",
            "Lat": "45.9",
            "Long": "18.1364",
            "KSH kód": "09663",
            "Jogállás": "6",
            "Terület": "425",
            "Népesség": "116",
            "Lakások": "66"
        },
        {
            "Ir. szám": "7814",
            "name": "Ócsárd",
            "Megyekód": "BA",
            "Lat": "45.9342",
            "Long": "18.1519",
            "KSH kód": "03780",
            "Jogállás": "6",
            "Terület": "1266",
            "Népesség": "410",
            "Lakások": "142"
        },
        {
            "Ir. szám": "7814",
            "name": "Kisdér",
            "Megyekód": "BA",
            "Lat": "45.9406",
            "Long": "18.1281",
            "KSH kód": "11183",
            "Jogállás": "6",
            "Terület": "437",
            "Népesség": "126",
            "Lakások": "54"
        },
        {
            "Ir. szám": "7814",
            "name": "Siklósbodony",
            "Megyekód": "BA",
            "Lat": "45.9103",
            "Long": "18.1203",
            "KSH kód": "23205",
            "Jogállás": "6",
            "Terület": "433",
            "Népesség": "138",
            "Lakások": "46"
        },
        {
            "Ir. szám": "7815",
            "name": "Harkány",
            "Megyekód": "BA",
            "Lat": "45.8478",
            "Long": "18.2375",
            "KSH kód": "21528",
            "Jogállás": "4",
            "Terület": "2569",
            "Népesség": "4013",
            "Lakások": "1814"
        },
        {
            "Ir. szám": "7817",
            "name": "Rádfalva",
            "Megyekód": "BA",
            "Lat": "45.8597",
            "Long": "18.1258",
            "KSH kód": "11518",
            "Jogállás": "6",
            "Terület": "1288",
            "Népesség": "202",
            "Lakások": "90"
        },
        {
            "Ir. szám": "7817",
            "name": "Diósviszló",
            "Megyekód": "BA",
            "Lat": "45.8772",
            "Long": "18.1633",
            "KSH kód": "32373",
            "Jogállás": "6",
            "Terület": "1581",
            "Népesség": "725",
            "Lakások": "247"
        },
        {
            "Ir. szám": "7817",
            "name": "Márfa",
            "Megyekód": "BA",
            "Lat": "45.86",
            "Long": "18.1853",
            "KSH kód": "09450",
            "Jogállás": "6",
            "Terület": "409",
            "Népesség": "220",
            "Lakások": "68"
        },
        {
            "Ir. szám": "7822",
            "name": "Nagyharsány",
            "Megyekód": "BA",
            "Lat": "45.8464",
            "Long": "18.3922",
            "KSH kód": "02653",
            "Jogállás": "6",
            "Terület": "2601",
            "Népesség": "1572",
            "Lakások": "579"
        },
        {
            "Ir. szám": "7823",
            "name": "Kistapolca",
            "Megyekód": "BA",
            "Lat": "45.8219",
            "Long": "18.3847",
            "KSH kód": "03151",
            "Jogállás": "6",
            "Terület": "357",
            "Népesség": "200",
            "Lakások": "72"
        },
        {
            "Ir. szám": "7823",
            "name": "Siklósnagyfalu",
            "Megyekód": "BA",
            "Lat": "45.8197",
            "Long": "18.3614",
            "KSH kód": "08800",
            "Jogállás": "6",
            "Terület": "909",
            "Népesség": "413",
            "Lakások": "132"
        },
        {
            "Ir. szám": "7824",
            "name": "Egyházasharaszti",
            "Megyekód": "BA",
            "Lat": "45.8094",
            "Long": "18.3336",
            "KSH kód": "16498",
            "Jogállás": "6",
            "Terület": "1099",
            "Népesség": "313",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7824",
            "name": "Old",
            "Megyekód": "BA",
            "Lat": "45.7881",
            "Long": "18.3522",
            "KSH kód": "21704",
            "Jogállás": "6",
            "Terület": "1407",
            "Népesség": "348",
            "Lakások": "152"
        },
        {
            "Ir. szám": "7826",
            "name": "Alsószentmárton",
            "Megyekód": "BA",
            "Lat": "45.7892",
            "Long": "18.3056",
            "KSH kód": "33279",
            "Jogállás": "6",
            "Terület": "1361",
            "Népesség": "1172",
            "Lakások": "308"
        },
        {
            "Ir. szám": "7827",
            "name": "Kásád",
            "Megyekód": "BA",
            "Lat": "45.7783",
            "Long": "18.3989",
            "KSH kód": "17464",
            "Jogállás": "6",
            "Terület": "1329",
            "Népesség": "322",
            "Lakások": "138"
        },
        {
            "Ir. szám": "7827",
            "name": "Beremend",
            "Megyekód": "BA",
            "Lat": "45.7853",
            "Long": "18.4347",
            "KSH kód": "31927",
            "Jogállás": "5",
            "Terület": "4826",
            "Népesség": "2651",
            "Lakások": "1030"
        },
        {
            "Ir. szám": "7831",
            "name": "Pellérd",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "18.15",
            "KSH kód": "16115",
            "Jogállás": "6",
            "Terület": "2093",
            "Népesség": "2119",
            "Lakások": "653"
        },
        {
            "Ir. szám": "7833",
            "name": "Görcsöny",
            "Megyekód": "BA",
            "Lat": "45.9694",
            "Long": "18.1358",
            "KSH kód": "30438",
            "Jogállás": "6",
            "Terület": "1852",
            "Népesség": "1696",
            "Lakások": "533"
        },
        {
            "Ir. szám": "7833",
            "name": "Szőke",
            "Megyekód": "BA",
            "Lat": "45.96",
            "Long": "18.1858",
            "KSH kód": "22211",
            "Jogállás": "6",
            "Terület": "648",
            "Népesség": "139",
            "Lakások": "55"
        },
        {
            "Ir. szám": "7833",
            "name": "Regenye",
            "Megyekód": "BA",
            "Lat": "45.9692",
            "Long": "18.1681",
            "KSH kód": "25292",
            "Jogállás": "6",
            "Terület": "615",
            "Népesség": "140",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7834",
            "name": "Baksa",
            "Megyekód": "BA",
            "Lat": "45.9536",
            "Long": "18.0889",
            "KSH kód": "03975",
            "Jogállás": "6",
            "Terület": "1382",
            "Népesség": "773",
            "Lakások": "270"
        },
        {
            "Ir. szám": "7834",
            "name": "Tengeri",
            "Megyekód": "BA",
            "Lat": "45.9261",
            "Long": "18.0881",
            "KSH kód": "13675",
            "Jogállás": "6",
            "Terület": "446",
            "Népesség": "54",
            "Lakások": "30"
        },
        {
            "Ir. szám": "7834",
            "name": "Téseny",
            "Megyekód": "BA",
            "Lat": "45.9399",
            "Long": "18.0885",
            "KSH kód": "20978",
            "Jogállás": "6",
            "Terület": "1208",
            "Népesség": "319",
            "Lakások": "110"
        },
        {
            "Ir. szám": "7836",
            "name": "Bogádmindszent",
            "Megyekód": "BA",
            "Lat": "45.9069",
            "Long": "18.0406",
            "KSH kód": "21892",
            "Jogállás": "6",
            "Terület": "1158",
            "Népesség": "417",
            "Lakások": "135"
        },
        {
            "Ir. szám": "7836",
            "name": "Ózdfalu",
            "Megyekód": "BA",
            "Lat": "45.9292",
            "Long": "18.0217",
            "KSH kód": "18661",
            "Jogállás": "6",
            "Terület": "800",
            "Népesség": "185",
            "Lakások": "70"
        },
        {
            "Ir. szám": "7837",
            "name": "Hegyszentmárton",
            "Megyekód": "BA",
            "Lat": "45.9039",
            "Long": "18.09",
            "KSH kód": "21023",
            "Jogállás": "6",
            "Terület": "1878",
            "Népesség": "432",
            "Lakások": "166"
        },
        {
            "Ir. szám": "7838",
            "name": "Nagycsány",
            "Megyekód": "BA",
            "Lat": "45.8667",
            "Long": "17.95",
            "KSH kód": "18111",
            "Jogállás": "6",
            "Terület": "565",
            "Népesség": "153",
            "Lakások": "53"
        },
        {
            "Ir. szám": "7838",
            "name": "Hirics",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "18",
            "KSH kód": "03285",
            "Jogállás": "6",
            "Terület": "1468",
            "Népesség": "240",
            "Lakások": "78"
        },
        {
            "Ir. szám": "7838",
            "name": "Piskó",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "17.95",
            "KSH kód": "23506",
            "Jogállás": "6",
            "Terület": "1155",
            "Népesség": "257",
            "Lakások": "84"
        },
        {
            "Ir. szám": "7838",
            "name": "Besence",
            "Megyekód": "BA",
            "Lat": "45.9",
            "Long": "17.9667",
            "KSH kód": "30049",
            "Jogállás": "6",
            "Terület": "958",
            "Népesség": "129",
            "Lakások": "48"
        },
        {
            "Ir. szám": "7838",
            "name": "Vajszló",
            "Megyekód": "BA",
            "Lat": "45.8633",
            "Long": "17.9761",
            "KSH kód": "28538",
            "Jogállás": "5",
            "Terület": "1771",
            "Népesség": "1781",
            "Lakások": "707"
        },
        {
            "Ir. szám": "7838",
            "name": "Lúzsok",
            "Megyekód": "BA",
            "Lat": "45.8333",
            "Long": "17.95",
            "KSH kód": "18865",
            "Jogállás": "6",
            "Terület": "652",
            "Népesség": "261",
            "Lakások": "67"
        },
        {
            "Ir. szám": "7838",
            "name": "Páprád",
            "Megyekód": "BA",
            "Lat": "45.8925",
            "Long": "18.0097",
            "KSH kód": "07010",
            "Jogállás": "6",
            "Terület": "1211",
            "Népesség": "169",
            "Lakások": "77"
        },
        {
            "Ir. szám": "7838",
            "name": "Vejti",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "17.9667",
            "KSH kód": "18519",
            "Jogállás": "6",
            "Terület": "976",
            "Népesség": "166",
            "Lakások": "72"
        },
        {
            "Ir. szám": "7839",
            "name": "Kemse",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "17.9167",
            "KSH kód": "06789",
            "Jogállás": "6",
            "Terület": "896",
            "Népesség": "65",
            "Lakások": "37"
        },
        {
            "Ir. szám": "7839",
            "name": "Zaláta",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "17.8833",
            "KSH kód": "25122",
            "Jogállás": "6",
            "Terület": "2183",
            "Népesség": "289",
            "Lakások": "132"
        },
        {
            "Ir. szám": "7841",
            "name": "Baranyahídvég",
            "Megyekód": "BA",
            "Lat": "45.8464",
            "Long": "18.0233",
            "KSH kód": "20464",
            "Jogállás": "6",
            "Terület": "849",
            "Népesség": "205",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7841",
            "name": "Sámod",
            "Megyekód": "BA",
            "Lat": "45.8544",
            "Long": "18.0375",
            "KSH kód": "18050",
            "Jogállás": "6",
            "Terület": "620",
            "Népesség": "213",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7841",
            "name": "Adorjás",
            "Megyekód": "BA",
            "Lat": "45.8506",
            "Long": "18.0628",
            "KSH kód": "06868",
            "Jogállás": "6",
            "Terület": "810",
            "Népesség": "191",
            "Lakások": "65"
        },
        {
            "Ir. szám": "7841",
            "name": "Kisszentmárton",
            "Megyekód": "BA",
            "Lat": "45.8242",
            "Long": "18.0242",
            "KSH kód": "08651",
            "Jogállás": "6",
            "Terület": "1486",
            "Népesség": "272",
            "Lakások": "111"
        },
        {
            "Ir. szám": "7841",
            "name": "Kórós",
            "Megyekód": "BA",
            "Lat": "45.8669",
            "Long": "18.0844",
            "KSH kód": "08110",
            "Jogállás": "6",
            "Terület": "1512",
            "Népesség": "228",
            "Lakások": "85"
        },
        {
            "Ir. szám": "7843",
            "name": "Cún",
            "Megyekód": "BA",
            "Lat": "45.8125",
            "Long": "18.0669",
            "KSH kód": "11086",
            "Jogállás": "6",
            "Terület": "1843",
            "Népesség": "243",
            "Lakások": "94"
        },
        {
            "Ir. szám": "7843",
            "name": "Kémes",
            "Megyekód": "BA",
            "Lat": "45.8242",
            "Long": "18.1011",
            "KSH kód": "10542",
            "Jogállás": "6",
            "Terület": "689",
            "Népesség": "510",
            "Lakások": "190"
        },
        {
            "Ir. szám": "7843",
            "name": "Drávapiski",
            "Megyekód": "BA",
            "Lat": "45.84",
            "Long": "18.1011",
            "KSH kód": "12380",
            "Jogállás": "6",
            "Terület": "491",
            "Népesség": "126",
            "Lakások": "43"
        },
        {
            "Ir. szám": "7843",
            "name": "Tésenfa",
            "Megyekód": "BA",
            "Lat": "45.8131",
            "Long": "18.1183",
            "KSH kód": "32744",
            "Jogállás": "6",
            "Terület": "840",
            "Népesség": "183",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7843",
            "name": "Szaporca",
            "Megyekód": "BA",
            "Lat": "45.8136",
            "Long": "18.1044",
            "KSH kód": "34032",
            "Jogállás": "6",
            "Terület": "965",
            "Népesség": "237",
            "Lakások": "98"
        },
        {
            "Ir. szám": "7846",
            "name": "Drávacsepely",
            "Megyekód": "BA",
            "Lat": "45.8292",
            "Long": "18.1386",
            "KSH kód": "28121",
            "Jogállás": "6",
            "Terület": "660",
            "Népesség": "214",
            "Lakások": "89"
        },
        {
            "Ir. szám": "7847",
            "name": "Kovácshida",
            "Megyekód": "BA",
            "Lat": "45.8342",
            "Long": "18.1814",
            "KSH kód": "24226",
            "Jogállás": "6",
            "Terület": "809",
            "Népesség": "269",
            "Lakások": "95"
        },
        {
            "Ir. szám": "7847",
            "name": "Drávaszerdahely",
            "Megyekód": "BA",
            "Lat": "45.8358",
            "Long": "18.1633",
            "KSH kód": "30030",
            "Jogállás": "6",
            "Terület": "624",
            "Népesség": "200",
            "Lakások": "78"
        },
        {
            "Ir. szám": "7847",
            "name": "Ipacsfa",
            "Megyekód": "BA",
            "Lat": "45.8347",
            "Long": "18.2033",
            "KSH kód": "29948",
            "Jogállás": "6",
            "Terület": "601",
            "Népesség": "212",
            "Lakások": "87"
        },
        {
            "Ir. szám": "7849",
            "name": "Drávacsehi",
            "Megyekód": "BA",
            "Lat": "45.8128",
            "Long": "18.1672",
            "KSH kód": "28617",
            "Jogállás": "6",
            "Terület": "740",
            "Népesség": "208",
            "Lakások": "79"
        },
        {
            "Ir. szám": "7850",
            "name": "Drávapalkonya",
            "Megyekód": "BA",
            "Lat": "45.8031",
            "Long": "18.1789",
            "KSH kód": "22734",
            "Jogállás": "6",
            "Terület": "1013",
            "Népesség": "273",
            "Lakások": "110"
        },
        {
            "Ir. szám": "7851",
            "name": "Drávapalkonya",
            "Megyekód": "BA",
            "Lat": "45.8031",
            "Long": "18.1789",
            "KSH kód": "22734",
            "Jogállás": "6",
            "Terület": "1013",
            "Népesség": "273",
            "Lakások": "110"
        },
        {
            "Ir. szám": "7851",
            "name": "Drávacsehi",
            "Megyekód": "BA",
            "Lat": "45.8128",
            "Long": "18.1672",
            "KSH kód": "28617",
            "Jogállás": "6",
            "Terület": "740",
            "Népesség": "208",
            "Lakások": "79"
        },
        {
            "Ir. szám": "7851",
            "name": "Drávaszabolcs",
            "Megyekód": "BA",
            "Lat": "45.8058",
            "Long": "18.2114",
            "KSH kód": "28608",
            "Jogállás": "6",
            "Terület": "1122",
            "Népesség": "686",
            "Lakások": "286"
        },
        {
            "Ir. szám": "7853",
            "name": "Gordisa",
            "Megyekód": "BA",
            "Lat": "45.7964",
            "Long": "18.2333",
            "KSH kód": "33084",
            "Jogállás": "6",
            "Terület": "1088",
            "Népesség": "310",
            "Lakások": "103"
        },
        {
            "Ir. szám": "7854",
            "name": "Matty",
            "Megyekód": "BA",
            "Lat": "45.7981",
            "Long": "18.2661",
            "KSH kód": "15051",
            "Jogállás": "6",
            "Terület": "1690",
            "Népesség": "352",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7900",
            "name": "Botykapeterd",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "17.8667",
            "KSH kód": "13365",
            "Jogállás": "6",
            "Terület": "1743",
            "Népesség": "347",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7900",
            "name": "Szigetvár",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "17.8",
            "KSH kód": "26578",
            "Jogállás": "4",
            "Terület": "3951",
            "Népesség": "10900",
            "Lakások": "4305"
        },
        {
            "Ir. szám": "7900",
            "name": "Csertő",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "17.8167",
            "KSH kód": "13851",
            "Jogállás": "6",
            "Terület": "1426",
            "Népesség": "406",
            "Lakások": "175"
        },
        {
            "Ir. szám": "7901",
            "name": "Csertő",
            "Megyekód": "BA",
            "Lat": "46.1",
            "Long": "17.8167",
            "KSH kód": "13851",
            "Jogállás": "6",
            "Terület": "1426",
            "Népesség": "406",
            "Lakások": "175"
        },
        {
            "Ir. szám": "7912",
            "name": "Nyugotszenterzsébet",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.9167",
            "KSH kód": "26329",
            "Jogállás": "6",
            "Terület": "923",
            "Népesség": "242",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7912",
            "name": "Nagyváty",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "17.9333",
            "KSH kód": "14191",
            "Jogállás": "6",
            "Terület": "1267",
            "Népesség": "360",
            "Lakások": "126"
        },
        {
            "Ir. szám": "7912",
            "name": "Nagypeterd",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "17.9",
            "KSH kód": "27164",
            "Jogállás": "6",
            "Terület": "1163",
            "Népesség": "655",
            "Lakások": "225"
        },
        {
            "Ir. szám": "7913",
            "name": "Szentdénes",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.9333",
            "KSH kód": "32009",
            "Jogállás": "6",
            "Terület": "1215",
            "Népesség": "319",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7914",
            "name": "Katádfa",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.8667",
            "KSH kód": "06965",
            "Jogállás": "6",
            "Terület": "439",
            "Népesség": "180",
            "Lakások": "69"
        },
        {
            "Ir. szám": "7914",
            "name": "Rózsafa",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "17.8833",
            "KSH kód": "04516",
            "Jogállás": "6",
            "Terület": "1664",
            "Népesség": "387",
            "Lakások": "141"
        },
        {
            "Ir. szám": "7914",
            "name": "Bánfa",
            "Megyekód": "BA",
            "Lat": "45.9833",
            "Long": "17.8833",
            "KSH kód": "07603",
            "Jogállás": "6",
            "Terület": "1206",
            "Népesség": "211",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7915",
            "name": "Szentegát",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.8333",
            "KSH kód": "33613",
            "Jogállás": "6",
            "Terület": "2778",
            "Népesség": "415",
            "Lakások": "179"
        },
        {
            "Ir. szám": "7915",
            "name": "Dencsháza",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.8333",
            "KSH kód": "07773",
            "Jogállás": "6",
            "Terület": "1398",
            "Népesség": "596",
            "Lakások": "211"
        },
        {
            "Ir. szám": "7918",
            "name": "Tótújfalu",
            "Megyekód": "SO",
            "Lat": "45.8994",
            "Long": "17.6556",
            "KSH kód": "16407",
            "Jogállás": "6",
            "Terület": "1327",
            "Népesség": "209",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7918",
            "name": "Szentborbás",
            "Megyekód": "SO",
            "Lat": "45.8833",
            "Long": "17.6667",
            "KSH kód": "32841",
            "Jogállás": "6",
            "Terület": "747",
            "Népesség": "134",
            "Lakások": "64"
        },
        {
            "Ir. szám": "7918",
            "name": "Lakócsa",
            "Megyekód": "SO",
            "Lat": "45.8964",
            "Long": "17.6839",
            "KSH kód": "11040",
            "Jogállás": "6",
            "Terület": "2532",
            "Népesség": "583",
            "Lakások": "269"
        },
        {
            "Ir. szám": "7921",
            "name": "Somogyhatvan",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "17.7167",
            "KSH kód": "25070",
            "Jogállás": "6",
            "Terület": "1352",
            "Népesség": "363",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7922",
            "name": "Basal",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "17.7833",
            "KSH kód": "15495",
            "Jogállás": "6",
            "Terület": "404",
            "Népesség": "203",
            "Lakások": "71"
        },
        {
            "Ir. szám": "7922",
            "name": "Somogyapáti",
            "Megyekód": "BA",
            "Lat": "46.0833",
            "Long": "17.75",
            "KSH kód": "23807",
            "Jogállás": "6",
            "Terület": "1007",
            "Népesség": "540",
            "Lakások": "220"
        },
        {
            "Ir. szám": "7922",
            "name": "Somogyviszló",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "17.7667",
            "KSH kód": "21281",
            "Jogállás": "6",
            "Terület": "1263",
            "Népesség": "251",
            "Lakások": "111"
        },
        {
            "Ir. szám": "7922",
            "name": "Patapoklosi",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "17.75",
            "KSH kód": "17792",
            "Jogállás": "6",
            "Terület": "1265",
            "Népesség": "338",
            "Lakások": "121"
        },
        {
            "Ir. szám": "7923",
            "name": "Basal",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "17.7833",
            "KSH kód": "15495",
            "Jogállás": "6",
            "Terület": "404",
            "Népesség": "203",
            "Lakások": "71"
        },
        {
            "Ir. szám": "7924",
            "name": "Somogyviszló",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "17.7667",
            "KSH kód": "21281",
            "Jogállás": "6",
            "Terület": "1263",
            "Népesség": "251",
            "Lakások": "111"
        },
        {
            "Ir. szám": "7925",
            "name": "Magyarlukafa",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "17.7667",
            "KSH kód": "23542",
            "Jogállás": "6",
            "Terület": "1297",
            "Népesség": "81",
            "Lakások": "50"
        },
        {
            "Ir. szám": "7925",
            "name": "Somogyhárságy",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "17.7833",
            "KSH kód": "29142",
            "Jogállás": "6",
            "Terület": "3097",
            "Népesség": "448",
            "Lakások": "216"
        },
        {
            "Ir. szám": "7926",
            "name": "Vásárosbéc",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "17.7333",
            "KSH kód": "24952",
            "Jogállás": "6",
            "Terület": "2464",
            "Népesség": "186",
            "Lakások": "96"
        },
        {
            "Ir. szám": "7932",
            "name": "Almáskeresztúr",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "17.9",
            "KSH kód": "20376",
            "Jogállás": "6",
            "Terület": "1303",
            "Népesség": "82",
            "Lakások": "54"
        },
        {
            "Ir. szám": "7932",
            "name": "Szulimán",
            "Megyekód": "BA",
            "Lat": "46.1333",
            "Long": "17.8",
            "KSH kód": "15404",
            "Jogállás": "6",
            "Terület": "1049",
            "Népesség": "219",
            "Lakások": "117"
        },
        {
            "Ir. szám": "7932",
            "name": "Mozsgó",
            "Megyekód": "BA",
            "Lat": "46.1167",
            "Long": "17.85",
            "KSH kód": "29540",
            "Jogállás": "6",
            "Terület": "2176",
            "Népesség": "1045",
            "Lakások": "351"
        },
        {
            "Ir. szám": "7934",
            "name": "Almamellék",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "17.8833",
            "KSH kód": "13329",
            "Jogállás": "6",
            "Terület": "4433",
            "Népesség": "471",
            "Lakások": "164"
        },
        {
            "Ir. szám": "7935",
            "name": "Horváthertelend",
            "Megyekód": "BA",
            "Lat": "46.1667",
            "Long": "17.9167",
            "KSH kód": "23074",
            "Jogállás": "6",
            "Terület": "536",
            "Népesség": "83",
            "Lakások": "30"
        },
        {
            "Ir. szám": "7935",
            "name": "Csebény",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "17.9333",
            "KSH kód": "21591",
            "Jogállás": "6",
            "Terület": "376",
            "Népesség": "105",
            "Lakások": "44"
        },
        {
            "Ir. szám": "7935",
            "name": "Ibafa",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "17.9167",
            "KSH kód": "33066",
            "Jogállás": "6",
            "Terület": "2930",
            "Népesség": "233",
            "Lakások": "94"
        },
        {
            "Ir. szám": "7936",
            "name": "Szentlászló",
            "Megyekód": "BA",
            "Lat": "46.15",
            "Long": "17.8333",
            "KSH kód": "16355",
            "Jogállás": "6",
            "Terület": "1406",
            "Népesség": "864",
            "Lakások": "282"
        },
        {
            "Ir. szám": "7937",
            "name": "Boldogasszonyfa",
            "Megyekód": "BA",
            "Lat": "46.1833",
            "Long": "17.8333",
            "KSH kód": "13116",
            "Jogállás": "6",
            "Terület": "1722",
            "Népesség": "446",
            "Lakások": "214"
        },
        {
            "Ir. szám": "7940",
            "name": "Szentlőrinc",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "17.9833",
            "KSH kód": "15866",
            "Jogállás": "4",
            "Terület": "2782",
            "Népesség": "7000",
            "Lakások": "2526"
        },
        {
            "Ir. szám": "7940",
            "name": "Csonkamindszent",
            "Megyekód": "BA",
            "Lat": "46.05",
            "Long": "17.9667",
            "KSH kód": "22576",
            "Jogállás": "6",
            "Terület": "553",
            "Népesség": "187",
            "Lakások": "61"
        },
        {
            "Ir. szám": "7940",
            "name": "Kacsóta",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "17.95",
            "KSH kód": "04297",
            "Jogállás": "6",
            "Terület": "951",
            "Népesség": "259",
            "Lakások": "95"
        },
        {
            "Ir. szám": "7951",
            "name": "Gerde",
            "Megyekód": "BA",
            "Lat": "45.9897",
            "Long": "18.0242",
            "KSH kód": "12751",
            "Jogállás": "6",
            "Terület": "1276",
            "Népesség": "584",
            "Lakások": "188"
        },
        {
            "Ir. szám": "7951",
            "name": "Pécsbagota",
            "Megyekód": "BA",
            "Lat": "45.9903",
            "Long": "18.0733",
            "KSH kód": "21519",
            "Jogállás": "6",
            "Terület": "607",
            "Népesség": "117",
            "Lakások": "39"
        },
        {
            "Ir. szám": "7951",
            "name": "Szabadszentkirály",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "18.0333",
            "KSH kód": "15079",
            "Jogállás": "6",
            "Terület": "1269",
            "Népesség": "804",
            "Lakások": "297"
        },
        {
            "Ir. szám": "7951",
            "name": "Velény",
            "Megyekód": "BA",
            "Lat": "45.9803",
            "Long": "18.0533",
            "KSH kód": "22071",
            "Jogállás": "6",
            "Terület": "709",
            "Népesség": "159",
            "Lakások": "61"
        },
        {
            "Ir. szám": "7953",
            "name": "Királyegyháza",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.9667",
            "KSH kód": "20552",
            "Jogállás": "6",
            "Terület": "2288",
            "Népesség": "980",
            "Lakások": "323"
        },
        {
            "Ir. szám": "7954",
            "name": "Gilvánfa",
            "Megyekód": "BA",
            "Lat": "45.9167",
            "Long": "17.9667",
            "KSH kód": "18333",
            "Jogállás": "6",
            "Terület": "1543",
            "Népesség": "398",
            "Lakások": "99"
        },
        {
            "Ir. szám": "7954",
            "name": "Magyartelek",
            "Megyekód": "BA",
            "Lat": "45.9333",
            "Long": "17.9833",
            "KSH kód": "04385",
            "Jogállás": "6",
            "Terület": "684",
            "Népesség": "225",
            "Lakások": "73"
        },
        {
            "Ir. szám": "7954",
            "name": "Gyöngyfa",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.95",
            "KSH kód": "28404",
            "Jogállás": "6",
            "Terület": "878",
            "Népesség": "120",
            "Lakások": "51"
        },
        {
            "Ir. szám": "7954",
            "name": "Kisasszonyfa",
            "Megyekód": "BA",
            "Lat": "45.9467",
            "Long": "18.0061",
            "KSH kód": "18908",
            "Jogállás": "6",
            "Terület": "887",
            "Népesség": "187",
            "Lakások": "74"
        },
        {
            "Ir. szám": "7954",
            "name": "Magyarmecske",
            "Megyekód": "BA",
            "Lat": "45.95",
            "Long": "17.9667",
            "KSH kód": "05412",
            "Jogállás": "6",
            "Terület": "1195",
            "Népesség": "322",
            "Lakások": "113"
        },
        {
            "Ir. szám": "7957",
            "name": "Okorág",
            "Megyekód": "BA",
            "Lat": "45.9167",
            "Long": "17.8833",
            "KSH kód": "20686",
            "Jogállás": "6",
            "Terület": "1185",
            "Népesség": "174",
            "Lakások": "86"
        },
        {
            "Ir. szám": "7958",
            "name": "Kákics",
            "Megyekód": "BA",
            "Lat": "45.9025",
            "Long": "17.8528",
            "KSH kód": "06415",
            "Jogállás": "6",
            "Terület": "1489",
            "Népesség": "203",
            "Lakások": "95"
        },
        {
            "Ir. szám": "7960",
            "name": "Marócsa",
            "Megyekód": "BA",
            "Lat": "45.9167",
            "Long": "17.8167",
            "KSH kód": "15219",
            "Jogállás": "6",
            "Terület": "1138",
            "Népesség": "89",
            "Lakások": "57"
        },
        {
            "Ir. szám": "7960",
            "name": "Drávaiványi",
            "Megyekód": "BA",
            "Lat": "45.85",
            "Long": "17.8167",
            "KSH kód": "32391",
            "Jogállás": "6",
            "Terület": "1101",
            "Népesség": "202",
            "Lakások": "100"
        },
        {
            "Ir. szám": "7960",
            "name": "Sellye",
            "Megyekód": "BA",
            "Lat": "45.8747",
            "Long": "17.8439",
            "KSH kód": "28741",
            "Jogállás": "4",
            "Terület": "2518",
            "Népesség": "2849",
            "Lakások": "1160"
        },
        {
            "Ir. szám": "7960",
            "name": "Drávasztára",
            "Megyekód": "BA",
            "Lat": "45.8167",
            "Long": "17.8167",
            "KSH kód": "21698",
            "Jogállás": "6",
            "Terület": "1818",
            "Népesség": "414",
            "Lakások": "240"
        },
        {
            "Ir. szám": "7960",
            "name": "Sósvertike",
            "Megyekód": "BA",
            "Lat": "45.8333",
            "Long": "17.8667",
            "KSH kód": "31714",
            "Jogállás": "6",
            "Terület": "660",
            "Népesség": "179",
            "Lakások": "81"
        },
        {
            "Ir. szám": "7960",
            "name": "Sumony",
            "Megyekód": "BA",
            "Lat": "45.9714",
            "Long": "17.9058",
            "KSH kód": "02547",
            "Jogállás": "6",
            "Terület": "2021",
            "Népesség": "443",
            "Lakások": "174"
        },
        {
            "Ir. szám": "7964",
            "name": "Csányoszró",
            "Megyekód": "BA",
            "Lat": "45.8819",
            "Long": "17.9019",
            "KSH kód": "19901",
            "Jogállás": "6",
            "Terület": "2856",
            "Népesség": "717",
            "Lakások": "267"
        },
        {
            "Ir. szám": "7966",
            "name": "Bogdása",
            "Megyekód": "BA",
            "Lat": "45.8833",
            "Long": "17.8",
            "KSH kód": "10694",
            "Jogállás": "6",
            "Terület": "2099",
            "Népesség": "301",
            "Lakások": "128"
        },
        {
            "Ir. szám": "7967",
            "name": "Markóc",
            "Megyekód": "BA",
            "Lat": "45.8667",
            "Long": "17.7667",
            "KSH kód": "16443",
            "Jogállás": "6",
            "Terület": "579",
            "Népesség": "57",
            "Lakások": "36"
        },
        {
            "Ir. szám": "7967",
            "name": "Drávafok",
            "Megyekód": "BA",
            "Lat": "45.8864",
            "Long": "17.76",
            "KSH kód": "17419",
            "Jogállás": "6",
            "Terület": "2390",
            "Népesség": "519",
            "Lakások": "215"
        },
        {
            "Ir. szám": "7967",
            "name": "Drávakeresztúr",
            "Megyekód": "BA",
            "Lat": "45.8333",
            "Long": "17.7667",
            "KSH kód": "09159",
            "Jogállás": "6",
            "Terület": "1297",
            "Népesség": "139",
            "Lakások": "103"
        },
        {
            "Ir. szám": "7968",
            "name": "Felsőszentmárton",
            "Megyekód": "BA",
            "Lat": "45.8522",
            "Long": "17.7",
            "KSH kód": "08819",
            "Jogállás": "6",
            "Terület": "1946",
            "Népesség": "948",
            "Lakások": "510"
        },
        {
            "Ir. szám": "7971",
            "name": "Hobol",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "17.7667",
            "KSH kód": "33932",
            "Jogállás": "6",
            "Terület": "1827",
            "Népesség": "996",
            "Lakások": "335"
        },
        {
            "Ir. szám": "7972",
            "name": "Gyöngyösmellék",
            "Megyekód": "BA",
            "Lat": "45.9833",
            "Long": "17.7",
            "KSH kód": "22664",
            "Jogállás": "6",
            "Terület": "1002",
            "Népesség": "290",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7972",
            "name": "Pettend",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.7",
            "KSH kód": "29762",
            "Jogállás": "6",
            "Terület": "598",
            "Népesség": "147",
            "Lakások": "50"
        },
        {
            "Ir. szám": "7973",
            "name": "Bürüs",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.7667",
            "KSH kód": "07533",
            "Jogállás": "6",
            "Terület": "1630",
            "Népesség": "104",
            "Lakások": "44"
        },
        {
            "Ir. szám": "7973",
            "name": "Teklafalu",
            "Megyekód": "BA",
            "Lat": "45.95",
            "Long": "17.7333",
            "KSH kód": "32072",
            "Jogállás": "6",
            "Terület": "1676",
            "Népesség": "351",
            "Lakások": "134"
        },
        {
            "Ir. szám": "7973",
            "name": "Endrőc",
            "Megyekód": "BA",
            "Lat": "45.9294",
            "Long": "17.7586",
            "KSH kód": "28273",
            "Jogállás": "6",
            "Terület": "1133",
            "Népesség": "384",
            "Lakások": "130"
        },
        {
            "Ir. szám": "7973",
            "name": "Várad",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.75",
            "KSH kód": "08138",
            "Jogállás": "6",
            "Terület": "793",
            "Népesség": "110",
            "Lakások": "48"
        },
        {
            "Ir. szám": "7975",
            "name": "Kétújfalu",
            "Megyekód": "BA",
            "Lat": "45.9669",
            "Long": "17.705",
            "KSH kód": "08572",
            "Jogállás": "6",
            "Terület": "1630",
            "Népesség": "680",
            "Lakások": "254"
        },
        {
            "Ir. szám": "7976",
            "name": "Zádor",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.6653",
            "KSH kód": "17747",
            "Jogállás": "6",
            "Terület": "1522",
            "Népesség": "355",
            "Lakások": "147"
        },
        {
            "Ir. szám": "7976",
            "name": "Szörény",
            "Megyekód": "BA",
            "Lat": "45.9667",
            "Long": "17.6833",
            "KSH kód": "05528",
            "Jogállás": "6",
            "Terület": "441",
            "Népesség": "61",
            "Lakások": "33"
        },
        {
            "Ir. szám": "7977",
            "name": "Drávagárdony",
            "Megyekód": "SO",
            "Lat": "45.95",
            "Long": "17.6167",
            "KSH kód": "13611",
            "Jogállás": "6",
            "Terület": "627",
            "Népesség": "148",
            "Lakások": "62"
        },
        {
            "Ir. szám": "7977",
            "name": "Potony",
            "Megyekód": "SO",
            "Lat": "45.9303",
            "Long": "17.6433",
            "KSH kód": "28361",
            "Jogállás": "6",
            "Terület": "1590",
            "Népesség": "211",
            "Lakások": "126"
        },
        {
            "Ir. szám": "7977",
            "name": "Kastélyosdombó",
            "Megyekód": "SO",
            "Lat": "45.9544",
            "Long": "17.6133",
            "KSH kód": "08411",
            "Jogállás": "6",
            "Terület": "1308",
            "Népesség": "265",
            "Lakások": "108"
        },
        {
            "Ir. szám": "7979",
            "name": "Drávatamási",
            "Megyekód": "SO",
            "Lat": "45.9444",
            "Long": "17.5694",
            "KSH kód": "15884",
            "Jogállás": "6",
            "Terület": "845",
            "Népesség": "387",
            "Lakások": "109"
        },
        {
            "Ir. szám": "7980",
            "name": "Pettend",
            "Megyekód": "BA",
            "Lat": "46",
            "Long": "17.7",
            "KSH kód": "29762",
            "Jogállás": "6",
            "Terület": "598",
            "Népesség": "147",
            "Lakások": "50"
        },
        {
            "Ir. szám": "7981",
            "name": "Kistamási",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "17.7333",
            "KSH kód": "12353",
            "Jogállás": "6",
            "Terület": "438",
            "Népesség": "133",
            "Lakások": "44"
        },
        {
            "Ir. szám": "7981",
            "name": "Merenye",
            "Megyekód": "BA",
            "Lat": "46.0667",
            "Long": "17.7",
            "KSH kód": "07737",
            "Jogállás": "6",
            "Terület": "1447",
            "Népesség": "281",
            "Lakások": "124"
        },
        {
            "Ir. szám": "7981",
            "name": "Tótszentgyörgy",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "17.725",
            "KSH kód": "26994",
            "Jogállás": "6",
            "Terület": "750",
            "Népesség": "173",
            "Lakások": "80"
        },
        {
            "Ir. szám": "7981",
            "name": "Molvány",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "17.75",
            "KSH kód": "29522",
            "Jogállás": "6",
            "Terület": "1376",
            "Népesség": "187",
            "Lakások": "78"
        },
        {
            "Ir. szám": "7981",
            "name": "Nemeske",
            "Megyekód": "BA",
            "Lat": "46.0167",
            "Long": "17.7167",
            "KSH kód": "18236",
            "Jogállás": "6",
            "Terület": "1057",
            "Népesség": "273",
            "Lakások": "109"
        },
        {
            "Ir. szám": "7985",
            "name": "Kisdobsza",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "17.6667",
            "KSH kód": "33905",
            "Jogállás": "6",
            "Terület": "995",
            "Népesség": "264",
            "Lakások": "103"
        },
        {
            "Ir. szám": "7985",
            "name": "Nagydobsza",
            "Megyekód": "BA",
            "Lat": "46.0333",
            "Long": "17.6667",
            "KSH kód": "33899",
            "Jogállás": "6",
            "Terület": "1323",
            "Népesség": "694",
            "Lakások": "224"
        },
        {
            "Ir. szám": "7987",
            "name": "Istvándi",
            "Megyekód": "SO",
            "Lat": "46.0167",
            "Long": "17.6333",
            "KSH kód": "21333",
            "Jogállás": "6",
            "Terület": "3030",
            "Népesség": "608",
            "Lakások": "190"
        },
        {
            "Ir. szám": "7988",
            "name": "Darány",
            "Megyekód": "SO",
            "Lat": "45.9803",
            "Long": "17.5853",
            "KSH kód": "31352",
            "Jogállás": "6",
            "Terület": "2810",
            "Népesség": "931",
            "Lakások": "354"
        },
        {
            "Ir. szám": "8000",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4166",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8001",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8002",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8003",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8004",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8005",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8006",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8007",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8008",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8011",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8019",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8020",
            "name": "Székesfehérvár",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.4167",
            "KSH kód": "14827",
            "Jogállás": "1",
            "Terület": "17089",
            "Népesség": "102035",
            "Lakások": "42981"
        },
        {
            "Ir. szám": "8041",
            "name": "Csór",
            "Megyekód": "FE",
            "Lat": "47.2",
            "Long": "18.2667",
            "KSH kód": "09779",
            "Jogállás": "6",
            "Terület": "4149",
            "Népesség": "1797",
            "Lakások": "630"
        },
        {
            "Ir. szám": "8042",
            "name": "Moha",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.3333",
            "KSH kód": "25715",
            "Jogállás": "6",
            "Terület": "988",
            "Népesség": "522",
            "Lakások": "183"
        },
        {
            "Ir. szám": "8043",
            "name": "Iszkaszentgyörgy",
            "Megyekód": "FE",
            "Lat": "47.2333",
            "Long": "18.3",
            "KSH kód": "32018",
            "Jogállás": "6",
            "Terület": "2627",
            "Népesség": "1974",
            "Lakások": "666"
        },
        {
            "Ir. szám": "8044",
            "name": "Kincsesbánya",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.2667",
            "KSH kód": "02343",
            "Jogállás": "6",
            "Terület": "1087",
            "Népesség": "1499",
            "Lakások": "577"
        },
        {
            "Ir. szám": "8045",
            "name": "Isztimér",
            "Megyekód": "FE",
            "Lat": "47.2833",
            "Long": "18.2",
            "KSH kód": "02200",
            "Jogállás": "6",
            "Terület": "5388",
            "Népesség": "967",
            "Lakások": "370"
        },
        {
            "Ir. szám": "8046",
            "name": "Bakonykúti",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.2",
            "KSH kód": "23153",
            "Jogállás": "6",
            "Terület": "1245",
            "Népesség": "117",
            "Lakások": "68"
        },
        {
            "Ir. szám": "8051",
            "name": "Sárkeresztes",
            "Megyekód": "FE",
            "Lat": "47.25",
            "Long": "18.35",
            "KSH kód": "31802",
            "Jogállás": "6",
            "Terület": "2327",
            "Népesség": "1493",
            "Lakások": "551"
        },
        {
            "Ir. szám": "8052",
            "name": "Fehérvárcsurgó",
            "Megyekód": "FE",
            "Lat": "47.3",
            "Long": "18.2667",
            "KSH kód": "32203",
            "Jogállás": "6",
            "Terület": "2964",
            "Népesség": "1878",
            "Lakások": "710"
        },
        {
            "Ir. szám": "8053",
            "name": "Bodajk",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.2333",
            "KSH kód": "18254",
            "Jogállás": "4",
            "Terület": "2898",
            "Népesség": "4124",
            "Lakások": "1475"
        },
        {
            "Ir. szám": "8054",
            "name": "Bodajk",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.2333",
            "KSH kód": "18254",
            "Jogállás": "4",
            "Terület": "2898",
            "Népesség": "4124",
            "Lakások": "1475"
        },
        {
            "Ir. szám": "8055",
            "name": "Balinka",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.1833",
            "KSH kód": "31103",
            "Jogállás": "6",
            "Terület": "1862",
            "Népesség": "987",
            "Lakások": "381"
        },
        {
            "Ir. szám": "8056",
            "name": "Bakonycsernye",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.0833",
            "KSH kód": "08730",
            "Jogállás": "6",
            "Terület": "3813",
            "Népesség": "3149",
            "Lakások": "1217"
        },
        {
            "Ir. szám": "8060",
            "name": "Mór",
            "Megyekód": "FE",
            "Lat": "47.3833",
            "Long": "18.2",
            "KSH kód": "18485",
            "Jogállás": "4",
            "Terület": "10861",
            "Népesség": "14354",
            "Lakások": "5647"
        },
        {
            "Ir. szám": "8061",
            "name": "Mór",
            "Megyekód": "FE",
            "Lat": "47.3833",
            "Long": "18.2",
            "KSH kód": "18485",
            "Jogállás": "4",
            "Terület": "10861",
            "Népesség": "14354",
            "Lakások": "5647"
        },
        {
            "Ir. szám": "8062",
            "name": "Mór",
            "Megyekód": "FE",
            "Lat": "47.3833",
            "Long": "18.2",
            "KSH kód": "18485",
            "Jogállás": "4",
            "Terület": "10861",
            "Népesség": "14354",
            "Lakások": "5647"
        },
        {
            "Ir. szám": "8065",
            "name": "Nagyveleg",
            "Megyekód": "FE",
            "Lat": "47.3667",
            "Long": "18.1167",
            "KSH kód": "23588",
            "Jogállás": "6",
            "Terület": "1317",
            "Népesség": "684",
            "Lakások": "284"
        },
        {
            "Ir. szám": "8066",
            "name": "Pusztavám",
            "Megyekód": "FE",
            "Lat": "47.4333",
            "Long": "18.2333",
            "KSH kód": "17774",
            "Jogállás": "6",
            "Terület": "3469",
            "Népesség": "2534",
            "Lakások": "991"
        },
        {
            "Ir. szám": "8071",
            "name": "Magyaralmás",
            "Megyekód": "FE",
            "Lat": "47.3",
            "Long": "18.3333",
            "KSH kód": "27678",
            "Jogállás": "6",
            "Terület": "2242",
            "Népesség": "1515",
            "Lakások": "566"
        },
        {
            "Ir. szám": "8072",
            "name": "Söréd",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.2833",
            "KSH kód": "02893",
            "Jogállás": "6",
            "Terület": "625",
            "Népesség": "488",
            "Lakások": "198"
        },
        {
            "Ir. szám": "8073",
            "name": "Csákberény",
            "Megyekód": "FE",
            "Lat": "47.35",
            "Long": "18.3333",
            "KSH kód": "05360",
            "Jogállás": "6",
            "Terület": "4137",
            "Népesség": "1218",
            "Lakások": "515"
        },
        {
            "Ir. szám": "8074",
            "name": "Csókakő",
            "Megyekód": "FE",
            "Lat": "47.35",
            "Long": "18.2667",
            "KSH kód": "21908",
            "Jogállás": "6",
            "Terület": "1092",
            "Népesség": "1284",
            "Lakások": "501"
        },
        {
            "Ir. szám": "8080",
            "name": "Bodmér",
            "Megyekód": "FE",
            "Lat": "47.45",
            "Long": "18.55",
            "KSH kód": "11624",
            "Jogállás": "6",
            "Terület": "717",
            "Népesség": "213",
            "Lakások": "120"
        },
        {
            "Ir. szám": "8081",
            "name": "Zámoly",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.4167",
            "KSH kód": "30243",
            "Jogállás": "6",
            "Terület": "4850",
            "Népesség": "2260",
            "Lakások": "799"
        },
        {
            "Ir. szám": "8082",
            "name": "Gánt",
            "Megyekód": "FE",
            "Lat": "47.3833",
            "Long": "18.4",
            "KSH kód": "15750",
            "Jogállás": "6",
            "Terület": "5815",
            "Népesség": "852",
            "Lakások": "274"
        },
        {
            "Ir. szám": "8083",
            "name": "Csákvár",
            "Megyekód": "FE",
            "Lat": "47.4",
            "Long": "18.45",
            "KSH kód": "20002",
            "Jogállás": "5",
            "Terület": "11876",
            "Népesség": "5218",
            "Lakások": "1912"
        },
        {
            "Ir. szám": "8084",
            "name": "Csákvár",
            "Megyekód": "FE",
            "Lat": "47.4",
            "Long": "18.45",
            "KSH kód": "20002",
            "Jogállás": "5",
            "Terület": "11876",
            "Népesség": "5218",
            "Lakások": "1912"
        },
        {
            "Ir. szám": "8085",
            "name": "Vértesboglár",
            "Megyekód": "FE",
            "Lat": "47.4333",
            "Long": "18.5333",
            "KSH kód": "13897",
            "Jogállás": "6",
            "Terület": "2321",
            "Népesség": "911",
            "Lakások": "358"
        },
        {
            "Ir. szám": "8086",
            "name": "Felcsút",
            "Megyekód": "FE",
            "Lat": "47.45",
            "Long": "18.5833",
            "KSH kód": "29939",
            "Jogállás": "6",
            "Terület": "2202",
            "Népesség": "1764",
            "Lakások": "672"
        },
        {
            "Ir. szám": "8087",
            "name": "Alcsútdoboz",
            "Megyekód": "FE",
            "Lat": "47.4167",
            "Long": "18.6167",
            "KSH kód": "15176",
            "Jogállás": "6",
            "Terület": "5071",
            "Népesség": "1454",
            "Lakások": "604"
        },
        {
            "Ir. szám": "8088",
            "name": "Tabajd",
            "Megyekód": "FE",
            "Lat": "47.4",
            "Long": "18.6333",
            "KSH kód": "14465",
            "Jogállás": "6",
            "Terület": "2657",
            "Népesség": "974",
            "Lakások": "390"
        },
        {
            "Ir. szám": "8089",
            "name": "Vértesacsa",
            "Megyekód": "FE",
            "Lat": "47.3667",
            "Long": "18.5833",
            "KSH kód": "02750",
            "Jogállás": "6",
            "Terület": "3619",
            "Népesség": "1733",
            "Lakások": "687"
        },
        {
            "Ir. szám": "8092",
            "name": "Pátka",
            "Megyekód": "FE",
            "Lat": "47.2833",
            "Long": "18.5",
            "KSH kód": "28848",
            "Jogállás": "6",
            "Terület": "4038",
            "Népesség": "1644",
            "Lakások": "624"
        },
        {
            "Ir. szám": "8093",
            "name": "Lovasberény",
            "Megyekód": "FE",
            "Lat": "47.3",
            "Long": "18.55",
            "KSH kód": "19114",
            "Jogállás": "6",
            "Terület": "6062",
            "Népesség": "2733",
            "Lakások": "1105"
        },
        {
            "Ir. szám": "8095",
            "name": "Pákozd",
            "Megyekód": "FE",
            "Lat": "47.2167",
            "Long": "18.55",
            "KSH kód": "25751",
            "Jogállás": "6",
            "Terület": "4330",
            "Népesség": "3033",
            "Lakások": "977"
        },
        {
            "Ir. szám": "8096",
            "name": "Sukoró",
            "Megyekód": "FE",
            "Lat": "47.2333",
            "Long": "18.6167",
            "KSH kód": "14951",
            "Jogállás": "6",
            "Terület": "1627",
            "Népesség": "1190",
            "Lakások": "445"
        },
        {
            "Ir. szám": "8097",
            "name": "Nadap",
            "Megyekód": "FE",
            "Lat": "47.2667",
            "Long": "18.6167",
            "KSH kód": "27599",
            "Jogállás": "6",
            "Terület": "693",
            "Népesség": "507",
            "Lakások": "235"
        },
        {
            "Ir. szám": "8100",
            "name": "Várpalota",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "18.1333",
            "KSH kód": "11439",
            "Jogállás": "4",
            "Terület": "7726",
            "Népesség": "20688",
            "Lakások": "8516"
        },
        {
            "Ir. szám": "8101",
            "name": "Várpalota",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "18.1333",
            "KSH kód": "11439",
            "Jogállás": "4",
            "Terület": "7726",
            "Népesség": "20688",
            "Lakások": "8516"
        },
        {
            "Ir. szám": "8102",
            "name": "Várpalota",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "18.1333",
            "KSH kód": "11439",
            "Jogállás": "4",
            "Terület": "7726",
            "Népesség": "20688",
            "Lakások": "8516"
        },
        {
            "Ir. szám": "8103",
            "name": "Várpalota",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "18.1333",
            "KSH kód": "11439",
            "Jogállás": "4",
            "Terület": "7726",
            "Népesség": "20688",
            "Lakások": "8516"
        },
        {
            "Ir. szám": "8104",
            "name": "Várpalota",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "18.1333",
            "KSH kód": "11439",
            "Jogállás": "4",
            "Terület": "7726",
            "Népesség": "20688",
            "Lakások": "8516"
        },
        {
            "Ir. szám": "8105",
            "name": "Pétfürdő",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "18.1167",
            "KSH kód": "34254",
            "Jogállás": "5",
            "Terület": "1732",
            "Népesség": "4813",
            "Lakások": "1896"
        },
        {
            "Ir. szám": "8109",
            "name": "Tés",
            "Megyekód": "VE",
            "Lat": "47.2667",
            "Long": "18.0333",
            "KSH kód": "20570",
            "Jogállás": "6",
            "Terület": "4903",
            "Népesség": "857",
            "Lakások": "419"
        },
        {
            "Ir. szám": "8111",
            "name": "Seregélyes",
            "Megyekód": "FE",
            "Lat": "47.1",
            "Long": "18.5833",
            "KSH kód": "20206",
            "Jogállás": "5",
            "Terület": "7819",
            "Népesség": "4668",
            "Lakások": "1598"
        },
        {
            "Ir. szám": "8112",
            "name": "Zichyújfalu",
            "Megyekód": "FE",
            "Lat": "47.1333",
            "Long": "18.6833",
            "KSH kód": "34263",
            "Jogállás": "6",
            "Terület": "1082",
            "Népesség": "969",
            "Lakások": "273"
        },
        {
            "Ir. szám": "8121",
            "name": "Tác",
            "Megyekód": "FE",
            "Lat": "47.0833",
            "Long": "18.4",
            "KSH kód": "29267",
            "Jogállás": "6",
            "Terület": "4569",
            "Népesség": "1694",
            "Lakások": "565"
        },
        {
            "Ir. szám": "8122",
            "name": "Csősz",
            "Megyekód": "FE",
            "Lat": "47.0333",
            "Long": "18.4167",
            "KSH kód": "06734",
            "Jogállás": "6",
            "Terület": "1711",
            "Népesség": "1074",
            "Lakások": "382"
        },
        {
            "Ir. szám": "8123",
            "name": "Soponya",
            "Megyekód": "FE",
            "Lat": "47.0167",
            "Long": "18.45",
            "KSH kód": "33321",
            "Jogállás": "5",
            "Terület": "4996",
            "Népesség": "2051",
            "Lakások": "690"
        },
        {
            "Ir. szám": "8124",
            "name": "Káloz",
            "Megyekód": "FE",
            "Lat": "46.95",
            "Long": "18.4833",
            "KSH kód": "16683",
            "Jogállás": "6",
            "Terület": "4779",
            "Népesség": "2460",
            "Lakások": "919"
        },
        {
            "Ir. szám": "8125",
            "name": "Sárkeresztúr",
            "Megyekód": "FE",
            "Lat": "47",
            "Long": "18.55",
            "KSH kód": "25344",
            "Jogállás": "6",
            "Terület": "4675",
            "Népesség": "2630",
            "Lakások": "772"
        },
        {
            "Ir. szám": "8126",
            "name": "Sárszentágota",
            "Megyekód": "FE",
            "Lat": "46.9667",
            "Long": "18.5667",
            "KSH kód": "31538",
            "Jogállás": "6",
            "Terület": "4550",
            "Népesség": "1379",
            "Lakások": "510"
        },
        {
            "Ir. szám": "8127",
            "name": "Aba",
            "Megyekód": "FE",
            "Lat": "47.0333",
            "Long": "18.5167",
            "KSH kód": "17376",
            "Jogállás": "5",
            "Terület": "8805",
            "Népesség": "4657",
            "Lakások": "1550"
        },
        {
            "Ir. szám": "8130",
            "name": "Enying",
            "Megyekód": "FE",
            "Lat": "46.9333",
            "Long": "18.25",
            "KSH kód": "02802",
            "Jogállás": "4",
            "Terület": "8278",
            "Népesség": "6982",
            "Lakások": "2601"
        },
        {
            "Ir. szám": "8131",
            "name": "Enying",
            "Megyekód": "FE",
            "Lat": "46.9333",
            "Long": "18.25",
            "KSH kód": "02802",
            "Jogállás": "4",
            "Terület": "8278",
            "Népesség": "6982",
            "Lakások": "2601"
        },
        {
            "Ir. szám": "8132",
            "name": "Lepsény",
            "Megyekód": "FE",
            "Lat": "47",
            "Long": "18.25",
            "KSH kód": "07269",
            "Jogállás": "5",
            "Terület": "3908",
            "Népesség": "3164",
            "Lakások": "1194"
        },
        {
            "Ir. szám": "8133",
            "name": "Mezőszentgyörgy",
            "Megyekód": "FE",
            "Lat": "47",
            "Long": "18.2833",
            "KSH kód": "06576",
            "Jogállás": "6",
            "Terület": "2712",
            "Népesség": "1337",
            "Lakások": "468"
        },
        {
            "Ir. szám": "8134",
            "name": "Mátyásdomb",
            "Megyekód": "FE",
            "Lat": "46.9167",
            "Long": "18.35",
            "KSH kód": "16948",
            "Jogállás": "6",
            "Terület": "3539",
            "Népesség": "750",
            "Lakások": "283"
        },
        {
            "Ir. szám": "8135",
            "name": "Dég",
            "Megyekód": "FE",
            "Lat": "46.8667",
            "Long": "18.45",
            "KSH kód": "32753",
            "Jogállás": "6",
            "Terület": "4686",
            "Népesség": "2136",
            "Lakások": "863"
        },
        {
            "Ir. szám": "8136",
            "name": "Lajoskomárom",
            "Megyekód": "FE",
            "Lat": "46.85",
            "Long": "18.35",
            "KSH kód": "07506",
            "Jogállás": "5",
            "Terület": "7567",
            "Népesség": "2232",
            "Lakások": "892"
        },
        {
            "Ir. szám": "8137",
            "name": "Mezőkomárom",
            "Megyekód": "FE",
            "Lat": "46.8333",
            "Long": "18.2833",
            "KSH kód": "05689",
            "Jogállás": "6",
            "Terület": "2905",
            "Népesség": "964",
            "Lakások": "411"
        },
        {
            "Ir. szám": "8138",
            "name": "Szabadhídvég",
            "Megyekód": "FE",
            "Lat": "46.8167",
            "Long": "18.2833",
            "KSH kód": "18740",
            "Jogállás": "6",
            "Terület": "4410",
            "Népesség": "875",
            "Lakások": "408"
        },
        {
            "Ir. szám": "8142",
            "name": "Úrhida",
            "Megyekód": "FE",
            "Lat": "47.1333",
            "Long": "18.3333",
            "KSH kód": "17622",
            "Jogállás": "6",
            "Terület": "725",
            "Népesség": "2194",
            "Lakások": "742"
        },
        {
            "Ir. szám": "8143",
            "name": "Sárszentmihály",
            "Megyekód": "FE",
            "Lat": "47.15",
            "Long": "18.3333",
            "KSH kód": "11776",
            "Jogállás": "6",
            "Terület": "3740",
            "Népesség": "3015",
            "Lakások": "1061"
        },
        {
            "Ir. szám": "8144",
            "name": "Sárkeszi",
            "Megyekód": "FE",
            "Lat": "47.1667",
            "Long": "18.2833",
            "KSH kód": "02699",
            "Jogállás": "6",
            "Terület": "1479",
            "Népesség": "614",
            "Lakások": "207"
        },
        {
            "Ir. szám": "8145",
            "name": "Nádasdladány",
            "Megyekód": "FE",
            "Lat": "47.1333",
            "Long": "18.25",
            "KSH kód": "13903",
            "Jogállás": "6",
            "Terület": "2635",
            "Népesség": "1834",
            "Lakások": "633"
        },
        {
            "Ir. szám": "8146",
            "name": "Jenő",
            "Megyekód": "FE",
            "Lat": "47.1",
            "Long": "18.25",
            "KSH kód": "15972",
            "Jogállás": "6",
            "Terület": "556",
            "Népesség": "1344",
            "Lakások": "504"
        },
        {
            "Ir. szám": "8151",
            "name": "Szabadbattyán",
            "Megyekód": "FE",
            "Lat": "47.1167",
            "Long": "18.3833",
            "KSH kód": "28705",
            "Jogállás": "5",
            "Terület": "3366",
            "Népesség": "4547",
            "Lakások": "1553"
        },
        {
            "Ir. szám": "8152",
            "name": "Kőszárhegy",
            "Megyekód": "FE",
            "Lat": "47.1",
            "Long": "18.3333",
            "KSH kód": "30650",
            "Jogállás": "6",
            "Terület": "590",
            "Népesség": "1620",
            "Lakások": "571"
        },
        {
            "Ir. szám": "8153",
            "name": "Kőszárhegy",
            "Megyekód": "FE",
            "Lat": "47.1",
            "Long": "18.3333",
            "KSH kód": "30650",
            "Jogállás": "6",
            "Terület": "590",
            "Népesség": "1620",
            "Lakások": "571"
        },
        {
            "Ir. szám": "8154",
            "name": "Polgárdi",
            "Megyekód": "FE",
            "Lat": "47.05",
            "Long": "18.3",
            "KSH kód": "17525",
            "Jogállás": "4",
            "Terület": "7216",
            "Népesség": "6819",
            "Lakások": "2065"
        },
        {
            "Ir. szám": "8156",
            "name": "Kisláng",
            "Megyekód": "FE",
            "Lat": "46.9667",
            "Long": "18.3833",
            "KSH kód": "28990",
            "Jogállás": "6",
            "Terület": "5306",
            "Népesség": "2506",
            "Lakások": "1020"
        },
        {
            "Ir. szám": "8157",
            "name": "Füle",
            "Megyekód": "FE",
            "Lat": "47.05",
            "Long": "18.25",
            "KSH kód": "06114",
            "Jogállás": "6",
            "Terület": "3032",
            "Népesség": "877",
            "Lakások": "399"
        },
        {
            "Ir. szám": "8161",
            "name": "Ősi",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "18.2",
            "KSH kód": "24068",
            "Jogállás": "6",
            "Terület": "3586",
            "Népesség": "2081",
            "Lakások": "749"
        },
        {
            "Ir. szám": "8162",
            "name": "Küngös",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "18.1833",
            "KSH kód": "04066",
            "Jogállás": "6",
            "Terület": "940",
            "Népesség": "530",
            "Lakások": "182"
        },
        {
            "Ir. szám": "8163",
            "name": "Csajág",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "18.1833",
            "KSH kód": "16072",
            "Jogállás": "6",
            "Terület": "2237",
            "Népesség": "826",
            "Lakások": "317"
        },
        {
            "Ir. szám": "8164",
            "name": "Balatonfőkajár",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "18.2167",
            "KSH kód": "29461",
            "Jogállás": "6",
            "Terület": "2192",
            "Népesség": "1357",
            "Lakások": "537"
        },
        {
            "Ir. szám": "8171",
            "name": "Balatonvilágos",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "18.1667",
            "KSH kód": "03559",
            "Jogállás": "6",
            "Terület": "2921",
            "Népesség": "1192",
            "Lakások": "588"
        },
        {
            "Ir. szám": "8172",
            "name": "Balatonvilágos",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "18.1667",
            "KSH kód": "03559",
            "Jogállás": "6",
            "Terület": "2921",
            "Népesség": "1192",
            "Lakások": "588"
        },
        {
            "Ir. szám": "8173",
            "name": "Balatonvilágos",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "18.1667",
            "KSH kód": "03559",
            "Jogállás": "6",
            "Terület": "2921",
            "Népesség": "1192",
            "Lakások": "588"
        },
        {
            "Ir. szám": "8174",
            "name": "Balatonkenese",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "18.1167",
            "KSH kód": "05148",
            "Jogállás": "5",
            "Terület": "6557",
            "Népesség": "3263",
            "Lakások": "1667"
        },
        {
            "Ir. szám": "8175",
            "name": "Balatonfűzfő",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "18.05",
            "KSH kód": "02219",
            "Jogállás": "4",
            "Terület": "925",
            "Népesség": "4335",
            "Lakások": "1899"
        },
        {
            "Ir. szám": "8181",
            "name": "Berhida",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "18.1333",
            "KSH kód": "33127",
            "Jogállás": "4",
            "Terület": "4273",
            "Népesség": "6034",
            "Lakások": "2036"
        },
        {
            "Ir. szám": "8182",
            "name": "Berhida",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "18.1333",
            "KSH kód": "33127",
            "Jogállás": "4",
            "Terület": "4273",
            "Népesség": "6034",
            "Lakások": "2036"
        },
        {
            "Ir. szám": "8183",
            "name": "Papkeszi",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "18.0833",
            "KSH kód": "07348",
            "Jogállás": "6",
            "Terület": "2239",
            "Népesség": "1605",
            "Lakások": "543"
        },
        {
            "Ir. szám": "8184",
            "name": "Papkeszi",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "18.0833",
            "KSH kód": "07348",
            "Jogállás": "6",
            "Terület": "2239",
            "Népesség": "1605",
            "Lakások": "543"
        },
        {
            "Ir. szám": "8191",
            "name": "Öskü",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "18.0833",
            "KSH kód": "25450",
            "Jogállás": "6",
            "Terület": "4830",
            "Népesség": "2325",
            "Lakások": "812"
        },
        {
            "Ir. szám": "8192",
            "name": "Hajmáskér",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "18.0167",
            "KSH kód": "15361",
            "Jogállás": "6",
            "Terület": "3814",
            "Népesség": "3111",
            "Lakások": "1292"
        },
        {
            "Ir. szám": "8193",
            "name": "Sóly",
            "Megyekód": "VE",
            "Lat": "47.1333",
            "Long": "18.0333",
            "KSH kód": "03601",
            "Jogállás": "6",
            "Terület": "1028",
            "Népesség": "471",
            "Lakások": "153"
        },
        {
            "Ir. szám": "8194",
            "name": "Vilonya",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "18.0667",
            "KSH kód": "15705",
            "Jogállás": "6",
            "Terület": "1350",
            "Népesség": "615",
            "Lakások": "232"
        },
        {
            "Ir. szám": "8195",
            "name": "Királyszentistván",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "18.05",
            "KSH kód": "05421",
            "Jogállás": "6",
            "Terület": "891",
            "Népesség": "467",
            "Lakások": "162"
        },
        {
            "Ir. szám": "8196",
            "name": "Litér",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "18.0167",
            "KSH kód": "04552",
            "Jogállás": "6",
            "Terület": "1283",
            "Népesség": "2055",
            "Lakások": "699"
        },
        {
            "Ir. szám": "8200",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8201",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8202",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8204",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8205",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8206",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8207",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8220",
            "name": "Balatonalmádi",
            "Megyekód": "VE",
            "Lat": "47.033",
            "Long": "18.0164",
            "KSH kód": "05838",
            "Jogállás": "4",
            "Terület": "4988",
            "Népesség": "8886",
            "Lakások": "4167"
        },
        {
            "Ir. szám": "8221",
            "name": "Balatonalmádi",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "18.0167",
            "KSH kód": "05838",
            "Jogállás": "4",
            "Terület": "4988",
            "Népesség": "8886",
            "Lakások": "4167"
        },
        {
            "Ir. szám": "8222",
            "name": "Balatonalmádi",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "18.0167",
            "KSH kód": "05838",
            "Jogállás": "4",
            "Terület": "4988",
            "Népesség": "8886",
            "Lakások": "4167"
        },
        {
            "Ir. szám": "8225",
            "name": "Szentkirályszabadja",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.9667",
            "KSH kód": "16267",
            "Jogállás": "6",
            "Terület": "2238",
            "Népesség": "2061",
            "Lakások": "677"
        },
        {
            "Ir. szám": "8226",
            "name": "Alsóörs",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.9833",
            "KSH kód": "30526",
            "Jogállás": "6",
            "Terület": "3334",
            "Népesség": "1412",
            "Lakások": "667"
        },
        {
            "Ir. szám": "8227",
            "name": "Felsőörs",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.95",
            "KSH kód": "24369",
            "Jogállás": "6",
            "Terület": "1724",
            "Népesség": "1528",
            "Lakások": "511"
        },
        {
            "Ir. szám": "8228",
            "name": "Lovas",
            "Megyekód": "VE",
            "Lat": "47",
            "Long": "17.9667",
            "KSH kód": "05564",
            "Jogállás": "6",
            "Terület": "594",
            "Népesség": "433",
            "Lakások": "169"
        },
        {
            "Ir. szám": "8229",
            "name": "Csopak",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.9333",
            "KSH kód": "02185",
            "Jogállás": "6",
            "Terület": "2398",
            "Népesség": "1576",
            "Lakások": "788"
        },
        {
            "Ir. szám": "8229",
            "name": "Paloznak",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.95",
            "KSH kód": "17011",
            "Jogállás": "6",
            "Terület": "880",
            "Népesség": "417",
            "Lakások": "173"
        },
        {
            "Ir. szám": "8230",
            "name": "Balatonfüred",
            "Megyekód": "VE",
            "Lat": "46.95",
            "Long": "17.8833",
            "KSH kód": "21175",
            "Jogállás": "4",
            "Terület": "4645",
            "Népesség": "13422",
            "Lakások": "5654"
        },
        {
            "Ir. szám": "8230",
            "name": "Balatonszőlős",
            "Megyekód": "VE",
            "Lat": "46.9616",
            "Long": "17.875",
            "KSH kód": "28501",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "602",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8231",
            "name": "Balatonszőlős",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.8333",
            "KSH kód": "28501",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "602",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8232",
            "name": "Balatonszőlős",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.8333",
            "KSH kód": "28501",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "602",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8233",
            "name": "Balatonszőlős",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.8333",
            "KSH kód": "28501",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "602",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8236",
            "name": "Balatonszőlős",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.8333",
            "KSH kód": "28501",
            "Jogállás": "6",
            "Terület": "1281",
            "Népesség": "602",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8237",
            "name": "Tihany",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.9",
            "KSH kód": "30465",
            "Jogállás": "6",
            "Terület": "2733",
            "Népesség": "1369",
            "Lakások": "653"
        },
        {
            "Ir. szám": "8241",
            "name": "Aszófő",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.8333",
            "KSH kód": "07339",
            "Jogállás": "6",
            "Terület": "832",
            "Népesség": "413",
            "Lakások": "160"
        },
        {
            "Ir. szám": "8242",
            "name": "Örvényes",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.8167",
            "KSH kód": "10092",
            "Jogállás": "6",
            "Terület": "446",
            "Népesség": "154",
            "Lakások": "81"
        },
        {
            "Ir. szám": "8242",
            "name": "Balatonudvari",
            "Megyekód": "VE",
            "Lat": "46.9",
            "Long": "17.8167",
            "KSH kód": "15565",
            "Jogállás": "6",
            "Terület": "1880",
            "Népesség": "284",
            "Lakások": "148"
        },
        {
            "Ir. szám": "8243",
            "name": "Balatonakali",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.75",
            "KSH kód": "25308",
            "Jogállás": "6",
            "Terület": "3620",
            "Népesség": "632",
            "Lakások": "277"
        },
        {
            "Ir. szám": "8244",
            "name": "Dörgicse",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.7167",
            "KSH kód": "06363",
            "Jogállás": "6",
            "Terület": "1912",
            "Népesség": "262",
            "Lakások": "130"
        },
        {
            "Ir. szám": "8245",
            "name": "Pécsely",
            "Megyekód": "VE",
            "Lat": "46.95",
            "Long": "17.7833",
            "KSH kód": "22451",
            "Jogállás": "6",
            "Terület": "2001",
            "Népesség": "539",
            "Lakások": "215"
        },
        {
            "Ir. szám": "8245",
            "name": "Vászoly",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.7667",
            "KSH kód": "16656",
            "Jogállás": "6",
            "Terület": "855",
            "Népesség": "201",
            "Lakások": "96"
        },
        {
            "Ir. szám": "8246",
            "name": "Tótvázsony",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.7833",
            "KSH kód": "02714",
            "Jogállás": "6",
            "Terület": "4250",
            "Népesség": "1192",
            "Lakások": "446"
        },
        {
            "Ir. szám": "8247",
            "name": "Hidegkút",
            "Megyekód": "VE",
            "Lat": "47",
            "Long": "17.8333",
            "KSH kód": "20756",
            "Jogállás": "6",
            "Terület": "1358",
            "Népesség": "458",
            "Lakások": "171"
        },
        {
            "Ir. szám": "8248",
            "name": "Veszprémfajsz",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "17.9",
            "KSH kód": "21430",
            "Jogállás": "6",
            "Terület": "1174",
            "Népesség": "274",
            "Lakások": "93"
        },
        {
            "Ir. szám": "8248",
            "name": "Nemesvámos",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.8833",
            "KSH kód": "02194",
            "Jogállás": "6",
            "Terület": "4077",
            "Népesség": "2517",
            "Lakások": "881"
        },
        {
            "Ir. szám": "8251",
            "name": "Zánka",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.6833",
            "KSH kód": "20826",
            "Jogállás": "6",
            "Terület": "1955",
            "Népesség": "937",
            "Lakások": "428"
        },
        {
            "Ir. szám": "8252",
            "name": "Balatonszepezd",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.6667",
            "KSH kód": "17154",
            "Jogállás": "6",
            "Terület": "2506",
            "Népesség": "399",
            "Lakások": "213"
        },
        {
            "Ir. szám": "8253",
            "name": "Révfülöp",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.6333",
            "KSH kód": "05625",
            "Jogállás": "5",
            "Terület": "1036",
            "Népesség": "1127",
            "Lakások": "551"
        },
        {
            "Ir. szám": "8254",
            "name": "Kővágóörs",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.6",
            "KSH kód": "23454",
            "Jogállás": "6",
            "Terület": "2209",
            "Népesség": "828",
            "Lakások": "371"
        },
        {
            "Ir. szám": "8254",
            "name": "Kékkút",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.5667",
            "KSH kód": "26037",
            "Jogállás": "6",
            "Terület": "368",
            "Népesség": "86",
            "Lakások": "36"
        },
        {
            "Ir. szám": "8255",
            "name": "Balatonrendes",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.5833",
            "KSH kód": "33844",
            "Jogállás": "6",
            "Terület": "676",
            "Népesség": "119",
            "Lakások": "66"
        },
        {
            "Ir. szám": "8256",
            "name": "Salföld",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.55",
            "KSH kód": "30793",
            "Jogállás": "6",
            "Terület": "518",
            "Népesség": "50",
            "Lakások": "39"
        },
        {
            "Ir. szám": "8256",
            "name": "Ábrahámhegy",
            "Megyekód": "VE",
            "Lat": "46.8167",
            "Long": "17.5667",
            "KSH kód": "04561",
            "Jogállás": "6",
            "Terület": "1488",
            "Népesség": "440",
            "Lakások": "228"
        },
        {
            "Ir. szám": "8257",
            "name": "Salföld",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.55",
            "KSH kód": "30793",
            "Jogállás": "6",
            "Terület": "518",
            "Népesség": "50",
            "Lakások": "39"
        },
        {
            "Ir. szám": "8258",
            "name": "Badacsonytomaj",
            "Megyekód": "VE",
            "Lat": "46.8",
            "Long": "17.5167",
            "KSH kód": "22327",
            "Jogállás": "4",
            "Terület": "3271",
            "Népesség": "2178",
            "Lakások": "1068"
        },
        {
            "Ir. szám": "8261",
            "name": "Badacsonytomaj",
            "Megyekód": "VE",
            "Lat": "46.8",
            "Long": "17.5167",
            "KSH kód": "22327",
            "Jogállás": "4",
            "Terület": "3271",
            "Népesség": "2178",
            "Lakások": "1068"
        },
        {
            "Ir. szám": "8263",
            "name": "Badacsonytördemic",
            "Megyekód": "VE",
            "Lat": "46.8167",
            "Long": "17.4833",
            "KSH kód": "03267",
            "Jogállás": "6",
            "Terület": "1025",
            "Népesség": "834",
            "Lakások": "437"
        },
        {
            "Ir. szám": "8264",
            "name": "Szigliget",
            "Megyekód": "VE",
            "Lat": "46.8",
            "Long": "17.4333",
            "KSH kód": "24891",
            "Jogállás": "6",
            "Terület": "3426",
            "Népesség": "857",
            "Lakások": "441"
        },
        {
            "Ir. szám": "8265",
            "name": "Hegymagas",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.4333",
            "KSH kód": "25803",
            "Jogállás": "6",
            "Terület": "789",
            "Népesség": "243",
            "Lakások": "153"
        },
        {
            "Ir. szám": "8271",
            "name": "Mencshely",
            "Megyekód": "VE",
            "Lat": "46.95",
            "Long": "17.7",
            "KSH kód": "27137",
            "Jogállás": "6",
            "Terület": "1261",
            "Népesség": "249",
            "Lakások": "122"
        },
        {
            "Ir. szám": "8272",
            "name": "Balatoncsicsó",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.6667",
            "KSH kód": "03072",
            "Jogállás": "6",
            "Terület": "1320",
            "Népesség": "199",
            "Lakások": "98"
        },
        {
            "Ir. szám": "8272",
            "name": "Szentjakabfa",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.6667",
            "KSH kód": "11545",
            "Jogállás": "6",
            "Terület": "576",
            "Népesség": "107",
            "Lakások": "47"
        },
        {
            "Ir. szám": "8272",
            "name": "Szentantalfa",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.6833",
            "KSH kód": "03568",
            "Jogállás": "6",
            "Terület": "661",
            "Népesség": "412",
            "Lakások": "123"
        },
        {
            "Ir. szám": "8272",
            "name": "Óbudavár",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.7",
            "KSH kód": "23913",
            "Jogállás": "6",
            "Terület": "323",
            "Népesség": "56",
            "Lakások": "30"
        },
        {
            "Ir. szám": "8272",
            "name": "Tagyon",
            "Megyekód": "VE",
            "Lat": "46.9",
            "Long": "17.6833",
            "KSH kód": "17039",
            "Jogállás": "6",
            "Terület": "276",
            "Népesség": "89",
            "Lakások": "38"
        },
        {
            "Ir. szám": "8273",
            "name": "Monoszló",
            "Megyekód": "VE",
            "Lat": "46.9",
            "Long": "17.65",
            "KSH kód": "22512",
            "Jogállás": "6",
            "Terület": "746",
            "Népesség": "132",
            "Lakások": "67"
        },
        {
            "Ir. szám": "8274",
            "name": "Köveskál",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.6",
            "KSH kód": "25858",
            "Jogállás": "6",
            "Terület": "1449",
            "Népesség": "384",
            "Lakások": "177"
        },
        {
            "Ir. szám": "8275",
            "name": "Balatonhenye",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.6167",
            "KSH kód": "03638",
            "Jogállás": "6",
            "Terület": "1173",
            "Népesség": "124",
            "Lakások": "66"
        },
        {
            "Ir. szám": "8281",
            "name": "Szentbékkálla",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.5667",
            "KSH kód": "07092",
            "Jogállás": "6",
            "Terület": "1070",
            "Népesség": "193",
            "Lakások": "105"
        },
        {
            "Ir. szám": "8282",
            "name": "Mindszentkálla",
            "Megyekód": "VE",
            "Lat": "46.8667",
            "Long": "17.55",
            "KSH kód": "04534",
            "Jogállás": "6",
            "Terület": "1084",
            "Népesség": "312",
            "Lakások": "143"
        },
        {
            "Ir. szám": "8283",
            "name": "Káptalantóti",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.5167",
            "KSH kód": "05634",
            "Jogállás": "6",
            "Terület": "1173",
            "Népesség": "436",
            "Lakások": "190"
        },
        {
            "Ir. szám": "8284",
            "name": "Kisapáti",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.4667",
            "KSH kód": "07931",
            "Jogállás": "6",
            "Terület": "689",
            "Népesség": "372",
            "Lakások": "139"
        },
        {
            "Ir. szám": "8284",
            "name": "Nemesgulács",
            "Megyekód": "VE",
            "Lat": "46.8333",
            "Long": "17.4833",
            "KSH kód": "02787",
            "Jogállás": "6",
            "Terület": "835",
            "Népesség": "998",
            "Lakások": "417"
        },
        {
            "Ir. szám": "8286",
            "name": "Gyulakeszi",
            "Megyekód": "VE",
            "Lat": "46.8667",
            "Long": "17.4833",
            "KSH kód": "09520",
            "Jogállás": "6",
            "Terület": "967",
            "Népesség": "732",
            "Lakások": "256"
        },
        {
            "Ir. szám": "8291",
            "name": "Vöröstó",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.7167",
            "KSH kód": "11703",
            "Jogállás": "6",
            "Terület": "619",
            "Népesség": "100",
            "Lakások": "53"
        },
        {
            "Ir. szám": "8291",
            "name": "Barnag",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.75",
            "KSH kód": "15778",
            "Jogállás": "6",
            "Terület": "1201",
            "Népesség": "123",
            "Lakások": "83"
        },
        {
            "Ir. szám": "8291",
            "name": "Nagyvázsony",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.7",
            "KSH kód": "19196",
            "Jogállás": "6",
            "Terület": "7629",
            "Népesség": "1742",
            "Lakások": "679"
        },
        {
            "Ir. szám": "8291",
            "name": "Pula",
            "Megyekód": "VE",
            "Lat": "47",
            "Long": "17.65",
            "KSH kód": "11420",
            "Jogállás": "6",
            "Terület": "1464",
            "Népesség": "218",
            "Lakások": "96"
        },
        {
            "Ir. szám": "8292",
            "name": "Öcs",
            "Megyekód": "VE",
            "Lat": "47",
            "Long": "17.6167",
            "KSH kód": "28112",
            "Jogállás": "6",
            "Terület": "1370",
            "Népesség": "208",
            "Lakások": "110"
        },
        {
            "Ir. szám": "8294",
            "name": "Kapolcs",
            "Megyekód": "VE",
            "Lat": "46.95",
            "Long": "17.6167",
            "KSH kód": "14553",
            "Jogállás": "6",
            "Terület": "1397",
            "Népesség": "422",
            "Lakások": "219"
        },
        {
            "Ir. szám": "8294",
            "name": "Vigántpetend",
            "Megyekód": "VE",
            "Lat": "46.9667",
            "Long": "17.6333",
            "KSH kód": "09733",
            "Jogállás": "6",
            "Terület": "1119",
            "Népesség": "210",
            "Lakások": "108"
        },
        {
            "Ir. szám": "8295",
            "name": "Taliándörögd",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.5667",
            "KSH kód": "17321",
            "Jogállás": "6",
            "Terület": "2808",
            "Népesség": "718",
            "Lakások": "296"
        },
        {
            "Ir. szám": "8296",
            "name": "Hegyesd",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.5167",
            "KSH kód": "02422",
            "Jogállás": "6",
            "Terület": "1105",
            "Népesség": "164",
            "Lakások": "87"
        },
        {
            "Ir. szám": "8296",
            "name": "Monostorapáti",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.5667",
            "KSH kód": "24040",
            "Jogállás": "6",
            "Terület": "2555",
            "Népesség": "1083",
            "Lakások": "473"
        },
        {
            "Ir. szám": "8297",
            "name": "Hegyesd",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.5167",
            "KSH kód": "02422",
            "Jogállás": "6",
            "Terület": "1105",
            "Népesség": "164",
            "Lakások": "87"
        },
        {
            "Ir. szám": "8300",
            "name": "Raposka",
            "Megyekód": "VE",
            "Lat": "46.8806",
            "Long": "17.4362",
            "KSH kód": "03948",
            "Jogállás": "6",
            "Terület": "490",
            "Népesség": "233",
            "Lakások": "107"
        },
        {
            "Ir. szám": "8300",
            "name": "Tapolca",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.4333",
            "KSH kód": "29434",
            "Jogállás": "4",
            "Terület": "6348",
            "Népesség": "16211",
            "Lakások": "6854"
        },
        {
            "Ir. szám": "8301",
            "name": "Raposka",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.4333",
            "KSH kód": "03948",
            "Jogállás": "6",
            "Terület": "490",
            "Népesség": "233",
            "Lakások": "107"
        },
        {
            "Ir. szám": "8302",
            "name": "Raposka",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.4333",
            "KSH kód": "03948",
            "Jogállás": "6",
            "Terület": "490",
            "Népesség": "233",
            "Lakások": "107"
        },
        {
            "Ir. szám": "8303",
            "name": "Raposka",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.4333",
            "KSH kód": "03948",
            "Jogállás": "6",
            "Terület": "490",
            "Népesség": "233",
            "Lakások": "107"
        },
        {
            "Ir. szám": "8308",
            "name": "Zalahaláp",
            "Megyekód": "VE",
            "Lat": "46.9167",
            "Long": "17.4667",
            "KSH kód": "12654",
            "Jogállás": "6",
            "Terület": "2413",
            "Népesség": "1285",
            "Lakások": "413"
        },
        {
            "Ir. szám": "8308",
            "name": "Sáska",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.4833",
            "KSH kód": "13772",
            "Jogállás": "6",
            "Terület": "3747",
            "Népesség": "269",
            "Lakások": "122"
        },
        {
            "Ir. szám": "8311",
            "name": "Nemesvita",
            "Megyekód": "VE",
            "Lat": "46.8167",
            "Long": "17.3667",
            "KSH kód": "28422",
            "Jogállás": "6",
            "Terület": "925",
            "Népesség": "356",
            "Lakások": "180"
        },
        {
            "Ir. szám": "8312",
            "name": "Balatonederics",
            "Megyekód": "VE",
            "Lat": "46.8167",
            "Long": "17.3833",
            "KSH kód": "12238",
            "Jogállás": "6",
            "Terület": "1858",
            "Népesség": "1053",
            "Lakások": "458"
        },
        {
            "Ir. szám": "8313",
            "name": "Balatongyörök",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "17.3667",
            "KSH kód": "17002",
            "Jogállás": "6",
            "Terület": "3759",
            "Népesség": "999",
            "Lakások": "448"
        },
        {
            "Ir. szám": "8314",
            "name": "Vonyarcvashegy",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "17.3167",
            "KSH kód": "12919",
            "Jogállás": "5",
            "Terület": "1428",
            "Népesség": "2192",
            "Lakások": "885"
        },
        {
            "Ir. szám": "8315",
            "name": "Gyenesdiás",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.2833",
            "KSH kód": "23302",
            "Jogállás": "5",
            "Terület": "1850",
            "Népesség": "3478",
            "Lakások": "1362"
        },
        {
            "Ir. szám": "8316",
            "name": "Vállus",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.3",
            "KSH kód": "10685",
            "Jogállás": "6",
            "Terület": "2180",
            "Népesség": "131",
            "Lakások": "60"
        },
        {
            "Ir. szám": "8316",
            "name": "Várvölgy",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "17.3",
            "KSH kód": "14182",
            "Jogállás": "6",
            "Terület": "2504",
            "Népesség": "1083",
            "Lakások": "463"
        },
        {
            "Ir. szám": "8318",
            "name": "Lesencefalu",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.35",
            "KSH kód": "17570",
            "Jogállás": "6",
            "Terület": "719",
            "Népesség": "309",
            "Lakások": "152"
        },
        {
            "Ir. szám": "8318",
            "name": "Lesencetomaj",
            "Megyekód": "VE",
            "Lat": "46.85",
            "Long": "17.3667",
            "KSH kód": "17871",
            "Jogállás": "6",
            "Terület": "2408",
            "Népesség": "1102",
            "Lakások": "369"
        },
        {
            "Ir. szám": "8319",
            "name": "Lesenceistvánd",
            "Megyekód": "VE",
            "Lat": "46.8667",
            "Long": "17.35",
            "KSH kód": "21962",
            "Jogállás": "6",
            "Terület": "864",
            "Népesség": "960",
            "Lakások": "387"
        },
        {
            "Ir. szám": "8321",
            "name": "Uzsa",
            "Megyekód": "VE",
            "Lat": "46.9",
            "Long": "17.3333",
            "KSH kód": "34218",
            "Jogállás": "6",
            "Terület": "2024",
            "Népesség": "323",
            "Lakások": "162"
        },
        {
            "Ir. szám": "8330",
            "name": "Sümeg",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.2833",
            "KSH kód": "25593",
            "Jogállás": "4",
            "Terület": "6413",
            "Népesség": "6423",
            "Lakások": "2533"
        },
        {
            "Ir. szám": "8331",
            "name": "Sümeg",
            "Megyekód": "VE",
            "Lat": "46.9833",
            "Long": "17.2833",
            "KSH kód": "25593",
            "Jogállás": "4",
            "Terület": "6413",
            "Népesség": "6423",
            "Lakások": "2533"
        },
        {
            "Ir. szám": "8341",
            "name": "Szalapa",
            "Megyekód": "ZA",
            "Lat": "46.9833",
            "Long": "17.15",
            "KSH kód": "20543",
            "Jogállás": "6",
            "Terület": "452",
            "Népesség": "216",
            "Lakások": "93"
        },
        {
            "Ir. szám": "8341",
            "name": "Kisvásárhely",
            "Megyekód": "ZA",
            "Lat": "47",
            "Long": "17.2",
            "KSH kód": "16364",
            "Jogállás": "6",
            "Terület": "435",
            "Népesség": "58",
            "Lakások": "38"
        },
        {
            "Ir. szám": "8341",
            "name": "Mihályfa",
            "Megyekód": "ZA",
            "Lat": "46.9833",
            "Long": "17.1833",
            "KSH kód": "02130",
            "Jogállás": "6",
            "Terület": "1207",
            "Népesség": "371",
            "Lakások": "181"
        },
        {
            "Ir. szám": "8342",
            "name": "Óhíd",
            "Megyekód": "ZA",
            "Lat": "46.9667",
            "Long": "17.1833",
            "KSH kód": "27775",
            "Jogállás": "6",
            "Terület": "1248",
            "Népesség": "594",
            "Lakások": "249"
        },
        {
            "Ir. szám": "8344",
            "name": "Hetyefő",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.1667",
            "KSH kód": "15088",
            "Jogállás": "6",
            "Terület": "411",
            "Népesség": "93",
            "Lakások": "50"
        },
        {
            "Ir. szám": "8344",
            "name": "Zalaerdőd",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.1333",
            "KSH kód": "29993",
            "Jogállás": "6",
            "Terület": "1769",
            "Népesség": "273",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8345",
            "name": "Dabronc",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "17.1667",
            "KSH kód": "17172",
            "Jogállás": "6",
            "Terület": "2027",
            "Népesség": "463",
            "Lakások": "207"
        },
        {
            "Ir. szám": "8346",
            "name": "Gógánfa",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.1833",
            "KSH kód": "18193",
            "Jogállás": "6",
            "Terület": "1317",
            "Népesség": "787",
            "Lakások": "312"
        },
        {
            "Ir. szám": "8347",
            "name": "Ukk",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.2167",
            "KSH kód": "23010",
            "Jogállás": "6",
            "Terület": "1387",
            "Népesség": "298",
            "Lakások": "168"
        },
        {
            "Ir. szám": "8348",
            "name": "Megyer",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.2",
            "KSH kód": "04987",
            "Jogállás": "6",
            "Terület": "426",
            "Népesség": "29",
            "Lakások": "25"
        },
        {
            "Ir. szám": "8348",
            "name": "Zalameggyes",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.2167",
            "KSH kód": "33039",
            "Jogállás": "6",
            "Terület": "256",
            "Népesség": "64",
            "Lakások": "34"
        },
        {
            "Ir. szám": "8348",
            "name": "Rigács",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.2167",
            "KSH kód": "25849",
            "Jogállás": "6",
            "Terület": "617",
            "Népesség": "196",
            "Lakások": "111"
        },
        {
            "Ir. szám": "8349",
            "name": "Zalagyömörő",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.2333",
            "KSH kód": "27207",
            "Jogállás": "6",
            "Terület": "1111",
            "Népesség": "444",
            "Lakások": "194"
        },
        {
            "Ir. szám": "8351",
            "name": "Sümegprága",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.2833",
            "KSH kód": "05388",
            "Jogállás": "6",
            "Terület": "579",
            "Népesség": "637",
            "Lakások": "248"
        },
        {
            "Ir. szám": "8352",
            "name": "Bazsi",
            "Megyekód": "VE",
            "Lat": "46.9333",
            "Long": "17.25",
            "KSH kód": "07427",
            "Jogállás": "6",
            "Terület": "910",
            "Népesség": "407",
            "Lakások": "205"
        },
        {
            "Ir. szám": "8353",
            "name": "Vindornyalak",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "17.2",
            "KSH kód": "06549",
            "Jogállás": "6",
            "Terület": "449",
            "Népesség": "83",
            "Lakások": "50"
        },
        {
            "Ir. szám": "8353",
            "name": "Zalaszántó",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "17.2333",
            "KSH kód": "33288",
            "Jogállás": "6",
            "Terület": "3773",
            "Népesség": "972",
            "Lakások": "545"
        },
        {
            "Ir. szám": "8354",
            "name": "Karmacs",
            "Megyekód": "ZA",
            "Lat": "46.8333",
            "Long": "17.1833",
            "KSH kód": "18041",
            "Jogállás": "6",
            "Terület": "1422",
            "Népesség": "769",
            "Lakások": "358"
        },
        {
            "Ir. szám": "8354",
            "name": "Zalaköveskút",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.15",
            "KSH kód": "18768",
            "Jogállás": "6",
            "Terület": "227",
            "Népesség": "23",
            "Lakások": "19"
        },
        {
            "Ir. szám": "8354",
            "name": "Vindornyafok",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.1833",
            "KSH kód": "32142",
            "Jogállás": "6",
            "Terület": "350",
            "Népesség": "127",
            "Lakások": "71"
        },
        {
            "Ir. szám": "8355",
            "name": "Vindornyaszőlős",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "17.1667",
            "KSH kód": "11800",
            "Jogállás": "6",
            "Terület": "1055",
            "Népesség": "380",
            "Lakások": "172"
        },
        {
            "Ir. szám": "8356",
            "name": "Nagygörbő",
            "Megyekód": "ZA",
            "Lat": "46.9333",
            "Long": "17.1833",
            "KSH kód": "05218",
            "Jogállás": "6",
            "Terület": "745",
            "Népesség": "194",
            "Lakások": "115"
        },
        {
            "Ir. szám": "8356",
            "name": "Kisgörbő",
            "Megyekód": "ZA",
            "Lat": "46.9333",
            "Long": "17.1667",
            "KSH kód": "09812",
            "Jogállás": "6",
            "Terület": "659",
            "Népesség": "174",
            "Lakások": "104"
        },
        {
            "Ir. szám": "8357",
            "name": "Döbröce",
            "Megyekód": "ZA",
            "Lat": "46.9333",
            "Long": "17.2",
            "KSH kód": "07782",
            "Jogállás": "6",
            "Terület": "270",
            "Népesség": "70",
            "Lakások": "47"
        },
        {
            "Ir. szám": "8357",
            "name": "Sümegcsehi",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.2167",
            "KSH kód": "21397",
            "Jogállás": "6",
            "Terület": "1744",
            "Népesség": "628",
            "Lakások": "240"
        },
        {
            "Ir. szám": "8360",
            "name": "Keszthely",
            "Megyekód": "ZA",
            "Lat": "46.7658",
            "Long": "17.2574",
            "KSH kód": "18421",
            "Jogállás": "4",
            "Terület": "7598",
            "Népesség": "21100",
            "Lakások": "9903"
        },
        {
            "Ir. szám": "8361",
            "name": "Keszthely",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.25",
            "KSH kód": "18421",
            "Jogállás": "4",
            "Terület": "7598",
            "Népesség": "21100",
            "Lakások": "9903"
        },
        {
            "Ir. szám": "8362",
            "name": "Keszthely",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.25",
            "KSH kód": "18421",
            "Jogállás": "4",
            "Terület": "7598",
            "Népesség": "21100",
            "Lakások": "9903"
        },
        {
            "Ir. szám": "8363",
            "name": "Keszthely",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.25",
            "KSH kód": "18421",
            "Jogállás": "4",
            "Terület": "7598",
            "Népesség": "21100",
            "Lakások": "9903"
        },
        {
            "Ir. szám": "8364",
            "name": "Keszthely",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.25",
            "KSH kód": "18421",
            "Jogállás": "4",
            "Terület": "7598",
            "Népesség": "21100",
            "Lakások": "9903"
        },
        {
            "Ir. szám": "8371",
            "name": "Nemesbük",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "17.15",
            "KSH kód": "32948",
            "Jogállás": "6",
            "Terület": "998",
            "Népesség": "651",
            "Lakások": "350"
        },
        {
            "Ir. szám": "8372",
            "name": "Cserszegtomaj",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "17.2167",
            "KSH kód": "07135",
            "Jogállás": "6",
            "Terület": "1260",
            "Népesség": "2581",
            "Lakások": "1016"
        },
        {
            "Ir. szám": "8373",
            "name": "Rezi",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.2167",
            "KSH kód": "23898",
            "Jogállás": "6",
            "Terület": "2978",
            "Népesség": "1190",
            "Lakások": "506"
        },
        {
            "Ir. szám": "8380",
            "name": "Felsőpáhok",
            "Megyekód": "ZA",
            "Lat": "46.7899",
            "Long": "17.1838",
            "KSH kód": "11642",
            "Jogállás": "6",
            "Terület": "727",
            "Népesség": "649",
            "Lakások": "294"
        },
        {
            "Ir. szám": "8380",
            "name": "Hévíz",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "17.1667",
            "KSH kód": "03814",
            "Jogállás": "4",
            "Terület": "831",
            "Népesség": "4335",
            "Lakások": "3626"
        },
        {
            "Ir. szám": "8381",
            "name": "Felsőpáhok",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "17.1667",
            "KSH kód": "11642",
            "Jogállás": "6",
            "Terület": "727",
            "Népesség": "649",
            "Lakások": "294"
        },
        {
            "Ir. szám": "8391",
            "name": "Sármellék",
            "Megyekód": "ZA",
            "Lat": "46.7167",
            "Long": "17.1667",
            "KSH kód": "14906",
            "Jogállás": "6",
            "Terület": "3537",
            "Népesség": "1862",
            "Lakások": "770"
        },
        {
            "Ir. szám": "8392",
            "name": "Zalavár",
            "Megyekód": "ZA",
            "Lat": "46.6667",
            "Long": "17.1667",
            "KSH kód": "13736",
            "Jogállás": "6",
            "Terület": "3106",
            "Népesség": "923",
            "Lakások": "414"
        },
        {
            "Ir. szám": "8393",
            "name": "Szentgyörgyvár",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "17.1333",
            "KSH kód": "23126",
            "Jogállás": "6",
            "Terület": "1166",
            "Népesség": "324",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8394",
            "name": "Alsópáhok",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.1833",
            "KSH kód": "32081",
            "Jogállás": "6",
            "Terület": "1802",
            "Népesség": "1326",
            "Lakások": "597"
        },
        {
            "Ir. szám": "8400",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8401",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8403",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8404",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8408",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8409",
            "name": "Úrkút",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.6333",
            "KSH kód": "20853",
            "Jogállás": "6",
            "Terület": "2013",
            "Népesség": "2115",
            "Lakások": "746"
        },
        {
            "Ir. szám": "8411",
            "name": "Úrkút",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.6333",
            "KSH kód": "20853",
            "Jogállás": "6",
            "Terület": "2013",
            "Népesség": "2115",
            "Lakások": "746"
        },
        {
            "Ir. szám": "8412",
            "name": "Úrkút",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.6333",
            "KSH kód": "20853",
            "Jogállás": "6",
            "Terület": "2013",
            "Népesség": "2115",
            "Lakások": "746"
        },
        {
            "Ir. szám": "8413",
            "name": "Eplény",
            "Megyekód": "VE",
            "Lat": "47.2167",
            "Long": "17.9167",
            "KSH kód": "33941",
            "Jogállás": "6",
            "Terület": "828",
            "Népesség": "512",
            "Lakások": "182"
        },
        {
            "Ir. szám": "8414",
            "name": "Olaszfalu",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.9167",
            "KSH kód": "26514",
            "Jogállás": "6",
            "Terület": "4280",
            "Népesség": "1107",
            "Lakások": "390"
        },
        {
            "Ir. szám": "8415",
            "name": "Nagyesztergár",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "17.9",
            "KSH kód": "23180",
            "Jogállás": "6",
            "Terület": "1829",
            "Népesség": "1251",
            "Lakások": "404"
        },
        {
            "Ir. szám": "8416",
            "name": "Dudar",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.95",
            "KSH kód": "02936",
            "Jogállás": "6",
            "Terület": "2458",
            "Népesség": "1631",
            "Lakások": "642"
        },
        {
            "Ir. szám": "8417",
            "name": "Csetény",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "18",
            "KSH kód": "31699",
            "Jogállás": "6",
            "Terület": "1837",
            "Népesség": "1909",
            "Lakások": "701"
        },
        {
            "Ir. szám": "8418",
            "name": "Bakonyoszlop",
            "Megyekód": "VE",
            "Lat": "47.35",
            "Long": "17.9333",
            "KSH kód": "30410",
            "Jogállás": "6",
            "Terület": "1420",
            "Népesség": "526",
            "Lakások": "211"
        },
        {
            "Ir. szám": "8419",
            "name": "Csesznek",
            "Megyekód": "VE",
            "Lat": "47.35",
            "Long": "17.8833",
            "KSH kód": "24642",
            "Jogállás": "6",
            "Terület": "2420",
            "Népesség": "541",
            "Lakások": "206"
        },
        {
            "Ir. szám": "8420",
            "name": "Zirc",
            "Megyekód": "VE",
            "Lat": "47.2667",
            "Long": "17.8667",
            "KSH kód": "26499",
            "Jogállás": "4",
            "Terület": "3740",
            "Népesség": "7093",
            "Lakások": "2660"
        },
        {
            "Ir. szám": "8422",
            "name": "Bakonynána",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "17.9667",
            "KSH kód": "25991",
            "Jogállás": "6",
            "Terület": "1493",
            "Népesség": "1011",
            "Lakások": "392"
        },
        {
            "Ir. szám": "8423",
            "name": "Szápár",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "18.0333",
            "KSH kód": "16489",
            "Jogállás": "6",
            "Terület": "709",
            "Népesség": "520",
            "Lakások": "198"
        },
        {
            "Ir. szám": "8424",
            "name": "Jásd",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "18.0333",
            "KSH kód": "17437",
            "Jogállás": "6",
            "Terület": "1028",
            "Népesség": "804",
            "Lakások": "355"
        },
        {
            "Ir. szám": "8425",
            "name": "Lókút",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.8667",
            "KSH kód": "18856",
            "Jogállás": "6",
            "Terület": "1811",
            "Népesség": "505",
            "Lakások": "191"
        },
        {
            "Ir. szám": "8426",
            "name": "Pénzesgyőr",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.7833",
            "KSH kód": "15547",
            "Jogállás": "6",
            "Terület": "1765",
            "Népesség": "353",
            "Lakások": "153"
        },
        {
            "Ir. szám": "8427",
            "name": "Bakonybél",
            "Megyekód": "VE",
            "Lat": "47.25",
            "Long": "17.7333",
            "KSH kód": "23746",
            "Jogállás": "6",
            "Terület": "2436",
            "Népesség": "1259",
            "Lakások": "581"
        },
        {
            "Ir. szám": "8428",
            "name": "Borzavár",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.8333",
            "KSH kód": "30252",
            "Jogállás": "6",
            "Terület": "1300",
            "Népesség": "732",
            "Lakások": "344"
        },
        {
            "Ir. szám": "8429",
            "name": "Porva",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "17.8167",
            "KSH kód": "23515",
            "Jogállás": "6",
            "Terület": "2813",
            "Népesség": "461",
            "Lakások": "186"
        },
        {
            "Ir. szám": "8430",
            "name": "Bakonyszentkirály",
            "Megyekód": "VE",
            "Lat": "47.3667",
            "Long": "17.8833",
            "KSH kód": "22813",
            "Jogállás": "6",
            "Terület": "2790",
            "Népesség": "875",
            "Lakások": "378"
        },
        {
            "Ir. szám": "8431",
            "name": "Bakonyszentlászló",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "05944",
            "Jogállás": "6",
            "Terület": "3851",
            "Népesség": "1790",
            "Lakások": "773"
        },
        {
            "Ir. szám": "8432",
            "name": "Fenyőfő",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "30678",
            "Jogállás": "6",
            "Terület": "2965",
            "Népesség": "119",
            "Lakások": "78"
        },
        {
            "Ir. szám": "8433",
            "name": "Bakonygyirót",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "28936",
            "Jogállás": "6",
            "Terület": "685",
            "Népesség": "144",
            "Lakások": "117"
        },
        {
            "Ir. szám": "8434",
            "name": "Románd",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "17507",
            "Jogállás": "6",
            "Terület": "985",
            "Népesség": "301",
            "Lakások": "143"
        },
        {
            "Ir. szám": "8435",
            "name": "Gic",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.7667",
            "KSH kód": "16717",
            "Jogállás": "6",
            "Terület": "1968",
            "Népesség": "471",
            "Lakások": "151"
        },
        {
            "Ir. szám": "8436",
            "name": "Bakonypéterd",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "22062",
            "Jogállás": "6",
            "Terület": "1002",
            "Népesség": "265",
            "Lakások": "124"
        },
        {
            "Ir. szám": "8437",
            "name": "Lázi",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "04376",
            "Jogállás": "6",
            "Terület": "1679",
            "Népesség": "568",
            "Lakások": "239"
        },
        {
            "Ir. szám": "8438",
            "name": "Veszprémvarsány",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "22691",
            "Jogállás": "6",
            "Terület": "2119",
            "Népesség": "997",
            "Lakások": "424"
        },
        {
            "Ir. szám": "8439",
            "name": "Sikátor",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "08606",
            "Jogállás": "6",
            "Terület": "1374",
            "Népesség": "304",
            "Lakások": "144"
        },
        {
            "Ir. szám": "8440",
            "name": "Herend",
            "Megyekód": "VE",
            "Lat": "47.1333",
            "Long": "17.75",
            "KSH kód": "23658",
            "Jogállás": "4",
            "Terület": "1956",
            "Népesség": "3489",
            "Lakások": "1290"
        },
        {
            "Ir. szám": "8441",
            "name": "Márkó",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.8167",
            "KSH kód": "32212",
            "Jogállás": "6",
            "Terület": "3441",
            "Népesség": "1143",
            "Lakások": "379"
        },
        {
            "Ir. szám": "8442",
            "name": "Hárskút",
            "Megyekód": "VE",
            "Lat": "47.1833",
            "Long": "17.8167",
            "KSH kód": "25566",
            "Jogállás": "6",
            "Terület": "3446",
            "Népesség": "671",
            "Lakások": "230"
        },
        {
            "Ir. szám": "8443",
            "name": "Bánd",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.8",
            "KSH kód": "14173",
            "Jogállás": "6",
            "Terület": "984",
            "Népesség": "696",
            "Lakások": "236"
        },
        {
            "Ir. szám": "8444",
            "name": "Szentgál",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.7333",
            "KSH kód": "07922",
            "Jogállás": "6",
            "Terület": "9501",
            "Népesség": "2765",
            "Lakások": "1012"
        },
        {
            "Ir. szám": "8445",
            "name": "Csehbánya",
            "Megyekód": "VE",
            "Lat": "47.1833",
            "Long": "17.6833",
            "KSH kód": "20251",
            "Jogállás": "6",
            "Terület": "1283",
            "Népesség": "280",
            "Lakások": "123"
        },
        {
            "Ir. szám": "8445",
            "name": "Városlőd",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "17.65",
            "KSH kód": "07065",
            "Jogállás": "6",
            "Terület": "2227",
            "Népesség": "1436",
            "Lakások": "513"
        },
        {
            "Ir. szám": "8446",
            "name": "Kislőd",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "17.6167",
            "KSH kód": "30173",
            "Jogállás": "6",
            "Terület": "3818",
            "Népesség": "1246",
            "Lakások": "439"
        },
        {
            "Ir. szám": "8447",
            "name": "Kislőd",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "17.6167",
            "KSH kód": "30173",
            "Jogállás": "6",
            "Terület": "3818",
            "Népesség": "1246",
            "Lakások": "439"
        },
        {
            "Ir. szám": "8448",
            "name": "Kislőd",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "17.6167",
            "KSH kód": "30173",
            "Jogállás": "6",
            "Terület": "3818",
            "Népesség": "1246",
            "Lakások": "439"
        },
        {
            "Ir. szám": "8449",
            "name": "Magyarpolány",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.55",
            "KSH kód": "20437",
            "Jogállás": "6",
            "Terület": "2697",
            "Népesség": "1138",
            "Lakások": "493"
        },
        {
            "Ir. szám": "8451",
            "name": "Magyarpolány",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.55",
            "KSH kód": "20437",
            "Jogállás": "6",
            "Terület": "2697",
            "Népesség": "1138",
            "Lakások": "493"
        },
        {
            "Ir. szám": "8452",
            "name": "Halimba",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "17.5333",
            "KSH kód": "07898",
            "Jogállás": "6",
            "Terület": "1262",
            "Népesség": "1152",
            "Lakások": "435"
        },
        {
            "Ir. szám": "8452",
            "name": "Szőc",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.5167",
            "KSH kód": "16692",
            "Jogállás": "6",
            "Terület": "755",
            "Népesség": "439",
            "Lakások": "108"
        },
        {
            "Ir. szám": "8454",
            "name": "Nyirád",
            "Megyekód": "VE",
            "Lat": "47",
            "Long": "17.45",
            "KSH kód": "24004",
            "Jogállás": "6",
            "Terület": "6136",
            "Népesség": "1949",
            "Lakások": "749"
        },
        {
            "Ir. szám": "8455",
            "name": "Pusztamiske",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.45",
            "KSH kód": "28972",
            "Jogállás": "6",
            "Terület": "1782",
            "Népesség": "413",
            "Lakások": "154"
        },
        {
            "Ir. szám": "8456",
            "name": "Noszlop",
            "Megyekód": "VE",
            "Lat": "47.1833",
            "Long": "17.4667",
            "KSH kód": "14757",
            "Jogállás": "6",
            "Terület": "3406",
            "Népesség": "1068",
            "Lakások": "419"
        },
        {
            "Ir. szám": "8457",
            "name": "Bakonypölöske",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.4833",
            "KSH kód": "06327",
            "Jogállás": "6",
            "Terület": "1104",
            "Népesség": "387",
            "Lakások": "163"
        },
        {
            "Ir. szám": "8458",
            "name": "Oroszi",
            "Megyekód": "VE",
            "Lat": "47.15",
            "Long": "17.4167",
            "KSH kód": "16595",
            "Jogállás": "6",
            "Terület": "474",
            "Népesség": "138",
            "Lakások": "89"
        },
        {
            "Ir. szám": "8460",
            "name": "Devecser",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.4333",
            "KSH kód": "32276",
            "Jogállás": "4",
            "Terület": "6411",
            "Népesség": "4806",
            "Lakások": "1883"
        },
        {
            "Ir. szám": "8461",
            "name": "Devecser",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.4333",
            "KSH kód": "32276",
            "Jogállás": "4",
            "Terület": "6411",
            "Népesség": "4806",
            "Lakások": "1883"
        },
        {
            "Ir. szám": "8468",
            "name": "Kolontár",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.4833",
            "KSH kód": "30182",
            "Jogállás": "6",
            "Terület": "2172",
            "Népesség": "804",
            "Lakások": "262"
        },
        {
            "Ir. szám": "8469",
            "name": "Kamond",
            "Megyekód": "VE",
            "Lat": "47.1333",
            "Long": "17.2",
            "KSH kód": "19141",
            "Jogállás": "6",
            "Terület": "2050",
            "Népesség": "421",
            "Lakások": "159"
        },
        {
            "Ir. szám": "8471",
            "name": "Nemeshany",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.3667",
            "KSH kód": "05555",
            "Jogállás": "6",
            "Terület": "1050",
            "Népesség": "415",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8471",
            "name": "Káptalanfa",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.35",
            "KSH kód": "14270",
            "Jogállás": "6",
            "Terület": "3371",
            "Népesség": "877",
            "Lakások": "316"
        },
        {
            "Ir. szám": "8471",
            "name": "Bodorfa",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.3333",
            "KSH kód": "04321",
            "Jogállás": "6",
            "Terület": "222",
            "Népesség": "109",
            "Lakások": "55"
        },
        {
            "Ir. szám": "8473",
            "name": "Gyepükaján",
            "Megyekód": "VE",
            "Lat": "47.05",
            "Long": "17.3333",
            "KSH kód": "28671",
            "Jogállás": "6",
            "Terület": "911",
            "Népesség": "363",
            "Lakások": "158"
        },
        {
            "Ir. szám": "8474",
            "name": "Csabrendek",
            "Megyekód": "VE",
            "Lat": "47.0167",
            "Long": "17.3",
            "KSH kód": "30924",
            "Jogállás": "6",
            "Terület": "4494",
            "Népesség": "3032",
            "Lakások": "1099"
        },
        {
            "Ir. szám": "8475",
            "name": "Hosztót",
            "Megyekód": "VE",
            "Lat": "47.0833",
            "Long": "17.25",
            "KSH kód": "07250",
            "Jogállás": "6",
            "Terület": "727",
            "Népesség": "76",
            "Lakások": "62"
        },
        {
            "Ir. szám": "8475",
            "name": "Veszprémgalsa",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.2833",
            "KSH kód": "19336",
            "Jogállás": "6",
            "Terület": "871",
            "Népesség": "288",
            "Lakások": "141"
        },
        {
            "Ir. szám": "8475",
            "name": "Szentimrefalva",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "17.2833",
            "KSH kód": "10065",
            "Jogállás": "6",
            "Terület": "1087",
            "Népesség": "200",
            "Lakások": "101"
        },
        {
            "Ir. szám": "8476",
            "name": "Zalaszegvár",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.2333",
            "KSH kód": "23597",
            "Jogállás": "6",
            "Terület": "684",
            "Népesség": "154",
            "Lakások": "77"
        },
        {
            "Ir. szám": "8477",
            "name": "Apácatorna",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.3",
            "KSH kód": "28370",
            "Jogállás": "6",
            "Terület": "729",
            "Népesség": "171",
            "Lakások": "84"
        },
        {
            "Ir. szám": "8477",
            "name": "Tüskevár",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.3167",
            "KSH kód": "04631",
            "Jogállás": "6",
            "Terület": "1701",
            "Népesség": "571",
            "Lakások": "269"
        },
        {
            "Ir. szám": "8477",
            "name": "Kisberzseny",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.2667",
            "KSH kód": "29072",
            "Jogállás": "6",
            "Terület": "529",
            "Népesség": "90",
            "Lakások": "59"
        },
        {
            "Ir. szám": "8478",
            "name": "Somlójenő",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.3667",
            "KSH kód": "05157",
            "Jogállás": "6",
            "Terület": "815",
            "Népesség": "318",
            "Lakások": "142"
        },
        {
            "Ir. szám": "8479",
            "name": "Borszörcsök",
            "Megyekód": "VE",
            "Lat": "47.1333",
            "Long": "17.4",
            "KSH kód": "04765",
            "Jogállás": "6",
            "Terület": "1180",
            "Népesség": "375",
            "Lakások": "156"
        },
        {
            "Ir. szám": "8481",
            "name": "Somlóvásárhely",
            "Megyekód": "VE",
            "Lat": "47.1167",
            "Long": "17.3833",
            "KSH kód": "26569",
            "Jogállás": "6",
            "Terület": "2321",
            "Népesség": "1121",
            "Lakások": "453"
        },
        {
            "Ir. szám": "8482",
            "name": "Doba",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.3833",
            "KSH kód": "10870",
            "Jogállás": "6",
            "Terület": "2122",
            "Népesség": "502",
            "Lakások": "261"
        },
        {
            "Ir. szám": "8483",
            "name": "Kisszőlős",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.3333",
            "KSH kód": "23001",
            "Jogállás": "6",
            "Terület": "649",
            "Népesség": "132",
            "Lakások": "72"
        },
        {
            "Ir. szám": "8483",
            "name": "Somlószőlős",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.3667",
            "KSH kód": "25779",
            "Jogállás": "6",
            "Terület": "1946",
            "Népesség": "672",
            "Lakások": "322"
        },
        {
            "Ir. szám": "8484",
            "name": "Vid",
            "Megyekód": "VE",
            "Lat": "47.2167",
            "Long": "17.35",
            "KSH kód": "09502",
            "Jogállás": "6",
            "Terület": "317",
            "Népesség": "130",
            "Lakások": "67"
        },
        {
            "Ir. szám": "8484",
            "name": "Somlóvecse",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.35",
            "KSH kód": "14243",
            "Jogállás": "6",
            "Terület": "493",
            "Népesség": "102",
            "Lakások": "62"
        },
        {
            "Ir. szám": "8484",
            "name": "Nagyalásony",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.35",
            "KSH kód": "27979",
            "Jogállás": "6",
            "Terület": "1401",
            "Népesség": "514",
            "Lakások": "215"
        },
        {
            "Ir. szám": "8485",
            "name": "Dabrony",
            "Megyekód": "VE",
            "Lat": "47.25",
            "Long": "17.3333",
            "KSH kód": "28237",
            "Jogállás": "6",
            "Terület": "1700",
            "Népesség": "376",
            "Lakások": "197"
        },
        {
            "Ir. szám": "8491",
            "name": "Karakószörcsök",
            "Megyekód": "VE",
            "Lat": "47.1333",
            "Long": "17.2833",
            "KSH kód": "08749",
            "Jogállás": "6",
            "Terület": "711",
            "Népesség": "329",
            "Lakások": "133"
        },
        {
            "Ir. szám": "8492",
            "name": "Kerta",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.2833",
            "KSH kód": "25654",
            "Jogállás": "6",
            "Terület": "1546",
            "Népesség": "635",
            "Lakások": "252"
        },
        {
            "Ir. szám": "8493",
            "name": "Iszkáz",
            "Megyekód": "VE",
            "Lat": "47.1667",
            "Long": "17.3",
            "KSH kód": "28015",
            "Jogállás": "6",
            "Terület": "1574",
            "Népesség": "350",
            "Lakások": "208"
        },
        {
            "Ir. szám": "8494",
            "name": "Kiscsősz",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.2833",
            "KSH kód": "23700",
            "Jogállás": "6",
            "Terület": "904",
            "Népesség": "129",
            "Lakások": "85"
        },
        {
            "Ir. szám": "8495",
            "name": "Csögle",
            "Megyekód": "VE",
            "Lat": "47.2167",
            "Long": "17.2667",
            "KSH kód": "32814",
            "Jogállás": "6",
            "Terület": "1691",
            "Népesség": "647",
            "Lakások": "314"
        },
        {
            "Ir. szám": "8496",
            "name": "Kispirit",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.25",
            "KSH kód": "04288",
            "Jogállás": "6",
            "Terület": "496",
            "Népesség": "99",
            "Lakások": "68"
        },
        {
            "Ir. szám": "8496",
            "name": "Nagypirit",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.2333",
            "KSH kód": "21403",
            "Jogállás": "6",
            "Terület": "1017",
            "Népesség": "278",
            "Lakások": "159"
        },
        {
            "Ir. szám": "8497",
            "name": "Adorjánháza",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.25",
            "KSH kód": "31307",
            "Jogállás": "6",
            "Terület": "1142",
            "Népesség": "381",
            "Lakások": "206"
        },
        {
            "Ir. szám": "8497",
            "name": "Egeralja",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.25",
            "KSH kód": "33871",
            "Jogállás": "6",
            "Terület": "894",
            "Népesség": "230",
            "Lakások": "116"
        },
        {
            "Ir. szám": "8500",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8501",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8502",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8503",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8505",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8510",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8511",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8512",
            "name": "Nyárád",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "17.3667",
            "KSH kód": "29009",
            "Jogállás": "6",
            "Terület": "1979",
            "Népesség": "909",
            "Lakások": "357"
        },
        {
            "Ir. szám": "8513",
            "name": "Mihályháza",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.3333",
            "KSH kód": "04668",
            "Jogállás": "6",
            "Terület": "2152",
            "Népesség": "771",
            "Lakások": "317"
        },
        {
            "Ir. szám": "8514",
            "name": "Mezőlak",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.3667",
            "KSH kód": "23560",
            "Jogállás": "6",
            "Terület": "2158",
            "Népesség": "1055",
            "Lakások": "391"
        },
        {
            "Ir. szám": "8515",
            "name": "Békás",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.35",
            "KSH kód": "27058",
            "Jogállás": "6",
            "Terület": "652",
            "Népesség": "214",
            "Lakások": "97"
        },
        {
            "Ir. szám": "8516",
            "name": "Kemeneshőgyész",
            "Megyekód": "VE",
            "Lat": "47.35",
            "Long": "17.3",
            "KSH kód": "19734",
            "Jogállás": "6",
            "Terület": "2608",
            "Népesség": "508",
            "Lakások": "240"
        },
        {
            "Ir. szám": "8517",
            "name": "Magyargencs",
            "Megyekód": "VE",
            "Lat": "47.3667",
            "Long": "17.3",
            "KSH kód": "26374",
            "Jogállás": "6",
            "Terület": "3801",
            "Népesség": "556",
            "Lakások": "278"
        },
        {
            "Ir. szám": "8518",
            "name": "Kemenesszentpéter",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.2333",
            "KSH kód": "12478",
            "Jogállás": "6",
            "Terület": "2135",
            "Népesség": "647",
            "Lakások": "327"
        },
        {
            "Ir. szám": "8521",
            "name": "Nagyacsád",
            "Megyekód": "VE",
            "Lat": "47.3667",
            "Long": "17.3667",
            "KSH kód": "23551",
            "Jogállás": "6",
            "Terület": "1396",
            "Népesség": "666",
            "Lakások": "261"
        },
        {
            "Ir. szám": "8522",
            "name": "Nemesgörzsöny",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.3667",
            "KSH kód": "05652",
            "Jogállás": "6",
            "Terület": "1925",
            "Népesség": "698",
            "Lakások": "298"
        },
        {
            "Ir. szám": "8523",
            "name": "Egyházaskesző",
            "Megyekód": "VE",
            "Lat": "47.4167",
            "Long": "17.3333",
            "KSH kód": "10445",
            "Jogállás": "6",
            "Terület": "2012",
            "Népesség": "497",
            "Lakások": "246"
        },
        {
            "Ir. szám": "8523",
            "name": "Várkesző",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.3167",
            "KSH kód": "26204",
            "Jogállás": "6",
            "Terület": "641",
            "Népesség": "187",
            "Lakások": "112"
        },
        {
            "Ir. szám": "8531",
            "name": "Várkesző",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.3167",
            "KSH kód": "26204",
            "Jogállás": "6",
            "Terület": "641",
            "Népesség": "187",
            "Lakások": "112"
        },
        {
            "Ir. szám": "8532",
            "name": "Marcaltő",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.3667",
            "KSH kód": "22220",
            "Jogállás": "6",
            "Terület": "2209",
            "Népesség": "813",
            "Lakások": "338"
        },
        {
            "Ir. szám": "8533",
            "name": "Malomsok",
            "Megyekód": "VE",
            "Lat": "47.45",
            "Long": "17.4",
            "KSH kód": "03610",
            "Jogállás": "6",
            "Terület": "2574",
            "Népesség": "546",
            "Lakások": "262"
        },
        {
            "Ir. szám": "8534",
            "name": "Csikvánd",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.45",
            "KSH kód": "13505",
            "Jogállás": "6",
            "Terület": "1453",
            "Népesség": "488",
            "Lakások": "228"
        },
        {
            "Ir. szám": "8541",
            "name": "Takácsi",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.4833",
            "KSH kód": "13125",
            "Jogállás": "6",
            "Terület": "1913",
            "Népesség": "857",
            "Lakások": "350"
        },
        {
            "Ir. szám": "8542",
            "name": "Vaszar",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.5167",
            "KSH kód": "26648",
            "Jogállás": "6",
            "Terület": "3369",
            "Népesség": "1512",
            "Lakások": "650"
        },
        {
            "Ir. szám": "8543",
            "name": "Gecse",
            "Megyekód": "VE",
            "Lat": "47.45",
            "Long": "17.5333",
            "KSH kód": "09292",
            "Jogállás": "6",
            "Terület": "934",
            "Népesség": "425",
            "Lakások": "202"
        },
        {
            "Ir. szám": "8544",
            "name": "Szerecseny",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.55",
            "KSH kód": "03887",
            "Jogállás": "6",
            "Terület": "1239",
            "Népesség": "813",
            "Lakások": "375"
        },
        {
            "Ir. szám": "8545",
            "name": "Gyarmat",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.5",
            "KSH kód": "26860",
            "Jogállás": "6",
            "Terület": "2245",
            "Népesség": "1232",
            "Lakások": "555"
        },
        {
            "Ir. szám": "8551",
            "name": "Nagygyimót",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.55",
            "KSH kód": "08262",
            "Jogállás": "6",
            "Terület": "2344",
            "Népesség": "548",
            "Lakások": "249"
        },
        {
            "Ir. szám": "8552",
            "name": "Vanyola",
            "Megyekód": "VE",
            "Lat": "47.3833",
            "Long": "17.5833",
            "KSH kód": "21777",
            "Jogállás": "6",
            "Terület": "1951",
            "Népesség": "571",
            "Lakások": "276"
        },
        {
            "Ir. szám": "8553",
            "name": "Lovászpatona",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.6333",
            "KSH kód": "05087",
            "Jogállás": "6",
            "Terület": "4990",
            "Népesség": "1161",
            "Lakások": "520"
        },
        {
            "Ir. szám": "8554",
            "name": "Nagydém",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.6833",
            "KSH kód": "10001",
            "Jogállás": "6",
            "Terület": "1332",
            "Népesség": "383",
            "Lakások": "158"
        },
        {
            "Ir. szám": "8555",
            "name": "Bakonytamási",
            "Megyekód": "VE",
            "Lat": "47.4167",
            "Long": "17.7333",
            "KSH kód": "24129",
            "Jogállás": "6",
            "Terület": "2049",
            "Népesség": "665",
            "Lakások": "291"
        },
        {
            "Ir. szám": "8556",
            "name": "Pápateszér",
            "Megyekód": "VE",
            "Lat": "47.3833",
            "Long": "17.7",
            "KSH kód": "31255",
            "Jogállás": "6",
            "Terület": "3074",
            "Népesség": "1201",
            "Lakások": "485"
        },
        {
            "Ir. szám": "8557",
            "name": "Bakonyság",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.65",
            "KSH kód": "29902",
            "Jogállás": "6",
            "Terület": "856",
            "Népesség": "50",
            "Lakások": "51"
        },
        {
            "Ir. szám": "8557",
            "name": "Bakonyszentiván",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.6667",
            "KSH kód": "23922",
            "Jogállás": "6",
            "Terület": "914",
            "Népesség": "227",
            "Lakások": "117"
        },
        {
            "Ir. szám": "8558",
            "name": "Csót",
            "Megyekód": "VE",
            "Lat": "47.3667",
            "Long": "17.6167",
            "KSH kód": "32878",
            "Jogállás": "6",
            "Terület": "2002",
            "Népesség": "1049",
            "Lakások": "380"
        },
        {
            "Ir. szám": "8561",
            "name": "Adásztevel",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.55",
            "KSH kód": "07302",
            "Jogállás": "6",
            "Terület": "837",
            "Népesség": "785",
            "Lakások": "320"
        },
        {
            "Ir. szám": "8562",
            "name": "Nagytevel",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.5667",
            "KSH kód": "25201",
            "Jogállás": "6",
            "Terület": "1441",
            "Népesség": "531",
            "Lakások": "226"
        },
        {
            "Ir. szám": "8563",
            "name": "Homokbödöge",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.6",
            "KSH kód": "27818",
            "Jogállás": "6",
            "Terület": "1615",
            "Népesség": "694",
            "Lakások": "298"
        },
        {
            "Ir. szám": "8564",
            "name": "Ugod",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "17.6",
            "KSH kód": "24767",
            "Jogállás": "6",
            "Terület": "6279",
            "Népesség": "1456",
            "Lakások": "599"
        },
        {
            "Ir. szám": "8565",
            "name": "Béb",
            "Megyekód": "VE",
            "Lat": "47.35",
            "Long": "17.6",
            "KSH kód": "22901",
            "Jogállás": "6",
            "Terület": "704",
            "Népesség": "257",
            "Lakások": "106"
        },
        {
            "Ir. szám": "8571",
            "name": "Bakonykoppány",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.6833",
            "KSH kód": "07287",
            "Jogállás": "6",
            "Terület": "683",
            "Népesség": "186",
            "Lakások": "119"
        },
        {
            "Ir. szám": "8572",
            "name": "Bakonyszücs",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.6833",
            "KSH kód": "26417",
            "Jogállás": "6",
            "Terület": "3521",
            "Népesség": "344",
            "Lakások": "157"
        },
        {
            "Ir. szám": "8581",
            "name": "Németbánya",
            "Megyekód": "VE",
            "Lat": "47.2167",
            "Long": "17.65",
            "KSH kód": "10409",
            "Jogállás": "6",
            "Terület": "1219",
            "Népesség": "70",
            "Lakások": "66"
        },
        {
            "Ir. szám": "8581",
            "name": "Bakonyjákó",
            "Megyekód": "VE",
            "Lat": "47.2167",
            "Long": "17.6",
            "KSH kód": "29513",
            "Jogállás": "6",
            "Terület": "4718",
            "Népesség": "659",
            "Lakások": "255"
        },
        {
            "Ir. szám": "8582",
            "name": "Farkasgyepű",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.6333",
            "KSH kód": "10250",
            "Jogállás": "6",
            "Terület": "1005",
            "Népesség": "407",
            "Lakások": "150"
        },
        {
            "Ir. szám": "8591",
            "name": "Farkasgyepű",
            "Megyekód": "VE",
            "Lat": "47.2",
            "Long": "17.6333",
            "KSH kód": "10250",
            "Jogállás": "6",
            "Terület": "1005",
            "Népesség": "407",
            "Lakások": "150"
        },
        {
            "Ir. szám": "8591",
            "name": "Nóráp",
            "Megyekód": "VE",
            "Lat": "47.2667",
            "Long": "17.4667",
            "KSH kód": "26523",
            "Jogállás": "6",
            "Terület": "758",
            "Népesség": "196",
            "Lakások": "87"
        },
        {
            "Ir. szám": "8592",
            "name": "Dáka",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "17.4333",
            "KSH kód": "20154",
            "Jogállás": "6",
            "Terület": "2540",
            "Népesség": "673",
            "Lakások": "178"
        },
        {
            "Ir. szám": "8593",
            "name": "Pápadereske",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.4",
            "KSH kód": "19868",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "278",
            "Lakások": "104"
        },
        {
            "Ir. szám": "8594",
            "name": "Pápasalamon",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.4333",
            "KSH kód": "21607",
            "Jogállás": "6",
            "Terület": "1396",
            "Népesség": "336",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8595",
            "name": "Kup",
            "Megyekód": "VE",
            "Lat": "47.25",
            "Long": "17.4667",
            "KSH kód": "26541",
            "Jogállás": "6",
            "Terület": "2478",
            "Népesség": "443",
            "Lakások": "179"
        },
        {
            "Ir. szám": "8596",
            "name": "Pápakovácsi",
            "Megyekód": "VE",
            "Lat": "47.2667",
            "Long": "17.4833",
            "KSH kód": "11855",
            "Jogállás": "6",
            "Terület": "1392",
            "Népesség": "545",
            "Lakások": "186"
        },
        {
            "Ir. szám": "8597",
            "name": "Ganna",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.5333",
            "KSH kód": "12742",
            "Jogállás": "6",
            "Terület": "1547",
            "Népesség": "258",
            "Lakások": "96"
        },
        {
            "Ir. szám": "8597",
            "name": "Döbrönte",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.55",
            "KSH kód": "29470",
            "Jogállás": "6",
            "Terület": "1079",
            "Népesség": "256",
            "Lakások": "100"
        },
        {
            "Ir. szám": "8598",
            "name": "Döbrönte",
            "Megyekód": "VE",
            "Lat": "47.2333",
            "Long": "17.55",
            "KSH kód": "29470",
            "Jogállás": "6",
            "Terület": "1079",
            "Népesség": "256",
            "Lakások": "100"
        },
        {
            "Ir. szám": "8600",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9058",
            "Long": "18.0485",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8601",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9",
            "Long": "18.05",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8602",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9",
            "Long": "18.05",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8604",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9",
            "Long": "18.05",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8609",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9",
            "Long": "18.05",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8611",
            "name": "Siófok",
            "Megyekód": "SO",
            "Lat": "46.9",
            "Long": "18.05",
            "KSH kód": "17631",
            "Jogállás": "4",
            "Terület": "12466",
            "Népesség": "24221",
            "Lakások": "11710"
        },
        {
            "Ir. szám": "8612",
            "name": "Nyim",
            "Megyekód": "SO",
            "Lat": "46.8",
            "Long": "18.1167",
            "KSH kód": "03203",
            "Jogállás": "6",
            "Terület": "976",
            "Népesség": "295",
            "Lakások": "145"
        },
        {
            "Ir. szám": "8613",
            "name": "Balatonendréd",
            "Megyekód": "SO",
            "Lat": "46.8333",
            "Long": "17.9833",
            "KSH kód": "19460",
            "Jogállás": "6",
            "Terület": "4002",
            "Népesség": "1386",
            "Lakások": "557"
        },
        {
            "Ir. szám": "8614",
            "name": "Bálványos",
            "Megyekód": "SO",
            "Lat": "46.7833",
            "Long": "17.95",
            "KSH kód": "24457",
            "Jogállás": "6",
            "Terület": "2369",
            "Népesség": "541",
            "Lakások": "266"
        },
        {
            "Ir. szám": "8617",
            "name": "Kőröshegy",
            "Megyekód": "SO",
            "Lat": "46.8333",
            "Long": "17.9",
            "KSH kód": "15510",
            "Jogállás": "6",
            "Terület": "2170",
            "Népesség": "1610",
            "Lakások": "722"
        },
        {
            "Ir. szám": "8618",
            "name": "Kereki",
            "Megyekód": "SO",
            "Lat": "46.8",
            "Long": "17.9167",
            "KSH kód": "04598",
            "Jogállás": "6",
            "Terület": "1443",
            "Népesség": "562",
            "Lakások": "214"
        },
        {
            "Ir. szám": "8619",
            "name": "Pusztaszemes",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.9333",
            "KSH kód": "23311",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "386",
            "Lakások": "183"
        },
        {
            "Ir. szám": "8621",
            "name": "Zamárdi",
            "Megyekód": "SO",
            "Lat": "46.8833",
            "Long": "17.95",
            "KSH kód": "06008",
            "Jogállás": "4",
            "Terület": "4515",
            "Népesség": "2385",
            "Lakások": "1052"
        },
        {
            "Ir. szám": "8622",
            "name": "Szántód",
            "Megyekód": "SO",
            "Lat": "46.8667",
            "Long": "17.9",
            "KSH kód": "34236",
            "Jogállás": "6",
            "Terület": "760",
            "Népesség": "354",
            "Lakások": "259"
        },
        {
            "Ir. szám": "8623",
            "name": "Balatonföldvár",
            "Megyekód": "SO",
            "Lat": "46.85",
            "Long": "17.8833",
            "KSH kód": "07117",
            "Jogállás": "4",
            "Terület": "1532",
            "Népesség": "2071",
            "Lakások": "1166"
        },
        {
            "Ir. szám": "8624",
            "name": "Balatonszárszó",
            "Megyekód": "SO",
            "Lat": "46.8333",
            "Long": "17.8333",
            "KSH kód": "24907",
            "Jogállás": "5",
            "Terület": "3013",
            "Népesség": "1903",
            "Lakások": "918"
        },
        {
            "Ir. szám": "8625",
            "name": "Szólád",
            "Megyekód": "SO",
            "Lat": "46.7833",
            "Long": "17.85",
            "KSH kód": "15194",
            "Jogállás": "6",
            "Terület": "1998",
            "Népesség": "583",
            "Lakások": "300"
        },
        {
            "Ir. szám": "8626",
            "name": "Teleki",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.8333",
            "KSH kód": "23968",
            "Jogállás": "6",
            "Terület": "805",
            "Népesség": "202",
            "Lakások": "85"
        },
        {
            "Ir. szám": "8627",
            "name": "Kötcse",
            "Megyekód": "SO",
            "Lat": "46.75",
            "Long": "17.8667",
            "KSH kód": "18148",
            "Jogállás": "6",
            "Terület": "1954",
            "Népesség": "496",
            "Lakások": "236"
        },
        {
            "Ir. szám": "8628",
            "name": "Nagycsepely",
            "Megyekód": "SO",
            "Lat": "46.75",
            "Long": "17.8333",
            "KSH kód": "20598",
            "Jogállás": "6",
            "Terület": "1980",
            "Népesség": "367",
            "Lakások": "168"
        },
        {
            "Ir. szám": "8630",
            "name": "Balatonboglár",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.6667",
            "KSH kód": "33853",
            "Jogállás": "4",
            "Terület": "3204",
            "Népesség": "5920",
            "Lakások": "2784"
        },
        {
            "Ir. szám": "8631",
            "name": "Balatonboglár",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.6667",
            "KSH kód": "33853",
            "Jogállás": "4",
            "Terület": "3204",
            "Népesség": "5920",
            "Lakások": "2784"
        },
        {
            "Ir. szám": "8634",
            "name": "Balatonboglár",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.6667",
            "KSH kód": "33853",
            "Jogállás": "4",
            "Terület": "3204",
            "Népesség": "5920",
            "Lakások": "2784"
        },
        {
            "Ir. szám": "8635",
            "name": "Ordacsehi",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "17.6167",
            "KSH kód": "16823",
            "Jogállás": "6",
            "Terület": "2256",
            "Népesség": "766",
            "Lakások": "342"
        },
        {
            "Ir. szám": "8636",
            "name": "Balatonszemes",
            "Megyekód": "SO",
            "Lat": "46.8167",
            "Long": "17.7833",
            "KSH kód": "22822",
            "Jogállás": "6",
            "Terület": "3602",
            "Népesség": "1782",
            "Lakások": "716"
        },
        {
            "Ir. szám": "8637",
            "name": "Balatonőszöd",
            "Megyekód": "SO",
            "Lat": "46.8",
            "Long": "17.8",
            "KSH kód": "11916",
            "Jogállás": "6",
            "Terület": "1508",
            "Népesség": "531",
            "Lakások": "334"
        },
        {
            "Ir. szám": "8638",
            "name": "Balatonlelle",
            "Megyekód": "SO",
            "Lat": "46.7833",
            "Long": "17.6833",
            "KSH kód": "33862",
            "Jogállás": "4",
            "Terület": "4323",
            "Népesség": "4911",
            "Lakások": "2297"
        },
        {
            "Ir. szám": "8640",
            "name": "Fonyód",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "17.55",
            "KSH kód": "14632",
            "Jogállás": "4",
            "Terület": "5355",
            "Népesség": "4965",
            "Lakások": "2580"
        },
        {
            "Ir. szám": "8641",
            "name": "Birján",
            "Megyekód": "SO",
            "Lat": "46.7111",
            "Long": "17.4194",
            "KSH kód": "05139",
            "Jogállás": "6",
            "Terület": "907",
            "Népesség": "492",
            "Lakások": "198"
        },
        {
            "Ir. szám": "8642",
            "name": "Birján",
            "Megyekód": "SO",
            "Lat": "46.7111",
            "Long": "17.4194",
            "KSH kód": "05139",
            "Jogállás": "6",
            "Terület": "907",
            "Népesség": "492",
            "Lakások": "198"
        },
        {
            "Ir. szám": "8644",
            "name": "Birján",
            "Megyekód": "SO",
            "Lat": "46.7111",
            "Long": "17.4194",
            "KSH kód": "05139",
            "Jogállás": "6",
            "Terület": "907",
            "Népesség": "492",
            "Lakások": "198"
        },
        {
            "Ir. szám": "8646",
            "name": "Balatonfenyves",
            "Megyekód": "SO",
            "Lat": "46.7167",
            "Long": "17.5",
            "KSH kód": "20729",
            "Jogállás": "6",
            "Terület": "5193",
            "Népesség": "1856",
            "Lakások": "1076"
        },
        {
            "Ir. szám": "8647",
            "name": "Balatonmáriafürdő",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "17.3833",
            "KSH kód": "14562",
            "Jogállás": "6",
            "Terület": "2677",
            "Népesség": "646",
            "Lakások": "390"
        },
        {
            "Ir. szám": "8647",
            "name": "Balatonkeresztúr",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "17.3833",
            "KSH kód": "07375",
            "Jogállás": "6",
            "Terület": "2106",
            "Népesség": "1486",
            "Lakások": "688"
        },
        {
            "Ir. szám": "8648",
            "name": "Balatonkeresztúr",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "17.3833",
            "KSH kód": "07375",
            "Jogállás": "6",
            "Terület": "2106",
            "Népesség": "1486",
            "Lakások": "688"
        },
        {
            "Ir. szám": "8649",
            "name": "Balatonberény",
            "Megyekód": "SO",
            "Lat": "46.7167",
            "Long": "17.3167",
            "KSH kód": "27377",
            "Jogállás": "6",
            "Terület": "2609",
            "Népesség": "1155",
            "Lakások": "672"
        },
        {
            "Ir. szám": "8651",
            "name": "Balatonszabadi",
            "Megyekód": "SO",
            "Lat": "46.8833",
            "Long": "18.1333",
            "KSH kód": "16601",
            "Jogállás": "6",
            "Terület": "4134",
            "Népesség": "3022",
            "Lakások": "1139"
        },
        {
            "Ir. szám": "8652",
            "name": "Siójut",
            "Megyekód": "SO",
            "Lat": "46.8833",
            "Long": "18.1333",
            "KSH kód": "23092",
            "Jogállás": "6",
            "Terület": "1073",
            "Népesség": "593",
            "Lakások": "234"
        },
        {
            "Ir. szám": "8653",
            "name": "Ádánd",
            "Megyekód": "SO",
            "Lat": "46.8667",
            "Long": "18.1667",
            "KSH kód": "06080",
            "Jogállás": "6",
            "Terület": "2952",
            "Népesség": "2294",
            "Lakások": "865"
        },
        {
            "Ir. szám": "8654",
            "name": "Ságvár",
            "Megyekód": "SO",
            "Lat": "46.8333",
            "Long": "18.1167",
            "KSH kód": "14942",
            "Jogállás": "6",
            "Terület": "3844",
            "Népesség": "1905",
            "Lakások": "695"
        },
        {
            "Ir. szám": "8655",
            "name": "Som",
            "Megyekód": "SO",
            "Lat": "46.8",
            "Long": "18.15",
            "KSH kód": "04127",
            "Jogállás": "6",
            "Terület": "2335",
            "Népesség": "688",
            "Lakások": "266"
        },
        {
            "Ir. szám": "8656",
            "name": "Nagyberény",
            "Megyekód": "SO",
            "Lat": "46.8",
            "Long": "18.1667",
            "KSH kód": "29063",
            "Jogállás": "6",
            "Terület": "2314",
            "Népesség": "1357",
            "Lakások": "603"
        },
        {
            "Ir. szám": "8658",
            "name": "Bábonymegyer",
            "Megyekód": "SO",
            "Lat": "46.75",
            "Long": "18.1167",
            "KSH kód": "28316",
            "Jogállás": "6",
            "Terület": "2193",
            "Népesség": "820",
            "Lakások": "366"
        },
        {
            "Ir. szám": "8660",
            "name": "Tab",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "18.0333",
            "KSH kód": "08590",
            "Jogállás": "4",
            "Terület": "2586",
            "Népesség": "4504",
            "Lakások": "1931"
        },
        {
            "Ir. szám": "8660",
            "name": "Zala",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "18",
            "KSH kód": "11466",
            "Jogállás": "6",
            "Terület": "924",
            "Népesség": "253",
            "Lakások": "133"
        },
        {
            "Ir. szám": "8660",
            "name": "Torvaj",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "18.05",
            "KSH kód": "10153",
            "Jogállás": "6",
            "Terület": "1142",
            "Népesség": "273",
            "Lakások": "150"
        },
        {
            "Ir. szám": "8660",
            "name": "Sérsekszőlős",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "18.0167",
            "KSH kód": "32133",
            "Jogállás": "6",
            "Terület": "665",
            "Népesség": "150",
            "Lakások": "71"
        },
        {
            "Ir. szám": "8660",
            "name": "Lulla",
            "Megyekód": "SO",
            "Lat": "46.7833",
            "Long": "18.0333",
            "KSH kód": "10010",
            "Jogállás": "6",
            "Terület": "1039",
            "Népesség": "251",
            "Lakások": "94"
        },
        {
            "Ir. szám": "8666",
            "name": "Bedegkér",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "18.0667",
            "KSH kód": "20710",
            "Jogállás": "6",
            "Terület": "2600",
            "Népesség": "457",
            "Lakások": "248"
        },
        {
            "Ir. szám": "8666",
            "name": "Somogyegres",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "18.0333",
            "KSH kód": "20057",
            "Jogállás": "6",
            "Terület": "1081",
            "Népesség": "195",
            "Lakások": "132"
        },
        {
            "Ir. szám": "8667",
            "name": "Kánya",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "18.0667",
            "KSH kód": "05272",
            "Jogállás": "6",
            "Terület": "1452",
            "Népesség": "437",
            "Lakások": "224"
        },
        {
            "Ir. szám": "8668",
            "name": "Tengőd",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "18.1",
            "KSH kód": "32416",
            "Jogállás": "6",
            "Terület": "3021",
            "Népesség": "509",
            "Lakások": "273"
        },
        {
            "Ir. szám": "8669",
            "name": "Miklósi",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "18",
            "KSH kód": "33376",
            "Jogállás": "6",
            "Terület": "1047",
            "Népesség": "234",
            "Lakások": "124"
        },
        {
            "Ir. szám": "8671",
            "name": "Kapoly",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "17.9667",
            "KSH kód": "33394",
            "Jogállás": "6",
            "Terület": "2237",
            "Népesség": "699",
            "Lakások": "345"
        },
        {
            "Ir. szám": "8672",
            "name": "Zics",
            "Megyekód": "SO",
            "Lat": "46.6833",
            "Long": "17.9833",
            "KSH kód": "33057",
            "Jogállás": "6",
            "Terület": "1501",
            "Népesség": "365",
            "Lakások": "193"
        },
        {
            "Ir. szám": "8673",
            "name": "Somogymeggyes",
            "Megyekód": "SO",
            "Lat": "46.7167",
            "Long": "17.9167",
            "KSH kód": "12876",
            "Jogállás": "6",
            "Terület": "1565",
            "Népesség": "539",
            "Lakások": "241"
        },
        {
            "Ir. szám": "8674",
            "name": "Nágocs",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "17.9667",
            "KSH kód": "15909",
            "Jogállás": "6",
            "Terület": "2226",
            "Népesség": "664",
            "Lakások": "248"
        },
        {
            "Ir. szám": "8675",
            "name": "Andocs",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.9333",
            "KSH kód": "28714",
            "Jogállás": "6",
            "Terület": "4327",
            "Népesség": "1117",
            "Lakások": "476"
        },
        {
            "Ir. szám": "8676",
            "name": "Karád",
            "Megyekód": "SO",
            "Lat": "46.6833",
            "Long": "17.85",
            "KSH kód": "05263",
            "Jogállás": "6",
            "Terület": "5238",
            "Népesség": "1549",
            "Lakások": "847"
        },
        {
            "Ir. szám": "8681",
            "name": "Visz",
            "Megyekód": "SO",
            "Lat": "46.7333",
            "Long": "17.7833",
            "KSH kód": "06877",
            "Jogállás": "6",
            "Terület": "602",
            "Népesség": "200",
            "Lakások": "103"
        },
        {
            "Ir. szám": "8681",
            "name": "Látrány",
            "Megyekód": "SO",
            "Lat": "46.75",
            "Long": "17.75",
            "KSH kód": "14863",
            "Jogállás": "6",
            "Terület": "2231",
            "Népesség": "1350",
            "Lakások": "577"
        },
        {
            "Ir. szám": "8683",
            "name": "Somogytúr",
            "Megyekód": "SO",
            "Lat": "46.7",
            "Long": "17.7667",
            "KSH kód": "32601",
            "Jogállás": "6",
            "Terület": "3530",
            "Népesség": "438",
            "Lakások": "195"
        },
        {
            "Ir. szám": "8684",
            "name": "Somogybabod",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "17.7833",
            "KSH kód": "32470",
            "Jogállás": "6",
            "Terület": "1058",
            "Népesség": "501",
            "Lakások": "239"
        },
        {
            "Ir. szám": "8685",
            "name": "Gamás",
            "Megyekód": "SO",
            "Lat": "46.6167",
            "Long": "17.7667",
            "KSH kód": "06451",
            "Jogállás": "6",
            "Terület": "4292",
            "Népesség": "758",
            "Lakások": "387"
        },
        {
            "Ir. szám": "8691",
            "name": "Gamás",
            "Megyekód": "SO",
            "Lat": "46.6167",
            "Long": "17.7667",
            "KSH kód": "06451",
            "Jogállás": "6",
            "Terület": "4292",
            "Népesség": "758",
            "Lakások": "387"
        },
        {
            "Ir. szám": "8692",
            "name": "Gyugy",
            "Megyekód": "SO",
            "Lat": "46.6833",
            "Long": "17.6833",
            "KSH kód": "23904",
            "Jogállás": "6",
            "Terület": "1531",
            "Népesség": "277",
            "Lakások": "139"
        },
        {
            "Ir. szám": "8692",
            "name": "Szőlősgyörök",
            "Megyekód": "SO",
            "Lat": "46.7167",
            "Long": "17.6833",
            "KSH kód": "11101",
            "Jogállás": "6",
            "Terület": "1863",
            "Népesség": "1218",
            "Lakások": "486"
        },
        {
            "Ir. szám": "8693",
            "name": "Kisberény",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.6667",
            "KSH kód": "30827",
            "Jogállás": "6",
            "Terület": "746",
            "Népesség": "210",
            "Lakások": "81"
        },
        {
            "Ir. szám": "8693",
            "name": "Lengyeltóti",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "17.65",
            "KSH kód": "26675",
            "Jogállás": "4",
            "Terület": "3957",
            "Népesség": "3334",
            "Lakások": "1239"
        },
        {
            "Ir. szám": "8694",
            "name": "Hács",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.6833",
            "KSH kód": "18634",
            "Jogállás": "6",
            "Terület": "2128",
            "Népesség": "388",
            "Lakások": "193"
        },
        {
            "Ir. szám": "8695",
            "name": "Buzsák",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.5833",
            "KSH kód": "17358",
            "Jogállás": "6",
            "Terület": "5968",
            "Népesség": "1328",
            "Lakások": "706"
        },
        {
            "Ir. szám": "8696",
            "name": "Táska",
            "Megyekód": "SO",
            "Lat": "46.6167",
            "Long": "17.5333",
            "KSH kód": "13693",
            "Jogállás": "6",
            "Terület": "2598",
            "Népesség": "410",
            "Lakások": "191"
        },
        {
            "Ir. szám": "8697",
            "name": "Öreglak",
            "Megyekód": "SO",
            "Lat": "46.6",
            "Long": "17.6167",
            "KSH kód": "07056",
            "Jogállás": "6",
            "Terület": "2072",
            "Népesség": "1641",
            "Lakások": "587"
        },
        {
            "Ir. szám": "8698",
            "name": "Pamuk",
            "Megyekód": "SO",
            "Lat": "46.55",
            "Long": "17.65",
            "KSH kód": "26392",
            "Jogállás": "6",
            "Terület": "1196",
            "Népesség": "246",
            "Lakások": "127"
        },
        {
            "Ir. szám": "8698",
            "name": "Somogyvár",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.6667",
            "KSH kód": "19442",
            "Jogállás": "6",
            "Terület": "5299",
            "Népesség": "1911",
            "Lakások": "725"
        },
        {
            "Ir. szám": "8699",
            "name": "Somogyvámos",
            "Megyekód": "SO",
            "Lat": "46.5667",
            "Long": "17.6833",
            "KSH kód": "21856",
            "Jogállás": "6",
            "Terület": "2502",
            "Népesség": "757",
            "Lakások": "322"
        },
        {
            "Ir. szám": "8700",
            "name": "Csömend",
            "Megyekód": "SO",
            "Lat": "46.5667",
            "Long": "17.5",
            "KSH kód": "27270",
            "Jogállás": "6",
            "Terület": "866",
            "Népesség": "302",
            "Lakások": "131"
        },
        {
            "Ir. szám": "8700",
            "name": "Marcali",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.4167",
            "KSH kód": "18500",
            "Jogállás": "4",
            "Terület": "10440",
            "Népesség": "11838",
            "Lakások": "4975"
        },
        {
            "Ir. szám": "8701",
            "name": "Csömend",
            "Megyekód": "SO",
            "Lat": "46.5667",
            "Long": "17.5",
            "KSH kód": "27270",
            "Jogállás": "6",
            "Terület": "866",
            "Népesség": "302",
            "Lakások": "131"
        },
        {
            "Ir. szám": "8705",
            "name": "Somogyszentpál",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.4833",
            "KSH kód": "06600",
            "Jogállás": "6",
            "Terület": "2896",
            "Népesség": "757",
            "Lakások": "374"
        },
        {
            "Ir. szám": "8706",
            "name": "Nikla",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.5167",
            "KSH kód": "10959",
            "Jogállás": "6",
            "Terület": "2335",
            "Népesség": "709",
            "Lakások": "323"
        },
        {
            "Ir. szám": "8707",
            "name": "Pusztakovácsi",
            "Megyekód": "SO",
            "Lat": "46.5333",
            "Long": "17.5833",
            "KSH kód": "19026",
            "Jogállás": "6",
            "Terület": "4338",
            "Népesség": "875",
            "Lakások": "349"
        },
        {
            "Ir. szám": "8707",
            "name": "Libickozma",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.5333",
            "KSH kód": "32355",
            "Jogállás": "6",
            "Terület": "2297",
            "Népesség": "39",
            "Lakások": "50"
        },
        {
            "Ir. szám": "8708",
            "name": "Somogyfajsz",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.5667",
            "KSH kód": "18078",
            "Jogállás": "6",
            "Terület": "1826",
            "Népesség": "533",
            "Lakások": "219"
        },
        {
            "Ir. szám": "8709",
            "name": "Somogyfajsz",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.5667",
            "KSH kód": "18078",
            "Jogállás": "6",
            "Terület": "1826",
            "Népesség": "533",
            "Lakások": "219"
        },
        {
            "Ir. szám": "8710",
            "name": "Balatonszentgyörgy",
            "Megyekód": "SO",
            "Lat": "46.6833",
            "Long": "17.3",
            "KSH kód": "21324",
            "Jogállás": "6",
            "Terület": "2368",
            "Népesség": "1610",
            "Lakások": "695"
        },
        {
            "Ir. szám": "8711",
            "name": "Vörs",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "17.2667",
            "KSH kód": "09645",
            "Jogállás": "6",
            "Terület": "2265",
            "Népesség": "450",
            "Lakások": "276"
        },
        {
            "Ir. szám": "8712",
            "name": "Balatonújlak",
            "Megyekód": "SO",
            "Lat": "46.6667",
            "Long": "17.3833",
            "KSH kód": "16470",
            "Jogállás": "6",
            "Terület": "1081",
            "Népesség": "507",
            "Lakások": "261"
        },
        {
            "Ir. szám": "8713",
            "name": "Kéthely",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.4",
            "KSH kód": "11174",
            "Jogállás": "6",
            "Terület": "4906",
            "Népesség": "2370",
            "Lakások": "875"
        },
        {
            "Ir. szám": "8714",
            "name": "Kéthely",
            "Megyekód": "SO",
            "Lat": "46.65",
            "Long": "17.4",
            "KSH kód": "11174",
            "Jogállás": "6",
            "Terület": "4906",
            "Népesség": "2370",
            "Lakások": "875"
        },
        {
            "Ir. szám": "8714",
            "name": "Kelevíz",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.4167",
            "KSH kód": "17446",
            "Jogállás": "6",
            "Terület": "491",
            "Népesség": "340",
            "Lakások": "128"
        },
        {
            "Ir. szám": "8716",
            "name": "Gadány",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.4",
            "KSH kód": "26222",
            "Jogállás": "6",
            "Terület": "1970",
            "Népesség": "387",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8716",
            "name": "Mesztegnyő",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.4333",
            "KSH kód": "16106",
            "Jogállás": "6",
            "Terület": "4471",
            "Népesség": "1406",
            "Lakások": "557"
        },
        {
            "Ir. szám": "8716",
            "name": "Hosszúvíz",
            "Megyekód": "SO",
            "Lat": "46.5167",
            "Long": "17.45",
            "KSH kód": "30775",
            "Jogállás": "6",
            "Terület": "710",
            "Népesség": "43",
            "Lakások": "35"
        },
        {
            "Ir. szám": "8717",
            "name": "Szenyér",
            "Megyekód": "SO",
            "Lat": "46.4667",
            "Long": "17.3833",
            "KSH kód": "30146",
            "Jogállás": "6",
            "Terület": "2019",
            "Népesség": "311",
            "Lakások": "147"
        },
        {
            "Ir. szám": "8717",
            "name": "Nemeskisfalud",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "17.3667",
            "KSH kód": "25511",
            "Jogállás": "6",
            "Terület": "282",
            "Népesség": "103",
            "Lakások": "46"
        },
        {
            "Ir. szám": "8718",
            "name": "Tapsony",
            "Megyekód": "SO",
            "Lat": "46.4667",
            "Long": "17.3333",
            "KSH kód": "24615",
            "Jogállás": "6",
            "Terület": "3161",
            "Népesség": "697",
            "Lakások": "402"
        },
        {
            "Ir. szám": "8719",
            "name": "Böhönye",
            "Megyekód": "SO",
            "Lat": "46.4",
            "Long": "17.4",
            "KSH kód": "26532",
            "Jogállás": "6",
            "Terület": "6417",
            "Népesség": "2383",
            "Lakások": "948"
        },
        {
            "Ir. szám": "8721",
            "name": "Vése",
            "Megyekód": "SO",
            "Lat": "46.4167",
            "Long": "17.3",
            "KSH kód": "08183",
            "Jogállás": "6",
            "Terület": "4292",
            "Népesség": "778",
            "Lakások": "337"
        },
        {
            "Ir. szám": "8722",
            "name": "Nemesdéd",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "17.25",
            "KSH kód": "07913",
            "Jogállás": "6",
            "Terület": "2661",
            "Népesség": "718",
            "Lakások": "325"
        },
        {
            "Ir. szám": "8723",
            "name": "Varászló",
            "Megyekód": "SO",
            "Lat": "46.4333",
            "Long": "17.2167",
            "KSH kód": "09274",
            "Jogállás": "6",
            "Terület": "1276",
            "Népesség": "146",
            "Lakások": "80"
        },
        {
            "Ir. szám": "8724",
            "name": "Inke",
            "Megyekód": "SO",
            "Lat": "46.4",
            "Long": "17.2",
            "KSH kód": "26301",
            "Jogállás": "6",
            "Terület": "5176",
            "Népesség": "1277",
            "Lakások": "446"
        },
        {
            "Ir. szám": "8725",
            "name": "Iharosberény",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.1167",
            "KSH kód": "27784",
            "Jogállás": "6",
            "Terület": "4966",
            "Népesség": "1272",
            "Lakások": "477"
        },
        {
            "Ir. szám": "8726",
            "name": "Somogycsicsó",
            "Megyekód": "SO",
            "Lat": "46.3167",
            "Long": "17.1333",
            "KSH kód": "11484",
            "Jogállás": "6",
            "Terület": "1238",
            "Népesség": "165",
            "Lakások": "98"
        },
        {
            "Ir. szám": "8726",
            "name": "Iharos",
            "Megyekód": "SO",
            "Lat": "46.3333",
            "Long": "17.1",
            "KSH kód": "19619",
            "Jogállás": "6",
            "Terület": "2268",
            "Népesség": "477",
            "Lakások": "208"
        },
        {
            "Ir. szám": "8728",
            "name": "Pogányszentpéter",
            "Megyekód": "SO",
            "Lat": "46.3833",
            "Long": "17.0667",
            "KSH kód": "27553",
            "Jogállás": "6",
            "Terület": "1315",
            "Népesség": "497",
            "Lakások": "169"
        },
        {
            "Ir. szám": "8731",
            "name": "Hollád",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.3167",
            "KSH kód": "06211",
            "Jogállás": "6",
            "Terület": "820",
            "Népesség": "235",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8731",
            "name": "Tikos",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.3",
            "KSH kód": "17844",
            "Jogállás": "6",
            "Terület": "335",
            "Népesség": "127",
            "Lakások": "55"
        },
        {
            "Ir. szám": "8732",
            "name": "Főnyed",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.2667",
            "KSH kód": "22707",
            "Jogállás": "6",
            "Terület": "573",
            "Népesség": "74",
            "Lakások": "53"
        },
        {
            "Ir. szám": "8732",
            "name": "Sávoly",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.2667",
            "KSH kód": "02051",
            "Jogállás": "6",
            "Terület": "2684",
            "Népesség": "513",
            "Lakások": "229"
        },
        {
            "Ir. szám": "8732",
            "name": "Szegerdő",
            "Megyekód": "SO",
            "Lat": "46.6333",
            "Long": "17.2833",
            "KSH kód": "18607",
            "Jogállás": "6",
            "Terület": "546",
            "Népesség": "226",
            "Lakások": "107"
        },
        {
            "Ir. szám": "8733",
            "name": "Somogysámson",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.3",
            "KSH kód": "15626",
            "Jogállás": "6",
            "Terület": "2177",
            "Népesség": "776",
            "Lakások": "284"
        },
        {
            "Ir. szám": "8734",
            "name": "Somogyzsitfa",
            "Megyekód": "SO",
            "Lat": "46.5667",
            "Long": "17.3",
            "KSH kód": "04835",
            "Jogállás": "6",
            "Terület": "2722",
            "Népesség": "637",
            "Lakások": "312"
        },
        {
            "Ir. szám": "8735",
            "name": "Csákány",
            "Megyekód": "SO",
            "Lat": "46.5333",
            "Long": "17.2833",
            "KSH kód": "03799",
            "Jogállás": "6",
            "Terület": "1609",
            "Népesség": "279",
            "Lakások": "191"
        },
        {
            "Ir. szám": "8736",
            "name": "Szőkedencs",
            "Megyekód": "SO",
            "Lat": "46.55",
            "Long": "17.25",
            "KSH kód": "05810",
            "Jogállás": "6",
            "Terület": "1869",
            "Népesség": "288",
            "Lakások": "185"
        },
        {
            "Ir. szám": "8737",
            "name": "Somogysimonyi",
            "Megyekód": "SO",
            "Lat": "46.4833",
            "Long": "17.2167",
            "KSH kód": "27535",
            "Jogállás": "6",
            "Terület": "1582",
            "Népesség": "88",
            "Lakások": "37"
        },
        {
            "Ir. szám": "8738",
            "name": "Nemesvid",
            "Megyekód": "SO",
            "Lat": "46.5",
            "Long": "17.2667",
            "KSH kód": "17561",
            "Jogállás": "6",
            "Terület": "1838",
            "Népesség": "724",
            "Lakások": "335"
        },
        {
            "Ir. szám": "8739",
            "name": "Nagyszakácsi",
            "Megyekód": "SO",
            "Lat": "46.4833",
            "Long": "17.3167",
            "KSH kód": "25520",
            "Jogállás": "6",
            "Terület": "2137",
            "Népesség": "472",
            "Lakások": "287"
        },
        {
            "Ir. szám": "8741",
            "name": "Zalaapáti",
            "Megyekód": "ZA",
            "Lat": "46.7333",
            "Long": "17.1167",
            "KSH kód": "17400",
            "Jogállás": "6",
            "Terület": "2342",
            "Népesség": "1653",
            "Lakások": "605"
        },
        {
            "Ir. szám": "8741",
            "name": "Bókaháza",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.1",
            "KSH kód": "19929",
            "Jogállás": "6",
            "Terület": "670",
            "Népesség": "313",
            "Lakások": "147"
        },
        {
            "Ir. szám": "8742",
            "name": "Esztergályhorváti",
            "Megyekód": "ZA",
            "Lat": "46.7",
            "Long": "17.1167",
            "KSH kód": "15769",
            "Jogállás": "6",
            "Terület": "1670",
            "Népesség": "462",
            "Lakások": "201"
        },
        {
            "Ir. szám": "8743",
            "name": "Zalaszabar",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "17.1167",
            "KSH kód": "22947",
            "Jogállás": "6",
            "Terület": "1695",
            "Népesség": "554",
            "Lakások": "269"
        },
        {
            "Ir. szám": "8744",
            "name": "Orosztony",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "17.0667",
            "KSH kód": "25052",
            "Jogállás": "6",
            "Terület": "1845",
            "Népesség": "478",
            "Lakások": "226"
        },
        {
            "Ir. szám": "8745",
            "name": "Kerecseny",
            "Megyekód": "ZA",
            "Lat": "46.6167",
            "Long": "17.05",
            "KSH kód": "31529",
            "Jogállás": "6",
            "Terület": "1283",
            "Népesség": "289",
            "Lakások": "166"
        },
        {
            "Ir. szám": "8746",
            "name": "Nagyrada",
            "Megyekód": "ZA",
            "Lat": "46.6167",
            "Long": "17.1167",
            "KSH kód": "16513",
            "Jogállás": "6",
            "Terület": "1285",
            "Népesség": "496",
            "Lakások": "204"
        },
        {
            "Ir. szám": "8747",
            "name": "Garabonc",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "17.1333",
            "KSH kód": "12946",
            "Jogállás": "6",
            "Terület": "1856",
            "Népesség": "739",
            "Lakások": "265"
        },
        {
            "Ir. szám": "8747",
            "name": "Zalamerenye",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "17.1",
            "KSH kód": "31617",
            "Jogállás": "6",
            "Terület": "1399",
            "Népesség": "165",
            "Lakások": "141"
        },
        {
            "Ir. szám": "8749",
            "name": "Zalakaros",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "17.1333",
            "KSH kód": "11785",
            "Jogállás": "4",
            "Terület": "1717",
            "Népesség": "1764",
            "Lakások": "928"
        },
        {
            "Ir. szám": "8751",
            "name": "Zalakomár",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "17.1833",
            "KSH kód": "10348",
            "Jogállás": "6",
            "Terület": "5488",
            "Népesség": "3076",
            "Lakások": "1049"
        },
        {
            "Ir. szám": "8752",
            "name": "Zalakomár",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "17.1833",
            "KSH kód": "10348",
            "Jogállás": "6",
            "Terület": "5488",
            "Népesség": "3076",
            "Lakások": "1049"
        },
        {
            "Ir. szám": "8753",
            "name": "Balatonmagyaród",
            "Megyekód": "ZA",
            "Lat": "46.6",
            "Long": "17.1833",
            "KSH kód": "26462",
            "Jogállás": "6",
            "Terület": "3153",
            "Népesség": "496",
            "Lakások": "259"
        },
        {
            "Ir. szám": "8754",
            "name": "Galambok",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "17.1333",
            "KSH kód": "12991",
            "Jogállás": "6",
            "Terület": "2591",
            "Népesség": "1277",
            "Lakások": "527"
        },
        {
            "Ir. szám": "8756",
            "name": "Kisrécse",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "17.0667",
            "KSH kód": "32726",
            "Jogállás": "6",
            "Terület": "342",
            "Népesség": "198",
            "Lakások": "103"
        },
        {
            "Ir. szám": "8756",
            "name": "Csapi",
            "Megyekód": "ZA",
            "Lat": "46.5333",
            "Long": "17.1",
            "KSH kód": "06132",
            "Jogállás": "6",
            "Terület": "1011",
            "Népesség": "181",
            "Lakások": "86"
        },
        {
            "Ir. szám": "8756",
            "name": "Zalasárszeg",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "17.0833",
            "KSH kód": "02972",
            "Jogállás": "6",
            "Terület": "304",
            "Népesség": "122",
            "Lakások": "43"
        },
        {
            "Ir. szám": "8756",
            "name": "Nagyrécse",
            "Megyekód": "ZA",
            "Lat": "46.4833",
            "Long": "17.05",
            "KSH kód": "14979",
            "Jogállás": "6",
            "Terület": "2699",
            "Népesség": "1107",
            "Lakások": "374"
        },
        {
            "Ir. szám": "8761",
            "name": "Zalaigrice",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "17.0167",
            "KSH kód": "16896",
            "Jogállás": "6",
            "Terület": "773",
            "Népesség": "115",
            "Lakások": "63"
        },
        {
            "Ir. szám": "8761",
            "name": "Pacsa",
            "Megyekód": "ZA",
            "Lat": "46.7167",
            "Long": "17.0167",
            "KSH kód": "31741",
            "Jogállás": "5",
            "Terület": "2270",
            "Népesség": "1831",
            "Lakások": "711"
        },
        {
            "Ir. szám": "8762",
            "name": "Gétye",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.0667",
            "KSH kód": "20039",
            "Jogállás": "6",
            "Terület": "666",
            "Népesség": "117",
            "Lakások": "94"
        },
        {
            "Ir. szám": "8762",
            "name": "Szentpéterúr",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "17.0333",
            "KSH kód": "11165",
            "Jogállás": "6",
            "Terület": "1596",
            "Népesség": "1054",
            "Lakások": "444"
        },
        {
            "Ir. szám": "8764",
            "name": "Dióskál",
            "Megyekód": "ZA",
            "Lat": "46.6667",
            "Long": "17.05",
            "KSH kód": "28778",
            "Jogállás": "6",
            "Terület": "1963",
            "Népesség": "492",
            "Lakások": "256"
        },
        {
            "Ir. szám": "8764",
            "name": "Zalaszentmárton",
            "Megyekód": "ZA",
            "Lat": "46.7",
            "Long": "17.0667",
            "KSH kód": "13091",
            "Jogállás": "6",
            "Terület": "498",
            "Népesség": "76",
            "Lakások": "39"
        },
        {
            "Ir. szám": "8765",
            "name": "Egeraracsa",
            "Megyekód": "ZA",
            "Lat": "46.6667",
            "Long": "17.0833",
            "KSH kód": "24262",
            "Jogállás": "6",
            "Terület": "703",
            "Népesség": "325",
            "Lakások": "169"
        },
        {
            "Ir. szám": "8767",
            "name": "Pötréte",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "16.95",
            "KSH kód": "24660",
            "Jogállás": "6",
            "Terület": "1412",
            "Népesség": "264",
            "Lakások": "147"
        },
        {
            "Ir. szám": "8767",
            "name": "Felsőrajk",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "17",
            "KSH kód": "21476",
            "Jogállás": "6",
            "Terület": "2055",
            "Népesség": "760",
            "Lakások": "327"
        },
        {
            "Ir. szám": "8767",
            "name": "Alsórajk",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "17",
            "KSH kód": "18829",
            "Jogállás": "6",
            "Terület": "845",
            "Népesség": "365",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8771",
            "name": "Hahót",
            "Megyekód": "ZA",
            "Lat": "46.6425",
            "Long": "16.9236",
            "KSH kód": "10269",
            "Jogállás": "6",
            "Terület": "3901",
            "Népesség": "1137",
            "Lakások": "494"
        },
        {
            "Ir. szám": "8772",
            "name": "Zalaszentbalázs",
            "Megyekód": "ZA",
            "Lat": "46.5878",
            "Long": "16.9186",
            "KSH kód": "18564",
            "Jogállás": "6",
            "Terület": "2099",
            "Népesség": "843",
            "Lakások": "349"
        },
        {
            "Ir. szám": "8772",
            "name": "Börzönce",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "16.9",
            "KSH kód": "21139",
            "Jogállás": "6",
            "Terület": "560",
            "Népesség": "63",
            "Lakások": "35"
        },
        {
            "Ir. szám": "8773",
            "name": "Pölöskefő",
            "Megyekód": "ZA",
            "Lat": "46.6",
            "Long": "16.95",
            "KSH kód": "31112",
            "Jogállás": "6",
            "Terület": "1232",
            "Népesség": "396",
            "Lakások": "158"
        },
        {
            "Ir. szám": "8773",
            "name": "Kacorlak",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.9667",
            "KSH kód": "22965",
            "Jogállás": "6",
            "Terület": "760",
            "Népesség": "230",
            "Lakások": "90"
        },
        {
            "Ir. szám": "8774",
            "name": "Kilimán",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "17",
            "KSH kód": "32267",
            "Jogállás": "6",
            "Terület": "572",
            "Népesség": "246",
            "Lakások": "102"
        },
        {
            "Ir. szám": "8774",
            "name": "Gelse",
            "Megyekód": "ZA",
            "Lat": "46.6",
            "Long": "17",
            "KSH kód": "12089",
            "Jogállás": "6",
            "Terület": "2245",
            "Népesség": "1080",
            "Lakások": "462"
        },
        {
            "Ir. szám": "8774",
            "name": "Gelsesziget",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "17",
            "KSH kód": "09089",
            "Jogállás": "6",
            "Terület": "498",
            "Népesség": "271",
            "Lakások": "96"
        },
        {
            "Ir. szám": "8776",
            "name": "Magyarszerdahely",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "16.9333",
            "KSH kód": "13064",
            "Jogállás": "6",
            "Terület": "1622",
            "Népesség": "519",
            "Lakások": "171"
        },
        {
            "Ir. szám": "8776",
            "name": "Bocska",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "16.9167",
            "KSH kód": "31291",
            "Jogállás": "6",
            "Terület": "738",
            "Népesség": "374",
            "Lakások": "143"
        },
        {
            "Ir. szám": "8776",
            "name": "Magyarszentmiklós",
            "Megyekód": "ZA",
            "Lat": "46.5344",
            "Long": "16.9383",
            "KSH kód": "02909",
            "Jogállás": "6",
            "Terület": "419",
            "Népesség": "277",
            "Lakások": "103"
        },
        {
            "Ir. szám": "8777",
            "name": "Fűzvölgy",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "16.95",
            "KSH kód": "16531",
            "Jogállás": "6",
            "Terület": "314",
            "Népesség": "145",
            "Lakások": "51"
        },
        {
            "Ir. szám": "8777",
            "name": "Homokkomárom",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "16.9167",
            "KSH kód": "24873",
            "Jogállás": "6",
            "Terület": "1670",
            "Népesség": "217",
            "Lakások": "79"
        },
        {
            "Ir. szám": "8777",
            "name": "Hosszúvölgy",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "16.9333",
            "KSH kód": "11208",
            "Jogállás": "6",
            "Terület": "360",
            "Népesség": "171",
            "Lakások": "78"
        },
        {
            "Ir. szám": "8778",
            "name": "Újudvar",
            "Megyekód": "ZA",
            "Lat": "46.5333",
            "Long": "17",
            "KSH kód": "32197",
            "Jogállás": "6",
            "Terület": "1631",
            "Népesség": "941",
            "Lakások": "373"
        },
        {
            "Ir. szám": "8782",
            "name": "Ligetfalva",
            "Megyekód": "ZA",
            "Lat": "46.8333",
            "Long": "17.0667",
            "KSH kód": "28200",
            "Jogállás": "6",
            "Terület": "473",
            "Népesség": "60",
            "Lakások": "35"
        },
        {
            "Ir. szám": "8782",
            "name": "Zalacsány",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "17.1167",
            "KSH kód": "04002",
            "Jogállás": "6",
            "Terület": "1607",
            "Népesség": "1005",
            "Lakások": "384"
        },
        {
            "Ir. szám": "8782",
            "name": "Tilaj",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "17.05",
            "KSH kód": "27951",
            "Jogállás": "6",
            "Terület": "816",
            "Népesség": "203",
            "Lakások": "97"
        },
        {
            "Ir. szám": "8784",
            "name": "Kehidakustány",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.1",
            "KSH kód": "32902",
            "Jogállás": "6",
            "Terület": "1975",
            "Népesség": "1159",
            "Lakások": "645"
        },
        {
            "Ir. szám": "8785",
            "name": "Kallósd",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "17.0667",
            "KSH kód": "05537",
            "Jogállás": "6",
            "Terület": "541",
            "Népesség": "90",
            "Lakások": "68"
        },
        {
            "Ir. szám": "8785",
            "name": "Kehidakustány",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.1",
            "KSH kód": "32902",
            "Jogállás": "6",
            "Terület": "1975",
            "Népesség": "1159",
            "Lakások": "645"
        },
        {
            "Ir. szám": "8788",
            "name": "Zalaszentlászló",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "17.1167",
            "KSH kód": "07232",
            "Jogállás": "6",
            "Terület": "1967",
            "Népesség": "858",
            "Lakások": "325"
        },
        {
            "Ir. szám": "8788",
            "name": "Sénye",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "17.1333",
            "KSH kód": "14085",
            "Jogállás": "6",
            "Terület": "304",
            "Népesség": "43",
            "Lakások": "33"
        },
        {
            "Ir. szám": "8789",
            "name": "Sénye",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "17.1333",
            "KSH kód": "14085",
            "Jogállás": "6",
            "Terület": "304",
            "Népesség": "43",
            "Lakások": "33"
        },
        {
            "Ir. szám": "8790",
            "name": "Zalaszentgrót",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0833",
            "KSH kód": "32522",
            "Jogállás": "4",
            "Terület": "8162",
            "Népesség": "7313",
            "Lakások": "3048"
        },
        {
            "Ir. szám": "8792",
            "name": "Zalavég",
            "Megyekód": "ZA",
            "Lat": "47",
            "Long": "17.0333",
            "KSH kód": "17057",
            "Jogállás": "6",
            "Terület": "1211",
            "Népesség": "416",
            "Lakások": "228"
        },
        {
            "Ir. szám": "8793",
            "name": "Zalavég",
            "Megyekód": "ZA",
            "Lat": "47",
            "Long": "17.0333",
            "KSH kód": "17057",
            "Jogállás": "6",
            "Terület": "1211",
            "Népesség": "416",
            "Lakások": "228"
        },
        {
            "Ir. szám": "8795",
            "name": "Zalavég",
            "Megyekód": "ZA",
            "Lat": "47",
            "Long": "17.0333",
            "KSH kód": "17057",
            "Jogállás": "6",
            "Terület": "1211",
            "Népesség": "416",
            "Lakások": "228"
        },
        {
            "Ir. szám": "8796",
            "name": "Türje",
            "Megyekód": "ZA",
            "Lat": "46.9833",
            "Long": "17.1",
            "KSH kód": "12609",
            "Jogállás": "6",
            "Terület": "3823",
            "Népesség": "1713",
            "Lakások": "690"
        },
        {
            "Ir. szám": "8797",
            "name": "Batyk",
            "Megyekód": "ZA",
            "Lat": "46.9833",
            "Long": "17.0333",
            "KSH kód": "24864",
            "Jogállás": "6",
            "Terület": "809",
            "Népesség": "407",
            "Lakások": "192"
        },
        {
            "Ir. szám": "8798",
            "name": "Zalabér",
            "Megyekód": "ZA",
            "Lat": "46.9667",
            "Long": "17.0333",
            "KSH kód": "24280",
            "Jogállás": "6",
            "Terület": "1278",
            "Népesség": "718",
            "Lakások": "334"
        },
        {
            "Ir. szám": "8799",
            "name": "Dötk",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0167",
            "KSH kód": "16009",
            "Jogállás": "6",
            "Terület": "172",
            "Népesség": "26",
            "Lakások": "22"
        },
        {
            "Ir. szám": "8799",
            "name": "Pakod",
            "Megyekód": "ZA",
            "Lat": "46.9667",
            "Long": "17",
            "KSH kód": "24271",
            "Jogállás": "6",
            "Terület": "1255",
            "Népesség": "982",
            "Lakások": "354"
        },
        {
            "Ir. szám": "8800",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.4618",
            "Long": "17.0102",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8801",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8802",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8803",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8804",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8808",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8809",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.5142",
            "Long": "16.9717",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "8821",
            "name": "Nagybakónak",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "17.05",
            "KSH kód": "12760",
            "Jogállás": "6",
            "Terület": "1794",
            "Népesség": "401",
            "Lakások": "226"
        },
        {
            "Ir. szám": "8822",
            "name": "Zalaújlak",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "17.0833",
            "KSH kód": "09380",
            "Jogállás": "6",
            "Terület": "964",
            "Népesség": "108",
            "Lakások": "81"
        },
        {
            "Ir. szám": "8824",
            "name": "Sand",
            "Megyekód": "ZA",
            "Lat": "46.4167",
            "Long": "17.1333",
            "KSH kód": "15556",
            "Jogállás": "6",
            "Terület": "748",
            "Népesség": "419",
            "Lakások": "197"
        },
        {
            "Ir. szám": "8825",
            "name": "Miháld",
            "Megyekód": "ZA",
            "Lat": "46.45",
            "Long": "17.1333",
            "KSH kód": "27526",
            "Jogállás": "6",
            "Terület": "2164",
            "Népesség": "847",
            "Lakások": "373"
        },
        {
            "Ir. szám": "8825",
            "name": "Pat",
            "Megyekód": "ZA",
            "Lat": "46.4333",
            "Long": "17.1833",
            "KSH kód": "29656",
            "Jogállás": "6",
            "Terület": "833",
            "Népesség": "228",
            "Lakások": "134"
        },
        {
            "Ir. szám": "8827",
            "name": "Zalaszentjakab",
            "Megyekód": "ZA",
            "Lat": "46.4833",
            "Long": "17.1333",
            "KSH kód": "18096",
            "Jogállás": "6",
            "Terület": "701",
            "Népesség": "375",
            "Lakások": "141"
        },
        {
            "Ir. szám": "8831",
            "name": "Zalaszentjakab",
            "Megyekód": "ZA",
            "Lat": "46.4833",
            "Long": "17.1333",
            "KSH kód": "18096",
            "Jogállás": "6",
            "Terület": "701",
            "Népesség": "375",
            "Lakások": "141"
        },
        {
            "Ir. szám": "8831",
            "name": "Liszó",
            "Megyekód": "ZA",
            "Lat": "46.3667",
            "Long": "17",
            "KSH kód": "25025",
            "Jogállás": "6",
            "Terület": "2591",
            "Népesség": "419",
            "Lakások": "166"
        },
        {
            "Ir. szám": "8834",
            "name": "Murakeresztúr",
            "Megyekód": "ZA",
            "Lat": "46.3522",
            "Long": "16.8781",
            "KSH kód": "25210",
            "Jogállás": "6",
            "Terület": "1171",
            "Népesség": "1780",
            "Lakások": "687"
        },
        {
            "Ir. szám": "8835",
            "name": "Fityeház",
            "Megyekód": "ZA",
            "Lat": "46.3667",
            "Long": "16.9167",
            "KSH kód": "19187",
            "Jogállás": "6",
            "Terület": "650",
            "Népesség": "667",
            "Lakások": "248"
        },
        {
            "Ir. szám": "8840",
            "name": "Csurgónagymarton",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17.0833",
            "KSH kód": "10603",
            "Jogállás": "6",
            "Terület": "1482",
            "Népesség": "192",
            "Lakások": "85"
        },
        {
            "Ir. szám": "8840",
            "name": "Csurgó",
            "Megyekód": "SO",
            "Lat": "46.2667",
            "Long": "17.1",
            "KSH kód": "21315",
            "Jogállás": "4",
            "Terület": "5942",
            "Népesség": "5290",
            "Lakások": "2109"
        },
        {
            "Ir. szám": "8849",
            "name": "Szenta",
            "Megyekód": "SO",
            "Lat": "46.25",
            "Long": "17.1833",
            "KSH kód": "11509",
            "Jogállás": "6",
            "Terület": "6447",
            "Népesség": "391",
            "Lakások": "172"
        },
        {
            "Ir. szám": "8851",
            "name": "Gyékényes",
            "Megyekód": "SO",
            "Lat": "46.2378",
            "Long": "17.0053",
            "KSH kód": "30960",
            "Jogállás": "6",
            "Terület": "3376",
            "Népesség": "1010",
            "Lakások": "469"
        },
        {
            "Ir. szám": "8852",
            "name": "Zákány",
            "Megyekód": "SO",
            "Lat": "46.2744",
            "Long": "16.9511",
            "KSH kód": "14623",
            "Jogállás": "6",
            "Terület": "909",
            "Népesség": "1044",
            "Lakások": "590"
        },
        {
            "Ir. szám": "8852",
            "name": "Gyékényes",
            "Megyekód": "SO",
            "Lat": "46.2378",
            "Long": "17.0053",
            "KSH kód": "30960",
            "Jogállás": "6",
            "Terület": "3376",
            "Népesség": "1010",
            "Lakások": "469"
        },
        {
            "Ir. szám": "8853",
            "name": "Zákányfalu",
            "Megyekód": "SO",
            "Lat": "46.2858",
            "Long": "16.9926",
            "KSH kód": "34403",
            "Jogállás": "6",
            "Terület": "670",
            "Népesség": "651",
            "Lakások": "243"
        },
        {
            "Ir. szám": "8854",
            "name": "Őrtilos",
            "Megyekód": "SO",
            "Lat": "46.2797",
            "Long": "16.9294",
            "KSH kód": "14012",
            "Jogállás": "6",
            "Terület": "2112",
            "Népesség": "585",
            "Lakások": "327"
        },
        {
            "Ir. szám": "8855",
            "name": "Belezna",
            "Megyekód": "ZA",
            "Lat": "46.3278",
            "Long": "16.94",
            "KSH kód": "09168",
            "Jogállás": "6",
            "Terület": "3093",
            "Népesség": "836",
            "Lakások": "285"
        },
        {
            "Ir. szám": "8856",
            "name": "Nemespátró",
            "Megyekód": "ZA",
            "Lat": "46.3167",
            "Long": "17.0167",
            "KSH kód": "19859",
            "Jogállás": "6",
            "Terület": "1225",
            "Népesség": "313",
            "Lakások": "166"
        },
        {
            "Ir. szám": "8856",
            "name": "Surd",
            "Megyekód": "ZA",
            "Lat": "46.3192",
            "Long": "16.9706",
            "KSH kód": "02404",
            "Jogállás": "6",
            "Terület": "2194",
            "Népesség": "635",
            "Lakások": "246"
        },
        {
            "Ir. szám": "8857",
            "name": "Nemespátró",
            "Megyekód": "ZA",
            "Lat": "46.3167",
            "Long": "17.0167",
            "KSH kód": "19859",
            "Jogállás": "6",
            "Terület": "1225",
            "Népesség": "313",
            "Lakások": "166"
        },
        {
            "Ir. szám": "8858",
            "name": "Somogybükkösd",
            "Megyekód": "SO",
            "Lat": "46.3",
            "Long": "17",
            "KSH kód": "25043",
            "Jogállás": "6",
            "Terület": "1180",
            "Népesség": "91",
            "Lakások": "79"
        },
        {
            "Ir. szám": "8858",
            "name": "Porrogszentkirály",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.05",
            "KSH kód": "13930",
            "Jogállás": "6",
            "Terület": "1365",
            "Népesség": "320",
            "Lakások": "160"
        },
        {
            "Ir. szám": "8858",
            "name": "Porrogszentpál",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.0167",
            "KSH kód": "28510",
            "Jogállás": "6",
            "Terület": "354",
            "Népesség": "95",
            "Lakások": "47"
        },
        {
            "Ir. szám": "8858",
            "name": "Porrog",
            "Megyekód": "SO",
            "Lat": "46.2833",
            "Long": "17.0333",
            "KSH kód": "11828",
            "Jogállás": "6",
            "Terület": "1402",
            "Népesség": "246",
            "Lakások": "135"
        },
        {
            "Ir. szám": "8861",
            "name": "Szepetnek",
            "Megyekód": "ZA",
            "Lat": "46.4308",
            "Long": "16.905",
            "KSH kód": "07700",
            "Jogállás": "6",
            "Terület": "3039",
            "Népesség": "1625",
            "Lakások": "552"
        },
        {
            "Ir. szám": "8862",
            "name": "Semjénháza",
            "Megyekód": "ZA",
            "Lat": "46.4",
            "Long": "16.85",
            "KSH kód": "30997",
            "Jogállás": "6",
            "Terület": "470",
            "Népesség": "649",
            "Lakások": "218"
        },
        {
            "Ir. szám": "8863",
            "name": "Molnári",
            "Megyekód": "ZA",
            "Lat": "46.3833",
            "Long": "16.8333",
            "KSH kód": "21014",
            "Jogállás": "6",
            "Terület": "1286",
            "Népesség": "716",
            "Lakások": "273"
        },
        {
            "Ir. szám": "8864",
            "name": "Tótszerdahely",
            "Megyekód": "ZA",
            "Lat": "46.4",
            "Long": "16.8",
            "KSH kód": "25113",
            "Jogállás": "6",
            "Terület": "1230",
            "Népesség": "1215",
            "Lakások": "446"
        },
        {
            "Ir. szám": "8865",
            "name": "Tótszentmárton",
            "Megyekód": "ZA",
            "Lat": "46.4167",
            "Long": "16.8167",
            "KSH kód": "16382",
            "Jogállás": "6",
            "Terület": "1017",
            "Népesség": "892",
            "Lakások": "332"
        },
        {
            "Ir. szám": "8866",
            "name": "Becsehely",
            "Megyekód": "ZA",
            "Lat": "46.4458",
            "Long": "16.7928",
            "KSH kód": "23144",
            "Jogállás": "6",
            "Terület": "3607",
            "Népesség": "2141",
            "Lakások": "756"
        },
        {
            "Ir. szám": "8866",
            "name": "Petrivente",
            "Megyekód": "ZA",
            "Lat": "46.4333",
            "Long": "16.85",
            "KSH kód": "30757",
            "Jogállás": "6",
            "Terület": "765",
            "Népesség": "384",
            "Lakások": "136"
        },
        {
            "Ir. szám": "8868",
            "name": "Kistolmács",
            "Megyekód": "ZA",
            "Lat": "46.4833",
            "Long": "16.75",
            "KSH kód": "20941",
            "Jogállás": "6",
            "Terület": "1220",
            "Népesség": "165",
            "Lakások": "98"
        },
        {
            "Ir. szám": "8868",
            "name": "Letenye",
            "Megyekód": "ZA",
            "Lat": "46.4286",
            "Long": "16.7306",
            "KSH kód": "12122",
            "Jogállás": "4",
            "Terület": "4172",
            "Népesség": "4260",
            "Lakások": "1621"
        },
        {
            "Ir. szám": "8868",
            "name": "Zajk",
            "Megyekód": "ZA",
            "Lat": "46.4833",
            "Long": "16.7167",
            "KSH kód": "24590",
            "Jogállás": "6",
            "Terület": "1239",
            "Népesség": "211",
            "Lakások": "95"
        },
        {
            "Ir. szám": "8868",
            "name": "Murarátka",
            "Megyekód": "ZA",
            "Lat": "46.4531",
            "Long": "16.685",
            "KSH kód": "07968",
            "Jogállás": "6",
            "Terület": "1204",
            "Népesség": "273",
            "Lakások": "134"
        },
        {
            "Ir. szám": "8872",
            "name": "Szentmargitfalva",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "16.6667",
            "KSH kód": "14225",
            "Jogállás": "6",
            "Terület": "312",
            "Népesség": "96",
            "Lakások": "73"
        },
        {
            "Ir. szám": "8872",
            "name": "Muraszemenye",
            "Megyekód": "ZA",
            "Lat": "46.4667",
            "Long": "16.6167",
            "KSH kód": "33987",
            "Jogállás": "6",
            "Terület": "1615",
            "Népesség": "653",
            "Lakások": "297"
        },
        {
            "Ir. szám": "8873",
            "name": "Csörnyeföld",
            "Megyekód": "ZA",
            "Lat": "46.5008",
            "Long": "16.6319",
            "KSH kód": "33978",
            "Jogállás": "6",
            "Terület": "2125",
            "Népesség": "467",
            "Lakások": "223"
        },
        {
            "Ir. szám": "8874",
            "name": "Kerkaszentkirály",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "16.5833",
            "KSH kód": "09575",
            "Jogállás": "6",
            "Terület": "811",
            "Népesség": "249",
            "Lakások": "127"
        },
        {
            "Ir. szám": "8874",
            "name": "Dobri",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "16.5833",
            "KSH kód": "25885",
            "Jogállás": "6",
            "Terület": "789",
            "Népesség": "169",
            "Lakások": "113"
        },
        {
            "Ir. szám": "8876",
            "name": "Tormafölde",
            "Megyekód": "ZA",
            "Lat": "46.5386",
            "Long": "16.5944",
            "KSH kód": "04969",
            "Jogállás": "6",
            "Terület": "1886",
            "Népesség": "365",
            "Lakások": "210"
        },
        {
            "Ir. szám": "8877",
            "name": "Tornyiszentmiklós",
            "Megyekód": "ZA",
            "Lat": "46.515",
            "Long": "16.5578",
            "KSH kód": "32638",
            "Jogállás": "6",
            "Terület": "1852",
            "Népesség": "654",
            "Lakások": "307"
        },
        {
            "Ir. szám": "8878",
            "name": "Lovászi",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "16.5667",
            "KSH kód": "28167",
            "Jogállás": "6",
            "Terület": "967",
            "Népesség": "1226",
            "Lakások": "590"
        },
        {
            "Ir. szám": "8879",
            "name": "Kerkateskánd",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "16.5833",
            "KSH kód": "32647",
            "Jogállás": "6",
            "Terület": "894",
            "Népesség": "168",
            "Lakások": "92"
        },
        {
            "Ir. szám": "8879",
            "name": "Szécsisziget",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.6",
            "KSH kód": "11554",
            "Jogállás": "6",
            "Terület": "620",
            "Népesség": "232",
            "Lakások": "104"
        },
        {
            "Ir. szám": "8881",
            "name": "Sormás",
            "Megyekód": "ZA",
            "Lat": "46.45",
            "Long": "16.9167",
            "KSH kód": "06567",
            "Jogállás": "6",
            "Terület": "1637",
            "Népesség": "904",
            "Lakások": "319"
        },
        {
            "Ir. szám": "8882",
            "name": "Eszteregnye",
            "Megyekód": "ZA",
            "Lat": "46.4667",
            "Long": "16.8833",
            "KSH kód": "06178",
            "Jogállás": "6",
            "Terület": "2009",
            "Népesség": "734",
            "Lakások": "285"
        },
        {
            "Ir. szám": "8883",
            "name": "Rigyác",
            "Megyekód": "ZA",
            "Lat": "46.4667",
            "Long": "16.8667",
            "KSH kód": "24138",
            "Jogállás": "6",
            "Terület": "1536",
            "Népesség": "448",
            "Lakások": "155"
        },
        {
            "Ir. szám": "8885",
            "name": "Borsfa",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "16.7833",
            "KSH kód": "10056",
            "Jogállás": "6",
            "Terület": "1183",
            "Népesség": "728",
            "Lakások": "287"
        },
        {
            "Ir. szám": "8885",
            "name": "Valkonya",
            "Megyekód": "ZA",
            "Lat": "46.5",
            "Long": "16.8167",
            "KSH kód": "26268",
            "Jogállás": "6",
            "Terület": "755",
            "Népesség": "64",
            "Lakások": "46"
        },
        {
            "Ir. szám": "8886",
            "name": "Oltárc",
            "Megyekód": "ZA",
            "Lat": "46.5272",
            "Long": "16.8372",
            "KSH kód": "25478",
            "Jogállás": "6",
            "Terület": "2953",
            "Népesség": "255",
            "Lakások": "148"
        },
        {
            "Ir. szám": "8887",
            "name": "Bázakerettye",
            "Megyekód": "ZA",
            "Lat": "46.5233",
            "Long": "16.7294",
            "KSH kód": "18698",
            "Jogállás": "6",
            "Terület": "803",
            "Népesség": "851",
            "Lakások": "392"
        },
        {
            "Ir. szám": "8887",
            "name": "Lasztonya",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.7167",
            "KSH kód": "03434",
            "Jogállás": "6",
            "Terület": "877",
            "Népesség": "97",
            "Lakások": "59"
        },
        {
            "Ir. szám": "8888",
            "name": "Lispeszentadorján",
            "Megyekód": "ZA",
            "Lat": "46.5333",
            "Long": "16.7",
            "KSH kód": "10889",
            "Jogállás": "6",
            "Terület": "914",
            "Népesség": "323",
            "Lakások": "177"
        },
        {
            "Ir. szám": "8888",
            "name": "Kiscsehi",
            "Megyekód": "ZA",
            "Lat": "46.5167",
            "Long": "16.6667",
            "KSH kód": "16249",
            "Jogállás": "6",
            "Terület": "1143",
            "Népesség": "179",
            "Lakások": "106"
        },
        {
            "Ir. szám": "8888",
            "name": "Maróc",
            "Megyekód": "ZA",
            "Lat": "46.55",
            "Long": "16.6667",
            "KSH kód": "02103",
            "Jogállás": "6",
            "Terület": "516",
            "Népesség": "86",
            "Lakások": "70"
        },
        {
            "Ir. szám": "8891",
            "name": "Bánokszentgyörgy",
            "Megyekód": "ZA",
            "Lat": "46.5456",
            "Long": "16.7872",
            "KSH kód": "08439",
            "Jogállás": "6",
            "Terület": "3254",
            "Népesség": "678",
            "Lakások": "298"
        },
        {
            "Ir. szám": "8891",
            "name": "Várfölde",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.7667",
            "KSH kód": "02529",
            "Jogállás": "6",
            "Terület": "1487",
            "Népesség": "209",
            "Lakások": "106"
        },
        {
            "Ir. szám": "8893",
            "name": "Bucsuta",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.85",
            "KSH kód": "07986",
            "Jogállás": "6",
            "Terület": "1619",
            "Népesség": "238",
            "Lakások": "111"
        },
        {
            "Ir. szám": "8893",
            "name": "Szentliszló",
            "Megyekód": "ZA",
            "Lat": "46.5756",
            "Long": "16.8239",
            "KSH kód": "08615",
            "Jogállás": "6",
            "Terület": "689",
            "Népesség": "320",
            "Lakások": "141"
        },
        {
            "Ir. szám": "8895",
            "name": "Pusztamagyaród",
            "Megyekód": "ZA",
            "Lat": "46.6",
            "Long": "16.8333",
            "KSH kód": "06530",
            "Jogállás": "6",
            "Terület": "1603",
            "Népesség": "569",
            "Lakások": "277"
        },
        {
            "Ir. szám": "8896",
            "name": "Pusztaszentlászló",
            "Megyekód": "ZA",
            "Lat": "46.6369",
            "Long": "16.8442",
            "KSH kód": "26639",
            "Jogállás": "6",
            "Terület": "1034",
            "Népesség": "636",
            "Lakások": "274"
        },
        {
            "Ir. szám": "8897",
            "name": "Söjtör",
            "Megyekód": "ZA",
            "Lat": "46.6672",
            "Long": "16.8583",
            "KSH kód": "19080",
            "Jogállás": "6",
            "Terület": "3616",
            "Népesség": "1561",
            "Lakások": "651"
        },
        {
            "Ir. szám": "8900",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8901",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8902",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8903",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8904",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8905",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8906",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8907",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8908",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8909",
            "name": "Zalaegerszeg",
            "Megyekód": "ZA",
            "Lat": "46.84",
            "Long": "16.8439",
            "KSH kód": "32054",
            "Jogállás": "1",
            "Terület": "9985",
            "Népesség": "61774",
            "Lakások": "25497"
        },
        {
            "Ir. szám": "8911",
            "name": "Kiskutas",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.8",
            "KSH kód": "20312",
            "Jogállás": "6",
            "Terület": "469",
            "Népesség": "183",
            "Lakások": "86"
        },
        {
            "Ir. szám": "8911",
            "name": "Nagykutas",
            "Megyekód": "ZA",
            "Lat": "46.9236",
            "Long": "16.8078",
            "KSH kód": "22178",
            "Jogállás": "6",
            "Terület": "845",
            "Népesség": "456",
            "Lakások": "180"
        },
        {
            "Ir. szám": "8912",
            "name": "Kispáli",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.8167",
            "KSH kód": "16081",
            "Jogállás": "6",
            "Terület": "451",
            "Népesség": "261",
            "Lakások": "113"
        },
        {
            "Ir. szám": "8912",
            "name": "Nagypáli",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.85",
            "KSH kód": "28468",
            "Jogállás": "6",
            "Terület": "634",
            "Népesség": "418",
            "Lakások": "165"
        },
        {
            "Ir. szám": "8913",
            "name": "Egervár",
            "Megyekód": "ZA",
            "Lat": "46.9336",
            "Long": "16.8553",
            "KSH kód": "33428",
            "Jogállás": "6",
            "Terület": "1029",
            "Népesség": "1048",
            "Lakások": "383"
        },
        {
            "Ir. szám": "8913",
            "name": "Lakhegy",
            "Megyekód": "ZA",
            "Lat": "46.9333",
            "Long": "16.8667",
            "KSH kód": "33792",
            "Jogállás": "6",
            "Terület": "858",
            "Népesség": "489",
            "Lakások": "204"
        },
        {
            "Ir. szám": "8913",
            "name": "Gősfa",
            "Megyekód": "ZA",
            "Lat": "46.9667",
            "Long": "16.8667",
            "KSH kód": "29771",
            "Jogállás": "6",
            "Terület": "1045",
            "Népesség": "319",
            "Lakások": "131"
        },
        {
            "Ir. szám": "8914",
            "name": "Vasboldogasszony",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "16.8833",
            "KSH kód": "10302",
            "Jogállás": "6",
            "Terület": "1175",
            "Népesség": "623",
            "Lakások": "216"
        },
        {
            "Ir. szám": "8915",
            "name": "Nemesrádó",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "17",
            "KSH kód": "18023",
            "Jogállás": "6",
            "Terület": "1402",
            "Népesség": "322",
            "Lakások": "208"
        },
        {
            "Ir. szám": "8917",
            "name": "Dobronhegy",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "16.75",
            "KSH kód": "32708",
            "Jogállás": "6",
            "Terület": "215",
            "Népesség": "154",
            "Lakások": "68"
        },
        {
            "Ir. szám": "8917",
            "name": "Pálfiszeg",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.7333",
            "KSH kód": "14720",
            "Jogállás": "6",
            "Terület": "559",
            "Népesség": "174",
            "Lakások": "70"
        },
        {
            "Ir. szám": "8917",
            "name": "Milejszeg",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "16.75",
            "KSH kód": "02662",
            "Jogállás": "6",
            "Terület": "908",
            "Népesség": "362",
            "Lakások": "162"
        },
        {
            "Ir. szám": "8918",
            "name": "Csonkahegyhát",
            "Megyekód": "ZA",
            "Lat": "46.7983",
            "Long": "16.7242",
            "KSH kód": "31149",
            "Jogállás": "6",
            "Terület": "828",
            "Népesség": "374",
            "Lakások": "140"
        },
        {
            "Ir. szám": "8918",
            "name": "Németfalu",
            "Megyekód": "ZA",
            "Lat": "46.8208",
            "Long": "16.6736",
            "KSH kód": "25414",
            "Jogállás": "6",
            "Terület": "867",
            "Népesség": "192",
            "Lakások": "70"
        },
        {
            "Ir. szám": "8919",
            "name": "Kustánszeg",
            "Megyekód": "ZA",
            "Lat": "46.7839",
            "Long": "16.6875",
            "KSH kód": "19479",
            "Jogállás": "6",
            "Terület": "1142",
            "Népesség": "531",
            "Lakások": "211"
        },
        {
            "Ir. szám": "8921",
            "name": "Zalaszentiván",
            "Megyekód": "ZA",
            "Lat": "46.8886",
            "Long": "16.8994",
            "KSH kód": "02608",
            "Jogállás": "6",
            "Terület": "1310",
            "Népesség": "1076",
            "Lakások": "407"
        },
        {
            "Ir. szám": "8921",
            "name": "Zalaszentlőrinc",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.8833",
            "KSH kód": "13301",
            "Jogállás": "6",
            "Terület": "489",
            "Népesség": "306",
            "Lakások": "129"
        },
        {
            "Ir. szám": "8921",
            "name": "Alibánfa",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "16.9333",
            "KSH kód": "02644",
            "Jogállás": "6",
            "Terület": "584",
            "Népesség": "484",
            "Lakások": "165"
        },
        {
            "Ir. szám": "8921",
            "name": "Pethőhenye",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "16.9167",
            "KSH kód": "05704",
            "Jogállás": "6",
            "Terület": "1142",
            "Népesség": "416",
            "Lakások": "192"
        },
        {
            "Ir. szám": "8923",
            "name": "Nemesapáti",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "16.95",
            "KSH kód": "06169",
            "Jogállás": "6",
            "Terület": "1037",
            "Népesség": "531",
            "Lakások": "217"
        },
        {
            "Ir. szám": "8924",
            "name": "Alsónemesapáti",
            "Megyekód": "ZA",
            "Lat": "46.8481",
            "Long": "16.935",
            "KSH kód": "19512",
            "Jogállás": "6",
            "Terület": "1451",
            "Népesség": "736",
            "Lakások": "287"
        },
        {
            "Ir. szám": "8925",
            "name": "Nemessándorháza",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.95",
            "KSH kód": "25609",
            "Jogállás": "6",
            "Terület": "1116",
            "Népesség": "253",
            "Lakások": "176"
        },
        {
            "Ir. szám": "8925",
            "name": "Búcsúszentlászló",
            "Megyekód": "ZA",
            "Lat": "46.7861",
            "Long": "16.9314",
            "KSH kód": "20613",
            "Jogállás": "6",
            "Terület": "154",
            "Népesség": "878",
            "Lakások": "289"
        },
        {
            "Ir. szám": "8925",
            "name": "Nemesszentandrás",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.95",
            "KSH kód": "08059",
            "Jogállás": "6",
            "Terület": "547",
            "Népesség": "292",
            "Lakások": "137"
        },
        {
            "Ir. szám": "8925",
            "name": "Kisbucsa",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "16.95",
            "KSH kód": "21379",
            "Jogállás": "6",
            "Terület": "880",
            "Népesség": "483",
            "Lakások": "192"
        },
        {
            "Ir. szám": "8925",
            "name": "Nemeshetés",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "16.9167",
            "KSH kód": "07658",
            "Jogállás": "6",
            "Terület": "661",
            "Népesség": "310",
            "Lakások": "194"
        },
        {
            "Ir. szám": "8929",
            "name": "Pölöske",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.9333",
            "KSH kód": "09867",
            "Jogállás": "6",
            "Terület": "4523",
            "Népesség": "910",
            "Lakások": "327"
        },
        {
            "Ir. szám": "8931",
            "name": "Vöckönd",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "16.95",
            "KSH kód": "08369",
            "Jogállás": "6",
            "Terület": "279",
            "Népesség": "85",
            "Lakások": "49"
        },
        {
            "Ir. szám": "8931",
            "name": "Kemendollár",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "16.9667",
            "KSH kód": "25353",
            "Jogállás": "6",
            "Terület": "1607",
            "Népesség": "529",
            "Lakások": "211"
        },
        {
            "Ir. szám": "8932",
            "name": "Gyűrűs",
            "Megyekód": "ZA",
            "Lat": "46.8833",
            "Long": "17",
            "KSH kód": "12539",
            "Jogállás": "6",
            "Terület": "419",
            "Népesség": "94",
            "Lakások": "54"
        },
        {
            "Ir. szám": "8932",
            "name": "Pókaszepetk",
            "Megyekód": "ZA",
            "Lat": "46.9233",
            "Long": "16.9683",
            "KSH kód": "21050",
            "Jogállás": "6",
            "Terület": "1869",
            "Népesség": "1008",
            "Lakások": "370"
        },
        {
            "Ir. szám": "8932",
            "name": "Zalaistvánd",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.9833",
            "KSH kód": "12496",
            "Jogállás": "6",
            "Terület": "1080",
            "Népesség": "371",
            "Lakások": "161"
        },
        {
            "Ir. szám": "8934",
            "name": "Bezeréd",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "17.0167",
            "KSH kód": "12830",
            "Jogállás": "6",
            "Terület": "1214",
            "Népesség": "158",
            "Lakások": "156"
        },
        {
            "Ir. szám": "8935",
            "name": "Orbányosfa",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "16.9833",
            "KSH kód": "20835",
            "Jogállás": "6",
            "Terület": "568",
            "Népesség": "142",
            "Lakások": "62"
        },
        {
            "Ir. szám": "8935",
            "name": "Almásháza",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.05",
            "KSH kód": "23384",
            "Jogállás": "6",
            "Terület": "498",
            "Népesség": "62",
            "Lakások": "24"
        },
        {
            "Ir. szám": "8935",
            "name": "Nagykapornak",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "17",
            "KSH kód": "20589",
            "Jogállás": "6",
            "Terület": "2441",
            "Népesség": "931",
            "Lakások": "391"
        },
        {
            "Ir. szám": "8935",
            "name": "Misefa",
            "Megyekód": "ZA",
            "Lat": "46.8",
            "Long": "16.9833",
            "KSH kód": "04756",
            "Jogállás": "6",
            "Terület": "651",
            "Népesség": "298",
            "Lakások": "120"
        },
        {
            "Ir. szám": "8935",
            "name": "Padár",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "17.0167",
            "KSH kód": "24776",
            "Jogállás": "6",
            "Terület": "1069",
            "Népesség": "142",
            "Lakások": "76"
        },
        {
            "Ir. szám": "8936",
            "name": "Zalaszentmihály",
            "Megyekód": "ZA",
            "Lat": "46.725",
            "Long": "16.9494",
            "KSH kód": "18449",
            "Jogállás": "6",
            "Terület": "2082",
            "Népesség": "1002",
            "Lakások": "472"
        },
        {
            "Ir. szám": "8943",
            "name": "Bocfölde",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.8333",
            "KSH kód": "17543",
            "Jogállás": "6",
            "Terület": "873",
            "Népesség": "1128",
            "Lakások": "401"
        },
        {
            "Ir. szám": "8943",
            "name": "Csatár",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.8833",
            "KSH kód": "23436",
            "Jogállás": "6",
            "Terület": "779",
            "Népesség": "561",
            "Lakások": "210"
        },
        {
            "Ir. szám": "8944",
            "name": "Sárhida",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.85",
            "KSH kód": "08101",
            "Jogállás": "6",
            "Terület": "509",
            "Népesség": "808",
            "Lakások": "280"
        },
        {
            "Ir. szám": "8945",
            "name": "Bak",
            "Megyekód": "ZA",
            "Lat": "46.7292",
            "Long": "16.8464",
            "KSH kód": "04738",
            "Jogállás": "6",
            "Terület": "2305",
            "Népesség": "1708",
            "Lakások": "611"
        },
        {
            "Ir. szám": "8946",
            "name": "Tófej",
            "Megyekód": "ZA",
            "Lat": "46.6703",
            "Long": "16.8056",
            "KSH kód": "05342",
            "Jogállás": "6",
            "Terület": "1532",
            "Népesség": "706",
            "Lakások": "292"
        },
        {
            "Ir. szám": "8946",
            "name": "Baktüttös",
            "Megyekód": "ZA",
            "Lat": "46.7",
            "Long": "16.8167",
            "KSH kód": "15097",
            "Jogállás": "6",
            "Terület": "971",
            "Népesség": "338",
            "Lakások": "143"
        },
        {
            "Ir. szám": "8946",
            "name": "Pusztaederics",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.8",
            "KSH kód": "27465",
            "Jogállás": "6",
            "Terület": "955",
            "Népesség": "181",
            "Lakások": "97"
        },
        {
            "Ir. szám": "8947",
            "name": "Szentkozmadombja",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "16.7667",
            "KSH kód": "03124",
            "Jogállás": "6",
            "Terület": "508",
            "Népesség": "87",
            "Lakások": "71"
        },
        {
            "Ir. szám": "8947",
            "name": "Zalatárnok",
            "Megyekód": "ZA",
            "Lat": "46.7019",
            "Long": "16.7628",
            "KSH kód": "29683",
            "Jogállás": "6",
            "Terület": "2595",
            "Népesség": "719",
            "Lakások": "341"
        },
        {
            "Ir. szám": "8948",
            "name": "Barlahida",
            "Megyekód": "ZA",
            "Lat": "46.7158",
            "Long": "16.7025",
            "KSH kód": "27447",
            "Jogállás": "6",
            "Terület": "610",
            "Népesség": "131",
            "Lakások": "86"
        },
        {
            "Ir. szám": "8948",
            "name": "Nova",
            "Megyekód": "ZA",
            "Lat": "46.6839",
            "Long": "16.6839",
            "KSH kód": "03355",
            "Jogállás": "6",
            "Terület": "3929",
            "Népesség": "833",
            "Lakások": "363"
        },
        {
            "Ir. szám": "8949",
            "name": "Mikekarácsonyfa",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.7",
            "KSH kód": "22141",
            "Jogállás": "6",
            "Terület": "850",
            "Népesség": "284",
            "Lakások": "147"
        },
        {
            "Ir. szám": "8951",
            "name": "Gutorfölde",
            "Megyekód": "ZA",
            "Lat": "46.6414",
            "Long": "16.7383",
            "KSH kód": "02097",
            "Jogállás": "6",
            "Terület": "2485",
            "Népesség": "1081",
            "Lakások": "508"
        },
        {
            "Ir. szám": "8951",
            "name": "Csertalakos",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.7",
            "KSH kód": "10649",
            "Jogállás": "6",
            "Terület": "199",
            "Népesség": "31",
            "Lakások": "35"
        },
        {
            "Ir. szám": "8953",
            "name": "Szentpéterfölde",
            "Megyekód": "ZA",
            "Lat": "46.6167",
            "Long": "16.7667",
            "KSH kód": "30067",
            "Jogállás": "6",
            "Terület": "1212",
            "Népesség": "134",
            "Lakások": "84"
        },
        {
            "Ir. szám": "8954",
            "name": "Ortaháza",
            "Megyekód": "ZA",
            "Lat": "46.6167",
            "Long": "16.6833",
            "KSH kód": "04950",
            "Jogállás": "6",
            "Terület": "703",
            "Népesség": "130",
            "Lakások": "63"
        },
        {
            "Ir. szám": "8956",
            "name": "Kányavár",
            "Megyekód": "ZA",
            "Lat": "46.5667",
            "Long": "16.6833",
            "KSH kód": "04473",
            "Jogállás": "6",
            "Terület": "420",
            "Népesség": "125",
            "Lakások": "94"
        },
        {
            "Ir. szám": "8956",
            "name": "Pördefölde",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "16.7167",
            "KSH kód": "32513",
            "Jogállás": "6",
            "Terület": "1912",
            "Népesség": "65",
            "Lakások": "42"
        },
        {
            "Ir. szám": "8956",
            "name": "Páka",
            "Megyekód": "ZA",
            "Lat": "46.5894",
            "Long": "16.6506",
            "KSH kód": "29160",
            "Jogállás": "6",
            "Terület": "2318",
            "Népesség": "1143",
            "Lakások": "535"
        },
        {
            "Ir. szám": "8957",
            "name": "Csömödér",
            "Megyekód": "ZA",
            "Lat": "46.6083",
            "Long": "16.6442",
            "KSH kód": "29364",
            "Jogállás": "6",
            "Terület": "779",
            "Népesség": "604",
            "Lakások": "271"
        },
        {
            "Ir. szám": "8957",
            "name": "Kissziget",
            "Megyekód": "ZA",
            "Lat": "46.6167",
            "Long": "16.6667",
            "KSH kód": "13055",
            "Jogállás": "6",
            "Terület": "707",
            "Népesség": "138",
            "Lakások": "95"
        },
        {
            "Ir. szám": "8957",
            "name": "Hernyék",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.65",
            "KSH kód": "19895",
            "Jogállás": "6",
            "Terület": "1031",
            "Népesség": "110",
            "Lakások": "72"
        },
        {
            "Ir. szám": "8957",
            "name": "Zebecke",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.6833",
            "KSH kód": "03726",
            "Jogállás": "6",
            "Terület": "445",
            "Népesség": "73",
            "Lakások": "52"
        },
        {
            "Ir. szám": "8958",
            "name": "Iklódbördőce",
            "Megyekód": "ZA",
            "Lat": "46.6",
            "Long": "16.6167",
            "KSH kód": "13921",
            "Jogállás": "6",
            "Terület": "1230",
            "Népesség": "312",
            "Lakások": "142"
        },
        {
            "Ir. szám": "8960",
            "name": "Lenti",
            "Megyekód": "ZA",
            "Lat": "46.6217",
            "Long": "16.5392",
            "KSH kód": "12575",
            "Jogállás": "4",
            "Terület": "7380",
            "Népesség": "8328",
            "Lakások": "3386"
        },
        {
            "Ir. szám": "8961",
            "name": "Lenti",
            "Megyekód": "ZA",
            "Lat": "46.6217",
            "Long": "16.5392",
            "KSH kód": "12575",
            "Jogállás": "4",
            "Terület": "7380",
            "Népesség": "8328",
            "Lakások": "3386"
        },
        {
            "Ir. szám": "8966",
            "name": "Lenti",
            "Megyekód": "ZA",
            "Lat": "46.6217",
            "Long": "16.5392",
            "KSH kód": "12575",
            "Jogállás": "4",
            "Terület": "7380",
            "Népesség": "8328",
            "Lakások": "3386"
        },
        {
            "Ir. szám": "8969",
            "name": "Bödeháza",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.4167",
            "KSH kód": "04163",
            "Jogállás": "6",
            "Terület": "667",
            "Népesség": "70",
            "Lakások": "58"
        },
        {
            "Ir. szám": "8969",
            "name": "Zalaszombatfa",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "16.45",
            "KSH kód": "27562",
            "Jogállás": "6",
            "Terület": "589",
            "Népesség": "47",
            "Lakások": "39"
        },
        {
            "Ir. szám": "8969",
            "name": "Gáborjánháza",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "16.4167",
            "KSH kód": "17516",
            "Jogállás": "6",
            "Terület": "507",
            "Népesség": "73",
            "Lakások": "63"
        },
        {
            "Ir. szám": "8969",
            "name": "Szijártóháza",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "16.45",
            "KSH kód": "26161",
            "Jogállás": "6",
            "Terület": "378",
            "Népesség": "26",
            "Lakások": "29"
        },
        {
            "Ir. szám": "8971",
            "name": "Zalabaksa",
            "Megyekód": "ZA",
            "Lat": "46.6997",
            "Long": "16.5522",
            "KSH kód": "07579",
            "Jogállás": "6",
            "Terület": "1610",
            "Népesség": "633",
            "Lakások": "284"
        },
        {
            "Ir. szám": "8971",
            "name": "Kerkabarabás",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "16.5667",
            "KSH kód": "29489",
            "Jogállás": "6",
            "Terület": "1029",
            "Népesség": "284",
            "Lakások": "136"
        },
        {
            "Ir. szám": "8973",
            "name": "Felsőszenterzsébet",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.45",
            "KSH kód": "30890",
            "Jogállás": "6",
            "Terület": "824",
            "Népesség": "17",
            "Lakások": "15"
        },
        {
            "Ir. szám": "8973",
            "name": "Magyarföld",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.4167",
            "KSH kód": "08916",
            "Jogállás": "6",
            "Terület": "889",
            "Népesség": "27",
            "Lakások": "26"
        },
        {
            "Ir. szám": "8973",
            "name": "Alsószenterzsébet",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.4833",
            "KSH kód": "08767",
            "Jogállás": "6",
            "Terület": "963",
            "Népesség": "77",
            "Lakások": "40"
        },
        {
            "Ir. szám": "8973",
            "name": "Ramocsa",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.45",
            "KSH kód": "14298",
            "Jogállás": "6",
            "Terület": "273",
            "Népesség": "48",
            "Lakások": "21"
        },
        {
            "Ir. szám": "8973",
            "name": "Csesztreg",
            "Megyekód": "ZA",
            "Lat": "46.7136",
            "Long": "16.5203",
            "KSH kód": "02583",
            "Jogállás": "6",
            "Terület": "2300",
            "Népesség": "803",
            "Lakások": "361"
        },
        {
            "Ir. szám": "8973",
            "name": "Kerkafalva",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.5",
            "KSH kód": "15112",
            "Jogállás": "6",
            "Terület": "1850",
            "Népesség": "123",
            "Lakások": "79"
        },
        {
            "Ir. szám": "8973",
            "name": "Kerkakutas",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.5",
            "KSH kód": "22080",
            "Jogállás": "6",
            "Terület": "2007",
            "Népesség": "138",
            "Lakások": "96"
        },
        {
            "Ir. szám": "8975",
            "name": "Szentgyörgyvölgy",
            "Megyekód": "ZA",
            "Lat": "46.7244",
            "Long": "16.4156",
            "KSH kód": "18652",
            "Jogállás": "6",
            "Terület": "2958",
            "Népesség": "425",
            "Lakások": "243"
        },
        {
            "Ir. szám": "8976",
            "name": "Nemesnép",
            "Megyekód": "ZA",
            "Lat": "46.7006",
            "Long": "16.4625",
            "KSH kód": "32665",
            "Jogállás": "6",
            "Terület": "972",
            "Népesség": "126",
            "Lakások": "93"
        },
        {
            "Ir. szám": "8976",
            "name": "Márokföld",
            "Megyekód": "ZA",
            "Lat": "46.7167",
            "Long": "16.45",
            "KSH kód": "31981",
            "Jogállás": "6",
            "Terület": "744",
            "Népesség": "44",
            "Lakások": "40"
        },
        {
            "Ir. szám": "8977",
            "name": "Baglad",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "16.4833",
            "KSH kód": "11059",
            "Jogállás": "6",
            "Terület": "486",
            "Népesség": "42",
            "Lakások": "43"
        },
        {
            "Ir. szám": "8977",
            "name": "Lendvajakabfa",
            "Megyekód": "ZA",
            "Lat": "46.6833",
            "Long": "16.45",
            "KSH kód": "11475",
            "Jogállás": "6",
            "Terület": "618",
            "Népesség": "28",
            "Lakások": "45"
        },
        {
            "Ir. szám": "8977",
            "name": "Resznek",
            "Megyekód": "ZA",
            "Lat": "46.6619",
            "Long": "16.4792",
            "KSH kód": "17118",
            "Jogállás": "6",
            "Terület": "1842",
            "Népesség": "308",
            "Lakások": "155"
        },
        {
            "Ir. szám": "8978",
            "name": "Belsősárd",
            "Megyekód": "ZA",
            "Lat": "46.65",
            "Long": "16.4833",
            "KSH kód": "20950",
            "Jogállás": "6",
            "Terület": "979",
            "Népesség": "110",
            "Lakások": "44"
        },
        {
            "Ir. szám": "8978",
            "name": "Lendvadedes",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "16.5167",
            "KSH kód": "27483",
            "Jogállás": "6",
            "Terület": "402",
            "Népesség": "36",
            "Lakások": "26"
        },
        {
            "Ir. szám": "8978",
            "name": "Gosztola",
            "Megyekód": "ZA",
            "Lat": "46.5833",
            "Long": "16.5333",
            "KSH kód": "13569",
            "Jogállás": "6",
            "Terület": "698",
            "Népesség": "30",
            "Lakások": "45"
        },
        {
            "Ir. szám": "8978",
            "name": "Rédics",
            "Megyekód": "ZA",
            "Lat": "46.6142",
            "Long": "16.4775",
            "KSH kód": "31592",
            "Jogállás": "6",
            "Terület": "2532",
            "Népesség": "988",
            "Lakások": "381"
        },
        {
            "Ir. szám": "8978",
            "name": "Külsősárd",
            "Megyekód": "ZA",
            "Lat": "46.6333",
            "Long": "16.5",
            "KSH kód": "32221",
            "Jogállás": "6",
            "Terület": "152",
            "Népesség": "83",
            "Lakások": "36"
        },
        {
            "Ir. szám": "8981",
            "name": "Gellénháza",
            "Megyekód": "ZA",
            "Lat": "46.7614",
            "Long": "16.7878",
            "KSH kód": "08068",
            "Jogállás": "6",
            "Terület": "524",
            "Népesség": "1615",
            "Lakások": "643"
        },
        {
            "Ir. szám": "8981",
            "name": "Lickóvadamos",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.7667",
            "KSH kód": "03054",
            "Jogállás": "6",
            "Terület": "669",
            "Népesség": "202",
            "Lakások": "127"
        },
        {
            "Ir. szám": "8983",
            "name": "Babosdöbréte",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "16.7833",
            "KSH kód": "21263",
            "Jogállás": "6",
            "Terület": "1252",
            "Népesség": "535",
            "Lakások": "220"
        },
        {
            "Ir. szám": "8983",
            "name": "Nagylengyel",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.7667",
            "KSH kód": "04455",
            "Jogállás": "6",
            "Terület": "1024",
            "Népesség": "522",
            "Lakások": "200"
        },
        {
            "Ir. szám": "8983",
            "name": "Ormándlak",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.75",
            "KSH kód": "22497",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "118",
            "Lakások": "51"
        },
        {
            "Ir. szám": "8984",
            "name": "Iborfia",
            "Megyekód": "ZA",
            "Lat": "46.7333",
            "Long": "16.75",
            "KSH kód": "03188",
            "Jogállás": "6",
            "Terület": "259",
            "Népesség": "12",
            "Lakások": "20"
        },
        {
            "Ir. szám": "8984",
            "name": "Petrikeresztúr",
            "Megyekód": "ZA",
            "Lat": "46.7333",
            "Long": "16.7333",
            "KSH kód": "19178",
            "Jogállás": "6",
            "Terület": "877",
            "Népesség": "395",
            "Lakások": "182"
        },
        {
            "Ir. szám": "8984",
            "name": "Gombosszeg",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.7333",
            "KSH kód": "17613",
            "Jogállás": "6",
            "Terület": "216",
            "Népesség": "47",
            "Lakások": "28"
        },
        {
            "Ir. szám": "8985",
            "name": "Becsvölgye",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.7",
            "KSH kód": "18360",
            "Jogállás": "6",
            "Terület": "2140",
            "Népesség": "833",
            "Lakások": "355"
        },
        {
            "Ir. szám": "8986",
            "name": "Pusztaapáti",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.6167",
            "KSH kód": "16920",
            "Jogállás": "6",
            "Terület": "1059",
            "Népesség": "28",
            "Lakások": "24"
        },
        {
            "Ir. szám": "8986",
            "name": "Szilvágy",
            "Megyekód": "ZA",
            "Lat": "46.7333",
            "Long": "16.6333",
            "KSH kód": "17853",
            "Jogállás": "6",
            "Terület": "2427",
            "Népesség": "211",
            "Lakások": "99"
        },
        {
            "Ir. szám": "8986",
            "name": "Pórszombat",
            "Megyekód": "ZA",
            "Lat": "46.7264",
            "Long": "16.5781",
            "KSH kód": "16850",
            "Jogállás": "6",
            "Terület": "1552",
            "Népesség": "320",
            "Lakások": "140"
        },
        {
            "Ir. szám": "8988",
            "name": "Kálócfa",
            "Megyekód": "ZA",
            "Lat": "46.75",
            "Long": "16.5667",
            "KSH kód": "21500",
            "Jogállás": "6",
            "Terület": "1021",
            "Népesség": "158",
            "Lakások": "92"
        },
        {
            "Ir. szám": "8988",
            "name": "Kozmadombja",
            "Megyekód": "ZA",
            "Lat": "46.7667",
            "Long": "16.55",
            "KSH kód": "13082",
            "Jogállás": "6",
            "Terület": "763",
            "Népesség": "61",
            "Lakások": "33"
        },
        {
            "Ir. szám": "8989",
            "name": "Dobronhegy",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "16.75",
            "KSH kód": "32708",
            "Jogállás": "6",
            "Terület": "215",
            "Népesség": "154",
            "Lakások": "68"
        },
        {
            "Ir. szám": "8990",
            "name": "Pálfiszeg",
            "Megyekód": "ZA",
            "Lat": "46.7833",
            "Long": "16.7333",
            "KSH kód": "14720",
            "Jogállás": "6",
            "Terület": "559",
            "Népesség": "174",
            "Lakások": "70"
        },
        {
            "Ir. szám": "8991",
            "name": "Hottó",
            "Megyekód": "ZA",
            "Lat": "46.85",
            "Long": "16.75",
            "KSH kód": "28325",
            "Jogállás": "6",
            "Terület": "670",
            "Népesség": "362",
            "Lakások": "135"
        },
        {
            "Ir. szám": "8991",
            "name": "Teskánd",
            "Megyekód": "ZA",
            "Lat": "46.8469",
            "Long": "16.7758",
            "KSH kód": "08828",
            "Jogállás": "6",
            "Terület": "485",
            "Népesség": "1133",
            "Lakások": "366"
        },
        {
            "Ir. szám": "8991",
            "name": "Böde",
            "Megyekód": "ZA",
            "Lat": "46.8375",
            "Long": "16.7206",
            "KSH kód": "22877",
            "Jogállás": "6",
            "Terület": "917",
            "Népesség": "310",
            "Lakások": "129"
        },
        {
            "Ir. szám": "8992",
            "name": "Zalaboldogfa",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "16.7667",
            "KSH kód": "28495",
            "Jogállás": "6",
            "Terület": "1147",
            "Népesség": "354",
            "Lakások": "140"
        },
        {
            "Ir. szám": "8992",
            "name": "Boncodfölde",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "16.7333",
            "KSH kód": "10782",
            "Jogállás": "6",
            "Terület": "590",
            "Népesség": "358",
            "Lakások": "134"
        },
        {
            "Ir. szám": "8992",
            "name": "Bagod",
            "Megyekód": "ZA",
            "Lat": "46.8781",
            "Long": "16.7444",
            "KSH kód": "30368",
            "Jogállás": "6",
            "Terület": "1637",
            "Népesség": "1356",
            "Lakások": "470"
        },
        {
            "Ir. szám": "8992",
            "name": "Hagyárosbörönd",
            "Megyekód": "ZA",
            "Lat": "46.9",
            "Long": "16.7167",
            "KSH kód": "10931",
            "Jogállás": "6",
            "Terület": "1125",
            "Népesség": "294",
            "Lakások": "126"
        },
        {
            "Ir. szám": "8994",
            "name": "Zalaszentgyörgy",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "16.7167",
            "KSH kód": "33136",
            "Jogállás": "6",
            "Terület": "983",
            "Népesség": "429",
            "Lakások": "159"
        },
        {
            "Ir. szám": "8994",
            "name": "Kávás",
            "Megyekód": "ZA",
            "Lat": "46.8667",
            "Long": "16.7167",
            "KSH kód": "32063",
            "Jogállás": "6",
            "Terület": "664",
            "Népesség": "252",
            "Lakások": "98"
        },
        {
            "Ir. szám": "8995",
            "name": "Keménfa",
            "Megyekód": "ZA",
            "Lat": "46.8333",
            "Long": "16.6333",
            "KSH kód": "06071",
            "Jogállás": "6",
            "Terület": "1489",
            "Népesség": "91",
            "Lakások": "48"
        },
        {
            "Ir. szám": "8995",
            "name": "Salomvár",
            "Megyekód": "ZA",
            "Lat": "46.8489",
            "Long": "16.6633",
            "KSH kód": "27720",
            "Jogállás": "6",
            "Terület": "1611",
            "Népesség": "621",
            "Lakások": "256"
        },
        {
            "Ir. szám": "8996",
            "name": "Zalacséb",
            "Megyekód": "ZA",
            "Lat": "46.8603",
            "Long": "16.6639",
            "KSH kód": "02486",
            "Jogállás": "6",
            "Terület": "963",
            "Népesség": "563",
            "Lakások": "192"
        },
        {
            "Ir. szám": "8997",
            "name": "Zalaháshágy",
            "Megyekód": "ZA",
            "Lat": "46.89",
            "Long": "16.6328",
            "KSH kód": "23834",
            "Jogállás": "6",
            "Terület": "1213",
            "Népesség": "380",
            "Lakások": "172"
        },
        {
            "Ir. szám": "8998",
            "name": "Vaspör",
            "Megyekód": "ZA",
            "Lat": "46.9161",
            "Long": "16.65",
            "KSH kód": "14322",
            "Jogállás": "6",
            "Terület": "1345",
            "Népesség": "391",
            "Lakások": "204"
        },
        {
            "Ir. szám": "8998",
            "name": "Ozmánbük",
            "Megyekód": "ZA",
            "Lat": "46.9167",
            "Long": "16.6833",
            "KSH kód": "18953",
            "Jogállás": "6",
            "Terület": "669",
            "Népesség": "186",
            "Lakások": "93"
        },
        {
            "Ir. szám": "8999",
            "name": "Csöde",
            "Megyekód": "ZA",
            "Lat": "46.8167",
            "Long": "16.5333",
            "KSH kód": "27492",
            "Jogállás": "6",
            "Terület": "1061",
            "Népesség": "80",
            "Lakások": "54"
        },
        {
            "Ir. szám": "8999",
            "name": "Zalalövő",
            "Megyekód": "ZA",
            "Lat": "46.8483",
            "Long": "16.5936",
            "KSH kód": "30313",
            "Jogállás": "4",
            "Terület": "5264",
            "Népesség": "3053",
            "Lakások": "1268"
        },
        {
            "Ir. szám": "9000",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9001",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9002",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9003",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9004",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9005",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9006",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9007",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9008",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9009",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9010",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9011",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9012",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9013",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9019",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9020",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9021",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9022",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9023",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9024",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9025",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9026",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9027",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9028",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9029",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9030",
            "name": "Győr",
            "Megyekód": "GS",
            "Lat": "47.6076",
            "Long": "17.7819",
            "KSH kód": "25584",
            "Jogállás": "1",
            "Terület": "17462",
            "Népesség": "130476",
            "Lakások": "55350"
        },
        {
            "Ir. szám": "9061",
            "name": "Vámosszabadi",
            "Megyekód": "GS",
            "Lat": "47.7667",
            "Long": "17.65",
            "KSH kód": "12405",
            "Jogállás": "6",
            "Terület": "2237",
            "Népesség": "1562",
            "Lakások": "508"
        },
        {
            "Ir. szám": "9062",
            "name": "Vének",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.7667",
            "KSH kód": "05014",
            "Jogállás": "6",
            "Terület": "689",
            "Népesség": "193",
            "Lakások": "82"
        },
        {
            "Ir. szám": "9062",
            "name": "Kisbajcs",
            "Megyekód": "GS",
            "Lat": "47.75",
            "Long": "17.6833",
            "KSH kód": "22886",
            "Jogállás": "6",
            "Terület": "874",
            "Népesség": "820",
            "Lakások": "276"
        },
        {
            "Ir. szám": "9063",
            "name": "Nagybajcs",
            "Megyekód": "GS",
            "Lat": "47.7667",
            "Long": "17.7",
            "KSH kód": "02361",
            "Jogállás": "6",
            "Terület": "765",
            "Népesség": "929",
            "Lakások": "372"
        },
        {
            "Ir. szám": "9071",
            "name": "Gönyű",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.8333",
            "KSH kód": "02060",
            "Jogállás": "6",
            "Terület": "2163",
            "Népesség": "3147",
            "Lakások": "1133"
        },
        {
            "Ir. szám": "9072",
            "name": "Nagyszentjános",
            "Megyekód": "GS",
            "Lat": "47.7167",
            "Long": "17.8833",
            "KSH kód": "33543",
            "Jogállás": "6",
            "Terület": "3030",
            "Népesség": "1810",
            "Lakások": "616"
        },
        {
            "Ir. szám": "9073",
            "name": "Bőny",
            "Megyekód": "GS",
            "Lat": "47.65",
            "Long": "17.8667",
            "KSH kód": "33950",
            "Jogállás": "6",
            "Terület": "5042",
            "Népesség": "2192",
            "Lakások": "776"
        },
        {
            "Ir. szám": "9074",
            "name": "Rétalap",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.9167",
            "KSH kód": "33969",
            "Jogállás": "6",
            "Terület": "1320",
            "Népesség": "555",
            "Lakások": "222"
        },
        {
            "Ir. szám": "9081",
            "name": "Győrújbarát",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.65",
            "KSH kód": "07481",
            "Jogállás": "6",
            "Terület": "3361",
            "Népesség": "5998",
            "Lakások": "2084"
        },
        {
            "Ir. szám": "9082",
            "name": "Nyúl",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.6833",
            "KSH kód": "12955",
            "Jogállás": "6",
            "Terület": "2514",
            "Népesség": "4196",
            "Lakások": "1527"
        },
        {
            "Ir. szám": "9083",
            "name": "Écs",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.7167",
            "KSH kód": "16708",
            "Jogállás": "6",
            "Terület": "1984",
            "Népesség": "1852",
            "Lakások": "708"
        },
        {
            "Ir. szám": "9084",
            "name": "Győrság",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.75",
            "KSH kód": "31316",
            "Jogállás": "6",
            "Terület": "820",
            "Népesség": "1494",
            "Lakások": "573"
        },
        {
            "Ir. szám": "9085",
            "name": "Pázmándfalu",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.7833",
            "KSH kód": "12715",
            "Jogállás": "6",
            "Terület": "1939",
            "Népesség": "983",
            "Lakások": "371"
        },
        {
            "Ir. szám": "9086",
            "name": "Töltéstava",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "17.7333",
            "KSH kód": "16674",
            "Jogállás": "6",
            "Terület": "2317",
            "Népesség": "2187",
            "Lakások": "586"
        },
        {
            "Ir. szám": "9088",
            "name": "Bakonypéterd",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "22062",
            "Jogállás": "6",
            "Terület": "1002",
            "Népesség": "265",
            "Lakások": "124"
        },
        {
            "Ir. szám": "9089",
            "name": "Lázi",
            "Megyekód": "VE",
            "Lat": "47.4",
            "Long": "17.825",
            "KSH kód": "04376",
            "Jogállás": "6",
            "Terület": "1679",
            "Népesség": "568",
            "Lakások": "239"
        },
        {
            "Ir. szám": "9090",
            "name": "Pannonhalma",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.75",
            "KSH kód": "24305",
            "Jogállás": "4",
            "Terület": "2958",
            "Népesség": "3948",
            "Lakások": "1296"
        },
        {
            "Ir. szám": "9091",
            "name": "Ravazd",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.75",
            "KSH kód": "21801",
            "Jogállás": "6",
            "Terület": "2854",
            "Népesség": "1200",
            "Lakások": "486"
        },
        {
            "Ir. szám": "9092",
            "name": "Tarjánpuszta",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.7833",
            "KSH kód": "33914",
            "Jogállás": "6",
            "Terület": "844",
            "Népesség": "378",
            "Lakások": "141"
        },
        {
            "Ir. szám": "9093",
            "name": "Győrasszonyfa",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.8",
            "KSH kód": "08721",
            "Jogállás": "6",
            "Terület": "648",
            "Népesség": "507",
            "Lakások": "208"
        },
        {
            "Ir. szám": "9094",
            "name": "Tápszentmiklós",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.85",
            "KSH kód": "21971",
            "Jogállás": "6",
            "Terület": "2077",
            "Népesség": "960",
            "Lakások": "337"
        },
        {
            "Ir. szám": "9095",
            "name": "Táp",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.8333",
            "KSH kód": "14261",
            "Jogállás": "6",
            "Terület": "1988",
            "Népesség": "720",
            "Lakások": "280"
        },
        {
            "Ir. szám": "9096",
            "name": "Nyalka",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.8167",
            "KSH kód": "19752",
            "Jogállás": "6",
            "Terület": "1286",
            "Népesség": "444",
            "Lakások": "193"
        },
        {
            "Ir. szám": "9097",
            "name": "Mezőörs",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.8833",
            "KSH kód": "12812",
            "Jogállás": "6",
            "Terület": "4848",
            "Népesség": "1013",
            "Lakások": "333"
        },
        {
            "Ir. szám": "9098",
            "name": "Mezőörs",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.8833",
            "KSH kód": "12812",
            "Jogállás": "6",
            "Terület": "4848",
            "Népesség": "1013",
            "Lakások": "333"
        },
        {
            "Ir. szám": "9099",
            "name": "Pér",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "17.8",
            "KSH kód": "15529",
            "Jogállás": "6",
            "Terület": "3148",
            "Népesség": "2359",
            "Lakások": "950"
        },
        {
            "Ir. szám": "9100",
            "name": "Tét",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.5167",
            "KSH kód": "19035",
            "Jogállás": "4",
            "Terület": "5635",
            "Népesség": "4010",
            "Lakások": "1469"
        },
        {
            "Ir. szám": "9111",
            "name": "Tényő",
            "Megyekód": "GS",
            "Lat": "47.5333",
            "Long": "17.65",
            "KSH kód": "11457",
            "Jogállás": "6",
            "Terület": "2638",
            "Népesség": "1566",
            "Lakások": "676"
        },
        {
            "Ir. szám": "9112",
            "name": "Sokorópátka",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "17.7",
            "KSH kód": "12627",
            "Jogállás": "6",
            "Terület": "1686",
            "Népesség": "1087",
            "Lakások": "478"
        },
        {
            "Ir. szám": "9113",
            "name": "Koroncó",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.5333",
            "KSH kód": "24633",
            "Jogállás": "6",
            "Terület": "2691",
            "Népesség": "1965",
            "Lakások": "681"
        },
        {
            "Ir. szám": "9121",
            "name": "Győrszemere",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.5667",
            "KSH kód": "15653",
            "Jogállás": "6",
            "Terület": "3311",
            "Népesség": "3122",
            "Lakások": "1127"
        },
        {
            "Ir. szám": "9122",
            "name": "Felpéc",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.6",
            "KSH kód": "33251",
            "Jogállás": "6",
            "Terület": "2247",
            "Népesség": "885",
            "Lakások": "378"
        },
        {
            "Ir. szám": "9123",
            "name": "Kajárpéc",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "17.6333",
            "KSH kód": "12113",
            "Jogállás": "6",
            "Terület": "3189",
            "Népesség": "1314",
            "Lakások": "577"
        },
        {
            "Ir. szám": "9124",
            "name": "Gyömöre",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.5667",
            "KSH kód": "20400",
            "Jogállás": "6",
            "Terület": "2049",
            "Népesség": "1311",
            "Lakások": "505"
        },
        {
            "Ir. szám": "9125",
            "name": "Szerecseny",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.55",
            "KSH kód": "03887",
            "Jogállás": "6",
            "Terület": "1239",
            "Népesség": "813",
            "Lakások": "375"
        },
        {
            "Ir. szám": "9126",
            "name": "Gyarmat",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.5",
            "KSH kód": "26860",
            "Jogállás": "6",
            "Terület": "2245",
            "Népesség": "1232",
            "Lakások": "555"
        },
        {
            "Ir. szám": "9127",
            "name": "Csikvánd",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.45",
            "KSH kód": "13505",
            "Jogállás": "6",
            "Terület": "1453",
            "Népesség": "488",
            "Lakások": "228"
        },
        {
            "Ir. szám": "9131",
            "name": "Mórichida",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.4167",
            "KSH kód": "02556",
            "Jogállás": "6",
            "Terület": "3230",
            "Népesség": "835",
            "Lakások": "362"
        },
        {
            "Ir. szám": "9132",
            "name": "Árpás",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.4",
            "KSH kód": "32249",
            "Jogállás": "6",
            "Terület": "1427",
            "Népesség": "247",
            "Lakások": "128"
        },
        {
            "Ir. szám": "9133",
            "name": "Rábaszentmiklós",
            "Megyekód": "GS",
            "Lat": "47.5333",
            "Long": "17.4167",
            "KSH kód": "17297",
            "Jogállás": "6",
            "Terület": "501",
            "Népesség": "127",
            "Lakások": "63"
        },
        {
            "Ir. szám": "9133",
            "name": "Kisbabot",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.4167",
            "KSH kód": "02413",
            "Jogállás": "6",
            "Terület": "610",
            "Népesség": "211",
            "Lakások": "112"
        },
        {
            "Ir. szám": "9134",
            "name": "Bodonhely",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.4",
            "KSH kód": "06220",
            "Jogállás": "6",
            "Terület": "1110",
            "Népesség": "299",
            "Lakások": "134"
        },
        {
            "Ir. szám": "9135",
            "name": "Rábaszentmihály",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.4333",
            "KSH kód": "33710",
            "Jogállás": "6",
            "Terület": "1097",
            "Népesség": "510",
            "Lakások": "200"
        },
        {
            "Ir. szám": "9136",
            "name": "Rábacsécsény",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.4333",
            "KSH kód": "33701",
            "Jogállás": "6",
            "Terület": "1568",
            "Népesség": "601",
            "Lakások": "241"
        },
        {
            "Ir. szám": "9136",
            "name": "Mérges",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.45",
            "KSH kód": "20385",
            "Jogállás": "6",
            "Terület": "651",
            "Népesség": "86",
            "Lakások": "53"
        },
        {
            "Ir. szám": "9141",
            "name": "Ikrény",
            "Megyekód": "GS",
            "Lat": "47.65",
            "Long": "17.5333",
            "KSH kód": "20604",
            "Jogállás": "6",
            "Terület": "1558",
            "Népesség": "1826",
            "Lakások": "566"
        },
        {
            "Ir. szám": "9142",
            "name": "Rábapatona",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "17.4833",
            "KSH kód": "25335",
            "Jogállás": "6",
            "Terület": "3975",
            "Népesség": "2460",
            "Lakások": "935"
        },
        {
            "Ir. szám": "9143",
            "name": "Enese",
            "Megyekód": "GS",
            "Lat": "47.65",
            "Long": "17.4167",
            "KSH kód": "15033",
            "Jogállás": "6",
            "Terület": "1990",
            "Népesség": "1795",
            "Lakások": "604"
        },
        {
            "Ir. szám": "9144",
            "name": "Kóny",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "17.3667",
            "KSH kód": "11262",
            "Jogállás": "6",
            "Terület": "2888",
            "Népesség": "2646",
            "Lakások": "1047"
        },
        {
            "Ir. szám": "9145",
            "name": "Bágyogszovát",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.3667",
            "KSH kód": "28769",
            "Jogállás": "6",
            "Terület": "2406",
            "Népesség": "1321",
            "Lakások": "551"
        },
        {
            "Ir. szám": "9146",
            "name": "Rábapordány",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.3333",
            "KSH kód": "24721",
            "Jogállás": "6",
            "Terület": "2189",
            "Népesség": "1066",
            "Lakások": "448"
        },
        {
            "Ir. szám": "9147",
            "name": "Dör",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.3",
            "KSH kód": "21917",
            "Jogállás": "6",
            "Terület": "1101",
            "Népesség": "619",
            "Lakások": "231"
        },
        {
            "Ir. szám": "9151",
            "name": "Abda",
            "Megyekód": "GS",
            "Lat": "47.7",
            "Long": "17.55",
            "KSH kód": "11882",
            "Jogállás": "6",
            "Terület": "1902",
            "Népesség": "3107",
            "Lakások": "1100"
        },
        {
            "Ir. szám": "9152",
            "name": "Börcs",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "17.5",
            "KSH kód": "06619",
            "Jogállás": "6",
            "Terület": "1278",
            "Népesség": "1279",
            "Lakások": "419"
        },
        {
            "Ir. szám": "9153",
            "name": "Öttevény",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.4833",
            "KSH kód": "02635",
            "Jogállás": "6",
            "Terület": "2367",
            "Népesség": "2867",
            "Lakások": "1031"
        },
        {
            "Ir. szám": "9154",
            "name": "Mosonszentmiklós",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.3833",
            "KSH kód": "33677",
            "Jogállás": "6",
            "Terület": "3071",
            "Népesség": "2319",
            "Lakások": "947"
        },
        {
            "Ir. szám": "9155",
            "name": "Lébény",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.3833",
            "KSH kód": "33668",
            "Jogállás": "5",
            "Terület": "8137",
            "Népesség": "3221",
            "Lakások": "1225"
        },
        {
            "Ir. szám": "9161",
            "name": "Győrsövényház",
            "Megyekód": "GS",
            "Lat": "47.7",
            "Long": "17.3667",
            "KSH kód": "19309",
            "Jogállás": "6",
            "Terület": "2421",
            "Népesség": "788",
            "Lakások": "319"
        },
        {
            "Ir. szám": "9162",
            "name": "Bezi",
            "Megyekód": "GS",
            "Lat": "47.6667",
            "Long": "17.4",
            "KSH kód": "10560",
            "Jogállás": "6",
            "Terület": "1122",
            "Népesség": "462",
            "Lakások": "193"
        },
        {
            "Ir. szám": "9163",
            "name": "Fehértó",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "17.35",
            "KSH kód": "06956",
            "Jogállás": "6",
            "Terület": "1138",
            "Népesség": "469",
            "Lakások": "221"
        },
        {
            "Ir. szám": "9164",
            "name": "Markotabödöge",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "17.3167",
            "KSH kód": "06770",
            "Jogállás": "6",
            "Terület": "1629",
            "Népesség": "454",
            "Lakások": "242"
        },
        {
            "Ir. szám": "9165",
            "name": "Cakóháza",
            "Megyekód": "GS",
            "Lat": "47.7",
            "Long": "17.2833",
            "KSH kód": "27085",
            "Jogállás": "6",
            "Terület": "288",
            "Népesség": "48",
            "Lakások": "31"
        },
        {
            "Ir. szám": "9165",
            "name": "Rábcakapi",
            "Megyekód": "GS",
            "Lat": "47.7",
            "Long": "17.2833",
            "KSH kód": "03753",
            "Jogállás": "6",
            "Terület": "787",
            "Népesség": "177",
            "Lakások": "89"
        },
        {
            "Ir. szám": "9165",
            "name": "Tárnokréti",
            "Megyekód": "GS",
            "Lat": "47.7167",
            "Long": "17.3167",
            "KSH kód": "04172",
            "Jogállás": "6",
            "Terület": "954",
            "Népesség": "199",
            "Lakások": "107"
        },
        {
            "Ir. szám": "9167",
            "name": "Bősárkány",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "17.25",
            "KSH kód": "15501",
            "Jogállás": "5",
            "Terület": "2333",
            "Népesség": "2158",
            "Lakások": "784"
        },
        {
            "Ir. szám": "9168",
            "name": "Acsalag",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "17.2",
            "KSH kód": "33385",
            "Jogállás": "6",
            "Terület": "1041",
            "Népesség": "456",
            "Lakások": "225"
        },
        {
            "Ir. szám": "9169",
            "name": "Maglóca",
            "Megyekód": "GS",
            "Lat": "47.6667",
            "Long": "17.2833",
            "KSH kód": "26444",
            "Jogállás": "6",
            "Terület": "577",
            "Népesség": "87",
            "Lakások": "68"
        },
        {
            "Ir. szám": "9169",
            "name": "Barbacs",
            "Megyekód": "GS",
            "Lat": "47.65",
            "Long": "17.3",
            "KSH kód": "06196",
            "Jogállás": "6",
            "Terület": "1365",
            "Népesség": "738",
            "Lakások": "308"
        },
        {
            "Ir. szám": "9171",
            "name": "Győrújfalu",
            "Megyekód": "GS",
            "Lat": "47.7167",
            "Long": "17.6167",
            "KSH kód": "31787",
            "Jogállás": "6",
            "Terület": "737",
            "Népesség": "1391",
            "Lakások": "464"
        },
        {
            "Ir. szám": "9172",
            "name": "Győrzámoly",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.5833",
            "KSH kód": "15228",
            "Jogállás": "6",
            "Terület": "2638",
            "Népesség": "2304",
            "Lakások": "746"
        },
        {
            "Ir. szám": "9173",
            "name": "Győrladamér",
            "Megyekód": "GS",
            "Lat": "47.75",
            "Long": "17.5667",
            "KSH kód": "13198",
            "Jogállás": "6",
            "Terület": "860",
            "Népesség": "1503",
            "Lakások": "509"
        },
        {
            "Ir. szám": "9174",
            "name": "Dunaszeg",
            "Megyekód": "GS",
            "Lat": "47.7667",
            "Long": "17.55",
            "KSH kód": "21078",
            "Jogállás": "6",
            "Terület": "1726",
            "Népesség": "1967",
            "Lakások": "666"
        },
        {
            "Ir. szám": "9175",
            "name": "Dunaszentpál",
            "Megyekód": "GS",
            "Lat": "47.7833",
            "Long": "17.5",
            "KSH kód": "15875",
            "Jogállás": "6",
            "Terület": "981",
            "Népesség": "727",
            "Lakások": "255"
        },
        {
            "Ir. szám": "9176",
            "name": "Mecsér",
            "Megyekód": "GS",
            "Lat": "47.8",
            "Long": "17.4833",
            "KSH kód": "27359",
            "Jogállás": "6",
            "Terület": "2111",
            "Népesség": "594",
            "Lakások": "293"
        },
        {
            "Ir. szám": "9177",
            "name": "Ásványráró",
            "Megyekód": "GS",
            "Lat": "47.8333",
            "Long": "17.5",
            "KSH kód": "26921",
            "Jogállás": "6",
            "Terület": "3916",
            "Népesség": "1923",
            "Lakások": "814"
        },
        {
            "Ir. szám": "9178",
            "name": "Hédervár",
            "Megyekód": "GS",
            "Lat": "47.8333",
            "Long": "17.4667",
            "KSH kód": "12308",
            "Jogállás": "6",
            "Terület": "1428",
            "Népesség": "1147",
            "Lakások": "455"
        },
        {
            "Ir. szám": "9181",
            "name": "Kimle",
            "Megyekód": "GS",
            "Lat": "47.8333",
            "Long": "17.3667",
            "KSH kód": "14748",
            "Jogállás": "6",
            "Terület": "3727",
            "Népesség": "2158",
            "Lakások": "847"
        },
        {
            "Ir. szám": "9182",
            "name": "Károlyháza",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "17.5833",
            "KSH kód": "34351",
            "Jogállás": "6",
            "Terület": "1125",
            "Népesség": "682",
            "Lakások": "152"
        },
        {
            "Ir. szám": "9183",
            "name": "Károlyháza",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "17.5833",
            "KSH kód": "34351",
            "Jogállás": "6",
            "Terület": "1125",
            "Népesség": "682",
            "Lakások": "152"
        },
        {
            "Ir. szám": "9184",
            "name": "Kunsziget",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.5167",
            "KSH kód": "31626",
            "Jogállás": "6",
            "Terület": "1788",
            "Népesség": "1240",
            "Lakások": "433"
        },
        {
            "Ir. szám": "9200",
            "name": "Mosonmagyaróvár",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.2833",
            "KSH kód": "04783",
            "Jogállás": "4",
            "Terület": "8535",
            "Népesség": "32256",
            "Lakások": "12608"
        },
        {
            "Ir. szám": "9201",
            "name": "Mosonmagyaróvár",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.2833",
            "KSH kód": "04783",
            "Jogállás": "4",
            "Terület": "8535",
            "Népesség": "32256",
            "Lakások": "12608"
        },
        {
            "Ir. szám": "9202",
            "name": "Mosonmagyaróvár",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.2833",
            "KSH kód": "04783",
            "Jogállás": "4",
            "Terület": "8535",
            "Népesség": "32256",
            "Lakások": "12608"
        },
        {
            "Ir. szám": "9203",
            "name": "Mosonmagyaróvár",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.2833",
            "KSH kód": "04783",
            "Jogállás": "4",
            "Terület": "8535",
            "Népesség": "32256",
            "Lakások": "12608"
        },
        {
            "Ir. szám": "9211",
            "name": "Feketeerdő",
            "Megyekód": "GS",
            "Lat": "47.9333",
            "Long": "17.2833",
            "KSH kód": "32717",
            "Jogállás": "6",
            "Terület": "674",
            "Népesség": "497",
            "Lakások": "154"
        },
        {
            "Ir. szám": "9221",
            "name": "Levél",
            "Megyekód": "GS",
            "Lat": "47.9",
            "Long": "17.2",
            "KSH kód": "19239",
            "Jogállás": "6",
            "Terület": "2491",
            "Népesség": "1808",
            "Lakások": "641"
        },
        {
            "Ir. szám": "9222",
            "name": "Hegyeshalom",
            "Megyekód": "GS",
            "Lat": "47.9167",
            "Long": "17.1667",
            "KSH kód": "17905",
            "Jogállás": "5",
            "Terület": "5266",
            "Népesség": "3486",
            "Lakások": "1399"
        },
        {
            "Ir. szám": "9223",
            "name": "Bezenye",
            "Megyekód": "GS",
            "Lat": "47.9667",
            "Long": "17.2167",
            "KSH kód": "29805",
            "Jogállás": "6",
            "Terület": "3003",
            "Népesség": "1393",
            "Lakások": "547"
        },
        {
            "Ir. szám": "9224",
            "name": "Rajka",
            "Megyekód": "GS",
            "Lat": "48",
            "Long": "17.2",
            "KSH kód": "26587",
            "Jogállás": "6",
            "Terület": "5263",
            "Népesség": "2385",
            "Lakások": "949"
        },
        {
            "Ir. szám": "9225",
            "name": "Dunakiliti",
            "Megyekód": "GS",
            "Lat": "47.9667",
            "Long": "17.2833",
            "KSH kód": "27739",
            "Jogállás": "6",
            "Terület": "3366",
            "Népesség": "1816",
            "Lakások": "671"
        },
        {
            "Ir. szám": "9226",
            "name": "Dunasziget",
            "Megyekód": "GS",
            "Lat": "47.9333",
            "Long": "17.3667",
            "KSH kód": "10454",
            "Jogállás": "6",
            "Terület": "3590",
            "Népesség": "1449",
            "Lakások": "583"
        },
        {
            "Ir. szám": "9228",
            "name": "Halászi",
            "Megyekód": "GS",
            "Lat": "47.8833",
            "Long": "17.3333",
            "KSH kód": "26790",
            "Jogállás": "6",
            "Terület": "3645",
            "Népesség": "2905",
            "Lakások": "1049"
        },
        {
            "Ir. szám": "9231",
            "name": "Máriakálnok",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.3167",
            "KSH kód": "12283",
            "Jogállás": "6",
            "Terület": "1548",
            "Népesség": "1641",
            "Lakások": "558"
        },
        {
            "Ir. szám": "9232",
            "name": "Darnózseli",
            "Megyekód": "GS",
            "Lat": "47.85",
            "Long": "17.4333",
            "KSH kód": "21865",
            "Jogállás": "6",
            "Terület": "1961",
            "Népesség": "1588",
            "Lakások": "589"
        },
        {
            "Ir. szám": "9233",
            "name": "Lipót",
            "Megyekód": "GS",
            "Lat": "47.8667",
            "Long": "17.4667",
            "KSH kód": "16221",
            "Jogállás": "6",
            "Terület": "1609",
            "Népesség": "659",
            "Lakások": "249"
        },
        {
            "Ir. szám": "9234",
            "name": "Kisbodak",
            "Megyekód": "GS",
            "Lat": "47.9",
            "Long": "17.4167",
            "KSH kód": "07816",
            "Jogállás": "6",
            "Terület": "904",
            "Népesség": "362",
            "Lakások": "163"
        },
        {
            "Ir. szám": "9235",
            "name": "Dunaremete",
            "Megyekód": "GS",
            "Lat": "47.8833",
            "Long": "17.4333",
            "KSH kód": "02079",
            "Jogállás": "6",
            "Terület": "436",
            "Népesség": "246",
            "Lakások": "103"
        },
        {
            "Ir. szám": "9235",
            "name": "Püski",
            "Megyekód": "GS",
            "Lat": "47.8833",
            "Long": "17.4",
            "KSH kód": "12964",
            "Jogállás": "6",
            "Terület": "839",
            "Népesség": "650",
            "Lakások": "249"
        },
        {
            "Ir. szám": "9241",
            "name": "Jánossomorja",
            "Megyekód": "GS",
            "Lat": "47.8",
            "Long": "17.1167",
            "KSH kód": "29221",
            "Jogállás": "4",
            "Terület": "14897",
            "Népesség": "5811",
            "Lakások": "2181"
        },
        {
            "Ir. szám": "9242",
            "name": "Jánossomorja",
            "Megyekód": "GS",
            "Lat": "47.8",
            "Long": "17.1167",
            "KSH kód": "29221",
            "Jogállás": "4",
            "Terület": "14897",
            "Népesség": "5811",
            "Lakások": "2181"
        },
        {
            "Ir. szám": "9243",
            "name": "Várbalog",
            "Megyekód": "GS",
            "Lat": "47.85",
            "Long": "17.0833",
            "KSH kód": "18412",
            "Jogállás": "6",
            "Terület": "2270",
            "Népesség": "406",
            "Lakások": "247"
        },
        {
            "Ir. szám": "9244",
            "name": "Újrónafő",
            "Megyekód": "GS",
            "Lat": "47.8",
            "Long": "17.2",
            "KSH kód": "31839",
            "Jogállás": "6",
            "Terület": "4863",
            "Népesség": "803",
            "Lakások": "308"
        },
        {
            "Ir. szám": "9245",
            "name": "Mosonszolnok",
            "Megyekód": "GS",
            "Lat": "47.85",
            "Long": "17.1833",
            "KSH kód": "28149",
            "Jogállás": "6",
            "Terület": "4393",
            "Népesség": "1624",
            "Lakások": "544"
        },
        {
            "Ir. szám": "9300",
            "name": "Csorna",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "17.25",
            "KSH kód": "04039",
            "Jogállás": "4",
            "Terület": "9173",
            "Népesség": "10708",
            "Lakások": "4258"
        },
        {
            "Ir. szám": "9301",
            "name": "Csorna",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "17.25",
            "KSH kód": "04039",
            "Jogállás": "4",
            "Terület": "9173",
            "Népesség": "10708",
            "Lakások": "4258"
        },
        {
            "Ir. szám": "9311",
            "name": "Pásztori",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.2667",
            "KSH kód": "23481",
            "Jogállás": "6",
            "Terület": "852",
            "Népesség": "380",
            "Lakások": "128"
        },
        {
            "Ir. szám": "9312",
            "name": "Szilsárkány",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.2667",
            "KSH kód": "03364",
            "Jogállás": "6",
            "Terület": "1668",
            "Népesség": "662",
            "Lakások": "309"
        },
        {
            "Ir. szám": "9313",
            "name": "Rábacsanak",
            "Megyekód": "GS",
            "Lat": "47.5333",
            "Long": "17.3",
            "KSH kód": "04792",
            "Jogállás": "6",
            "Terület": "1385",
            "Népesség": "512",
            "Lakások": "276"
        },
        {
            "Ir. szám": "9314",
            "name": "Egyed",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.35",
            "KSH kód": "20288",
            "Jogállás": "6",
            "Terület": "1343",
            "Népesség": "564",
            "Lakások": "249"
        },
        {
            "Ir. szám": "9315",
            "name": "Sobor",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "17.3833",
            "KSH kód": "24208",
            "Jogállás": "6",
            "Terület": "1672",
            "Népesség": "287",
            "Lakások": "151"
        },
        {
            "Ir. szám": "9316",
            "name": "Rábaszentandrás",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "17.3333",
            "KSH kód": "15273",
            "Jogállás": "6",
            "Terület": "1160",
            "Népesség": "505",
            "Lakások": "212"
        },
        {
            "Ir. szám": "9317",
            "name": "Szany",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.3",
            "KSH kód": "08536",
            "Jogállás": "5",
            "Terület": "3413",
            "Népesség": "2137",
            "Lakások": "1001"
        },
        {
            "Ir. szám": "9321",
            "name": "Farád",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.2",
            "KSH kód": "33996",
            "Jogállás": "6",
            "Terület": "2902",
            "Népesség": "1960",
            "Lakások": "733"
        },
        {
            "Ir. szám": "9322",
            "name": "Rábatamási",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.1667",
            "KSH kód": "15422",
            "Jogállás": "6",
            "Terület": "2176",
            "Népesség": "997",
            "Lakások": "437"
        },
        {
            "Ir. szám": "9323",
            "name": "Jobaháza",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "17.2",
            "KSH kód": "06646",
            "Jogállás": "6",
            "Terület": "806",
            "Népesség": "571",
            "Lakások": "180"
        },
        {
            "Ir. szám": "9324",
            "name": "Potyond",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.1833",
            "KSH kód": "27304",
            "Jogállás": "6",
            "Terület": "284",
            "Népesség": "107",
            "Lakások": "50"
        },
        {
            "Ir. szám": "9324",
            "name": "Bogyoszló",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.1833",
            "KSH kód": "04367",
            "Jogállás": "6",
            "Terület": "2621",
            "Népesség": "604",
            "Lakások": "315"
        },
        {
            "Ir. szám": "9325",
            "name": "Sopronnémeti",
            "Megyekód": "GS",
            "Lat": "47.5333",
            "Long": "17.2167",
            "KSH kód": "02617",
            "Jogállás": "6",
            "Terület": "709",
            "Népesség": "277",
            "Lakások": "126"
        },
        {
            "Ir. szám": "9326",
            "name": "Szil",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.2333",
            "KSH kód": "19266",
            "Jogállás": "6",
            "Terület": "3103",
            "Népesség": "1235",
            "Lakások": "631"
        },
        {
            "Ir. szám": "9327",
            "name": "Rábasebes",
            "Megyekód": "GS",
            "Lat": "47.4333",
            "Long": "17.2333",
            "KSH kód": "20136",
            "Jogállás": "6",
            "Terület": "539",
            "Népesség": "100",
            "Lakások": "82"
        },
        {
            "Ir. szám": "9327",
            "name": "Vág",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "17.2167",
            "KSH kód": "02237",
            "Jogállás": "6",
            "Terület": "1403",
            "Népesség": "513",
            "Lakások": "297"
        },
        {
            "Ir. szám": "9330",
            "name": "Kapuvár",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.0333",
            "KSH kód": "28334",
            "Jogállás": "4",
            "Terület": "9605",
            "Népesség": "10458",
            "Lakások": "4311"
        },
        {
            "Ir. szám": "9331",
            "name": "Kapuvár",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.0333",
            "KSH kód": "28334",
            "Jogállás": "4",
            "Terület": "9605",
            "Népesség": "10458",
            "Lakások": "4311"
        },
        {
            "Ir. szám": "9332",
            "name": "Kapuvár",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.0333",
            "KSH kód": "28334",
            "Jogállás": "4",
            "Terület": "9605",
            "Népesség": "10458",
            "Lakások": "4311"
        },
        {
            "Ir. szám": "9339",
            "name": "Kapuvár",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.0333",
            "KSH kód": "28334",
            "Jogállás": "4",
            "Terület": "9605",
            "Népesség": "10458",
            "Lakások": "4311"
        },
        {
            "Ir. szám": "9341",
            "name": "Kisfalud",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.1",
            "KSH kód": "33695",
            "Jogállás": "6",
            "Terület": "1510",
            "Népesség": "760",
            "Lakások": "312"
        },
        {
            "Ir. szám": "9342",
            "name": "Mihályi",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.1",
            "KSH kód": "21980",
            "Jogállás": "6",
            "Terület": "1628",
            "Népesség": "1094",
            "Lakások": "469"
        },
        {
            "Ir. szám": "9343",
            "name": "Beled",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.1",
            "KSH kód": "10588",
            "Jogállás": "5",
            "Terület": "2647",
            "Népesség": "2686",
            "Lakások": "1102"
        },
        {
            "Ir. szám": "9343",
            "name": "Vásárosfalu",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "17.1167",
            "KSH kód": "17880",
            "Jogállás": "6",
            "Terület": "420",
            "Népesség": "161",
            "Lakások": "72"
        },
        {
            "Ir. szám": "9343",
            "name": "Edve",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "17.1333",
            "KSH kód": "22442",
            "Jogállás": "6",
            "Terület": "505",
            "Népesség": "114",
            "Lakások": "72"
        },
        {
            "Ir. szám": "9344",
            "name": "Rábakecöl",
            "Megyekód": "GS",
            "Lat": "47.4333",
            "Long": "17.1167",
            "KSH kód": "14793",
            "Jogállás": "6",
            "Terület": "2303",
            "Népesség": "693",
            "Lakások": "354"
        },
        {
            "Ir. szám": "9345",
            "name": "Páli",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.1667",
            "KSH kód": "19637",
            "Jogállás": "6",
            "Terület": "1958",
            "Népesség": "358",
            "Lakások": "211"
        },
        {
            "Ir. szám": "9346",
            "name": "Magyarkeresztúr",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.1667",
            "KSH kód": "13912",
            "Jogállás": "6",
            "Terület": "1674",
            "Népesség": "447",
            "Lakások": "226"
        },
        {
            "Ir. szám": "9346",
            "name": "Vadosfa",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.1333",
            "KSH kód": "06239",
            "Jogállás": "6",
            "Terület": "419",
            "Népesség": "72",
            "Lakások": "44"
        },
        {
            "Ir. szám": "9346",
            "name": "Zsebeháza",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.2",
            "KSH kód": "16869",
            "Jogállás": "6",
            "Terület": "465",
            "Népesség": "124",
            "Lakások": "72"
        },
        {
            "Ir. szám": "9351",
            "name": "Babót",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "17.0833",
            "KSH kód": "15042",
            "Jogállás": "6",
            "Terület": "2167",
            "Népesség": "1109",
            "Lakások": "474"
        },
        {
            "Ir. szám": "9352",
            "name": "Veszkény",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.0833",
            "KSH kód": "04589",
            "Jogállás": "6",
            "Terület": "1035",
            "Népesség": "922",
            "Lakások": "386"
        },
        {
            "Ir. szám": "9353",
            "name": "Szárföld",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "17.1167",
            "KSH kód": "15714",
            "Jogállás": "6",
            "Terület": "1712",
            "Népesség": "894",
            "Lakások": "399"
        },
        {
            "Ir. szám": "9354",
            "name": "Osli",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "17.0833",
            "KSH kód": "15121",
            "Jogállás": "6",
            "Terület": "1880",
            "Népesség": "884",
            "Lakások": "377"
        },
        {
            "Ir. szám": "9361",
            "name": "Hövej",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "17.0167",
            "KSH kód": "10029",
            "Jogállás": "6",
            "Terület": "852",
            "Népesség": "314",
            "Lakások": "160"
        },
        {
            "Ir. szám": "9362",
            "name": "Himod",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "17.0167",
            "KSH kód": "04020",
            "Jogállás": "6",
            "Terület": "2270",
            "Népesség": "633",
            "Lakások": "393"
        },
        {
            "Ir. szám": "9363",
            "name": "Gyóró",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "17.0167",
            "KSH kód": "23843",
            "Jogállás": "6",
            "Terület": "1164",
            "Népesség": "409",
            "Lakások": "170"
        },
        {
            "Ir. szám": "9364",
            "name": "Cirák",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "17.0333",
            "KSH kód": "15954",
            "Jogállás": "6",
            "Terület": "1176",
            "Népesség": "588",
            "Lakások": "270"
        },
        {
            "Ir. szám": "9365",
            "name": "Dénesfa",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "17.0333",
            "KSH kód": "32595",
            "Jogállás": "6",
            "Terület": "1754",
            "Népesség": "353",
            "Lakások": "172"
        },
        {
            "Ir. szám": "9371",
            "name": "Vitnyéd",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "16.9833",
            "KSH kód": "25797",
            "Jogállás": "6",
            "Terület": "3225",
            "Népesség": "1370",
            "Lakások": "509"
        },
        {
            "Ir. szám": "9372",
            "name": "Csapod",
            "Megyekód": "GS",
            "Lat": "47.5167",
            "Long": "16.9167",
            "KSH kód": "08563",
            "Jogállás": "6",
            "Terület": "2918",
            "Népesség": "519",
            "Lakások": "274"
        },
        {
            "Ir. szám": "9373",
            "name": "Pusztacsalád",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "16.9",
            "KSH kód": "31370",
            "Jogállás": "6",
            "Terület": "2406",
            "Népesség": "255",
            "Lakások": "134"
        },
        {
            "Ir. szám": "9374",
            "name": "Iván",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "16.9167",
            "KSH kód": "31635",
            "Jogállás": "6",
            "Terület": "5474",
            "Népesség": "1321",
            "Lakások": "466"
        },
        {
            "Ir. szám": "9375",
            "name": "Répceszemere",
            "Megyekód": "GS",
            "Lat": "47.4333",
            "Long": "16.9833",
            "KSH kód": "14438",
            "Jogállás": "6",
            "Terület": "942",
            "Népesség": "284",
            "Lakások": "144"
        },
        {
            "Ir. szám": "9375",
            "name": "Csáfordjánosfa",
            "Megyekód": "GS",
            "Lat": "47.4167",
            "Long": "16.95",
            "KSH kód": "10047",
            "Jogállás": "6",
            "Terület": "528",
            "Népesség": "214",
            "Lakások": "105"
        },
        {
            "Ir. szám": "9375",
            "name": "Csér",
            "Megyekód": "GS",
            "Lat": "47.4167",
            "Long": "16.9333",
            "KSH kód": "30191",
            "Jogállás": "6",
            "Terület": "294",
            "Népesség": "23",
            "Lakások": "28"
        },
        {
            "Ir. szám": "9400",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.672",
            "Long": "16.5852",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9401",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9402",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9403",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9404",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9405",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9406",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9407",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9408",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9409",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.6",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9421",
            "name": "Fertőrákos",
            "Megyekód": "GS",
            "Lat": "47.7167",
            "Long": "16.65",
            "KSH kód": "12414",
            "Jogállás": "6",
            "Terület": "2190",
            "Népesség": "2172",
            "Lakások": "860"
        },
        {
            "Ir. szám": "9422",
            "name": "Harka",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.6",
            "KSH kód": "07649",
            "Jogállás": "6",
            "Terület": "1100",
            "Népesség": "1728",
            "Lakások": "535"
        },
        {
            "Ir. szám": "9423",
            "name": "Ágfalva",
            "Megyekód": "GS",
            "Lat": "47.6833",
            "Long": "16.5167",
            "KSH kód": "04880",
            "Jogállás": "6",
            "Terület": "1308",
            "Népesség": "2119",
            "Lakások": "621"
        },
        {
            "Ir. szám": "9431",
            "name": "Fertőd",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.8667",
            "KSH kód": "09885",
            "Jogállás": "4",
            "Terület": "4856",
            "Népesség": "3474",
            "Lakások": "1433"
        },
        {
            "Ir. szám": "9433",
            "name": "Fertőd",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.8667",
            "KSH kód": "09885",
            "Jogállás": "4",
            "Terület": "4856",
            "Népesség": "3474",
            "Lakások": "1433"
        },
        {
            "Ir. szám": "9434",
            "name": "Fertőd",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.8667",
            "KSH kód": "09885",
            "Jogállás": "4",
            "Terület": "4856",
            "Népesség": "3474",
            "Lakások": "1433"
        },
        {
            "Ir. szám": "9435",
            "name": "Sarród",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.8667",
            "KSH kód": "30021",
            "Jogállás": "6",
            "Terület": "4007",
            "Népesség": "1112",
            "Lakások": "433"
        },
        {
            "Ir. szám": "9436",
            "name": "Fertőszéplak",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.8333",
            "KSH kód": "31440",
            "Jogállás": "6",
            "Terület": "2178",
            "Népesség": "1254",
            "Lakások": "479"
        },
        {
            "Ir. szám": "9437",
            "name": "Hegykő",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.8",
            "KSH kód": "18403",
            "Jogállás": "6",
            "Terület": "2684",
            "Népesség": "1322",
            "Lakások": "535"
        },
        {
            "Ir. szám": "9438",
            "name": "Sarród",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.8667",
            "KSH kód": "30021",
            "Jogállás": "6",
            "Terület": "4007",
            "Népesség": "1112",
            "Lakások": "433"
        },
        {
            "Ir. szám": "9441",
            "name": "Agyagosszergény",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.95",
            "KSH kód": "29407",
            "Jogállás": "6",
            "Terület": "1989",
            "Népesség": "896",
            "Lakások": "373"
        },
        {
            "Ir. szám": "9442",
            "name": "Fertőendréd",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "16.9167",
            "KSH kód": "09487",
            "Jogállás": "6",
            "Terület": "1509",
            "Népesség": "610",
            "Lakások": "242"
        },
        {
            "Ir. szám": "9443",
            "name": "Petőháza",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "16.9",
            "KSH kód": "22831",
            "Jogállás": "6",
            "Terület": "264",
            "Népesség": "1061",
            "Lakások": "423"
        },
        {
            "Ir. szám": "9444",
            "name": "Fertőszentmiklós",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "16.8833",
            "KSH kód": "15343",
            "Jogállás": "4",
            "Terület": "3940",
            "Népesség": "3802",
            "Lakások": "1466"
        },
        {
            "Ir. szám": "9451",
            "name": "Röjtökmuzsaj",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "16.8333",
            "KSH kód": "11068",
            "Jogállás": "6",
            "Terület": "1586",
            "Népesség": "444",
            "Lakások": "206"
        },
        {
            "Ir. szám": "9451",
            "name": "Ebergőc",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "16.8167",
            "KSH kód": "26347",
            "Jogállás": "6",
            "Terület": "502",
            "Népesség": "154",
            "Lakások": "99"
        },
        {
            "Ir. szám": "9461",
            "name": "Lövő",
            "Megyekód": "GS",
            "Lat": "47.5",
            "Long": "16.7833",
            "KSH kód": "31194",
            "Jogállás": "6",
            "Terület": "1747",
            "Népesség": "1463",
            "Lakások": "516"
        },
        {
            "Ir. szám": "9462",
            "name": "Völcsej",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "16.7667",
            "KSH kód": "16319",
            "Jogállás": "6",
            "Terület": "932",
            "Népesség": "392",
            "Lakások": "168"
        },
        {
            "Ir. szám": "9463",
            "name": "Sopronhorpács",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "16.7333",
            "KSH kód": "29090",
            "Jogállás": "6",
            "Terület": "2069",
            "Népesség": "826",
            "Lakások": "323"
        },
        {
            "Ir. szám": "9464",
            "name": "Und",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "16.7",
            "KSH kód": "20792",
            "Jogállás": "6",
            "Terület": "679",
            "Népesség": "335",
            "Lakások": "146"
        },
        {
            "Ir. szám": "9471",
            "name": "Nemeskér",
            "Megyekód": "GS",
            "Lat": "47.4833",
            "Long": "16.8",
            "KSH kód": "29328",
            "Jogállás": "6",
            "Terület": "642",
            "Népesség": "228",
            "Lakások": "98"
        },
        {
            "Ir. szám": "9472",
            "name": "Újkér",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "16.8167",
            "KSH kód": "19673",
            "Jogállás": "6",
            "Terület": "3238",
            "Népesség": "974",
            "Lakások": "436"
        },
        {
            "Ir. szám": "9473",
            "name": "Egyházasfalu",
            "Megyekód": "GS",
            "Lat": "47.4667",
            "Long": "16.7667",
            "KSH kód": "15237",
            "Jogállás": "6",
            "Terület": "2164",
            "Népesség": "851",
            "Lakások": "354"
        },
        {
            "Ir. szám": "9474",
            "name": "Szakony",
            "Megyekód": "GS",
            "Lat": "47.4333",
            "Long": "16.7167",
            "KSH kód": "11369",
            "Jogállás": "6",
            "Terület": "1350",
            "Népesség": "456",
            "Lakások": "239"
        },
        {
            "Ir. szám": "9474",
            "name": "Gyalóka",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "16.7",
            "KSH kód": "17969",
            "Jogállás": "6",
            "Terület": "393",
            "Népesség": "73",
            "Lakások": "51"
        },
        {
            "Ir. szám": "9475",
            "name": "Répcevis",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "16.6833",
            "KSH kód": "07746",
            "Jogállás": "6",
            "Terület": "609",
            "Népesség": "356",
            "Lakások": "161"
        },
        {
            "Ir. szám": "9476",
            "name": "Zsira",
            "Megyekód": "GS",
            "Lat": "47.45",
            "Long": "16.6833",
            "KSH kód": "04622",
            "Jogállás": "6",
            "Terület": "1473",
            "Népesség": "737",
            "Lakások": "234"
        },
        {
            "Ir. szám": "9481",
            "name": "Pinnye",
            "Megyekód": "GS",
            "Lat": "47.5833",
            "Long": "16.7667",
            "KSH kód": "14687",
            "Jogállás": "6",
            "Terület": "865",
            "Népesség": "342",
            "Lakások": "145"
        },
        {
            "Ir. szám": "9482",
            "name": "Nagylózs",
            "Megyekód": "GS",
            "Lat": "47.5667",
            "Long": "16.7833",
            "KSH kód": "32939",
            "Jogállás": "6",
            "Terület": "1925",
            "Népesség": "953",
            "Lakások": "274"
        },
        {
            "Ir. szám": "9483",
            "name": "Sopronkövesd",
            "Megyekód": "GS",
            "Lat": "47.55",
            "Long": "16.75",
            "KSH kód": "25724",
            "Jogállás": "6",
            "Terület": "2681",
            "Népesség": "1168",
            "Lakások": "462"
        },
        {
            "Ir. szám": "9484",
            "name": "Pereszteg",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "16.7333",
            "KSH kód": "23773",
            "Jogállás": "6",
            "Terület": "2250",
            "Népesség": "1382",
            "Lakások": "493"
        },
        {
            "Ir. szám": "9485",
            "name": "Nagycenk",
            "Megyekód": "GS",
            "Lat": "47.6",
            "Long": "16.7",
            "KSH kód": "02495",
            "Jogállás": "5",
            "Terület": "1945",
            "Népesség": "1847",
            "Lakások": "731"
        },
        {
            "Ir. szám": "9491",
            "name": "Hidegség",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.75",
            "KSH kód": "23375",
            "Jogállás": "6",
            "Terület": "1690",
            "Népesség": "352",
            "Lakások": "154"
        },
        {
            "Ir. szám": "9492",
            "name": "Fertőhomok",
            "Megyekód": "GS",
            "Lat": "47.6167",
            "Long": "16.7667",
            "KSH kód": "10658",
            "Jogállás": "6",
            "Terület": "1261",
            "Népesség": "583",
            "Lakások": "221"
        },
        {
            "Ir. szám": "9493",
            "name": "Fertőboz",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.7",
            "KSH kód": "11253",
            "Jogállás": "6",
            "Terület": "1363",
            "Népesség": "249",
            "Lakások": "106"
        },
        {
            "Ir. szám": "9494",
            "name": "Fertőboz",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.7",
            "KSH kód": "11253",
            "Jogállás": "6",
            "Terület": "1363",
            "Népesség": "249",
            "Lakások": "106"
        },
        {
            "Ir. szám": "9495",
            "name": "Kópháza",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.65",
            "KSH kód": "06895",
            "Jogállás": "6",
            "Terület": "873",
            "Népesség": "2001",
            "Lakások": "662"
        },
        {
            "Ir. szám": "9500",
            "name": "Celldömölk",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "17.15",
            "KSH kód": "27094",
            "Jogállás": "4",
            "Terület": "5239",
            "Népesség": "11247",
            "Lakások": "4723"
        },
        {
            "Ir. szám": "9501",
            "name": "Celldömölk",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "17.15",
            "KSH kód": "27094",
            "Jogállás": "4",
            "Terület": "5239",
            "Népesség": "11247",
            "Lakások": "4723"
        },
        {
            "Ir. szám": "9502",
            "name": "Celldömölk",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "17.15",
            "KSH kód": "27094",
            "Jogállás": "4",
            "Terület": "5239",
            "Népesség": "11247",
            "Lakások": "4723"
        },
        {
            "Ir. szám": "9503",
            "name": "Celldömölk",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "17.15",
            "KSH kód": "27094",
            "Jogállás": "4",
            "Terület": "5239",
            "Népesség": "11247",
            "Lakások": "4723"
        },
        {
            "Ir. szám": "9511",
            "name": "Kemenesmihályfa",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "17.1167",
            "KSH kód": "13426",
            "Jogállás": "6",
            "Terület": "1781",
            "Népesség": "527",
            "Lakások": "231"
        },
        {
            "Ir. szám": "9512",
            "name": "Ostffyasszonyfa",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "17.05",
            "KSH kód": "32629",
            "Jogállás": "6",
            "Terület": "3431",
            "Népesség": "793",
            "Lakások": "390"
        },
        {
            "Ir. szám": "9513",
            "name": "Csönge",
            "Megyekód": "VA",
            "Lat": "47.3361",
            "Long": "17.1139",
            "KSH kód": "22390",
            "Jogállás": "6",
            "Terület": "2527",
            "Népesség": "379",
            "Lakások": "198"
        },
        {
            "Ir. szám": "9514",
            "name": "Kenyeri",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "17.0833",
            "KSH kód": "09937",
            "Jogállás": "6",
            "Terület": "3396",
            "Népesség": "869",
            "Lakások": "445"
        },
        {
            "Ir. szám": "9515",
            "name": "Pápoc",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "17.1333",
            "KSH kód": "14067",
            "Jogállás": "6",
            "Terület": "3132",
            "Népesség": "334",
            "Lakások": "303"
        },
        {
            "Ir. szám": "9516",
            "name": "Vönöck",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "17.1667",
            "KSH kód": "03142",
            "Jogállás": "6",
            "Terület": "1760",
            "Népesség": "749",
            "Lakások": "340"
        },
        {
            "Ir. szám": "9517",
            "name": "Kemenessömjén",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "17.1333",
            "KSH kód": "24484",
            "Jogállás": "6",
            "Terület": "1605",
            "Népesség": "610",
            "Lakások": "235"
        },
        {
            "Ir. szám": "9521",
            "name": "Kemenesszentmárton",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "17.1667",
            "KSH kód": "20084",
            "Jogállás": "6",
            "Terület": "429",
            "Népesség": "205",
            "Lakások": "96"
        },
        {
            "Ir. szám": "9522",
            "name": "Kemenesmagasi",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "17.2167",
            "KSH kód": "12247",
            "Jogállás": "6",
            "Terület": "3319",
            "Népesség": "872",
            "Lakások": "439"
        },
        {
            "Ir. szám": "9523",
            "name": "Szergény",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "17.2667",
            "KSH kód": "27793",
            "Jogállás": "6",
            "Terület": "1561",
            "Népesség": "339",
            "Lakások": "173"
        },
        {
            "Ir. szám": "9531",
            "name": "Mersevát",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "17.2167",
            "KSH kód": "14809",
            "Jogállás": "6",
            "Terület": "1054",
            "Népesség": "573",
            "Lakások": "225"
        },
        {
            "Ir. szám": "9532",
            "name": "Külsővat",
            "Megyekód": "VE",
            "Lat": "47.3",
            "Long": "17.2333",
            "KSH kód": "16142",
            "Jogállás": "6",
            "Terület": "1931",
            "Népesség": "788",
            "Lakások": "266"
        },
        {
            "Ir. szám": "9533",
            "name": "Nemesszalók",
            "Megyekód": "VE",
            "Lat": "47.2833",
            "Long": "17.3",
            "KSH kód": "21759",
            "Jogállás": "6",
            "Terület": "2056",
            "Népesség": "952",
            "Lakások": "359"
        },
        {
            "Ir. szám": "9534",
            "name": "Vinár",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "17.2833",
            "KSH kód": "24651",
            "Jogállás": "6",
            "Terület": "460",
            "Népesség": "248",
            "Lakások": "93"
        },
        {
            "Ir. szám": "9534",
            "name": "Marcalgergelyi",
            "Megyekód": "VE",
            "Lat": "47.3167",
            "Long": "17.2667",
            "KSH kód": "29294",
            "Jogállás": "6",
            "Terület": "776",
            "Népesség": "419",
            "Lakások": "176"
        },
        {
            "Ir. szám": "9541",
            "name": "Vinár",
            "Megyekód": "VA",
            "Lat": "47.125",
            "Long": "17.175",
            "KSH kód": "24651",
            "Jogállás": "6",
            "Terület": "460",
            "Népesség": "248",
            "Lakások": "93"
        },
        {
            "Ir. szám": "9542",
            "name": "Nemeskocs",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "17.1833",
            "KSH kód": "02839",
            "Jogállás": "6",
            "Terület": "793",
            "Népesség": "380",
            "Lakások": "147"
        },
        {
            "Ir. szám": "9542",
            "name": "Boba",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "17.1833",
            "KSH kód": "29203",
            "Jogállás": "6",
            "Terület": "1095",
            "Népesség": "837",
            "Lakások": "328"
        },
        {
            "Ir. szám": "9544",
            "name": "Kemenespálfa",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "17.1833",
            "KSH kód": "20996",
            "Jogállás": "6",
            "Terület": "1372",
            "Népesség": "442",
            "Lakások": "166"
        },
        {
            "Ir. szám": "9545",
            "name": "Jánosháza",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "17.1667",
            "KSH kód": "11679",
            "Jogállás": "5",
            "Terület": "2341",
            "Népesség": "2515",
            "Lakások": "1095"
        },
        {
            "Ir. szám": "9547",
            "name": "Karakó",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "17.2",
            "KSH kód": "10913",
            "Jogállás": "6",
            "Terület": "1035",
            "Népesség": "197",
            "Lakások": "111"
        },
        {
            "Ir. szám": "9548",
            "name": "Nemeskeresztúr",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "17.2",
            "KSH kód": "03674",
            "Jogállás": "6",
            "Terület": "1217",
            "Népesség": "271",
            "Lakások": "173"
        },
        {
            "Ir. szám": "9549",
            "name": "Keléd",
            "Megyekód": "VA",
            "Lat": "47.0833",
            "Long": "17.1167",
            "KSH kód": "32036",
            "Jogállás": "6",
            "Terület": "872",
            "Népesség": "72",
            "Lakások": "60"
        },
        {
            "Ir. szám": "9551",
            "name": "Mesteri",
            "Megyekód": "VA",
            "Lat": "47.2167",
            "Long": "17.0833",
            "KSH kód": "15723",
            "Jogállás": "6",
            "Terület": "1169",
            "Népesség": "232",
            "Lakások": "155"
        },
        {
            "Ir. szám": "9552",
            "name": "Vásárosmiske",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "17.0667",
            "KSH kód": "09195",
            "Jogállás": "6",
            "Terület": "1342",
            "Népesség": "361",
            "Lakások": "178"
        },
        {
            "Ir. szám": "9553",
            "name": "Köcsk",
            "Megyekód": "VA",
            "Lat": "47.1833",
            "Long": "17.0833",
            "KSH kód": "04190",
            "Jogállás": "6",
            "Terület": "1258",
            "Népesség": "284",
            "Lakások": "169"
        },
        {
            "Ir. szám": "9553",
            "name": "Kemeneskápolna",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "17.1167",
            "KSH kód": "16911",
            "Jogállás": "6",
            "Terület": "497",
            "Népesség": "88",
            "Lakások": "67"
        },
        {
            "Ir. szám": "9554",
            "name": "Borgáta",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "17.0833",
            "KSH kód": "03090",
            "Jogállás": "6",
            "Terület": "621",
            "Népesség": "149",
            "Lakások": "101"
        },
        {
            "Ir. szám": "9554",
            "name": "Egyházashetye",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "17.1167",
            "KSH kód": "10232",
            "Jogállás": "6",
            "Terület": "1320",
            "Népesség": "346",
            "Lakások": "177"
        },
        {
            "Ir. szám": "9555",
            "name": "Kissomlyó",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "17.1",
            "KSH kód": "05953",
            "Jogállás": "6",
            "Terület": "858",
            "Népesség": "243",
            "Lakások": "148"
        },
        {
            "Ir. szám": "9556",
            "name": "Duka",
            "Megyekód": "VA",
            "Lat": "47.181",
            "Long": "17.0929",
            "KSH kód": "02927",
            "Jogállás": "6",
            "Terület": "1524",
            "Népesség": "236",
            "Lakások": "166"
        },
        {
            "Ir. szám": "9561",
            "name": "Nagysimonyi",
            "Megyekód": "VA",
            "Lat": "47.2667",
            "Long": "17.0667",
            "KSH kód": "26143",
            "Jogállás": "6",
            "Terület": "1567",
            "Népesség": "976",
            "Lakások": "426"
        },
        {
            "Ir. szám": "9561",
            "name": "Tokorcs",
            "Megyekód": "VA",
            "Lat": "47.2667",
            "Long": "17.1",
            "KSH kód": "09229",
            "Jogállás": "6",
            "Terület": "640",
            "Népesség": "333",
            "Lakások": "149"
        },
        {
            "Ir. szám": "9600",
            "name": "Sárvár",
            "Megyekód": "VA",
            "Lat": "47.255",
            "Long": "16.944",
            "KSH kód": "21306",
            "Jogállás": "4",
            "Terület": "6465",
            "Népesség": "15011",
            "Lakások": "6202"
        },
        {
            "Ir. szám": "9601",
            "name": "Sárvár",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.9333",
            "KSH kód": "21306",
            "Jogállás": "4",
            "Terület": "6465",
            "Népesség": "15011",
            "Lakások": "6202"
        },
        {
            "Ir. szám": "9602",
            "name": "Sárvár",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.9333",
            "KSH kód": "21306",
            "Jogállás": "4",
            "Terület": "6465",
            "Népesség": "15011",
            "Lakások": "6202"
        },
        {
            "Ir. szám": "9608",
            "name": "Sárvár",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.9333",
            "KSH kód": "21306",
            "Jogállás": "4",
            "Terület": "6465",
            "Népesség": "15011",
            "Lakások": "6202"
        },
        {
            "Ir. szám": "9609",
            "name": "Sárvár",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.9333",
            "KSH kód": "21306",
            "Jogállás": "4",
            "Terület": "6465",
            "Népesség": "15011",
            "Lakások": "6202"
        },
        {
            "Ir. szám": "9611",
            "name": "Csénye",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.8667",
            "KSH kód": "09070",
            "Jogállás": "6",
            "Terület": "1912",
            "Népesség": "723",
            "Lakások": "297"
        },
        {
            "Ir. szám": "9612",
            "name": "Porpác",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.8",
            "KSH kód": "29896",
            "Jogállás": "6",
            "Terület": "619",
            "Népesség": "161",
            "Lakások": "77"
        },
        {
            "Ir. szám": "9612",
            "name": "Bögöt",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "16.8",
            "KSH kód": "11651",
            "Jogállás": "6",
            "Terület": "499",
            "Népesség": "387",
            "Lakások": "166"
        },
        {
            "Ir. szám": "9621",
            "name": "Ölbő",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "16.8667",
            "KSH kód": "12043",
            "Jogállás": "6",
            "Terület": "2353",
            "Népesség": "751",
            "Lakások": "308"
        },
        {
            "Ir. szám": "9622",
            "name": "Szeleste",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "16.8333",
            "KSH kód": "33172",
            "Jogállás": "6",
            "Terület": "2075",
            "Népesség": "701",
            "Lakások": "279"
        },
        {
            "Ir. szám": "9623",
            "name": "Répceszentgyörgy",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.85",
            "KSH kód": "28626",
            "Jogállás": "6",
            "Terület": "575",
            "Népesség": "115",
            "Lakások": "81"
        },
        {
            "Ir. szám": "9624",
            "name": "Chernelházadamonya",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.85",
            "KSH kód": "09876",
            "Jogállás": "6",
            "Terület": "785",
            "Népesség": "202",
            "Lakások": "105"
        },
        {
            "Ir. szám": "9625",
            "name": "Bő",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.8",
            "KSH kód": "05476",
            "Jogállás": "6",
            "Terület": "1065",
            "Népesség": "646",
            "Lakások": "309"
        },
        {
            "Ir. szám": "9625",
            "name": "Gór",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.8",
            "KSH kód": "11156",
            "Jogállás": "6",
            "Terület": "1099",
            "Népesség": "306",
            "Lakások": "174"
        },
        {
            "Ir. szám": "9631",
            "name": "Hegyfalu",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.8833",
            "KSH kód": "32188",
            "Jogállás": "6",
            "Terület": "1189",
            "Népesség": "781",
            "Lakások": "312"
        },
        {
            "Ir. szám": "9632",
            "name": "Sajtoskál",
            "Megyekód": "VA",
            "Lat": "47.4",
            "Long": "16.8667",
            "KSH kód": "10579",
            "Jogállás": "6",
            "Terület": "941",
            "Népesség": "419",
            "Lakások": "155"
        },
        {
            "Ir. szám": "9633",
            "name": "Simaság",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "16.85",
            "KSH kód": "26763",
            "Jogállás": "6",
            "Terület": "1013",
            "Népesség": "543",
            "Lakások": "246"
        },
        {
            "Ir. szám": "9634",
            "name": "Iklanberény",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "16.8",
            "KSH kód": "29504",
            "Jogállás": "6",
            "Terület": "296",
            "Népesség": "31",
            "Lakások": "36"
        },
        {
            "Ir. szám": "9634",
            "name": "Lócs",
            "Megyekód": "VA",
            "Lat": "47.4",
            "Long": "16.8167",
            "KSH kód": "29081",
            "Jogállás": "6",
            "Terület": "513",
            "Népesség": "118",
            "Lakások": "97"
        },
        {
            "Ir. szám": "9635",
            "name": "Zsédeny",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.9",
            "KSH kód": "21643",
            "Jogállás": "6",
            "Terület": "643",
            "Népesség": "200",
            "Lakások": "109"
        },
        {
            "Ir. szám": "9636",
            "name": "Pósfa",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.85",
            "KSH kód": "15671",
            "Jogállás": "6",
            "Terület": "535",
            "Népesség": "290",
            "Lakások": "111"
        },
        {
            "Ir. szám": "9641",
            "name": "Rábapaty",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "16.9333",
            "KSH kód": "26073",
            "Jogállás": "6",
            "Terület": "2141",
            "Népesség": "1760",
            "Lakások": "662"
        },
        {
            "Ir. szám": "9643",
            "name": "Jákfa",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.9667",
            "KSH kód": "06406",
            "Jogállás": "6",
            "Terület": "2011",
            "Népesség": "540",
            "Lakások": "242"
        },
        {
            "Ir. szám": "9651",
            "name": "Uraiújfalu",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.9833",
            "KSH kód": "21537",
            "Jogállás": "6",
            "Terület": "1908",
            "Népesség": "869",
            "Lakások": "380"
        },
        {
            "Ir. szám": "9652",
            "name": "Nick",
            "Megyekód": "VA",
            "Lat": "47.4",
            "Long": "17.0167",
            "KSH kód": "10843",
            "Jogállás": "6",
            "Terület": "1140",
            "Népesség": "521",
            "Lakások": "221"
        },
        {
            "Ir. szám": "9653",
            "name": "Répcelak",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "17.0167",
            "KSH kód": "30881",
            "Jogállás": "4",
            "Terület": "1382",
            "Népesség": "2443",
            "Lakások": "1057"
        },
        {
            "Ir. szám": "9654",
            "name": "Csánig",
            "Megyekód": "VA",
            "Lat": "47.3944",
            "Long": "17.0056",
            "KSH kód": "26772",
            "Jogállás": "6",
            "Terület": "819",
            "Népesség": "390",
            "Lakások": "173"
        },
        {
            "Ir. szám": "9661",
            "name": "Vasegerszeg",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.9167",
            "KSH kód": "15334",
            "Jogállás": "6",
            "Terület": "1272",
            "Népesség": "398",
            "Lakások": "150"
        },
        {
            "Ir. szám": "9662",
            "name": "Tompaládony",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.9",
            "KSH kód": "12335",
            "Jogállás": "6",
            "Terület": "944",
            "Népesség": "316",
            "Lakások": "146"
        },
        {
            "Ir. szám": "9662",
            "name": "Mesterháza",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.8667",
            "KSH kód": "31875",
            "Jogállás": "6",
            "Terület": "419",
            "Népesség": "156",
            "Lakások": "82"
        },
        {
            "Ir. szám": "9663",
            "name": "Nemesládony",
            "Megyekód": "VA",
            "Lat": "47.4",
            "Long": "16.8833",
            "KSH kód": "26240",
            "Jogállás": "6",
            "Terület": "567",
            "Népesség": "124",
            "Lakások": "96"
        },
        {
            "Ir. szám": "9664",
            "name": "Nagygeresd",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.9167",
            "KSH kód": "09283",
            "Jogállás": "6",
            "Terület": "940",
            "Népesség": "268",
            "Lakások": "152"
        },
        {
            "Ir. szám": "9665",
            "name": "Vámoscsalád",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.9667",
            "KSH kód": "31051",
            "Jogállás": "6",
            "Terület": "1178",
            "Népesség": "353",
            "Lakások": "158"
        },
        {
            "Ir. szám": "9671",
            "name": "Sitke",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "17.0333",
            "KSH kód": "30748",
            "Jogállás": "6",
            "Terület": "2720",
            "Népesség": "651",
            "Lakások": "306"
        },
        {
            "Ir. szám": "9672",
            "name": "Gérce",
            "Megyekód": "VA",
            "Lat": "47.2167",
            "Long": "17.0167",
            "KSH kód": "26152",
            "Jogállás": "6",
            "Terület": "1830",
            "Népesség": "1142",
            "Lakások": "503"
        },
        {
            "Ir. szám": "9673",
            "name": "Káld",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "17.05",
            "KSH kód": "29957",
            "Jogállás": "6",
            "Terület": "4244",
            "Népesség": "1059",
            "Lakások": "475"
        },
        {
            "Ir. szám": "9674",
            "name": "Vashosszúfalu",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "17.0667",
            "KSH kód": "20349",
            "Jogállás": "6",
            "Terület": "1392",
            "Népesség": "373",
            "Lakások": "214"
        },
        {
            "Ir. szám": "9675",
            "name": "Bögöte",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "17.0367",
            "KSH kód": "07296",
            "Jogállás": "6",
            "Terület": "2074",
            "Népesség": "312",
            "Lakások": "151"
        },
        {
            "Ir. szám": "9676",
            "name": "Hosszúpereszteg",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "17.0167",
            "KSH kód": "20880",
            "Jogállás": "6",
            "Terület": "3700",
            "Népesség": "652",
            "Lakások": "407"
        },
        {
            "Ir. szám": "9681",
            "name": "Sótony",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.95",
            "KSH kód": "22983",
            "Jogállás": "6",
            "Terület": "1493",
            "Népesség": "645",
            "Lakások": "288"
        },
        {
            "Ir. szám": "9682",
            "name": "Nyőgér",
            "Megyekód": "VA",
            "Lat": "47.1833",
            "Long": "16.9333",
            "KSH kód": "22318",
            "Jogállás": "6",
            "Terület": "1073",
            "Népesség": "317",
            "Lakások": "177"
        },
        {
            "Ir. szám": "9683",
            "name": "Bejcgyertyános",
            "Megyekód": "VA",
            "Lat": "47.15",
            "Long": "16.925",
            "KSH kód": "10384",
            "Jogállás": "6",
            "Terület": "3143",
            "Népesség": "476",
            "Lakások": "270"
        },
        {
            "Ir. szám": "9684",
            "name": "Egervölgy",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "16.9167",
            "KSH kód": "28796",
            "Jogállás": "6",
            "Terület": "886",
            "Népesség": "368",
            "Lakások": "224"
        },
        {
            "Ir. szám": "9685",
            "name": "Szemenye",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "16.9",
            "KSH kód": "27997",
            "Jogállás": "6",
            "Terület": "1180",
            "Népesség": "327",
            "Lakások": "194"
        },
        {
            "Ir. szám": "9700",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2448",
            "Long": "16.6168",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9701",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9702",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9703",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9704",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9705",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9706",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9707",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9708",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9709",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9710",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9719",
            "name": "Szombathely",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.6167",
            "KSH kód": "03009",
            "Jogállás": "1",
            "Terület": "9750",
            "Népesség": "79513",
            "Lakások": "33655"
        },
        {
            "Ir. szám": "9721",
            "name": "Gencsapáti",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "16.6",
            "KSH kód": "24183",
            "Jogállás": "6",
            "Terület": "2239",
            "Népesség": "2822",
            "Lakások": "925"
        },
        {
            "Ir. szám": "9722",
            "name": "Perenye",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "16.5833",
            "KSH kód": "13684",
            "Jogállás": "6",
            "Terület": "1622",
            "Népesség": "701",
            "Lakások": "223"
        },
        {
            "Ir. szám": "9723",
            "name": "Lukácsháza",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.5833",
            "KSH kód": "14021",
            "Jogállás": "6",
            "Terület": "936",
            "Népesség": "1059",
            "Lakások": "387"
        },
        {
            "Ir. szám": "9724",
            "name": "Gyöngyösfalu",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "16.5833",
            "KSH kód": "11943",
            "Jogállás": "6",
            "Terület": "965",
            "Népesség": "1132",
            "Lakások": "397"
        },
        {
            "Ir. szám": "9725",
            "name": "Kőszegszerdahely",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.5167",
            "KSH kód": "20109",
            "Jogállás": "6",
            "Terület": "737",
            "Népesség": "492",
            "Lakások": "202"
        },
        {
            "Ir. szám": "9725",
            "name": "Cák",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.5167",
            "KSH kód": "08271",
            "Jogállás": "6",
            "Terület": "648",
            "Népesség": "280",
            "Lakások": "109"
        },
        {
            "Ir. szám": "9725",
            "name": "Kőszegdoroszló",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.55",
            "KSH kód": "05740",
            "Jogállás": "6",
            "Terület": "965",
            "Népesség": "241",
            "Lakások": "104"
        },
        {
            "Ir. szám": "9726",
            "name": "Velem",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.5",
            "KSH kód": "26000",
            "Jogállás": "6",
            "Terület": "861",
            "Népesség": "342",
            "Lakások": "164"
        },
        {
            "Ir. szám": "9727",
            "name": "Bozsok",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.5",
            "KSH kód": "05023",
            "Jogállás": "6",
            "Terület": "2091",
            "Népesség": "342",
            "Lakások": "139"
        },
        {
            "Ir. szám": "9730",
            "name": "Kőszeg",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.55",
            "KSH kód": "16832",
            "Jogállás": "4",
            "Terület": "5466",
            "Népesség": "12032",
            "Lakások": "4600"
        },
        {
            "Ir. szám": "9731",
            "name": "Kőszeg",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.55",
            "KSH kód": "16832",
            "Jogállás": "4",
            "Terület": "5466",
            "Népesség": "12032",
            "Lakások": "4600"
        },
        {
            "Ir. szám": "9732",
            "name": "Kőszeg",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.55",
            "KSH kód": "16832",
            "Jogállás": "4",
            "Terület": "5466",
            "Népesség": "12032",
            "Lakások": "4600"
        },
        {
            "Ir. szám": "9733",
            "name": "Horvátzsidány",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "16.6167",
            "KSH kód": "16887",
            "Jogállás": "6",
            "Terület": "1217",
            "Népesség": "787",
            "Lakások": "323"
        },
        {
            "Ir. szám": "9733",
            "name": "Kiszsidány",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "16.65",
            "KSH kód": "15486",
            "Jogállás": "6",
            "Terület": "493",
            "Népesség": "101",
            "Lakások": "39"
        },
        {
            "Ir. szám": "9733",
            "name": "Ólmod",
            "Megyekód": "VA",
            "Lat": "47.4167",
            "Long": "16.6",
            "KSH kód": "22044",
            "Jogállás": "6",
            "Terület": "366",
            "Népesség": "93",
            "Lakások": "51"
        },
        {
            "Ir. szám": "9734",
            "name": "Peresznye",
            "Megyekód": "VA",
            "Lat": "47.4333",
            "Long": "16.65",
            "KSH kód": "08882",
            "Jogállás": "6",
            "Terület": "1073",
            "Népesség": "712",
            "Lakások": "196"
        },
        {
            "Ir. szám": "9735",
            "name": "Csepreg",
            "Megyekód": "VA",
            "Lat": "47.4",
            "Long": "16.7167",
            "KSH kód": "12140",
            "Jogállás": "4",
            "Terület": "4954",
            "Népesség": "3452",
            "Lakások": "1274"
        },
        {
            "Ir. szám": "9736",
            "name": "Tormásliget",
            "Megyekód": "VA",
            "Lat": "47.3846",
            "Long": "16.6321",
            "KSH kód": "34087",
            "Jogállás": "6",
            "Terület": "753",
            "Népesség": "337",
            "Lakások": "122"
        },
        {
            "Ir. szám": "9737",
            "name": "Bük",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.75",
            "KSH kód": "02431",
            "Jogállás": "4",
            "Terület": "2086",
            "Népesség": "3402",
            "Lakások": "1613"
        },
        {
            "Ir. szám": "9738",
            "name": "Tömörd",
            "Megyekód": "VA",
            "Lat": "47.3667",
            "Long": "16.6833",
            "KSH kód": "05166",
            "Jogállás": "6",
            "Terület": "1536",
            "Népesség": "276",
            "Lakások": "108"
        },
        {
            "Ir. szám": "9739",
            "name": "Pusztacsó",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.6333",
            "KSH kód": "10311",
            "Jogállás": "6",
            "Terület": "726",
            "Népesség": "173",
            "Lakások": "59"
        },
        {
            "Ir. szám": "9739",
            "name": "Kőszegpaty",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.65",
            "KSH kód": "26046",
            "Jogállás": "6",
            "Terület": "1225",
            "Népesség": "198",
            "Lakások": "77"
        },
        {
            "Ir. szám": "9739",
            "name": "Nemescsó",
            "Megyekód": "VA",
            "Lat": "47.35",
            "Long": "16.6167",
            "KSH kód": "23320",
            "Jogállás": "6",
            "Terület": "738",
            "Népesség": "319",
            "Lakások": "118"
        },
        {
            "Ir. szám": "9740",
            "name": "Pusztacsó",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.6333",
            "KSH kód": "10311",
            "Jogállás": "6",
            "Terület": "726",
            "Népesség": "173",
            "Lakások": "59"
        },
        {
            "Ir. szám": "9741",
            "name": "Vassurány",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "16.7",
            "KSH kód": "25982",
            "Jogállás": "6",
            "Terület": "1009",
            "Népesség": "810",
            "Lakások": "278"
        },
        {
            "Ir. szám": "9742",
            "name": "Salköveskút",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "16.7",
            "KSH kód": "18883",
            "Jogállás": "6",
            "Terület": "1320",
            "Népesség": "509",
            "Lakások": "192"
        },
        {
            "Ir. szám": "9743",
            "name": "Söpte",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "16.65",
            "KSH kód": "24800",
            "Jogállás": "6",
            "Terület": "1398",
            "Népesség": "808",
            "Lakások": "273"
        },
        {
            "Ir. szám": "9744",
            "name": "Vasasszonyfa",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "16.6667",
            "KSH kód": "20394",
            "Jogállás": "6",
            "Terület": "1065",
            "Népesség": "382",
            "Lakások": "155"
        },
        {
            "Ir. szám": "9745",
            "name": "Meszlen",
            "Megyekód": "VA",
            "Lat": "47.3333",
            "Long": "16.7",
            "KSH kód": "32832",
            "Jogállás": "6",
            "Terület": "1202",
            "Népesség": "230",
            "Lakások": "99"
        },
        {
            "Ir. szám": "9746",
            "name": "Acsád",
            "Megyekód": "VA",
            "Lat": "47.3167",
            "Long": "16.7333",
            "KSH kód": "07214",
            "Jogállás": "6",
            "Terület": "1434",
            "Népesség": "689",
            "Lakások": "185"
        },
        {
            "Ir. szám": "9747",
            "name": "Vasszilvágy",
            "Megyekód": "VA",
            "Lat": "47.3",
            "Long": "16.75",
            "KSH kód": "12104",
            "Jogállás": "6",
            "Terület": "1180",
            "Népesség": "414",
            "Lakások": "168"
        },
        {
            "Ir. szám": "9748",
            "name": "Vát",
            "Megyekód": "VA",
            "Lat": "47.2833",
            "Long": "16.7833",
            "KSH kód": "02246",
            "Jogállás": "6",
            "Terület": "2327",
            "Népesség": "696",
            "Lakások": "251"
        },
        {
            "Ir. szám": "9749",
            "name": "Nemesbőd",
            "Megyekód": "VA",
            "Lat": "47.2667",
            "Long": "16.7333",
            "KSH kód": "24509",
            "Jogállás": "6",
            "Terület": "914",
            "Népesség": "627",
            "Lakások": "240"
        },
        {
            "Ir. szám": "9751",
            "name": "Vép",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.7333",
            "KSH kód": "26426",
            "Jogállás": "5",
            "Terület": "3289",
            "Népesség": "3461",
            "Lakások": "1171"
        },
        {
            "Ir. szám": "9752",
            "name": "Bozzai",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.7667",
            "KSH kód": "06390",
            "Jogállás": "6",
            "Terület": "443",
            "Népesség": "320",
            "Lakások": "117"
        },
        {
            "Ir. szám": "9752",
            "name": "Kenéz",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.7833",
            "KSH kód": "02574",
            "Jogállás": "6",
            "Terület": "717",
            "Népesség": "282",
            "Lakások": "111"
        },
        {
            "Ir. szám": "9754",
            "name": "Pecöl",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.8333",
            "KSH kód": "14988",
            "Jogállás": "6",
            "Terület": "1726",
            "Népesség": "803",
            "Lakások": "302"
        },
        {
            "Ir. szám": "9754",
            "name": "Megyehíd",
            "Megyekód": "VA",
            "Lat": "47.2167",
            "Long": "16.8333",
            "KSH kód": "30872",
            "Jogállás": "6",
            "Terület": "604",
            "Népesség": "347",
            "Lakások": "124"
        },
        {
            "Ir. szám": "9756",
            "name": "Ikervár",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.9",
            "KSH kód": "11387",
            "Jogállás": "6",
            "Terület": "3534",
            "Népesség": "1865",
            "Lakások": "669"
        },
        {
            "Ir. szám": "9757",
            "name": "Meggyeskovácsi",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "16.8667",
            "KSH kód": "25760",
            "Jogállás": "6",
            "Terület": "2464",
            "Népesség": "745",
            "Lakások": "329"
        },
        {
            "Ir. szám": "9761",
            "name": "Táplánszentkereszt",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.7",
            "KSH kód": "32045",
            "Jogállás": "6",
            "Terület": "2008",
            "Népesség": "2555",
            "Lakások": "843"
        },
        {
            "Ir. szám": "9762",
            "name": "Tanakajd",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.7333",
            "KSH kód": "16212",
            "Jogállás": "6",
            "Terület": "1182",
            "Népesség": "791",
            "Lakások": "274"
        },
        {
            "Ir. szám": "9763",
            "name": "Vasszécseny",
            "Megyekód": "VA",
            "Lat": "47.1833",
            "Long": "16.7667",
            "KSH kód": "29373",
            "Jogállás": "6",
            "Terület": "1795",
            "Népesség": "1432",
            "Lakások": "488"
        },
        {
            "Ir. szám": "9764",
            "name": "Csempeszkopács",
            "Megyekód": "VA",
            "Lat": "47.15",
            "Long": "16.8",
            "KSH kód": "23816",
            "Jogállás": "6",
            "Terület": "540",
            "Népesség": "313",
            "Lakások": "118"
        },
        {
            "Ir. szám": "9766",
            "name": "Rábatöttös",
            "Megyekód": "VA",
            "Lat": "47.125",
            "Long": "16.8333",
            "KSH kód": "17996",
            "Jogállás": "6",
            "Terület": "648",
            "Népesség": "224",
            "Lakások": "121"
        },
        {
            "Ir. szám": "9766",
            "name": "Zsennye",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "16.8167",
            "KSH kód": "12292",
            "Jogállás": "6",
            "Terület": "502",
            "Népesség": "97",
            "Lakások": "57"
        },
        {
            "Ir. szám": "9766",
            "name": "Rum",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "16.85",
            "KSH kód": "10597",
            "Jogállás": "6",
            "Terület": "1685",
            "Népesség": "1263",
            "Lakások": "453"
        },
        {
            "Ir. szám": "9771",
            "name": "Balogunyom",
            "Megyekód": "VA",
            "Lat": "47.1667",
            "Long": "16.65",
            "KSH kód": "05102",
            "Jogállás": "6",
            "Terület": "1212",
            "Népesség": "1242",
            "Lakások": "425"
        },
        {
            "Ir. szám": "9772",
            "name": "Kisunyom",
            "Megyekód": "VA",
            "Lat": "47.15",
            "Long": "16.65",
            "KSH kód": "02501",
            "Jogállás": "6",
            "Terület": "1000",
            "Népesség": "414",
            "Lakások": "174"
        },
        {
            "Ir. szám": "9773",
            "name": "Sorokpolány",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "16.6667",
            "KSH kód": "29692",
            "Jogállás": "6",
            "Terület": "1329",
            "Népesség": "869",
            "Lakások": "280"
        },
        {
            "Ir. szám": "9774",
            "name": "Gyanógeregye",
            "Megyekód": "VA",
            "Lat": "47.1167",
            "Long": "16.7667",
            "KSH kód": "27030",
            "Jogállás": "6",
            "Terület": "707",
            "Népesség": "163",
            "Lakások": "67"
        },
        {
            "Ir. szám": "9774",
            "name": "Sorkikápolna",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "16.7",
            "KSH kód": "27960",
            "Jogállás": "6",
            "Terület": "886",
            "Népesség": "265",
            "Lakások": "103"
        },
        {
            "Ir. szám": "9774",
            "name": "Sorkifalud",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "16.7333",
            "KSH kód": "07171",
            "Jogállás": "6",
            "Terület": "1737",
            "Népesség": "654",
            "Lakások": "267"
        },
        {
            "Ir. szám": "9775",
            "name": "Nemeskolta",
            "Megyekód": "VA",
            "Lat": "47.15",
            "Long": "16.7667",
            "KSH kód": "24059",
            "Jogállás": "6",
            "Terület": "734",
            "Népesség": "371",
            "Lakások": "147"
        },
        {
            "Ir. szám": "9776",
            "name": "Püspökmolnári",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "16.8",
            "KSH kód": "07278",
            "Jogállás": "6",
            "Terület": "1510",
            "Népesség": "937",
            "Lakások": "384"
        },
        {
            "Ir. szám": "9777",
            "name": "Rábahídvég",
            "Megyekód": "VA",
            "Lat": "47.0667",
            "Long": "16.75",
            "KSH kód": "03197",
            "Jogállás": "6",
            "Terület": "2241",
            "Népesség": "977",
            "Lakások": "398"
        },
        {
            "Ir. szám": "9781",
            "name": "Egyházashollós",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.6833",
            "KSH kód": "30429",
            "Jogállás": "6",
            "Terület": "1904",
            "Népesség": "547",
            "Lakások": "261"
        },
        {
            "Ir. szám": "9782",
            "name": "Nemesrempehollós",
            "Megyekód": "VA",
            "Lat": "47.0833",
            "Long": "16.6833",
            "KSH kód": "09672",
            "Jogállás": "6",
            "Terület": "1205",
            "Népesség": "270",
            "Lakások": "155"
        },
        {
            "Ir. szám": "9783",
            "name": "Egyházasrádóc",
            "Megyekód": "VA",
            "Lat": "47.0833",
            "Long": "16.6167",
            "KSH kód": "25946",
            "Jogállás": "6",
            "Terület": "2479",
            "Népesség": "1327",
            "Lakások": "519"
        },
        {
            "Ir. szám": "9784",
            "name": "Rádóckölked",
            "Megyekód": "VA",
            "Lat": "47.0833",
            "Long": "16.6",
            "KSH kód": "07551",
            "Jogállás": "6",
            "Terület": "1895",
            "Népesség": "268",
            "Lakások": "133"
        },
        {
            "Ir. szám": "9784",
            "name": "Harasztifalu",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.55",
            "KSH kód": "05713",
            "Jogállás": "6",
            "Terület": "789",
            "Népesség": "166",
            "Lakások": "85"
        },
        {
            "Ir. szám": "9784",
            "name": "Nagykölked",
            "Megyekód": "VA",
            "Lat": "47.0667",
            "Long": "16.55",
            "KSH kód": "32920",
            "Jogállás": "6",
            "Terület": "978",
            "Népesség": "159",
            "Lakások": "71"
        },
        {
            "Ir. szám": "9789",
            "name": "Sé",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.5667",
            "KSH kód": "10278",
            "Jogállás": "6",
            "Terület": "603",
            "Népesség": "1413",
            "Lakások": "470"
        },
        {
            "Ir. szám": "9791",
            "name": "Dozmat",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.5167",
            "KSH kód": "16151",
            "Jogállás": "6",
            "Terület": "854",
            "Népesség": "232",
            "Lakások": "91"
        },
        {
            "Ir. szám": "9791",
            "name": "Torony",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.5333",
            "KSH kód": "29878",
            "Jogállás": "6",
            "Terület": "1283",
            "Népesség": "1942",
            "Lakások": "660"
        },
        {
            "Ir. szám": "9792",
            "name": "Bucsu",
            "Megyekód": "VA",
            "Lat": "47.2667",
            "Long": "16.5",
            "KSH kód": "32984",
            "Jogállás": "6",
            "Terület": "1654",
            "Népesség": "602",
            "Lakások": "214"
        },
        {
            "Ir. szám": "9793",
            "name": "Narda",
            "Megyekód": "VA",
            "Lat": "47.2333",
            "Long": "16.4667",
            "KSH kód": "02042",
            "Jogállás": "6",
            "Terület": "1035",
            "Népesség": "503",
            "Lakások": "193"
        },
        {
            "Ir. szám": "9794",
            "name": "Felsőcsatár",
            "Megyekód": "VA",
            "Lat": "47.2167",
            "Long": "16.45",
            "KSH kód": "13587",
            "Jogállás": "6",
            "Terület": "1790",
            "Népesség": "468",
            "Lakások": "190"
        },
        {
            "Ir. szám": "9795",
            "name": "Vaskeresztes",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.45",
            "KSH kód": "30702",
            "Jogállás": "6",
            "Terület": "912",
            "Népesség": "357",
            "Lakások": "127"
        },
        {
            "Ir. szám": "9796",
            "name": "Horvátlövő",
            "Megyekód": "VA",
            "Lat": "47.1833",
            "Long": "16.45",
            "KSH kód": "12733",
            "Jogállás": "6",
            "Terület": "616",
            "Népesség": "221",
            "Lakások": "70"
        },
        {
            "Ir. szám": "9796",
            "name": "Pornóapáti",
            "Megyekód": "VA",
            "Lat": "47.15",
            "Long": "16.4667",
            "KSH kód": "20367",
            "Jogállás": "6",
            "Terület": "1514",
            "Népesség": "404",
            "Lakások": "139"
        },
        {
            "Ir. szám": "9797",
            "name": "Nárai",
            "Megyekód": "VA",
            "Lat": "47.2",
            "Long": "16.5667",
            "KSH kód": "17367",
            "Jogállás": "6",
            "Terület": "1599",
            "Népesség": "1216",
            "Lakások": "431"
        },
        {
            "Ir. szám": "9798",
            "name": "Ják",
            "Megyekód": "VA",
            "Lat": "47.1333",
            "Long": "16.5833",
            "KSH kód": "13958",
            "Jogállás": "6",
            "Terület": "4495",
            "Népesség": "2575",
            "Lakások": "915"
        },
        {
            "Ir. szám": "9799",
            "name": "Szentpéterfa",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "16.4833",
            "KSH kód": "21254",
            "Jogállás": "6",
            "Terület": "3124",
            "Népesség": "988",
            "Lakások": "385"
        },
        {
            "Ir. szám": "9800",
            "name": "Vasvár",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.8",
            "KSH kód": "04695",
            "Jogállás": "4",
            "Terület": "5510",
            "Népesség": "4396",
            "Lakások": "1926"
        },
        {
            "Ir. szám": "9801",
            "name": "Vasvár",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.8",
            "KSH kód": "04695",
            "Jogállás": "4",
            "Terület": "5510",
            "Népesség": "4396",
            "Lakások": "1926"
        },
        {
            "Ir. szám": "9811",
            "name": "Andrásfa",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.8",
            "KSH kód": "12317",
            "Jogállás": "6",
            "Terület": "823",
            "Népesség": "318",
            "Lakások": "146"
        },
        {
            "Ir. szám": "9812",
            "name": "Telekes",
            "Megyekód": "VA",
            "Lat": "46.9389",
            "Long": "16.7725",
            "KSH kód": "29568",
            "Jogállás": "6",
            "Terület": "1081",
            "Népesség": "519",
            "Lakások": "197"
        },
        {
            "Ir. szám": "9813",
            "name": "Gersekarát",
            "Megyekód": "VA",
            "Lat": "46.9739",
            "Long": "16.7467",
            "KSH kód": "30942",
            "Jogállás": "6",
            "Terület": "1992",
            "Népesség": "709",
            "Lakások": "329"
        },
        {
            "Ir. szám": "9813",
            "name": "Sárfimizdó",
            "Megyekód": "VA",
            "Lat": "46.95",
            "Long": "16.7167",
            "KSH kód": "09788",
            "Jogállás": "6",
            "Terület": "746",
            "Népesség": "103",
            "Lakások": "64"
        },
        {
            "Ir. szám": "9814",
            "name": "Halastó",
            "Megyekód": "VA",
            "Lat": "46.95",
            "Long": "16.7",
            "KSH kód": "29452",
            "Jogállás": "6",
            "Terület": "565",
            "Népesség": "102",
            "Lakások": "73"
        },
        {
            "Ir. szám": "9821",
            "name": "Hegyhátszentpéter",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.8167",
            "KSH kód": "08226",
            "Jogállás": "6",
            "Terület": "684",
            "Népesség": "153",
            "Lakások": "92"
        },
        {
            "Ir. szám": "9821",
            "name": "Győrvár",
            "Megyekód": "VA",
            "Lat": "46.9878",
            "Long": "16.8417",
            "KSH kód": "09724",
            "Jogállás": "6",
            "Terület": "1659",
            "Népesség": "674",
            "Lakások": "289"
        },
        {
            "Ir. szám": "9823",
            "name": "Pácsony",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.8667",
            "KSH kód": "07162",
            "Jogállás": "6",
            "Terület": "1001",
            "Népesség": "279",
            "Lakások": "132"
        },
        {
            "Ir. szám": "9824",
            "name": "Olaszfa",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.8833",
            "KSH kód": "19743",
            "Jogállás": "6",
            "Terület": "1654",
            "Népesség": "470",
            "Lakások": "257"
        },
        {
            "Ir. szám": "9825",
            "name": "Oszkó",
            "Megyekód": "VA",
            "Lat": "47.0333",
            "Long": "16.8833",
            "KSH kód": "07667",
            "Jogállás": "6",
            "Terület": "2031",
            "Népesség": "670",
            "Lakások": "304"
        },
        {
            "Ir. szám": "9826",
            "name": "Petőmihályfa",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.7833",
            "KSH kód": "11572",
            "Jogállás": "6",
            "Terület": "995",
            "Népesség": "237",
            "Lakások": "107"
        },
        {
            "Ir. szám": "9831",
            "name": "Bérbaltavár",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.9667",
            "KSH kód": "32124",
            "Jogállás": "6",
            "Terület": "2567",
            "Népesség": "502",
            "Lakások": "308"
        },
        {
            "Ir. szám": "9832",
            "name": "Nagytilaj",
            "Megyekód": "VA",
            "Lat": "46.9733",
            "Long": "16.9653",
            "KSH kód": "24837",
            "Jogállás": "6",
            "Terület": "1566",
            "Népesség": "142",
            "Lakások": "134"
        },
        {
            "Ir. szám": "9833",
            "name": "Csehi",
            "Megyekód": "VA",
            "Lat": "47.0333",
            "Long": "16.95",
            "KSH kód": "19488",
            "Jogállás": "6",
            "Terület": "934",
            "Népesség": "270",
            "Lakások": "155"
        },
        {
            "Ir. szám": "9834",
            "name": "Csehimindszent",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.95",
            "KSH kód": "12724",
            "Jogállás": "6",
            "Terület": "1543",
            "Népesség": "361",
            "Lakások": "194"
        },
        {
            "Ir. szám": "9835",
            "name": "Mikosszéplak",
            "Megyekód": "VA",
            "Lat": "47.0333",
            "Long": "16.9833",
            "KSH kód": "30599",
            "Jogállás": "6",
            "Terület": "1866",
            "Népesség": "331",
            "Lakások": "159"
        },
        {
            "Ir. szám": "9836",
            "name": "Csipkerek",
            "Megyekód": "VA",
            "Lat": "47.0667",
            "Long": "16.95",
            "KSH kód": "26064",
            "Jogállás": "6",
            "Terület": "1313",
            "Népesség": "358",
            "Lakások": "244"
        },
        {
            "Ir. szám": "9841",
            "name": "Kám",
            "Megyekód": "VA",
            "Lat": "47.1",
            "Long": "16.8833",
            "KSH kód": "04640",
            "Jogállás": "6",
            "Terület": "1530",
            "Népesség": "448",
            "Lakások": "212"
        },
        {
            "Ir. szám": "9842",
            "name": "Alsóújlak",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.8",
            "KSH kód": "22725",
            "Jogállás": "6",
            "Terület": "2102",
            "Népesség": "610",
            "Lakások": "305"
        },
        {
            "Ir. szám": "9900",
            "name": "Körmend",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.6",
            "KSH kód": "13532",
            "Jogállás": "4",
            "Terület": "5279",
            "Népesség": "12028",
            "Lakások": "5046"
        },
        {
            "Ir. szám": "9901",
            "name": "Körmend",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.6",
            "KSH kód": "13532",
            "Jogállás": "4",
            "Terület": "5279",
            "Népesség": "12028",
            "Lakások": "5046"
        },
        {
            "Ir. szám": "9909",
            "name": "Magyarnádalja",
            "Megyekód": "VA",
            "Lat": "47",
            "Long": "16.5333",
            "KSH kód": "27289",
            "Jogállás": "6",
            "Terület": "385",
            "Népesség": "211",
            "Lakások": "85"
        },
        {
            "Ir. szám": "9909",
            "name": "Körmend",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.6",
            "KSH kód": "13532",
            "Jogállás": "4",
            "Terület": "5279",
            "Népesség": "12028",
            "Lakások": "5046"
        },
        {
            "Ir. szám": "9912",
            "name": "Magyarszecsőd",
            "Megyekód": "VA",
            "Lat": "47.0333",
            "Long": "16.65",
            "KSH kód": "17288",
            "Jogállás": "6",
            "Terület": "1126",
            "Népesség": "446",
            "Lakások": "195"
        },
        {
            "Ir. szám": "9912",
            "name": "Molnaszecsőd",
            "Megyekód": "VA",
            "Lat": "47.05",
            "Long": "16.6833",
            "KSH kód": "03294",
            "Jogállás": "6",
            "Terület": "1169",
            "Népesség": "431",
            "Lakások": "174"
        },
        {
            "Ir. szám": "9913",
            "name": "Döröske",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.7",
            "KSH kód": "03036",
            "Jogállás": "6",
            "Terület": "440",
            "Népesség": "84",
            "Lakások": "65"
        },
        {
            "Ir. szám": "9913",
            "name": "Szarvaskend",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.6833",
            "KSH kód": "17729",
            "Jogállás": "6",
            "Terület": "1034",
            "Népesség": "220",
            "Lakások": "125"
        },
        {
            "Ir. szám": "9913",
            "name": "Nagymizdó",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.6667",
            "KSH kód": "15060",
            "Jogállás": "6",
            "Terület": "676",
            "Népesség": "131",
            "Lakások": "79"
        },
        {
            "Ir. szám": "9914",
            "name": "Döbörhegy",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.7",
            "KSH kód": "20695",
            "Jogállás": "6",
            "Terület": "1175",
            "Népesség": "163",
            "Lakások": "117"
        },
        {
            "Ir. szám": "9915",
            "name": "Nádasd",
            "Megyekód": "VA",
            "Lat": "46.9636",
            "Long": "16.6192",
            "KSH kód": "06716",
            "Jogállás": "6",
            "Terület": "3560",
            "Népesség": "1329",
            "Lakások": "543"
        },
        {
            "Ir. szám": "9915",
            "name": "Katafa",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.6333",
            "KSH kód": "26620",
            "Jogállás": "6",
            "Terület": "481",
            "Népesség": "382",
            "Lakások": "160"
        },
        {
            "Ir. szám": "9915",
            "name": "Hegyháthodász",
            "Megyekód": "VA",
            "Lat": "46.9333",
            "Long": "16.65",
            "KSH kód": "14997",
            "Jogállás": "6",
            "Terület": "808",
            "Népesség": "176",
            "Lakások": "97"
        },
        {
            "Ir. szám": "9915",
            "name": "Hegyhátsál",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.65",
            "KSH kód": "30216",
            "Jogállás": "6",
            "Terület": "458",
            "Népesség": "147",
            "Lakások": "84"
        },
        {
            "Ir. szám": "9917",
            "name": "Halogy",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.5667",
            "KSH kód": "10676",
            "Jogállás": "6",
            "Terület": "705",
            "Népesség": "264",
            "Lakások": "145"
        },
        {
            "Ir. szám": "9917",
            "name": "Daraboshegy",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.5667",
            "KSH kód": "15990",
            "Jogállás": "6",
            "Terület": "459",
            "Népesség": "90",
            "Lakások": "61"
        },
        {
            "Ir. szám": "9918",
            "name": "Felsőmarác",
            "Megyekód": "VA",
            "Lat": "46.9333",
            "Long": "16.5167",
            "KSH kód": "29841",
            "Jogállás": "6",
            "Terület": "1744",
            "Népesség": "264",
            "Lakások": "191"
        },
        {
            "Ir. szám": "9919",
            "name": "Csákánydoroszló",
            "Megyekód": "VA",
            "Lat": "46.9708",
            "Long": "16.5069",
            "KSH kód": "03911",
            "Jogállás": "6",
            "Terület": "2661",
            "Népesség": "1772",
            "Lakások": "597"
        },
        {
            "Ir. szám": "9921",
            "name": "Vasalja",
            "Megyekód": "VA",
            "Lat": "47.0167",
            "Long": "16.5167",
            "KSH kód": "02884",
            "Jogállás": "6",
            "Terület": "1124",
            "Népesség": "325",
            "Lakások": "150"
        },
        {
            "Ir. szám": "9922",
            "name": "Pinkamindszent",
            "Megyekód": "VA",
            "Lat": "47.0333",
            "Long": "16.4833",
            "KSH kód": "06248",
            "Jogállás": "6",
            "Terület": "1101",
            "Népesség": "160",
            "Lakások": "109"
        },
        {
            "Ir. szám": "9923",
            "name": "Kemestaródfa",
            "Megyekód": "VA",
            "Lat": "47",
            "Long": "16.5167",
            "KSH kód": "22716",
            "Jogállás": "6",
            "Terület": "635",
            "Népesség": "237",
            "Lakások": "119"
        },
        {
            "Ir. szám": "9931",
            "name": "Ivánc",
            "Megyekód": "VA",
            "Lat": "46.9333",
            "Long": "16.5",
            "KSH kód": "31680",
            "Jogállás": "6",
            "Terület": "1714",
            "Népesség": "706",
            "Lakások": "239"
        },
        {
            "Ir. szám": "9931",
            "name": "Hegyhátszentmárton",
            "Megyekód": "VA",
            "Lat": "46.9333",
            "Long": "16.4833",
            "KSH kód": "21838",
            "Jogállás": "6",
            "Terület": "1274",
            "Népesség": "57",
            "Lakások": "54"
        },
        {
            "Ir. szám": "9932",
            "name": "Viszák",
            "Megyekód": "VA",
            "Lat": "46.8833",
            "Long": "16.5",
            "KSH kód": "07940",
            "Jogállás": "6",
            "Terület": "1013",
            "Népesség": "254",
            "Lakások": "126"
        },
        {
            "Ir. szám": "9933",
            "name": "Őrimagyarósd",
            "Megyekód": "VA",
            "Lat": "46.8883",
            "Long": "16.5397",
            "KSH kód": "13453",
            "Jogállás": "6",
            "Terület": "1229",
            "Népesség": "231",
            "Lakások": "116"
        },
        {
            "Ir. szám": "9934",
            "name": "Felsőjánosfa",
            "Megyekód": "VA",
            "Lat": "46.85",
            "Long": "16.5667",
            "KSH kód": "08013",
            "Jogállás": "6",
            "Terület": "306",
            "Népesség": "196",
            "Lakások": "88"
        },
        {
            "Ir. szám": "9934",
            "name": "Szaknyér",
            "Megyekód": "VA",
            "Lat": "46.8667",
            "Long": "16.5333",
            "KSH kód": "16504",
            "Jogállás": "6",
            "Terület": "293",
            "Népesség": "59",
            "Lakások": "40"
        },
        {
            "Ir. szám": "9934",
            "name": "Hegyhátszentjakab",
            "Megyekód": "VA",
            "Lat": "46.8661",
            "Long": "16.5547",
            "KSH kód": "18032",
            "Jogállás": "6",
            "Terület": "947",
            "Népesség": "281",
            "Lakások": "160"
        },
        {
            "Ir. szám": "9935",
            "name": "Szőce",
            "Megyekód": "VA",
            "Lat": "46.8833",
            "Long": "16.5833",
            "KSH kód": "19521",
            "Jogállás": "6",
            "Terület": "1871",
            "Népesség": "375",
            "Lakások": "215"
        },
        {
            "Ir. szám": "9936",
            "name": "Kisrákos",
            "Megyekód": "VA",
            "Lat": "46.8667",
            "Long": "16.5",
            "KSH kód": "11147",
            "Jogállás": "6",
            "Terület": "1106",
            "Népesség": "211",
            "Lakások": "98"
        },
        {
            "Ir. szám": "9937",
            "name": "Pankasz",
            "Megyekód": "VA",
            "Lat": "46.8333",
            "Long": "16.5",
            "KSH kód": "23108",
            "Jogállás": "6",
            "Terület": "927",
            "Népesség": "431",
            "Lakások": "180"
        },
        {
            "Ir. szám": "9938",
            "name": "Szatta",
            "Megyekód": "VA",
            "Lat": "46.8",
            "Long": "16.4833",
            "KSH kód": "02440",
            "Jogállás": "6",
            "Terület": "601",
            "Népesség": "69",
            "Lakások": "37"
        },
        {
            "Ir. szám": "9938",
            "name": "Nagyrákos",
            "Megyekód": "VA",
            "Lat": "46.8333",
            "Long": "16.4667",
            "KSH kód": "29869",
            "Jogállás": "6",
            "Terület": "1611",
            "Népesség": "272",
            "Lakások": "151"
        },
        {
            "Ir. szám": "9941",
            "name": "Ispánk",
            "Megyekód": "VA",
            "Lat": "46.8667",
            "Long": "16.45",
            "KSH kód": "07977",
            "Jogállás": "6",
            "Terület": "692",
            "Népesség": "111",
            "Lakások": "56"
        },
        {
            "Ir. szám": "9941",
            "name": "Őriszentpéter",
            "Megyekód": "VA",
            "Lat": "46.8408",
            "Long": "16.4294",
            "KSH kód": "10630",
            "Jogállás": "4",
            "Terület": "3356",
            "Népesség": "1198",
            "Lakások": "527"
        },
        {
            "Ir. szám": "9942",
            "name": "Szalafő",
            "Megyekód": "VA",
            "Lat": "46.8667",
            "Long": "16.3667",
            "KSH kód": "10223",
            "Jogállás": "6",
            "Terület": "2737",
            "Népesség": "219",
            "Lakások": "145"
        },
        {
            "Ir. szám": "9943",
            "name": "Kondorfa",
            "Megyekód": "VA",
            "Lat": "46.895",
            "Long": "16.4017",
            "KSH kód": "13028",
            "Jogállás": "6",
            "Terület": "2161",
            "Népesség": "541",
            "Lakások": "297"
        },
        {
            "Ir. szám": "9944",
            "name": "Bajánsenye",
            "Megyekód": "VA",
            "Lat": "46.8",
            "Long": "16.3833",
            "KSH kód": "17020",
            "Jogállás": "6",
            "Terület": "2185",
            "Népesség": "488",
            "Lakások": "246"
        },
        {
            "Ir. szám": "9944",
            "name": "Kerkáskápolna",
            "Megyekód": "VA",
            "Lat": "46.7833",
            "Long": "16.4333",
            "KSH kód": "19761",
            "Jogállás": "6",
            "Terület": "920",
            "Népesség": "100",
            "Lakások": "56"
        },
        {
            "Ir. szám": "9945",
            "name": "Kercaszomor",
            "Megyekód": "VA",
            "Lat": "46.7833",
            "Long": "16.3667",
            "KSH kód": "26596",
            "Jogállás": "6",
            "Terület": "1287",
            "Népesség": "205",
            "Lakások": "115"
        },
        {
            "Ir. szám": "9946",
            "name": "Magyarszombatfa",
            "Megyekód": "VA",
            "Lat": "46.7628",
            "Long": "16.3467",
            "KSH kód": "25423",
            "Jogállás": "6",
            "Terület": "1594",
            "Népesség": "277",
            "Lakások": "152"
        },
        {
            "Ir. szám": "9946",
            "name": "Velemér",
            "Megyekód": "VA",
            "Lat": "46.7333",
            "Long": "16.3833",
            "KSH kód": "18430",
            "Jogállás": "6",
            "Terület": "955",
            "Népesség": "87",
            "Lakások": "73"
        },
        {
            "Ir. szám": "9951",
            "name": "Rátót",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.4333",
            "KSH kód": "23861",
            "Jogállás": "6",
            "Terület": "727",
            "Népesség": "241",
            "Lakások": "99"
        },
        {
            "Ir. szám": "9952",
            "name": "Gasztony",
            "Megyekód": "VA",
            "Lat": "46.9625",
            "Long": "16.4594",
            "KSH kód": "30906",
            "Jogállás": "6",
            "Terület": "1427",
            "Népesség": "453",
            "Lakások": "206"
        },
        {
            "Ir. szám": "9953",
            "name": "Nemesmedves",
            "Megyekód": "VA",
            "Lat": "47",
            "Long": "16.4",
            "KSH kód": "31556",
            "Jogállás": "6",
            "Terület": "474",
            "Népesség": "21",
            "Lakások": "13"
        },
        {
            "Ir. szám": "9953",
            "name": "Vasszentmihály",
            "Megyekód": "VA",
            "Lat": "46.9667",
            "Long": "16.4167",
            "KSH kód": "11633",
            "Jogállás": "6",
            "Terület": "641",
            "Népesség": "348",
            "Lakások": "172"
        },
        {
            "Ir. szám": "9954",
            "name": "Rönök",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.35",
            "KSH kód": "26806",
            "Jogállás": "6",
            "Terület": "1722",
            "Népesség": "447",
            "Lakások": "163"
        },
        {
            "Ir. szám": "9955",
            "name": "Rönök",
            "Megyekód": "VA",
            "Lat": "46.9833",
            "Long": "16.35",
            "KSH kód": "26806",
            "Jogállás": "6",
            "Terület": "1722",
            "Népesség": "447",
            "Lakások": "163"
        },
        {
            "Ir. szám": "9961",
            "name": "Rábagyarmat",
            "Megyekód": "VA",
            "Lat": "46.9406",
            "Long": "16.4286",
            "KSH kód": "26736",
            "Jogállás": "6",
            "Terület": "1679",
            "Népesség": "816",
            "Lakások": "306"
        },
        {
            "Ir. szám": "9962",
            "name": "Csörötnek",
            "Megyekód": "VA",
            "Lat": "46.9514",
            "Long": "16.3764",
            "KSH kód": "04224",
            "Jogállás": "6",
            "Terület": "2053",
            "Népesség": "870",
            "Lakások": "331"
        },
        {
            "Ir. szám": "9962",
            "name": "Magyarlak",
            "Megyekód": "VA",
            "Lat": "46.95",
            "Long": "16.35",
            "KSH kód": "03221",
            "Jogállás": "6",
            "Terület": "762",
            "Népesség": "741",
            "Lakások": "301"
        },
        {
            "Ir. szám": "9970",
            "name": "Szentgotthárd",
            "Megyekód": "VA",
            "Lat": "46.9519",
            "Long": "16.3256",
            "KSH kód": "31583",
            "Jogállás": "4",
            "Terület": "6773",
            "Népesség": "8953",
            "Lakások": "3313"
        },
        {
            "Ir. szám": "9971",
            "name": "Szentgotthárd",
            "Megyekód": "VA",
            "Lat": "46.9519",
            "Long": "16.3256",
            "KSH kód": "31583",
            "Jogállás": "4",
            "Terület": "6773",
            "Népesség": "8953",
            "Lakások": "3313"
        },
        {
            "Ir. szám": "9981",
            "name": "Szentgotthárd",
            "Megyekód": "VA",
            "Lat": "46.9519",
            "Long": "16.3256",
            "KSH kód": "31583",
            "Jogállás": "4",
            "Terület": "6773",
            "Népesség": "8953",
            "Lakások": "3313"
        },
        {
            "Ir. szám": "9982",
            "name": "Apátistvánfalva",
            "Megyekód": "VA",
            "Lat": "46.9006",
            "Long": "16.2647",
            "KSH kód": "08873",
            "Jogállás": "6",
            "Terület": "1286",
            "Népesség": "369",
            "Lakások": "166"
        },
        {
            "Ir. szám": "9982",
            "name": "Kétvölgy",
            "Megyekód": "VA",
            "Lat": "46.8833",
            "Long": "16.2167",
            "KSH kód": "19345",
            "Jogállás": "6",
            "Terület": "628",
            "Népesség": "127",
            "Lakások": "72"
        },
        {
            "Ir. szám": "9982",
            "name": "Orfalu",
            "Megyekód": "VA",
            "Lat": "46.8833",
            "Long": "16.2833",
            "KSH kód": "17093",
            "Jogállás": "6",
            "Terület": "694",
            "Népesség": "60",
            "Lakások": "32"
        },
        {
            "Ir. szám": "9983",
            "name": "Alsószölnök",
            "Megyekód": "VA",
            "Lat": "46.9278",
            "Long": "16.2086",
            "KSH kód": "22549",
            "Jogállás": "6",
            "Terület": "1002",
            "Népesség": "363",
            "Lakások": "170"
        },
        {
            "Ir. szám": "9983",
            "name": "Szakonyfalu",
            "Megyekód": "VA",
            "Lat": "46.9167",
            "Long": "16.2333",
            "KSH kód": "20932",
            "Jogállás": "6",
            "Terület": "1119",
            "Népesség": "354",
            "Lakások": "140"
        },
        {
            "Ir. szám": "9985",
            "name": "Felsőszölnök",
            "Megyekód": "VA",
            "Lat": "46.8797",
            "Long": "16.1731",
            "KSH kód": "23287",
            "Jogállás": "6",
            "Terület": "2356",
            "Népesség": "584",
            "Lakások": "299"
        },
        {
            "Ir. szám": "2087",
            "name": "Piliscsaba",
            "Megyekód": "PE",
            "Lat": "47.6167",
            "Long": "18.8333",
            "KSH kód": "07144",
            "Jogállás": "5",
            "Terület": "2557",
            "Népesség": "7873",
            "Lakások": "2468"
        },
        {
            "Ir. szám": "2099",
            "name": "Pilisszentkereszt",
            "Megyekód": "PE",
            "Lat": "47.7",
            "Long": "18.9",
            "KSH kód": "18731",
            "Jogállás": "6",
            "Terület": "1721",
            "Népesség": "2270",
            "Lakások": "788"
        },
        {
            "Ir. szám": "2738",
            "name": "Cegléd",
            "Megyekód": "PE",
            "Lat": "47.1667",
            "Long": "19.8",
            "KSH kód": "11341",
            "Jogállás": "4",
            "Terület": "24487",
            "Népesség": "38318",
            "Lakások": "15722"
        },
        {
            "Ir. szám": "2835",
            "name": "Tata",
            "Megyekód": "KE",
            "Lat": "47.65",
            "Long": "18.3167",
            "KSH kód": "20127",
            "Jogállás": "4",
            "Terület": "7817",
            "Népesség": "24902",
            "Lakások": "9483"
        },
        {
            "Ir. szám": "2921",
            "name": "Komárom",
            "Megyekód": "KE",
            "Lat": "47.7333",
            "Long": "18.1167",
            "KSH kód": "05449",
            "Jogállás": "4",
            "Terület": "7019",
            "Népesség": "19747",
            "Lakások": "8201"
        },
        {
            "Ir. szám": "3024",
            "name": "Lőrinci",
            "Megyekód": "HE",
            "Lat": "47.7333",
            "Long": "19.6833",
            "KSH kód": "30401",
            "Jogállás": "4",
            "Terület": "2353",
            "Népesség": "6126",
            "Lakások": "2318"
        },
        {
            "Ir. szám": "3065",
            "name": "Pásztó",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.7",
            "KSH kód": "07409",
            "Jogállás": "4",
            "Terület": "7260",
            "Népesség": "9880",
            "Lakások": "4108"
        },
        {
            "Ir. szám": "3078",
            "name": "Bátonyterenye",
            "Megyekód": "NO",
            "Lat": "47.9667",
            "Long": "19.8333",
            "KSH kód": "33534",
            "Jogállás": "4",
            "Terület": "7892",
            "Népesség": "13270",
            "Lakások": "5820"
        },
        {
            "Ir. szám": "3082",
            "name": "Pásztó",
            "Megyekód": "NO",
            "Lat": "47.9167",
            "Long": "19.7",
            "KSH kód": "07409",
            "Jogállás": "4",
            "Terület": "7260",
            "Népesség": "9880",
            "Lakások": "4108"
        },
        {
            "Ir. szám": "3141",
            "name": "Salgótarján",
            "Megyekód": "NO",
            "Lat": "48.1167",
            "Long": "19.8167",
            "KSH kód": "25788",
            "Jogállás": "1",
            "Terület": "10084",
            "Népesség": "38207",
            "Lakások": "18276"
        },
        {
            "Ir. szám": "3147",
            "name": "Kazár",
            "Megyekód": "NO",
            "Lat": "48.05",
            "Long": "19.8667",
            "KSH kód": "28389",
            "Jogállás": "6",
            "Terület": "3039",
            "Népesség": "2066",
            "Lakások": "804"
        },
        {
            "Ir. szám": "3221",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3232",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3233",
            "name": "Gyöngyös",
            "Megyekód": "HE",
            "Lat": "47.7833",
            "Long": "19.9333",
            "KSH kód": "05236",
            "Jogállás": "4",
            "Terület": "5531",
            "Népesség": "32546",
            "Lakások": "14438"
        },
        {
            "Ir. szám": "3234",
            "name": "Mátraszentimre",
            "Megyekód": "HE",
            "Lat": "47.9167",
            "Long": "19.8833",
            "KSH kód": "29045",
            "Jogállás": "6",
            "Terület": "2129",
            "Népesség": "501",
            "Lakások": "271"
        },
        {
            "Ir. szám": "3244",
            "name": "Parád",
            "Megyekód": "HE",
            "Lat": "47.9167",
            "Long": "20.0333",
            "KSH kód": "07436",
            "Jogállás": "5",
            "Terület": "3720",
            "Népesség": "1961",
            "Lakások": "1011"
        },
        {
            "Ir. szám": "3625",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3651",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3661",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3662",
            "name": "Ózd",
            "Megyekód": "BZ",
            "Lat": "48.2167",
            "Long": "20.3",
            "KSH kód": "14492",
            "Jogállás": "4",
            "Terület": "9170",
            "Népesség": "35621",
            "Lakások": "14953"
        },
        {
            "Ir. szám": "3783",
            "name": "Edelény",
            "Megyekód": "BZ",
            "Lat": "48.3",
            "Long": "20.7333",
            "KSH kód": "10728",
            "Jogállás": "4",
            "Terület": "5684",
            "Népesség": "10372",
            "Lakások": "3567"
        },
        {
            "Ir. szám": "3923",
            "name": "Gesztely",
            "Megyekód": "BZ",
            "Lat": "48.1",
            "Long": "20.9667",
            "KSH kód": "15608",
            "Jogállás": "6",
            "Terület": "2882",
            "Népesség": "2826",
            "Lakások": "951"
        },
        {
            "Ir. szám": "3944",
            "name": "Sátoraljaújhely",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.6667",
            "KSH kód": "05120",
            "Jogállás": "4",
            "Terület": "7347",
            "Népesség": "16299",
            "Lakások": "6875"
        },
        {
            "Ir. szám": "3945",
            "name": "Sátoraljaújhely",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.6667",
            "KSH kód": "05120",
            "Jogállás": "4",
            "Terület": "7347",
            "Népesség": "16299",
            "Lakások": "6875"
        },
        {
            "Ir. szám": "3988",
            "name": "Sátoraljaújhely",
            "Megyekód": "BZ",
            "Lat": "48.4",
            "Long": "21.6667",
            "KSH kód": "05120",
            "Jogállás": "4",
            "Terület": "7347",
            "Népesség": "16299",
            "Lakások": "6875"
        },
        {
            "Ir. szám": "4067",
            "name": "Egyek",
            "Megyekód": "HB",
            "Lat": "47.6333",
            "Long": "20.9",
            "KSH kód": "15741",
            "Jogállás": "5",
            "Terület": "10479",
            "Népesség": "5071",
            "Lakások": "2392"
        },
        {
            "Ir. szám": "4074",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4078",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4079",
            "name": "Debrecen",
            "Megyekód": "HB",
            "Lat": "47.7675",
            "Long": "21.24",
            "KSH kód": "15130",
            "Jogállás": "1",
            "Terület": "46165",
            "Népesség": "206225",
            "Lakások": "89716"
        },
        {
            "Ir. szám": "4086",
            "name": "Hajdúböszörmény",
            "Megyekód": "HB",
            "Lat": "47.6667",
            "Long": "21.5167",
            "KSH kód": "03045",
            "Jogállás": "4",
            "Terület": "37078",
            "Népesség": "31793",
            "Lakások": "12312"
        },
        {
            "Ir. szám": "4162",
            "name": "Szerep",
            "Megyekód": "HB",
            "Lat": "47.2333",
            "Long": "21.15",
            "KSH kód": "33437",
            "Jogállás": "6",
            "Terület": "5604",
            "Népesség": "1604",
            "Lakások": "571"
        },
        {
            "Ir. szám": "4246",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4252",
            "name": "Nyíradony",
            "Megyekód": "HB",
            "Lat": "47.6833",
            "Long": "21.9167",
            "KSH kód": "06187",
            "Jogállás": "4",
            "Terület": "9659",
            "Népesség": "7874",
            "Lakások": "2796"
        },
        {
            "Ir. szám": "4253",
            "name": "Nyíradony",
            "Megyekód": "HB",
            "Lat": "47.6833",
            "Long": "21.9167",
            "KSH kód": "06187",
            "Jogállás": "4",
            "Terület": "9659",
            "Népesség": "7874",
            "Lakások": "2796"
        },
        {
            "Ir. szám": "4446",
            "name": "Tiszaeszlár",
            "Megyekód": "SZ",
            "Lat": "48.05",
            "Long": "21.4667",
            "KSH kód": "09113",
            "Jogállás": "6",
            "Terület": "5460",
            "Népesség": "2676",
            "Lakások": "1097"
        },
        {
            "Ir. szám": "4447",
            "name": "Tiszalök",
            "Megyekód": "SZ",
            "Lat": "48.0167",
            "Long": "21.3833",
            "KSH kód": "23524",
            "Jogállás": "4",
            "Terület": "5872",
            "Népesség": "5768",
            "Lakások": "2262"
        },
        {
            "Ir. szám": "4481",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "4486",
            "name": "Tiszatelek",
            "Megyekód": "SZ",
            "Lat": "48.2",
            "Long": "21.8",
            "KSH kód": "14447",
            "Jogállás": "6",
            "Terület": "3378",
            "Népesség": "1473",
            "Lakások": "541"
        },
        {
            "Ir. szám": "4551",
            "name": "Nyíregyháza",
            "Megyekód": "SZ",
            "Lat": "47.95",
            "Long": "21.7167",
            "KSH kód": "17206",
            "Jogállás": "1",
            "Terület": "27454",
            "Népesség": "117597",
            "Lakások": "50269"
        },
        {
            "Ir. szám": "5095",
            "name": "Tiszavárkony",
            "Megyekód": "JN",
            "Lat": "47.0667",
            "Long": "20.1833",
            "KSH kód": "31866",
            "Jogállás": "6",
            "Terület": "3562",
            "Népesség": "1598",
            "Lakások": "693"
        },
        {
            "Ir. szám": "5152",
            "name": "Jászberény",
            "Megyekód": "JN",
            "Lat": "47.5",
            "Long": "19.9167",
            "KSH kód": "18209",
            "Jogállás": "4",
            "Terület": "22135",
            "Népesség": "27134",
            "Lakások": "11771"
        },
        {
            "Ir. szám": "5212",
            "name": "Törökszentmiklós",
            "Megyekód": "JN",
            "Lat": "47.1833",
            "Long": "20.4167",
            "KSH kód": "27313",
            "Jogállás": "4",
            "Terület": "18516",
            "Népesség": "21758",
            "Lakások": "8989"
        },
        {
            "Ir. szám": "5213",
            "name": "Fegyvernek",
            "Megyekód": "JN",
            "Lat": "47.2667",
            "Long": "20.5333",
            "KSH kód": "16647",
            "Jogállás": "5",
            "Terület": "7148",
            "Népesség": "6629",
            "Lakások": "2680"
        },
        {
            "Ir. szám": "5349",
            "name": "Kenderes",
            "Megyekód": "JN",
            "Lat": "47.25",
            "Long": "20.6833",
            "KSH kód": "17145",
            "Jogállás": "4",
            "Terület": "11124",
            "Népesség": "4917",
            "Lakások": "2013"
        },
        {
            "Ir. szám": "5461",
            "name": "Tiszaföldvár",
            "Megyekód": "JN",
            "Lat": "46.9833",
            "Long": "20.25",
            "KSH kód": "13815",
            "Jogállás": "4",
            "Terület": "8034",
            "Népesség": "11548",
            "Lakások": "4724"
        },
        {
            "Ir. szám": "5623",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5671",
            "name": "Békéscsaba",
            "Megyekód": "BE",
            "Lat": "46.6833",
            "Long": "21.1",
            "KSH kód": "15200",
            "Jogállás": "1",
            "Terület": "19393",
            "Népesség": "64784",
            "Lakások": "29240"
        },
        {
            "Ir. szám": "5752",
            "name": "Medgyesegyháza",
            "Megyekód": "BE",
            "Lat": "46.5",
            "Long": "21.0333",
            "KSH kód": "30128",
            "Jogállás": "5",
            "Terület": "6429",
            "Népesség": "3848",
            "Lakások": "1873"
        },
        {
            "Ir. szám": "6044",
            "name": "Kecskemét",
            "Megyekód": "BK",
            "Lat": "46.9",
            "Long": "19.7833",
            "KSH kód": "26684",
            "Jogállás": "1",
            "Terület": "32136",
            "Népesség": "111428",
            "Lakások": "46329"
        },
        {
            "Ir. szám": "6648",
            "name": "Csongrád",
            "Megyekód": "CS",
            "Lat": "46.7",
            "Long": "20.15",
            "KSH kód": "05111",
            "Jogállás": "4",
            "Terület": "17389",
            "Népesség": "17686",
            "Lakások": "8468"
        },
        {
            "Ir. szám": "7018",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7019",
            "name": "Sárbogárd",
            "Megyekód": "FE",
            "Lat": "46.8833",
            "Long": "18.6333",
            "KSH kód": "23694",
            "Jogállás": "4",
            "Terület": "18934",
            "Népesség": "12922",
            "Lakások": "5089"
        },
        {
            "Ir. szám": "7027",
            "name": "Paks",
            "Megyekód": "TO",
            "Lat": "46.6333",
            "Long": "18.8667",
            "KSH kód": "04862",
            "Jogállás": "4",
            "Terület": "15408",
            "Népesség": "19833",
            "Lakások": "8241"
        },
        {
            "Ir. szám": "7187",
            "name": "Bonyhád",
            "Megyekód": "TO",
            "Lat": "46.3",
            "Long": "18.5333",
            "KSH kód": "06497",
            "Jogállás": "4",
            "Terület": "7213",
            "Népesség": "13957",
            "Lakások": "5592"
        },
        {
            "Ir. szám": "7409",
            "name": "Kaposvár",
            "Megyekód": "SO",
            "Lat": "46.3667",
            "Long": "17.8",
            "KSH kód": "20473",
            "Jogállás": "1",
            "Terület": "11359",
            "Népesség": "67663",
            "Lakások": "27911"
        },
        {
            "Ir. szám": "7557",
            "name": "Barcs",
            "Megyekód": "SO",
            "Lat": "45.96",
            "Long": "17.4586",
            "KSH kód": "32799",
            "Jogállás": "4",
            "Terület": "12290",
            "Népesség": "11699",
            "Lakások": "4511"
        },
        {
            "Ir. szám": "7714",
            "name": "Mohács",
            "Megyekód": "BA",
            "Lat": "45.9931",
            "Long": "18.6831",
            "KSH kód": "23959",
            "Jogállás": "4",
            "Terület": "11223",
            "Népesség": "19129",
            "Lakások": "8706"
        },
        {
            "Ir. szám": "7715",
            "name": "Mohács",
            "Megyekód": "BA",
            "Lat": "45.9931",
            "Long": "18.6831",
            "KSH kód": "23959",
            "Jogállás": "4",
            "Terület": "11223",
            "Népesség": "19129",
            "Lakások": "8706"
        },
        {
            "Ir. szám": "8054",
            "name": "Balinka",
            "Megyekód": "FE",
            "Lat": "47.3167",
            "Long": "18.1833",
            "KSH kód": "31103",
            "Jogállás": "6",
            "Terület": "1862",
            "Népesség": "987",
            "Lakások": "381"
        },
        {
            "Ir. szám": "8172",
            "name": "Balatonkenese",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "18.1167",
            "KSH kód": "05148",
            "Jogállás": "5",
            "Terület": "6557",
            "Népesség": "3263",
            "Lakások": "1667"
        },
        {
            "Ir. szám": "8173",
            "name": "Balatonkenese",
            "Megyekód": "VE",
            "Lat": "47.0333",
            "Long": "18.1167",
            "KSH kód": "05148",
            "Jogállás": "5",
            "Terület": "6557",
            "Népesség": "3263",
            "Lakások": "1667"
        },
        {
            "Ir. szám": "8184",
            "name": "Balatonfűzfő",
            "Megyekód": "VE",
            "Lat": "47.0667",
            "Long": "18.05",
            "KSH kód": "02219",
            "Jogállás": "4",
            "Terület": "925",
            "Népesség": "4335",
            "Lakások": "1899"
        },
        {
            "Ir. szám": "8236",
            "name": "Balatonfüred",
            "Megyekód": "VE",
            "Lat": "46.95",
            "Long": "17.8833",
            "KSH kód": "21175",
            "Jogállás": "4",
            "Terület": "4645",
            "Népesség": "13422",
            "Lakások": "5654"
        },
        {
            "Ir. szám": "8257",
            "name": "Badacsonytomaj",
            "Megyekód": "VE",
            "Lat": "46.8",
            "Long": "17.5167",
            "KSH kód": "22327",
            "Jogállás": "4",
            "Terület": "3271",
            "Népesség": "2178",
            "Lakások": "1068"
        },
        {
            "Ir. szám": "8297",
            "name": "Tapolca",
            "Megyekód": "VE",
            "Lat": "46.8833",
            "Long": "17.4333",
            "KSH kód": "29434",
            "Jogállás": "4",
            "Terület": "6348",
            "Népesség": "16211",
            "Lakások": "6854"
        },
        {
            "Ir. szám": "8411",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8412",
            "name": "Veszprém",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.9167",
            "KSH kód": "11767",
            "Jogállás": "1",
            "Terület": "12690",
            "Népesség": "63405",
            "Lakások": "26062"
        },
        {
            "Ir. szám": "8447",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8448",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8451",
            "name": "Ajka",
            "Megyekód": "VE",
            "Lat": "47.1",
            "Long": "17.5667",
            "KSH kód": "06673",
            "Jogállás": "4",
            "Terület": "9505",
            "Népesség": "29685",
            "Lakások": "12589"
        },
        {
            "Ir. szám": "8531",
            "name": "Marcaltő",
            "Megyekód": "VE",
            "Lat": "47.4333",
            "Long": "17.3667",
            "KSH kód": "22220",
            "Jogállás": "6",
            "Terület": "2209",
            "Népesség": "813",
            "Lakások": "338"
        },
        {
            "Ir. szám": "8591",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8598",
            "name": "Pápa",
            "Megyekód": "VE",
            "Lat": "47.3333",
            "Long": "17.4667",
            "KSH kód": "31945",
            "Jogállás": "4",
            "Terület": "9168",
            "Népesség": "32664",
            "Lakások": "13223"
        },
        {
            "Ir. szám": "8691",
            "name": "Balatonboglár",
            "Megyekód": "SO",
            "Lat": "46.7667",
            "Long": "17.6667",
            "KSH kód": "33853",
            "Jogállás": "4",
            "Terület": "3204",
            "Népesség": "5920",
            "Lakások": "2784"
        },
        {
            "Ir. szám": "8709",
            "name": "Marcali",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.4167",
            "KSH kód": "18500",
            "Jogállás": "4",
            "Terület": "10440",
            "Népesség": "11838",
            "Lakások": "4975"
        },
        {
            "Ir. szám": "8714",
            "name": "Marcali",
            "Megyekód": "SO",
            "Lat": "46.5833",
            "Long": "17.4167",
            "KSH kód": "18500",
            "Jogállás": "4",
            "Terület": "10440",
            "Népesség": "11838",
            "Lakások": "4975"
        },
        {
            "Ir. szám": "8785",
            "name": "Zalaszentgrót",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0833",
            "KSH kód": "32522",
            "Jogállás": "4",
            "Terület": "8162",
            "Népesség": "7313",
            "Lakások": "3048"
        },
        {
            "Ir. szám": "8789",
            "name": "Zalaszentgrót",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0833",
            "KSH kód": "32522",
            "Jogállás": "4",
            "Terület": "8162",
            "Népesség": "7313",
            "Lakások": "3048"
        },
        {
            "Ir. szám": "8793",
            "name": "Zalaszentgrót",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0833",
            "KSH kód": "32522",
            "Jogállás": "4",
            "Terület": "8162",
            "Népesség": "7313",
            "Lakások": "3048"
        },
        {
            "Ir. szám": "8795",
            "name": "Zalaszentgrót",
            "Megyekód": "ZA",
            "Lat": "46.95",
            "Long": "17.0833",
            "KSH kód": "32522",
            "Jogállás": "4",
            "Terület": "8162",
            "Népesség": "7313",
            "Lakások": "3048"
        },
        {
            "Ir. szám": "8831",
            "name": "Nagykanizsa",
            "Megyekód": "ZA",
            "Lat": "46.4618",
            "Long": "17.0102",
            "KSH kód": "30933",
            "Jogállás": "3",
            "Terület": "14840",
            "Népesség": "50540",
            "Lakások": "21648"
        },
        {
            "Ir. szám": "9183",
            "name": "Mosonszentmiklós",
            "Megyekód": "GS",
            "Lat": "47.7333",
            "Long": "17.3833",
            "KSH kód": "33677",
            "Jogállás": "6",
            "Terület": "3071",
            "Népesség": "2319",
            "Lakások": "947"
        },
        {
            "Ir. szám": "9434",
            "name": "Sarród",
            "Megyekód": "GS",
            "Lat": "47.6333",
            "Long": "16.8667",
            "KSH kód": "30021",
            "Jogállás": "6",
            "Terület": "4007",
            "Népesség": "1112",
            "Lakások": "433"
        },
        {
            "Ir. szám": "9494",
            "name": "Sopron",
            "Megyekód": "GS",
            "Lat": "47.672",
            "Long": "16.5852",
            "KSH kód": "08518",
            "Jogállás": "3",
            "Terület": "16906",
            "Népesség": "59036",
            "Lakások": "25073"
        },
        {
            "Ir. szám": "9541",
            "name": "Celldömölk",
            "Megyekód": "VA",
            "Lat": "47.25",
            "Long": "17.15",
            "KSH kód": "27094",
            "Jogállás": "4",
            "Terület": "5239",
            "Népesség": "11247",
            "Lakások": "4723"
        },
        {
            "Ir. szám": "9740",
            "name": "Bük",
            "Megyekód": "VA",
            "Lat": "47.3833",
            "Long": "16.75",
            "KSH kód": "02431",
            "Jogállás": "4",
            "Terület": "2086",
            "Népesség": "3402",
            "Lakások": "1613"
        },
        {
            "Ir. szám": "9955",
            "name": "Szentgotthárd",
            "Megyekód": "VA",
            "Lat": "46.9519",
            "Long": "16.3256",
            "KSH kód": "31583",
            "Jogállás": "4",
            "Terület": "6773",
            "Népesség": "8953",
            "Lakások": "3313"
        }
    ],
    "jogallas": [
        {
            "Jogállás": "megyeszékhely, megyei jogú város",
            "Súly": "1"
        },
        {
            "Jogállás": "fővárosi kerület",
            "Súly": "2"
        },
        {
            "Jogállás": "megyei jogú város",
            "Súly": "3"
        },
        {
            "Jogállás": "város",
            "Súly": "4"
        },
        {
            "Jogállás": "nagyközség",
            "Súly": "5"
        },
        {
            "Jogállás": "község",
            "Súly": "6"
        }
    ],
    "telepulesreszek": [
        {
            "Ir. szám": "1028",
            "Település": "Budapest II. kerület",
            "Településrész": "Pesthidegkút"
        },
        {
            "Ir. szám": "1029",
            "Település": "Budapest II. kerület",
            "Településrész": "Adyliget"
        },
        {
            "Ir. szám": "1029",
            "Település": "Budapest II. kerület",
            "Településrész": "Máriaremete"
        },
        {
            "Ir. szám": "1163",
            "Település": "Budapest XVI. kerület",
            "Településrész": "Sashalom"
        },
        {
            "Ir. szám": "1165",
            "Település": "Budapest XVI. kerület",
            "Településrész": "Mátyásföld"
        },
        {
            "Ir. szám": "1174",
            "Település": "Budapest XVII. kerület",
            "Településrész": "Helikopter-lakópark"
        },
        {
            "Ir. szám": "1184",
            "Település": "Budapest XVIII. kerület",
            "Településrész": "Pestszentlőrinc"
        },
        {
            "Ir. szám": "1186",
            "Település": "Budapest XVIII. kerület",
            "Településrész": "Pestszentlőrinc"
        },
        {
            "Ir. szám": "1188",
            "Település": "Budapest XVIII. kerület",
            "Településrész": "Pestszentimre"
        },
        {
            "Ir. szám": "1221",
            "Település": "Budapest XXII. kerület",
            "Településrész": "Budafok"
        },
        {
            "Ir. szám": "1223",
            "Település": "Budapest XXII. kerület",
            "Településrész": "Budafok"
        },
        {
            "Ir. szám": "1223",
            "Település": "Budapest XXII. kerület",
            "Településrész": "Budatétény"
        },
        {
            "Ir. szám": "1224",
            "Település": "Budapest XXII. kerület",
            "Településrész": "Nagytétény"
        },
        {
            "Ir. szám": "1225",
            "Település": "Budapest XXII. kerület",
            "Településrész": "Nagytétény"
        },
        {
            "Ir. szám": "2021",
            "Település": "Tahitótfalu",
            "Településrész": "Tótfalu"
        },
        {
            "Ir. szám": "2022",
            "Település": "Tahitótfalu",
            "Településrész": "Tahi"
        },
        {
            "Ir. szám": "2026",
            "Település": "Visegrád",
            "Településrész": "Gizellatelep"
        },
        {
            "Ir. szám": "2035",
            "Település": "Érd",
            "Településrész": "Érdparkváros"
        },
        {
            "Ir. szám": "2036",
            "Település": "Érd",
            "Településrész": "Érdliget"
        },
        {
            "Ir. szám": "2042",
            "Település": "Budaörs",
            "Településrész": "Kamaraerdő"
        },
        {
            "Ir. szám": "2087",
            "Település": "Piliscsaba",
            "Településrész": "Klotildliget"
        },
        {
            "Ir. szám": "2099",
            "Település": "Pilisszentkereszt",
            "Településrész": "Dobogókő"
        },
        {
            "Ir. szám": "2102",
            "Település": "Gödöllő",
            "Településrész": "Máriabesnyő"
        },
        {
            "Ir. szám": "2131",
            "Település": "Göd",
            "Településrész": "Alsógöd"
        },
        {
            "Ir. szám": "2132",
            "Település": "Göd",
            "Településrész": "Felsőgöd"
        },
        {
            "Ir. szám": "2145",
            "Település": "Kerepes",
            "Településrész": "Szilasliget"
        },
        {
            "Ir. szám": "2241",
            "Település": "Sülysáp",
            "Településrész": "Tápiósüly"
        },
        {
            "Ir. szám": "2242",
            "Település": "Sülysáp",
            "Településrész": "Tápiósáp"
        },
        {
            "Ir. szám": "2371",
            "Település": "Dabas",
            "Településrész": "Sári"
        },
        {
            "Ir. szám": "2373",
            "Település": "Dabas",
            "Településrész": "Gyón"
        },
        {
            "Ir. szám": "2407",
            "Település": "Dunaújváros",
            "Településrész": "Pálhalma"
        },
        {
            "Ir. szám": "2427",
            "Település": "Baracs",
            "Településrész": "Apátszállás"
        },
        {
            "Ir. szám": "2453",
            "Település": "Ercsi",
            "Településrész": "Sinatelep"
        },
        {
            "Ir. szám": "2484",
            "Település": "Gárdony",
            "Településrész": "Agárd"
        },
        {
            "Ir. szám": "2485",
            "Település": "Gárdony",
            "Településrész": "Dinnyés"
        },
        {
            "Ir. szám": "2508",
            "Település": "Esztergom",
            "Településrész": "Pilisszentlélek"
        },
        {
            "Ir. szám": "2537",
            "Település": "Nyergesújfalu",
            "Településrész": "Viscosa"
        },
        {
            "Ir. szám": "2640",
            "Település": "Szendehely",
            "Településrész": "Katalinpuszta"
        },
        {
            "Ir. szám": "2738",
            "Település": "Cegléd",
            "Településrész": "Budai út"
        },
        {
            "Ir. szám": "2835",
            "Település": "Tata",
            "Településrész": "Agostyán"
        },
        {
            "Ir. szám": "2879",
            "Település": "Kisbér",
            "Településrész": "Hánta"
        },
        {
            "Ir. szám": "2903",
            "Település": "Komárom",
            "Településrész": "Koppánymonostor"
        },
        {
            "Ir. szám": "2921",
            "Település": "Komárom",
            "Településrész": "Szőny"
        },
        {
            "Ir. szám": "3022",
            "Település": "Lőrinci",
            "Településrész": "Mátravidéki erőmű"
        },
        {
            "Ir. szám": "3024",
            "Település": "Lőrinci",
            "Településrész": "Selyp"
        },
        {
            "Ir. szám": "3065",
            "Település": "Pásztó",
            "Településrész": "Hasznos"
        },
        {
            "Ir. szám": "3071",
            "Település": "Bátonyterenye",
            "Településrész": "Nagybátony"
        },
        {
            "Ir. szám": "3078",
            "Település": "Bátonyterenye",
            "Településrész": "Kisterenye"
        },
        {
            "Ir. szám": "3082",
            "Település": "Pásztó",
            "Településrész": "Mátrakeresztes"
        },
        {
            "Ir. szám": "3102",
            "Település": "Salgótarján",
            "Településrész": "Baglyasalja"
        },
        {
            "Ir. szám": "3104",
            "Település": "Salgótarján",
            "Településrész": "Zagyvapálfalva"
        },
        {
            "Ir. szám": "3109",
            "Település": "Salgótarján",
            "Településrész": "Salgóbánya"
        },
        {
            "Ir. szám": "3141",
            "Település": "Salgótarján",
            "Településrész": "Zagyvaróna"
        },
        {
            "Ir. szám": "3144",
            "Település": "Mátranovák",
            "Településrész": "Bányatelep"
        },
        {
            "Ir. szám": "3145",
            "Település": "Mátraterenye",
            "Településrész": "Homokterenye"
        },
        {
            "Ir. szám": "3146",
            "Település": "Mátraterenye",
            "Településrész": "Nádújfalu"
        },
        {
            "Ir. szám": "3147",
            "Település": "Kazár",
            "Településrész": "Mizserfa"
        },
        {
            "Ir. szám": "3221",
            "Település": "Gyöngyös",
            "Településrész": "Kékestető"
        },
        {
            "Ir. szám": "3232",
            "Település": "Gyöngyös",
            "Településrész": "Mátrafüred"
        },
        {
            "Ir. szám": "3233",
            "Település": "Gyöngyös",
            "Településrész": "Mátraháza"
        },
        {
            "Ir. szám": "3234",
            "Település": "Mátraszentimre",
            "Településrész": "Galyatető"
        },
        {
            "Ir. szám": "3244",
            "Település": "Parád",
            "Településrész": "Parádfürdő"
        },
        {
            "Ir. szám": "3272",
            "Település": "Visonta",
            "Településrész": "Mátrai Erőmű"
        },
        {
            "Ir. szám": "3304",
            "Település": "Eger",
            "Településrész": "Felnémet"
        },
        {
            "Ir. szám": "3508",
            "Település": "Miskolc",
            "Településrész": "Görömböly"
        },
        {
            "Ir. szám": "3508",
            "Település": "Miskolc",
            "Településrész": "Hejőcsaba"
        },
        {
            "Ir. szám": "3509",
            "Település": "Miskolc",
            "Településrész": "Újdiósgyőr"
        },
        {
            "Ir. szám": "3510",
            "Település": "Miskolc",
            "Településrész": "Bükkszentlászló"
        },
        {
            "Ir. szám": "3510",
            "Település": "Miskolc",
            "Településrész": "Lyukó"
        },
        {
            "Ir. szám": "3510",
            "Település": "Miskolc",
            "Településrész": "Pereces"
        },
        {
            "Ir. szám": "3510",
            "Település": "Miskolc",
            "Településrész": "Szarkahegy"
        },
        {
            "Ir. szám": "3511",
            "Település": "Miskolc",
            "Településrész": "Diósgyőr"
        },
        {
            "Ir. szám": "3515",
            "Település": "Miskolc",
            "Településrész": "Egyetemváros"
        },
        {
            "Ir. szám": "3516",
            "Település": "Miskolc",
            "Településrész": "Görömböly"
        },
        {
            "Ir. szám": "3517",
            "Település": "Miskolc",
            "Településrész": "Lillafüred"
        },
        {
            "Ir. szám": "3518",
            "Település": "Miskolc",
            "Településrész": "Pereces"
        },
        {
            "Ir. szám": "3519",
            "Település": "Miskolc",
            "Településrész": "Tapolca"
        },
        {
            "Ir. szám": "3519",
            "Település": "Miskolc",
            "Településrész": "Tapolcafürdő"
        },
        {
            "Ir. szám": "3521",
            "Település": "Miskolc",
            "Településrész": "Szirma"
        },
        {
            "Ir. szám": "3524",
            "Település": "Miskolc",
            "Településrész": "Avas"
        },
        {
            "Ir. szám": "3528",
            "Település": "Miskolc",
            "Településrész": "Martinkertváros"
        },
        {
            "Ir. szám": "3530",
            "Település": "Miskolc",
            "Településrész": "Avas"
        },
        {
            "Ir. szám": "3585",
            "Település": "Tiszaújváros",
            "Településrész": "Óváros"
        },
        {
            "Ir. szám": "3603",
            "Település": "Ózd",
            "Településrész": "Sajóvárkony"
        },
        {
            "Ir. szám": "3604",
            "Település": "Ózd",
            "Településrész": "Bánszállás"
        },
        {
            "Ir. szám": "3621",
            "Település": "Ózd",
            "Településrész": "Uraj"
        },
        {
            "Ir. szám": "3625",
            "Település": "Ózd",
            "Településrész": "Szentsimon"
        },
        {
            "Ir. szám": "3651",
            "Település": "Ózd",
            "Településrész": "Center"
        },
        {
            "Ir. szám": "3661",
            "Település": "Ózd",
            "Településrész": "Hódoscsépány"
        },
        {
            "Ir. szám": "3662",
            "Település": "Ózd",
            "Településrész": "Somsálybánya"
        },
        {
            "Ir. szám": "3672",
            "Település": "Borsodnádasd",
            "Településrész": "Lemezgyár"
        },
        {
            "Ir. szám": "3783",
            "Település": "Edelény",
            "Településrész": "Finke"
        },
        {
            "Ir. szám": "3860",
            "Település": "Encs",
            "Településrész": "Fügöd"
        },
        {
            "Ir. szám": "3860",
            "Település": "Encs ",
            "Településrész": "Abaújdevecser"
        },
        {
            "Ir. szám": "3923",
            "Település": "Gesztely",
            "Településrész": "Újharangod"
        },
        {
            "Ir. szám": "3944",
            "Település": "Sátoraljaújhely",
            "Településrész": "Károlyfalva"
        },
        {
            "Ir. szám": "3945",
            "Település": "Sátoraljaújhely",
            "Településrész": "Rudabányácska"
        },
        {
            "Ir. szám": "3988",
            "Település": "Sátoraljaújhely",
            "Településrész": "Széphalom"
        },
        {
            "Ir. szám": "4002",
            "Település": "Debrecen",
            "Településrész": "Dombos"
        },
        {
            "Ir. szám": "4002",
            "Település": "Debrecen",
            "Településrész": "Kismacs"
        },
        {
            "Ir. szám": "4014",
            "Település": "Debrecen",
            "Településrész": "Pallag"
        },
        {
            "Ir. szám": "4022",
            "Település": "Debrecen",
            "Településrész": "Tégláskert"
        },
        {
            "Ir. szám": "4063",
            "Település": "Debrecen",
            "Településrész": "Nagymacs"
        },
        {
            "Ir. szám": "4067",
            "Település": "Egyek",
            "Településrész": "Telekháza"
        },
        {
            "Ir. szám": "4074",
            "Település": "Hajdúböszörmény",
            "Településrész": "Nagypród"
        },
        {
            "Ir. szám": "4078",
            "Település": "Debrecen",
            "Településrész": "Haláp"
        },
        {
            "Ir. szám": "4079",
            "Település": "Debrecen",
            "Településrész": "Bánk"
        },
        {
            "Ir. szám": "4085",
            "Település": "Hajdúnánás",
            "Településrész": "Tedej"
        },
        {
            "Ir. szám": "4086",
            "Település": "Hajdúböszörmény",
            "Településrész": "Hajdúvid"
        },
        {
            "Ir. szám": "4103",
            "Település": "Berettyóújfalu",
            "Településrész": "Berettyószentmárton"
        },
        {
            "Ir. szám": "4162",
            "Település": "Szerep",
            "Településrész": "Hosszúhát"
        },
        {
            "Ir. szám": "4224",
            "Település": "Hajdúböszörmény",
            "Településrész": "Bodaszőlő"
        },
        {
            "Ir. szám": "4225",
            "Település": "Debrecen",
            "Településrész": "Józsa"
        },
        {
            "Ir. szám": "4246",
            "Település": "Nyíregyháza",
            "Településrész": "Butykatelep"
        },
        {
            "Ir. szám": "4252",
            "Település": "Nyíradony",
            "Településrész": "Tamásipuszta"
        },
        {
            "Ir. szám": "4253",
            "Település": "Nyíradony",
            "Településrész": "Aradványpuszta"
        },
        {
            "Ir. szám": "4281",
            "Település": "Létavértes",
            "Településrész": "Nagyléta"
        },
        {
            "Ir. szám": "4283",
            "Település": "Létavértes",
            "Településrész": "Vértes"
        },
        {
            "Ir. szám": "4405",
            "Település": "Nyíregyháza",
            "Településrész": "Borbánya"
        },
        {
            "Ir. szám": "4413",
            "Település": "Nyíregyháza",
            "Településrész": "Jósaváros"
        },
        {
            "Ir. szám": "4431",
            "Település": "Nyíregyháza",
            "Településrész": "Sóstófürdő"
        },
        {
            "Ir. szám": "4432",
            "Település": "Nyíregyháza",
            "Településrész": "Nyírszőlős"
        },
        {
            "Ir. szám": "4433",
            "Település": "Nyíregyháza",
            "Településrész": "Felsősima"
        },
        {
            "Ir. szám": "4435",
            "Település": "Nyíregyháza",
            "Településrész": "Rozsrétszőlős"
        },
        {
            "Ir. szám": "4446",
            "Település": "Tiszaeszlár",
            "Településrész": "Bashalom"
        },
        {
            "Ir. szám": "4447",
            "Település": "Tiszalök",
            "Településrész": "Kisfástanya"
        },
        {
            "Ir. szám": "4471",
            "Település": "Gávavencsellő",
            "Településrész": "Gáva"
        },
        {
            "Ir. szám": "4472",
            "Település": "Gávavencsellő",
            "Településrész": "Vencsellő"
        },
        {
            "Ir. szám": "4481",
            "Település": "Nyíregyháza",
            "Településrész": "Sóstóhegy"
        },
        {
            "Ir. szám": "4486",
            "Település": "Tiszatelek",
            "Településrész": "Kétérköz"
        },
        {
            "Ir. szám": "4551",
            "Település": "Nyíregyháza",
            "Településrész": "Oros"
        },
        {
            "Ir. szám": "4803",
            "Település": "Vásárosnamény",
            "Településrész": "Gergelyiugornya"
        },
        {
            "Ir. szám": "4804",
            "Település": "Vásárosnamény",
            "Településrész": "Vitka"
        },
        {
            "Ir. szám": "5008",
            "Település": "Szolnok",
            "Településrész": "Szandaszőllős"
        },
        {
            "Ir. szám": "5095",
            "Település": "Tiszavárkony",
            "Településrész": "Tiszavárkonyi-Szőlők"
        },
        {
            "Ir. szám": "5152",
            "Település": "Jászberény",
            "Településrész": "Portelek"
        },
        {
            "Ir. szám": "5212",
            "Település": "Törökszentmiklós",
            "Településrész": "Surjány"
        },
        {
            "Ir. szám": "5213",
            "Település": "Fegyvernek",
            "Településrész": "Szapárfalu"
        },
        {
            "Ir. szám": "5323",
            "Település": "Tiszaszentimre",
            "Településrész": "Újszentgyörgy"
        },
        {
            "Ir. szám": "5349",
            "Település": "Kenderes",
            "Településrész": "Bánhalma"
        },
        {
            "Ir. szám": "5358",
            "Település": "Tiszafüred",
            "Településrész": "Tiszaörvény"
        },
        {
            "Ir. szám": "5359",
            "Település": "Tiszafüred",
            "Településrész": "Kócsújfalu"
        },
        {
            "Ir. szám": "5449",
            "Település": "Kunszentmárton",
            "Településrész": "Kungyalu"
        },
        {
            "Ir. szám": "5461",
            "Település": "Tiszaföldvár",
            "Településrész": "Homok"
        },
        {
            "Ir. szám": "5501",
            "Település": "Gyomaendrőd",
            "Településrész": "Gyoma"
        },
        {
            "Ir. szám": "5502",
            "Település": "Gyomaendrőd",
            "Településrész": "Endrőd"
        },
        {
            "Ir. szám": "5623",
            "Település": "Békéscsaba",
            "Településrész": "Gerla"
        },
        {
            "Ir. szám": "5664",
            "Település": "Medgyesbodzás",
            "Településrész": "Gábortelep"
        },
        {
            "Ir. szám": "5671",
            "Település": "Békéscsaba",
            "Településrész": "Mezőmegyer"
        },
        {
            "Ir. szám": "5703",
            "Település": "Gyula",
            "Településrész": "József Attila szanatórium"
        },
        {
            "Ir. szám": "5711",
            "Település": "Gyula",
            "Településrész": "Gyulavári"
        },
        {
            "Ir. szám": "5722",
            "Település": "Sarkad",
            "Településrész": "Cukorgyár"
        },
        {
            "Ir. szám": "5752",
            "Település": "Medgyesegyháza",
            "Településrész": "Bánkút"
        },
        {
            "Ir. szám": "5903",
            "Település": "Orosháza",
            "Településrész": "Rákóczitelep"
        },
        {
            "Ir. szám": "5904",
            "Település": "Orosháza",
            "Településrész": "Gyopárosfürdő"
        },
        {
            "Ir. szám": "5905",
            "Település": "Orosháza",
            "Településrész": "Szentetornya"
        },
        {
            "Ir. szám": "6008",
            "Település": "Kecskemét",
            "Településrész": "Méntelek"
        },
        {
            "Ir. szám": "6044",
            "Település": "Kecskemét",
            "Településrész": "Hetényegyháza"
        },
        {
            "Ir. szám": "6062",
            "Település": "Tiszakécske",
            "Településrész": "Tiszabög"
        },
        {
            "Ir. szám": "6066",
            "Település": "Tiszaalpár",
            "Településrész": "Alpár"
        },
        {
            "Ir. szám": "6067",
            "Település": "Tiszaalpár",
            "Településrész": "Tiszaújfalú"
        },
        {
            "Ir. szám": "6327",
            "Település": "Harta",
            "Településrész": "Állampuszta"
        },
        {
            "Ir. szám": "6503",
            "Település": "Baja",
            "Településrész": "Bajaszentistván"
        },
        {
            "Ir. szám": "6648",
            "Település": "Csongrád",
            "Településrész": "Bokros"
        },
        {
            "Ir. szám": "6710",
            "Település": "Szeged",
            "Településrész": "Szentmihály"
        },
        {
            "Ir. szám": "6728",
            "Település": "Szeged",
            "Településrész": "Baktó"
        },
        {
            "Ir. szám": "6729",
            "Település": "Szeged",
            "Településrész": "Tompasziget"
        },
        {
            "Ir. szám": "6753",
            "Település": "Szeged",
            "Településrész": "Tápé"
        },
        {
            "Ir. szám": "6757",
            "Település": "Szeged",
            "Településrész": "Gyálarét"
        },
        {
            "Ir. szám": "6771",
            "Település": "Szeged",
            "Településrész": "Szőreg"
        },
        {
            "Ir. szám": "6791",
            "Település": "Szeged",
            "Településrész": "Kiskundorozsm"
        },
        {
            "Ir. szám": "6791",
            "Település": "Szeged",
            "Településrész": "Kiskundorozsma"
        },
        {
            "Ir. szám": "6806",
            "Település": "Hódmezővásárhely",
            "Településrész": "Szikáncs"
        },
        {
            "Ir. szám": "6903",
            "Település": "Makó",
            "Településrész": "Rákos"
        },
        {
            "Ir. szám": "7003",
            "Település": "Sárbogárd",
            "Településrész": "Sárszentmiklós"
        },
        {
            "Ir. szám": "7018",
            "Település": "Sárbogárd",
            "Településrész": "Pusztaegres"
        },
        {
            "Ir. szám": "7019",
            "Település": "Sárbogárd",
            "Településrész": "Sárhatvan"
        },
        {
            "Ir. szám": "7027",
            "Település": "Paks",
            "Településrész": "Dunakömlőd"
        },
        {
            "Ir. szám": "7131",
            "Település": "Tolna",
            "Településrész": "Mözs"
        },
        {
            "Ir. szám": "7187",
            "Település": "Bonyhád",
            "Településrész": "Majos"
        },
        {
            "Ir. szám": "7300",
            "Település": "Komló",
            "Településrész": "Mecsekjánosi"
        },
        {
            "Ir. szám": "7385",
            "Település": "Gödre",
            "Településrész": "Gödreszentmárton"
        },
        {
            "Ir. szám": "7386",
            "Település": "Gödre",
            "Településrész": "Gödrekeresztúr"
        },
        {
            "Ir. szám": "7409",
            "Település": "Kaposvár",
            "Településrész": "Kaposfüred"
        },
        {
            "Ir. szám": "7557",
            "Település": "Barcs",
            "Településrész": "Somogytarnóca"
        },
        {
            "Ir. szám": "7691",
            "Település": "Pécs",
            "Településrész": "Vasas"
        },
        {
            "Ir. szám": "7693",
            "Település": "Pécs",
            "Településrész": "Hird"
        },
        {
            "Ir. szám": "7693",
            "Település": "Pécs",
            "Településrész": "Vasas"
        },
        {
            "Ir. szám": "7714",
            "Település": "Mohács",
            "Településrész": "Újmohács"
        },
        {
            "Ir. szám": "7715",
            "Település": "Mohács",
            "Településrész": "Sárhát"
        },
        {
            "Ir. szám": "8019",
            "Település": "Székesfehérvár",
            "Településrész": "Börgönd"
        },
        {
            "Ir. szám": "8054",
            "Település": "Balinka",
            "Településrész": "Eszény"
        },
        {
            "Ir. szám": "8103",
            "Település": "Várpalota",
            "Településrész": "Inota"
        },
        {
            "Ir. szám": "8104",
            "Település": "Várpalota",
            "Településrész": "Inotai Erőmű"
        },
        {
            "Ir. szám": "8131",
            "Település": "Enying",
            "Településrész": "Balatonbozsok"
        },
        {
            "Ir. szám": "8171",
            "Település": "Balatonvilágos",
            "Településrész": "Balatonaliga"
        },
        {
            "Ir. szám": "8172",
            "Település": "Balatonkenese",
            "Településrész": "Balatonakarattya"
        },
        {
            "Ir. szám": "8173",
            "Település": "Balatonkenese",
            "Településrész": "Üdülőtelep"
        },
        {
            "Ir. szám": "8182",
            "Település": "Berhida",
            "Településrész": "Peremartongyártelep"
        },
        {
            "Ir. szám": "8184",
            "Település": "Balatonfűzfő",
            "Településrész": "Fűzfőgyártelep"
        },
        {
            "Ir. szám": "8236",
            "Település": "Balatonfüred",
            "Településrész": "Balatonarács"
        },
        {
            "Ir. szám": "8257",
            "Település": "Badacsonytomaj",
            "Településrész": "Badacsonyörs"
        },
        {
            "Ir. szám": "8261",
            "Település": "Badacsonytomaj",
            "Településrész": "Badacsony"
        },
        {
            "Ir. szám": "8297",
            "Település": "Tapolca",
            "Településrész": "Diszel"
        },
        {
            "Ir. szám": "8411",
            "Település": "Veszprém",
            "Településrész": "Kádárta"
        },
        {
            "Ir. szám": "8412",
            "Település": "Veszprém",
            "Településrész": "Gyulafirátót"
        },
        {
            "Ir. szám": "8447",
            "Település": "Ajka",
            "Településrész": "Ajkarendek"
        },
        {
            "Ir. szám": "8448",
            "Település": "Ajka",
            "Településrész": "Bakonygyepes"
        },
        {
            "Ir. szám": "8451",
            "Település": "Ajka",
            "Településrész": "Padragkút"
        },
        {
            "Ir. szám": "8511",
            "Település": "Pápa",
            "Településrész": "Borsosgyőr"
        },
        {
            "Ir. szám": "8531",
            "Település": "Marcaltő",
            "Településrész": "Ihász"
        },
        {
            "Ir. szám": "8591",
            "Település": "Pápa",
            "Településrész": "Kéttornyúlak"
        },
        {
            "Ir. szám": "8598",
            "Település": "Pápa",
            "Településrész": "Tapolcafő"
        },
        {
            "Ir. szám": "8609",
            "Település": "Siófok",
            "Településrész": "Balatonszéplak"
        },
        {
            "Ir. szám": "8611",
            "Település": "Siófok",
            "Településrész": "Balatonkiliti"
        },
        {
            "Ir. szám": "8691",
            "Település": "Balatonboglár",
            "Településrész": "Szőlőskislak"
        },
        {
            "Ir. szám": "8709",
            "Település": "Marcali",
            "Településrész": "Horvátkút"
        },
        {
            "Ir. szám": "8714",
            "Település": "Marcali",
            "Településrész": "Bize"
        },
        {
            "Ir. szám": "8751",
            "Település": "Zalakomár",
            "Településrész": "Kiskomárom"
        },
        {
            "Ir. szám": "8752",
            "Település": "Zalakomár",
            "Településrész": "Komárváros"
        },
        {
            "Ir. szám": "8785",
            "Település": "Zalaszentgrót",
            "Településrész": "Zalakoppány"
        },
        {
            "Ir. szám": "8789",
            "Település": "Zalaszentgrót",
            "Településrész": "Zalaudvarnok"
        },
        {
            "Ir. szám": "8793",
            "Település": "Zalaszentgrót",
            "Településrész": "Tekenye"
        },
        {
            "Ir. szám": "8795",
            "Település": "Zalaszentgrót",
            "Településrész": "Csáford"
        },
        {
            "Ir. szám": "8808",
            "Település": "Nagykanizsa",
            "Településrész": "Palin"
        },
        {
            "Ir. szám": "8809",
            "Település": "Nagykanizsa",
            "Településrész": "Sánc"
        },
        {
            "Ir. szám": "8831",
            "Település": "Nagykanizsa",
            "Településrész": "Miklósfa"
        },
        {
            "Ir. szám": "8904",
            "Település": "Zalaegerszeg",
            "Településrész": "Andráshida"
        },
        {
            "Ir. szám": "8908",
            "Település": "Zalaegerszeg",
            "Településrész": "Szanatórium"
        },
        {
            "Ir. szám": "8966",
            "Település": "Lenti",
            "Településrész": "Lentikápolna"
        },
        {
            "Ir. szám": "9010",
            "Település": "Győr",
            "Településrész": "Bácsa"
        },
        {
            "Ir. szám": "9011",
            "Település": "Győr",
            "Településrész": "Győrszentiván"
        },
        {
            "Ir. szám": "9012",
            "Település": "Győr",
            "Településrész": "Ménfőcsanak"
        },
        {
            "Ir. szám": "9019",
            "Település": "Győr",
            "Településrész": "Gyirmót"
        },
        {
            "Ir. szám": "9025",
            "Település": "Győr",
            "Településrész": "Pinnyéd"
        },
        {
            "Ir. szám": "9027",
            "Település": "Győr",
            "Településrész": "Városrét"
        },
        {
            "Ir. szám": "9098",
            "Település": "Mezőörs",
            "Településrész": "Mindszentpuszta"
        },
        {
            "Ir. szám": "9183",
            "Település": "Mosonszentmiklós",
            "Településrész": "Mosonújhely"
        },
        {
            "Ir. szám": "9241",
            "Település": "Jánossomorja",
            "Településrész": "Mosonszentjános"
        },
        {
            "Ir. szám": "9242",
            "Település": "Jánossomorja",
            "Településrész": "Pusztasomorja"
        },
        {
            "Ir. szám": "9339",
            "Település": "Kapuvár",
            "Településrész": "Öntésmajor"
        },
        {
            "Ir. szám": "9407",
            "Település": "Sopron",
            "Településrész": "Sopronkőhida"
        },
        {
            "Ir. szám": "9408",
            "Település": "Sopron",
            "Településrész": "Brennbergbánya"
        },
        {
            "Ir. szám": "9433",
            "Település": "Fertőd",
            "Településrész": "Tőzeggyármajor"
        },
        {
            "Ir. szám": "9434",
            "Település": "Sarród",
            "Településrész": "Fertőújlak"
        },
        {
            "Ir. szám": "9438",
            "Település": "Sarród",
            "Településrész": "Nyárliget"
        },
        {
            "Ir. szám": "9494",
            "Település": "Sopron",
            "Településrész": "Balf"
        },
        {
            "Ir. szám": "9541",
            "Település": "Celldömölk",
            "Településrész": "Izsákfa"
        },
        {
            "Ir. szám": "9608",
            "Település": "Sárvár",
            "Településrész": "Rábasömjén"
        },
        {
            "Ir. szám": "9609",
            "Település": "Sárvár",
            "Településrész": "Lánkapuszta"
        },
        {
            "Ir. szám": "9707",
            "Település": "Szombathely",
            "Településrész": "Herény"
        },
        {
            "Ir. szám": "9719",
            "Település": "Szombathely",
            "Településrész": "Szentkirály"
        },
        {
            "Ir. szám": "9740",
            "Település": "Bük",
            "Településrész": "Bükfürdő"
        },
        {
            "Ir. szám": "9909",
            "Település": "Körmend",
            "Településrész": "Horvátnádalja"
        },
        {
            "Ir. szám": "9955",
            "Település": "Szentgotthárd",
            "Településrész": "Rábafüzes"
        },
        {
            "Ir. szám": "9981",
            "Település": "Szentgotthárd",
            "Településrész": "Farkasfa"
        }
    ]
}

export default DATA