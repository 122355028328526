import React, {Component} from 'react';
import ReactQuill from 'react-quill'; 

import 'react-quill/dist/quill.snow.css';
import './MessagesSender.css'




 class MessagesSender extends Component  {

  state = {
    newmessages : '',
  }
  reactQuillRef;
  formats =  [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ];

  modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      
    ],
  };


  componentDidMount() {
  
  }
  handleChange(value)
  {
      //console.log(value);
      this.setState({newmessages:value});
  }
  
  sendMessage = ()=>
  {
    //console.log('Az üzenet: '+ this.state.newmessages);
    //console.log('TXT:',this.reactQuillRef.makeUnprivilegedEditor(this.reactQuillRef.getEditor()).getText());
    //let message = this.reactQuillRef.makeUnprivilegedEditor(this.reactQuillRef.getEditor()).getText();
    let message = this.state.newmessages;
    if (message!="" || message!=" ")
    {
      this.props.call(message);
      this.setState({newmessages:""});
    }
    
  }

  render(){


    
    return (
       
        <div className="messages_sender">
        <ReactQuill modules={this.modules} formats={this.formats} ref={(el) => { this.reactQuillRef = el }} placeholder= "üzenet..." value={this.state.newmessages} onChange={(value)=>this.handleChange(value)} style={{height:'140px',minHeight:'140px'}} />
        <div className="w-100 d-flex justify-content-end">
            <button className="askButton" onClick={()=>this.sendMessage()}>Küldés</button>
        </div>
        </div>


      
    );

  }
}


export default MessagesSender;


