import React, {Component} from 'react';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
} from "react-router-dom";


/* ROUTS */
import RegisterLanding from      './Register_Landing/RegisterLanding';
import RegisterSelector from     './Register_Selector/RegisterSelector';
import RegisterUser from         './Register_user/registerUser';
import RegisterProfessional from './Register_professional/registerProfessional';
//import RegisterPage from './RegisterPage'
import Page404 from '../Page404';



 class Registration extends Component  {
  render(){
    const {path} = this.props.match
    return (

      <Switch>
        <Route exact path={path} component={RegisterLanding}/>
        <Route path={`${path}/:subpage`} component={RegistrationComponents}/>
      </Switch>

    );

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default Registration;


 const RegistrationComponents =() => {

    const { subpage } = useParams();
    const history = useHistory()

    

    switch (subpage) {
      case "selector":
            return <RegisterSelector/>
      case "user":
            if(history.location.hash && history.location.hash === "#professional"){
              return <RegisterUser registerType="professional" />
            }else{
              return <RegisterUser registerType="user" />
            }
            

      case "professional":
            return <RegisterProfessional/>
      default:
            return <RegisterLanding/>
    }

}