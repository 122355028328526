import React,{Component} from 'react';
import parse from 'html-react-parser';

import './MessagesReader.css'



//ez az üzenet formátuma amit a messages.js konvertál
//const loadedMessages = [
//{ id:'1',senderid:'1121',date:'2018 marc.4 12:19', text:"Minions ipsum ut labore velit bappleees nostrud aliquip. Underweaaar chasy veniam butt duis irure ut exercitation. Poopayee butt ullamco underweaaar aaaaaah underweaaar aaaaaah reprehenderit exercitation poulet tikka masala ad. Tatata bala tu aliquip velit tulaliloo irure et chasy pepete cillum. Consectetur labore para tú enim magna et"},
//];



 class MessagesReader extends Component  {

  state = {

    scrollPosition:null,

  }
   

  scrollArea;

  componentDidMount() {
    this.scrollArea = document.querySelector("#messagesInbox");
    this.scrollArea.addEventListener('scroll', this.listenToScroll);
    
  }
  componentWillUnmount() {
    this.scrollArea.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = ()=>
  {
    this.storeScrollPosition();
  }

  componentDidUpdate(prevProps,prevState)
  {
 
    if (this.state.scrollPosition==null)
    {
      this.scrollMoveToBottom();
      this.storeScrollPosition();
      console.log("scrollposition");
    }else

    if (this.state.scrollPosition.height!=this.scrollArea.scrollHeight)
    {
      console.log("----------AAAAAAAAAA______________",prevState,this.state);
      
      this.scrollArea.scrollTop = this.scrollArea.scrollHeight-this.scrollArea.clientHeight;
      this.storeScrollPosition();

    }

     
    
  }

  storeScrollPosition()
  {
   let st = {...this.state};
   st.scrollPosition = {position:this.scrollArea.scrollTop,height:this.scrollArea.scrollHeight};
   this.setState(st);
   console.log("SOKSZOR FUTSZ VAZZE", st.scrollPosition);
  }  

  scrollMoveToBottom()
  {
    this.scrollArea.scrollTop = this.scrollArea.scrollHeight-this.scrollArea.clientHeight;
  }

 



  render(){

   
    let cards = this.props.messages.map(
      (ret,index) =>
      {
        return <MessagesCard key={index} messageType={ret.messageType} date={ret.date} text={ret.text} img={ret.img} ></MessagesCard>
      }
    );
    
    return (
       
        <div className="messagesInbox" id="messagesInbox" ref='scrollArea'>
            {cards.length!=0 ? cards:"Jelenleg nincs üzenet"}
        </div>


      
    );

  }
}


export default MessagesReader;


class MessagesCard extends Component  {

  
  render(){
    //console.log(this.props)

    if (this.props.messageType=="own")
    {
      return(
        <div className="right-box d-flex justify-content-start">
          <div className="textbox w-100">
              <div className="date">{this.props.date}</div>
              <div className="text">{parse(this.props.text)}</div>
          </div>
          <img className="img" src={this.props.img} alt="profile image"/>
        </div>
        );      

    }else
    {
      return(
      <div className="left-box d-flex justify-content-start">
        <img className="img" src={this.props.img} alt="profile image"/>
        <div className="textbox w-100">
            <div className="date">{this.props.date}</div>
            <div className="text">{parse(this.props.text)}</div>
        </div>
      </div>
      );
    }


  }
} 