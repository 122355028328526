import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";
import mesterlaptop from "../Assets/mesterlaptop.png"

 class Page404 extends Component  {
  

    render(){
      const window_width =  this.props.systemState.dimension.width;
      return (
        <div style={{...styles.outerBG}}>

        <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
          <div>404</div>
        </div>
       
            {/* LIST */}
            <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        
              <div style={{display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:'center', alignItems:"center"}}>
                  <div style={{...styles.fontStyle, fontSize:30, margin:50}}>404</div>
                  <div style={{...styles.fontStyle}}>A KERESETT OLDAL NEM TALÁLHATÓ</div>
                  <img src={mesterlaptop} style={{ width:"50%", height:"50%" }} />
              </div>

          </Container>

        <div style={{...styles.bottomSpacer}}/>

      </div>
      );
  
    }
  }

  const styles = {

    headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
    bottomSpacer: {height:30, width:"100%"},
    outerBG: {background:"#F6F9FE"},
    fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},

    
  }
  



/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState} = state;
  return {systemState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Page404);
