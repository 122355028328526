/* 
* REQUEST CONFIGURATION 
*/

/* ======= ENVIROVMENT ======= */

//export const env_baseURL = "http://vh.i234.me:4091/";
export const env_baseURL = "https://api.mesterportal.com/";
export const env_apiPrefix = "api/";
export const env_apiVersion = "v1/";

/* =========================== */