import React, {Component} from 'react';
import {Link} from "react-router-dom";
import img_1 from "./Assets/1.png"; 
import img_2 from "./Assets/2.png"; 
//styles
import './Services.css';
//images

const ServicesBlog = [
    {
        img: img_1,
        id:7,
        title:"ÁCS",
        description: "Az ács olyan szakképzett mesterember, aki zsaluzatokat, állvány-szerkezeteket és maradó faszerkezeteket készít, bont, átalakít, javít és karbantart. Ácsmesterségnek nevezzük a faszerkezeteket, illetve a stabil gerendaszerkezeteket készítő kisiparos tevékenységet. Az ács, vagy áts szó honfoglalás előtti török eredetű jövevényszavunk, valószínűleg a még nem specializálódott fafaragó embert jelölték vele. (Szláv megfelelője a taszár, illetve a teszér.)",
        link:""

    },
    {
        img: img_2,
        id:8,
        title:"BÁDOGOS",
        description: "A bádogos hagyományos fémműves kisipar művelője: fehér és fekete bádogból, cink-, ón-, réz-, alumínium- stb. lemezekből különféle egyszerűbb használati vagy tároló edényeket, tartályokat, lámpatesteket, lemezkályhákat, tűzhelyeket, lemezfedéseket, ereszeket, ereszcsatornákat készít. Magyarországon a 15. században honosodott meg, s a 19. században a legkülönfélébb bádogedényeket készítették a parasztság számára: vízmerítő és borászati edények, pl. meszely, tölcsér és háztartási eszközök: tésztaszűrő, tésztareszelő, öntözőkanna, stb. A 20. század elején a tehetősebb városi lakosság számára eleinte szintén bádogosok készítették az elterjedőben lévő fürdőszobák felszereléseit: a kádat, az öblítőszéket és a vízmelegítőféleségeket, majd ezeket be is szerelték.",
        link:""

    }    
];

 class Services extends Component  {



  render(){

    this.renderedServices = ServicesBlog.map(
        (ret,index) => {
            if (index<this.props.num)
            {
                return(
                    <div key={index} className="servicesBlog">
                        <div className="ServiceImage" style={{backgroundImage: `url(${ret.img})`}}></div>
                        <div className="ServiceTitle">{ret.title}</div>
                        <div className="ServiceDescription">{ret.description}</div>
                        <Link to={`/servicesmarty/${ret.id}`}><div className="ServiceLink">Tovább a cikkre</div></Link>
                    </div>

                );
            }
            return null;
        }
    );

    console.log(this.renderedServices);

    return (

    <div>    
        <div className="row">
            <div className="col-12">
                <div className="serviceTittle">Szolgáltatás kisokos</div>
            </div>
        </div>
        <div className="d-flex w-100 justify-content-center" style={{flexWrap:"wrap"}}>
            {this.renderedServices}          

        </div>
    </div>
    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default Services;

