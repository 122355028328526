import React, {Component} from 'react';
import {connect} from "react-redux";

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  useParams,
  Redirect
} from "react-router-dom";


/* ROUTS */
import Profiles from './Profiles/Profiles';
import Offer from './Offer/Offer';
import Messages from './Messages/Messages';
import Mandate from './Mandate/Mandate';
import Calendar from './Calendar/Calendar';
import ToolsRent from './ToolsRent/ToolsRent';
import SuccessFees from './SuccessFees/SuccessFees';
import UserProfiles from './UserProfiles/UserProfiles';




 class Experts extends Component  {
    
    render(){
      console.log()
      const {path} = this.props.match;
      const window_width =  this.props.systemState.dimension.width;
      return (
        <div style={{...styles.outerBG}}>
            <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
              <div>MESTER PROFIL</div>
            </div>
            <Switch>
              <Redirect exact path="/experts" to={`${path}/profiles`}/>
              <Route path={`${path}/:subpages`} component={ExpertsSubManager}/>
            </Switch>
            <div style={{...styles.bottomSpacer}}/>
        </div>
      );
    }
}

const styles = {
  headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
  bottomSpacer: {height:30, width:"100%"},
  outerBG: {background:"#F6F9FE"}
}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Experts);


/*===================================================  
* ROUTS
====================================================*/
 const ExpertsSubManager =() => {

  const { subpages } = useParams();
 
    switch (subpages) {
      case "profiles":
            return <Profiles/>
      case "userprofiles":
              return <UserProfiles/>
      case "offer":
            return <Offer/>
      case "messages":
            return <Messages/>
      case "mandate":
            return <Mandate/>
      case "calendar":
            return <Calendar/>
      case "toolsrent":
            return <ToolsRent/>   
      case "successfees":
            return <SuccessFees/> 

      default : 
            return <Profiles/>      
     
    }

}