


// return the end of the input date
export const endOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

// return the start of the input date
export const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth());

/// return to date of added number
export const addMounthToDate = (number, date) => new Date(date.setMonth(date.getMonth()+number)); 
/// endOfMonth(date_plus_mounth(0))