import React, {Component} from 'react';
import queryString from "query-string";
import {connect} from 'react-redux';
/* ROUTER */
import {withRouter} from "react-router-dom";
import Map from "../../../../View/Map";
import {request}   from "../../../../Model/Request";
import parse from 'html-react-parser';
import { Modal, Button} from "react-bootstrap";
import chat_icon from "../../../../Assets/message_2.png";

import './Agreement.css'

const OffersDetailsData = 
    {
          
        ads_tittle: 'Ács Munkálatok',
        ads_id:'25455',
        ads_description: 'Elementum aenean interdum volutpat parturient dictum urna. Sed urna curabitur interdum vitae pulvinar consequat massa. Risus, eget proin pellentesque morbi. Imperdiet diam in donec morbi dignissim vel auctor. .....',
        ads_duedate:"1 hónapon belül",
        ads_cost_limit:"50.000 - 100.000 Ft",
        ads_workplace:"Pákozd",
        ads_img:'https://picsum.photos/id/11/373/293',

        offer_description:"Facilisis integer velit ornare sapien massa sagittis gravida vel. Habitasse sed potenti at nisi diam dictum. Quam arcu, aliquet scelerisque pharetra in maecenas luctus. Aenean pellentesque interdum auctor in a eget fringilla. Molestie imperdiet mauris tristique dolor tempor integer nulla. Purus, orci, neque egestas lacus, ultrices pellentesque amet, in enim..... Facilisis integer velit ornare sapien massa sagittis gravida vel. Habitasse sed potenti at nisi diam dictum. Quam arcu, aliquet scelerisque pharetra in maecenas luctus. Aenean pellentesque interdum auctor in a eget fringilla. Molestie imperdiet mauris tristique dolor tempor integer nulla. Purus, orci, neque egestas lacus, ultrices pellentesque amet, in enim.....",
        offer_details:[
            {job_tittle:"Tetőablak",job_quantity:"1 Brb",job_price:"75000 Ft"},
            {job_tittle:"Homlokzati Nyílászáró",job_quantity:"1 Brb",job_price:"75000 Ft"},
            {job_tittle:"Műanyag Ablak",job_quantity:"1 Brb",job_price:"75000 Ft"},
        ],
        offer_total:"225.000 Ft",
        status:'inview',  //inview, accepted, rejected
        id: '1',
        agreement:{
          builder:{
            name:"Kovács Alajos",
            address:"1111 Budapest Fő u. 6",
            trusted:"Kovács Lajos",
            companynumber:"1111114343",
            registrycourt:"Kecskeméti cégbíróság",
            taxnumber:"1232132-3-44",
            bankaccountnumber:"11734234-234234324-10000008",
            agent:"Nagy Béla Károly"
          },
          implementer:{
            name:"Nagykovácsi Áron",
            address:"1212 Budapest Kocsis u. 33",
            trusted:"Nagykovácsi Áron",
            companynumber:"1111114343",
            registrycourt:"Kecskeméti cégbíróság",
            taxnumber:"1232132-3-44",
            bankaccountnumber:"11734234-234234324-10000008",            
          },
          contractsubject:"ablakcsere",
          customerestate:"ház",
          estateaddress:"1203 Budapest Kolossy u. 44",
          givendate:"2021.01.13",
          startdate:"2021.01.20",
          duedate:"2021.02.01",
          price:"1.250.000 Ft",
          jobparts:[
            "ablak kibontás",
            "falazás",
            "ablakbeépítés",
            "festés"
          ],
          subcontractors:"Nagy Béla vállalkozó asztalos, Kis József festő"


        }
    };

 class Agreement extends Component  {

  state ={
      loadedData:null,
      isModalShow:false, 
      errorMessage:"",  
      successMessage:"",
      start_date:"",
      end_date:"",
      clean_HTML:"",

  }

  componentDidMount(){
    const parsed = queryString.parse(this.props.location.search);
    
    /* GUARD FUNCTION */
    if(typeof parsed.id === "undefined" || parsed.id === null || parsed.id === "" || isNaN(parsed.id)) this.props.history.goBack()

    this.get_data(parsed.id)

  }


  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`

  get_data = async (id) => {

        const response = await request({url:`offers/confirmed/contract?offer_id=${id}`})

        if(typeof response.error !== "undefined" || typeof response.data === "undefined") return


        const start_date = response.data[0].attributes.data.match(/<p id="start_date">.*?<\/p>/g) === null ? this.dateParser_hyphen(new Date( new Date().setDate(new Date().getDate() + 1))) : response.data[0].attributes.data.match(/<p id="start_date">.*?<\/p>/g)[0].replace(/<(.|\n)*?>/g, '').trim()
        const end_date   = response.data[0].attributes.data.match(/<p id="end_date">.*?<\/p>/g)   === null ? this.dateParser_hyphen(new Date( new Date().setDate(new Date().getDate() + 3))) : response.data[0].attributes.data.match(/<p id="end_date">.*?<\/p>/g)[0].replace(/<(.|\n)*?>/g, '').trim()

       

        this.setState({
          loadedData:response.data[0], 
          start_date,
          end_date,
          clean_HTML:response.data[0].attributes.data.replace('<!DOCTYPE html><html><head><title>CONTRACT</title></head><body>', '').replace('<!DOCTYPE html><html><head><meta charset="utf-8"><title>CONTRACT</title></head><body>', '').replace('</body></html>', '')
        });

  }

  

  response = async (type) => {

      const url_end = type === "accept" ? "confirm" : "deny";

      let data = { contract_id : this.state.loadedData.id };

      if(type === "accept"){
        data = { ...data, 
          from_date:this.state.start_date,
          until_date:this.state.end_date
        }
      }
      
     



      const response = await request({ url:`/offers/confirmed/contract/${url_end}`, method:"PATCH", data })

      console.log(response)

      if(typeof response.error !== "undefined"){
          this.setState({
                isModalShow:true,
                errorMessage:"Hiba lépett fel a szerverrel való kommunikáció során! Kérlek próbáld meg később."
          })
      }

      if(typeof response.error === "undefined"){
        this.setState({
            isModalShow:true,
            successMessage: type === "accept" 
                ? "SIKERESEN ELFOGADTAD A SZERZŐDÉST!"
                : "SIKERESEN ELUTASÍTOTTAD A SZERZŐDÉS!"
      })
      }




  }

  render(){
    

    let offerData = {...this.state.loadedData};
    console.log(this.state);


    return (

      <>
      <Modal show={this.state.isModalShow} onHide={ ()=> {
              if(this.state.successMessage !== ""){
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                this.props.history.goBack()
              }else{
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
              }
              
              } }>
        <Modal.Header>
                <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{...styles.p}}>{this.state.errorMessage}</div>
                <div style={{...styles.p}}>{this.state.successMessage}</div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant={ this.state.successMessage === "" ? "secondary" : "primary"} onClick={ ()=> 
                  
                  {
                    if(this.state.successMessage !== ""){
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                      this.props.history.goBack()
                    }else{
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                    }
                  }
                  
                  }>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>


      <div className="innerContainer d-flex justify-content-center">
        <div className="innerAligner d-flex flex-wrap justify-content-start">
          <div className="w-100 agreementdetail">
            <div className="agreementadstittle">Hirdetés</div>
            <div className="agreementadssection d-flex flex-wrap justify-content-start w-100">
                <div className="agreementads-left">
                    
                        <div className="adsleft-subtittle offpaddingsmall">{offerData?.relation?.ad?.attributes?.profession?.name ?? ""}</div>
                        <div className="adsleft-text">Hirdetés szám: {offerData?.relation?.ad?.attributes?.hash_id}</div>
                        <div className="adsleft-text offpaddingsmall">Leirás:</div>
                        <div className="adsleft-text">{parse(offerData?.relation?.ad?.attributes?.description ?? "<p></p>")}</div>
                </div>  
                <div className="vdecor"></div>                  
                <div className="agreementads-middle">
                        <div className="adsright-subtittle">Határidő</div>
                        <div className="adsright-text offpaddingsmall">{offerData?.relation?.ad?.attributes?.deadline?.name}</div>
                        <div className="adsright-subtittle">Költséghatár</div>
                        <div className="adsright-text offpaddingsmall">{offerData?.relation?.ad?.attributes?.payment_limit?.name}</div> 
                        <div className="adsright-subtittle">Munka végzés helye</div>
                        <div className="adsright-text">{offerData?.relation?.ad?.attributes?.location?.city}</div>                                                
                </div>
                
                <div className="agreementads-right">
                    <img className="agreementads-img" src={offerData?.relation?.ad?.attributes?.basic_image?.src} alt="image"/>
                </div>                                
            </div>

          {
            this.state.loadedData !== null
            ? <div style={{flex:1, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
             <div style={{ height:60, width:60, backgroundColor:"rgba(38,134,176,0.6)",backgroundPosition:"center", backgroundSize:"60%", backgroundRepeat:"no-repeat" ,backgroundImage:`url(${chat_icon})`, borderRadius:7 }}
              onClick={()=> this.props.history.push({
              pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
              search: '',
              state: { type: "user" , id:  this.state.loadedData.relation.sender.id}
            }) }/>
            <div style={{display:"flex", height:60, alignItems:"center"  }}><div style={{padding:5, fontSize:10}}>BESZÉLGETÉS A MEGBÍZOTTAL</div></div>
            </div>
            : null
           }

            <div className="agreementadstittle">Elkészített Szerződés</div>
            <div className="agreementads-hdekor"></div>
            <div className="demotivator">
                <div className="agreementadstittle tittlemargin">Szerződés!</div>
                <div className="agreement-content">

                {parse(`${this.state.clean_HTML ?? ""}`)} 
                
                </div> 
                  

                <div className="agreementads-endbuttons d-flex w-100 justify-content-center">
                    
                {this.state?.loadedData?.attributes?.status=="sent" ?
                
                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                   
                        <button className="declinebtn" onClick={()=>{this.response("deny")}}>Elutasítás</button>
                        <button className="acceptbtn"  onClick={()=>{this.response("accept")}}>Szerződés elfogadása</button>

                    </div>
                    :

                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                   
                    <div className="agreementadstittle">A szerződést elfogadta</div>

                    </div>                    

                }
                </div>            
            </div>


          </div>
        </div>
      </div>   
      
      

      </>
    );


  }
}


const styles = {
  description:{maxHeight:145, overflow:"hidden"}
}




/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default  withRouter(connect(mapStateToProps,{})(Agreement));




