import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";
import Sidebar from "../SideBar/SideBar";
import Profiles from  "../../Users/Profiles/ProfilesModule";


 class UserProfiles extends Component  {

 

  render(){
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
      <Container style={{backgroundColor:"white", paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        <div style={{display:"flex"}}>
          <div style={{flex:2}}><Sidebar/></div>
          <div style={{flex:12, padding:40, paddingTop:50, paddingBottom:50,}}>
            <Profiles/>
          </div>
        </div>
      </Container>
    );
  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(UserProfiles);


 