import React, {Component} from 'react';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  useParams,
  
  Redirect,
  NavLink
} from "react-router-dom";


import './Users.css'

/* ROUTS */
import Profiles from      './Profiles/Profiles';
import Offers from      './Offers/Offers';
import Messages from      './Messages/Messages';
import Ads from      './Ads/Ads';
import Favorites from      './Favorites/Favorites';
import Ratings from      './Ratings/Ratings';





 class Users extends Component  {

  state = {
    currentPage : 0,
  }

  menus = [
    {tittle:'Profilom',img:require("../../Assets/user_icons/profil.svg")},
    {tittle:'Ajánlatok',img:require("../../Assets/user_icons/offers.svg")},
    {tittle:'Üzenetek',img:require("../../Assets/user_icons/messages.svg")},
    {tittle:'Hirdetéseim',img:require("../../Assets/user_icons/ad.svg")},
    {tittle:'Kedvencek',img:require("../../Assets/user_icons/favorites.svg")},
    {tittle:'Értékelések',img:require("../../Assets/user_icons/ratings.svg")},

  ];

  componentDidMount() {
    let { pathname} = this.props.location; 
    this.setPageFromUrl(pathname);

  }

  setPageFromUrl(pathname)
  {
    
    let pageIndex = 0;
      switch (pathname) {
        case "/users/profiles": pageIndex = 0; break;
        case "/users/offers": pageIndex = 1;  break; 
        case "/users/messages": pageIndex = 2;  break; 
        case "/users/ads": pageIndex = 3; break;
        case "/users/favorites":pageIndex = 4; break;
        case "/users/ratings":pageIndex = 5; break;
        default : pageIndex = 0      
      }
      
      console.log("Életciklus pathname:",pathname);
      console.log("Életciklus pageindex:",pageIndex);

      this.changeCurrentPage(pageIndex);
  }

  changeCurrentPage=(page) =>{
    console.log("Facim",page);
     
    this.setState({
      currentPage: page
    }
    ); 

    console.log(this.state);
  }
  
  getTittle = (param)=>
    {
      console.log(param);
      switch (param) {
        case "/users/profiles":return this.menus[0].tittle
        case "/users/offers":return this.menus[1].tittle
        case "/users/messages":return this.menus[2].tittle
        case "/users/ads":return this.menus[3].tittle
        case "/users/favorites":return this.menus[4].tittle
        case "/users/ratings":return this.menus[5].tittle
        default : return this.menus[0].tittle     
       
      }
    }
    
  render(){
      let {path} = this.props.match;

      let { pathname} = this.props.location; 
      console.log(pathname);
      console.log(this.props);
      
    return (
       <div className="usersContainer">
         
          <div className="content">
            <div className="tittle d-flex w-100 justify-content-center justify-content-md-start">
              <div>{this.getTittle(pathname)}</div>
            </div>
            <div className="navMenu row w-100">
              
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/profiles'><MenuElement onclick={this.changeCurrentPage} page={0} tittle="Profilom" img={require("../../Assets/user_icons/profil.svg")} isActive={this.state.currentPage===0 ? '1':'0'}/></NavLink></div>
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/offers'><MenuElement onclick={this.changeCurrentPage} page={1} tittle="Ajánlatok" img={require("../../Assets/user_icons/offers.svg")} isActive={this.state.currentPage===1 ? '1':'0'}/></NavLink></div>
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/messages'><MenuElement onclick={this.changeCurrentPage} page={2} tittle="Üzenetek" img={require("../../Assets/user_icons/messages.svg")} isActive={this.state.currentPage===2 ? '1':'0'}/></NavLink></div>
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/ads'><MenuElement onclick={this.changeCurrentPage} page={3} tittle="Hirdetéseim" img={require("../../Assets/user_icons/ad.svg")} isActive={this.state.currentPage===3 ? '1':'0'}/></NavLink></div>
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/favorites'><MenuElement onclick={this.changeCurrentPage} page={4} tittle="Kedvencek" img={require("../../Assets/user_icons/favorites.svg")} isActive={this.state.currentPage===4 ? '1':'0'}/></NavLink></div>
              <div className="col-4 col-lg-2 pl-0 pr-0"><NavLink className="text-center" to='/users/ratings'><MenuElement onclick={this.changeCurrentPage} page={5} tittle="Értékelések" img={require("../../Assets/user_icons/ratings.svg")} isActive={this.state.currentPage===5 ? '1':'0'}/></NavLink></div>
             
            </div>
          
            <div className="innerContent">
              <Switch >
                <Redirect exact path="/users" to={`${path}/profiles`}/>
                <Route path={`${path}/:subpages`} component={UsersSubManager}/>
              </Switch>
            </div>
          </div>
      </div>
    );

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default Users;


 const UsersSubManager =() => {

    const { subpages } = useParams();
    


    switch (subpages) {
      case "profiles":return <Profiles/>
      case "offers":return <Offers/>
      case "messages":return <Messages/>
      case "ads":return <Ads/>
      case "favorites":return <Favorites/>
      case "ratings":return <Ratings/>

      default : return <Profiles/>      
     
    }

}



class MenuElement extends Component  {

  
  render(){
    //console.log(this.props)
    return(
      <div className="menu" onClick={()=>this.props.onclick(this.props.page)}>
        <button className={this.props.isActive ==='0' ? 'menubtn':'activatedmenubtn'}>
            <img  src={this.props.img}/>
            <div>{this.props.tittle}</div>
        </button>
        
      </div>

    );
  }
}
