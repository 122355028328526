import React, {Component,} from 'react';
import {connect} from 'react-redux';
import {request} from '../../../Model/Request';

import {Modal, Button} from "react-bootstrap"

import default_image from "../../../Assets/mester_default.png"
import {endOfMonth, addMounthToDate} from "../../../Model/calendar_functions/calendar_finctions"

import DatePicker from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import "react-datepicker/dist/react-datepicker.css";
import "../../../Model/date_picker/css/react_datepicker_custome.css";
/* CALENDAR */
import locale from 'date-fns/locale/hu';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import "../../../Model/Calendar/CalendarChange.css";
import addBTN from "./Assets/add.png";
import pipe from "./Assets/pipe.png"




const equals =  (array) => {
  // if the other array is a falsy value, return
  if (!array)
      return false;

  // compare lengths - can save a lot of time 
  if (this.length != array.length)
      return false;

  for (var i = 0, l=this.length; i < l; i++) {
      // Check if we have nested arrays
      if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i]))
              return false;       
      }           
      else if (this[i] != array[i]) { 
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;   
      }           
  }       
  return true;
}

const honapok = [
  "január",
  "február",
  "március",
  "április",
  "május",
  "június",
  "július",
  "augusztus",
  "szeptember",
  "október",
  "november",
  "december",]

 class Calendar extends Component  {

  state= {

    addButton_1_data:{start:null, end:null},
    addButtonType:null,

    from_date:new Date(new Date().setDate(new Date().getDate()+1)),
    until_date:addMounthToDate(1, endOfMonth(new Date())),

         data:[],
         isSelected:false,
         months:2,

         days : [],
         focused:[0,0],
         trash:[],
         originalDates:[],
         modified:[],
  
    
  }

  componentDidMount(){
     this.props.systemState.dimension.width <= 991 ? this.setState({months:1}) : this.setState({months:2}) 
     this.getTools()  
  }

  componentDidUpdate(prevProps, prevState){
    if( this.props.systemState.dimension.width !== prevProps.systemState.dimension.width ){
      this.props.systemState.dimension.width <= 991 ? this.setState({months:1}) : this.setState({months:2})
    }



   if(JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) && prevState.data.length !== 0){
    
    this.getToolCalendar();

    if(prevState.isSelected === false && this.state.isSelected === false){
      this.setState({isSelected:true})
    }

    }

  }

  getToolCalendar = async () => {

    const selected_id = this.state.data.filter(i =>  i.selected === true)[0].toold_id;
    const url = `/public/user/profile/calendar/tool?user_id=${this.props.userState.user.id}&tool_id=${selected_id}`;
    const response = await request({url})

    console.log(response)

    if(typeof response !== "undefined" && typeof response.error === "undefined" ){

      const safe_data = response?.data ?? [];

      if(safe_data.length !== 0){

       
        const days = safe_data.filter(item  =>  item.attributes.event_type === "Tool" && item.attributes.event_type  !== null ).map( (item, index)=> {
          return { 
              type: "old",
              id:item.id,
              event_id:item.attributes.event_id,
              startDate:new Date(item.attributes.from_date),
              endDate:new Date(item.attributes.until_date),
              key: item.attributes.status === "busy" ? "own__"+ item.id + "_" + item.attributes.event_id : "help_" + item.id + "_" + item.attributes.event_id,
          }
       });
  
        /// days.filter(x => !this.state.days.includes(x)); 
        const oldDataArray = this.state.days.map(item=>item.id).filter(u => typeof u !== "undefined");
        const originalDatesOldArray = this.state.originalDates.map(item=>item.id).filter(u => typeof u !== "undefined")
     
  
        this.setState({
          days:[...this.state.days, ...days.filter(x => !oldDataArray.includes(x.id)) ],
          originalDates:[...this.state.originalDates,  ...days.filter(x => !originalDatesOldArray.includes(x.id))]
        }) 

      }else{

        this.setState({
          days:[],
          originalDates:[],
          modified:[]
        })

      }

      

     

    }

  }

  saveDate = async () => {

    
  

    for (let index = 0; index < this.state.trash.length; index++) {
      const item = this.state.trash[index];



      const data = {
        from_date : item.from_date,
        until_date: item.until_date,
        status    : "free",
        tool_id   : this.state.data.filter( i => i.selected === true)[0].toold_id
      }

      const response = await request({ url:"/user/profile/calendar/tool", data, method:"PATCH" });

      console.log(response)

    }

     for (let index = 0; index < this.state.days.length; index++) {
     
      const item = this.state.days[index];
      console.log()
      if(item.type === "new"){

        const data = {
          from_date : this.dateParser_hyphen(item.startDate),
          until_date: this.dateParser_hyphen(item.endDate),
          status    : "borrowable",
          tool_id   : this.state.data.filter( i => i.selected === true)[0].toold_id

        }

        const response = await request({ url:"/user/profile/calendar/tool", data, method:"POST" });
        console.log(response)

      }
      
    } 

    for (let index = 0; index < this.state.modified.length; index++) {
      const item = this.state.modified[index].split("_");

      const id = parseInt(item[0]);
      const event_id = parseInt(item[0]);
    

      /// find original date
      const delete_date = this.state.originalDates.filter( item => item.id === id );
      
      if(delete_date.length > 0){

              /// delete

              const data = {
                from_date  : this.dateParser_hyphen(delete_date[0].startDate),
                until_date : this.dateParser_hyphen(delete_date[0].endDate),
                status    : "free",
                tool_id   : this.state.data.filter( i => i.selected === true)[0].toold_id
              }
        
              
              const delete_response = await request({ url:"/user/profile/calendar/tool", data, method:"PATCH" });

                if(typeof delete_response.error === "undefined"){

                    const add_date = this.state.days.filter( item => item.id === id );

                    if(add_date.length > 0){


                      const data_add = {
                        from_date : this.dateParser_hyphen(add_date[0].startDate),
                        until_date: this.dateParser_hyphen(add_date[0].endDate),
                        status    : "borrowable",
                        tool_id   : this.state.data.filter( i => i.selected === true)[0].toold_id
                      }
              
                      const response_add = await request({ url:"/user/profile/calendar/tool", data:data_add, method:"POST" });
                      console.log(response_add)
                      

                    }


                }


      }
      

      /// add as new date 
      

    }



  }

  getTools = async () => {

    try{

      const id = this.props?.userState?.user?.company[0]?.id ?? null

      if(id === null) return

      const response = await request({ url:`/user/profile/tools?tool_id=${id}&tool_type=company` })

      if(typeof response.error === "undefined"){

          const data = response?.data?.tools ?? [];
        
          if(data.length > 0){
            this.setState({ data : data.map(item => { 
                  return {  
                    toold_id:item.id, 
                    name:item?.attribute?.name ?? "", 
                    src:item?.attribute?.basic_image?.src ?? default_image, 
                    selected:false,
                  } 
              })  
            })
          }


      }
      
 
    }catch(e){
      console.log(e)
    }
   

  };

  dateParser = (date) => `${date.getFullYear()} ${ honapok[date.getMonth()]} ${date.getDate()}`

  addButton =()=> {
    return(
      <div style={{padding:10, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}}>
        <div style={{fontSize:13, fontFamily:"MONDA"}}>{"ÚJ IDŐPONT"}</div>
        <div style={{fontSize:10, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
       <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
      </div>
    )
  }

  
  saveBTN = () => {
    return(
      <div 
        style={{display:"flex", justifyContent:"space-evenly", flexDirection: this.props.systemState.dimension.width <= 550 ?"column":"row", alignItems:"center", marginTop:50}}
        onClick={()=> this.saveDate()}
        >
          <div onClick={()=>console.log()} style={{cursor:"pointer",background:"#217BA7", height:65, width:300, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:5,  marginTop:40}}>
            <div style={{color:"white", fontFamily:"Maven Pro", fontSize:20, fontWeight:"bold"}}> MENTÉS </div>
          </div>
      </div>
    )
  }

  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`


  addDateToLocalData = () => {
    

    this.setState({
   
       days:  [...this.state.days, 
                {
                  type:"new", 
                  startDate: this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start, 
                  endDate: this.state.addButton_1_data.end  === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.end, 
                  key: this.state.addButton === 0 ? "help_" + (this.state.days.length + 1)  : "own__" + (this.state.days.length + 1) 
                } 
              ], 
                focused:[this.state.days.length,0],
                isModalShow:false, 
                addButton_1_data:{start:null, end:null}, 
                addButtonType:null,
      }) 


  }

  addDateComponent = () => {
    return(
      <div style={{ display:"flex", flexDirection:"row" }}>
        <div style={{flex:1, display:"flex", alignItems:"center", flexDirection:"column"}} >
          <div stle={{...styles.p}}>dátum kezdete</div>
        <DatePicker 
          locale={hu} 
          minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
          selected={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.start = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
        <div style={{flex:1, display:"flex",alignItems:"center", flexDirection:"column"}} >
        <div stle={{...styles.p}}>dátum vége</div>
        <DatePicker 
          locale={hu} 
          minDate={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start }
          selected={  
            
              this.state.addButton_1_data.end === null && this.state.addButton_1_data.start === null
                ? new Date(new Date().setDate(new Date().getDate()+1)) 
                : this.state.addButton_1_data.start !== null  &&  this.state.addButton_1_data.end !== null &&  parseInt(new Date(this.state.addButton_1_data.start ).getTime()/1000) >  parseInt(new Date(this.state.addButton_1_data.end).getTime()/1000) 
                   ?  this.state.addButton_1_data.start
                   :  this.state.addButton_1_data.end === null
                      ? this.state.addButton_1_data.start
                      : this.state.addButton_1_data.end 

                
            } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.end = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
      </div>
    )
  }



  toolsBox = ({image, name, index, selected}) => 
  <div 
    style={{ marginTop:30, marginBottom:30, marginLeft:30, marginRight:30, borderRadius:7, display:"flex", height:60, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", cursor:"pointer" }}
    onClick={ ()=> this.setState({ 
          data: this.state.data.map( (item, itemIndex) => {  
            if(name === item.name){return {...item, selected:true} }else{ return {...item, selected:false } } 
          }) 
        }) 
    } > 
    <div style={{flex:2, borderRadius:10, backgroundImage:`url(${image})`, backgroundSize:"cover", backgroundPosition:"100%, 100%"}} />
    <div style={{flex:4, display:"flex", alignItems:"center", paddingLeft:4, paddingright:4, fontSize:12, fontFamily:"MONDA", color:"#4F4F4F"}}>{name}</div>
    <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div style={{border:"1px solid black", height:20, width:20,  display:"flex", justifyContent:"center", alignItems:"center"}} >
        { selected 
        ? <img src={pipe} style={{height:10, width:14}} />
        : null
        }
      </div>
    </div>
  </div>
  
  setDateState = (a) => this.setState({ from_date: a, until_date: addMounthToDate( 1, endOfMonth(a) ) })   

  render(){

    const window_width =  this.props.systemState.dimension.width;

    return (
          <div>

            <Modal size="lg" centered show={this.state.isModalShow} onHide={() =>this.setState({isModalShow:false, addButton_1_data:{start:null, end:null}, addButtonType:null })} >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  ÚJ IDŐPONT HOZZÁADÁSA
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                { this.addDateComponent()}
              </Modal.Body>
              <Modal.Footer>
                <Button  variant="secondary" onClick={()=>this.setState({isModalShow:false, addButton_1_data:{start:null, end:null}, addButtonType:null})}>MÉGSEM</Button>
                <Button variant="primary"  onClick={()=>{ this.addDateToLocalData() }}>HOZZÁAD</Button>
              </Modal.Footer>
            </Modal>
          
          <div style={{fontSize:20, fontFamily:"MONDA", color:"#4F4F4F", fontWeight:"BOLD", letterSpacing:3, paddingLeft:30}}>SZERSZÁMAIM</div>
          <div style={{fontSize:12, fontFamily:"MONDA", color:"#4F4F4F", fontWeight:"BOLD", paddingLeft:30, marginTop:20, marginBottom:50}}>A KIVÁLASZTOTT SZERSZÁMNAK SZERKESZTHETEWD A BÉRBEADÁSI IDEJÉT</div>

          <div style={{display:"flex", flexDirection: window_width <= 991 ? "column" : "row"}}>

            <div style={{flex:1}}>
              {
                typeof this.state.data !==  "undefined" && this.state.data.length > 0 
                  ? this.state.data.filter( (item, index) => index % 2 === 0 ).map( (item, index) => <div key={"odd" + index} > {this.toolsBox({image:item.src, name:item.name, index, selected:item.selected})} </div>  )
                  : null 
              }
            </div>
            <div style={{flex:1}}>
            {
                typeof this.state.data !==  "undefined" && this.state.data.length > 0 
                  ? this.state.data.filter( (item, index) => index % 2 !== 0 ).map( (item, index) => <div key={"even" + index} > {this.toolsBox({image:item.src, name:item.name, index, selected:item.selected})} </div>  )
                  : null 
            }
            </div>

          </div>


          <div>
            {/* NÉZET */}
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:30, marginBottom:30, flexDirection: window_width <= 769 ? "column":"row"}}>
              <div style={{ borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  >NÉZET</div>
              <div onClick={()=> this.setState({months:1}) } style={{ cursor:"pointer", border: `${this.state.months===1?1:0}px solid #1E466D`, borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  > 1 HÓNAP </div>
              { window_width <= 991 ? null : <div onClick={()=> this.setState({months:2}) } style={{cursor:"pointer", border: `${this.state.months===2?1:0}px solid #1E466D`, borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  > 2 HÓNAP </div> }
            </div>
            
            <div style={{display:"flex"}}>

                <div style={{flex: this.state.months === 2 ? 0.5:2,  background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)"}}></div>

                <div style={{flex:2}}>
                <DateRange
                  showDateDisplay={false}
                  showPreview={true}
                  showSelectionPreview={true}
                  minDate={new Date( new Date().setDate(new Date().getDate() + 1))}
                 
                  focusedRange={this.state.focused}
                  shownDate = { this.state.from_date}
                  onShownDateChange={ (date)=> this.setDateState(date) }
                  onRangeFocusChange={(focused)=>this.setState({focused})}
                  disabledDates={[]}
                  ranges={[ ...this.state.days  ]}
                  rangeColors={this.state.days.length > 0 ? [  ...this.state.days.map( (item, index) => item.key.includes("own") ? "rgb(61, 145, 255)" : "rgb(61, 145, 255)" )] : ["rgb(61, 145, 255)"]}
                  locale={locale}
                  months={ this.state.months }
                  direction={"horizontal"}
                  onChange={ (e) => {

/* 
                    const uniqe = e[Object.keys(e)[0]].id + "_" + e[Object.keys(e)[0]].event_id;
                    if( !this.state.modified.includes(uniqe) && e[Object.keys(e)[0]].type === "old" ){
                      this.setState({ modified : [...this.state.modified, uniqe] })
                    }

                    this.setState({days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) }) 
 */
                    const uniqe = e[Object.keys(e)[0]].id + "_" + e[Object.keys(e)[0]].event_id;

                    if( !this.state.modified.includes(uniqe) && e[Object.keys(e)[0]].type === "old" ){

                      this.setState({ 
                        modified : [...this.state.modified, uniqe],
                        days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) 
                      })

                    } else {
                      this.setState({ 
                        days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) 
                      })
                      

                    }


                  }
              }
            />
              </div>

                <div style={{flex: this.state.months === 2 ? 0.5:2,  background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)"}}></div>

           </div>
           <div style={{background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)", transform:"rotate(180deg)"}} >
             <div style={{transform:"rotate(180deg)", height:100, marginRight:50, marginLeft:50}}>

               <div style={{marginBottom:20, width:"100%", height:1, backgroundColor:"white",}} />

                <div style={{display:"flex", justifyContent:"space-between"}}>

                    <div style={{flex:1}}></div>
                    <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(61, 145, 255)"}} />
                      <div style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10}} >BÉRELHETŐ IDŐPONT</div>
                    </div>
                    {/* <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(79, 79, 79)"}} />
                      <div  style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10, }} >ELFOGLALT</div>
                    </div> */}
                    <div style={{flex:1}}></div>

                </div>

               
              </div>

           </div>

            <div style={{display:"flex", justifyContent:"space-evenly", marginTop:30, marginBottom:30}}>

            {/* 
            //days:  [...this.state.days, {type:"new", startDate: new Date(new Date().setDate(new Date().getDate()+1)), endDate: new Date(new Date().setDate(new Date().getDate()+1)), key: "help_" + (this.state.days.length + 1) } ], focused:[this.state.days.length,0]
            */}
            {
              this.state.isSelected 
                ?
                <div onClick={ () => { this.setState({isModalShow:true, addButtonType:0 }) }} > 
                  {this.addButton()}
                </div>
                : null
            }
            

          {/* <div onClick={ () => { this.setState({days:  [...this.state.days, { startDate: new Date(), endDate: new Date(), key: "tools__" + (this.state.days.length + 1) } ], focused:[this.state.days.length,0]}) }} >
            {this.addButton("SAJÁT MUNKA")}
          </div> */}

        </div>

            {this.state.days.map( (item, index) => 
                <div key={index} style={{marginLeft:20, marginRight:20}}>
                <div style={{width:'100%', height:1, backgroundColor:"#4F4F4F", marginBottom:10, marginTop:10}} />

                  <div  style={{ cursor:"pointer", display:"flex", justifyContent: "center", flexDirection:window_width <= 769 ? "column":"row",  margin:10,  borderRadius: 5, padding:      this.state.focused[0] === index ? 10 : 2, opacity:      this.state.focused[0] === index ? 1 : 1,border:       `${this.state.focused[0] === index ? "3" : "0"}px ${this.state.focused[0] === index ? "#007bff" : "#4F4F4F"} solid`,background:  this.state.focused[0] === index ? "rgba(38, 134, 176, 0.2)" : "white" }}>

                      <div 
                          onClick={()=> this.setState({focused:[index,0]}) }
                          style={{flex:1, 
                            display:"flex",
                            alignItems:"center",
                            justifyContent: "center",
                            textAlign:"center",
                            borderRadius:5,
                            color:"white", 
                            padding:5,
                            background:"rgb(61, 145, 255)", 
                            }}>{"SZERSZÁM"}</div>

                      <div  onClick={()=> this.setState({focused:[index,0]}) } style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.startDate) */}
                      <DatePicker 
                        locale={hu} 
                        minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
                        selected={ item.startDate } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 
/* 
                          const uniqe = item.id + "_" + item.event_id;
                            if( !this.state.modified.includes(uniqe) && item.type === "old" ){
                              this.setState({ modified : [...this.state.modified, uniqe] })
                            }
                            let modified_item = {...item};
                                modified_item.startDate = date;
                                modified_item.endDate =  parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                                ?  item.startDate
                                :  item.endDate 

                              this.setState({ days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) }) 
 */
                        const uniqe = item.id + "_" + item.event_id;
                        let modified_item = {...item};
                              modified_item.startDate = date;
                              modified_item.endDate =  parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                              ?  item.startDate
                              :  item.endDate

                          if( !this.state.modified.includes(uniqe) && item.type === "old" ){

                            this.setState({  
                              modified : [...this.state.modified, uniqe],
                              days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) 
                            })

                          } else {

                            /*  let days = [...this.state.days]
                                  days[index] = modified_item */
                            this.setState({ days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} )  })

                            }


                        } } />
                      </div>
                      <div  onClick={()=> this.setState({focused:[index,0]}) } style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.endDate) */}
                      <DatePicker 
                        locale={hu} 
                        minDate={ item.startDate  }
                        selected={ 
                         
                            parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                              ?  item.startDate
                              :  item.endDate 
                        } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 

                          /* const uniqe = item.id + "_" + item.event_id;
                            if( !this.state.modified.includes(uniqe) && item.type === "old" ){
                              this.setState({ modified : [...this.state.modified, uniqe] })
                            }
                            let modified_item = {...item};
                                modified_item.endDate = date;

                            this.setState({days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) }) 
 */

                            const uniqe = item.id + "_" + item.event_id;

                            let modified_item = {...item};
                                modified_item.endDate = date;

                                if( !this.state.modified.includes(uniqe) && item.type === "old" ){

                                    this.setState({  
                                        modified : [...this.state.modified, uniqe],  
                                        days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) 
                                    }) 

                              } else {

                                /*  let days = [...this.state.days]
                                    days[index] = modified_item */
                                    this.setState({ 
                                      days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} )  
                                    })

                              }



                        } } />
                      </div>
            
                      <div 
                       // onClick={()=> this.setState({focused:[ index === 0 ? 0 : index-1 ,0], days:this.state.days.filter((f_item, f_index)=> f_index !== index)})}
                       onClick={ async ()=> {
                               

                        const del_object = this.state.days.filter((f_item, f_index)=> f_index === index)[0];

                        if(typeof del_object.startDate === "undefined" || typeof del_object.endDate ==="undefined" ) return

                        if(del_object.type === "old"){
                          const data = {
                            from_date : this.dateParser_hyphen(del_object.startDate),
                            until_date: this.dateParser_hyphen(del_object.endDate),
                            status    : "free"
                          }

                          this.setState({ trash : [...this.state.trash, data] })
                        }

                       

                        this.setState({
                                focused: [ index === 0 ? 0 : index-1 ,0], 
                                days:    this.state.days.filter((f_item, f_index)=> f_index !== index)
                         })

                     }
                }
                        style={{flex:1, 
                    
                          alignItems:"center",
                          justifyContent:"center",
                          display:"flex", 
                          background:"#E30606", 
                          color:"white",
                          borderRadius:5}}>TÖRLÉS</div>

                  </div> 
              </div>
            )}


            {this.saveBTN()}
          </div>


          </div>
    );

  }
  
}
const styles = {
    p : { 
      fontFamily:"Maven Pro", 
      letterSpacing:2
  }
}



/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Calendar);

