import React, {Component} from 'react';
import {connect} from 'react-redux';
import {request} from '../../../Model/Request';
import {Container, Modal, Button} from "react-bootstrap";
import Sidebar from "../SideBar/SideBar";
import ReactQuill from 'react-quill';
import ImageUpload from "../../../View/ImageUpload";
import telepulesek from '../../../Model/telepules';
import Map from "../../../View/Map";
import defaultImage from "../../../Assets/mesterlaptop.png"
import cover from "./Assets/empty.png";
import addBTN from "./Assets/add.png";

import DatePicker from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import "react-datepicker/dist/react-datepicker.css";
import "../../../Model/date_picker/css/react_datepicker_custome.css";

/* CALENDAR */
import locale from 'date-fns/locale/hu';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../../../Model/Calendar/CalendarChange.css"

const honapok = [
  "január",
  "február",
  "március",
  "április",
  "május",
  "június",
  "július",
  "augusztus",
  "szeptember",
  "október",
  "november",
  "december",]


 class ToolsRent extends Component  {


  onSelect = dates => this.setState({dates})

  state = {

    addButton_1_data:{start:null, end:null},
    addButtonType:null,

    tool_data:[],

    type:0,

    /// TOOLS
    toolsID:null,
    name:"",
    descriptionText:"",
    price:"",
    images:[
      {img:null, name:0},
      {img:null, name:1},
      {img:null, name:2},
    ],
    dates:[],

    imageIndexCounter:2,

    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }],

    months:2,
    days : [],
    focused:[0,0],

    isModalShow:false,
    errorMessage:'',
    successMessage:'',

   

  }

  componentDidMount(){

    this.getTools()
    
  }


  componentDidUpdate(prevProps, prevState){



    if(this.state.price !== prevState.price && isNaN(this.state.price) ){
      this.setState({price:prevState.price})
    }

    if(this.state.type !==prevState.type && this.state.type === 0 ){
      this.getTools()
    }

    if(this.state.type !==prevState.type && this.state.type === 1 && this.state.toolsID !== null){

      const data = this.state.tool_data.filter( item => item.id === this.state.toolsID  )[0]

      if(typeof data === "object"){

  
        
         this.setState({
          descriptionText:data?.attribute?.description ?? "",
          name: data?.attribute?.name ?? "",
          price: data?.attribute?.unit_price ?? "0",
          images:[{img: data?.attribute?.basic_image?.src ?? null, name:0, id: data?.attribute?.basic_image?.id ?? null }, ...data.attribute.images.map( (item, index) =>{ return {img:item.src, name:index + 1, id:item.id } }) ],
          data:[{
            id: 0,
            name: data?.attribute?.location?.city ?? "",
            zip: data?.attribute?.location?.zip_code ?? "",
            latitude: data?.attribute?.location?.lat ?? "0",
            longitude: data?.attribute?.location?.lng ?? "0",
            circle : {
              radius: 0,
              options: {
                strokeColor: "#217BA7",
                fillColor:"#217BA7"
              }
            }
          }]
    

        }) 

      }
      

    }


  };

  imageToState = ({base64data, index}) => {

    this.setState(state=>{

        return {  images:state.images.map(item=> {
                        if(item.name === index) return {...item, img:base64data}
                        else return {...item}
                  })}
    });
  };

  addItemToImages = () => {
    this.setState(state=>{

      const newImages = [...state.images,{img:null, name: this.state.imageIndexCounter + 1}]
        return {images:newImages, imageIndexCounter:this.state.imageIndexCounter + 1}
    });
  };

  /// validation Timeout for search
  validationTimeout;

  setGeoInput = (e) => {

    const {id} = e.target;

    let {value} = e.target;

    clearTimeout(this.validationTimeout);



    this.setState(state=>{
      const newData = state.data.map((item, index) => { if(index == id){  return {...item, name:value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() } } else {return {...item}} });
      return {data:newData}
    })



      this.validationTimeout = setTimeout(()=>{

        const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())
        if( settlement.length > 0 ){

          this.setState(state=>{
              const newData2 = state.data.map((item, index)=> { if(index == id){  return { ...item, longitude:settlement[0].Long, latitude:settlement[0].Lat, zip:settlement[0]["Ir. szám"] }  }else{ return { ...item } } } );
              return {data:newData2}
            })
        }
        else{
            this.setState({
              errorMessage:`A következő település nem található: ${value}`,
              isModalShow:true
            })


            this.setState(state=>{
              const newData2 = state.data.map((item, index)=> { if(index == id){ return {  ...item, name : "" } }else{ return { ...item } } } );
              return {data:newData2}
            })
        }
    },2000)


  };

  setInput = (e) => {
    const {id} = e.target;
    let {value} = e.target;

    this.setState({ [id]:value })
  };

  getTools = async () => {

    try{

      const id = this.props?.userState?.user?.company[0]?.id ?? null

      if(id === null) return

      const response = await request({ url:`/user/profile/tools?tool_id=${id}&tool_type=company` })

      if(typeof response.error === "undefined"){

          const data = response?.data?.tools ?? [];
        
          if(data.length > 0){
            this.setState({ tool_data : data  })
          }


      }
      
 
    }catch(e){
      console.log(e)
    }
   

  };

  removeItemsFromImages = (name) => {

    this.setState({images:this.state.images.filter(item => item.name !== name)})
  };

  buttonStyleHandler = (type) => {

    let background = "white";
    let color = "black";


    if(type ===  this.state.type){
      background = "#0C76B3";
      color = "white"
    }

      

    return { background, color}

  };

  resetState = () => {
    return {     
      descriptionText:"",
      name:"",
      price:"",
      images:[
        {img:null, name:0},
        {img:null, name:1},
        {img:null, name:2},
      ],
      dates:[],
      imageIndexCounter:2, 
      data:[{
        id: 0,
        name: "",
        zip:"",
        latitude: "0",
        longitude: "0",
        circle : {
          radius: 0,
          options: {
            strokeColor: "#217BA7",
            fillColor:"#217BA7"
          }
        }
      }],
  
      months:2,
      days : [],
      focused:[0,0],

    }
  };

  buttonFunctionHandler = (type) => {
    window.scrollTo({ top: 0 });
    this.setState({type, toolsID:null, ...this.resetState()})
  };

  editButtonfunction = (id) => {
    this.setState({type:1, toolsID:id, ...this.resetState()})
  };

  smallBoxComponent = (item) => {

    return(
      <div style={{ ...styles.boxcomponentContainer }} key={item.id}>

          {/* image */}
          <div style={{ backgroundImage:`url(${item?.attribute?.basic_image?.src ?? defaultImage})`, ...styles.boxcomponentImage }}/>
          {/* container */}
          <div style={{padding:10}}>
            {/* title */}
           <div style={{ ...styles.fontStyle, fontWeight:"bold", ...styles.boxcomponentTitle, textAlign:"center", height:50 }}>{item?.attribute?.name ?? ""}</div>
            {/* text */}
          <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:50 }}>{this.removeHTMLTags(item?.attribute?.description ?? "")}</div>
            {/* button */}
            <div style={{ ...styles.fontStyle, ...styles.boxcomponentButton, cursor:"pointer" }} onClick={()=> this.editButtonfunction(item.id) } >SZERKESZTÉS</div>
          </div>

      </div>
    )

  };

  titleComponet = ({title}) => {
    return(
      <>
      <div>{title}</div>
      <div style={{width:'100%', height:1, backgroundColor:"black"}} />
      </>
    )
  };

  removeHTMLTags = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return data.replace(regex, "");
  }

  toolsComponent = (window_width) => {


    return(

      <div>

      <Modal show={this.state.isModalShow} onHide={ ()=> this.setState({isModalShow:false, errorMessage:"", successMessage:""}) }>
        <Modal.Header>
          <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{...styles.p}}>{this.state.errorMessage}</div>
          <div style={{...styles.p}}>{this.state.successMessage}</div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant={this.state.errorMessage !== "" ? "secondary" : "primary"} onClick={ () => 
          {
            this.setState({isModalShow:false, errorMessage:"", successMessage:"", type: this.state.errorMessage !== "" ? 1 : 0 })
            if(this.state.errorMessage === ""){
              window.scrollTo(0, 0)
            }
          }}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered show={this.state.isModalShow_2} onHide={() =>this.setState({isModalShow_2:false, addButton_1_data:{start:null, end:null}, addButtonType:null })} >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  ÚJ IDŐPONT HOZZÁADÁSA
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                { this.addDateComponent()}
              </Modal.Body>
              <Modal.Footer>
                <Button  variant="secondary" onClick={()=>this.setState({isModalShow_2:false, addButton_1_data:{start:null, end:null}, addButtonType:null})}>MÉGSEM</Button>
                <Button variant="primary"  onClick={()=>{ this.addDateToLocalData() }}>HOZZÁAD</Button>
              </Modal.Footer>
            </Modal>


      {/* NAME OF THE TOOL */}
      {this.titleComponet({title:"SZERSZÁM NEVE"})}
      <input id={"name"}  style={{ ...styles.input, ...styles.p}} value={this.state.name} onChange={(e)=> this.setInput(e)}  />


      {/* TOOL DETAILD DESCRIPTION */}
      {this.titleComponet({title:"SZERSZÁM RÉSZLETES LEÍRÁSA"})}
      <ReactQuill
          placeholder= "Leírás helye..."
          value={this.state.descriptionText}
          onChange={(descriptionText)=>this.setState({descriptionText})}
          style={{height:300, marginTop:20, marginBottom:70}}  />


      {/* TOOL RENT PRICE */}
      {this.titleComponet({title:"BÉRBEADÁSI ÁR"})}

      <div style={{display:"flex"}}>
        <div style={{flex:10}}>
            <input id={"price"} style={{...styles.input, ...styles.p, borderRightColor:"transparent", borderRadius:0}} value={this.state.price} onChange={(e)=> this.setInput(e)} />
       </div>
       <div style={{flex:2, display:"flex",  height:40, marginTop:20, marginBottom:20, border: "1px solid #4F4F4F", borderLeftColor:"transparent", marginLeft:-1, justifyContent:'center', alignItems:"center"}}>
           <div style={{...styles.p}}> FT / NAP </div>
        </div>
      </div>



      {/* TOOL IMAGES */}
      {this.titleComponet({title:"KÉPEK A SZERSZÁMRÓL"})}

        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly", marginTop:20, marginBottom:20}}>

          {

            this.state.images.map( (item, index) =>

              <div key={index + "img"} style={{margin:10}}>
                
                  {
                    item.img === null
                      ? <ImageUpload index={item.name} imageToState={this.imageToState} cover={cover} />
                      : <div style={{...styles.imgDIV, backgroundImage:`url(${item.img})`}} />
                  }

                  <div onClick={()=> {this.removeItemsFromImages(item.name); this.deleteImage(item.id,item.img )}} style={{position:"relative", top:-220, left:150, backgroundColor:'red', width:70, padding:2, borderRadius:6, cursor: "pointer"}} >
                      <div style={{display:"flex", justifyContent:'center', color:'white', fontSize:12, fontFamily:"MONDA"}}>TÖRLÉS</div>
                  </div>

              </div>)

          }

          <div style={{padding:60, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}} onClick={()=>this.addItemToImages()}>
            <div style={{fontSize:12, fontFamily:"MONDA"}}>TOVÁBBI</div>
            <div style={{fontSize:12, fontFamily:"MONDA"}}>KÉPEK</div>
            <div style={{fontSize:12, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
            <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
          </div>

        </div>


    



     {/*
        <DateRange
          showDateDisplay={false}
          showPreview={true}
          showSelectionPreview={true}
          minDate={new Date()}
          disabledDates={[]}
          ranges={[this.state.newSelection]}
          rangeColors={[]}
          locale={locale}
          months={ this.props.systemState.dimension.width <= 768 ? 1 : this.state.months }
          direction={"horizontal"}
          // onChange={ (e) => this.setState({days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) }) }
          onChange={ (e) => { this.setState({[Object.keys(e)[0]]:e[Object.keys(e)[0]]}) } }
        /> 
      */}


      { this.state.toolsID === null 
      
      ?<div>
      {/* TOOL RENT DATES */}
      {this.titleComponet({title:"BÉRLÉSRE KÍNÁLT IDŐPONTOK"})}
      <DateRange
        showDateDisplay={false}
        showPreview={true}
        showSelectionPreview={true}
        minDate={new Date()}
        color={"red"}
        focusedRange={this.state.focused}
        onRangeFocusChange={(focused)=>this.setState({focused})}
        disabledDates={[]}
        ranges={[ ...this.state.days  ]}
        rangeColors={this.state.days.length > 0 ? [ ...this.state.days.map( item =>  item = "rgb(61, 145, 255)" ) ] : ["rgb(61, 145, 255)"]}
        locale={locale}
        months={ this.state.months }
        direction={"horizontal"}
        onChange={ (e) => this.setState({days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) }) } />

        <div style={{display:"flex", justifyContent:"space-evenly", marginTop:30, marginBottom:30}}>

          {/* <div onClick={ () => { this.setState({days:  [...this.state.days, { startDate: new Date(), endDate: new Date(), key: "tools_" + (this.state.days.length + 1) } ], focused:[this.state.days.length,0]}) }} > 
            {this.addButton()}
          </div> */}

          <div onClick={ () => { this.setState({isModalShow_2:true, addButtonType:0 }) }} > 
          {this.addButton()}
          </div>

        </div>

        {this.state.days.map( (item, index) => 
              <div key={index} style={{marginLeft:20, marginRight:20}}>
              <div style={{width:'100%', height:1, backgroundColor:"#4F4F4F", marginBottom:10, marginTop:10}} />

                <div  style={{ cursor:"pointer", display:"flex", justifyContent: "center", flexDirection:window_width <= 769 ? "column":"row",  margin:10,  borderRadius: 5, padding:      this.state.focused[0] === index ? 10 : 2, opacity:      this.state.focused[0] === index ? 1 : 1,border:       `${this.state.focused[0] === index ? "3" : "0"}px ${this.state.focused[0] === index ? "#007bff" : "#4F4F4F"} solid`,background:  this.state.focused[0] === index ? "rgba(38, 134, 176, 0.2)" : "white" }}>

                    <div 
                        onClick={()=> this.setState({focused:[index,0]}) }
                        style={{flex:1, 
                          display:"flex",
                          alignItems:"center",
                          justifyContent: "center",
                          textAlign:"center",
                          borderRadius:5,
                          color:"white", 
                          padding:5,
                          background: "rgb(61, 145, 255)", 
                          }}>{"SZERSZÁM"}</div>

                    <div  onClick={()=> this.setState({focused:[index,0]}) } style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.startDate) */}
                    <DatePicker 
                        locale={hu} 
                        minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
                        selected={ item.startDate } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 

                          const uniqe = item.id + "_" + item.event_id;
                          /// kommentelve. mert itt noncs módosítás, csak új felvitel
                           /*  if( !this.state.modified.includes(uniqe) && item.type === "old" ){
                              this.setState({ modified : [...this.state.modified, uniqe] })
                            } */
                            let modified_item = item;
                                modified_item.startDate = date;
                                modified_item.endDate =  parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                                ?  item.startDate
                                :  item.endDate 

                              this.setState({ days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) }) 

                        } } />
                    </div>
                    <div  onClick={()=> this.setState({focused:[index,0]}) } style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.endDate) */}
                    <DatePicker 
                        locale={hu} 
                        minDate={ item.startDate  }
                        selected={ 
                         
                            parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                              ?  item.startDate
                              :  item.endDate 
                        } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 

                          const uniqe = item.id + "_" + item.event_id;
                            if( !this.state.modified.includes(uniqe) && item.type === "old" ){
                              this.setState({ modified : [...this.state.modified, uniqe] })
                            }
                            let modified_item = item;
                                modified_item.endDate = date;

                            this.setState({days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) }) 

                        } } />
                    </div>
          
                    <div 
                      onClick={()=> this.setState({focused:[ index === 0 ? 0 : index-1 ,0], days:this.state.days.filter((f_item, f_index)=> f_index !== index)})}
                      style={{flex:1, 
                  
                        alignItems:"center",
                        justifyContent:"center",
                        display:"flex", 
                        background:"#E30606", 
                        color:"white",
                        borderRadius:5}}>TÖRLÉS</div>

                </div> 
            </div>
        )}
        </div>
        :null
        }

        <div style={{marginTop:30}} />

       {/* TOOL PICKUP POINTS */}
      {this.titleComponet({title:"ÁTVÉTELI LEHETŐSÉG"})}
      <input style={{...styles.input}} id={0} value={this.state.data[0].name}  onChange={(e)=> this.setGeoInput(e)} />
      <Map
          center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} /// lat: 47.497913, lng: 19.040236
          zoom={10}
          places={this.state.data}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
          loadingElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%`, filter:"grayscale(95)" }} />}
          containerElement={<div style={{ height:'300px', width:'100%' }} />} />


        {/* SAVE / DELETE / CANCEL */}
        <div style={{display:"flex", flexDirection: this.props.systemState.dimension.width > 950 ? "row":"column", justifyContent:"space-evenly", flexWrap:"wrap", marginBottom:50, marginTop:150}}>

        {/* select */}

        {
          this.state.toolsID !== null ?
          <>
            <div style={{...styles.p, ...styles.button, backgroundColor:"rgb(196, 196, 196)", width:this.props.systemState.dimension.width > 950 ? "20%" : "100%", margin:10, cursor:"pointer",  }}  onClick={()=>this.buttonFunctionHandler(0)} >MÉGSEM</div>
            <div style={{...styles.p, ...styles.button, backgroundColor:"rgb(196, 196, 196)",    width:this.props.systemState.dimension.width > 950 ? "20%" : "100%", margin:10, cursor:"pointer",  }}  onClick={()=>this.deleteTool()} >TÖRLÉS</div>
            <div style={{...styles.p, ...styles.button, backgroundColor:"rgb(61, 145, 255)",  width:this.props.systemState.dimension.width > 950 ? "20%" : "100%", margin:10, cursor:"pointer",  }}  onClick={()=>this.saveNewTool()} >MÓDOSÍTÁS</div>
          </>
          :
          <>
            <div style={{...styles.p, ...styles.button, backgroundColor:"rgb(196, 196, 196)", width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, cursor:"pointer",  }}  onClick={()=>this.buttonFunctionHandler(0)} >MÉGSEM</div>
            <div style={{...styles.p, ...styles.button, backgroundColor:"rgb(61, 145, 255)",  width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, cursor:"pointer",   }}  onClick={()=>this.saveNewTool() } >MENTÉS</div>
          </>
        }


        </div>

      </div>
    )


  }

  addDateToLocalData = () => {
    

    this.setState({
   
       days:  [...this.state.days, 
                {
                  type:"new", 
                  startDate: this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start, 
                  endDate: this.state.addButton_1_data.end  === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.end, 
                  key: this.state.addButton === 0 ? "help_" : "own__" + (this.state.days.length + 1) 
                } 
              ], 
                focused:[this.state.days.length,0],
                isModalShow_2:false, 
                addButton_1_data:{start:null, end:null}, 
                addButtonType:null,
      }) 


  }

  addDateComponent = () => {
    return(
      <div style={{ display:"flex", flexDirection:"row" }}>
        <div style={{flex:1, display:"flex", alignItems:"center", flexDirection:"column"}} >
          <div stle={{...styles.p}}>dátum kezdete</div>
        <DatePicker 
          locale={hu} 
          minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
          selected={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.start = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
        <div style={{flex:1, display:"flex",alignItems:"center", flexDirection:"column"}} >
        <div stle={{...styles.p}}>dátum vége</div>
        <DatePicker 
          locale={hu} 
          minDate={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start }
          selected={  
            
              this.state.addButton_1_data.end === null && this.state.addButton_1_data.start === null
                ? new Date(new Date().setDate(new Date().getDate()+1)) 
                : this.state.addButton_1_data.start !== null  &&  this.state.addButton_1_data.end !== null &&  parseInt(new Date(this.state.addButton_1_data.start ).getTime()/1000) >  parseInt(new Date(this.state.addButton_1_data.end).getTime()/1000) 
                   ?  this.state.addButton_1_data.start
                   :  this.state.addButton_1_data.end === null
                      ? this.state.addButton_1_data.start
                      : this.state.addButton_1_data.end 

                
            } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.end = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
      </div>
    )
  }


  saveNewTool = async () => {



    const id = this.props?.userState?.user?.company[0]?.id ?? null

    if(id === null){
      this.setState({errorMessage:"Rendszer szintű hiba! Kérlek próbáld meg később!", isModalShow:true})
      return
    }


    /* NAME */
    if(this.state.name === ""){
      this.setState({errorMessage:"A szerszám nevének kitöltése kötelező!", isModalShow:true})
      return
    }

    /* DESCRIPTION */
    if(this.state.descriptionText.replace(/<(.|\n)*?>/g, '').trim().length < 1 ){
      this.setState({errorMessage:"A szerszám részletes leírása kötelező!", isModalShow:true})
      return
    }

     /* PRICE */
    if(this.state.price === ""){
      this.setState({errorMessage:"A bérlemény árának megadása kötelező!", isModalShow:true})
      return
    }

    /* GEOLOCATION */
    if(this.state.latitude === 0){
      this.setState({errorMessage:"Az átvétel helyszinének megadása kötelező!", isModalShow:true})
      return
    }


    /// images// 
    const imagesArray = this.state.images.filter(item => item.img !== null);

    if(imagesArray.lenght === 0){
      /// nincs feltöltve kéep
      this.setState({errorMessage:"Legalább 1 kép feltöltése kötelező!", isModalShow:true})
      return
    }


    if(this.state.toolsID === null){


      if(this.state.dates.lenght === 0){

        /// nincs feltöltve dátum
        this.setState({errorMessage:"A bérbeadási dátum megadása kötelező", isModalShow:true})
        return

      }

      let error_type = 0;

      const saveData =   {
        tool_type : "company",
        tool_id : id,
        tool : {
          name : this.state.name,
          description :this.state.descriptionText,
          quantity : 1,
          unit_price : parseInt(this.state.price)
        },
        "location":{
          "lat" : parseInt(this.state.latitude),
          "lng" : parseInt(this.state.longitude),
          "distance" : null,
          "country_code": "hu",
          "zip_code": parseInt(this.state.data.zip),
          "country": null,
          "county": null,
          "city": this.state.data.name,
          "street": null,
          "house_number": null,
          "extra":{
              "floor" : null,
              "door" : null
          },
          "formatted_address": null
        },
          "img": {
            mime:imagesArray[0].img.split(";")[0].replace("data:image/",""),
            src: imagesArray[0].img
        }
      }

      const response = await request({ 
                url:`/user/profile/tools?tool_id=${this.props.userState.user.company[0].id}`,
                method:"POST", 
                data:saveData 
            });

          
      if(typeof response.error !== "undefined"){ error_type = 1 }


      if(typeof response.error === "undefined"){

        const tool_id = response?.data?.id ?? null
        
        if(tool_id !== null){

          const response_tool_calendar = await request({ 
              url:`/user/profile/calendar/tool`,
              method:"POST", 
              data:{
                "from_date":this.dateParser_hyphen(this.state.days[0].startDate),
                "until_date":this.dateParser_hyphen(this.state.days[0].endDate),
                "status":"borrowable",
                "tool_id":tool_id
               }
          });
        
          /// error handler
          if(typeof response_tool_calendar.error !== "undefined"){error_type = 2}

             const filtered_img = this.state.images.filter( i => i.img !== null)


              if(filtered_img.length > 1){

                  for (let index = 0; index < filtered_img.length; index++) {

                      const item = filtered_img[index];

                      if(index > 0 && item.img.includes("base64")){

                          const data = {

                            upload_to:{
                              type: "tool",
                              id: this.state.toolsID
                            },

                            img: {
                              type: "description",
                              mime:item.img.split(";")[0].replace("data:image/",""),
                              src: item.img
                            }

                          }
                        
                          const response = await request({ url:"/image", method:"POST", data });

                          if(typeof response.error !== "undefined"){ 
                            error_type = 3
                          }


                      }

                    
                  }

              }
         


            if(error_type !== 0){

              switch (error_type) {
                case 1:
                  this.setState({errorMessage:"Hiba lépett fel a mentés közben!", isModalShow:true})
                  return
                case 2:
                  this.setState({errorMessage:"Hiba lépett fel a mentés közben, nem sikerült menteni a naptár adatokat !", isModalShow:true})
                  return
                case 3:
                  this.setState({errorMessage:"Hiba lépett fel a mentés közben, nem sikerült menteni a képeket !", isModalShow:true})
                  return
              
                default:
                  this.setState({errorMessage:"Hiba lépett fel a mentés közben!", isModalShow:true})
                  return
              }

            }else{
              this.setState({successMessage:"SIKERES MENTÉS!", isModalShow:true})
            }
         

             
        }
        


       /// const response = await request({ url:`/user/profile/tools?tool_id=${this.props.userState.user.company[0].id}`, method:"POST", data:saveData })

       
      }else{
        this.setState({errorMessage:"Hiba lépett fel a mentés közben!", isModalShow:true})
      }

      

    }else{

    

      const saveData = {
        id  : this.state.toolsID,
        tool : {
            name : this.state.name,
            description :this.state.descriptionText,
            quantity : 1,
            unit_price : parseInt(this.state.price)
        },
        "location":{
          "lat" : parseFloat(this.state.data[0].latitude),
          "lng" : parseFloat(this.state.data[0].longitude),
          "distance" : null,
          "country_code": "hu",
          "zip_code": parseInt(this.state.data[0].zip),
          "country": null,
          "county": null,
          "city": this.state.data[0].name,
          "street": null,
          "house_number": null,
          "extra":{
              "floor" : null,
              "door" : null
          },
          "formatted_address": null
        }
      }
                
      const response = await request({ url:`/user/profile/tools`, method:"PATCH", data:saveData })
    
      if(typeof response.error === "undefined" ){

       

        const filtered_img = this.state.images.filter( i => i.img !== null && !i.img.includes("http"))



        const first_element_of_original =  this.state.images.filter( i => i.img )[0]

          for (let index = 0; index < filtered_img.length; index++) {

                      const item = filtered_img[index];


                      const data = {

                            upload_to:{
                              type: "tool",
                              id: this.state.toolsID
                            },

                            img: {
                              type: first_element_of_original?.name  === item?.name  ? "basic" : "description",
                              mime:item.img.split(";")[0].replace("data:image/",""),
                              src: item.img
                            }

                      }
                      
                   

                      const response = await request({ url:"/image", method:"POST", data });
                      console.log(response)
                     


                      

                    
                  }

            

        


        this.setState({successMessage:"SIKERES MÓDOSÍTÁS!", isModalShow:true})
      }


  }

  }

  deleteTool = async () => {

    const id = this.props?.userState?.user?.company[0]?.id ?? null;

    if(this.state.toolsID === null || id === null)  return


    const response = await request({ 
        url:`/user/profile/tools`, 
        method:"DELETE", 
        data:{
          "tool_type" : "company",
          "tool_id" : id,
          "id" : this.state.toolsID
        } 
    });

    console.log(response)
    
  }

  deleteImage = async (id, img) => {

      /// GUARD FUNCTION
      if(typeof id === "undefined" || id === null || typeof img === "undefined" || img === null || this.state.toolsID === null ||  typeof this.state.toolsID === "undefined")return

      if(img.includes("http") && !img.includes("data:image/")){

        const data = {
          "tool_id" : this.state.toolsID,
          "image_id":id
        };

        const response = await request({  url:"/user/profile/tools/image", method:"DELETE", data })

        if(typeof response.error === "undefined"){
          this.setState({
            isModalShow:true,
            successMessage:"SIKERES!",
            errorMessage: "A KÉP TÖRLÉSE"
          })
        }else{
          this.setState({
            isModalShow:true,
            errorMessage: "A KÉP TÖRLÉSE SIKERTELEN!"
          })
        }


  }
}
  /* DIARY HANDLERS  */

  addButton =()=> {
    return(
      <div style={{padding:10, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}}>
        <div style={{fontSize:13, fontFamily:"MONDA"}}>{"ÚJ IDŐPONT"}</div>
        <div style={{fontSize:10, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
       <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
      </div>
    )
  }

  dateParser = (date) => `${date.getFullYear()} ${ honapok[date.getMonth()]} ${date.getDate()}`;

  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`



  render(){
    const window_width =  this.props.systemState.dimension.width;
    console.log(this.state)
    return (
      <Container style={{backgroundColor:"white", paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
      <div style={{display:"flex"}}>
        <div style={{flex:2}}><Sidebar/></div>
        <div style={{flex:12, paddingTop:50, paddingBottom:50}}>

          <div style={{display:"flex", justifyContent:"space-evenly", marginBottom:50}}>

                {/* select */}

               {/*  <div style={{...styles.p, ...styles.button, ...this.buttonStyleHandler(0)  }}  onClick={()=>this.buttonFunctionHandler(0)} >c</div>
                <div style={{...styles.p, ...styles.button, ...this.buttonStyleHandler(1)  }}  onClick={()=>this.buttonFunctionHandler(1)} >ÚJ SZERSZÁM</div> */}

            <div style={{...styles.p, ...styles.button, width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, ...this.buttonStyleHandler(0), cursor:"pointer",  }}  onClick={()=>this.buttonFunctionHandler(0)} >SZERSZÁMAIM</div>
            <div style={{...styles.p, ...styles.button, width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, ...this.buttonStyleHandler(1), cursor:"pointer",  }}  onClick={()=>this.buttonFunctionHandler(1)} >ÚJ SZERSZÁM</div>

          </div>

          {
             this.state.type === 0
             ?
             <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:'center'}}>
               { this.state.tool_data.map( item => this.smallBoxComponent(item) ) }
             </div>
             :
             <div style={{margin:60}}>
             { this.toolsComponent(window_width) }
             </div>
          }





        </div>
      </div>
    </Container>
    );

  }

}


const styles = {

  boxcomponentContainer: {width:250, margin:10, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius:5 },
  boxcomponentImage: { height:250, width:250, backgroundPosition:"center center",  backgroundRepeat:"no-repeat", borderRadius:5},
  boxcomponentTitle: { marginTop:10, marginBottom:10},
  boxcomponentDescription: {marginTop:10, marginLeft:10, marginRight:10, marginBottom:20, overflow: "hidden", textOverflow: "ellipsis",},
  boxcomponentButton:{fontSize:12, textAlign:"center", margin:10, padding:10, background:"#0D74B0", color:"white", borderRadius:5},


  input:    {width:"100%", padding:10, height:40, marginTop:20, marginBottom:20, border: "1px solid #4F4F4F",  bordeRadius:5,},
  p:        {fontSize:12, fontFamily:"Maven Pro", letterSpacing:2},
  button:   {color:"white", padding:8, display:"flex", justifyContent:"center" ,borderRadius:4, border:'1px solid #4F4F4F', height:65, alignItems:"center", fontSize:14},
  imgDIV :  {backgroundSize:"cover",
             objectFitt:"cover",
             backgroundRepeat:"no-repeat",
             backgroundColor:"white",
             backgroundPosition:"center",
             width:210,
             height:210,
             borderRadius:6,
             boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"},

}

/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(ToolsRent);
