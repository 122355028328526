import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
import {setUserAction} from '../../../Model/Action';
import {withRouter} from 'react-router-dom';
import {request} from "../../../Model/Request";
import ImageUpload from "../../../View/ImageUpload";
import {Container} from "react-bootstrap";
import cover from "./Assets/empty.png";
import loader from '../../../Assets/mloader.gif';



const validationArray = [
  {name:"corporationName",            minLenght: 2, maxLenght: 200,  },
  {name:"corporationType",            minLenght: 2, maxLenght: 200,  },
  {name:"city",                       minLenght: 2, maxLenght: 100,  },
  {name:"street",                     minLenght: 2, maxLenght: 100,  },
  {name:"zip",                        minLenght: 4, maxLenght: 4,    },
  {name:"phonenumber",                minLenght: 6, maxLenght: 20,   },
  {name:"tax_number",                 minLenght: 9, maxLenght: 13,   },
  {name:"registrationNumber",         minLenght: 2, maxLenght: 50,   },
];

const downSideArray = [
  {htmlFor:"fgcegnev",            htmlForText:"CÉGNÉV",               id:"corporationName",     type:"text"},
  {htmlFor:"fgvallalkozastipusa", htmlForText:"VÁLLALKOZÁS TÍPUSA",   id:"corporationType",     type:"text"},
  {htmlFor:"fgvaros",             htmlForText:"VÁROS",                id:"city",                type:"text"},
  {htmlFor:"fgiranyitoszam",      htmlForText:"IRÁNYÍTÓSZÁM",         id:"zip",                 type:"text"},
  {htmlFor:"fgutca",              htmlForText:"UTCA, HÁZSZÁM",        id:"street",              type:"text"},
  {htmlFor:"fgtelefonszam",       htmlForText:"TELEFONSZÁM",          id:"phonenumber",         type:"text"},
  {htmlFor:"fgcegadoszam",        htmlForText:"CÉG ADÓSZÁMA",         id:"tax_number",          type:"text"},
  {htmlFor:"fgcegjegyzekszam",    htmlForText:"CÉGJEGYZÉKSZÁM",       id:"registrationNumber",  type:"text"},
  {htmlFor:"fgbizonyitvanyszam",  htmlForText:"BIZONYÍTVÁNY SZÁMA",   id:"diplomaNumber",       type:"text"},
];

const numbersAndDash_8_ = (input) => {
    const regex = /^\d{8}-$/gm;
    return regex.test(input);
}
const numbersAndDash_8_1 = (input) => {
    const regex = /^\d{8}-\d{1}$/gm;
    return regex.test(input);
}
const numbersAndDash_8_1_ = (input) => {
    const regex = /^\d{8}-\d{1}-$/gm;
    return regex.test(input);
}
const numbersAndDash_8_1_1= (input) => {
    const regex = /^\d{8}-\d{1}-\d{1}$/gm;
    return regex.test(input);
}
const numbersAndDash_8_1_2 = (input) => {
    const regex = /^\d{8}-\d{1}-\d{2}$/gm;
    return regex.test(input);
}

 class RegisterCorporationData extends Component  {

  state = {
    loading:false,
    image :null,

    corporationName:    "",
    corporationType:    "",
    city:               "",
    zip:                "",
    street:             "",
    phonenumber:        "",
    tax_number:         "",
    registrationNumber: "",
    diplomaNumber:      "",
    
    corporationName_valid:   false,
    corporationType_valid:   false,
    city_valid:              false,
    zip_valid:               false,
    street_valid:            false,
    phonenumber_valid:       false,
    tax_number_valid:        false,
    registrationNumber_valid:false,
    diplomaNumber_valid:     false,

  }

  _isMounted = false;

  componentDidMount(){
    this._isMounted = true;
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
    this.propsDataToState()
  } 


  componentDidUpdate(prevProps, prevState){

    /// loading disable all inputs 
    if(this.state.loading !== prevState.loading){

        if(this.state.loading){
            [...document.querySelectorAll("input")].map(i => i.disabled = true)
        }else{
            [...document.querySelectorAll("input")].map(i => i.disabled = false)
        }

    }
    

    if(
        (this.state.tax_number.length === 8 && prevState.tax_number.length === 7 ) || 
        (this.state.tax_number.length === 10 && prevState.tax_number.length === 9 ) 
    ){
      this.setState({ tax_number : this.state.tax_number + "-" }) 
    }  

   /*   if(
       (this.state.taxNumber.length === 9 && prevState.taxNumber.length === 10 ) || 
       (this.state.taxNumber.length === 11 && prevState.taxNumber.length === 13 )
      ) this.setState({taxNumber: this.state.taxNumber.substring(0, this.state.taxNumber.length - 2)})  */
   
 

    /// form validation
    validationArray.map( i =>{

        if(this.state[i.name] !== prevState[i.name] ){

            ///  i.name === "registrationNumber" || i.name === "diplomaNumber" ||
            /// if its zip or phonenumber the imputed value must be a number
            if( ( i.name === "zip" || i.name === "phonenumber" || (i.name === "tax_number")) && isNaN(this.state[i.name])){
                
      
                
                if( i.name === "tax_number" && 
                    numbersAndDash_8_(this.state.tax_number)    === false && 
                    numbersAndDash_8_1(this.state.tax_number)   === false && 
                    numbersAndDash_8_1_(this.state.tax_number)  === false && 
                    numbersAndDash_8_1_1(this.state.tax_number) === false &&
                    numbersAndDash_8_1_2(this.state.tax_number) === false ){

                    this.setState({[i.name]: prevState[i.name] })
                }else if(i.name !== "tax_number"){
                    this.setState({[i.name]: prevState[i.name] })
                }
                
                //this.setState({[i.name]: prevState[i.name] })
            }

           // (i.name === "tax_number")

            //console.log(numbersAndDash(this.state.tax_number))

           


            if(this.state[i.name].length === 0){

                document.querySelector(`input#${i.name}`).style.background = "rgba(227,6,6,0.15)";
                this.setState({[`${i.name}_valid`]: false})


            } else if(this.state[i.name].length < i.minLenght){

                document.querySelector(`input#${i.name}`).style.background = "rgba(253,217,166,0.26)";
                this.setState({[`${i.name}_valid`]: false})


            } else if(this.state[i.name].length >= i.minLenght && this.state[i.name].length <= i.maxLenght){

                    /// default
                    document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                    this.setState({[`${i.name}_valid`]: true})
              
                
            } else if(this.state[i.name].length >= i.maxLenght){

                this.setState({[i.name]: prevState[i.name] })
             
            }
            

          
        };

    })


  }


  
  componentWillUnmount() {
    this._isMounted = false;
  }


  imageToState = ({base64data}) => this.setState({image:base64data})
  
    


  propsDataToState = () => {

    /// GUARD
   if( typeof this.props.userState.user.incompleteRegistration === "undefined" ||  this.props.userState.user.incompleteRegistration.stepTwo.complete === false ) return
    

    const { details } =  this.props.userState.user.incompleteRegistration.stepTwo;

        this.setState(state =>{
        
            const newState = {...state};

                /// ID
                if(typeof details.company.id !== "undefined"){
                    newState.id = details.company.id;
                }
                
                
                /// details
                newState.corporationName = details.company.name;
                newState.corporationType = details.company.type;

                /// location
                newState.zip    = details.location.zip_code  ===    null ? "" : details.location.zip;
                newState.city   = details.location.city      ===    null ? "" : details.location.city;
                newState.street = details.location.street    ===    null ? "" : details.location.street;

            

                newState.phonenumber         = details.phoneNumber;
                newState.tax_number          = details.tax_number;
                newState.registrationNumber  = details.registrationNumber;
                newState.diplomaNumber       = details.certificateNumber ?? "";

                newState.image              =  typeof details.images[0] === "undefined" || details.images[0] === null ? null : details.images[0]

                newState.corporationName_valid    = true;
                newState.corporationType_valid    = true;
                newState.city_valid               = true;
                newState.zip_valid                = true;
                newState.street_valid             = true;
                newState.phonenumber_valid        = true;
                newState.taxNumber_valid          = true;
                newState.registrationNumber_valid = true;
                newState.diplomaNumber_valid      = true;

            

                return newState

        });

  }



  onChangeHandler = (e) => {
      const {id} = e.target;
      const {value} = e.target;

      if(id === "terms_of_use") {
          this.setState({[id]:!this.state.terms_of_use}) 
      }else{
          this.setState({[id]:value}) 
      }
      
  }

  downSideComponent = ({htmlFor, htmlForText, type, id, name, index}) => {
    return(
        <div key={index} style={{display:"flex", flex:1, flexDirection:'column', paddingLeft:15, paddingRight:15}} >
                <label  style={{...styles.p, fontWeight:"bold", letterSpacing:3}} htmlFor={htmlFor}>{htmlForText}</label>
                <input type={type} className="form-control" id={id} placeholder="" name={name} onChange={this.onChangeHandler} value={this.state[id]} style={{...styles.p}}/>
        </div> 
    )
  }

  removeImage = () => this.setState({image:null})


  saveButton = async () => {

      /* =============
       FINAL VALIDATION
       ============= */
       this.setState({loading:true});
       /// if this trigger to TRUE the function will return
       let stop = false;
       /// error message
       let description = ""
       /// STATE keys
       const keys =  Object.keys(this.state);

       keys.reverse().map( i => {
          if( i.includes("_valid") && this.state[i] === false && !i.includes("diplomaNumber")){

                stop = true

                const id = i.replace("_valid","");
                document.querySelector(`#${id}`).style.background = "rgba(227,6,6,0.15)"
                
                switch (i) {
                        case "corporationName_valid":
                            description = "A CÉGNÉV mező kitöltése nem megfelelő!"
                        break;
                        case "corporationType_valid":
                            description = "A VÁLLALKOZÁS TÍPUSA mező kitöltése nem megfelelő!"
                        break;
                        case "city_valid":
                            description = "A VÁROS mező kitöltése nem megfelelő!"
                        break;
                        case "zip_valid":
                            description = "A IRÁNYÍTÓSZÁM mező kitöltése nem megfelelő!"
                        break;
                        case "street_valid":
                            description = "A UTCA, HÁZSZÁM mező kitöltése nem megfelelő!"
                        break;
                        case "phonenumber_valid":
                            description = "A TELEFONSZÁM mező kitöltése nem megfelelő!"
                        break;
                        case "taxNumber_valid":
                            description = "A CÉG ADÓSZÁMA mező kitöltése nem megfelelő!"
                        break;
                        case "registrationNumber_valid":
                            description = "Az CÉGJEGYZÉKSZÁM mező kitöltése nem megfelelő!"
                        break;


                    /*     case "diplomaNumber_valid":
                            description = "A BIZONYÍTVÁNY SZÁMA mező kitöltése nem megfelelő!"
                        break; */
                        
                
                    default:
                        break;
                } 

          }
       })

       if(stop === true){
           document.querySelector("#errorText").innerHTML = description;
           
           this.setState({loading:false});
           return
       }

       document.querySelector("#errorText").innerHTML = "";

       /// REQUEST

       /// DATA what we send
       
       let sendData =  {
            "name":     this.state.corporationName,
            "type":     this.state.corporationType,
            "phone_number": 	this.state.phonenumber,
            "tax_number": 	this.state.tax_number,
            "registration_number": this.state.registrationNumber,
            "certificate_number": this.state.diplomaNumber,
            "location": [
                {
                    "distance" : null,
                    "country_code": "hu",
                    "zip_code": this.state.zip,
                    "country": null,
                    "county": null,
                    "city": this.state.city,
                    "street": this.state.street,
                    "house_number": null,
                    "extra":{
                        "floor" : null,
                        "door" : null
                    },
                    "formatted_address": null
                }
            ]
            
        }

        if(typeof this.state.id !== "undefined"){
            sendData.id = this.state.id
        }

       
        console.log(sendData)
        

        

        const response = await request({ method:"post", url:"/auth/register/step-two", data:sendData });
      
        console.log(response)

        this.setState({loading:false});

         /// if there is any error
        /// =============
        /// ERROR HANDLER
        /// =============
        if(typeof response.error !== "undefined"){
            
            if(typeof response.error.data !== "undefined"){

                if(typeof response.error.data.certificate_number !== "undefined"){
                    description = description + ` ${this.state.diplomaNumber} bizonyítvány számmal már regisztráltak! `
                }
                if(typeof response.error.data.name !== "undefined"){
                    description = description + ` ${this.state.corporationName} cégnévvel már regisztráltak! `
                }
                if(typeof response.error.data.registration_number !== "undefined"){
                    description = description + ` ${this.state.registrationNumber} cégjegyzékszámmal már regisztráltak! `
                }
                if(typeof response.error.data.tax_number !== "undefined"){
                    description = description + ` ${this.state.tax_number} adószámmal már regisztráltak! `
                }


            }else{
                description = "HIBA A SZERVERREL VALÓ KOMMUNIKÁCIÓ SORÁN"
            }

           
              
               
            

            document.querySelector("#errorText").innerHTML = description;
            return

        } 

        if(response.message === "Create was success" || response.message === "Update was success"){

            
            ///
             /*  const incompleteRegistration = {
                stepTwo : {
                    complete: true,
                    details: {
                        location: {zip: response.data.location.zip_code, city: response.data.location.city, street: response.data.location.street}, */
                        /* IDEIGLENES */
                        ///  phoneNumber: response.data.company.phoneNumber,
            /*             phoneNumber: this.props.userState.user.incompleteRegistration.stepTwo.details.phoneNumber,

                        tax_number: response.data.company.tax_number,
                        registrationNumber: response.data.company.registration_number,
                        certificateNumber: response.data.company.certificate_number, 
                        company: {id: response.data.company.id, name: response.data.company.name, type: response.data.company.type}}
                },
                stepThree: {...this.props.userState.user.incompleteRegistration.stepThree},
                stepFour: {...this.props.userState.user.incompleteRegistration.stepFour}
            }; */



            let user = {...this.props.userState.user}; 

                user.incompleteRegistration.stepTwo.complete = true;
                
                user.incompleteRegistration.stepTwo.details = {
                    location: {
                        zip: response.data.location.zip_code, 
                        city: response.data.location.city, 
                        street: response.data.location.street
                    }, 
                    phoneNumber: this.state.phonenumber,
                    tax_number: response.data.company.attributes.tax_number,
                    registrationNumber: response.data.company.attributes.registration_number,
                    certificateNumber: response.data.company.attributes.certificate_number, 
                    images:[this.state.image],
                    company: {
                        id: response.data.company.id, 
                        name: response.data.company.attributes.name, 
                        type: response.data.company.attributes.type
                    }
                }
            
                
       

           

                await this.props.setUserAction({...user})
              



                   /// send image if there is any
                if(this.state.image !== "" && this.state.image !== null ){
                
                    const data = { 
                        "upload_to":{ 
                            "type": "company","id": response.data.company.id }, 
                            "img": { 
                                "type": "certificate", 
                                "mime":this.state.image.split(";")[0].replace("data:image/",""),
                                "src": this.state.image } 
                        };
                    const dataImage = await request({ method:"post", url:"/image", data });
                    console.log(dataImage)
                    

                }  

                this.props.doneFunction({level:1})

        }
        



    /// if the server response is OK
    /// GO TO THE NEXT LEVEL

   // this.props.doneFunction({level:1})

  }

 render(){
 console.log(this.state)
 const window_width = this.props.systemState.dimension.width;
    return (


      <Container style={{backgroundColor:"white", padding:50, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", marginBottom:30 }}>
                <div style={{marginLeft:15}}>
                <p style={{...styles.p, fontSize:20, fontWeight:"bold"}}>CÉGES ADATOK</p>
                <p style={{...styles.p, fontSize:12, fontWeight:"normal"}}>KÉRLEK TÖLTS KI MINDEN ADATOT A TOVÁBBLÉPÉSHEZ</p>
                <div style={{...styles.divider, marginBottom:30}} />
                </div>

            <div style={{display:"flex", flex:8, flexDirection:'column'}}>  

             {/*  {downSideArray.map( (item, index) =>  this.downSideComponent({htmlFor:item.htmlFor, htmlForText:item.htmlForText, type:item.type, id:item.id, name:item.htmlFor, index})  )} */}

              { this.downSideComponent({htmlFor:downSideArray[0].htmlFor, htmlForText:downSideArray[0].htmlForText, type:downSideArray[0].type, id:downSideArray[0].id, name:downSideArray[0].htmlFor, index:0}) } 
              { this.downSideComponent({htmlFor:downSideArray[1].htmlFor, htmlForText:downSideArray[1].htmlForText, type:downSideArray[1].type, id:downSideArray[1].id, name:downSideArray[1].htmlFor, index:1}) } 

              <div style={{display:"flex", flexDirection:window_width < 900 ? "column" : 'row'}}>
                { this.downSideComponent({htmlFor:downSideArray[2].htmlFor, htmlForText:downSideArray[2].htmlForText, type:downSideArray[2].type, id:downSideArray[2].id, name:downSideArray[2].htmlFor, index:2}) } 
                { this.downSideComponent({htmlFor:downSideArray[3].htmlFor, htmlForText:downSideArray[3].htmlForText, type:downSideArray[3].type, id:downSideArray[3].id, name:downSideArray[3].htmlFor, index:3}) } 
              </div>
              <div style={{display:"flex", flexDirection:window_width < 900 ? "column" : 'row', marginTop:40}}>
                { this.downSideComponent({htmlFor:downSideArray[4].htmlFor, htmlForText:downSideArray[4].htmlForText, type:downSideArray[4].type, id:downSideArray[4].id, name:downSideArray[4].htmlFor, index:4}) } 
                { this.downSideComponent({htmlFor:downSideArray[5].htmlFor, htmlForText:downSideArray[5].htmlForText, type:downSideArray[5].type, id:downSideArray[5].id, name:downSideArray[5].htmlFor, index:5}) } 
              </div>
              { this.downSideComponent({htmlFor:downSideArray[6].htmlFor, htmlForText:downSideArray[6].htmlForText, type:downSideArray[6].type, id:downSideArray[6].id, name:downSideArray[6].htmlFor, index:6}) } 
              { this.downSideComponent({htmlFor:downSideArray[7].htmlFor, htmlForText:downSideArray[7].htmlForText, type:downSideArray[7].type, id:downSideArray[7].id, name:downSideArray[7].htmlFor, index:7}) } 


              <div style={{marginLeft:15, marginTop:50}}>
                <p style={{...styles.p, fontSize:20, fontWeight:"bold"}}>OPCIONÁLIS</p>
                <div style={{...styles.divider, marginBottom:30}} />
              </div>

              { this.downSideComponent({htmlFor:downSideArray[8].htmlFor, htmlForText:downSideArray[8].htmlForText, type:downSideArray[8].type, id:downSideArray[8].id, name:downSideArray[8].htmlFor, index:8}) } 

              <div style={{display:"flex", flexDirection:window_width < 900 ? "column" : 'row', marginTop:40}}>
              <div style={{display:"flex", flex:1, marginLeft:15, flexDirection:"column", alignItems: window_width < 900 ? "center":"flex-start", marginBottom:30}}>
                  <div style={{...styles.p}}>BIZONYÍTVÁNY KÉP FELTÖLTÉS</div>
                  <div style={{...styles.p}}>(EGYÉNI VÁLLALKOZÓK ESETÉBEN)</div> 
              </div>
              <div style={{display:"flex", flex:1, marginLeft:15, justifyContent: window_width < 900 ? "center":"flex-end"}}>
                    <div style={{marginRight:15}}>

                    {
                        this.state.image === null 
                          ? <ImageUpload cover={cover} index={0} imageToState={this.imageToState} /> 
                          : <> 
                            <div style={{...styles.imgDIV, backgroundImage:`url(${this.state.image})`}} />
                            <div onClick={()=>this.removeImage()} style={{position:"relative", top:-220, left:150, backgroundColor:'red', width:70, padding:2, borderRadius:6, cursor: "pointer"}} >
                                <div style={{display:"flex", justifyContent:'center', color:'white', fontSize:12, fontFamily:"MONDA"}}>TÖRLÉS</div>
                            </div> 
                           </>
                        
                      } 
                
                      
                      
                      
                        

                        
                    </div>
              </div>
             
              </div>
                 
         </div>  



         <div className="d-flex row w-100 errorPadding">
            <div className="w-100">
                <div className="hdivideretop"></div> 
                <div className="errorText" id="errorText"></div>
            </div>
        </div>   
        <div className="d-flex row justify-content-center justify-content-lg-end ">
            <div className="buttons">
                <button className="registerButton" onClick={()=> this.saveButton()}>Mentés</button>
            </div>                
        </div>   

        </Container>
 
 
    );

  }
}

const styles = {
  p:        {fontSize:15, fontFamily:"Maven Pro", letterSpacing:2, marginTop:10, fontWeight:"bold"},
  divider:  {height:1, widht:"100%", backgroundColor:"#4F4F4F"},
  imgDIV : {backgroundSize:"cover",
  objectFitt:"cover",
  backgroundRepeat:"no-repeat",
  backgroundColor:"white",
  backgroundPosition:"center", 
  width:210, 
  height:210,
  borderRadius:6,
  boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"}
}


/*====================== 
* State to Props
=====================*/
 const mapStateToProps = state => {
    const {systemState, userState, tokenState} = state;
    return {systemState, userState, tokenState};
};
  
/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
    setUserAction,
})(RegisterCorporationData));
