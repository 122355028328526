import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/Index.css";
import 'react-image-lightbox/style.css';

import System from './Controller/System';
import * as serviceWorker from './serviceWorker';

/// REACT-REDUX
import { Provider } from 'react-redux';
/// REDUX
import { createStore } from 'redux';
/// REDUCERS
import Reducers from './Model/Reducers';

import { PersistGate } from 'redux-persist/integration/react'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['systemState']
  }

const persistedReducer = persistReducer(persistConfig, Reducers)
export let store = createStore(persistedReducer);
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor}>
      <System/>
    </PersistGate>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); 