/* 
*
*   IMAGE UPLOAD CLASS
*       //imageToState: function, return the uploaded image in base64 format
*       //maxWidth: int, the maximum with of the image, default is 600 px
*
*/


import React from 'react';

import loadImage from "blueimp-load-image";
import emptyImage from "./Assets/empty.png";

import icon from "./Assets/icon.png";


export default class ImgUpload extends React.Component {


    state = {imagePreviewUrl: false, onMouseOver: false, img_type:"landscape", container_type:"landscape" };
   
        componentDidMount(){
            this.setState({
                container_type: this.container_ref.getBoundingClientRect().width < this.container_ref.getBoundingClientRect().height ? "portrait" : "landscape"
            })
            
        }

        container_ref;

        backgtoundStyle = () => {
            if(this.state.imagePreviewUrl){
                return { backgroundImage: this.state.onMouseOver ? `url(${icon}), url(${this.state.imagePreviewUrl})` : `url(${this.state.imagePreviewUrl})`,  backgroundSize: this.state.type === "landscape" ? "cover" : "cover"  } 
            }else{
                return {  backgroundImage:`url(${typeof this.props.cover === "undefined"  ? emptyImage : this.props.cover})`, backgroundSize: this.state.container_type === "landscape" ? "cover" :  "contain"  }
            }
        }
        
        imageContainerStylesProps = () => 
            typeof this.props.imageContainerStyles !== "undefined" 
                ? this.props.imageContainerStyles
                : null
        
        imageStyleProps = () => 
            typeof this.props.imageStyle !== "undefined" 
                ? this.props.imageStyle
                : null

        fileChangedHandler = async (event) => {

            const boolFile = event.target.files[0];
            
            loadImage.parseMetaData(boolFile, (data) => {
                
                    // default image orientation
                    var orientation = 0;
                    // if exif data available, update orientation
                    if (data.exif) orientation = data.exif.get('Orientation');
                        
                        
    
                    
                    loadImage(boolFile,  (canvas) => {
                        // here's the base64 data result
                        
                        const img_type = canvas.width < canvas.height  ? "portrait" : "landscape";
                  
                        const base64data = canvas.toDataURL('image/jpeg');
                            this.props.imageToState({base64data, index:this.props.index});
                            this.setState({imagePreviewUrl:base64data, img_type});
                    },{
                        // should be set to canvas : true to activate auto fix orientation
                        canvas: true,
                        orientation: orientation,
                        maxWidth: typeof this.props.maxWidth !== "undefined" ? this.props.maxWidth : 800
                    });
            })
        
        }

  
    render() {
        console.log(this.state)
        return ( 
                <div 
                    ref={(ref)=> this.container_ref = ref}
                    className="image-container" 
                    style={ {...this.backgtoundStyle(), ...styles.imgBG, ...this.imageContainerStylesProps() } }
                    onMouseOver={()=> this.setState({onMouseOver: true})} 
                    onMouseOut={()=> this.setState({onMouseOver: false})} 
                >              
                    <input 
                        id="dropzone"
                        type="file" 
                        name="avatar" 
                        onChange={this.fileChangedHandler} 
                        accept="image/*"
                        style={{
                            height: 200, 
                            width:  200,
                            opacity:0,
                            cursor:'pointer',
                            ...this.imageStyleProps()
                        }} />
                </div>
               
          );
    }

}

const styles = {
    imgBG : {
     
        objectFitt:"cover",
        backgroundRepeat:"no-repeat",
        backgroundColor:"white",
        backgroundPosition:"center", 
        width:210, 
        height:210,
        borderRadius:6,
        boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)",
    },
    imgOverlay : {
        backgroundSize:"cover",
        objectFitt:"cover",
        backgroundRepeat:"no-repeat",
        backgroundColor:"black",
        borderRadius:6,
    }
}


