import React, {Component} from 'react';
import {connect} from 'react-redux';
/// AXIOS
import {request} from "../../Model/Request";
/// BOOTSTRAP
import {Container, Modal, Button} from "react-bootstrap";
import Map from "../../View/Map";

import { NavLink } from "react-router-dom";
import parse from 'html-react-parser';

//import "./ExpertTool.css"

import defaultImage from "../../Assets/mesterlaptop.png"

const splitToChunks = (arr, chunkSize, acc = []) => (
  arr.length > chunkSize ?
      splitToChunks(
          arr.slice(chunkSize),
          chunkSize,
          [...acc, arr.slice(0, chunkSize)]
      ) :
      [...acc, arr]
)



 class Search extends Component  {


  state = {
    tool_data:[],
    user_data:null,
    type:0,

    /// TOOLS
    toolsID:null,
    name:"",
    descriptionText:"",
    price:"",
    images:[
      {img:null, name:0},
      {img:null, name:1},
      {img:null, name:2},
    ],

    gallery:[],
 


    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }],




  }

  componentDidMount(){
    window.scrollTo(0,0)
    this.getData();
    console.log(this.imageRefs)
  }


  imageRefs = [];



  isUserLoggedIN = () => {
    const {user} = this.props.userState
    return Object.entries(user).length === 0 && user.constructor === Object ? false : true
   }

 getData = async () => {

  const response = await request({ url:`/public/user/profile/tools/single?id=${this.props.match.params.id}` });

  const safe_response = response?.data ?? ""
  if(safe_response === "")return

  this.setState({tool_data:[safe_response]},()=> this.editButtonfunction(this.props.match.params.id))

  const response_owner = await request({ url:`/public/company/owner?id=${this.props.match.params.user_id}` });
  const safe_response_owner = response_owner?.data ?? ""
  if(safe_response_owner === "")return

  this.setState({ user_data: safe_response_owner})

  this.setState({gallery: splitToChunks(this.state.images, 5)}) 


 }

 componentDidUpdate(prevProps, prevState){



 

  if(this.state.type !==prevState.type && this.state.type === 1 && this.state.toolsID !== null){

    const data = this.state.tool_data[0]


    if(typeof data === "object"){


      
       this.setState({
        descriptionText:data?.attribute?.description ?? "",
        name: data?.attribute?.name ?? "",
        price: data?.attribute?.unit_price ?? "0",
        images:[{img: data?.attribute?.basic_image?.src ?? null, name:0 }, ...data.attribute.images.map( (item, index) =>{ return {img:item.src, name:index + 1} }) ],
        data:[{
          id: 0,
          name: data?.attribute?.location?.city ?? "",
          zip: data?.attribute?.location?.zip_code ?? "",
          latitude: data?.attribute?.location?.lat ?? "0",
          longitude: data?.attribute?.location?.lng ?? "0",
          circle : {
            radius: 0,
            options: {
              strokeColor: "#217BA7",
              fillColor:"#217BA7"
            }
          }
        }]
  

      }) 

    }
    

  }


 };








getTools = async () => {

  try{

    const id = this.props?.userState?.user?.company[0]?.id ?? null

    if(id === null) return

    const response = await request({ url:`/user/profile/tools?tool_id=${id}&tool_type=company` })

    if(typeof response.error === "undefined"){

        const data = response?.data?.tools ?? [];
      
        if(data.length > 0){
          this.setState({ tool_data : data  })
        }


    }
    

  }catch(e){
    console.log(e)
  }
 

};


jumpToChat = ()=> {
  this.isUserLoggedIN() 
     ? this.props.history.push({ 
           pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
           search: '',
           state: { type: "company" , id:  this.props.match.params.user_id}
       })
     : this.props.history.push({
       pathname: "/registration/user", 
       state: {  
           type: "basic", 
           from: "chat",
           data: { type: "company" , id:  this.props.match.params.user_id }
         }
     })
     
}


removeItemsFromImages = (name) => {

  this.setState({images:this.state.images.filter(item => item.name !== name)})
};

buttonStyleHandler = (type) => {

  let background = "white";
  let color = "black";


  if(type ===  this.state.type){
    background = "#0C76B3";
    color = "white"
  }

    

  return { background, color}

};

resetState = () => {
  return {     
    descriptionText:"",
    name:"",
    price:"",
    images:[
      {img:null, name:0},
      {img:null, name:1},
      {img:null, name:2},
    ],
    dates:[],
    imageIndexCounter:2, 
    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }],

    months:2,
    days : [],
    focused:[0,0],

  }
};

buttonFunctionHandler = (type) => {
  window.scrollTo({ top: 0 });
  this.setState({type, toolsID:null, ...this.resetState()})
};

editButtonfunction = (id) => {
  this.setState({type:1, toolsID:id, ...this.resetState()})
};

smallBoxComponent = (item) => {

  return(
    <div style={{ ...styles.boxcomponentContainer }} key={item.id}>

        {/* image */}
        <div style={{ backgroundImage:`url(${item?.attribute?.basic_image?.src ?? defaultImage})`, ...styles.boxcomponentImage }}/>
        {/* container */}
        <div style={{padding:10}}>
          {/* title */}     
         <div style={{ ...styles.fontStyle, fontWeight:"bold", ...styles.boxcomponentTitle, textAlign:"center"  }}>{item?.attribute?.name ?? ""}</div>
          {/* text */}
        <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:50 }}>{this.removeHTMLTags(item?.attribute?.description ?? "")}</div>
          {/* button */}
          <div style={{ ...styles.fontStyle, ...styles.boxcomponentButton }} onClick={()=> this.editButtonfunction(item.id) } >SZERKESZTÉS</div>
        </div>

    </div>
  )

};

titleComponet = ({title}) => {
  return(
    <>
    <div>{title}</div>
    <div style={{width:'100%', height:1, backgroundColor:"black"}} />
    </>
  )
};

removeHTMLTags = (data) => {
  const regex = /(<([^>]+)>)/ig;
  return data.replace(regex, "");
}

toolsComponent = (window_width) => {


  return(

    <div>

    {/* NAME OF THE TOOL */}
    <div style={{...styles.fontStyle, fontSize:26, marginBottom:10, marginTop:20}}>{this.state.name}</div>

    {this.titleComponet({title:""})}
    <div style={{margin:50}} />

    <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>

    <div style={{...styles.fontStyle,  marginBottom:10 }} >BÉRBEADÓ</div>
    <div style={{ width:80, height:80, borderRadius:40, background:`url(${this.state.user_data?.attributes?.avatar?.src ?? null !== null ? this.state.user_data.attributes.avatar.src : defaultImage }) center center / cover no-repeat white` }}  />
    <div style={{...styles.fontStyle,  marginBottom:10, marginTop:20 }} >{this.state.user_data?.attributes?.name ?? null !== null ? this.state.user_data.attributes.name : "" }</div>

    <div style={{...styles.button, ...styles.fontStyle2,  marginBottom:40, marginTop:20, textAlign:"center", cursor:"pointer" }} onClick={()=> this.jumpToChat()} >KAPCSOLATBALÉPEK A MESTERREL</div>

    </div>

    {this.titleComponet({title:""})}
    <div style={{margin:50}} />

  


    {/* TOOL IMAGES */}

{/* 
<div style={{...styles.imgDIVBig, backgroundImage:`url(${item[0].img})`}} /> 
*/}

    <div >
    {
      this.state.gallery.map((item, index)=>{
        this.imageRefs[index] = [];
        return(
          <div  key={index} style={{ ...styles.parent, marginBottom:10}}>
          <div style={{...styles.col}}>
            <div style={{...styles.big}}>
              {typeof item[0] !== "undefined" ? <div style={{ ...styles.newBGimg,  backgroundImage:`url(${item[0].img})`, height:"100%", width:"100%"  }}  ref={ ref => this.imageRefs[index][0] = ref }  />  : null}
            
            </div>
          </div>
          <div style={{...styles.col, ...styles.wrap}}>
            <div style={{...styles.box}}>
            {typeof item[1] !== "undefined" ? <div style={{ ...styles.newBGimg,  backgroundImage:`url(${item[1].img})`, height:"100%", width:"100%"  }}  ref={ ref => this.imageRefs[index][1] = ref }  /> : null}
            </div>
            <div style={{...styles.box}}>
            {typeof item[2] !== "undefined" ? <div style={{ ...styles.newBGimg,  backgroundImage:`url(${item[2].img})`, height:"100%", width:"100%"  }} ref={ ref => this.imageRefs[index][2] = ref }  /> : null}
            </div>
            <div style={{...styles.box}}>
            {typeof item[3] !== "undefined" ? <div style={{ ...styles.newBGimg,  backgroundImage:`url(${item[3].img})`, height:"100%", width:"100%"  }} ref={ ref => this.imageRefs[index][3] = ref }  /> : null}
            </div>
            <div style={{...styles.box}}>
            {typeof item[4] !== "undefined" ? <div style={{ ...styles.newBGimg,  backgroundImage:`url(${item[4].img})`, height:"100%", width:"100%"  }} ref={ ref => this.imageRefs[index][4] = ref }  /> : null}
            </div>
          </div>
        </div>
        )
      })
    }
    </div>






   




    <div style={{margin:50}} />
    {/* TOOL DETAILD DESCRIPTION */}
    {this.titleComponet({title:"SZERSZÁM RÉSZLETES LEÍRÁSA"})}
    <div style={{...styles.fontStyle2, fontSize:18, marginBottom:50, marginTop:20}}>{parse(this.state.descriptionText)}</div>
  

 

    {/* TOOL RENT PRICE */}
    {this.titleComponet({title:"BÉRBEADÁSI ÁR"})}

    <div style={{display:"flex"}}>
    
        <div style={{...styles.fontStyle2, fontSize:18, marginBottom:50, marginTop:20}}  >{this.state.price}  FT / NAP </div>
     
    </div>



  
  


  
      <div style={{marginTop:30}} />

     {/* TOOL PICKUP POINTS */}
    {this.titleComponet({title:"ÁTVÉTELI LEHETŐSÉG"})}
    
    <div style={{...styles.fontStyle2, fontSize:18, marginBottom:50, marginTop:20}}  >{this.state.data[0].name} </div>

    <Map
        center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} /// lat: 47.497913, lng: 19.040236
        zoom={10}
        places={this.state.data}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
        loadingElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%`, filter:"grayscale(95)" }} />}
        containerElement={<div style={{ height:'300px', width:'100%' }} />} />




  <div style={{marginBottom:30}}  />

    
    

    </div>
  )


}






  render(){
    console.log(this.props)
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
  
      <div style={{...styles.outerBG}}>

            <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
              <div>SZERSZÁM BÉRBEADÁS</div>
            </div>
           

              {/* LIST */}
              <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>

                    {
                      this.state.type === 0 ||  ( this.state.tool_data.length === 0 || this.state.user_data === null )
                        ?   <div style={{margin:60, ...styles.fontStyle, display:"flex", justifyContent:"center"}}> A SZERSZÁM JELENLEG NEM ELÉRHETŐ</div>
                        :   <div style={{margin:60}}>{ this.toolsComponent(window_width) }</div>
                    }

            </Container>

            <div style={{...styles.bottomSpacer}}/>
        </div>


      




    );

  }
}

const styles = {

  headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
  bottomSpacer: {height:30, width:"100%"},
  outerBG: {background:"#F6F9FE"},
  headerMainContainer : {paddingTop:10, paddingBottom:10, display:"flex", paddingLeft:15},
  headerSubContainer : {flex:10, marginTop:15, marginBottom:15},
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  fontStyle2: {letterSpacing:3, fontFamily:"MONDA", fontSize:12},
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },
  input:    {width:"100%", padding:10, height:40, marginTop:20, marginBottom:20, border: "1px solid #4F4F4F",  bordeRadius:5,},
  p:        {fontSize:12, fontFamily:"Maven Pro", letterSpacing:2},
  button:   {color:"white", padding:12, display:"flex", justifyContent:"center" ,borderRadius:4, border:'1px solid #4F4F4F', height:65, alignItems:"center", fontSize:14, background:"rgb(13, 116, 176)", width:200},
  imgDIV :  {backgroundSize:"cover",
             objectFitt:"cover",
             backgroundRepeat:"no-repeat",
             backgroundColor:"white",
             backgroundPosition:"center",
             width:210,
             height:210,
             borderRadius:6,
             boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"},
  imgDIVBig :{backgroundSize:"cover",
             objectFitt:"cover",
             backgroundRepeat:"no-repeat",
             backgroundColor:"white",
             backgroundPosition:"center",
             width:"100%",
             height:"auto",
             borderRadius:6,
             boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"},      
  newBGimg : {backgroundSize:"cover",
              objectFitt:"cover",
              backgroundRepeat:"no-repeat",
              backgroundColor:"white",
              backgroundPosition:"center",
              boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)",
              borderRadius:6,
          
            },             

  boxcomponentContainer: {width:250, margin:10, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius:5 },
  boxcomponentImage: { height:200, width:250, backgroundPosition:"center center", backgroundSize:"cover",  backgroundRepeat:"no-repeat", borderRadius:5},
  boxcomponentTitle: { marginTop:10, marginBottom:10},
  boxcomponentDescription: {marginTop:10, marginLeft:10, marginRight:10, marginBottom:20, overflow: "hidden", textOverflow: "ellipsis",},
  boxcomponentButton:{fontSize:12, textAlign:"center", margin:10, padding:10, background:"#0D74B0", color:"white", borderRadius:5},
  parent: {
    display: "flex"
  },
  col: {
    flex: "0 0 50%",
    height: "35vw",
    padding:"0!important",
    margin:"0!important",
  },
  wrap : {
    display: "flex",
    flexWrap:"wrap",
    justifyContent: "center",
  },
  box : {
    flex: "0 0 50%",
    height: "50%",
    padding:10,
  },
  big : {
    padding:10,
    flex: "0 0 15vw",
    height: "35vw"
  }
}


/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Search);
