import React, {Component} from 'react';

import { NavLink} from "react-router-dom";
//styles
import './RegisterLanding.css';
 class RegisterLanding extends Component  {
  render(){

    return (
      <div className="row d-flex regland ">
          <div className="regleft">
              <div className="regAlign">
                <div className="contentTittle">
                    <div>Hozz</div>
                    <div className="letter">Létre</div>
                    <div className="letter">Profilt</div>
                </div>
                <div>
                    <div className="content">
                      <p>A Mesterportál olyan szakemberek gyűjtőhelye, akik szeretik a kihívásokat és készen állnak szakmai kompetenciájukat úgy meghirdetni, <br/>hogy a Megrendelő biztos legyen benne, hogy igényeit hatékonyan megvalósítják meglepetések nélkül. </p>
                      <p>Az oldalon személyre szabhatjuk profilunkat és beállíthatjuk, hogy milyen munkálatokat végzünk. A naptár funkció nem csak a Mesterembernek,<br/>de a Megrendelőnek is nagy segítség, hiszen a megállapodáskötés elengedhetetlen feltétele az időpontok pontos meghatározása.</p>
                      <p>A főoldalon a legaktuálisabb témákat, híreket osztjuk a szakmai életben történő változásokról és sikerekről. <br/>A  profil szükséges a szakember kereséséhez és a hirdetés feladásához és a szerszámok kölcsönzéséhez.</p>
                      <p>Fogjunk össze Mesterek!</p>
                    </div>
                </div>
                <div>
                
                <NavLink to='/registration/selector'><button>Regisztráció</button></NavLink>
                </div>
              </div>
          </div>
          <div className="regright">
              <div>
                 <div className="rightImage"></div>
                 <div className="d-none d-lg-flex rightLogo"></div>
              </div>
          </div>
      </div>
    );

  }
}



export default RegisterLanding;
