import React, {Component, Fragment} from 'react';



import Select from 'react-select';
import ImageUpload from "../../../../View/ImageUpload";
import Map from "../../../../View/Map";
import {request}   from "../../../../Model/Request";
import telepulesek from '../../../../Model/telepules'

import {Container, Modal, Button} from "react-bootstrap";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './NewAds.css'
import cover from "../../../../Assets/empty.png";
import {mester_default_base64} from "../../../../Assets/mester_default_base64";
import addBTN from "../../../../Assets/add.png";

const deadlineArray = ["A lehető leghamarabb", "1 hónapon belül", "3 hónapon belül", "Rugalmas kezdés"];
const budgetArray   = ["0 - 20.000 Ft", "20.000 - 50.000 Ft", "50.000 - 150.000 Ft", "Nincs összeghatár"];

 class NewAds extends Component  {

  state ={

    /* SEARCH */
    servicesList:null,
    mainCategorySelected:null,
    selectedSubCategoryList:null,
    selectedSubCategory:null,


    /* MODAL */
    isModalShow:false,
    errorMessage:'',
    successMessage:'',

   /* IMAGES */ 
   imageIndexCounter:2,
    images:[  
      {img:null, name:0},
    ],

    /* MAP DATA */
    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }],

    /* TEXT EDITOR */
    descriptionText:"",

    /* DEADLINE */
    deadline:"",
    budget :"",


  }

  componentDidMount(){
    this.mainTypeRequest()
  }

  componentDidUpdate(prevProps, prevState){

    if(this.state.mainCategorySelected !==  prevState.mainCategorySelected){
      this.setState({selectedSubCategory:null})
      this.mainCategoryRequest()
    }

  }


  /// validation Timeout for settlament search
  validationTimeout;

  setInput = (e) => {

    const {id} = e.target;

    let {value} = e.target;
   
    clearTimeout(this.validationTimeout);
    
    

    this.setState(state=>{
      const newData = state.data.map((item, index) => { if(index == id){  return {...item, name:value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() } } else {return {...item}} }); 
      return {data:newData}
    })

      

      this.validationTimeout = setTimeout(()=>{ 
        
        const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())
        if( settlement.length > 0 ){
          document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(38,134,176,0.26)";
          this.setState(state=>{ 
              const newData2 = state.data.map((item, index)=> { if(index == id){  return { ...item, longitude:settlement[0].Long, latitude:settlement[0].Lat, zip:settlement[0]["Ir. szám"] }  }else{ return { ...item } } } );
              return {data:newData2}
            })
        }
        else{
           
            this.setState({ isModalShow:true, errorMessage:`A következő település nem található: ${value}` })
            document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(227,6,6,0.15)";
            this.setState(state=>{ 
              const newData2 = state.data.map((item, index)=> { if(index == id){ return {  ...item, name : "" } }else{ return { ...item } } } );
              return {data:newData2}
            })
        } 
    },2000)

  }

  mainTypeRequest = async () => {
    try{
      const response = await request({url:`/profession?per_page=1000&current_page=1`, method:"GET",  });
      this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
    }catch(e){
      /// error handler
        console.log(e)
    }
   }

   mainCategoryRequest = async () => {
     try{
       
       const response = await request({url:`/profession/specialization?profession_id=${this.state.mainCategorySelected.value}`, method:"GET",  });
      this.setState({selectedSubCategoryList:response.data.map(item=>{ return {value: item.id,     label: item.title }  })})
    }catch(e){
      /// error handler

    }
   }

  onChangeHandler = (e)=>
  {
    console.log(e.target.value);
  }
  imageToState = ({base64data, index}) => {
 
    this.setState({images:this.state.images.map(item => item.name === index ? {...item, img:"base64", src:base64data} : {...item} )})
  }

  imageToState = ({base64data, index}) => {

    this.setState(state=>{

        return {  images:state.images.map(item=> {
                        if(item.name === index) return {...item, img:base64data}
                        else return {...item}
                  })}
    });
  };

  addItemToImages = () => {
    this.setState(state=>{

      const newImages = [...state.images,{img:null, name: this.state.imageIndexCounter + 1}]
        return {images:newImages, imageIndexCounter:this.state.imageIndexCounter + 1}
    });
  };

  removeItemsFromImages = (name) => {

    this.setState({images:this.state.images.filter(item => item.name !== name)})
  };


  checkBoxcomponent = ({item, index, type}) => 
  <label className="containerchecka" key={type+"_"+index}>{item}
    <input id={index} type="checkbox" onChange={()=> this.setState({[type]:index})} checked={this.state[type] === index ? true : false} />
    <span className="checkmarka"></span>
  </label>

  


saveFunction = async () => {

  /* VALIDATION */

  /// MAIN CATEGORY
  const mainCategory = this.state.mainCategorySelected?.value ?? null
  if(mainCategory === null) {
    this.setState({
      isModalShow:true,
      errorMessage:"A munka megnevezésénél a főkategória kiválasztása kötelező!"
    });

    return
  }

   /// deadline
   if(this.state.deadline === "") {
     this.setState({
       isModalShow:true,
       errorMessage:"A határidő kiválasztása kötelező!"
     });
     
     return
   }

    /// budget
    if(this.state.budget === "") {
      this.setState({
        isModalShow:true,
        errorMessage:"A költséghatár kiválasztása kötelező!"
      });
      return
    }

    /// map
    if(this.state.data[0].name === "") {
      this.setState({
        isModalShow:true,
        errorMessage:"A település kiválasztása kötelező!"
      });
      return
    }

    /// description text
    if(this.state.descriptionText.replace(/<(.|\n)*?>/g, '').trim().length === 0 ){
      this.setState({
        isModalShow:true,
        errorMessage:"A munka részletes leírása kötelező!"
      });
    
      return
    } 


    let safe_images = this.state.images.filter(item=> item.img !== null);
    console.log(safe_images)
    if(safe_images.length === 0){
       safe_images = [{img:mester_default_base64, name:0}]
    }


    const data = {
      "profession_id": this.state.mainCategorySelected.value,
      "deadline_id" : parseInt(this.state.deadline),
      "payment_limit_id":parseInt(this.state.budget),
      "description": this.state.descriptionText,
      "location":{
        "lat" : parseFloat(this.state.data[0].latitude),
        "lng" : parseFloat(this.state.data[0].longitude),
        "distance" : null,
        "country_code": "hu",
        "zip_code": parseInt(this.state.data[0].zip),
        "country": null,
        "county": null,
        "city": this.state.data[0].name,
        "street": null,
        "house_number": null,
        "extra":{
            "floor" : null,
            "door" : null
        },
        "formatted_address": null
      },
      "img": {
        "mime":safe_images[0].img.split(";")[0].replace("data:image/",""),
        "src":safe_images[0].img
      }
    } 

    console.log(safe_images)
    console.log(safe_images.length > 1)

    const response = await request({ url:"/ads", data, method:"POST"});

   // console.log("========== MAIN ==========")
   // console.log(response)
   // console.log("========== ==== ==========")

    const advert_id = response?.data?.id ?? null

    if( typeof response.error === "undefined" && advert_id !== null ){



          if(safe_images.length > 1 ){

            let loop_error = false;

            for (let index = 0; index < safe_images.length; index++) {
              if(index === 0) continue

              const item = safe_images[index];

              const img_data = {
                upload_to:{
                  type: "ads",
                  id: advert_id
                },
                img: {
                  type: "description",
                  mime:item.img.split(";")[0].replace("data:image/",""),
                  src: item.img
                }
              };

            // console.log("========== LOOP DATA ==========")
            // console.log(img_data)
            // console.log("========== ==== ===============")

              const response = await request({url:"/image", method:"post", data:img_data});

              if(typeof response.error !== "undefined"){
                loop_error =  true
              }

            // console.log("========== LOOP RESPONSE ==========")
            // console.log(response)
            // console.log("========== ==== ===================")
              
            }

            if(loop_error === false){

              this.setState({
                  successMessage:"SIKERES MENTÉS",
                  isModalShow:true,
                  servicesList:null,
                  mainCategorySelected:null,
                  selectedSubCategoryList:null,
                  selectedSubCategory:null,
                  imageIndexCounter:2,
                  images:[{img:null, name:0}],
                  data:[{
                    id: 0,
                    name: "",
                    zip:"",
                    latitude: "0",
                    longitude: "0",
                    circle : {
                      radius: 0,
                      options: {
                        strokeColor: "#217BA7",
                        fillColor:"#217BA7"
                      }
                    }
                  }],
                  descriptionText:"",
                  deadline:"",
                  budget :"",
              })

            }else{  

              this.setState({
                errorMessage:"Hiba lépett fel a mentés közben!",
                isModalShow:true,
              })

            }
            

            

          }else{

            this.setState({
              successMessage:"SIKERES MENTÉS",
              isModalShow:true,
              servicesList:null,
              mainCategorySelected:null,
              selectedSubCategoryList:null,
              selectedSubCategory:null,
              imageIndexCounter:2,
              images:[{img:null, name:0}],
              data:[{
                id: 0,
                name: "",
                zip:"",
                latitude: "0",
                longitude: "0",
                circle : {
                  radius: 0,
                  options: {
                    strokeColor: "#217BA7",
                    fillColor:"#217BA7"
                  }
                }
              }],
              descriptionText:"",
              deadline:"",
              budget :"",
            })

          }

          


    }else{

      this.setState({
        isModalShow:true,
        errorMessage:"Hiba merült fel a szerverrel való kommunikáció során! Kérlek próbáld meg később!"
      })

    }
 




}

  render(){
    
    
    console.log(this.state)
    return (
      <>

        <Modal show={this.state.isModalShow} onHide={ ()=> this.setState({isModalShow:false, errorMessage:"",  successMessage:""}) }>
          <Modal.Header>
            <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{...styles.p}}>{this.state.errorMessage}</div>
            <div style={{...styles.p}}>{this.state.successMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={ this.state.successMessage !== "" ? "primary" : "secondary"} onClick={ ()=> this.setState({isModalShow:false, errorMessage:"",  successMessage:""}) }>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

      <div className="innerContainer d-flex justify-content-center">
        <div className="innerAligner d-flex flex-wrap justify-content-start">
          <div className="w-100 newads">
              <div className="d-flex">
                <div className="headerTittle">
                  <div>Hirdess</div>
                  <div>Meg</div>
                  <div>Munkát</div>
                </div>
               
              </div>
              <div className="subtittle">Munka megnevezése</div>
              <div className="hdecor"></div>
              <div className="w-100 d-flex marginer">
                <div className="w-50 selectRightsa">
                    {/* <Select className="selector"
                    options={this.state.servicesList}
                    onChange={(e)=>this.setState({mainCategorySelected:e})}
                    styles={styles.selectStyle}
                    placeholder={"VÁLASSZ SZOLGÁLTATÁS!"}
                    noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                    isDisabled={this.state.servicesList === null ? true:false}/> */}
                     <Select
                        options={this.state.servicesList}
                        onChange={(e)=>this.setState({mainCategorySelected:e})}
                        styles={styles.selectStyle}
                        placeholder={"SZOLGÁLTATÁS!"}
                        noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                        isDisabled={this.state.servicesList === null ? true:false}  />
                </div>
                <div className="w-50 selectLeftsa">
                    {/* <Select className="selector"
                    options={this.state.subservicesList}
                    onChange={(e)=>this.setState({subCategorySelected:e})}
                    styles={styles.selectStyle}
                    placeholder={"VÁLASSZ SZOLGÁLTATÁS!"}
                    noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                    isDisabled={this.state.subservicesList === null ? true:false}/>  */}  



                    {/* !!!!!!!!!!!!!!!!!! */}
                    {/* AZÉRT VAN KIKOMMENTELVE, MERT A BACK-END NEM VESZ ÁT ALKATEGÓRIA ADATOT */}
                    {/* !!!!!!!!!!!!!!!!!! */}


                    {/* <Select
                        options={this.state.selectedSubCategoryList}
                        value={this.state.selectedSubCategory === null ? null : this.state.selectedSubCategory}
                        onChange={(e)=>this.setState({selectedSubCategory:e})}
                        styles={styles.selectStyle}
                        placeholder={"ALKATEGÓRIA!"}
                        noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                        isDisabled={this.state.selectedSubCategoryList === null ? true:false}  />    */}         
                </div>
              </div>

              <div className="d-flex flex-wrap w-100 marginer">

                <div className="w-50 selectRight">
                  <div className="w-100 subsub">
                      Határidő
                  </div>
                    {
                      deadlineArray.map( (item, index)=> this.checkBoxcomponent({index:index+1, item, type:"deadline"}) )
                    }
{/*   
                    <label className="containerchecka">A lehető leghamarabb
                        <input id={0} type="checkbox" onChange={()=> this.setState({deadline:0})} checked={ this.state.deadline === 0 ? true : false} />
                        <span className="checkmarka"></span>
                        </label>
                    <label className="containerchecka">1 hónapon belül
                         <input id={1} type="checkbox" onChange={()=> this.setState({deadline:1})} checked={this.state.deadline === 1 ? true : false} />
                        <span className="checkmarka"></span>
                    </label>
                    <label className="containerchecka">3 hónapon belül
                        <input id={2} type="checkbox" onChange={this.onChangeHandler}/>
                        <span className="checkmarka"></span>
                    </label>
                    <label className="containerchecka">Rugalmas kezdés
                      <input  id={3} type="checkbox" onChange={this.onChangeHandler}/>
                      <span className="checkmarka"></span>
                    </label>    
*/}                                       
                </div>

                <div className="w-50 selectLeft">
                  <div className="w-100 subsub">
                      Költséghatár               
                  </div>
                {
                    budgetArray.map( (item, index)=> this.checkBoxcomponent({index:index+1, item, type:"budget"}) )
                }
{/* 
                    <label className="containerchecka">0 - 20.000 Ft
                        <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                        <span className="checkmarka"></span>
                    </label>
                    <label className="containerchecka">20.000 - 50.000 Ft
                        <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                        <span className="checkmarka"></span>
                    </label>
                    <label className="containerchecka">50.000 - 150.000 Ft
                        <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                        <span className="checkmarka"></span>
                    </label>
                    <label className="containerchecka">Nincs összeghatár
                      <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                      <span className="checkmarka"></span>
                    </label>   
 */}
                </div>
              
              </div>   
              <div className="subtittle ">Fényképek feltöltése</div>
              <div className="hdecor"></div>
              <div className="d-flex flex-wrap justify-content-start w-100">

                  {/* <div className="benceImage">
                      <ImgUpload index={0} imageToState={this.imageToState} cover={cover} imageContainerStyles={{ width:293,height:186,backgroundSize: 'contain' }} imageStyle={{ width:293,height:186,backgroundSize: 'cover' }} />
                  </div>
                  <div style={{padding:50, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}} onClick={()=>this.addItemToImages()}>
                      <div style={{fontSize:12, fontFamily:"MONDA"}}>TOVÁBBI</div>
                      <div style={{fontSize:12, fontFamily:"MONDA"}}>KÉPEK</div>
                      <div style={{fontSize:12, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
                      <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
                  </div> */}

                  <div style={{...styles.imageContainer}}>

                  {
                    this.state.images.map( (item, index)=>

                    <div key={index + "img"} style={{margin:10}}>
                      {
                        item.img === null
                          ? <ImageUpload index={item.name} imageToState={this.imageToState} cover={cover}  imageContainerStyles={{ width:293,height:186,backgroundSize: 'contain' }} imageStyle={{ width:293,height:186,backgroundSize: 'cover' }} />
                          : <div style={{...styles.imgDIV, backgroundImage:`url(${item.img})`}} />
                      }

                        <div onClick={()=>this.removeItemsFromImages(item.name)} style={{...styles.removeArea}} >
                            <div style={{...styles.removeText}}>TÖRLÉS</div>
                        </div>


                    </div> )

                  }

                  <div style={{padding:60, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}} onClick={()=>this.addItemToImages()}>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>TOVÁBBI</div>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>KÉPEK</div>
                    <div style={{fontSize:12, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
                    <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
                  </div>

                  </div>
    
              
              </div>
              <div className="subtittle">Munka végzés helye</div>
              <div className="hdecor"></div>
              <div className="marginerb"><input id={0} uniqe={"settlement"+0}  placeholder="Város" onChange={(e)=> this.setInput(e)} value={this.state.data[0].name} /></div>
              <div className="marginerc">
                  <Map
                  center={{ lat: parseFloat(this.state?.data[0]?.latitude) , lng: parseFloat(this.state?.data[0]?.longitude) }} /// lat: 47.497913, lng: 19.040236
                  zoom={this.state.data?.length < 2 ? 9 : 7}
                  places={this.state?.data}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height:'300px', width:'100%' }} />}
                  />               
              </div>
              <div className="subtittle">Munka részletes leírása</div>
              <div className="hdecor"></div>  
              <div>
               {/*  <textarea className="btextarea w-100" placeholder="Részletes leírás"></textarea> */}
                <ReactQuill
                  placeholder= "Részletes leírás..."
                  value={this.state.descriptionText}
                  onChange={(descriptionText)=>this.setState({descriptionText})}
                  style={{height:300, marginTop:20, marginBottom:70}}  />
              </div> 
              <div className="text-right marginerd">
                  <button className="sendbutton" onClick={()=> this.saveFunction()}>Küldés</button>
              </div>           
    
           </div>
        </div>
      </div>
      </>
    );


  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default NewAds;


const styles = {



  selectStyle : {
    container: provided => ({
      ...provided,
      borderRadius:3,
      width: "100%",
      fontFamily:"Maven Pro",
      fontSize:16,
      fontWeight:"bold",
      color:'#4F4F4F',
    }),

    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected ? "#1C466F":"white",
      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"

    }),

    control: base => ({
      ...base,
      border: 0,
      borderRadius:3,
      boxShadow: 'none',
      fontFamily:"Maven Pro",
      height:50,
      letterSpacing: 3
    })
  },
  imageContainer: {display:"flex", flexWrap:"wrap", justifyContent:"space-evenly", marginTop:20, marginBottom:20},
  removeArea: {position:"relative", top:-200, left:225, backgroundColor:'red', width:70, padding:2, borderRadius:6, cursor: "pointer"},
  removeText: {display:"flex", justifyContent:'center', color:'white', fontSize:12, fontFamily:"MONDA"},
  imgDIV :   {backgroundSize:"cover",
              objectFitt:"cover",
              backgroundRepeat:"no-repeat",
              backgroundColor:"white",
              backgroundPosition:"center",
              width:293,
              height:186,
              borderRadius:6,
              boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"},
}