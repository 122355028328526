/* REACT */
import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
/* ROUTER */
import {withRouter} from 'react-router-dom';
/* BOOTSTRAP */
import {Container, Row, Col} from "react-bootstrap";
/* SVG */
import Logo from "../View/SVG/Logo";
/* IMAGES */
import carts from "../Assets/carts.png";



class Footer extends Component  {

  /// Detect if user logged in or not
  isUserLoggedIN = () => {
    const {user} = this.props.userState
    return Object.entries(user).length === 0 && user.constructor === Object ? false : true
  }

  footerComponent = () => {

    const screenWidth = this.props.systemState.dimension.width;

    return(
      <div style={{...styles.mainDiv, height: this.props.location.pathname !== "/registration/professional" ? 965 :  100 }}>
          <Container>
          { this.props.location.pathname !== "/registration/professional" ?
            <>
            <div style={{...styles.subContainer, ...{paddingTop: screenWidth < 764 ? 50 : 170}}}>

                <Row style={{width:"100%"}}>
                  <Col xs={12} md={7}>
                    <div>
                      <p style={{...styles.p1, ...{textAlign:screenWidth < 764 ? "center": "left", fontSize:14}}}>SZERETNÉD MINÉL TÖBB</p>
                      <p style={{...styles.p1, ...{textAlign:screenWidth < 764 ? "center": "left", fontSize:14}}}>MESTEREMBER ÁRAJÁNLATÁT LÁTNI?</p>
                    </div>
                    <div style={{display:"flex", alignItems:"flex-start", minHeight:'144', marginTop:40 }} >
                      <p style={{...styles.p2,...{textAlign: screenWidth < 764 ? "center": "left", fontWeight:"bold"}}}>HIRDESS MEG MUNKÁT!</p>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>

                  {
                       !this.isUserLoggedIN() || ( this.isUserLoggedIN() && this.props.userState.user.attributes.type === "basic") 
                       ?
                       <div style={{cursor:"pointer", display:"flex", alignItems:"flex-end", height:'100%', justifyContent:screenWidth < 764 ? "center":"flex-end", marginTop: screenWidth < 764 ? 10 : 0  }} onClick={()=> this.props.history.push("/users/ads/new")} >
                          <div style={{padding:17, background:"#2686B0", height:65, width:365,  borderRadius:7}}>
                            <p style={{color:"white", fontSize:20, textAlign:'center', letterSpacing: screenWidth < 992 ? 0: 5 }}>HIRDETÉS FELADÁSA</p>
                          </div>
                       </div>
                     : null
                  }

                    


                  </Col>
                </Row>  
            </div>
            <Row >
              <Col xs={12} md={12}>
                <div style={{...styles.divider}} />
              </Col>
            </Row>
            <Row style={{width:"100%"}}>
                <Col xs={12} md={6}>
                  <div style={{display:"flex", flexDirection:"column", alignItems: screenWidth <= 764 ? "center": "flex-start", marginTop: screenWidth <= 764 ? 20: 0}}>
                      <Logo/>
                      <img src={carts} alt="" style={{marginTop:20}} />
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <div style={{display:"flex", height:'100%', flexDirection:"row"}}>
                            {
                              screenWidth > 764 ?  
                                <div style={{display:"flex", flexDirection:"column", marginTop: screenWidth < 764 ? 20: 0, flexGrow:1 }}>
                                  <div style={{...styles.dividerVertical}} />
                                </div>
                              : null
                            }
                          <div style={{display:"flex", flexDirection:"column", justifyContent:"center",  alignItems: screenWidth <= 764 ? "center": "flex-start", marginTop: screenWidth < 764 ? 20: 0, flexGrow: screenWidth <= 764 ? 1 : 2}}>
                            <p style={{...styles.p1, ...styles.p3, ...{color:'white'}}}>KIK VAGYUNK</p>
                            <p style={{...styles.p1, ...styles.p3}}>Kapcsolat</p>
                            <p style={{...styles.p1, ...styles.p3}}>Rólunk</p>
                            <p style={{...styles.p1, ...styles.p3, cursor:"pointer"}} onClick={()=> this.props.history.push("/afsz")} >ÁFSZ</p>
                          </div>
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div style={{display:"flex", height:'100%', flexDirection:"row"}}>
                      <div style={{display:"flex", flexDirection:"column", justifyContent:"center",  alignItems: screenWidth <= 764 ? "center": "flex-start", marginTop: screenWidth < 764 ? 20: 0, flexGrow: screenWidth <= 764 ? 1 : 2}}>
                        <p style={{...styles.p1, ...styles.p3, ...{color:'white'}}}>INFORMÁCIÓ</p>
                        <p style={{...styles.p1, ...styles.p3, cursor:"pointer"}} onClick={()=> this.props.history.push("/faq")}>Hogyan működik</p>
                        <p style={{...styles.p1, ...styles.p3, cursor:"pointer"}} onClick={()=> this.props.history.push("/faq")}>Vásárlás menete</p>
                        <p style={{...styles.p1, ...styles.p3, cursor:"pointer"}} onClick={()=> this.props.history.push("/faq")}>GY.I.K.</p>
                      </div>
                      {
                        screenWidth > 764 ?  
                          <div style={{display:"flex", flexDirection:"column", marginTop: screenWidth < 764 ? 20: 0, flexGrow:1, }}>
                            <div style={{...styles.dividerVertical}} />
                          </div>
                        : null
                      }
                   </div>  
                  </Col>
            </Row>  
            <Row style={{width:"100%", marginTop: screenWidth <= 764 ? 20 : 70}}>
              <Col xs={12} md={6}>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center",  alignItems: screenWidth <= 764 ? "center": "flex-start"}}>
                    <p style={{...styles.p1, ...styles.p4, ...{color:'white'}}}>©2019 mesterportal.com, All rights reserved</p>
                  </div>
              </Col>
              <Col xs={12} md={6} >
                <Row>
                  <Col xs={12} md={6}>
                      <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end",  alignItems: screenWidth <= 764 ? "center": "flex-end"}}>
                        <p style={{...styles.p1, ...styles.p4, ...{color:'white', cursor:"pointer"}}}  onClick={()=> this.props.history.push("/afsz")} >Felhasználási feltételek</p>
                      </div>
                  </Col>
                  <Col xs={12} md={6}>
                      <div style={{display:"flex", flexDirection:"column", justifyContent:"center",  alignItems: screenWidth <= 764 ? "center": "flex-end"}}>
                        <p style={{...styles.p1, ...styles.p4, ...{color:'white', marginRight:screenWidth <= 764 ? 0 : 30, fontWeight:"bold", cursor:"pointer"}}} onClick={()=> this.props.history.push("/ai")} >Adatvédelmi irányelvek</p>
                      </div>
                  </Col>
                </Row>    

              </Col>
            </Row>    
            </>
            : null }
         
          </Container>    
        </div>
    )
  }
  
  render(){
  
    return this.props.location.pathname === "/registration"  ? null : this.footerComponent()

  }
}



const styles = {
  mainDiv: {display:"flex",  background:"linear-gradient(180deg, #233657 0%, #0C78B5 0%, #155F92 22.22%, #1E466D 56.34%)" },
  subContainer: { paddingRight:0, paddingLeft:0, width:"100%", display:"flex", },
  divider: {width:'100%', height:2, backgroundColor:"rgba(255, 255, 255, 0.5)", marginTop:50, marginBottom:100, marginRight:10},
  dividerVertical: {width:1, height:"100%", backgroundColor:"rgba(255, 255, 255, 0.5)"},
  myfirstRow:{display:"flex", height:144 },
  p1: {color:"white",   padding:0, margin:0,  fontSize:12, letterSpacing:3, },
  p2: {color:"#2686B0", padding:0, margin:0,  fontSize:40, letterSpacing:5,  },
  p3: {paddingBottom:3, color:"#A9B7BC"},
  p4: {fontSize:8, paddingBottom:6}
}


/*====================== 
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{})(Footer));