import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";
import parse from 'html-react-parser';

const html = `
<p style="margin-top: 20px; margin-bottom: 50px; text-align: justify; font-stretch: normal; font-size: 14px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; color: rgb(36, 64, 132);"><span style="font-kerning: none;"><h1 style="margin-bottom:90px">Tartalomjegyz&eacute;k</h1></span></p>
<ol>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong><em>A MESTERPORT&Aacute;L KFT. T&Ouml;BBEK K&Ouml;Z&Ouml;TT AZ ITT FELSOROLT TEV&Eacute;KENYS&Eacute;GEKKEL KAPCSOLATBAN KEZELI SZEM&Eacute;LYES ADATAIDAT &nbsp; &nbsp;</em></strong></span></li>
</ol>
<ul style="margin-left: 12px ">
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>A weboldalunkon t&ouml;rt&eacute;nő v&aacute;s&aacute;rl&aacute;s, &eacute;rt&eacute;kes&iacute;t&eacute;s &eacute;s az ehhez kapcsol&oacute;d&oacute; szolg&aacute;ltat&aacute;sok &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>H&iacute;rleveleinkkel kapcsolatos adatkezel&eacute;s &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>&Uuml;gyf&eacute;lszolg&aacute;lathoz &eacute;s panaszkezel&eacute;shez kapcsol&oacute;d&oacute; adatkezel&eacute;sek &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>Profilalkot&aacute;s &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>S&uuml;ti kezel&eacute;s &nbsp; &nbsp;</strong></span></li>
</ul>
<ol start="2">
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong><em>MILYEN JOGAIM VANNAK A SZEM&Eacute;LYES ADATOK KEZEL&Eacute;S&Eacute;VEL, FELDOLGOZ&Aacute;S&Aacute;VAL KAPCSOLATBAN? &nbsp; &nbsp;</em></strong></span></li>
</ol>
<ul style="margin-left: 12px ">
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>Helyesb&iacute;t&eacute;s joga &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>T&ouml;rl&eacute;shez vagy m&aacute;s n&eacute;ven elfeledtet&eacute;shez val&oacute; jog &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>Adathordozhat&oacute;s&aacute;ghoz val&oacute; jog: &nbsp; &nbsp;</strong></span></li>
    <li style="margin-top: 0px; margin-right: 0px; margin-bottom: 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong>Visszavon&aacute;s joga &nbsp; &nbsp;</strong></span></li>
</ul>
<ol start="3">
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong><em>ELJ&Aacute;R&Aacute;S A FENTEBB ISMERTETETT JOGAIDNAK GYAKORL&Aacute;S&Aacute;VAL KAPCSOLATBAN &nbsp; &nbsp;</em></strong></span></li>
</ol>
<ol start="5">
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong><em>ADATFELDOLGOZ&Oacute;K &nbsp; &nbsp;</em></strong></span></li>
</ol>
<ol start="6">
    <li style="margin: 0px; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;"><strong><em>T&Aacute;J&Eacute;KOZTAT&Aacute;S &nbsp; &nbsp;</em></strong></span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A Mesterport&aacute;l Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g az mesterport&aacute;l.com &eacute;s mesterportal.hu tulajdonosa &eacute;s &uuml;zemeltetője, tov&aacute;bbiakban: Mesterport&aacute;l. Az Mesterport&aacute;l biztos&iacute;tja szem&eacute;lyes adataid v&eacute;delm&eacute;t. Szem&eacute;lyes adataid megad&aacute;sa n&eacute;lk&uuml;l&ouml;zhetetlen ahhoz, hogy a leadott megrendel&eacute;seid teljes&uuml;lni tudjanak vagy felt&ouml;lt&ouml;tt szolg&aacute;ltat&aacute;said &eacute;rt&eacute;kes&iacute;thetőek legyenek. Az Mesterport&aacute;l csak azokat az adataidat kezeli, amelyek sz&uuml;ks&eacute;gesek ahhoz, hogy a legjobb szolg&aacute;ltat&aacute;st ny&uacute;jtsuk, illetve, amely kezel&eacute;s&eacute;hez hozz&aacute;j&aacute;rulsz. &nbsp;Ezen az inform&aacute;ci&oacute;s oldalon &ouml;sszegyűjt&ouml;tt&uuml;k a legfontosabb tudnival&oacute;kat, amelyek alapj&aacute;n meggyőződhetsz arr&oacute;l, hogy milyen form&aacute;ban kezelj&uuml;k a szem&eacute;lyes adataidat. Kiemelj&uuml;k azonban, hogy ez az inform&aacute;ci&oacute;s oldal nem helyettes&iacute;ti az Mesterport&aacute;l adatkezel&eacute;sekre vonatkoz&oacute; hivatalos&nbsp;</span><span style="font-kerning: none; background-color: #ffffff;"><a href="/at">Adatv&eacute;delmi T&aacute;j&eacute;koztat&oacute;</a></span><span style="font-kerning: none;">j&aacute;t. Az adatkezel&eacute;s teljess&eacute;g&eacute;ről a t&aacute;j&eacute;koztat&oacute; ny&uacute;jt teljes k&ouml;rű t&aacute;j&eacute;koztat&aacute;st, k&eacute;r&uuml;nk, ezt vedd figyelembe jelen t&aacute;j&eacute;koztat&oacute; olvas&aacute;sa k&ouml;zben.</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A t&aacute;j&eacute;koztat&oacute; &eacute;rtelmez&eacute;s&eacute;hez fontos, hogy tudjad, mi sz&aacute;m&iacute;t szem&eacute;lyes adatnak. Szem&eacute;lyes adatnak minős&uuml;l a R&aacute;d, mint term&eacute;szetes szem&eacute;lyre vonatkoz&oacute; b&aacute;rmely inform&aacute;ci&oacute;, amely alapj&aacute;n azonos&iacute;tott vagy azonos&iacute;that&oacute; vagy mint term&eacute;szetes szem&eacute;ly. Ilyen szem&eacute;lyes adatnak minős&uuml;l t&ouml;bbek k&ouml;z&ouml;tt az neved, telefonsz&aacute;mod, sz&uuml;let&eacute;si d&aacute;tumod.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 14px;"><br></p>
<ol>
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A MESTERPORT&Aacute;L KFT. T&Ouml;BBEK K&Ouml;Z&Ouml;TT AZ ITT FELSOROLT TEV&Eacute;KENYS&Eacute;GEKKEL KAPCSOLATBAN KEZELI SZEM&Eacute;LYES ADATAIDAT</span></li>
</ol>
<p style="margin: 0px 0px 2px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">&nbsp;A weboldalunkon t&ouml;rt&eacute;nő v&aacute;s&aacute;rl&aacute;s, &eacute;rt&eacute;kes&iacute;t&eacute;s &eacute;s az ehhez kapcsol&oacute;d&oacute; szolg&aacute;ltat&aacute;sok</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Itt t&ouml;bbek k&ouml;z&ouml;tt a regisztr&aacute;ci&oacute;val kapcsolatos adataidat t&aacute;roljuk. Ez lehetőv&eacute; teszi, hogy v&aacute;s&aacute;rolni (szolg&aacute;ltat&aacute;st ig&eacute;nybe venni) &eacute;s/vagy &eacute;rt&eacute;kes&iacute;teni tudj az Mesterport&aacute;l fel&uuml;let&eacute;n, illetve, hogy meg tudjunk T&eacute;ged k&uuml;l&ouml;nb&ouml;ztetni a t&ouml;bbi felhaszn&aacute;l&oacute;t&oacute;l. Ezen adataid alapj&aacute;n tudjuk a k&ouml;z&ouml;tt&uuml;nk l&eacute;trej&ouml;tt szerződ&eacute;st teljes&iacute;teni. Az itt megadott adatok szolg&aacute;lnak a kapcsolattart&aacute;sra is, p&eacute;ld&aacute;ul abban az esetben, ha valamilyen inform&aacute;ci&oacute;t szeretn&eacute;nk k&ouml;z&ouml;lni a megrendel&eacute;seddel, term&eacute;k felad&aacute;ssal kapcsolatban.&nbsp;</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none; background-color: #ffffff;">A weboldalunkon t&ouml;rt&eacute;nő szolg&aacute;ltat&aacute;s v&aacute;s&aacute;rl&aacute;ssal &eacute;s szolg&aacute;ltat&aacute;s &aacute;rus&iacute;t&aacute;ssal kapcsolatos r&eacute;szletes inform&aacute;ci&oacute;k az&nbsp;&Aacute;ltal&aacute;nos Felhaszn&aacute;l&aacute;si Felt&eacute;telekben olvashat&oacute;ak.</span></p>
<p style="margin: 0px 0px 2px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">H&iacute;rleveleinkkel kapcsolatos adatkezel&eacute;s</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Fontos sz&aacute;munkra, hogy amennyiben &eacute;rdekesnek tal&aacute;lod, akkor megfelelő aj&aacute;nlatokat &eacute;s hasznos inform&aacute;ci&oacute;kat tudjunk Neked k&uuml;ldeni email &uuml;zenet form&aacute;j&aacute;ban. H&iacute;rlevelet csak abban az esetben k&uuml;ld&uuml;nk, ha a regisztr&aacute;ci&oacute;val egyidejűleg, vagy regisztr&aacute;ci&oacute; n&eacute;lk&uuml;l erre k&uuml;l&ouml;n feliratkozol. Tudnod kell azonban, hogy ezek az e-mailek marketing c&eacute;lt szolg&aacute;lnak &eacute;s az Mesterport&aacute;l aktu&aacute;lis akci&oacute;it illetve haszn&aacute;lati seg&eacute;dleteit tartalmazz&aacute;k.</span></p>
<p style="margin: 0px 0px 2px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">&Uuml;gyf&eacute;lszolg&aacute;lathoz &eacute;s panaszkezel&eacute;shez kapcsol&oacute;d&oacute; adatkezel&eacute;sek</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Az Mesterport&aacute;l &uuml;gyf&eacute;lszolg&aacute;latot &uuml;zemeltet annak &eacute;rdek&eacute;ben, hogy felhaszn&aacute;l&oacute;inkkal tudjuk a kapcsolatot tartani. &Uuml;gyf&eacute;lszolg&aacute;latunkkal a kapcsolatot mind email &uuml;zenetben, mind postai lev&eacute;lben, mind pedig telefonon fel tudod venni. Panaszkezel&eacute;st a v&aacute;s&aacute;rl&aacute;s teljes&iacute;t&eacute;s folyamata alatt tudsz kezdem&eacute;nyezni.&nbsp;</span><span style="font-kerning: none; background-color: #ffffff;">Ezzel kapcsolatos r&eacute;szletes t&aacute;j&eacute;koztat&oacute;nkat az &Aacute;ltal&aacute;nos Felhaszn&aacute;l&aacute;si Felt&eacute;telekben &eacute;rheti el.</span></p>
<p style="margin: 0px 0px 2px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Profilalkot&aacute;s</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Hozz&aacute;j&aacute;rul&aacute;s alapj&aacute;n profilalkot&aacute;st v&eacute;gz&uuml;nk annak &eacute;rdek&eacute;ben, hogy az ig&eacute;nyeidnek, k&iacute;v&aacute;ns&aacute;gaidnak &eacute;s &eacute;rdeklőd&eacute;si k&ouml;r&ouml;dnek megfelelően kialak&iacute;tott aj&aacute;nlatokkal szolg&aacute;lhassunk. Az aj&aacute;nlatokat h&iacute;rlev&eacute;len kereszt&uuml;l juttathatjuk el, e-mailben, vagy a weboldalon jelen&iacute;thetj&uuml;k meg. Profilalkot&aacute;s a szem&eacute;lyes adatok automatiz&aacute;lt kezel&eacute;s&eacute;nek b&aacute;rmely olyan form&aacute;ja, amelynek sor&aacute;n a szem&eacute;lyes adatokat a felhaszn&aacute;l&oacute;hoz fűződő bizonyos szem&eacute;lyes jellemzők &eacute;rt&eacute;kel&eacute;s&eacute;re (pl. szem&eacute;lyes preferenci&aacute;khoz, &eacute;rdeklőd&eacute;shez, eg&eacute;szs&eacute;gi &aacute;llapothoz, viselked&eacute;shez, tart&oacute;zkod&aacute;si helyhez vagy mozg&aacute;shoz kapcsol&oacute;d&oacute; jellemzők) elemz&eacute;s&eacute;re, vagy előrejelz&eacute;s&eacute;re haszn&aacute;ljuk.&nbsp;</span></p>
<p style="margin: 0px 0px 2px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">S&uuml;ti kezel&eacute;s</span></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A k&eacute;nyelmes v&aacute;s&aacute;rl&aacute;si &eacute;lm&eacute;ny &eacute;rdek&eacute;ben s&uuml;tiket haszn&aacute;lunk a tartalom &eacute;s a k&ouml;z&ouml;ss&eacute;gi funkci&oacute;k biztos&iacute;t&aacute;s&aacute;hoz, a weboldal forgalmunk elemz&eacute;s&eacute;hez &eacute;s rekl&aacute;moz&aacute;s c&eacute;lj&aacute;b&oacute;l. A s&uuml;ti kezel&eacute;sre vonatkoz&oacute; t&aacute;j&eacute;koztat&oacute;nkat az &Aacute;ltal&aacute;nos Felhaszn&aacute;l&aacute;si Felt&eacute;telekben olvashatod.&nbsp;</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 14px;"><br></p>
<ol start="2">
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">MILYEN JOGAIM VANNAK A SZEM&Eacute;LYES ADATOK KEZEL&Eacute;S&Eacute;VEL, FELDOLGOZ&Aacute;S&Aacute;VAL KAPCSOLATBAN?</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Nagyon fontos sz&aacute;munkra, hogy megfelelően tudjunk T&eacute;ged t&aacute;j&eacute;koztatni a szem&eacute;lyes adataidr&oacute;l, illetve az is fontos, hogy az ezzel kapcsolatos jogaidat megfelelően tudd gyakorolni. &Ouml;sszeszedt&uuml;k, hogy az adatkezel&eacute;ssel kapcsolatosan milyen jogok illetnek meg T&eacute;ged.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">T&aacute;j&eacute;koztat&aacute;shoz, valamint a kezelt szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;shez val&oacute; jog</span><span style="font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; -webkit-font-kerning: none;">&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Mint szem&eacute;lyes adatok kezel&eacute;s&eacute;ben &eacute;rintett, jogosult vagy arra, hogy visszajelz&eacute;st kapj től&uuml;nk arra vonatkoz&oacute;an, hogy szem&eacute;lyes adataid kezel&eacute;se folyamatban van-e n&aacute;lunk, &eacute;s ha folyamatban van, akkor jogosult vagy arra is, hogy a szem&eacute;lyes adatokhoz &eacute;s az ezzel kapcsolatos inform&aacute;ci&oacute;khoz hozz&aacute;f&eacute;rj. Ilyen inform&aacute;ci&oacute;k t&ouml;bbek k&ouml;z&ouml;tt az &aacute;ltalunk v&eacute;gzett adatkezel&eacute;s c&eacute;lja, a szem&eacute;lyes adatok kateg&oacute;ri&aacute;i, kikkel k&ouml;z&ouml;lj&uuml;k a szem&eacute;lyes adataidat, szem&eacute;lyes adataid t&aacute;rol&aacute;s&aacute;nak tervezett időtartama.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Helyesb&iacute;t&eacute;s joga</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">K&eacute;r&eacute;s eset&eacute;n jogosult vagy arra, hogy a R&aacute;d vonatkoz&oacute; pontatlan szem&eacute;lyes adatokat helyesb&iacute;ts&uuml;k. Ezzel kapcsolatban jogosult vagy arra is, hogy a hi&aacute;nyos szem&eacute;lyes adataidat kieg&eacute;sz&iacute;ts&uuml;k.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">T&ouml;rl&eacute;shez vagy m&aacute;s n&eacute;ven elfeledtet&eacute;shez val&oacute; jog</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben az itt felsorolt indokok valamelyik fenn&aacute;ll, akkor jogosult vagy arra, hogy k&eacute;r&eacute;sedre k&eacute;sedelem n&eacute;lk&uuml;l t&ouml;r&ouml;lj&uuml;k a szem&eacute;lyes adataidat. Ezek az indokok az al&aacute;bbiak:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">a) szem&eacute;lyes adatokra m&aacute;r nincs sz&uuml;ks&eacute;g abb&oacute;l a c&eacute;lb&oacute;l, amelyből azokat gyűjt&ouml;tt&uuml;k vagy m&aacute;s m&oacute;don kezelt&uuml;k;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">b) az &eacute;rintett visszavonja az adatkezel&eacute;s alapj&aacute;t k&eacute;pező hozz&aacute;j&aacute;rul&aacute;s&aacute;t, &eacute;s az adatkezel&eacute;snek nincs m&aacute;s jogalapja;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">c) az &eacute;rintett tiltakozik az adatkezel&eacute;s ellen, &eacute;s nincs elsőbbs&eacute;get &eacute;lvező jogszerű ok az adatkezel&eacute;sre vagy amennyiben az adatkezel&eacute;s k&ouml;zvetlen &uuml;zletszerz&eacute;shez kapcsol&oacute;dna;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">d) a szem&eacute;lyes adatokat jogellenesen kezelt&uuml;k;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">e) a szem&eacute;lyes adatokat az adatkezelőre alkalmazand&oacute; uni&oacute;s vagy tag&aacute;llami jogban elő&iacute;rt jogi k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;s&eacute;hez t&ouml;r&ouml;lni kell;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">f) a szem&eacute;lyes adatok gyűjt&eacute;s&eacute;re inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok k&iacute;n&aacute;l&aacute;s&aacute;val kapcsolatosan ker&uuml;lt sor.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Az adatkezel&eacute;s korl&aacute;toz&aacute;s&aacute;hoz val&oacute; jog</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben az itt felsorolt indokok valamelyike fenn&aacute;ll, akkor jogosult vagy arra, hogy k&eacute;r&eacute;sedre korl&aacute;tozzuk a szem&eacute;lyes adatok kezel&eacute;s&eacute;t. Ezek az indokok az al&aacute;bbiak:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">a) az &eacute;rintett vitatja a szem&eacute;lyes adatok pontoss&aacute;g&aacute;t, ez esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik, amely lehetőv&eacute; teszi, hogy az &eacute;rintett ellenőrizze a szem&eacute;lyes adatok pontoss&aacute;g&aacute;t;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">b) az adatkezel&eacute;s jogellenes, &eacute;s az &eacute;rintett ellenzi az adatok t&ouml;rl&eacute;s&eacute;t, &eacute;s ehelyett k&eacute;ri azok felhaszn&aacute;l&aacute;s&aacute;nak korl&aacute;toz&aacute;s&aacute;t;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">c) az Adatkezelőnek m&aacute;r nincs sz&uuml;ks&eacute;ge a szem&eacute;lyes adatokra adatkezel&eacute;s c&eacute;lj&aacute;b&oacute;l, de az &eacute;rintett ig&eacute;nyli azokat jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez, &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy v&eacute;delm&eacute;hez; vagy</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">d) az &eacute;rintett az Adatkezelő k&ouml;z&eacute;rdeken vagy jogos &eacute;rdeken alapul&oacute; adatkezel&eacute;se kapcs&aacute;n tiltakozott az adatkezel&eacute;s ellen; ez esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik, am&iacute;g meg&aacute;llap&iacute;t&aacute;sra nem ker&uuml;l, hogy az adatkezelő jogos indokai elsőbbs&eacute;get &eacute;lveznek-e az &eacute;rintett jogos indokaival szemben.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben az adatkezel&eacute;s a fentiek alapj&aacute;n korl&aacute;toz&aacute;s al&aacute; esik, az ilyen szem&eacute;lyes adatokat a t&aacute;rol&aacute;s kiv&eacute;tel&eacute;vel csak a Te hozz&aacute;j&aacute;rul&aacute;soddal, vagy jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez, &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy v&eacute;delm&eacute;hez, vagy m&aacute;s term&eacute;szetes vagy jogi szem&eacute;ly jogainak v&eacute;delme &eacute;rdek&eacute;ben, vagy az Uni&oacute;, illetve valamely tag&aacute;llam fontos k&ouml;z&eacute;rdek&eacute;ből lehet kezelni.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Adathordozhat&oacute;s&aacute;ghoz val&oacute; jog:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Jogosult vagy arra is, hogy az &aacute;ltalunk kezelt, R&aacute;d vonatkoz&oacute; szem&eacute;lyes adatokat tagolt, sz&eacute;les k&ouml;rben haszn&aacute;lt, g&eacute;ppel olvashat&oacute; form&aacute;ban megkapd &eacute;s jogosult &nbsp;vagy arra is, hogy ezeket az adatokat egy m&aacute;sik adatkezelőnek tov&aacute;bb&iacute;tsd an&eacute;lk&uuml;l, hogy ezt mi akad&aacute;lyozn&aacute;nk. Erre akkor van lehetős&eacute;ges, ha az adatkezel&eacute;s hozz&aacute;j&aacute;rul&aacute;son, vagy szerződ&eacute;sen alapul &eacute;s az adatkezel&eacute;s automatiz&aacute;lt m&oacute;don t&ouml;rt&eacute;nik.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Ezzel kapcsolatban k&ouml;zvetlen tov&aacute;bb&iacute;t&aacute;sra is van lehetős&eacute;ged.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Tiltakoz&aacute;shoz val&oacute; jog</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Jogosult vagy arra, hogy a saj&aacute;t helyzeteddel kapcsolatos okb&oacute;l, b&aacute;rmikor tiltakozz a szem&eacute;lyes adataid kezel&eacute;se ellen, ha az adatkezel&eacute;s p&eacute;ld&aacute;ul az Mesterport&aacute;l-hoz kapcsol&oacute;d&oacute; jogos &eacute;rdeken alapul. Ezzel kapcsolatban azonban felh&iacute;vjuk a figyelmed arra, hogy amennyiben megtiltod, vagy k&eacute;red az adatkezel&eacute;s megsz&uuml;ntet&eacute;s&eacute;t, akkor fenn&aacute;llhat az az eset is, hogy nem tudunk megfelelően kiszolg&aacute;lni.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 13px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Visszavon&aacute;s joga</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Term&eacute;szetesen jogosult vagy arra is, hogy a hozz&aacute;j&aacute;rul&aacute;sodon alapul&oacute; hozz&aacute;j&aacute;rul&aacute;sod, b&aacute;rmikor visszavond. Ez alapj&aacute;n teh&aacute;t, ha p&eacute;ld&aacute;ul feliratkozt&aacute;l a h&iacute;rlevel&uuml;nkre, akkor b&aacute;rmikor k&eacute;rheted, hogy a tov&aacute;bbiakban ne k&uuml;ldj&uuml;nk Neked h&iacute;rlevelet.</span></p>
<ol start="3">
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">MILYEN L&Eacute;P&Eacute;SEKET TETT&Uuml;NK SZEM&Eacute;LYES ADATAID BIZTONS&Aacute;GA &Eacute;RDEK&Eacute;BEN?</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Az Mesterport&aacute;l korszerű hardver &eacute;s szoftver megold&aacute;sokat alkalmaz annak &eacute;rdek&eacute;ben, hogy az adataid biztons&aacute;gban legyenek. Ezek a rendszerek a technika jelenlegi &aacute;ll&aacute;sa alapj&aacute;n v&eacute;dik az adataidat a jogosulatlan hozz&aacute;f&eacute;r&eacute;stől, a megv&aacute;ltoztat&aacute;st&oacute;l, tov&aacute;bb&iacute;t&aacute;st&oacute;l, nyilv&aacute;noss&aacute;gra hozatalt&oacute;l, t&ouml;rl&eacute;stől illetve a megsemmis&iacute;t&eacute;stől. A szem&eacute;lyes adataidat csak a t&aacute;j&eacute;koztat&oacute;inkban meghat&aacute;rozott adatkezelők illetve munkat&aacute;rsaink ismerhetik meg. A biztons&aacute;g &eacute;rdek&eacute;ben t&ouml;bbek k&ouml;z&ouml;tt tűzfalakat, v&iacute;rus- &eacute;s behatol&aacute;s v&eacute;delmet, illetve jelszavas v&eacute;delmet is alkalmazunk, egyesesetekben pedig titkos&iacute;t&aacute;st alkalmazunk.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px; min-height: 11px;"><br></p>
<ol start="4">
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">ELJ&Aacute;R&Aacute;S A FENTEBB ISMERTETETT JOGAIDNAK GYAKORL&Aacute;S&Aacute;VAL KAPCSOLATBAN</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Az Mesterport&aacute;l indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l, de mindenf&eacute;lek&eacute;ppen a k&eacute;relem be&eacute;rkez&eacute;s&eacute;től sz&aacute;m&iacute;tott egy h&oacute;napon (30 napon) bel&uuml;l t&aacute;j&eacute;koztat T&eacute;ged a jelen t&aacute;j&eacute;koztat&oacute;ban, illetve a hivatalos t&aacute;j&eacute;koztat&oacute;kban megtal&aacute;lhat&oacute; jogok gyakorl&aacute;s&aacute;val kapcsolatos k&eacute;relmednek nyom&aacute;n megtett int&eacute;zked&eacute;sről. Sz&uuml;ks&eacute;g eset&eacute;n, amennyiben a k&eacute;relmed t&uacute;l &ouml;sszetett, illetve nagyon sok k&eacute;relem &eacute;rkezik egyszerre, akkor ez a hat&aacute;ridő tov&aacute;bbi k&eacute;t h&oacute;nappal meghosszabb&iacute;that&oacute;.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Ezen inform&aacute;ci&oacute;kat, illetve a t&aacute;j&eacute;koztat&aacute;sokat d&iacute;jmentesen biztos&iacute;tjuk sz&aacute;modra.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A rendszerben t&aacute;rolt szem&eacute;lyes adataidat &eacute;s az adatkezel&eacute;ssel kapcsolatos b&aacute;rmely k&eacute;rd&eacute;sedet, k&eacute;r&eacute;sedet k&eacute;rj&uuml;k, juttassd el az info@Mesterport&aacute;l.com e-mail c&iacute;mre, illetve &iacute;r&aacute;sban a 1221 Budapest, Tompa u. 8. postac&iacute;m&uuml;nkre. K&eacute;rj&uuml;k, tartsd szem előtt, hogy szem&eacute;lyes adataidnak kezel&eacute;s&eacute;vel kapcsolatban &ndash; az Te &eacute;rdek&eacute;ben - csak abban az esetben &aacute;ll m&oacute;dunkban felvil&aacute;gos&iacute;t&aacute;st adni, illetve int&eacute;zked&eacute;st tenni, ha szem&eacute;lyazonoss&aacute;godat hitelt &eacute;rdemlően igazoltad.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben a jogaidat szeretn&eacute;d gyakorolni, k&eacute;rj&uuml;k, t&ouml;ltsd le &nbsp;</span>
<a href="/kaj.pdf" download><span style="font-kerning: none; background-color: #ffffff;">innét </span></a>
<span style="font-kerning: none;"> a &bdquo;K&eacute;relem adatv&eacute;delemi joggyakorl&aacute;shoz&rdquo; elnevez&eacute;sű dokumentumot &eacute;s al&aacute;&iacute;rva juttasd el hozz&aacute;nk a fent megadott postac&iacute;mre vagy pedig elektronikus &uacute;ton az&nbsp;</span><span style="font-kerning: none; background-color: #ffffff;"><a href="mailto:info@mesterportal.com">info@mesterportal.com</a></span><span style="font-kerning: none;">&nbsp;email c&iacute;mre.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">A k&eacute;relmed megv&aacute;laszol&aacute;s&aacute;hoz minden esetben sz&uuml;ks&eacute;g&uuml;nk van az al&aacute;bbi inform&aacute;ci&oacute;kra:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">regisztr&aacute;ci&oacute;kor megadott email c&iacute;m</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">teljes n&eacute;v</span></li>
</ul>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben e-mailben juttatod el hozz&aacute;nk k&eacute;relmedet, &uuml;gyelj arra, hogy a regisztr&aacute;ci&oacute;kor megadott email c&iacute;mről k&uuml;ldd el a megkeres&eacute;st.</span></p>
<ol start="5">
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">ADATFELDOLGOZ&Oacute;K</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Szem&eacute;lyes adataidat term&eacute;szetesen kiemelten kezelj&uuml;k. Fontos tudnod azonban, hogy a szerződ&eacute;s teljes&iacute;t&eacute;se &eacute;rdek&eacute;ben a hat&aacute;lyos jogszab&aacute;lyoknak megfelelően, illetve hozz&aacute;j&aacute;rul&aacute;sod alapj&aacute;n Adatfeldolgoz&oacute;kat vesz&uuml;nk ig&eacute;nybe. Ilyen Adatfeldolgoz&oacute;k t&ouml;bbek k&ouml;z&ouml;tt, de nem kiz&aacute;r&oacute;lagosan:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">term&eacute;k gy&aacute;rt&oacute;k, forgalmaz&oacute;k, akik &eacute;rt&eacute;kes&iacute;tenek az oldalon</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">szolg&aacute;ltat&oacute;k, az oldalon kereszt&uuml;l hirdetik munk&aacute;ikat</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Mesterport&aacute;l munkav&aacute;llal&oacute;i</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">a fizet&eacute;si megold&aacute;st szolg&aacute;ltat&oacute;k</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Az adatfeldolgoz&oacute;k &ouml;n&aacute;ll&oacute; d&ouml;nt&eacute;seket nem hoznak &eacute;s folyamatosan ellenőrizz&uuml;k őket, annak &eacute;rdek&eacute;ben, hogy szem&eacute;lyes adataid biztons&aacute;gban legyenek.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">K&uuml;l&ouml;n kiemelj&uuml;k, hogy a szem&eacute;lyes adataidat soha nem adjuk el, azokkal nem keresked&uuml;nk.</span></p>
<ol start="6">
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">T&Aacute;J&Eacute;KOZTAT&Aacute;S</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Amennyiben k&eacute;rd&eacute;sed mer&uuml;l fel a jelen t&aacute;j&eacute;koztat&oacute;val kapcsolatban, k&eacute;rj&uuml;k, &iacute;rd meg nek&uuml;nk az&nbsp;</span><span style="font-kerning: none; background-color: #ffffff;"><a href="mailto:info@mesterportal.com">info@Mesterportal.com</a></span><span style="font-kerning: none;">&nbsp;e-mail c&iacute;mre &eacute;s koll&eacute;g&aacute;nk v&aacute;laszol a k&eacute;rd&eacute;seidre. A jelen Adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; &eacute;s annak mindenkori m&oacute;dos&iacute;t&aacute;sai megtal&aacute;lhat&oacute;ak a&nbsp;</span><span style="font-kerning: none; background-color: #ffffff;"><a href="/at">https://mesterport&aacute;l.com/at</a></span><span style="font-kerning: none;">&nbsp;c&iacute;men.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Maven Pro; letter-spacing: 3px;"><span style="font-kerning: none;">Adatkezelő &eacute;s el&eacute;rhetős&eacute;gei: Mesterport&aacute;l Kft. (sz&eacute;khely: <strong>8623 Balatonf&ouml;ldv&aacute;r, Kil&aacute;t&oacute; k&ouml;z 5.</strong>, c&eacute;gjegyz&eacute;ksz&aacute;m: <strong>14-09-316271</strong>, e-mail: <a href="mailto:info@mesterportal.com">info@mesterportal.com</a>, www.mesterportal.com);</span></p>
`



 class Afsz extends Component  {
  
 

    render(){
      const window_width =  this.props.systemState.dimension.width;
      return (
        <div style={{...styles.outerBG}}>

        <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
          <div>ADATVÉDELMI IRÁNYELVEK</div>
        </div>
       
            {/* LIST */}
            <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        

              <div style={{padding:60}}>
               
              {parse(html)}
                  
              </div>

          </Container>

        <div style={{...styles.bottomSpacer}}/>

      </div>
      );
  
    }
  }

  const styles = {
    div_container : {display:"flex", flexDirection:"column", flexWrap:"wrap"},
    headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
    bottomSpacer: {height:30, width:"100%"},
    outerBG: {background:"#F6F9FE"},
    title_p: {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"bold", color:"#0C78B5", marginTop:20, marginBottom:20 },
    body_p:  {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"400",  color:"#4F4F4F" },

    
  }
  



/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState} = state;
  return {systemState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Afsz);
