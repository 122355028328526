import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
import {setUserAction} from '../../../Model/Action';
import {withRouter} from 'react-router-dom';
import ImageUpload from "../../../View/ImageUpload";
import Map from "../../../View/Map";
import {request} from '../../../Model/Request';
import {Container, Modal, Button} from "react-bootstrap";
import ReactQuill from 'react-quill'; 

import telepulesek from '../../../Model/telepules'
//styles
//import './registerUser.css';
import 'react-quill/dist/quill.snow.css';
import cover from "./Assets/empty.png";
import addBTN from "./Assets/add.png";



import 'ssuggestor/dist/styles.css';
import b3Theme from 'ssuggestor/dist/bootstrap-3.json';
import Suggestor from 'ssuggestor';




 class RegisterDataSheet extends Component  {

  state = {
    //telepulesek:[],
   /*  images:{
      0:{img:null, name:0},
      1:{img:null, name:1},
      2:{img:null, name:2}
    }, */
    images:[
      {img:null, name:0},
      {img:null, name:1},
      {img:null, name:2},
    ],
    imageIndexCounter:2,
    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }],
    text: '',
    isModalShow:false,
    errorMessage:'',

  };

  _isMounted = false;
  

  componentDidMount(){

    this._isMounted = true;
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;

    this.setState({telepulesek:telepulesek.telepulesek});

    /// ha van leírás, rakja hozzá
    if( typeof this.props.userState.user.incompleteRegistration.stepThree.details.company.details !== "undefined" ){
      this.setState({text:this.props.userState.user.incompleteRegistration.stepThree.details.company.details})
    }

     /// ha van geo adat, rakja hozzá
    if( typeof this.props.userState.user.incompleteRegistration.stepThree.details.company.location !== "undefined" &&
        this.props.userState.user.incompleteRegistration.stepThree.details.company.location.length > 0 ){
      console.log(this.props.userState.user.incompleteRegistration.stepThree.details.company.location)
              this.setState({data:this.props.userState.user.incompleteRegistration.stepThree.details.company.location.map( (item, index) => {
                return {
                  id: index,
                  name: item.city,
                  zip:item.zip_code,
                  latitude: item.lat,
                  longitude: item.lng,
                  circle : {
                    radius: item.distance,
                    options: {
                      strokeColor: "#217BA7",
                      fillColor:"#217BA7"
                    }
                  }
                }
              })})            

      }

      /// ha van kép, rakja hozzá
      if( typeof this.props.userState.user.incompleteRegistration.stepThree.details.company.images !== "undefined" &&
          this.props.userState.user.incompleteRegistration.stepThree.details.company.images.length > 0 ){

           this.setState({images: this.props.userState.user.incompleteRegistration.stepThree.details.company.images.map( (item, index) =>  { return { img: item, name:index }  } ) })  

      }
   
                

  }

  componentDidUpdate(prevProps, prevState){

    if(this.state.text !== prevState.text){

      const value = this.state.text.replace(/<(.|\n)*?>/g, '').trim().length
  
      switch (true) {

        case value === 0:
          document.querySelector('.ql-editor').style.background = "rgba(227,6,6,0.15)";
          break;

        case (value > 0 && value < 5):
          document.querySelector('.ql-editor').style.background = "rgba(253,217,166,0.26)";
          break;

        case value > 5:
          document.querySelector('.ql-editor').style.background = "rgba(38,134,176,0.26)";
          break;
      
        default:
          break;
      }  

    }     

  }
 
  componentWillUnmount(){
    this._isMounted = false
  }

  /// validation Timeout for search
  validationTimeout;

  handleChange = (value) => {
    this.setState({ text: value })
  };

  setInput = (e) => {

        const {id} = e.target;

        let {value} = e.target;
       
        clearTimeout(this.validationTimeout);
        
        

        this.setState(state=>{
          const newData = state.data.map((item, index) => { if(index == id){  return {...item, name:value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() } } else {return {...item}} }); 
          return {data:newData}
        })

          

          this.validationTimeout = setTimeout(()=>{ 
            
            const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())
            if( settlement.length > 0 ){
              document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(38,134,176,0.26)";
              this.setState(state=>{ 
                  const newData2 = state.data.map((item, index)=> { if(index == id){  return { ...item, longitude:settlement[0].Long, latitude:settlement[0].Lat, zip:settlement[0]["Ir. szám"] }  }else{ return { ...item } } } );
                  return {data:newData2}
                })
            }
            else{
               
                this.setState({ isModalShow:true, errorMessage:`A következő település nem található: ${value}` })
                document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(227,6,6,0.15)";
                this.setState(state=>{ 
                  const newData2 = state.data.map((item, index)=> { if(index == id){ return {  ...item, name : "" } }else{ return { ...item } } } );
                  return {data:newData2}
                })
            } 
        },2000)

          
          /*      this.validationTimeout = setTimeout(()=>{ 
                  const settlement = telepulesek.telepulesek.filter( item => item.name === value)
                  if( settlement.length > 0 ){
                    console.log(settlement[0].Lat)
                    console.log(settlement[0].Long)
                    this.setState(state=>{ const newData2 = state.data.map((item, index)=> { if(index == id){  item.longitude=settlement[0].Long; item.latitude=settlement[0].Lat   ; return item }else{ return item} } );
                        return {...state, data:newData2}
                      })
                  }
                  else{
                      alert(`A következő település nem található: ${value} `);
                      this.setState(state=>{ const newData2 = state.data.map((item, index)=> { if(index == id){  item.name=""; return item }else{ return item} } );
                        return {...state, data:newData2}
                      })
                  } 
              },2000) */
  }



  imageToState = ({base64data, index}) => {



    this.setState(state=>{
  
        return {  images:state.images.map(item=> { 
                   if(item.name === index) return {...item, img:base64data}
                   else return {...item}
                  })}
                  
                });   
  };

  addItemToImages = () => {
    this.setState(state=>{
      
      const newImages = [...state.images,{img:null, name: this.state.imageIndexCounter + 1}]
     // const newImages =  {...state.images, [this.state.imageIndexCounter]:{img:null}}
   // const newImages =  {...state.images, [Object.entries(state.images).length]:{img:null}}
        return {images:newImages, imageIndexCounter:this.state.imageIndexCounter + 1}
    }); 
  };





  removeItemsFromImages = (name) => {
    //const newFilteredImages =  Object.entries({...this.state.images}).filter(([item]) =>  item != index[0]);
   // const newFilteredImages =  Object.entries({...this.state.images}).filter(([item]) =>   item != index[0] );



    //const itemIndex = index[0]
    
    //const { [this.state.images[itemIndex]]: deleted, ...objectWithoutDeletedProp } = this.state.images;
    //console.log(objectWithoutDeletedProp)

   /*  let newObj = { ...this.state.images};
    delete newObj[index];
    */
   
    
    
    this.setState({images:this.state.images.filter(item => item.name !== name)})  
  };




  addItemToData = () => this.setState(state=>{ const newData = [...state.data,{id: this.state.data.length , name: "",latitude: "0",longitude: "0", circle : { radius: 0, options: { strokeColor: "#217BA7", fillColor:"#217BA7" }}}]; return  {data:newData} } );

  removeFromData = (id) =>  this.setState(state=>{ const newData = state.data.filter(item => item.id !== id); return  {data:newData} } )

  getPlace = async (address) => {
      const response = request({baseURL:"https://maps.googleapis.com/maps/api/geocode/json?address="+address+"&sensor=false&key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI", method:"POST"})
      console.log(response)
  };

  setRadius = (index, e) => {
    let {value} = e.target;
    if(value === "" || isNaN(value) ) value = 0;
    this.setState(state=>{
      let newData = [...state.data];
          newData[index].circle.radius = parseInt(value);

          return {data:newData}
    })
  };



  inputCompionent = ({index, addRemovebutton=false}) => {
    return(
      <div key={index + "input"} style={{display:"flex", flexWrap:"wrap", flexDirection:"row", alignItems:'center'}}>

          <div style={{margin:15, flexGrow: addRemovebutton ? 3 :1}}>
            <p style={{margin:0, padding:0, fontFamily:"Maven Pro", fontSize:15, fontWeight:"bold", letterSpacing:3, marginBottom:12}}>TELEPÜLÉS</p>
            <input style={{fontFamily:"Maven Pro", fontSize:15, fontWeight:"bold",borderRadius:4, borderWidth:1, BorderColor:'black', width:"100%",  padding:7}} id={index} uniqe={"settlement"+index} onChange={(e)=>this.setInput(e)} value={this.state.data[index].name} />
          </div>

          <div style={{margin:15, flexGrow:1}}>
            <p style={{margin:0, padding:0, fontFamily:"Maven Pro", fontSize:15, fontWeight:"bold", letterSpacing:3, marginBottom:12}}>KÖRZET (KM)</p>
            <input style={{fontFamily:"Maven Pro", fontSize:15, fontWeight:"bold", borderRadius:4, borderWidth:1, BorderColor:'black', width:"100%", padding:7}} onChange={(e)=>this.setRadius(index, e)} value={this.state.data[index].circle.radius} />
          </div>

          {addRemovebutton ? <div style={{ flexGrow:1, marginTop:10, marginLeft:15}}><div onClick={()=>this.removeFromData(index)} style={{cursor: "pointer", fontFamily:"MONDA", fontSize:11, fontWeight:'bold'}} >törlés</div></div> : null}

      </div>
    )
  };

  saveButton = async () => {

    document.querySelector("#errorText").innerHTML = "";

    let stop = false;
    /// error message
    let description = ""

    /// üres-e a leírás
    if(this.state.text.replace(/<(.|\n)*?>/g, '').trim().length < 5 ){

      description = "A BEMUTATKOZÁS mező kitöltése kötelező!";
      document.querySelector('.ql-editor').style.background = "rgba(227,6,6,0.15)";
      document.querySelector("#errorText").innerHTML = description;
      return
    }

    /// vannak-e location adfatok
    this.state.data.map( item => {
      
      if(item.name === ""){
        stop = true
        description = this.state.data.length > 1 ? "Minden Település mező kitöltése kötelező!" : "Legalább egy Település mező kitöltése kötelező";
        document.querySelector(`[uniqe=settlement${item.id}]`).style.background = "rgba(227,6,6,0.15)";
        return
        
      }

    });


    if(stop === true){
      document.querySelector("#errorText").innerHTML = description;
      return
    }

    
    if(stop === false){
      document.querySelector("#errorText").innerHTML = ""
    }
  
      
  

  
      /* ========================================= */
      /* SEND LOCCATION DATA */
      /* ========================================= */

          const locationData = this.state.data.map( item => {

            return  { 
                      type:"area",
                      locationable: { 
                        type:"company", 
                        id:this.props.userState.user.incompleteRegistration.stepTwo.details.company.id 
                      },
                      lat : item.latitude,
                      lng : item.longitude,
                      distance : item.circle.radius,
                      country_code: "hu",
                      zip_code: item.zip,
                      country: null,
                      county:  null,
                      city: item.name,
                      street: null,
                      house_number: null,
                      extra:{
                        floor : null,
                        door : null
                    },
                    formatted_address: null
                  }
           })

           /// getSaved Data
           const getLocationResponse = await request({ url:`/company/profile/geo?id=${this.props.userState.user.incompleteRegistration.stepTwo.details.company.id}`, method:"GET" });
              
           if(typeof getLocationResponse.data !== "undefined" &&  getLocationResponse.data.length > 0){

                for (let index = 0; index < getLocationResponse.data.length; index++) {
                    const item = getLocationResponse.data[index];
                    if(item.type === "area"){
                      await request({ 
                        url:`/company/profile/geo/delete`, 
                        method:"DELETE", 
                        data:{  
                            company_id:this.props.userState.user.incompleteRegistration.stepTwo.details.company.id, 
                            location_id:item.id
                        } 
                      })
                     
                    }
                }

           }else{
            document.querySelector("#errorText").innerHTML = "Geolokációs adatok mentése sikertelen. - Hiba a szerverrel való kommunikáció során.";
            return
           }
           



          const locationResponse = await request({ url:"/location", data:locationData, method:"POST" });
                
          if(typeof locationResponse.message !== "undefined" && locationResponse.message === "Store successful!"){

            let user = {...this.props.userState.user};
                user.incompleteRegistration.stepThree.details.company = {...user.incompleteRegistration.stepThree.details.company, location:[...locationData]}
                this.props.setUserAction({...user})
              
          }else{
            document.querySelector("#errorText").innerHTML = "Geolokációs adatok mentése sikertelen. - Hiba a szerverrel való kommunikáció során.";
            return
          }

      /* ========================================= */
      /* SEND DESCRIPTION DATA */
      /* ========================================= */

          const companyDescriptionData = {
            company_id:this.props.userState.user.incompleteRegistration.stepTwo.details.company.id,
            description: this.state.text
          }

          const descriptionResponse = await request({url:"/auth/register/step-three", data:companyDescriptionData, method:"POST"});

          if(typeof descriptionResponse.message !== "undefined" && descriptionResponse.message === "Store success!" ){

            let user = {...this.props.userState.user};
                user.incompleteRegistration.stepThree.details.company = {...user.incompleteRegistration.stepThree.details.company, details:this.state.text}
                this.props.setUserAction({...user})

          }else{
            document.querySelector("#errorText").innerHTML = "A bemutatkozás letárolása sikertelen. - Hiba a szerverrel való kommunikáció során.";
            return
          }

      /* ========================================= */
      /* SEND IMAGES DATA */
      /* ========================================= */
                
        const filteredImages = this.state.images.filter(item=> item.img !==  null);

        if(filteredImages.length > 0){
            
              /// delete old images 
              let imageResponse = await request({url:`/company/profile/picture?id=${this.props.userState.user.incompleteRegistration.stepTwo.details.company.id}&type=basic`, method:"GET"});

              imageResponse.data.basic = imageResponse.data.basic.filter(item => item !== null)
           
              if(typeof imageResponse.data !== "undefined" && typeof imageResponse.data.basic !== "undefined" &&  imageResponse.data.basic.length > 0){

                for (let index = 0; index < imageResponse.data.basic.length; index++) {
                    const item = imageResponse.data.basic[index];
                  
                    if(item.type === "basic"){
                      await request({ 
                        url:`/company/profile/picture/delete`, 
                        method:"DELETE", 
                        data:{  
                            company_id:this.props.userState.user.incompleteRegistration.stepTwo.details.company.id, 
                            image_id:item.id
                        } 
                      })
                     
                    }
                }

           }

   

           

              for (let index = 0; index < filteredImages.length; index++) {

                    const item = filteredImages[index];
                    
                    const data = {
                      upload_to:{
                        type: "company",
                        id: this.props.userState.user.incompleteRegistration.stepTwo.details.company.id
                      },
                      img: {
                        type: "basic",
                        mime:item.img.split(";")[0].replace("data:image/",""),
                        src: item.img
                      }
                    };

                    console.log(data)

            

                    const response = await request({url:"/image", method:"post", data});
                    
                    if(typeof response.message !== "undefined" && response.message === "Image upload successful!" ){

                        console.log("kép feltöltve: " + filteredImages.length + " / " + (index + 1))

                        let user = {...this.props.userState.user};
                            // user.incompleteRegistration.stepThree.details.company.images = [];
                            user.incompleteRegistration.stepThree.details.company.images[index] = filteredImages[index].img
                            this.props.setUserAction({...user})

                    }else{
                      document.querySelector("#errorText").innerHTML = "A bemutatkozás mentése sikertelen. - Hiba a szerverrel való kommunikáció során.";
                      return
                    }
                
              }

          

       

              let user = {...this.props.userState.user};
              user.incompleteRegistration.stepThree.complete = true
              this.props.setUserAction({...user})
              this.props.doneFunction({level:2})
       

              document.body.scrollTop = 0; 
              document.documentElement.scrollTop = 0;
        }else{

          let user = {...this.props.userState.user};
          user.incompleteRegistration.stepThree.complete = true
          this.props.setUserAction({...user})
          this.props.doneFunction({level:2})
          document.body.scrollTop = 0; 
          document.documentElement.scrollTop = 0;

        }

        
  





  /*   [
      {
        "locationable": {
          "type": "user",
          "id": 1 			
        },
        "lat" : 47.43331,
        "lng" : 19.03392,
        "distance" : null,      /// ===!!!!
        "country_code": "hu",
        "zip_code": "1221",    /// ===!!!!
        "country": null,
        "county": null,
        "city": "Budapest",    /// ===!!!!
        "street": null,
        "house_number": null,
        "extra":{
            "floor" : null,
            "door" : null
        },
        "formatted_address": null
      } 
    ]
    */
    //const textResponse = await request({url:"", })
   

  /*   Object.values(this.state.images).map( (item, index) => {

      const data = { "upload_to":{ "type": "company", "id": 1 }, "img": { "type": "basic", "src": this.state.image } };
      request({ method:"post", url:"/image", data })

    })  */
    /// if the server response is OK
    ///
    //this.props.doneFunction({level:2})

  };

  render(){
  console.log(this.state)


 
    const screenWidth = this.props.systemState.dimension.width;
    

    return (
  
        <div> 
              <Container style={{backgroundColor:"white", padding:50, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", marginBottom:30 }}>

        <Modal show={this.state.isModalShow} onHide={ ()=> this.setState({isModalShow:false, errorMessage:""}) }>
          <Modal.Header>
            <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{...styles.p}}>{this.state.errorMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={ ()=> this.setState({isModalShow:false, errorMessage:""}) }>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
            

                <p style={{...styles.p, fontSize:20, fontWeight:"bold"}}>BEMUTATKOZÁS</p>
                <div style={{...styles.divider, marginBottom:30}} />
                
                <ReactQuill  placeholder= "Bemutatkozó szöveg helye..." value={this.state.text} onChange={(value)=>this.handleChange(value)} style={{height:300, marginBottom:80}}  />

              
                <p style={{...styles.p, fontSize:20, fontWeight:"bold", marginTop:30}}>KÖRZET</p>
                <div style={{...styles.divider, marginBottom:30}} />

                <div style={{display:"flex", flexDirection: screenWidth < 550 ? "column" : "row", alignItems : screenWidth < 550 ? "center" : "flex-start" }}>

                    <div style={{flexGrow:2, width: screenWidth < 550 ? "100%" : "65%"}}>

                   {this.inputCompionent({index:0})} 
                  

                    { this.state.data.map( (item, index) =>  index > 0 ? this.inputCompionent({index:index, addRemovebutton:true}): null  ) } 
                    <div onClick={()=>this.addItemToData()} style={{fontFamily:"MONDA", fontSize:12, marginTop:15, marginLeft:15, color:"#4F4F4F", cursor: "pointer"}}>TOVÁBBI KÖRZET HOZZÁADÁSA</div>
                  </div>

                  <div style={{flexGrow:1, width: screenWidth < 550 ? "100%" : "35%"}}>
                    <Map
                      center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} /// lat: 47.497913, lng: 19.040236
                      zoom={this.state.data.length < 2 ? 9 : 7}
                      places={this.state.data}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                      loadingElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%`, filter:"grayscale(0.9)" }} />}
                      containerElement={<div style={{ height:'300px', width:'100%' }} />}
                    /> 
                  </div>

                </div>

                <p style={{...styles.p, fontSize:20, fontWeight:"bold", marginTop:30}}>FÉNYKÉPEK</p>
                <div style={{...styles.divider, marginBottom:30}} />

                <div style={{display:"flex", flexWrap:"wrap", justifyContent:screenWidth < 550 ? "center" : null}}>



                  
                  {
                    
                    this.state.images.map( (item, index)=> 
                    <div key={index + "img"} style={{margin:10}}>
                      
                      {
                        item.img === null && this._isMounted
                          ? <ImageUpload index={item.name} imageToState={this.imageToState} cover={cover} /> 
                          : <div style={{...styles.imgDIV, backgroundImage:`url(${item.img})`}} />
                        /*   <img style={{height:200, width:200}} src={item.img} /> */
                      } 
                
                      
                      
                      
                        <div onClick={()=>this.removeItemsFromImages(item.name)} style={{position:"relative", top:-220, left:150, backgroundColor:'red', width:70, padding:2, borderRadius:6, cursor: "pointer"}} >
                            <div style={{display:"flex", justifyContent:'center', color:'white', fontSize:12, fontFamily:"MONDA"}}>TÖRLÉS</div>
                        </div> 
                     
                      
                     </div> )

                    }






                  <div style={{padding:50, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}} onClick={()=>this.addItemToImages()}>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>TOVÁBBI</div>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>KÉPEK</div>
                    <div style={{fontSize:12, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
                    <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
                  </div>
                </div>
                <div className="d-flex row w-100 errorPadding">
                    <div className="w-100">
                        <div className="hdivideretop"></div> 
                        <div className="errorText" id="errorText"></div>
                        {/* <div className="hdividerebottom"></div> */}
                    </div>
                </div>   
                
                <div className="d-flex row justify-content-center justify-content-lg-end ">
                    <div className="buttons">
                            <button className="backButton" onClick={()=> this.props.goBack()} >Vissza</button> 
                            <button className="registerButton" onClick={()=> this.saveButton()}>Mentés</button>
                    </div>                
                </div> 
              </Container>
         </div>
     
    );

  }
}

const styles = {
  divider:  {height:1, widht:"100%", backgroundColor:"#4F4F4F"},
  p:        {fontSize:12, fontFamily:"Maven Pro", letterSpacing:2},
  imgDIV :  { 
              backgroundSize:"cover",
              objectFitt:"cover",
              backgroundRepeat:"no-repeat",
              backgroundColor:"white",
              backgroundPosition:"center", 
              width:210, 
              height:210,
              borderRadius:6,
              boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
            }
 }

/*====================== 
* State to Props
=====================*/
 const mapStateToProps = state => {
    const {systemState, userState, tokenState} = state;
    return {systemState, userState, tokenState};
};
  
/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
  setUserAction
})(RegisterDataSheet));
