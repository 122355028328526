import React, {Component} from 'react';
import {withRouter} from "react-router-dom"
import img_1 from "./Assets/1.png"; 
import img_2 from "./Assets/2.png"; 
import img_3 from "./Assets/3.png"; 
import img_4 from "./Assets/4.png"; 
import img_5 from "./Assets/5.png"; 
import img_6 from "./Assets/6.png";

//styles
import './Offers.css';

const OuterOffers = [
    {
        id:1,
        img:img_1,
        tittle: 'TETŐ ÉPÍTŐ KISOKOS',
        description: 'Tetőt mindenki látott már, de ha meg szeretnénk fogalmazni, hogy pontosan miről is van szó, a laikusok már kicsit bajban lennének.',
        link: ''
    },
    {   
        id:2,
        img:img_2,
        tittle: 'NAPELEMEK FAJTÁI',
        description: 'Számos napelem fajtáról lehet olvasni, hallani. Ennél is többféle napelemet, napkollektort lehet vásárolni, amik a különböző napelem típusoknak a különböző megoldásai. ',
        link: ''
    },
    {
        id:3,
        img:img_3,
        tittle: 'ÍGY TERVEZZÜK MEG A HÁZ KÜLSŐ SZÍNÉT!',
        description:"A ház külső falait egyrészt állagmegóvás okán, másrészt pedig esztétikai okokból festjük be. Főként a ház színe határozza meg azt az első benyomást, amely a látogatót éri megérkezéskor.",
        link: ''
    }
                   
];

const InnerOffers = [
    {
        id:4,
        img:img_4,
        tittle: 'FESTÉS ELŐTTI ZSÍRTALANÍTÁS',
        description: 'Egy szoba kifestése nem nehéz feladat, ennek ellenére vannak olyan részei – főleg az előkészületeknél –, melyeket nem szabad félvállról venni. ',
        link: ''
    },
    {
        id:5,
        img:img_5,
        tittle: 'EZ AZ 5 LEGTIPIKUSABB FESTÉSI HIBA',
        description: 'A legtöbben azt gondoljuk, hogy nincs is egyszerűbb feladat a falfestésnél, ami részben igaz is, de csak akkor, ha odafigyelünk a részletekre.',
        link: ''
    },
    {
        id:6,
        img:img_6,
        tittle: 'PARKETTÁZÁS',
        description: 'A parkettázás megkezdése előtt minden esetben ki kell egyenlíteni az aljzatot. Bármilyen parkettáról van szó, valamint bármilyen lerakási módszerről, az egyenetlen aljzat tönkreteheti rövid idő alatt a parkettát, ezért ezt a munkafázist nem érdemes kihagyni.',
        link: ''
    }               
];

 class Offers extends Component  {




  render(){
 
    if (this.props.viewType=='outer')
    {
        this.offerList = OuterOffers.map
        ( 
            (ret,index)=> 
                { 
                    if (index < this.props.num) 
                        return (
                            <div key={index} className="offers" onClick={ ()=> this.props.history.push(`/servicesmarty/${ret.id}`)}>
                                <div className="offerImg" style= {{backgroundImage: `url(${ret.img})`}} ></div>
                                <div className="offerTittle">{ret.tittle}</div>
                                <div className="offerDescription">{ret.description}</div>
                            </div>
                            ); 
                } 
        );
    }else
    {
        this.offerList = InnerOffers.map
        ( 
            (ret,index)=> 
                { 
                    if (index < this.props.num) 
                        return (
                            <div key={index} className="offers" onClick={ ()=> this.props.history.push(`/servicesmarty/${ret.id}`)}>
                                <div className="offerImg" style= {{backgroundImage: `url(${ret.img})`}} ></div>
                                <div className="offerTittle">{ret.tittle}</div>
                                <div className="offerDescription">{ret.description}</div>
                            </div>
                            ); 
                } 
        );        
    }


    return (
        <>

        <div className="offerTittle" style={{fontSize:17, marginTop:60, display:"flex", justifyContent:"center"}}>CIKKEK ÉRDEKESSÉGEK</div>
        <div className="d-flex w-100 justify-content-center offerPadding flex-wrap">
            {this.offerList}                                                      
         </div>    
      </>

    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default withRouter(Offers);

