import React, {Component} from 'react';
import { Link } from "react-router-dom";
//styles
import './Expert.css';



 class Expert extends Component  {


  render(){
    
    return (
        <div className="d-flex justify-content-center" >
        <div className="row expert">
            <div className="col-lg-6 order-2 order-lg-1 d-flex justify-content-center"><div className="askExpertImg"></div></div>
            <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-lg-start justify-content-center" style={{paddingLeft:'30px'}}>
                <div>
                    <div className="askExpertTittle">Kérdezd a szakit</div>
                    <div className="askDescription">Bizonytalan vagy? Elakadtál ? Felmerültek benned kérdések a mukával kapcsolatban de nincs a közeledben szakértő akihez fordulhatnál. Ne aggódj, mi segítünk! Tedd fel a kérdésed és találunk rá válszat tapaszatalatal rendelkező szakembereink segitségével.</div>
                    <div className="text-lg-left text-center"><Link to={"/search?per_page=10&page=1"} ><button className="askButton" >Írd meg kérésed</button></Link></div>
                </div>
            </div>

        </div>  
        </div>


    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default Expert;

