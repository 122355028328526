import React, {Component} from 'react';

//styles
import './AddPostingSection.css';
//images
import Workdecor from './../../../Assets/workdecor.png'; 

/* BOOTSTRAP */
//import {Container, Col, Row} from 'react-bootstrap';


 class AddPostingSection extends Component  {

    componentDidMount () {
    }

  render(){
    console.log(this.props)
    return (
        
    <div>
        <div className="d-flex w-100 justify-content-center">
            <div className="decorTittle position-relative">
                <div>szeretnéd minél több </div>
                <div>mesterember árajánlatát látni?</div>
            </div>  
        </div>
        <div className="d-flex w-100 justify-content-center mb-5">
            
            <div className="workImage">
                <img className="decorFirstImage" src={Workdecor} alt=""></img>
            </div>
            <div className="workTittle">
                <div>Hirdess</div>
                <div>Meg</div>
                <div>Munkát!</div>
                <div><button>Hirdetés feladása</button></div>
            </div>                
        </div>       
    </div>       
    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default AddPostingSection;

