import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import RegisterCorporationData from './registerCorporationData';
import RegisterDataSheet from "./registerDataSheet";
import RegisterServices from "./registerServices";


 class RegisterProfessional extends Component  {

    state = { level: 0 }

    currentPathname = "/registration/professional";


    componentDidMount(){

        

        /// guard function
        if(typeof this.props.userState.user.incompleteRegistration === "undefined"){
            this.props.history.push("/registration");
        }

        if(this.props.userState.user.incompleteRegistration.stepTwo.complete){
            this.setState({level:1})
        }
         if(this.props.userState.user.incompleteRegistration.stepThree.complete){
            this.setState({level:2})
        } 


        
       
           
         /*  const {stepTwo, stepThree, stepFor} = this.props.userState.user.incompleteRegistration;
          let data = {...this.state.data};
              data.stepTwo   = stepTwo;
              data.stepThree = stepThree;
              data.stepFor   = stepFor;
          let level; 
                if(stepTwo.complete) level = 1
                if(stepThree.complete) level = 2

                this.setState({level,data },()=> console.log(this.state))
                 */

       
        
       /// STUCK IN HISTORY
       const { history } = this.props;
        history.listen((newLocation, action) => {
          if (action === "PUSH") {
            if (newLocation.pathname !== this.currentPathname) {
              // Save new location
              this.currentPathname = newLocation.pathname;
              // Clone location object and push it to history
              history.push({ pathname: newLocation.pathname });
            }
          } else {
            // Send user back if they try to navigate back
            history.go(1);
          }
        }); 

    }
    
    windowWidthToFontSize = () => {
        if(this.props.systemState.dimension.width > 860) return 90
        else    return this.props.systemState.dimension.width / 10.7  
    }
    

    components = (data) =>  {
        switch (data) {
            case 0:
               return <RegisterCorporationData  doneFunction={this.doneFunction} />  
            case 1:
                return <RegisterDataSheet       doneFunction={this.doneFunction} goBack={this.goBack} />   
            case 2:
                return <RegisterServices        doneFunction={this.doneFunction} goBack={this.goBack} />       
            default:
                return <RegisterCorporationData doneFunction={this.doneFunction} /> 
        }
    } 


    doneFunction = ({level}) =>  this.setState({level})

    goBack = () => this.setState({level:this.state.level - 1})
       
    style = (number) =>({
        number:{ display:"flex", 
                 height:  this.props.systemState.dimension.width < 550 ? 45 : 80, 
                 width:   this.props.systemState.dimension.width < 550 ? 45 : 80, 
                 fontSize:this.props.systemState.dimension.width < 550 ? 18 : 33, 
                 alignItems:"center", 
                 justifyContent:'center', 
                 backgroundColor: this.state.level >= number ? "#0C76B3" : "#ECECED", 
                 borderRadius:40, 
                 border:`${this.props.systemState.dimension.width < 550 ? "3px" : "7px"} solid ${this.state.level >= number ? "#1C466F" : "#ECECED"}`, 
                 color:"white" },
        title:{
                display:"flex", 
                justifyContent:'center', 
                marginBottom:this.props.systemState.dimension.width < 550 ? 30 : 2, 
            
                fontFamily:"Maven Pro", 
                fontWeight:"bold",
                fontSize:this.props.systemState.dimension.width < 550 ? 8 : 10, 
                letterSpacing:this.props.systemState.dimension.width < 550 ? 0 : 3,
             
                transform: `rotate(${this.props.systemState.dimension.width < 550 ? 90 : 0}deg)` },
        dividerContainer:{display:"flex",alignItems:"flex-end" },
        divider:{ 
            height:this.props.systemState.dimension.width < 550 ? 5 : 10, 
            width:this.props.systemState.dimension.width > 350 ? 50 : 25,  
            marginBottom: this.props.systemState.dimension.width < 550 ? 20 : 35
         }    
    });


  render(){
 
    return (
        <div style={{backgroundColor:"#F6F9FE"}}> 

            {
                this.props.systemState.dimension.width <= 550 
                ? <div style={{display:"flex", flex:1, justifyContent:'center', marginTop:-20, marginBottom:20}}><div style={{fontFamily:"Maven Pro", color: "#A9B7BC", fontWeight:"bold", fontSize:this.windowWidthToFontSize()/2, letterSpacing:15}}>REGISZTRÁCIÓ</div></div>
                : null 
            }
            

            <div style={{display:"flex", justifyContent:"space-around"}}>
                <div style={{display:"flex", flexDirection:"row", marginTop:50, marginBottom:20}}>

                    <div style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                        <div style={{ ...this.style().title }}>CÉGES ADATOK</div>
                        <div style={{ ...this.style(0).number, marginTop:10 }}>1</div>
                    </div>

                    <div style={{ ...this.style().dividerContainer }}><div style={{ ...this.style().divider, ...{backgroundColor:'#1C466F', marginLeft:this.props.systemState.dimension.width < 550 ? -8 : -16} }}/> <div style={{  ...this.style().divider, ...{backgroundColor: this.state.level > 0 ? '#1C466F' : "#ECECED"}  }}/></div><div>
                        <div style={this.style().title}>PROFIL</div>
                        <div style={{ ...this.style(1).number, marginTop: this.props.systemState.dimension.width < 550 ? 40 : 10 }}>2</div>
                    </div>
                    <div style={{ ...this.style().dividerContainer }}><div style={{ ...this.style().divider, ...{backgroundColor:this.state.level >= 1 ? '#1C466F' : "#ECECED"} }}/><div style={{ ...this.style().divider, ...{backgroundColor:this.state.level === 2 ? '#1C466F' : "#ECECED", marginRight:this.props.systemState.dimension.width < 550 ? -16 : -26} }}/></div>
                    
                    <div style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
                        <div style={{ ...this.style().title }}>SZOLGÁLTATÁSOK</div>
                        <div style={{...this.style(2).number, marginTop:10 }}>3</div>
                    </div>
                    
                </div>
            </div>

            {
                this.props.systemState.dimension.width > 550 
                ? <div style={{display:"flex", flex:1, justifyContent:'center', marginTop:-30, marginBottom:20}}> <div style={{fontFamily:"Maven Pro", color:"white", fontWeight:"bold", fontSize:this.windowWidthToFontSize(), letterSpacing:15}}>REGISZTRÁCIÓ</div></div>
                : null 
            }

            
            
            {/* <========= COMPONENT =========> */}
            {  this.components(this.state.level)  }

        </div>
    );

  }
}


/*====================== 
* State to Props
=====================*/
 const mapStateToProps = state => {
    const {systemState, userState, tokenState} = state;
    return {systemState, userState, tokenState};
};
  
/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{})(RegisterProfessional));
