import React, {Component} from 'react';

import { Link} from "react-router-dom";
//styles
import './RegisterSelector.css';
 class RegisterSelector extends Component  {
  render(){

    return (
      <div className="register">
        <div className="d-flex w-100 justify-content-center position-absolute"><div className="registerSelectorDecor">Szerekkör választó</div></div>
        <div className="d-flex w-100 justify-content-center position-absolute"><div className="registerSelectorDecorLine"></div></div>
        <div className="d-flex w-100 justify-content-center position-absolute d-lg-none">
            <div className="regmobiletittleblock">
              <div className="askone">Felhasználó</div>
              <div className="asktwo">Vagy</div>
              <div className="askone">Szakember</div>
              <div className="askdivider"></div>
            </div>
        </div>
        <div className="d-flex row registerSelectorRow justify-content-center">
            <div className="regcol col-lg-6 d-flex justify-content-center justify-content-lg-end">
                <div className="aligner laligner">
                    <div className="tittle">Felhasználók</div>
                    <div className="description">
                        <p>Mesterembert házhoz rendelni a bejelentkezést követően nyílik lehetőséged. Ha még nem hoztál volna létre saját profilt, azt a Regisztráció gombra kattintva teheted meg. Azonban ha már van felhasználói fiókod, kattints a Belépés gombra.</p>
                        <p>A bejelentkezést követően megtalálhatod a tökéletes mesterembert a problémádra. Az oldal tetején lévő kereső mező segítségével célzottan keresgélhetsz az oldalon. Ehhez nem kell mást tenned, mint a “Keress szakember” gombra nyomnod, majd a szolgáltatás és a város megadása után a “Keresés” gombra kattintva meg is találod a mesterembered.</p>
                        <p>Ha viszont nem elérhető abban az időben, nyugodtan megnézheted a naptárát és foglalást tehetsz. Nem mellesleg szerszámait is igénybe veheted!</p>
                    </div>
                    <div className="calign"><Link to={{pathname: '/registration/user', hash:"#user", state: { type: "basic",  from:"selector" }}} ><button>Regisztráció Felhasználóként</button></Link></div>
                </div>
            </div>
            <div className="regcol col-lg-6 d-flex justify-content-center justify-content-lg-start">
                <div className="aligner raligner">
                    <div className="tittle">Szakemberek</div>
                    <div className="description">
                        <p>Akkor kerülsz a radarra, ha regisztálva vagy. Ha még nem hoztál volna létre saját profilt, azt a Regisztráció gombra kattintva teheted meg. Azonban ha már van felhasználói fiókod, kattints a Belépés gombra.</p>
                        <p>A bejelentkezést követően elérhetővé válik a naptárad sokak számára. Figyelemmel kísérelheted kikölcsönzött eszközeid hollétét. Te szabhatod meg szolgáltatásaid, kiszállásod és eszközeid igénybevételének árát. Egy helyen láthatod ajánlásaidat, a megbízásaidat és munkanapjaidat. Bérelhetsz másoktól eszközöket.</p>
                        <p>Érjük el együtt, hogy szaktudásunk hatékonyan legyen alkalmazva!</p>
                    </div>
                    <div className="calign" style={{marginTop:120}}><Link to={{pathname: '/registration/user', hash:"#professional", state: { type: "professional", from:"selector" }}} ><button>Regisztráció Szakemberként</button></Link></div>
                </div>
            </div>
        </div>

      
      </div>
    );

  }
}



export default RegisterSelector;
