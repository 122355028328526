import React, {Component} from 'react';

/* ROUTER */

import {NavLink} from "react-router-dom";

import './AdsList.css'
import {request}   from "../../../../Model/Request";
import parse from 'html-react-parser';
import default_image from "../../../../Assets/mester_default.png";
import {Container, Modal, Button} from "react-bootstrap";
import Select from 'react-select';


 class AdsList extends Component  {
   
  
  state={
    loadedData: [],
    resultsNumber:0,
    per_page:9,
    page:1
  };

  componentDidMount(){
    this.getAdsRequest()
  }

  getAdsRequest = async () => {
    try{
      const response = await request({url:`/user/profile/ads?per_page=${this.state.per_page}&page=${this.state.page}`, method:"GET",  });
      console.log(response);
      let temp = response.data.ads.map((res)=>{
        return {
          img:res.attributes?.basic_image?.src ?? default_image,
          related_ads_id:res.attributes.hash_id,
          related_ads_tittle:res.attributes.profession.name,
          description:res.attributes.description,
          id:res.id
        }
      });
      this.setState({loadedData:temp,  resultsNumber:response.data?.paginator?.total_item ?? 0,  page:response.data?.paginator?.current_page ?? 1})
      //this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
    }catch(e){
      /// error handler
        console.log(e)
    }
  }

  paginatorComponent = () => {
    return(
      <>
      <div style={{...styles.line, marginTop:30}} />
      <Container style={{display:"flex"}}>
      
      {/* middle */}
      <div  style={{display:"flex", alignItems:"center", justifyContent:"center", color:"#4F4F4F", ...styles.fontStyle, padding:5}}>TALÁLATI OLDAL: </div>

      <Select
              options={
                Array(this.state.resultsNumber)
                  .fill(null)
                  .map( (x,i) => i )
                  .filter( (item, index) =>  index % this.state.per_page === 0)
                  .map((item, index)=>{ return {value:index+1, label:index+1} })
              }
              onChange={ async (e)=>{ 
                  await this.setState({page:e.label})
                  await this.getAdsRequest()
              }}
              styles={styles.pageSelectStyle}
              placeholder={"1"}
              noOptionsMessage={()=><div>Nincs választható oldal</div>}
              />
  
  </Container>
      <div style={{...styles.line}} />
      <div style={{marginBottom:70}} />
  </>
    )
  }

  render(){
    
 /*    console.log("===========")
    console.log(this.props.id)
    console.log("===========") */

    let adsList;
    if (this.state.loadedData.length!=0)
    {
      adsList = this.state.loadedData.map(

        (ret,index)=> 
        {
            return (
              <AdsCard key={index} img={ret.img} description={ret.description} related_ads_id={ret.related_ads_id} related_ads_tittle={ret.related_ads_tittle} id={ret.id}/>
            );
        }

      );
    }else
    {
      adsList = <div className="errorTxt">Jelenleg nincs megtekinthető hirdetés!</div>
    }
    
    return (
      <>
      {
        this.paginatorComponent()
      }
       <div className="innerContainer d-flex justify-content-center">
          <div className="innerAligner d-flex flex-wrap justify-content-center">
              {adsList}
              <div className="w-100 decor"></div>
              <div className="w-100 text-center">

              <NavLink to='/users/ads/new'><button className="newaddsbtn">Hirdetés Feladása</button></NavLink>

              </div>
              
          </div>
          
      </div>
      </>
    );


  }
}

const styles = {
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  pageSelectStyle : {
    container: provided => ({
      ...provided,
      borderRadius:3,
      width: 200,
      fontFamily:"Maven Pro",
      fontSize:16,
      fontWeight:"bold",
      color:'#4F4F4F',
    }),

    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected ? "#1C466F":"white",
      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"

    }),

    control: base => ({
      ...base,
      border: 0,
      borderRadius:3,
      boxShadow: 'none',
      fontFamily:"Maven Pro",
      height:50,
      letterSpacing: 3
    })
},
}

/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default AdsList;


class AdsCard extends Component  {

  
  render(){
    console.log(this.props.id)
    return(
      <div className="card">
      <img className="images" crossOrigin="anonymous" src={this.props.img} alt=""/>
      <div className="names">{this.props.related_ads_tittle}</div>
      <div className="descriptionContainer d-flex justify-content-center">
         <div className="description modifier d-flex align-items-center">
            Hirdetés szám: {this.props.related_ads_id}
         </div>
      </div> 
      <div className=" d-flex justify-content-center">
        <div className="description modifier align-items-center">
          Leírás:
        </div>
      </div> 
      <div className=" d-flex justify-content-center">
        <div className="description modifier-twoline align-items-center">
        {parse(this.props.description)}
        </div>    
      </div> 


      <div className="buttonContainer">
      
      <NavLink to={'/users/ads/details?id='+this.props.id}>
          <button className="cardbutton">tovább a hirdetéshez</button>
      </NavLink>
      </div>
      
   </div>

    );
  }
}