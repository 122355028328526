import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {setUserAction} from "../../../Model/Action/index";
import {request} from '../../../Model/Request';
import ImageUpload from "../../../View/ImageUpload";
import {Container, Modal} from "react-bootstrap";
import Sidebar from "../SideBar/SideBar";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import cover from "../../../Assets/empty.png";
import RegisterServices from "../../Registration/Register_professional/registerServices";

import Map from "../../../View/Map";
import telepulesek from  '../../../Model/telepules';
import {quantities} from "../../../Model/quantities";
import parse from 'html-react-parser';
import addBTN from "./Assets/add.png";
import {objectEquals} from "../../../Model/ObjectEqual"

import defaultBanner from "../../../Assets/mester_default.png"
 



 class Profiles extends Component  {


  state = {
    companyID:null,
    imageIndexCounter:null,
    deleteImage:[],


    modalShow:false,
    introductionEdit:false, 
    placesEdit:false,
    imagesFromJobsEdit:false,
    servicesEdit:false,
    bannerEdit:false,
    mapEdit:false,
    profilImageEdit:false,
    text:"",      /// introduction
    images:[],    /// images about my works
    data:[],      /// geo DATA
    saveData:[],  /// service DATA
    banner:"",    /// banner image
    avatar :"",

  }



 componentDidMount(){

   // `company/profile/profession?id=${}`
   const selectedCompany = this.props.userState.user.company.filter(item => item.selected === true)
   if(selectedCompany.length === 0) return
   const id = selectedCompany[0].id;
   this.setState({companyID:id})
   

   const getDataArray = [
    {url:`company/profile/profession?id=${id}`,         state:"saveData"},
    {url:`company/profile/geo?id=${id}`,                state:"data"},
    {url:`company/profile/picture?id=${id}&type=basic`, state:"images"},
    {url:`company/profile/picture?id=${id}&type=banner`,state:"banner"},
    {url:`company/profile/introduction?id=${id}`,       state:"text"},
  ]

   for (let index = 0; index < getDataArray.length; index++) {
     const item = getDataArray[index];
     this.getData({url:item.url, state:item.state})
   }
  


 }

 




  getData = async ({url, state }) => {

    try{

      const response = await request({url, method:"GET"});

      if(typeof response.error !== "undefined"){
        return
      }else{
      
        if(url.includes("introduction")){
          this.setState({[state]:response.data.introduction})
        }else if(url.includes("basic")){
          const data = response.data.basic.filter(item => item !== null).map( (item, index) => { return {...item, name:index} } )
          this.setState({[state]:data, imageIndexCounter:data.length })
        }else if(url.includes("banner")){
     
          console.log(response)
          const data = response?.data?.banner ?? [];
          const safe_data = data.filter( item => item !== null)
       
        
          if(safe_data.length === 0) return
            this.setState({banner:safe_data[0].src})


        }else if(url.includes("geo")){
          this.setState({[state]: response.data.filter( item => item.type === "area").map( (item, index)=>{ return { 
            id: index,
            name: item.city,
            zip:item.zip_code,
            latitude: item.lat,
            longitude: item.lng,
            circle : {
              radius: item.distance,
              options: {
                strokeColor: "#217BA7",
                fillColor:"#217BA7"
              }
            }
           }  } ) })

        }else{
          this.setState({[state]:response.data})
          
        }
        
      }
      
    }catch(e){
      console.log(e)
    }


  }

  titleComponet = ({title, state }) => {
    return(
      <>
      <div style={{display:"flex", flexDirection:"row", marginTop:60, marginBottom:20}}>
        <div style={{flex:1, fontFamily:"Maven Pro", fontWeight:"bold", fontSize:21, letterSpacing:3, color:"#4F4F4F", }}>{title}</div>
        <div style={{flex:1,  fontFamily:"Maven Pro", fontWeight:"bold", fontSize:11,letterSpacing:3, textAlign:"end", cursor:"pointer",}} onClick={()=>{this.setState({[state]:true, modalShow:true})}}>MÓDOSÍTÁS</div>
      </div>

      <div style={{width:'100%', height:0.5, backgroundColor:"grey", marginBottom:20}} />
      </>
    )
  }

  handleChange = (value) => this.setState({ text: value })
  
  removeItemsFromImages = async (name, id) =>  {

      if(id !== -1){
       this.setState({deleteImage:[...this.state.deleteImage, id]})
      }
      
      this.setState({images:this.state.images.filter(item => item.name !== name)})  
  }
  


  avatarImageToState = ({base64data}) => {
    this.setState({avatar:base64data})
  };





  addItemToImages = () => {
      this.setState(state=>{   

        const newImages = [...state.images,{img:null, src:null, name: this.state.imageIndexCounter + 1}]
          return {images:newImages, imageIndexCounter:this.state.imageIndexCounter + 1}
      }); 
  };



  imageToState = ({base64data, index}) => {
    this.setState({images:this.state.images.map(item => item.name === index ? {...item, img:"base64", src:base64data} : {...item} )})
  }

  bannerToState = ({base64data}) => {
    this.setState({banner:base64data})
};




  imagesFromJobsEditComponent = () => {

    return(
      <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly"}}>
      {
          this.state.images.map( (item, index)=>  item === null ? null :  
          
          <div key={index + "img"} style={{margin:10}}>

            {
              item.img === null 
                ? <ImageUpload index={item.name} imageToState={this.imageToState} cover={cover} /> 
                : <div style={{...styles.imgDIV, backgroundImage:`url(${item.src})`}} />
            } 

            <div onClick={()=>this.removeItemsFromImages(item.name, typeof item.id !== "undefined" ? item.id : -1)} style={{position:"relative", top:-220, left:150, width:70, padding:2, borderRadius:6, cursor: "pointer", background:"red"}} >
              <div style={{display:"flex", justifyContent:'center', color:'white', fontSize:12, fontFamily:"MONDA"}}>TÖRLÉS</div>
            </div> 


         
         </div> )
       }
       <div style={{padding:50, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}} onClick={()=>this.addItemToImages()}>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>TOVÁBBI</div>
                    <div style={{fontSize:12, fontFamily:"MONDA"}}>KÉPEK</div>
                    <div style={{fontSize:12, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
                    <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
                  </div>
       </div> 
    )
    
      
  
  }


  addItemToData = () => this.setState(state=>{ const newData = [...state.data,{id: this.state.data.length , name: "",latitude: "0",longitude: "0", circle : { radius: 0, options: { strokeColor: "#217BA7", fillColor:"#217BA7" }}}]; return  {data:newData} } )
  removeFromData = (id) =>  this.setState(state=>{ const newData = state.data.filter(item => item.id !== id); return  {data:newData} } )
  setRadius = (index, e) => {
    let {value} = e.target;
    if(value === "" || isNaN(value) ) value = 0;
    this.setState(state=>{
      let newData = [...state.data];
          newData[index].circle.radius = parseInt(value);

          return {data:newData}
    })
  };



  inputCompionent = ({index, addRemovebutton=false}) => {
    return(
      <div key={index + "input"} style={{display:"flex", flexWrap:"wrap", flexDirection:"row", alignItems:'center'}}>

          <div style={{margin:15, flexGrow: addRemovebutton ? 3 :1}}>
            <p style={{margin:0, padding:0, fontFamily:"MONDA", fontSize:12, fontWeight:"bold", letterSpacing:3, marginBottom:12}}>TELEPÜLÉS</p>
            <input style={{borderRadius:4, borderWidth:1, BorderColor:'black', width:"100%"}} id={index} uniqe={"settlement"+index} onChange={(e)=>this.setInput(e)} value={this.state.data[index].name} />
          </div>

          <div style={{margin:15, flexGrow:1}}>
            <p style={{margin:0, padding:0, fontFamily:"MONDA", fontSize:12, fontWeight:"bold", letterSpacing:3, marginBottom:12}}>KÖRZET (KM)</p>
            <input style={{ borderRadius:4, borderWidth:1, BorderColor:'black', width:"100%", paddingLeft:10}} onChange={(e)=>this.setRadius(index, e)} value={this.state.data[index].circle.radius} />
          </div>

          {addRemovebutton ? <div style={{ flexGrow:1, marginTop:10, marginLeft:15}}><div onClick={()=>this.removeFromData(index)} style={{cursor: "pointer", fontFamily:"MONDA", fontSize:11, fontWeight:'bold'}} >törlés</div></div> : null}

      </div>
    )
  };

  validationTimeout;
  setInput = (e) => {

    const {id} = e.target;

    let {value} = e.target;
   
    clearTimeout(this.validationTimeout);
    
    

    this.setState(state=>{
      const newData = state.data.map((item, index) => { if(index == id){  return {...item, name:value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() } } else {return {...item}} }); 
      return {data:newData}
    })

      

      this.validationTimeout = setTimeout(()=>{ 
        
        const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())

        if( settlement.length > 0 ){

          document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(38,134,176,0.26)";
          this.setState(state=>{ 
              const newData2 = state.data.map((item, index)=> { if(index == id){  return { ...item, longitude:settlement[0].Long, latitude:settlement[0].Lat, zip:settlement[0]["Ir. szám"] }  }else{ return { ...item } } } );
              return {data:newData2}
            })

        }else{

            alert(`A következő település nem található: ${value} `);
            document.querySelector(`[uniqe=settlement${id}]`).style.background = "rgba(227,6,6,0.15)";
            this.setState(state=>{ 
              const newData2 = state.data.map((item, index)=> { if(index == id){ return {  ...item, name : "" } }else{ return { ...item } } } );
              return {data:newData2}
            })

        } 
    },2000)

      
  }



  mapsEdit = () => {

    return(
      <div style={{display:"flex", flexDirection: this.props.systemState.dimension.width < 550 ? "column" : "row", alignItems : this.props.systemState.dimension.width < 550 ? "center" : "flex-start" }}>

                <div style={{flexGrow:2, width: this.props.systemState.dimension.width < 550 ? "100%" : "65%"}}>

                   {this.inputCompionent({index:0})} 
                
                    { this.state.data.map( (item, index) =>  index > 0 ? this.inputCompionent({index:index, addRemovebutton:true}): null  ) } 
                    <div onClick={()=>this.addItemToData()} style={{fontFamily:"MONDA", fontSize:12, marginTop:15, marginLeft:15, color:"#4F4F4F", cursor: "pointer"}}>TOVÁBBI KÖRZET HOZZÁADÁSA</div>
                  </div>

                  <div style={{flexGrow:1, width: this.props.systemState.dimension.width < 550 ? "100%" : "35%"}}>
                    <Map
                      center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} /// lat: 47.497913, lng: 19.040236
                      zoom={this.state.data.length < 2 ? 9 : 7}
                      places={this.state.data}
                  
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                      loadingElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%`, filter:"grayscale(95)" }} />}
                      containerElement={<div style={{ height:'300px', width:'100%' }} />}
                    /> 
                  </div>

                
      </div>
    )



  }

  bannerEditComponent = () => {
    return(
      <div style={{display:"flex", justifyContent:"center"}}>

        <ImageUpload 
          index={0} 
          imageToState={this.bannerToState} 
          cover={this.state.banner === "" ? cover : this.state.banner} 
          imageContainerStyles={{ width:400 }} 
          imageStyle = {{width:400}}
          /> 
      </div>
    )
  }
 
  profilImageEditComponent = () => {
    return(
      <div style={{display:"flex", justifyContent:"center"}}>

      <ImageUpload 
              index={0} 
              imageToState={this.avatarImageToState} 
              cover={this.props.userState?.user?.attributes?.avatar?.src ?? null} 
              imageContainerStyles={{ backgroundSize: 'cover' }} 
              imageStyle={{ backgroundSize: 'cover' }}  /> 
      </div>
    )
  }

  servicesComponent = (type, item, index) => {
    return(
      <div key={ type + index}>
                    <div style={{...styles.services, marginTop:20}}>{item.name}</div>

                    { 
                      item.specializations.map( (specItem, specIndex) => 
                      <div key={specIndex + item.name + specItem.name } style={{display:"flex", flexDirection:"column"}}> 
                        <div style={{flex:1, marginTop:10, marginBottom:10, fontWeight:"bold",}}>{specItem.name}</div> 
                        <div style={{flex:1, display:"flex"}}>
                            <div style={{flex:1, display:"flex", justifyContent:"flex-start", fontFamily: "Maven Pro",fontWeight:"bold",  letterSpacing: 2}}>1 {quantities[specItem.unit].label}</div> 
                            <div style={{flex:1, display:"flex", justifyContent:"flex-end",   fontFamily: "Maven Pro",fontWeight:"bold", letterSpacing: 2}}>{specItem.price} Ft</div> 
                        </div>
                      </div>
                      ) 
                    }
                  </div> 
    )
  }


  saveCancelBTN = ({cancelFunction, saveFunction}) => {
    return(
      <div style={{display:"flex", justifyContent:"space-evenly", flexDirection: this.props.systemState.dimension.width <= 550 ?"column":"row", alignItems:"center"}}>

          <div onClick={()=>cancelFunction()} style={{cursor:"pointer",background:"#C4C4C4", height:65, width:300, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:5,  marginTop:40}}>
            <div style={{color:"white", fontFamily:"Maven Pro", fontSize:20, fontWeight:"bold"}}> MÉGSEM </div>
          </div>

          <div onClick={()=>saveFunction()} style={{cursor:"pointer",background:"#217BA7", height:65, width:300, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:5,  marginTop:40}}>
            <div style={{color:"white", fontFamily:"Maven Pro", fontSize:20, fontWeight:"bold"}}> MENTÉS </div>
          </div>

      </div>
    )
  }

  

  selectedModalComponent = () => {
    switch (true) {
      case this.state.introductionEdit === true:
           return  <div>
                    <ReactQuill value={this.state.text} onChange={(value)=>this.handleChange(value)} style={{height:300, marginBottom:80}}  />
                    {this.saveCancelBTN({saveFunction: this.saveIntroduction, cancelFunction: this.cancelIntroduction})}
                  </div>

      case this.state.servicesEdit === true:
            return  <RegisterServices importData={ [...this.state.saveData]} exportData={this.exportData} cancel={this.cancelServicesEdit}  />

      case this.state.imagesFromJobsEdit === true:
           return  <div>
                  { this.imagesFromJobsEditComponent() }
                  {this.saveCancelBTN({saveFunction: this.saveImages, cancelFunction: this.cancelImages})}
                  </div>
       case this.state.mapEdit === true:
            return  <div>
                    {this.mapsEdit() }
                    {this.saveCancelBTN({saveFunction: this.saveMap, cancelFunction: this.cancelMap})}
                   </div>
       case this.state.bannerEdit === true:
          return  <div>
                  {this.bannerEditComponent() }
                  {this.saveCancelBTN({saveFunction: this.saveBanner, cancelFunction: this.cancelBanner})}
                  </div>
      case this.state.profilImageEdit === true:
        return  <div>
                {this.profilImageEditComponent() }
                {this.saveCancelBTN({saveFunction: this.saveAvatarImage, cancelFunction: this.cancelAvatarImage})}
                </div>            


    
      default:
        break; 
    }
  }



  /* 
  SAVE CANCEL FUNCTIONS
  */
  saveIntroduction = async () => {


    if(this.state.text.replace(/<(.|\n)*?>/g, '').trim().length < 5 ) return
    
    const companyDescriptionData = {
      id: this.state.companyID,
      description: this.state.text
    }

    const response = await request({url:"/company/profile/introduction/update", data:companyDescriptionData, method:"PATCH"});

    if(typeof response.error !== "undefined"){
      console.log(response.error)
    }else{
      this.setState({
        modalShow:false,
        introductionEdit:false, 
        placesEdit:false,
        imagesFromJobsEdit:false,
        servicesEdit:false,
      })
    }

  }

  cancelIntroduction  = async () => {
    await this.getData({url:`company/profile/introduction?id=${this.state.companyID}`, state:"text" })
    this.setState({
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
    })
  }


  cancelAvatarImage = () => {

    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
      avatar:""
    })
  
  }

  saveAvatarImage = async () => {

    if(this.state.avatar === "") {
      this.cancelAvatarImage()
      return
    }

        const data = {
          upload_to:{
          type: "user",
          id: this.props.userState.user.id
          },
          img: {
          type: "avatar",
          mime:this.state.avatar.split(";")[0].replace("data:image/",""),
          src: this.state.avatar
          }
      }

      const response_avatar = await request({url:"/image", method:"post", data});
      console.log(response_avatar)
      if(typeof response_avatar.error === "undefined"){

        const response = await request({url:"/user"});

                const avatar = response.data?.user?.attribute?.attributes?.avatar ?? ""

                if(avatar !== ""){
                    
                    let {user} = this.props.userState;
                    user.attributes.avatar = avatar;
                    this.props.setUserAction(user)


                }

      }

  }


  cancelImages = async () => {
  
    await this.getData({url:`company/profile/picture?id=${this.state.companyID}&type=basic`, state:"images" })
    

    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
    })
  
  }

  saveImages = async () => {

    /// if there any delete image
    if(this.state.deleteImage.length > 0){
      for (let index = 0; index < this.state.deleteImage.length; index++) {
          const item = this.state.deleteImage[index];
          await request({ url:"/company/profile/picture/delete", method:"delete", data:{company_id : this.state.companyID, image_id:item} });
      }
    }

    const uploadArray = this.state.images.filter( item => typeof item.img !== "undefined" )
    if(uploadArray.length > 0){
      for (let index = 0; index < uploadArray.length; index++) {

        const item = uploadArray[index];
  
        const data = {
          upload_to:{
            type: "company",
            id: this.state.companyID
          },
          img: {
            type: "basic",
            mime:item.src.split(";")[0].replace("data:image/",""),
            src: item.src
          }
        }
        
  
         await request({ method:"post", url:"/image", data });
      }
    }

    await this.getData({url:`company/profile/picture?id=${this.state.companyID}&type=basic`, state:"images" })
    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
    })


  }

  cancelMap = async () => {
    
    await this.getData({url:`company/profile/geo?id=${this.state.companyID}`, state:"data" })

    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
      mapEdit:false,
    })
  }

  saveMap = async () => {
    
        const locationData = this.state.data.map( item => {

          return  { 
                    type:"area",
                    locationable: { 
                      type:"company", 
                      id:this.state.companyID 
                    },
                    lat : item.latitude,
                    lng : item.longitude,
                    distance : item.circle.radius,
                    country_code: "hu",
                    zip_code: item.zip,
                    country: null,
                    county:  null,
                    city: item.name,
                    street: null,
                    house_number: null,
                    extra:{
                      floor : null,
                      door : null
                  },
                  formatted_address: null
                }
        })
        /// getSaved Data
        const getLocationResponse = await request({ url:`/company/profile/geo?id=${this.state.companyID}`, method:"GET" });
              
        if(typeof getLocationResponse.data !== "undefined" &&  getLocationResponse.data.length > 0){

             for (let index = 0; index < getLocationResponse.data.length; index++) {
                 const item = getLocationResponse.data[index];
                 if(item.type === "area"){
                   console.log("geo delete")
                   await request({ 
                     url:`/company/profile/geo/delete`, 
                     method:"DELETE", 
                     data:{  
                         company_id:this.state.companyID, 
                         location_id:item.id
                     } 
                   })
                  
                 }
             }

        }

        
        const locationResponse = await request({ url:"/location", data:locationData, method:"POST" });
        console.log(locationResponse)

        this.setState({
          deleteImage:[],
          modalShow:false,
          introductionEdit:false, 
          placesEdit:false,
          imagesFromJobsEdit:false,
          servicesEdit:false,
          mapEdit:false,
        })

  }


  saveBanner = async () => {

    if(!this.state.banner.includes("base64")) return

    const data = {
      upload_to:{
        type: "company",
        id: this.state.companyID
      },
      img: {
        type: "banner",
        mime:this.state.banner.split(";")[0].replace("data:image/",""),
        src: this.state.banner
      }
    }


     await request({ method:"post", url:"/image", data });

     await this.getData({url:`company/profile/picture?id=${this.state.companyID}&type=banner`, state:"banner" })

     this.setState({
       deleteImage:[],
       modalShow:false,
       introductionEdit:false, 
       placesEdit:false,
       imagesFromJobsEdit:false,
       servicesEdit:false,
       bannerEdit:false,
       mapEdit:false,
     })


  }

  cancelBanner = async () => {

    await this.getData({url:`company/profile/picture?id=${this.state.companyID}&type=banner`, state:"banner" })

    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
      bannerEdit:false,
      mapEdit:false,
    })
  }


  exportData = async (saveData) => {


    const oldDataArray = this.state.saveData.map(item=>item.name)
    const savedataArray = saveData.map(item=>item.name)

    const deleted = oldDataArray.filter(x => !savedataArray.includes(x)); /// check for DELETED elements
    const added   = savedataArray.filter(x => !oldDataArray.includes(x)); /// check for ADDED element
    const union   = oldDataArray.filter(x => savedataArray.includes(x)); /// check for UPDATED elements


    console.log(deleted)
    console.log(added)
    console.log(union)
   
    if(deleted.length !== 0){

        /// DELETE from database
        for (let index = 0; index < this.state.saveData.length; index++) {

              const item = this.state.saveData[index];

             if(deleted.includes(item.name)){

                  //console.log(`törlöm: ${item.name}`)
                  const response = await request({ url:"/company/profile/profession/delete", method:"DELETE",  data:{ company_id:this.state.companyID, profession_id:item.id } })
                  if(typeof response.error !== "undefined"){ break } 

              } 
 

            }
  
        }
       
    if(added.length !== 0){
  
          for (let index = 0; index < saveData.length; index++) {
              const item = saveData[index];
  
                if(added.includes(item.name)){
                 // console.log(`hozzá adom: ${JSON.stringify(item)}`)

                  const response = await request({  url:"/company/profile/profession",  method:"POST", data:{
                    company_id:this.state.companyID,
                    professions:[
                      {
                        profession_id: item.id,
                        specializations:[...item.specializations]
                      }
                    ]
                  }
                }) 
                if(typeof response.error !== "undefined"){ break } 

                }
          }
  
        }
  
    if(union.length > 0){
      
          const response = await request({ url:`company/profile/profession?id=${this.state.companyID}`, method:"GET"});
         
  
          for (let index = 0; index < this.state.saveData.length; index++) {
  
               const item = this.state.saveData[index];
  
               if(union.includes(item.name)){
  
                    const examArray =  response.data.filter(filterItem => filterItem.name === item.name )[0]
                    if(!objectEquals(item.specializations, examArray.specializations)){
                     // console.log('külömbség')

                      const response = await request({  url:"/company/profile/profession/update",  method:"PATCH", data:{
                        company_id:this.state.companyID,
                        professions:[
                            {
                              profession_id: item.id,
                              specializations:[...item.specializations]
                            }
                          ]
                        }
                      }) 
                      if(typeof response.error !== "undefined"){ break } 


                    }
  
  
  
               }
            
          }
  
  
        }
    

       
        this.setState({
          deleteImage:[],
          modalShow:false,
          introductionEdit:false, 
          placesEdit:false,
          imagesFromJobsEdit:false,
          servicesEdit:false,
        })

    
  } 

  cancelServicesEdit = async () => {
    this.getData({url:`company/profile/profession?id=${this.state.companyID}`, state:"saveData"})
    this.setState({
      deleteImage:[],
      modalShow:false,
      introductionEdit:false, 
      placesEdit:false,
      imagesFromJobsEdit:false,
      servicesEdit:false,
    })
  }


  render(){
  
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
      <Container className="profile" style={{backgroundColor:"white", paddingTop:0, paddingBottom:0, paddingLeft:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        
      
      

        <Modal centered size="xl" show={this.state.modalShow} onHide={ () => this.setState({
                modalShow:false,
                introductionEdit:false, 
                placesEdit:false,
                imagesFromJobsEdit:false,
                servicesEdit:false,
        })}>

        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title"> MÓDOSÍTÁS </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { this.selectedModalComponent() }
        </Modal.Body>

      </Modal>


       <div >

       <div style={{display:"flex",}}>

        <div style={{flex:2}}><Sidebar/></div>
        <div style={{display:"flex", flex:12, flexDirection: this.props.systemState.dimension.width > 990 ? "row" : "column"  }}>

        
             <div style={{padding:60}}>


             
          
           {this.titleComponet({title:"BEMUTATKOZÁS", state:"introductionEdit" })}
           <div style={{border:"#ccc solid 0.5px", padding:20}}>{parse(this.state.text)}</div>
           <div style={{marginTop:30, marginBottom:30}} />





           {this.titleComponet({title:"BANNERKÉP", state:"bannerEdit" })}
           <div style={{display:"flex", justifyContent:"center"}}>
           <div style={{...styles.imgDIV, width: window_width >= 1200 
                                                ? 770 
                                                : window_width >= 990
                                                    ? window_width / 1.9
                                                    : window_width / 4, backgroundImage:`url(${this.state.banner === "" ? defaultBanner : this.state.banner})`}}/>
           </div>
           <div style={{marginTop:30, marginBottom:30}} />

          


           {this.titleComponet({title:"PROFILKÉP", state:"profilImageEdit" })}
            <div style={{display:"flex", justifyContent:"center"}}>
              <div  style={{...styles.imgDIV, backgroundImage:`url(${this.props.userState?.user?.attributes?.avatar?.src ?? null})`}} />
            </div>
            <div style={{marginTop:30, marginBottom:30}} />



            {this.titleComponet({title:"MUNKÁIM", state:"imagesFromJobsEdit" })}
            <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly"}}>
            { this.state.images.map( (item, index)=>  item === null 
              ? null 
              : <div  key={new Date().getMinutes() + new Date().getMilliseconds() + index} style={{...styles.imgDIV, backgroundImage:`url(${item.src})`}} /> ) }
            </div>
            <div style={{marginTop:30, marginBottom:30}} />

         


            {this.titleComponet({title:"SZOLGÁLTATÁSOK", state:"servicesEdit" })}
            <div style={{marginTop:30, marginBottom:30}} />

            <div style={{ display:"flex", flexDirection: this.props.systemState.dimension.width <= 922 ? "column":"row"}}>

              <div style={{flex:1, padding:15}}>
                {
                typeof this.state.saveData !== "undefined" && this.state.saveData.length > 0 
                ? this.state.saveData.map((item, index)=> index % 2 === 0 
                  ? this.servicesComponent("even_", item, index)
                  : null
                )
                :null
                }
              </div>
              <div style={{flex:1, padding:15}}>
              {
                typeof this.state.saveData !== "undefined" && this.state.saveData.length > 0 
                ? this.state.saveData.map((item, index)=> index % 2 === 1 
                  ? this.servicesComponent("odd_", item, index)
                  : null
                )
                :null
                }
              </div>


            </div>

           
      




            {this.titleComponet({title:"TÉRKÉP", state:"mapEdit" })}
            {
             this.state.data.length > 0 ? 
             <Map
             center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} /// lat: 47.497913, lng: 19.040236
             zoom={this.state.data.length < 2 ? 9 : 7}
             places={this.state.data}
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
             loadingElement={<div style={{ height: `100%` }} />}
             mapElement={<div style={{ height: `100%`, filter:"hue-rotate(130deg) grayscale(0.9)" }} />}
             containerElement={<div style={{ height:'300px', width:'100%' }} />}
           />
           : null
           }
           
         


          </div>

          


          </div>

      </div> 



      </div>
      


      </Container>
    );

  }
}

const styles = {

  imgDIV : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:210, 
            height:210,
            borderRadius:6,
            margin:5,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },
  services: {display:"flex",padding: 15, background: "rgb(12, 118, 179)", justifyContent: "flex-start", borderRadius: 4, 
  fontSize: 20,
fontFamily: "Maven Pro",
letterSpacing: 2,
fontWeight: "bold",
color: "white"}        

}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{
  setUserAction
})(Profiles);

