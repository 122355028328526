import React, {Component} from 'react';
import queryString from "query-string";

/* ROUTER */
import {withRouter} from "react-router-dom";
import Map from "../../../../View/Map";

import {request}   from "../../../../Model/Request";
import parse from 'html-react-parser';

import './AdsDetails.css'


 class AdsDetails extends Component  {

  state ={

    images:[],
    data:[],
    loadedData:{},

  }

  componentDidMount(){
    const parsed = queryString.parse(this.props.location.search);
    console.log(parsed);
    this.getAdsRequest(parsed);
  }

  getAdsRequest = async (id) => {
    try{
      console.log(id)
      const response = await request({url:`/user/profile/ads?ad_id=${id.id}&per_page=1000&current_page=1`, method:"GET",  });
      console.log(response);
      let st = {...this.state};
          st.loadedData = {...response.data.ad};
          this.setState(st);
      //let temp = response.data.ads.map((res)=>{
      //  return {img:res.attributes.basic_image.src,related_ads_id:res.attributes.hash_id,related_ads_tittle:res.attributes.profession.name,description:res.attributes.description,id:res.id}
      //});
      //this.setState({loadedData:temp})
      //this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
    }catch(e){
      /// error handler
        console.log(e)
    }

   }

  render(){
    
    //const parsed = queryString.parse(this.props.location.search);
    //console.log(parsed.id)

    return (

      <div className="innerContainer d-flex justify-content-center">
        <div className="innerAligner d-flex flex-wrap justify-content-start">
          <div className="w-100 adsdetail">
            <div className="adsopentext">
                <div>Hirdetés szám: {this.state.loadedData?.attributes?.hash_id}</div>
            </div>
            <div className="mainimg">
              <img src={this.state.loadedData?.attributes?.basic_image?.src}/>
            </div>
            <div className="jobsname text-center">
              {this.state.loadedData?.attributes?.profession?.name}
            </div>
            <div className="hdecorline"></div>
            <div className="jobinfo d-flex w-100">
              <div className="w-50 leftside">
                <div className="maintittle">Határidő</div>
                <div className="subtittle">{this.state.loadedData?.attributes?.deadline?.name}</div>
              </div>
              <div className="w-50 rightside">
                <div className="maintittle">Költség határ</div>
                <div className="subtittle">{this.state.loadedData?.attributes?.payment_limit?.name}</div>
              </div>
            </div>
            <div className="imggalery d-flex flex-wrap justify-content-center">
            {this.state.loadedData?.attributes?.images.map(
              (res,index)=>{return( index<3 ? <img key={index} index={index} className="imgformater" src={res.src}/>:null)}
            ) }

            </div>
            <div className="subtittleads">Munkavégzés helye</div>
            <div className="hdecorline"></div>
            <div className="mapsformat">
                <Map
                center={{ lat: parseFloat(this.state.loadedData?.attributes?.location?.lat) , lng: parseFloat(this.state.loadedData?.attributes?.location?.lng) }} /// lat: 47.497913, lng: 19.040236
                zoom={this.state.data?.length < 2 ? 9 : 7}
                places={this.state?.data}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height:'224px', width:'100%' }} />}
                />               
           </div>
           <div className="subtittleads">Munka részletes leírása</div>
           <div className="jobdescription">{parse(this.state.loadedData?.attributes?.description ?? "")}</div>
          </div>
        </div>
      </div>      
    );


  }
}






/*===================================================  
* Export the class
====================================================*/
export default withRouter(AdsDetails);




