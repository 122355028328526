import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";
import parse from 'html-react-parser';

const html = `

<h1 style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 14px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><h1 style="margin-bottom: 60px">Tartalomjegyz&eacute;k</h1></span></h1>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<ol>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Fogalom meghat&aacute;roz&aacute;sok &nbsp; &nbsp;</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Szem&eacute;lyes adatok kezel&eacute;s&eacute;re vonatkoz&oacute; elvek &nbsp; &nbsp;</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Adatkezel&eacute;s</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Ig&eacute;nybe vett adatfeldolgoz&oacute;k</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Cookie-k (s&uuml;tik) kezel&eacute;se</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>H&iacute;rlev&eacute;l, DM tev&eacute;kenys&eacute;g</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Az adatgyűjt&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re &eacute;s az adatkezel&eacute;s c&eacute;lja:&nbsp;</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>Panaszkezel&eacute;s</em></strong></span></li>
    <li style="margin: 0px 0px 6px; font-stretch: normal; font-size: 12px;  font-family: Maven Pro; letter-spacing: 2px; line-height: 1.15;"><span style="font-kerning: none;"><strong><em>&Uuml;gyf&eacute;lkapcsolatok &eacute;s egy&eacute;b adatkezel&eacute;sek &nbsp; &nbsp;</em></strong></span></li>
</ol>
    <p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 12px;"><br></p>
    <p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;">Mesterport&aacute;l Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g (Sz&eacute;khely: 8623 Balatonf&ouml;ldv&aacute;r, Kil&aacute;t&oacute; k&ouml;z 5., ad&oacute;sz&aacute;m: 26276852214, c&eacute;gjegyz&eacute;ksz&aacute;m: 14-09-316271) (a tov&aacute;bbiakban: Szolg&aacute;ltat&oacute;, adatkezelő) al&aacute; veti mag&aacute;t a k&ouml;vetkező t&aacute;j&eacute;koztat&oacute;nak.</p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><br>A term&eacute;szetes szem&eacute;lyeknek a szem&eacute;lyes adatok kezel&eacute;se tekintet&eacute;ben t&ouml;rt&eacute;nő v&eacute;delm&eacute;ről &eacute;s az ilyen adatok szabad &aacute;raml&aacute;s&aacute;r&oacute;l, valamint a 95/46/EK rendelet hat&aacute;lyon k&iacute;v&uuml;l helyez&eacute;s&eacute;ről (&aacute;ltal&aacute;nos adatv&eacute;delmi rendelet) AZ EUR&Oacute;PAI PARLAMENT &Eacute;S A TAN&Aacute;CS (EU) 2016/679 RENDELETE (2016. &aacute;prilis 27.) szerint az al&aacute;bbi t&aacute;j&eacute;koztat&aacute;st adjuk.</p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><br>Jelen adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; az al&aacute;bbi oldalak (weblapok/honlapok, webshopok/web&aacute;ruh&aacute;zak) adatkezel&eacute;s&eacute;t szab&aacute;lyozza: mesterportal.com</p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><br>A t&aacute;j&eacute;koztat&oacute; ill. annak m&oacute;dos&iacute;t&aacute;sai a fenti c&iacute;men t&ouml;rt&eacute;nő k&ouml;zz&eacute;t&eacute;tellel l&eacute;pnek hat&aacute;lyba. A Mesterport&aacute;l Kft. fenntartja mag&aacute;nak a jogot a jelen t&aacute;j&eacute;koztat&oacute; megv&aacute;ltoztat&aacute;s&aacute;ra, az esetleges v&aacute;ltoz&aacute;sokr&oacute;l kellő időben &eacute;rtes&iacute;ti felhaszn&aacute;l&oacute;it.</p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><br><strong>Az adatkezelő &eacute;s el&eacute;rhetős&eacute;gei:</strong></p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><br>N&eacute;v: Mesterport&aacute;l Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g</p>
    <p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;">Sz&eacute;khely: 8623 Balatonf&ouml;ldv&aacute;r, Kil&aacute;t&oacute; k&ouml;z 5.</p>
    <p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;">E-mail: info@mesterportal.com</p>
<ol>
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Fogalom meghat&aacute;roz&aacute;sok</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>&Eacute;rintett/Felhaszn&aacute;l&oacute;:</strong> b&aacute;rmely meghat&aacute;rozott, szem&eacute;lyes adat alapj&aacute;n azonos&iacute;tott vagy &ndash; k&ouml;zvetlen&uuml;l vagy k&ouml;zvetve &ndash; azonos&iacute;that&oacute; term&eacute;szetes szem&eacute;ly.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Szem&eacute;lyes adat:</strong> az &eacute;rintettel kapcsolatba hozhat&oacute; adat &ndash; k&uuml;l&ouml;n&ouml;sen az &eacute;rintett neve, azonos&iacute;t&oacute; jele, valamint egy vagy t&ouml;bb fizikai, fiziol&oacute;giai, ment&aacute;lis, gazdas&aacute;gi, kultur&aacute;lis vagy szoci&aacute;lis azonoss&aacute;g&aacute;ra jellemző ismeret -, valamint az adatb&oacute;l levonhat&oacute;, az &eacute;rintettre vonatkoz&oacute; k&ouml;vetkeztet&eacute;s.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Adatkezelő:</strong> az a term&eacute;szetes vagy jogi szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, aki vagy amely &ouml;n&aacute;ll&oacute;an vagy m&aacute;sokkal egy&uuml;tt az adatok kezel&eacute;s&eacute;nek c&eacute;lj&aacute;t meghat&aacute;rozza, az adatkezel&eacute;sre (bele&eacute;rtve a felhaszn&aacute;lt eszk&ouml;zt) vonatkoz&oacute; d&ouml;nt&eacute;seket meghozza &eacute;s v&eacute;grehajtja, vagy az &aacute;ltala megb&iacute;zott adatfeldolgoz&oacute;val v&eacute;grehajtatja.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Adatkezel&eacute;s:</strong> az alkalmazott elj&aacute;r&aacute;st&oacute;l f&uuml;ggetlen&uuml;l az adatokon v&eacute;gzett b&aacute;rmely művelet vagy a műveletek &ouml;sszess&eacute;ge, &iacute;gy k&uuml;l&ouml;n&ouml;sen gyűjt&eacute;se, felv&eacute;tele, r&ouml;gz&iacute;t&eacute;se, rendszerez&eacute;se, t&aacute;rol&aacute;sa, megv&aacute;ltoztat&aacute;sa, felhaszn&aacute;l&aacute;sa, lek&eacute;rdez&eacute;se, tov&aacute;bb&iacute;t&aacute;sa, nyilv&aacute;noss&aacute;gra hozatala, &ouml;sszehangol&aacute;sa vagy &ouml;sszekapcsol&aacute;sa, z&aacute;rol&aacute;sa, t&ouml;rl&eacute;se &eacute;s megsemmis&iacute;t&eacute;se, valamint az adatok tov&aacute;bbi felhaszn&aacute;l&aacute;s&aacute;nak megakad&aacute;lyoz&aacute;sa, f&eacute;nyk&eacute;p-, hang- vagy k&eacute;pfelv&eacute;tel k&eacute;sz&iacute;t&eacute;se, valamint a szem&eacute;ly azonos&iacute;t&aacute;s&aacute;ra alkalmas fizikai jellemzők (pl. ujj- vagy teny&eacute;rnyomat, DNS-minta, &iacute;riszk&eacute;p) r&ouml;gz&iacute;t&eacute;se;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Adatfeldolgoz&aacute;s:</strong> az adatkezel&eacute;si műveletekhez kapcsol&oacute;d&oacute; technikai feladatok elv&eacute;gz&eacute;se, f&uuml;ggetlen&uuml;l a műveletek v&eacute;grehajt&aacute;s&aacute;hoz alkalmazott m&oacute;dszertől &eacute;s eszk&ouml;ztől, valamint az alkalmaz&aacute;s hely&eacute;től, felt&eacute;ve, hogy a technikai feladatot az adatokon v&eacute;gzik;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Adatfeldolgoz&oacute;:</strong> az a term&eacute;szetes vagy jogi szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, aki vagy amely az adatkezelővel k&ouml;t&ouml;tt szerződ&eacute;se alapj&aacute;n &ndash; bele&eacute;rtve a jogszab&aacute;ly rendelkez&eacute;se alapj&aacute;n t&ouml;rt&eacute;nő szerződ&eacute;sk&ouml;t&eacute;st is &ndash; adatok feldolgoz&aacute;s&aacute;t v&eacute;gzi;</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Adatv&eacute;delmi incidens:</strong> szem&eacute;lyes adat jogellenes kezel&eacute;se vagy feldolgoz&aacute;sa, &iacute;gy k&uuml;l&ouml;n&ouml;sen a jogosulatlan hozz&aacute;f&eacute;r&eacute;s, megv&aacute;ltoztat&aacute;s, tov&aacute;bb&iacute;t&aacute;s, nyilv&aacute;noss&aacute;gra hozatal, t&ouml;rl&eacute;s vagy megsemmis&iacute;t&eacute;s, valamint a v&eacute;letlen megsemmis&uuml;l&eacute;s &eacute;s s&eacute;r&uuml;l&eacute;s.</span></p>
<ol start="2">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Szem&eacute;lyes adatok kezel&eacute;s&eacute;re vonatkoz&oacute; elvek</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Szem&eacute;lyes adatok:</strong>&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">a) kezel&eacute;s&eacute;t jogszerűen &eacute;s tisztess&eacute;gesen, valamint az &eacute;rintett sz&aacute;m&aacute;ra &aacute;tl&aacute;that&oacute; m&oacute;don kell v&eacute;gezni (&bdquo;jogszerűs&eacute;g, tisztess&eacute;ges elj&aacute;r&aacute;s &eacute;s &aacute;tl&aacute;that&oacute;s&aacute;g&rdquo;);</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">b) gyűjt&eacute;se csak meghat&aacute;rozott, egy&eacute;rtelmű &eacute;s jogszerű c&eacute;lb&oacute;l t&ouml;rt&eacute;njen, &eacute;s azokat ne kezelj&eacute;k ezekkel a c&eacute;lokkal &ouml;ssze nem egyeztethető m&oacute;don; a 89. cikk (1) bekezd&eacute;s&eacute;nek megfelelően nem minős&uuml;l az eredeti c&eacute;llal &ouml;ssze nem egyeztethetőnek a k&ouml;z&eacute;rdekű archiv&aacute;l&aacute;s c&eacute;lj&aacute;b&oacute;l, tudom&aacute;nyos &eacute;s t&ouml;rt&eacute;nelmi kutat&aacute;si c&eacute;lb&oacute;l vagy statisztikai c&eacute;lb&oacute;l t&ouml;rt&eacute;nő tov&aacute;bbi adatkezel&eacute;s (&bdquo;c&eacute;lhoz k&ouml;t&ouml;tts&eacute;g&rdquo;);</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">c) az adatkezel&eacute;s c&eacute;ljai szempontj&aacute;b&oacute;l megfelelőek &eacute;s relev&aacute;nsak kell, hogy legyenek, &eacute;s a sz&uuml;ks&eacute;gesre kell korl&aacute;toz&oacute;dniuk (&bdquo;adattakar&eacute;koss&aacute;g&rdquo;);</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">d) pontosnak &eacute;s sz&uuml;ks&eacute;g eset&eacute;n naprak&eacute;sznek kell lenni&uuml;k; minden &eacute;szszerű int&eacute;zked&eacute;st meg kell tenni annak &eacute;rdek&eacute;ben, hogy az adatkezel&eacute;s c&eacute;ljai szempontj&aacute;b&oacute;l pontatlan szem&eacute;lyes adatokat halad&eacute;ktalanul t&ouml;r&ouml;lj&eacute;k vagy helyesb&iacute;ts&eacute;k (&bdquo;pontoss&aacute;g&rdquo;);</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">e) t&aacute;rol&aacute;s&aacute;nak olyan form&aacute;ban kell t&ouml;rt&eacute;nnie, amely az &eacute;rintettek azonos&iacute;t&aacute;s&aacute;t csak a szem&eacute;lyes adatok kezel&eacute;se c&eacute;ljainak el&eacute;r&eacute;s&eacute;hez sz&uuml;ks&eacute;ges ideig teszi lehetőv&eacute;; a szem&eacute;lyes adatok enn&eacute;l hosszabb ideig t&ouml;rt&eacute;nő t&aacute;rol&aacute;s&aacute;ra csak akkor ker&uuml;lhet sor, amennyiben a szem&eacute;lyes adatok kezel&eacute;s&eacute;re a 89. cikk (1) bekezd&eacute;s&eacute;nek megfelelően k&ouml;z&eacute;rdekű archiv&aacute;l&aacute;s c&eacute;lj&aacute;b&oacute;l, tudom&aacute;nyos &eacute;s t&ouml;rt&eacute;nelmi kutat&aacute;si c&eacute;lb&oacute;l vagy statisztikai c&eacute;lb&oacute;l ker&uuml;l majd sor, az e rendeletben az &eacute;rintettek jogainak &eacute;s szabads&aacute;gainak v&eacute;delme &eacute;rdek&eacute;ben elő&iacute;rt megfelelő technikai &eacute;s szervez&eacute;si int&eacute;zked&eacute;sek v&eacute;grehajt&aacute;s&aacute;ra is figyelemmel (&bdquo;korl&aacute;tozott t&aacute;rolhat&oacute;s&aacute;g&rdquo;);</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">f) kezel&eacute;s&eacute;t oly m&oacute;don kell v&eacute;gezni, hogy megfelelő technikai vagy szervez&eacute;si int&eacute;zked&eacute;sek alkalmaz&aacute;s&aacute;val biztos&iacute;tva legyen a szem&eacute;lyes adatok megfelelő biztons&aacute;ga, az adatok jogosulatlan vagy jogellenes kezel&eacute;s&eacute;vel, v&eacute;letlen elveszt&eacute;s&eacute;vel, megsemmis&iacute;t&eacute;s&eacute;vel vagy k&aacute;rosod&aacute;s&aacute;val szembeni v&eacute;delmet is ide&eacute;rtve (&bdquo;integrit&aacute;s &eacute;s bizalmas jelleg&rdquo;).</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezelő felelős a fentiek megfelel&eacute;s&eacute;rt, tov&aacute;bb&aacute; k&eacute;pesnek kell lennie e megfelel&eacute;s igazol&aacute;s&aacute;ra (&bdquo;elsz&aacute;moltathat&oacute;s&aacute;g&rdquo;).</span></p>
<ol start="3">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Adatkezel&eacute;s</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Mesterport&aacute;l Kft. adatkezel&eacute;si c&eacute;lk&eacute;nt az al&aacute;bbi c&eacute;lokat jel&ouml;li meg: regisztr&aacute;ci&oacute; a rendel&eacute;s &eacute;s szolg&aacute;ltat&aacute;s ig&eacute;nybev&eacute;tel gyorsabb lead&aacute;sa &eacute;rdek&eacute;ben, online piact&eacute;r &uuml;zemeltet&eacute;s&eacute;vel kapcsolatos feladatok (a v&aacute;s&aacute;rl&oacute;k nyilv&aacute;ntart&aacute;sa, szolg&aacute;ltat&aacute;s megrendel&eacute;sek illetve szolg&aacute;ltat&aacute;sok teljes&iacute;t&eacute;se, megk&ouml;nny&iacute;t&eacute;se, v&aacute;s&aacute;rl&oacute;i &eacute;s elad&oacute;i kapcsolattart&aacute;s stb.), h&iacute;rlevelek k&uuml;ld&eacute;se, az oldalaival kapcsolatos marketingtev&eacute;kenys&eacute;g, időszakos prom&oacute;ci&oacute;k, nyerem&eacute;nyj&aacute;t&eacute;kok szervez&eacute;se &eacute;s lebonyol&iacute;t&aacute;sa.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Web&aacute;ruh&aacute;z &eacute;s a weboldalak műk&ouml;dtet&eacute;shez kapcsol&oacute;d&oacute; adatkezel&eacute;s</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><br>&nbsp;Az adatgyűjt&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re &eacute;s az adatkezel&eacute;s c&eacute;lja:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Szem&eacute;lyes adat</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adatkezel&eacute;s c&eacute;lja</strong></span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Vezet&eacute;k-&eacute;s keresztn&eacute;v: A kapcsolatfelv&eacute;telhez, a v&aacute;s&aacute;rl&aacute;shoz &eacute;s a szab&aacute;lyszerű sz&aacute;mla ki&aacute;ll&iacute;t&aacute;s&aacute;hoz sz&uuml;ks&eacute;ges.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Jelsz&oacute;: A felhaszn&aacute;l&oacute;i fi&oacute;kba t&ouml;rt&eacute;nő biztons&aacute;gos bel&eacute;p&eacute;st szolg&aacute;lja.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">E-mail c&iacute;m: Kapcsolattart&aacute;s</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Telefonsz&aacute;m: Kapcsolattart&aacute;s, a sz&aacute;ml&aacute;z&aacute;ssal, vagy a sz&aacute;ll&iacute;t&aacute;ssal kapcsolatos k&eacute;rd&eacute;sek hat&eacute;konyabb egyeztet&eacute;se, szolg&aacute;ltat&aacute;sok teljes&iacute;t&eacute;s&eacute;nek egyszerűbb&eacute; t&eacute;tele</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Sz&aacute;ml&aacute;z&aacute;si n&eacute;v &eacute;s c&iacute;m: A szab&aacute;lyszerű sz&aacute;mla ki&aacute;ll&iacute;t&aacute;sa, tov&aacute;bb&aacute; a szerződ&eacute;s l&eacute;trehoz&aacute;sa, tartalm&aacute;nak meghat&aacute;roz&aacute;sa, m&oacute;dos&iacute;t&aacute;sa, teljes&iacute;t&eacute;s&eacute;nek figyelemmel k&iacute;s&eacute;r&eacute;se, az abb&oacute;l sz&aacute;rmaz&oacute; d&iacute;jak sz&aacute;ml&aacute;z&aacute;sa, valamint az azzal kapcsolatos k&ouml;vetel&eacute;sek &eacute;rv&eacute;nyes&iacute;t&eacute;se.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Munkav&eacute;gz&eacute;s helysz&iacute;ne: A szolg&aacute;ltat&aacute;s elv&eacute;gz&eacute;s&eacute;nek helysz&iacute;ne, mely alapj&aacute;n a szolg&aacute;ltat&oacute; &aacute;raj&aacute;nlatot tud adni.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A v&aacute;s&aacute;rl&aacute;s/regisztr&aacute;ci&oacute; időpontja: Technikai művelet v&eacute;grehajt&aacute;sa.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A v&aacute;s&aacute;rl&aacute;s/regisztr&aacute;ci&oacute; kori IP c&iacute;m: Technikai művelet v&eacute;grehajt&aacute;sa.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az e-mail c&iacute;m &eacute;s a felhaszn&aacute;l&oacute;n&eacute;v eset&eacute;ben nem sz&uuml;ks&eacute;ges, hogy szem&eacute;lyes adatot tartalmazzon.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az &eacute;rintettek k&ouml;re</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az Mesterport&aacute;l online piact&eacute;ren regisztr&aacute;lt valamennyi v&aacute;s&aacute;rl&oacute; vagy elad&oacute; &eacute;rintett.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adatkezel&eacute;s időtartama, az adatok t&ouml;rl&eacute;s&eacute;nek hat&aacute;rideje</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A regisztr&aacute;ci&oacute; t&ouml;rl&eacute;s&eacute;vel azonnal. Az &eacute;rintett &aacute;ltal megadott b&aacute;rmely szem&eacute;lyes adat t&ouml;rl&eacute;s&eacute;ről az adatkezelő a GDPR 19. cikke alapj&aacute;n, elektronikus &uacute;ton t&aacute;j&eacute;koztatja az &eacute;rintettet. Ha az &eacute;rintett t&ouml;rl&eacute;si k&eacute;relme kiterjed az &aacute;ltala megadott e-mail c&iacute;mre is, akkor az adatkezelő a t&aacute;j&eacute;koztat&aacute;st k&ouml;vetően az e-mail c&iacute;met is t&ouml;rli. Kiv&eacute;ve a sz&aacute;mviteli bizonylatok eset&eacute;ben, hiszen a sz&aacute;mvitelről sz&oacute;l&oacute; 2000. &eacute;vi C. t&ouml;rv&eacute;ny 169. &sect; (2) bekezd&eacute;se alapj&aacute;n 8 &eacute;vig meg kell őrizni ezeket az adatokat. A k&ouml;nyvviteli elsz&aacute;mol&aacute;st k&ouml;zvetlen&uuml;l &eacute;s k&ouml;zvetetten al&aacute;t&aacute;maszt&oacute; sz&aacute;mviteli bizonylatot (ide&eacute;rtve a fők&ouml;nyvi sz&aacute;ml&aacute;kat, az analitikus, illetve r&eacute;szletező nyilv&aacute;ntart&aacute;sokat is), legal&aacute;bb 8 &eacute;vig kell olvashat&oacute; form&aacute;ban, a k&ouml;nyvel&eacute;si feljegyz&eacute;sek hivatkoz&aacute;sa alapj&aacute;n visszakereshető m&oacute;don megőrizni. Az adatok megismer&eacute;s&eacute;re jogosult lehets&eacute;ges adatkezelők szem&eacute;lye, a szem&eacute;lyes adatok c&iacute;mzettjei: A szem&eacute;lyes adatokat az adatkezelőnek az erre felhatalmazott munkat&aacute;rsai kezelhetik a jelen t&aacute;j&eacute;koztat&oacute;ban foglaltak alapj&aacute;n.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se:</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintett k&eacute;relmezheti az adatkezelőtől a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, &eacute;s az &eacute;rintettnek joga van az adathordozhat&oacute;s&aacute;ghoz, tov&aacute;bb&aacute; a hozz&aacute;j&aacute;rul&aacute;s b&aacute;rmely időpontban t&ouml;rt&eacute;nő visszavon&aacute;s&aacute;hoz.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok t&ouml;rl&eacute;s&eacute;t, m&oacute;dos&iacute;t&aacute;s&aacute;t, vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, az adatok hordozhat&oacute;s&aacute;g&aacute;t, az adatkezel&eacute;sek elleni tiltakoz&aacute;st az al&aacute;bbi m&oacute;dokon tudja &eacute;rintett kezdem&eacute;nyezni: e-mail &uacute;tj&aacute;n az info@Mesterport&aacute;l.com c&iacute;men, &eacute;s postai &uacute;ton: Mesterport&aacute;l Kft., 8623 Balatonf&ouml;ldv&aacute;r, J&oacute;zsef Attila utca 13/A.</span></p>
<ol start="4">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Ig&eacute;nybe vett adatfeldolgoz&oacute;k</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>T&aacute;rhely-szolg&aacute;ltat&oacute;</strong>&nbsp;</span></p>
<p style="margin: 0px 0px 5px; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatfeldolgoz&oacute; &aacute;ltal ell&aacute;tott tev&eacute;kenys&eacute;g: T&aacute;rhely-szolg&aacute;ltat&aacute;s</span></p>
<p style="margin: 0px 0px 5px; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatfeldolgoz&oacute; megnevez&eacute;se &eacute;s el&eacute;rhetős&eacute;ge: Magyar Hosting Kft.,</span><span style='font-stretch: normal; line-height: normal; font-family: "Helvetica Neue"; -webkit-font-kerning: none; color: rgb(255, 255, 255); background-color: rgb(255, 255, 255);'>&nbsp;</span><span style="font-kerning: none;">1132 Budapest, Victor Hugo u. 18-22., <a href="mailto:info@mhosting.hu"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 255);">info@mhosting.hu</span></a></span></p>
<p style="margin: 0px 0px 5px; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><br>&nbsp;Az adatkezel&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re: Az &eacute;rintett &aacute;ltal megadott valamennyi szem&eacute;lyes adat.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek k&ouml;re:A weboldalt haszn&aacute;l&oacute; valamennyi felhaszn&aacute;l&oacute;.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s c&eacute;lja: A weboldal el&eacute;rhetőv&eacute; t&eacute;tele, megfelelő műk&ouml;dtet&eacute;se.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s időtartama, az adatok t&ouml;rl&eacute;s&eacute;nek hat&aacute;rideje: Az adatkezelő &eacute;s a t&aacute;rhely-szolg&aacute;ltat&oacute; k&ouml;z&ouml;tti meg&aacute;llapod&aacute;s megszűn&eacute;s&eacute;ig, vagy az &eacute;rintettnek a t&aacute;rhely-szolg&aacute;ltat&oacute; fel&eacute; int&eacute;zett t&ouml;rl&eacute;si k&eacute;relm&eacute;ig tart az adatkezel&eacute;s.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatfeldolgoz&aacute;s jogalapja: a 6. cikk (1) bekezd&eacute;s c) &eacute;s f) pontja, illetve az elektronikus kereskedelemi szolg&aacute;ltat&aacute;sok, valamint az inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok egyes k&eacute;rd&eacute;seiről sz&oacute;l&oacute; 2001. &eacute;vi CVIII. t&ouml;rv&eacute;ny 13/A. &sect; (3) bekezd&eacute;se.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; a szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;sa c&eacute;lj&aacute;b&oacute;l kezelheti azon szem&eacute;lyes adatokat, amelyek a szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;hoz technikailag elengedhetetlen&uuml;l sz&uuml;ks&eacute;gesek. A szolg&aacute;ltat&oacute;nak az egy&eacute;b felt&eacute;telek azonoss&aacute;ga eset&eacute;n &uacute;gy kell megv&aacute;lasztania &eacute;s minden esetben oly m&oacute;don kell &uuml;zemeltetnie az inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;sa sor&aacute;n alkalmazott eszk&ouml;z&ouml;ket, hogy szem&eacute;lyes adatok kezel&eacute;s&eacute;re csak akkor ker&uuml;lj&ouml;n sor, ha ez a szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;hoz &eacute;s az e t&ouml;rv&eacute;nyben meghat&aacute;rozott egy&eacute;b c&eacute;lok teljes&uuml;l&eacute;s&eacute;hez felt&eacute;tlen&uuml;l sz&uuml;ks&eacute;ges, azonban ebben az esetben is csak a sz&uuml;ks&eacute;ges m&eacute;rt&eacute;kben &eacute;s ideig.</span></p>
<ol start="5">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Cookie-k (s&uuml;tik) kezel&eacute;se</span></li>
</ol>
<p style="margin: 0px; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Weboldalakra jellemző cookie-k az &uacute;gynevezett &bdquo;jelsz&oacute;val v&eacute;dett munkamenethez haszn&aacute;lt cookie&rdquo;, &bdquo;bev&aacute;s&aacute;rl&oacute;kos&aacute;rhoz sz&uuml;ks&eacute;ges cookie-k&rdquo; &eacute;s &bdquo;biztons&aacute;gi cookie-k&rdquo;, melyek haszn&aacute;lat&aacute;hoz nem sz&uuml;ks&eacute;ges előzetes hozz&aacute;j&aacute;rul&aacute;st k&eacute;rni az &eacute;rintettektől.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re: egyedi azonos&iacute;t&oacute;sz&aacute;m, d&aacute;tumok, időpontok.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek k&ouml;re: A weboldalt l&aacute;togat&oacute; valamennyi &eacute;rintett.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s c&eacute;lja: A felhaszn&aacute;l&oacute;k azonos&iacute;t&aacute;sa, a &bdquo;bev&aacute;s&aacute;rl&oacute;kos&aacute;r&rdquo; nyilv&aacute;ntart&aacute;s&aacute;ra &eacute;s a l&aacute;togat&oacute;k nyomon k&ouml;vet&eacute;se.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">S&uuml;ti t&iacute;pusa: Munkamenet s&uuml;tik (session)</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatkezel&eacute;s jogalapja: Az elektronikus kereskedelmi szolg&aacute;ltat&aacute;sok, valamint az inform&aacute;ci&oacute;s t&aacute;rsadalmi szolg&aacute;ltat&aacute;sok egyes k&eacute;rd&eacute;seiről sz&oacute;l&oacute; 2001. CVIII. t&ouml;rv&eacute;ny (Elkertv.) 13/A. &sect; (3) bekezd&eacute;se</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatkezel&eacute;s időtartama: A vonatkoz&oacute; l&aacute;togat&oacute;i munkamenet lez&aacute;r&aacute;s&aacute;ig tart&oacute; időszak</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Kezelt adatk&ouml;r: connect.sid</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatok megismer&eacute;s&eacute;re jogosult lehets&eacute;ges adatkezelők szem&eacute;lye: A cookie-k haszn&aacute;lat&aacute;val nem kezel szem&eacute;lyes adatokat az adatkezelő.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se: Az &eacute;rintettnek lehetős&eacute;g&uuml;k van a cookie-kat t&ouml;r&ouml;lni a b&ouml;ng&eacute;szők Eszk&ouml;z&ouml;k/Be&aacute;ll&iacute;t&aacute;sok men&uuml;j&eacute;ben &aacute;ltal&aacute;ban az Adatv&eacute;delem men&uuml;pont be&aacute;ll&iacute;t&aacute;sai alatt.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s jogalapja: Az &eacute;rintettől hozz&aacute;j&aacute;rul&aacute;s nem sz&uuml;ks&eacute;ges, amennyiben a cookie-k haszn&aacute;lat&aacute;nak kiz&aacute;r&oacute;lagos c&eacute;lja az elektronikus h&iacute;rk&ouml;zlő h&aacute;l&oacute;zaton kereszt&uuml;l t&ouml;rt&eacute;nő k&ouml;zl&eacute;stov&aacute;bb&iacute;t&aacute;s vagy arra az előfizető vagy felhaszn&aacute;l&oacute; &aacute;ltal kifejezetten k&eacute;rt, az inform&aacute;ci&oacute;s t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;s ny&uacute;jt&aacute;s&aacute;hoz a szolg&aacute;ltat&oacute;nak felt&eacute;tlen&uuml;l sz&uuml;ks&eacute;ge van.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se: Az &eacute;rintettnek lehetős&eacute;g&uuml;k van a cookie-kat t&ouml;r&ouml;lni a b&ouml;ng&eacute;szők Eszk&ouml;z&ouml;k/Be&aacute;ll&iacute;t&aacute;sok men&uuml;j&eacute;ben &aacute;ltal&aacute;ban az Adatv&eacute;delem men&uuml;pont be&aacute;ll&iacute;t&aacute;sai alatt. S&uuml;tik (Cookie-k) t&ouml;rl&eacute;se az al&aacute;bbi b&ouml;ng&eacute;szők eset&eacute;ben a mellet&uuml;k jel&ouml;lt linken tal&aacute;lhat&oacute; inform&aacute;ci&oacute; szerint.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Figyelem: a lejjebb jel&ouml;lt b&ouml;ng&eacute;szők -emellett az olyanok is, melyek m&eacute;g kev&eacute;sb&eacute; ismertek, vagy ak&aacute;r a j&ouml;vőben ker&uuml;lnek piacra- folyamatosan friss&uuml;lnek, ez&eacute;rt javasolt a szolg&aacute;ltat&oacute; felkeres&eacute;se az aktu&aacute;lis be&aacute;ll&iacute;t&aacute;si lehetős&eacute;gek felm&eacute;r&eacute;s&eacute;re, valamint adatv&eacute;delmi ir&aacute;nyelveik megismer&eacute;s&eacute;re!</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Mozilla:&nbsp;https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Google Chrome:&nbsp;https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=hu</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Internet Explorer: <a href="https://support.microsoft.com/hu-hu/help/278835/how-to-delete-cookie-files-in-internet-explorer"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 0);">https://support.microsoft.com/hu-hu/help/278835/how-to-delete-cookie-files-in-internet-explorer</span></a></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Safari (iOS rendszerek): <a href="https://support.apple.com/hu-hu/HT201265"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 0);">https://support.apple.com/hu-hu/HT201265/</span></a></span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">S&uuml;tiket kezel m&eacute;g tov&aacute;bb&aacute;:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Google: <a href="https://policies.google.com/technologies/cookies"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 0);">https://policies.google.com/technologies/cookies</span></a></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Facebook: <a href="https://hu-hu.facebook.com/policies/cookies/"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 0);">https://hu-hu.facebook.com/policies/cookies/</span></a></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Google Adwords konverzi&oacute;k&ouml;vet&eacute;s haszn&aacute;lata</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A &bdquo;Google AdWords&rdquo; nevű online rekl&aacute;mprogramot haszn&aacute;lja az adatkezelő, tov&aacute;bb&aacute; annak keretein bel&uuml;l ig&eacute;nybe veszi a Google konverzi&oacute;k&ouml;vető szolg&aacute;ltat&aacute;s&aacute;t. A Google konverzi&oacute;k&ouml;vet&eacute;s a Google Inc. elemző szolg&aacute;ltat&aacute;sa (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Amikor a Felhaszn&aacute;l&oacute; egy weboldalt Google-hirdet&eacute;s &aacute;ltal &eacute;r el, akkor egy a konverzi&oacute;k&ouml;vet&eacute;shez sz&uuml;ks&eacute;ges cookie ker&uuml;l a sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;re. Ezeknek a cookie-knak az &eacute;rv&eacute;nyess&eacute;ge korl&aacute;tozott, &eacute;s nem tartalmaznak semmilyen szem&eacute;lyes adatot, &iacute;gy a Felhaszn&aacute;l&oacute; nem is azonos&iacute;that&oacute; &aacute;ltaluk. Amikor a Felhaszn&aacute;l&oacute; a weboldal bizonyos oldalait b&ouml;ng&eacute;szi, &eacute;s a cookie m&eacute;g nem j&aacute;rt le, akkor a Google &eacute;s az adatkezelő is l&aacute;thatja, hogy a Felhaszn&aacute;l&oacute; a hirdet&eacute;sre kattintott. Minden Google AdWords &uuml;gyf&eacute;l m&aacute;sik cookie-t kap, &iacute;gy azokat az AdWords &uuml;gyfeleinek weboldalain kereszt&uuml;l nem lehet nyomon k&ouml;vetni.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az inform&aacute;ci&oacute;k &ndash; melyeket a konverzi&oacute;k&ouml;vető cookie-k seg&iacute;ts&eacute;g&eacute;vel szereztek &ndash; azt a c&eacute;lt szolg&aacute;lj&aacute;k, hogy az AdWords konverzi&oacute;k&ouml;vet&eacute;st v&aacute;laszt&oacute; &uuml;gyfeleinek sz&aacute;m&aacute;ra konverzi&oacute;s statisztik&aacute;kat k&eacute;sz&iacute;tsenek. Az &uuml;gyfelek &iacute;gy t&aacute;j&eacute;koz&oacute;dnak a hirdet&eacute;s&uuml;kre kattint&oacute; &eacute;s konverzi&oacute;k&ouml;vető c&iacute;mk&eacute;vel ell&aacute;tott oldalra tov&aacute;bb&iacute;tott felhaszn&aacute;l&oacute;k sz&aacute;m&aacute;r&oacute;l. Azonban olyan inform&aacute;ci&oacute;khoz nem jutnak hozz&aacute;, melyekkel b&aacute;rmelyik felhaszn&aacute;l&oacute;t azonos&iacute;tani lehetne. Ha nem szeretne r&eacute;szt venni a konverzi&oacute;k&ouml;vet&eacute;sben, akkor ezt elutas&iacute;thatja az&aacute;ltal, hogy b&ouml;ng&eacute;szőj&eacute;ben letiltja a cookie-k telep&iacute;t&eacute;s&eacute;nek lehetős&eacute;g&eacute;t. Ezut&aacute;n &Ouml;n nem fog szerepelni a konverzi&oacute;k&ouml;vet&eacute;si statisztik&aacute;kban.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Tov&aacute;bbi inform&aacute;ci&oacute; valamint a Google adatv&eacute;delmi nyilatkozata az al&aacute;bbi oldalon &eacute;rhető el: <a href="https://policies.google.com/privacy?gl=de"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 255);">https://policies.google.com/privacy?gl=de</span></a></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Google Analytics alkalmaz&aacute;sa</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ez a honlap a Google Analytics alkalmaz&aacute;st haszn&aacute;lja, amely a Google Inc. (&bdquo;Google&rdquo;) webelemző szolg&aacute;ltat&aacute;sa. A Google Analytics &uacute;gynevezett &bdquo;cookie-kat&rdquo;, sz&ouml;vegf&aacute;jlokat haszn&aacute;l, amelyeket a sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;re mentenek, &iacute;gy előseg&iacute;tik Felhaszn&aacute;l&oacute; &aacute;ltal l&aacute;togatott weblap haszn&aacute;lat&aacute;nak elemz&eacute;s&eacute;t.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Felhaszn&aacute;l&oacute; &aacute;ltal haszn&aacute;lt weboldallal kapcsolatos cookie-kkal l&eacute;trehozott inform&aacute;ci&oacute;k rendszerint a Google egyik USA-beli szerver&eacute;re ker&uuml;lnek &eacute;s t&aacute;rol&oacute;dnak. Az IP-anonimiz&aacute;l&aacute;s weboldali aktiv&aacute;l&aacute;s&aacute;val a Google a Felhaszn&aacute;l&oacute; IP-c&iacute;m&eacute;t az Eur&oacute;pai Uni&oacute; tag&aacute;llamain bel&uuml;l vagy az Eur&oacute;pai Gazdas&aacute;gi T&eacute;rs&eacute;gről sz&oacute;l&oacute; meg&aacute;llapod&aacute;sban r&eacute;szes m&aacute;s &aacute;llamokban előzőleg megr&ouml;vid&iacute;ti.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A teljes IP-c&iacute;mnek a Google USA-ban l&eacute;vő szerver&eacute;re t&ouml;rt&eacute;nő tov&aacute;bb&iacute;t&aacute;s&aacute;ra &eacute;s ottani ler&ouml;vid&iacute;t&eacute;s&eacute;re csak kiv&eacute;teles esetekben ker&uuml;l sor. Eme weboldal &uuml;zemeltetőj&eacute;nek megb&iacute;z&aacute;s&aacute;b&oacute;l a Google ezeket az inform&aacute;ci&oacute;kat arra fogja haszn&aacute;lni, hogy ki&eacute;rt&eacute;kelje, hogyan haszn&aacute;lta a Felhaszn&aacute;l&oacute; a honlapot, tov&aacute;bb&aacute;, hogy a weboldal &uuml;zemeltetőj&eacute;nek a honlap aktivit&aacute;s&aacute;val &ouml;sszef&uuml;ggő jelent&eacute;seket k&eacute;sz&iacute;tsen, valamint, hogy a weboldal- &eacute;s az internethaszn&aacute;lattal kapcsolatos tov&aacute;bbi szolg&aacute;ltat&aacute;sokat teljes&iacute;tsen.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Google Analytics keretein bel&uuml;l a Felhaszn&aacute;l&oacute; b&ouml;ng&eacute;szője &aacute;ltal tov&aacute;bb&iacute;tott IP-c&iacute;met nem vezeti &ouml;ssze a Google m&aacute;s adataival. A cookie-k t&aacute;rol&aacute;s&aacute;t a Felhaszn&aacute;l&oacute; a b&ouml;ng&eacute;szőj&eacute;nek megfelelő be&aacute;ll&iacute;t&aacute;s&aacute;val megakad&aacute;lyozhatja, azonban felh&iacute;vjuk figyelm&eacute;t, hogy ebben az esetben előfordulhat, hogy ennek a honlapnak nem minden funkci&oacute;ja lesz teljes k&ouml;rűen haszn&aacute;lhat&oacute;. Megakad&aacute;lyozhatja tov&aacute;bb&aacute;, hogy a Google gyűjtse &eacute;s feldolgozza a cookie-k &aacute;ltali, a Felhaszn&aacute;l&oacute; weboldalhaszn&aacute;lattal kapcsolatos adatait (bele&eacute;rtve az IP-c&iacute;met is), ha let&ouml;lti &eacute;s telep&iacute;ti a k&ouml;vetkező linken el&eacute;rhető b&ouml;ng&eacute;sző plugint: <a href="https://tools.google.com/dlpage/gaoptout?hl=hu"><span style="font-stretch: normal; line-height: normal; -webkit-font-kerning: none; color: rgb(0, 0, 255);">https://tools.google.com/dlpage/gaoptout?hl=hu</span></a></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Facebook Pixel</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Mesterport&aacute;l &nbsp;a honlapba, (vagy a Mesterport&aacute;l b&aacute;rmely aloldal&aacute;ba) &uacute;gynevezett Facebook pixel eszk&ouml;z haszn&aacute;lat&aacute;val JavaScript-k&oacute;dot &aacute;gyaz be, amely &uuml;zenetet k&uuml;ld a Facebook-nak, amikor a felhaszn&aacute;l&oacute; felkeresi az oldalt vagy v&eacute;grehajt ott egy műveletet. Ez egyr&eacute;szt az Adatkezelő rekl&aacute;mra ford&iacute;tott k&ouml;lts&eacute;gei megt&eacute;r&uuml;l&eacute;s&eacute;nek &eacute;rtelmez&eacute;s&eacute;t seg&iacute;ti, m&aacute;sr&eacute;szt megk&ouml;nny&iacute;ti, hogy olyan felhaszn&aacute;l&oacute;k sz&aacute;m&aacute;ra jelen&iacute;tse meg hirdet&eacute;seit, akikn&eacute;l nagyobb val&oacute;sz&iacute;nűs&eacute;gű a Facebook-on k&iacute;v&uuml;li konverzi&oacute;. A Facebook pixelt szem&eacute;lyes adatokat nem gyűjt, nem t&aacute;rol, nem tov&aacute;bb&iacute;t. A Facebook pixel haszn&aacute;lat&aacute;r&oacute;l, műk&ouml;d&eacute;s&eacute;ről a www.facebook.com oldalon tal&aacute;lhat&oacute; bővebb inform&aacute;ci&oacute;.&nbsp;</span></p>
<ol start="6">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">H&iacute;rlev&eacute;l, DM tev&eacute;kenys&eacute;g</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A gazdas&aacute;gi rekl&aacute;mtev&eacute;kenys&eacute;g alapvető felt&eacute;teleiről &eacute;s egyes korl&aacute;tair&oacute;l sz&oacute;l&oacute; 2008. &eacute;vi XLVIII. t&ouml;rv&eacute;ny 6. &sect;-a &eacute;rtelm&eacute;ben Felhaszn&aacute;l&oacute; előzetesen &eacute;s kifejezetten hozz&aacute;j&aacute;rulhat ahhoz, hogy Szolg&aacute;ltat&oacute; rekl&aacute;maj&aacute;nlataival, egy&eacute;b k&uuml;ldem&eacute;nyeivel a regisztr&aacute;ci&oacute;kor megadott el&eacute;rhetős&eacute;gein (pl. elektronikus lev&eacute;lc&iacute;m, vagy telefonsz&aacute;m) megkeresse. Tov&aacute;bb&aacute; Felhaszn&aacute;l&oacute; a jelen t&aacute;j&eacute;koztat&oacute; rendelkez&eacute;seit szem előtt tartva hozz&aacute;j&aacute;rulhat ahhoz, hogy Szolg&aacute;ltat&oacute; a rekl&aacute;maj&aacute;nlatok k&uuml;ld&eacute;s&eacute;hez sz&uuml;ks&eacute;ges szem&eacute;lyes adatait kezelje.&nbsp;</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Szolg&aacute;ltat&oacute; nem k&uuml;ld k&eacute;retlen rekl&aacute;m&uuml;zenetet, &eacute;s Felhaszn&aacute;l&oacute; korl&aacute;toz&aacute;s &eacute;s indokol&aacute;s n&eacute;lk&uuml;l, ingyenesen leiratkozhat az aj&aacute;nlatok k&uuml;ld&eacute;s&eacute;ről. Ebben az esetben Szolg&aacute;ltat&oacute; minden &ndash; a rekl&aacute;m&uuml;zenetek k&uuml;ld&eacute;s&eacute;hez sz&uuml;ks&eacute;ges &ndash; szem&eacute;lyes adat&aacute;t t&ouml;rli nyilv&aacute;ntart&aacute;s&aacute;b&oacute;l &eacute;s tov&aacute;bbi rekl&aacute;maj&aacute;nlataival nem keresi meg a Felhaszn&aacute;l&oacute;t. Felhaszn&aacute;l&oacute; a rekl&aacute;mokr&oacute;l leiratkozhat az &uuml;zenetben l&eacute;vő linkre kattintva.&nbsp;</span></p>
<ol start="7">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Az adatgyűjt&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re &eacute;s az adatkezel&eacute;s c&eacute;lja:</span></li>
</ol>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 11px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Szem&eacute;lyes adat</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adatkezel&eacute;s c&eacute;lja</strong></span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">N&eacute;v, e-mail c&iacute;m: Azonos&iacute;t&aacute;s, a h&iacute;rlev&eacute;lre val&oacute; feliratkoz&aacute;s lehetőv&eacute; t&eacute;tele</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A feliratkoz&aacute;s időpontja: Technikai művelet v&eacute;grehajt&aacute;sa</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A feliratkoz&aacute;s kori IP c&iacute;m: Technikai művelet v&eacute;grehajt&aacute;sa</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek k&ouml;re: A h&iacute;rlev&eacute;lre feliratkoz&oacute; valamennyi &eacute;rintett.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s c&eacute;lja: rekl&aacute;mot tartalmaz&oacute; elektronikus &uuml;zenetek k&uuml;ld&eacute;se az &eacute;rintett r&eacute;sz&eacute;re, t&aacute;j&eacute;koztat&aacute;s ny&uacute;jt&aacute;sa az aktu&aacute;lis inform&aacute;ci&oacute;kr&oacute;l, term&eacute;kekről, akci&oacute;kr&oacute;l, &uacute;j szolg&aacute;ltat&aacute;sokr&oacute;l, funkci&oacute;kr&oacute;l stb.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s időtartama, az adatok t&ouml;rl&eacute;s&eacute;nek hat&aacute;rideje: a hozz&aacute;j&aacute;rul&oacute; nyilatkozat visszavon&aacute;s&aacute;ig, azaz a leiratkoz&aacute;sig tart az adatkezel&eacute;s.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatok megismer&eacute;s&eacute;re jogosult lehets&eacute;ges adatkezelők szem&eacute;lye, a szem&eacute;lyes adatok c&iacute;mzettjei: A szem&eacute;lyes adatokat az adatkezelő munkat&aacute;rsai kezelhetik, a fenti alapelvek tiszteletben tart&aacute;s&aacute;val.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintett k&eacute;relmezheti az adatkezelőtől a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, &eacute;s</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">tiltakozhat az ilyen szem&eacute;lyes adatok kezel&eacute;se ellen, valamint</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az &eacute;rintettnek joga van az adathordozhat&oacute;s&aacute;ghoz, tov&aacute;bb&aacute; a hozz&aacute;j&aacute;rul&aacute;s b&aacute;rmely időpontban t&ouml;rt&eacute;nő visszavon&aacute;s&aacute;hoz.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok t&ouml;rl&eacute;s&eacute;t, m&oacute;dos&iacute;t&aacute;s&aacute;t, vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, az adatok hordozhat&oacute;s&aacute;g&aacute;t, az adatkezel&eacute;sek elleni tiltakoz&aacute;st az al&aacute;bbi m&oacute;dokon tudja &eacute;rintett kezdem&eacute;nyezni:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">postai &uacute;ton a 8623 Balatonf&ouml;ldv&aacute;r, Kil&aacute;t&oacute; k&ouml;z 5.,</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">e-mail &uacute;tj&aacute;n az info@mesterportal.com e-mail c&iacute;men.</span></li>
</ul>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s jogalapja: az &eacute;rintett hozz&aacute;j&aacute;rul&aacute;sa, 6. cikk (1) bekezd&eacute;s a) &eacute;s f) pontja, &eacute;s a gazdas&aacute;gi rekl&aacute;mtev&eacute;kenys&eacute;g alapvető felt&eacute;teleiről &eacute;s egyes korl&aacute;tair&oacute;l sz&oacute;l&oacute; 2008. &eacute;vi XLVIII. t&ouml;rv&eacute;ny 6. &sect; (5) bekezd&eacute;se:<br>&nbsp;A rekl&aacute;moz&oacute;, a rekl&aacute;mszolg&aacute;ltat&oacute;, illetve a rekl&aacute;m k&ouml;zz&eacute;tevője &ndash; a hozz&aacute;j&aacute;rul&aacute;sban meghat&aacute;rozott k&ouml;rben &ndash; a n&aacute;luk hozz&aacute;j&aacute;rul&oacute; nyilatkozatot tevő szem&eacute;lyek szem&eacute;lyes adatair&oacute;l nyilv&aacute;ntart&aacute;st vezet. Az ebben a nyilv&aacute;ntart&aacute;sban r&ouml;gz&iacute;tett &ndash; a rekl&aacute;m c&iacute;mzettj&eacute;re vonatkoz&oacute; &ndash; adat csak a hozz&aacute;j&aacute;rul&oacute; nyilatkozatban foglaltaknak megfelelően, annak visszavon&aacute;s&aacute;ig kezelhető, &eacute;s harmadik f&eacute;l sz&aacute;m&aacute;ra kiz&aacute;r&oacute;lag az &eacute;rintett szem&eacute;ly előzetes hozz&aacute;j&aacute;rul&aacute;s&aacute;val adhat&oacute; &aacute;t.</span></p>
<ol start="8">
    <li style="margin: 0px 0px 20.2px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Panaszkezel&eacute;s</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 11px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatgyűjt&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re &eacute;s az adatkezel&eacute;s c&eacute;lja:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Szem&eacute;lyes adat</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adatkezel&eacute;s c&eacute;lja</strong></span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Vezet&eacute;k-&eacute;s keresztn&eacute;v: Azonos&iacute;t&aacute;s, kapcsolattart&aacute;s.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">E-mail c&iacute;m: Kapcsolattart&aacute;s.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Telefonsz&aacute;m: Kapcsolattart&aacute;s.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Sz&aacute;ml&aacute;z&aacute;si n&eacute;v &eacute;s c&iacute;m: Azonos&iacute;t&aacute;s, a megrendelt szolg&aacute;ltat&aacute;ssal kapcsolatosan felmer&uuml;lő minős&eacute;gi kifog&aacute;sok, k&eacute;rd&eacute;sek &eacute;s probl&eacute;m&aacute;k kezel&eacute;s&eacute;re.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek k&ouml;re: Az online piacteret haszn&aacute;l&oacute; &eacute;s minős&eacute;gi kifog&aacute;ssal &eacute;lő, panaszt tevő valamennyi &eacute;rintett.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s időtartama, az adatok t&ouml;rl&eacute;s&eacute;nek hat&aacute;rideje: A felvett kifog&aacute;sr&oacute;l felvett jegyzők&ouml;nyv, &aacute;tirat &eacute;s az arra adott v&aacute;lasz m&aacute;solati p&eacute;ld&aacute;nyait a fogyaszt&oacute;v&eacute;delemről sz&oacute;l&oacute; 1997. &eacute;vi CLV. t&ouml;rv&eacute;ny 17/A. &sect; (7) bekezd&eacute;se alapj&aacute;n 5 &eacute;vig kell megőrizni.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatok megismer&eacute;s&eacute;re jogosult lehets&eacute;ges adatkezelők szem&eacute;lye, a szem&eacute;lyes adatok c&iacute;mzettjei: A szem&eacute;lyes adatokat az adatkezelő munkat&aacute;rsai kezelhetik, a fenti alapelvek tiszteletben tart&aacute;s&aacute;val.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintett k&eacute;relmezheti az adatkezelőtől a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, &eacute;s</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">tiltakozhat az ilyen szem&eacute;lyes adatok kezel&eacute;se ellen, valamint</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az &eacute;rintettnek joga van az adathordozhat&oacute;s&aacute;ghoz, tov&aacute;bb&aacute; a hozz&aacute;j&aacute;rul&aacute;s b&aacute;rmely időpontban t&ouml;rt&eacute;nő visszavon&aacute;s&aacute;hoz.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st, azok t&ouml;rl&eacute;s&eacute;t, m&oacute;dos&iacute;t&aacute;s&aacute;t, vagy kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;s&aacute;t, az adatok hordozhat&oacute;s&aacute;g&aacute;t, az adatkezel&eacute;sek elleni tiltakoz&aacute;st az al&aacute;bbi m&oacute;dokon tudja &eacute;rintett kezdem&eacute;nyezni:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">postai &uacute;ton a 8623 Balatonf&ouml;ldv&aacute;r, J&oacute;zsef Attila utca 13. c&iacute;men,</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">e-mail &uacute;tj&aacute;n az info@Mesterport&aacute;l.com e-mail c&iacute;men.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s jogalapja: 6. cikk (1) bekezd&eacute;s c) pont, &eacute;s a fogyaszt&oacute;v&eacute;delemről sz&oacute;l&oacute; 1997. &eacute;vi CLV. t&ouml;rv&eacute;ny 17/A. &sect; (7) bekezd&eacute;se.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>K&ouml;z&ouml;ss&eacute;gi oldalak</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatgyűjt&eacute;s t&eacute;nye, a kezelt adatok k&ouml;re: Facebook /Youtube /Instagram /Google+ /Twitter /Pinterest stb. k&ouml;z&ouml;ss&eacute;gi oldalakon regisztr&aacute;lt neve, illetve a felhaszn&aacute;l&oacute; nyilv&aacute;nos profilk&eacute;pe.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettek k&ouml;re: Valamennyi &eacute;rintett, aki regisztr&aacute;lt a Facebook /Youtube /Instagram /Google+/ Twitter /Pinterest stb. k&ouml;z&ouml;ss&eacute;gi oldalakon, &eacute;s &bdquo;l&aacute;jkolta&rdquo; a weboldalt.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatgyűjt&eacute;s c&eacute;lja: A k&ouml;z&ouml;ss&eacute;gi oldalakon, a weboldal egyes tartalmi elemeinek, term&eacute;keinek, akci&oacute;inak vagy mag&aacute;nak a weboldalnak a megoszt&aacute;sa, illetve &bdquo;l&aacute;jkol&aacute;sa&rdquo;, n&eacute;pszerűs&iacute;t&eacute;se.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s időtartama, az adatok t&ouml;rl&eacute;s&eacute;nek hat&aacute;rideje, az adatok megismer&eacute;s&eacute;re jogosult lehets&eacute;ges adatkezelők szem&eacute;lye &eacute;s az &eacute;rintettek adatkezel&eacute;ssel kapcsolatos jogainak ismertet&eacute;se: Az adatok forr&aacute;s&aacute;r&oacute;l, azok kezel&eacute;s&eacute;ről, illetve az &aacute;tad&aacute;s m&oacute;dj&aacute;r&oacute;l, &eacute;s jogalapj&aacute;r&oacute;l az adott k&ouml;z&ouml;ss&eacute;gi oldalon t&aacute;j&eacute;koz&oacute;dhat az &eacute;rintett. Az adatkezel&eacute;s a k&ouml;z&ouml;ss&eacute;gi oldalakon val&oacute;sul meg, &iacute;gy az adatkezel&eacute;s időtartam&aacute;ra, m&oacute;dj&aacute;ra, illetve az adatok t&ouml;rl&eacute;si &eacute;s m&oacute;dos&iacute;t&aacute;si lehetős&eacute;geire az adott k&ouml;z&ouml;ss&eacute;gi oldal szab&aacute;lyoz&aacute;sa vonatkozik.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s jogalapja: az &eacute;rintett &ouml;nk&eacute;ntes hozz&aacute;j&aacute;rul&aacute;sa szem&eacute;lyes adatai kezel&eacute;s&eacute;hez a k&ouml;z&ouml;ss&eacute;gi oldalakon.</span></p>
<ol start="9">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">&Uuml;gyf&eacute;lkapcsolatok &eacute;s egy&eacute;b adatkezel&eacute;sek</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Amennyiben az adatkezelő szolg&aacute;ltat&aacute;saink ig&eacute;nybev&eacute;tele sor&aacute;n k&eacute;rd&eacute;s mer&uuml;lne fel, esetleg probl&eacute;m&aacute;ja lenne az &eacute;rintettnek, a honlapon megadott m&oacute;dokon (telefon, e-mail, k&ouml;z&ouml;ss&eacute;gi oldalak stb.) kapcsolatba l&eacute;phet az adatkezelővel.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatkezelő a be&eacute;rkezett e-maileket, &uuml;zeneteket, telefonon, Facebook-on stb. megadott adatokat az &eacute;rdeklődő nev&eacute;vel &eacute;s e-mail c&iacute;m&eacute;vel, valamint m&aacute;s, &ouml;nk&eacute;nt megadott szem&eacute;lyes adat&aacute;val egy&uuml;tt, az adatk&ouml;zl&eacute;stől sz&aacute;m&iacute;tott legfeljebb 2 &eacute;v eltelt&eacute;vel t&ouml;rli. E t&aacute;j&eacute;koztat&oacute;ban fel nem sorolt adatkezel&eacute;sekről az adat felv&eacute;telekor adunk t&aacute;j&eacute;koztat&aacute;st. Kiv&eacute;teles hat&oacute;s&aacute;gi megkeres&eacute;sre, illetőleg jogszab&aacute;ly felhatalmaz&aacute;sa alapj&aacute;n m&aacute;s szervek megkeres&eacute;se eset&eacute;n a Szolg&aacute;ltat&oacute; k&ouml;teles t&aacute;j&eacute;koztat&aacute;s ad&aacute;s&aacute;ra, adatok k&ouml;zl&eacute;s&eacute;re, &aacute;tad&aacute;s&aacute;ra, illetőleg iratok rendelkez&eacute;sre bocs&aacute;t&aacute;s&aacute;ra.</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A Szolg&aacute;ltat&oacute; ezen esetekben a megkereső r&eacute;sz&eacute;re &ndash; amennyiben az a pontos c&eacute;lt &eacute;s az adatok k&ouml;r&eacute;t megjel&ouml;lte &ndash; szem&eacute;lyes adatot csak annyit &eacute;s olyan m&eacute;rt&eacute;kben ad ki, amely a megkeres&eacute;s c&eacute;lj&aacute;nak megval&oacute;s&iacute;t&aacute;s&aacute;hoz elengedhetetlen&uuml;l sz&uuml;ks&eacute;ges.</span></p>
<ol start="10">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Az &eacute;rintettek jogai</span></li>
</ol>
<p style="margin: 0px; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>A hozz&aacute;f&eacute;r&eacute;s joga</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy az adatkezelőtől visszajelz&eacute;st kapjon arra vonatkoz&oacute;an, hogy szem&eacute;lyes adatainak kezel&eacute;se folyamatban van-e, &eacute;s ha ilyen adatkezel&eacute;s folyamatban van, jogosult arra, hogy a szem&eacute;lyes adatokhoz &eacute;s a rendeletben felsorolt inform&aacute;ci&oacute;khoz hozz&aacute;f&eacute;r&eacute;st kapjon.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>A helyesb&iacute;t&eacute;shez val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy k&eacute;r&eacute;s&eacute;re az adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l helyesb&iacute;tse a Felhaszn&aacute;l&oacute;ra vonatkoz&oacute; pontatlan szem&eacute;lyes adatokat. Figyelembe v&eacute;ve az adatkezel&eacute;s c&eacute;lj&aacute;t, Felhaszn&aacute;l&oacute; jogosult arra, hogy k&eacute;rje a hi&aacute;nyos szem&eacute;lyes adatok &ndash; egyebek mellett kieg&eacute;sz&iacute;tő nyilatkozat &uacute;tj&aacute;n t&ouml;rt&eacute;nő &ndash; kieg&eacute;sz&iacute;t&eacute;s&eacute;t.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>A t&ouml;rl&eacute;shez val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy k&eacute;r&eacute;s&eacute;re az adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l t&ouml;r&ouml;lje a Felhaszn&aacute;l&oacute; vonatkoz&oacute; szem&eacute;lyes adatokat, az adatkezelő pedig k&ouml;teles arra, hogy Felhaszn&aacute;l&oacute;ra vonatkoz&oacute; szem&eacute;lyes adatokat indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l t&ouml;r&ouml;lje meghat&aacute;rozott felt&eacute;telek eset&eacute;n.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az elfeledtet&eacute;shez val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha az adatkezelő nyilv&aacute;noss&aacute;gra hozta a szem&eacute;lyes adatot, &eacute;s azt t&ouml;r&ouml;lni k&ouml;teles, az el&eacute;rhető technol&oacute;gia &eacute;s a megval&oacute;s&iacute;t&aacute;s k&ouml;lts&eacute;geinek figyelembev&eacute;tel&eacute;vel megteszi az &eacute;sszerűen elv&aacute;rhat&oacute; l&eacute;p&eacute;seket &ndash; ide&eacute;rtve technikai int&eacute;zked&eacute;seket &ndash; annak &eacute;rdek&eacute;ben, hogy t&aacute;j&eacute;koztassa az adatokat kezelő adatkezelőket, hogy Felhaszn&aacute;l&oacute; k&eacute;relmezte a sz&oacute;ban forg&oacute; szem&eacute;lyes adatokra mutat&oacute; linkek vagy e szem&eacute;lyes adatok m&aacute;solat&aacute;nak, illetve m&aacute;sodp&eacute;ld&aacute;ny&aacute;nak t&ouml;rl&eacute;s&eacute;t.&nbsp;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adatkezel&eacute;s korl&aacute;toz&aacute;s&aacute;hoz val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy k&eacute;r&eacute;s&eacute;re az adatkezelő korl&aacute;tozza az adatkezel&eacute;st, ha az al&aacute;bbi felt&eacute;telek valamelyike teljes&uuml;l:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; vitatja a szem&eacute;lyes adatok pontoss&aacute;g&aacute;t, ez esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik, amely lehetőv&eacute; teszi, hogy az adatkezelő ellenőrizze a szem&eacute;lyes adatok pontoss&aacute;g&aacute;t;</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az adatkezel&eacute;s jogellenes, &eacute;s Felhaszn&aacute;l&oacute; ellenzi az adatok t&ouml;rl&eacute;s&eacute;t, &eacute;s ehelyett k&eacute;ri azok felhaszn&aacute;l&aacute;s&aacute;nak korl&aacute;toz&aacute;s&aacute;t;</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az adatkezelőnek m&aacute;r nincs sz&uuml;ks&eacute;ge a szem&eacute;lyes adatokra adatkezel&eacute;s c&eacute;lj&aacute;b&oacute;l, de Felhaszn&aacute;l&oacute; ig&eacute;nyli azokat jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez, &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy v&eacute;delm&eacute;hez;</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; tiltakozott az adatkezel&eacute;s ellen; ez esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik, am&iacute;g meg&aacute;llap&iacute;t&aacute;sra nem ker&uuml;l, hogy az adatkezelő jogos indokai elsőbbs&eacute;get &eacute;lveznek-e Felhaszn&aacute;l&oacute; jogos indokaival szemben.&nbsp;</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Az adathordozhat&oacute;s&aacute;ghoz val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy a Felhaszn&aacute;l&oacute;ra vonatkoz&oacute;, Felhaszn&aacute;l&oacute; &aacute;ltal egy adatkezelő rendelkez&eacute;s&eacute;re bocs&aacute;tott szem&eacute;lyes adatokat tagolt, sz&eacute;les k&ouml;rben haszn&aacute;lt, g&eacute;ppel olvashat&oacute; form&aacute;tumban megkapja, tov&aacute;bb&aacute; jogosult arra, hogy ezeket az adatokat egy m&aacute;sik adatkezelőnek tov&aacute;bb&iacute;tsa an&eacute;lk&uuml;l, hogy ezt akad&aacute;lyozn&aacute; az az adatkezelő, amelynek a szem&eacute;lyes adatokat a rendelkez&eacute;s&eacute;re bocs&aacute;totta.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>A tiltakoz&aacute;shoz val&oacute; jog</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Felhaszn&aacute;l&oacute; jogosult arra, hogy a saj&aacute;t helyzet&eacute;vel kapcsolatos okokb&oacute;l b&aacute;rmikor tiltakozzon szem&eacute;lyes adatainak a kezel&eacute;se ellen, ide&eacute;rtve az eml&iacute;tett rendelkez&eacute;seken alapul&oacute; profilalkot&aacute;st is.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;"><strong>Tiltakoz&aacute;s k&ouml;zvetlen &uuml;zletszerz&eacute;s est&eacute;n</strong></span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha a szem&eacute;lyes adatok kezel&eacute;se k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben t&ouml;rt&eacute;nik, Felhaszn&aacute;l&oacute; jogosult arra, hogy b&aacute;rmikor tiltakozzon a Felhaszn&aacute;l&oacute;ra vonatkoz&oacute; szem&eacute;lyes adatok e c&eacute;lb&oacute;l t&ouml;rt&eacute;nő kezel&eacute;se ellen, ide&eacute;rtve a profilalkot&aacute;st is, amennyiben az a k&ouml;zvetlen &uuml;zletszerz&eacute;shez kapcsol&oacute;dik. Ha Felhaszn&aacute;l&oacute; tiltakozik a szem&eacute;lyes adatok k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben t&ouml;rt&eacute;nő kezel&eacute;se ellen, akkor a szem&eacute;lyes adatok a tov&aacute;bbiakban e c&eacute;lb&oacute;l nem kezelhetők.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Automatiz&aacute;lt d&ouml;nt&eacute;shozatal egyedi &uuml;gyekben, bele&eacute;rtve a profilalkot&aacute;st</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">&Ouml;n jogosult arra, hogy ne terjedjen ki Felhaszn&aacute;l&oacute;ra az olyan, kiz&aacute;r&oacute;lag automatiz&aacute;lt adatkezel&eacute;sen &ndash; ide&eacute;rtve a profilalkot&aacute;st is &ndash; alapul&oacute; d&ouml;nt&eacute;s hat&aacute;lya, amely r&aacute; n&eacute;zve joghat&aacute;ssal j&aacute;rna vagy őt hasonl&oacute;k&eacute;ppen jelentős m&eacute;rt&eacute;kben &eacute;rinten&eacute;.</span></li>
</ul>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az előző bekezd&eacute;s nem alkalmazand&oacute; abban az esetben, ha a d&ouml;nt&eacute;s: Felhaszn&aacute;l&oacute; &eacute;s az adatkezelő k&ouml;z&ouml;tti szerződ&eacute;s megk&ouml;t&eacute;se vagy teljes&iacute;t&eacute;se &eacute;rdek&eacute;ben sz&uuml;ks&eacute;ges; meghozatal&aacute;t az adatkezelőre alkalmazand&oacute; olyan uni&oacute;s vagy tag&aacute;llami jog teszi lehetőv&eacute;, amely &Ouml;n jogainak &eacute;s szabads&aacute;gainak, valamint jogos &eacute;rdekeinek v&eacute;delm&eacute;t szolg&aacute;l&oacute; megfelelő int&eacute;zked&eacute;seket is meg&aacute;llap&iacute;t; vagy Felhaszn&aacute;l&oacute; kifejezett hozz&aacute;j&aacute;rul&aacute;s&aacute;n alapul.</span></p>
<ol start="11">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Int&eacute;zked&eacute;si hat&aacute;ridő</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l, de mindenf&eacute;lek&eacute;ppen a k&eacute;relem be&eacute;rkez&eacute;s&eacute;től sz&aacute;m&iacute;tott 1 h&oacute;napon bel&uuml;l t&aacute;j&eacute;koztatja Felhaszn&aacute;l&oacute;t a fenti k&eacute;relmek nyom&aacute;n hozott int&eacute;zked&eacute;sekről. Sz&uuml;ks&eacute;g eset&eacute;n ez 2 h&oacute;nappal meghosszabb&iacute;that&oacute;. A hat&aacute;ridő meghosszabb&iacute;t&aacute;s&aacute;r&oacute;l az adatkezelő a k&eacute;sedelem okainak megjel&ouml;l&eacute;s&eacute;vel a k&eacute;relem k&eacute;zhezv&eacute;tel&eacute;től sz&aacute;m&iacute;tott 1 h&oacute;napon bel&uuml;l t&aacute;j&eacute;koztatja Felhaszn&aacute;l&oacute;t.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha az adatkezelő nem tesz int&eacute;zked&eacute;seket Felhaszn&aacute;l&oacute; k&eacute;relme nyom&aacute;n, k&eacute;sedelem n&eacute;lk&uuml;l, de legk&eacute;sőbb a k&eacute;relem be&eacute;rkez&eacute;s&eacute;től sz&aacute;m&iacute;tott egy h&oacute;napon bel&uuml;l t&aacute;j&eacute;koztatja Felhaszn&aacute;l&oacute;t az int&eacute;zked&eacute;s elmarad&aacute;s&aacute;nak okair&oacute;l, valamint arr&oacute;l, hogy Felhaszn&aacute;l&oacute; panaszt ny&uacute;jthat be valamely fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l, &eacute;s &eacute;lhet b&iacute;r&oacute;s&aacute;gi jogorvoslati jog&aacute;val.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezel&eacute;s biztons&aacute;ga</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezelő &eacute;s az adatfeldolgoz&oacute; a tudom&aacute;ny &eacute;s technol&oacute;gia &aacute;ll&aacute;sa &eacute;s a megval&oacute;s&iacute;t&aacute;s k&ouml;lts&eacute;gei, tov&aacute;bb&aacute; az adatkezel&eacute;s jellege, hat&oacute;k&ouml;re, k&ouml;r&uuml;lm&eacute;nyei &eacute;s c&eacute;ljai, valamint a term&eacute;szetes szem&eacute;lyek jogaira &eacute;s szabads&aacute;gaira jelentett, v&aacute;ltoz&oacute; val&oacute;sz&iacute;nűs&eacute;gű &eacute;s s&uacute;lyoss&aacute;g&uacute; kock&aacute;zat figyelembev&eacute;tel&eacute;vel megfelelő technikai &eacute;s szervez&eacute;si int&eacute;zked&eacute;seket hajt v&eacute;gre annak &eacute;rdek&eacute;ben, hogy a kock&aacute;zat m&eacute;rt&eacute;k&eacute;nek megfelelő szintű adatbiztons&aacute;got garant&aacute;lja, ide&eacute;rtve, t&ouml;bbek k&ouml;z&ouml;tt, adott esetben:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">a) a szem&eacute;lyes adatok &aacute;lneves&iacute;t&eacute;s&eacute;t &eacute;s titkos&iacute;t&aacute;s&aacute;t;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">b) a szem&eacute;lyes adatok kezel&eacute;s&eacute;re haszn&aacute;lt rendszerek &eacute;s szolg&aacute;ltat&aacute;sok folyamatos bizalmas jelleg&eacute;nek biztos&iacute;t&aacute;s&aacute;t, integrit&aacute;s&aacute;t, rendelkez&eacute;sre &aacute;ll&aacute;s&aacute;t &eacute;s ellen&aacute;ll&oacute; k&eacute;pess&eacute;g&eacute;t;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">c) fizikai vagy műszaki incidens eset&eacute;n az arra val&oacute; k&eacute;pess&eacute;get, hogy a szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;st &eacute;s az adatok rendelkez&eacute;sre &aacute;ll&aacute;s&aacute;t kellő időben vissza lehet &aacute;ll&iacute;tani;</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">d) az adatkezel&eacute;s biztons&aacute;g&aacute;nak garant&aacute;l&aacute;s&aacute;ra hozott technikai &eacute;s szervez&eacute;si int&eacute;zked&eacute;sek hat&eacute;konys&aacute;g&aacute;nak rendszeres tesztel&eacute;s&eacute;re, felm&eacute;r&eacute;s&eacute;re &eacute;s &eacute;rt&eacute;kel&eacute;s&eacute;re szolg&aacute;l&oacute; elj&aacute;r&aacute;st.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintett t&aacute;j&eacute;koztat&aacute;sa az adatv&eacute;delmi incidensről</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha az adatv&eacute;delmi incidens val&oacute;sz&iacute;nűs&iacute;thetően magas kock&aacute;zattal j&aacute;r a term&eacute;szetes szem&eacute;lyek jogaira &eacute;s szabads&aacute;gaira n&eacute;zve, az adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l t&aacute;j&eacute;koztatja az &eacute;rintettet az adatv&eacute;delmi incidensről.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintett r&eacute;sz&eacute;re adott t&aacute;j&eacute;koztat&aacute;sban vil&aacute;gosan &eacute;s k&ouml;z&eacute;rthetően ismertetni kell az adatv&eacute;delmi incidens jelleg&eacute;t, &eacute;s k&ouml;z&ouml;lni kell az adatv&eacute;delmi tisztviselő vagy a tov&aacute;bbi t&aacute;j&eacute;koztat&aacute;st ny&uacute;jt&oacute; egy&eacute;b kapcsolattart&oacute; nev&eacute;t &eacute;s el&eacute;rhetős&eacute;geit; ismertetni kell az adatv&eacute;delmi incidensből eredő, val&oacute;sz&iacute;nűs&iacute;thető k&ouml;vetkezm&eacute;nyeket; ismertetni kell az adatkezelő &aacute;ltal az adatv&eacute;delmi incidens orvosl&aacute;s&aacute;ra tett vagy tervezett int&eacute;zked&eacute;seket, bele&eacute;rtve adott esetben az adatv&eacute;delmi incidensből eredő esetleges h&aacute;tr&aacute;nyos k&ouml;vetkezm&eacute;nyek enyh&iacute;t&eacute;s&eacute;t c&eacute;lz&oacute; int&eacute;zked&eacute;seket.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az &eacute;rintettet nem kell t&aacute;j&eacute;koztatni, ha a k&ouml;vetkező felt&eacute;telek b&aacute;rmelyike teljes&uuml;l:</span></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az adatkezelő megfelelő technikai &eacute;s szervez&eacute;si v&eacute;delmi int&eacute;zked&eacute;seket hajtott v&eacute;gre, &eacute;s ezeket az int&eacute;zked&eacute;seket az adatv&eacute;delmi incidens &aacute;ltal &eacute;rintett adatok tekintet&eacute;ben alkalmazt&aacute;k, k&uuml;l&ouml;n&ouml;sen azokat az int&eacute;zked&eacute;seket &ndash; mint p&eacute;ld&aacute;ul a titkos&iacute;t&aacute;s alkalmaz&aacute;sa &ndash;, amelyek a szem&eacute;lyes adatokhoz val&oacute; hozz&aacute;f&eacute;r&eacute;sre fel nem jogos&iacute;tott szem&eacute;lyek sz&aacute;m&aacute;ra &eacute;rtelmezhetetlenn&eacute; teszik az adatokat;</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">az adatkezelő az adatv&eacute;delmi incidenst k&ouml;vetően olyan tov&aacute;bbi int&eacute;zked&eacute;seket tett, amelyek biztos&iacute;tj&aacute;k, hogy az &eacute;rintett jogaira &eacute;s szabads&aacute;gaira jelentett, magas kock&aacute;zat a tov&aacute;bbiakban val&oacute;sz&iacute;nűs&iacute;thetően nem val&oacute;sul meg;</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">a t&aacute;j&eacute;koztat&aacute;s ar&aacute;nytalan erőfesz&iacute;t&eacute;st tenne sz&uuml;ks&eacute;gess&eacute;. Ilyen esetekben az &eacute;rintetteket nyilv&aacute;nosan k&ouml;zz&eacute;tett inform&aacute;ci&oacute;k &uacute;tj&aacute;n kell t&aacute;j&eacute;koztatni, vagy olyan hasonl&oacute; int&eacute;zked&eacute;st kell hozni, amely biztos&iacute;tja az &eacute;rintettek hasonl&oacute;an hat&eacute;kony t&aacute;j&eacute;koztat&aacute;s&aacute;t.</span></li>
</ul>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha az adatkezelő m&eacute;g nem &eacute;rtes&iacute;tette az &eacute;rintettet az adatv&eacute;delmi incidensről, a fel&uuml;gyeleti hat&oacute;s&aacute;g, miut&aacute;n m&eacute;rlegelte, hogy az adatv&eacute;delmi incidens val&oacute;sz&iacute;nűs&iacute;thetően magas kock&aacute;zattal j&aacute;r-e, elrendelheti az &eacute;rintett t&aacute;j&eacute;koztat&aacute;s&aacute;t.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Adatv&eacute;delmi incidens bejelent&eacute;se a hat&oacute;s&aacute;gnak</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatv&eacute;delmi incidenst az adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l, &eacute;s ha lehets&eacute;ges, legk&eacute;sőbb 72 &oacute;r&aacute;val azut&aacute;n, hogy az adatv&eacute;delmi incidens a tudom&aacute;s&aacute;ra jutott, bejelenti az 55. cikk alapj&aacute;n illet&eacute;kes fel&uuml;gyeleti hat&oacute;s&aacute;gnak, kiv&eacute;ve, ha az adatv&eacute;delmi incidens val&oacute;sz&iacute;nűs&iacute;thetően nem j&aacute;r kock&aacute;zattal a term&eacute;szetes szem&eacute;lyek jogaira &eacute;s szabads&aacute;gaira n&eacute;zve. Ha a bejelent&eacute;s nem t&ouml;rt&eacute;nik meg 72 &oacute;r&aacute;n bel&uuml;l, mell&eacute;kelni kell hozz&aacute; a k&eacute;sedelem igazol&aacute;s&aacute;ra szolg&aacute;l&oacute; indokokat is.</span></p>
<ol start="12">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">Panaszt&eacute;teli lehetős&eacute;g</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Az adatkezelő esetleges jogs&eacute;rt&eacute;se ellen panasszal a Nemzeti Adatv&eacute;delmi &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;gn&aacute;l lehet &eacute;lni:</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Nemzeti Adatv&eacute;delmi &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;g 1125 Budapest, Szil&aacute;gyi Erzs&eacute;bet fasor 22/C.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Levelez&eacute;si c&iacute;m: 1530 Budapest, Postafi&oacute;k: 5.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Telefon: +36 -1-391-1400</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Fax: +36-1-391-1410</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">E-mail: ugyfelszolgalat@naih.hu</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">B&iacute;r&oacute;s&aacute;gi jog&eacute;rv&eacute;nyes&iacute;t&eacute;s</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Azt, hogy az adatkezel&eacute;s a jogszab&aacute;lyban foglaltaknak megfelel, az adatkezelő k&ouml;teles bizony&iacute;tani. Az adattov&aacute;bb&iacute;t&aacute;s jogszerűs&eacute;g&eacute;t az adat&aacute;tvevő k&ouml;teles bizony&iacute;tani.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A per elb&iacute;r&aacute;l&aacute;sa a t&ouml;rv&eacute;nysz&eacute;k hat&aacute;sk&ouml;r&eacute;be tartozik. A per &ndash; az &eacute;rintett v&aacute;laszt&aacute;sa szerint &ndash; az &eacute;rintett lak&oacute;helye vagy tart&oacute;zkod&aacute;si helye szerinti t&ouml;rv&eacute;nysz&eacute;k előtt is megind&iacute;that&oacute;. A perben f&eacute;l lehet az is, akinek egy&eacute;bk&eacute;nt nincs perbeli jogk&eacute;pess&eacute;ge. A perbe a Hat&oacute;s&aacute;g az &eacute;rintett pernyertess&eacute;ge &eacute;rdek&eacute;ben beavatkozhat. Ha a b&iacute;r&oacute;s&aacute;g a k&eacute;relemnek helyt ad, az adatkezelőt a t&aacute;j&eacute;koztat&aacute;s megad&aacute;s&aacute;ra, az adat helyesb&iacute;t&eacute;s&eacute;re, z&aacute;rol&aacute;s&aacute;ra, t&ouml;rl&eacute;s&eacute;re, az automatiz&aacute;lt adatfeldolgoz&aacute;ssal hozott d&ouml;nt&eacute;s megsemmis&iacute;t&eacute;s&eacute;re, az &eacute;rintett tiltakoz&aacute;si jog&aacute;nak figyelembev&eacute;tel&eacute;re, illetve az adat&aacute;tvevő &aacute;ltal k&eacute;rt adat kiad&aacute;s&aacute;ra k&ouml;telezi.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha a b&iacute;r&oacute;s&aacute;g az adat&aacute;tvevő k&eacute;relm&eacute;t elutas&iacute;tja, az adatkezelő k&ouml;teles az &eacute;rintett szem&eacute;lyes adat&aacute;t az &iacute;t&eacute;let k&ouml;zl&eacute;s&eacute;től sz&aacute;m&iacute;tott 3 napon bel&uuml;l t&ouml;r&ouml;lni. Az adatkezelő k&ouml;teles az adatokat akkor is t&ouml;r&ouml;lni, ha az adat&aacute;tvevő a meghat&aacute;rozott hat&aacute;ridőn bel&uuml;l nem fordul b&iacute;r&oacute;s&aacute;ghoz. A b&iacute;r&oacute;s&aacute;g elrendelheti &iacute;t&eacute;let&eacute;nek &ndash; az adatkezelő azonos&iacute;t&oacute; adatainak k&ouml;zz&eacute;t&eacute;tel&eacute;vel t&ouml;rt&eacute;nő &ndash; nyilv&aacute;noss&aacute;gra hozatal&aacute;t, ha azt az adatv&eacute;delem &eacute;rdekei &eacute;s nagyobb sz&aacute;m&uacute; &eacute;rintett v&eacute;dett jogai megk&ouml;vetelik.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">K&aacute;rt&eacute;r&iacute;t&eacute;s &eacute;s s&eacute;relemd&iacute;j</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Ha az adatkezelő az &eacute;rintett adatainak jogellenes kezel&eacute;s&eacute;vel vagy az adatbiztons&aacute;g k&ouml;vetelm&eacute;nyeinek megszeg&eacute;s&eacute;vel az &eacute;rintett szem&eacute;lyis&eacute;gi jog&aacute;t megs&eacute;rti, az &eacute;rintett az adatkezelőtől s&eacute;relemd&iacute;jat k&ouml;vetelhet. Az &eacute;rintettel szemben az adatkezelő felel az adatfeldolgoz&oacute; &aacute;ltal okozott k&aacute;r&eacute;rt &eacute;s az adatkezelő k&ouml;teles megfizetni az &eacute;rintettnek az adatfeldolgoz&oacute; &aacute;ltal okozott szem&eacute;lyis&eacute;gi jogs&eacute;rt&eacute;s eset&eacute;n j&aacute;r&oacute; s&eacute;relemd&iacute;jat is. Az adatkezelő mentes&uuml;l az okozott k&aacute;r&eacute;rt val&oacute; felelőss&eacute;g &eacute;s a s&eacute;relemd&iacute;j megfizet&eacute;s&eacute;nek k&ouml;telezetts&eacute;ge al&oacute;l, ha bizony&iacute;tja, hogy a k&aacute;rt vagy az &eacute;rintett szem&eacute;lyis&eacute;gi jog&aacute;nak s&eacute;relm&eacute;t az adatkezel&eacute;s k&ouml;r&eacute;n k&iacute;v&uuml;l eső elh&aacute;r&iacute;thatatlan ok id&eacute;zte elő.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Nem kell megt&eacute;r&iacute;teni a k&aacute;rt &eacute;s nem k&ouml;vetelhető a s&eacute;relemd&iacute;j annyiban, amennyiben a k&aacute;r a k&aacute;rosult vagy a szem&eacute;lyis&eacute;gi jog megs&eacute;rt&eacute;s&eacute;vel okozott jogs&eacute;relem az &eacute;rintett sz&aacute;nd&eacute;kos vagy s&uacute;lyosan gondatlan magatart&aacute;s&aacute;b&oacute;l sz&aacute;rmazott.</span></p>
<p style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Egy&eacute;b rendelkez&eacute;sek</span></p>
<p style="margin: 0px 0px 12px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; minden sz&uuml;ks&eacute;ges biztons&aacute;gi l&eacute;p&eacute;st, szervez&eacute;si &eacute;s technikai int&eacute;zked&eacute;st megtesz a szem&eacute;lyes adatok biztons&aacute;ga, illetve azok jogosulatlan megv&aacute;ltoztat&aacute;s&aacute;nak, megsemmis&iacute;t&eacute;s&eacute;nek &eacute;s felhaszn&aacute;l&aacute;s&aacute;nak megakad&aacute;lyoz&aacute;sa &eacute;rdek&eacute;ben. Szolg&aacute;ltat&oacute; mindent megtesz a kezelt adatok hiteless&eacute;g&eacute;nek, bizalmass&aacute;g&aacute;nak megőrz&eacute;se &eacute;rdek&eacute;ben, &eacute;s, hogy azokhoz a Felhaszn&aacute;l&oacute;k &eacute;s az arra jogosultak mindig hozz&aacute;f&eacute;rjenek. Szolg&aacute;ltat&oacute; fenntartja a jogot arra, hogy jelen t&aacute;j&eacute;koztat&oacute;t Felhaszn&aacute;l&oacute; előzetes &eacute;rtes&iacute;t&eacute;se mellett egyoldal&uacute;an m&oacute;dos&iacute;tsa. A m&oacute;dos&iacute;t&aacute;s hat&aacute;lyba l&eacute;p&eacute;s&eacute;t k&ouml;vetően a szolg&aacute;ltat&aacute;s haszn&aacute;lat&aacute;val Felhaszn&aacute;l&oacute; r&aacute;utal&oacute; magatart&aacute;ssal elfogadja a m&oacute;dos&iacute;tott t&aacute;j&eacute;koztat&oacute;ban foglaltakat.</span></p>
<ol start="13">
    <li style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 16px; line-height: normal; font-family: Carlito;"><span style="font-kerning: none;">&Uuml;zemeltető adatai</span></li>
</ol>
<p style="margin: 0px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; min-height: 14px;"><br></p>
<ul>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; neve: Mesterport&aacute;l Kft.</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; sz&eacute;khelye: 8623 Balatonf&ouml;ldv&aacute;r, J&oacute;zsef Attila utca 13/A.</span><span style="font-stretch: normal; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; -webkit-font-kerning: none;"><br></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; el&eacute;rhetős&eacute;ge, az ig&eacute;nybe vevőkkel val&oacute; kapcsolattart&aacute;sra szolg&aacute;l&oacute;, rendszeresen haszn&aacute;lt elektronikus levelez&eacute;si c&iacute;me: info@Mesterport&aacute;l.com</span><span style="font-stretch: normal; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; -webkit-font-kerning: none;"><br></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">C&eacute;gjegyz&eacute;ksz&aacute;m: 14-09-316271</span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">, vezetve a Kaposv&aacute;ri T&ouml;rv&eacute;nysz&eacute;k C&eacute;gb&iacute;r&oacute;s&aacute;ga nyilv&aacute;ntart&aacute;s&aacute;ban</span><span style="font-stretch: normal; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; -webkit-font-kerning: none;"><br></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szolg&aacute;ltat&oacute; ad&oacute;sz&aacute;ma: 26276852-2-14</span><span style="font-stretch: normal; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; -webkit-font-kerning: none;"><br></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">Statisztikai sz&aacute;mjel: 26276852-7911-113-14</span><span style="font-stretch: normal; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px; -webkit-font-kerning: none;"><br></span></li>
    <li style="margin: 0px 0px 5px; text-align: justify; font-stretch: normal; font-size: 12px; line-height: normal;  font-family: Maven Pro; letter-spacing: 2px;"><span style="font-kerning: none;">A szerződ&eacute;s nyelve: magyar</span></li>
</ul>


`

 class At extends Component  {
  
 

    render(){
      const window_width =  this.props.systemState.dimension.width;
      return (
        <div style={{...styles.outerBG}}>

        <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
          <div>Adatkezelési tájékoztató</div>
        </div>
       
            {/* LIST */}
            <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        
              <div style={{padding:60}}>
               
              {parse(html)}
                  
              </div>

          </Container>

        <div style={{...styles.bottomSpacer}}/>

      </div>
      );
  
    }
  }

  const styles = {
    div_container : {display:"flex", flexDirection:"column", flexWrap:"wrap"},
    headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
    bottomSpacer: {height:30, width:"100%"},
    outerBG: {background:"#F6F9FE"},
    title_p: {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"bold", color:"#0C78B5", marginTop:20, marginBottom:20 },
    body_p:  {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"400",  color:"#4F4F4F" },

    
  }
  



/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState} = state;
  return {systemState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(At);
