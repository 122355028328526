import React, {Component} from 'react';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  useParams,
  useRouteMatch,
  Redirect,
  NavLink
} from "react-router-dom";

import './Ads.css'

/* ROUTS */
import AdsDetails from      './AdsDetails/AdsDetails';
import AdsList from      './AdsList/AdsList';
import NewAds from      './NewAds/NewAds';



 class Ads extends Component  {

  

  render(){
    

    
    //let path = this.props.match.path;
    //console.log(this.props.match.path);
    
    return (
      <Switch >
          <Redirect exact path="/users/ads" to={`/users/ads/list`}/>
          <Route path={`/users/ads/:subpages`} component={AdsSubManager}/>
      </Switch>
    );


  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default Ads;




const AdsSubManager =() => {

  const { subpages } = useParams();

  switch (subpages) {
    case "list":return <AdsList />
    case "details":return <AdsDetails/>
    case "new":return <NewAds/>


    default : return <AdsList/>      
   
  }

}