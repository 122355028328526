import React, {Component} from 'react';
import {connect} from 'react-redux';
/// AXIOS
import {request} from "../../Model/Request";
/// BOOTSTRAP
import {Container} from "react-bootstrap";


import { NavLink } from "react-router-dom";


import defaultImage from "../../Assets/mesterlaptop.png"





 class Search extends Component  {


  state = {
    tool_data:[],
    user_id:null
  }

  componentDidMount(){
    this.getData()
  }

  

 getData = async () => {

  const response = await request({ url:`/public/user/profile/tools?tool_type=company&tool_id=${this.props.match.params.id}` });

  const safe_response = response?.data?.tools ?? []
  if(safe_response.length === 0)return

  this.setState({tool_data:safe_response, user_id:this.props.match.params.id})

 }





smallBoxComponent = (item) => {

  return(
    <div style={{ ...styles.boxcomponentContainer }} key={item.id}>
        {/* image */}
        <div style={{ backgroundImage:`url(${item?.attribute?.basic_image?.src ?? defaultImage})`, ...styles.boxcomponentImage }}/>
        {/* container */}
        <div style={{padding:10}}>
          {/* title */}
         <div style={{ ...styles.fontStyle, fontWeight:"bold", ...styles.boxcomponentTitle, textAlign:"center" }}>{item?.attribute?.name ?? ""}</div>
          {/* text */}
        <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:50 }}>{this.removeHTMLTags(item?.attribute?.description ?? "")}</div>
          {/* button */}
          <div style={{ ...styles.fontStyle, ...styles.boxcomponentButton }} onClick={()=> this.props.history.push(`/experttool/${item.id}/${this.state.user_id}`)  } >TOVÁBB A BÉRLÉSHEZ</div>
        </div>

    </div>
  )

};


removeHTMLTags = (data) => {
  const regex = /(<([^>]+)>)/ig;
  return data.replace(regex, "");
}














  render(){
    console.log(this.props)
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
  


      <div style={{...styles.outerBG}}>

            <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
              <div>SZERSZÁM BÉRBEADÁS</div>
            </div>
           
                {/* LIST */}
                <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
            
                  <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:'center'}}>
                    { this.state.tool_data.length > 0 
                        ? this.state.tool_data.map( item => this.smallBoxComponent(item) ) 
                        : <div style={{...styles.p, fontSize:20, fontWeight:"bold"}}> JELENLEG NINCS BÉRLÉSRE KÍNÁLT SZERSZÁM</div>
                      
                    }
                  </div>

              </Container>

            <div style={{...styles.bottomSpacer}}/>

        </div>


      




    );

  }
}

const styles = {

  headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
  bottomSpacer: {height:30, width:"100%"},
  outerBG: {background:"#F6F9FE"},
  headerMainContainer : {paddingTop:10, paddingBottom:10, display:"flex", paddingLeft:15},
  headerSubContainer : {flex:10, marginTop:15, marginBottom:15},
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  fontStyle2: {letterSpacing:3, fontFamily:"MONDA", fontSize:12},
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },
  input:    {width:"100%", padding:10, height:40, marginTop:20, marginBottom:20, border: "1px solid #4F4F4F",  bordeRadius:5,},
  p:        {fontSize:12, fontFamily:"Maven Pro", letterSpacing:2},
  button:   {color:"white", padding:8, display:"flex", justifyContent:"center" ,borderRadius:4, border:'1px solid #4F4F4F', height:65, alignItems:"center", fontSize:14},
  imgDIV :  {backgroundSize:"cover",
             objectFitt:"cover",
             backgroundRepeat:"no-repeat",
             backgroundColor:"white",
             backgroundPosition:"center",
             width:210,
             height:210,
             borderRadius:6,
             boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"},

  boxcomponentContainer: {width:250, margin:10, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius:5 },
  boxcomponentImage: { height:200, width:250, backgroundPosition:"center center", backgroundSize:"cover",  backgroundRepeat:"no-repeat", borderRadius:5},
  boxcomponentTitle: { marginTop:10, marginBottom:10},
  boxcomponentDescription: {marginTop:10, marginLeft:10, marginRight:10, marginBottom:20, overflow: "hidden", textOverflow: "ellipsis",},
  boxcomponentButton:{fontSize:12, textAlign:"center", margin:10, padding:10, background:"#0D74B0", color:"white", borderRadius:5},

}


/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Search);
