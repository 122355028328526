import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import { ProfileBig, OfferBig, MessageBig, MandateBig, CalendarBig, ToolsRent, SucccessFeeBig, ProfileDataSheet}  from "./Assets/Svg.js"


const pageData = [
  {url:"profiles",    name:"üzleti profilom",                icon:<ProfileBig />,        iconSmall:<ProfileBig         width={25} height={25} />},
  {url:"userprofiles", name:"adatlapom",              icon:<ProfileDataSheet />,  iconSmall:<ProfileDataSheet   width={25} height={25} />},
  {url:"offer",       name:"ajánlataim",              icon:<OfferBig/>,           iconSmall:<OfferBig           width={25} height={25} />},
  {url:"messages",    name:"üzenetek",                icon:<MessageBig/>,         iconSmall:<MessageBig         width={25} height={25} />},
  {url:"mandate",     name:"megbízások",              icon:<MandateBig/>,         iconSmall:<MandateBig         width={25} height={25} />},
  {url:"calendar",    name:"naptár",                  icon:<CalendarBig/>,        iconSmall:<CalendarBig        width={25} height={25} />},
  {url:"toolsrent",   name:"szerszám bérbeadás",      icon:<ToolsRent/>,          iconSmall:<ToolsRent          width={25} height={25} />},
  {url:"successfees", name:"sikerdíjak",              icon:<SucccessFeeBig/>,     iconSmall:<SucccessFeeBig     width={25} height={25} />},
]



 class SideBar extends Component  {

  refsArray = [];

  state = {
    left:0,
    right:0
  }


  componentDidMount(){

    this.corner()
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props !== prevProps){
      this.corner()
    }
  }

  corner = () => {
    if( this.props.systemState.dimension.width > 550){
      pageData.map( (item, index)=>  { 
        if(this.props.location.pathname === `/experts/${item.url}` ){
          const ccoordinates = this.refsArray[index].getBoundingClientRect();
          this.setState({left:ccoordinates.left, right:ccoordinates.left + ccoordinates.width}) 
        }
        
      })
    }
   
   
   
  }

  navigate = (data) => {
    this.props.history.push(`/experts/${data}`)
  }
  

bigSlidebarComponent = () => (
  <div  style={{width:135,background: "linear-gradient(180.01deg, #0C78B5 0%, #1D456D 100%)", height:"100%", borderTopRightRadius:25, borderBottomRightRadius:25, paddingTop:12.5, paddingBottom:12.5}}>
       {pageData.map( (item, index) =>
 
       <div  key={index} onClick={()=>this.navigate(item.url)} style={{ cursor:"pointer",  boxShadow: this.props.location.pathname === `/experts/${item.url}` ?  "0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 30px rgba(0, 0, 0, 0.14)" : null }}>
         {this.props.location.pathname === `/experts/${item.url}` 
         ? <>
            <div style={{position:"absolute", height:160, width:12.5, background:"#0C78B5", left:this.state.right, borderTopRightRadius:25, borderBottomRightRadius:25  }} />
            <div style={{position:"absolute", height:160, width:12.5, background:"#0C78B5", left:this.state.left-12.5, borderTopLeftRadius:25, borderBottomLeftRadius:25 }} />
         </>
         : null
         }

       <div  id={"btn"+index}   ref={(ref)=>this.refsArray[index] = ref} style={{ height:160,  display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", background: this.props.location.pathname === `/experts/${item.url}` ? "#0C78B5" : "transparent" }}>
         
              <div>{item.icon}</div>
              <div style={{display:"flex", textAlign: "center"}}>
                <div style={{fontSize:13, fontFamily:"Maven Pro", fontWeight:"700", color:"white", marginTop:24, letterSpacing:2,}}>{item.name.toLocaleUpperCase()}</div>
              </div>

        </div>
        
       </div>
  
    
          )}
     </div> 
     
)

mobileSidebarComponent = () => (
  <div style={{
    width:55,background: "linear-gradient(180.01deg, #0C78B5 0%, #1D456D 100%)", 
    height:"100%", borderTopRightRadius:25, borderBottomRightRadius:25, paddingTop:25, paddingBottom:25}}>
  {pageData.map( (item, index) =>

  <div key={index} onClick={()=>this.navigate(item.url)}>
    

  <div  id={"btn"+index}   ref={(ref)=>this.refsArray[index] = ref} style={{ height:52,  display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", background: this.props.location.pathname === `/experts/${item.url}` ? "#0C78B5" : "transparent" }}>
    
         <div>{item.iconSmall}</div>
        

   </div>
   
  </div>


     )}
</div> 
)
 


  render(){
    console.log(this.props)
    const window_width =  this.props.systemState.dimension.width;
    return window_width > 550 ? this.bigSlidebarComponent() : this.mobileSidebarComponent()

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{})(SideBar));


 