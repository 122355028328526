import React from 'react';


export const ProfileBig = (props) => {
  return (
    <svg  width={54} height={54} viewBox="0 0 54 54" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h53.888v54H0V0zm9.957 14.76v27.806l12.292-13.929 4.668 4.262 4.668-4.262v8.783l5.497 5.146h6.847l-.052-27.806h-11.93l-5.03 5.77-4.668-5.77H9.957zm12.241 20.374h-1.452v1.507h1.452v-1.507zm-1.452 1.975h1.452v1.507h-1.452v-1.507zm3.475.052h-1.452v1.507h1.452V37.16zm-1.452-2.08h1.452v1.508h-1.452v-1.507z"
        fill="#fff"
      />
    </svg>
  )
}

export const OfferBig = (props) => {
  return (
    <svg width={65} height={65} viewBox="0 0 65 65" fill="none" {...props}>
      <path
        d="M46.387 33.823l5.47 5.474a4.031 4.031 0 005.7-5.7L44.024 20.055c-2.46-2.46-5.177-2.693-8.079.21l-.844.834a4.031 4.031 0 01-5.7-5.7l2.852-2.848c3.291-3.295 5.164-4.49 7.934-4.49h4.375l17.926 17.916-6.279 6.279"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.374 33.822l6.898 6.895a4.033 4.033 0 01-5.703 5.704l-6.288-6.29"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.284 40.13l4.863 4.866a4.031 4.031 0 01-5.696 5.7l-2.85-2.851"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.516 32.258l-6.5-6.502 16.146-16.15s2.149 2.5 3.955 2.5c2.191 0 2.727-.71 4.031-2.015 2.141-2.143 5.362-2.079 8.063.618m3.7 37.445l1.401 1.404a4.032 4.032 0 01-5.697 5.702l-1.401-1.405 5.697-5.7z"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.722 36.446a4.031 4.031 0 10-5.697-5.7l-2.847 2.85a4.031 4.031 0 005.7 5.7l2.844-2.85z"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.425 42.146a4.032 4.032 0 00-5.702-5.7l-4.272 4.271a4.03 4.03 0 005.7 5.7l4.274-4.27zm5.697 5.7a4.032 4.032 0 00-5.697-5.7l-2.85 2.85a4.032 4.032 0 005.7 5.699l2.847-2.848z"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.51 53.855a4.03 4.03 0 10-5.701-5.7l-1.403 1.404a4.031 4.031 0 005.699 5.701l1.406-1.405z"
        stroke="#fff"
        strokeWidth={2.016}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const MessageBig = (props) => {
  return (
    <svg width={68} height={50} viewBox="0 0 68 50" fill="none" {...props}>
      <path
        d="M16.144 44.177l-4.307 4.144-4.307-4.144h8.614z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={1}
        y={15}
        width={43}
        height={26}
        rx={3}
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="M23.5 14V5a4 4 0 014-4H63a4 4 0 014 4v20a4 4 0 01-4 4H44.5"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  )
}

export const MandateBig = (props) => {
  return (
    <svg width={64} height={64} viewBox="0 0 64 64" fill="none" {...props}>
      <path
        d="M46 39v3a3 3 0 01-3 3H21a3 3 0 01-3-3v-3H2v20h60V39H46zM6 7h52M6 31h52H6zm0-8h52H6zm0-8h52H6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CalendarBig = (props) => {
  return (
    <svg width={62} height={68} viewBox="0 0 62 68" fill="none" {...props}>
      <rect
        x={1}
        y={6}
        width={60}
        height={61}
        rx={1}
        stroke="#fff"
        strokeWidth={2}
      />
      <mask id="prefix__a" fill="#fff">
        <rect x={7} y={12} width={48.222} height={49} rx={1.556} />
      </mask>
      <rect
        x={7}
        y={12}
        width={48.222}
        height={49}
        rx={1.556}
        stroke="#fff"
        strokeWidth={4}
        mask="url(#prefix__a)"
      />
      <path
        d="M16 1v17M31 1v17M45 1v17"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export const ToolsRent = (props) => {
  return (
    <svg width={68} height={65} viewBox="0 0 68 65" fill="none" {...props}>
      <path
        d="M5.467 51.546l7.042-4.294a1.3 1.3 0 011.53.124l2.837 2.446a1.3 1.3 0 01.409.651l.861 3.214a1.3 1.3 0 01-.63 1.48l-8.038 4.436a11.738 11.738 0 0019.364-10.121 4.012 4.012 0 011.153-3.345l19.266-18.672a3.98 3.98 0 013.356-1.046c.485.07.974.109 1.464.117a11.659 11.659 0 0011.806-13.323l-7.999 4.874a1.3 1.3 0 01-1.53-.125l-2.836-2.445a1.3 1.3 0 01-.409-.651l-.862-3.214a1.3 1.3 0 01.63-1.48l8.514-4.687a11.67 11.67 0 00-7.693-2.414 11.734 11.734 0 00-11.093 13.091 3.945 3.945 0 01-1.132 3.32L22.279 38.077a3.962 3.962 0 01-3.367 1.047 11.738 11.738 0 00-13.48 11.42c-.006.34.012.67.035 1.001z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.18 34.116l-6.843-7.333a3.944 3.944 0 00-3.314-1.146A11.735 11.735 0 012.977 14.491a11.671 11.671 0 012.446-7.684l4.652 8.534a1.3 1.3 0 001.477.636l3.218-.849a1.3 1.3 0 00.652-.406l2.457-2.826a1.3 1.3 0 00.13-1.53l-4.84-8.018a11.658 11.658 0 0113.275 11.86c-.01.49-.052.979-.124 1.463a3.98 3.98 0 001.033 3.36l6.974 7.053m-.257 16.159l3.785 3.814a3.963 3.963 0 011.033 3.37 11.735 11.735 0 0011.366 13.528c.339.007.669-.01 1-.031l-4.265-7.06a1.3 1.3 0 01.13-1.53l2.458-2.826c.171-.198.4-.34.653-.406l3.217-.849a1.3 1.3 0 011.478.636l4.403 8.057A11.738 11.738 0 0049.286 39.54a4.009 4.009 0 01-3.34-1.167l-3.75-4.003"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SucccessFeeBig = (props) => {
  return (
    <svg width={62} height={62} viewBox="0 0 62 62" fill="none" {...props}>
      <path
        d="M58 1H4a3 3 0 00-3 3v50a3 3 0 003 3h54a3 3 0 003-3V4a3 3 0 00-3-3zM53 57v4M9 57v4-4z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 29a12 12 0 111.6 6M9 9h44v40H9V9zM7 19h4-4zm0 20h4-4z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 31a2 2 0 100-4 2 2 0 000 4zM33 29H17"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ProfileDataSheet = (props) => {
  return(
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" {...props}>
      <path 
        d="M45.2465 45.7852C36.3588 44.0795 35.91 41.2965 35.91 39.501V38.4237C38.3266 36.3256 40.1006 33.5868 41.0271 30.5235H41.1169C43.8101 30.5235 44.5283 24.8677 44.5283 23.97C44.5283 23.0723 44.6181 19.7506 41.8351 19.7506C47.5806 3.5912 31.8701 -2.87256 20.0199 5.38669C15.1721 5.38669 14.7232 12.5686 16.5187 19.7506C13.7357 19.7506 13.8254 23.162 13.8254 23.97C13.8254 24.778 14.4539 30.5235 17.2369 30.5235C18.1346 33.7554 19.3017 36.718 21.4563 38.6032V39.6805C21.4563 41.476 21.9051 44.1693 12.9277 45.7852C3.95024 47.4011 1.79565 55.6604 1.79565 55.6604H55.6603C55.0728 53.18 53.7747 50.9248 51.9251 49.1709C50.0755 47.4169 47.7546 46.2403 45.2465 45.7852V45.7852Z" 
        stroke="#fff" 
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  )
}