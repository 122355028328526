import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import './Profiles.css';
import ProfilesModule from "./ProfilesModule";


 class Profiles extends Component  {


  render(){
    return (
        <div>
            <div className="d-flex row justify-content-center registerUserRow">
                <div className="col registerUserBlock">
                    <ProfilesModule/>
                </div>
            </div>
        </div>
    );
  }
}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
})(Profiles));
