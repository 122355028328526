import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
/* REDUX ACTIONS */
import {setTokenAction, setUserAction, scrollAction} from "../../../Model/Action/index";
/* IMG LOADER */
import ImgUpload from "../../../View/ImageUpload";
/* HTTP REQUEST */
import {request} from "../../../Model/Request";

import {withRouter} from "react-router-dom";

import './Profiles.css'



const validationArray = [
  {name:"first_name",                 minLenght: 2, maxLenght: 50,   },
  {name:"last_name",                  minLenght: 2, maxLenght: 50,   },
  {name:"email",                      minLenght: 5, maxLenght: 100,  },
  {name:"password_old",               minLenght: 8, maxLenght: 50,   },
  {name:"password",                   minLenght: 8, maxLenght: 50,   },
  {name:"password_confirmation",      minLenght: 8, maxLenght: 50,   },
  {name:"city",                       minLenght: 2, maxLenght: 100,  },
  {name:"street",                     minLenght: 2, maxLenght: 100,  },
  {name:"phone_number",               minLenght: 11,maxLenght: 20,   },
  {name:"zip",                        minLenght: 4, maxLenght: 4,    },
];

const downSideArrayEmail = [
    {htmlFor:"fgemail",     htmlForText:"E-mail cím",          type:"email",    id:"email", name:"fgemail", readOnly:true},
];

const downSideArray = [
  {htmlFor:"fgpassold",   htmlForText:"Régi Jelszó",            type:"password", id:"password_old",          name:"fgpassold",   readOnly:false,  subText:""},
  {htmlFor:"fgpass",      htmlForText:"Új Jelszó",              type:"password", id:"password",              name:"fgpass",      readOnly:false,  subText:"minimum 8 karakter, tartalmazzon 1 számot"},
  {htmlFor:"fgpasscheck", htmlForText:"Új Jelszó megerősítése", type:"password", id:"password_confirmation", name:"fgpasscheck", readOnly:false,  subText:""},
]

const isEmail = (email = null) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const isPassword = (password) => {
  const regex = /^(?=.*\d).{8,}$/
  return regex.test(password);
}


 class Profiles extends Component  {

  state = {
    first_name: "",
    last_name:  "",
    type:       "basic",
    email:      "",
    password:   "",
    password_confirmation : "",
    password_old: "",
    zip: "",
    city: "",
    street: "",
    phone_number: "", 

    avatar :"",
    avatar_previous:"",

    ///LOADING
    loading:false,

    /// VALIDATION
    avatar_valid:true,
    first_name_valid:true,
    last_name_valid:true,
    email_valid:false,
    password_valid:false,
    password_confirmation_valid:false,
    paswordsIdentical:false,
    zip_valid:false,
    city_valid:false,
    street_valid:false,
    phone_number_valid:false, 
    terms_of_use: false, 

    type_pw:"password",
    type_pw_again:"password",
    type_pw_old:"password"

}

componentDidMount(){

  this.getData()

}

componentDidUpdate(prevProps, prevState){

  /// loading disable all inputs 
  if(this.state.loading !== prevState.loading){

      if(this.state.loading){
          [...document.querySelectorAll("input")].map(i => i.disabled = true)
      }else{
          [...document.querySelectorAll("input")].map(i => i.disabled = false)
      }

  }
  
  /// compare the two passwords
  if(this.state.password !== prevState.password || this.state.password_confirmation !== prevState.password_confirmation ){
      if(this.state.password === this.state.password_confirmation ){
          this.setState({paswordsIdentical:true})
      }else{
          this.setState({paswordsIdentical:false})
      }
  }

  /// form validation
  validationArray.map( i =>{

      if(this.state[i.name] !== prevState[i.name] ){

          /// if its zip or phonenumber the imputed value must be a number
          if( (i.name === "zip" || i.name === "phone_number" )  && isNaN(this.state[i.name])){
              this.setState({[i.name]: prevState[i.name] })
          }


          if(this.state[i.name].length === 0){

              document.querySelector(`input#${i.name}`).style.background = "rgba(227,6,6,0.15)";
              this.setState({[`${i.name}_valid`]: false})


          } else if(this.state[i.name].length < i.minLenght){

              document.querySelector(`input#${i.name}`).style.background = "rgba(253,217,166,0.26)";
              this.setState({[`${i.name}_valid`]: false})


          } else if(this.state[i.name].length >= i.minLenght && this.state[i.name].length <= i.maxLenght){
                
                  /// email case
                  if(i.name === "email") {
                      if(isEmail(this.state[i.name])){
                          this.setState({[`${i.name}_valid`]: true})
                          document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                      }else{
                          return
                      }    
                  }
                  
                  console.log(i.name)
                  /// password OR password_confirmation
                  if(i.name === "password" || i.name === "password_confirmation" ) {

                          if(isPassword(this.state[i.name])){
                              this.setState({[`${i.name}_valid`]: true})
                              document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                          }else{
                              return
                          }
                  }

                  /// default
                  document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                  this.setState({[`${i.name}_valid`]: true})
            
              
          } else if(this.state[i.name].length >= i.maxLenght){

              this.setState({[i.name]: prevState[i.name] })
           
          }
          

        
      };

  })


}


getData = async () => {
  try{

    const response = await request({url:"/user"});
       
    this.setState({
        email: response.data?.user?.attribute?.attributes?.email ?? "",
        first_name: response.data?.user?.attribute?.attributes?.name.split(" ").filter( (item, index) => index > 0 ).toString() ?? "",
        last_name: response.data?.user?.attribute?.attributes?.name.split(" ")[0] ?? "",
        avatar_previous:response.data?.user?.attribute?.attributes?.avatar?.src,
        phone_number:response.data?.user?.profile?.phone_number ?? "",
        zip: response.data?.user?.location?.zip_code.toString() ?? "",
        city:response.data?.user?.location?.city ?? "",
        street: response.data?.user?.location?.street ?? "",
      })



  }catch(e){
    console.log(e)
  }
 

}

imageToState = ({base64data, index}) => {
  this.setState({avatar:base64data, avatar_valid:true})
};

onChangeHandler = (e) => {
  const {id} = e.target;
  const {value} = e.target;

  if(id === "terms_of_use") {
      this.setState({[id]:!this.state.terms_of_use}) 
  }else{
      this.setState({[id]:value}) 
  }
  
}

saveButton = async () => {



    document.querySelector("#errorText").innerHTML = "";
    document.querySelector("#successText").innerHTML = "";
    document.querySelector("#errorText_password").innerHTML = "";
    document.querySelector("#successText_password").innerHTML = "";
    document.querySelector("#errorText_image").innerHTML = "";
    document.querySelector("#successText_image").innerHTML = "";
    


    const userInformationChange = async () => {

        /// if this trigger to TRUE the function will return
    let stop = false;
    /// error message
    let description = ""
    /// STATE keys
    const keys =  Object.keys(this.state);

    keys.reverse().map( i => {
        if( i.includes("_valid") && this.state[i] === false && !i.includes("password") && !i.includes("email")){

            stop = true

            const id = i.replace("_valid","");

            if(id!=="avatar") document.querySelector(`#${id}`).style.background = "rgba(227,6,6,0.15)";
            
            
            switch (i) {
                    case "avatar_valid":
                        description = "Profilkép feltöltése kötelező!"
                    break;
                    case "first_name_valid":
                        description = "A keresztnév kitöltése nem megfelelő!"
                    break;
                    case "last_name_valid":
                        description = "A családnév kitöltése nem megfelelő!"
                    break;
                    /* case "email_valid":
                        description = "A emailcím kitöltése nem megfelelő!"
                    break; */
                    /*  case "password_valid":
                        description = "A jelsző kitöltése nem megfelelő!"
                    break;
                    case "password_confirmation_valid":
                        description = "Az ismételt jelszó kitöltése nem megfelelő!"
                    break;
                    case "paswordsIdentical":
                        description = "A két jelszó nem azonos!"
                    break; */
                    case "zip_valid":
                        description = "Az irányítószám kitöltése nem megfelelő!"
                    break;
                    case "city_valid":
                        description = "A város kitöltése!"
                    break;
                    case "street_valid":
                        description = "Az utca kitöltése nem megfelelő!"
                    break;
                    case "phone_number_valid":
                        description = "A telefonszám kitöltése nem megfelelő!"
                    break;
                    /*  
                    case "terms_of_use":
                        description = "A felhasználási feltételek elfogadása kötelező!"
                    break;
                    */
                default:
                    break;
            } 

        }
    })

    if(stop === true){
        document.querySelector("#errorText").innerHTML = description;
        this.setState({loading:false});
        return
    }

    document.querySelector("#errorText").innerHTML = ""



    

    /// adatok feltöltése

    

            const response_profile = await request({
            method:"PATCH",
            url:"/user/profile", 
            data:{      
                        first_name: this.state.first_name, 
                        last_name:  this.state.last_name,
                        phone_number:this.state.phone_number,
                        introduction:""

                 }
            });


           const response_location = await request({
                method:"PATCH",
                url:"/user/profile/location", 
                data:{
                    "location":{
                        "lat" : null,
                        "lng" : null,
                        "distance" : null,
                        "country_code": "hu",
                        "zip_code": this.state.zip,
                        "country": null,
                        "county": null,
                        "city": this.state.city,
                        "street": this.state.street,
                        "house_number": null,
                        "extra":{
                                "floor" : null,
                                "door" : null
                        }
                    }
                }
           }); 
    
                      
          if(typeof response_profile.error !== "undefined" || typeof response_location.error !== "undefined"){
            document.querySelector("#errorText").innerHTML = "Hiba lépett fel a mentés közben : felhasználói adatok mentése!"
            return

          }else{

            let {user} = this.props.userState;
                user.attributes.name = this.state.last_name + " " + this.state.first_name;
                this.props.setUserAction(user)    

          }


    }

    const userImageChange = async () => {

            /// van új kép ? 

        if(this.state.avatar !== ""){

            const data = {
                upload_to:{
                type: "user",
                id: this.props.userState.user.id
                },
                img: {
                type: "avatar",
                mime:this.state.avatar.split(";")[0].replace("data:image/",""),
                src: this.state.avatar
                }
            };

            const response_avatar = await request({url:"/image", method:"post", data});

            if(typeof response_avatar.error !== "undefined"){
              
                document.querySelector("#errorText_image").innerHTML = "Hiba a kép feltöltése közben";
                return 

            }else{

                document.querySelector("#errorText_image").innerHTML = "";
                document.querySelector("#successText_image").innerHTML = "Sikeres profilkép változtatás!";


                const response = await request({url:"/user"});

                const avatar = response.data?.user?.attribute?.attributes?.avatar ?? ""

                if(avatar !== ""){
                    
                    let {user} = this.props.userState;
                    user.attributes.avatar = avatar;
                    this.props.setUserAction(user)


                }
        
              
                
            return

            }

        }

    }
    
    const userPasswordChange = async () => {
        /// ha a password nem ures

    if(this.state.password_old !== "" && this.state.password !== ""  && this.state.password_confirmation !== ""){
        // validalja a passwordot
        // toltse fel a passwordot

        let stop = false;
        /// error message
        let description = ""
        /// STATE keys
        const keys =  Object.keys(this.state);
    
        keys.reverse().map( i => {
        if( i.includes("_valid") && i.includes("password") && this.state[i] === false){
    
                stop = true
    
                const id = i.replace("_valid","");
    
                document.querySelector(`#${id}`).style.background = "rgba(227,6,6,0.15)";
                

                switch (i) {
                
                        case "password_valid":
                            description = "A jelsző kitöltése nem megfelelő!"
                        break;
                        case "password_confirmation_valid":
                            description = "Az ismételt jelszó kitöltése nem megfelelő!"
                        break;
                        case "paswordsIdentical":
                            description = "A két jelszó nem azonos!"
                        break; 
                    default:
                        break;
                } 
    
        }
        })

        if(stop === true){
            document.querySelector("#errorText_password").innerHTML = description;
            this.setState({loading:false});
            return
        }

        if(this.state.password !== this.state.password_confirmation){
            document.querySelector("#errorText_password").innerHTML = "Az ismételt jelszó és a jelszó nem azonos!";

            this.setState({password:"", password_confirmation:""});
            return 
        }
    
        document.querySelector("#errorText_password").innerHTML = ""


        /* const data = {
            old_password: this.state.password_old,  
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
        } */const data = {
                "old_password":this.state.password_old,
                "password":this.state.password,
                "password_confirmation":this.state.password_confirmation
            }



        const response_password = await request({url:"/user/profile/password-change", method:"POST", data});

        if(typeof response_password.error !== "undefined"){

                if(typeof response_password.error.message !== "undefined" && response_password.error.message === "The given password does not match with the user`s password"){
                    document.querySelector("#errorText_password").innerHTML = "Az megadott régi jelszó nem megfelelő";
                    this.setState({password_old:""});
                    return 
                }


                document.querySelector("#errorText_password").innerHTML = "Az ismételt jelszó és a jelszó nem azonos!";
                this.setState({password:"", password_confirmation:""});
                return 

        }else{
            document.querySelector("#errorText_password").innerHTML = "";
            document.querySelector("#successText_password").innerHTML = "Sikeres jelszó változtatás!";
            return
        }

       
    }

    }


    /// if the user changed her/his profil image
    userImageChange()
    // if the user changed her/his password
    userPasswordChange()
    // user information
    userInformationChange()



  



      

}



/* ==============
COMPONENTS
============== */

/* downSideComponent = ({htmlFor, htmlForText, type, id, name, index, readOnly}) => {
  return(
      <div className="row w-100" key={index}>
          <div className="col">
              <label htmlFor={htmlFor}>{htmlForText}</label>
              <input type={type} className="form-control" id={id} placeholder="" name={name} onChange={this.onChangeHandler} value={this.state[id]} readOnly={readOnly}  />
          </div>
      </div> 
  )
} */

downSideComponent = ({htmlFor, htmlForText, type, id, name, index, subText, readOnly}) => {
    return(
        <div className="row w-100" key={index}>
            <div className="col" style={{display:"flex", flexDirection:"column"}}>
                <label htmlFor={htmlFor}>{htmlForText}</label>
                {subText !== "" ? <label style={{marginTop:-15, marginBottom:15, fontSize:10}}>{subText}</label> : null}
                <div style={{display:"flex"}}>
                <input type={
                     !type.includes("pass")
                         ? type 
                         :  this.state[name === "fgpasscheck" 
                         ? "type_pw_again" 
                             :  name === "fgpassold" 
                                 ? "type_pw_old"
                                 :"type_pw"] } className="form-control" id={id} placeholder="" name={name} onChange={this.onChangeHandler} value={this.state[id]} style={{ textTransform: "none"}} readOnly={readOnly}/>
                {  name.includes("pass") ? 
                    <div onClick={()=> { 

                        const type_for_sate = name === "fgpasscheck" 
                                ? "type_pw_again" 
                                    :  name === "fgpassold" 
                                        ? "type_pw_old"
                                        :"type_pw" ;
                   this.setState({[type_for_sate]: this.state[type_for_sate] === "password" ? "text" : "password" })

                }} style={{ paddingLeft:5, paddingRight:5, height:40, display:"flex", alignItems:"center", cursor:"pointer" }}>{
                    
                this.state[name === "fgpasscheck" 
                ? "type_pw_again" 
                    :  name === "fgpassold" 
                        ? "type_pw_old"
                        :"type_pw"] === "password" ? "jelszó felfedése" : "jelszó elrejtése"}</div> : null}
                </div>
            </div>
        </div> 
    )
}

  render(){
    console.log(this.props)
    return (
    <div> 
        
      

<div className="d-flex row w-100">
    <div className="w-100">
        <div className="registerFormTittle">Személyes adatok</div>
        <div className="hdivider"></div>
    </div>
</div>

<div className="d-flex row w-100 justify-content-center">
<div className="benceImage">
    <ImgUpload index={0} imageToState={this.imageToState} cover={this.state.avatar_previous} imageContainerStyles={{ backgroundSize: 'cover' }} imageStyle={{ backgroundSize: 'cover' }}  /> 
</div>
<div className="rightcontainer">
    <div className="row">
        <div className="col-xl rightblock">
            <label htmlFor="fgvezeteknev">Vezetéknév</label>
            <input type="text" className="form-control" id="last_name" placeholder="" name="fgvezeteknev" onChange={this.onChangeHandler} value={this.state.last_name} />
        </div>
        <div className="col-xl rightblockslide">
            <label htmlFor="fgkeresztnev">Keresztnév</label>
            <input type="text" className="form-control" id="first_name" placeholder="" name="fgkeresztnev" onChange={this.onChangeHandler} value={this.state.first_name} />
        </div>
    </div>
    <div className="row">
        <div className="col-xl rightblock">
            <label htmlFor="fgiranyitoszam">Irányítószám</label>
            <input type="text" className="form-control" id="zip" placeholder="" name="fgiranyitoszam" onChange={this.onChangeHandler} value={this.state.zip}/>
        </div>
        <div className="col-xl rightblockslide">
            <label htmlFor="fgvaros">Város</label>
            <input type="text" className="form-control" id="city" placeholder="" name="fgvaros" onChange={this.onChangeHandler} value={this.state.city}/>
        </div>
    </div>  
    <div className="row">
        <div className="col-xl rightblock">
            <label htmlFor="fgutca">Utca, Házszám</label>
            <input type="text" className="form-control" id="street" placeholder="" name="fgutca" onChange={this.onChangeHandler} value={this.state.street}/>
        </div>
        <div className="col-xl rightblockslide">
            <label htmlFor="fgtelefon">Telefonszám</label>
            <input type="text" className="form-control" id="phone_number" placeholder="" name="fgtelefon" onChange={this.onChangeHandler} value={this.state.phone_number}/>
        </div>
    </div>                    
</div>

</div> 




<div className="d-flex row w-100 paddingModifier">
<div className="w-100">
    <div className="registerFormTittle">Email adatok</div>
    <div className="hdividerbottom"></div>
</div>
</div>
<div className="d-flex row downside">
{downSideArrayEmail.map( (item, index) =>  this.downSideComponent({htmlFor:item.htmlFor, htmlForText:item.htmlForText, type:item.type, id:item.id, name:item.name, index, readOnly:item.readOnly})  )}
</div>


<div className="d-flex row w-100 paddingModifier">
<div className="w-100">
    <div className="registerFormTittle">Fiók adatok</div>
    <div className="hdividerbottom"></div>
</div>
</div>
<div className="d-flex row downside">
{downSideArray.map( (item, index) =>  this.downSideComponent({htmlFor:item.htmlFor, htmlForText:item.htmlForText, type:item.type, id:item.id, name:item.name, index, readOnly:item.readOnly})  )}
</div>




<div className="d-flex row ">
<div className="row">
    <div className="form-checks col-md-12 col-xl">
        {/* 
          <label className="containercheck">Elfogadom a felhasználási<br/>feltáteleket
            <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                <span className="checkmark"></span>
          </label> 
        */}
    </div>
  
</div>                

</div>


<div className="d-flex row w-100 errorPadding">
<div className="w-100">
    <div className="hdivideretop"></div>
    <div className="errorText" id="errorText"></div>
    <div className="errorText" id="errorText_image"></div>
    <div className="errorText" id="errorText_password"></div>
    <div className="successText" id="successText"></div>
    <div className="successText" id="successText_image"></div>
    <div className="successText" id="successText_password"></div>
    <div className="hdividerebottom"></div>
</div>
</div>   

<div className="d-flex row justify-content-center justify-content-lg-end ">
<div className="buttons">
    <button className="registerButton" onClick={()=> this.saveButton()}>Mentés</button>
</div>                
</div>                


      
 

    </div>
    );
  }
}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
  setUserAction
})(Profiles));


/* 


*/