import React, {Component} from 'react';
import {connect, ReactReduxContext} from 'react-redux';
/// települes
import telepulesek from '../../Model/telepules'
/// AXIOS
import {request} from "../../Model/Request";
/// SELECT
import Select from 'react-select';
/// BOOTSTRAP
import {Container} from "react-bootstrap";

/// QUERY
import queryString from "query-string";

/// PARSE
import parse from 'html-react-parser';

import { NavLink, Link } from "react-router-dom";

import xButton from "./Assets/x.png"
import solo from "./Assets/1.png";
import trio from "./Assets/3.png";
import defaultImage from "../../Assets/mesterlaptop.png"




 class Search extends Component  {


  state = {

       subResetAllow:true,

       servicesList:null,
       mainCategorySelected:null,

       selectedSubCategoryList:null,
       selectedSubCategory:null,

       selectedSettlement:"",

       isToolTipVisible: true,

       isSmallBoxes:true,

       /* RESPONSE */
       data:[],
       resultsNumber:0,
       page:1,
       per_page:10
  }

  componentDidMount(){
   
    this.startFunction()
 
  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.location.search !== this.props.location.search){
      /* SEARCH */

      this.searchFunction();

    }



    if (prevProps.systemState.dimension.width !== this.props.systemState.dimension.width && this.state.isSmallBoxes === false && this.props.systemState.dimension.width >= 922 ){
      this.setState({isSmallBoxes:true})
    }



    if(this.state.mainCategorySelected !==  prevState.mainCategorySelected){
      if(this.state.subResetAllow) {this.setState({selectedSubCategory:null})}
      this.mainCategoryRequest()
    }

    if(this.state.page !== prevState.page){


      let searchString = `per_page=10&page=${this.state.page}`;


      if(this.state.mainCategorySelected !== null){
        searchString = searchString === "" ? "" : searchString + "&";
        searchString = searchString + `mainid=${this.state.mainCategorySelected.value}&mainname=${encodeURI(this.state.mainCategorySelected.label)}`
      }
  
      if(this.state.selectedSubCategory !== null){
        searchString = searchString === "" ? "" : searchString + "&";
        searchString = searchString + `subid=${this.state.selectedSubCategory.value}&subname=${encodeURI(this.state.selectedSubCategory.label)}`
      }
  
      if(this.state.selectedSettlement !== ""){
        searchString = searchString === "" ? "" : searchString + "&";
        searchString = searchString + `settlement=${encodeURI(this.state.selectedSettlement)}`
      }

      this.props.history.push(`/search${ searchString === "" ? "" : "?" + searchString }`)

    }



  }

  startFunction = async () => {

        const parsed = queryString.parse(window.location.search);
        console.log("====================")
        console.log(parsed.subid,parsed.subname )
        console.log(parsed)
     
      await this.setState({subResetAllow:false})

      await this.setState({
        ubResetAllow:false,
        mainCategorySelected:typeof parsed.mainid !== "undefined" && typeof parsed.mainname !== "undefined" ? {value:parsed.mainid, label:parsed.mainname } : null,
        selectedSubCategory: typeof parsed.subid  !== "undefined" && typeof parsed.subname  !== "undefined" ? {value:parsed.subid, label:parsed.subname } : null,
        selectedSettlement:parsed.settlement ?? "",
        page:parsed.page ?? 1
      })
      
      /*   if(typeof parsed.mainid !== "undefined" && typeof parsed.mainname !== "undefined"){  this.setState({mainCategorySelected:{value:parsed.mainid, label:parsed.mainname }})  }
        if(typeof parsed.subid !== "undefined" && typeof parsed.subname !== "undefined"){    this.setState({selectedSubCategory:{value:parsed.subid, label:parsed.subname }})   }
        if(typeof parsed.settlement !== "undefined"){  this.setState({selectedSettlement:parsed.settlement}) } */
       
        await this.mainTypeRequest();
     
        
        
        /// SEARCH RESULTS
        await this.searchFunction();
        ///

        await this.setState({subResetAllow:false})

  }

  mainTypeRequest = async () => {
    try{
      const response = await request({url:`/profession?per_page=1000&current_page=1`, method:"GET",  });
      this.setState({servicesList:response.data.profession.items.map(item=>{ return {value: item.id,     label: item.name }  })})
    }catch(e){
      console.log(e)
    }

  }

  mainCategoryRequest = async () => {
    try{
      const response = await request({url:`/profession/specialization?profession_id=${this.state.mainCategorySelected.value}`, method:"GET",  });
      this.setState({selectedSubCategoryList:response.data.map(item=>{ return {value: item.id,     label: item.title }  })})
    }catch(e){
      console.log(e)
    }

  }


  removeHTMLTags = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return data.replace(regex, "");
  }

  searchFunction = async() => {



    let searchString = `per_page=10&page=${this.state.page}`;

    if(this.state.mainCategorySelected !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `profession_id=${this.state.mainCategorySelected.value}`
    }

    if(this.state.selectedSubCategory !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `profession_specialization_id=${this.state.selectedSubCategory.value}`
    }

    if(this.state.selectedSettlement !== ""){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `city=${encodeURI(this.state.selectedSettlement)}`
    }


    const result = await request({url:`/search${ searchString === "" ? "" : "?" + searchString }`, method:"GET"})
    
    if(result === null || typeof result.error !== "undefined"){
      this.setState({ data:[], resultsNumber:0 })
    }else{
      this.setState({ data:result.data.professionals, resultsNumber:result.data.results_no })
    }
    
    window.scrollTo(0, 0)

  }


  setInput = (e) => {


    let {value} = e.target;
    this.setState({selectedSettlement: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() })

/*     clearTimeout(this.validationTimeout);



    this.setState({selectedSettlement: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() })



      this.validationTimeout = setTimeout(()=>{


        const settlement = telepulesek.telepulesek.filter( item => item.name.toLowerCase() === value.toLowerCase())


        if( settlement.length === 0 ){
          if(value.lenght > 0){
            alert(`A következő település nem található: ${value} `);
          }
          
          this.setState({selectedSettlement: "" })
        }

    },1500)
 */


  }

  searchButtonFunction = () => {

    let searchString = `per_page=10&page=${this.state.page}`;


    if(this.state.mainCategorySelected !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `mainid=${this.state.mainCategorySelected.value}&mainname=${encodeURI(this.state.mainCategorySelected.label)}`
    }

    if(this.state.selectedSubCategory !== null){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `subid=${this.state.selectedSubCategory.value}&subname=${encodeURI(this.state.selectedSubCategory.label)}`
    }

    if(this.state.selectedSettlement !== ""){
      searchString = searchString === "" ? "" : searchString + "&";
      searchString = searchString + `settlement=${encodeURI(this.state.selectedSettlement)}`
    }


    this.props.history.push(`/search${ searchString === "" ? "" : "?" + searchString }`)

  }


 /*  searchResult = async () => {
    try{

      let searchString = "";

      if(this.state.mainCategorySelected !== null){
        searchString = searchString + `profession_id=${this.state.mainCategorySelected.value}`
      }

      if(this.state.selectedSubCategory !== null){
        searchString = searchString === "" ? "" : searchString + "&";
        searchString = searchString + `profession_specialization_id=${this.state.selectedSubCategory.value}`
      }

      if(this.state.selectedSettlement !== ""){
        searchString = searchString === "" ? "" : searchString + "&";
        searchString = searchString + `settlement=${encodeURI(this.state.selectedSettlement)}`
      }

   
      searchString = searchString === "" ? "?" : searchString + "&";
      searchString = searchString + `per_page=10&page=${this.state.page}`

      const response = await request({ url:`/search${ searchString === "" ? "" : "?" + searchString }` })

    }catch(e){

    }


  } */

  smallBoxComponent = (item, index) => {
    
  

    return(

      <div style={{ ...styles.boxcomponentContainer, cursor:"pointer" }}  key={"small"+ new Date() + index} onClick={()=> typeof item.company[0] !== "undefined" &&  typeof item.company[0].id !== "undefined" ? this.props.history.push(`/expert/${item?.company[0]?.id ?? "" }`) : null}>
      
          {/* image */}
          <div style={{ ...styles.boxcomponentImage, backgroundImage:`url(${item?.user?.attributes?.avatar?.src ??  defaultImage})`, }}/>

          {/* container */}
          <div style={{padding:10}}>
            {/* title */}
            <div style={{ ...styles.fontStyle, ...styles.boxcomponentTitle, textAlign:"center" }}>{item?.user?.attributes?.name ?? ""}</div>
            {/* text */}
            <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:50 }}> { this.removeHTMLTags(item?.company[0]?.attributes?.introduction ?? "") } </div>
            {/* button */}
            {
             typeof item.company[0] !== "undefined" &&  typeof item.company[0].id !== "undefined"
               ?  <NavLink to={`/expert/${item?.company[0]?.id ?? "" }`} ><div style={{ ...styles.fontStyle, ...styles.boxcomponentButton,  }}>tovább az adatlaphoz</div></NavLink>
               : null
            }
          </div>
          
      </div>
    )

  }

  bigBoxComponent = (item, index) => {
    return(
      <div style={{width:"100%", height:300, margin:10, boxShadow:"rgba(0, 0, 0, 0.2) -1px 5px 5px",  display:"flex", cursor:"pointer"}} key={"big"+ new Date() + index} onClick={()=> typeof item.company[0] !== "undefined" &&  typeof item.company[0].id !== "undefined" ? this.props.history.push(`/expert/${item?.company[0]?.id ?? "" }`) : null}>

       <div style={{flex:2, padding:10}}>
          {/* title */}
      <div style={{ ...styles.fontStyle, ...styles.boxcomponentTitle, padding:10 }}>{item?.user?.attributes?.name ?? ""}</div>
            {/* text */}
      <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:150 }}>{parse(item?.company[0]?.attributes?.introduction ?? "")}</div>

       </div>
       <div style={{...styles.boxcomponentImage, backgroundImage:`url(${item?.user?.attributes?.avatar?.src ??  defaultImage})`, flex:1, display:"flex", justifyContent:"center", alignItems:'flex-end'}}>
         {/* button */}
         {
             typeof item.company[0] !== "undefined" &&  typeof item.company[0].id !== "undefined"
               ?  <NavLink to={`/expert/${item?.company[0]?.id ?? "" }`} ><div style={{ ...styles.fontStyle, ...styles.boxcomponentButton,  }}>tovább az adatlaphoz</div></NavLink>
               : null
         }
       </div>

      </div>
    )
  }




  render(){
    console.log(this.props)
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
      <>
          <div style={{backgroundColor:"#0D74B0"}}>
          <Container style={{ ...styles.headerMainContainer, flexDirection:window_width <= 922 ? "column":"row" }}>

                          <div style={{...styles.headerSubContainer}}>
                            <Select
                              options={this.state.servicesList}
                              value={this.state.mainCategorySelected === null ? null : this.state.mainCategorySelected}
                              onChange={(e)=>this.setState({mainCategorySelected:e})}
                              styles={styles.selectStyle}
                              placeholder={"SZOLGÁLTATÁS!"}
                              noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                              isDisabled={this.state.servicesList === null ? true:false}  />
                            </div>

                            <div style={{flex:1}} />

                            <div style={{...styles.headerSubContainer}}>
                              <Select
                                isClearable={true}
                                options={this.state.selectedSubCategoryList}
                                value={this.state.selectedSubCategory === null ? null : this.state.selectedSubCategory}
                                onChange={(e)=>this.setState({selectedSubCategory:e})}
                                styles={styles.selectStyle}
                                placeholder={"SZOLGÁLTATÁS ALKATEGÓRIA!"}
                                noOptionsMessage={()=><div>VÁLASSZ SZOLGÁLTATÁST!</div>}
                                isDisabled={this.state.selectedSubCategoryList === null ? true:false}  />
                            </div>

                            <div style={{flex:1}} />

                            <div style={{...styles.headerSubContainer}}>
                              <input
                                type="text"
                                id="szolg"
                                name="szolg"
                                placeholder="VÁROS MEGADÁSA"
                                value={this.state.selectedSettlement}
                                style={{width:"100%", padding:10, height:50, ...styles.fontStyle_input}}
                                onChange={(e)=> this.setInput(e)} />
                            </div>

                            <div style={{flex:1}} />

                            <div style={{...styles.headerSubContainer, display:"flex", justifyContent:"center", alignItems:"center"}}>

                              <div
                                  style={{...styles.fontStyle, color:"white", fontSize:25, cursor:"pointer", border:"2px solid white", paddingTop:6, paddingBottom:4, paddingLeft:30, paddingRight:30, borderRadius:10}}
                                  onClick={()=> this.searchButtonFunction() }>KERESÉS</div>

                            </div>



          </Container>
          </div>

     {/*      {
          window_width <= 922 || this.state.isToolTipVisible === false
          ? null
          :
          <>
          <div style={{...styles.closeBtn}} />
          <div style={{...styles.toolTipContainer,  width: window_width > 991 ? "60%": window_width > 921 ? "40%" : "0%", }}>
            Ide jöhetnek a funkciók amik elérhetőek a user számára Look, just because I don't be givin' no man a foot massage don't make it right for Marsellus to throw Antwone into a glass motherfuckin' house.
          </div>
          </>
          }

          <Container style={{ ...styles.headerMainContainer, flexDirection:window_width <= 922 ? "column":"row" }}>

              <div style={{flex:1, fontFamily: "Maven Pro", fontWeight: "bold", color:"#4F4F4F", fontSize:window_width <= 922 ? window_width/13.5 : 70, textTransform:"uppercase", letterSpacing:3, display:"flex", alignItems:"flex-end"}}>
                Hogyan keress szakembert
              </div>
              <div style={{flex:1, display:"flex", alignItems:"flex-end", fontFamily: "Maven Pro",  color:"#4F4F4F", fontSize:12, marginBottom:20}}>
              Now that we know who you are, I know who I am. I'm not a mistake! It all makes sense! In a comic, you know how you can tell who the arch-villain's going to be? He's the exact opposite of the hero. And most times they're friends, like you and me! I should've known way back when...
              </div>

          </Container> */}


          {window_width <= 922
          ? null
          : <Container style={{ ...styles.headerMainContainer, flexDirection:"column"}}>

              <div style={{...styles.line}} />

                  <div style={{display:"flex"}}>

                      {/* start */}
                      <div  style={{flex:1,display:"flex" }}>
                          <div style={{color:"#4F4F4F", ...styles.fontStyle, padding:5}} >MEGJELENÍTÉS:</div>

                          <div style={{border:`${this.state.isSmallBoxes ? 1 : 0 }px solid #E0E0E0`, borderRadius:5, marginRight:20, marginLeft:20, padding:5 }}>
                            <div style={{backgroundImage:`url(${trio})`, height:23, width:50, backgroundRepeat:"no-repeat", backgroundSize:"contain", backgroundPosition:"center",  }} onClick={()=> this.setState({ isSmallBoxes:true }) } />
                          </div>

                          <div style={{border:`${this.state.isSmallBoxes ? 0 : 1 }px solid #E0E0E0`, borderRadius:5, marginRight:20, marginLeft:20, padding:5 }}>
                            <div style={{backgroundImage:`url(${solo})`, height:23, width:70, backgroundRepeat:"no-repeat", backgroundSize:"contain", backgroundPosition:"center"    }} onClick={()=> this.setState({ isSmallBoxes:false}) } />
                          </div>

                      </div>


                      {/* end */}
                      <div  style={{flex:1, display:"flex", justifyContent:"flex-end", color:"#4F4F4F", ...styles.fontStyle, padding:5}}>TALÁLATOK SZÁMA: {`${this.state.resultsNumber}`} </div>

                    </div>

              <div style={{...styles.line}} />

            </Container>}

            <Container style={{display:"flex"}}>
                {/* middle */}
                <div  style={{display:"flex", alignItems:"center", justifyContent:"center", color:"#4F4F4F", ...styles.fontStyle, padding:5}}>TALÁLATI OLDAL: </div>

                <Select
                        options={
                          Array(this.state.resultsNumber)
                            .fill(null)
                            .map( (x,i) => i )
                            .filter( (item, index) =>  index % this.state.per_page === 0)
                            .map((item, index)=>{ return {value:index+1, label:index+1} })
                        }
                        onChange={ async (e)=>{ 
                            await this.setState({page:e.label})
                            await this.searchFunction()
                        }}
                        styles={styles.pageSelectStyle}
                        placeholder={"1"}
                        noOptionsMessage={()=><div>Nincs választható oldal</div>}
                        />

            </Container>

          {/* LIST */}
          <Container style={{ ...styles.headerMainContainer, flexDirection:"row", flexWrap:"wrap", justifyContent:'center',  minHeight:600 }}>

            { this.state.data.map( (item, index) => this.state.isSmallBoxes ? this.smallBoxComponent(item, index) : this.bigBoxComponent(item, index) )  }

          </Container>


  </>

    );

  }
}

const styles = {

  closeBtn:{position:"absolute", right:40, marginTop:20, width:30, height:30, borderRadius:15, background:"#2686B0", backgroundImage:`url(${xButton})`, zIndex:10, backgroundPosition:"center center", backgroundSize:"10px 10px", backgroundRepeat:"no-repeat", boxShadow:"rgba(0, 0, 0, 0.5) -1px 5px 5px"},
  toolTipContainer:{position:"absolute", right:50, marginTop:30, paddingTop:20, paddingLeft:20, paddingRight:50,height:150, background:"#0D74B0", zIndex:0,  boxShadow:"rgba(0, 0, 0, 0.5) -1px 5px 5px", color:'white',},
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  fontStyle_input: {letterSpacing:3, fontFamily:"Maven Pro", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  fontStyle2: {letterSpacing:3, fontFamily:"MONDA", fontSize:12},
  headerMainContainer : {paddingTop:10, paddingBottom:10, display:"flex", paddingLeft:15},
  headerSubContainer : {flex:10, marginTop:15, marginBottom:15},
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },

  boxcomponentContainer: {width:350, margin:10, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius:5 },
  boxcomponentImage: { height:300, width:350, backgroundPosition:"center center", backgroundSize:"cover",  backgroundRepeat:"no-repeat", borderRadius:5},
  boxcomponentTitle: { marginTop:10, marginBottom:10},
  boxcomponentDescription: {marginTop:10, marginLeft:10, marginRight:10, marginBottom:20, overflow: "hidden", textOverflow: "ellipsis",},
  boxcomponentButton:{fontSize:12, textAlign:"center", margin:10, padding:10, background:"#0D74B0", color:"white", borderRadius:5},

  selectStyle : {
                container: provided => ({
                  ...provided,
                  borderRadius:3,
                  width: "100%",
                  fontFamily:"Maven Pro",
                  fontSize:16,
                  fontWeight:"bold",
                  color:'#4F4F4F',
                }),

                option: (provided, state) => ({
                  ...provided,
                 // borderBottom: '1px solid #4F4F4F',
                  backgroundColor: state.isSelected ? "#1C466F":"white",
                  color: state.isSelected ? 'white' : '#4F4F4F',
                  width: '100%',
                  fontFamily:"Maven Pro"

                }),

                control: base => ({
                  ...base,
                  border: 0,
                  borderRadius:3,
                  boxShadow: 'none',
                  fontFamily:"Maven Pro",
                  height:50,
                  letterSpacing: 3
                })
    },
    pageSelectStyle : {
      container: provided => ({
        ...provided,
        borderRadius:3,
        width: 200,
        fontFamily:"Maven Pro",
        fontSize:16,
        fontWeight:"bold",
        color:'#4F4F4F',
      }),

      option: (provided, state) => ({
        ...provided,
       // borderBottom: '1px solid #4F4F4F',
        backgroundColor: state.isSelected ? "#1C466F":"white",
        color: state.isSelected ? 'white' : '#4F4F4F',
        width: '100%',
        fontFamily:"Maven Pro"

      }),

      control: base => ({
        ...base,
        border: 0,
        borderRadius:3,
        boxShadow: 'none',
        fontFamily:"Maven Pro",
        height:50,
        letterSpacing: 3
      })
},
}


/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Search);
