import React, {Component} from 'react';
import {connect} from 'react-redux';
/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  NavLink,
  withRouter
  
} from "react-router-dom";

import './Favorites.css'
import {request}   from "../../../Model/Request";
import parse from 'html-react-parser';
import default_image from "../../../Assets/mester_default.png";

const FavoritesData = [
  {
      img:'https://picsum.photos/id/11/373/293',
      description: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
      related_favorites_id:'25451',
      related_favorites_tittle:"Asztalos Lajos",
      id: '1'
  },
  {
      img:'https://picsum.photos/id/12/373/293',
      description: 'quis id diam mi mauris hac bibendum ',
      related_favorites_id:'25452',
      related_favorites_tittle:"Asztalos Lajos",
      id: '2'
  },
  {
      img:'https://picsum.photos/id/13/373/293',
      description: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
      related_favorites_id:'25453',
      related_favorites_tittle:"Asztalos Lajos",
      id: '3'
  },
  {
      img:'https://picsum.photos/id/14/373/293',
      description: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
      related_favorites_id:'25454',
      related_favorites_tittle:"Asztalos Lajos",
      id: '4'
  },
  {
      img:'https://picsum.photos/id/15/373/293',
      description: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
      related_favorites_id:'25455',
      related_favorites_tittle:"Asztalos Lajos",
      id: '5'
  }  
];




 class Favorites extends Component  {
  state = {
    data:[]
  }

  componentDidMount(){
    console.log("-----------------------------------------------------",this.props);
    this.get_favorites(this.props.userState.user.id,this.props.userState.user.type)
  }


  get_favorites = async (id,type) => {

    try{

      /* REQUEST */
      const response = await request({url:`/favourites?id=${id}&type=${type}`})
      
      /* GUARD FUNC */
      console.log(response);
      if(typeof response.error !== "undefined" || typeof response.data === "undefined" || response.data.length === 0) return

      
      this.setState({data:response.data.map( item => { return {  
          img: item?.avatar ?? default_image, 
          description: item?.introduction ?? "",
          related_tittle: item?.name ?? "",
          id: item?.id ?? ""
      } } )})
      
      
    }catch(e){
      console.log(e)
    }

    

  }



  render(){
    
    
      console.log(this.state.data);
      let favoritesList;
      if (this.state.data.length!=0)
      {
        favoritesList = this.state.data.map(
  
          (ret,index)=> 
          {
              return (
                <FavoritesCard key={index} img={ret.img} description={ret.description} related_id={ret.related_favorites_id} related_tittle={ret.related_tittle} id={ret.id}/>
              );
          }
  
        );
      }else
      {
        favoritesList = <div className="errorTxt">Jelenleg nincs megtekinthető kedvenc!</div>
      }
      
      return (
         <div className="innerContainer d-flex justify-content-center">
            <div className="innerAligner d-flex flex-wrap justify-content-center">
                {favoritesList}
            </div>
            
        </div>
      );
  
    

  }
}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default  withRouter(connect(mapStateToProps,{})(Favorites));



class FavoritesCard extends Component  {

  
  render(){
    //console.log(this.props)
    return(
      <div className="card">
      <img className="images" src={this.props.img} alt=""/>
      <div className="names">{this.props.related_tittle}</div>
      <div className="descriptionContainer d-flex justify-content-center">
         <div className="description d-flex align-items-start overflow-hidden"><div>{parse(this.props.description)}</div></div>
      </div> 
      <div className="buttonContainer">
         
         <NavLink to={'/expert/'+this.props.id}><button className="cardbutton">tovább az adatlaphoz</button></NavLink>
      </div>
      
   </div>

    );
  }
}