import React, {Component} from 'react';
import {connect} from 'react-redux';
import {request} from '../../../Model/Request';
import {Container} from "react-bootstrap";
import Sidebar from "../SideBar/SideBar";
import defaultImage from "../../../Assets/mesterlaptop.png"
/* ROUTER */
import { Link } from "react-router-dom";
import Select from 'react-select';

 class Mandate extends Component  {

  state = {
    data:[],
    resultsNumber:0,
    page:1,
    per_page:9
  }

  componentDidMount(){
    this.getData()
  }

  getData = async()=> { 
    const response = await request({ url:`/offers/confirmed?per_page=${this.state.per_page}&page=${this.state.page}`, method:"GET" })
    console.log(response)
    if(typeof response.error === "undefined"){
      this.setState({data:response?.data?.offers ?? [], resultsNumber: response.data?.paginator?.total_item ?? 0, page: response.data?.paginator?.current_page ?? 1 })
    }
  }

  removeHTMLTags = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return data.replace(regex, "");
  }

  smallBoxComponent = (item, index) => {

    return(
      <div style={{ ...styles.boxcomponentContainer }} key={"mandate_" + item.id ?? 0}>

          {/* image */}
          <div style={{ backgroundImage:`url(${item.relation?.basic_image?.src ?? defaultImage})`, ...styles.boxcomponentImage }}/>
          {/* container */}
          <div style={{padding:10}}>
            {/* title */}
            <div style={{ ...styles.fontStyle, ...styles.boxcomponentTitle, textAlign:"center" }}>ELFOGADOTT AJÁNLAT</div>
            {/* text */}
            <div style={{ ...styles.fontStyle2, ...styles.boxcomponentDescription, height:140 }}> {this.removeHTMLTags(item.description) ?? ""} </div>
            {/* button */}
            <Link to={{ pathname: '/contract', state: { ...this.state.data[index] } }} > 
              <div style={{ ...styles.fontStyle, ...styles.boxcomponentButton }} >tovább a szerződéshez</div>
            </Link>
          </div>

      </div>
    )

  }

  paginatorComponent = () => {
    return(
      <>
      <div style={{...styles.line}} />
      <Container style={{display:"flex"}} >
      
      {/* middle */}
      <div  style={{display:"flex", alignItems:"center", justifyContent:"center", color:"#4F4F4F", ...styles.fontStyle, padding:5}}>TALÁLATI OLDAL: </div>

      <Select
              options={
                Array(this.state.resultsNumber)
                  .fill(null)
                  .map( (x,i) => i )
                  .filter( (item, index) =>  index % this.state.per_page === 0)
                  .map((item, index)=>{ return {value:index+1, label:index+1} })
              }
              onChange={ async (e)=>{ 
                  await this.setState({page:e.label})
                  await this.getData()
              }}
              styles={styles.pageSelectStyle}
              placeholder={"1"}
              noOptionsMessage={()=><div>Nincs választható oldal</div>}
              />
  
      </Container>
      <div style={{...styles.line}} />
      <div style={{marginBottom:70}} />
      </>
    )
  }

  render(){
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (
      <Container style={{backgroundColor:"white", paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        <div style={{display:"flex"}}>
          <div style={{flex:2}}><Sidebar/></div>
          <div style={{flex:12,paddingTop:50, paddingBottom:50,}}>
  
          { this.paginatorComponent() }
   
          <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:'center'}}>
          {  
              typeof this.state.data !== "undefined" && this.state.data !== null && this.state.data.length > 0 
                ? this.state.data.map( (item, index) => this.smallBoxComponent(item, index) ) 
                : <div style={{fontSize:20, fontFamily:"MONDA"}}>JELENLEG NINCCS MEGBÍZÁSOD</div> 
          }
          </div>

          </div>
        </div>
      </Container>
    );

  }
  
}

const styles = {

  boxcomponentContainer: {width:280, maxHeight:550, margin:10, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius:5 },
  boxcomponentImage: { height:250, width:280, backgroundPosition:"center center",  backgroundRepeat:"no-repeat", borderRadius:5},
  boxcomponentTitle: { marginTop:10, marginBottom:10},
  boxcomponentDescription: {maxHeight:100,marginTop:10, marginLeft:10, marginRight:10, marginBottom:20, overflow: "hidden", textOverflow: "ellipsis",},
  boxcomponentButton:{fontSize:12, textAlign:"center", margin:10, padding:10, background:"#0D74B0", color:"white", borderRadius:5},
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  fontStyle2: {letterSpacing:3, fontFamily:"Open Sans", fontSize:12, fontWeight:"normal",},
  pageSelectStyle : {
    container: provided => ({
      ...provided,
      borderRadius:3,
      width: 200,
      fontFamily:"Maven Pro",
      fontSize:16,
      fontWeight:"bold",
      color:'#4F4F4F',
    }),

    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected ? "#1C466F":"white",
      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"

    }),

    control: base => ({
      ...base,
      border: 0,
      borderRadius:3,
      boxShadow: 'none',
      fontFamily:"Maven Pro",
      height:50,
      letterSpacing: 3
    })
}

} 


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Mandate);


 