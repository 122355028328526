import React, {Component, Fragment} from 'react';

/* REDUX CONNECT */
import {connect} from 'react-redux';
/* REDUX ACTIONS */
import {dimensionAction, scrollAction} from '../Model/Action/index';

import loader from '../Assets/mloader.gif';


/// components
import RouterComponent from './RouterComponent';

class System extends Component  {

/*====================== 
* LIFE CYCLE
=======================*/



componentDidMount() {
  /// create listener
  window.addEventListener('resize',     this.handleWindowSizeChange);
  window.addEventListener("scroll",     this.handleWindowScrollChange, false);
  window.addEventListener("dragenter",  this.handleDragFile, false);
  window.addEventListener("dragover",   this.handleDragFile, false);
  window.addEventListener("drop",       this.handleDragFile, false);
  
}

componentWillUnmount() {
  /// remove listener
  window.removeEventListener('resize',    this.handleWindowSizeChange);
  window.removeEventListener("scroll",    this.handleWindowScrollChange, false);
  window.removeEventListener("dragenter", this.handleDragFile, false);
  window.removeEventListener("dragover",  this.handleDragFile, false);
  window.removeEventListener("drop",      this.handleDragFile, false);
  
}



/*====================== 
* HANDLERS
=======================*/

/// add window dimensions to the STORE
handleWindowSizeChange = () => {
  this.props.dimensionAction({ width: window.innerWidth, height:window.innerHeight });
};

handleWindowScrollChange = (e) => {
/*   const yScroll = window.scrollY; // TOP - BOTTOM
  const xScroll = window.scrollX; // LEFT - RIGHT
  console.log(yScroll)  */
  
 // this.props.scrollAction({ x:  window.scrollX, y: window.scrollY })
}

handleDragFile = (e) => {
  if (e.target.id != "dropzone") {
    e.preventDefault();
    e.dataTransfer.effectAllowed = "none";
    e.dataTransfer.dropEffect = "none";
  }
}

isUserLoggedIN = () => {
  const {user} = this.props.userState
  return Object.entries(user).length === 0 && user.constructor === Object ? false : true
 }




  render(){
    return (
      <>
        <div  style={{display: this.props.systemState.loading ? "block" : "none", position:"fixed", top: this.props.systemState.dimension.height / 2, left:"50%",  zIndex:100000}}><img src={loader} style={{width:200, height:150, marginLeft:-100}} /></div>   
        <div className="registeruser" style={{ filter: this.props.systemState.loading ? 'blur(6px)':'blur(0px)'}}>
            <RouterComponent/>
        </div>
      </>
    );

  }
}

/*====================== 
 * State to Props
 =====================*/
const mapStateToProps = state => {
  const {systemState} = state;
  return {systemState};
};

/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,
  {
    dimensionAction,
    scrollAction
  }
  )(System);
