import React, {Component} from 'react';

/* REDUX CONNECT */
import {connect} from 'react-redux';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

/* VIEW COMPONSNTS */
import Header from '../View/Header/Header';
import Footer from '../View/Footer'
import RouterObserver from "../View/RoutObserver";


/* ROUTS */
import OpeningPage from './OpeningPage/OpeningPage';


import LogInPage from './Login/LogInPage';
import Search from "./Search/Search";

import Registration from './Registration/registration';

import Users from './Users/Users';
import Experts from './Experts/Experts';
import Expert from './Expert/Expert';
import AdvertOffer from './AdvertOffer/AdvertOffer';
import Contract from './Contract/Contract';
import SuccessFee from "./Experts/SuccessFees/SuccessFee";
import ExpertTools from "./Expert/ExpertTools";
import ExpertTool from "./Expert/ExpertTool";
import AskTheExpert from "./AskTheExpert/AskTheExpert";
import ServiceSmarty from "./ServiceSmarty/ServiceSmarty";
import Faq from "./Faq/Faq";
import Ai from "./Ai/Ai";
import At from "./Ai/At";
import Afsz from "./Afsz/Afsz";

/// error page 404
import Page404 from './Page404';



 class RouterComponent extends Component  {

  isUserLoggedIN = () => {
    const {user} = this.props.userState
    return Object.entries(user).length === 0 && user.constructor === Object ? false : true
  }

  userType = () => this.isUserLoggedIN() ? this.props.userState?.user?.attributes?.type ?? null : null





  render(){

    const PrivateRoute = ({component: Component, ...rest}) => {

      const rounter_type = rest.type;
      const user_type = this.userType();
      return (
          // Show the component only when the user is logged in
          // Otherwise, redirect the user to /signin page
          <Route {...rest} render={props => (
            this.isUserLoggedIN()
                ? rounter_type === user_type ? <Component {...props} /> : <Redirect to="/" />
                : <Redirect to="/login" />
          )} />
      );
  };


    return (

      <Router>
        <RouterObserver>
          <div>
            <Header/>

                {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                <Switch>
                  <Route path="/"                          component={OpeningPage}  exact/>
                  <Route path="/login"                     component={LogInPage}    exact/>
                  <Route path="/registration"              component={Registration}/>
                  <Route path="/asktheexpert"              component={AskTheExpert}/>
                  <Route path="/servicesmarty/:id"         component={ServiceSmarty}/>
                  <Route path="/faq"                       component={Faq}/>
                  <Route path="/ai"                        component={Ai}/>
                  <Route path="/at"                        component={At}/>
                  <Route path="/Afsz"                      component={Afsz}/>
                  
          
                  
                  <Route path="/search"                    component={Search}/>
                  <Route path="/expert/:id"                component={Expert}/>
                  <Route path="/experttools/:id"           component={ExpertTools}/>
                  <Route path="/experttool/:id/:user_id"   component={ExpertTool}/>


                  {/* PRIVATE ROUTES */}
                  <PrivateRoute path="/users"         component={Users}       type="basic" />
                  <PrivateRoute path="/experts"       component={Experts}     type="professional"/>
                  <PrivateRoute path="/advertoffer"   component={AdvertOffer} type="professional"  exact/>
                  <PrivateRoute path="/contract"      component={Contract}    type="professional"  exact/>
                  <PrivateRoute path="/successfee"    component={SuccessFee}  type="professional"  exact/>

                  {/* 404 */}
                  <Route path="*"                     component={Page404} />
                </Switch>

              <Footer/>
            </div>
          </RouterObserver>
        </Router>

    );

  }
}

/*======================
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {userState} = state;
  return {userState};
};

/*===================================================
 * Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(RouterComponent);
