import React, {Component} from 'react';
import {connect} from 'react-redux';

import {request} from '../../Model/Request';


import {Container} from "react-bootstrap";

import 'react-quill/dist/quill.snow.css';
import Map from "../../View/Map";

import defaultBanner from "../../Assets/mester_default.png";
import chat_icon from "../../Assets/message_2.png";

import Lightbox from 'react-image-lightbox';

import parse from 'html-react-parser';
import queryString from "query-string";

/* CALENDAR */
import {endOfMonth, addMounthToDate} from "../../Model/calendar_functions/calendar_finctions"
import locale from 'date-fns/locale/hu';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import "../../Model/Calendar/CalendarChange.css";

import empty_star from "../../Assets/empty_star.png";
import filled_star from "../../Assets/filled_star.png";



const honapok = [
  "január",
  "február",
  "március",
  "április",
  "május",
  "június",
  "július",
  "augusztus",
  "szeptember",
  "október",
  "november",
  "december",]

 class Expert extends Component  {


  state = {

    isOpen:false,
    photoIndex: 0,

    user_id:null,
    text:"",   /// introduction
    images:[],    /// images about my works
    data:[],      /// geo DATA
    /* 
    data:[{
      id: 0,
      name: "",
      zip:"",
      latitude: "0",
      longitude: "0",
      circle : {
        radius: 0,
        options: {
          strokeColor: "#217BA7",
          fillColor:"#217BA7"
        }
      }
    }]
    */
    saveData:[],  /// service DATA
    avatar:"",
    name:"",
    banner:"",
    ratingData:[],

    months:2,

    from_date:new Date(new Date().setDate(new Date().getDate()+1)),
    until_date:addMounthToDate(1, endOfMonth(new Date())),
    days : [],
    originalDates:[],
    focused:[0,0]

  }


  getDataArray = [
    {url:`public/company/profession?id=${this.props.match.params.id}`,                  state:"saveData"},
    {url:`public/company/geo?id=${this.props.match.params.id}`,                         state:"data"},
    {url:`public/company/picture?id=${this.props.match.params.id}&type=basic`,          state:"images"},
    {url:`public/company/picture?id=${this.props.match.params.id}&type=banner`,         state:"banner"},
    {url:`public/company/profile/introduction?id=${this.props.match.params.id}`,        state:"text"},
    {url:`public/company/owner?id=${this.props.match.params.id}`,                       state:"avatar"},
  ]

 componentDidMount(){
   // `company/profile/profession?id=${}`
   this.props.systemState.dimension.width <= 991 
        ?  this.setState({  months:1 }) 
        :  this.setState({  months:2 })

      //  this.setState({})  from_date:this.dateParser_hyphen(addMounthToDate(this.startNumber)), until_date: this.dateParser_hyphen(endOfMonth(addMounthToDate(this.startNumber + 1))) 

    
     
   for (let index = 0; index < this.getDataArray.length; index++) {
     const item = this.getDataArray[index];
     this.getData({url:item.url, state:item.state})
   }

   (async ()=>{

    const response = await request({url:`/public/company/owner?id=${this.props.match.params.id}`, method:"GET"})
    if(typeof response.error !==  "undefined") return
    const user_id = response?.data?.id ?? null

    if(user_id !== null){
      const response_rate = await request({url:`/rating?format=list&user_id=${user_id}&per_page=10&page=1`, method:"GET"});
      console.log("===========")
      console.log(user_id)
      console.log(response_rate)
      console.log("===========")
      if(typeof response_rate.error ===  "undefined"){
        const expert_rate = response_rate?.data?.ratings ?? [];
        this.setState({ratingData:expert_rate})
      }
    }
 
    await this.setState({user_id})

    await this.getDate()

  })();

   
  

 }


 componentDidUpdate(prevProps, prevState){

  if(prevProps.systemState.dimension.width !== this.props.systemState.dimension.width){
    
      if( this.props.systemState.dimension.width <= 950 ){
        this.setState({months:1})
      }else{
        this.setState({months:2})
      }

  }

  if(prevState.from_date !== this.state.from_date){
    this.getDate()
  }

 }

 


 getData = async ({url, state }) => {

  try{

    const response = await request({url, method:"GET"});

    if(typeof response.error !== "undefined"){
      return
    }else{
     
      if(url.includes("introduction")){
        this.setState({[state]:response.data.introduction})
      }else if(url.includes("basic")){

        console.log("============")
        console.log(response)
        console.log("============")

        const data = Object.values(response.data.basic).filter(item => item !== null)
       
        this.setState({[state]:data})

      }else if(url.includes("banner")){
  
        
        const data = response?.data?.banner ?? {src:""};
        const safe_data = data[Object.keys(data)[0]].src
        
      
          if(safe_data.length === 0) return
          this.setState({banner:safe_data}) 



      }else if(url.includes("owner")){
        this.setState({avatar:response.data.attributes.avatar.src, name:response.data.attributes.name})
      }else if(url.includes("geo")){

        this.setState({[state]:response.data.filter( item => item.type === "area").map( (item, index) => {  return {
            id: index,
            name: item.city,
            zip:item.zip_code,
            latitude: item.lat,
            longitude: item.lng,
            circle : {
              radius: item.distance,
              options: {
                strokeColor: "#217BA7",
                fillColor:"#217BA7"
              }
            }

          }    } )})
      
          
      }else{
        console.log(response.data)
        this.setState({[state]:response.data})
        
      }
      
    }
     
  }catch(e){
    console.log(e)
  }


 }

 dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`

 getDate = async () => {

  try{

   
      const id = this.state.user_id
    
    
      if(id === null || this.state.from_date === null || this.state.until_date === null ) return


      console.log("_______")
      console.log(this.dateParser_hyphen(this.state.from_date))
      console.log("_______")
      console.log(this.dateParser_hyphen(this.state.until_date))
      console.log("_______")

      const url = `/public/calendar?user_id=${id}&from_date=${this.dateParser_hyphen(this.state.from_date)}&until_date=${this.dateParser_hyphen(this.state.until_date)}`;
      const response = await request({ url, method:"GET" })

    
  
  
      if(typeof response !== "undefined" && typeof response.error === "undefined" ){

        const safe_data = response?.data ?? [];

       
        

        const days = safe_data.filter(item => item.attributes.event_type !== "Tool" && item.attributes.event_type  !== null ).map( (item, index)=> {
          return { 
              type: "old",
              id:item.id,
              event_id:item.attributes.event_id,
              startDate:new Date(item.attributes.from_date),
              endDate:new Date(item.attributes.until_date),
              key: item.attributes.status === "busy" ? "own__"+ item.id + "_" + item.attributes.event_id : "help_" + item.id + "_" + item.attributes.event_id,
          }
       })

      

       /// days.filter(x => !this.state.days.includes(x)); 
       const oldDataArray = this.state.days.map(item=>item.id).filter(u => typeof u !== "undefined");
       const originalDatesOldArray = this.state.originalDates.map(item=>item.id).filter(u => typeof u !== "undefined")
    
       console.log(oldDataArray)
       console.log(originalDatesOldArray)

       this.setState({
         days:[...this.state.days, ...days.filter(x => !oldDataArray.includes(x.id)) ],
         originalDates:[...this.state.originalDates,  ...days.filter(x => !originalDatesOldArray.includes(x.id))]
       }) 

       /*  this.setState({
          days,
          originalDates:days
        })  */

      }


  }catch(e){
    console.log(e)
  }
  

 }



  
 setDateState = (a) => this.setState({ from_date: a, until_date: addMounthToDate( 1, endOfMonth(a) ) }) 
 
  
  
 isUserLoggedIN = () => {
  const {user} = this.props.userState
  return Object.entries(user).length === 0 && user.constructor === Object ? false : true
 }


 


  titleComponet = ({title, state }) => {
    return(
      <>
      <div style={{display:"flex", flexDirection:"row", marginTop:50}}>
        <div style={{flex:1}}>{title}</div>
      </div>
      <div style={{width:'100%', height:1, backgroundColor:"black", marginBottom:30, marginTop:10}} />
      </>
    )
  }

  ratingComponent = (index, item) => {

    const clean = item?.attributes?.rates?.clean ?? 0;
    const exact = item?.attributes?.rates?.exact ?? 0;
    const precise = item?.attributes?.rates?.precise ?? 0;
    const avarege = Math.round((clean + exact + precise) / 3)

    const stars = (avarege) => {

      const container = (data = []) => <div style={{display:"flex", justifyContent:"space-evenly"}}> { data.map( (item, index) => <img key={index} src={item} width="45px" height="45px" /> ) }  </div>


      switch (avarege) {
        case 0: return container([empty_star,   empty_star,   empty_star,    empty_star,    empty_star])
        case 1: return container([filled_star,  empty_star,   empty_star,    empty_star,    empty_star])
        case 2: return container([filled_star,  filled_star,  empty_star,    empty_star,    empty_star])
        case 3: return container([filled_star,  filled_star,  filled_star,   empty_star,    empty_star])
        case 4: return container([filled_star,  filled_star,  filled_star,   filled_star,   empty_star])
        case 5: return container([filled_star,  filled_star,  filled_star,   filled_star,   filled_star])
          
        default:case 0: return container([empty_star, empty_star, empty_star, empty_star, empty_star, empty_star])
         
      }

    }

    return(
      <div key={"rate_"+index}>
        
        <div style={{fontFamily:"Maven Pro", fontSize:15, fontWeight:"bold", color:"#4F4F4F", letterSpacing:3}}>{item?.attributes?.rater?.name ?? ""}</div>
        <div style={{fontFamily:"Maven Pro", fontSize:15, fontWeight:"normal", color:"#4F4F4F", letterSpacing:3}}>{item?.attributes?.textual ?? ""}</div>
        <div style={{marginBottom:50, marginTop:20}}>{stars(avarege)}</div>
      </div>
    )

  }



/*   selectedModalComponent = () => {
    switch (true) {
      case this.state.introductionEdit === true:
           return  <ReactQuill value={this.state.text} onChange={(value)=>this.handleChange(value)} style={{height:300, marginBottom:80}}  />

      case this.state.servicesEdit === true:
            return  <RegisterServices importData={this.state.saveData}  exportData={this.exportData} />

      case this.state.imagesFromJobsEdit === true:
           return  this.imagesFromJobsEditComponent()

    
      default:
        break;
    }
  } */

  render(){
  

    console.log(this.state)
    console.log(this.props)

 
    return (

      <div>

        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.images[this.state.photoIndex].src}
            nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length].src}
            prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length].src}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
              })
            }
          />
        )}
        

      <div style={{ ...styles.imgCover, backgroundImage:`url(${this.state.banner !== "" ? this.state.banner : defaultBanner})`  }}>

        {

           this.isUserLoggedIN() 
            ? <div 
              style={{ height:60, width:60, backgroundColor:"rgba(38,134,176,0.6)",backgroundPosition:"center", backgroundSize:"60%", backgroundRepeat:"no-repeat" ,backgroundImage:`url(${chat_icon})`,  position:"absolute", right:80, marginTop:30, borderRadius:10, zIndex:200}} 
              onClick={()=> this.props.history.push({
              pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
              search: '',
              state: { type: "company" , id:  this.props.match.params.id}
              }) }></div>
             :  null

        }
      
            <div style={{...styles.firstContainer}}>
             
              {/* AVATAR IMG */}
               <div style={{ ...styles.avatarIMG,backgroundImage: `url(${this.state.avatar})` }}></div>
              {/* NAME */}
               <div style={{zIndex:10, marginTop:90, ...styles.p, fontSize:30,  olor:"#4F4F4F"}}>{this.state.name}</div>
           </div>
           
      </div>
      <div style={{...styles.secoundContainer}}>
      
      <div style={{display:"flex", justifyContent:"space-evenly", alignItems:"center", height:100}}>

        <div style={{...styles.p, ...styles.button, width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, cursor:"pointer" }}  onClick={()=> this.props.history.push(`/experttools/${this.props.match.params.id}`) } >BÉRELHETŐ SZERSZÁMAIM </div>
        <div style={{...styles.p, ...styles.button, width:this.props.systemState.dimension.width > 950 ? "33%" : "100%", margin:10, cursor:"pointer" }}  onClick={()=> {
   
            this.isUserLoggedIN() 
              ? this.props.history.push({ 
                    pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
                    search: '',
                    state: { type: "company" , id:  this.props.match.params.id}
                })
              : this.props.history.push({
                pathname: "/registration/user", 
                state: {  
                    type: "basic", 
                    from: "chat",
                    data: { type: "company" , id:  this.props.match.params.id }
                  }
              }) 
              
        }} >AJÁNLAT KÉRÉS</div>

      </div>

      </div>

      <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        
      
      


       <div>

  

      
        <div style={{display:"flex", flexDirection: this.props.systemState.dimension.width > 990 ? "row" : "column"  }}>

        
             <div style={{flex:3,  padding:30}}>


             
          
                  {this.titleComponet({title:"BEMUTATKOZÁS", state:"introductionEdit" })}
                     
                   {/*  <div>{typeof this.state.text === "undefined" || this.state.text === ""  || this.state.text === null  ? "" : parse(this.state.text)}</div>  */}

                    <div>{parse(this.state.text ?? "")}</div> 
                      <div style={{marginTop:30, marginBottom:30}} />

                      
                        {this.titleComponet({title:"MUNKÁIM", state:"imagesFromJobsEdit" })}
                      <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center" }}>
                        { this.state.images.map( 
                          (item, index)=>  
                          index === 0 
                              ? <div key={index} style={{...styles.imgDIVBig, backgroundImage:`url(${item.src})`, cursor:"pointer" }} onClick={()=>{ this.setState({ isOpen: true, photoIndex:index }) }} />
                              : <div key={index} style={{...styles.imgDIV,    backgroundImage:`url(${item.src})`, cursor:"pointer" }} onClick={()=>{ this.setState({ isOpen: true, photoIndex:index }) }} />
                          )
                        }
                        </div>
                        <div style={{marginTop:30, marginBottom:30}} />

                    
                        {this.titleComponet({title:"SZOLGÁLTATÁSOK", state:"servicesEdit" })}
                        <div style={{marginTop:30, marginBottom:30}} />
                      
                        <div style={{display:"flex", flexWrap:"wrap"}}>
                        {     
                              typeof this.state.saveData !== "undefined" &&
                              this.state.saveData !== null &&
                              this.state.saveData.length !== 0 
                              ? this.state.saveData.map(
                              (item, index)=> 
                              <div key={new Date().getMilliseconds() + index} style={{width:300, display:"flex", flexDirection:"column",  justifyContent:'center', alignItems:"flex-start", fontFamily:"MONDA", fontSize:20}}>
                                <div key={index + item.name}>{`${item.name}`}</div>
                                {/* { 
                                 item.specializations.map( (specItem, specIndex) => <div key={specIndex + item.name + specItem.name }>{`${item.name}/${specItem.name}`}</div>) 
                                } */} 

                              </div>
                              )
                              : null
                        }
                        </div>

                        {this.titleComponet({title:"NAPTÁR", state:"imagesFromJobsEdit" })}
                        <div style={{width:'100%', height:1, backgroundColor:"black"}} />

                      {/*   <DateRange
                          showDateDisplay={false}
                          showPreview={true}
                          showSelectionPreview={true}
                         

                          ///// this have to ask GET
                          onShownDateChange={(a)=> {console.log(a) }}
                          disabledDates={[]}
                          ranges={[ ...this.state.days  ]}
                          rangeColors={this.state.days.length > 0 ? [  ...this.state.days.map( (item, index) => item.key.includes("own") ? "rgb(61, 145, 255)" : "#4F4F4F" )] : ["#4F4F4F"] }
                          locale={locale}
                          months={ this.state.months }
                          direction={"horizontal"}
                        /> */}

                  <DateRange
                    showDateDisplay={false}
                    showPreview={true}
                    showSelectionPreview={true}
                    minDate={new Date( new Date().setDate(new Date().getDate() + 1))}
                    shownDate = { this.state.from_date}
                    ///n this.setState({  from_date:a, until_date:addMounthToDate(1, endOfMonth(a)) }) 
                    onShownDateChange={ (date)=> this.setDateState(date) }
                    focusedRange={this.state.focused}
                    onRangeFocusChange={(focused)=> focused}
                    disabledDates={[]}
                    ranges={[ ...this.state.days  ]}
                    rangeColors={this.state.days.length > 0 ? [  ...this.state.days.map( (item, index) => item.key.includes("help") ? "rgb(61, 145, 255)" : "#4F4F4F" )] : ["#4F4F4F"] }
                    locale={locale}
                    months={ this.state.months }
                    direction={"horizontal"}
                    onChange={ (e) => e }
                />
           
           <div style={{background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)", transform:"rotate(180deg)"}} >
             <div style={{transform:"rotate(180deg)", height:100, marginRight:50, marginLeft:50}}>

               <div style={{marginBottom:20, width:"100%", height:1, backgroundColor:"white",}} />

                <div style={{display:"flex", justifyContent:"space-between"}}>

                    <div style={{flex:1}}></div>
                    <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(61, 145, 255)"}} />
                      <div style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10}} >MUNKÁT VÁLLAL</div>
                    </div>

                    <div style={{flex:1}}></div>
                    <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(79, 79, 79)"}} />
                      <div  style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10, }} >ELFOGLALT</div>
                    </div>
                    <div style={{flex:1}}></div>

                </div>

               
              </div>

           </div>


         


          </div>

             <div style={{flex:2, padding:30, flexDirection:"column"}}>

                <div>
                  {
                    this.state.data.length > 0 ?  
                    <>
                    {this.titleComponet({title:"MUNKAVÉGZÉS HELYE", state:"introductionEdit" })}
                    <Map
                      center={{ lat: parseFloat(this.state.data[0].latitude) , lng: parseFloat(this.state.data[0].longitude) }} 
                      zoom={9}
                      places={this.state.data}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyACF9fxvIiQpRviYSy489fi9CKABwzhQvI"
                      loadingElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%`, filter:"grayscale(95)"}} />}
                
                      containerElement={<div style={{ height:'600px', width:'100%' }} />}
                    />  
                    </>
                    : null
                  } 
                  </div>

                  <div>
                  { 
                    this.state.ratingData.length > 0 ?  
                    <>
                        {this.titleComponet({title:"ÉRTÉKELÉSEK", state:"" })}
                        {this.state.ratingData.map( (item, index)=> this.ratingComponent(index, item)  )}
                    </>   
                    : null

                  }
                  </div>

           </div>

           



        </div>

      </div> 



  


      </Container>
    
     </div>
    );

  }
 }

const styles = {

  imgDIV : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"33%", 
            height:210,
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },
imgDIVBig : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%", 
            height:410,
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },

imgCover : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%",
            height:600,
},  

firstContainer : {
            position:"absolute", 
            left:0, 
            right:0, 
            height:200, 
            backgroundColor:"rgba(255, 255, 255, 0.9)", 
            marginTop:400, 
            display:"flex", 
            justifyContent:'center'
},

secoundContainer : { 
            height:100, 
            width:"100%",
            background: "linear-gradient(180deg, #0F3A83 0%, #0C78B5 100%)",
            ransform: "rotate(180deg)",
            marginBottom:30
},

avatarIMG :         {
              height:270, 
              width:270, 
              borderRadius:14, 
              border:'4px solid #FFF', 
              
              backgroundSize:"cover",
              objectFitt:"cover",
              backgroundRepeat:"no-repeat",
              backgroundColor:"white",
              backgroundPosition:"center", 
              zIndex:2, 
              position:"absolute", 
              bottom:150,
              boxShadow:"  2px 4px 20px rgba(0, 0, 0, 0.25)"
},
p :                    {
              fontFamily:"Maven Pro", 
              letterSpacing:4
              
              
              
},

button:               {
              color:"white", 
              padding:8, 
              display:"flex", 
              justifyContent:"center",
              borderRadius:4, 
              border:'1px solid #FFF', 
              height:65, 
              alignItems:"center", 
              fontSize:14
},

}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Expert);


/* 

<div style={{display:"flex", flex:12, flexDirection: this.props.systemState.dimension.width > 990 ? "row" : "column"  }}>

        
             <div style={{flex:3, backgroundColor:"red", padding:60}}>

          
            <div>BEMUTATKOZÁS</div>
            <div style={{width:'100%', height:1, backgroundColor:"black"}} />
            {this.introductionComponent()}


            <div style={{marginTop:30, marginBottom:30}} />

          
            <div>MUNKÁIM</div>
            <div style={{width:'100%', height:1, backgroundColor:"black"}} />
            {this.imagesComponent()}


            <div style={{marginTop:30, marginBottom:30}} />

         
            <div>SZOLGÁLTATÁSOK</div>
            <div style={{width:'100%', height:1, backgroundColor:"black"}} />

         
       
         


          </div>

             <div style={{flex:2, backgroundColor:"green"}}></div>


          </div>
*/

 


