import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from "react-bootstrap";


const questionArray = [
    {
      title:"1. Mi az Mesterportál?", 
      body:`A Mesterportál egy webalkalmazás, ahol megálmododott célunk, hogy Magyarország minél teljeskörűbb szépségápolási közösségi terét hozzuk létre Nektek! A hazai szépségipari vállalkozások digitalizálása érdekében ingyenesen biztosítunk megjelenési felületet, időpontfoglalót és egyszerű pénzügy menedzsment rendszert bevételeid, költségeid nyilvántartására. Hír- és blogfelületünkön regisztrált szalonok és szépségipari szakemberek tollából értesülhetsz kezelésekről, innovatív szolgáltatásokról, és professzionális termékekről.
            Gyártóknak és forgalmazóknak szűrővel ellátot webshop felületet biztosítunk termékeik értékesítéséhez. Nálunk a szépségápolásod van a központban ha magánszemélyként látogatsz az oldalra ahol tesztjeinkből megismerheted bőrtípusod, hajtípusod és évszakelméletünk segítségével iránymutatást adunk a megfelelő színek kiválasztásához sminkben, hajszínben de még az ruhatárad összeállításában is alapul veheted a teszt eredményét.`
    },
    {
      title:"2. Hogyan tudok időpontot foglalni egy kezelésre?",
      body:`A szépségszalonok között regisztráció / bejelentkezés nélkül is tudsz böngészni.
            1. A főoldalon a szűrő segítségével a szolgáltatás típusa, a szakember neve és a szépségszalon szerint is tudsz keresni.
            Emellett be tudod állítani, hogy milyen kategóriában (kozmetika, fodrászat...stb) keresel szolgáltatást, hol, mikor és, hogy milyen márkát preferálsz.
            Ha nem szeretnéd a szűrőt használni, akkor a főoldalon az egyes kategóriákra kattintva már át is tudsz ugrani a szalon listára.
            2. Ezután a bal oldali sávban további szűrési lehetőségeket találsz, míg középen az elérhető szalonok listáját.
            3. Ha kiválasztottad a szalont és szeretnél egy kezelésre bejelentkezni, akkor regisztrálnod kell, vagy be kell jelentkezned.
            4. Miután ráklikkeltél a kívánt szolgáltatásra, és a szakembert is kiválasztottad már meg is jelenik az online naptára, ahol ki tudod választani az időpontot, amikor szeretnél menni az adott kezelésre. 5. Végezetül a ’foglalásaim’ menüpontban láthatod összesítve, hogy melyik napra, hány órára, milyen kezelést/kezeléseket foglaltál, melyik szakembernél, hogy mennyi ideig fog tartani és hogy összesen mennyibe kerül.`
     },
     {
       title:"3. Hogyan tudom a szalonommal igénybe venni a szolgáltatást?",
       body:`Ha mint szalon tulajdonos regisztrálsz, akkor eldöntheted, hogy melyik kollégádnak melyik Mesterportál funkcióhoz adsz hozzáférést, és ennek megfelelően tudod te kezelni a munkatársak/beosztottak profilját és naptárát, vagy ezt megtehetik saját maguk.

       Lépések:
       1. Először mint magánszemély tudsz regisztrálni. Ezután az ’új szalon hozzáadása’ gombbal elkészítheted a szalonod regisztrációját. Ha egyéni vállalkozó vagy akkor is erre a gombra kell kattintanod, mert itt tudod felvinni a vállalkozásod részleteit.
       2. Készítsd el a szalonod profilját! Itt meg tudod adni az alap adatokat, készíthetsz leírást a szalonról, megadhatod a nyitvatartási időt és feltölthetsz képeket is az üzletről. Valamint itt tudod meghívni a beosztottakat, munkatársakat az Mesterportál rendszerébe.
       3. Amennyiben, mint szalon tulajdonos regisztrálsz, akkor ezután hozzáadhatod a kollégákat a szalonhoz, megadhatod az adataikat, jogosultságaikat valamint, hogy milyen kezeléseket végeznek és, hogy ezek mennyibe kerülnek. Érdemes pontosan felvezetni, hogy az egyes kezelések mennyi időt vesznek igénybe, így a naptárban a foglalást a korábban megadott időtartamú sávra húzza be a rendszer.
       4. Aktiváld a naptáradat és a kollégákét (attól függően, hogy kinek milyen jogosultságot adtál)! Be tudod állítani a szabad időpontokat, amikor dolgozol és, amikre bejelentkezéseket fogadsz, napi vagy heti nézetben.
       5. Már használhatod is az online foglalási rendszert.
       `
     }
]


 class Faq extends Component  {
  
    title = (data) => <div style={{...styles.title_p}}>{data}</div>
    body = (data) => <div style={{...styles.body_p}}>{data}</div>

    render(){
      const window_width =  this.props.systemState.dimension.width;
      return (
        <div style={{...styles.outerBG}}>

        <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
          <div>GY.I.K.</div>
        </div>
       
            {/* LIST */}
            <Container style={{backgroundColor:"white", paddingTop:50, paddingBottom:50, paddingLeft:0, paddingRight:window_width>550?50:0, borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>
        
              <div style={{padding:60}}>
               
              {
                questionArray.map( (item, index)=> <div styles ={{...styles.div_container}}>{this.title(item.title)}{this.body(item.body)}</div> )
              
              }
                  
              </div>

          </Container>

        <div style={{...styles.bottomSpacer}}/>

      </div>
      );
  
    }
  }

  const styles = {
    div_container : {display:"flex", flexDirection:"column", flexWrap:"wrap"},
    headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},
    bottomSpacer: {height:30, width:"100%"},
    outerBG: {background:"#F6F9FE"},
    title_p: {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"bold", color:"#0C78B5", marginTop:20, marginBottom:20 },
    body_p:  {letterSpacing:3, fontFamily:"Maven Pro", fontSize:12, fontWeight:"400",  color:"#4F4F4F" },

    
  }
  



/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState} = state;
  return {systemState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Faq);
