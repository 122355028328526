import React, {Component} from 'react';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  useParams,
  useRouteMatch,
  Redirect,
  NavLink
} from "react-router-dom";

import './Offers.css'

/* ROUTS */
import OfferDetails from      './OfferDetails/OfferDetails';
import OffersList from      './OffersList/OffersList';
import Agreement from      './Agreement/Agreement';



 class Offers extends Component  {

  

  render(){
    

    
    //let path = this.props.match.path;
    //console.log(this.props.match.path);
    
    return (
      <Switch >
          <Redirect exact path="/users/offers" to={`/users/offers/list`}/>
          <Route path={`/users/offers/:subpages`} component={OffersSubManager}/>
      </Switch>
    );


  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default Offers;




const OffersSubManager =() => {

  const { subpages } = useParams();

  switch (subpages) {
    case "list":return <OffersList />
    case "details":return <OfferDetails/>
    case "agreement":return <Agreement/>


    default : return <OffersList/>      
   
  }

}