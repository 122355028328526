import React, {Component} from 'react';

//styles
import './OpeningPage.css';
/* BOOTSTRAP */
//import {Container, Col, Row} from 'react-bootstrap';

import {Helmet} from "react-helmet";


//sections

import AddPostingSection from "./AddPostingSection/AddPostingSection";
import WorkLists from "./WorkLists/WorkLists";
import Offers from "./Offers/Offers";
import Services from "./Services/Services";
import Expert from "./Expert/Expert";
import AskOffers from "./AskOffers/AskOffers";

 class OpeningPage extends Component  {

  render(){
    return (
        <div>
          <div className="worksBackground">
              <Helmet><title>Mester Portál</title></Helmet> 
              <AddPostingSection></AddPostingSection>

              <WorkLists viewType="inner" num = "30"></WorkLists> 
              <Offers viewType="inner" num="5"></Offers>  

              <WorkLists viewType="outer" num = "30" ></WorkLists>
              <Offers viewType="outer" num="5"></Offers>  
                
          </div>
          <Services num="2"></Services>
          <Expert></Expert>
          <AskOffers></AskOffers>
        </div>  
        
    );

  }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default OpeningPage;

