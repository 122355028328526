const INITIAL_STATE = { 
    user:{}  
};



export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'setUserAction':
        return {...state, user: action.payload.setUserAction};
      default:
        return state;
    }
  };

