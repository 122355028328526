import React, {Fragment} from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle
} from "react-google-maps";

class Map extends React.Component {
  render(){
    return (
      <GoogleMap
        zoom={this.props.zoom}
        center={this.props.center}
      >
        {this.props.places.map(place => {
          return (
            <Fragment key={place.id}>
              <Marker
                position={{
                  lat: parseFloat(place.latitude),
                  lng: parseFloat(place.longitude)
                }}
              />
              <Circle
                center={{
                  lat: parseFloat(place.latitude),
                  lng: parseFloat(place.longitude)
                }}
                radius={place.circle.radius * 1000}
                options={place.circle.options}
              />
            </Fragment>
          );
        })}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));