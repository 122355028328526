import React, {Component} from 'react';

/* ROUTER */

import {
  BrowserRouter as Router,
  NavLink
} from "react-router-dom";
import {Container} from "react-bootstrap";
import Select from 'react-select';

import './OffersList.css';
import {request}   from "../../../../Model/Request";
import parse from 'html-react-parser';
import default_image from "../../../../Assets/mester_default.png";




 class OffersList extends Component  {

  state = {
    data:[],
    /* 
      img:'https://picsum.photos/id/11/373/293',
      name: 'Asztalos Lajos',
      related_ads_id:'25455',
      related_ads_tittle:"Ács munkálatokkkkk",
      status:'inview',  //inview, accepted, rejected,agreement
      id: '1'
    */
   resultsNumber:0,
   per_page:9,
   page:1

  }

  componentDidMount(){
    this.get_offers()
  }


  get_offers = async () => {

    try{

      /* REQUEST */
      const response = await request({url:`/offers/all?per_page=${this.state.per_page}&page=${this.state.page}`})
      
      /* GUARD FUNC */
     
      if(typeof response.error !== "undefined" || typeof response.data === "undefined" || typeof response.data.items === "undefined" || response.data.items.length === 0) return

      this.setState({data:response.data.items.map( item => { return {  
          img: item?.relation?.basic_image?.src ?? default_image, 
          name: item?.sender?.name ?? "", 
          related_ads_id: item?.relation?.hash_id ?? "",
          related_ads_tittle: item?.profession_name ?? "",
          status: item?.status ?? "",
          id: item?.id ?? ""
      } } ),
      resultsNumber:response.data?.paginator?.total_item ?? 0,
      page:response.data?.paginator?.current_page ?? 1
    })
      
      
    }catch(e){
      console.log(e)
    }

    

  }

  paginatorComponent = () => {
    return(
      <>
      <div style={{...styles.line, marginTop:30}} />
      <Container style={{display:"flex"}}>
      
      {/* middle */}
      <div  style={{display:"flex", alignItems:"center", justifyContent:"center", color:"#4F4F4F", ...styles.fontStyle, padding:5}}>TALÁLATI OLDAL: </div>

      <Select
              options={
                Array(this.state.resultsNumber)
                  .fill(null)
                  .map( (x,i) => i )
                  .filter( (item, index) =>  index % this.state.per_page === 0)
                  .map((item, index)=>{ return {value:index+1, label:index+1} })
              }
              onChange={ async (e)=>{ 
                  await this.setState({page:e.label})
                  await this.get_offers()
              }}
              styles={styles.pageSelectStyle}
              placeholder={"1"}
              noOptionsMessage={()=><div>Nincs választható oldal</div>}
              />
  
  </Container>
      <div style={{...styles.line}} />
      <div style={{marginBottom:70}} />
  </>
    )
  }


 offersCard = ({key, img, name, related_ads_id, related_ads_tittle, status, id }) => 
        <div className="card" key={key}>
            <img className="images" src={img} alt=""/>
            <div className="names">{name}</div>
            <div className="descriptionContainer d-flex justify-content-center">
              <div className="description d-flex align-items-center">ajánlatot küldött a {related_ads_id}. ({related_ads_tittle}) számú hírdetésedre </div>
            </div> 
            
            <div className="buttonContainer">
            {
              status === 'contracted' 
                ?<NavLink to={'/users/offers/agreement?id='+id}> <button className="cardbutton">tovább a szerződésre</button></NavLink>
                :<NavLink to={'/users/offers/details?id='+id}><button className="cardbutton">tovább az ajánlatra</button></NavLink>          
            }
            </div>
        
        </div>
  


  


  
  render(){
    console.log(this.state);
    return (
      <>
      {
        this.paginatorComponent()
      }
       <div className="innerContainer d-flex justify-content-center">
          <div className="innerAligner d-flex flex-wrap justify-content-center">
              {
                this.state.data.length === 0 
                  ? <div className="errorTxt">Jelenleg nincs megtekinthető ajánlat!</div>
                  :  this.state.data.map( (ret,index)=> this.offersCard ({  key:index,  img:ret.img,  name:ret.name,  related_ads_id:ret.related_ads_id,  related_ads_tittle:ret.related_ads_tittle,  status:ret.status,  id:ret.id }))
              }
          </div>
          
      </div>
      </>
    );
  }
}

const styles = {
  line: {  marginTop:5, marginBottom:5, width:'100%', height:1, background:"#C4C4C4" },
  fontStyle: {letterSpacing:3, fontFamily:"MONDA", fontSize:16, fontWeight:"bold", textTransform:"uppercase"},
  pageSelectStyle : {
    container: provided => ({
      ...provided,
      borderRadius:3,
      width: 200,
      fontFamily:"Maven Pro",
      fontSize:16,
      fontWeight:"bold",
      color:'#4F4F4F',
    }),

    option: (provided, state) => ({
      ...provided,
     // borderBottom: '1px solid #4F4F4F',
      backgroundColor: state.isSelected ? "#1C466F":"white",
      color: state.isSelected ? 'white' : '#4F4F4F',
      width: '100%',
      fontFamily:"Maven Pro"

    }),

    control: base => ({
      ...base,
      border: 0,
      borderRadius:3,
      boxShadow: 'none',
      fontFamily:"Maven Pro",
      height:50,
      letterSpacing: 3
    })
},
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default OffersList;



 