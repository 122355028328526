const INITIAL_STATE = { 
    token:{}  
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'setTokenAction':
        return {...state, token: action.payload.setTokenAction};
      default:
        return state;
    }
  };

