import React, {Component, Fregment} from 'react';
import {connect} from 'react-redux';
/* REDUX ACTIONS */
import {setTokenAction, setUserAction} from "../../Model/Action/";
import {request} from "../../Model/Request";
import {Modal, Button} from "react-bootstrap";
import queryString from "query-string";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import './LoginPage.css';

const isPassword = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
  return regex.test(password);
}



 class LogInPage extends Component  {

  state = {
    type:0, /// type: int, 0 = login, 1 = forgot password
    email:"",

    password:"",
    password_confirmation:false,

    password_again:"",
    password_again_confirmation:false,
    
    token:"",
    paswordsIdentical:false,

    modalShow:false,
    modalText:"",
    modalTitle:"",

 
  }


  componentDidMount(){

    const parse = queryString.parse(this.props.location.search);
    
    if(Object.entries(parse).length !== 0 && typeof parse.message !== "undefined" ){
      
            if(parse.message === "autherror"){
              this.setState({
                modalShow:true,
                modalText:" A Hiba merült fel a felhasználó azonosítása közben, kérem jelentkezzen be újra!",
                modalTitle:" Autentikációs hiba "
              })
            }

            if(parse.message === "verification" && typeof parse.token !== "undefined"){
    
               this.setState({type:2, token:parse.token})

            }



    }

  }

  componentDidUpdate(prevProps, prevState){

    if(prevState.password !== this.state.password){
        if(isPassword(this.state.password)){
          this.setState({password_confirmation:true})
        }else{
          this.setState({password_confirmation:false})
        }
    }


    if(prevState.password_again !== this.state.password_again){
      if(isPassword(this.state.password_again)){
        this.setState({password_again_confirmation:true})
      }else{
        this.setState({password_again_confirmation:false})
      }
    }


    if(prevState.password_again !== this.state.password_again || prevState.password !== this.state.password){

      if(this.state.password === this.state.password_again){
          this.setState({paswordsIdentical:true})
      }else{
        this.setState({paswordsIdentical:false})
      }


    }

    





  }



  loginBTN = async () => {

    if(this.state.type === 0){ /// LOGIN

        const response = await request({ method:"POST", url:"/auth/login", data:{ password:this.state.password, email:this.state.email } });

      console.log(response)

        if(typeof response.error !== "undefined"){

          if(typeof response.error.message === "undefined") return

          let errorMessage = "";
          switch (response.error.message) {
            case "Unauthorized!":
              errorMessage = "Nem megfelelő jelszó, vagy email cím!";
              this.setState({email:"", password:"", modalShow:true, modalText:errorMessage, modalTitle:"BELÉPÉSI HIBA"});
              break;

            default:
              errorMessage = "";
              break;
          }


        }else{


          let incompleteRegistration = false;
          switch (response.message) {
            case "Logged in successful!":


                  /// add token to Redux
                  this.props.setTokenAction({...response.data.token, created: new Date()});

                  /// create user object
                  let user = {...response.data.user.attributes};
                  if (typeof response.data.user.companies !== "undefined"){
                      user = {...user, company:[...response.data.user.companies.map( (item, index ) =>  index === 0 ? {...item, selected:true } : {...item, selected:false} )] }
                  }

                    


                  /// if the user object has incompleteRegistration element
                  if(typeof response.data.user.incompleteRegistration !== "undefined"){
                        incompleteRegistration = true;
                        user.incompleteRegistration = response.data.user.incompleteRegistration


                        user.incompleteRegistration.stepTwo.details.images =  user.incompleteRegistration.stepTwo.details.images.map( (item, index) => {
                          if(typeof item.src) return item.src
                        });
                        user.incompleteRegistration.stepThree.details.company.images = user.incompleteRegistration.stepThree.details.company.images.map( (item, index) => {
                          if(typeof item.src) return item.src
                        });



                    }

                  /// add user to Redux
                  this.props.setUserAction(user);
                

                  incompleteRegistration // ha nincs befejezve a regisztrációja
                  ? this.props.history.push("/registration/professional")
                  : user.attributes.type === "professional" /// ha be van fejezve a rgisztrációja és profi
                    ? this.props.history.push("/experts/profiles")
                    : this.props.history.push("/users/profiles")


              break;

            default:
              break;
          }

        }

    }else if(this.state.type === 1){ /// SEND PASSWORD REMINDER

      const response = await request({ 
            method:"POST", 
            url:"/auth/password/forgot/send", 
            data:{ email:this.state.email } 
      });
      
      if(typeof response.message !== "undefined" && response.message === "Success" ){
          this.setState({
            modalShow: true,
            modalTitle: "ÚJ JELSZÓ BEÁLLÍTÁSA",
            modalText: "A megadott e-mail címre elküldtük a további teendőket.",
          })
      }
      

    }else if(this.state.type === 2){

          if(!this.state.password_confirmation){
            this.setState({
              modalShow: true,
              modalTitle: "HIBÁS JELSZÓ",
              modalText: "A jelszónak minimum 8 karakterből kell állnia, és tartalmaznia kell legalább 1 nagy betűt és 1 számot.",
            })
            return
          }

          if(!this.state.paswordsIdentical){
            this.setState({
              modalShow: true,
              modalTitle: "HIBÁS JELSZÓ",
              modalText: "A két jelszó nem azonos!",
            })
            return
          }

          const data = {
            "token": this.state.token,
            "password": this.state.password,
            "password_confirmation": this.state.password_again
          }

          const response = await request({ 
            method:"POST", 
            url:"/auth/password/forgot", 
            data 
          });

          console.log(response)
          if( typeof response.error !== "undefined"){
            if(typeof response.error.token !== "undefined"){

              this.setState({
                modalShow: true,
                modalTitle: "HITELESÍTÉSI HIBA!",
                modalText: "A használt token érvénytelen!",
              })
              return

            }else{

              this.setState({
                modalShow: true,
                modalTitle: "HITELESÍTÉSI HIBA!",
                modalText: "Kérlek próbáld meg később!",
              })
              return

            }      
          }

          if(typeof response.message !== "undefined" && response.message === "Logged in successful!"){
            let incompleteRegistration = false;
             /// add token to Redux
             this.props.setTokenAction({...response.data.token, created: new Date()});

             /// create user object
             let user = {...response.data.user.attributes};
             if (typeof response.data.user.companies !== "undefined"){
                 user = {...user, company:[...response.data.user.companies.map( (item, index ) =>  index === 0 ? {...item, selected:true } : {...item, selected:false} )] }
             }

               


             /// if the user object has incompleteRegistration element
             if(typeof response.data.user.incompleteRegistration !== "undefined"){
                   incompleteRegistration = true;
                   user.incompleteRegistration = response.data.user.incompleteRegistration


                   user.incompleteRegistration.stepTwo.details.images =  user.incompleteRegistration.stepTwo.details.images.map( (item, index) => {
                     if(typeof item.src) return item.src
                   });
                   user.incompleteRegistration.stepThree.details.company.images = user.incompleteRegistration.stepThree.details.company.images.map( (item, index) => {
                     if(typeof item.src) return item.src
                   });



               }

             /// add user to Redux
             this.props.setUserAction(user);
           

             incompleteRegistration // ha nincs befejezve a regisztrációja
             ? this.props.history.push("/registration/professional")
             : user.attributes.type === "professional" /// ha be van fejezve a rgisztrációja és profi
               ? this.props.history.push("/experts/profiles")
               : this.props.history.push("/users/profiles")

          }

          


    }


  }

  typeChanger = () => this.setState({type: this.state.type === 0 ? 1 : 0})



  /* 
  
  SOCIAL LOGIN
  
  */
  facebook_button = () => <button className="facebook">Bejelentkezés Facebook fiókkal</button>


  facebook_authenticate = (response) => {
    console.log(response);
    // Api call to server so we can validate the token
  };


  google_button = () => <button className="google">Bejelentkezés Google fiókkal</button>

  google_authenticate_success = (response) => {
    console.log(response);
  };

  google_authenticate_error = (response) => {
    console.log(response);
  };








  buttonComponent = () => {
    switch (this.state.type) {
      case 0:
        return <button>{"Belépés"}</button>

      case 1:
        return <button>{"Új jelszó igénylése"}</button>

      case 2:
        return <button>{"Új jelszó mentése"}</button>
    
      default:
        return <button>{"Belépés"}</button>
    }
  }
  loginComponent = () => {
    return(
      <>
        <div className="logleft d-flex justify-content-center justify-content-md-end order-2 order-md-1">
          <div className="leftAligner">
              <div className="verticalTittle position-relative">Bejelentkezés</div>
      
                <div className="facebutton"> 
                <FacebookLogin
                  appId="1735464033319601"
                  autoLoad={false}
                  fields="name,email,picture"
                  language={"hu_HU"}
                  callback={this.facebook_authenticate} 
                  render={renderProps =>  <div onClick={renderProps.onClick}>{this.facebook_button()}</div>}
                  />
                 
                </div>
                <div className="googlebutton">
                <GoogleLogin
                  clientId="493138919650-j70t2ap20ebnrim0lruprh8t4c1jnamr.apps.googleusercontent.com"
                  buttonText="Login"
                  autoLoad={false}
                  onSuccess={this.google_authenticate_success}
                  onFailure={this.google_authenticate_error}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps =>  <div onClick={renderProps.onClick}>{this.google_button()}</div>}
                 
                />
                </div>

             
       
              <div className="email">
                <input placeholder="E-mail" onChange={(e)=> this.setState({email:e.target.value}) } value={this.state.email} />
              </div>
              <div className="password">
                <input placeholder="Jelszó" type="password" onChange={(e)=> this.setState({password:e.target.value}) } value={this.state.password} /> 
              </div>
              <div className="forgottenPassword">
                <div onClick={()=>this.typeChanger()} style={{cursor:"pointer"}}> { this.state.type === 0 ? "Elfelejtett jelszó" : "Bejelentkezés" }</div>
              </div>
              <div className="myLoginButton" onClick={()=>this.loginBTN()} >
                  {this.buttonComponent()}
              </div>
              <div className="row d-flex policies justify-content-center">
                <div className="d-flex aligner">
                  <div className="leftpos">Felhasználási feltételek</div>
                  <div className="rightpos">Adatvédelmi irányelvek</div>
                </div>
              </div>
          </div>
        </div>
        <div className="logright d-flex justify-content-start order-1 order-md-2">
          <div className="rightAligner w-100">
              <div className="rightTittleUp">Üdvözöllek</div>
              <div className="rightTittleDown">Újra</div>
          </div>

        </div>      
      </>
    )
  }

  forgotPasswordComponent = () => {
    return(
      <>
        <div className="logleft d-flex justify-content-center justify-content-md-end order-2 order-md-1">
          <div className="leftAligner">
              <div className="verticalTittle position-relative">Bejelentkezés</div>
         
              <div className="facebutton">Add meg a regisztráció során használt email címed!</div>
                
          
              <div className="email">
                <input placeholder="E-mail" onChange={(e)=> this.setState({email:e.target.value}) } value={this.state.email} />
              </div>
              <div className="password"></div>
              <div className="forgottenPassword">
                <div onClick={()=>this.typeChanger()} style={{cursor:"pointer"}}> { this.state.type === 0 ? "Elfelejtett jelszó" : "Bejelentkezés" }</div>
              </div>
              <div className="myLoginButton" onClick={()=>this.loginBTN()} >
                  {this.buttonComponent()}
              </div>
              <div className="row d-flex policies justify-content-center">
                <div className="d-flex aligner">
                  <div className="leftpos">Felhasználási feltételek</div>
                  <div className="rightpos">Adatvédelmi irányelvek</div>
                </div>
              </div>
          </div>
        </div>
        <div className="logright d-flex justify-content-start order-1 order-md-2">
          <div className="rightAligner w-100">
              <div className="rightTittleUp">Üdvözöllek</div>
              <div className="rightTittleDown">Újra</div>
          </div>

        </div>      
      </>
    )
  }

  newPasswordComponent = () => {
    return(
      <>
        <div className="logleft d-flex justify-content-center justify-content-md-end order-2 order-md-1">
          <div className="leftAligner">
              <div className="verticalTittle position-relative">Bejelentkezés</div>
         
              <div className="facebutton">Add meg az új jelszavad!</div>
                
          
              <div className="password">
                <input placeholder="token" value={this.state.token} readOnly/>
              </div>

              <div className="password">
                <input placeholder="Új jelszó" type="password" onChange={(e)=> this.setState({password:e.target.value}) } value={this.state.password} />
              </div>
              
              <div className="password">
                <input placeholder="Ismételt új jelszó" type="password" onChange={(e)=> this.setState({password_again:e.target.value}) } value={this.state.password_again} />
              </div>
              
              <div className="myLoginButton" onClick={()=>this.loginBTN()} >
                  {this.buttonComponent()}
              </div>

              <div className="row d-flex policies justify-content-center">
                <div className="d-flex aligner">
                  <div className="leftpos">Felhasználási feltételek</div>
                  <div className="rightpos">Adatvédelmi irányelvek</div>
                </div>
              </div>

          </div>
        </div>
        <div className="logright d-flex justify-content-start order-1 order-md-2">
          <div className="rightAligner w-100">
              <div className="rightTittleUp">Üdvözöllek</div>
              <div className="rightTittleDown">Újra</div>
          </div>

        </div>      
      </>
    )
  }


  componentSwicher = () => {
    switch (this.state.type) {
      case 0:
        return this.loginComponent()
      case 1:
        return this.forgotPasswordComponent()
      case 2: 
        return this.newPasswordComponent()
      default:
        return this.loginComponent()
    }
  }

  render(){
    console.log(this.props)
    console.log(this.state)
    return (
      <div className="row d-flex logland ">

        <Modal show={this.state.modalShow} onHide={()=> this.setState({modalShow:false, modalText:"", modalTitle:""}) }>
          <Modal.Header>
              <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalText}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> this.setState({modalShow:false, modalText:"", modalTitle:""}) }>
                Bezár
            </Button>
          </Modal.Footer>
      </Modal>

      {this.componentSwicher()}

      </div>
    );

  }
}


/*======================
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState};
};

/*===================================================
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{
  setTokenAction, setUserAction
})(LogInPage);
