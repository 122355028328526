import React, {Component} from 'react';
import {request} from '../../../../Model/Request'
import {connect} from 'react-redux';
import {setTokenAction, setUserAction} from "../../../../Model/Action/";

import './PartnerSelector.css'


 class PartnerSelector extends Component  {

  state = {
    partners:[]
  }



  componentDidMount() {

      //itt majd betöltést kell indítani és annak eredményével hívni
     // this.convertLoadedPartnersAndSetState(loadedPartners);
     console.log(this.props);
      this.partnerRequest({url:`/messenger?selected_type=${this.props.userState.user.type}&selected_id=${this.props.userState.user.id}`, body:"", state:"partners" });

      
  }

  componentDidUpdate(prevProps, prevState){
  
  }

  //ezt adjuk át a cardnak és ha click van ebbe jön az id
  changeSelectedPartner = (partnerId)=>
  {

    //console.log("change",partnerId,this.state.partners);
  
    let isChange = false;
    let partners = this.state.partners.map
    (
      (ret)=>
      {
        if (ret.id===partnerId) 
        {
            if (ret.isActive!="1"){
              ret.isActive="1";

              //a propsban megadott fügvénynek átadjuk a partner objectumot telibe
              this.props.call(ret);
              isChange = true;
            }
            
        }else
        {
          ret.isActive="0";
        }
        return ret;
      }
    );
    
    if (isChange) 

    this.setState({
      partners: partners
    }
    ); 

  }

  //ha betöltődik a partnerek listája akkor ez berakja a plus változót és elhelyezi state-be
  convertLoadedPartnersAndSetState (fullpartners) 
  {

    
    var part = fullpartners.map
        (
          (ret)=>
          {
            ret.isActive = "0";
            //console.log(ret);
            return ret;
          }
        );
   
    this.setState({
        partners: part
      }, () => {
        this.changeSelectedPartner(this.state.partners[0].id);
               });


  }


  // REQUEST

    partnerRequest = async ({url,body,state}) => {
      try{

        let response = await request({url,body})

        if(typeof response.error !== "undefined"){
             // console.log(response.error)
        }else{
            console.log(response)

            /// HA BESZÉLGETÉST KEZDEMÉNYEZ EGY OLDALRÓL
            if(this.props.newpartner !== null){
           
              /// HA EZT EGY COMPANY-VAL TESZI
              if(this.props.newpartner.type.toLowerCase() === "company"){
                      /// KÉRJE LE A COMPANY-HOZ TARTOZÓ USER ADATAIT
                      let data_owner = await request({url:`/public/company/owner?id=${this.props.newpartner.id}`});
                      /// ha volt hiba a lekérdezésben, akkor térjen visdza
                      if(typeof data_owner.error !== "undefined") return
                      /// lekérdezett adatokól szedje ki az attributes objectet
                      const {attributes} = data_owner.data;

                      /// ha a beszélgetés előzményekben szerepel a user
                      if(Array.isArray(response.data) && response.data.map(item => item.id ).includes(data_owner.data.id) ){   
                              /// az objectum index-e a tömbben
                              const index_of_delete = response.data.findIndex(item => item.id === data_owner.data.id); 
                              /// objectum kimásolása a tömbből
                              const object_of_rerange = response.data.splice(index_of_delete, 1);
                              ///  a tömbből az objectum eltávolítása
                              response.data =  response.data.filter((item, index) => index !==  index_of_delete)
                              /// az objectum a tömb első helyére való visszaillesztése
                              response.data = [...object_of_rerange, ...response.data]
          
                      }else{  
                            /// ha nem tömb érkezett vissza, akkor váltson típust és legyen egy üres tömb
                            if(!Array.isArray(response.data)){
                              response.data = []
                            }
                            /// ha a beszélgetés előzményekben  NEM  szerepel a user  
                            response.data = [{id: data_owner.data.id, isActive: "1", type: "User", attributes},...response.data];
                          
                      }


                  

              }

               /// HA EZT EGY USER-RAL TESZI
              if(this.props.newpartner.type.toLowerCase() === "user"){
                    /// KÉRJE LE A USER ADATAIT
                    let data_owner = await request({url:`/public/user?id=${this.props.newpartner.id}`}); 
                    /// ha volt hiba a lekérdezésben, akkor térjen visdza
                    if(typeof data_owner.error !== "undefined") return
                    /// lekérdezett adatokól szedje ki az attributes objectet
                    const {attributes} = data_owner.data;
                    /// ha a beszélgetés előzményekben szerepel a user
                    if(Array.isArray(response.data) && response.data.map(item => item.id ).includes(data_owner.data.id) ){
                      /// az objectum index-e a tömbben
                      const index_of_delete = response.data.findIndex(item => item.id === data_owner.data.id);
                      /// objectum kimásolása a tömbből
                      const object_of_rerange = response.data.splice(index_of_delete, 1);
                      console.log(object_of_rerange)
                      ///  a tömbből az objectum eltávolítása
                      response.data =  response.data.filter((item, index) => index !==  index_of_delete)
                      /// az objectum a tömb első helyére való visszaillesztése
                      response.data = [...object_of_rerange, ...response.data]
                    }else{
                      /// ha nem tömb érkezett vissza, akkor váltson típust és legyen egy üres tömb
                      if(!Array.isArray(response.data)){
                        response.data = []
                      }
                      /// ha a beszélgetés előzményekben  NEM  szerepel a user 
                      response.data = [{id: this.props.newpartner.id, isActive: "1", type: "User", attributes},...response.data];
                    }
                

              }


            }

            let st = response.data.map((ret, index)=> {

              ret.isActive = index === 0 ? "1" : "0";
              //console.log(ret);
              return ret;
            });

    

            await this.setState({ [state]: st })
                  this.props.call(st[0])
        }

      }catch(e){
       //  console.log(e)
      }
    }


  render(){

   
    
    return (
       
        <div className="partners" style={ typeof this.props.expert !== "undefined" ? {background:"gainsboro"}:null }>
        
           {
             this.state.partners.map
            (
              (res,index)=>
              {
                  return  <PartnerCard key={index} click={this.changeSelectedPartner} isActive={res.isActive} partnerId={res.id} partnerImg={res.attributes.avatar.src} partnerName={res.attributes.name}></PartnerCard>
              }
            )
          }
        </div>


      
    );

  }
}
/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState};
};

export default connect(mapStateToProps,{
  setTokenAction,
  setUserAction,
 })(PartnerSelector);

class PartnerCard extends Component  {

  
  render(){
    //console.log(this.props)
    return(
      <div onClick={()=>this.props.click(this.props.partnerId)} className="partnerCard d-flex justify-content-center align-items-center" style={{ backgroundColor: this.props.isActive==="1" ? 'white':null,marginLeft: this.props.isActive==="1" ? '12px':null,position: this.props.isActive==="1" ? 'relative':'inherit' }}>
      
      <div className="cardContainer text-center" style={{marginLeft: this.props.isActive==="1" ? '-24px':null}} >
          <img className="partnerimg" src={this.props.partnerImg}/>
          <div className="partnerName" style={{color: this.props.isActive==="1" ? "#4F4F4F":"#FFFFFF", }}>{this.props.partnerName}</div>
      </div>
    </div>

    );
  }
} 

