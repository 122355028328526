import React, {Component} from 'react';
import {request} from "../../../Model/Request";
import {Link, withRouter} from "react-router-dom";

//styles
import './WorkLists.css';





 class WorkLists extends Component  {

    state = {
        outerWork:[],
        innerWork:[],
        outerWork_limit:true,
        innerWork_limit:true,
    }
    

    componentDidMount(){
        this.getData("indoor","innerWork")
        this.getData("outdoor","outerWork")
    }

    getData = async (type, stateName) => {
        const response = await  request({url:`/profession?type=${type}&limit=10000&per_page=10000`})
        if(typeof response.error === "undefined"){
            console.log(response.data.profession.items)
            this.setState({[stateName]:response.data.profession.items.map(item =>  {  
                if(item.name.includes("/")){
                    return {...item, name: item.name.split("/").join(" / ")}
                }else{
                    return {...item}
                }

            }  )})
        }

    }
    

  render(){


    console.log(this.props)
    console.log(this.state)
    return (
       <> 
      <div>
      <div className="row outerWorkPadding">
      <div className="col-12">
      <div className="outerWorkSubTittle"> legnépszerűbb</div>
          <div className="outerWorkTittle"> {this.props.viewType =='outer' ? 'Kültéri munkálatok' : 'Beltéri munkálatok'}</div>
      </div>
  </div>
  <div className="outerWorkDisplay d-flex w-100 justify-content-center">
      <div className="endDisplay d-flex justify-content-end">
          <div className="leftOuterWork">
           {/*  {this.firstColumn} */}
            { this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].length > 0
              ?  this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].map( (ret,index)=> { if (index % 3 == 0)  return  (this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] && index < 6) || this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"]  === false ? <div onClick={ ()=> this.props.history.push(`/search?per_page=10&page=1&mainid=${ret.id}&mainname=${ret.name}`)} style={{...styles.box}} key={ret.id + "_" + index}>{ret.name}</div> : null; } )
              :  null
             }
          </div>

      </div>
      <div className="centerDisplay d-flex justify-content-center">
          <div className="centerOuterWork">
          { this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].length > 0
              ?  this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].map( (ret,index)=> { if (index % 3 == 1)  return  (this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] && index < 6) || this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"]  === false ? <div onClick={ ()=> this.props.history.push(`/search?per_page=10&page=1&mainid=${ret.id}&mainname=${ret.name}`)} style={{...styles.box}} key={ret.id + "_" + index}>{ret.name}</div> : null; } )
              :  null
             }
          </div>

      </div>
      <div className="startDisplay d-flex justify-start">
          <div className="rightOuterWork">
            { 
              this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].length > 0
              ?  this.state[this.props.viewType === 'outer' ? "outerWork" : "innerWork"].map( (ret,index)=> { if (index % 3 == 2)  return  (this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] && index < 6) || this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"]  === false ? <div onClick={ ()=> this.props.history.push(`/search?per_page=10&page=1&mainid=${ret.id}&mainname=${ret.name}`)} style={{...styles.box}} key={ret.id + "_" + index}>{ret.name}</div> : null; } )
              :  null
             }
          </div>
      </div>                                
  </div> 
  </div> 


    <div style={{margin:20, display:"flex", justifyContent:"center"}}> 

        <div style={{...styles.box_2, width:305, marginLeft:50}} onClick={()=> this.setState({ [this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] : !this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] })}> {this.state[this.props.viewType === 'outer' ? "outerWork_limit" : "innerWork_limit"] ? "TOVÁBB A TELJES LISTÁHOZ" : "KEVESEBB"} </div>

   </div>
       
       

</>
        
    );

  }
}

const styles = {
    box : {
        margin:17,
        minWidth:305,
        height:70,
        background: "#FFFFFF",
        boxShadow: "2px 5.38332px 6.72915px rgba(0, 0, 0, 0.2), 0px 4.03749px 20.1874px rgba(0, 0, 0, 0.12), 0px 10.7666px 20.1874px rgba(0, 0, 0, 0.14)",
        borderRadius: 10,
        fontFamily: "Monda",        
        fontWeight: "bold",
        fontSize:15,
        letterSpacing:4,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        pading:5,
        cursor:"pointer"
    },
    box_2 : {
        margin:17,
        minWidth:305,
        height:70,

        background: "transparent",        

        borderRadius: 10,
        fontFamily: "Monda",        
        fontWeight: "bold",
        fontSize:15,
        letterSpacing:2,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        pading:55,
        cursor:"pointer",
        border: "2px solid #2686B0"
    }
}




/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default withRouter(WorkLists);

