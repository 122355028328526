import  { Component } from 'react';
import { withRouter} from "react-router-dom";
import { connect } from "react-redux";


class ScrollToTop extends Component {

    componentDidMount(){
      
      /// if the user has an incomplete registration
      if(typeof this.props.userState.user.incompleteRegistration !== "undefined"){
        this.props.history.push("/registration/professional")
      }

      

    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
      
    }
  
    

    render() {
      return this.props.children
    }
  }
  
  /*====================== 
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {userState, systemState} = state;
  return {userState, systemState};
};

/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default  withRouter(connect(mapStateToProps,{})(ScrollToTop));

