import React, {Component,} from 'react';
import {connect} from 'react-redux';
import {request} from '../../../Model/Request';

import {Modal, Button} from "react-bootstrap"

import DatePicker from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import "react-datepicker/dist/react-datepicker.css";
import "../../../Model/date_picker/css/react_datepicker_custome.css";


/* CALENDAR */
import {endOfMonth, addMounthToDate} from "../../../Model/calendar_functions/calendar_finctions"
import locale from 'date-fns/locale/hu';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import "../../../Model/Calendar/CalendarChange.css";

import addBTN from "./Assets/add.png";


const honapok = [
  "január",
  "február",
  "március",
  "április",
  "május",
  "június",
  "július",
  "augusztus",
  "szeptember",
  "október",
  "november",
  "december",]

 class Calendars extends Component  {

  state= {

        isModalShow:false,
        months:2,
       
        addButton_1_data:{start:null, end:null},
        addButtonType:null,
       

        from_date:new Date(new Date().setDate(new Date().getDate()+1)),
        until_date:addMounthToDate(1, endOfMonth(new Date())),
     


    

         days : [
        /*    {
            startDate: new Date(),
            endDate: new Date(),
            key: 'own__0',
            showDateDisplay:true
        
          },
          {
            startDate: addMounthToDate(2, endOfMonth(new Date())),
            endDate: addMounthToDate(3, endOfMonth(new Date())),
            key: 'help_1',
            showDateDisplay:true
        
          }, */
         ],
         focused:[0,0],
         trash:[],
         originalDates:[], // 0
         modified:[],
  
    
  }

  componentDidMount(){
     this.props.systemState.dimension.width <= 991 
        ?  this.setState({  months:1 }) 
        :  this.setState({  months:2 })

      //  this.setState({})  from_date:this.dateParser_hyphen(addMounthToDate(this.startNumber)), until_date: this.dateParser_hyphen(endOfMonth(addMounthToDate(this.startNumber + 1))) 

       this.getDate()

  }

  componentDidUpdate(prevProps, prevState){
    if( this.props.systemState.dimension.width !== prevProps.systemState.dimension.width ){
      this.props.systemState.dimension.width <= 991 ? this.setState({months:1}) : this.setState({months:2})
    }
    if(prevState.from_date !== this.state.from_date){
      this.getDate()
    }

  
 
  }

  saveDate = async () => {

    /* DELETE */
    for (let index = 0; index < this.state.trash.length; index++) {
      const item = this.state.trash[index];

      const data = {
        from_date : item.from_date,
        until_date: item.until_date,
        status    : "free"
      }

      const  response = await request({ url:"/user/profile/calendar", data, method:"PATCH" });
      console.log(response)

    }

    /* ADD */
    for (let index = 0; index < this.state.days.length; index++) {
      const item = this.state.days[index];

      if(item.type === "new"){

        const data = {
          from_date : this.dateParser_hyphen(item.startDate),
          until_date: this.dateParser_hyphen(item.endDate),
          status    : item.key.includes("own") ? "busy" : "canHelp"
        }

        const response = await request({ url:"/user/profile/calendar", data, method:"POST" });
        console.log(response)

      }
      
    } 

    /* MODIFY */
    for (let index = 0; index < this.state.modified.length; index++) {

      const item = this.state.modified[index].split("_");

      const id = parseInt(item[0]);
      const event_id = parseInt(item[0]);
    

      /// find original date
      const delete_date = this.state.originalDates.filter( item => item.id === id ); // 2
      
      if(delete_date.length > 0){

              /// delete

              const data = {
                from_date  : this.dateParser_hyphen(delete_date[0].startDate),
                until_date : this.dateParser_hyphen(delete_date[0].endDate),
                status     : "free"
              }
        
              
              const delete_response = await request({ url:"/user/profile/calendar", data, method:"PATCH" });

                if(typeof delete_response.error === "undefined"){

                    const add_date = this.state.days.filter( item => item.id === id );

                    if(add_date.length > 0){


                      const data_add = {
                        from_date : this.dateParser_hyphen(add_date[0].startDate),
                        until_date: this.dateParser_hyphen(add_date[0].endDate),
                        status    : add_date[0].key.includes("own") ? "busy" : "canHelp"
                      }
              
                      const response_add = await request({ url:"/user/profile/calendar", data:data_add, method:"POST" });
                      
                      

                    }


                }


      }
      

      /// add as new date 
      

    }

  

  }

  saveBTN = () => {
    return(
      <div 
        style={{display:"flex", justifyContent:"space-evenly", flexDirection: this.props.systemState.dimension.width <= 550 ?"column":"row", alignItems:"center", marginTop:50}}
        onClick={()=> this.saveDate()}
        >
          <div onClick={()=>console.log()} style={{cursor:"pointer",background:"#217BA7", height:65, width:300, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:5,  marginTop:40}}>
            <div style={{color:"white", fontFamily:"Maven Pro", fontSize:20, fontWeight:"bold"}}> MENTÉS </div>
          </div>
      </div>
    )
  }



  getDate = async () => {

    try{

        const id = this.props?.userState?.user?.id ?? null;
        if(id === null || this.state.from_date === null || this.state.until_date === null ) return

        console.log(`user_id=${id}&from_date=${this.dateParser_hyphen(this.state.from_date)}&until_date=${this.dateParser_hyphen(this.state.until_date)}`)
        const url = "/user/profile/calendar?" 
        const query = encodeURI(`user_id=${id}&from_date=${this.dateParser_hyphen(this.state.from_date)}&until_date=${this.dateParser_hyphen(this.state.until_date)}`)
        
       
        //const url ="/user/profile/calendar?user_id=27&from_date=2020-04-08&until_date=2020-05-11";

        const response = await request({ url: url+query, method:"GET" })

        console.log(response)

        if(typeof response !== "undefined" && typeof response.error === "undefined" ){

          const safe_data = response?.data ?? [];

          if(safe_data.length === 0) return

          

          const days = safe_data.filter(item => item.attributes.event_type !== "Tool" &&  item.attributes.event_type !== null).map( (item, index)=> {
            return { 
                type: "old",
                id:item.id,
                event_id:item.attributes.event_id,
                startDate:new Date(item.attributes.from_date),
                endDate:new Date(item.attributes.until_date),
                key: item.attributes.status === "busy" ? "own__"+ item.id + "_" + item.attributes.event_id : "help_" + item.id + "_" + item.attributes.event_id,
            }
         });

          /// days.filter(x => !this.state.days.includes(x)); 
          const oldDataArray = this.state.days.map(item=>item.id).filter(u => typeof u !== "undefined");
          const originalDatesOldArray = this.state.originalDates.map(item=>item.id).filter(u => typeof u !== "undefined") // 3 4 
       

          this.setState({
            days:[...this.state.days, ...days.filter(x => !oldDataArray.includes(x.id)) ],
            originalDates:[...this.state.originalDates,  ...days.filter(x => !originalDatesOldArray.includes(x.id))] // 5 6  7
          }) 

        }


    }catch(e){
      console.log(e)
    }
    

  }


  dateParser = (date) => `${date.getFullYear()} ${ honapok[date.getMonth()]} ${date.getDate()}`

  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`

  addButton =(text)=> {
    return(
      <div style={{padding:10, display:"flex", alignItems:'center', flexDirection:'column', cursor: "pointer"}}>
        <div style={{fontSize:13, fontFamily:"MONDA"}}>{text}</div>
        <div style={{fontSize:10, fontFamily:"MONDA", marginBottom:10}}>HOZZÁADÁSA</div>
       <div style={{height:60, width:60, backgroundImage: `url(${addBTN})`, backgroundSize:"cover", objectFitt:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", }} ></div>
      </div>
    )
  }



  addDateToLocalData = () => {
    

    this.setState({
   
       days:  [...this.state.days, 
                {
                  type:"new", 
                  startDate: this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start, 
                  endDate: this.state.addButton_1_data.end  === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.end, 
                  key: this.state.addButtonType === 0 ? "help_"  + (this.state.days.length + 1) : "own__" + (this.state.days.length + 1) ,
        
                } 
              ], 
                focused:[this.state.days.length,0],
                isModalShow:false, 
                addButton_1_data:{start:null, end:null}, 
                addButtonType:null,
      }) 


  }

  addDateComponent = () => {
    return(
      <div style={{ display:"flex", flexDirection:"row" }}>
        <div style={{flex:1, display:"flex", alignItems:"center", flexDirection:"column"}} >
          <div stle={{...styles.p}}>dátum kezdete</div>
        <DatePicker 
          locale={hu} 
          minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
          selected={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.start = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
        <div style={{flex:1, display:"flex",alignItems:"center", flexDirection:"column"}} >
        <div stle={{...styles.p}}>dátum vége</div>
        <DatePicker 
          locale={hu} 
          minDate={ this.state.addButton_1_data.start === null ? new Date(new Date().setDate(new Date().getDate()+1)) : this.state.addButton_1_data.start }
          selected={  
            
            this.state.addButton_1_data.end === null && this.state.addButton_1_data.start === null
            ? new Date(new Date().setDate(new Date().getDate()+1)) 
            : this.state.addButton_1_data.start !== null  &&  this.state.addButton_1_data.end !== null &&  parseInt(new Date(this.state.addButton_1_data.start ).getTime()/1000) >  parseInt(new Date(this.state.addButton_1_data.end).getTime()/1000) 
               ?  this.state.addButton_1_data.start
               :  this.state.addButton_1_data.end === null
                  ? this.state.addButton_1_data.start
                  : this.state.addButton_1_data.end 
                    
            } 
          dateFormat="yyyy-MM-dd"
          style={{ ...styles.p}}
          onChange={ date => { 
            let addButton_1_data = {...this.state.addButton_1_data};
                addButton_1_data.end = date;
                this.setState({addButton_1_data})
           } } />  
        </div>
      </div>
    )
  }

  
  setDateState = (a) => this.setState({ from_date: a, until_date: addMounthToDate( 1, endOfMonth(a) ) })   
  


  render(){
    const window_width =  this.props.systemState.dimension.width;
    console.log("_________")
    console.log(this.state)
    console.log("_________")
    return (
          <>

          <Modal size="lg" centered show={this.state.isModalShow} onHide={() =>this.setState({isModalShow:false, addButton_1_data:{start:null, end:null}, addButtonType:null })} >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  ÚJ IDŐPONT HOZZÁADÁSA
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                { this.addDateComponent()}
              </Modal.Body>
              <Modal.Footer>
                <Button  variant="secondary" onClick={()=>this.setState({isModalShow:false, addButton_1_data:{start:null, end:null}, addButtonType:null})}>MÉGSEM</Button>
                <Button variant="primary"  onClick={()=>{ this.addDateToLocalData() }}>HOZZÁAD</Button>
              </Modal.Footer>
            </Modal>





            {/* NÉZET */}
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:30, marginBottom:30, flexDirection: window_width <= 769 ? "column":"row"}}>
              <div style={{ borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  >NÉZET</div>
              <div onClick={()=> this.setState({months:1}) } style={{ cursor:"pointer" ,border: `${this.state.months===1?1:0}px solid #1E466D`, borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  > 1 HÓNAP </div>
              { window_width <= 991 ? null : <div onClick={()=> this.setState({months:2}) } style={{ cursor:"pointer" ,border: `${this.state.months===2?1:0}px solid #1E466D`, borderRadius:5, paddingTop:10, paddingBottom:10, paddingLeft:40, paddingRight:40 }}  > 2 HÓNAP </div> }
            </div>
            
            <div style={{display:"flex"}}>

                <div style={{flex: this.state.months === 2 ? 0.5:2, background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)"}}></div>

                <div style={{flex:2}}>
                <DateRange
                  showDateDisplay={false}
                  showPreview={true}
                  showSelectionPreview={true}
                  minDate={new Date( new Date().setDate(new Date().getDate() + 1))}
                  shownDate = { this.state.from_date}
                  ///n this.setState({  from_date:a, until_date:addMounthToDate(1, endOfMonth(a)) }) 
                  onShownDateChange={ (date)=> this.setDateState(date) }
                  focusedRange={this.state.focused}
                  onRangeFocusChange={(focused)=>this.setState({focused})}
                  disabledDates={[]}
                  ranges={[ ...this.state.days  ]}
                  rangeColors={this.state.days.length > 0 ? [  ...this.state.days.map( (item, index) => item.key.includes("help") ? "rgb(61, 145, 255)" : "#4F4F4F" )] : ["#4F4F4F"] }
                  locale={locale}
                  months={ this.state.months }
                  direction={"horizontal"}
                  onChange={ (e) => {
        
                    
                        const uniqe = e[Object.keys(e)[0]].id + "_" + e[Object.keys(e)[0]].event_id;

                        if( !this.state.modified.includes(uniqe) && e[Object.keys(e)[0]].type === "old" ){

                          this.setState({ 
                            modified : [...this.state.modified, uniqe],
                            days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) 
                          })

                        } else {
                          this.setState({ 
                            days: this.state.days.map( item => Object.keys(e)[0] === item.key ? {...e[Object.keys(e)[0]]} : {...item} ) 
                          })
                          

                        }

                       
                      }
                  }
                />
              </div>

                <div style={{flex: this.state.months === 2 ? 0.5:2,  background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)"}}></div>

           </div>


           <div style={{background: "linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%)", transform:"rotate(180deg)"}} >
             <div style={{transform:"rotate(180deg)", height:100, marginRight:50, marginLeft:50}}>

               <div style={{marginBottom:20, width:"100%", height:1, backgroundColor:"white",}} />

                <div style={{display:"flex", justifyContent:"space-between"}}>

                    <div style={{flex:1}}></div>
                    <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(61, 145, 255)"}} />
                      <div style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10}} >MUNKÁT VÁLLAL</div>
                    </div>
                    <div style={{flex:1}}>
                      <div   style={{position:"absolute",height:40, width:40, borderRadius:20, background:"rgb(79, 79, 79)"}} />
                      <div  style={{ ...styles.p, color:"white", position:"absolute", marginTop:8, marginLeft:10, }} >ELFOGLALT</div>
                    </div>
                    <div style={{flex:1}}></div>

                </div>

               
              </div>

           </div>

          <div style={{display:"flex", justifyContent:"space-evenly", marginTop:30, marginBottom:30}}>


         

          <div 
              onClick={ 
                  () => { 
                      this.setState({
                          /*  days:  [...this.state.days, 
                                  {
                                    type:"new", 
                                    startDate: new Date(new Date().setDate(new Date().getDate()+1)), 
                                    endDate: new Date(new Date().setDate(new Date().getDate()+1)), 
                                    key: "help_" + (this.state.days.length + 1) } ], 
                                    focused:[this.state.days.length,0]  */
                                    /*  addButton_1_active: true  */
                                    isModalShow:true,
                                    addButtonType:0,
                                  }) 
                                    }} > 
            {/*  { this.state.addButton_1_active ?  this.addDateComponent() : this.addButton("KISEGÍTŐ MUNKÁT VÁLLAL") }  */}
      { this.addButton("MUNKÁT VÁLLAL") } 
          </div>

          <div onClick={ () => {

             this.setState({
             /*   days:  [...this.state.days, 
                  {type:"new", 
                  startDate: new Date(new Date().setDate(new Date().getDate()+1)), 
                  endDate: new Date(new Date().setDate(new Date().getDate()+1)),
                   key: "own__" + (this.state.days.length + 1) } ], focused:[this.state.days.length,0] */
                   isModalShow:true,
                   addButtonType:1,
              }) 

             }} >
            {this.addButton("ELFOGLALT")}
          </div>

        </div>

          {this.state.days.map( (item, index) => 
         
              <div key={index} style={{marginLeft:20, marginRight:20}}>
                 {console.log(item)}
              <div style={{width:'100%', height:1, backgroundColor:"#4F4F4F", marginBottom:10, marginTop:10}} />

                <div  style={{ cursor:"pointer", display:"flex", justifyContent: "center", flexDirection:window_width <= 769 ? "column":"row",  margin:10,  borderRadius: 5, padding:      this.state.focused[0] === index ? 10 : 2, opacity:      this.state.focused[0] === index ? 1 : 1,border:       `${this.state.focused[0] === index ? "3" : "0"}px ${this.state.focused[0] === index ? "#007bff" : "#4F4F4F"} solid`,background:  this.state.focused[0] === index ? "rgba(38, 134, 176, 0.2)" : "white" }}>

                    <div 
                        onClick={()=> this.setState({focused:[index,0]}) }
                        style={{flex:1, 
                          display:"flex",
                          alignItems:"center",
                          justifyContent: "center",
                          textAlign:"center",
                          borderRadius:5,
                          color:"white", 
                          padding:5,
                          background:item.key.includes("help") ? "rgb(61, 145, 255)" : "#4F4F4F", 
                          }}>{item.key.includes("help") ?"MUNKÁT VÁLLAL" :"ELFOGLALT"}</div>

                    <div  
                      onClick={()=> {
                        this.setState({focused:[index,0]});
                      
                      }
                    
                    } 
                      style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.startDate) */}
                      <DatePicker 
                        locale={hu} 
                        minDate={ new Date(new Date().setDate(new Date().getDate()+1)) }
                        selected={ item.startDate } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 


                          const uniqe = item.id + "_" + item.event_id;
                          let modified_item = {...item};
                                modified_item.startDate = date;
                                modified_item.endDate =  parseInt(new Date(item.startDate).getTime()/1000) >  parseInt(new Date(item.endDate).getTime()/1000) 
                                ?  item.startDate
                                :  item.endDate

                            if( !this.state.modified.includes(uniqe) && item.type === "old" ){

                              this.setState({  
                                modified : [...this.state.modified, uniqe],
                                days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) 
                               })

                            } else {

                               /*  let days = [...this.state.days]
                                    days[index] = modified_item */
                                    this.setState({ days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} )  })
  
                              }
                             

                          /*     this.setState({ 
                                days: this.state.days.map( elem => {
                                  if( modified_item.key === elem.key){
                                    return {...modified_item}
                                  }else{
                                    return {...elem}
                                  }
                                })
                              }); */
                                //  modified_item.key === elem.key ? {...modified_item} : {...elem} ) }) 

                        } } />
                      </div>
                    <div  
                      onClick={()=> this.setState({focused:[index,0]}) } 
                      style={{flex:1, justifyContent:"center", display:"flex", textAlign:"center"}}>{/* this.dateParser(item.endDate) */}
                      <DatePicker 
                        locale={hu} 
                        minDate={ item.startDate  }
                        selected={ 
                         
                            parseInt(new Date(item.startDate).getTime()/1000) > parseInt(new Date(item.endDate).getTime()/1000) 
                              ?  item.startDate
                              :  item.endDate  
                       
                        } 
                        dateFormat="yyyy-MM-dd"
                        style={{ ...styles.p}}
                        onChange={ date => { 

                         const uniqe = item.id + "_" + item.event_id;

                         let modified_item = {...item};
                             modified_item.endDate = date;
                        
                             if( !this.state.modified.includes(uniqe) && item.type === "old" ){

                                  this.setState({  
                                      modified : [...this.state.modified, uniqe],  
                                      days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} ) 
                                  }) 

                            } else {

                             /*  let days = [...this.state.days]
                                  days[index] = modified_item */
                                  this.setState({ 
                                    days: this.state.days.map( elem => modified_item.key === elem.key ? {...modified_item} : {...elem} )  
                                  })

                            }
                       
                           

                             

                             

                           /*      this.setState({ 
                                  days: this.state.days.map( elem => {
                                    if( modified_item.key === elem.key){                                 
                                      return {...modified_item}
                                    }else{
                                      return {...elem}
                                    }
                                  })
                                }); */ 

                                

                     
                                
                            
                           


                        } } />
                      </div>
          
                    <div 
                      onClick={ async ()=> {
                               

                              const del_object = this.state.days.filter((f_item, f_index)=> f_index === index)[0];

                              if(typeof del_object.startDate === "undefined" || typeof del_object.endDate ==="undefined" ) return

                              if(del_object.type === "old"){
                                const data = {
                                  from_date : this.dateParser_hyphen(del_object.startDate),
                                  until_date: this.dateParser_hyphen(del_object.endDate),
                                  status    : "free"
                                }

                                this.setState({ trash : [...this.state.trash, data] })
                              }

                             

                              this.setState({
                                      focused: [ index === 0 ? 0 : index-1 ,0], 
                                      days:    this.state.days.filter((f_item, f_index)=> f_index !== index)
                               })

                           }
                      }
                      style={{flex:1, 
                  
                        alignItems:"center",
                        justifyContent:"center",
                        display:"flex", 
                        background:"#E30606", 
                        color:"white",
                        borderRadius:5}}>TÖRLÉS</div>

                </div> 
            </div>
          )}

            {this.saveBTN()}

        </>

        
    );

  }
 }

 const styles = {
  p : { 
    fontFamily:"Maven Pro", 
    letterSpacing:2
}
 }
/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(Calendars);

