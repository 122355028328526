import React, {Component} from 'react';

/* ROUTER */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  
} from "react-router-dom";

import './Ratings.css'
import {request}   from "../../../Model/Request";
import parse from 'html-react-parser';
import default_image from "../../../Assets/mester_default.png";


const RatingsData = [
  {
      img:'https://picsum.photos/id/11/373/293',
      name:'Kovács László',
      clarity:null,
      precision:null,
      accuracy:null,
      text_rating: null,
      rating_id:'25451',
      rated_id:'32',
      rated:false,
      id: '1'
  },
  {
    img:'https://picsum.photos/id/211/373/293',
    name:'Nagy Béla',
    clarity:'3.5',
    precision:'4',
    accuracy:'5',
    text_rating: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
    rating_id:'25451',
    rated_id:'32',
    rated:true,
    id: '2'
  },
  {
    img:'https://picsum.photos/id/212/373/293',
    name:'Nagy Béla',
    clarity:'2.5',
    precision:'4',
    accuracy:'5',
    text_rating: 'quis id diam mi mauris hac bibendum rhoncus elit turpis elit nibh eu sed at auctor facilisi consectetur at nisi lorem egestas ac non sagittis integer convallis egestas eget eu gravida',
    rating_id:'25451',
    rated_id:'32',
    rated:true,
    id: '3'
  },
]


 class Ratings extends Component  {


  state = {

    ratingsData:[],

  }
  


  componentDidMount() {
   
    this.get_ratings();
  }  


  get_ratings = async () => {

    try{

      /* REQUEST */
      const response = await request({url:`/rating/list`})
      
      /* GUARD FUNC */
      console.log(response);
      if(typeof response.error !== "undefined" || typeof response.data === "undefined" || typeof response.data.items === "undefined" || response.data.items.length === 0) return

      
      this.setState({ratingsData:response.data.items.map( item => { 
        return {  
          img: item?.offer?.image ?? default_image, 
          name: item?.professional?.name ?? "",
          clarity: item?.rating?.types?.clean ?? null,
          precision: item?.rating?.types?.precise ?? null,
          accuracy: item?.rating?.types?.exact ?? null,         
          text_rating: item?.rating?.textual ?? null,
          id: item?.offer?.id,
          rated: item?.rating?.types.length != 0 ? true:false
              } 
        })});
    }catch(e){
      console.log(e)
    }

    

  }



  createRateing = (e)=>
  {
    let ratingsData = [...this.state.ratingsData].map(
      (rating)=>
      {
        if (rating.id==e.id)
        {
          rating.clarity = e.clarity;
          rating.precision = e.precision;
          rating.accuracy = e.accuracy;
          rating.text_rating = e.text_rating;
        }
        return rating;
      }
    );
    this.setState({ratingsData:[...ratingsData]});
    
    console.log(this.state.ratingsData);

  }

  storeRating = async (id)=>
  {

    console.log(id,this.state.ratingsData);
    let ratingData = [...this.state.ratingsData].filter(
      (rating)=>
      {
        if (rating.id==id)
        {
          return rating;
        }
        
      }
    )[0];
    console.log(ratingData);
    console.log(ratingData.clarity);
    console.log(ratingData.precision);
    console.log(ratingData.accuracy);
    console.log(ratingData.text_rating);

    if ((ratingData.clarity!=null && ratingData.clarity!=0) && (ratingData.precision!=null && ratingData.precision!=0) && (ratingData.accuracy!=null && ratingData.accuracy!=0) && (ratingData.text_rating!=null && ratingData.text_rating!=""))
    {
      //ide kell egy tényleges mentés

      let servertyperating = {};
      servertyperating.rate_type = "offer";
      servertyperating.rate_id = ratingData.id;
      servertyperating.types = {
        clean: ratingData.clarity,
        exact: ratingData.accuracy,
        precise:ratingData.precision
      };
      servertyperating.rating_textual = ratingData.text_rating;

      try{

        /* REQUEST */
        const response = await request({url:`/rating`,method:'POST',data:servertyperating})
        if (response.message=="Rating saved!")
        {
          this.setToRated(ratingData.id);
        }
      
      }catch(e){
        console.log(e)
      }

      
    }else

    {
        console.log("NINCS MINDEN ADAT KITÖLTVE!!!")
    }
    
  }

  setToRated = (id)=>
  {
    let ratingsData = [...this.state.ratingsData].map(
      (rating)=>
      {
        if (rating.id==id)
        {

          rating.rated = true;
        }
        return rating;
      }
    );
    this.setState({ratingsData:[...ratingsData]});
    
    console.log(this.state.ratingsData);
  }

  render(){
    
    let ratingsList;
    if (this.state.ratingsData.length!=0)
    {
      ratingsList =  this.state.ratingsData.map(

        (ret,index)=> 
        {
            return (
              <RatingCard key={index} onClick={this.createRateing} storeRating={this.storeRating} img={ret.img} name={ret.name} clarity={ret.clarity} precision={ret.precision} accuracy={ret.accuracy} text_rating={ret.text_rating} rated={ret.rated} id={ret.id}/>
            );
        }

      );

    }else
    {
      ratingsList = <div className="errorTxt">Jelenleg nincs megtekinthető értékelés!</div>
    }
    
    return (
       <div className="innerContainer-rating d-flex justify-content-center">
          <div className="innerAligner d-flex flex-wrap justify-content-start">
              {ratingsList}
          </div>
          
      </div>
    );

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

export default Ratings;


class RatingCard extends Component  {

  clarity=0;
  precision=0;
  accuracy=0;
  text_rating="";

  setClarity = (e)=>
  {
    console.log("clarity",e);
    this.clarity = e;
    this.pushAllRating();
  }

  setPrecision = (e)=>
  {
    console.log("precision",e);
    this.precision = e;
    this.pushAllRating();
  }  

  setAccuracy = (e)=>
  {
    console.log("accuracy",e);
    this.accuracy = e;
    this.pushAllRating();
  }    

  setTextRating = (e)=>
  {
    this.text_rating = e.target.value;
    this.pushAllRating();
    console.log("szovrat",e.target.value);
  }


  pushAllRating ()
  {
    this.props.onClick({clarity:this.clarity,precision:this.precision,accuracy:this.accuracy,text_rating:this.text_rating,id:this.props.id})
  }

  
  
  render(){
    //console.log(this.props)
    return(
      <div className="card-rating d-flex w-100">
        
        <div className="w-100 leftholder">
          <div className="names">{this.props.name}</div>
          <div className="ratedelement d-flex"><div className="tittlepadding">tisztaság</div><RatingStars key='1' rated={this.props.rated} rating={this.props.clarity} onClick={this.setClarity}></RatingStars></div>
          <div className="ratedelement d-flex"><div className="tittlepadding">precizitás</div><RatingStars key='2' rated={this.props.rated} rating={this.props.precision} onClick={this.setPrecision}></RatingStars></div>
          <div className="ratedelement d-flex"><div className="tittlepadding">pontosság</div><RatingStars key='3' rated={this.props.rated} rating={this.props.accuracy} onClick={this.setAccuracy}></RatingStars></div>
          <div className="descriptionContainer d-flex justify-content-center">
            {this.props.rated ? 
                <div className="description d-flex align-items-center w-100">{this.props.text_rating}</div> 
                :
                <div className="description d-flex align-items-center w-100">
                  <textarea className="btextarea w-100" placeholder="Értékelés" onChange={(e)=>this.setTextRating(e)}></textarea>
                </div>
            }
            
          </div> 

        </div>
        <div className="rightholder">
          <img className="images position-relative" src={this.props.img} alt=""/>
          <div className="buttonContainer position-relative">
            <div className="decor"></div>
            {this.props.rated ? 
              <button className="cardbutton-rated">Értékelted</button> 
              : 
              <button onClick={()=>this.props.storeRating(this.props.id)} className="cardbutton">Értékelés elküldése</button>}
            
          </div>          
        </div>
        
        
      
   </div>

    );
  }
}



class RatingStars extends Component  {

  
  setValue=(e)=>
  {
    console.log("Ezt az értékelést nyomta:",e);
    this.props.onClick(e);
  }

  render(){

    let stars=[];
    for (let i=1;i<6;i++)
    {
      if (i<= Math.round(this.props.rating))
      {
        if (this.props.rated)
        {
          stars.push( <img key={i} className="stars" src={require('../../../Assets/rating-star-full.svg')} alt=""/> );
        }else
        {
            stars.push( <img key={i} onClick={()=>this.setValue(i)} className="stars-active" src={require('../../../Assets/rating-star-full.svg')} alt=""/> );
        }
    
      }else
      {
        if (this.props.rated)
        {
          stars.push( <img key={i} className="stars" src={require('../../../Assets/rating-star-empty.svg')} alt=""/> );
        }else
        {
          stars.push( <img key={i} onClick={()=>this.setValue(i)} className="stars-active" src={require('../../../Assets/rating-star-empty.svg')} alt=""/> );
        }
        
      }
    }
    //console.log(this.props)
    return(
      <div className="rating-stars d-flex">
        {stars}
      </div>

    );
  }
}