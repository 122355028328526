import React, {Component} from 'react';
/* REDUX CONNECT */
import {connect} from 'react-redux';
/* REDUX ACTIONS */
import {setTokenAction, setUserAction} from "../../../Model/Action/index";
/* IMG LOADER */
import ImgUpload from "../../../View/ImageUpload";
/* HTTP REQUEST */
import {request} from "../../../Model/Request";

import {withRouter} from "react-router-dom";

import {mester_default_base64} from '../../../Assets/mester_default_base64';

import pipe from "./Assets/pipe.png"

//styles
import './registerUser.css';




    const validationArray = [
        {name:"first_name",                 minLenght: 2, maxLenght: 50,   },
        {name:"last_name",                  minLenght: 2, maxLenght: 50,   },
        {name:"email",                      minLenght: 5, maxLenght: 100,  },
        {name:"password",                   minLenght: 8, maxLenght: 50,   },
        {name:"password_confirmation",      minLenght: 8, maxLenght: 50,   },
        {name:"city",                       minLenght: 2, maxLenght: 100,  },
        {name:"street",                     minLenght: 2, maxLenght: 100,  },
        {name:"phone_number",               minLenght: 11,maxLenght: 20,   },
        {name:"zip",                        minLenght: 4, maxLenght: 4,    },
    ];

    const downSideArray = [
        {htmlFor:"fgemail",     htmlForText:"E-mail cím",          type:"email",    id:"email",                 name:"fgemail",     subText:""},
        {htmlFor:"fgpass",      htmlForText:"Jelszó",              type:"password", id:"password",              name:"fgpass",      subText:"minimum 8 karakter, tartalmazzon 1 számot"},
        {htmlFor:"fgpasscheck", htmlForText:"Jelszó megerősítése", type:"password", id:"password_confirmation", name:"fgpasscheck", subText:""},
    ]

    const isEmail = (email = null) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    const isPassword = (password) => {
       /// const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
       const regex = /^(?=.*\d).{8,}$/
        return regex.test(password);
    }


    class RegisterUser extends Component  {

    state = {
        first_name: "",
        last_name:  "",
        type:       "basic",
        email:      "",
        password:   "",
        password_confirmation : "",
        zip: "",
        city: "",
        street: "",
        phone_number: "", 

        avatar :"",

        ///LOADING
        loading:false,

        /// VALIDATION
        avatar_valid:false,
        first_name_valid:false,
        last_name_valid:false,
        email_valid:false,
        password_valid:false,
        password_confirmation_valid:false,
        paswordsIdentical_valid:false,
        zip_valid:false,
        city_valid:false,
        street_valid:false,
        phone_number_valid:false, 
        terms_of_use: false, 

        type_pw:"password",
        type_pw_again:"password",


    }


    /* ===============
    LIFE CYCLE EVENTS
    =============== */

    componentDidMount(){ 
        
        

       // this.props.history.push({pathname: '/registration/professional', state: { level: 0, from:"userRegistration" } })
       const type = typeof this.props.registerType === "undefined" ? "basic" : this.props.registerType;
       const from = typeof this.props.location?.state?.from === "undefined" ? "selector" : this.props.location.state.from;
       const data = typeof this.props.location?.state?.data === "undefined" ? null : this.props.location.state.data 
        
        this.setState({type, from, data})

       
    }

    componentDidUpdate(prevProps, prevState){

        /// loading disable all inputs 
        if(this.state.loading !== prevState.loading){

            if(this.state.loading){
                [...document.querySelectorAll("input")].map(i => i.disabled = true)
            }else{
                [...document.querySelectorAll("input")].map(i => i.disabled = false)
            }

        }
        
        /// compare the two passwords
        if(this.state.password !== prevState.password || this.state.password_confirmation !== prevState.password_confirmation ){
            if(this.state.password === this.state.password_confirmation ){
                this.setState({paswordsIdentical_valid:true})
            }else{
                this.setState({paswordsIdentical_valid:false})
            }
        }

        /// form validation
        validationArray.map( i =>{

            if(this.state[i.name] !== prevState[i.name] ){

                /// if its zip or phonenumber the imputed value must be a number
                if( (i.name === "zip" || i.name === "phone_number" )  && isNaN(this.state[i.name])){
                    this.setState({[i.name]: prevState[i.name] })
                }


                if(this.state[i.name].length === 0){

                    document.querySelector(`input#${i.name}`).style.background = "rgba(227,6,6,0.15)";
                    this.setState({[`${i.name}_valid`]: false})


                } else if(this.state[i.name].length < i.minLenght){

                    document.querySelector(`input#${i.name}`).style.background = "rgba(253,217,166,0.26)";
                    this.setState({[`${i.name}_valid`]: false})


                } else if(this.state[i.name].length >= i.minLenght && this.state[i.name].length <= i.maxLenght){
                      
                        /// email case
                        if(i.name === "email") {
                            if(isEmail(this.state[i.name])){
                                this.setState({[`${i.name}_valid`]: true})
                                document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                            }else{
                                return
                            }    
                        }
                        
                        /// password OR password_confirmation
                        if(i.name === "password" || i.name === "password_confirmation") {

                                if(isPassword(this.state[i.name])){
                                    this.setState({[`${i.name}_valid`]: true})
                                    document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                                }else{
                                    return
                                }
                        }

                        /// default
                        document.querySelector(`input#${i.name}`).style.background = "rgba(38,134,176, 0.26)";
                        this.setState({[`${i.name}_valid`]: true})
                  
                    
                } else if(this.state[i.name].length >= i.maxLenght){

                    this.setState({[i.name]: prevState[i.name] })
                 
                }
                

              
            };

        })


    }

    /* ===============
     METHODS
    ================*/

    imageToState = ({base64data, index}) => {
        this.setState({avatar:base64data, avatar_valid:true})
    };

    onChangeHandler = (e) => {
        const {id} = e.target;
        const {value} = e.target;

        if(id === "terms_of_use") {
            this.setState({[id]:!this.state.terms_of_use}) 
        }else{
            this.setState({[id]:value}) 
        }
        
    }

    registerButton = async () => {

     try {
       this.setState({loading:true});

         /// add empty token object to Redux
        this.props.setTokenAction({});
         /// add empty user object to Redux
        this.props.setUserAction({});


       /* =============
       FINAL VALIDATION
       ============= */

       /// if this trigger to TRUE the function will return
       let stop = false;
       /// error message
       let description = ""
       /// STATE keys
       const keys =  Object.keys(this.state);



        if(this.state.avatar === ""){
            this.setState({
                avatar:mester_default_base64,
                avatar_valid:true,

            })
        }



       keys.reverse().map( i => {
          if( (i.includes("_valid") || i === "terms_of_use")  && this.state[i] === false){

                stop = true

                const id = i.replace("_valid","");

                if(id === "avatar" || id === "paswordsIdentical" ){
                    /// custom error handler
                }
                else{
                    document.querySelector(`#${id}`).style.background = "rgba(227,6,6,0.15)";
                } 
                
            
                
                switch (i) {
                       /*  case "avatar_valid":
                            description = "Profilkép feltöltése kötelező!"
                        break; */
                        case "first_name_valid":
                            description = "A keresztnév kitöltése nem megfelelő!"
                        break;
                        case "last_name_valid":
                            description = "A családnév kitöltése nem megfelelő!"
                        break;
                        case "email_valid":
                            description = "A emailcím kitöltése nem megfelelő!"
                        break;
                        case "password_valid":
                            description = "A jelsző kitöltése nem megfelelő!"
                        break;
                        case "password_confirmation_valid":
                            description = "Az ismételt jelszó kitöltése nem megfelelő!"
                        break;
                        case "paswordsIdentical_valid":
                            description = "A két jelszó nem azonos!"
                        break;
                        case "zip_valid":
                            description = "Az irányítószám kitöltése nem megfelelő!"
                        break;
                        case "city_valid":
                            description = "A város kitöltése!"
                        break;
                        case "street_valid":
                            description = "Az utca kitöltése nem megfelelő!"
                        break;
                        case "phone_number_valid":
                            description = "A telefonszám kitöltése nem megfelelő! Példa a megfelelő formátumra: 06701234567"
                        break;
                        case "terms_of_use":
                            description = "A felhasználási feltételek elfogadása kötelező!"
                        break;
                
                    default:
                        break;
                } 

          }
       })

       if(stop === true){
           document.querySelector("#errorText").innerHTML = description;
           
           this.setState({loading:false});
           return
       }

       document.querySelector("#errorText").innerHTML = ""

       
       /// REQUEST
       const {first_name,last_name,type,email,password,password_confirmation,zip,city,street,phone_number,terms_of_use, avatar } = this.state;

       console.log(first_name,last_name,type,email,password,password_confirmation,zip,city,street,phone_number,terms_of_use, avatar);
     
        const response = await request({
                method:"post",
                url:"auth/register", 
                data:{first_name,last_name,type,email,password,password_confirmation,zip,city,street,phone_number,terms_of_use, avatar}
        }); 

    


      

        /// if there is any error
        /// =============
        /// ERROR HANDLER
        /// =============
        if(typeof response.error !== "undefined"){
            
                /// email type errors
                if(typeof response.error.data.email !== "undefined"){
                    const {email} = response.error.data;
                    email.map((e)=>{
                        switch (e) {
                            case "unique:users":
                                description = `A megadott email cím: ${this.state.email} már foglalt!`
                                break;
                            default:
                                break;
                        }
                    })
                }

                  /// email type errors
                if(typeof response.error.data.password !== "undefined"){
                    const {password} = response.error.data;
                    password.map((e)=>{
                        switch (e) {
                            case "confirmed":
                                description = `A megadott jelszó és a megismételt jelszó nem egyezik!`
                                break;
                            default:
                                break;
                        }
                    })
                }


                document.querySelector("#errorText").innerHTML = description;
                return

        }

       
            /* GUARD FUNCTION */
            if(typeof response.data === "undefined" || typeof response.data.token === "undefined"){
               // this.props.history.push("/login")
                return
            }

            
        
        /// add token to Redux
        this.props.setTokenAction({...response.data.token, created: new Date()});


        if(this.state.type === "basic"){
            /// add user to Redux
            this.props.setUserAction({...response.data.user});

        }else{
            let user = {...response.data.user};
                user.incompleteRegistration = {
                    stepTwo: {
                      complete: false,
                      details: {
                        company: {
                          name: null,
                          type: null
                        },
                        location: {
                          zip: null,
                          city: null,
                          street: null
                        },
                        phoneNumber: null,
                        tax_number: null,
                        registrationNumber: null,
                        certificateNumber: null,
                        images:[],
                      }
                    },
                    stepThree: {
                      complete: false,
                      details: {
                        company: {
                          description: null,
                          location:[],
                          images:[]
                        }
                      }
                    },
                    stepFour: {
                      complete: false
                    }
                }

                this.props.setUserAction({...user});
        }

        if(this.state.type === "basic"){
            ///navigate to the main page
            if(this.state.from === "header_footer_button"){
                this.props.history.push("/users/ads/new")
                return
            }else if(this.state.from === "chat" && this.state.data !== null){
                
                this.props.history.push({
                    pathname: "/users/messages", 
                    state: {  
                        type: this.state.data.type, 
                        id:   this.state.data.id 
                      }
                  }); 
                  return  
            }else{
                this.props.history.push("/users/profiles/")
                return
            }
            
        }else if(this.state.type === "professional"){
             ///navigate to the main page
             this.props.history.push("/registration/professional")
        }else{
             ///navigate to the main page
             this.props.history.push("/")
        }

        

     }catch(e){
         console.log(e)
     }

            

    }
    


    /* ==============
    COMPONENTS
    ============== */

    downSideComponent = ({htmlFor, htmlForText, type, id, name, index, subText}) => {
        return(
            <div className="row w-100" key={index}>
                <div className="col" style={{display:"flex", flexDirection:"column"}}>
                    <label htmlFor={htmlFor}>{htmlForText}</label>
                    {subText !== "" ? <label style={{marginTop:-15, marginBottom:15, fontSize:10}}>{subText}</label> : null}
                    <div style={{display:"flex"}}>
                    <input type={ !type.includes("pass") ? type : index === 1 ? this.state.type_pw  : this.state.type_pw_again  } className="form-control" id={id} placeholder="" name={name} onChange={this.onChangeHandler} value={this.state[id]} style={{ textTransform: "none"}}/>
                    {  name.includes("pass") ? <div onClick={()=> { 
                       const type_for_sate = name === "fgpasscheck" ? "type_pw_again" : "type_pw";
                       this.setState({[type_for_sate]: this.state[type_for_sate] === "password" ? "text" : "password" })

                    }} style={{ paddingLeft:5, paddingRight:5, height:40, display:"flex", alignItems:"center", cursor:"pointer" }}>{
                        
                    this.state[name === "fgpasscheck" ? "type_pw_again" : "type_pw"] === "password" ? "jelszó felfedése" : "jelszó elrejtése"}</div> : null}
                    </div>
                </div>
            </div> 
        )
    }


  render(){
      console.log(this.state)
    return (
    <div> 
  
     
        <div className="d-flex w-100 justify-content-center position-absolute"><div className="registerUserDecor">Regisztráció</div></div>
        <div className="d-flex row justify-content-center registerUserRow" >
            <div className="col registerUserBlock" style={{marginTop:25,marginLeft:80, marginRight:80}}>
                <div className="d-flex row w-100">
                    <div className="w-100">
                        <div className="registerFormTittle">Személyes adatok</div>
                        <div className="hdivider"></div>
                    </div>
                </div>
                <div className="d-flex row w-100 justify-content-center">
                    <div className="benceImage">
                        <ImgUpload 
                            index={0} 
                            imageToState={this.imageToState} 
                                  // imageContainerStyles={{ width:400 }} 
                            />  
                           
                    </div>
                    <div className="rightcontainer">
                        <div className="row">
                            <div className="col-xl rightblock">
                                <label htmlFor="fgvezeteknev">Vezetéknév</label>
                                <input type="text" className="form-control" id="last_name" placeholder="" name="fgvezeteknev" onChange={this.onChangeHandler} value={this.state.last_name} />
                            </div>
                            <div className="col-xl rightblockslide">
                                <label htmlFor="fgkeresztnev">Keresztnév</label>
                                <input type="text" className="form-control" id="first_name" placeholder="" name="fgkeresztnev" onChange={this.onChangeHandler} value={this.state.first_name} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl rightblock">
                                <label htmlFor="fgiranyitoszam">Irányítószám</label>
                                <input type="text" className="form-control" id="zip" placeholder="" name="fgiranyitoszam" onChange={this.onChangeHandler} value={this.state.zip}/>
                            </div>
                            <div className="col-xl rightblockslide">
                                <label htmlFor="fgvaros">Város</label>
                                <input type="text" className="form-control" id="city" placeholder="" name="fgvaros" onChange={this.onChangeHandler} value={this.state.city}/>
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col-xl rightblock">
                                <label htmlFor="fgutca">Utca, Házszám</label>
                                <input type="text" className="form-control" id="street" placeholder="" name="fgutca" onChange={this.onChangeHandler} value={this.state.street}/>
                            </div>
                            <div className="col-xl rightblockslide">
                                <label htmlFor="fgtelefon">Telefonszám</label>
                                <input type="text" className="form-control" id="phone_number" placeholder="" name="fgtelefon" onChange={this.onChangeHandler} value={this.state.phone_number}/>
                            </div>
                        </div>                    
                    </div>
                </div> 
                <div className="d-flex row w-100 paddingModifier">
                    <div className="w-100">
                        <div className="registerFormTittle">Fiók adatok</div>
                        <div className="hdividerbottom"></div>
                    </div>
                </div>
                <div className="d-flex row downside">
                    {downSideArray.map( (item, index) =>  this.downSideComponent({htmlFor:item.htmlFor, htmlForText:item.htmlForText, type:item.type, id:item.id, name:item.name, subText:item.subText, index})  )}
                </div>
   
                <div className="d-flex row ">
                    <div className="row">
                        <div className="form-checks col-md-12 col-xl">
                            <label className="containercheck">Elfogadom a felhasználási<br/>feltáteleket
                                <input id="terms_of_use" type="checkbox" onChange={this.onChangeHandler}/>
                                    <span className="checkmark" style={{ background:"white", backgroundPosition:"center", backgroundSize:"10px 8px", backgroundRepeat:"no-repeat", backgroundImage:`url(${ this.state.terms_of_use ? pipe : null})`}}></span> 
                            </label>
                        </div>
                       
                    </div>                
                
                </div>


                <div className="d-flex row w-100 errorPadding">
                    <div className="w-100">
                        <div className="hdivideretop"></div>
                        <div className="errorText" id="errorText"></div>
                        <div className="hdividerebottom"></div>
                    </div>
                </div>   
                
                <div className="d-flex row justify-content-center justify-content-lg-end ">
                    <div className="buttons">
                            <button className="backButton" onClick={()=> this.props.history.goBack()} >Vissza</button>
                            <button className="registerButton" onClick={()=> this.registerButton()}>Mentés</button>
                    </div>                
                
                </div>                


            </div>
        </div>
      
    

    </div>
    );

  }
    }


/*====================== 
* State to Props
=====================*/
 const mapStateToProps = state => {
    const {systemState, userState, tokenState} = state;
    return {systemState, userState, tokenState};
};
  
/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
    setTokenAction, 
    setUserAction
})(RegisterUser));
