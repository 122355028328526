import React, {Component} from 'react';
import {connect} from 'react-redux';

import {request} from '../../Model/Request';
import {quantities} from "../../Model/quantities";

import chat_icon from "../../Assets/message_2.png";

import {Container, Modal, Button} from "react-bootstrap";
import Map from "../../View/Map";
import parse, { htmlToDOM } from 'html-react-parser';

import ReactQuill, { Quill } from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';



import DatePicker from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import "react-datepicker/dist/react-datepicker.css";
import "../../Model/date_picker/css/react_datepicker_custome.css";
import defaultImage from "../../Assets/header_background.png"
import polygon from "../../Assets/polygon.png"

/* LAST */


 class AdvertOffer extends Component  {


  state = {

    isModalShow:false,
    errorMessage:"",
    successMessage:"",

    

    mandateData:"",

    companyID:null,

    quantities : [],

    text_1 : "",
    text_1_2: "",
    text_2 : "",

    start_date:"",
    end_date:"",

    description:"",
    deadline:"",
    city:"",
    budget:"",
    basic_image:null,
   

    user:null,
    customer:null,
   
  
 
  }

   ReactQuillRef;

   componentDidMount(){
    
    

   
    (async ()=>{
  
      await  this.setState({ quantities })
    
        const selectedCompany = this.props.userState.user.company.filter(item => item.selected === true)
        if(selectedCompany.length === 0) return
        const id = selectedCompany[0].id;

        if(typeof this.props.location.state === "undefined") this.props.history.goBack()

          console.log("===============location========")
          console.log(this.props.location.state)
          console.log("=======================")
          console.log("=======================")
          console.log(this.props.userState)
          console.log("=======================")

        const response_user = await request({ url:`/public/user?id=${this.props.location.state.sender.id}` })
        if(typeof response_user.error === "undefined"){
          this.state.user = response_user.data
        }

        const response_offer = await request({ url:`/offers/mine?offer_id=${this.props.location.state.id}` })
        console.log("=============response_offer==========")
        console.log(response_offer)
        console.log("=======================")


        const response_adv_data = await request({ url:`/ads?ad_id=${response_offer.data.relation.id}` })
        console.log("=============adv_data==========")
        console.log(response_adv_data)
        console.log("=======================")

        const response_user_data = await request({ url:`/public/user?id=${response_adv_data.data.relation.id}` })
        console.log("=============USER==========")
        console.log(response_user_data)
        console.log("=======================")
        
        
        
        /// saját adatok - név
        /// dátum ---


          const text_1 =`
          <h1>SZERZŐDÉS</h1>
          <br></br>
          <p>Amely létrejött egyrészről</p>
          <br></br>
          <p>(Építtető)</p>
          <p>Neve: ${response_user_data?.data.attributes?.name ?? ""}</p>
          <p>Lakhelye (székhelye): ${response_user_data?.data?.attributes?.location?.formatted_address ?? ""}</p>
          <p>Képviseli: </p>
          <p>Cégjegyzékszáma: </p>
          <p>Nyilvántartó cégbíróság: </p>
          <p>Adószáma: </p>
          <p>Bankszámlaszáma: </p>
          <p>Beruházás lebonyolító, az építtető általános megbízottjaként: </p>
          <p>mint Megrendelő,</p>
          <br></br>
          <p>másrészről</p>
          <br></br>


          <p>(építőipari kivitelező vállalkozó) Neve: ${this.props.userState.user?.attributes?.name ?? ""}</p>
          <p>Székhelye: </p>
          <p>Képviseli: </p>
          <p>Cégjegyzékszáma: ${this.props.userState.user?.company[0]?.attributes?.tax_number ?? ""}</p>
          <p>Nyilvántartó cégbíróság: </p>
          <p>Adószáma: ${this.props.userState.user?.company[0]?.attributes?.registration_number ?? ""}</p>
          <p>Bankszámlaszáma: </p>
          <p>mint Kivitelező</p>
          <p>(Megrendelő és Kivitelező együtt továbbiakban: Szerződő felek) között az alulírott</p>
          <p>napon és helyen az alábbi feltételekkel:</p>
          <br></br>

          
          <p>A szerződés tárgya:  kelt árajánlat alapján.</p>
          <br></br>
          
          <p>1/ A Megrendelő tulajdonában levő (vagy más jogcímen rendelkezésére álló)</p>
          <p></p>
          <p>ingatlanon, a mellékletként e szerződés elválaszthatatlan részét képező árajánlat alapján, teljes kivitelezése (építését,bontását, átépítését, bővítését, felújítását) az e szerződésben meghatározott</p>
          <p>feltételekkel.</p>
          <br></br>
          <br></br>
          <p>2) A Megrendelő köteles </p>
          <p>( cím )</p>
          `;

          const text_1_2 = `
          <p>levő építési munkaterületet a munka végzésére alkalmas állapotban </p>
          <p id="start_date">{start_date}</p><p> -ig</p>
          <p>a Vállalkozó részére átadni.</p>
          <br></br>
          <br></br>
          <p>3) A munkák kezdésének a Kivitelező által megjelölt, a Megrendelő által elfogadott  időpontja: {start_date} </p>
          <p></p>
          <br></br>
          <br></br>
          <p>4) A kivitelezés Kivitelező által vállalt, a megrendelő által elfogadott teljesítési határideje: <p id="end_date">{end_date}</p> </p>
          <p></p>
          <br></br>
          <br></br>
          `;

          const text_2 = `
          <p>5) A Kivitelező a munkát a saját költségén végzi. A költségek magukban foglalják a Kivitelező által igénybe vett alvállalkozók szerződésükben meghatározott (az alvállalkozók minden költségét magába foglaló) vállalkozói díját.</p>
          <br></br>
          <br></br>
          <p>6/a) A vállalkozói díj: </p>
          <br></br>
          <p>6/b) Részteljesítésben történő megállapodás esetén az 5) pontban meghatározott</p>

        
          

          <br></br>
          <br></br>
          <p>6/c) A felek megállapodnak abban, hogy az előre nem számítható régészeti lelet, vagy előírás szerint elvégzett lőszermentesítés ellenére talált lőszerek feltárása, elszállítás (hatástalanítása) miatti leállás idejével a teljesítési határidők meghosszabbodnak. Ezeknek a munkáknak a költségét a Megrendelő viseli, a Kivitelezőnek díj, vagy kártérítési igénye nem keletkezik.</p>
          <br></br>
          <p>6/d) A szerződés alapját képező 1/a) pont szerinti dokumentációban nem szereplő külön megrendelt munkatétel, mint pótmunka a vállalkozói díjon kívüli külön elszámolás tárgyát képezi.  </p>
          <br></br>
          <br></br>
          <p>7)	A felek megállapodnak abban, hogy a Megrendelő az átadás-átvételi eljárás során felfedezett és a jegyzőkönyvben, vagy külön hiánypótlási jegyzőkönyvben, hiányjegyzékben rögzített mennyiségi és minőségi hibák, hiányok, hiányosságok ismeretében nyilatkozik arról, hogy a hiányosságok pótlását, a hibák kijavítását kéri, vagy árengedményt kér .</p>
          <br></br>
          <br></br>
          <p>8) Ha a Megrendelő a 8) pontban megjelölt hiányosságok pótlását, hibák kijavítását kéri, a pótlások és a kijavítások elvégzését követően, az azok elvégzését és átvételét igazoló jegyzőkönyv alapján, a Megrendelő (a fedezetkezelő, a feltételhez kötött letét, bankgarancia, hitel számla vezetője, utólagos, tételes elszámolás alapján az elszámolást követő) 10 napon belül köteles a 7) pontban megállapított vállalkozói díjat a Kivitelező számlájára átutalni..                   </p>
          <p>Részteljesítés esetén az elvégzett munkák átvételét igazoló átadás-átvételi jegyzőkönyv alapján, a 7) pontban meghatározott ellenértéket az átadás-átvételt követő 10 napon belül köteles átutalni a Kivitelező számlájára.</p>
          <br></br>
          <br></br>
          <p>9) A felek megállapodnak abban, hogy </p>
          <p>- a Kivitelező által végzendő (beleértve az alvállalkozókat is)  munkákhoz az elektromos áram és vízszolgáltatást a Megrendelő biztosítja, azok költsége nem része a vállalkozói díjnak.</p>
          <br></br>
          <br></br>
          <p>10) A Kivitelező az építmény megvalósításához szakmunkák elvégzésére az alábbi általa kiválasztott, a jogszabályban előírt követelményeknek  megfelelő    alvállalkozókat veszi igénybe:</p>
          <p></p>
          <br></br>
          <br></br>
          <p>11) Ha további alvállalkozó bevonása válik szükségessé, a Kivitelező arról közvetlenül is értesíti a Megrendelőt. </p>
          <p>Az alvállalkozók által bevont alvállalkozók jogszerű alkalmazásáért az alvállalkozó mellett a Kivitelező is felelős.</p>
          <br></br>
          <br></br>
          <p>12) Az építési munkaterületen csak a jogszerűen munkát végzők tartózkodhatnak.</p>
          <br></br>
          <br></br>
          <p>13) A Kivitelező köteles a Megrendelő megbízottjaival együttműködni a megbízásuk szerinti és jogszabályban meghatározott kötelezettségeik teljesítésének biztosítása érdekében. Köteles továbbá az e szerződés tárgyát képező munkákkal kapcsolatos tájékoztatási kötelezettségeinek  folyamatosan eleget tenni. </p>
          <br></br>
          <br></br>
          <p>14) A Kivitelező köteles biztosítani a Megrendelő számára az anyagok és munkák ellenőrzésének lehetőségét. Ha valamely munkarészt beépít vagy eltakar a tájékoztatási kötelezettségének a Megrendelő előzetes értesítésével tesz eleget . </p>
          <br></br>
          <br></br>
          <p>15) A Megrendelő az utasítás adási jogkörével élve nem adhat jogszabályi, vagy hatósági előírással ellentétes utasítást a Kivitelezőnek. Amennyiben e rendelkezéstől eltér, a Kivitelező köteles annak teljesítését megtagadni, és mind az utasítást, mind a végrehajtás megtagadásának tényét az építési naplóba rögzíteni. </p>
          <p>Szakszerűtlen utasítás esetén köteles a Megrendelőt figyelmeztetni, az utasítás fenntartása esetén a műszaki ellenőrt értesíteni, azt az építési naplóba bejegyezni, és a műszaki ellenőr aláírását követően a megrendelő kockázatára a munkát az utasítás szerint elvégezheti, ha az az élet – és vagyonbiztonságot nem veszélyezteti.    </p>
          <br></br>
          <br></br>
          <p>16) A kivitelezési munkák közben keletkező építési hulladékok gyűjtése, elszállíttatása a hulladék jogosult kezelőhöz a Vállalkozó kötelezettsége.</p>
          <br></br>
          <br></br>
          <p>17) Az építési munkákhoz igénybevett közterület tisztántartása a Vállalkozó kötelessége.</p>
          <br></br>
          <br></br>
          <p>18) A Kivitelező teljesítésének elválaszthatatlan része az építési munkák befejezését követően minden hulladék elszállítása és a teljes tereprendezés.</p>
          <br></br>
          <br></br>
          <p>19)  A felek kijelentik, a szerződésből eredő gazdasági vitáik rendezésére jelen alávetési nyilatkozatukkal elfogadják a Magyar Kereskedelmi és Iparkamara mellett működő Választottbíróság kizárólagos illetékességét.</p>
          <br></br>
          <br></br>
          <p>20) A jelen szerződésben nem szabályozott kérdésekben a Ptk. rendelkezései az irányadóak.</p>
          <br></br>
          <br></br>
          `
     
        const from = response_offer.data?.relation?.payment_limit[0]?.from ?? "";
        const to = response_offer.data?.relation?.payment_limit[0]?.to ?? "";

        this.setState({
            companyID:id, 
            text_1, text_2, text_1_2,
            mandateData:this.props.location.state, 
            start_date:new Date(this.props?.location.state?.from_date ?? ""), 
            end_date:new Date(this.props?.location?.state?.until_date ?? ""),

            description:response_offer.data?.relation?.description ?? "",
            deadline:response_offer.data?.relation?.deadline[0]?.title ?? "" ,
            budget: from + " -  " + to,
            city:response_offer.data?.relation?.location?.city ?? "",
            basic_image:response_offer.data?.relation?.basic_image?.src ?? null,
            customer:response_user_data.data
        })


        document.querySelectorAll(".ql-toolbar")[1].style.display = "none";
        const x = document.querySelectorAll(".ql-container.ql-snow")[0].style.borderBottomColor = "rgba(0,0,0,0)";
        
     


      })()

   
      
   }


  componentDidUpdate(prevProps, prevState){
  
  }


  
 

  removeHTMLTags = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return data.replace(regex, "");
  }

  handleChange_text_1 = (value) => {
    this.setState({ text_1: value })
  };

  handleChange_text_2 = (value) => {
    this.setState({ text_2: value })
  };


  dateParser_hyphen = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`

  
  


  name_unit_price_box = (label, title) => 
    <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
      <div style={{ ...styles.p, alignItems:"center", fontSize:10, display:"flex", paddingRight:10}}> {title} </div>
      <div style={{ ...styles.p, padding:5, border:"1px solid #ccc", borderRadius:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center"}} >{label}</div> 
    </div>
  
  date_box = (title, type) => 
  <div style={{flex:1,display:"flex", flexDirection: this.props.systemState.dimension.width <= 950 ? "column":"row", padding:10, justifyContent:"space-evenly", }} >
    <div style={{ ...styles.p, alignItems:"center", fontSize:10, display:"flex", paddingRight:10}}> {title} </div>
    <div style={{ ...styles.p, padding:5, width: this.props.systemState.dimension.width <= 950 ? "100%":"50%", display:"flex",paddingLeft:5, alignItems:"center"}} >
    
    
    <DatePicker 
        locale={hu} 
        minDate={new Date()}
        selected={this.state[type]} 
        dateFormat="yyyy-MM-dd"
        style={{ ...styles.p}}
        onChange={ date => this.setState({ [type]: date }) } />  

    </div> 
  </div>




sendContract = async () => {


  const text_1_2 = this.state.text_1_2 
  .replace(/{start_date}/g,this.dateParser_hyphen(this.state.start_date))
  .replace("{end_date}",this.dateParser_hyphen(this.state.end_date))
 

  const html = '<!DOCTYPE html><html><head><meta charset="utf-8"><title>CONTRACT</title></head><body>' + this.state.text_1 + text_1_2 + this.state.text_2 + "</body></html>";

  const data = {
      offer_id:this.props.location.state.id,
      data:html
  }

  const response = await request({ url:"/offers/confirmed/contract", method:"POST", data })

      if( typeof response.error === "undefined" ){

        this.setState({
          isModalShow:true,
          successMessage:"A SZERZŐDÉS SIKERESEN ELKÜLDVE"
        })

      }else{

        this.setState({
          isModalShow:true,
          errorMessage:"Hiba lépett fel a szerverrel való kommunikáció során! Kérlek próbáld meg később."
        })

      }


 
}

goBack = () => this.props.history.goBack()

  render(){
  
    console.log(this.state)
    const window_width =  this.props.systemState.dimension.width;
    return (

      <div style={{ background:"rgb(246, 249, 254)", paddingBottom:50}}>

            <Modal show={this.state.isModalShow} onHide={ ()=> {
              if(this.state.successMessage !== ""){
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                this.props.history.goBack()
              }else{
                this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
              }
              
              } }>
              <Modal.Header>
                <Modal.Title style={{...styles.p, fontSize:22}}></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{...styles.p}}>{this.state.errorMessage}</div>
                <div style={{...styles.p}}>{this.state.successMessage}</div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant={ this.state.successMessage === "" ? "secondary" : "primary"} onClick={ ()=> 
                  
                  {
                    if(this.state.successMessage !== ""){
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                      this.props.history.goBack()
                    }else{
                      this.setState({isModalShow:false, errorMessage:"",  successMessage:""})
                    }
                  }
                  
                  }>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            <div style={{...styles.headerText, fontSize: window_width > 901 ? 90 : window_width / 10 }}>
              <div>{"SZERZŐDÉS"}</div>
            </div>

           {/*  {  this.offer_SITE(window_width)  } */}
          
           <Container style={{backgroundColor:"white", borderRadius:6, boxShadow:"2px 4px 20px rgba(0, 0, 0, 0.25)" }}>

            <div style={{display:"flex", paddingTop:50}}>
            <div style={{flex:1, ...styles.p, display:"flex", alignItems:'center'}}>  <img src={polygon} style={{filter: "invert(1)", transform:"rotate(90deg)", height:10, cursor:"pointer", marginRight:10}} onClick={()=> this.goBack()} /> <div style={{cursor:"pointer"}} onClick={()=> this.goBack()} > VISSZA </div></div>
            <div style={{flex:1, display:"flex", justifyContent:"center", ...styles.header }}>HÍRDETÉS</div>
            <div style={{flex:1}}></div>
            </div>
            {/* banner image */}
              <div style={{...styles.imgDIVBig_offer, marginTop:20, marginBottom:20}}>


              <div style={{ display:"flex", padding:0, flexDirection:window_width <= 950 ? "column" :"row"}}>

              <div style={{...styles.infoBox, paddingTop:20, paddingBottom:20, paddingLeft:40, paddingRight:40}}>
                <div style={{...styles.p}}>{this.state.data?.attributes?.profession?.name ?? ""}</div>
                <div style={{...styles.p,  marginTop:5, fontSize:10, }}>{ this.state.data?.attributes?.hash_id ?? "" }</div>
                <div style={{...styles.p, marginTop:5}}> leírás: </div>
                <div style={{...styles.p,  marginTop:5, maxHeight:100, overflow:"hidden"}}>{this.removeHTMLTags(this.state.description ?? "")}</div>
              </div>

              <div style={{ flex:1, paddingTop:20, paddingBottom:20 }}><div style={{...styles.divider_vertical}}/></div>

              <div style={{...styles.infoBox, alignItems:"flex-start", paddingTop:20, paddingBottom:20}}>
                <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>HATÁRIDŐ</div>
                <div style={{...styles.p}}>{this.state.deadline ?? ""}</div>
                <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>KÖLTSÉGHATÁR</div>
                <div style={{...styles.p}}>{this.state.budget ?? ""}</div>
                <div style={{...styles.p,marginTop:5, fontWeight:"bold", color:"#1E466D", fontSize:20 }}>MUNKAVÉGZÉS HELYE</div>
                <div style={{...styles.p}}>{this.state.city  ?? ""}</div>
              </div>

              <div style={{...styles.infoBox, backgroundImage:`url(${this.state.basic_image ?? defaultImage})`, backgroundSize:"cover", minHeight:200, borderTopRightRadius:5, borderBottomRightRadius:5}}>
                
              </div>

              </div>


              </div>   

              {
                this.state.expert_id !== ""
                ? <div style={{flex:1, display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:'column', marginTop:30}}>
                    <div style={{ height:60, width:60, backgroundColor:"rgba(38,134,176,0.6)",backgroundPosition:"center", backgroundSize:"60%", backgroundRepeat:"no-repeat" ,backgroundImage:`url(${chat_icon})`, borderRadius:7 }}
                      onClick={()=> this.props.history.push({
                      pathname: this.props.userState.user.attributes.type === "professional" ? '/experts/messages' : '/users/messages',
                      search: '',
                      state: { type: "user" , id:  this.state.customer.id}
                    }) }/>
                    <div style={{display:"flex", height:60, alignItems:"center"  }}>
                      <div style={{padding:5, fontSize:10}}>BESZÉLGETÉS A MEGBÍZÓVAL</div>
                      </div>
                  </div>
                : null
              }


              <div style={{display:"flex", justifyContent:"center", paddingTop:50, paddingBottom:25}}>
                  <div style={{...styles.header}}>SZERZŐDÉS</div>
              </div>

              {/* divider */}
              <div style={{...styles.divider,  marginBottom:50}} />

              {/* EDITOR */}
              <ReactQuill  
                placeholder= "Ajánlat helye..." 
                value={this.state.text_1} 
                onChange={(value)=> this.handleChange_text_1(value)} 
                style={{minHeight:300, borderColor:"red"}}  
                ref={(ref)=> this.ReactQuillRef = ref}
                />

                <div style={{ padding:15, borderColor:'#ccc', borderWidth:1, borderLeftStyle:"solid", borderRightStyle:"solid"}}>
                <div style={{...styles.p_}}>levő építési munkaterületet a munka végzésére alkalmas állapotban </div>
                <div style={{...styles.p_}}> 

           
                <DatePicker 
                  locale={hu} 
                  selected={this.state.start_date} 
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  style={{ ...styles.p_}}
                  onChange={ date => this.setState({ start_date: date }) } />   -ig</div>
                <div style={{...styles.p_}}>a Vállalkozó részére átadni.</div>
          
                <br></br>
                <div style={{...styles.p_}}>3) A munkák kezdésének a Kivitelező által megjelölt, a Megrendelő által elfogadott  időpontja:  
                <DatePicker 
                minDate={new Date()}
                  locale={hu} 
                  selected={this.state.start_date} 
                  dateFormat="yyyy-MM-dd"
                  style={{ ...styles.p_}}
                  onChange={ date => this.setState({ start_date: date }) } /></div>
                <p></p>
     
                <br></br>
                <div style={{...styles.p_}}>4) A kivitelezés Kivitelező által vállalt, a megrendelő által elfogadott teljesítési határideje:  
                <DatePicker 
                minDate={new Date()}
                  locale={hu} 
                  selected={this.state.end_date} 
                  dateFormat="yyyy-MM-dd"
                  style={{ ...styles.p_}}
                  onChange={ date => this.setState({ end_date: date }) } /></div>
                <p></p>
   
                <br></br>
                </div>

                 {/* EDITOR */}
              <ReactQuill  
                placeholder= "Ajánlat helye..." 
                value={this.state.text_2} 
                onChange={(value)=> this.handleChange_text_2(value)} 
                style={{minHeight:300, marginBottom:80}}  
                ref={(ref)=> this.ReactQuillRef = ref}
                />


            {/* divider */}
            <div style={{...styles.divider,  marginBottom:50, marginTop:50}} />



            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <div 
                    style={{ ...styles.buttonContainer, width: window_width <= 950 ? "100%" : "33%", display:"flex", cursor:"pointer", marginBottom:80 }}
                    onClick={()=>  this.sendContract() } >
                        <div style={{...styles.p, fontWeight:"bold", color:"white"}} >SZERZŐDÉS KÜLDÉSE</div>
                </div>
            </div>



   

     


    
      

</Container> 


    
      </div>
    );

  }
}

const styles = {

  imgDIV : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:310, 
            height:210,
            borderRadius:6,
            marginTop:20,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },
imgDIVBig : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%", 
            height:410,
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },

imgDIVBig_offer : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%",
            borderRadius:6,
            boxShadow:"  0px 4px 20px rgba(0, 0, 0, 0.25)"
          },          

imgCover : {
            backgroundSize:"cover",
            objectFitt:"cover",
            backgroundRepeat:"no-repeat",
            backgroundColor:"white",
            backgroundPosition:"center", 
            width:"100%",
            height:600,
},  

firstContainer : {
            position:"absolute", 
            left:0, 
            right:0, 
            height:200, 
            backgroundColor:"rgba(255, 255, 255, 0.9)", 
            marginTop:400, 
            display:"flex", 
            justifyContent:'center'
},

secoundContainer : { 
            height:100, 
            width:"100%",
            background: "linear-gradient(180deg, #0F3A83 0%, #0C78B5 100%)",
            ransform: "rotate(180deg)",
            marginBottom:30
},

subContainer : {
             padding:30
},

infoBox : {
            flex:10, 
            display:"flex", 
            alignItems:"center", 
            flexDirection:"column", 
            padding:10
},

p : { 
            fontFamily:"Maven Pro", 
            letterSpacing:2
},

header : { 
  fontFamily:"Maven Pro", 
  letterSpacing:2,
  fontSize:26,
  fontWeight:"bold",
  color:"#4F4f4F"
},

divider : {
            width:"100%", 
            height:1, 
            backgroundColor:"#4F4F4F"
},

divider_vertical : {
            width:1, 
            height:"100%", 
            backgroundColor:"#4F4F4F"
},

imageContainer : {
            marginTop:50,
            display:"flex", 
            justifyContent:"space-evenly", 
            flexWrap:"wrap"
},
headerText: {display:"flex", justifyContent:"center", fontFamily:"Maven Pro", fontWeight:"bold", color:"white"},

buttonContainer: {
  paddingTop:20, paddingBottom:20, paddingRight:40, paddingLeft:40, background:"#217BA7", borderRadius:5, justifyContent:'center', marginTop:30,
},
p_:{fontSize:13},

mainSelectStyle : {
  container: provided => ({
    ...provided,
    border:'1px black solid',
    borderRadius:4,
    width: "100%",
    marginLeft:5, 
    marginRight:10,
   fontFamily:"Maven Pro",
   fontSize:16, 
   fontWeight:"bold",
   color:'#4F4F4F'
  }),
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px solid #4F4F4F',
    backgroundColor: state.isSelected ? "#1C466F":"white",
    color: state.isSelected ? 'white' : '#4F4F4F',
    width: '100%',
    fontFamily:"Maven Pro"
    
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    fontFamily:"Maven Pro"
  })
},

}


/*====================== 
* State to Props
=====================*/
const mapStateToProps = state => {
  const {systemState, userState, tokenState} = state;
  return {systemState, userState, tokenState}
};

/*===================================================  
* Export the class and connect it to the STORE
====================================================*/
export default connect(mapStateToProps,{})(AdvertOffer);


/* 
{
      "type": "Ads",
      "id": 1,
      "attributes": {
        "hash_id": "179xM",
        "status": "active",
        "profession": {
          "id": 1,
          "name": "Ablakos",
          "type": "indoor"
        },
        "deadline": {
          "id": 1,
          "name": "A lehető leghamarabb"
        },
        "payment_limit": {
          "id": 4,
          "name": "Nincs összeghatár"
        },
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sddd, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "basic_image": "https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg",
        "images": [
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
          {src:"https://image.shutterstock.com/z/stock-photo-bright-spring-view-of-the-cameo-island-picturesque-morning-scene-on-the-port-sostis-zakinthos-1048185397.jpg"},
        ],
        "location": {
          "id": 2,
          "type": "area",
          "lat": 47.5011,
          "lng": 19.0469,
          "distance": 60,
          "zip_code": 1011,
          "city": "Budapest i. kerület",
          "formatted_address": "1221 Budapest, Tompa utca 8."
        } 
      },
      "relation": {
        "type": "User",
        "id": 2
      }
    }

*/