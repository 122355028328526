import React, {Component} from 'react';
import PartnerSelector from './PartnerSelector/PartnerSelector';
import MessageReader from './MessagesReader/MessagesReader';
import MessagesSender from './MessagesSender/MessagesSender';
import {request} from '../../../Model/Request'
import {connect} from 'react-redux';
import {setTokenAction, setUserAction} from "../../../Model/Action/";

/* ROUTER */
import {
  BrowserRouter as Router,
  withRouter 
} from "react-router-dom";

import no_image from "../../../Assets/no_message.png"
import './Messages.css'


 const TIMEINTERVAL = 10000;


 class Messages extends Component  {

  state = {
    selectedPartners : null,
    newPartner:null,
    messages: [],
    pagination:null
  }

  myTimeout;
  serverCommunicationisRunning = false;

  componentDidMount() {

    const chat_data = this.props?.location?.state ?? "";
    if(chat_data !== ""){
     this.setState({newPartner:{type:chat_data.type, id:chat_data.id}})
    }

  }

  

 
   componentWillUnmount(){
    clearTimeout(this.myTimeout)
   }
   componentDidUnMount()
   {
    clearTimeout(this.myTimeout)
   }

  componentDidUpdate(){
    console.log("=========================")
    console.log(this.state.selectedPartners)
    console.log(this.state.messages)
    console.log("=========================")

  }

  isUserLoggedIN = () => {
    const {user} = this.props.userState
    console.log(user)
    return Object.entries(user).length === 0 && user.constructor === Object ? false : true
  }

  run(){

    clearTimeout(this.myTimeout);
   /*  alert(this.isUserLoggedIN()) */
   if(this.isUserLoggedIN()){ 
   
      this.messageRequest({method:"GET", url:`/messenger/conversation?per_page=${1000}&page=${1}&participant_id=${this.state.selectedPartners.id}&participant_type=${this.state.selectedPartners.type}&selected_type=${this.props.userState.user.type}&selected_id=${this.props.userState.user.id}`, body:"", callBackFunction:this.getMessagesHandler, extraData:this.state.selectedPartners});
      this.myTimeout = setTimeout(()=>{ this.run(); }, TIMEINTERVAL); 
     } 
     
  }

  changeCurrentPartner= async (partner) =>{
    //console.log("user",partner);
    let st = {...this.state};
    st.selectedPartners = partner;
    st.messages = [];
    await this.setState({...st}); 
    this.run();
    //await this.messageRequest({method:"GET", url:`/messenger/conversation?per_page=${1000}&page=${1}&participant_id=${partner.id}&participant_type=${partner.entity_type}`, body:"", callBackFunction:this.getMessagesHandler, extraData:partner});
  }; 

  

  addNewMessagesFromMessageSender= async (message)=>{
    console.log("@@@",message);
    if (message!="")
    {
      let st = {...this.state};
      st.messages.push(
        {
          id:st.messages.length,
          senderid:this.props.userState.user.id,
          date: "date",
          text: message,
          name: this.props.userState.user.attributes.name,
          messageType:"own",
          img:this.props.userState.user.attributes.avatar.src            
        }
       );

        await this.setState(st);

      console.log("RESIVER")
      console.log(this.state.selectedPartners.type)
      console.log(this.state.selectedPartners.id)
      console.log("SENDER")
      console.log(this.props.userState.user.type)
      console.log(this.props.userState.user.id)

       await this.messageRequest({
         method:"POST", 
         url:"/messenger", 
         body:{
           receiver:{
              type:this.state.selectedPartners.type,
              id:this.state.selectedPartners.id
           },
           sender:{
             type: this.props.userState.user.type,
             id:this.props.userState.user.id
           },
           message: message
          }, 
          callBackFunction:this.postMessageHandler });
        
      


    }
  }




  getMessagesHandler = async (response, partner) => {
      //console.log("------------------------------------------------------------------------");
          //console.log(response.data);

          let convertedMessages = response?.data?.message.map(
            (message,index) =>
            {
              let ret={};
               if (message.sender.id==this.props.userState.user.id)
               {
                 ret.id = index;
                 ret.senderid = this.props.userState.user.id;
                 ret.date = message.created_at;
                 ret.text = message.message;
                 ret.name = this.props.userState.user.name;
                 ret.messageType = "own";
                 ret.img = this.props.userState.user.attributes.avatar.src;
       
               }else
               {
                 ret.id = index;
                 ret.senderid = partner.id;
                 ret.date = message.created_at;
                 ret.text = message.message;
                 ret.name = partner.name;
                 ret.messageType = "sender";
                 ret.img = partner.attributes.avatar.src;          
       
               }
               return ret;
            }
            
          );
          //console.log("------------------------------------------------------------------------");
          //console.log(convertedMessages);

          let st = {...this.state};
              st.messages = convertedMessages;
              st.pagination = response.data.paginator;
           this.setState(st);
          //console.log('ajjjajjajja',convertedMessages);
  }

  postMessageHandler  = async (response) => {
    this.run();
    //await this.messageRequest({method:"GET", url:`/messenger/conversation?per_page=${1000}&page=${1}&participant_id=${this.state.selectedPartners.id}&participant_type=${this.state.selectedPartners.entity_type}`, body:"", callBackFunction:this.getMessagesHandler, extraData:this.state.selectedPartners});
  }

  // REQUEST
  messageRequest = async ({method, url, body, callBackFunction, extraData=null}) => {
      if (this.serverCommunicationisRunning) return;
      
      try{
        
        this.serverCommunicationisRunning = true;
        const response = await request({url,method,data:body})

        this.serverCommunicationisRunning = false;

        if(typeof response.error !== "undefined"){
              console.log(response.error)
        }else{

          //extraData !== null ? callBackFunction(response, extraData) : 
          callBackFunction(response, extraData)
           
        }

      }catch(e){
        this.serverCommunicationisRunning = false;
         //console.log(e)
      }
    }


    specialStyle = () => {
      
      if( 992 < this.props.systemState.dimension.width  ){
        console.log(1)
        return {zoom:"100%", MozTransform:"scale(1)", MozTransformOrigin:"left top"}
      }
      if( 776 <  this.props.systemState.dimension.width ){
        console.log(2)
        console.log(this.props.systemState.dimension.width)
        return {zoom:"80%", MozTransform:"scale(0.8)", MozTransformOrigin:"left top"}
      }
      if(450 < this.props.systemState.dimension.width){
        console.log(3)
        return {zoom:"60%", MozTransform:"scale(0.6)", MozTransformOrigin:"left top"}
      }
      if(300 < this.props.systemState.dimension.width ){
        console.log(4)
        return {zoom:"40%", MozTransform:"scale(0.4)", MozTransformOrigin:"left top"}
      }
      if(100 < this.props.systemState.dimension.width ){
        console.log(4)
        return {zoom:"20%", MozTransform:"scale(0.2)", MozTransformOrigin:"left top"}
      }
      
      return {zoom:"100%", MozTransform:"scale(1)", MozTransformOrigin:"left top"}
      
      
    }

  render(){


    
    return (
       <div className="innerContainer d-flex w-100" style ={{ ...this.specialStyle() }}>

        

            
             <div style={{ justifyContent:"center", alignItems:"center", width:'100%', display: this.state.selectedPartners === null ? "flex" : "none"  }} > 
                <img src={no_image} height='100%' width="auto" style={{paddingBottom:30}} />
              </div> 
               
               <div style={{ display:  this.state.selectedPartners === null ? "none" : "flex", width:"100%"}}>
             <PartnerSelector newpartner={this.state.newPartner} call={this.changeCurrentPartner} expert={this.props.expert}></PartnerSelector>
             <div className="messages">
                <MessageReader user={this.props.userState.user} partner={this.state.selectedPartners} messages={this.state.messages}></MessageReader>
                <MessagesSender call={this.addNewMessagesFromMessageSender}></MessagesSender>
             </div>
               </div>
          
             
          

      </div>
    );

  }
}


/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/

/*====================== 
 * State to Props
 =====================*/
 const mapStateToProps = state => {
  const {systemState, userState} = state;
  return {systemState, userState};
};

/*===================================================  
 * Export the class and connect it to the STORE
====================================================*/
export default withRouter(connect(mapStateToProps,{
 setTokenAction,
 setUserAction,
})(Messages));