/* 
* QUANTITIES
*/

export const quantities = [
    { value: 0,      label: 'mm'},
    { value: 1,      label: 'cm'},
    { value: 2,      label: 'dm'},
    { value: 3,      label: 'm'},
    { value: 4,      label: 'km'},
    { value: 5,      label: 'mm2'},
    { value: 6,      label: 'cm2'},
    { value: 7,      label: 'dm2'},
    { value: 8,      label: 'm2'},
    { value: 9,      label: 'km2'},
    { value: 10,     label: 'mm3'},
    { value: 11,     label: 'cm3'},
    { value: 12,     label: 'dm3'},
    { value: 13,     label: 'm3'},
    { value: 14,     label: 'km3'},
    { value: 15,     label: 'db' },
  ]
