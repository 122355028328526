/// REDUX STORE
import {store} from "../index";
/// REQUEST CONFIG
import {env_baseURL, env_apiPrefix, env_apiVersion} from "./RequestConfig";
/// AXIOS
import axios from 'axios';
/// Request cancel token
const CancelToken = axios.CancelToken;
/// request cancel
export const source = CancelToken.source();



/*
*
* REFRESH TOKEN HANDLERS
*
*/

/// is the new refresh token feching in progress?
let isAlreadyFetchingAccessToken = false
/// requests array
let subscribers = []

/// fetch the request anagin
function onAccessTokenFetched(access_token) {
  console.log(subscribers)
  subscribers = subscribers.filter(item => typeof item === "function").map(callback => callback(access_token))
}

/// add requests to the array
function addSubscriber(callback) {
  subscribers.push(callback)
}


/// fetch new access and new refresh token
const fetchAccessToken = async () => {
     /*  try{
      const data = await request({url:"token/refresh", method:"POST", data:{"refresh-token": store.getState().tokenState.token.refresh} })
      console.log(data)
      return data
    }catch(e){
      return e
    }   */
    try{

      const response = await fetch(`${env_baseURL}${env_apiPrefix}${env_apiVersion}token/refresh`,{
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + store.getState().tokenState.token.access
        },
        body: JSON.stringify({"refresh-token": store.getState().tokenState.token.refresh })
      });

   
   
      
      const data = await response.json();

      if(isAlreadyFetchingAccessToken === false) return

      console.log("=====>=====>=====>=====>=====>")
      console.log("REFRESH TOKEN DATA:")
      console.log(data)
      console.log("=====>=====>=====>=====>=====>")



      if( 
         response.status === 500                        ||  /// ha 500 internal tér vissza
          (
          typeof data.error !== "undefined" && 
          typeof data.error.message !== "undefined" &&  
                 data.error.message === "Please log in to get the next refresh token"
          )                                              || /// ha van error object és az error object tartalmaz message kulcsot és ha ez a kulcs értéke egyenlő "Please log in to get the next refresh token"
         typeof data.message === "undefined"             || /// ha nincs message object
                data.message === "Unauthenticated."      || /// ha a message értéke = "Unauthenticated."
         typeof data.data.token_type === "undefined"     ||
         typeof data.data.expires_in === "undefined"     ||
         typeof data.data.access_token === "undefined"   
                
        ){

        store.dispatch({
          type: 'setTokenAction',
          payload:  {
            setTokenAction : {}
          }
        });

        store.dispatch({
          type: 'setUserAction',
          payload:  {
            setUserAction : {}
          }
        });

        store.subscribe(()=>{ console.log("Unauthenticated") });

        console.log("error ágra futott")
      
        window.location.replace(window.location.origin + "/login?message=autherror")

        return {error:"error"}

      }else{
        return data;
      }

     


      

      }catch(e){
        return e
      }
}


/// is the user logged in ?
const isUserLoggedIN = () => {
  const {token} = store.getState().tokenState
  return Object.entries(token).length === 0 && token.constructor === Object ? false : true
}



const systemstate_load_update = (bool) =>{
  store.dispatch({
    type: 'loadAction',
    payload:  bool
  });

  store.subscribe(()=>{});
}


/*
* INTERSEPTORS
*
* ///  request interceptor
* ///  response interceptor
*
*/


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    /// add token to the header if the is a token in the store
   // console.log(config.url)

   
    if(
      config.url.includes("messenger")                                || /// MESSAGE
      config.url.includes("notifications")                            || /// NOTIFICATION
      config.url.includes("/profession?per_page=1000&current_page=1") || /// PROFESSION MAIN CATEGORY
      config.url.includes("/profession/specialization?profession_id=")   /// PROFESSION SUB CATEGORY
      ){}else{
        systemstate_load_update(true)
      }
    


    if(isUserLoggedIN()){
      config.headers.Authorization = 'Bearer ' + store.getState().tokenState.token.access
    }
    return config;
  }, function (error) {
    //console.log(error)
    // Do something with request error
    return Promise.reject(error);
});



// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
  
    systemstate_load_update(false)

    return response;

    },  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    systemstate_load_update(false)
    console.log(error)
    const { config, response: { status } } = error
    const originalRequest = config

  /*   if(status === 401 && originalRequest.url.includes("/messenger")){
      return
    } */

    /// if the statuscode is 401 AUTH ERROR and the user has TOKEN
    if (status === 401) {
       
      /// if there is no token fetching progress
      if (!isAlreadyFetchingAccessToken) {

        console.log("<------> FUT <------>")
        /// set the refresh token indicator boolian to TRUE
        isAlreadyFetchingAccessToken = true

        console.log("======= TOKEN FETCH BEGIN ========")
        /// get new token from server
        fetchAccessToken().then((newToken)=>{

        console.log("===========NEW TOKEN ===========")
        console.log(newToken)
        console.log("================================")

        if(typeof newToken.error !== "undefined") return

        /// store the new access token
        store.dispatch({
          type: 'setTokenAction',
          payload:  {
            setTokenAction : {
                type:       newToken.data.token_type,
                expires_in: newToken.data.expires_in,
                access:     newToken.data.access_token,
                refresh:    newToken.data.refresh_token,
            }
          }
        });

        store.subscribe( () => {} );

        const {access_token} = newToken.data;

        isAlreadyFetchingAccessToken = false

        onAccessTokenFetched(access_token)
        originalRequest.headers.Authorization = 'Bearer ' + newToken.data.access_token

        return axios(originalRequest)


        })



        

      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = 'Bearer ' + access_token
          resolve(axios(originalRequest))
        })
      })
      return retryOriginalRequest
    }

    return Promise.reject(error);
});



/*
* REQUEST
*
* ///  baseURL
* ///  url
* ///  method
* ///  headers
* ///  timeout
* ///  responseType
* ///  data
*/

export const request = async({
    baseURL = `${env_baseURL}${env_apiPrefix}${env_apiVersion}`,
    url = '',
    method  = 'GET',
    headers,
    timeout = 120000,
    responseType = 'json',
    data = {},
  }) => {


    let header = {'Content-Type': 'application/json', 'Accept': 'application/json'};
    /// if header is defined
    if(typeof headers !== "undefined" && headers !== null && headers !== ""  ){
        /// if header object and not empty
        if(Object.entries(headers).length !== 0 && headers.constructor === Object){
            header = {...header, headers}
        }
    }


    try {
      const response = await axios({
        baseURL,
        url,
        method,
        headers:header,
        timeout,
        responseType,
        data,
        cancelToken: source.token
      });

      console.log(response)
      if(typeof response.data !== "undefined"){
        return response.data
      }else{
        return response
      }


    } catch (error) {
     
        if(typeof error.response !== "undefined" && typeof error.response.data !== "undefined" ){
          console.log(error)
          let { data } = error.response;
          
          if(typeof data.error === "undefined"){
            data.error = "error";
          }
          
          return data
        }else{
          console.log("safety error ág")
          let data = error;
          data.error = "error"
          return data
        }


    }

}



/////
/*
const testFunc = () => {

  console.log(store.getState())
  store.dispatch({
      type: 'dimensionAction2',
      payload:  {
          dimension : { "height": 666, "width": 666 }
      }
  })
  store.subscribe(()=>{})
  return store.getState()

}
 */

////// FETCH

    /* try{

    const response = await fetch(`${env_baseURL}${env_apiPrefix}${env_apiVersion}token/refresh`,{
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization' : 'Bearer ' + store.getState().tokenState.token.access
      },
      body: JSON.stringify({"refresh-token": store.getState().tokenState.token.refresh })
    });

    return await response.json()

    }catch(e){
      return e
    }   */


   